import apolloClient from "core/graphql/apolloClient";
import commonActions from "../actions";
import queryActions from "../../queries/actions";
import queryLoader from "core/graphql/queryLoader";

export default async (store, action) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("generateCache"),
      variables: { input: "fetch" }
    });
    if (!data.generateCache) {
      console.log("GraphQL response", data);
      throw new Error("No cache information received");
    }

    if (
      (action.version !== data.generateCache && action.flag !== "wait") ||
      store.getState().cache.cache === 0
    ) {
      store.dispatch(commonActions.setFlag("wait"));
      store.dispatch(commonActions.setCacheVersion(data.generateCache));
      store.dispatch(queryActions.removeAllQueries());

      // figure out if we need to clear cacheStorage?
      // if (window.caches) {
      //   caches.keys().then(function(names) {
      //     for (let name of names) {
      //       console.log(name);
      //        caches.delete(name);
      //     }
      //   });
      //   console.log("Cleared service worker cache.");
      // }

      store.dispatch(commonActions.setFlag("ready"));
    }
  } catch (error) {
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
