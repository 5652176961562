import { useBoolean } from "ahooks";
import Icon from "app/assets/icon/icon";
import { DomainWishlistFragment } from "app/generated/graphql";
import ModalHeader from "app/layout/ModalHeader";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React, { useMemo } from "react";
import { useMutation } from "react-apollo-hooks";
import queryLoader from "app/graphql/queryLoader";
import { useWishlistAddToCart } from "app/state/hooks/wishlistHooks/wishlistHookAddToCart";

export interface FooterProps {
  wishlistName: DomainWishlistFragment["name"];
  onRemoveAll: () => void;
  canRemove: boolean;
  downloadButton: React.ReactNode;
  addAllToCart?: () => any;
  wishlists?: any;
}

const Footer: React.FC<FooterProps> = (props) => {
  const [isOpen, setIsOpen] = useBoolean();
  // @ts-ignore
  const [, addAllToCart] = useWishlistAddToCart();

  const handleRemove = React.useCallback(() => {
    props.onRemoveAll();

    setIsOpen.setFalse();
  }, [props, setIsOpen]);
  const [deleteSavedBuildsFromWishlist] = useMutation(
    queryLoader("deleteSavedBuildsFromWishlist")
  );

  const findSelectedWishlistItemsIds = useMemo(() => {
    const { wishlistName, wishlists } = props;
    const findWishlist = wishlists?.find(
      // @ts-ignore
      (wishlist) => wishlist.name === wishlistName
    );
    return findWishlist?.items?.reduce(
      // @ts-ignore
      (acc, cur) => [...acc, +cur?.id],
      []
    );
  }, [props?.wishlists, props?.wishlistName]);

  return (
    <>
      <MDBRow className="mt-5 pt-5">
        <MDBCol>
          <MDBContainer className="pt-2 mb-5 pb-5 border-top-1">
            <MDBRow>
              <MDBCol className="d-flex favorite-action justify-content-between">
                <div className="d-flex">
                  <div className="grow">{props.downloadButton}</div>
                  <MDBBtn
                    className="position-relative mt-5 d-flex align-items-center btn btn-default"
                    onClick={
                      () => addAllToCart({ name: props.wishlistName })
                      // .then(() =>
                      //   deleteSavedBuildsFromWishlist({
                      //     variables: {
                      //       input: {
                      //         // @ts-ignore
                      //         item_ids: findSelectedWishlistItemsIds,
                      //         wishlist: props.wishlistName,
                      //       },
                      //     },
                      //   })
                      // )
                    }
                  >
                    <div
                      className="position-absolute ml-3"
                      style={{ left: 0, transform: "scale(0.9)" }}
                    >
                      <Icon icon={"requestQuoteWhite"} />
                    </div>
                    <span className="ml-3">Add All to Quote Request</span>
                  </MDBBtn>
                </div>
                <MDBBtn
                  color="secondary"
                  className="position-relative mt-5 d-flex align-items-center"
                  disabled={!props.canRemove}
                  onClick={setIsOpen.setTrue}
                >
                  <div
                    className="position-absolute ml-3"
                    style={{ left: 0, transform: "scale(0.66)" }}
                  >
                    <Icon icon="closeDefault" />
                  </div>
                  Remove All
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
      <MDBModal
        isOpen={isOpen}
        inline={false}
        overflowScroll
        noClickableBodyWithoutBackdrop={false}
        centered
      >
        <MDBModalBody className="p-0">
          <ModalHeader onClose={setIsOpen.setFalse}>Remove All</ModalHeader>
          <MDBRow className="mt-5 mx-5 px-sm-5 mb-5 pb-5">
            <MDBCol>
              {/* @ts-ignore */}
              <MDBTypography className="text-center" variant="body-2">
                {`Are you sure that you want to remove all items from ${props.wishlistName}`}
              </MDBTypography>
              <MDBRow className="mt-5">
                <MDBCol className="d-flex">
                  <MDBBtn
                    onClick={setIsOpen.setFalse}
                    className="mr-4"
                    color="secondary"
                  >
                    Cancel
                  </MDBBtn>
                  <MDBBtn onClick={handleRemove}>Confirm</MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
      </MDBModal>
    </>
  );
};

export default Footer;
