import { Customer, DomainCartFragment } from "app/generated/graphql";
import { createSelector } from "reselect";

export const selectCustomer = (state: {
  customer: {
    data: Customer & {
      token: string;
      loading: boolean;
      cart: DomainCartFragment;
    };
  };
}) => state.customer;

export const selectCustomerData = createSelector(
  selectCustomer,
  ({ data }) => data
);

export const selectCustomerToken = createSelector(
  selectCustomerData,
  ({ token }) => token
);

export const selectFirstname = createSelector(
  selectCustomerData,
  ({ firstname }) => firstname
);

export const selectLastname = createSelector(
  selectCustomerData,
  ({ lastname }) => lastname
);

export const selectEmail = createSelector(
  selectCustomerData,
  ({ email }) => email
);

export const selectIsSubscribed = createSelector(
  selectCustomerData,
  ({ sign_up_for_updates }) => sign_up_for_updates
);

export const selectAddresses = createSelector(
  selectCustomerData,
  ({ addresses }) => addresses
);
export const selectBillingAddress = createSelector(
  selectAddresses,
  (addresses) => addresses?.find((address) => address?.default_billing === true)
);
export const selectShippingAddress = createSelector(
  selectAddresses,
  (addresses) =>
    addresses?.find((address) => address?.default_shipping === true)
);

export const selectIsLoggedIn = createSelector(selectCustomerToken, Boolean);

export const selectCart = createSelector(
  selectCustomerData,
  ({ cart }) => cart
);

export const selectCustomerLoading = createSelector(
  selectCustomerData,
  ({ loading }) => loading
);
