import { hasId, isDefined } from "app/assets/utils/utils";
import {
  Country,
  CountryCodeEnum,
  CustomerAddress,
  Maybe,
  Region,
} from "app/generated/graphql";
import { CountryOption, RegionOption } from "../register/steps/config";
import {
  Address,
  RequiredCountry,
  RequiredCustomerAddress,
  RequiredRegion,
} from "./models";

export const decodePhone = (phone: string) =>
  phone.includes("-")
    ? phone
    : `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`;
export const encodePhone = (phone: string) => phone.split("-").join("");

export const addCountryNameToAddress = (countries: Country[]) => (
  address: CustomerAddress
): Address => ({
  ...address,
  country_name: countries.find((country) => country.id === address.country_code)
    ?.full_name_english,
});

export function isUsOrCan(
  country: Country
): country is Omit<Country, "id"> & {
  id: CountryCodeEnum.Us | CountryCodeEnum.Ca;
} {
  return country.id === CountryCodeEnum.Us || country.id === CountryCodeEnum.Ca;
}

export function isCountryDefined(
  value?: Maybe<Country>
): value is RequiredCountry {
  return isDefined(value) && hasId(value);
}

export function isRegionDefined(
  value?: Maybe<Region>
): value is RequiredRegion {
  return isDefined(value) && hasId(value);
}

export const toCountryOption = (country: RequiredCountry): CountryOption => ({
  value: country.id,
  label: country.full_name_english,
});
export const toRegionOption = (region: RequiredRegion): RegionOption => ({
  value: region.id,
  label: region.name,
});

export function isAddressDefined(
  address?: Maybe<CustomerAddress>
): address is RequiredCustomerAddress {
  return isDefined(address) && hasId(address);
}
