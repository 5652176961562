import React, { memo, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useCustomerCartQuery } from "app/generated/graphql";
import { formatCount } from "app/layout/Badge/utils";
import CartContext from "app/layout/cart";
import { useFavorites } from "app/pages/collections/hooks";
import { selectIsLoggedIn } from "app/state/redux/data/customer/selectors";
import HeaderBottomDisplay from "./headerBottom";

const HeaderBottomStateContainer = memo(
  ({
    menu,
    activeTopMenu,
    setActiveTopMenu,
    newsData,
    getNewsLazyQuery,
    clearUnreadNews,
    activeMenu
  }) => {
    let subItems =
      activeTopMenu !== false ? menu?.children[activeTopMenu]?.children : [];
    let collectionsMenu = menu.children[activeTopMenu]?.name === "Collections";
    let productsMenu = menu.children[activeTopMenu]?.name === "Products";
    let fabricsFinishesMenu =
      menu.children[activeTopMenu]?.name === "Fabrics & Finishes";
    let customOptionsMenu =
      menu.children[activeTopMenu]?.name === "Custom Options";

    const isLoggedIn = useSelector(selectIsLoggedIn);

    const cartContext = useContext(CartContext);

    const favorites = useFavorites();

    const cartQuery = useCustomerCartQuery({
      skip: !isLoggedIn,
      fetchPolicy: "network-only",
    });

    useEffect(() => {
      if (isLoggedIn) getNewsLazyQuery();
    }, []);

    const displayProps = {
      isLoggedIn,
    };
    return (
      <HeaderBottomDisplay
        {...displayProps}
        children={menu?.children}
        subItems={subItems}
        newsInfo={newsData?.getNewsInfo}
        activeTopMenu={activeTopMenu}
        setActiveTopMenu={setActiveTopMenu}
        collectionsMenu={collectionsMenu}
        fabricsFinishesMenu={fabricsFinishesMenu}
        productsMenu={productsMenu}
        customOptionsMenu={customOptionsMenu}
        onCartClick={cartContext.openModal}
        favoritesCount={formatCount(favorites?.items?.length)}
        cartItemsCount={formatCount(cartQuery.data?.customerCart.items?.length)}
        clearUnreadNews={clearUnreadNews}
        activeMenu={activeMenu}
      />
    );
  }
);

export default HeaderBottomStateContainer;
