import {
  CartAddressInput,
  Maybe,
  PlaceOrderInput,
} from "app/generated/graphql";
import {
  emailSchema,
  phoneSchema,
  zipCodeSchema,
} from "app/pages/customer/config";
import { DeepNonNullable } from "react-select/src/components";
import * as yup from "yup";
import { CountryOption, Nullable, RegionOption } from "./models";

const timelines = [
  "Select estimated timeline",
  "I don't know",
  "30 days",
  "90 days",
  "1 year",
  "2 year",
];
export const timelineOptions = timelines.map((value) => ({
  label: value,
  value,
}));
export type TimelineOptions = typeof timelineOptions;
export type TimelineOption = typeof timelineOptions[number];
export type TimelineValue = TimelineOption["value"];

type Street = CartAddressInput["street"][number];

export interface CartValues {
  projectDetails: Pick<
    PlaceOrderInput,
    "project_name" | "timeline" | "additional_details"
  > & {
    project_due_date: PlaceOrderInput["project_due_date"];
  };
  contactInformation: DeepNonNullable<
    Pick<
      CartAddressInput,
      "firstname" | "lastname" | "telephone" | "company" | "order_email"
    >
  >;
  address: Required<Pick<CartAddressInput, "city" | "postcode">> &
    Nullable<Pick<CartAddressInput, "region_id">> & {
      country: Maybe<CountryOption>;
      address1: Street;
      address2?: Street;
    };
}

export const cartValues: CartValues = {
  projectDetails: {
    project_name: "",
    project_due_date: new Date().getTime(),
    timeline: timelineOptions[0].value,
    additional_details: "",
  },
  contactInformation: {
    firstname: "",
    lastname: "",
    company: "",
    telephone: "",
    order_email: "",
  },
  address: {
    city: "",
    country: null,
    region_id: null,
    address1: "",
    address2: "",
    postcode: "",
  },
};

export const regionSchema = yup.object().required().shape<RegionOption>({
  value: yup.number().required(),
  label: yup.string().required(),
});

export const countrySchema = yup
  .object()
  .required("Country is required.")
  .nullable()
  .shape<CountryOption>({
    value: yup.string().required(),
    label: yup.string().required(),
    regions: yup.array().defined().nullable().of(regionSchema),
  });

export const cartSchema: yup.ObjectSchema<CartValues> = yup
  .object()
  .required()
  .shape({
    projectDetails: yup
      .object()
      .required()
      .shape<CartValues["projectDetails"]>({
        project_name: yup.string().required("Project Name is required."),
        project_due_date: yup
          .number()
          .required("Project due date is required.")
          .min(0),
        timeline: yup
          .string()
          .oneOf(timelines.slice(1), "Please select a timeline.")
          .required("Timeline due date is required."),
        additional_details: yup.string(),
      }),
    contactInformation: yup
      .object()
      .required()
      .shape<CartValues["contactInformation"]>({
        company: yup.string().required("Company is required."),
        firstname: yup.string().required("First name is required."),
        lastname: yup.string().required("Last name is required."),
        telephone: phoneSchema,
        order_email: emailSchema,
      }),
    address: yup
      .object()
      .required()
      .shape<CartValues["address"]>({
        city: yup.string().required("City is required."),
        country: countrySchema,
        region_id: yup
          .number()
          .nullable()
          .when("country", {
            is: (country) => !!country?.regions,
            then: yup.number().required("State is required."),
            otherwise: yup.number(),
          }),
        address1: yup.string().required("Address is required."),
        address2: yup.string(),
        postcode: zipCodeSchema,
      }),
  });
