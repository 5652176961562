import React, { useEffect } from "react";
import Alert from "./alert";
import { useMediaQuery } from "react-responsive";

const SessionMessagesItem = (props) => {
  let time;
  let closeTime = 4000;
  let isMobile = useMediaQuery({ maxWidth: 480 });
  if (props.closeTime) closeTime = props.closeTime;

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        props.onClose();
      }, closeTime);
      return;
    }
    if (props.autoClose) {
      time = setTimeout(() => {
        props.onClose();
      }, closeTime);
      props.settimeId(time);
    } else {
      const clearId = clearInterval(time);
      props.settimeId(clearId);
    }
    return () => {
      clearInterval(time);
    };
  }, [props.autoClose]);
  let message = "";
  if (props.text) {
    message = props.text.replace("Error: GraphQL error: ", "");
  }

  return (
    <>
      {/* <ScrollToTopOnMount /> */}
      <Alert
        onMouseOver={props.onMouseOver}
        onMouseLeave={props.onMouseLeave}
        variant={props.type}
        closeLabel="Remove message"
        dismissible={props.dismissible}
        onClose={props.onClose}
        successIcon={props.successIcon}
        errorIcon={props.errorIcon}
      >
        {message}
        {props.options}
      </Alert>
    </>
  );
};

export default SessionMessagesItem;
