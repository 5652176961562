// Customer Action Creators

import types from "./types";

const loginCustomer = (email, password, cartToken, client) => {
  return {
    type: types.LOGIN_CUSTOMER,
    email: email,
    password: password,
    cartToken: cartToken,
    client: client,
  };
};

const logoutCustomer = (hideMessage = false, logoutMessage = null) => {
  return {
    type: types.LOGOUT_CUSTOMER,
    hideMessage: hideMessage,
    logoutMessage: logoutMessage,
  };
};

const registerB2CCustomer = (request) => {
  return {
    type: types.REGISTER_B2C_CUSTOMER,
    request: request,
  };
};

const registerB2BCustomer = (request, address) => {
  return {
    type: types.REGISTER_B2B_CUSTOMER,
    request: request,
    address: address,
  };
};

const registerCustomerSuccess = (status) => {
  return {
    type: types.REGISTER_CUSTOMER_SUCCESS,
    status: status,
  };
};

const getCustomerInformation = (
  token,
  isLoggedIn = false,
  email = "",
  cartToken
) => {
  return {
    type: types.GET_CUSTOMER_INFORMATION,
    token: token,
    isLoggedIn: isLoggedIn,
    email: email,
    cartToken: cartToken,
  };
};

const setCustomerInformation = (customer, isLoggedIn = false) => {
  return {
    type: types.SET_CUSTOMER_INFORMATION,
    customer: customer,
    isLoggedIn: isLoggedIn,
  };
};

const _setCustomerToken = (token, cartToken) => {
  return {
    type: types._SET_CUSTOMER_TOKEN,
    token: token,
    cartToken: cartToken,
  };
};

const updateCustomerInformation = (request) => {
  return {
    type: types.UPDATE_CUSTOMER_INFORMATION,
    request: request,
  };
};

const _reduceCustomer = (customer_data) => {
  return {
    type: types._REDUCE_CUSTOMER,
    customer: customer_data,
  };
};

const createEmptyCart = (oldCartToken) => {
  return {
    type: types.CREATE_EMPTY_CART,
    oldCartToken: oldCartToken,
  };
};

const setCartToken = (cartToken, oldCartToken) => {
  return {
    type: types.SET_CART_TOKEN,
    token: cartToken,
    oldCartToken: oldCartToken,
  };
};

const getCartInformation = (token, oldCartToken) => {
  return {
    type: types.GET_CART_INFORMATION,
    token: token,
    oldCartToken: oldCartToken,
  };
};

const setCartInformation = (cart) => {
  return {
    type: types.SET_CART_INFORMATION,
    cart: cart,
  };
};

const addProductsToCart = (items) => {
  return {
    type: types.ADD_PRODUCT_TO_CART,
    items: items,
  };
};

const removeProductFromCart = (id) => {
  return {
    type: types.REMOVE_PRODUCT_FROM_CART,
    item_id: id,
  };
};

const updateCart = (items) => {
  return {
    items: items,
    type: types.UPDATE_CART,
  };
};

const clearCart = () => {
  return {
    type: types.CLEAR_CART,
  };
};

const newsletterCustomerSubscribe = (
  email,
  first_name,
  last_name,
  company,
  what_describes_you,
  country,
  state,
  zip
) => {
  return {
    type: types.NEWSLETTER_CUSTOMER_SUBSCRIBE,
    email: email,
    first_name: first_name,
    last_name: last_name,
    what_describes_you: what_describes_you,
    company: company,
    state: state,
    country: country,
    zip: zip,
  };
};

const createWishlist = (name) => {
  return {
    type: types.CREATE_WISHLIST,
    name: name,
  };
};
const shareWishlist = (emails, message, wishlist_id, multiwishlist_id) => {
  return {
    type: types.SHARE_WISHLIST,
    emails: emails,
    message: message,
    wishlist_id: wishlist_id,
    multiwishlist_id: multiwishlist_id,
  };
};
const deleteWishlist = (id) => {
  return {
    type: types.DELETE_WISHLIST,
    id: id,
  };
};

const renameWishlist = (id, name) => {
  return {
    type: types.RENAME_WISHLIST,
    id: id,
    name: name,
  };
};

const addProductToWishlist = (wishlist_id, product_id, options) => {
  return {
    type: types.ADD_PRODUCT_TO_WISHLIST,
    wishlist_id: wishlist_id,
    product_id: product_id,
    options: options,
  };
};

const removeItemFromWishlist = (wishlist_item_id) => {
  return {
    type: types.REMOVE_ITEM_FROM_WISHLIST,
    wishlist_item_id: wishlist_item_id,
  };
};

const _reduceWishlist = (wishlist_object) => {
  return {
    type: types._REDUCE_WISHLIST,
    wishlist: wishlist_object,
  };
};

const copyWishlistItem = (wishlist_item_id, wishlist_id) => {
  return {
    type: types.COPY_WISHLIST_ITEM,
    wishlist_item_id: wishlist_item_id,
    wishlist_id: wishlist_id,
  };
};

const setGuestEmailOnCart = (email) => {
  return {
    type: types.SET_GUEST_EMAIL_ON_CART,
    email: email,
  };
};

const setBillingAddressOnCart = (billing_address) => {
  return {
    type: types.SET_BILLING_ADDRESS_ON_CART,
    billing_address: billing_address,
  };
};

const setShippingAddressOnCart = (shipping_address) => {
  return {
    type: types.SET_SHIPPING_ADDRESSES_ON_CART,
    shipping_address: shipping_address,
  };
};

const setShippingMethodsOnCart = (shipping_methods) => {
  return {
    type: types.SET_SHIPPING_METHODS_ON_CART,
    shipping_methods: shipping_methods,
  };
};

const editCustomerAddress = (request) => {
  return {
    type: types.EDIT_CUSTOMER_ADDRESS,
    request: request,
  };
};

const _reduceCustomerAddress = (data) => {
  return {
    type: types._REDUCE_CUSTOMER_ADDRESS,
    data: data,
  };
};

const createCustomerAddress = (request) => {
  return {
    type: types.CREATE_CUSTOMER_ADDRESS,
    request,
  };
};

const forgotCustomerPassword = (email) => {
  return {
    type: types.FORGOT_CUSTOMER_PASSWORD,
    email: email,
  };
};

const resetCustomerPassword = (token, password, confirmPassword) => {
  return {
    type: types.RESET_CUSTOMER_PASSWORD,
    token: token,
    password: password,
    confirmPassword: confirmPassword,
  };
};

const resetCustomerPasswordSuccess = (status) => {
  return {
    type: types.RESET_CUSTOMER_PASSWORD_SUCCESS,
    status: status,
  };
};

const placeOrder = () => {
  return {
    type: types.PLACE_ORDER,
  };
};

const setPaymentMethodOnCart = (payment_method) => {
  return {
    type: types.SET_PAYMENT_METHOD_ON_CART,
    payment_method: payment_method,
  };
};

const placeOrderSuccess = (order_id) => {
  return {
    type: types.PLACE_ORDER_SUCCESS,
    order_id: order_id,
  };
};

const applyCouponToCart = (coupon) => {
  return {
    type: types.APPLY_COUPON_TO_CART,
    coupon: coupon,
  };
};

const removeCouponFromCart = () => {
  return {
    type: types.REMOVE_COUPON_FROM_CART,
  };
};

export default {
  loginCustomer,
  logoutCustomer,
  registerB2CCustomer,
  registerB2BCustomer,
  registerCustomerSuccess,
  getCustomerInformation,
  updateCustomerInformation,
  setCustomerInformation,
  _setCustomerToken,
  _reduceCustomer,
  createEmptyCart,
  setCartToken,
  setCartInformation,
  getCartInformation,
  addProductsToCart,
  updateCart,
  clearCart,
  removeProductFromCart,
  newsletterCustomerSubscribe,
  createWishlist,
  deleteWishlist,
  renameWishlist,
  addProductToWishlist,
  removeItemFromWishlist,
  _reduceWishlist,
  copyWishlistItem,
  setGuestEmailOnCart,
  setBillingAddressOnCart,
  setShippingAddressOnCart,
  setShippingMethodsOnCart,
  shareWishlist,
  editCustomerAddress,
  _reduceCustomerAddress,
  createCustomerAddress,
  forgotCustomerPassword,
  resetCustomerPassword,
  resetCustomerPasswordSuccess,
  placeOrder,
  setPaymentMethodOnCart,
  placeOrderSuccess,
  applyCouponToCart,
  removeCouponFromCart,
};
