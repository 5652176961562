import AppBreadcrumbs from "app/layout/breadcrumbs";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";

export interface BreadcrumbsProps { }

const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  return (
    <MDBContainer className="mb-4 mt-5 pt-4">
      <MDBRow>
        <MDBCol>
          <AppBreadcrumbs />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Breadcrumbs;
