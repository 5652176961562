import React, { useEffect } from "react";

function useTagboxHelper() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widget.taggbox.com/embed-lite.min.js";
    script.type = "text/javascript";
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);
  const tag = (
    <div
      className="taggbox"
      style={{ width: "100%", height: "100%" }}
      data-widget-id="161015"
      data-tags="false"
    ></div>
  );
  return {
    tag,
  };
}

export default useTagboxHelper;
