import { MDBContainer } from "mdbreact";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
const SkeletonFooter = (props) => {
  let isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <>
      {!isMobile ? (
        <MDBContainer fluid className="p-0">
          <Skeleton width={`100%`} height={`70rem`} />
        </MDBContainer>
      ) : (
        <MDBContainer fluid className="p-0">
          <Skeleton width={`100%`} height={`57rem`} />
        </MDBContainer>
      )}
    </>
  );
};

export default SkeletonFooter;
