import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";

const InfiniteScrollSkeleton = () => {
  const isMobile = useMediaQuery({ maxWidth: "600px" });
  return (
    <div className="fabric-sidebar-skeleton">
      <MDBContainer fluid className="px-0 pt-5 pr-3 my-1 pb-4 items-skeleton">
        <MDBCol>
          <MDBRow>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} maxWidth={`5.6rem`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} maxWidth={`5.6rem`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} maxWidth={`5.6rem`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} maxWidth={`5.6rem`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} maxWidth={`5.6rem`} />
            </MDBCol>
            {!isMobile && (
              <>
                <MDBCol>
                  <Skeleton
                    height={`5.6rem`}
                    width={`100%`}
                    maxWidth={`5.6rem`}
                  />
                </MDBCol>
                <MDBCol>
                  <Skeleton
                    height={`5.6rem`}
                    width={`100%`}
                    maxWidth={`5.6rem`}
                  />
                </MDBCol>
              </>
            )}
          </MDBRow>
        </MDBCol>
      </MDBContainer>
    </div>
  );
};
export default InfiniteScrollSkeleton;
