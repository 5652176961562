import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
const GET_CATEGORY_PRODUCTS = gql`
  query customGetCategory($id: String!, $pageSize: Int!) {
    products(
      filter: { category_id: { eq: $id }, is_featured: { eq: "1" } }
      pageSize: $pageSize
    ) {
      items {
        id
        ffi_lanect
        ffi_laners
        ffi_lanesl
        ffi_isvisible
        is_new
        url_key
        sku
        name
        description {
          html
        }
        small_image {
          url(width: 265, height: 265)
          label
        }
        image {
          url(width: 700, height: 700)
          label
        }
        __typename
      }
    }
  }
`;
function useGetCategoryProducts(id = 0, pageSize = 20) {
  let { data, loading, error } = useQuery(GET_CATEGORY_PRODUCTS, {
    variables: {
      id,
      pageSize,
    },
  });
  if (loading) {
    return {};
  }
  if (error) {
    console.error(error);
    return {};
  }
  return data;
}

export { useGetCategoryProducts };
