// @ts-check
import {
  contactNameSchema,
  emailSchema,
  phoneSchema,
  zipCodeSchema,
  isEmptyRegex,
} from "app/pages/customer/config";
import * as yup from "yup";

/**
 * @type {yup.ObjectSchema<import('../config').RegionOption>}
 */
export const regionSchema = yup.object().required().shape({
  value: yup.number().required(),
  label: yup.string().required(),
});

export const countrySchema = yup
  .object()
  .required("Country is required.")
  .nullable()
  .shape({
    value: yup.string().required(),
    label: yup.string().required(),
    regions: yup.array().defined().nullable().of(regionSchema),
  });

/**
 *
 * @typedef {object} MailingShippingValues
 * @prop {string} contactName
 * @prop {string} lastName
 * @prop {string} firstName
 * @prop {import('../config').CountryOptionWithRegions | null} country
 * @prop {string} address1
 * @prop {string} [address2]
 * @prop {string} city
 * @prop {number | null} region
 * @prop {string} zipCode
 * @prop {string} email
 * @prop {string} phone
 */

/**
 * @type {yup.ObjectSchema<MailingShippingValues | undefined | null>}
 */
export const validationSchema = yup
  .object()
  .nullable()
  .shape({
    contactName: contactNameSchema,
    firstName: yup
      .string()
      .required("First Name is required.")
      .matches(isEmptyRegex, "First Name is required."),
    lastName: yup
      .string()
      .required("Last Name is required.")
      .matches(isEmptyRegex, "Last Name is required."),
    country: countrySchema,
    address1: yup
      .string()
      .required("Address is required.")
      .matches(isEmptyRegex, "Address is required."),
    address2: yup.string(),
    city: yup
      .string()
      .required("City is required")
      .matches(isEmptyRegex, "City is required."),
    region: yup
      .number()
      .defined()
      .nullable()
      .when("country", {
        is: (country) => !!country?.regions,
        then: yup.number().required("Region is required"),
        otherwise: yup.number(),
      }),
    zipCode: zipCodeSchema,
    email: emailSchema,
    phone: phoneSchema,
  });

/**
 * @type {MailingShippingValues}
 */
export const initialValues = {
  contactName: "",
  firstName: "",
  lastName: "",
  country: null,
  address1: "",
  address2: "",
  city: "",
  region: null,
  zipCode: "",
  email: "",
  phone: "",
};

/**
 * @typedef {'mailing' | 'shipping'} MailingAndShippingFormKey
 */

/**
 * @typedef {{ mailing: MailingShippingValues; shipping: MailingShippingValues | null; sameAsMailing: boolean; updates: boolean; }} MailingAndShippingValues
 */

/**
 * @type {yup.ObjectSchema<MailingAndShippingValues>}
 */
// @ts-ignore
export const mailingAndShippingValidationSchema = yup
  .object()
  .required()
  .shape({
    mailing: validationSchema.required(),
    shipping: validationSchema.when("sameAsMailing", {
      is: false,
      then: yup.object().required(),
    }),
    sameAsMailing: yup.boolean().required(),
    updates: yup.boolean().required(),
    // termsAndConditions: yup
    //   .mixed()
    //   .required()
    //   .oneOf(
    //     [true],
    //     "Before proceeding, please accept the terms and conditions."
    //   ),
  });

/**
 * @type {MailingAndShippingValues}
 */
export const mailingAndShippingInitialValues = {
  mailing: initialValues,
  shipping: null,
  sameAsMailing: true,
  updates: true,
  // termsAndConditions: true,
};
