import { Maybe } from "app/generated/graphql";
import Label from "app/layout/Label";
import MaskedInput from "app/layout/MaskedInput";
import Switch from "app/layout/Switch";
import Checkbox from "app/layout/Checkbox";
import { phoneMask, usAndCanZipCodeMask } from "app/pages/customer/config";
import { RegionOption } from "app/pages/customer/register/steps/config";
import Select from "core/components/select";
import { FormikProps } from "formik";
import { MDBAlert, MDBBox, MDBCol, MDBInput, MDBRow } from "mdbreact";
import React from "react";
import SaveSection from "../../../components/SaveSection";
import {
  CountryOption,
  RequiredCountry,
  RequiredRegion,
} from "../../../models";
import {
  isRegionDefined,
  isUsOrCan,
  toCountryOption,
  toRegionOption,
} from "../../../utils";
import { AddressCategory, AddressValues } from "./config";

export interface FormProps {
  countries: RequiredCountry[];
  form: FormikProps<AddressValues>;
  alert: Pick<
    React.ComponentProps<typeof MDBAlert>,
    "color" | "children"
  > | null;
}

const Form: React.FC<FormProps> = (props) => {
  const countryOptions: CountryOption[] = React.useMemo(
    () => props.countries.map(toCountryOption),
    [props.countries]
  );
  const countryOption: Maybe<CountryOption> = React.useMemo(
    () =>
      props.form.values.country
        ? toCountryOption(props.form.values.country)
        : null,
    [props.form.values.country]
  );

  const regions: RequiredRegion[] = React.useMemo(
    () =>
      (props.form.values.country?.available_regions ?? []).filter(
        isRegionDefined
      ),
    [props.form.values.country]
  );
  const regionOptions: RegionOption[] = React.useMemo(
    () => regions.map(toRegionOption),
    [regions]
  );
  const regionOption: Maybe<RegionOption> = React.useMemo(
    () =>
      regionOptions.find(
        ({ value }) => value === props.form.values.region_id
      ) ?? null,
    [regionOptions, props.form.values.region_id]
  );

  return (
    <MDBRow className="account-form-wrapper">
      <MDBCol>
        <MDBRow className="mb-5 pb-5">
          <MDBCol size="12" lg="6">
            <div className={`pb-5`}>
              <div className="mt-5 position-relative">
                <MDBInput
                  size="lg"
                  label="First Name"
                  hint="ex: John"
                  required
                  {...props.form.getFieldProps("firstname")}
                />
                <div className="position-absolute text-red">
                  {props.form.touched.firstname && props.form.errors.firstname}
                </div>
              </div>
            </div>
            <div className={`pb-5`}>
              <div className="mt-5 position-relative">
                <MDBInput
                  size="lg"
                  label="Last Name"
                  hint="ex: Smith"
                  required
                  {...props.form.getFieldProps("lastname")}
                />
                <div className="position-absolute text-red">
                  {props.form.touched.lastname && props.form.errors.lastname}
                </div>
              </div>
            </div>
            <div className={`pb-5`}>
              <div className="mt-5 position-relative">
                <MDBInput
                  size="lg"
                  label="Email"
                  hint="ex: email@email"
                  required
                  {...props.form.getFieldProps("email")}
                />
                <div className="position-absolute text-red">
                  {props.form.touched.email && props.form.errors.email}
                </div>
              </div>
            </div>
            <div className={`pb-md-5`}>
              <div className="position-relative">
                <MaskedInput
                  {...props.form.getFieldProps("telephone")}
                  label="Phone"
                  placeholder="xxx-xxx-xxxx"
                  required
                  mask={phoneMask}
                  className="lg"
                />
                <div className="mt-4 position-absolute text-red">
                  {props.form.touched.telephone && props.form.errors.telephone}
                </div>
              </div>
            </div>
            <div className={`mt-5`}>
              <div className="position-relative">
                <MDBInput
                  size="lg"
                  required
                  label="Address Title"
                  {...props.form.getFieldProps("address_label")}
                />
                <div className="mt-4 position-absolute text-red">
                  {props.form.touched.address_label &&
                    props.form.errors.address_label}
                </div>
              </div>
            </div>
          </MDBCol>
          <MDBCol size="12" lg="6" className="mt-5 mt-md-0">
            <Label className="position-relative" text="Country" required>
              <Select
                placeholder="Select Country"
                options={countryOptions}
                value={countryOption}
                onChange={(option: CountryOption) => {
                  const country = props.countries.find(
                    (country) => country.id === option.value
                  );
                  if (country) {
                    props.form.setFieldValue("country", country);
                    props.form.setFieldValue("region", null);
                  } else {
                    console.error(`Country with id ${option.value} not found.`);
                  }
                }}
                onBlur={() => {
                  props.form.setFieldTouched("country");
                }}
                className="lg"
              />
              <div className="mt-4 position-absolute text-red">
                {props.form.touched.country && props.form.errors.country}
              </div>
            </Label>
            <div className="mt-5 pt-4 position-relative">
              <MDBInput
                size="lg"
                label="Address 1"
                hint="123 Street Name"
                required
                {...props.form.getFieldProps("address1")}
              />
              <div className="position-absolute text-red">
                {props.form.touched.address1 && props.form.errors.address1}
              </div>
            </div>
            <div className="mt-5 pt-4 position-relative">
              <MDBInput
                size="lg"
                label="Address 2"
                hint="apartment or suite no."
                {...props.form.getFieldProps("address2")}
              />
              <div className="position-absolute text-red">
                {props.form.touched.address2 && props.form.errors.address2}
              </div>
            </div>
            <div className="mt-5 pt-4 position-relative">
              <MDBInput
                size="lg"
                label="City"
                hint="city name"
                required
                {...props.form.getFieldProps("city")}
              />
              <div className="position-absolute text-red">
                {props.form.touched.city && props.form.errors.city}
              </div>
            </div>
            {regionOptions.length !== 0 && (
              <Label
                text="State"
                required={!!props.form.values.country?.available_regions}
                className="mt-5 position-relative"
              >
                <Select
                  placeholder="Select State"
                  options={regionOptions}
                  value={regionOption}
                  onChange={(option: RegionOption) => {
                    props.form.setFieldValue("region_id", option.value);
                  }}
                  onBlur={() => {
                    props.form.setFieldTouched("region_id");
                  }}
                  disabled={!regionOptions.length}
                  className="lg"
                />
                <div className="mt-4 position-absolute text-red">
                  {props.form.touched.region_id && props.form.errors.region_id}
                </div>
              </Label>
            )}
            <div className="mt-3 pt-4 position-relative address-zip-field">
              <MaskedInput
                // size="lg"
                label="Zip code/Postal code"
                placeholder="zip or postal code"
                required
                mask={
                  props.form?.values?.country &&
                  isUsOrCan(props.form.values.country)
                    ? // @ts-ignore
                      usAndCanZipCodeMask(props.form.values.country.id)
                    : false
                }
                {...props.form.getFieldProps("postcode")}
              />

              <div className="position-absolute text-red">
                {props.form.touched.postcode && props.form.errors.postcode}
              </div>
            </div>
            <div className="mt-5">
              <Checkbox
                label="Use as my default Billing Address"
                checked={props.form.values.default_billing}
                onChange={(e) => {
                  props.form.setFieldValue("default_billing", e.target.checked);
                }}
              />
              {/* <Switch
                label="Use as my Default Mailing Address"
                checked={
                  props.form.values.addressCategory === AddressCategory.mailing
                }
                onChange={(e) => {
                  props.form.setFieldValue(
                    "addressCategory",
                    e.target.checked
                      ? AddressCategory.mailing
                      : AddressCategory.new
                  );
                }}
              /> */}
            </div>
            <div className="mt-5 position-relative">
              <Checkbox
                label="Use as my default Shipping Address"
                checked={props.form.values.default_shipping}
                onChange={(e) => {
                  props.form.setFieldValue(
                    "default_shipping",
                    e.target.checked
                  );
                }}
              />
              {/* <Switch
                label="Use as my default Shipping Address"
                checked={
                  props.form.values.addressCategory === AddressCategory.shipping
                }
                onChange={(e) => {
                  props.form.setFieldValue(
                    "addressCategory",
                    e.target.checked
                      ? AddressCategory.shipping
                      : AddressCategory.new
                  );
                }}
              /> */}
              <div className="position-absolute text-red">
                {props.form.touched.addressCategory &&
                  props.form.errors.addressCategory}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        {props.alert && (
          <MDBBox mb={5}>
            <MDBAlert color={props.alert.color} dismiss>
              <div style={{ flexGrow: 1 }}>{props.alert.children}</div>
            </MDBAlert>
          </MDBBox>
        )}
        <SaveSection
          onClick={props.form.submitForm}
          disabled={props.form.isSubmitting}
        />
      </MDBCol>
    </MDBRow>
  );
};

export default Form;
