import React, { useMemo, useCallback } from "react";
import { MDBInput, MDBBtn, MDBTypography } from "mdbreact";
import DatePicker from "react-date-picker";
import { useBoolean } from "ahooks";
import Icon from "app/assets/icon/icon";
import Label from "app/layout/Label";
import { useQuery } from "@apollo/react-hooks";
import { GET_CUSTOMER_ORDER_STATUSES } from '../query';
import Select from "app/layout/Select";

function FilterBy({ form, formatDate, resetFilters }) {
  const [isCalendarFromOpen, setCalendarFromOpen] = useBoolean();
  const [isCalendarToOpen, setCalendarToOpen] = useBoolean();
  const from = useMemo(() => new Date(form.values.from), [form]);
  const to = useMemo(() => new Date(form.values.to), [form]);

  const toMinDate = useMemo(() => {
    const nextDay = from.setDate(from.getDate());
    return new Date(nextDay);
  }, [from]);

  const fromMaxDate = useMemo(() => {
    const dayBefore = to.setDate(to.getDate());
    return new Date(dayBefore);
  }, [to]);

  const { data: orderStatuses } = useQuery(GET_CUSTOMER_ORDER_STATUSES);
  const customOrderStatuses = useMemo(() => {
    return orderStatuses?.getCustomOrderStatuses;
  }, [orderStatuses])
  return (
    <div className="filter-by mb-5">
      <MDBTypography tag="h3" className="mb-5">
        Filter by
      </MDBTypography>
      <form onSubmit={form.handleSubmit}>
        <div className="d-flex justify-content-between">
          <div>
            <div className="position-relative">
              <MDBInput
                size="lg"
                value={formatDate(from)}
                label="From"
                maxDate={to}
                hint="Select Start Date"
                required
              />
              <div
                className="cursor-pointer position-absolute calendar-icon"
                style={{ top: 10, right: 15 }}
              >
                <Icon
                  icon="calendar"
                  onClick={() => setCalendarFromOpen.toggle()}
                />
              </div>
              <DatePicker
                isOpen={isCalendarFromOpen}
                value={from}
                maxDate={fromMaxDate}
                onChange={useCallback(
                  (dates) => {
                    form.setFieldValue(
                      "from",
                      dates ? dates.getTime() : dates[0]?.getTime()
                    );
                  },
                  [form]
                )}
              />
            </div>
          </div>
          <div>
            <div className="position-relative">
              <MDBInput
                size="lg"
                value={formatDate(to)}
                label="To"
                hint="Select End Date"
                required
              />
              <div
                className="cursor-pointer position-absolute calendar-icon"
                style={{ top: 10, right: 15 }}
              >
                <Icon
                  icon="calendar"
                  onClick={() => setCalendarToOpen.toggle()}
                />
              </div>
              <DatePicker
                isOpen={isCalendarToOpen}
                value={to}
                minDate={toMinDate}
                onChange={useCallback(
                  (dates) => {
                    form.setFieldValue(
                      "to",
                      dates ? dates.getTime() : dates[0]?.getTime()
                    );
                  },
                  [form]
                )}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <Label className="position-relative" text="Quote Request #">
            <MDBInput
              size="lg"
              hint="Enter Invoice #"
              type="text"
              {...form.getFieldProps("quoteRequest")}
            />
          </Label>
          <Label className="position-relative" text="Status">
            <Select
                  placeholder="Select Status"
                  value={form.values?.status}
                  options={customOrderStatuses}
                  isSearchable={false}
                  // @ts-ignore
                  onChange={(option) => {
                    // @ts-ignore
                    form.setFieldValue(`status`, option);
                  }}
                  onBlur={() => {
                    form.setFieldTouched("status");
                  }}
                  className="md-form form-lg"
                />
          </Label>
        </div>
        <div className="d-flex justify-content-between">
          <MDBBtn type="submit" className="mt-5 m-0">
            Apply
          </MDBBtn>
          <MDBBtn type="button" className="mt-5 m-0" onClick={resetFilters}>
            Reset filters
          </MDBBtn>
        </div>
      </form>
    </div>
  );
}

export default FilterBy;
