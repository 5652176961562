import React, { useEffect } from "react";
import useCustomer from "app/state/hooks/customer/useCustomer";
import Select from "../../../../../../layout/Select";
import { pure } from "recompose";

const Dropdown = ({ option, optionDispatch, optionSku }) => {
  const customer = useCustomer().isLoggedIn();

  const possibleOptions = option.value
    .filter((optionValue) => !optionValue?.title?.includes("DISCONTINUED"))
    .map((optionValue) => ({
      label: `${optionValue.title}  ${
        customer
          ? !optionValue.price
            ? "(standard)"
            : ""
          : //FIXME: When FF wants
            // : "+ $" + optionValue.price.toFixed(2)
            ""
      }`,
      value: optionValue.option_type_id,
    }));

  return (
    <>
      <div className={"selector-container"}>
        <h2 className={"body-2 selector-title"}>{option.title}</h2>
        <Select
          className={"dropdown-select"}
          placeholder={`${`Please Select ${option.title}`}`}
          wrapperClass={"w-100"}
          isSearchable={false}
          options={possibleOptions}
          value={possibleOptions.find((option) => option.value === optionSku)}
          onChange={(e) => {
            optionDispatch({
              type: "setAttribute",
              attribute: option.title.toLowerCase().split(" ").join("_"),
              payload: e.value,
            });
          }}
        />
      </div>
    </>
  );
};

export default pure(Dropdown);
