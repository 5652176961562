// TODO add back // @ts-heck

import Icon from "app/assets/icon/icon";
import Logo from "app/layout/logo";
import _ from "lodash";
import { MDBBox, MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React from "react";

export const RepListing = ({ tempReps = [], isResult }) => {
  if (_.isEmpty(tempReps) || !isResult) {
    return (
      <MDBRow className="pl-4 pr-4 find-rep-initial mb-4">
        <MDBCol className="bg-gray-lighten-4">
          <MDBRow className="mb-5">
            <MDBCol className="d-flex align-items-center justify-content-center logo-wrapper">
              <Logo type={"desktop"} />
            </MDBCol>
          </MDBRow>
          <MDBRow className="mb-5">
            <MDBCol className="d-flex align-items-center justify-content-center">
              <MDBTypography
                tag="h2"
                variant="display-2"
                className="text-center"
              >
                {!isResult && "Complete the Requirements Above"}
                {isResult &&
                  "A sales representative is not available in your region"}
              </MDBTypography>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol className="d-flex align-items-center justify-content-center">
              <MDBTypography tag="h2" className="text-center">
                {!isResult &&
                  "Please enter a Zipcode and Industry to locate your sales representative."}
                {isResult &&
                  "Please contact customer service at 828.758.5571 or email us at inquiries@fairfieldfurniture.com"}
              </MDBTypography>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    );
  } else {
    return (
      <MDBRow>
        {tempReps.map((rep, index) => {
          return (
            <MDBCol key={rep.name} size="12" lg="4" className="mb-5 h-100">
              <MDBBox
                tag="div"
                className={"border-default-1 p-5"}
                // @ts-ignore
                style={{ minHeight: "34rem" }}
              >
                <MDBRow className="mb-4">
                  <MDBCol className="d-flex align-items-center justify-content-center">
                    <MDBTypography tag="h3" className="fs-24 text-center">
                      {rep.name}
                    </MDBTypography>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol className="d-flex align-items-center justify-content-center">
                    {Object.keys(rep.lanes).map((lane) => {
                      if (rep.lanes[lane]) {
                        // @ts-ignore
                        return <Icon className="mx-2" icon={lane} />;
                      }
                      return null;
                    })}
                  </MDBCol>
                </MDBRow>
                <MDBRow className="my-5">
                  <MDBCol>
                    <MDBBox className="dark separator" />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol className="px-5 d-flex-column align-items-center justify-content-center">
                    {/* Address and mobile removed from array 
                    (if you want to show them only add them in array) 
                    - because of Sales reps security or privacy issues */}
                    {["phone", "fax", "email"].map((item) => {
                      if (rep[item]) {
                        return (
                          <MDBRow className="mb-3">
                            <MDBCol
                              size="2"
                              className="d-flex offset-1 align-items-center justify-content-around"
                            >
                              <Icon icon={item} />
                            </MDBCol>
                            <MDBCol size="8">
                              <MDBTypography tag="h2" className="body-5 fs-16">
                                {item === "email" && (
                                  <a
                                    href={"mailto:" + rep[item]}
                                    className="link-3 fs-16 rep-email"
                                  >
                                    {rep[item].toLowerCase()}
                                  </a>
                                )}
                                {item !== "email" &&
                                  rep[item].replace(/<br>/g, "\n")}
                              </MDBTypography>
                            </MDBCol>
                          </MDBRow>
                        );
                      }
                      return null;
                    })}
                    {/*Get Directions also commented - Client request*/}
                    {/* <MDBRow className="mb-3">
                      <MDBCol
                        size="2"
                        className="d-flex offset-1 align-items-center justify-content-around"
                      >
                        <Icon icon="location" className={`location-icon`} />
                      </MDBCol>
                      <MDBCol size="8">
                        <MDBTypography tag="h2" className="body-5 fs-16">
                          <a
                            href={
                              "https://www.google.com/maps?saddr=Current+Location&daddr=" +
                              rep.address.replace(/<[^>]+>/g, "")
                            }
                            target={"_blank"}
                            rel="noopener noreferrer"
                            className="fs-16"
                          >
                            Get Direction
                          </a>
                        </MDBTypography>
                      </MDBCol>
                    </MDBRow> */}
                  </MDBCol>
                </MDBRow>
              </MDBBox>
            </MDBCol>
          );
        })}
      </MDBRow>
    );
  }
};
