import React from "react";
import Icon from "app/assets/icon/icon";
import { Collapse } from "app/layout/Collapse";
import ProductWidget from "app/layout/productWidget";
import { getBadges, getIsFavorite } from "app/pages/collections/utils";
import _ from "lodash";
import { MDBBox, MDBCol, MDBRow, MDBTypography } from "mdbreact";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";

const DisplayInfo = ({
  name,
  stockQty = "xxx",
  pricingPerYard = "x",
  showLanes,
  laneData,
  designer,
  description = "Lorem Ipsum Dolor Sit amet",
  relatedProducts = [],
  sliderProps = {},
  details,
  specifications,
  dimensions,
  favorites,
  toggleFavorite,
  onToggleFavorite,
  showRelatedProducts,
  fabricPDP,
  customizationProps,
  withMaxWidth,
  collection,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <div
      className="info-container-wrapper"
      style={{ maxWidth: !isMobile && withMaxWidth ? "44.4rem" : "100%" }}
    >
      {customizationProps && (
        <MDBRow className={`mb-5`}>
          {collection?.[0] !== "false" && (
            <MDBCol>
              <MDBTypography tag="h2">{collection?.join(", ")}</MDBTypography>
            </MDBCol>
          )}
          <MDBCol
            className={`d-flex align-items-end flex-column flex-sm-row available-in-industry ${
              designer !== null || collection?.[0] !== "false"
                ? "justify-content-end"
                : ""
            }`.trim()}
          >
            {showLanes && (
              <>
                <MDBTypography
                  tag="span"
                  className="fs-14 fwregular text-gray-darken-3"
                >
                  Available in:
                </MDBTypography>
                <MDBBox className={`d-flex`}>
                  {Object.keys(laneData).map((lane) => {
                    if (laneData[lane]) {
                      return <Icon className="mx-2" icon={lane} />;
                    }
                    return null;
                  })}
                </MDBBox>
              </>
            )}
          </MDBCol>
        </MDBRow>
      )}
      {fabricPDP && customizationProps && (
        <MDBRow className="fabric-info mb-4">
          <MDBCol lg="10" size="12">
            <MDBRow className="mb-4">
              <MDBCol size="12">
                <MDBTypography tag="h1">{name}</MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-3 ">
              <MDBCol className="attribute-name">
                <MDBTypography tag="h4" className="fwmedium">
                  Stock
                </MDBTypography>
              </MDBCol>
              <MDBCol className="attribute-value">
                <MDBTypography tag="h4" className="text-primary fwlight">
                  {stockQty} yards
                </MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-3 ">
              <MDBCol className="attribute-name">
                <MDBTypography tag="h4" className="fwmedium">
                  Pricing per yard
                </MDBTypography>
              </MDBCol>
              <MDBCol className="attribute-value">
                <MDBTypography tag="h4" className="text-primary fwlight">
                  ${pricingPerYard}
                </MDBTypography>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      )}
      <MDBRow>
        <MDBCol size="12">
          {description && !fabricPDP && customizationProps && (
            <Collapse isOpen={true} className="my-2" title="Description">
              <MDBTypography tag="h2" className="body-1 text-primary">
                {description}
              </MDBTypography>
            </Collapse>
          )}
          {details?.length !== 0 && customizationProps && (
            <Collapse
              isOpen={description?.length !== 0 ? false : true}
              className="my-2"
              title="Details"
            >
              {details.map((attr, i) => {
                return <AttributeDisplay key={i} attr={attr} />;
              })}
            </Collapse>
          )}
          {details?.length !== 0 && customizationProps && fabricPDP && (
            <Collapse
              isOpen={details?.length !== 0 ? false : true}
              className="my-2"
              title="Overall"
            >
              {details.map((attr, i) => {
                return <AttributeDisplay key={i} attr={attr} />;
              })}
            </Collapse>
          )}
          {specifications?.length !== 0 && customizationProps && (
            <Collapse
              isOpen={details.length !== 0 ? false : true}
              className="my-2"
              title="Specifications"
            >
              {specifications.map((attr, i) => {
                return <AttributeDisplay key={i} attr={attr} />;
              })}
            </Collapse>
          )}
          {dimensions && customizationProps && !fabricPDP && (
            <Collapse
              isOpen={specifications?.length !== 0 ? false : true}
              className="my-2"
              title="Dimensions (in)"
            >
              {dimensions.map((attr, i) => {
                return <AttributeDisplay key={i} attr={attr} />;
              })}
            </Collapse>
          )}
        </MDBCol>
      </MDBRow>
      {showRelatedProducts && relatedProducts.length > 0 && (
        <MDBRow className="related-products mb-lg-0 mb-5">
          <>
            <MDBCol size="12" className="mb-3">
              <MDBTypography tag="h3">Related Products</MDBTypography>
            </MDBCol>
            <MDBCol size="12">
              <Slider {...sliderProps}>
                {relatedProducts.map((product) => {
                  const badges = getBadges(product);
                  const isFavorite = getIsFavorite({
                    favorites: favorites ?? [],
                    sku: product.sku,
                  });
                  const handleToggleFavorite = () => {
                    toggleFavorite(product);
                  };
                  return (
                    <ProductWidget
                      {...product}
                      key={product.sku}
                      image={product.thumbnail}
                      colSize="12"
                      isFavorite={isFavorite}
                      is_new={product.is_new}
                      onToggleFavorite={handleToggleFavorite}
                      badges={badges}
                      showFavorites={false}
                    />
                  );
                })}
              </Slider>
            </MDBCol>
          </>
        </MDBRow>
      )}
    </div>
  );
};

const AttributeDisplay = ({ attr, ext = "" }) => {
  return (
    <>
      {Object.keys(attr).map((attrN) => {
        const attrName = _.capitalize(attrN)
          .replace(/_/g, " ")
          .replace("Weight", "Weight (lbs)");
        const attrValue =
          attr[attrN] === "N/A"
            ? false
            : attr[attrN] === 1
            ? "Yes"
            : attr[attrN];

        return (
          <React.Fragment key={attrName}>
            {attrValue ? (
              <MDBRow className="py-2">
                <MDBCol size="6" className="">
                  <MDBTypography tag="h4" className="text-primary fwlight">
                    {attrName}
                  </MDBTypography>
                </MDBCol>
                <MDBCol size="6">
                  <MDBTypography tag="h4" className="text-primary fwthin">
                    {attrValue}
                    {ext}
                  </MDBTypography>
                </MDBCol>
              </MDBRow>
            ) : (
              <></>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default React.memo(DisplayInfo);
