import checked from "app/assets/icon/checked.svg";
import heartFull from "app/assets/icon/heartFull.svg";
import { WishlistJola } from "app/generated/graphql";
import clsx from "clsx";
import { MDBTypography } from "mdbreact";
import React from "react";
import { favoritesName } from "../../../config";

export interface WishListRowProps extends WishlistJola {
  checked: boolean;
  onCheck: React.EventHandler<React.SyntheticEvent>;
}

const checkboxSize = 40;

const imgStyle = {
  maxWidth: "100%",
  maxHeight: "100%",
};
const WishlistRow: React.FC<WishListRowProps> = (props) => {
  return (
    <div className="d-flex py-3">
      <div
        className="mr-4 pr-2 d-flex justify-content-center align-items-center"
        style={{ width: 100, height: 100 }}
      >
        {/* * this method will be migrated from, following the API update */}
        {props.name === favoritesName ? (
          <img
            src={heartFull}
            alt={favoritesName}
            style={{ transform: "scale(1.8)" }}
          />
        ) : props.items?.[0]?.product?.small_image?.url ? (
          <img
            style={imgStyle}
            src={props.items?.[0].product.small_image.url}
            alt={props.items?.[0].product.small_image.label ?? "First product"}
          />
        ) : (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <MDBTypography>No products.</MDBTypography>
          </div>
        )}
      </div>
      <div
        className="d-flex justify-content-center flex-column"
        style={{ flexGrow: 1 }}
      >
        {/* @ts-ignore */}
        <MDBTypography variant="body-2">{props.name}</MDBTypography>
        {/* @ts-ignore */}
        <MDBTypography variant="body-4">
          {props.items_count} List(s)
        </MDBTypography>
      </div>
      <div className="d-flex align-items-center ">
        <div
          className={clsx(
            "border-default-1",
            "border-radius-05",
            "overflow-hidden",
            "pointer",
            {
              "bg-default": props.checked,
            }
          )}
          style={{ width: checkboxSize, height: checkboxSize }}
          onClick={props.onCheck}
          onKeyUp={props.onCheck}
          tabIndex={0}
          role="button"
        >
          {props.checked ? (
            <img className="w-100 h-100 p-2" src={checked} alt={"Checked"} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default WishlistRow;
