import React from "react";
import { gql } from "graphql.macro";
import CollectionNotDataContainer from "./collectionNotCustom-data-container";
import { useQuery } from "@apollo/react-hooks";
import SkeletonCategory from "../../skeleton-category";

const MEDIA_GALLERY_ENTRY = gql`
  query Category($id: String!) {
    categoryList(filters: { ids: { eq: $id } }) {
      category_video
      media_gallery_entries {
        image_url
      }
    }
  }
`;

const CollectionNotCustomQueryContainer = ({
  data,
  productsInCollection,
  currentPage,
  setCurrentPage,
  handleSetPageSize,
  pageSizeOptions,
  pageSize,
  setPageSize,
  totalPages,
  totalCount,
  ...props
}) => {
  let categoryId = props?.queryProps?.filter?.category_id?.eq;
  const { data: dataR, error: errorR, loading: landingR } = useQuery(
    MEDIA_GALLERY_ENTRY,
    {
      variables: {
        id: categoryId,
      },
    }
  );
  if (landingR) return <SkeletonCategory />;
  if (errorR) return <>ERROR</>;
  let imageAndVideo = dataR?.categoryList;
  return (
    <CollectionNotDataContainer
      {...props}
      data={data}
      imageAndVideo={imageAndVideo}
      productsInCollection={productsInCollection}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalPages={totalPages}
      totalCount={totalCount}
      setPageSize={setPageSize}
      pageSizeOptions={pageSizeOptions}
      pageSize={pageSize}
    />
  );
};

export default CollectionNotCustomQueryContainer;
