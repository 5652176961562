import { Maybe } from "app/generated/graphql";
import * as yup from "yup";

export interface QuantityValues {
  quantity: Maybe<number>;
}

export const quantityValues: QuantityValues = {
  quantity: null,
};

export const quantitySchema = yup
  .object()
  .required()
  .shape<QuantityValues>({
    quantity: yup.number().required().nullable().min(0),
  });
