import Icon from "app/assets/icon/icon";
import { DomainCartFragment, Money } from "app/generated/graphql";
import { determineCurrency } from "config/currency";
import { number_format } from "core/components/price/price";
import { MDBBtn, MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React, { useMemo } from "react";
import DownloadPDF from "./DownloadPDF";

export interface CartSummaryProps
  extends Required<Pick<React.ComponentProps<typeof MDBBtn>, "disabled">> {
  quantity: number;
  money: Money;
  onSubmit: () => void;
  onOpenSummary: () => void;
  cartItems: DomainCartFragment;
}

const CartSummary: React.FC<CartSummaryProps> = (props) => {
  const calculatePriceWithoutOptions = useMemo(() => {
    // @ts-ignore
    const arr = [...props?.cartItems];
    // @ts-ignore
    return arr?.length
      ? // @ts-ignore
        arr?.reduce(
          // @ts-ignore
          (acc, curr) =>
            acc +
            (curr?.quantity || 1) *
              (curr?.product?.price_range?.minimum_price?.final_price?.value ||
                0),
          0
        )
      : 0;
  }, [props?.cartItems]);

  return (
    <div className="bg-gray-lighten-4 p-4">
      <div>
        <div className="d-flex">
          <MDBTypography className="grow" tag="h1" variant="h1">
            Quote Summary
          </MDBTypography>
          <Icon
            className="cursor-pointer"
            icon="rightMobile"
            onClick={props.onOpenSummary}
          />
        </div>
        <div className="mt-5 pt-4 border-top-1 border-default">
          <MDBRow>
            <MDBCol size="7" className="d-flex flex-column align-items-end">
              {/* @ts-ignore */}
              <MDBTypography tag="h2" className={`text-gray-darken-2`}>
                Item(s)
              </MDBTypography>
              {/* <MDBTypography className="mt-4 text-gray-darken-2" tag="h2">
                Quote Subtotal
              </MDBTypography> */}
            </MDBCol>
            <MDBCol size="5" className="d-flex flex-column">
              {/* @ts-ignore */}
              <MDBTypography tag="h2">QTY {props.quantity}</MDBTypography>
              {/* <MDBTypography className="mt-4" tag="h2"> */}
              {/* {determineCurrency(props.money.currency)}{" "} */}
              {/* {number_format(props.money.value, 2)} */}
              {/* {calculatePriceWithoutOptions?.toFixed(2)} */}
              {/* </MDBTypography> */}
            </MDBCol>
          </MDBRow>
        </div>
        <div className="mt-5 pt-4 border-top-1 border-default">
          <DownloadPDF
            className={`w-100 icon icon-left m-0 mb-4`}
            //@ts-ignore
            items={
              ({ items: props.cartItems } as unknown) as DomainCartFragment
            }
            //@ts-ignore
            quantityPerPage={props.cartItems?.length}
          />
          <MDBBtn className="w-100 m-0" onClick={props.onSubmit}>
            Submit Quote
          </MDBBtn>
        </div>
      </div>
    </div>
  );
};

export default CartSummary;
