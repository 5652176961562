import React from "react";

export interface FieldLengthProps {
  length: number;
  maxLength: number;
}

const FieldLength: React.FC<FieldLengthProps> = (props) => {
  return (
    <div className="text-gray position-absolute" style={{ right: 20, top: 13 }}>
      {props.length.toString().padStart(2, "0")}
      {"/"}
      {props.maxLength}
    </div>
  );
};

export default FieldLength;
