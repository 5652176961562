import React, { useRef } from "react";
import Slider from "react-slick";
import { MDBRow, MDBBox } from "mdbreact";
import Filters from "./filters";

const FabricSidebarToolbar = ({
  fabricsCategories,
  selectedCategory,
  setSelectedCategory,
  activeFilters,
  setActiveFilters,
  initialAggregations,
  loadingFabricItems,
  selectedFilters,
  setSelectedFilters,
  currentPage,
  setCurrentPage,
  allItems,
}) => {
  const toolbarRef = useRef(null);
  const aggregations = initialAggregations;
  let toolbarStyle = {
    position: "sticky",
    top: 0,
    zIndex: 10,
    boxShadow: "0px 10px 10px -10px rgba(0, 0, 0, 0.5)",
  };
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    ease: "linear",
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="fabrics-toolbar" style={toolbarStyle} ref={toolbarRef}>
      <MDBRow className="fabrics-categories-toolbar">
        <Slider {...settings} className={`flex-wrap w-100 d-flex`}>
          {fabricsCategories &&
            fabricsCategories.length > 0 &&
            fabricsCategories.map((category, index) => {
              if (category.id != 8344 && category.id != 8349) {
                return (
                  <MDBBox
                    onClick={() => {
                      setSelectedCategory(category.id);
                      setCurrentPage(1);
                    }}
                    key={`fabric-category-item-${index}`}
                    className={`fabrics-categories-toolbar-item ${
                      selectedCategory === category.id && "active-item"
                    }`}
                  >
                    <p className="text-gray-darken-3">{category.name}</p>
                  </MDBBox>
                );
              }
            })}
        </Slider>
      </MDBRow>
      <Filters
        aggregations={aggregations}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        loadingFabricItems={loadingFabricItems}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        allItems={allItems}
      />
    </div>
  );
};
export default FabricSidebarToolbar;
