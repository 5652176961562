import gql from "graphql-tag";

//query for fabric subcategories
export const FABRICS_CATEGORIES = gql`
  query FabricsCategories($id: String!) {
    categoryList(filters: { ids: { eq: $id } }) {
      id
      name
      url_key
      include_in_menu
      url_path
      children {
        id
        name
        jola_product_count
        url_key
        url_path
        include_in_menu
      }
    }
  }
`;

//query for fabric products
export const FABRICS = gql`
  query Fabrics(
    $filters: ProductAttributeFilterInput!
    $pageSize: Int!
    $currentPage: Int!
  ) {
    products(filter: $filters, pageSize: $pageSize, currentPage: $currentPage) {
      aggregations {
        label
        attribute_code
        options {
          label
          value
        }
      }
      total_count
      items {
        id
        name
        sku
        url_key
        ffifabrics_grade
        image {
          url(width: 240, height: 300)
          label
        }
        description {
          html
        }
        price_range {
          maximum_price {
            final_price {
              value
            }
          }
        }
        pattern: ffifabrics_pattern
        grade: ffifabrics_grade
        collection: ffifabrics_collection
        color: ffifabrics_hexclrgrp
        decCord: ffifabrics_dec_cord
        stockQty: ffifabrics_avail
        fringe: ffifabrics_fringe
        tape: ffifabrics_tape
        ffifabrics_fabric
        ffifabrics_collection_label
      }
    }
  }
`;

//query for fabric products without aggregations - using this query after any filter is applied, so the filter options wouldn't re-render
export const FABRICS_WITHOUT_AGGREGATIONS = gql`
  query FabricsWithoutAggregations(
    $filters: ProductAttributeFilterInput!
    $pageSize: Int!
    $currentPage: Int!
  ) {
    products(filter: $filters, pageSize: $pageSize, currentPage: $currentPage) {
      total_count
      items {
        id
        name
        sku
        url_key
        ffifabrics_grade
        image {
          url(width: 240, height: 300)
          label
        }
        description {
          html
        }
        pattern: ffifabrics_pattern
        grade: ffifabrics_grade
        collection: ffifabrics_collection
        color: ffifabrics_hexclrgrp
        decCord: ffifabrics_dec_cord
        fringe: ffifabrics_fringe
        ffifabrics_fabric
        tape: ffifabrics_tape
        stockQty: ffifabrics_avail
        ffifabrics_collection_label
      }
    }
  }
`;
