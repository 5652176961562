// @ts-check
import Checkbox from "app/layout/Checkbox";
import FileInput from "app/layout/FileInput";
import Label from "app/layout/Label";
import MaskedInput from "app/layout/MaskedInput";
import Steps from "app/layout/registration/Steps";
import Select from "app/layout/Select";
import { acceptDocuments } from "app/pages/Cart/utils";
import { customerRegisterUpload } from "app/utils/upload";
import { formatFileName } from "app/utils/utils";
import clsx from "clsx";
import { MB2 } from "config/upload";
import { omit } from "lodash";
import {
  MDBBox,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React from "react";
import { businessTypes, industryTypes } from "./config";

/**
 *
 * @typedef {{ status: "initial", value: null }
 * | { status: "required-error", value: null }
 * | { status: "size-error", value: File }
 * | { status: "loading", value: File }
 * | { status: "upload-error", value: File }
 * | { status: "success", value: { name: string } }} Doc
 */

/**
 * @typedef {object} StepOneProps
 * @prop {import('app/layout/registration/Steps').StepsProps['setStep']} setStep
 * @prop {import('formik').FormikProps<import('./config').CompanyDetailsValues>} form
 * @prop {Doc} taxIdDoc
 * @prop {React.Dispatch<React.SetStateAction<Doc>>} setTaxIdDoc
 *@typedef {any} isEmptyValue
 * @param {StepOneProps} props
 */
// @ts-ignore
const StepOne = ({ taxIdDoc, setTaxIdDoc, form, setStep, setError, error }) => {
  const [isAccountNumber, setIsAccountNumber] = React.useState(true);
  const industryTypeOption = React.useMemo(
    () => industryTypes.find(({ value }) => value === form.values.industryType),
    [form]
  );
  const businessTypeOption = React.useMemo(
    () => businessTypes.find(({ value }) => value === form.values.businessType),
    [form]
  );

  /**
   * @type {typeof form.submitForm}
   */
  const submit = React.useCallback(() => {
    return form.submitForm();
  }, [form]);

  const formattedTaxIdDoc = React.useMemo(() => {
    return taxIdDoc.value?.name.replace(/-\w+(?=\.\w+$)/, "") ?? "";
  }, [taxIdDoc]);
  React.useEffect(() => {
    if (isAccountNumber) {
      form.values.taxId = "";
    } else {
      form.values.fairfield_account_number = "";
    }
    setError("");
  }, [isAccountNumber]);
  let taxIdregex = /^\d{2}-\d{7}$/;
  let accountNumberRegex = /^\d{6}/;
  React.useEffect(() => {
    if (
      accountNumberRegex.test(form.values.fairfield_account_number) &&
      isAccountNumber
    ) {
      setError("");
    } else if (taxIdregex.test(form.values.taxId) && !isAccountNumber) {
      setError("");
    }
  }, [form.values.fairfield_account_number, form.values.taxId]);

  // @ts-ignore
  const checkIfErrorExits = (filed, value) => {
    if (value === "") {
      setError(
        `${
          filed === "taxId" ? "Tax ID" : "Fairfield Chair Account"
        } is required.`
      );
    } else if (!taxIdregex.test(value) && filed === "taxId") {
      setError("Tax ID must be in the following form: xx-xxxxxxx");
    } else if (
      !accountNumberRegex.test(value) &&
      filed === "fairfield_account_number"
    ) {
      setError("Fairfield Chair Account must be in the following form: xxxxxx");
    } else {
      setError("");
    }
    // @ts-ignore
    form.values[filed] = value;
  };
  return (
    <>
      <MDBRow className="my-5 pb-3 steps">
        <MDBCol className="pb-3">
          <Steps step={0} setStep={setStep} validateStep={submit} />
        </MDBCol>
      </MDBRow>
      <MDBContainer>
        <MDBRow className="mb-md-5 pb-4">
          <MDBCol className="col-12 col-lg-10 offset-lg-1">
            <form onSubmit={form.handleSubmit}>
              <MDBRow>
                <MDBCol className="col-12 col-md-6">
                  <MDBRow>
                    <MDBCol>
                      <MDBRow className="mb-5 pb-md-5">
                        <MDBCol className="mb-3 customer-type-step-one___required-label">
                          <Label text="Customer Type" className="mb-0">
                            <MDBRow className="mt-4">
                              <MDBCol className="mb-2 mb-md-0 d-flex align-items-center">
                                <Checkbox
                                  checked={form.values.isNew}
                                  onChange={React.useCallback(() => {
                                    form.setFieldValue(
                                      "isNew",
                                      !form.values.isNew
                                    );
                                  }, [form])}
                                  className="mb-0"
                                />
                                <MDBTypography className="mb-0 ml-3 fs-13 fwregular text-gray-darken-3">
                                  I’m an Existing Fairfield Chair Customer
                                </MDBTypography>
                              </MDBCol>
                            </MDBRow>
                          </Label>

                          {form.touched.isNew && form.errors.isNew}
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol className="mb-35">
                          <MDBInput
                            containerClass="w-100"
                            {...form.getFieldProps("companyName")}
                            label="Company Name"
                            hint="ex: Fairfield Chair"
                            required
                            // @ts-ignore
                            className={clsx("min-width-xv", {
                              error:
                                form.touched.companyName &&
                                form.errors.companyName,
                            })}
                          />
                          <div className="text-red position-absolute">
                            {form.touched.companyName &&
                              form.errors.companyName}
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol className="mb-4">
                          <MDBInput
                            containerClass="w-100"
                            {...form.getFieldProps("websiteUrl")}
                            label="Website URL"
                            hint="ex: https://fairfield.joladev.com/"
                            className={clsx("min-width-xv", {
                              error:
                                form.touched.websiteUrl &&
                                form.errors.websiteUrl,
                            })}
                          />
                          <div className="text-red position-absolute">
                            {form.touched.websiteUrl && form.errors.websiteUrl}
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol>
                          <MaskedInput
                            {...form.getFieldProps("taxId")}
                            label="Tax ID"
                            placeholder="ex: xx-xxxxxxx"
                            required={!isAccountNumber}
                            mask={[
                              /\d/,
                              /\d/,
                              "-",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            className={clsx("min-width-xv", "mb-3", {
                              error:
                                (form.touched.taxId && form.errors.taxId) ||
                                (!isAccountNumber && error !== ""),
                            })}
                            onChange={(e) =>
                              checkIfErrorExits("taxId", e.target.value)
                            }
                            onBlur={(e) => {
                              if (e.target.value === "") {
                                setError("Tax ID is required.");
                              }
                            }}
                            disabled={isAccountNumber}
                          />
                          <div className="text-red">
                            {/* {form.touched.taxId && form.errors.taxId} */}
                            {!isAccountNumber && error !== "" && <p>{error}</p>}
                          </div>
                        </MDBCol>
                        <MDBCol size="12" className="mb-35">
                          <Checkbox
                            className=""
                            // key={option.value}
                            checked={isAccountNumber}
                            label={`Don’t have a Tax ID use your Fairfield Chair Account Number`}
                            onChange={() =>
                              setIsAccountNumber(!isAccountNumber)
                            }
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol className="mb-35">
                          <MaskedInput
                            {...form.getFieldProps("fairfield_account_number")}
                            label="Fairfield Chair Account"
                            placeholder="ex: xxxxxx"
                            required={isAccountNumber}
                            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                            className={clsx("min-width-xv", "mb-4", {
                              error:
                                (form.touched.fairfield_account_number &&
                                  form.errors.fairfield_account_number) ||
                                (isAccountNumber && error !== ""),
                            })}
                            disabled={!isAccountNumber}
                            onChange={(e) =>
                              checkIfErrorExits(
                                "fairfield_account_number",
                                e.target.value
                              )
                            }
                            onBlur={(e) => {
                              if (e.target.value === "") {
                                setError(
                                  "Fairfield Chair Account is required."
                                );
                              }
                            }}
                          />
                          <div
                            className="text-red position-absolute masked-input-error"
                            style={{ bottom: "-0.6rem" }}
                          >
                            {/* {form.touched.fairfield_account_number &&
                              form.errors.fairfield_account_number} */}
                            {isAccountNumber && error !== "" && <p>{error}</p>}
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol className="col-12 col-md-6">
                  <MDBRow>
                    <MDBCol className="mb-5">
                      <Label required text="Industry Type" className="mb-0">
                        <MDBBox className="mt-3">
                          <Select
                            placeholder="Select Industry Type"
                            value={industryTypeOption}
                            options={industryTypes}
                            // @ts-ignore
                            onChange={(option) => {
                              form.setFieldValue("industryType", option.value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched("industryType");
                            }}
                            className={clsx("min-width-xv", {
                              error:
                                form.touched.industryType &&
                                form.errors.industryType,
                            })}
                          />
                        </MDBBox>
                      </Label>

                      <div className="text-red position-absolute">
                        {form.touched.industryType && form.errors.industryType}
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol className="mb-5">
                      <Label required text="Business Type" className="mb-0">
                        <MDBBox className="mt-3">
                          <Select
                            placeholder="Select Business Type"
                            value={businessTypeOption}
                            options={businessTypes}
                            // @ts-ignore
                            onChange={(option) => {
                              form.setFieldValue("businessType", option.value);
                            }}
                            onBlur={() => {
                              form.setFieldTouched("businessType");
                            }}
                            className={clsx("min-width-xv", {
                              error:
                                form.touched.businessType &&
                                form.errors.businessType,
                            })}
                          />
                        </MDBBox>
                      </Label>

                      <div className="text-red position-absolute">
                        {form.touched.businessType && form.errors.businessType}
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      {/* @ts-ignore */}
                      <MDBTypography variant="body-1" className="mb-5">
                        How did you hear about us?
                      </MDBTypography>
                      <MDBRow>
                        <MDBCol className="col-12 col-md-6">
                          <Checkbox
                            {...form.getFieldProps("channel['trade magazine']")}
                            checked={form.values.channel["trade magazine"]}
                            className="mb-20"
                            label="Trade Magazine"
                          />
                          <Checkbox
                            {...form.getFieldProps("channel.instagram")}
                            checked={form.values.channel.instagram}
                            className="mb-20"
                            label="Instagram"
                          />
                          <Checkbox
                            {...form.getFieldProps("channel.pinterest")}
                            checked={form.values.channel.pinterest}
                            className="mb-20"
                            label="Pinterest"
                          />
                          <Checkbox
                            {...form.getFieldProps("channel.facebook")}
                            checked={form.values.channel.facebook}
                            className="mb-20"
                            label="Facebook"
                          />
                          <Checkbox
                            {...form.getFieldProps("channel.linkedin")}
                            checked={form.values.channel.linkedin}
                            className="mb-20"
                            label="Linkedin"
                          />
                          <Checkbox
                            {...form.getFieldProps("channel['word of mouth']")}
                            checked={form.values.channel["word of mouth"]}
                            className="mb-20"
                            label="Word of Mouth"
                          />
                          <Checkbox
                            {...form.getFieldProps(
                              "channel['company website']"
                            )}
                            className="mb-20"
                            checked={form.values.channel["company website"]}
                            label="Company Website"
                          />
                        </MDBCol>
                        <MDBCol className="col-12 col-md-6">
                          <Checkbox
                            {...form.getFieldProps("channel.retailer")}
                            checked={form.values.channel.retailer}
                            className="mb-20"
                            label="Retailer"
                          />
                          <Checkbox
                            {...form.getFieldProps("channel['in-house show']")}
                            checked={form.values.channel["in-house show"]}
                            className="mb-20"
                            label="In-House Show"
                          />
                          <Checkbox
                            {...omit(
                              form.getFieldProps("channel['trade show']"),
                              "value"
                            )}
                            checked={form.values.channel["trade show"] !== null}
                            onChange={(e) => {
                              form.setFieldValue(
                                "channel['trade show']",
                                e.target.checked ? "" : null
                              );
                            }}
                            className="mb-20"
                            label="Trade Show"
                          />
                          <MDBBox className="mb-20">
                            <MDBInput
                              containerClass="w-100"
                              {...form.getFieldProps("channel['trade show']")}
                              // @ts-ignore
                              value={form.values.channel["trade show"] ?? ""}
                              disabled={
                                form.values.channel["trade show"] === null
                              }
                              hint="Please Specify"
                              className="min-width-xv"
                            />
                          </MDBBox>
                          <Checkbox
                            {...omit(
                              form.getFieldProps("channel.other"),
                              "value"
                            )}
                            checked={form.values.channel.other !== null}
                            onChange={(e) => {
                              form.setFieldValue(
                                "channel.other",
                                e.target.checked ? "" : null
                              );
                            }}
                            className="mb-20"
                            label="Other"
                          />
                          <MDBInput
                            containerClass="w-100"
                            {...form.getFieldProps("channel.other")}
                            // @ts-ignore
                            value={form.values.channel.other ?? ""}
                            disabled={form.values.channel.other === null}
                            hint="Please Specify"
                            className="min-width-xv"
                          />
                        </MDBCol>
                      </MDBRow>
                      <div className="text-red position-absolute">
                        {form.touched.channel && form.errors.channel}
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCol>
        </MDBRow>
        <MDBRow className="border-top-1 pt-5 mb-100">
          <MDBCol className="d-flex justify-content-end">
            <MDBBtn
              onClick={() => {
                if (
                  isAccountNumber &&
                  form.values.fairfield_account_number === ""
                ) {
                  setError("Fairfield Chair Account is required.");
                } else if (!isAccountNumber && form.values.taxId === "") {
                  setError("Tax ID is required.");
                }
                submit();
                window.scrollTo(0, 0);
              }}
              disabled={
                taxIdDoc.status === "loading" ||
                taxIdDoc.status === "size-error"
              }
              className="mb-0"
            >
              Next
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default StepOne;
