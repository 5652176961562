import JolaViewer from "@jola_interactive/jola_viewer";
import {
  TextureLoader,
  PlaneBufferGeometry,
  MeshBasicMaterial,
  ShadowMaterial,
  RepeatWrapping,
  Mesh,
  Vector3,
  Box3,
  LineBasicMaterial,
  Color,
  Group,
  BufferGeometry,
  Line,
  FontLoader,
  TextGeometry,
  sRGBEncoding,
  Fog,
  Euler,
} from "three";
import themeConfiguration from "../../../config/themeConfiguration";

export const HavenModels = [
  "back-B-01.gltf",
  "back-B-24.gltf",
  "back-B-26.gltf",
  "back-B-27.gltf",
  "back-B-29.gltf",
  "back-B-31.gltf",
  "back-B-32.gltf",
  "back-B-50.gltf",
  "back-B-51.gltf",
  "back-B-52.gltf",
  "back-B-53.gltf",
  "back-B-54.gltf",
  "back-B-59.gltf",
  "back-B-60.gltf",
  "back-B-70.gltf",
  "back-B-71.gltf",
  "back-B-72.gltf",
  "back-B-78.gltf",
  "back-B-79.gltf",
  "back-B-wide-01.gltf",
  "back-B-wide-26.gltf",
  "back-B-wide-27.gltf",
  "back-B-wide-31.gltf",
  "back-B-wide-32.gltf",
  "back-B-wide-50.gltf",
  "back-B-wide-51.gltf",
  "back-B-wide-52.gltf",
  "back-B-wide-53.gltf",
  "back-B-wide-54.gltf",
  "back-B-wide-59.gltf",
  "back-B-wide-60.gltf",
  "back-B-wide-70.gltf",
  "back-B-wide-71.gltf",
  "back-B-wide-72.gltf",
  "back-K-01.gltf",
  "back-K-24.gltf",
  "back-K-26.gltf",
  "back-K-27.gltf",
  "back-K-29.gltf",
  "back-K-31.gltf",
  "back-K-32.gltf",
  "back-K-50.gltf",
  "back-K-51.gltf",
  "back-K-52.gltf",
  "back-K-53.gltf",
  "back-K-54.gltf",
  "back-K-59.gltf",
  "back-K-60.gltf",
  "back-K-70.gltf",
  "back-K-71.gltf",
  "back-K-72.gltf",
  "back-K-78.gltf",
  "back-K-79.gltf",
  "back-K-wide-01.gltf",
  "back-K-wide-26.gltf",
  "back-K-wide-27.gltf",
  "back-K-wide-31.gltf",
  "back-K-wide-32.gltf",
  "back-K-wide-50.gltf",
  "back-K-wide-51.gltf",
  "back-K-wide-52.gltf",
  "back-K-wide-53.gltf",
  "back-K-wide-54.gltf",
  "back-K-wide-59.gltf",
  "back-K-wide-60.gltf",
  "back-K-wide-70.gltf",
  "back-K-wide-71.gltf",
  "back-K-wide-72.gltf",
  "cushion-left.gltf",
  "cushion-right.gltf",
  "frame-01.gltf",
  "frame-20.gltf",
  "frame-24.gltf",
  "frame-26.gltf",
  "frame-27.gltf",
  "frame-29.gltf",
  "frame-31.gltf",
  "frame-32.gltf",
  "frame-50.gltf",
  "frame-51.gltf",
  "frame-52.gltf",
  "frame-53.gltf",
  "frame-54.gltf",
  "frame-59.gltf",
  "frame-60.gltf",
  "frame-70.gltf",
  "frame-71.gltf",
  "frame-72.gltf",
  "frame-78.gltf",
  "frame-79.gltf",
  "leg-J.gltf",
  "leg-S-01.gltf",
  "leg-S-20.gltf",
  "leg-S-24.gltf",
  "leg-S-26.gltf",
  "leg-S-27.gltf",
  "leg-S-29.gltf",
  "leg-S-31.gltf",
  "leg-S-32.gltf",
  "leg-S-50.gltf",
  "leg-S-51.gltf",
  "leg-S-52.gltf",
  "leg-S-53.gltf",
  "leg-S-54.gltf",
  "leg-S-59.gltf",
  "leg-S-60.gltf",
  "leg-S-70.gltf",
  "leg-S-71.gltf",
  "leg-S-72.gltf",
  "leg-S-78.gltf",
  "leg-S-79.gltf",
  "leg-T.gltf",
  "seat-01.gltf",
  "seat-20.gltf",
  "seat-24.gltf",
  "seat-26.gltf",
  "seat-27.gltf",
  "seat-29.gltf",
  "seat-31.gltf",
  "seat-32.gltf",
  "seat-50.gltf",
  "seat-51.gltf",
  "seat-52.gltf",
  "seat-53.gltf",
  "seat-54.gltf",
  "seat-59.gltf",
  "seat-60.gltf",
  "seat-70.gltf",
  "seat-71.gltf",
  "seat-72.gltf",
  "seat-78.gltf",
  "seat-79.gltf",
  "seat-wide-01.gltf",
  "seat-wide-26.gltf",
  "seat-wide-27.gltf",
  "seat-wide-31.gltf",
  "seat-wide-32.gltf",
  "seat-wide-50.gltf",
  "seat-wide-51.gltf",
  "seat-wide-52.gltf",
  "seat-wide-53.gltf",
  "seat-wide-54.gltf",
  "seat-wide-59.gltf",
  "seat-wide-60.gltf",
  "seat-wide-70.gltf",
  "seat-wide-71.gltf",
  "seat-wide-72.gltf",
  "side-E-26.gltf",
  "side-E-27.gltf",
  "side-E-60.gltf",
  "side-E.gltf",
  "side-L-26.gltf",
  "side-L-27.gltf",
  "side-L-60.gltf",
  "side-L.gltf",
  "side-P-26.gltf",
  "side-P-27.gltf",
  "side-P-60.gltf",
  "side-P.gltf",
  "side-S-26.gltf",
  "side-S-27.gltf",
  "side-S-60.gltf",
  "side-S.gltf",
  "side-T-26.gltf",
  "side-T-27.gltf",
  "side-T-60.gltf",
  "side-T.gltf",
];

export const zoomOptions = {
  min: -3,
  current: 0,
  max: 8,
};

export class Player extends JolaViewer {
  constructor(containerID, options) {
    super(containerID, options);
    this.collectionPath = options.collectionPath
      ? options.collectionPath
      : "haven-reserve";

    this.hdrPath = `${themeConfiguration.model_url}/3d/${this.collectionPath}/webgl/hdr/hdr.hdr`;
    this.loadHDR(this.hdrPath, 0.2);

    this.scene.background = new Color("#fbfbfb");

    this.model = new Group();
    this.mainFabricCounter = 0;
    this.init = false;
    this.model.name = "model";
    this.modelFrame = new Group();
    this.modelArm = new Group();
    this.modelSeat = new Group();
    this.modelBack = new Group();
    this.modelBase = new Group();
    this.modelCushion = new Group();
    this.modelDimensions = new Group();

    this.framePin = "small_pins";
    this.sidePin = "side_pins";

    this.materials = [];

    this.textureLoader = new TextureLoader();

    this.frameObject = {
      id: "01",
      name: "Chair",
      image: "images/chair.jpg",
    };
    this.armObject = {
      id: "E",
      name: "Side E",
      image: "images/side-E.jpg",
      type: "wide",
    };
    this.seatObject = {
      id: "1",
    };
    this.backObject = {
      id: "B",
      name: "Boxed",
      image: "images/back-B.jpg",
    };
    this.baseObject = {
      id: "J",
      name: "Leg J",
      image: "images/leg-J.jpg",
      type: "leg",
    };

    this.pinsObject = {};

    this.modelsPath = `${themeConfiguration.model_url}/3d/${this.collectionPath}/webgl/models/`;
    this.cushionEnabled = true;
    this.nailsEnabled = false;

    this.productDimensions = {};
  }

  async Init() {
    this.model.add(this.modelFrame);
    this.model.add(this.modelArm);
    this.model.add(this.modelSeat);
    this.model.add(this.modelBack);
    this.model.add(this.modelBase);
    this.model.add(this.modelCushion);
    this.model.add(this.modelDimensions);

    this.scene.add(this.model);

    await this.SetFloor();

    await this.LoadFinish(
      "main",
      `${themeConfiguration.model_url}/3d/textures/fabric/3100_linen_seamless.jpg`,
      `${themeConfiguration.model_url}/3d/textures/fabric/3100_linen_seamless_icon.jpg`,
      "Linen",
      8
    );

    await this.LoadFinish(
      "woodMAT",
      `${themeConfiguration.model_url}/3d/textures/wood/cerused_espresso_seamless.jpg`,
      `${themeConfiguration.model_url}/3d/textures/wood/cerused_espresso_seamless_icon.jpg`,
      "Cerused Expresso",
      1
    );

    await this.LoadFinish(
      "nail_finish",
      `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_aged_bronze.jpg`,
      `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_aged_bronze_swach.png`,
      "Aged Bronze",
      "5",
      "0.3",
      "0.15"
    );

    await this.LoadFinish(
      "base_finish",
      `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_aged_bronze.jpg`,
      `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_aged_bronze_swach.png`,
      "Aged Bronze",
      "5",
      "0.3",
      "0.15"
    );

    this.materials.push({
      type: "nail_finish",
      map: null,
    });

    await this.LoadFrame(this.frameObject, true);
  }

  async SetFloor() {
    let parquetMap = await this.LoadTexture(
      `${themeConfiguration.model_url}/3d/textures/floor/grid.jpg`
    );
    parquetMap.repeat.set(500, 500);
    parquetMap.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    parquetMap.wrapT = RepeatWrapping;
    parquetMap.wrapS = RepeatWrapping;

    let geo = new PlaneBufferGeometry(100, 100);
    let mat = new MeshBasicMaterial({
      map: parquetMap,
    });

    this.plane = new Mesh(geo, mat);
    //plane.visible = false;
    this.plane.receiveShadow = true;
    this.plane.position.set(0, 0, 0);
    this.plane.rotation.set(Math.PI / -2, 0, 0);

    this.scene.fog = new Fog(0xfbfbfb, 10, 20);
    this.scene.add(this.plane);

    let shadowMat = new ShadowMaterial({ opacity: 0.2 });
    this.shadowPlane = new Mesh(geo, shadowMat);
    this.shadowPlane.receiveShadow = true;
    this.shadowPlane.position.set(0, 0, 0);
    this.shadowPlane.rotation.set(Math.PI / -2, 0, 0);
    this.scene.add(this.shadowPlane);
  }

  async LoadFloor(map, repeat) {
    let newMap = await this.LoadTexture(map);
    newMap.repeat.set(repeat, repeat);
    newMap.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    newMap.wrapS = RepeatWrapping;
    newMap.wrapT = RepeatWrapping;
    if (this.plane?.material) {
      this.plane.material.map = newMap;
    }
  }

  LoadObject(url) {
    return new Promise((resolve, reject) => {
      try {
        this.loader.load(url, (result) => {
          this.updateMap(result.scene);
          resolve(result);
        });
      } catch (e) {
        reject(false);
      }
    });
  }

  async Reset() {
    this.modelFrame.children = [];
    this.modelArm.children = [];
    this.modelBack.children = [];
    this.modelSeat.children = [];
    this.modelBase.children = [];
    this.modelCushion.children = [];
    this.modelDimensions.children = [];
  }

  async LoadFrame(frameObject, updateCameraPosition = false) {
    await this.Reset();
    await this.HideDimensions();

    this.frameObject = frameObject;

    if (Array.isArray(this.frameObject.id)) {
      for (const id of this.frameObject.id) {
        let componentPath = this.modelsPath + "frame-" + id + ".gltf";
        let componentModel = await this.LoadObject(componentPath);

        let component = componentModel.scene;

        if (this.modelFrame.children.length > 0) {
          let frame = this.modelFrame.children[
            this.modelFrame.children.length - 1
          ];
          try {
            let componentR = await frame.getObjectByName("component_r");
            if (!componentR) {
              this.LoadFrame(frameObject);
              return;
            }

            componentR.getWorldQuaternion(component.quaternion);
            component.updateMatrixWorld();

            let componentL = component.getObjectByName("component_l");

            component.rotateX(-componentL.rotation.x);
            component.rotateY(-componentL.rotation.y);
            component.rotateZ(-componentL.rotation.z);

            component.updateMatrixWorld();

            //Position
            let componentRWP = new Vector3();
            componentR.getWorldPosition(componentRWP);
            let componentLWP = new Vector3();
            componentL.getWorldPosition(componentLWP);

            component.position.add(componentRWP);
            component.position.add(componentLWP.negate());

            component.updateMatrixWorld();

            component.remove(componentL);
          } catch (e) {
            console.error(e);
          }
        }

        component.name = id;
        this.modelFrame.add(component);
        this.modelFrame.updateMatrixWorld();
      }
    } else {
      let framePath =
        this.modelsPath + "frame-" + this.frameObject.id + ".gltf";
      let frameData = await this.LoadObject(framePath);

      frameData.scene.name = this.frameObject.id;
      this.modelFrame.add(frameData.scene);
      this.modelFrame.updateMatrixWorld();
    }

    await this.LoadArm(this.armObject);

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async LoadArm(armobject, updateCameraPosition = false) {
    this.modelArm.children = [];

    this.armObject = armobject;

    let armPath;
    let customName;

    for (const frame of this.modelFrame.children) {
      armPath = this.modelsPath + "side-" + this.armObject.id + ".gltf";

      customName = "side-" + this.armObject.id + "-" + frame.name + ".gltf";

      if (this.ModelExist(customName)) {
        armPath = this.modelsPath + customName;
      }

      if (frame.getObjectByName("side_l")) {
        let leftArm = await this.LoadObject(armPath);
        let leftSide = leftArm.scene;

        leftSide.rotation.copy(frame.rotation);
        leftSide.updateMatrixWorld();

        let offsetL = new Vector3();
        leftSide
          .getObjectByName("sockets")
          .getObjectByName("side_r")
          .getWorldPosition(offsetL);

        let frameSocketPositionL = new Vector3();
        frame
          .getObjectByName("sockets")
          .getObjectByName("side_l")
          .getWorldPosition(frameSocketPositionL);

        leftSide.position.copy(frameSocketPositionL);
        leftSide.position.add(offsetL.negate());

        leftSide.updateMatrixWorld();

        leftSide.name = "model-side-l";
        this.modelArm.add(leftSide);
      }

      if (frame.getObjectByName("side_r")) {
        if (
          frame.name === "27" ||
          frame.name === "52" ||
          frame.name === "54" ||
          frame.name === "72"
        ) {
          let rightArm = await this.LoadObject(armPath);
          let rightSide = rightArm.scene;

          rightSide.scale.set(-1, 1, 1);
          frame
            .getObjectByName("sockets")
            .getObjectByName("side_r")
            .getWorldQuaternion(rightSide.quaternion);
          rightSide.updateMatrixWorld();

          let offsetR = new Vector3();
          rightSide
            .getObjectByName("sockets")
            .getObjectByName("side_r")
            .getWorldPosition(offsetR);

          let frameSocketPositionR = new Vector3();
          frame
            .getObjectByName("sockets")
            .getObjectByName("side_r")
            .getWorldPosition(frameSocketPositionR);

          rightSide.position.copy(frameSocketPositionR);
          rightSide.position.add(
            new Vector3(-offsetR.x, -offsetR.y, -offsetR.z)
          );
          rightSide.updateMatrixWorld();

          rightSide.name = "model-side-r";
          this.modelArm.add(rightSide);
        } else {
          let rightArm = await this.LoadObject(armPath);
          let rightSide = rightArm.scene;

          rightSide.rotation.copy(frame.rotation);
          rightSide.updateMatrixWorld();

          let offsetR = new Vector3();
          rightSide
            .getObjectByName("sockets")
            .getObjectByName("side_r")
            .getWorldPosition(offsetR);

          let frameSocketPositionR = new Vector3();
          frame
            .getObjectByName("sockets")
            .getObjectByName("side_r")
            .getWorldPosition(frameSocketPositionR);

          rightSide.scale.set(-1, 1, 1);
          rightSide.position.copy(frameSocketPositionR);
          rightSide.position.add(
            new Vector3(offsetR.x, -offsetR.y, -offsetR.z)
          );

          rightSide.updateMatrixWorld();

          rightSide.name = "model-side-r";
          this.modelArm.add(rightSide);
        }
      }
    }

    if (this.modelFrame.children[0]?.name !== "20") {
      await this.LoadBack(this.backObject);
    }

    await this.ShowFramePins(
      this.framePin,
      `${themeConfiguration.model_url}/3d/textures/nail_finish/nail_small`,
      "Small"
    );
    await this.ShowSidePins(
      this.sidePin,
      `${themeConfiguration.model_url}/3d/textures/nail_finish/nail_small`,
      "Small"
    );

    await this.LoadSeat(this.seatObject);
    await this.LoadBase(this.baseObject);
    await this.LoadCushion();

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async LoadBack(backobject, updateCameraPosition = false) {
    this.modelBack.children = [];

    this.backObject = backobject;

    for (const frame of this.modelFrame.children) {
      let backPath;
      let customName;

      if (this.armObject.type) {
        customName =
          "back-" +
          this.backObject.id +
          "-" +
          this.armObject.type +
          "-" +
          frame.name +
          ".gltf";
        if (this.ModelExist(customName)) {
          backPath = this.modelsPath + customName;
        } else {
          backPath =
            this.modelsPath +
            "back-" +
            this.backObject.id +
            "-" +
            frame.name +
            ".gltf";
        }
      } else {
        backPath =
          this.modelsPath +
          "back-" +
          this.backObject.id +
          "-" +
          frame.name +
          ".gltf";
      }

      let backLoader = await this.LoadObject(backPath);
      let back = backLoader.scene;

      back.rotation.copy(frame.rotation);
      back.updateMatrixWorld();

      let offset = new Vector3();
      back
        .getObjectByName("sockets")
        .getObjectByName("back_pillow")
        .getWorldPosition(offset);

      let frameSocketPosition = new Vector3();
      frame
        .getObjectByName("sockets")
        .getObjectByName("back_pillow")
        .getWorldPosition(frameSocketPosition);

      back.position.copy(frameSocketPosition);
      back.position.add(offset.negate());

      back.updateMatrixWorld();

      back.name = "model-back";
      this.modelBack.add(back);
    }

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async LoadSeat(seatobject, updateCameraPosition = false) {
    this.modelSeat.children = [];

    this.seatObject = seatobject;

    for (const frame of this.modelFrame.children) {
      let seatPath;
      let customName;

      if (this.armObject.type) {
        customName = "seat-" + this.armObject.type + "-" + frame.name + ".gltf";
        if (this.ModelExist(customName)) {
          seatPath = this.modelsPath + customName;
        } else {
          seatPath = this.modelsPath + "seat-" + frame.name + ".gltf";
        }
      } else {
        seatPath = this.modelsPath + "seat-" + frame.name + ".gltf";
      }

      var seatLoader = await this.LoadObject(seatPath);
      let seat = seatLoader.scene;

      frame
        .getObjectByName("sockets")
        .getObjectByName("seat_pillow")
        .getWorldQuaternion(seat.quaternion);

      seat.updateMatrixWorld();

      let offset = new Vector3();
      seat
        .getObjectByName("sockets")
        .getObjectByName("seat_pillow")
        .getWorldPosition(offset);

      let frameSocketPosition = new Vector3();
      frame
        .getObjectByName("sockets")
        .getObjectByName("seat_pillow")
        .getWorldPosition(frameSocketPosition);

      seat.position.copy(frameSocketPosition);
      seat.position.add(offset.negate());

      seat.updateMatrixWorld();

      seat.name = "model-back";
      this.modelSeat.add(seat);
    }

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async LoadBase(baseobject, updateCameraPosition = false) {
    this.modelBase.children = [];

    this.baseObject = baseobject;

    let basePath;

    if (this.baseObject.type === "leg") {
      basePath = this.modelsPath + "leg-" + this.baseObject.id + ".gltf";

      let legLoader = await this.LoadObject(basePath);
      let leg = legLoader.scene;

      for (const arm of this.modelArm.children) {
        let sockets = arm.getObjectByName("sockets");
        for (const socket of sockets.children) {
          if (socket.name.startsWith("leg")) {
            let newLeg = leg.clone();

            let offset = newLeg
              .getObjectByName("sockets")
              .getObjectByName("leg_b").position;

            socket.getWorldPosition(newLeg.position);

            newLeg.position.add(offset.negate());
            newLeg.updateMatrixWorld();

            newLeg.rotation.copy(socket.rotation);
            newLeg.updateMatrixWorld();

            this.modelBase.add(newLeg);
          }
        }
      }
      for (const frame of this.modelFrame.children) {
        let sockets = frame.getObjectByName("sockets");
        for (const socket of sockets.children) {
          if (socket.name.startsWith("leg")) {
            let newLeg = leg.clone();

            let offset = newLeg
              .getObjectByName("sockets")
              .getObjectByName("leg_b").position;

            socket.getWorldPosition(newLeg.position);

            newLeg.position.add(offset.negate());
            newLeg.updateMatrixWorld();

            newLeg.rotation.copy(socket.rotation);
            newLeg.updateMatrixWorld();

            this.modelBase.add(newLeg);
          }
        }
      }
    } else if (this.baseObject.type === "skirt") {
      for (const frame of this.modelFrame.children) {
        basePath =
          this.modelsPath +
          "leg-" +
          this.baseObject.id +
          "-" +
          frame.name +
          ".gltf";

        let skirtLoader = await this.LoadObject(basePath);
        let skirt = skirtLoader.scene;

        skirt.rotation.copy(frame.rotation);
        skirt.updateMatrixWorld();

        let offset = new Vector3();
        skirt
          .getObjectByName("sockets")
          .getObjectByName("skirt_base")
          .getWorldPosition(offset);

        let frameSkirtSocket = new Vector3();
        frame
          .getObjectByName("sockets")
          .getObjectByName("skirt_base")
          .getWorldPosition(frameSkirtSocket);

        skirt.position.copy(frameSkirtSocket);
        skirt.position.add(offset.negate());

        skirt.updateMatrixWorld();

        skirt.name = "model-skirt";
        this.modelBase.add(skirt);
      }
    }

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async LoadCushion() {
    this.modelCushion.children = [];

    if (!this.cushionEnabled) {
      return;
    }

    for (const frame of this.modelFrame.children) {
      if (frame.getObjectByName("cushion_l")) {
        let cushionPath = this.modelsPath + "cushion-left.gltf";
        let cushionLoader = await this.LoadObject(cushionPath);

        let cushion = cushionLoader.scene;

        frame
          .getObjectByName("sockets")
          .getObjectByName("cushion_l")
          .getWorldQuaternion(cushion.quaternion);
        cushion.updateMatrixWorld();

        let offset = new Vector3();
        cushion
          .getObjectByName("sockets")
          .getObjectByName("cushion_base")
          .getWorldPosition(offset);

        let frameSocketPosition = new Vector3();
        frame
          .getObjectByName("sockets")
          .getObjectByName("cushion_l")
          .getWorldPosition(frameSocketPosition);

        cushion.position.copy(frameSocketPosition);
        cushion.position.add(offset.negate());

        cushion.updateMatrixWorld();
        this.modelCushion.add(cushion);
      }

      if (frame.getObjectByName("cushion_r")) {
        let cushionPath = this.modelsPath + "cushion-right.gltf";
        let cushionLoader = await this.LoadObject(cushionPath);

        let cushion = cushionLoader.scene;

        frame
          .getObjectByName("sockets")
          .getObjectByName("cushion_r")
          .getWorldQuaternion(cushion.quaternion);
        cushion.updateMatrixWorld();

        let offset = new Vector3();
        cushion
          .getObjectByName("sockets")
          .getObjectByName("cushion_base")
          .getWorldPosition(offset);

        let frameSocketPosition = new Vector3();
        frame
          .getObjectByName("sockets")
          .getObjectByName("cushion_r")
          .getWorldPosition(frameSocketPosition);

        cushion.position.copy(frameSocketPosition);
        cushion.position.add(offset.negate());

        cushion.updateMatrixWorld();
        this.modelCushion.add(cushion);
      }
    }
  }

  LoadTexture(url) {
    return new Promise((resolve) => {
      this.textureLoader.load(url, (result) => resolve(result));
    });
  }

  async LoadFinish(
    type, // nail_finish
    map,
    icon,
    name,
    repeat = 1,
    roughness,
    metalness,
    updateCameraPosition = false
  ) {
    // if (type === "main" && !this.init) {
    //   this.mainFabricCounter += 1;
    // }
    // if (type === "main" && this.mainFabricCounter > 1) {
    //   this.mainFabricCounter = 0;
    //   this.init = true;
    //   return;
    // }

    let newMap = null;
    if (map) {
      var finishLoader = await this.LoadTexture(map);

      newMap = finishLoader;
      newMap.wrapS = RepeatWrapping;
      newMap.wrapT = RepeatWrapping;
      newMap.repeat.set(repeat, repeat);
    }

    let materialObject = {
      type: type,
      map: newMap,
      icon: icon,
      name: name,
      roughness: roughness || null,
      metalness: metalness || null,
    };

    let found = false;
    this.materials.forEach((material) => {
      if (material.type === type) {
        found = true;

        material.map = newMap;
        material.icon = icon;
        material.name = name;

        material.roughness = roughness || null;
        material.metalness = metalness || null;
      }
    });

    if (!found) {
      this.materials.push(materialObject);
    }

    this.updateMap(this.model);

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  setCushionsVisible(newState) {
    this.cushionEnabled = newState;
    this.LoadCushion();
  }

  setPlaneVisible(newState) {
    if (this.plane) {
      this.plane.visible = newState;
    }
  }

  setNailsVisible(newState) {
    this.nailsEnabled = newState;
    this.ShowFramePins(this.framePin);
    this.ShowSidePins(this.sidePin);
  }

  async setDimensions(productDimensions) {
    this.productDimensions = productDimensions;
  }

  async setDimensionsVisible(newState) {
    this.dimensionsToggle = newState;

    if (this.dimensionsToggle) {
      await this.ShowDimensions();
    } else {
      await this.HideDimensions();
    }
  }

  async HideDimensions() {
    this.modelDimensions.children = [];
  }

  async ShowDimensions() {
    await this.HideDimensions();

    let box = new Box3().setFromObject(this.model);

    let lineMaterial = new LineBasicMaterial({
      color: 0x000000,
    });

    let lineOffset = 0.02;

    let x = [];
    x.push(
      new Vector3(box.min.x, box.max.y + lineOffset, box.min.z - lineOffset)
    );
    x.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.min.z - lineOffset
      )
    );

    let y = [];
    y.push(
      new Vector3(box.max.x + lineOffset, box.min.y, box.max.z + lineOffset)
    );
    y.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.max.z + lineOffset
      )
    );

    let z = [];
    z.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.max.z + lineOffset
      )
    );
    z.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.min.z - lineOffset
      )
    );

    let geometryX = new BufferGeometry().setFromPoints(x);
    let geometryY = new BufferGeometry().setFromPoints(y);
    let geometryZ = new BufferGeometry().setFromPoints(z);

    let lineX = new Line(geometryX, lineMaterial);
    lineX.name = "lineX";
    let lineY = new Line(geometryY, lineMaterial);
    lineY.name = "lineY";
    let lineZ = new Line(geometryZ, lineMaterial);
    lineZ.name = "lineZ";

    lineX.computeLineDistances();

    this.modelDimensions.add(lineX);
    this.modelDimensions.add(lineY);
    this.modelDimensions.add(lineZ);

    let swapDimensions = false;

    let boxWidth = box.max.x - box.min.x;
    let boxHeight = box.max.y - box.min.y;
    let boxDepth = box.max.z - box.min.z;

    if (
      (boxWidth < boxDepth &&
        Number(this.productDimensions.width) >
          Number(this.productDimensions.depth)) ||
      (boxWidth > boxDepth &&
        Number(this.productDimensions.width) <
          Number(this.productDimensions.depth))
    ) {
      swapDimensions = true;
    }

    let distanceX = swapDimensions
      ? this.productDimensions.depth
      : this.productDimensions.width;
    let distanceY = this.productDimensions.height;
    let distanceZ = swapDimensions
      ? this.productDimensions.width
      : this.productDimensions.depth;

    const fontLoader = new FontLoader();

    fontLoader.load(
      `${themeConfiguration.model_url}/3d/fonts/helvetiker_regular.typeface.json`,
      (font) => {
        const fontGeometryX = new TextGeometry(distanceX.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });

        fontGeometryX.translate(-0.1, 0, 0);

        let fontMeshX = new Mesh(
          fontGeometryX,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        let fontOffset = 0.02;

        fontMeshX.position.copy(
          new Vector3(
            box.min.x + (box.max.x - box.min.x) / 2,
            box.max.y + fontOffset + lineOffset,
            box.min.z
          )
        );
        fontMeshX.updateMatrixWorld();

        fontMeshX.name = "fontMeshX";
        this.modelDimensions.add(fontMeshX);

        const fontGeometryY = new TextGeometry(distanceY.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });

        fontGeometryY.translate(0.05, 0, 0);

        let fontMeshY = new Mesh(
          fontGeometryY,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        fontMeshY.rotateY(-Math.PI / 2);
        fontMeshY.position.copy(
          new Vector3(
            box.max.x,
            box.min.y + (box.max.y - box.min.y) / 2,
            box.max.z
          )
        );
        fontMeshY.updateMatrixWorld();

        fontMeshY.name = "fontMeshY";
        this.modelDimensions.add(fontMeshY);

        const fontGeometryZ = new TextGeometry(distanceZ.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });
        fontGeometryZ.translate(-0.05, 0, 0);

        let fontMeshZ = new Mesh(
          fontGeometryZ,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        fontMeshZ.rotateY(-Math.PI / 2);
        fontMeshZ.position.copy(
          new Vector3(
            box.max.x,
            box.max.y + fontOffset + lineOffset,
            box.min.z + (box.max.z - box.min.z) / 2
          )
        );
        fontMeshZ.updateMatrixWorld();

        fontMeshZ.name = "fontMeshZ";
        this.modelDimensions.add(fontMeshZ);
      }
    );
  }

  async ShowFramePins(pinsobject) {
    this.framePin = pinsobject;
    for (const frame of this.modelFrame.children) {
      let smallPins = frame.getObjectByName("small_pins");
      let mediumPins = frame.getObjectByName("medium_pins");
      let largePins = frame.getObjectByName("large_pins");

      if (smallPins) smallPins.visible = false;
      if (mediumPins) mediumPins.visible = false;
      if (largePins) largePins.visible = false;

      if (frame.getObjectByName(pinsobject) && this.nailsEnabled)
        frame.getObjectByName(pinsobject).visible = true;
    }

    for (const arm of this.modelArm.children) {
      let smallPins = arm.getObjectByName("small_pins");
      let mediumPins = arm.getObjectByName("medium_pins");
      let largePins = arm.getObjectByName("large_pins");

      if (smallPins) smallPins.visible = false;
      if (mediumPins) mediumPins.visible = false;
      if (largePins) largePins.visible = false;

      if (arm.getObjectByName(pinsobject) && this.nailsEnabled)
        arm.getObjectByName(pinsobject).visible = true;
    }
  }

  async ShowSidePins(pinsobject) {
    this.sidePin = pinsobject;
    for (const arm of this.modelArm.children) {
      let sidePins = arm.getObjectByName("side_pins");

      if (sidePins) sidePins.visible = false;

      if (arm.getObjectByName(pinsobject) && this.nailsEnabled)
        arm.getObjectByName(pinsobject).visible = true;
    }
  }

  degreesToRadians = (degrees) => {
    var pi = Math.PI;
    return degrees * (pi / 180);
  };

  determinCameraDistance = (modelSize) => {
    let cameraDistance;
    let halfFOVInRadians = this.degreesToRadians(
      (this.camera.fov * this.camera.aspect) / 4
    );
    let height = modelSize.height;
    cameraDistance = height / 2 / Math.tan(halfFOVInRadians);
    return cameraDistance;
  };

  updateZoom(zoom, diff = 0) {
    let newZoom = 1 + (zoom + diff) / 10;

    if (
      newZoom >= 1 + zoomOptions.min / 10 &&
      newZoom <= 1 + zoomOptions.max / 10
    ) {
      this.controls.object.zoom = newZoom;
      this.controls.object.updateProjectionMatrix();
      return {
        ...zoomOptions,
        current: zoom + diff,
      };
    } else {
      return { ...zoomOptions, current: zoom };
    }
  }

  getCameraAngle = () => {
    const euler = new Euler();
    const rotation = euler.setFromQuaternion(this.camera.quaternion);
    const radians = rotation._z > 0 ? rotation._z : 2 * Math.PI + rotation._z;
    return radians * (180 / Math.PI);
  };

  async UpdateCameraPosition() {
    let box = new Box3().setFromObject(this.model);
    let size = box.getSize(new Vector3()).length();
    let center = box.getCenter(new Vector3());

    if (this.plane) {
      this.plane.position.y = box.min.y;
      this.shadowPlane.position.y = box.min.y;
    }

    this.controls.minDistance = size * 0.75;
    this.controls.maxDistance = size * 1.5;

    this.camera.position.copy(center);
    this.camera.position.x += 0; //this.size * 0.1;
    this.camera.position.y += size * 0.2;
    this.camera.position.z += size * 1.3;
    this.camera.updateMatrixWorld();

    this.controls.target = center;
    this.camera.lookAt(center);

    this.camera.updateProjectionMatrix();

    if (this.dimensionsToggle) {
      await this.ShowDimensions();
    }

    if (document.getElementById("loading-screen"))
      document.getElementById("loading-screen").classList.add("fade-out");
  }

  updateEnvMap() {
    var object = this.scene.getObjectByName("model");

    object.traverse((o) => {
      if (o.isMesh) {
        o.castShadow = true;
        o.receiveShadow = true;
        if (this.envMap !== null) {
          o.material.envMap = this.envMap;
          o.material.envMapIntensity = this.envMapIntensity;
        }

        o.material.needsUpdate = true;
      }
    });
  }

  updateMap(object) {
    object.traverse((o) => {
      if (o.isMesh) {
        o.castShadow = true;
        o.receiveShadow = true;

        let found = false;
        this.materials.forEach((material) => {
          if (material.type === o.material.name) {
            found = true;
            if (material.map) o.material.map = material.map;
            if (material.roughness) o.material.roughness = material.roughness;
            if (material.metalness) o.material.metalness = material.metalness;
          }
        });

        if (!found && this.materials.length !== 0) {
          if (this?.materials?.[0]?.map) o.material.map = this.materials[0].map;
          if (this?.materials?.[0]?.roughness && o?.materials?.roughness)
            o.materials.roughness = this.materials?.[0]?.roughness;
          if (this?.materials?.[0]?.metalness && o?.materials?.metalness)
            o.material.metalness = this.materials?.[0]?.metalness;
        }

        if (this.envMap) {
          o.material.envMap = this.envMap;
          o.material.envMapIntensity = this.envMapIntensity;
        }

        if (o.material) {
          if (o.material.map) {
            o.material.map.encoding = sRGBEncoding;
          }

          if (o.material.ao) {
            o.material.ao.encoding = sRGBEncoding;
          }
        }

        o.material.needsUpdate = true;
      }
    });
  }

  update() {
    requestAnimationFrame(this.update);
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    if (this.dimensionsToggle) {
      let fontMeshX = this.modelDimensions.getObjectByName("fontMeshX");
      let fontMeshY = this.modelDimensions.getObjectByName("fontMeshY");
      let fontMeshZ = this.modelDimensions.getObjectByName("fontMeshZ");
      fontMeshX && fontMeshX.lookAt(this.camera.position);
      fontMeshY && fontMeshY.lookAt(this.camera.position);
      fontMeshZ && fontMeshZ.lookAt(this.camera.position);
    }
    this.cameraAngle = this.getCameraAngle();
  }

  ModelExist(modelName) {
    return HavenModels.includes(modelName);
  }

  async FileExist(urlToFile) {
    var XMLHttpRequestPromise = require("xhr-promise");
    let xhr = new XMLHttpRequestPromise();
    return xhr
      .send({ method: "HEAD", url: urlToFile })
      .then(function (results) {
        if (
          results.status !== 200 ||
          results.headers["content-type"].indexOf("text") !== -1
        ) {
          throw new Error("request failed");
        } else {
          return true;
        }
      })
      .catch(function (e) {
        return false;
      });
  }
  getCameraPosition() {
    return {
      x: this.camera.position.x,
      y: this.camera.position.y,
      z: this.camera.position.z,
    };
  }
  setCameraPosition({ x = 50, y = -1, z = 50 }) {
    this.camera.position.x = x;
    this.camera.position.y = y;
    this.camera.position.z = z;
  }
}
