export default {
  data: {
    compareItems: {
      guestIndex: null,
      customerId: null,
      items: [],
      totalItems: 0,
    },
  },
  open: false,
  loading: false,
};
