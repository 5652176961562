import React from "react";
import "./imageContainer.scss";

const ImageContainer = props => {
  return (
    <>
      {(props.imgContainer && (
        <div className={props.imgContainer} src={props.src}>
          {props.children}
        </div>
      )) ||
        props.children}
    </>
  );
};

export default ImageContainer;