// @ts-check
// @ts-ignore
import Icon from "app/assets/icon/icon";
import {
  CustomerToken,
  CustomizableOptionInterface,
  DomainProductFragment,
} from "app/generated/graphql";
import Breadcrumbs from "app/layout/breadcrumbs";
import useCustomer from "app/state/hooks/customer/useCustomer";
// import Price from "core/components/price";
import {
  MDBBox,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBLink,
  MDBRow,
  MDBTooltip,
  MDBTypography,
} from "mdbreact";
import React, { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { DownloadImage } from "./utils";
import {
  useFavorites,
  useToggleFavoriteProductParam,
} from "../../../../collections/hooks";
import { ProductWithSku } from "../../../../collections/models";
import CollectionsContext from "../../../../../layout/collections/CollectionsContext/CollectionsContext";
import Button from "core/components/button";

const Toolbar: React.FC<
  Pick<
    DomainProductFragment,
    "id" | "name" | "sku" | "image" | "price_range"
  > & {
    customer: CustomerToken["token"];
    addToCart: () => void;
    isAddingToCart: boolean;
    options: Array<CustomizableOptionInterface>;
    fabricPDP: boolean;
    isCustom: boolean;
    productData: ProductWithSku;
    calculateOptions: () => Array<{}>;
  }
> = ({ name, productData, sku, calculateOptions, image }) => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  // let price = price_range?.minimum_price?.final_price?.value || false;
  const favorites = useFavorites();
  const customer = useCustomer().isLoggedIn();
  const toggleFavorite = useToggleFavoriteProductParam();

  const { setSelectedProduct } = useContext(CollectionsContext);

  return (
    <MDBContainer fluid className={`fabric-toolbar`}>
      <MDBRow className="bg-gray-lighten-4 py-3 border-bottom px-lg-5 px-4 border-default toolbar-wrapper">
        <MDBCol className="px-lg-5">
          <MDBContainer fluid className="px-lg-5">
            <MDBRow>
              <MDBCol size="12">
                <Breadcrumbs wrapperClass={"d-none d-lg-block"} />
              </MDBCol>
              <MDBCol size="12" lg="7">
                <MDBRow>
                  <MDBCol size="12" className="d-flex align-items-center mb-2">
                    <MDBTypography tag="h2" className="header-1 ">
                      {name}
                    </MDBTypography>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12" className="d-flex align-items-center">
                    <MDBTypography tag="h2" className="body-2 ">
                      {sku /* @TODO: FIX THIS */}
                    </MDBTypography>
                    {isMobile ? (
                      <MDBCol
                        onClick={() => {
                          toggleFavorite(productData, calculateOptions());
                        }}
                      >
                        {favorites?.items?.find(
                          (favorite) =>
                            favorite?.product?.sku === productData?.sku
                        ) ? (
                          <Icon icon={"heartFull"} />
                        ) : (
                          <Icon icon={"heartGray"} />
                        )}
                      </MDBCol>
                    ) : (
                      <></>
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol
                size="12"
                lg="5"
                className="mt-4 mt-lg-0 pt-md-4 pt-lg-0 d-flex flex-md-row align-items-lg-center align-items-start price-and-download-wrapper justify-content-start justify-content-lg-end"
              >
                {/* {price && showPrice && (
                  <div className="price-container">
                    <MDBTypography tag="h2" className="text-default">
                      <Price price={price} type={"fixed"} />
                    </MDBTypography>
                    <MDBTypography tag="h5" className="text-default ml-2">
                      Per Yard
                    </MDBTypography>
                  </div>
                )} */}
                {!isMobile ? (
                  <MDBBtn
                    className="ml-4 m-0"
                    size="sm"
                    color="secondary"
                    onClick={() => {
                      // let addToFavoritesNotLogedIn = JSON.stringify({
                      //   title: "Add to Favorites",
                      //   productName: name,
                      //   productImage: image,
                      // });
                      // window.localStorage.setItem(
                      //   "favorites",
                      //   addToFavoritesNotLogedIn
                      // );
                      toggleFavorite(productData, calculateOptions());
                      setSelectedProduct(productData);
                    }}
                  >
                    {favorites?.items?.find(
                      (favorite) => favorite?.product?.sku === productData?.sku
                    ) ? (
                      <Icon icon={"heartFull"} />
                    ) : (
                      <Icon icon={"heartGray"} />
                    )}
                  </MDBBtn>
                ) : (
                  <></>
                )}
                <div className="tooltip-container mt-3 mt-md-0">
                  {!isMobile ? (
                    <>
                      <MDBCol
                        size="12"
                        lg="12"
                        className="tooltip-container d-flex align-items-center justify-content-center"
                      >
                        <MDBTooltip
                          className="tooltip-pdp"
                          placement="top"
                          clickable
                        >
                          <Button className={`btn-secondary btn w-100 ml-4`}>
                            Downloads
                          </Button>

                          <span>
                            <MDBRow className="w-100 m-0 py-2 px-2">
                              <MDBCol
                                size="6"
                                lg="12"
                                className="px-lg-0 py-1 "
                              >
                                <MDBBox>
                                  <MDBTypography
                                    tag="h2"
                                    className="ml-1 d-flex align-items-center link-text-3"
                                  >
                                    <Icon icon="downloadSmall" />
                                    <MDBLink
                                      className="d-inline-block ml-1 p-0 link-text-3"
                                      onClick={() => {
                                        if (image?.url)
                                          DownloadImage(image.url);
                                      }}
                                    >
                                      Image
                                    </MDBLink>
                                  </MDBTypography>
                                </MDBBox>
                              </MDBCol>
                            </MDBRow>
                          </span>
                        </MDBTooltip>
                      </MDBCol>
                    </>
                  ) : (
                    <MDBRow className="w-100 d-flex justify-content-between m-0 px-md-4">
                      <MDBBox className="px-0 d-flex py-1 justify-content-right">
                        <Icon icon="downloadSmall" />
                        <MDBLink
                          className="d-inline-block p-0 link-text-3 ml-2"
                          onClick={() => {
                            if (image?.url) {
                              DownloadImage(image.url);
                            }
                          }}
                        >
                          Image
                        </MDBLink>
                      </MDBBox>
                    </MDBRow>
                  )}
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default React.memo(Toolbar);
