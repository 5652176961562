import React from "react";
import Input from "./input";
// import { isUndefined } from "../../state/helper/functions";

const InputStateContainer = props => {
  let validator = props.validator;
  let validateFlag = props.validate;

  let useWrapper = props.usewrapper;
  let inputName = props.name;

  let wrapperClass =
    typeof props.wrapperClass !== "undefined" ? " " + props.wrapperClass : "";

  if (typeof useWrapper === "undefined" || useWrapper === true) {
    return (
      <>
        <div className={`input-wrapper${wrapperClass}`}>
          <div
            className={`input ${(props.className && props.className) ||
              ""} ${(validator &&
              validator.errorMessages[inputName] &&
              "input-validation-error" &&
              validateFlag &&
              "input-validation-error") ||
              ""}`}
          >
            <Input {...props} />
          </div>
          {/* {!isUndefined(validator) && (
            <div
              className={`validation-message validation-message-${inputName}`}
            >
              {validator.errorMessages[inputName] &&
                validateFlag &&
                validator.errorMessages[inputName]}
            </div>
          )} */}
        </div>
      </>
    );
  }
  return <Input {...props} />;
};

export default InputStateContainer;
