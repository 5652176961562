import { Country, Customer, Maybe } from "app/generated/graphql";
import { customerPaths } from "app/pages/customer/routes";
import { MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";
import AddressesSubtitle from "../../components/AddressesSubtitle";
import AddressOverview from "../../components/AddressOverview";
import Title from "../../components/Title";
import { Address } from "../../models";
import { addCountryNameToAddress } from "../../utils";
import SalesRap from "./SalesRep";

export interface AccountProps
  extends Pick<
    Customer,
    "firstname" | "lastname" | "email" | "sign_up_for_updates" | "is_subscribed"
  > {
  addresses: Customer["addresses"];
  countries: Country[];
  salesRapData: any;
}

const Account: React.FC<AccountProps> = (props) => {
  const billingAddress: Maybe<Address> = React.useMemo(() => {
    const address =
      props?.addresses?.find((address) => address?.default_billing) ?? null;
    return address && addCountryNameToAddress(props.countries)(address);
  }, [props]);
  const shippingAddress: Maybe<Address> = React.useMemo(() => {
    const address =
      props?.addresses?.find((address) => address?.default_shipping) ?? null;
    return address && addCountryNameToAddress(props.countries)(address);
  }, [props]);

  return (
    <>
      <Title>Account Dashboard</Title>
      <MDBTypography tag="h3" className="mb-5">
        Account Information
      </MDBTypography>
      <MDBRow>
        <MDBCol size="12" lg="6" className="mb-5 pb-5">
          <MDBTypography tag="h4" className="mb-4 pb-2">
            Contact Information
          </MDBTypography>
          {/* @ts-ignore */}
          <MDBTypography className="mb-3" variant="body-6">
            {props.firstname} {props.lastname}
          </MDBTypography>
          {/* @ts-ignore */}
          <MDBTypography variant="body-6" className="mb-4 pb-2">
            {props.email}
          </MDBTypography>
          <MDBRow>
            <MDBCol className="d-flex">
              <Link
                to={customerPaths.children.account.children.info.path}
                className="mr-5 pr-5"
              >
                <MDBTypography
                  // @ts-ignore
                  variant="body-1"
                  className="d-inline-block underline mr-5"
                >
                  Edit
                </MDBTypography>
              </Link>
              <Link
                to={{
                  pathname: "/customer/account/info",
                  state: { changePassword: true },
                }}
              >
                {/* @ts-ignore */}
                <MDBTypography variant="body-1" className="underline">
                  Change Password
                </MDBTypography>
              </Link>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol size="12" lg="6" className="mb-5 pb-5">
          <MDBTypography tag="h4" className="mb-4 pb-2">
            Newsletter Subscription
          </MDBTypography>
          {/* @ts-ignore */}
          <MDBTypography variant="body-1" className="mb-5">
            {props.is_subscribed
              ? "You are currently subscribed to our newsletter"
              : "You can subscribe to our newsletter below."}
          </MDBTypography>
          <Link to={customerPaths.children.account.children.newsletter.path}>
            <MDBTypography
              // @ts-ignore
              variant="body-1"
              className="d-inline-block underline"
            >
              Edit
            </MDBTypography>
          </Link>
        </MDBCol>
      </MDBRow>
      {props?.salesRapData ? <SalesRap data={props?.salesRapData} /> : null}
      <MDBRow>
        <MDBCol>
          <AddressesSubtitle />
          <MDBRow>
            {billingAddress && (
              <AddressOverview
                address={billingAddress}
                title="Default Billing Address"
                editLink={
                  customerPaths.children.account.children.addresses.children
                    .mailing.path
                }
              />
            )}
            {shippingAddress && (
              <AddressOverview
                address={shippingAddress}
                title="Default Shipping Address"
                editLink={
                  customerPaths.children.account.children.addresses.children
                    .shipping.path
                }
              />
            )}
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default Account;
