import { isEmpty } from "core/helpers/functions";

export const addFilter = (
  option,
  activeFilters,
  setActiveFilters,
  filterCode,
  filterType
) => {
  const isSwitchFilter = filterType === "boolean" ? true : false;
  const optionsSyntax = isSwitchFilter
    ? { eq: option.value }
    : { in: [option.value] };

  let optionsArray = null;
  if (isEmpty(activeFilters)) {
    return setActiveFilters({
      ...activeFilters,
      [filterCode]: optionsSyntax,
    });
  } else {
    let activeIndex = false;
    Object.keys(activeFilters).find((item, index) => {
      if (item === filterCode) {
        activeIndex = index;
      }
      return activeIndex;
    });
    if (activeIndex === false) {
      return setActiveFilters({
        ...activeFilters,
        [filterCode]: optionsSyntax,
      });
    } else {
      Object.keys(activeFilters).map((item, index) => {
        if (filterCode === item && !isSwitchFilter) {
          return (optionsArray = {
            [item]: {
              in: Object.values(Object.values(activeFilters)[index])[0].concat(
                option.value
              ),
            },
          });
        } else return false;
      });
    }
    return setActiveFilters({
      ...activeFilters,
      ...optionsArray,
    });
  }
};

export const removeFilter = (
  option,
  activeFilters,
  setActiveFilters,
  filterCode,
  filterType
) => {
  let isSwitchFilter = filterType === "boolean" ? true : false;

  let activeIndex = false;
  activeIndex = Object.keys(activeFilters).indexOf(filterCode);
  if (activeIndex === false) {
    return false;
  } else {
    let filteredArray = false;
    if (isSwitchFilter) {
      filteredArray = {
        [filterCode]: { eq: "" },
      };
    } else {
      filteredArray = Object.values(activeFilters)[activeIndex].in.filter(
        (item) => item !== option.value
      );
      filteredArray = {
        [filterCode]: { in: [...filteredArray] },
      };
    }

    return setActiveFilters({ ...activeFilters, ...filteredArray });
  }
};

export const isOptionActive = (option, activeFilters, filter) => {
  if (isEmpty(activeFilters)) {
    return false;
  } else {
    let activeFilterIndex = false;
    activeFilterIndex = Object.keys(activeFilters).indexOf(filter);
    if (activeFilterIndex !== -1) {
      let isOptionActive = false;
      Object.values(activeFilters)[activeFilterIndex].in.find((item) => {
        return (isOptionActive = item === option.value ? true : false);
      });
      return isOptionActive;
    }
  }
};
