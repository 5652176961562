// @ts-check

import findLast from "lodash/findLast";
import { createSelector } from "reselect";

/**
 * TODO turn type and target into enums
 * @typedef {{ text: string; type: string; target: string; closeTime: number; }} Message
 *
 * @param {{ messages: { items: Array<Message> } }} state
 */
export const selectMessages = ({ messages }) => messages;
export const selectMessageItems = createSelector(
  selectMessages,
  ({ items }) => items
);

export const selectLatestLoginMessage = createSelector(
  selectMessageItems,
  (messages) => findLast(messages, (message) => message.target === "login")
);

export const selectLatestLoginMessageText = createSelector(
  selectLatestLoginMessage,
  (message) => message?.text.replace("Error: GraphQL error: ", "").replace("GraphQL error: ", "") ?? ""
);
