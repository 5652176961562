// Action types

const ADD_TO_COMPARE = "jola/compare/ADD_TO_COMPARE";
const REMOVE_COMPARE = "jola/compare/REMOVE_COMPARE";
const CLEAR_COMPARE = "jola/compare/CLEAR_COMPARE";
const SET_COMPARE = "jola/compare/SET_COMPARE";
const SET_OPEN_STATE = "jola/compare/SET_OPEN_STATE";

export default {
  ADD_TO_COMPARE,
  REMOVE_COMPARE,
  CLEAR_COMPARE,
  SET_COMPARE,
  SET_OPEN_STATE,
};
