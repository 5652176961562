import { OrderItemData } from "app/generated/graphql";
import { createContext } from "react";

interface OrderItemDataRecord
  extends Record<
    NonNullable<OrderItemData["product_sku"]>,
    Pick<OrderItemData, "comment" | "document_names">
  > {}

export type DocumentName = NonNullable<
  NonNullable<OrderItemData["document_names"]>[number]
>;

export interface ICartContext {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  orderItemDataRecord: OrderItemDataRecord;
  setOrderItemDataRecord: (record: OrderItemDataRecord) => void;
}

export const initialCartContext: ICartContext = {
  isModalOpen: false,
  openModal: () => {},
  closeModal: () => {},
  orderItemDataRecord: {},
  setOrderItemDataRecord: () => {},
};

export default createContext(initialCartContext);
