import CmsBlock from "app/layout/cmsBlock";
import React from "react";
import { Link } from "react-router-dom";
import SearchResults from "../../../searchResults";
import { MDBBox } from "mdbreact";
const SearchBuildCollection = ({
  searchVal,
  toggleFn,
  category,
  searchResult,
}) => {
  return (
    <>
      {!searchVal.length > 0 ? (
        <MDBBox className="build-content">
          <div
            className="col-12 my-5 build-content-link"
            onClick={toggleFn}
            onKeyDown={toggleFn}
            role="button"
            tabIndex={0}
          >
            <Link
              to={"/collections/build-your-own"}
              className="btn-light-blue btn"
            >
              View Collection
            </Link>
          </div>
          <CmsBlock
            id="search_build_own"
            skeletonProps={{ height: "50rem", width: "100%" }}
          />
        </MDBBox>
      ) : (
        <SearchResults
          category={category}
          toggleFn={toggleFn}
          searchResult={searchResult}
        />
      )}
    </>
  );
};

export default SearchBuildCollection;
