import Icon from "app/assets/icon/icon";
import React from "react";
import { Link } from "react-router-dom";
import { EditSectionProps } from "../models";

export interface EditLinkProps extends Pick<EditSectionProps, "editLink"> {}

const EditLink: React.FC<EditLinkProps> = (props) => {
  return props.editLink ? (
    <Link
      to={
        typeof props?.editLink === "object"
          ? `${props?.editLink?.pathname}${props?.editLink?.search}`
          : "/" + props?.editLink
      }
    >
      <Icon icon="edit" />
    </Link>
  ) : (
    <div
      title="URL key not available for the given product"
      style={{ opacity: 0.5 }}
    >
      <Icon icon="edit" />
    </div>
  );
};

export default EditLink;
