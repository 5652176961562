// @ts-check
import {
  emailSchema,
  passwordMinLength,
  passwordSchema,
  phoneSchema,
  isEmptyRegex,
} from "app/pages/customer/config";
import * as yup from "yup";

/**
 *
 * @typedef {object} ContactInfoValues
 * @prop {string} firstName
 * @prop {string} lastName
 * @prop {string} phone
 * @prop {string} email
 * @prop {string} password
 * @prop {string} confirmPassword
 */

/**
 * @type {yup.ObjectSchema<ContactInfoValues>}
 */
export const contactInfoValidationSchema = yup
  .object()
  .required()
  .shape({
    firstName: yup
      .string()
      .required("First name is required.")
      .matches(isEmptyRegex, "First name is required."),
    lastName: yup
      .string()
      .required("Last name is required.")
      .matches(isEmptyRegex, "Last name is required."),
    phone: phoneSchema,
    email: emailSchema,
    password: passwordSchema,
    confirmPassword: yup
      .string()
      .min(passwordMinLength)
      .required("Please confirm your password")
      .oneOf([yup.ref("password")], "Passwords must match."),
  });

/**
 * @type {ContactInfoValues}
 */
export const contactInfoInitialValues = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  password: "",
  confirmPassword: "",
};
