// Customer Action types

const LOGIN_CUSTOMER = "jola/customer/LOGIN_CUSTOMER";
const LOGOUT_CUSTOMER = "jola/customer/LOGOUT_CUSTOMER";
const _REDUCE_CUSTOMER = "jola/customer/_REDUCE_CUSTOMER";
const REGISTER_B2C_CUSTOMER = "jola/customer/REGISTER_B2C_CUSTOMER";
const REGISTER_B2B_CUSTOMER = "jola/customer/REGISTER_B2B_CUSTOMER";
const REGISTER_CUSTOMER_SUCCESS = "jola/customer/REGISTER_CUSTOMER_SUCCESS";
const _SET_CUSTOMER_TOKEN = "jola/customer/_SET_CUSTOMER_TOKEN";
const UPDATE_CUSTOMER_INFORMATION = "jola/customer/UPDATE_CUSTOMER_INFORMATION";
const GET_CUSTOMER_INFORMATION = "jola/customer/GET_CUSTOMER_INFORMATION";
const SET_CUSTOMER_INFORMATION = "jola/customer/SET_CUSTOMER_INFORMATION";
const CREATE_EMPTY_CART = "jola/customer/CREATE_EMPTY_CART";
const SET_CART_TOKEN = "jola/customer/SET_CART_TOKEN";
const GET_CART_INFORMATION = "jola/customer/GET_CART_INFORMATION";
const SET_CART_INFORMATION = "jola/customer/SET_CART_INFORMATION";
const ADD_PRODUCT_TO_CART = "jola/customer/ADD_PRODUCT_TO_CART";
const REMOVE_PRODUCT_FROM_CART = "jola/customer/REMOVE_PRODUCT_FROM_CART";
const UPDATE_CART = "jola/customer/UPDATE_CART";
const CLEAR_CART = "jola/customer/CLEAR_CART";
const NEWSLETTER_CUSTOMER_SUBSCRIBE =
  "jola/customer/NEWSLETTER_CUSTOMER_SUBSCRIBE";
const CREATE_WISHLIST = "jola/customer/CREATE_WISHLIST";
const DELETE_WISHLIST = "jola/customer/DELETE_WISHLIST";
const RENAME_WISHLIST = "jola/customer/RENAME_WISHLIST";
const _REDUCE_WISHLIST = "jola/customer/_REDUCE_WISHLIST";
const ADD_PRODUCT_TO_WISHLIST = "jola/customer/ADD_PRODUCT_TO_WISHLIST";
const REMOVE_ITEM_FROM_WISHLIST = "jola/customer/REMOVE_ITEM_FROM_WISHLIST";
const COPY_WISHLIST_ITEM = "jola/customer/COPY_WISHLIST_ITEM";
const SHARE_WISHLIST = "jola/customer/SHARE_WISHLIST";
const EDIT_CUSTOMER_ADDRESS = "jola/customer/EDIT_CUSTOMER_ADDRESS";
const _REDUCE_CUSTOMER_ADDRESS = "jola/customer/_REDUCE_CUSTOMER_ADDRESS";
const CREATE_CUSTOMER_ADDRESS = "jola/customer/CREATE_CUSTOMER_ADDRESS";

// checkout setters
const SET_GUEST_EMAIL_ON_CART = "jola/customer/SET_GUEST_EMAIL_ON_CART";
const SET_BILLING_ADDRESS_ON_CART = "jola/customer/SET_BILLING_ADDRESS_ON_CART";
const SET_SHIPPING_ADDRESSES_ON_CART =
  "jola/customer/SET_SHIPPING_ADDRESSES_ON_CART";
const SET_SHIPPING_METHODS_ON_CART =
  "jola/customer/SET_SHIPPING_METHODS_ON_CART";
const FORGOT_CUSTOMER_PASSWORD = "jola/customer/FORGOT_CUSTOMER_PASSWORD";
const RESET_CUSTOMER_PASSWORD = "jola/customer/RESET_PASSWORD";
const RESET_CUSTOMER_PASSWORD_SUCCESS = "jola/customer/RESET_PASSWORD_SUCCESS";
const SET_PAYMENT_METHOD_ON_CART = "jola/customer/SET_PAYMENT_METHOD_ON_CART";

// checkout action
const PLACE_ORDER = "jola/customer/PLACE_ORDER";
const PLACE_ORDER_SUCCESS = "jola/customer/PLACE_ORDER_SUCCESS";
const APPLY_COUPON_TO_CART = "jola/customer/APPLY_COUPON_TO_CART";
const REMOVE_COUPON_FROM_CART = "jola/csutomer/REMOVE_COUPON_FROM_CART";
const UPDATE_ITEM_REDUCER = "jola/customer/UPDATE_ITEM_REDUCER";
const SET_PO_NUMBER = "jola/customer/SET_PO_NUMBER";
const SET_ORDER_NOTES = "jola/customer/SET_ORDER_NOTES";

export default {
  LOGIN_CUSTOMER,
  LOGOUT_CUSTOMER,
  _REDUCE_CUSTOMER,
  REGISTER_B2C_CUSTOMER,
  REGISTER_B2B_CUSTOMER,
  REGISTER_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_INFORMATION,
  _SET_CUSTOMER_TOKEN,
  GET_CUSTOMER_INFORMATION,
  SET_CUSTOMER_INFORMATION,
  CREATE_EMPTY_CART,
  SET_CART_TOKEN,
  GET_CART_INFORMATION,
  SET_CART_INFORMATION,
  ADD_PRODUCT_TO_CART,
  REMOVE_PRODUCT_FROM_CART,
  UPDATE_CART,
  CLEAR_CART,
  NEWSLETTER_CUSTOMER_SUBSCRIBE,
  CREATE_WISHLIST,
  DELETE_WISHLIST,
  RENAME_WISHLIST,
  _REDUCE_WISHLIST,
  ADD_PRODUCT_TO_WISHLIST,
  REMOVE_ITEM_FROM_WISHLIST,
  COPY_WISHLIST_ITEM,
  SET_GUEST_EMAIL_ON_CART,
  SET_BILLING_ADDRESS_ON_CART,
  SET_SHIPPING_ADDRESSES_ON_CART,
  SET_SHIPPING_METHODS_ON_CART,
  SHARE_WISHLIST,
  EDIT_CUSTOMER_ADDRESS,
  _REDUCE_CUSTOMER_ADDRESS,
  CREATE_CUSTOMER_ADDRESS,
  FORGOT_CUSTOMER_PASSWORD,
  RESET_CUSTOMER_PASSWORD,
  RESET_CUSTOMER_PASSWORD_SUCCESS,
  PLACE_ORDER,
  SET_PAYMENT_METHOD_ON_CART,
  PLACE_ORDER_SUCCESS,
  APPLY_COUPON_TO_CART,
  REMOVE_COUPON_FROM_CART,
  UPDATE_ITEM_REDUCER,
  SET_PO_NUMBER,
  SET_ORDER_NOTES,
};
