import CoreSelect from "core/components/select";
import React from "react";

/**
 * @typedef {React.ComponentProps<typeof CoreSelect>} SelectProps
 */
/**
 * @type {React.FC<SelectProps>}
 */
const Select = (props) => {
  return (
    <CoreSelect
      {...props}
      styles={{
        ...props.styles,
        control: (base, state) => ({
          ...base,
          boxShadow: "none",
          borderColor: "inherit",
          opacity: state.isFocused && !state.isDisabled ? 1 : 0.5,
          ...props.styles?.control,
        }),
      }}
    />
  );
};

export default Select;
