import { MDBRow, MDBCol, MDBTypography, MDBBtn, MDBBox } from "mdbreact";
import Logo from "app/layout/logo";
import React from "react";

export interface EmptyWishlistProps {}

const EmptyWishlist: React.FC<EmptyWishlistProps> = (props) => {
  let pathName = window.location.pathname;
  return (
    <MDBCol className="empty-wishlist mt-5">
      <MDBBox className={`empty-wishlist-inner bg-gray-lighten-4 py-100`}>
        <MDBRow className="mb-5">
          <MDBCol className="d-flex align-items-center justify-content-center logo-wrapper">
            <Logo type={"desktop"} />
          </MDBCol>
        </MDBRow>
        <MDBRow className="mb-5">
          <MDBCol className="d-flex align-items-center justify-content-center">
            <MDBTypography
              tag="h2"
              variant="display-2"
              className="text-center fwlight"
            >
              {`Looks like you haven’t added any products to your ${
                (pathName.includes("saved") && "Saved Builds") ||
                (pathName.includes("projects") && "Project") ||
                "Favorites"
              } yet.`}
            </MDBTypography>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="d-flex align-items-center justify-content-center">
            <MDBBtn href={`/products`}>Start Browsing</MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBBox>
    </MDBCol>
  );
};

export default EmptyWishlist;
