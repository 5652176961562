import React, { useState, useMemo, useEffect } from "react";
import AccountCustomerOrders from "./CustomerOrders";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { CUSTOMER_QUERY, GET_ORDER_PDF } from "./query";
import { isEqual } from "lodash";

export type CustomerOrdersDataContainer = {};

const CustomerOrdersDataContainer: React.FC<CustomerOrdersDataContainer> = (
  props
) => {
  const [currentPage, setCurrentPage] = useState(1);
  let [pageSize, setPageSize] = useState(10);
  const [sortData, setSortData] = useState({
    sorting_attribute: "",
    sorting_direction: "",
  });
  const location: any = useLocation();
  const orderNumber = useMemo(
    () => (location.search ? location.search?.split("=")?.[1] : ""),
    [location]
  );

  // @ts-ignore
  const [getCustomers, { data }] = useLazyQuery(CUSTOMER_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const [getOrderPdf, { data: orderData }] = useLazyQuery(GET_ORDER_PDF, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      window.open(orderData?.getCustomerOrderPdf, "_blank");
    },
  });

  const [customerData, setCustomerData] = useState(null);

  let nextDay = new Date();
  //   @ts-ignore
  nextDay = nextDay.setDate(nextDay.getDate());
  const form = useFormik({
    initialValues: {
      // @ts-ignore
      from: new Date(null),
      to: new Date(nextDay),
      quoteRequest: "",
      name: "",
      status: null,
    },
    onSubmit: async (values) => {
      setAppliedVariables(variables);
      setPageSize(10);
      setCurrentPage(1);
    },
    onReset: () => {
      setAppliedVariables(variables);
    },
  });

  const variables = useMemo(() => {
    return {
      pageSize,
      currentPage,
      sorting_attribute: sortData?.sorting_attribute,
      sorting_direction: sortData?.sorting_direction,
      filter: {
        number: { eq: form?.values?.quoteRequest },
        name: { eq: form?.values?.name },
        status: { eq: form?.values?.status?.["value"] },
        created_at: {
          from: isEqual(form?.values?.from, form?.values?.to)
            ? // @ts-ignore
              new Date(null)
            : new Date(form?.values?.from).toISOString(),
          to: new Date(form?.values?.to).toISOString(),
        },
      },
    };
  }, [pageSize, currentPage, sortData, form]);

  const [appliedVariables, setAppliedVariables] = useState(variables);

  useEffect(() => {
    return getCustomers({
      variables: orderNumber
        ? { filter: { number: { eq: orderNumber } } }
        : appliedVariables,
    });
  }, [appliedVariables, getCustomers, orderNumber]);

  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize]);

  const resetFilters = () => {
    form.resetForm();
    setPageSize(10);
  };

  useEffect(() => {
    let newVariables = JSON.parse(JSON.stringify(appliedVariables));
    newVariables.currentPage = currentPage;
    newVariables.pageSize = pageSize;
    newVariables.sorting_attribute = sortData.sorting_attribute;
    newVariables.sorting_direction = sortData.sorting_direction;
    setAppliedVariables(newVariables);
  }, [pageSize, currentPage, sortData]);

  useEffect(() => {
    getCustomers({ variables });
  }, []);

  useEffect(() => {
    if (data) setCustomerData(data);
  }, [data]);

  const totalPages = useMemo(() => {
    // @ts-ignore
    const size = pageSize?.value ? pageSize.value : pageSize;
    return data?.customer
      ? Math.ceil((data?.customer?.orders?.total_count || 1) / (size || 1))
      : 1;
  }, [data, pageSize]);

  return (
    <AccountCustomerOrders
      //@ts-ignore
      orders={customerData?.customer?.orders}
      //@ts-ignore
      customer={customerData?.customer}
      form={form}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      totalPages={totalPages}
      pageSize={pageSize}
      setPageSize={setPageSize}
      getOrderPdf={getOrderPdf}
      pageSizeOptions={[
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
      ]}
      location={location}
      orderNumber={orderNumber}
      //@ts-ignore
      setSortData={setSortData}
      //@ts-ignore
      resetFilters={resetFilters}
    />
  );
};

export default CustomerOrdersDataContainer;
