export default (state, action) => {
  return Object.assign({}, state, {
    data: {
      ...state.data,
      token: action.token,
      loggedIn: true,
      loading: true,
    },
  });
};
