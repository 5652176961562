const SEARCH_PAGE_SIZE = "jola/search/SEARCH_PAGE_SIZE";
const SEARCH_CURRENT_PAGE = "jola/search/SEARCH_CURRENT_PAGE";
const SEARCH_TOTAL_PAGES = "jola/search/SEARCH_TOTAL_PAGES";
const SEARCH_SET_DATA = "jola/search/SEARCH_SET_DATA";
export default {
  SEARCH_PAGE_SIZE,
  SEARCH_CURRENT_PAGE,
  SEARCH_TOTAL_PAGES,
  SEARCH_SET_DATA,
};
