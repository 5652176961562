import MagicSliderDots from "react-magic-slider-dots";
import React from "react";

export const sliderSettings = (isPatternFilter) => {
  return {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    controls: true,
    arrows: true,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: 0,
    rows: isPatternFilter ? 4 : 3,
    slidesPerRow: isPatternFilter
      ? 4
      : window.location.pathname.includes("graded-in")
      ? window.innerWidth > 576
        ? 2
        : 2
      : 3,
    adaptiveHeight: false,
    variableWidth: false,
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />;
    },

    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          controls: true,
          arrows: true,
          slidesToScroll: 1,
          swipeToSlide: true,
          initialSlide: 0,
          rows: 3,
          slidesPerRow: 3,
          adaptiveHeight: false,
          variableWidth: false,
          appendDots: (dots) => {
            return (
              <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />
            );
          },
        },
      },
    ],
  };
};
