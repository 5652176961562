import React from "react";
import { Route, Switch } from "react-router-dom";
import globalPages, {
  DefaultPage,
  ErrorPage,
  TemporaryPage,
} from "core/config/routing/pages/globalPages";
import customerPages from "core/config/routing/pages/customerPages";
import guestPages from "core/config/routing/pages/guestPages";
import DynamicRouter from "./dynamic-content-router";
import DynamicGraphQlWrapperQueryContainer from "app/overrides/dynamicGraphQlWrapper";
import queryLoader from "app/config/routing/loader";
import { withStatusCode } from "react-router-dispatcher-status-code";
import { loadState } from "core/state/redux/localStorage";
import { customerActions } from "core/state/redux/data/customer";
import { useDispatch } from "react-redux";

const Router = (props) => {
  let globalProps = props;
  let state = loadState();
  const dispatch = useDispatch();

  if (state?.customer?.data?.token) {
    return (
      <Switch>
        <Route key={"index"} path="/" exact component={DefaultPage} />
        {Object.keys(customerPages).map((key) => {
          return (
            <Route
              key={key}
              path={"/" + key}
              component={customerPages[key].component}
              exact={customerPages[key].exact}
            />
          );
        })}
        {Object.keys(globalPages).map((key) => {
          return (
            <Route
              key={key}
              path={"/" + key}
              render={(props) => {
                const Component = globalPages[key].component;
                return (
                  <Component
                    {...props}
                    isCustom={globalPages[key].isCustom}
                    type={globalPages[key].type}
                  />
                );
              }}
              exact={globalPages[key].exact}
            />
          );
        })}
        <Route
          path="/:url"
          render={(props) => {
            return (
              <DynamicGraphQlWrapperQueryContainer
                query={queryLoader("urlResolver")}
                queryIdentifier={"urlResolver"}
                variables={{ url: props.location.pathname.substr(1) }}
                loader={false}
                data={globalProps.data}
                loadingComponent={() => {
                  return (
                    <>
                      <TemporaryPage />
                    </>
                  );
                }}
              >
                <DynamicRouter
                  {...globalProps}
                  variables={{ url: props.location.pathname.substr(1) }}
                />
              </DynamicGraphQlWrapperQueryContainer>
            );
          }}
        />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route key={"index"} path="/" exact component={DefaultPage} />
        {Object.keys(guestPages).map((key) => {
          return (
            <Route
              key={key}
              path={"/" + key}
              component={guestPages[key].component}
              exact={guestPages[key].exact}
            />
          );
        })}
        {Object.keys(globalPages).map((key) => {
          return (
            <Route
              key={key}
              path={"/" + key}
              render={(props) => {
                const Component = globalPages[key].component;
                return (
                  <Component
                    {...props}
                    isCustom={globalPages[key].isCustom}
                    type={globalPages[key].type}
                  />
                );
              }}
              exact={globalPages[key].exact}
            />
          );
        })}
        <Route
          path="/:url"
          render={(props) => {
            return (
              <DynamicGraphQlWrapperQueryContainer
                query={queryLoader("urlResolver")}
                queryIdentifier={"urlResolver"}
                variables={{ url: props.location.pathname.substr(1) }}
                loader={false}
                data={globalProps.data}
                loadingComponent={() => {
                  return (
                    <>
                      <TemporaryPage />
                    </>
                  );
                }}
              >
                <DynamicRouter
                  {...globalProps}
                  variables={{ url: props.location.pathname.substr(1) }}
                />
              </DynamicGraphQlWrapperQueryContainer>
            );
          }}
        />
        {/* If you want to redirect all guests to specific url if page is not available or found */}
        {/* <Redirect key={"redirection"} to="/" component={DefaultPage} /> */}

        {/* <Route key={"not-found"} component={ErrorPage} status={404} /> */}
        <Route
          render={({ staticContext }) => {
            // if (staticContext) {
            staticContext.statusCode = 404;
            // }
            return withStatusCode(404)(ErrorPage);
          }}
          status={404}
        />
      </Switch>
    );
  }
};

export default Router;
