import Pagination from "app/layout/Pagination/Pagination";
import Select from "app/layout/Select";
import { MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdbreact";
import React, { useEffect } from "react";
import PerPage from "app/layout/Pagination/PerPage";
const Toolbar = ({
  currentPage,
  pageSize,
  setCurrentPage,
  setPageSize,
  totalCount,
  totalPages,
  pageSizeOptions,
  searchPageInput,
  categoryName,
  optionsVal,
  loadingData = false,
}) => {
  let isSearchResultsPage = window.location.pathname === "/search-results";

  // useEffect(() => {
  //   if (!loadingData)
  //     setTimeout(() => {
  //       document.querySelector(".collection-row") &&
  //         document.querySelector(".collection-row").scrollIntoView({
  //           behavior: "smooth",
  //         });
  //     }, 100);
  // }, [pageSize, loadingData]);

  return (
    <MDBRow className="my-5 py-md-5 plp-counter-pagination-wrapper">
      <MDBCol
        size="12"
        lg={`${
          (((!isSearchResultsPage && totalCount >= 12) ||
            (isSearchResultsPage && totalCount >= 16)) &&
            "6") ||
          "12"
        }`}
        md={`${
          (((!isSearchResultsPage && totalCount >= 12) ||
            (isSearchResultsPage && totalCount >= 16)) &&
            "2") ||
          "12"
        }`}
        className={`d-flex align-items-center  pb-md-0 px-0 ${
          (((!isSearchResultsPage && totalCount >= 12) ||
            (isSearchResultsPage && totalCount >= 16)) &&
            "pb-5 justify-content-md-start justify-content-center") ||
          "pb-0 justify-content-center justify-content-md-end"
        }`}
      >
        <MDBTypography tag="span" className="body-6">
          {totalCount} Item(s)
        </MDBTypography>
      </MDBCol>
      {(((!isSearchResultsPage && totalCount >= 12) ||
        (isSearchResultsPage && totalCount >= 16)) && (
        <MDBCol
          size="12"
          md="10"
          lg="6"
          className="d-flex justify-content-md-end px-0"
        >
          <MDBContainer fluid className="p-lg-0">
            <MDBRow className="justify-content-between">
              <MDBCol
                className="px-0 p-0 d-flex justify-content-lg-end justify-content-start align-items-center view-and-pag-col view"
                style={{ overflow: "inherit" }}
              >
                <PerPage
                  value={pageSize}
                  totalCount={totalCount}
                  pageSizeOptions={pageSizeOptions}
                  setPageSize={setPageSize}
                  pageSize={pageSize}
                />
              </MDBCol>
              <MDBCol
                style={{ maxWidth: "fit-content" }}
                className="px-lg-0 fit-content p-0 d-flex justify-content-lg-end justify-content-end align-items-center view-and-pag-col pagination-wrapper"
              >
                <Pagination
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  loadingData={loadingData}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      )) || <></>}
    </MDBRow>
  );
};

export default React.memo(Toolbar);
