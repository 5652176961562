import Icon from "app/assets/icon/icon";
import { MDBCol, MDBRow } from "mdbreact";
import React from "react";
import { EditSectionProps } from "../models";
export interface EditCollectionProps
  extends Pick<EditSectionProps, "onDelete"> {
  edit: React.ReactNode;
}

const EditCollection: React.FC<EditCollectionProps> = (props) => {
  return (
    <MDBRow>
      <MDBCol className="d-flex justify-content-center">
        {/* @ts-ignore */}
        {props?.wishlistName === "My Favorites" &&
        window.location.pathname === "/customer/collections/lists" ? null : (
          <div
            style={{ transform: "scale(1.33)" }}
            className="pointer"
            onClick={props.onDelete}
            onKeyUp={props.onDelete}
            tabIndex={0}
            role="button"
          >
            <Icon icon="closeDefault" />
          </div>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default EditCollection;
