import React from "react";
import { connect } from "react-redux";
import commonActions from "app/state/redux/data/common/actions";
import customerActions from "app/state/redux/data/customer/actions";
import PoNumberStateContainer from "./poNumber-state-container";

const PoNumberReduxContainer = (props) => {
  return <PoNumberStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  cart_id:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cart !== "undefined" &&
    typeof state.customer.data.cart.id !== "undefined" &&
    state.customer.data.cart.id !== null
      ? state.customer.data.cart.id
      : "",
  po_number:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cart !== "undefined" &&
    typeof state.customer.data.cart.po_number !== "undefined" &&
    state.customer.data.cart.po_number !== null
      ? state.customer.data.cart.po_number
      : "",
});
const mapDispatchToProps = (dispatch) => {
  return {
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    addPoNumberToCart: (payload) => {
      dispatch(customerActions.setPoNumber(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PoNumberReduxContainer);
