import React from "react";
import { inStockLogicMethod } from "app/pages/product/components/toolbar/helper";
import { Link } from "react-router-dom";

function ProductInfo({ product, isLoggedIn }) {
  return (
    <div className="sidebar-product-info">
      <h2 className="mb-4">{product?.name}</h2>
      <h4 className="mb-2">Width: {product?.width}</h4>
      <h4 className="mb-2">
        Depth:{" "}
        {product?.depth && !Number(product?.depth)
          ? `${product?.frame_lowest_depth} - ${product?.frame_highest_depth}`
          : product?.depth || ""}
      </h4>
      <h4 className="mb-2">Frame Depth: {product?.frame_highest_depth}</h4>
      <h4 className="mb-2">Height: {product?.height}</h4>
      <h4 className="mb-2">Additional Info: {product?.additional_info}</h4>
      <h4 className="mb-2">
        Finish:{" "}
        {typeof product?.finish === "string"
          ? product?.finish
          : Array.isArray(product?.finish)
          ? product?.finish?.map((finish) => <span>{finish}</span>)
          : ""}
      </h4>
      <h4 className="mb-2">Cube: {product?.cube}</h4>
      <h4 className="mb-2">Weight (lbs): {product?.weight}</h4>
      <h4 className="mb-2">Country of origin: {product?.country_of_origin}</h4>
      <h4>
        SKU:{" "}
        <Link STY to={`/${product?.url_key}`} target="_blank">
          {product?.sku}
        </Link>
      </h4>
      <h4 className="mt-2">{inStockLogicMethod(product, isLoggedIn)}</h4>
    </div>
  );
}

export default ProductInfo;
