import apolloClient from "core/graphql/apolloClient";
import wishlistActions from "../../redux/data/wishlist/actions";
import queryLoader from "core/graphql/queryLoader";
import { messagesActions } from "../../redux/data/messages";
import { commonActions } from "../../redux/data/common";
import { useDispatch, useSelector } from "react-redux";

function useWishlist() {
  const wishlist = useSelector((state) => state.wishlist);
  const myWishlists = useSelector((state) => state.wishlist.data);
  const customer = useSelector((state) => state.customer);

  let dispatch = useDispatch();
  const addToWishlist = async (request) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("addItemsToWishlist"),
        variables: { input: request },
        fetchPolicy: "no-cache",
      });
      if (data.addItemsToWishlist !== null) {
        const { data } = await apolloClient.query({
          query: queryLoader("getWishlists"),
          fetchPolicy: "no-cache",
        });

        if (data.getWishlists !== null) {
          dispatch(wishlistActions.setWishlist(data.getWishlists));
        }
        dispatch(
          messagesActions.addMessage(
            "Item successfully added to project",
            "success"
          )
        );
        dispatch(commonActions.unlock("success"));
      }
    } catch (err) {
      console.log(err.message);
      return;
    }
  };
  const createWishlist = async (request) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("addNewWishlist"),
        variables: { input: request },
        fetchPolicy: "no-cache",
      });
      if (data.addNewWishlist !== null) {
        const { data } = await apolloClient.query({
          query: queryLoader("getWishlists"),
          fetchPolicy: "no-cache",
        });

        if (data.getWishlists !== null) {
          dispatch(wishlistActions.setWishlist(data.getWishlists));
        }

        dispatch(
          messagesActions.addMessage(
            "New project successfully created",
            "success"
          )
        );
        dispatch(commonActions.unlock("success"));
      }
    } catch (err) {
      console.log(err.message);
      return;
    }
  };

  const removeFromWishlist = async (request) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("deleteItemsFromWishlist"),
        variables: { input: request },
        fetchPolicy: "no-cache",
      });

      fetchWishlistData(true);
      dispatch(
        messagesActions.addMessage(
          "Item successfully removed from project",
          "success"
        )
      );
      dispatch(commonActions.unlock("success"));
      return data;
    } catch (error) {
      dispatch(messagesActions.addMessage(error.toString(), "danger"));
      return;
    }
  };
  const fetchWishlistData = async (force = false) => {
    if (force === false) {
      if (
        typeof customer !== "undefined" &&
        typeof customer.data !== "undefined" &&
        typeof customer.data.token !== "undefined" &&
        customer.data.token !== false
      ) {
        // Have a customer
        if (
          typeof wishlist !== "undefined" &&
          typeof wishlist.data !== "undefined" &&
          typeof wishlist.data.wishlistItems !== "undefined"
        ) {
          // have a wishlist
          return wishlist.data.wishlistItems;
        } else {
          try {
            const { data } = await apolloClient.query({
              query: queryLoader("getWishlists"),
              fetchPolicy: "no-cache",
            });
            if (data.getWishlists !== null) {
              dispatch(wishlistActions.setWishlist(data.getWishlists));
            }
            return data;
          } catch (err) {
            console.log(err);
          }
        }
      } else {
        if (wishlist.data.length > 0) {
          dispatch(wishlistActions.clearWishlistData());
        }
      }
    } else {
      const { data } = await apolloClient.query({
        query: queryLoader("getWishlists"),
        fetchPolicy: "no-cache",
      });
      if (data.getWishlists !== null) {
        dispatch(wishlistActions.setWishlist(data.getWishlists));
      }
    }
  };

  const getWishlistItems = () => {
    if (
      typeof wishlist.data !== "undefined" &&
      typeof wishlist.data.wishlistItems !== "undefined"
    ) {
      return wishlist.data.wishlistItems;
    }
    return wishlist.data;
  };
  const moveItemToWishlist = async (request) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("moveItemToWishlist"),
        variables: request,
        fetchPolicy: "no-cache",
      });
      if (data !== null) {
        fetchWishlistData(true);
        dispatch(messagesActions.addMessage("moved to wishlist", "success"));
        dispatch(commonActions.unlock("success"));
      }
    } catch (err) {
      console.log(err.message);
      return;
    }
  };
  const editWishlist = async (request) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("editWishlist"),
        variables: { input: request },
        fetchPolicy: "no-cache",
      });
      if (data.editWishlist !== null) {
        const { data } = await apolloClient.query({
          query: queryLoader("getWishlists"),
          fetchPolicy: "no-cache",
        });

        if (data.getWishlists !== null) {
          dispatch(wishlistActions.setWishlist(data.getWishlists));
        }

        dispatch(
          messagesActions.addMessage(
            "Wishlist name successfully changed",
            "success"
          )
        );
      }
    } catch (error) {
      console.log(error.message);
      return;
    }
  };
  const deleteWishlist = async (request) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("deleteWishlist"),
        variables: { input: request },
        fetchPolicy: "no-cache",
      });
      if (data.deleteWishlist !== null) {
        fetchWishlistData(true);

        dispatch(
          messagesActions.addMessage("Wishlist successfully removed", "success")
        );
        dispatch(commonActions.unlock("success"));
      }
    } catch (error) {
      console.log(error.message);
      return;
    }
  };

  const checkEmail = async (request) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("checkEmail"),
        variables: { email: request },
        fetchPolicy: "no-cache",
      });

      if (data.checkEmail !== null && data.checkEmail.username !== null) {
        dispatch(commonActions.unlock("success"));
        return data.checkEmail.username;
      }
    } catch (error) {
      dispatch(commonActions.unlock("fail"));
      return false;
    }
  };

  const setWishlistLoading = async (request) => {
    dispatch(wishlistActions.setWishlistLoading(request));
  };

  return [
    addToWishlist,
    removeFromWishlist,
    fetchWishlistData,
    getWishlistItems,
    moveItemToWishlist,
    editWishlist,
    wishlist,
    myWishlists,
    createWishlist,
    deleteWishlist,
    checkEmail,
    setWishlistLoading,
  ];
}
export { useWishlist };
