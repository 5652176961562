import React, { memo } from "react";
import { MDBCol, MDBBox, MDBBtn } from "mdbreact";
import themeConfiguration from "config/themeConfiguration";
import Icon from "app/assets/icon/icon";
import { isEqual } from "lodash";
import axios from "axios";

const Listing = memo(
  ({ vendor, logo_file, website, pdf }) => {
    const downloadFile = () => {
      var filesArray;
      var config;
      filesArray = [];
      const url =
        themeConfiguration.magento_url +
        "rest/V1/jola-filedownloader/downloadFiles";
      var rawRequest = JSON.stringify({
        type: "files",
        files: [
          {
            url: `${themeConfiguration.magento_url}${pdf}`,
          },
        ],
      });
      config = {
        method: "post",
        url,
        headers: {
          "Content-Type": "application/json",
        },
        data: rawRequest,
      };

      axios(config)
        .then((data) => {
          if (data.status === 200) {
            window.open(data.data, "_blank");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    return (
      <MDBCol size="6" md="3" className="logo-data-wrapper mb-4">
        <div className="img-holder">
          <a href={website} target="_blank">
            <img
              src={`${themeConfiguration.magento_url}${logo_file}`}
              alt={vendor}
              className="img-fluid"
            />
          </a>
        </div>
        <MDBBox className="d-flex flex-column text-center logo-info-box">
          <a href={website} target="_blank">{`Visit ${vendor}`}</a>
          {/*
          <MDBBtn
            className="m-auto"
            onClick={(e) => {
              downloadFile();
            }}
          >
            <Icon icon={`docIcon`} size="2" className="mr-3" />
            <span>Download Vendor Grades</span>
          </MDBBtn>
          */}
        </MDBBox>
      </MDBCol>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Listing;
