import { GALLERY_CATEGORY_DATA } from "app/pages/VirtualDesignChallenge/useVirtualDesignChallengeHook";
import { MDBCol, MDBRow } from "mdbreact";
import React, { useMemo } from "react";
import { useQuery } from "react-apollo-hooks";
import CmsBlock from "app/layout/cmsBlock";
import Slider from "react-slick";

function HomepageBanner() {
  const { data } = useQuery(GALLERY_CATEGORY_DATA, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    variables: {
      category_id: "10",
    },
  });
  const galleryData = useMemo(() => data?.getGalleryCategory?.galleries, [
    data?.getGalleryCategory?.galleries,
  ]);

  const sliderProps = {
    className: "gallery-images",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    controls: false,
    arrows: true,
    dots: false,
  };

  return (
    <div className="homepage-banner">
      <MDBRow className="px-4">
        <MDBCol className="px-lg-5 px-0 homepage-hero-banner">
          <MDBRow className="row w-100 d-flex flex-column-reverse flex-lg-row mx-0 mb-0 mb-lg-5 justify-content-center">
            <MDBCol
              size="12"
              lg="6"
              className="bg-secondary d-flex p-5 justify-content-center align-items-center"
            >
              <CmsBlock id="homepage_hero_banner_text" />
            </MDBCol>
            <MDBCol size="12" lg="6">
              <Slider {...sliderProps}>
                {galleryData?.map((item) => (
                  <img key={item.id} src={item.image} alt={item.name} />
                ))}
              </Slider>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </div>
  );
}

export default HomepageBanner;
