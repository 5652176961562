/**
 * Additional pages in the navigation that are not collected from categories endpoint
 */

export default [
  // {
  //   id: 0,
  //   name: "Quick Ship",
  //   url: "/quick-ship"
  // },
  {
    id: 0,
    name: "Contact Us",
    url: "/contact",
  },
];
