import Icon from "app/assets/icon/icon";
import { useMediaQuery } from "react-responsive";
import FileInput, { FileInputProps } from "app/layout/FileInput";
import Label from "app/layout/Label";
import { MDBCol, MDBRow, MDBBox } from "mdbreact";
import React from "react";
import { ItemProps } from "../models";
import { acceptDocuments } from "../utils";
import DocumentInputInfo from "./DocumentInputInfo";

export interface DocumentInputProps
  extends Pick<
      ItemProps,
      "documentNames" | "onDocumentUpload" | "isUploading" | "onRemoveDocument"
    >,
    Pick<FileInputProps, "flexDirection" | "buttonSize"> {
  infoClassName?: string;
}

const DocumentInput: React.FC<DocumentInputProps> = ({
  documentNames,
  onDocumentUpload,
  onRemoveDocument,
  isUploading,
  infoClassName,
  ...props
}) => {
  let isMobile = useMediaQuery({ maxWidth: 575.98 });
  return (
    <>
      <MDBRow className={`upload-field`}>
        <MDBCol>
          <Label required={false} text="Uploads" textClassName="text-default">
            <MDBBox className={`d-flex`}>
              <FileInput
                {...props}
                accept={acceptDocuments}
                value=""
                onChange={onDocumentUpload}
                hint="Upload File"
                disabled={isUploading}
                inputSize="lg"
                flexDirection={(isMobile && "column") || "row"}
              />
              <Icon
                className="ml-4 cursor-pointer add-document-icon"
                icon="plus"
              />
            </MDBBox>
          </Label>
          <DocumentInputInfo className={infoClassName} />
        </MDBCol>
      </MDBRow>
      {documentNames.map((documentName) => (
        <MDBRow key={documentName} className={`uploaded-items`}>
          <MDBCol className="d-flex align-items-center">
            <FileInput
              {...props}
              accept={acceptDocuments}
              // @ts-ignore
              value={documentName?.file_name}
              onChange={onDocumentUpload}
              hint="Upload File"
              disabled={isUploading}
              inputSize="lg"
              flexDirection={(isMobile && "column") || "row"}
            />
            <Icon
              className="ml-3 cursor-pointer remove-document-icon"
              icon="closeDefault"
              onClick={() => onRemoveDocument(documentName)}
            />
          </MDBCol>
        </MDBRow>
      ))}
    </>
  );
};

export default DocumentInput;
