import gql from "graphql-tag";

export const FABRIC_PRODUCT = gql`
  query FabricProduct($filters: ProductAttributeFilterInput!) {
    products(filter: $filters) {
      aggregations {
        attribute_code
        options {
          count
          label
          value
        }
        label
        count
      }
      items {
        id
        name
        sku
        url_key
        description {
          html
        }
        meta_description
        meta_title
        image {
          url(width: 700, height: 700)
          label
          disabled
        }
        media_gallery {
          url(width: 700, height: 700)
          label
          types
          disabled
        }
        small_image {
          url(width: 265, height: 265)
          label
          disabled
        }

        attribute_set_name
        # related_products {
        #   uid
        #   sku
        #   name
        #   url_key
        #   description {
        #     html
        #   }
        #   image {
        #     url(width: 700, height: 700)
        #     label
        #     disabled
        #   }
        #   small_image {
        #     url(width: 265, height: 265)
        #     label
        #     disabled
        #   }
        #   media_gallery {
        #     url(width: 700, height: 700)
        #     label
        #   }
        # }

        # Project Specific Attributes
        ffifabrics_vendor_image {
          label
          image
        }
        ffifabrics_vendor
        stockQty: ffifabrics_avail
        colorways: ffifabrics_colorways
        abrasion: ffifabrics_abrasion
        country: ffifabrics_country
        category1: ffifabrics_category1
        collection: ffifabrics_collection
        pattern: ffifabrics_pattern
        grade: ffifabrics_grade
        color: ffifabrics_descr
        ffifabrics_fabric
        ffifabrics_leather
        ffifabrics_std_width
        ffifabrics_vrepeat
        ffifabrics_hrepeat
        ffifabrics_fullcont
        #ffifabrics_clean_desc
      }
    }
  }
`;

//for logged in customers
export const FABRIC_PRODUCT_CUSTOMER = gql`
  query FabricProduct($filters: ProductAttributeFilterInput!) {
    products(filter: $filters) {
      aggregations {
        attribute_code
        options {
          count
          label
          value
        }
        label
        count
      }
      items {
        uid
        name
        sku
        url_key
        description {
          html
        }
        price_range {
          minimum_price {
            final_price {
              currency
              value
            }
          }
        }
        meta_description
        meta_title
        image {
          url(width: 700, height: 700)
          label
          disabled
        }
        media_gallery {
          url(width: 700, height: 700)
          label
          types
          disabled
        }
        small_image {
          url(width: 265, height: 265)
          label
          disabled
        }
        attribute_set_id
        attribute_set_name

        # related_products {
        #   uid
        #   sku
        #   name
        #   url_key
        #   description {
        #     html
        #   }
        #   image {
        #     url(width: 700, height: 700)
        #     label
        #     disabled
        #   }
        #   small_image {
        #     url(width: 265, height: 265)
        #     label
        #     disabled
        #   }
        #   media_gallery {
        #     url
        #     label
        #   }
        #   thumbnail {
        #     url(width: 150, height: 150)
        #     label
        #   }
        # }

        # Project Specific Attributes
        ffifabrics_vendor_image {
          label
          image
        }
        ffifabrics_vendor
        stockQty: ffifabrics_avail
        colorways: ffifabrics_colorways
        abrasion: ffifabrics_abrasion
        country: ffifabrics_country
        category1: ffifabrics_category1
        collection: ffifabrics_collection
        pattern: ffifabrics_pattern
        grade: ffifabrics_grade
        color: ffifabrics_descr
        ffifabrics_fabric
        ffifabrics_leather
        ffifabrics_std_width
        ffifabrics_vrepeat
        ffifabrics_hrepeat
        ffifabrics_fullcont
      }
    }
  }
`;
