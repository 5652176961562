import { MDBBtn } from "mdbreact";
import React from "react";

export interface SaveSectionProps extends React.ComponentProps<typeof MDBBtn> {}

const SaveSection: React.FC<SaveSectionProps> = (props) => {
  return (
    <div className="pt-5 border-top-1 d-flex justify-content-end">
      <MDBBtn {...props}>Save</MDBBtn>
    </div>
  );
};

export default SaveSection;
