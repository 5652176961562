export const generateLaneFilerString = (lane) => {
  let filterLanePart = "";

  if (typeof lane !== "undefined") {
    if (
      lane !== null &&
      lane.length > 0 &&
      !lane.includes("All") &&
      lane.includes("Contract")
    ) {
      filterLanePart = filterLanePart.concat(`"ffi_lanect": {"eq": "1"}`);
    }

    if (
      lane !== null &&
      lane.length > 0 &&
      !lane.includes("All") &&
      lane.includes("Retail")
    ) {
      filterLanePart = filterLanePart.concat(`"ffi_laners": {"eq": "1"}`);
    }

    if (
      lane !== null &&
      lane.length > 0 &&
      !lane.includes("All") &&
      lane.includes("Senior Living")
    ) {
      filterLanePart = filterLanePart.concat(`"ffi_lanesl": {"eq": "1"}`);
    }

    if (filterLanePart !== "") {
      filterLanePart = `{` + filterLanePart + `}`;
    } else {
      filterLanePart = `{}`;
    }
  }

  return filterLanePart;
};
