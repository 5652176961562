// @ts-check
import { isEmptyRegex } from "app/pages/customer/config";
import * as yup from "yup";

/**
 * @typedef {{ 'trade magazine': boolean; 'instagram': boolean;'pinterest': boolean; 'facebook': boolean; 'linkedin': boolean; 'word of mouth': boolean; 'company website': boolean; 'retailer': boolean; 'in-house show': boolean; 'trade show': string | null; 'other': string | null }} Channel
 *
 * @typedef {object} CompanyDetailsValues
 * @prop {boolean} isNew
 * @prop {string} companyName
 * @prop {string | null} websiteUrl
 * @prop {string} taxId
 * @prop {string} fairfield_account_number
 * @prop {number | null} industryType
 * @prop {number | null} businessType
 * @prop {Channel} channel
 */

export const industryTypes = [
  {
    label: "Residential",
    value: 1,
  },
  { label: "Commercial", value: 2 },
  { label: "Senior Living", value: 3 },
];

export const businessTypes = [
  { label: "Independent Retail", value: 1 },
  { label: "Designer Showroom", value: 2 },
  { label: "Independent Designer", value: 3 },
  { label: "Office Retail", value: 4 },
  { label: "Dealer/FFE", value: 5 },
  { label: "A&D", value: 6 },
  { label: "End User Direct", value: 7 },
  { label: "Ecommerce Retailer", value: 8 },
];

// modified version of https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
const urlRegex = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

/**
 * @type {yup.ObjectSchema<CompanyDetailsValues>}
 */
// @ts-ignore
export const companyDetailsValidationSchema = yup
  .object()
  .required()
  .shape({
    isNew: yup.bool(),
    companyName: yup
      .string()
      .required("Name is required.")
      .matches(isEmptyRegex, "Name is required."),
    websiteUrl: yup
      .string()
      .nullable()
      .matches(
        urlRegex,
        "Please specify a valid URL. For example: example.com or https://example.com"
      ),
    taxId: yup.string(),
    fairfield_account_number: yup.string(),
    industryType: yup
      .number()
      .required("Industry type is required.")
      .nullable()
      .oneOf([...industryTypes.map((type) => type.value), null]),
    businessType: yup
      .number()
      .required("Business type is required.")
      .nullable()
      .oneOf([...businessTypes.map((type) => type.value), null]),
    channel: yup.object().shape({
      "trade magazine": yup.boolean(),
      instagram: yup.boolean(),
      pinterest: yup.boolean(),
      retailer: yup.boolean(),
      "word of mouth": yup.boolean(),
      "trade show": yup.string().nullable(),
      "company website": yup.boolean(),
      facebook: yup.boolean(),
      linkedin: yup.boolean(),
      "in-house show": yup.boolean(),
      other: yup.string().nullable(),
    }),
  });

/**
 * @type {CompanyDetailsValues}
 */
export const companyDetailsInitialValues = {
  isNew: false,
  companyName: "",
  websiteUrl: "",
  taxId: "",
  fairfield_account_number: "",
  industryType: null,
  businessType: null,
  channel: {
    "trade magazine": false,
    instagram: false,
    pinterest: false,
    retailer: false,
    "word of mouth": false,
    "trade show": null,
    "company website": false,
    facebook: false,
    linkedin: false,
    "in-house show": false,
    other: null,
  },
};
