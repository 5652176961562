import apolloClient from "core/graphql/apolloClient";
import queryLoader from "core/graphql/queryLoader";
import { useDispatch } from "react-redux";
import { messagesActions } from "core/state/redux/data/messages";
import { customerActions } from "core/state/redux/data/customer";
import useLocking from "core/state/hooks/commonHooks/useLocking";
import { useWishlist } from "core/state/hooks/wishlistHooks/wishlistHookAddRemove";

function useWishlistAddToCart() {
  const [, , fetchWishlistData] = useWishlist();
  const [, unlock] = useLocking();

  let dispatch = useDispatch();

  const addToCartWishlist = async (request) => {
    try {
      await apolloClient.mutate({
        mutation: queryLoader("addItemToCart"),
        variables: request,
        fetchPolicy: "no-cache",
      });
      fetchWishlistData(true);
      dispatch(customerActions.getCartInformation());
      dispatch(messagesActions.addMessage("Added to quote request", "success"));
      unlock("success");
    } catch (err) {
      unlock("fail");
      console.log(err.message);
      return;
    }
  };

  const addAllToCart = async (request) => {
    try {
      await apolloClient.mutate({
        mutation: queryLoader("addAllToCart"),
        variables: request,
        fetchPolicy: "no-cache",
      });
      fetchWishlistData(true);
      dispatch(customerActions.getCartInformation());
      dispatch(
        messagesActions.addMessage("Added all to quote request", "success")
      );
      unlock("success");
    } catch (err) {
      unlock("fail");
      console.log(err);
      return;
    }
  };

  const deleteItemsFromWishlist = async (request) => {
    try {
      await apolloClient.mutate({
        mutation: queryLoader("deleteItemsFromWishlist"),
        variables: request,
        fetchPolicy: "no-cache",
      });
      fetchWishlistData(true);
      dispatch(
        messagesActions.addMessage("Items successfully removed", "success")
      );
      unlock("success");
    } catch (err) {
      unlock("fail");
      console.log(err);
      return;
    }
  };

  return [addToCartWishlist, addAllToCart, deleteItemsFromWishlist];
}
export { useWishlistAddToCart };
