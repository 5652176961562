import React, { Component, createContext } from "react";

export const ModalContext = createContext({
  component: null,
  props: {},
  renderCount: 0,
  showModal: () => {},
  hideModal: () => {},
  rerender: () => {},
  updateProps: () => {},
});

export class ModalProvider extends Component {
  showModal = (component, props = {}) => {
    this.setState({
      component,
      props
    });
  };

  hideModal = () => {
    this.setState({
      component: null,
      props: {}
    });
  };

  rerender = () => {
    this.setState({ renderCount: this.renderCount + 1 });
  };
  updateProps = (newProps) => {
    this.setState({...this.state, props:{...this.state.props, ...newProps}})
  };

  state = {
    component: null,
    props: {},
    renderCount: this.renderCount,
    showModal: this.showModal,
    hideModal: this.hideModal,
    rerender: this.rerender,
    updateProps: this.updateProps,
  };

  render() {
    return (
      <ModalContext.Provider value={this.state}>
        {this.props.children}
      </ModalContext.Provider>
    );
  }
}

export const ModalConsumer = ModalContext.Consumer;

export default ModalContext;
