import React, { useState, useCallback, Fragment } from "react";
import themeConfiguration from "config/themeConfiguration";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBTypography,
  MDBRow,
  MDBCol,
} from "mdbreact";
import FormatDate from "app/overrides/date";
import Price from "core/components/price";
import Icon from "app/assets/icon/icon";
import { Link } from "react-router-dom";
import ItemSelections from "../ItemSelections";

function Table({
  data,
  setSortData = () => {},
  location,
  ordersHead,
  isTablet,
  grand_total = 0,
}) {
  const [arrowsState, setArrowsState] = useState({
    number: "arrowDown",
    created_at: "arrowDown",
    name: "arrowDown",
    grand_total: "arrowDown",
    status: "arrowDown",
  });

  const handleArrowClick = useCallback(
    (key) => {
      if (!key) return;
      if (arrowsState[key] === "arrowDown") {
        setArrowsState((prevProps) => ({
          ...prevProps,
          [key]: "arrowUp",
        }));
      } else {
        setArrowsState((prevProps) => ({
          ...prevProps,
          [key]: "arrowDown",
        }));
      }
      setSortData({
        sorting_attribute: key,
        sorting_direction: arrowsState[key] === "arrowDown" ? "DESC" : "ASC",
      });
      for (const id in arrowsState) {
        if (id !== key) {
          setArrowsState((prevProps) => ({
            ...prevProps,
            [id]: "arrowDown",
          }));
        }
      }
    },
    [arrowsState]
  );

  const getItemImg = useCallback((item) => {
    const isByo = item?.selected_options?.find((option) =>
      option.label.includes("Build your own image")
    );
    if (!isByo) return item?.image;
    else return `${themeConfiguration.magento_url}${isByo?.value}`;
  }, []);

  return (
    <MDBTable className="quote-history-grid" wrapperClassName="table-wrapper">
      <MDBTableHead>
        <tr>
          {ordersHead?.map((order, i) => (
            <th
              className={`${order?.attribute ? "cursor-pointer" : ""}`}
              onClick={() =>
                handleArrowClick(order?.attribute ? order?.attribute : false)
              }
              key={i}
            >
              <MDBTypography tag="h4" className="d-inline">
                {order?.title}
              </MDBTypography>
              {order?.attribute && (
                <Icon
                  icon={arrowsState[order?.attribute]}
                  size="2"
                  className="ml-3"
                />
              )}
            </th>
          ))}
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        {location.search && data?.length ? (
          data?.map((item) => (
            <Fragment key={item?.id}>
              <tr>
                <td className={`body-2`}>
                  <img
                    className="item-image"
                    src={getItemImg(item)}
                    alt={item?.product_name}
                  />
                </td>
                <td className={`body-2`}>{item?.product_sku}</td>
                <td className={`body-2`}>{item?.product_name}</td>
                <td className={`body-2`}>
                  <Price
                    price={item?.product_sale_price?.value}
                    type={"fixed"}
                  />
                </td>
                <td className={`body-2`}>{item?.quantity_ordered}</td>
                {/* <td className={`body-2`}>{item?.quantity_shipped}</td> */}
                <td className={`body-2`}>
                  <Price price={item?.item_total?.value} type={"fixed"} />
                </td>
              </tr>
              <tr>
                <td colspan="7" className="selected-options">
                  <ItemSelections item={item} isTablet={isTablet} />
                  {item?.comment && (
                    <div
                      className={`mt-5 d-flex align-items-start ${
                        isTablet ? "flex-column" : ""
                      }`}
                    >
                      <p className="mr-5 option-title">Comment</p>
                      <p>{item.comment}</p>
                    </div>
                  )}
                </td>
              </tr>
            </Fragment>
          ))
        ) : data?.items !== null || typeof data?.items !== "undefined" ? (
          data?.items.map((order) => {
            let shipTo =
              order?.shipping_address?.firstname +
              " " +
              order?.shipping_address?.lastname;
            return (
              <>
                <tr>
                  <td className={`body-2`}>{order?.number}</td>
                  <td className={`body-2`}>
                    <FormatDate date={order?.order_date} />
                  </td>
                  <td className={`body-2`}>{shipTo}</td>
                  {order?.total?.grand_total?.value && (
                    <td className={`body-2`}>
                      <Price
                        price={order?.total?.grand_total?.value}
                        type={"fixed"}
                      />
                    </td>
                  )}
                  <td className={`body-2`}>{order?.status}</td>
                  <td className={`body-2 cursor-pointer underline`}>
                    <Link
                      to={`/customer/account/request-quote-history?eq=${order?.number}`}
                    >
                      View
                    </Link>
                  </td>
                </tr>
              </>
            );
          })
        ) : (
          <>
            <tr>
              <td>
                <MDBTypography tag="h4" className="d-inline">
                  {`${!location.search ? "No items ordered" : "No quotes"}`}
                </MDBTypography>
              </td>
            </tr>
          </>
        )}
        {location.search && data?.length && (
          <tr>
            <td colspan="7" className="table-footer flex w-100">
              <div className="d-flex justify-content-end">
                <p className="mr-5">Item(s)</p>
                <p>{data?.length}</p>
              </div>
              {/* <div className="d-flex justify-content-end">
                <p className="font-weight-bold mr-5">Quote Subtotal</p>
                <p className="font-weight-bold">
                  <Price price={grand_total} type={"fixed"} />
                </p>
              </div> */}
            </td>
          </tr>
        )}
      </MDBTableBody>
    </MDBTable>
  );
}

export default Table;
