import { useQuery } from "react-apollo-hooks";
import { WHATS_NEW } from "./query";
import { useGetCategoryProducts } from "app/state/hooks/getCategoryProducts";
import themeConfiguration from "config/themeConfiguration";
import React, { memo } from "react";
import WhatsNew from "./whatsNew";

import Skeleton from "react-loading-skeleton";

const DataContainer = memo((props) => {
  const { data, loading, error } = useQuery(WHATS_NEW, {
    variables: { id: themeConfiguration.whats_new_category_id },
    fetchPolicy: "cache-first",
  });
  if (loading)
    return (
      <>
        <Skeleton />
      </>
    );
  if (error) return console.error(error);
  let products = data?.categoryList[0].products?.items || [];
  let categoryLink = "/what-s-new";

  // let categoryData = useGetCategoryProducts(
  //   themeConfiguration.whats_new_category_id,
  //   4
  // );
  // console.log(categoryData);

  // let products = categoryData?.products?.items || [];
  // let categoryLink = categoryData?.category?.url_key || "/what-s-new";

  let displayProps = {
    products,
    categoryLink,
  };

  return <WhatsNew {...props} {...displayProps} />;
});

export default DataContainer;
