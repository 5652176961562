export const fabricSliderSettings = () => {
  return {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    controls: false,
    arrows: false,
  };
};
