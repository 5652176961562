import {
  CustomerAddress,
  CustomerAddressInput,
  CustomerAddressRegionInput,
} from "app/generated/graphql";
import {
  contactNameSchema,
  emailSchema,
  phoneSchema,
  firstName,
  lastName,
} from "app/pages/customer/config";
import Maybe from "graphql/tsutils/Maybe";
import { DeepNonNullable, NonUndefined } from "utility-types";
import * as yup from "yup";
import { RequiredCountry, RequiredRegion } from "../../../models";

export enum AddressCategory {
  mailing = "mailing",
  shipping = "shipping",
  new = "new",
}

type Street = NonNullable<NonNullable<CustomerAddress["street"]>[number]>;

export interface AddressValues
  extends DeepNonNullable<
    Required<
      Pick<
        CustomerAddressInput,
        "firstname" | "lastname" | "email" | "telephone" | "city" | "postcode"
      >
    >
  > {
  id: CustomerAddress["id"];
  country: Maybe<RequiredCountry>;
  region_id: NonUndefined<CustomerAddressRegionInput["region_id"]>;
  address1: Street;
  address2?: Street;
  addressCategory: AddressCategory;
  default_shipping: boolean;
  default_billing: boolean;
  address_label: string;
}

export const initialAddressValues: AddressValues = {
  id: null,
  city: "",
  country: null,
  firstname: "",
  lastname: "",
  email: "",
  postcode: "",
  region_id: null,
  address1: "",
  address2: "",
  telephone: "",
  addressCategory: AddressCategory.new,
  default_shipping: false,
  default_billing: false,
  address_label: "",
};

const regionSchema: yup.ObjectSchema<RequiredRegion> = yup
  .object()
  .required()
  .shape({
    id: yup.number().required(),
    name: yup.string(),
    code: yup.string(),
  });

const countrySchema: yup.ObjectSchema<Maybe<RequiredCountry>> = yup
  .object()
  .required("Country is required.")
  .nullable()
  .shape({
    id: yup.string().required(),
    full_name_english: yup.string(),
    full_name_locale: yup.string(),
    two_letter_abbreviation: yup.string(),
    three_letter_abbreviation: yup.string(),
    available_regions: yup.array().defined().nullable().of(regionSchema),
  });

let countryValue: string | undefined = "";
export const postCodeSchema = yup
  .string()
  .required("Zip code is required")
  .when("country", {
    is: (country: AddressValues["country"]) => {
      countryValue = country?.id;
      return country && (country.id === "US" || country.id === "CA");
    },
    // then: yup.string().matches(/^\d{5}$/, "Zip code must consist of 5 digits."),
    then: () => {
      if (countryValue === "US") {
        return yup
          .string()
          .required("Zip code is required")
          .matches(/^\d{5}$/, "Zip code must consist of 5 digits.");
      } else {
        return yup
          .string()
          .required("Zip code is required")
          .matches(
            /^[a-zA-Z0-9\-\s]{7}$/,
            "Zip code must consist of at least 6 characters."
          );
      }
    },
    otherwise: yup.string(),
  });

export const addressSchema: yup.ObjectSchema<AddressValues> = yup
  .object()
  .required()
  .shape({
    id: yup.number().nullable(),
    city: yup.string().required("City is required."),
    firstname: firstName,
    lastname: lastName,
    email: emailSchema,
    telephone: phoneSchema,
    address1: yup.string().required("Address is required."),
    address2: yup.string(),
    address_label: yup.string().required("Address Title is required."),
    postcode: postCodeSchema,
    country: countrySchema,
    region_id: yup
      .number()
      .defined()
      .nullable()
      .when("country", {
        is: (country: AddressValues["country"]) => !!country?.available_regions,
        then: yup.number().required("State is required."),
        otherwise: yup.number().notRequired(),
      }),
    addressCategory: yup
      .string()
      .required()
      .oneOf(Object.values(AddressCategory)),
    default_shipping: yup.bool(),
    default_billing: yup.bool(),
  });
