import React from "react";
import CollectionCustom from "./collectionCustom";
import CollectionNotCustom from "./collectionNotCustom";
import CollectionsListing from "./collectionsListing";

const Collections = ({
  category,
  categoryMain,
  heroBannerProps,
  queryProps,
  listingProps,
  toolbarProps,
  // showCollectionAsCategory,
  // setShowCollectionAsCategory,
  data,
  productsInCollection,
  currentPage,
  setCurrentPage,
  handleSetPageSize,
  pageSizeOptions,
  pageSize,
  setPageSize,
  totalPages,
  totalCount,
  ...props
}) => {
  return category?.children?.length > 0 ? (
    <CollectionsListing
      category={category}
      categoryMain={categoryMain}
      heroBannerProps={heroBannerProps}
      queryProps={queryProps}
      listingProps={listingProps}
      toolbarProps={toolbarProps}
      data={data}
      productsInCollection={productsInCollection}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalPages={totalPages}
      totalCount={totalCount}
      setPageSize={setPageSize}
      pageSizeOptions={pageSizeOptions}
      pageSize={pageSize}
      {...props}
    />
  ) : category.isCustom ? (
    <CollectionCustom
      category={category}
      categoryMain={categoryMain}
      heroBannerProps={heroBannerProps}
      queryProps={queryProps}
      listingProps={listingProps}
      toolbarProps={toolbarProps}
      data={data}
      productsInCollection={productsInCollection}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalPages={totalPages}
      totalCount={totalCount}
      setPageSize={setPageSize}
      pageSizeOptions={pageSizeOptions}
      pageSize={pageSize}
      {...props}
    />
  ) : (
    <CollectionNotCustom
      categoryMain={categoryMain}
      listingProps={listingProps}
      category={category}
      heroBannerProps={heroBannerProps}
      queryProps={queryProps}
      toolbarProps={toolbarProps}
      data={data}
      productsInCollection={productsInCollection}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalPages={totalPages}
      totalCount={totalCount}
      setPageSize={setPageSize}
      pageSizeOptions={pageSizeOptions}
      pageSize={pageSize}
      {...props}

      // showCollectionAsCategory={showCollectionAsCategory}
      // setShowCollectionAsCategory={setShowCollectionAsCategory}
    />
  );
};

export default Collections;
