import React from "react";
import GraphQlWrapper from "core/components/graphQlWrapper";
import StoreLocatorData from "./data-container";
import { GET_GOOGLE_API_KEY, GET_STORE_LOCATOR_QUERY } from "./queries";
import { useQuery } from "@apollo/react-hooks";
import StoreLocatorSkeleton from "./storeLocatorSkeleton";

const StoreLocatorQueryContainer = (props) => {
  const { data, loading, error } = useQuery(GET_GOOGLE_API_KEY);
  const googleApiKey = data?.storeConfig?.google_api_key;

  if (!loading && data) {
    return (
      <GraphQlWrapper
        query={GET_STORE_LOCATOR_QUERY}
        variables={{
          id: `store`,
          url: props.url,
        }}
        loader={true}
      >
        <StoreLocatorData {...props} googleApiKey={googleApiKey} />
      </GraphQlWrapper>
    );
  } else {
    return <StoreLocatorSkeleton />;
  }
};

export default StoreLocatorQueryContainer;
