import React from "react";
import { MDBBox, MDBCol, MDBRow, MDBTypography } from "mdbreact";
import { useMediaQuery } from "react-responsive";

function Title({ text }) {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <MDBRow className="w-100 mt-5 mb-4 title-container">
      <MDBCol className="d-block px-0" size={isMobile ? "2" : "3"}>
        <MDBBox className="h-50 border-bottom border-default" />
      </MDBCol>
      <MDBCol
        size={isMobile ? "8" : "6"}
        className="d-flex justify-content-center p-0 align-items-center"
      >
        <MDBTypography className="font-weight-light w-100 text-center" tag="h3">
          {text}
        </MDBTypography>
      </MDBCol>
      <MDBCol className="d-block px-0" size={isMobile ? "2" : "3"}>
        <MDBBox className="h-50 border-bottom border-default" />
      </MDBCol>
    </MDBRow>
  );
}

export default Title;
