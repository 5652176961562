import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { Link } from "react-router-dom";

const FabricDetail = (props) => {
  let { fabric, hideModal } = props;
  //Name, pattern, grade, color, collection
  return (
    <MDBContainer className="fabric-detail-container">
      <MDBRow className="fabric-detail-image-row">
        <MDBCol className="fabric-detail-image">
          <Link to={"/" + fabric.url_key} onClick={() => hideModal()}>
            <img
              src={fabric.image.url}
              alt={fabric.image.label}
              className={`border-tooltip-img`}
            />
          </Link>
        </MDBCol>
      </MDBRow>

      <MDBRow className="fabric-detail-info">
        <MDBCol>
          <h3>Name: {fabric.name}</h3>
          <h3>Pattern: {fabric.pattern}</h3>
          <h3>Grade: {fabric.grade}</h3>
          <h3>Color: {fabric.color}</h3>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};
export default FabricDetail;
