export default (state, action) => {
  console.log(state, action);
  return Object.assign({}, state, {
    data: {
      ...state.data,
      wishlistItems: action.payload,
    },

    loading: false,
  });
};
