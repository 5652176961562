import React from "react";
import { MDBRow } from "mdbreact";
import { generateFilterRender } from "./functions";

const Filters = (props) => {
  let {
    aggregations,
    activeFilters,
    setActiveFilters,
    selectedFilters,
    setSelectedFilters,
    currentPage,
    setCurrentPage,
    allItems,
  } = props;

  return (
    <MDBRow className="filters-container">
      {generateFilterRender(
        aggregations,
        activeFilters,
        setActiveFilters,
        selectedFilters,
        setSelectedFilters,
        currentPage,
        setCurrentPage,
        allItems
      )}
    </MDBRow>
  );
};

export default Filters;
