// Middleware
import types from "./types";
import unlock from "./middleware/unlock";
import checkCustomerAuthorization from "./middleware/checkCustomerAuthorization";
import routeChange from "./middleware/routeChange";
import routeChangeSuccess from "./middleware/routeChangeSuccess";
import lock from "./middleware/lock";
import sendEmail from "./middleware/sendEmail";

const commonMiddleware = (store) => {
  return (next) => {
    return async (action) => {
      next(action);
      switch (action.type) {
        case types.LOCK:
          return lock(store);

        case types.UNLOCK:
          return unlock(store, action);

        case types.CHECK_CUSTOMER_AUTHORIZATION:
          return checkCustomerAuthorization(store, action);

        case types.FORCE_CHECK_CUSTOMER_AUTHORIZATION:
          return checkCustomerAuthorization(store, action);

        case types.ROUTE_CHANGE:
          return routeChange(store, action);

        case types.ROUTE_CHANGE_SUCCESS:
          return routeChangeSuccess(store, action);

        case types.SEND_EMAIL:
          return sendEmail(store, action);

        default:
          return;
      }
    };
  };
};

export default commonMiddleware;
