import { uniqBy } from "lodash";
import React, { useState, useEffect } from "react";
import Item from "./sessionMessagesItem";

const SessionMessages = (props) => {
  const [autoClose, setautoClose] = useState(true);
  const [timeId, settimeId] = useState(0);

  useEffect(() => {
    const messages = document.querySelectorAll(".session-messages-inner");

    const findMessage = (text) =>
      Array.from(messages).find((node) => node.innerText.includes(text));

    const message = Array.from(messages).find(
      (node) =>
        node.innerText.includes("Added to favorites") ||
        node.innerText.includes("Product removed") ||
        node.innerText.includes("Product added") ||
        node.innerText.includes("Saved for later")
    );

    const addToFavorites = findMessage("Added to favorites");

    const productRemovedFrom = findMessage("Product removed from");
    const productAdded = findMessage("Product added");

    const buildYourOwn = findMessage("Saved for later");

    if (addToFavorites) {
      message.classList.add("add-to-favorites");
    }

    if (productRemovedFrom || productAdded) {
      message.classList.add("product-add-remove");
    }

    if (buildYourOwn) {
      message.classList.add("build-your-own");
      message.style.width = "auto";
    }

    if (message) {
      message.classList.add("favorites");
    }
  });

  let { target } = props;
  if (!props.messages || !props.messages.length) {
    return <></>;
  }

  let uniqueItems = uniqBy(props.messages, (message) => {
    return [message.text, message.target].join();
  });

  const handleMouseOver = (e) => {
    if (
      document.querySelectorAll(".session-messages-inner span div div span")[0]
        ?.innerHTML === "Add to Lists"
    ) {
      setautoClose(false);
    }
  };

  const handleMouseLeave = (e) => {
    if (
      document.querySelectorAll(".session-messages-inner span div div span")[0]
        ?.innerHTML === "Add to Lists"
    ) {
      setautoClose(true);
    }
  };

  return (
    <>
      {uniqueItems.map((message, index) => {
        if (target === message.target) {
          return (
            <Item
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              key={`session-message-${message.target}-${message.text}`}
              text={message.text}
              type={message.type}
              onClose={() => props.onMessageClose(message.text, message.type)}
              closeTime={message.closeTime}
              dismissible={props.dismissible}
              autoClose={autoClose}
              options={message.options}
              successIcon={props.successIcon}
              errorIcon={props.errorIcon}
              settimeId={settimeId}
            />
          );
        }
        return "";
      })}
    </>
  );
};
export default SessionMessages;
