import { useCountriesQuery, useCustomerQuery } from "app/generated/graphql";
import queryLoader from "app/graphql/queryLoader";
import React from "react";
import { useQuery } from "react-apollo-hooks";
import { isCountryDefined } from "../../utils";
import Account from "./Account";

export type AccountDataContainerProps = {};

const AccountDataContainer: React.FC<AccountDataContainerProps> = (props) => {
  const countriesQuery = useCountriesQuery();
  const { data: salesRapData } = useQuery(queryLoader("getSalesRepData"), {
    fetchPolicy: "cache-and-network",
  });

  const { data } = useCustomerQuery({
    fetchPolicy: "cache-and-network",
  });

  return (
    <Account
      firstname={data?.customer?.firstname}
      lastname={data?.customer?.lastname}
      email={data?.customer?.email}
      sign_up_for_updates={data?.customer?.sign_up_for_updates}
      addresses={data?.customer?.addresses}
      countries={(countriesQuery.data?.countries ?? []).filter(
        isCountryDefined
      )}
      is_subscribed={data?.customer?.is_subscribed}
      salesRapData={salesRapData?.getSalesRepData}
    />
  );
};

export default AccountDataContainer;
