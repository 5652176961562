import {
  ProductInterface,
  useCustomerCartQuery,
  useRemoveAllItemsFromCartMutation,
} from "app/generated/graphql";
import { useCustomerOrdersUpload } from "app/helpers/upload/hooks";
// import CartContext from "app/layout/cart";
// import { DocumentName } from "app/layout/cart/CartContext/CartContext";
import { formatFileName } from "app/utils/utils";
import { MB2 } from "config/upload";
import { messagesActions } from "core/state/redux/data/messages";
import { fromPairs } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Cart from "./Cart";
import { useIsMobile } from "./hooks";
import { ItemProps } from "./models";
// @ts-ignore
import { CartDataContext } from "./context/CartDataContext";
import { acceptDocuments } from "./utils";
export interface DataContainerProps {}

const DataContainer: React.FC<DataContainerProps> = (props) => {
  const dispatch = useDispatch();

  const cartQuery = useCustomerCartQuery({ fetchPolicy: "no-cache" });
  const [refetchData, setRefetchData] = useState(false);

  const isMobile = useIsMobile();

  // const { orderItemDataRecord, setOrderItemDataRecord } = React.useContext(
  //   CartContext
  // );
  // @ts-ignore
  const [orderItemDataRecord, setOrderItemDataRecord] = React.useState({});
  const [removeDocumentTrigger, setRemoveDocumentTrigger] = React.useState(
    false
  );

  useEffect(() => {
    if (cartQuery?.data?.customerCart) {
      let data = {};
      cartQuery.data.customerCart?.items?.map((item: any) => {
        if (item?.comment || item?.document_names) {
          let id = item.id;
          // @ts-ignore
          data[id] = {
            comment: item.comment,
            document_names: item?.document_names,
          };
        }
      });
      // @ts-ignore
      setOrderItemDataRecord(data);
    }
  }, [cartQuery]);

  useEffect(() => {
    if (refetchData) {
      cartQuery.refetch().then(() => {
        cartQuery.refetch().then(() => {
          setRefetchData(false);
          dispatch(
            messagesActions.addMessage("Updated quote request.", "success")
          );
        });
      });
    }
  }, [refetchData]);

  const [
    removeAllItemsFromCart,
    removeAllItemsFromCartResult,
  ] = useRemoveAllItemsFromCartMutation(
    React.useMemo(
      () => ({
        onCompleted: () => {
          cartQuery.refetch().then(() => {
            cartQuery.refetch().then(() => {
              setRefetchData(false);
              dispatch(messagesActions.addMessage("Items removed.", "success"));
            });
          });
        },
        onError: (error) => {
          dispatch(
            messagesActions.addMessage(
              error.graphQLErrors[0]?.message ??
                "Failed to remove items, please retry.",
              "error"
            )
          );
        },
      }),
      [dispatch]
    )
  );
  const handleRemoveAll = React.useCallback(() => {
    if (cartQuery.data?.customerCart.id) {
      removeAllItemsFromCart({
        variables: { cart_id: cartQuery.data.customerCart.id },
      });
    } else {
      console.error(
        "Cannot remove items from cart, cart id",
        cartQuery.data?.customerCart.id
      );
    }
  }, [removeAllItemsFromCart, cartQuery]);

  const [
    customerOrdersUpload,
    customerOrdersUploadResult,
  ] = useCustomerOrdersUpload();

  const handleDocumentUpload = React.useCallback(
    (sku: ProductInterface["sku"]) => (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const file = e.target.files?.[0];
      const validExtension = acceptDocuments.find((document) =>
        file?.name.toLowerCase().includes(document)
      );
      if (!validExtension) {
        dispatch(
          messagesActions.addMessage(
            "Allowed File Types in the Upload Field are PDF and JPG",
            "error"
          )
        );
        return;
      }
      if (sku) {
        if (file) {
          const name = formatFileName(file.name);
          if (file.size < MB2) {
            customerOrdersUpload(new File([file], name)).then((res) => {
              // @ts-ignore
              const itemData = orderItemDataRecord[sku];
              const record = {
                ...orderItemDataRecord,
                [sku]: {
                  ...itemData,
                  document_names: [
                    ...(itemData?.document_names ?? []),
                    { file_name: name, uploaded_file_name: res.data },
                  ],
                },
              };
              // @ts-ignore
              setOrderItemDataRecord(record);
            });
          } else {
            dispatch(
              messagesActions.addMessage(
                "File size must be less the 2 MB.",
                "error"
              )
            );
          }
        } else {
          console.error("Document name is not defined.");
        }
      } else {
        console.error("Cannot set document name from item with sku", sku);
      }
    },
    [
      customerOrdersUpload,
      setOrderItemDataRecord,
      orderItemDataRecord,
      dispatch,
    ]
  );

  const onRemoveDocument = React.useCallback(
    (productSku: ProductInterface["sku"]) => (documentName: any) => {
      setRemoveDocumentTrigger(true);
      setOrderItemDataRecord(
        fromPairs(
          Object.entries(orderItemDataRecord).map(([sku, data]) =>
            sku === productSku
              ? [
                  sku,
                  {
                    // @ts-ignore
                    ...data,
                    // @ts-ignore
                    document_names: data.document_names?.filter((file) => {
                      return file.file_name !== documentName?.file_name;
                    }),
                  },
                ]
              : [sku, data]
          )
        )
      );
    },
    [setOrderItemDataRecord, orderItemDataRecord]
  );

  const handleCommentChange = React.useCallback(
    (sku: ProductInterface["sku"]): ItemProps["onCommentChange"] => (e) => {
      if (sku) {
        setOrderItemDataRecord({
          ...orderItemDataRecord,
          [sku]: {
            // @ts-ignore
            ...orderItemDataRecord[sku],
            comment: e.target.value,
          },
        });
      } else {
        console.error("Cannot add comment since the product sku is", sku);
      }
    },
    [setOrderItemDataRecord, orderItemDataRecord]
  );
  const comment = Object.values(orderItemDataRecord).map((item: any, index) => {
    return item.comment;
  });
  return cartQuery.data ? (
    // @ts-ignore
    <CartDataContext.Provider value={{ refetchData, setRefetchData }}>
      <Cart
        isMobile={isMobile}
        cart={cartQuery.data.customerCart}
        orderItemDataRecord={orderItemDataRecord}
        comment={comment}
        onRemoveAll={handleRemoveAll}
        isRemovingAll={removeAllItemsFromCartResult.loading}
        onCommentChange={handleCommentChange}
        onDocumentUpload={handleDocumentUpload}
        isUploadingDocument={customerOrdersUploadResult.loading}
        onRemoveDocument={onRemoveDocument}
        cartQuery={cartQuery}
        removeDocumentTrigger={removeDocumentTrigger}
      />
    </CartDataContext.Provider>
  ) : null;
};

export default DataContainer;
