import { default as cacheMiddleware } from "./data/cache/middleware";
import { default as filtersMiddleware } from "./data/filters/middleware";
// additional middleware that will work on top of existing ones are defined here
let projectMiddleware = [];

// overwriting middleware, object index is mandatory, since filtering will not work if the middleware are not the same
export const overwritteMapper = {
  // 0: customerMiddleware,
  3: filtersMiddleware,
  4: cacheMiddleware,
};

export default projectMiddleware;
