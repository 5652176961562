import React, { useState } from "react";
import { MDBCol, MDBRow, MDBInput } from "mdbreact";
import Icon from "app/assets/icon/icon";
import MultiSelectFilterItem from "./multiSelectFilterItem";
import SwitchFilterItem from "./switchFilterItem";
import OptionsContainer from "./multiSelectFilterItem/filterOptions";
import { useMediaQuery } from "react-responsive";

function useFilterTypes() {
  const GetMultiSelectFilter = (
    filterType,
    activeFilters,
    setActiveFilters,
    selectedFilters,
    setSelectedFilters,
    currentPage,
    setCurrentPage,
    allItems
  ) => {
    // ADDED ALLITEMS IF WE NEED TO UPGRADE SEARCH FOR NAME
    const [
      openFilterOptionsContainer,
      setOpenFilterOptionsContainer,
    ] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: "600px" });

    const [filterSearchInput, setFilterSearchInput] = useState("");
    const filterOptions = (filterCode, filterOptions) => {
      let filteredOptions = filterOptions.filter((option) =>
        option.label.toLowerCase().includes(filterSearchInput.toLowerCase())
      );
      return filteredOptions;
    };
    return (
      <>
        <div key={filterType} className={`filters-${filterType[0]}-wrapper`}>
          {filterType[1].map((filter, index) => {
            return (
              <MultiSelectFilterItem
                key={`multi-select-filter-item-${index}`}
                filter={filter}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                openFilterOptionsContainer={openFilterOptionsContainer}
                setOpenFilterOptionsContainer={setOpenFilterOptionsContainer}
                filterSearchInput={filterSearchInput}
                setFilterSearchInput={setFilterSearchInput}
              />
            );
          })}
        </div>
        {(openFilterOptionsContainer && (
          <MDBCol>
            <div className="searchable-filter w-100">
              <MDBInput
                hint={`Search ${(
                  (selectedFilters === "ffifabrics_collection" &&
                    "Collection") ||
                  ""
                ).trim()}${(
                  (selectedFilters === "ffifabrics_grade" && "Grade") ||
                  ""
                ).trim()}${(
                  (selectedFilters === "ffifabrics_category1" && "Pattern") ||
                  ""
                ).trim()}${(
                  (selectedFilters === "ffifabrics_hexclrgrp" && "Color") ||
                  ""
                ).trim()}
                `}
                onChange={(e) => {
                  setFilterSearchInput(e.target.value);
                }}
                containerClass={`w-100 position-relative`}
                value={filterSearchInput}
              >
                <Icon
                  icon="searchGray"
                  className={`position-absolute search-filter-icon`}
                />
              </MDBInput>
            </div>
          </MDBCol>
        )) || <></>}
        <div
          className={`multi-select-options ${
            openFilterOptionsContainer && !isMobile && "active"
          }`}
        >
          {filterType[1].map((filter, index) => {
            if (selectedFilters === filter.attribute_code) {
              let options = filterOptions(
                filter.attribute_code,
                filter.options
              );
              return (
                <OptionsContainer
                  key={`multi-select-filter-options-${index}`}
                  options={options}
                  activeFilters={activeFilters}
                  setActiveFilters={setActiveFilters}
                  filterCode={filter.attribute_code}
                  filterType={filter.type}
                  setOpenFilterOptionsContainer={setOpenFilterOptionsContainer}
                  setSelectedFilters={setSelectedFilters}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              );
            } else return <></>;
          })}
        </div>
      </>
    );
  };
  const GetSwitchFilter = (
    filterType,
    activeFilters,
    setActiveFilters,
    currentPage,
    setCurrentPage
  ) => {
    return (
      <MDBCol>
        <MDBRow key={filterType} className={`filters-${filterType[0]}-wrapper`}>
          {filterType[1].map((filter, index) => {
            if (
              (filter &&
                filter.options &&
                filter.options[0] &&
                filter.options[0].label.includes("1")) ||
              (filter.options[1] && filter.options[1].label.includes("1"))
            ) {
              return (
                <SwitchFilterItem
                  key={`multi-select-filter-item-${index}`}
                  filter={filter}
                  activeFilters={activeFilters}
                  setActiveFilters={setActiveFilters}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              );
            } else return <></>;
          })}
        </MDBRow>
      </MDBCol>
    );
  };

  const GetDropdownSelectFilter = (props) => {
    return (
      <MDBCol className={`product-list-item product-item`}>
        Dropdown select filter
      </MDBCol>
    );
  };

  return { GetMultiSelectFilter, GetSwitchFilter, GetDropdownSelectFilter };
}

export default useFilterTypes;
