import Icon from "app/assets/icon/icon";
import { createEnterHandler } from "app/utils/utils";
import clsx from "clsx";
import React from "react";
import { IQuantityPerPage } from "../config";

const key = "productsPerPage";

export interface QuantityPerPageProps {
  current: IQuantityPerPage;
  quantity: IQuantityPerPage;
  onSelect: React.EventHandler<React.SyntheticEvent>;
}

const QuantityPerPage: React.FC<QuantityPerPageProps> = (props) => {
  return (
    <div
      className={clsx(
        "cursor-pointer border-solid border-width-2 project-pdf-layout",
        props.current === props.quantity
          ? "border-default"
          : "border-transparent"
      )}
      onClick={props.onSelect}
      onKeyUp={createEnterHandler(props.onSelect)}
      tabIndex={0}
      role="button"
    >
      <Icon icon={`${key}${props.quantity}`} />
    </div>
  );
};

export default QuantityPerPage;
