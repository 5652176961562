import Icon from "app/assets/icon/icon";
import _ from "lodash";
import { MDBCol, MDBCollapse, MDBRow, MDBTypography } from "mdbreact";
import React, { memo, useState } from "react";

/**
 * @type {React.NamedExoticComponent<{title?: string, content?: string, wrapperClass?: string, className?: string, stateHandler?: boolean, isOpen?: boolean, pdfBorder?:boolean, children?: React.reactNode }>}
 */
export const Collapse = memo(
  ({
    title = "You forgot the title for this elemet",
    content = "You forgot the content for this element",
    wrapperClass = "",
    className = "",
    stateHandler = false,
    isOpen = false,
    children,
    pdfBorder = false,
  }) => {
    let [isOpenInternal, setIsOpenInternal] = useState(isOpen);

    let isOpenHandler = (newState) => {
      if (_.isFunction(stateHandler)) {
        stateHandler(newState);
      } else {
        setIsOpenInternal(newState);
      }
    };
    let style = {
      paddingTop: "1rem",
      paddingBottom: "1rem",
    };
    return (
      <MDBCol className={className}>
        <MDBRow
          onClick={() => {
            isOpenHandler(!isOpenInternal);
          }}
          className={`row-wrapper py-3 rounded-lg ${
            !pdfBorder ? "bg-gray-lighten-4" : pdfBorder
          }`}
        >
          <MDBCol size="10" lg="11">
            <MDBTypography tag="h2" className="body-2">
              {title}
            </MDBTypography>
          </MDBCol>
          {!pdfBorder && (
            <MDBCol
              size="2"
              lg="1"
              className="d-flex justify-content-end align-items-center"
            >
              {isOpenInternal && <Icon icon="minusBlue" />}
              {!isOpenInternal && <Icon icon="plusBlue" />}
            </MDBCol>
          )}
        </MDBRow>
        <MDBRow>
          <MDBCol style={style}>
            <MDBCollapse
              isOpen={isOpenInternal}
              className={`${
                isOpenInternal ? "open-content" : "not-open-content"
              }`}
            >
              {children}
            </MDBCollapse>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    );
  }
);
