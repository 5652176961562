import ModalHeader from "app/layout/ModalHeader";
import {
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React from "react";
import { DomainCartItem } from "../../models";
import CartSummaryItem from "../CartSummaryItem";

export interface CartSummaryModalProps
  extends Required<
    Pick<React.ComponentProps<typeof MDBModal>, "isOpen" | "toggle">
  > {
  isMobile: boolean;
  items: DomainCartItem[];
}

const CartSummaryModal: React.FC<CartSummaryModalProps> = (props) => {
  return (
    <MDBModal
      inline={false}
      noClickableBodyWithoutBackdrop
      overflowScroll
      isOpen={props.isOpen}
      toggle={props.toggle}
      className="h-100 m-0 ml-auto"
      backdropClassName={"d-none"}
      contentClassName="h-100"
    >
      <MDBModalBody className="p-0 h-100 d-flex flex-column">
        <ModalHeader onClose={props.toggle}>Quote Summary</ModalHeader>
        <MDBRow className="mt-5 mb-4 pb-3 mx-4 px-3">
          <MDBCol>
            <MDBTypography tag="h3" variant="h3">
              {props.items.length} Item(s)
            </MDBTypography>
          </MDBCol>
        </MDBRow>
        <div className="grow overflow-y-auto overflow-x-hidden">
          {props.items.map((item) => (
            <MDBRow
              key={item.id}
              className="pt-4 mb-4 pb-2 border-top-1 border-gray-lighten-4"
            >
              <MDBCol className="mt-3">
                <CartSummaryItem key={item.id} item={item} />
              </MDBCol>
            </MDBRow>
          ))}
        </div>
      </MDBModalBody>
    </MDBModal>
  );
};

export default CartSummaryModal;
