import FieldLength from "app/layout/FieldLength";
import clsx from "clsx";
import { FieldInputProps } from "formik";
import { MDBInput } from "mdbreact";
import React from "react";

export interface NameFieldProps
  extends Pick<
    React.ComponentProps<typeof MDBInput>,
    "size" | "hint" | "containerClass"
  > {
  fieldProps: FieldInputProps<string>;
  length: number;
  maxLength: number;
  error: React.ReactNode;
  wrapperClass?: string;
}

const NameField: React.FC<NameFieldProps> = ({ size = "sm", ...props }) => {
  return (
    <div className={clsx("position-relative", props.wrapperClass)}>
      <MDBInput
        {...props.fieldProps}
        hint={props.hint}
        containerClass={clsx("m-0", props.containerClass)}
        size={size}
        className={clsx({
          error: props.error,
        })}
      />
      <FieldLength length={props.length} maxLength={props.maxLength} />
      <div className="mt-2 text-red position-absolute">{props.error}</div>
    </div>
  );
};

export default NameField;
