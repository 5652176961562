import { useBoolean } from "ahooks";
import { ApolloError } from "apollo-client";
import { useEditWishlistMutation } from "app/generated/graphql";
import BackSection from "app/layout/BackSection";
import ModalHeader from "app/layout/ModalHeader";
import { customerPaths } from "app/pages/customer/routes";
import { FormikConfig, useFormik } from "formik";
import { kebabCase } from "lodash";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import DownloadButton from "../../components/DownloadButton";
import EditCollection from "../../components/EditCollection";
import EditLink from "../../components/EditLink";
import Footer from "../../components/Footer";
import LoadingWishlist from "../../components/LoadingWishlist";
import NameField from "../../components/NameField";
import Wishlist from "../../components/Wishlist";
import {
  ModificationWishlistSchema,
  modificationWishlistValues,
  ModificationWishlistValues,
  WishlistNameLength,
} from "../../config";
import {
  useDeleteWishlist,
  useDeleteWishlistItem,
  useRemoveAllItemsFromWishlist,
  useToggleFavorite,
  useWishlists,
} from "../../hooks";
import { WithName } from "../../models";
import { getFavorites, isWishlistItemDefined } from "../../utils";

export interface ProjectProps extends RouteComponentProps<WithName> {}

const Project: React.FC<ProjectProps> = (props) => {
  const wishlists = useWishlists();

  const wishlist = React.useMemo(
    () =>
      wishlists.find(
        (list) => list.name && kebabCase(list.name) === props.match.params.name
      ),
    [props, wishlists]
  );

  const favorites = React.useMemo(() => getFavorites(wishlists), [wishlists]);

  const toggleFavorite = useToggleFavorite(favorites);

  const deleteWishlistItem = useDeleteWishlistItem(wishlist ?? null);

  const removeAll = useRemoveAllItemsFromWishlist(wishlist ?? null);

  const [isOpen, setIsOpen] = useBoolean();

  const [editWishlist] = useEditWishlistMutation();

  const modificationWishlistConfig: FormikConfig<ModificationWishlistValues> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues: {
        name: wishlist?.name ?? modificationWishlistValues.name,
      },
      validationSchema: ModificationWishlistSchema,
      onSubmit: (values, { setErrors, setFieldError }) => {
        if (wishlist?.name) {
          setErrors({});

          return editWishlist({
            variables: { fromName: wishlist.name, toName: values.name },
            optimisticResponse: {
              __typename: "Mutation",
              editWishlist: {
                __typename: "WishlistJola",
                ...wishlist,
                name: values.name,
              },
            },
          })
            .then(() => {
              setIsOpen.setFalse();

              props.history.replace(
                `${
                  customerPaths.children.collections.children.wishlists.path
                }/${kebabCase(values.name)}`
              );
            })
            .catch((error: ApolloError) => {
              setFieldError("name", error.graphQLErrors?.[0].message);
            });
        } else {
          console.error("Cannot edit wishlist", wishlist, "without a name");
        }
      },
    }),
    [editWishlist, wishlist, setIsOpen, props.history]
  );
  const modificationWishlistForm = useFormik(modificationWishlistConfig);

  const deleteWishlist = useDeleteWishlist();

  const handleDelete = React.useCallback(() => {
    modificationWishlistForm.setErrors({});

    const result = deleteWishlist(wishlist ?? null);
    if (result) {
      result.catch((error: ApolloError) => {
        modificationWishlistForm.setFieldError(
          "name",
          error.graphQLErrors?.[0].message
        );
      });
    }
  }, [modificationWishlistForm, deleteWishlist, wishlist]);

  return (
    <MDBContainer>
      <BackSection
        to={customerPaths.children.collections.children.wishlists.path}
      >
        Back to lists
      </BackSection>
      {wishlist && favorites ? (
        <Wishlist
          wishlist={wishlist}
          favorites={favorites}
          onToggleFavorite={toggleFavorite}
          onDelete={deleteWishlistItem}
          wishlistName={wishlist?.name ?? "Project"}
          editButton={
            <div
              onClick={setIsOpen.setTrue}
              onKeyUp={setIsOpen.setTrue}
              tabIndex={0}
              role="button"
            >
              <MDBTypography className="pointer text-default fs-16 mb-0">
                Edit
              </MDBTypography>
            </div>
          }
          EditSection={({ editLink, onDelete }) => (
            <EditCollection
              onDelete={onDelete}
              edit={<EditLink editLink={editLink} />}
              // @ts-ignore
              wishlistName={wishlist?.name}
            />
          )}
        />
      ) : (
        <LoadingWishlist />
      )}
      <Footer
        wishlistName={wishlist?.name ?? "Project"}
        canRemove={!!wishlist}
        onRemoveAll={removeAll}
        wishlists={wishlists}
        downloadButton={
          <DownloadButton
            items={wishlist?.items?.filter(isWishlistItemDefined) ?? []}
          />
        }
      />
      <MDBModal
        isOpen={isOpen}
        inline={false}
        overflowScroll
        noClickableBodyWithoutBackdrop={false}
        centered
      >
        <MDBModalBody className="p-0">
          <ModalHeader onClose={setIsOpen.setFalse}>Edit List Name</ModalHeader>
          <MDBRow className="mt-5 mx-5 px-5 mb-5 pb-5">
            <MDBCol>
              <form onSubmit={modificationWishlistForm.handleSubmit}>
                <NameField
                  fieldProps={modificationWishlistForm.getFieldProps("name")}
                  error={
                    modificationWishlistForm.touched.name &&
                    modificationWishlistForm.errors.name
                  }
                  length={modificationWishlistForm.values.name.length}
                  maxLength={WishlistNameLength.max}
                  hint="Enter List Name"
                  containerClass="w-100"
                />
                <MDBRow className="mt-5">
                  <MDBCol className="d-flex">
                    <MDBBtn
                      onClick={() =>
                        new Promise((resolve, reject) =>
                          resolve(handleDelete())
                        ).then(() => {
                          setIsOpen.setFalse();
                          props.history.push("/customer/collections/lists");
                        })
                      }
                      className="mr-4"
                      color="secondary"
                    >
                      Delete List
                    </MDBBtn>
                    <MDBBtn
                      onClick={modificationWishlistForm.submitForm}
                      type="submit"
                      disabled={modificationWishlistForm.isSubmitting}
                    >
                      Save
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default withRouter(Project);
