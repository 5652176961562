// Middleware
import types from "./types";
import addQuery from "./middleware/addQuery";
import removeSpecificQuery from "./middleware/removeSpecificQuery";
import addQueryData from "./middleware/addQueryData";

export default store => {
  return next => {
    return async action => {
      next(action);

      switch (action.type) {
        case types.ADD_QUERY:
          return addQuery(store, action);

        case types.REMOVE_SPECIFIC_QUERY:
          return removeSpecificQuery(store, action);

        case types.ADD_QUERY_DATA:
          return addQueryData(store, action);

        default:
          return;
      }
    };
  };
};
