import React, { useState } from "react";
import Header from "./header";

const HeaderStateContainer = (props) => {
  let { data } = props;
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState(data.categoryList[0]);
  let [activeTopMenu, setActiveTopMenu] = useState(false);
  return (
    <Header
      menu={menu}
      open={open}
      setOpen={setOpen}
      setMenu={setMenu}
      setActiveTopMenu={setActiveTopMenu}
      activeTopMenu={activeTopMenu}
    />
  );
};

export default HeaderStateContainer;
