import { useBoolean } from "ahooks";
import { useFormik } from "formik";
import React from "react";
import { useReactToPrint } from "react-to-print";
import Print, { PrintProps } from "./components/Print";
import {
  contactInformationSchema,
  contactInformationValues,
  ContactInformationValues,
  IQuantityPerPage,
  Pricing,
  quantitiesPerPageRecord,
} from "./config";
import DownloadButton, { DownloadButtonProps } from "./DownloadButton";

export interface DownloadButtonContainerProps {
  items: PrintProps["itemsWithPrintableProducts"];
}

const DownloadButtonContainer: React.FC<DownloadButtonContainerProps> = (
  props
) => {
  const contactInformationForm = useFormik<ContactInformationValues>(
    React.useMemo(
      () => ({
        initialValues: contactInformationValues,
        validationSchema: contactInformationSchema,
        onSubmit: () => {},
      }),
      []
    )
  );

  const [
    quantityPerPage,
    setQuantityPerPage,
  ] = React.useState<IQuantityPerPage>(quantitiesPerPageRecord[1]);
  const [pricing, setPricing] = React.useState<Pricing>(false);
  const [message, setMessage] = React.useState("");

  const printRef = React.useRef<Print>(null);
  const handleSave = useReactToPrint({ content: () => printRef.current });

  const [modalOpen, setModalOpen] = useBoolean();

  const [withContactInformation, setWithContactInformation] = useBoolean();
  return (
    <>
      <DownloadButton
        {...props}
        form={contactInformationForm}
        modalOpen={modalOpen}
        toggleModalOpen={React.useCallback(() => {
          setModalOpen.toggle();
        }, [setModalOpen])}
        withContactInformation={withContactInformation}
        toggleWithContactInformation={React.useCallback(() => {
          setWithContactInformation.toggle();
        }, [setWithContactInformation])}
        quantityPerPage={quantityPerPage}
        setQuantityPerPage={setQuantityPerPage}
        pricing={pricing}
        setPricing={setPricing}
        message={message}
        setMessage={setMessage}
        onSave={handleSave as DownloadButtonProps["onSave"]}
      />
      <div className="d-none">
        <Print
          ref={printRef}
          quantityPerPage={quantityPerPage}
          contactInformation={contactInformationForm.values}
          pricing={pricing}
          message={message}
          itemsWithPrintableProducts={props.items}
          classNameNotes={"notes-pdf"}
        />
      </div>
    </>
  );
};

export default DownloadButtonContainer;
