import gql from "graphql-tag";

export const CONTACT_US = gql`
  mutation contactUs(
    $company_name: String
    $customer_email: String
    $customer_type: String
    $existing_customer: String
    $first_name: String
    $last_name: String
    $message: String
    $subject: String
    $type_of_inquiry: String
    $zip_code: String
  ) {
    contactUs(
      input: {
        company_name: $company_name
        customer_email: $customer_email
        customer_type: $customer_type
        existing_customer: $existing_customer
        first_name: $first_name
        last_name: $last_name
        message: $message
        subject: $subject
        type_of_inquiry: $type_of_inquiry
        zip_code: $zip_code
      }
    )
  }
`;
