import React, { memo } from "react";
import ImageContainer from "./imageContainer";
import themeConfiguration from "config/themeConfiguration";
import lodash from "lodash";
import ImageLoader from "react-load-image";
import ReactLoader from "app/layout/loader";
import Skeleton from "react-loading-skeleton";

function Preloader(props) {
  return (
    (props.loaderAsSkeleton === true && (
      <div className={`d-flex`} style={{ width: "100%", height: "100%" }}>
        <Skeleton />
      </div>
    )) || (
      <div style={{ width: "100%", height: "100%" }}>
        <ReactLoader />
      </div>
    )
  );
}

const Image = memo(
  (props) => {
    let { source, errorImage } = props;
    if (typeof props.catalog !== "undefined" && props.catalog) {
      source =
        themeConfiguration.magento_url +
        themeConfiguration.media_url +
        props.source;
    }

    if (typeof props.source === "undefined") return <></>;
    if (!lodash.isUndefined(props.backend) && props.backend === true) {
      if (props.backend_public) {
      } else {
        source = themeConfiguration.magento_url + props.source;
      }
    } else {
      source = props?.source?.includes("http") ? props.source : props.source;
    }
    return (
      <ImageContainer {...props}>
        <ImageLoader
          src={source}
          onLoad={Preloader}
          className={props.wrapperClassName}
        >
          <img
            className={`${props.className} img-fluid ${
              source?.includes("/build_your_own_image") && "build-your-own-img"
            }`}
            alt={props.alt}
          />
          <div>{typeof errorImage !== "undefined" ? errorImage : "Error!"}</div>
          <Preloader
            loaderAsSkeleton={props.loaderAsSkeleton}
            type={props.type}
          />
        </ImageLoader>
      </ImageContainer>
    );
  },
  (prevProps, nextProps) => {
    return lodash.isEqual(prevProps, nextProps);
  }
);

export default Image;
