import placeHolder from "app/assets/images/800.png";
import CategoryWidget from "app/layout/categoryWidget";
import { MDBBox, MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdbreact";
import React, { useState, memo } from "react";
import Slider from "react-slick";
const ExploreIndustry = memo(
  ({ tabProps, sliderSettings = {}, categoryList, sliderRef }) => {
    let [imageError, setImageError] = useState(false);
    return (
      <MDBContainer fluid className="explore-industry-container py-5 px-0 mt-4">
        <MDBRow className="py-3">
          <MDBCol>
            <MDBContainer className="p-lg-4 p-0">
              <MDBRow className="w-100 m-0">
                <MDBCol size="3" className="d-none d-lg-block px-0">
                  <MDBBox className="h-50 border-bottom border-default w-100" />
                </MDBCol>
                <MDBCol className="d-flex justify-content-center align-items-center">
                  <MDBTypography
                    className="font-weight-light"
                    tag="h2"
                    variant="display-2"
                  >
                    Explore Our Product Categories
                  </MDBTypography>
                </MDBCol>
                <MDBCol size="3" className="d-none d-lg-block px-0">
                  <MDBBox className="h-50 border-bottom border-default w-100" />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCol>
        </MDBRow>
        <MDBContainer className="pb-4 pt-3">
          <MDBRow>
            <MDBCol className="d-flex justify-content-lg-center justify-content-between py-lg-3 py-0">
              {tabProps.tabs.map((tab, index) => {
                return (
                  <MDBBox
                    tag="div"
                    key={tab.id}
                    onClick={() => {
                      tabProps.setCurrentTab(index);
                      sliderRef.current.slickGoTo(0);
                    }}
                    className={`tab horisontal-tab mx-lg-5 mx-0 ${
                      tabProps.currentTab === index ? "active" : ""
                    }`.trim()}
                  >
                    {tab.name}
                  </MDBBox>
                );
              })}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBRow>
          <MDBCol
            className="px-0 explore-slider"
            style={{ paddingBottom: "4rem", overflowX: "hidden" }}
          >
            <Slider
              ref={(slider) => (sliderRef.current = slider)}
              style={{ marginRight: "-15px", marginLeft: "-15px" }}
              {...sliderSettings}
              className={"dark-dots"}
            >
              {categoryList.map((cat, i) => {
                return (
                  <CategoryWidget
                    setImageError={setImageError}
                    imageError={imageError}
                    key={cat.url_path}
                    name={cat.name}
                    url={cat.url_path}
                    heroImage={
                      (imageError === true && cat.hero_image) || cat.promo_image
                    }
                    placeHolder={placeHolder}
                  />
                );
              })}
            </Slider>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
);

export default ExploreIndustry;
