import CmsBlock from "app/layout/cmsBlock";
import React from "react";

const ContactThankYou: React.FC = () => {
  return (
    <div className="container-fluid">
      <CmsBlock id="contact-thank-you" />
    </div>
  );
};

export default ContactThankYou;
