import React from "react";
import { ModalConsumer } from "./ModalContext";
// --------------------------------------------------------------------------------------------------------------------------------------------------------
// This is the layout and functional definition for the website modal
// It should only be edited when you want to change the look and behaviour of every modal on the website, if you want to change a specific modal look
// for the component that defines the content for it. This is just a wraper that provides the modal functionallity.
// Keep in mind that this is loaded on every page of the website, so try to keep it light
// --------------------------------------------------------------------------------------------------------------------------------------------------------
const ModalRoot = (props) => {
  let ModalWrapper = props.ModalWrapper;

  return (
    <ModalConsumer>
      {({ component: Component, props = { withWrapper: true }, hideModal }) =>
        Component ? (
          props.withWrapper === false ? (
            <Component {...props} onRequestClose={hideModal} />
          ) : (
            <ModalWrapper {...props} hideModal={hideModal}>
              <Component {...props} onRequestClose={hideModal} />
            </ModalWrapper>
          )
        ) : null
      }
    </ModalConsumer>
  );
};

export default ModalRoot;
