import React, { useEffect, useState, useMemo } from "react";
import Step from "./components/step";
import {
  armArray as havenArmArray,
  backArray as havenBackArray,
  baseArray as havenBaseArray,
  frameArray as havenFrameArray,
} from "app/pages/Custom/haven/data";

import {
  armArray as urbanArmArray,
  backArray as urbanBackArray,
  baseArray as urbanBaseArray,
  seatArray as urbanSeatArray,
  frameArray as urbanFrameArray,
} from "app/pages/Custom/urban/data";

import {
  armArray as seniorArmArray,
  baseArray as seniorBaseArray,
  seatAndBackArray as seniorSeatAndBackArray,
  frameArray as seniorFrameArray,
  accessoryArrayLeft as seniorAccessoryArrayLeft,
  accessoryArrayRight as seniorAccessoryArrayRight,
  powerArray as seniorPowerArray,
} from "app/pages/Custom/senior/data";
import {
  modelsArray as monogramModelsArray,
  stripingArray as monogramStripingArray,
  finishesArray as monogramFinishArray,
} from "app/pages/Custom/monogram/data";
import { getObjectWithOptionIds } from "app/pages/collections/components/Wishlist/helper";
import finishes from "generation/finishes";

import {
  styles as benchMadeBenchesStyle,
  bases as benchMadeBenchesBases,
} from "app/pages/Custom/benchmade-benches/data";

import {
  tops as benchMadeROTops,
  bases as benchMadeROBases,
  nails as benchMadeRONails,
} from "app/pages/Custom/benchmade-round-ottomans/data";

import {
  styles as benchMadeSOStyles,
  tops as benchMadeSOTops,
  bases as benchMadeSOBases,
  nails as benchMadeSONails,
  sizes as benchMadeSONailSizes,
} from "app/pages/Custom/benchmade-square-ottomans/data";
import {
  tops as liveEdgeTops,
  bases as liveEdgeBases,
} from "app/pages/Custom/live-edge/data";
import { useHistory } from "react-router-dom";
import useMonogramHook from "app/pages/catalog/category/collections/collectionCustom/useMonogramHook";
import { pathnameIncludes } from "app/utils/utils";

const Steps = ({
  type,
  frame,
  back,
  arm,
  base,
  seat,
  seatAndBack,
  accessoryLeft,
  accessoryRight,
  powerOption,
  model,
  striping,
  finish,
  setFrame,
  setBack,
  setArm,
  setBase,
  setAccessoryLeft,
  setSeat,
  setSeatAndBack,
  setAccessoryRight,
  setPowerOption,
  setModel,
  setStriping,
  setFinish,
  modelReady,
  setModelReady,
  contrastDrawers,
  setContrastDrawers,
  top,
  setTop,
  nail,
  setNail,
  size,
  setSize,
  searchObj = false,
  sku,
}) => {
  const { monogramData } = useMonogramHook();
  const history = useHistory();
  const [havenLocalBaseArray, setHavenLocalBaseArray] = useState(
    havenBaseArray
  );
  useEffect(() => {
    if (window.location.pathname.includes("live-edge")) {
      let pathnameSplitArray = window.location.pathname.split("/");
      pathnameSplitArray[pathnameSplitArray.length - 1] = top?.sku;
      const search = new URLSearchParams(window.location.search);
      const baseSku = search.get("sku") || top?.relatedsku[0];
      setBase(liveEdgeBases.find((base) => base.sku === baseSku));
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set("sku", base.sku);
      history.replace({
        pathname: pathnameSplitArray.join("/"),
        search: urlParams.toString(),
      });
    }
  }, [top]);

  const [sOSizes, setSOSizes] = useState(benchMadeSONailSizes);

  useEffect(() => {
    if (frame?.sizes) {
      let updatedSizes = [];
      benchMadeSONailSizes.forEach((size) => {
        frame.sizes.forEach((frameSize) => {
          if (size.sku === frameSize) updatedSizes.push(size);
          else return;
        });
      });

      setSOSizes(updatedSizes);
    }
  }, [frame]);

  useEffect(
    () =>
      sOSizes?.length &&
      setSize(
        sOSizes?.find((sizes) => sizes.id === size?.id || sizes.id === size)
          ? size
          : sOSizes?.[0]
      ),
    [sOSizes]
  );

  let monogramFinishesArray = useMemo(
    () =>
      finishes.find((finish) => finish.name?.toLowerCase() === "custom")
        ?.products?.items,
    [history.location]
  );

  let initFinish = useMemo(() => {
    return { contrastDrawers, finish };
  }, [contrastDrawers]);

  const setElement = (arr, id) => arr?.find((finish) => finish.id === id);

  useEffect(() => {
    if (model) {
      let string = window.location.search;
      if (string) {
        if (string.includes("edit")) {
          string.replace("?edit=true", "");
        }
        let obj = getObjectWithOptionIds(string);
        obj["contrastDrawers"] &&
          setContrastDrawers(
            setElement(monogramFinishArray, obj["contrastDrawers"])
          );
        obj["finish"] &&
          setFinish(setElement(monogramFinishArray, obj["finish"]));
        obj["striping"] &&
          setStriping(setElement(monogramStripingArray, obj["striping"]));
      }
    }
  }, [model, history.location]);

  const [urbanLocalBaseArray, setUrbanLocalBaseArray] = useState(
    urbanBaseArray
  );
  const [urbanLocalArmArray, setUrbanLocalArmArray] = useState(urbanArmArray);
  const [urbanLocalBackArray, setUrbanLocalBackArray] = useState(
    urbanBackArray
  );

  const [seniorLocalArmArray, setSeniorLocalArmArray] = useState(
    seniorArmArray
  );
  const [seniorLocalPowerArray, setSeniorLocalPowerArray] = useState(
    seniorPowerArray
  );

  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (
      (base?.name === "Skirt" || base?.name === "Skirted") &&
      window.location.pathname.includes("benchmade-round-ottomans")
    )
      setNail(benchMadeRONails?.find((nail) => nail?.id === "S"));
  }, [base]);

  useEffect(() => {
    if (type === "Haven") {
      if (frame?.sku === "31" || frame?.sku === "32") {
        setChanged(true);
        let x = [];
        havenBaseArray.forEach((base) => {
          if (base.name === "Skirt") {
            x.push(base);
          }
        });
        setHavenLocalBaseArray(x);
      } else if (havenLocalBaseArray && havenLocalBaseArray.length === 1) {
        setHavenLocalBaseArray(havenBaseArray);
      }
    }

    if (type === "Urban") {
      if (frame?.id === "60" && seat?.id === "S") {
        setChanged(true);
        setModelReady(false);
        setUrbanLocalBackArray(
          urbanBackArray.filter((option) => option.id === "B")
        );
        setUrbanLocalBaseArray(
          urbanBaseArray.filter((option) => option.id === "P")
        );
        setUrbanLocalArmArray(
          urbanArmArray.filter((option) => option.id === "S")
        );
      } else if (frame?.id === "60" && seat?.id === "B") {
        setChanged(true);
        setModelReady(false);
        setUrbanLocalBackArray(
          urbanBackArray.filter((option) => option.id === "B")
        );
        setUrbanLocalBaseArray(
          urbanBaseArray.filter((option) => option.id === "P")
        );
        setUrbanLocalArmArray(urbanArmArray);
      } else {
        setUrbanLocalBackArray(urbanBackArray);
        setUrbanLocalBaseArray(urbanBaseArray);
        setUrbanLocalArmArray(urbanArmArray);
        setModelReady(true);
      }
    }

    if (type === "Senior") {
      if (frame?.name === "Wall Hugger Recliner with UV-C") {
        setChanged(true);
        setSeniorLocalArmArray(
          seniorArmArray.filter((option) => option.id === "M")
        );
        setSeniorLocalPowerArray(
          seniorPowerArray.filter((option) => option.id === "SR")
        );
      } else if (frame?.id === "Z") {
        setChanged(true);
        setSeniorLocalPowerArray(
          seniorPowerArray.filter(
            (option) =>
              option.id === "SR" || option.id === "MR" || option.id === "PR"
          )
        );
      } else if (frame?.id === "K") {
        setChanged(true);
        setSeniorLocalPowerArray(
          seniorPowerArray.filter(
            (option) =>
              option.id === "PB" || option.id === "MR" || option.id === "PR"
          )
        );
      } else if (frame?.id === "F") {
        setChanged(true);
        setSeniorLocalPowerArray(
          seniorPowerArray.filter((option) => option.id === "PL")
        );
      } else {
        setSeniorLocalArmArray(seniorArmArray);
        setSeniorLocalPowerArray(seniorPowerArray);
      }
    }
  }, [frame, seat]);

  useEffect(() => {
    if (type === "Haven" && changed) {
      setChanged(false);
      setBase(havenLocalBaseArray[0]);
    }
  }, [havenLocalBaseArray]);

  useEffect(() => {
    if (type === "Urban" && changed) {
      setChanged(false);
      setBase(urbanLocalBaseArray[0]);
    }
  }, [urbanLocalBaseArray]);

  useEffect(() => {
    if (type === "Urban" && changed) {
      setChanged(false);
      setArm(urbanLocalArmArray[0]);
    }
  }, [urbanLocalArmArray]);

  useEffect(() => {
    if (type === "Urban" && changed) {
      setChanged(false);
      setBack(urbanLocalBackArray[0]);
    }
  }, [urbanLocalBackArray]);

  useEffect(() => {
    if (type === "Senior" && changed) {
      setChanged(false);
      setArm(seniorLocalArmArray[0]);
    }
  }, [seniorLocalArmArray]);

  useEffect(() => {
    if (
      type === "Senior" &&
      changed &&
      !window.location.search.includes("powerOption")
    ) {
      setChanged(false);
      setPowerOption(seniorLocalPowerArray[0]);
    }
  }, [seniorLocalPowerArray]);

  useEffect(() => {
    if (!modelReady) {
      setTimeout(() => {
        setModelReady(true);
      }, 0);
    }
  }, [modelReady]);

  useEffect(() => {
    if (type === "Monogram" && initFinish?.finish !== finish)
      setContrastDrawers(finish);
  }, [finish]);

  let monogramFinishMixedArray = useMemo(
    () =>
      monogramFinishesArray?.map((data) => ({
        ...data,
        finishId: monogramFinishArray?.find((item) =>
          data?.name?.includes(item?.name)
        )?.id,
      })),
    [monogramFinishArray, monogramFinishesArray]
  );

  switch (type) {
    case "Haven":
      return (
        <div className={"steps-container-pdp"}>
          <Step
            step="1"
            title=" Frame"
            current={frame}
            options={havenFrameArray}
            setOption={setFrame}
          />
          {frame?.name !== "Ottoman" && (
            <>
              <Step
                step="2"
                title=" Back Cushion"
                current={back}
                options={havenBackArray}
                setOption={setBack}
              />
              <Step
                step="3"
                title=" Arm"
                current={arm}
                options={havenArmArray}
                setOption={setArm}
              />
            </>
          )}
          <Step
            step="4"
            title=" Base"
            current={base}
            options={havenLocalBaseArray}
            setOption={setBase}
          />
        </div>
      );

    case "Urban":
      return (
        <div className={"steps-container-pdp"}>
          <Step
            step="0"
            title=" Frame"
            current={frame}
            options={urbanFrameArray}
            setOption={(opt) => {
              setFrame(opt);
              if (opt.id !== "50") {
                setSeat(urbanSeatArray[0]);
              }
            }}
          />
          {(frame?.id === "50" || frame?.id === "60") && (
            <Step
              step="1"
              title=" Cushion"
              current={seat}
              options={urbanSeatArray}
              setOption={setSeat}
            />
          )}
          {frame?.name !== "Ottoman" && (
            <>
              <Step
                step="2"
                title=" Back Cushion"
                current={back}
                options={urbanLocalBackArray}
                setOption={setBack}
              />
              <Step
                step="3"
                title=" Arm"
                current={arm}
                options={urbanLocalArmArray}
                setOption={setArm}
              />
            </>
          )}
          <Step
            step="4"
            title=" Base"
            current={base}
            options={urbanLocalBaseArray}
            setOption={setBase}
          />
        </div>
      );

    case "Senior":
      return (
        <div className={"steps-container-pdp"}>
          <Step
            step="0"
            title=" Frame"
            current={frame}
            options={seniorFrameArray}
            setOption={(opt) => {
              setFrame(opt);
              // if (opt.id !== "50") {
              //   selectionsAndSelectors.setSeat(seatArray?.[0]);
              // }
            }}
          />
          {/*{selectionsAndSelectors?.frame?.id === "50" && (*/}
          {/*  <Step*/}
          {/*    step="1"*/}
          {/*    title=" Cushion"*/}
          {/*    current={selectionsAndSelectors.seat}*/}
          {/*    options={seatArray}*/}
          {/*    setOption={selectionsAndSelectors.setSeat}*/}
          {/*  />*/}
          {/*)}*/}
          {frame?.name !== "Ottoman" && !frame?.recliner && (
            <Step
              step="2"
              title=" Seat and Back"
              current={seatAndBack}
              options={seniorSeatAndBackArray}
              setOption={setSeatAndBack}
            />
          )}
          {frame?.name !== "Ottoman" && (
            <Step
              step="3"
              title=" Arm"
              current={arm}
              options={seniorLocalArmArray}
              setOption={setArm}
            />
          )}
          {!frame?.recliner && (
            <>
              <Step
                step="4"
                title=" Base"
                current={base}
                options={seniorBaseArray}
                setOption={setBase}
              />
            </>
          )}
          <Step
            step="5"
            title=" Left Accessory"
            current={accessoryLeft}
            options={seniorAccessoryArrayLeft}
            setOption={setAccessoryLeft}
          />
          <Step
            step="6"
            title=" Right Accessory"
            current={accessoryRight}
            options={seniorAccessoryArrayRight}
            setOption={setAccessoryRight}
          />
          {frame?.recliner && (
            <Step
              step="7"
              title=" Power Option"
              current={powerOption}
              options={seniorLocalPowerArray}
              setOption={setPowerOption}
            />
          )}
        </div>
      );
    case "Monogram":
      return (
        <div className={"steps-container-pdp"}>
          {!searchObj?.edit ? (
            <Step
              step="1"
              title=" Model"
              current={model}
              options={monogramData}
              setOption={setModel}
            />
          ) : null}
          <>
            {model?.striping && (
              <Step
                step="2"
                title=" Striping"
                current={striping}
                options={monogramStripingArray}
                setOption={setStriping}
              />
            )}
            <Step
              step="3"
              title=" Finish"
              current={finish}
              options={
                pathnameIncludes("monogram")
                  ? monogramFinishMixedArray
                  : monogramFinishMixedArray?.filter((monoFinish) =>
                      model?.finishIDs?.some(
                        (finishId) => finishId?.id === monoFinish?.finishId
                      )
                    )
              }
              // options={monogramFinishMixedArray}
              setOption={setFinish}
            />
            {model?.contrastDrawers && (
              <Step
                step="4"
                title=" Contrast Drawer Finish"
                current={contrastDrawers}
                options={monogramFinishesArray}
                setOption={setContrastDrawers}
              />
            )}
          </>
        </div>
      );
    case "LiveEdge":
      return (
        <div className={"steps-container-pdp"}>
          <Step
            step="1"
            title="Select Top"
            current={top}
            options={liveEdgeTops}
            setOption={setTop}
            sku={sku}
          />
          <Step
            step="2"
            title="Select Base"
            current={base}
            options={
              top
                ? liveEdgeBases.filter((item) =>
                    top?.relatedsku?.includes(item?.sku)
                  )
                : liveEdgeBases
            }
            setOption={setBase}
            sku={sku}
          />
        </div>
      );

    case "BenchmadeRoundOttomans":
      return (
        <div className={"steps-container-pdp"}>
          <Step
            step="1"
            title="Select Top"
            current={top}
            options={benchMadeROTops}
            setOption={setTop}
          />
          {base?.id !== "S" ? (
            <Step
              step="2"
              title="Select Nail"
              current={nail}
              options={
                base.id === "F"
                  ? benchMadeRONails.filter(
                      (nail) => nail.id === "N" || nail.id === "S"
                    )
                  : benchMadeRONails
              }
              setOption={setNail}
            />
          ) : null}
          <Step
            step="3"
            title="Select Base"
            current={base}
            options={benchMadeROBases}
            setOption={setBase}
          />
        </div>
      );

    case "BenchmadeSquareOttomans":
      return (
        <div className={"steps-container-pdp"}>
          <Step
            step="1"
            title="Select Style"
            current={frame}
            options={benchMadeSOStyles}
            setOption={setFrame}
          />
          <Step
            step="5"
            title="Select Size"
            current={
              sOSizes?.find((sizes) => sizes.id === size?.id)
                ? size
                : sOSizes?.[0]
            }
            options={sOSizes}
            setOption={setSize}
          />
          <Step
            step="2"
            title="Select Top"
            current={top}
            options={benchMadeSOTops}
            setOption={setTop}
          />
          {base?.id !== "S" ? (
            <Step
              step="3"
              title="Select Nail"
              current={nail}
              options={benchMadeSONails}
              setOption={setNail}
            />
          ) : null}
          <Step
            step="4"
            title="Select Base"
            current={base}
            options={benchMadeSOBases}
            setOption={setBase}
          />
        </div>
      );

    case "BenchmadeBenches":
      return (
        <div className={"steps-container-pdp"}>
          <Step
            step="1"
            title="Select Style"
            current={frame}
            options={benchMadeBenchesStyle}
            setOption={setFrame}
          />
          <Step
            step="2"
            title="Select Base"
            current={base}
            options={benchMadeBenchesBases}
            setOption={setBase}
          />
        </div>
      );

    default:
      return <></>;
  }
};

export default Steps;
