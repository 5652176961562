import {
  useCustomerQuery,
  useUpdateCustomerMutation,
} from "app/generated/graphql";
import React from "react";
import Newsletter from "./Newsletter";

export interface DataContainerProps {}

const DataContainer: React.FC<DataContainerProps> = (props) => {
  const { data } = useCustomerQuery();

  const [updateCustomer, { error, loading }] = useUpdateCustomerMutation();

  const toggleSubscription = React.useCallback(() => {
    // @ts-ignore
    const is_subscribed = !data?.customer?.is_subscribed;
    if (data?.customer?.id) {
      return updateCustomer({
        variables: {
          input: {
            is_subscribed,
          },
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateCustomer: {
            __typename: "CustomerOutput",
            customer: {
              __typename: "Customer",
              ...data.customer,
              // @ts-ignore
              is_subscribed,
            },
          },
        },
      });
    } else {
      console.error("No customer id.");
    }
  }, [data, updateCustomer]);

  return (
    <Newsletter
      // @ts-ignore
      subscribed={!!data?.customer?.is_subscribed}
      toggle={toggleSubscription}
      loading={loading}
      error={error}
    />
  );
};

export default DataContainer;
