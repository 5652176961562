/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @ts-nocheck

import React, { useEffect, useMemo, useState } from "react";
import { useBoolean } from "ahooks";
// import Icon from "app/assets/icon/icon";
// import { createEnterHandler } from "app/utils/utils";
// import { MDBTypography } from "mdbreact";
import Slider from "react-slick";
import MediaGalleryModal from "./components/MediaGalleryModal";
import { useMediaQuery } from "react-responsive";
import { styleManipulation } from "./helper";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";
import Fullscreen from "yet-another-react-lightbox/dist/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/dist/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/dist/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/dist/plugins/zoom";
import "yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css";
/**
 *
 * @typedef {{
 *   settings: React.ComponentProps<typeof Slider>
 *   images: import('app/generated/graphql').ProductInterface["media_gallery"];
 *   hideLabel: boolean;
 * }} MediaGalleryProps
 *
 * @type {React.FC<MediaGalleryProps>}
 */
const MediaGallery = ({ images = [], settings = {}, hideLabel = false }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const lightBoxImages = useMemo(() => {
    return images?.map((image) => {
      // @ts-ignore
      let array = image?.url?.split("/");
      const findCacheIndex = array.findIndex((el) => el === "cache");
      array.splice(findCacheIndex, 2);
      return { src: array.join("/"), alt: "image" };
    });
  }, [images]);

  useEffect(() => styleManipulation(modalOpen, isMobile), [modalOpen]);

  const additionalImages = images && images?.length > 1 && (
    <div
      tabIndex={0}
      role="button"
      // onClick={setModalOpen.setTrue}
      // onKeyUp={createEnterHandler(setModalOpen.setTrue)}
      className={`align-self-center cursor-pointer ${
        window.location.pathname.includes("build-your-own")
          ? "position-absolute"
          : ""
      } d-flex align-items-center view-image-details-container`}
    >
      {images?.slice(0, 3).map((image, i) => (
        <div
          key={i}
          className="img-holder"
          onClick={() => {
            setCurrentSlideIndex(i);
            setModalOpen(true);
          }}
        >
          <img
            src={image?.url ?? ""}
            alt={image?.label ?? ""}
            className="img-fluid"
          />
        </div>
      ))}
      {images?.length > 3 ? (
        <div
          className="img-holder"
          onClick={() => {
            setCurrentSlideIndex(3);
            setModalOpen(true);
          }}
        >
          {images?.length - 3 > 1 ? (
            <div className="bckg">
              <div className="num">
                <p>{images?.length - 4}+</p>
              </div>
            </div>
          ) : null}
          <img
            src={images?.[3]?.url ?? ""}
            alt={images?.[3]?.label ?? ""}
            className="img-fluid"
          />
        </div>
      ) : null}
      {/* <Icon icon="plus" size="2" />
  <MDBTypography className="ml-3 mb-0 text-gray-darken-3 view-image-details">
    View Image Details
  </MDBTypography> */}
    </div>
  );

  return (
    <div className="media-gallery position-relative d-flex flex-column">
      {!isMobile && additionalImages}
      {!window.location.pathname.includes("build-your-own") ? (
        <Slider {...settings} className="product-media-slider">
          {images?.map((image, index) => {
            return (
              <img
                className="pdp-slider-img"
                key={index}
                src={image?.url ?? ""}
                alt={image?.label || "The product"}
                onClick={() => {
                  setCurrentSlideIndex(index);
                  setModalOpen(true);
                }}
              />
            );
          })}
        </Slider>
      ) : null}
      {isMobile && additionalImages}
      {/* {images && (
        <MediaGalleryModal
          isOpen={modalOpen}
          toggle={setModalOpen.setFalse}
          images={images}
          currentSlideIndex={currentSlideIndex}
          hideLabel={hideLabel}
        />
      )} */}
      <Lightbox
        open={modalOpen}
        close={() => setModalOpen(false)}
        // @ts-ignore
        slides={lightBoxImages}
        // @ts-ignore
        plugins={[Fullscreen, Thumbnails, Zoom]}
        index={currentSlideIndex}
        zoom={{ maxZoomPixelRatio: 5 }}
        styles={{
          root: {
            "--yarl__color_backdrop": "rgba(0, 0, 0, .8)",
          },
        }}
        carousel={{ finite: true }}
        className={`${images?.length === 2 ? "yarl-two-images-fix" : ""} ${images?.length === 1 ? "yarl-one-image-fix" : ''}`}
      />
    </div>
  );
};

export default MediaGallery;
