import { commonActions } from "../../redux/data/common";
import { useDispatch } from "react-redux";

function useLocking() {
  let dispatch = useDispatch();

  const lock = async (request) => {
    dispatch(commonActions.lock(request));
  };

  const unlock = async (status) => {
    dispatch(commonActions.unlock(status));
  };

  return [lock, unlock];
}

export default useLocking;
