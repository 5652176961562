import { CustomerCartQuery } from "app/generated/graphql";
import React from "react";
import { useQuantityForm, useRemoveItemFromCart } from "../../hooks";
import { ItemProps } from "../../models";
import MobileItem from "./MobileItem";

export interface DataContainerProps
  extends Omit<ItemProps, "form" | "removeItemFromCart"> {
  cart: CustomerCartQuery["customerCart"];
}

const DataContainer: React.FC<DataContainerProps> = (props) => {
  const input = React.useMemo(
    () => ({ cart: props.cart, cart_item_id: Number(props.item.id) }),
    [props.cart, props.item]
  );

  const form = useQuantityForm(
    React.useMemo(() => ({ ...input, quantity: props.item.quantity }), [
      input,
      props.item.quantity,
    ])
  );

  const [removeItemFromCart] = useRemoveItemFromCart(input);

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  return (
    <MobileItem
      ref={inputRef}
      {...props}
      form={form}
      removeItemFromCart={removeItemFromCart}
    />
  );
};

export default DataContainer;
