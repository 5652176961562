import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdbreact";
import React from "react";

export interface OfflinePageProps {
  refetch: () => void;
}

const OfflinePage: React.FC<OfflinePageProps> = (props) => {
  const retry = React.useCallback(() => {
    props.refetch();
  }, [props]);
  return (
    <MDBContainer className="mt-5 mb-5 pb-5">
      <MDBRow className="mb-5 pb-5">
        <MDBCol>
          <MDBTypography className="mb-0 fs-72 text-gray-darken-3 fwthin">
            Content Can’t be Loaded
          </MDBTypography>
          <MDBTypography className="mt-4 pt-2 mb-0 fs-24 text-default">
            Sorry, you are not connected to the internet and this page cannot
            load
          </MDBTypography>
          <MDBTypography className="mt-4 mb-0 fs-13 text-primary">
            Let’s see if we can figure out what went wrong.
          </MDBTypography>
          <MDBTypography className="mt-3 mb-0 fs-13 text-primary">
            Check your connectivity and reconnect to the internet
          </MDBTypography>
          <MDBTypography className="mt-3 mb-0 fs-13 text-primary">
            Select a different internet connection
          </MDBTypography>
          <MDBBtn onClick={retry} className="m-0 mt-5">
            Retry
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default OfflinePage;
