import React, { useEffect, useRef } from "react";
import Icon from "../../../../../../assets/icon/icon";
import { useOnClickOutside } from "../../../../utils";
import Switch from "../../../../../../layout/Switch";

const CheckboxSidebar = ({
  visible,
  setVisible,
  availableOptions,
  optionState,
  optionDispatch,
  frame,
}) => {
  const clickOutsideRef = useRef(null);
  useOnClickOutside(clickOutsideRef, setVisible);

  useEffect(() => {
    availableOptions?.length &&
      availableOptions.forEach((element) =>
        optionDispatch({
          type: "setAttribute",
          attribute: element.title.toLowerCase().split(" ").join("_"),
          payload: false,
        })
      );
  }, [frame]);

  if (!visible) return <></>;

  return (
    <>
      <div
        className={"sidebar-container checkbox-sidebar-container"}
        ref={clickOutsideRef}
      >
        <div className={"sidebar-title-section"}>
          <Icon
            icon={"closeWhite"}
            onClick={() => {
              setVisible(false);
            }}
          />
          <h1>Select More Options</h1>
        </div>
        <div className={"checkbox-container"}>
          {availableOptions.map((option, index) => {
            return (
              <Switch
                key={`checkBox-option-${index}`}
                className={"checkbox-option"}
                //FIXME: When FF Wants
                // label={`${option.title} ${
                //   customer ? " + $" + option.value[0].price.toFixed(2) : ""
                // }`}
                label={option.title}
                checked={
                  optionState[
                    option.title.toLowerCase().split(" ").join("_")
                  ] || false
                }
                onChange={() =>
                  optionDispatch({
                    type: "setAttribute",
                    attribute: option.title.toLowerCase().split(" ").join("_"),
                    payload: !optionState[
                      option.title.toLowerCase().split(" ").join("_")
                    ]
                      ? option.value[0].option_type_id
                      : false,
                  })
                }
              />
            );
          })}
        </div>
        <div className={"sidebar-footer"}>
          <div
            className={"apply-button"}
            onClick={() => {
              setVisible(false);
            }}
          >
            <p>Apply</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckboxSidebar;
