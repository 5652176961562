import { ApolloError } from "apollo-client";
import {
  CustomerAddress,
  Maybe,
  UpdateCustomerAddressMutation,
} from "app/generated/graphql";
import { FormikConfig, useFormik } from "formik";
import React from "react";
import { ExecutionResult } from "react-apollo";
import { decodePhone } from "../../../utils";
import {
  AddressCategory,
  addressSchema,
  AddressValues,
  initialAddressValues,
} from "./config";
import Form, { FormProps } from "./Form";
import { useHistory } from "react-router-dom";

export interface StateContainerProps extends Omit<FormProps, "form" | "alert"> {
  onSubmit: (
    values: AddressValues
  ) => Promise<ExecutionResult<UpdateCustomerAddressMutation> | void>;
  address: Maybe<CustomerAddress>;
  error: Maybe<ApolloError>;
}

const StateContainer: React.FC<StateContainerProps> = ({
  address,
  ...props
}) => {
  const history = useHistory();
  const [alert, setAlert] = React.useState<FormProps["alert"] | null>(null);
  // @ts-ignore
  const formConfig: FormikConfig<AddressValues> = React.useMemo(() => {
    const country = props.countries.find(
      ({ id }) => id === address?.country_code
    );
    return {
      enableReinitialize: true,
      initialValues: {
        id: address?.id ?? initialAddressValues.id,
        firstname: address?.firstname ?? initialAddressValues.firstname,
        lastname: address?.lastname ?? initialAddressValues.lastname,
        email: address?.email ?? initialAddressValues.email,
        city: address?.city ?? initialAddressValues.city,
        country: country ?? initialAddressValues.country,
        postcode: address?.postcode ?? initialAddressValues.postcode,
        region_id:
          country?.available_regions?.find(
            (region) => region?.id === address?.region?.region_id
          )?.id ?? initialAddressValues.region_id,
        telephone:
          (address?.telephone && decodePhone(address.telephone)) ??
          initialAddressValues.telephone,
        address1: address?.street?.[0] ?? initialAddressValues.address1,
        address2: address?.street?.[1] ?? initialAddressValues.address2,
        addressCategory: address?.default_billing
          ? AddressCategory.mailing
          : address?.default_shipping
          ? AddressCategory.shipping
          : initialAddressValues.addressCategory,
        default_shipping:
          address?.default_shipping ?? initialAddressValues.default_shipping,
        default_billing:
          address?.default_billing ?? initialAddressValues.default_billing,
        address_label: address?.address_label,
      },
      validationSchema: addressSchema,
      onSubmit: (values) => {
        setAlert(null);
        return props.onSubmit(values).then(() => {
          setAlert({ color: "success", children: "Success!" });
          setTimeout(() => history.push("/customer/account/addresses"), 100);
        });
      },
    };
  }, [props, address]);
  const form = useFormik(formConfig);

  return (
    <Form
      {...props}
      form={form}
      alert={
        alert ??
        (props.error
          ? {
              color: "danger",
              children: props.error?.graphQLErrors?.[0] ?? props.error.message,
            }
          : null)
      }
    />
  );
};

export default StateContainer;
