import React, { useMemo, useContext } from "react";
import Icon from "app/assets/icon/icon";
import { WishlistJola } from "app/generated/graphql";
import ModalHeader from "app/layout/ModalHeader";
import { FormikProps } from "formik";
import { MDBBtn, MDBCol, MDBModal, MDBModalBody, MDBRow } from "mdbreact";
import { useLocation } from "react-router";
import { CreationWishlistValues, WishlistNameLength } from "../../config";
import { ProductWithSku, ToggleWishlistItemConfig } from "../../models";
import NameField from "../NameField";
import WishlistRow from "./components/WishlistRow";
import { useSelector } from "react-redux";
import CollectionsContext from "app/layout/collections/CollectionsContext";
import themeConfiguration from "config/themeConfiguration";
import { findConfigUrlFunction } from "./wishlistHelper";
export interface WishlistsModalProps
  extends Pick<React.ComponentProps<typeof MDBModal>, "isOpen"> {
  wishlists: WishlistJola[];
  product: ProductWithSku | null;
  onCheck: (config: ToggleWishlistItemConfig) => void;
  creationWishlistForm: FormikProps<CreationWishlistValues>;
  formShown: boolean;
  onShowForm: () => void;
  onClose: (ids: WishlistJola["id"][]) => void;
  formattedOptions?: Array<{}>;
}

const WishlistsModal: React.FC<WishlistsModalProps> = ({
  product,
  wishlists,
  onCheck,
  creationWishlistForm,
  formShown,
  // @ts-ignore
  selectedProduct: productSelected,
  onShowForm,
  onClose,
  formattedOptions,
}) => {
  const location = useLocation();
  const pathname = location.pathname;
  const { setSelectedProduct, selectedProduct, isFavorite } = useContext(
    CollectionsContext
  );

  const useProduct = useMemo(() => {
    return product
      ? product
      : productSelected
      ? productSelected
      : // @ts-ignore
        selectedProduct;
  }, [product, productSelected, selectedProduct]);

  const isCustom = useMemo(() => {
    // @ts-ignore
    return useProduct?.selected_customizable_options?.some((option) =>
      option.option_title.includes("Build your own image")
    ) && !window.location.pathname.includes("build-your-own")
      ? true
      : false;
  }, [useProduct]);

  // @ts-ignore
  const checkIfItemIsChecked = (wishistitem) => {
    // if (window.location.pathname.includes("customer/collections/lists/"))
    //   return true;
    if (
      !useProduct?.options &&
      !useProduct?.defaultOptions &&
      !useProduct?.selected_customizable_options
    )
      return true;
    let findConfigUrl = findConfigUrlFunction(
      useProduct?.selected_customizable_options
        ? useProduct?.selected_customizable_options
        : useProduct?.custom_image
        ? useProduct?.options
        : useProduct?.defaultOptions
        ? useProduct?.defaultOptions
        : useProduct?.options
    );
    let wishlistItemConfigUrl = findConfigUrlFunction(
      wishistitem?.selected_customizable_options
    );

    let urlToCompare = findConfigUrl?.value_string
      ? findConfigUrl?.value_string?.split("?")[1]
      : findConfigUrl?.option_value_title?.split("?")[1];

    if (
      wishlistItemConfigUrl?.option_value_title?.split("?")[1] === urlToCompare
    )
      return true;
    return false;
  };
  const itemExistsInProject = useMemo(() => {
    return wishlists.filter((wishlist) =>
      wishlist?.items?.some((wishistitem) => {
        if (
          wishistitem?.product?.sku === useProduct?.sku &&
          checkIfItemIsChecked(wishistitem)
        )
          return true;
      })
    );
  }, [useProduct]);

  // @ts-ignore
  const [newlyAdded, setNewlyAdded] = React.useState<WishlistJola["id"][]>([]);
  const [hideButton, setHideButton] = React.useState<boolean>(false);

  // @ts-ignore
  let customerToken = useSelector((state) => state?.customer?.data?.token);

  const getBYOImg = (selected_customizable_options: any[]) => {
    return (
      themeConfiguration.magento_url +
      selected_customizable_options?.find((option: any) =>
        option.option_value_title.includes("build_your_own_image")
      )?.option_value_title
    );
  };

  const handleClose = React.useCallback(() => {
    onClose(newlyAdded);
    setHideButton(false);
    setSelectedProduct(null);
  }, [onClose, newlyAdded]);

  return (
    <MDBModal
      isOpen={
        ((!!useProduct && itemExistsInProject?.length) ||
          (pathname.includes("customer/collections/") && useProduct)) &&
        customerToken
      }
      centered
      inline={false}
      overflowScroll
      noClickableBodyWithoutBackdrop={false}
      toggle={() => setSelectedProduct(null)}
      className={`favorites-modal`}
    >
      <MDBModalBody className="p-0">
        <ModalHeader onClose={handleClose}>Add to Lists</ModalHeader>
        <MDBRow className="mt-5 pt-3 mx-sm-5 mx-2 px-3 modal-content-holder">
          <MDBCol size="6" md="6" className="mb-3 text-center">
            <img
              style={{ maxWidth: "100%", height: "auto", maxHeight: "150px" }}
              // @ts-ignore
              src={
                isCustom
                  ? getBYOImg(useProduct?.selected_customizable_options)
                    ? getBYOImg(useProduct?.selected_customizable_options)
                    : useProduct?.custom_image
                  : useProduct?.small_image?.url
                  ? useProduct?.small_image?.url
                  : useProduct?.custom_image
              }
              // @ts-ignore
              alt={useProduct?.small_image?.label}
            />
          </MDBCol>
          <MDBCol
            size="6"
            md="6"
            className="mb-3 d-flex flex-column justify-content-center"
          >
            <h4 className="mb-2">
              {isCustom ? useProduct?.product?.sku : useProduct?.sku}
            </h4>
            <h4 className="mb-3">
              {isCustom ? useProduct?.product?.name : useProduct?.name}
            </h4>
          </MDBCol>
          <MDBCol className="mt-4 mb-3" size="12">
            <div className="mb-5 dark separator" />
          </MDBCol>
          <MDBCol>
            <MDBRow className="mb-3">
              <MDBCol className="d-flex justify-content-center">
                {!hideButton ? (
                  <button
                    className="btn w-100 create-new-project"
                    onClick={() => setHideButton(true)}
                  >
                    <Icon icon="plus" style={{ width: "20px" }} />
                    <span className="ml-3">Create New List</span>
                  </button>
                ) : formShown ? (
                  <form
                    className="d-flex align-items-center w-100"
                    onSubmit={creationWishlistForm.handleSubmit}
                  >
                    <NameField
                      length={creationWishlistForm.values.name.length}
                      maxLength={WishlistNameLength.max}
                      fieldProps={creationWishlistForm.getFieldProps("name")}
                      error={
                        creationWishlistForm.touched.name &&
                        creationWishlistForm.errors.name
                      }
                      hint="Create new folder"
                      wrapperClass="grow mr-3"
                      containerClass="m-0 w-100"
                    />
                    <MDBBtn
                      size="sm"
                      className="m-0"
                      type="submit"
                      disabled={creationWishlistForm.isSubmitting}
                      onClick={creationWishlistForm.submitForm}
                    >
                      Create
                    </MDBBtn>
                  </form>
                ) : (
                  <MDBBtn
                    className="w-100"
                    color="secondary"
                    onClick={onShowForm}
                  >
                    <div className="mr-4" style={{ transform: "scale(0.66)" }}>
                      <Icon icon="plus" />
                    </div>
                    Create New List
                  </MDBBtn>
                )}
              </MDBCol>
            </MDBRow>

            {wishlists
              // ?.filter((wishlist) => {
              //   return wishlist.name !== "My Favorites";
              // })
              .map((wishlist) => {
                let productSku = isCustom
                  ? useProduct?.product?.sku
                  : useProduct?.sku;

                const checked =
                  !!wishlist.items?.find(
                    (item) =>
                      item?.product?.sku === productSku &&
                      checkIfItemIsChecked(item)
                  ) &&
                  (isFavorite ||
                    (!useProduct?.options && !useProduct?.defaultOptions));

                return (
                  <WishlistRow
                    key={wishlist?.id}
                    {...wishlist}
                    // @ts-ignore
                    checked={checked}
                    onCheck={() => {
                      if (useProduct) {
                        if (isCustom) {
                          formattedOptions = useProduct?.selected_customizable_options?.map(
                            // @ts-ignore
                            (option) => {
                              return {
                                id: option?.option_id,
                                value_string: option?.option_value_id
                                  ? option?.option_value_id
                                  : option?.option_value_title,
                              };
                            }
                          );
                        }
                        // @ts-ignore
                        onCheck({
                          wishlist,
                          // @ts-ignore
                          product: product
                            ? product
                            : isCustom
                            ? useProduct?.product
                            : useProduct,
                          formattedOptions,
                        });

                        if (checked) {
                          setNewlyAdded((ids) =>
                            ids.filter((id) => id !== wishlist.id)
                          );
                        } else {
                          setNewlyAdded((ids) => ids.concat(wishlist.id));
                        }
                      }
                    }}
                  />
                );
              })}
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-5 mb-4 pt-3 mx-sm-5 mx-2 px-3">
          <MDBCol size="12">
            <MDBBtn
              size="sm"
              className="m-0 w-100"
              type="button"
              onClick={handleClose}
            >
              Done
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBModalBody>
    </MDBModal>
  );
};

export default WishlistsModal;
