import React from "react";
import { MDBRow, MDBCol } from "mdbreact";

const FinishSidebarToolbar = ({
  finishesCategories,
  selectedCategory,
  setSelectedCategory,
}) => {
  return (
    <MDBRow className="finishes-categories-toolbar">
      {finishesCategories &&
        finishesCategories.length > 0 &&
        finishesCategories.map((category, index) => {
          return (
            <MDBCol
              onClick={() => setSelectedCategory(category.id)}
              key={`finishes-category-item-${index}`}
              className={`finishes-categories-toolbar-item ${
                selectedCategory === category.id && "active-item"
              }`}
            >
              <p className="text-gray-darken-3">{category.name}</p>
            </MDBCol>
          );
        })}
    </MDBRow>
  );
};
export default FinishSidebarToolbar;
