import { useBoolean } from "ahooks";
import { useAllFilesQuery } from "app/generated/graphql";
import React from "react";
import { divisions, fileTypes } from "./config";
import _ from "lodash";
import {
  Division,
  FileId,
  SelectedFiles,
  SelectedTags,
  SortOrdersByTag,
  TagId,
  FileTypes,
} from "./models";
import Resources, { ResourcesProps } from "./Resources";
import {
  downloadFiles,
  filterFiles,
  groupFilesByTagId,
  groupTagsByCategory,
  isDomainFileData,
  sortFiles,
  toggleSelected,
} from "./utils";

export interface ResourcesContainerProps {}

const ResourcesContainer: React.FC<ResourcesContainerProps> = (props) => {
  const allFilesQuery = useAllFilesQuery({
    fetchPolicy: "no-cache",
  });

  const [division, setDivision] = React.useState<Division>(divisions[0]);
  const [fileTypesItem, setFileTypesItem] = React.useState<FileTypes>(
    fileTypes[0]
  );
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [selectedTags, setSelectedTags] = React.useState<SelectedTags>({});
  const handleToggleTag = React.useCallback((tagId: TagId) => {
    setSelectedTags(toggleSelected(tagId));
  }, []);

  const [selectedFiles, setSelectedFiles] = React.useState<SelectedFiles>({});
  const handleToggleFileById: ResourcesProps["onToggleFileById"] = React.useCallback(
    (fileId: FileId) => {
      setSelectedFiles(toggleSelected(fileId));
    },
    []
  );

  const [sortOrdersByTag, setSortOrdersByTag] = React.useState<SortOrdersByTag>(
    {}
  );

  const handleSortOrderByTagChange: ResourcesProps["onSortOrderByTagChange"] = React.useCallback(
    (tagId) => (prop, sortOrder) => {
      setSortOrdersByTag((orders) => ({
        ...orders,
        [tagId]: { ...(orders[tagId] ?? []), [prop]: sortOrder },
      }));
    },
    []
  );

  const domainFiles = React.useMemo(
    () => allFilesQuery.data?.getAllFiles?.filter(isDomainFileData) ?? [],
    [allFilesQuery.data]
  );
  let validDomainFiles = domainFiles.filter((item) => {
    return item.file !== "https://fairfieldm2.joladev.com/";
  });
  //Removed because select all doesn't exist
  // const handleSelectAllFiles = React.useCallback(() => {
  //   setSelectedFiles(() =>
  //     Object.fromEntries(validDomainFiles.map((file) => [file.file, true]))
  //   );
  // }, [validDomainFiles]);

  const filesAndTagByTagId = React.useMemo(
    () => sortFiles(groupFilesByTagId(domainFiles), sortOrdersByTag),
    [domainFiles, sortOrdersByTag]
  );

  const filteredFilesAndTagByTagId = React.useMemo(
    () => filterFiles(filesAndTagByTagId, division, selectedTags),
    [filesAndTagByTagId, division, selectedTags]
  );

  // const handleSelectAllCategoryTags: ResourcesProps["onSelectAllTags"] = React.useCallback(
  //   (category) => {
  //     setSelectedCategory(category);
  //     setSelectedTags((currentlySelectedTags) =>
  //       Object.fromEntries(
  //         Object.entries(filesAndTagByTagId).map(([tagId, { tag }]) => [
  //           tagId,
  //           tag.category === category ? true : currentlySelectedTags[tagId],
  //         ])
  //       )
  //     );
  //   },
  //   [filesAndTagByTagId]
  // );
  const handleSelectAllCategoryTags: ResourcesProps["onSelectAllTags"] = React.useCallback(() => {
    setSelectedTags(() =>
      Object.fromEntries(
        Object.entries(filesAndTagByTagId).map(([tagId]) => [tagId, true])
      )
    );
  }, [filesAndTagByTagId]);
  const onClearAll: ResourcesProps["onSelectAllTags"] = React.useCallback(() => {
    //@ts-ignore
    setSelectedTags({});
  }, [filesAndTagByTagId]);

  const tagsByCategory = React.useMemo(
    () => groupTagsByCategory(filesAndTagByTagId),
    [filesAndTagByTagId]
  );

  const [isOpen, { toggle }] = useBoolean();
  const handleToggle = React.useCallback(() => {
    toggle();
  }, [toggle]);

  // const handleDownloadSelected = React.useCallback((selectedFiles) => {
  //   downloadFiles(
  //     Object.keys(selectedFiles)
  //       .map((id) => validDomainFiles.find((file) => file.file === id))
  //       .filter(isDomainFileData)
  //   );
  // }, [domainFiles]);
  // Download files by category
  const handleDownloadAll = React.useCallback(
    (fileByCat) => {
      downloadFiles(fileByCat.filter(isDomainFileData));
    },
    [validDomainFiles]
  );

  const handleDownloadFile: ResourcesProps["onDownloadFile"] = React.useCallback(
    (id) => {
      const file = validDomainFiles.find((file) => file.file === id);
      if (file) {
        downloadFiles([file]);
      } else {
        console.error(
          `Cannot download file since the url "${id}" did not match any known file's url.`
        );
      }
    },
    [validDomainFiles]
  );

  return allFilesQuery.data ? (
    <Resources
      onDownloadFile={handleDownloadFile}
      onDownloadAll={handleDownloadAll}
      filesAndTagByTagId={filteredFilesAndTagByTagId}
      selectedTags={selectedTags}
      onToggleTag={handleToggleTag}
      onSelectAllTags={handleSelectAllCategoryTags}
      selectedFiles={selectedFiles}
      onToggleFileById={handleToggleFileById}
      // onSelectAllFiles={handleSelectAllFiles}
      tagsByCategory={tagsByCategory}
      division={division}
      onDivisionChange={setDivision}
      sortOrdersByTag={sortOrdersByTag}
      onSortOrderByTagChange={handleSortOrderByTagChange}
      isOpen={isOpen}
      toggle={handleToggle}
      onClearAll={onClearAll}
      fileTypesItem={fileTypesItem}
      //@ts-ignore
      setFileTypesItem={setFileTypesItem}
      fileTypes={fileTypes}
      selectedCategory={selectedCategory}
      //@ts-ignore
    />
  ) : null;
};

export default ResourcesContainer;
