import types from "./types";
import _reduceOptions from "./reducers/_reduceOptions";
// Main reducer
const productReducer = (state, action) => {
  if (state === undefined) {
    return [];
  }

  if (action.type === types._REDUCE_OPTIONS) {
    return _reduceOptions(state, action);
  }

  return state;
};

export default productReducer;
