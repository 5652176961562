import { WishlistJola } from "app/generated/graphql";
import CollectionsContext from "app/layout/collections/CollectionsContext";
import {
  CollectionChildren,
  collectionChildren,
} from "app/pages/customer/routes";
import clsx from "clsx";
import { MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdbreact";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";

export interface WishlistsWrapperProps {
  path: WishlistJola["name"];
  counts: { [key in keyof CollectionChildren]: number };
}

const countSize = 40;

const WishlistsWrapper: React.FC<WishlistsWrapperProps> = (props) => {
  const { setSelectedProduct } = useContext(CollectionsContext);
  useEffect(() => {
    setSelectedProduct(null);
  }, []);
  return (
    <>
      <Breadcrumbs />
      <MDBContainer fluid>
        {/* <MDBRow className="bg-gray-lighten-4">
          <MDBCol>
            <MDBContainer>
              <div className="d-flex favorites-toolbar">
                {Object.entries(collectionChildren).map(([key, child]) => {
                  const isActive = props.path?.startsWith(child.path);

                  const count = props.counts[key as keyof CollectionChildren];

                  return (
                    <Link
                      key={key}
                      to={child.path}
                      className={clsx(
                        "mr-5",
                        "pl-3",
                        "pt-4",
                        "pb-3",
                        "d-flex",
                        "align-items-center",
                        "border-bottom-5",
                        isActive ? " border-default" : "border-transparent"
                      )}
                    >
                      <MDBTypography
                        tag="h2"
                        variant="h2"
                        className={clsx(
                          "m-0 mr-4",
                          isActive ? "bold" : "fwregular"
                        )}
                      >
                        {child.label}
                      </MDBTypography>
                      <div
                        style={{ width: countSize, height: countSize }}
                        className={clsx(
                          "border-radius-05 border-2 border-gray align-items-center justify-content-center",
                          !!count ? "d-flex" : "d-none"
                        )}
                      >
                        {count ? (
                          <MDBTypography
                            // @ts-ignore
                            variant="body-2"
                          >
                            {count.toString()?.padStart(2, "0")}
                          </MDBTypography>
                        ) : null}
                      </div>
                    </Link>
                  );
                })}
              </div>
            </MDBContainer>
          </MDBCol>
        </MDBRow> */}
      </MDBContainer>
      <MDBContainer className="mt-2 pt-3 mb-5 pb-5">
        {props.children}
      </MDBContainer>
    </>
  );
};

export default WishlistsWrapper;
