import { customerPaths } from "app/pages/customer/routes";
import Select from "core/components/select";
import { History } from "history";
import { MDBCol } from "mdbreact";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { navigationItems } from "./config";
import Item from "./Item";

export interface NavigationProps extends RouteComponentProps {
  history: History;
}

const navigationOptions = navigationItems.map(({ label, pathname, itemClass }) => ({
  value: pathname,
  label,
  itemClass: itemClass
}));

const Navigation: React.FC<NavigationProps> = (props) => {
  const navigationOption = React.useMemo(
    () =>
      navigationOptions.find(({ value }) => props.location.pathname === value),
    [props]
  );
  return (
    <>
      <MDBCol
        size="12"
        lg="3"
        className="d-none d-md-block bg-gray-lighten-4 p-0 py-4 align-self-start account-sidebar"
      >
        {navigationItems.map((item) => (
          <Item
            key={item.pathname}
            {...item}
            active={
              item.pathname === customerPaths.children.account.path
                ? item.pathname === props.location.pathname
                : props.location.pathname.includes(item.pathname)
            }
          />
        ))}
        {/* <MDBBox className="mx-4 my-3 border-bottom-1" /> */}
      </MDBCol>
      <MDBCol className="d-md-none" size="12" lg="3">
        <Select
          className="d-md-none w-100"
          options={navigationOptions}
          value={navigationOption}
          onChange={({ value }: typeof navigationOptions[number]) => {
            props.history.push(value);
          }}
        />
      </MDBCol>
    </>
  );
};

export default Navigation;
