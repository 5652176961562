import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { customerPaths } from "../routes";
import Navigation from "./components/Navigation/Navigation";
import Account from "./pages/Account/";
import AccountInfo from "./pages/AccountInfo";
import Addresses from "./pages/Addresses";
import CustomerOrders from "./pages/CustomerOrders";
import Newsletter from "./pages/Newsletter";
import useCustomer from "../../../../core/state/hooks/customerHooks/useCustomer";
import { useHistory } from "react-router-dom";
import Claims from "./pages/Claims";

export interface AccountSwitchProps extends RouteComponentProps {}

const AccountRouteContainer: React.FC<AccountSwitchProps> = (props) => {
  const [_, isLoggedIn] = useCustomer();
  const history = useHistory();
  if (!isLoggedIn()) {
    history.push("/");
  }
  return (
    <MDBContainer className="my-5 pb-5 account-container">
      <MDBRow className="p-4 p-lg-0 pb-lg-5 mb-5">
        <Navigation {...props} />
        <MDBCol size="12" lg="9" className="mt-4 mt-lg-0">
          <Switch>
            <Route
              path={customerPaths.children.account.path}
              exact
              component={Account}
            />
            <Route
              path={customerPaths.children.account.children.info.path}
              component={AccountInfo}
            />
            <Route
              path={customerPaths.children.account.children.addresses.path}
              component={Addresses}
            />
            <Route
              path={customerPaths.children.account.children.newsletter.path}
              component={Newsletter}
            />
            <Route
              path={customerPaths.children.account.children.orders.path}
              component={CustomerOrders}
            />
            {/* <Route
              path={customerPaths.children.account.children.claims.path}
              component={Claims}
            /> */}
          </Switch>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default AccountRouteContainer;
