import Icon from "app/assets/icon/icon";
import { createEnterHandler } from "app/utils/utils";
import { MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React from "react";

export interface ModalHeaderBackProps {
  onBack: () => void;
}

const ModalHeaderBack: React.FC<ModalHeaderBackProps> = (props) => {
  return (
    <MDBRow>
      <MDBCol>
        <div className="bg-default py-3 position-relative d-flex align-items-center justify-content-center">
          <div
            className="left-0 ml-4 pl-2 position-absolute cursor-pointer d-flex align-items-center"
            onClick={props.onBack}
            onKeyUp={createEnterHandler(props.onBack)}
            tabIndex={0}
            role="button"
          >
            <Icon icon="leftMobileWhite" />
            <MDBTypography className="fs-16 text-white ml-4 mb-0">
              Back
            </MDBTypography>
          </div>
          <MDBTypography className="mb-0 text-white display-2 text-center modal-heading">
            {props.children}
          </MDBTypography>
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default ModalHeaderBack;
