import React, { useMemo, useState } from "react";
import { useQuery } from "react-apollo-hooks";
import { GET_FAQ } from "./queries";
import FaqItem from "./faqItem";
import Breadcrumbs from "app/layout/breadcrumbs/index.js";
import InputField from "core/components/inputField";
import CmsBlock from "app/layout/cmsBlock";

const FrequentlyAskedQuestions = (query = null) => {
  let isInfoPanel = useMemo(() => (query?.query ? true : false), [query]);

  const { data, loading, error } = useQuery(
    query?.query ? query?.query : GET_FAQ,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [searchVal, setSearchVal] = useState("");
  const faq = useMemo(
    () =>
      data?.getFAQ?.find((x) => {
        return x.title === "faq";
      }),
    [data]
  );

  const filteredItems = useMemo(() => {
    if (isInfoPanel) return data?.getNewsInfo?.news;
    let items = [];
    if (!faq) return items;
    if (!searchVal) {
      return faq.items;
    } else {
      return faq.items.filter((question) => {
        let searchSmall = searchVal.toLowerCase();
        return (
          question.title.toLowerCase().includes(searchSmall) ||
          question.content.toLowerCase().includes(searchSmall)
        );
      });
    }
  }, [faq, searchVal, isInfoPanel, data]);

  return (
    <div
      className={`page-full-width faq-page-container container`}
      itemScope
      itemType="http://schema.org/FAQPage"
      mainPageContent
    >
      <Breadcrumbs />
      <div
        className={`title-container d-flex flex-md-row flex-column justify-content-between mb-4 mt-4`}
      >
        <h1 className="mb-4 mb-md-0">{`${
          isInfoPanel ? "Announcements" : "Frequently Asked Questions"
        }`}</h1>
        {!isInfoPanel ? (
          <InputField
            name={"search"}
            type={"text"}
            required={false}
            value={searchVal}
            onChange={(event) => setSearchVal(event.target.value)}
            // label={"Search questions"}
            hint="Search questions"
          />
        ) : null}
      </div>
      <div className={`row listing-and-image-wrapper-faq`}>
        <div className={`col-md-12`}>
          {filteredItems?.map((item, index) => (
            <FaqItem item={item} key={`faq-item-${index}`} index={index} />
          ))}
          {/* <div className={`contact-us-faq-page`}>
            <p className="mb-0">Didn’t find what you’ve been looking for?</p>
            <u>
              <a href={`mailto:info@fairfield.joladev.com`}>CONTACT US</a>
            </u>
          </div> */}
        </div>
      </div>
      {!isInfoPanel ? <CmsBlock id="faq-contact-us" /> : null}
    </div>
  );
};

export default FrequentlyAskedQuestions;
