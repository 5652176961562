// @ts-check

import { messagesActions } from "core/state/redux/data/messages";
import React from "react";
import { useMutation } from "react-apollo-hooks";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { customerPaths } from "../routes";
import { createCustomerMutation } from "./graphql";
import RegisterStepContainer from "./StepsContainer";

/**
 * @typedef {object} DataContainerProps
 */
/**
 * @type {React.FC<DataContainerProps>}
 */
const DataContainer = () => {
  const dispatch = useDispatch();

  const EXISTING_USER = `You are an existing Fairfield user! Please login or reset your password with your email address in case you do not have a password setup.`;

  const history = useHistory();

  /**
   * @type {[import('react-apollo-hooks').MutationFn<void, import('./graphql').CustomerInput!>, import('react-apollo').MutationResult<void>]}
   */
  const [createCustomer] = useMutation(createCustomerMutation);

  /**
   * @type {[import('react-apollo-hooks').MutationFn<void, import('./graphql').CustomerAddressInput>, import('react-apollo').MutationResult<void>]}
   */

  /**
   * @typedef {'initial' | 'loading' | Error | 'success'} FormState
   *
   * @type {[FormState, React.Dispatch<React.SetStateAction<FormState>>]}
   */
  // @ts-ignore
  const [formStatus, setFormStatus] = React.useState("initial");
  // @ts-ignore
  const hearAboutMethod = (hearAbout) => {
    let arr = [];
    for (const [i, v] of Object.entries(hearAbout)) {
      if (v === true || typeof v === "string") {
        let index = Object.entries(hearAbout).findIndex(([key]) => key === i);
        arr.push(index);
      }
    }
    return arr.join(",");
  };

  /**
   * @type {(input: Parameters<import('./StepsContainer').StepsContainerProps['onSubmit']>[0]) => Promise<void>}
   */

  const handleSubmit = React.useCallback(
    ({
      companyDetails: {
        isNew,
        companyName,
        taxId,
        fairfield_account_number,
        industryType,
        businessType,
        channel,
        websiteUrl,
      },
      contactInfo: { email, firstName, lastName, phone, password },
      mailingValues,
      shippingValues,
      is_subscribed,
      taxIdDoc,
    }) => {
      if (mailingValues.country?.value && industryType && businessType) {
        setFormStatus("loading");
        /**
         * @type {import('./graphql').CustomerAddressInput}
         */
        const mailingVariables = {
          company: companyName.trim(),
          city: mailingValues.city.trim(),
          contact_name: `${mailingValues.firstName.trim()} ${mailingValues.lastName.trim()}`,
          firstname: mailingValues.firstName.trim(),
          lastname: mailingValues.lastName.trim(),
          country_id: mailingValues.country.value,
          postcode: mailingValues.zipCode,
          region: {
            region_id: mailingValues.region ? mailingValues.region : -1,
          },
          street: [
            mailingValues.address1.trim(),
            ...(mailingValues.address2 ? [mailingValues.address2] : []),
          ],
          telephone: mailingValues.phone.split("-").join(""),
          website: websiteUrl,
          email: mailingValues.email,
        };
        /**
         * @type {import('./graphql').CustomerAddressInput}
         */
        const shippingVariables = shippingValues
          ? {
              company: companyName.trim(),
              city: shippingValues.city.trim(),
              contact_name: `${shippingValues.firstName.trim()} ${shippingValues.lastName.trim()}`,
              firstname: shippingValues.firstName.trim(),
              lastname: shippingValues.lastName.trim(),
              country_id: shippingValues.country?.value,
              postcode: shippingValues.zipCode,
              region: {
                region_id: shippingValues.region ? shippingValues.region : -1,
              },
              street: [
                shippingValues.address1.trim(),
                ...(shippingValues.address2 ? [shippingValues.address2] : []),
              ],
              telephone: shippingValues.phone.split("-").join(""),
              website: websiteUrl,
              email: shippingValues.email,
            }
          : mailingVariables;

        let customerObject = {
          is_new: isNew,
          taxvat: taxId.split("-").join(""),
          fairfield_account_number: fairfield_account_number
            .split("-")
            .join(""),
          industry_type: industryType,
          business_type: businessType,
          hear_about: hearAboutMethod(channel),
          tradeshow_specify: channel["trade show"],
          other_specify: channel["other"],
          email,
          phone: phone.split("-").join(""),
          firstname: firstName.trim(),
          lastname: lastName.trim(),
          password,
          is_subscribed,
          tax_id_doc: "",
        };

        return createCustomer({
          variables: {
            // @ts-ignore
            input: {
              ...customerObject,
              billing_address: { ...mailingVariables, default_billing: true },
              shipping_address: {
                ...shippingVariables,
                default_shipping: true,
              },
            },
          },
        })
          .then(() => {
            setFormStatus("success");
            history.replace(
              customerPaths.children.register.children.thankYou.path
            );
          })
          .catch(
            /** @param {import('apollo-client').ApolloError} error */ (
              error
            ) => {
              if (
                error.graphQLErrors?.[0]?.message.includes(
                  "email address already exists"
                )
              ) {
                setFormStatus(new Error(EXISTING_USER));
              } else {
                setFormStatus(
                  new Error(error.graphQLErrors?.[0]?.message ?? error.message)
                );
              }
            }
          );
      } else {
        dispatch(
          messagesActions.addMessage(
            "Registration error: A required field is missing. Please check all steps.",
            "error"
          )
        );
        return Promise.resolve();
      }
    },
    [createCustomer, history, dispatch]
  );
  return (
    <>
      <RegisterStepContainer
        onSubmit={handleSubmit}
        // @ts-ignore
        isSubmitting={formStatus === "loading"}
        // @ts-ignore
        error={formStatus instanceof Error ? formStatus : null}
      />
    </>
  );
};

export default DataContainer;
