import React from "react";
import Skeleton from "react-loading-skeleton";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { useMediaQuery } from "react-responsive";

const SkeletonHeader = (props) => {
  let isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <>
      {!isMobile ? (
        <>
          <MDBContainer fluid className="header-container d-none d-lg-block">
            <MDBRow>
              <MDBCol className="header-top-container">
                <Skeleton height={`42px`} />
              </MDBCol>
            </MDBRow>
            <MDBRow className="py-0 px-5">
              <MDBCol className="ml-5 py-5">
                <Skeleton width={`100%`} height={`3.2rem`} />
              </MDBCol>
              <MDBCol className=" ml-5 py-5">
                <Skeleton width={`100%`} height={`3.2rem`} />
              </MDBCol>
              <MDBCol className=" ml-5 py-5">
                <Skeleton width={`100%`} height={`3.2rem`} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </>
      ) : (
        <>
          <MDBContainer fluid>
            <MDBRow className={`mx-0 py-2`}>
              <MDBCol className={`p-0`}>
                <Skeleton width={`100%`} height={`5rem`} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </>
      )}
    </>
  );
};
export default SkeletonHeader;
