// @ts-check

import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { useSelector } from "react-redux";
import FabricPdp from "./fabricPdp";
import Skeleton from "react-loading-skeleton";
import { FABRIC_PRODUCT, FABRIC_PRODUCT_CUSTOMER } from "./fabricQueries";
import { gql } from "graphql.macro";
import SkeletonProduct from "./fabricPdpSkeleton";

const COLORWAYS = gql`
  query Colorways($skus: [String]) {
    products(filter: { sku: { in: $skus } }) {
      items {
        name
        url_key
        sku
        url_rewrites {
          url
          parameters {
            name
            value
          }
        }
        thumbnail {
          url
        }
      }
    }
  }
`;
/**
 *
 * @type {React.FC<*>}
 */
const ProductQueryContainer = (props) => {
  let productSku =
    props.productSku !== undefined ? props.productSku : props.urlResolver?.sku;

  // @ts-ignore
  let customerToken = useSelector((state) => state?.customer?.data?.token);

  let filter = {
    filters: { sku: { eq: productSku } },
    pageSize: 1,
    currentPage: 1,
  };
  const FABRIC_QUERY = customerToken ? FABRIC_PRODUCT_CUSTOMER : FABRIC_PRODUCT;

  let { data, loading } = useQuery(FABRIC_QUERY, {
    variables: filter,
    fetchPolicy: "cache-first",
  });

  let productData = loading ? false : data?.products?.items[0];
  let colorwaysString = productData?.colorways;
  let colorwaysSkus = colorwaysString !== null && colorwaysString?.split(",");
  const {
    data: colorwaysData,
    loading: colorwaysLoading,
    error: colorwaysError,
  } = useQuery(COLORWAYS, {
    skip: !colorwaysSkus,
    variables: { skus: colorwaysSkus },
  });

  if (colorwaysLoading) return <Skeleton />;
  let aggregations = data?.products?.aggregations;
  if (loading) {
    return <SkeletonProduct />;
  }

  return (
    <FabricPdp
      {...props}
      productData={productData}
      colorwaysData={colorwaysData}
      aggregations={aggregations}
    />
  );
};

export default ProductQueryContainer;
