import Icon from "app/assets/icon/icon";
import { MDBBox, MDBTypography } from "mdbreact";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import Items from "./items";

const ToggleMenu = (props) => {
  let {
    items,
    menuItem,
    isOpen,
    setIsOpen,
    toggleHamburger,
    open,
    setOpen,
    closeBurger,
    collapseId,
    setCollapseId,
    setMenuItemSelectedById,
    menuItemSelectedById,
  } = props;
  const history = useHistory();
  const toggle = React.useCallback(() => {
    if (menuItem.name === "Consoles") {
      history.push("/products/shop-by/casegoods/consoles");
      document.getElementById("nav-icon1").click();
    }
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);
  if (menuItem.product_count === 0 && menuItem.children.length < 0) {
    return null;
  }

  // Client request to show same category under featured collections and upholstery
  //  Replace "featured-collections" with "upholstery" in the url_path
  if (menuItem?.children) {
    menuItem.children = menuItem.children.map((child) => {
      const newUrlPath = child.url_path
        .split("/")
        .map((path) => path.replace("featured-collections", "upholstery"))
        .join("/");
      return { ...child, url_path: newUrlPath };
    });
  }

  // Filter the children to only include those where include_in_menu is 1
  const includedChildren = menuItem.children?.filter(
    (child) => child.include_in_menu === 1
  );

  return (
    <div className={`toggle-menu-item`}>
      <div
        className="menu-item text-uppercase"
        id={`menu-item-${menuItem.name}`}
        onClick={toggle}
        onKeyUp={toggle}
        tabIndex={0}
        role="button"
      >
        <MDBBox className="dropdown-toggle nav-link static-link">
          <Link
            to={"/" + menuItem.url_path}
            disabled
            className={` link-mobile ${
              menuItem.children > 0
                ? "text-decoration-none text-uppercase"
                : "text-decoration-none text-uppercase disabled"
            } `}
            key={`nav-item-not-uncontrolled-nav-${menuItem.url_path}`}
            onClick={(e) => {
              if (
                collapseId.includes(menuItem.id) &&
                collapseId[collapseId.length - 1] !== menuItem.id
              ) {
                e.preventDefault();
              } else {
                toggleHamburger(props);
                closeBurger();
              }
            }}
          >
            <MDBTypography tag="span" className="body-1">
              {menuItem.name}
            </MDBTypography>
          </Link>
          {typeof menuItem !== "undefined" &&
          typeof menuItem.children !== "undefined" &&
          menuItem.children.length > 0 &&
          menuItem.include_in_menu &&
          includedChildren.length ? (
            <MDBTypography
              tag="span"
              className="body-1"
              onClick={(e) => {
                setMenuItemSelectedById([...menuItemSelectedById, menuItem]);
                if (!collapseId.includes(menuItem.id)) {
                  setCollapseId([...collapseId, menuItem.id]);
                }
              }}
            >
              <Icon
                icon="rightMobile"
                className={menuItem.name === "Consoles" && "d-none"}
                onClick={(e) => {
                  let toggleDiv = document.querySelectorAll(".toggle-dropdown");
                  toggleDiv.forEach((el) => {
                    el.scrollTop = 0;
                  });
                }}
              />
            </MDBTypography>
          ) : (
            <></>
          )}
        </MDBBox>
      </div>
      {typeof items !== "undefined" && items.length > 0 && (
        <MDBBox
          className={`toggle-dropdown p-0 text-uppercase ${
            collapseId.includes(menuItem.id) ? "active" : "not-active"
          } ${
            collapseId.includes(menuItem.id) &&
            collapseId[collapseId.length - 1] === menuItem.id
              ? "last-active"
              : ""
          }`}
        >
          <Items
            menuItem={menuItem}
            items={items}
            closeBurger={closeBurger}
            toggleHamburger={toggleHamburger}
            open={open}
            setOpen={setOpen}
            collapseId={collapseId}
            setCollapseId={setCollapseId}
            setMenuItemSelectedById={setMenuItemSelectedById}
            menuItemSelectedById={menuItemSelectedById}
          />
        </MDBBox>
      )}
    </div>
  );
};

export default ToggleMenu;
