import { isNil } from "lodash";
import { DeepNonNullable } from "utility-types/dist/mapped-types";

export function isDefined<Value>(value: Value): value is NonNullable<Value> {
  return !!value;
}

export function hasId(withId: {
  id?: unknown;
}): withId is Omit<typeof withId, "id"> &
  DeepNonNullable<Pick<typeof withId, "id">> {
  return !isNil(withId.id);
}
