// @ts-check

import { customerActions } from "app/state/redux/data/customer"; // * the absence of this import causes a dependency resolution issue
import { selectIsLoggedIn } from "app/state/redux/data/customer/selectors";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LoginDataContainer from "./DataContainer";

/**
 *
 * @typedef {Pick<import('./DataContainer').LoginStateContainerProps, 'hideModal'>} LoginReduxContainerProps
 *
 * @type {React.FC<LoginReduxContainerProps>}
 */
const LoginReduxContainer = (props) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();

  /**
   * @type {typeof customerActions["loginCustomer"]}
   */
  const login = React.useCallback(
    (...params) => dispatch(customerActions.loginCustomer(...params)),
    [dispatch]
  );
  const loginElement = (
    <LoginDataContainer {...props} isLoggedIn={isLoggedIn} login={login} />
  );

  return loginElement;
};

export default LoginReduxContainer;
