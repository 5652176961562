import gql from "graphql-tag";

export const GET_FAQ = gql`
  query getFAQ {
    getFAQ {
      entity_id
      title
      items {
        content
        entity_id
        title
        category_id
      }
    }
  }
`;