import React from "react";
// --------------------------------------------------------------------------------------------------------------------------------------------------------
// This is the default modal wrapper, change it to comply with the modals in your design
// --------------------------------------------------------------------------------------------------------------------------------------------------------
const ModalWrapper = (props) => {
  return (
    <div className="pwa-modal">
      <div className="content">{props.children}</div>
    </div>
  );
};

export default ModalWrapper;
