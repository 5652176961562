import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { cartPaths } from "./config";
import DataContainer from "./DataContainer";
import Submit from "./pages/Submit";
import ThankYou from "./pages/ThankYou";

export interface RouteContainerProps {}

const RouteContainer: React.FC<RouteContainerProps> = (props) => {
  return (
    <Switch>
      <Route path={cartPaths.path} exact component={DataContainer} />
      <Route path={cartPaths.children.submit.path} exact component={Submit} />
      <Route
        path={cartPaths.children.thankYou.path}
        exact
        component={ThankYou}
      />
      <Route render={() => <Redirect to={cartPaths.path} />} />
    </Switch>
  );
};

export default RouteContainer;
