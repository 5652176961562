import CmsBlock from "app/layout/cmsBlock";
import ProductWidget from "app/layout/productWidget";
import { getBadges } from "app/pages/collections/utils";
import { MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import MagicSliderDots from "react-magic-slider-dots";

const WhatsNew = ({ products, categoryLink }) => {
  const sliderSettingsWhats = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />;
    },
  };
  let isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <MDBContainer className="whats-new-container my-5 py-5">
      <MDBRow className="pb-3">
        <MDBCol className="whats-new-title">
          <MDBTypography
            tag="h1"
            variant="display-1"
            style={{ color: "#404040" }}
          >
            What's New
          </MDBTypography>
        </MDBCol>
        <MDBCol className="d-flex justify-content-end align-items-center">
          <Link to={categoryLink}>
            <MDBTypography tag="h3">View All</MDBTypography>
          </Link>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12" lg="6">
          <CmsBlock
            id="homepage_whats_new"
            skeletonProps={{ height: "70rem", width: "100%" }}
          />
        </MDBCol>
        <MDBCol size="12" lg="6">
          <MDBRow className="border-top border-transparent">
            {!isMobile ? (
              products &&
              products?.length &&
              products?.slice(0, 4).map((product, pIndex) => {
                const badges = getBadges(product);
                return (
                  <ProductWidget
                    colSize="6"
                    showFavorites={false}
                    key={pIndex}
                    {...product} //@Todo: Put this back when the issue with Images is fixed
                    className={`px-5 ${
                      pIndex < 2 ? "pb-1 mb-2" : "pb-0"
                    }`.trim()}
                    is_new={product.is_new}
                    badges={badges}
                    skeletonProps={{ height: "35rem", width: "25rem" }}
                  />
                );
              })
            ) : (
              <MDBCol size="12">
                <MDBContainer>
                  <MDBRow>
                    <MDBCol>
                      <Slider className={"dark-dots"} {...sliderSettingsWhats}>
                        {products?.slice(0, 4).map((product, pIndex) => {
                          const badges = getBadges(product);
                          return (
                            <ProductWidget
                              colSize="12"
                              showFavorites={false}
                              key={pIndex}
                              {...product} //@Todo: Put this back when the issue with Images is fixed
                              className={`px-5 ${
                                pIndex < 2 ? "pb-1 mb-2" : "pb-0"
                              }`.trim()}
                              is_new={product.is_new}
                              badges={badges}
                              skeletonProps={{
                                height: "35rem",
                                width: "25rem",
                              }}
                            />
                          );
                        })}
                      </Slider>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBCol>
            )}
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default React.memo(WhatsNew);
