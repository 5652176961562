import { MDBBtn, MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { calculateUpholsteryOptionSelectorPrice } from "../options/functions";

const FabricSidebarFooter = ({
  setViewDetails,
  isSelected,
  allItems,
  selectedFabric,
  setSelectedFabric,
  selectedFilters,
  setSelectedFilters,
  hideModal,
  defaultFabric,
  calculatedUpholsteryOptionsFabrics,
  currentOption,
  selectedFabricOption,
}) => {
  const isMobile = useMediaQuery({ maxWidth: "600px" });
  const [calculatedPrice, setCalculatedPrice] = useState("");
  // useEffect(() => {
  //   setSelectedFabric(
  //     allItems &&
  //       allItems.length > 0 &&
  //       allItems.find((item) => {
  //         return item.id === isSelected;
  //       })
  //   );
  // }, [isSelected, allItems, setSelectedFabric]);

  useEffect(() => {
    if (calculatedUpholsteryOptionsFabrics) {
      if (selectedFabricOption?.name === selectedFabric?.name) return;
      setCalculatedPrice(
        calculateUpholsteryOptionSelectorPrice(
          calculatedUpholsteryOptionsFabrics,
          selectedFabric ? selectedFabric : defaultFabric
        )
      );
    }
  }, [calculatedUpholsteryOptionsFabrics, selectedFabric]);

  return (
    (isMobile && selectedFilters && (
      <MDBRow className="bg-white py-4 d-flex align-items-center sidebar-footer">
        <MDBCol
          style={{
            height: "100%",
          }}
          className="sidebar-footer-column"
        >
          <MDBRow className="footer-buttons-wrapper mt-5">
            <MDBCol
              size="12"
              className="d-flex justify-content-end align-items-center"
            >
              <MDBBtn
                className="btn-primary continue"
                onClick={() => setSelectedFilters(false)}
              >
                Apply
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    )) || (
      <MDBRow className="bg-gray-lighten-4 py-4 d-flex align-items-center sidebar-footer">
        <MDBCol
          style={{
            height: "100%",
          }}
          className="sidebar-footer-column"
        >
          <MDBRow className="selected-item ">
            <MDBCol>
              <MDBRow>
                <MDBCol size="2">
                  <MDBTypography tag="h4" className="body-1 text-default fs-16">
                    Selected
                  </MDBTypography>
                </MDBCol>
                <MDBCol>
                  <MDBTypography
                    tag="h4"
                    className="body-2 text-gray-darken fs-16"
                  >
                    {selectedFabric
                      ? selectedFabric?.name
                      : currentOption.title.toLowerCase() !== "base trim" &&
                        currentOption.title.toLowerCase() !==
                          "decorative type frame" &&
                        currentOption.title.toLowerCase() !== "skirt trim" &&
                        currentOption.title.toLowerCase() !==
                          "decorative cord" &&
                        currentOption.title.toLowerCase() !== "fringe"
                      ? defaultFabric?.name
                      : "-"}
                  </MDBTypography>
                </MDBCol>
                {/*<MDBCol>*/}
                {/*  <MDBTypography*/}
                {/*    tag="h4"*/}
                {/*    className="body-2 text-gray-darken fs-16"*/}
                {/*  >*/}
                {/*    {calculatedPrice}*/}
                {/*  </MDBTypography>*/}
                {/*</MDBCol>*/}
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBRow className="footer-buttons-wrapper mt-5">
            {isMobile && (
              <MDBCol size="6" className="d-flex align-items-center">
                <MDBBtn
                  disabled={!selectedFabric}
                  className="btn-secondary view-details"
                  onClick={() => setViewDetails(true)}
                >
                  View details
                </MDBBtn>
              </MDBCol>
            )}
            <MDBCol
              size={isMobile ? "6" : "12"}
              className="d-flex justify-content-center align-items-center"
            >
              <MDBBtn
                className="btn-primary continue"
                onClick={() => hideModal(true)}
              >
                Apply
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    )
  );
};

export default FabricSidebarFooter;
