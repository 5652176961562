import { MDBRow } from "mdbreact";
import React from "react";
import LogoMobile from "./logo-mobile";

const Right = (props) => {
  return (
    <>
      <MDBRow className="">
        <LogoMobile {...props} />
      </MDBRow>
    </>
  );
};

export default Right;
