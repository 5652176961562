import React, { useEffect, memo } from "react";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBBox,
  MDBTypography,
} from "mdbreact";
import QRCode from "react-qr-code";
import Icon from "app/assets/icon/icon";
import ReactLoader from "app/layout/loader";
import { isEqual } from "lodash";
import themeConfiguration from "config/themeConfiguration";

const ARModal = memo(
  ({
    isOpen,
    setIsOpen,
    qrCodeUrl,
    setQrCodeUrl,
    collectionType,
    isMobile,
  }) => {
    useEffect(() => {
      const toolbarWrapper = document.querySelector(".toolbar-wrapper");
      const header = document.querySelector(".header-container");
      if (toolbarWrapper) {
        if (isOpen) {
          toolbarWrapper.style.zIndex = "1";
          header.style.zIndex = "1";
        } else {
          header.removeAttribute("style");
          toolbarWrapper.removeAttribute("style");
        }
      }
    }, [isOpen]);

    return (
      <MDBModal
        className="ar-modal"
        isOpen={isOpen}
        centered
        toggle={() => {
          setQrCodeUrl(null);
          setIsOpen(false);
        }}
      >
        <MDBModalHeader className="justify-content-center">
          <MDBBox
            className="ml-4 pl-2 position-absolute modal-title-ar cursor-pointer"
            onClick={() => setIsOpen(false)}
            style={{ right: 0 }}
          >
            <Icon icon="close" />
          </MDBBox>
          <MDBBox>
            <MDBTypography
              tag="h2"
              variant="display-2"
              className="font-weight-regular text-center"
            >
              {isMobile ? "Tap on the QR code" : "Scan QR"}
            </MDBTypography>
          </MDBBox>
        </MDBModalHeader>
        <MDBModalBody>
          <div className="ar-modal-body d-flex justify-content-center flex-column align-items-center">
            {!qrCodeUrl ? (
              <>
                <p>Generating QR Code</p>
                <div style={{ width: "100%", height: "100%" }}>
                  <ReactLoader />
                </div>
              </>
            ) : (
              <>
                <p className="mb-4">
                  Point your mobile device camera at the QR code below to see
                  this product come to life in augmented reality!*
                </p>
                <QRCode
                  value={`${themeConfiguration.app_url}build-your-own/${collectionType}?file_name=${qrCodeUrl}&ar=true`}
                />
                <p className="mt-4 mb-4">
                  *View in Room experience is currently supported on the
                  following devices:
                </p>
                <p>iPhone on iOS 12+</p>
                <p>Android 8.0+ with ARCore 1.9+ support.</p>
              </>
            )}
          </div>
        </MDBModalBody>
      </MDBModal>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default ARModal;
