import React, { memo, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { findByName } from "core/helpers/category";
import ExploreIndustry from "./exploreIndustry";
import MagicSliderDots from "react-magic-slider-dots";

const DataContainer = memo((props) => {
  let [currentTab, setCurrentTab] = useState(0);
  let sliderRef = useRef(null);

  let allCategories = useSelector(
    (state) => state.queries?.data[2]?.data?.categoryList
  );
  if (typeof allCategories === "undefined") return <></>;

  let categoryData = findByName("Shop By Industries", allCategories);
  let tabs = categoryData?.children || [];

  let sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={30} />;
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  let tabProps = {
    tabs,
    setCurrentTab,
    currentTab,
  };

  let categoryList = tabs[currentTab]?.children || [];

  let displayProps = {
    tabProps,
    sliderSettings,
    categoryList,
    sliderRef,
  };

  return <ExploreIndustry {...props} {...displayProps} />;
});

export default DataContainer;
