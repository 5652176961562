const swConfig = {
  onUpdate: (registration) => {
    registration.unregister();
    window.navigator.serviceWorker
      .register("/sw-update.js")
      .then(function () {});
  },
};

export default swConfig;
