/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { transform } from "core/components/cmsBlock/Parser";
import Icon from "app/assets/icon/icon";
// import Icon from "app/assets/icon/icon";

const FaqItem = ({ item, index }) => {
  const [showItem, setShowItem] = useState(index === 0);
  const handleShowItem = useCallback(() => {
    setShowItem(!showItem);
  }, [showItem]);
  return (
    <div className={`dropdown-item`}>
      <div
        itemProp={`mainEntry`}
        onClick={handleShowItem}
        onKeyDown={handleShowItem}
        className={`dropdown-trigger  d-flex flex-row align-items-center justify-content-between`}
      >
        <h5 itemProp={`name`}>{item.title}</h5>
        <Icon
          icon={`${showItem ? "minusBlue" : "plusBlue"}`}
          className={`${showItem ? "open" : ""}`}
        />
      </div>
      {showItem ? (
        <div>
          <div
            itemProp={`acceptedAnswer`}
            className={`dropdown-container  ${showItem ? "visible" : "hidden"}`}
          >
            {ReactHtmlParser(
              item.content
                ?.replaceAll("&lt;", "<")
                ?.replaceAll("&gt;", ">")
                ?.replaceAll("&amp;nbsp;", "<br>"),
              {
                decodeEntities: true,
                transform: transform,
              }
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FaqItem;
