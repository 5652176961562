import { combineReducers } from "redux";
import projectReducers from "app/state/redux/reducers";
import { default as customerReducer } from "./data/customer/reducer";
import { default as commonReducer } from "./data/common/reducer";
import { default as messagesReducer } from "./data/messages/reducer";
import { default as queryReducer } from "./data/queries/reducer";
import { default as searchReducer } from "./data/search/reducer";
import { default as productReducer } from "./data/product/reducer";
import { default as compare } from "./data/compare/reducer";
import { default as wishlist } from "./data/wishlist/reducer";

import filtersReducer from "./data/filters";
import cacheReducer from "./data/cache";

// import exportListReducer from "./data/exportList";
// import freightQuoteReducer from "./data/freightQuote";
// import { pendingTasksReducer } from "react-redux-spinner";

const globalReducers = combineReducers({
  customer: customerReducer,
  common: commonReducer,
  messages: messagesReducer,
  queries: queryReducer,
  filters: filtersReducer,
  cache: cacheReducer,
  search: searchReducer,
  product: productReducer,
  compare: compare,
  wishlist: wishlist,
  //   exportList: exportListReducer,
  //   freightInformation: freightQuoteReducer,
  //   pendingTasks: pendingTasksReducer
  ...projectReducers,
});

export default globalReducers;
