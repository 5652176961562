import {
  CreationCustomerFragment,
  CreationCustomerFragmentDoc,
  CustomerAddressInput,
  CustomerAddressRegionInput,
  Maybe,
  useCountriesQuery,
  useCreateCustomerAddressMutation,
  useCustomerQuery,
  useUpdateCustomerAddressMutation,
} from "app/generated/graphql";
import { customerPaths } from "app/pages/customer/routes";
import React from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import { isAddressCategory } from "../../../config";
import { RequiredCountry } from "../../../models";
import {
  encodePhone,
  isAddressDefined,
  isCountryDefined,
} from "../../../utils";
import { AddressCategory } from "./config";
import StateContainer, { StateContainerProps } from "./StateContainer";
import { isCountryCode } from "./utils";

export interface DataContainerProps {}

const DataContainer: React.FC<DataContainerProps> = (props) => {
  const match = useRouteMatch<{ category: string }>();

  const { data } = useCustomerQuery();

  const countriesQuery = useCountriesQuery();

  const countries: RequiredCountry[] = React.useMemo(() => {
    return (countriesQuery.data?.countries ?? []).filter(isCountryDefined);
  }, [countriesQuery]);

  const [
    updateCustomerAddress,
    updateCustomerAddressResult,
  ] = useUpdateCustomerAddressMutation();

  const [
    createCustomerAddress,
    createCustomerAddressResult,
  ] = useCreateCustomerAddressMutation();

  const handleSubmit: StateContainerProps["onSubmit"] = React.useCallback(
    ({
      id,
      country,
      region_id,
      address1,
      address2,
      addressCategory,
      telephone,
      default_billing,
      default_shipping,
      firstname,
      lastname,
      ...values
    }) => {
      const phone = encodePhone(telephone);

      const street = [address1, address2 ?? null];

      const country_id =
        country?.id && isCountryCode(country.id) ? country.id : null;

      const region = country?.available_regions?.find(
        (region) => region?.id === region_id
      );
      const regionInput: Maybe<CustomerAddressRegionInput> = region
        ? {
            region: region.name,
            region_code: region.code,
            region_id: region.id,
          }
        : null;
      const customerId = data?.customer?.id?.toString();
      if (customerId) {
        if (match.params.category === AddressCategory.new) {
          const input: CustomerAddressInput = {
            ...values,
            firstname,
            lastname,
            telephone: phone,
            street,
            country_id,
            region: regionInput,
            default_billing,
            default_shipping,
          };
          return createCustomerAddress({
            variables: { input },
            update: (cache, result) => {
              if (data?.customer && result.data?.createCustomerAddress) {
                const addresses = [
                  ...(data?.customer.addresses?.map((address) => ({
                    ...address,
                    ...(default_billing ? { default_billing: false } : {}),
                    ...(default_shipping ? { default_shipping: false } : {}),
                  })) ?? []),
                  result.data?.createCustomerAddress,
                ];

                cache.writeFragment<CreationCustomerFragment>({
                  fragment: CreationCustomerFragmentDoc,
                  fragmentName: "CreationCustomer",
                  id: `Customer:${customerId}`,
                  data: {
                    ...data.customer,
                    addresses,
                  },
                });
              }
            },
          });
        } else {
          const input: CustomerAddressInput = {
            ...values,
            firstname,
            lastname,
            telephone: phone,
            street,
            country_code: country_id,
            region: regionInput,
            default_billing,
            default_shipping,
          };
          if (id) {
            return updateCustomerAddress({
              variables: {
                id,
                input,
              },
              update: (cache, result) => {
                if (data?.customer && result.data?.updateCustomerAddress) {
                  const addresses = [
                    ...(data?.customer.addresses?.map((address) => {
                      if (
                        address?.id === result.data?.updateCustomerAddress?.id
                      ) {
                        return result.data?.updateCustomerAddress ?? null;
                      } else {
                        return {
                          ...address,
                          ...(default_billing
                            ? { default_billing: false }
                            : {}),
                          ...(default_shipping
                            ? { default_shipping: false }
                            : {}),
                        };
                      }
                    }) ?? []),
                  ];
                  cache.writeFragment<CreationCustomerFragment>({
                    fragment: CreationCustomerFragmentDoc,
                    fragmentName: "CreationCustomer",
                    id: `Customer:${customerId}`,
                    data: {
                      ...data.customer,
                      addresses,
                    },
                  });
                }
              },
            });
          } else {
            return Promise.reject("Cannot update address without id field.");
          }
        }
      } else {
        return Promise.reject("No customer id.");
      }
    },
    [match, updateCustomerAddress, createCustomerAddress, data]
  );

  const error =
    updateCustomerAddressResult.error ??
    createCustomerAddressResult.error ??
    null;

  if (isAddressCategory(match.params.category)) {
    const address =
      match.params.category === AddressCategory.mailing
        ? data?.customer?.addresses?.find(
            (address) => address?.default_billing
          ) ?? null
        : match.params.category === AddressCategory.shipping
        ? data?.customer?.addresses?.find(
            (address) => address?.default_shipping
          ) ?? null
        : null;
    return (
      <StateContainer
        address={address}
        onSubmit={handleSubmit}
        countries={countries}
        error={error}
      />
    );
  } else {
    const address = data?.customer?.addresses
      ?.filter(isAddressDefined)
      .find(({ id }) => id === Number(match.params.category));

    if (address) {
      return (
        <StateContainer
          address={address}
          onSubmit={handleSubmit}
          countries={countries}
          error={error}
        />
      );
    } else {
      return (
        <Redirect to={customerPaths.children.account.children.addresses.path} />
      );
    }
  }
};

export default DataContainer;
