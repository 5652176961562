import Icon from "app/assets/icon/icon";
import { Player } from "app/layout/Player";
import ProductPage from "app/pages/catalog/product";
import { ModalConsumer } from "core/components/modal/ModalContext";
import {
  MDBBox,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { PromosBYO } from "app/pages/catalog/product/PromosBYO";
import { useMediaQuery } from "react-responsive";

export const Sidebar = ({
  current,
  options,
  setOption,
  hideModal,
  title = "Select Base Option",
}) => {
  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);
  let [preselection, setPreselection] = useState(current);

  const reference = useRef(null);
  const onClickOutside = useCallback(
    (e) => {
      if (!reference.current.contains(e.target)) {
        hideModal();
      }
    },
    [hideModal]
  );
  useEffect(() => {
    document.addEventListener("click", onClickOutside);
    return () => {
      document.removeEventListener("click", onClickOutside);
    };
  }, [onClickOutside]);

  return (
    <div ref={reference} style={{ zIndex: "10001" }}>
      <MDBRow className="base-options-container" style={{ zIndex: "100001" }}>
        <MDBCol style={{ maxHeight: "100%", height: "100%" }}>
          <MDBRow
            style={{
              zIndex: "2",
            }}
            className="base-options-header"
          >
            <MDBCol className="bg-default flex-center mb-5 px-0">
              <Icon icon="closeWhite" size="4" onClick={hideModal} />
              <MDBTypography tag="h2" className="body-3 base-options-title">
                {title}
              </MDBTypography>
            </MDBCol>
          </MDBRow>
          <MDBRow
            className="p-3 flex-grow-1 items-container"
            style={{
              height: "calc(100% - 2rem)",
              maxHeigh: "calc(100% - 2rem)",
            }}
          >
            <MDBCol>
              <MDBRow style={{ paddingBottom: "18rem" }}>
                {options?.map((option) => {
                  return (
                    <MDBCol
                      key={option.id + Math.random() * 100}
                      size="6"
                      className={`p-3 base-option`}
                    >
                      <MDBRow className="mb-2">
                        <MDBCol
                          size="12"
                          onClick={() => {
                            if (
                              option === preselection &&
                              title.toLowerCase().indexOf("accessory") !== -1
                            ) {
                              setPreselection(null);
                              setOption("X");
                            } else {
                              setPreselection(option);
                              setOption(option);
                            }
                          }}
                          className="px-3"
                        >
                          <img
                            src={
                              title.toLowerCase().indexOf("left") !== -1
                                ? option.imageLeft
                                : title.toLowerCase().indexOf("right") !== -1
                                ? option.imageRight
                                : option.image || option.icon || option.map
                            }
                            alt={option.name}
                            className={`img-fluid border  ${
                              option.name === preselection?.name
                                ? "border-default "
                                : "border-white"
                            }`.trim()}
                          />

                          <Icon
                            icon="checked"
                            className={`select-option-icon ${
                              option.name === preselection?.name && "selected"
                            }`}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="option-title">
                        <MDBCol className="flex-center">
                          <MDBTypography tag="h4" className="text-darken-gray">
                            {option.name}
                          </MDBTypography>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  );
                })}
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBRow className="bg-gray-lighten-4 py-3 d-flex sidebar-footer align-items-center">
            <MDBCol size="12">
              <MDBRow>
                <MDBCol size="2" style={{ minWidth: "7rem" }}>
                  <MDBTypography tag="h4" className="body-2 text-default fs-16">
                    Selected
                  </MDBTypography>
                </MDBCol>
                <MDBCol>
                  <MDBTypography
                    tag="h4"
                    className="body-2 text-darken-gray fs-16"
                  >
                    {preselection?.name}
                  </MDBTypography>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol size="12">
              <MDBRow>
                {/* <MDBCol>
                 TO DO : View details
                </MDBCol> */}
                <MDBCol className="d-flex justify-content-center align-items-center">
                  <MDBBtn
                    onClick={() => {
                      hideModal();
                    }}
                  >
                    Continue
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </div>
  );
};

const Step = ({ step = 1, current, options, setOption, title }) => {
  return (
    <MDBRow className={`step-${step}-container p-3`}>
      <ModalConsumer value={{ useWrapper: false }}>
        {({ showModal, hideModal }) => {
          return (
            <MDBCol
              className="bg-gray-lighten-4 py-3 px-xl-5 px-3 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                showModal(() => {
                  return (
                    <Sidebar
                      current={current}
                      options={options}
                      setOption={setOption}
                      hideModal={hideModal}
                      title={title}
                    />
                  );
                });
              }}
            >
              <MDBRow className="align-items-center">
                <MDBCol xl="2" size="3">
                  <MDBBox
                    className="border border-default flex-center"
                    style={{
                      width: "4rem",
                      height: "4rem",
                      borderRadius: "100%",
                      overflow: "hidden",
                    }}
                  >
                    {!current && (
                      <MDBTypography
                        tag="h4"
                        className="body-3 text-default"
                        style={{
                          fontSize: "3.3rem",
                          transform: "translateY(2px)",
                        }}
                      >
                        {step}
                      </MDBTypography>
                    )}
                    {current && (
                      <img
                        src={
                          title.toLowerCase().indexOf("left") !== -1
                            ? current.imageLeft
                            : title.toLowerCase().indexOf("right") !== -1
                            ? current.imageRight
                            : current.image
                        }
                        alt={current.name}
                        className="img-fluid"
                      />
                    )}
                  </MDBBox>
                </MDBCol>
                <MDBCol
                  size="7"
                  xl="8"
                  className="px-4 d-flex flex-column justify-content-center"
                >
                  <MDBRow className="px-2 mb-3 configurator-sidebar fs-24">
                    <MDBCol>
                      <MDBTypography tag="h2" className="body-3 text-default">
                        {title}
                      </MDBTypography>
                    </MDBCol>
                  </MDBRow>
                  {current && (
                    <MDBRow className="px-2">
                      <MDBCol>
                        <MDBTypography
                          tag="h2"
                          className="body-3 text-default fs-16"
                        >
                          {current.name || "None"}
                        </MDBTypography>
                      </MDBCol>
                    </MDBRow>
                  )}
                </MDBCol>
                <MDBCol lg="2" size="2" className="p-xl-3 p-0">
                  <Icon icon="rightMobile" size="3" />
                </MDBCol>
              </MDBRow>
            </MDBCol>
          );
        }}
      </ModalConsumer>
    </MDBRow>
  );
};

const _CustomDisplay = (
  {
    player,
    setPlayer,
    seatAndBackArray,
    frameArray,
    armArray,
    baseArray,
    floorArray,
    accessoryLeftArray,
    accessoryRightArray,
    fabricArray,
    sku,
    showPdp,
    selectionsAndSelectors,
    fabricsSelections,
    allowSectional,
    pickSectional,
    currentSectionals,
    nailData,
    queryPathFabrics,
    productName,
    productDimensions,
    powerOptionsArray,
  },
  canvasRef
) => {
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [checkBoxOptionsCustom, setCheckBoxOptionsCustom] = React.useState({});
  const [calculatedFrameArray, setCalculatedFrameArray] = useState(
    JSON.parse(JSON.stringify(frameArray))
  );

  useEffect(() => {
    let localFrameArray = JSON.parse(JSON.stringify(frameArray));
    setCalculatedFrameArray(localFrameArray);
  }, [frameArray]);
  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const isMobile = useMediaQuery({ maxWidth: 992 });
  const isLaptop = useMediaQuery({ maxHeight: 768 });

  const [calculatedArmArray, setCalculatedArmArray] = useState(
    JSON.parse(JSON.stringify(armArray))
  );
  const [calculatedPowerArray, setCalculatedPowerArray] = useState(
    JSON.parse(JSON.stringify(powerOptionsArray))
  );

  const [changed, setChanged] = useState(false);
  const [initLoad, setInitLoad] = useState(false);
  useEffect(() => {
    if (initLoad) {
      if (
        selectionsAndSelectors?.frame?.name === "Wall Hugger Recliner with UV-C"
      ) {
        setChanged(true);
        setCalculatedArmArray(
          calculatedArmArray.filter((option) => option.id === "M")
        );
        setCalculatedPowerArray(
          calculatedPowerArray.filter((option) => option.id === "SR")
        );
      } else if (selectionsAndSelectors?.frame?.id === "Z") {
        setChanged(true);
        setCalculatedPowerArray(
          calculatedPowerArray.filter(
            (option) =>
              option.id === "SR" || option.id === "MR" || option.id === "PR"
          )
        );
      } else if (selectionsAndSelectors?.frame?.id === "K") {
        setChanged(true);
        setCalculatedPowerArray(
          calculatedPowerArray.filter(
            (option) =>
              option.id === "PB" || option.id === "MR" || option.id === "PR"
          )
        );
      } else if (selectionsAndSelectors?.frame?.id === "F") {
        setChanged(true);
        setCalculatedPowerArray(
          calculatedPowerArray.filter((option) => option.id === "PL")
        );
      } else {
        setCalculatedArmArray(JSON.parse(JSON.stringify(armArray)));
        setCalculatedPowerArray(JSON.parse(JSON.stringify(powerOptionsArray)));
      }
    }
  }, [sku, initLoad]);

  useEffect(() => {
    if (changed) {
      setChanged(false);
      selectionsAndSelectors.setArm(calculatedArmArray[0]);
    }
  }, [calculatedArmArray]);

  useEffect(() => {
    if (changed) {
      setChanged(false);
      selectionsAndSelectors.setPowerOption(calculatedPowerArray[0]);
    }
  }, [calculatedPowerArray]);

  useEffect(() => {
    setInitLoad(true);
    return () => {
      setInitLoad(false);
    };
  }, []);

  return (
    <>
      <MDBContainer fluid className="position-relative py-4 px-0">
        <MDBRow className="h-100 mx-0">
          <MDBCol
            size="12"
            lg="9"
            className={`px-${isMobile ? "0" : "5"} player d-block ${
              isFullscreen ? "fullscreen-canvas" : ""
            }`.trim()}
            style={{
              top: isMobile ? "0" : "4rem",
              minHeight: isMobile ? "33.7vh" : isLaptop ? "50.2vh" : "65.2vh",
              maxHeight: isMobile
                ? "33.7rem"
                : isLaptop
                ? "50.2rem"
                : "65.2rem",
              height: isMobile ? "33.7rem" : isLaptop ? "50.2rem" : "65.2rem",
              marginBottom: isMobile ? "3rem" : "0",
              overflowY: "visible",
              zIndex: 1,
              position: "sticky",
            }}
          >
            <Player
              ref={canvasRef}
              isFullscreen={isFullscreen}
              toggleFullscreen={toggleFullscreen}
              player={player}
              setPlayer={setPlayer}
              frame={selectionsAndSelectors.frame}
              arm={selectionsAndSelectors.arm}
              seatAndBack={selectionsAndSelectors.seatAndBack}
              base={selectionsAndSelectors.base}
              floor={selectionsAndSelectors.floor}
              floorOptions={floorArray}
              setFloor={selectionsAndSelectors.setFloor}
              mainFabric={fabricsSelections.mainFabric}
              backFabric={fabricsSelections.backFabric}
              weltFabric={fabricsSelections.weltFabric}
              pillowWeltFabric={fabricsSelections.decorativeCordFabric}
              cushionFabric={fabricsSelections.cushionFabric}
              throwPillowFabric={fabricsSelections.throwPillowFabric}
              nailSize={nailData.nailSize}
              nailFinish={nailData.nailFinish}
              sku={sku}
              collectionPath={"senior-living-solutions"}
              checkBoxOptionsCustom={checkBoxOptionsCustom}
              setCheckBoxOptionsCustom={setCheckBoxOptionsCustom}
              accessoryLeft={selectionsAndSelectors.accessoryLeft}
              accessoryRight={selectionsAndSelectors.accessoryRight}
              powerOption={selectionsAndSelectors.powerOption}
              productDimensions={productDimensions}
            />
          </MDBCol>
          <MDBCol
            size="12"
            lg="3"
            className={`controls px-5 ${
              selectionsAndSelectors?.frame?.recliner
                ? "recliner-true"
                : "recliner-false"
            } `}
          >
            <MDBRow>
              <MDBCol className="my-3 px-0">
                <MDBTypography
                  className={`${((isMobile && "fwregular") || "").trim()}`}
                  tag="h1"
                >
                  Senior Living Solutions
                </MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className={`${(!isMobile && "my-3") || ""} px-0`}>
                <MDBRow className={`mb-3`}>
                  <MDBCol size="12" lg="6">
                    {(isMobile && (
                      <MDBTypography tag="h1" className="mb-3">
                        {productName}
                      </MDBTypography>
                    )) || (
                      <MDBTypography tag="h3" className="text-default fwthin">
                        Product Sku
                      </MDBTypography>
                    )}
                  </MDBCol>
                  <MDBCol
                    size="12"
                    lg="6"
                    className={`${(isMobile && "text-left") || "text-right"}`}
                  >
                    {(isMobile && (
                      <MDBTypography
                        tag="h3"
                        className="fwregular text-gray-darken-3 mb-3"
                      >
                        {sku}
                      </MDBTypography>
                    )) || (
                      <MDBTypography
                        tag="h1"
                        className="text-default fwregular"
                      >
                        {sku}
                      </MDBTypography>
                    )}
                  </MDBCol>
                </MDBRow>
                {/* <MDBRow className="my-3">
                  <MDBCol size="6">
                    <MDBTypography tag="h1" className="text-default fwregular">
                       $X,XXX
                    </MDBTypography>
                  </MDBCol>
                </MDBRow> */}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol
                size="12"
                className="border border-default border-thic rounded"
                style={{
                  boxShadow: "0px 6px 4px #00000033",
                }}
              >
                <MDBRow>
                  <MDBCol size="12">
                    <Step
                      step="0"
                      title="Select Frame"
                      current={selectionsAndSelectors.frame}
                      options={calculatedFrameArray}
                      setOption={(opt) => {
                        selectionsAndSelectors.setFrame(opt);
                        // if (opt.id !== "50") {
                        //   selectionsAndSelectors.setSeat(seatArray?.[0]);
                        // }
                      }}
                    />
                    {/*{selectionsAndSelectors?.frame?.id === "50" && (*/}
                    {/*  <Step*/}
                    {/*    step="1"*/}
                    {/*    title="Select Cushion"*/}
                    {/*    current={selectionsAndSelectors.seat}*/}
                    {/*    options={seatArray}*/}
                    {/*    setOption={selectionsAndSelectors.setSeat}*/}
                    {/*  />*/}
                    {/*)}*/}
                    {selectionsAndSelectors?.frame?.name !== "Ottoman" &&
                      !selectionsAndSelectors?.frame?.recliner && (
                        <Step
                          step="2"
                          title="Select Seat and Back"
                          current={selectionsAndSelectors.seatAndBack}
                          options={seatAndBackArray}
                          setOption={selectionsAndSelectors.setSeatAndBack}
                        />
                      )}
                    {selectionsAndSelectors?.frame?.name !== "Ottoman" && (
                      <Step
                        step="3"
                        title="Select Arm"
                        current={selectionsAndSelectors.arm}
                        options={calculatedArmArray}
                        setOption={selectionsAndSelectors.setArm}
                      />
                    )}
                    {!selectionsAndSelectors?.frame?.recliner && (
                      <>
                        <Step
                          step="4"
                          title="Select Base"
                          current={selectionsAndSelectors.base}
                          options={baseArray}
                          setOption={selectionsAndSelectors.setBase}
                        />
                      </>
                    )}
                    <Step
                      step="5"
                      title="Select Left Accessory"
                      current={selectionsAndSelectors.accessoryLeft}
                      options={accessoryLeftArray}
                      setOption={selectionsAndSelectors.setAccessoryLeft}
                    />
                    <Step
                      step="6"
                      title="Select Right Accessory"
                      current={selectionsAndSelectors.accessoryRight}
                      options={accessoryRightArray}
                      setOption={selectionsAndSelectors.setAccessoryRight}
                    />
                    {selectionsAndSelectors?.frame?.recliner && (
                      <Step
                        step="7"
                        title="Select Power Option"
                        current={selectionsAndSelectors.powerOption}
                        options={calculatedPowerArray}
                        setOption={selectionsAndSelectors.setPowerOption}
                      />
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                {showPdp && (
                  <>
                    <ProductPage
                      data={{ urlResolver: { sku: sku } }}
                      isCustom={true}
                      player={player}
                      allowSectional={allowSectional}
                      pickSectional={pickSectional}
                      currentSectionals={currentSectionals}
                      fabricsSelections={fabricsSelections}
                      frame={selectionsAndSelectors.frame}
                      nailData={nailData}
                      noImage={true}
                      selectionsAndSelectors={selectionsAndSelectors}
                      queryPathFabrics={queryPathFabrics}
                      checkBoxOptionsCustom={checkBoxOptionsCustom}
                      selectBoxOptionsCustom={setCheckBoxOptionsCustom}
                    />
                  </>
                )}
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <PromosBYO />
    </>
  );
};

export const CustomDisplay = React.forwardRef(_CustomDisplay);
