import React from "react";
import SeoHelmet from "app/layout/seoHelmet";
import { MDBContainer, MDBRow } from "mdbreact";
import CmsBlock from "app/layout/cmsBlock";
import useTagboxHelper from "app/layout/Tagbox/useTagboxHelper";

const Commercial = () => {
  const { tag } = useTagboxHelper();
  return (
    <>
      <SeoHelmet title={"Commercial"} url={"/commercial"} />
      <MDBContainer className={"no-header-offset home-container p-0"} fluid>
        <MDBContainer className="m-0 hero-banner" fluid>
          <MDBRow className="justify-content-center">
            <CmsBlock id="commercial_hero" />
            {/* <CmsBlock id="crypton_hero_banner" /> */}
          </MDBRow>
        </MDBContainer>
        <CmsBlock id="commercial_multi_block-early-2024" />
        <CmsBlock id="commercial_2024_lookbook" />
        <CmsBlock id="commercial_kelley_Quote" />
        <CmsBlock id="commercial_carie_quote" />
        <CmsBlock id="commercial_senior_living_ebook" />
        <CmsBlock id="commercial_meet_callahan" />
        <CmsBlock id="commercial_massimo_tables" />
        <CmsBlock id="commercial_lets_get_social" />
        {tag}
      </MDBContainer>
    </>
  );
};

export default Commercial;
