// @ts-check

import { useBoolean } from "ahooks";
import ModalHeader from "app/layout/ModalHeader";
import { cartPaths } from "app/pages/Cart/config";
import { customerPaths } from "app/pages/customer/routes";
import { customerActions } from "app/state/redux/data/customer";
import { createEnterHandler } from "app/utils/utils";
import useCustomer from "core/state/hooks/customerHooks/useCustomer";
import { MDBModal, MDBModalBody, MDBTypography } from "mdbreact";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import HeaderTopDisplay from "./headerTop";
import { useSelector } from "react-redux";
import { StateType } from "typesafe-actions";

const paths = [
  customerPaths.children.account,
  cartPaths,
  customerPaths.children.account.children.orders,
  // customerPaths.children.account.children.claims,
  customerPaths.children.collections.children.wishlists,
];

export type RootState = StateType<
  typeof import("../../../../state/redux/data/customer/reducer").default
>;
/**
 *
 * @param {*} props
 */
const HeaderTop = (props: any) => {
  const history = useHistory();

  const dispatch = useDispatch();

  let [, isLoggedIn] = useCustomer();

  const [isModalOpen, setIsModalOpen] = useBoolean();

  const signOut = React.useCallback(() => {
    dispatch(customerActions.logoutCustomer());
    setIsModalOpen.setFalse();
  }, [dispatch, setIsModalOpen, history]);
  let numberOfQuotes = useSelector(
    (state: RootState) => state.customer?.data?.cart?.items?.length
  );
  if (numberOfQuotes === undefined) {
    numberOfQuotes = 0;
  }

  return (
    <>
      <HeaderTopDisplay
        {...props}
        isLoggedIn={isLoggedIn}
        onAccountClick={setIsModalOpen.setTrue}
      />
      <MDBModal
        inline={false}
        noClickableBodyWithoutBackdrop
        overflowScroll={false}
        isOpen={isModalOpen}
        toggle={setIsModalOpen.setFalse}
        backdropClassName="d-none"
        className="login-modal h-100 m-0 ml-auto"
        contentClassName="h-100"
      >
        <MDBModalBody className="p-0">
          <ModalHeader onClose={setIsModalOpen.setFalse}>
            My Account
          </ModalHeader>
          {paths.map(({ path, label }) => (
            <div key={path} className="pt-4 pb-4 pl-4 border-bottom-1">
              <Link to={path} onClick={setIsModalOpen.setFalse}>
                <MDBTypography className="mt-3 mb-3 ml-3 fs-14 text-default">
                  {label === "Quote Request"
                    ? label.toUpperCase() +
                      "\u00A0\u00A0" +
                      `(${numberOfQuotes})`
                    : label.toUpperCase()}
                </MDBTypography>
              </Link>
            </div>
          ))}
          <div
            className="pt-4 pb-4 pl-4 border-bottom-1"
            onClick={signOut}
            onKeyUp={createEnterHandler(signOut)}
            tabIndex={0}
            role="button"
          >
            <MDBTypography className="mt-3 mb-3 ml-3 fs-14 fwregular text-default">
              SIGN OUT
            </MDBTypography>
          </div>
        </MDBModalBody>
      </MDBModal>
    </>
  );
};

export default HeaderTop;
