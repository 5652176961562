import React, { useEffect, useMemo, useState } from "react";
import CategoryStateContainer from "../../../state-container";
import { useLocation } from "react-router-dom";

function FabricFinishesPlp({ categories, mainCategory }) {
  const [finishCategory, setFinishCategory] = useState({
    value: "",
    label: "All",
  });

  const { search } = useLocation();

  useEffect(() => {
    if (search?.includes("categoryId")) {
      const category = categories?.find(
        (cat) =>
          cat?.id ===
          Number(new URLSearchParams(window.location.search)?.get("categoryId"))
      );
      setFinishCategory({ value: category?.id, label: category?.name });
    }
  }, [search, categories]);

  let selectedCategory = useMemo(
    () =>
      finishCategory
        ? categories?.find((cat) => cat?.id === finishCategory?.value)
        : null,
    [finishCategory]
  );

  return (
    <CategoryStateContainer
      categories={categories}
      finishCategory={finishCategory}
      category={selectedCategory}
      setFinishCategory={setFinishCategory}
      mainCategory={mainCategory}
    />
  );
}

export default FabricFinishesPlp;
