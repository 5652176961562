export default (state, action) => {
  if (typeof action.payload.aggregations === "undefined") {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        items: [...action.payload.items],
        loading: false,
      },
    });
  }
  return Object.assign({}, state, {
    data: {
      ...state.data,
      ...action.payload,
      aggregations: [...action.payload.aggregations],
      loading: false,
    },
  });
};
