import gql from "graphql-tag";

export const CUSTOMER_QUERY = gql`
  query Customer(
    $filter: CustomerOrdersFilterInput
    $currentPage: Int
    $pageSize: Int
    $sorting_attribute: String
    $sorting_direction: String
  ) {
    customer {
      id: customer_id
      firstname
      lastname
      email
      phone
      sign_up_for_updates
      is_new
      taxvat
      industry_type
      business_type
      hear_about
      tradeshow_specify
      other_specify
      is_subscribed
      orders(
        filter: $filter
        currentPage: $currentPage
        pageSize: $pageSize
        sorting_attribute: $sorting_attribute
        sorting_direction: $sorting_direction
      ) {
        items {
          order_date
          number
          status
          grand_total
          project_details {
            project_name
            timeline
            project_due_date
            additional_details
          }
          comments {
            timestamp
            status
            message
          }
          items {
            id
            product_name
            product_sku
            product_url_key
            product_sale_price {
              currency
              value
            }
            item_total {
              currency
              value
            }
            image
            quantity_ordered
            quantity_shipped
            selected_options {
              value
              label
              fabric_finish_value
            }
            entered_options {
                label
                value
                fabric_finish_value
            }
            document_names {
              uploaded_file_name
            }
            comment
          }
          total {
            grand_total {
              currency
              value
            }
          }
          shipping_address {
            city
            street
            firstname
            lastname
            country_code
            postcode
          }
          billing_address {
            city
            street
            firstname
            lastname
            country_code
            postcode
          }
        }
        page_info {
          current_page
          page_size
          total_pages
        }
        total_count
      }
    }
  }
`;

export const GET_CUSTOMER_ORDER_STATUSES = gql`
  query getCustomOrderStatuses {
    getCustomOrderStatuses {
      value: status_code
      label: status_label
    }
  }
`;

export const GET_ORDER_PDF = gql`
  query getCustomerOrderPdf($order_increment_id: String!) {
    getCustomerOrderPdf(order_increment_id: $order_increment_id)
  }
`;
