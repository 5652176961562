// @ts-ignore
import { useQuery } from "@apollo/react-hooks";
import themeConfiguration from "config/themeConfiguration";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { FINISHES, FINISHES_CATEGORIES } from "../queriesFinish";
import { FinishSidebar as FinishSidebarDisplay } from "./finishSidebar";

const getFiltersByTypeOfFinish = (option, selectedCategory) => {
  switch (option.title) {
    // case "Nail Finish":
    //   return { ffifinish_nailhead: { eq: 1 } };
    default:
      return { category_id: { eq: selectedCategory } };
  }
};

/**
 *
 * @param {*} props
 */
const FinishSidebarQueryContainer = (props) => {
  let finishesCategories = [];

  let { allowedFinishes, categories } = props;
  const subId = themeConfiguration.finish_monogram_subcategory_id;
  const finishesResponse = useQuery(FINISHES_CATEGORIES, {
    variables: { id: themeConfiguration.finish_category_id },
    fetchPolicy: "cache-first",
  });
  // let monogramName = finishesResponse?.data?.categoryList[0]?.children[2].name;
  finishesCategories = finishesResponse?.data?.categoryList[0]?.children;

  //filtered categories by jola_product_count field
  finishesCategories =
    finishesCategories &&
    finishesCategories.filter((category) => {
      let hasItems = category.jola_product_count > 0;
      if (hasItems && allowedFinishes === "*A") {
        return category.id !== subId;
      } else if (hasItems && allowedFinishes === "*M") {
        return category.id;
      }
      return hasItems;
    });

  let defaultFinishCategory = 2762;

  if (finishesCategories && finishesCategories?.length > 0) {
    defaultFinishCategory = finishesCategories[0]?.id;
  }
  //preselected finish category
  const [selectedCategory, setSelectedCategory] = useState(
    allowedFinishes === "*A"
      ? themeConfiguration.finish_subcategory_id
      : themeConfiguration.finish_monogram_subcategory_id
  );

  let filterByTypeOfFinish = { category_id: { eq: selectedCategory } };

  props.customizationOptions.forEach((option) => {
    if (option.option_id === props.optionId) {
      filterByTypeOfFinish = getFiltersByTypeOfFinish(option, selectedCategory);
    }
  });

  const FinishesFilter = {
    filters: { ...filterByTypeOfFinish },
    pageSize: 35,
    currentPage: 1,
  };

  const { data, loading } = useQuery(FINISHES, {
    variables: FinishesFilter,
    fetchPolicy: "cache-first",
  });
  const finishesArray = loading ? false : data?.products;

  return (
    <FinishSidebarDisplay
      {...props}
      finishesArray={finishesArray}
      finishesCategories={finishesCategories}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      loadingFinishItems={loading}
      categories={categories}
      subId={subId}
    />
  );
};

export default withRouter(FinishSidebarQueryContainer);
