import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/** Defines the bundle products to add to the cart. */
export type AddBundleProductsToCartInput = {
  /** The ID of the cart. */
  cart_id: Scalars['String'];
  /** An array of bundle products to add. */
  cart_items: Array<Maybe<BundleProductCartItemInput>>;
};

/** Contains details about the cart after adding bundle products. */
export type AddBundleProductsToCartOutput = {
  __typename?: 'AddBundleProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
};

export type AddByCustomerIdInput = {
  /** Product SKU */
  product_sku?: Maybe<Scalars['String']>;
};

/** Defines the configurable products to add to the cart. */
export type AddConfigurableProductsToCartInput = {
  /** The ID of the cart. */
  cart_id: Scalars['String'];
  /** An array of configurable products to add. */
  cart_items: Array<Maybe<ConfigurableProductCartItemInput>>;
};

/** Contains details about the cart after adding configurable products. */
export type AddConfigurableProductsToCartOutput = {
  __typename?: 'AddConfigurableProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
};

export type AddDownloadableProductsToCartInput = {
  /** The ID of the cart. */
  cart_id: Scalars['String'];
  /** An array of downloadable products to add. */
  cart_items: Array<Maybe<DownloadableProductCartItemInput>>;
};

/** Contains details about the cart after adding downloadable products. */
export type AddDownloadableProductsToCartOutput = {
  __typename?: 'AddDownloadableProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
};

export type AddImagesToDownloadInput = {
  download_type?: Maybe<Scalars['String']>;
  skus: Array<Maybe<Scalars['String']>>;
};

export type AddItemsToWishlistInput = {
  products?: Maybe<Array<Maybe<ProductInput>>>;
  wishlists?: Maybe<Array<Maybe<WishlistName>>>;
};

export type AddItemToDownloaderOutput = {
  __typename?: 'addItemToDownloaderOutput';
  add_item_to_collection: ImageDownloads;
};

export type AddMultipleItemsToDownloadOutput = {
  __typename?: 'addMultipleItemsToDownloadOutput';
  add_multiple_items_to_download?: Maybe<Array<Maybe<ImageDownloads>>>;
};

/** Contains details about the cart after adding products to it. */
export type AddProductsToCartOutput = {
  __typename?: 'AddProductsToCartOutput';
  /** The cart after products have been added. */
  cart: Cart;
  /** Contains errors encountered while adding an item to the cart. */
  user_errors: Array<Maybe<CartUserInputError>>;
};

/** Contains products to add to an existing compare list. */
export type AddProductsToCompareListInput = {
  /** An array of product IDs to add to the compare list. */
  products: Array<Maybe<Scalars['ID']>>;
  /** The unique identifier of the compare list to modify. */
  uid: Scalars['ID'];
};

/** Contains the customer's wish list and any errors encountered. */
export type AddProductsToWishlistOutput = {
  __typename?: 'AddProductsToWishlistOutput';
  /** An array of errors encountered while adding products to a wish list. */
  user_errors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with all items that were successfully added. */
  wishlist: Wishlist;
};

export type AddSavedBuildToWishlistInput = {
  saved_builds?: Maybe<Array<Maybe<SavedBuildInput>>>;
  wishlists?: Maybe<Array<Maybe<WishlistName>>>;
};

/** Defines the simple and group products to add to the cart. */
export type AddSimpleProductsToCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of simple and group items to add. */
  cart_items: Array<Maybe<SimpleProductCartItemInput>>;
};

/** Contains details about the cart after adding simple or group products. */
export type AddSimpleProductsToCartOutput = {
  __typename?: 'AddSimpleProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
};

/** Defines the virtual products to add to the cart. */
export type AddVirtualProductsToCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of virtual products to add. */
  cart_items: Array<Maybe<VirtualProductCartItemInput>>;
};

/** Contains details about the cart after adding virtual products. */
export type AddVirtualProductsToCartOutput = {
  __typename?: 'AddVirtualProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
};

/** Contains the resultant wish list and any error information. */
export type AddWishlistItemsToCartOutput = {
  __typename?: 'AddWishlistItemsToCartOutput';
  /** An array of errors encountered while adding products to the customer's cart. */
  add_wishlist_items_to_cart_user_errors: Array<Maybe<WishlistCartUserInputError>>;
  /** Indicates whether the attempt to add items to the customer's cart was successful. */
  status: Scalars['Boolean'];
  /** Contains the wish list with all items that were successfully added. */
  wishlist: Wishlist;
};

/** Contains information for each filterable option (such as price, category `UID`, and custom attributes). */
export type Aggregation = {
  __typename?: 'Aggregation';
  /** Attribute code of the aggregation group. */
  attribute_code: Scalars['String'];
  /** The number of options in the aggregation group. */
  count?: Maybe<Scalars['Int']>;
  /** The aggregation display name. */
  label?: Maybe<Scalars['String']>;
  /** Array of options for the aggregation. */
  options?: Maybe<Array<Maybe<AggregationOption>>>;
  /** The relative position of the attribute in a layered navigation block. */
  position?: Maybe<Scalars['Int']>;
};

/** An implementation of `AggregationOptionInterface`. */
export type AggregationOption = AggregationOptionInterface & {
  __typename?: 'AggregationOption';
  /** The number of items that match the aggregation option. */
  count?: Maybe<Scalars['Int']>;
  /** The display label for an aggregation option. */
  label?: Maybe<Scalars['String']>;
  /** The internal ID that represents the value of the option. */
  value: Scalars['String'];
};

/** Defines aggregation option fields. */
export type AggregationOptionInterface = {
  /** The number of items that match the aggregation option. */
  count?: Maybe<Scalars['Int']>;
  /** The display label for an aggregation option. */
  label?: Maybe<Scalars['String']>;
  /** The internal ID that represents the value of the option. */
  value: Scalars['String'];
};

/** Filter category aggregations in layered navigation. */
export type AggregationsCategoryFilterInput = {
  /** Indicates whether to include only direct subcategories or all children categories at all levels. */
  includeDirectChildrenOnly?: Maybe<Scalars['Boolean']>;
};

/** An input object that specifies the filters used in product aggregations. */
export type AggregationsFilterInput = {
  /** Filter category aggregations in layered navigation. */
  category?: Maybe<AggregationsCategoryFilterInput>;
};

/** Contains the applied coupon code. */
export type AppliedCoupon = {
  __typename?: 'AppliedCoupon';
  /** The coupon code the shopper applied to the card. */
  code: Scalars['String'];
};

/** Specifies the coupon code to apply to the cart. */
export type ApplyCouponToCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** A valid coupon code. */
  coupon_code: Scalars['String'];
};

/** Contains details about the cart after applying a coupon. */
export type ApplyCouponToCartOutput = {
  __typename?: 'ApplyCouponToCartOutput';
  /** The cart after applying a coupon. */
  cart: Cart;
};

/** AreaInput defines the parameters which will be used for filter by specified location. */
export type AreaInput = {
  /** The radius for the search in KM. */
  radius: Scalars['Int'];
  /** The country code where search must be performed. Required parameter together with region, city or postcode. */
  search_term: Scalars['String'];
};

/** Contains the results of the request to assign a compare list. */
export type AssignCompareListToCustomerOutput = {
  __typename?: 'AssignCompareListToCustomerOutput';
  /** The contents of the customer's compare list. */
  compare_list?: Maybe<CompareList>;
  /** Indicates whether the compare list was successfully assigned to the customer. */
  result: Scalars['Boolean'];
};

/** Contains details about the attribute, including the code and type. */
export type Attribute = {
  __typename?: 'Attribute';
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attribute_code?: Maybe<Scalars['String']>;
  /** Attribute options list. */
  attribute_options?: Maybe<Array<Maybe<AttributeOption>>>;
  /** The data type of the attribute. */
  attribute_type?: Maybe<Scalars['String']>;
  /** The type of entity that defines the attribute. */
  entity_type?: Maybe<Scalars['String']>;
  /** The frontend input type of the attribute. */
  input_type?: Maybe<Scalars['String']>;
  /** Details about the storefront properties configured for the attribute. */
  storefront_properties?: Maybe<StorefrontProperties>;
};

/** Defines the attribute characteristics to search for the `attribute_code` and `entity_type` to search. */
export type AttributeInput = {
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attribute_code?: Maybe<Scalars['String']>;
  /** The type of entity that defines the attribute. */
  entity_type?: Maybe<Scalars['String']>;
};

/** Defines an attribute option. */
export type AttributeOption = {
  __typename?: 'AttributeOption';
  /** The label assigned to the attribute option. */
  label?: Maybe<Scalars['String']>;
  /** The attribute option value. */
  value?: Maybe<Scalars['String']>;
};

/** Describes a payment method that the shopper can use to pay for the order. */
export type AvailablePaymentMethod = {
  __typename?: 'AvailablePaymentMethod';
  /** The payment method code. */
  code: Scalars['String'];
  /** The payment method title. */
  title: Scalars['String'];
};

/** Contains details about the possible shipping methods and carriers. */
export type AvailableShippingMethod = {
  __typename?: 'AvailableShippingMethod';
  /** The cost of shipping using this shipping method. */
  amount: Money;
  /** Indicates whether this shipping method can be applied to the cart. */
  available: Scalars['Boolean'];
  /** @deprecated The field should not be used on the storefront. */
  base_amount?: Maybe<Money>;
  /** A string that identifies a commercial carrier or an offline shipping method. */
  carrier_code: Scalars['String'];
  /** The label for the carrier code. */
  carrier_title: Scalars['String'];
  /** Describes an error condition. */
  error_message?: Maybe<Scalars['String']>;
  /** A shipping method code associated with a carrier. The value could be null if no method is available. */
  method_code?: Maybe<Scalars['String']>;
  /** The label for the shipping method code. The value could be null if no method is available. */
  method_title?: Maybe<Scalars['String']>;
  /** The cost of shipping using this shipping method, excluding tax. */
  price_excl_tax: Money;
  /** The cost of shipping using this shipping method, including tax. */
  price_incl_tax: Money;
};

export enum BatchMutationStatus {
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  MixedResults = 'MIXED_RESULTS'
}

export type BestsellersProductOutput = {
  __typename?: 'BestsellersProductOutput';
  /** Currency */
  currency?: Maybe<Scalars['String']>;
  /** Period. */
  period?: Maybe<Scalars['String']>;
  /** Product ID */
  product_id?: Maybe<Scalars['String']>;
  /** Product Image URL */
  product_image_url?: Maybe<Scalars['String']>;
  /** Product Name */
  product_name?: Maybe<Scalars['String']>;
  /** Product Price */
  product_price?: Maybe<Scalars['String']>;
  /** Product SKU */
  product_sku?: Maybe<Scalars['String']>;
  /** Product URL */
  product_url?: Maybe<Scalars['String']>;
  /** Order Quantity. */
  qty_ordered?: Maybe<Scalars['Int']>;
};

/** Defines the billing address. */
export type BillingAddressInput = {
  /** Defines a billing address. */
  address?: Maybe<CartAddressInput>;
  /** An ID from the customer's address book that uniquely identifies the address to be used for billing. */
  customer_address_id?: Maybe<Scalars['Int']>;
  /** Indicates whether to set the billing address to be the same as the existing shipping address on the cart. */
  same_as_shipping?: Maybe<Scalars['Boolean']>;
  /** Indicates whether to set the shipping address to be the same as this billing address. */
  use_for_shipping?: Maybe<Scalars['Boolean']>;
};

/** Contains details about the billing address. */
export type BillingCartAddress = CartAddressInterface & {
  __typename?: 'BillingCartAddress';
  /** The city specified for the billing or shipping address. */
  city: Scalars['String'];
  /** The company specified for the billing or shipping address. */
  company?: Maybe<Scalars['String']>;
  /** An object containing the country label and code. */
  country: CartAddressCountry;
  /** @deprecated The field is used only in shipping address. */
  customer_notes?: Maybe<Scalars['String']>;
  /** The first name of the customer or guest. */
  firstname: Scalars['String'];
  /** The last name of the customer or guest. */
  lastname: Scalars['String'];
  order_email?: Maybe<Scalars['String']>;
  /** The ZIP or postal code of the billing or shipping address. */
  postcode?: Maybe<Scalars['String']>;
  /** An object containing the region label and code. */
  region?: Maybe<CartAddressRegion>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']>>;
  /** The telephone number for the billing or shipping address. */
  telephone?: Maybe<Scalars['String']>;
};

export type BraintreeCcVaultInput = {
  device_data?: Maybe<Scalars['String']>;
  public_hash: Scalars['String'];
};

export type BraintreeInput = {
  /**
   * Contains a fingerprint provided by Braintree JS SDK and should be sent with
   * sale transaction details to the Braintree payment gateway.
   */
  device_data?: Maybe<Scalars['String']>;
  /**
   * States whether an entered by a customer credit/debit card should be tokenized
   * for later usage. Required only if Vault is enabled for Braintree payment integration.
   */
  is_active_payment_token_enabler: Scalars['Boolean'];
  /**
   * The one-time payment token generated by Braintree payment gateway based on
   * card details. Required field to make sale transaction.
   */
  payment_method_nonce: Scalars['String'];
};

/** Contains details about an individual category that comprises a breadcrumb. */
export type Breadcrumb = {
  __typename?: 'Breadcrumb';
  /**
   * The ID of the category.
   * @deprecated Use `category_uid` instead.
   */
  category_id?: Maybe<Scalars['Int']>;
  /** The category level. */
  category_level?: Maybe<Scalars['Int']>;
  /** The display name of the category. */
  category_name?: Maybe<Scalars['String']>;
  /** The unique ID for a `Breadcrumb` object. */
  category_uid: Scalars['ID'];
  /** The URL key of the category. */
  category_url_key?: Maybe<Scalars['String']>;
  /** The URL path of the category. */
  category_url_path?: Maybe<Scalars['String']>;
};

/** An implementation for bundle product cart items. */
export type BundleCartItem = CartItemInterface & {
  __typename?: 'BundleCartItem';
  /** An array containing the bundle options the shopper selected. */
  bundle_options: Array<Maybe<SelectedBundleOption>>;
  /** cart item comment */
  comment?: Maybe<Scalars['String']>;
  /** An array containing the customizable options the shopper selected. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** cart item document_names */
  document_names?: Maybe<Array<Maybe<DocumentNamesData>>>;
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<CartItemError>>>;
  /** The entered gift message for the cart item */
  gift_message?: Maybe<GiftMessage>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Defines bundle product options for `CreditMemoItemInterface`. */
export type BundleCreditMemoItem = CreditMemoItemInterface & {
  __typename?: 'BundleCreditMemoItem';
  /** A list of bundle options that are assigned to a bundle product that is part of a credit memo. */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of refunded items. */
  quantity_refunded?: Maybe<Scalars['Float']>;
};

/** Defines bundle product options for `InvoiceItemInterface`. */
export type BundleInvoiceItem = InvoiceItemInterface & {
  __typename?: 'BundleInvoiceItem';
  /** A list of bundle options that are assigned to an invoiced bundle product. */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of invoiced items. */
  quantity_invoiced?: Maybe<Scalars['Float']>;
};

/** Defines an individual item within a bundle product. */
export type BundleItem = {
  __typename?: 'BundleItem';
  /**
   * An ID assigned to each type of item in a bundle product.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** An array of additional options for this bundle item. */
  options?: Maybe<Array<Maybe<BundleItemOption>>>;
  /** A number indicating the sequence order of this item compared to the other bundle items. */
  position?: Maybe<Scalars['Int']>;
  /** The range of prices for the product */
  price_range: PriceRange;
  /** Indicates whether the item must be included in the bundle. */
  required?: Maybe<Scalars['Boolean']>;
  /** The SKU of the bundle product. */
  sku?: Maybe<Scalars['String']>;
  /** The display name of the item. */
  title?: Maybe<Scalars['String']>;
  /** The input type that the customer uses to select the item. Examples include radio button and checkbox. */
  type?: Maybe<Scalars['String']>;
  /** The unique ID for a `BundleItem` object. */
  uid?: Maybe<Scalars['ID']>;
};

/** Defines the characteristics that comprise a specific bundle item and its options. */
export type BundleItemOption = {
  __typename?: 'BundleItemOption';
  /** Indicates whether the customer can change the number of items for this option. */
  can_change_quantity?: Maybe<Scalars['Boolean']>;
  /**
   * The ID assigned to the bundled item option.
   * @deprecated Use `uid` instead
   */
  id?: Maybe<Scalars['Int']>;
  /** Indicates whether this option is the default option. */
  is_default?: Maybe<Scalars['Boolean']>;
  /** The text that identifies the bundled item option. */
  label?: Maybe<Scalars['String']>;
  /** When a bundle item contains multiple options, the relative position of this option compared to the other options. */
  position?: Maybe<Scalars['Int']>;
  /** The price of the selected option. */
  price?: Maybe<Scalars['Float']>;
  /** One of FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** Contains details about this product option. */
  product?: Maybe<ProductInterface>;
  /**
   * Indicates the quantity of this specific bundle item.
   * @deprecated Use `quantity` instead.
   */
  qty?: Maybe<Scalars['Float']>;
  /** The quantity of this specific bundle item. */
  quantity?: Maybe<Scalars['Float']>;
  /** The unique ID for a `BundleItemOption` object. */
  uid: Scalars['ID'];
};

/** Defines the input for a bundle option. */
export type BundleOptionInput = {
  /** The ID of the option. */
  id: Scalars['Int'];
  /** The number of the selected item to add to the cart. */
  quantity: Scalars['Float'];
  /** An array with the chosen value of the option. */
  value: Array<Maybe<Scalars['String']>>;
};

/** Defines bundle product options for `OrderItemInterface`. */
export type BundleOrderItem = OrderItemInterface & {
  __typename?: 'BundleOrderItem';
  /** A list of bundle options that are assigned to the bundle product. */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** order item comment */
  comment?: Maybe<Scalars['String']>;
  /** The final discount information for the product. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** order item document_names */
  document_names?: Maybe<Array<Maybe<DocumentNames>>>;
  /** The entered option for the base product, such as a logo or image. */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  item_total: Money;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']>;
  /** URL key of the base product. */
  product_url_key?: Maybe<Scalars['String']>;
  /** The number of canceled items. */
  quantity_canceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items. */
  quantity_invoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item. */
  quantity_ordered?: Maybe<Scalars['Float']>;
  /** The number of refunded items. */
  quantity_refunded?: Maybe<Scalars['Float']>;
  /** The number of returned items. */
  quantity_returned?: Maybe<Scalars['Float']>;
  /** The number of shipped items. */
  quantity_shipped?: Maybe<Scalars['Float']>;
  /** The selected options for the base product, such as color or size. */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item. */
  status?: Maybe<Scalars['String']>;
};

/** Defines basic features of a bundle product and contains multiple BundleItems. */
export type BundleProduct = ProductInterface & RoutableInterface & PhysicalProductInterface & CustomizableProductInterface & {
  __typename?: 'BundleProduct';
  /**
   * Tearsheet PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  assembly_instructions?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  attribute_set_name?: Maybe<Scalars['String']>;
  /**
   * Availability date for the product.
   * @deprecated Use the `custom_attributes` field instead.
   */
  availability_date?: Maybe<Scalars['String']>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Products' is enabled.
   */
  canonical_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  catalog_page?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  /**
   * Order Form PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  collection_overview_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_caster_options?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_wheels_option?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_wood_finish?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  /** Indicates whether the bundle product has a dynamic price. */
  dynamic_price?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the bundle product has a dynamic SKU. */
  dynamic_sku?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the bundle product has a dynamically calculated weight. */
  dynamic_weight?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_apronh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_armh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_assem_req?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_avails?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_backconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_basefab?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_baselth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_bn_appl?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_casegd?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_casters?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cfgmodel?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_checkbox_test_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_collection?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_countryorg?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cubes?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cupholder?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_depth_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_designer?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_dftfabric?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_dftfinish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_duedate?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_extdesc2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_finish_req?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_finishopt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdepthgh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdeptlow?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimd?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimw?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_fulllftwal?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_fullyuph?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_height_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidebkh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestd?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestd_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw_test?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_introdat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_isvisible?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_lanect?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_laners?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_lanesl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leatheravl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_madeto?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_moistbarr?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_nail_opts?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhigharh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhighovh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhighsth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowarh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowovh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowsth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_onords?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_product_type_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_qty_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_reclindpt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_reclwallcl?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_riser?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seatconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seath?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seath_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_sprgconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_status?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdback?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdcushion?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdfinish?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdpllw1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tablet?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tariff?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tvrecline?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_uph_opts?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_wgtcar?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_wgtwrp?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_width_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_woodspec1?: Maybe<Scalars['Int']>;
  ffi_woodspec1_label?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_finish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_type_filterable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_abrasion?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_avail?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_avail_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_categories?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_category1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_collection?: Maybe<Scalars['String']>;
  ffifabrics_collection_label?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_colorways?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_country?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_cutyard?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_dec_cord?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_descr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_descript?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont9?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct9?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabric?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_finishtype?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fringe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fullcont?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_grade?: Maybe<Array<Maybe<Scalars['String']>>>;
  ffifabrics_grade_configurator_filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_hexclrgrp?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_hrepeat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_leather?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_pattern?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_std_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_tape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vendor?: Maybe<Array<Maybe<Scalars['String']>>>;
  ffifabrics_vendor_image?: Maybe<SwatchValues>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vinyl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vrepeat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_about?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_chm_distr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_cleaning?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_fairshield?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_nailhead?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_phy_distr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_sheen?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  file_upload?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  filterable_attribute_set_name?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  hotspots?: Maybe<Array<Maybe<ProductHotspotData>>>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** Add notification that loged user have added product in downloads in specific store */
  in_downloads?: Maybe<Scalars['String']>;
  /** Image is a part of in_real_life role. */
  in_real_life?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  in_stock_filter?: Maybe<Scalars['Int']>;
  industry_approval_attribute_option?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  introdat_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_featured?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_new?: Maybe<Scalars['Int']>;
  /** An array containing information about individual bundle items. */
  items?: Maybe<Array<Maybe<BundleItem>>>;
  /** Adding NULL for this field because of React core. */
  lead_time?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_image role. */
  lifestyle_image?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_image2 role. */
  lifestyle_image2?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_video role. */
  lifestyle_video?: Maybe<Scalars['String']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer?: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  new_from_date?: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  new_to_date?: Maybe<Scalars['String']>;
  /** Number of throw/kidney pillows */
  num_of_pillows?: Maybe<Scalars['Int']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** The range of prices for the product */
  price_range: PriceRange;
  /** An array of `TierPrice` objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  /** One of PRICE_RANGE or AS_LOW_AS. */
  price_view?: Maybe<PriceViewEnum>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_features?: Maybe<Scalars['String']>;
  product_features_label?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of `ProductLinks` objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** @deprecated Use the `custom_attributes` field instead. */
  ready_to_ship_filter?: Maybe<Scalars['Int']>;
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirect_code: Scalars['Int'];
  /** An array of products to be displayed in a Related Products block. */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /**
   * Packing Fee List PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  revit_file?: Maybe<Scalars['String']>;
  /** Indicates whether to ship bundle items together or individually. */
  ship_bundle_items?: Maybe<ShipBundleItemsEnum>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shop_by_position?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * Thread Board PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  spec_sheet?: Maybe<Scalars['String']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /**
   * The uploaded video.
   * @deprecated Use the `custom_attributes` field instead.
   */
  video_upload?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
};


/** Defines basic features of a bundle product and contains multiple BundleItems. */
export type BundleProductReviewsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

/** Defines a single bundle product. */
export type BundleProductCartItemInput = {
  /** A mandatory array of options for the bundle product, including each chosen option and specified quantity. */
  bundle_options: Array<Maybe<BundleOptionInput>>;
  /** The ID and value of the option. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** The quantity and SKU of the bundle product. */
  data: CartItemInput;
};

/** Defines bundle product options for `ShipmentItemInterface`. */
export type BundleShipmentItem = ShipmentItemInterface & {
  __typename?: 'BundleShipmentItem';
  /** A list of bundle options that are assigned to a shipped product. */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID'];
  /** The order item associated with the shipment item. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of shipped items. */
  quantity_shipped: Scalars['Float'];
};

/** Defines bundle product options for `WishlistItemInterface`. */
export type BundleWishlistItem = WishlistItemInterface & {
  __typename?: 'BundleWishlistItem';
  /** The date and time the item was added to the wish list. */
  added_at: Scalars['String'];
  /** An array containing information about the selected bundle items. */
  bundle_options?: Maybe<Array<Maybe<SelectedBundleOption>>>;
  /** Custom options selected for the wish list item. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
};

export type Cache = {
  __typename?: 'Cache';
  /** version */
  version?: Maybe<Scalars['String']>;
};

/** Contains the contents and other details about a guest or customer cart. */
export type Cart = {
  __typename?: 'Cart';
  /** @deprecated Use `applied_coupons` instead. */
  applied_coupon?: Maybe<AppliedCoupon>;
  /** An array of `AppliedCoupon` objects. Each object contains the `code` text attribute, which specifies the coupon code. */
  applied_coupons?: Maybe<Array<Maybe<AppliedCoupon>>>;
  /** An array of available payment methods. */
  available_payment_methods?: Maybe<Array<Maybe<AvailablePaymentMethod>>>;
  /** The billing address assigned to the cart. */
  billing_address?: Maybe<BillingCartAddress>;
  /** The email address of the guest or customer. */
  email?: Maybe<Scalars['String']>;
  /** The entered gift message for the cart */
  gift_message?: Maybe<GiftMessage>;
  /** The unique ID for a `Cart` object. */
  id: Scalars['ID'];
  /** Indicates whether the cart contains only virtual products. */
  is_virtual: Scalars['Boolean'];
  /** An array of products that have been added to the cart. */
  items?: Maybe<Array<Maybe<CartItemInterface>>>;
  /** Pricing details for the quote. */
  prices?: Maybe<CartPrices>;
  /** Indicates which payment method was applied to the cart. */
  selected_payment_method?: Maybe<SelectedPaymentMethod>;
  /** An array of shipping addresses assigned to the cart. */
  shipping_addresses: Array<Maybe<ShippingCartAddress>>;
  /** The total number of items in the cart. */
  total_quantity: Scalars['Float'];
};

/** Contains details the country in a billing or shipping address. */
export type CartAddressCountry = {
  __typename?: 'CartAddressCountry';
  /** The country code. */
  code: Scalars['String'];
  /** The display label for the country. */
  label: Scalars['String'];
};

/** Defines the billing or shipping address to be applied to the cart. */
export type CartAddressInput = {
  /** The address label. */
  address_label?: Maybe<Scalars['String']>;
  /** The city specified for the billing or shipping address. */
  city: Scalars['String'];
  /** The company specified for the billing or shipping address. */
  company?: Maybe<Scalars['String']>;
  /** The country code and label for the billing or shipping address. */
  country_code: Scalars['String'];
  /** The first name of the customer or guest. */
  firstname: Scalars['String'];
  /** The last name of the customer or guest. */
  lastname: Scalars['String'];
  order_email?: Maybe<Scalars['String']>;
  /** The ZIP or postal code of the billing or shipping address. */
  postcode?: Maybe<Scalars['String']>;
  /** A string that defines the state or province of the billing or shipping address. */
  region?: Maybe<Scalars['String']>;
  /** An integer that defines the state or province of the billing or shipping address. */
  region_id?: Maybe<Scalars['Int']>;
  /** Determines whether to save the address in the customer's address book. The default value is true. */
  save_in_address_book?: Maybe<Scalars['Boolean']>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']>>;
  /** The telephone number for the billing or shipping address. */
  telephone?: Maybe<Scalars['String']>;
};

export type CartAddressInterface = {
  /** The city specified for the billing or shipping address. */
  city: Scalars['String'];
  /** The company specified for the billing or shipping address. */
  company?: Maybe<Scalars['String']>;
  /** An object containing the country label and code. */
  country: CartAddressCountry;
  /** The first name of the customer or guest. */
  firstname: Scalars['String'];
  /** The last name of the customer or guest. */
  lastname: Scalars['String'];
  order_email?: Maybe<Scalars['String']>;
  /** The ZIP or postal code of the billing or shipping address. */
  postcode?: Maybe<Scalars['String']>;
  /** An object containing the region label and code. */
  region?: Maybe<CartAddressRegion>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']>>;
  /** The telephone number for the billing or shipping address. */
  telephone?: Maybe<Scalars['String']>;
};

/** Contains details about the region in a billing or shipping address. */
export type CartAddressRegion = {
  __typename?: 'CartAddressRegion';
  /** The state or province code. */
  code?: Maybe<Scalars['String']>;
  /** The display label for the region. */
  label?: Maybe<Scalars['String']>;
  /** The unique ID for a pre-defined region. */
  region_id?: Maybe<Scalars['Int']>;
};

/** Contains information about discounts applied to the cart. */
export type CartDiscount = {
  __typename?: 'CartDiscount';
  /** The amount of the discount applied to the item. */
  amount: Money;
  /** The description of the discount. */
  label: Array<Maybe<Scalars['String']>>;
};

export type CartItemError = {
  __typename?: 'CartItemError';
  /** An error code that describes the error encountered */
  code: CartItemErrorType;
  /** A localized error message */
  message: Scalars['String'];
};

export enum CartItemErrorType {
  Undefined = 'UNDEFINED',
  ItemQty = 'ITEM_QTY',
  ItemIncrements = 'ITEM_INCREMENTS'
}

/** Defines an item to be added to the cart. */
export type CartItemInput = {
  /** An array of entered options for the base product, such as personalization text. */
  entered_options?: Maybe<Array<Maybe<EnteredOptionInput>>>;
  /** For a child product, the SKU of its parent product. */
  parent_sku?: Maybe<Scalars['String']>;
  /** The amount or number of an item to add. */
  quantity: Scalars['Float'];
  /**
   * The selected options for the base product, such as color or size, using the
   * unique ID for an object such as `CustomizableRadioOption`,
   * `CustomizableDropDownOption`, or `ConfigurableProductOptionsValues`.
   */
  selected_options?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The SKU of the product. */
  sku: Scalars['String'];
};

/** An interface for products in a cart. */
export type CartItemInterface = {
  /** cart item comment */
  comment?: Maybe<Scalars['String']>;
  /** cart item document_names */
  document_names?: Maybe<Array<Maybe<DocumentNamesData>>>;
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<CartItemError>>>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Contains details about the price of the item, including taxes and discounts. */
export type CartItemPrices = {
  __typename?: 'CartItemPrices';
  /** An array of discounts to be applied to the cart item. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** An array of FPTs applied to the cart item. */
  fixed_product_taxes?: Maybe<Array<Maybe<FixedProductTax>>>;
  /**
   * The price of the item before any discounts were applied. The price that might
   * include tax, depending on the configured display settings for cart.
   */
  price: Money;
  /**
   * The price of the item before any discounts were applied. The price that might
   * include tax, depending on the configured display settings for cart.
   */
  price_including_tax: Money;
  /** The value of the price multiplied by the quantity of the item. */
  row_total: Money;
  /** The value of `row_total` plus the tax applied to the item. */
  row_total_including_tax: Money;
  /** The total of all discounts applied to the item. */
  total_item_discount?: Maybe<Money>;
};

/** Deprecated: The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
export type CartItemQuantity = {
  __typename?: 'CartItemQuantity';
  /** @deprecated The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
  cart_item_id: Scalars['Int'];
  /** @deprecated The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
  quantity: Scalars['Float'];
};

/** Contains details about the price of a selected customizable value. */
export type CartItemSelectedOptionValuePrice = {
  __typename?: 'CartItemSelectedOptionValuePrice';
  /** Indicates whether the price type is fixed, percent, or dynamic. */
  type: PriceTypeEnum;
  /** A string that describes the unit of the value. */
  units: Scalars['String'];
  /** A price value. */
  value: Scalars['Float'];
};

/** A single item to be updated. */
export type CartItemUpdateInput = {
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: Maybe<Scalars['Int']>;
  /** The unique ID for a `CartItemInterface` object. */
  cart_item_uid?: Maybe<Scalars['ID']>;
  /** An array that defines customizable options for the product. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** Gift message details for the cart item */
  gift_message?: Maybe<GiftMessageInput>;
  /** The new quantity of the item. */
  quantity?: Maybe<Scalars['Float']>;
};

/** Contains details about the final price of items in the cart, including discount and tax information. */
export type CartPrices = {
  __typename?: 'CartPrices';
  /** An array containing the names and amounts of taxes applied to each item in the cart. */
  applied_taxes?: Maybe<Array<Maybe<CartTaxItem>>>;
  /** @deprecated Use discounts instead. */
  discount?: Maybe<CartDiscount>;
  /** An array containing all discounts applied to the cart. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The total, including discounts, taxes, shipping, and other fees. */
  grand_total?: Maybe<Money>;
  /** The subtotal without any applied taxes. */
  subtotal_excluding_tax?: Maybe<Money>;
  /** The subtotal including any applied taxes. */
  subtotal_including_tax?: Maybe<Money>;
  /** The subtotal with any discounts applied, but not taxes. */
  subtotal_with_discount_excluding_tax?: Maybe<Money>;
};

/** Contains tax information about an item in the cart. */
export type CartTaxItem = {
  __typename?: 'CartTaxItem';
  /** The amount of tax applied to the item. */
  amount: Money;
  /** The description of the tax. */
  label: Scalars['String'];
};

/** An error encountered while adding an item to the the cart. */
export type CartUserInputError = {
  __typename?: 'CartUserInputError';
  /** A cart-specific error code. */
  code: CartUserInputErrorType;
  /** A localized error message. */
  message: Scalars['String'];
};

export enum CartUserInputErrorType {
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  NotSalable = 'NOT_SALABLE',
  InsufficientStock = 'INSUFFICIENT_STOCK',
  Undefined = 'UNDEFINED'
}

/**
 * Defines the filters to be used in the search. A filter contains at least one
 * attribute, a comparison operator, and the value that is being searched for.
 */
export type CategoryFilterInput = {
  /** Filter by the unique category ID for a `CategoryInterface` object. */
  category_uid?: Maybe<FilterEqualTypeInput>;
  /** Deprecated: use 'category_uid' to filter uniquely identifiers of categories. */
  ids?: Maybe<FilterEqualTypeInput>;
  /** Filter by the ispromoted attribute for the category. */
  ispromoted?: Maybe<FilterEqualTypeInput>;
  /** Filter by the display name of the category. */
  name?: Maybe<FilterMatchTypeInput>;
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_category_uid?: Maybe<FilterEqualTypeInput>;
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_id?: Maybe<FilterEqualTypeInput>;
  /** Filter by the showonhp attribute for the category. */
  showonhp?: Maybe<FilterEqualTypeInput>;
  /** Filter by the part of the URL that identifies the category. */
  url_key?: Maybe<FilterEqualTypeInput>;
  /** Filter by the URL path for the category. */
  url_path?: Maybe<FilterEqualTypeInput>;
};

export type CategoryInfo = {
  __typename?: 'CategoryInfo';
  /** Query by category_id */
  category_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** Query by category name */
  name?: Maybe<Scalars['String']>;
};

/** Contains the full set of attributes that can be returned in a category search. */
export type CategoryInterface = {
  available_sort_by?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of breadcrumb items. */
  breadcrumbs?: Maybe<Array<Maybe<Breadcrumb>>>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Categories' is enabled.
   */
  canonical_url?: Maybe<Scalars['String']>;
  catalog_pdf?: Maybe<Scalars['String']>;
  catalog_pdf_2?: Maybe<Scalars['String']>;
  catalog_pdf_title?: Maybe<Scalars['String']>;
  catalog_pdf_title2?: Maybe<Scalars['String']>;
  category_video?: Maybe<Scalars['String']>;
  children_count?: Maybe<Scalars['String']>;
  /** Contains a category CMS block. */
  cms_block?: Maybe<CmsBlock>;
  /**
   * The timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  custom_layout_update_file?: Maybe<Scalars['String']>;
  /** The attribute to use for sorting. */
  default_sort_by?: Maybe<Scalars['String']>;
  /** An optional description of the category. */
  description?: Maybe<Scalars['String']>;
  display_mode?: Maybe<Scalars['String']>;
  filter_price_range?: Maybe<Scalars['Float']>;
  frame_ids?: Maybe<Scalars['String']>;
  frames?: Maybe<Array<Maybe<Frame>>>;
  has_configurator?: Maybe<Scalars['Int']>;
  hero_image?: Maybe<Scalars['String']>;
  how_it_works?: Maybe<Scalars['String']>;
  hporderhc?: Maybe<Scalars['Int']>;
  hporderr?: Maybe<Scalars['Int']>;
  hpordersl?: Maybe<Scalars['Int']>;
  /**
   * An ID that uniquely identifies the category.
   * @deprecated Use `uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  include_in_menu?: Maybe<Scalars['Int']>;
  isCollection?: Maybe<Scalars['Int']>;
  isCustom?: Maybe<Scalars['Int']>;
  isFeatured?: Maybe<Scalars['Int']>;
  isGrid?: Maybe<Scalars['Int']>;
  isPromoted?: Maybe<Scalars['Int']>;
  is_anchor?: Maybe<Scalars['Int']>;
  is_collection?: Maybe<Scalars['String']>;
  jola_product_count?: Maybe<Scalars['String']>;
  landing_page?: Maybe<Scalars['Int']>;
  /** The depth of the category within the tree. */
  level?: Maybe<Scalars['Int']>;
  media_gallery_entries?: Maybe<Array<Maybe<CategoryMediaGalleryEntry>>>;
  meta_description?: Maybe<Scalars['String']>;
  meta_keywords?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  /** The display name of the category. */
  name?: Maybe<Scalars['String']>;
  /** The full category path. */
  path?: Maybe<Scalars['String']>;
  /** The category path within the store. */
  path_in_store?: Maybe<Scalars['String']>;
  /** The position of the category relative to other categories at the same level in tree. */
  position?: Maybe<Scalars['Int']>;
  possible_skus?: Maybe<Scalars['String']>;
  /**
   * The number of products in the category that are marked as visible. By default,
   * in complex products, parent products are visible, but their child products are not.
   */
  product_count?: Maybe<Scalars['Int']>;
  /** The list of products assigned to the category. */
  products?: Maybe<CategoryProducts>;
  promo_image?: Maybe<Scalars['String']>;
  showonhp?: Maybe<Scalars['Int']>;
  steps_description?: Maybe<Scalars['String']>;
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID'];
  /**
   * The timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** The URL key assigned to the category. */
  url_key?: Maybe<Scalars['String']>;
  /** The URL path assigned to the category. */
  url_path?: Maybe<Scalars['String']>;
  /** The part of the category URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
};


/** Contains the full set of attributes that can be returned in a category search. */
export type CategoryInterfaceProductsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  sort?: Maybe<ProductAttributeSortInput>;
};

export type CategoryMediaGalleryEntry = {
  __typename?: 'CategoryMediaGalleryEntry';
  image_url?: Maybe<Scalars['String']>;
};

/** Contains details about the products assigned to a category. */
export type CategoryProducts = {
  __typename?: 'CategoryProducts';
  /** An array of products that are assigned to the category. */
  items?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Pagination metadata. */
  page_info?: Maybe<SearchResultPageInfo>;
  /**
   * The number of products in the category that are marked as visible. By default,
   * in complex products, parent products are visible, but their child products are not.
   */
  total_count?: Maybe<Scalars['Int']>;
};

/** Contains a collection of `CategoryTree` objects and pagination information. */
export type CategoryResult = {
  __typename?: 'CategoryResult';
  /** A list of categories that match the filter criteria. */
  items?: Maybe<Array<Maybe<CategoryTree>>>;
  /** An object that includes the `page_info` and `currentPage` values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The total number of categories that match the criteria. */
  total_count?: Maybe<Scalars['Int']>;
};

/** Category Tree implementation. */
export type CategoryTree = CategoryInterface & RoutableInterface & {
  __typename?: 'CategoryTree';
  available_sort_by?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of breadcrumb items. */
  breadcrumbs?: Maybe<Array<Maybe<Breadcrumb>>>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Categories' is enabled.
   */
  canonical_url?: Maybe<Scalars['String']>;
  catalog_pdf?: Maybe<Scalars['String']>;
  catalog_pdf_2?: Maybe<Scalars['String']>;
  catalog_pdf_title?: Maybe<Scalars['String']>;
  catalog_pdf_title2?: Maybe<Scalars['String']>;
  category_video?: Maybe<Scalars['String']>;
  /** A tree of child categories. */
  children?: Maybe<Array<Maybe<CategoryTree>>>;
  children_count?: Maybe<Scalars['String']>;
  /** Contains a category CMS block. */
  cms_block?: Maybe<CmsBlock>;
  /**
   * The timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  custom_layout_update_file?: Maybe<Scalars['String']>;
  /** The attribute to use for sorting. */
  default_sort_by?: Maybe<Scalars['String']>;
  /** An optional description of the category. */
  description?: Maybe<Scalars['String']>;
  display_mode?: Maybe<Scalars['String']>;
  filter_price_range?: Maybe<Scalars['Float']>;
  frame_ids?: Maybe<Scalars['String']>;
  frames?: Maybe<Array<Maybe<Frame>>>;
  has_configurator?: Maybe<Scalars['Int']>;
  /** Query by hero image */
  hero_image?: Maybe<Scalars['String']>;
  /** Query by how it works CMS Block. */
  how_it_works?: Maybe<Scalars['String']>;
  hporderhc?: Maybe<Scalars['Int']>;
  hporderr?: Maybe<Scalars['Int']>;
  hpordersl?: Maybe<Scalars['Int']>;
  /**
   * An ID that uniquely identifies the category.
   * @deprecated Use `uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  include_in_menu?: Maybe<Scalars['Int']>;
  isCollection?: Maybe<Scalars['Int']>;
  isCustom?: Maybe<Scalars['Int']>;
  isFeatured?: Maybe<Scalars['Int']>;
  isGrid?: Maybe<Scalars['Int']>;
  isPromoted?: Maybe<Scalars['Int']>;
  is_anchor?: Maybe<Scalars['Int']>;
  is_collection?: Maybe<Scalars['String']>;
  jola_product_count?: Maybe<Scalars['String']>;
  landing_page?: Maybe<Scalars['Int']>;
  /** The depth of the category within the tree. */
  level?: Maybe<Scalars['Int']>;
  media_gallery_entries?: Maybe<Array<Maybe<CategoryMediaGalleryEntry>>>;
  meta_description?: Maybe<Scalars['String']>;
  meta_keywords?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  /** The display name of the category. */
  name?: Maybe<Scalars['String']>;
  /** The full category path. */
  path?: Maybe<Scalars['String']>;
  /** The category path within the store. */
  path_in_store?: Maybe<Scalars['String']>;
  /** The position of the category relative to other categories at the same level in tree. */
  position?: Maybe<Scalars['Int']>;
  /** Query by possible skus */
  possible_skus?: Maybe<Scalars['String']>;
  /**
   * The number of products in the category that are marked as visible. By default,
   * in complex products, parent products are visible, but their child products are not.
   */
  product_count?: Maybe<Scalars['Int']>;
  /** The list of products assigned to the category. */
  products?: Maybe<CategoryProducts>;
  /** Query by promo image */
  promo_image?: Maybe<Scalars['String']>;
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirect_code: Scalars['Int'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  showonhp?: Maybe<Scalars['Int']>;
  steps_description?: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID'];
  /**
   * The timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** The URL key assigned to the category. */
  url_key?: Maybe<Scalars['String']>;
  /** The URL path assigned to the category. */
  url_path?: Maybe<Scalars['String']>;
  /** The part of the category URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
};


/** Category Tree implementation. */
export type CategoryTreeProductsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  sort?: Maybe<ProductAttributeSortInput>;
};

export type ChangeName = {
  fromName?: Maybe<Scalars['String']>;
  toName?: Maybe<Scalars['String']>;
};

export type CheckEmailOutput = {
  __typename?: 'checkEmailOutput';
  /** Customer name by email */
  username?: Maybe<Scalars['String']>;
};

/** Defines details about an individual checkout agreement. */
export type CheckoutAgreement = {
  __typename?: 'CheckoutAgreement';
  /** The ID for a checkout agreement. */
  agreement_id: Scalars['Int'];
  /** The checkbox text for the checkout agreement. */
  checkbox_text: Scalars['String'];
  /** Required. The text of the agreement. */
  content: Scalars['String'];
  /** The height of the text box where the Terms and Conditions statement appears during checkout. */
  content_height?: Maybe<Scalars['String']>;
  /** Indicates whether the `content` text is in HTML format. */
  is_html: Scalars['Boolean'];
  /** Indicates whether agreements are accepted automatically or manually. */
  mode: CheckoutAgreementMode;
  /** The name given to the condition. */
  name: Scalars['String'];
};

/** Indicates how agreements are accepted. */
export enum CheckoutAgreementMode {
  /** Conditions are automatically accepted upon checkout. */
  Auto = 'AUTO',
  /** Shoppers must manually accept the conditions to place an order. */
  Manual = 'MANUAL'
}

/** An error encountered while adding an item to the cart. */
export type CheckoutUserInputError = {
  __typename?: 'CheckoutUserInputError';
  /** An error code that is specific to Checkout. */
  code: CheckoutUserInputErrorCodes;
  /** A localized error message. */
  message: Scalars['String'];
  /**
   * The path to the input field that caused an error. See the GraphQL
   * specification about path errors for details:
   * http://spec.graphql.org/draft/#sec-Errors
   */
  path: Array<Maybe<Scalars['String']>>;
};

export enum CheckoutUserInputErrorCodes {
  ReorderNotAvailable = 'REORDER_NOT_AVAILABLE',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  NotSalable = 'NOT_SALABLE',
  InsufficientStock = 'INSUFFICIENT_STOCK',
  Undefined = 'UNDEFINED'
}

export type ClaimItem = {
  acknowledgement_number: Scalars['String'];
  can_be_kept_at_discount?: Maybe<Scalars['String']>;
  can_be_repaired_locally: Scalars['String'];
  claim_damage_type: Scalars['String'];
  claim_type: Scalars['String'];
  damage_description: Scalars['String'];
  item_description: Scalars['String'];
  local_repair_quote?: Maybe<Scalars['String']>;
  original_carton: Scalars['String'];
  packaging_type: Scalars['String'];
  photo_upload: Array<Maybe<Scalars['String']>>;
  quantity?: Maybe<Scalars['String']>;
  style_number: Scalars['String'];
  where_is_the_item_located: Scalars['String'];
};

export type ClaimItems = {
  __typename?: 'ClaimItems';
  acknowledgement_number: Scalars['String'];
  claim_id: Scalars['String'];
  status: Scalars['String'];
  style_number: Scalars['String'];
};

export type ClaimOutput = {
  __typename?: 'ClaimOutput';
  claim_id: Scalars['String'];
  contact_consignee_name: Scalars['String'];
  contact_email: Scalars['String'];
  contact_fairfield_account_number?: Maybe<Scalars['String']>;
  contact_fax?: Maybe<Scalars['String']>;
  contact_first_name: Scalars['String'];
  contact_industry: Scalars['String'];
  contact_last_name: Scalars['String'];
  contact_phone: Scalars['String'];
  contact_zip: Scalars['String'];
  created_at: Scalars['String'];
  customer_id: Scalars['String'];
  items?: Maybe<Array<Maybe<SingleClaimItem>>>;
  remit_address1: Scalars['String'];
  remit_address2?: Maybe<Scalars['String']>;
  remit_city: Scalars['String'];
  remit_country: Scalars['String'];
  remit_first_name: Scalars['String'];
  remit_last_name: Scalars['String'];
  remit_state?: Maybe<Scalars['String']>;
  remit_title: Scalars['String'];
  remit_zip: Scalars['String'];
  sales_representative?: Maybe<Array<Maybe<SalesRepresentative>>>;
  status: Scalars['String'];
};

export type ClaimsEmailInput = {
  claim_items_info?: Maybe<Array<Maybe<ClaimItem>>>;
  contact_consignee_name: Scalars['String'];
  contact_email: Scalars['String'];
  contact_fairfield_account_number?: Maybe<Scalars['String']>;
  contact_fax?: Maybe<Scalars['String']>;
  contact_first_name: Scalars['String'];
  contact_industry: Scalars['String'];
  contact_last_name: Scalars['String'];
  contact_phone: Scalars['String'];
  contact_zip: Scalars['String'];
  remit_address1: Scalars['String'];
  remit_address2?: Maybe<Scalars['String']>;
  remit_city: Scalars['String'];
  remit_country: Scalars['String'];
  remit_first_name: Scalars['String'];
  remit_last_name: Scalars['String'];
  remit_state?: Maybe<Scalars['String']>;
  remit_title: Scalars['String'];
  remit_zip: Scalars['String'];
};

export type ClaimsFileInput = {
  claims_image?: Maybe<WMediaGalleryEntriesContent>;
};

export type ClaimsFileOutput = {
  __typename?: 'ClaimsFileOutput';
  claims_image?: Maybe<Scalars['String']>;
};

export type ClaimsInput = {
  /** Specifies which page of results to return. The default value is 1 */
  currentPage?: Maybe<Scalars['Int']>;
  /** Defines the filter to use for searching claims */
  filter?: Maybe<CustomerClaimsFilterInput>;
  /** Specifies the maximum number of results to return at once. The default value is 20 */
  pageSize?: Maybe<Scalars['Int']>;
  /** sorting attribute */
  sorting_attribute?: Maybe<Scalars['String']>;
  /** sorting direction ASC DESC */
  sorting_direction?: Maybe<Scalars['String']>;
};

export type ClaimsOutput = {
  __typename?: 'ClaimsOutput';
  /** Claim Items */
  items?: Maybe<Array<Maybe<ClaimItems>>>;
  /** Page information */
  page_info?: Maybe<ClaimsPageInfo>;
  /** Total number of items */
  total_count?: Maybe<Scalars['String']>;
};

export type ClaimsPageInfo = {
  __typename?: 'ClaimsPageInfo';
  current_page: Scalars['Int'];
  page_size: Scalars['Int'];
  total_pages: Scalars['Int'];
};

/** Contains details about a specific CMS block. */
export type CmsBlock = {
  __typename?: 'CmsBlock';
  /** The content of the CMS block in raw HTML. */
  content?: Maybe<Scalars['String']>;
  /** The CMS block identifier. */
  identifier?: Maybe<Scalars['String']>;
  /** The title assigned to the CMS block. */
  title?: Maybe<Scalars['String']>;
};

export type CmsBlockObject = {
  __typename?: 'CmsBlockObject';
  content?: Maybe<Scalars['String']>;
  creation_time?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  update_time?: Maybe<Scalars['String']>;
};

/** Contains an array CMS block items. */
export type CmsBlocks = {
  __typename?: 'CmsBlocks';
  /** An array of CMS blocks. */
  items?: Maybe<Array<Maybe<CmsBlock>>>;
};

/** Contains details about a CMS page. */
export type CmsPage = RoutableInterface & {
  __typename?: 'CmsPage';
  /** The content of the CMS page in raw HTML. */
  content?: Maybe<Scalars['String']>;
  /** The heading that displays at the top of the CMS page. */
  content_heading?: Maybe<Scalars['String']>;
  /** The ID of a CMS page. */
  identifier?: Maybe<Scalars['String']>;
  /** A brief description of the page for search results listings. */
  meta_description?: Maybe<Scalars['String']>;
  /** A brief description of the page for search results listings. */
  meta_keywords?: Maybe<Scalars['String']>;
  /** A page title that is indexed by search engines and appears in search results listings. */
  meta_title?: Maybe<Scalars['String']>;
  /** The design layout of the page, indicating the number of columns and navigation features used on the page. */
  page_layout?: Maybe<Scalars['String']>;
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirect_code: Scalars['Int'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** The name that appears in the breadcrumb trail navigation and in the browser title bar and tab. */
  title?: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /** The URL key of the CMS page, which is often based on the `content_heading`. */
  url_key?: Maybe<Scalars['String']>;
};

export type ColorSwatchData = SwatchDataInterface & {
  __typename?: 'ColorSwatchData';
  /** The value can be represented as color (HEX code), image link, or text. */
  value?: Maybe<Scalars['String']>;
};

/** Contains an attribute code that is used for product comparisons. */
export type ComparableAttribute = {
  __typename?: 'ComparableAttribute';
  /** An attribute code that is enabled for product comparisons. */
  code: Scalars['String'];
  /** The label of the attribute code. */
  label: Scalars['String'];
};

/** Defines an object used to iterate through items for product comparisons. */
export type ComparableItem = {
  __typename?: 'ComparableItem';
  /** An array of product attributes that can be used to compare products. */
  attributes: Array<Maybe<ProductAttribute>>;
  /** Details about a product in a compare list. */
  product: ProductInterface;
  /** The unique ID of an item in a compare list. */
  uid: Scalars['ID'];
};

/** Contains iterable information such as the array of items, the count, and attributes that represent the compare list. */
export type CompareList = {
  __typename?: 'CompareList';
  /** An array of attributes that can be used for comparing products. */
  attributes?: Maybe<Array<Maybe<ComparableAttribute>>>;
  /** The number of items in the compare list. */
  item_count: Scalars['Int'];
  /** An array of products to compare. */
  items?: Maybe<Array<Maybe<ComparableItem>>>;
  /** The unique ID assigned to the compare list. */
  uid: Scalars['ID'];
};

export type ComplexTextValue = {
  __typename?: 'ComplexTextValue';
  /** Text that can contain HTML tags. */
  html: Scalars['String'];
};

/** Contains details about a configurable product attribute option. */
export type ConfigurableAttributeOption = {
  __typename?: 'ConfigurableAttributeOption';
  /** The ID assigned to the attribute. */
  code?: Maybe<Scalars['String']>;
  /** A string that describes the configurable attribute option. */
  label?: Maybe<Scalars['String']>;
  /** The unique ID for a `ConfigurableAttributeOption` object. */
  uid: Scalars['ID'];
  /** A unique index number assigned to the configurable product option. */
  value_index?: Maybe<Scalars['Int']>;
};

/** An implementation for configurable product cart items. */
export type ConfigurableCartItem = CartItemInterface & {
  __typename?: 'ConfigurableCartItem';
  /** cart item comment */
  comment?: Maybe<Scalars['String']>;
  /** An array containing the configuranle options the shopper selected. */
  configurable_options: Array<Maybe<SelectedConfigurableOption>>;
  /** Product details of the cart item. */
  configured_variant: ProductInterface;
  /** An array containing the customizable options the shopper selected. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** cart item document_names */
  document_names?: Maybe<Array<Maybe<DocumentNamesData>>>;
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<CartItemError>>>;
  /** The entered gift message for the cart item */
  gift_message?: Maybe<GiftMessage>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Describes configurable options that have been selected and can be selected as a result of the previous selections. */
export type ConfigurableOptionAvailableForSelection = {
  __typename?: 'ConfigurableOptionAvailableForSelection';
  /** An attribute code that uniquely identifies a configurable option. */
  attribute_code: Scalars['String'];
  /** An array of selectable option value IDs. */
  option_value_uids: Array<Maybe<Scalars['ID']>>;
};

/** Defines basic features of a configurable product and its simple product variants. */
export type ConfigurableProduct = ProductInterface & RoutableInterface & PhysicalProductInterface & CustomizableProductInterface & {
  __typename?: 'ConfigurableProduct';
  /**
   * Tearsheet PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  assembly_instructions?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  attribute_set_name?: Maybe<Scalars['String']>;
  /**
   * Availability date for the product.
   * @deprecated Use the `custom_attributes` field instead.
   */
  availability_date?: Maybe<Scalars['String']>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Products' is enabled.
   */
  canonical_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  catalog_page?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  /**
   * Order Form PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  collection_overview_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_caster_options?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_wheels_option?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_wood_finish?: Maybe<Scalars['Int']>;
  /** An array of options for the configurable product. */
  configurable_options?: Maybe<Array<Maybe<ConfigurableProductOptions>>>;
  /**
   * An array of media gallery items and other details about selected configurable
   * product options as well as details about remaining selectable options.
   */
  configurable_product_options_selection?: Maybe<ConfigurableProductOptionsSelection>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_apronh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_armh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_assem_req?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_avails?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_backconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_basefab?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_baselth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_bn_appl?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_casegd?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_casters?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cfgmodel?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_checkbox_test_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_collection?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_countryorg?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cubes?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cupholder?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_depth_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_designer?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_dftfabric?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_dftfinish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_duedate?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_extdesc2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_finish_req?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_finishopt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdepthgh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdeptlow?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimd?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimw?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_fulllftwal?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_fullyuph?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_height_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidebkh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestd?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestd_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw_test?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_introdat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_isvisible?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_lanect?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_laners?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_lanesl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leatheravl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_madeto?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_moistbarr?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_nail_opts?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhigharh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhighovh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhighsth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowarh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowovh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowsth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_onords?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_product_type_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_qty_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_reclindpt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_reclwallcl?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_riser?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seatconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seath?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seath_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_sprgconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_status?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdback?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdcushion?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdfinish?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdpllw1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tablet?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tariff?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tvrecline?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_uph_opts?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_wgtcar?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_wgtwrp?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_width_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_woodspec1?: Maybe<Scalars['Int']>;
  ffi_woodspec1_label?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_finish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_type_filterable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_abrasion?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_avail?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_avail_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_categories?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_category1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_collection?: Maybe<Scalars['String']>;
  ffifabrics_collection_label?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_colorways?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_country?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_cutyard?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_dec_cord?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_descr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_descript?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont9?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct9?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabric?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_finishtype?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fringe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fullcont?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_grade?: Maybe<Array<Maybe<Scalars['String']>>>;
  ffifabrics_grade_configurator_filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_hexclrgrp?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_hrepeat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_leather?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_pattern?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_std_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_tape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vendor?: Maybe<Array<Maybe<Scalars['String']>>>;
  ffifabrics_vendor_image?: Maybe<SwatchValues>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vinyl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vrepeat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_about?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_chm_distr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_cleaning?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_fairshield?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_nailhead?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_phy_distr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_sheen?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  file_upload?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  filterable_attribute_set_name?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  hotspots?: Maybe<Array<Maybe<ProductHotspotData>>>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** Add notification that loged user have added product in downloads in specific store */
  in_downloads?: Maybe<Scalars['String']>;
  /** Image is a part of in_real_life role. */
  in_real_life?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  in_stock_filter?: Maybe<Scalars['Int']>;
  industry_approval_attribute_option?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  introdat_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_featured?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_new?: Maybe<Scalars['Int']>;
  /** Adding NULL for this field because of React core. */
  lead_time?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_image role. */
  lifestyle_image?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_image2 role. */
  lifestyle_image2?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_video role. */
  lifestyle_video?: Maybe<Scalars['String']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer?: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  new_from_date?: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  new_to_date?: Maybe<Scalars['String']>;
  /** Number of throw/kidney pillows */
  num_of_pillows?: Maybe<Scalars['Int']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** The range of prices for the product */
  price_range: PriceRange;
  /** An array of `TierPrice` objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_features?: Maybe<Scalars['String']>;
  product_features_label?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of `ProductLinks` objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** @deprecated Use the `custom_attributes` field instead. */
  ready_to_ship_filter?: Maybe<Scalars['Int']>;
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirect_code: Scalars['Int'];
  /** An array of products to be displayed in a Related Products block. */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /**
   * Packing Fee List PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  revit_file?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shop_by_position?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * Thread Board PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  spec_sheet?: Maybe<Scalars['String']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /** An array of simple product variants. */
  variants?: Maybe<Array<Maybe<ConfigurableVariant>>>;
  /**
   * The uploaded video.
   * @deprecated Use the `custom_attributes` field instead.
   */
  video_upload?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
};


/** Defines basic features of a configurable product and its simple product variants. */
export type ConfigurableProductConfigurable_Product_Options_SelectionArgs = {
  configurableOptionValueUids?: Maybe<Array<Scalars['ID']>>;
};


/** Defines basic features of a configurable product and its simple product variants. */
export type ConfigurableProductReviewsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

export type ConfigurableProductCartItemInput = {
  /** The ID and value of the option. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** The quantity and SKU of the configurable product. */
  data: CartItemInput;
  /** The SKU of the parent configurable product. */
  parent_sku?: Maybe<Scalars['String']>;
  /** Deprecated. Use `CartItemInput.sku` instead. */
  variant_sku?: Maybe<Scalars['String']>;
};

/** Contains details about configurable product options. */
export type ConfigurableProductOption = {
  __typename?: 'ConfigurableProductOption';
  /** An attribute code that uniquely identifies a configurable option. */
  attribute_code: Scalars['String'];
  /** The display name of the option. */
  label: Scalars['String'];
  /** The unique ID of the configurable option. */
  uid: Scalars['ID'];
  /** An array of values that are applicable for this option. */
  values?: Maybe<Array<Maybe<ConfigurableProductOptionValue>>>;
};

/** Defines configurable attributes for the specified product. */
export type ConfigurableProductOptions = {
  __typename?: 'ConfigurableProductOptions';
  /** A string that identifies the attribute. */
  attribute_code?: Maybe<Scalars['String']>;
  /**
   * The ID assigned to the attribute.
   * @deprecated Use `attribute_uid` instead.
   */
  attribute_id?: Maybe<Scalars['String']>;
  /**
   * The ID assigned to the attribute.
   * @deprecated Use `attribute_uid` instead.
   */
  attribute_id_v2?: Maybe<Scalars['Int']>;
  /** The unique ID for an `Attribute` object. */
  attribute_uid: Scalars['ID'];
  /**
   * The configurable option ID number assigned by the system.
   * @deprecated Use `uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** A displayed string that describes the configurable product option. */
  label?: Maybe<Scalars['String']>;
  /** A number that indicates the order in which the attribute is displayed. */
  position?: Maybe<Scalars['Int']>;
  /**
   * This is the same as a product's `id` field.
   * @deprecated `product_id` is not needed and can be obtained from its parent.
   */
  product_id?: Maybe<Scalars['Int']>;
  /** The unique ID for a `ConfigurableProductOptions` object. */
  uid: Scalars['ID'];
  /** Indicates whether the option is the default. */
  use_default?: Maybe<Scalars['Boolean']>;
  /** An array that defines the `value_index` codes assigned to the configurable product. */
  values?: Maybe<Array<Maybe<ConfigurableProductOptionsValues>>>;
};

/** Contains metadata corresponding to the selected configurable options. */
export type ConfigurableProductOptionsSelection = {
  __typename?: 'ConfigurableProductOptionsSelection';
  /** An array of all possible configurable options. */
  configurable_options?: Maybe<Array<Maybe<ConfigurableProductOption>>>;
  /** Product images and videos corresponding to the specified configurable options selection. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /** The configurable options available for further selection based on the current selection. */
  options_available_for_selection?: Maybe<Array<Maybe<ConfigurableOptionAvailableForSelection>>>;
  /**
   * A variant represented by the specified configurable options selection. The
   * value is expected to be null until selections are made for each configurable option.
   */
  variant?: Maybe<SimpleProduct>;
};

/** Contains the index number assigned to a configurable product option. */
export type ConfigurableProductOptionsValues = {
  __typename?: 'ConfigurableProductOptionsValues';
  /** The label of the product on the default store. */
  default_label?: Maybe<Scalars['String']>;
  /** The label of the product. */
  label?: Maybe<Scalars['String']>;
  /** The label of the product on the current store. */
  store_label?: Maybe<Scalars['String']>;
  /** Swatch data for a configurable product option. */
  swatch_data?: Maybe<SwatchDataInterface>;
  /** The unique ID for a `ConfigurableProductOptionsValues` object. */
  uid?: Maybe<Scalars['ID']>;
  /** Indicates whether to use the default_label. */
  use_default_value?: Maybe<Scalars['Boolean']>;
  /**
   * A unique index number assigned to the configurable product option.
   * @deprecated Use `uid` instead.
   */
  value_index?: Maybe<Scalars['Int']>;
};

/** Defines a value for a configurable product option. */
export type ConfigurableProductOptionValue = {
  __typename?: 'ConfigurableProductOptionValue';
  /** Indicates whether the product is available with this selected option. */
  is_available: Scalars['Boolean'];
  /** Indicates whether the value is the default. */
  is_use_default: Scalars['Boolean'];
  /** The display name of the value. */
  label: Scalars['String'];
  /** The URL assigned to the thumbnail of the swatch image. */
  swatch?: Maybe<SwatchDataInterface>;
  /** The unique ID of the value. */
  uid: Scalars['ID'];
};

/** Contains all the simple product variants of a configurable product. */
export type ConfigurableVariant = {
  __typename?: 'ConfigurableVariant';
  /** An array of configurable attribute options. */
  attributes?: Maybe<Array<Maybe<ConfigurableAttributeOption>>>;
  /** An array of linked simple products. */
  product?: Maybe<SimpleProduct>;
};

/** A configurable product wish list item. */
export type ConfigurableWishlistItem = WishlistItemInterface & {
  __typename?: 'ConfigurableWishlistItem';
  /** The date and time the item was added to the wish list. */
  added_at: Scalars['String'];
  /**
   * The SKU of the simple product corresponding to a set of selected configurable options.
   * @deprecated Use `ConfigurableWishlistItem.configured_variant.sku` instead.
   */
  child_sku: Scalars['String'];
  /** An array of selected configurable options. */
  configurable_options?: Maybe<Array<Maybe<SelectedConfigurableOption>>>;
  /** Product details of the selected variant. The value is null if some options are not configured. */
  configured_variant?: Maybe<ProductInterface>;
  /** Custom options selected for the wish list item. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
};

export type ConfiguratorFileInput = {
  configurator_image?: Maybe<WMediaGalleryEntriesContent>;
};

export type ConfiguratorFileOutput = {
  __typename?: 'ConfiguratorFileOutput';
  configurator_image?: Maybe<Scalars['String']>;
};

export type ContactUsInput = {
  company_name?: Maybe<Scalars['String']>;
  customer_email?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Scalars['String']>;
  existing_customer?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  type_of_inquiry?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  /** An array of regions within a particular country. */
  available_regions?: Maybe<Array<Maybe<Region>>>;
  /** The name of the country in English. */
  full_name_english?: Maybe<Scalars['String']>;
  /** The name of the country in the current locale. */
  full_name_locale?: Maybe<Scalars['String']>;
  /** The unique ID for a `Country` object. */
  id?: Maybe<Scalars['String']>;
  /** The three-letter abbreviation of the country, such as USA. */
  three_letter_abbreviation?: Maybe<Scalars['String']>;
  /** The two-letter abbreviation of the country, such as US. */
  two_letter_abbreviation?: Maybe<Scalars['String']>;
};

/** The list of country codes. */
export enum CountryCodeEnum {
  /** Afghanistan */
  Af = 'AF',
  /** Åland Islands */
  Ax = 'AX',
  /** Albania */
  Al = 'AL',
  /** Algeria */
  Dz = 'DZ',
  /** American Samoa */
  As = 'AS',
  /** Andorra */
  Ad = 'AD',
  /** Angola */
  Ao = 'AO',
  /** Anguilla */
  Ai = 'AI',
  /** Antarctica */
  Aq = 'AQ',
  /** Antigua & Barbuda */
  Ag = 'AG',
  /** Argentina */
  Ar = 'AR',
  /** Armenia */
  Am = 'AM',
  /** Aruba */
  Aw = 'AW',
  /** Australia */
  Au = 'AU',
  /** Austria */
  At = 'AT',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bahamas */
  Bs = 'BS',
  /** Bahrain */
  Bh = 'BH',
  /** Bangladesh */
  Bd = 'BD',
  /** Barbados */
  Bb = 'BB',
  /** Belarus */
  By = 'BY',
  /** Belgium */
  Be = 'BE',
  /** Belize */
  Bz = 'BZ',
  /** Benin */
  Bj = 'BJ',
  /** Bermuda */
  Bm = 'BM',
  /** Bhutan */
  Bt = 'BT',
  /** Bolivia */
  Bo = 'BO',
  /** Bosnia & Herzegovina */
  Ba = 'BA',
  /** Botswana */
  Bw = 'BW',
  /** Bouvet Island */
  Bv = 'BV',
  /** Brazil */
  Br = 'BR',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** British Virgin Islands */
  Vg = 'VG',
  /** Brunei */
  Bn = 'BN',
  /** Bulgaria */
  Bg = 'BG',
  /** Burkina Faso */
  Bf = 'BF',
  /** Burundi */
  Bi = 'BI',
  /** Cambodia */
  Kh = 'KH',
  /** Cameroon */
  Cm = 'CM',
  /** Canada */
  Ca = 'CA',
  /** Cape Verde */
  Cv = 'CV',
  /** Cayman Islands */
  Ky = 'KY',
  /** Central African Republic */
  Cf = 'CF',
  /** Chad */
  Td = 'TD',
  /** Chile */
  Cl = 'CL',
  /** China */
  Cn = 'CN',
  /** Christmas Island */
  Cx = 'CX',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Colombia */
  Co = 'CO',
  /** Comoros */
  Km = 'KM',
  /** Congo-Brazzaville */
  Cg = 'CG',
  /** Congo-Kinshasa */
  Cd = 'CD',
  /** Cook Islands */
  Ck = 'CK',
  /** Costa Rica */
  Cr = 'CR',
  /** Côte d’Ivoire */
  Ci = 'CI',
  /** Croatia */
  Hr = 'HR',
  /** Cuba */
  Cu = 'CU',
  /** Cyprus */
  Cy = 'CY',
  /** Czech Republic */
  Cz = 'CZ',
  /** Denmark */
  Dk = 'DK',
  /** Djibouti */
  Dj = 'DJ',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Ecuador */
  Ec = 'EC',
  /** Egypt */
  Eg = 'EG',
  /** El Salvador */
  Sv = 'SV',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Eritrea */
  Er = 'ER',
  /** Estonia */
  Ee = 'EE',
  /** Ethiopia */
  Et = 'ET',
  /** Falkland Islands */
  Fk = 'FK',
  /** Faroe Islands */
  Fo = 'FO',
  /** Fiji */
  Fj = 'FJ',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** French Guiana */
  Gf = 'GF',
  /** French Polynesia */
  Pf = 'PF',
  /** French Southern Territories */
  Tf = 'TF',
  /** Gabon */
  Ga = 'GA',
  /** Gambia */
  Gm = 'GM',
  /** Georgia */
  Ge = 'GE',
  /** Germany */
  De = 'DE',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greece */
  Gr = 'GR',
  /** Greenland */
  Gl = 'GL',
  /** Grenada */
  Gd = 'GD',
  /** Guadeloupe */
  Gp = 'GP',
  /** Guam */
  Gu = 'GU',
  /** Guatemala */
  Gt = 'GT',
  /** Guernsey */
  Gg = 'GG',
  /** Guinea */
  Gn = 'GN',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Haiti */
  Ht = 'HT',
  /** Heard &amp; McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Hong Kong SAR China */
  Hk = 'HK',
  /** Hungary */
  Hu = 'HU',
  /** Iceland */
  Is = 'IS',
  /** India */
  In = 'IN',
  /** Indonesia */
  Id = 'ID',
  /** Iran */
  Ir = 'IR',
  /** Iraq */
  Iq = 'IQ',
  /** Ireland */
  Ie = 'IE',
  /** Isle of Man */
  Im = 'IM',
  /** Israel */
  Il = 'IL',
  /** Italy */
  It = 'IT',
  /** Jamaica */
  Jm = 'JM',
  /** Japan */
  Jp = 'JP',
  /** Jersey */
  Je = 'JE',
  /** Jordan */
  Jo = 'JO',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Kenya */
  Ke = 'KE',
  /** Kiribati */
  Ki = 'KI',
  /** Kuwait */
  Kw = 'KW',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Laos */
  La = 'LA',
  /** Latvia */
  Lv = 'LV',
  /** Lebanon */
  Lb = 'LB',
  /** Lesotho */
  Ls = 'LS',
  /** Liberia */
  Lr = 'LR',
  /** Libya */
  Ly = 'LY',
  /** Liechtenstein */
  Li = 'LI',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Macau SAR China */
  Mo = 'MO',
  /** Macedonia */
  Mk = 'MK',
  /** Madagascar */
  Mg = 'MG',
  /** Malawi */
  Mw = 'MW',
  /** Malaysia */
  My = 'MY',
  /** Maldives */
  Mv = 'MV',
  /** Mali */
  Ml = 'ML',
  /** Malta */
  Mt = 'MT',
  /** Marshall Islands */
  Mh = 'MH',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Mauritius */
  Mu = 'MU',
  /** Mayotte */
  Yt = 'YT',
  /** Mexico */
  Mx = 'MX',
  /** Micronesia */
  Fm = 'FM',
  /** Moldova */
  Md = 'MD',
  /** Monaco */
  Mc = 'MC',
  /** Mongolia */
  Mn = 'MN',
  /** Montenegro */
  Me = 'ME',
  /** Montserrat */
  Ms = 'MS',
  /** Morocco */
  Ma = 'MA',
  /** Mozambique */
  Mz = 'MZ',
  /** Myanmar (Burma) */
  Mm = 'MM',
  /** Namibia */
  Na = 'NA',
  /** Nauru */
  Nr = 'NR',
  /** Nepal */
  Np = 'NP',
  /** Netherlands */
  Nl = 'NL',
  /** Netherlands Antilles */
  An = 'AN',
  /** New Caledonia */
  Nc = 'NC',
  /** New Zealand */
  Nz = 'NZ',
  /** Nicaragua */
  Ni = 'NI',
  /** Niger */
  Ne = 'NE',
  /** Nigeria */
  Ng = 'NG',
  /** Niue */
  Nu = 'NU',
  /** Norfolk Island */
  Nf = 'NF',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** North Korea */
  Kp = 'KP',
  /** Norway */
  No = 'NO',
  /** Oman */
  Om = 'OM',
  /** Pakistan */
  Pk = 'PK',
  /** Palau */
  Pw = 'PW',
  /** Palestinian Territories */
  Ps = 'PS',
  /** Panama */
  Pa = 'PA',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Paraguay */
  Py = 'PY',
  /** Peru */
  Pe = 'PE',
  /** Philippines */
  Ph = 'PH',
  /** Pitcairn Islands */
  Pn = 'PN',
  /** Poland */
  Pl = 'PL',
  /** Portugal */
  Pt = 'PT',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Samoa */
  Ws = 'WS',
  /** San Marino */
  Sm = 'SM',
  /** São Tomé & Príncipe */
  St = 'ST',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Senegal */
  Sn = 'SN',
  /** Serbia */
  Rs = 'RS',
  /** Seychelles */
  Sc = 'SC',
  /** Sierra Leone */
  Sl = 'SL',
  /** Singapore */
  Sg = 'SG',
  /** Slovakia */
  Sk = 'SK',
  /** Slovenia */
  Si = 'SI',
  /** Solomon Islands */
  Sb = 'SB',
  /** Somalia */
  So = 'SO',
  /** South Africa */
  Za = 'ZA',
  /** South Georgia & South Sandwich Islands */
  Gs = 'GS',
  /** South Korea */
  Kr = 'KR',
  /** Spain */
  Es = 'ES',
  /** Sri Lanka */
  Lk = 'LK',
  /** St. Barthélemy */
  Bl = 'BL',
  /** St. Helena */
  Sh = 'SH',
  /** St. Kitts & Nevis */
  Kn = 'KN',
  /** St. Lucia */
  Lc = 'LC',
  /** St. Martin */
  Mf = 'MF',
  /** St. Pierre & Miquelon */
  Pm = 'PM',
  /** St. Vincent & Grenadines */
  Vc = 'VC',
  /** Sudan */
  Sd = 'SD',
  /** Suriname */
  Sr = 'SR',
  /** Svalbard & Jan Mayen */
  Sj = 'SJ',
  /** Swaziland */
  Sz = 'SZ',
  /** Sweden */
  Se = 'SE',
  /** Switzerland */
  Ch = 'CH',
  /** Syria */
  Sy = 'SY',
  /** Taiwan */
  Tw = 'TW',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tanzania */
  Tz = 'TZ',
  /** Thailand */
  Th = 'TH',
  /** Timor-Leste */
  Tl = 'TL',
  /** Togo */
  Tg = 'TG',
  /** Tokelau */
  Tk = 'TK',
  /** Tonga */
  To = 'TO',
  /** Trinidad & Tobago */
  Tt = 'TT',
  /** Tunisia */
  Tn = 'TN',
  /** Turkey */
  Tr = 'TR',
  /** Turkmenistan */
  Tm = 'TM',
  /** Turks & Caicos Islands */
  Tc = 'TC',
  /** Tuvalu */
  Tv = 'TV',
  /** Uganda */
  Ug = 'UG',
  /** Ukraine */
  Ua = 'UA',
  /** United Arab Emirates */
  Ae = 'AE',
  /** United Kingdom */
  Gb = 'GB',
  /** United States */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** U.S. Outlying Islands */
  Um = 'UM',
  /** U.S. Virgin Islands */
  Vi = 'VI',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vanuatu */
  Vu = 'VU',
  /** Vatican City */
  Va = 'VA',
  /** Venezuela */
  Ve = 'VE',
  /** Vietnam */
  Vn = 'VN',
  /** Wallis & Futuna */
  Wf = 'WF',
  /** Western Sahara */
  Eh = 'EH',
  /** Yemen */
  Ye = 'YE',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

/** Contains an array of product IDs to use for creating a compare list. */
export type CreateCompareListInput = {
  /** An array of product IDs to add to the compare list. */
  products?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

/** Assigns a specific `cart_id` to the empty cart. */
export type CreateEmptyCartInput = {
  /** The ID to assign to the cart. */
  cart_id?: Maybe<Scalars['String']>;
};

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export type CreatePayflowProTokenOutput = {
  __typename?: 'CreatePayflowProTokenOutput';
  /** The RESPMSG returned by PayPal. If the `result` is `0`, then `response_message` is `Approved`. */
  response_message: Scalars['String'];
  /** A non-zero value if any errors occurred. */
  result: Scalars['Int'];
  /** The RESULT returned by PayPal. A value of `0` indicates the transaction was approved. */
  result_code: Scalars['Int'];
  /** A secure token generated by PayPal. */
  secure_token: Scalars['String'];
  /** A secure token ID generated by PayPal. */
  secure_token_id: Scalars['String'];
};

/** Defines a new product review. */
export type CreateProductReviewInput = {
  /** The customer's nickname. Defaults to the customer name, if logged in. */
  nickname: Scalars['String'];
  /** The ratings details by category. For example, Price: 5 stars, Quality: 4 stars, etc. */
  ratings: Array<Maybe<ProductReviewRatingInput>>;
  /** The SKU of the reviewed product. */
  sku: Scalars['String'];
  /** The summary (title) of the review. */
  summary: Scalars['String'];
  /** The review text. */
  text: Scalars['String'];
};

/** Contains the completed product review. */
export type CreateProductReviewOutput = {
  __typename?: 'CreateProductReviewOutput';
  /** Product review details. */
  review: ProductReview;
};

/** Required fields for Payflow Pro and Payments Pro credit card payments. */
export type CreditCardDetailsInput = {
  /** The credit card expiration month. */
  cc_exp_month: Scalars['Int'];
  /** The credit card expiration year. */
  cc_exp_year: Scalars['Int'];
  /** The last 4 digits of the credit card. */
  cc_last_4: Scalars['Int'];
  /** The credit card type. */
  cc_type: Scalars['String'];
};

/** Contains credit memo details. */
export type CreditMemo = {
  __typename?: 'CreditMemo';
  /** Comments on the credit memo. */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `CreditMemo` object. */
  id: Scalars['ID'];
  /** An array containing details about refunded items. */
  items?: Maybe<Array<Maybe<CreditMemoItemInterface>>>;
  /** The sequential credit memo number. */
  number: Scalars['String'];
  /** Details about the total refunded amount. */
  total?: Maybe<CreditMemoTotal>;
};

export type CreditMemoItem = CreditMemoItemInterface & {
  __typename?: 'CreditMemoItem';
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of refunded items. */
  quantity_refunded?: Maybe<Scalars['Float']>;
};

/** Credit memo item details. */
export type CreditMemoItemInterface = {
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of refunded items. */
  quantity_refunded?: Maybe<Scalars['Float']>;
};

/** Contains credit memo price details. */
export type CreditMemoTotal = {
  __typename?: 'CreditMemoTotal';
  /** An adjustment manually applied to the order. */
  adjustment: Money;
  /** The final base grand total amount in the base currency. */
  base_grand_total: Money;
  /** The applied discounts to the credit memo. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes. */
  grand_total: Money;
  /** Details about the shipping and handling costs for the credit memo. */
  shipping_handling?: Maybe<ShippingHandling>;
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes. */
  subtotal: Money;
  /** The credit memo tax details. */
  taxes?: Maybe<Array<Maybe<TaxItem>>>;
  /** The shipping amount for the credit memo. */
  total_shipping: Money;
  /** The amount of tax applied to the credit memo. */
  total_tax: Money;
};

export type Currency = {
  __typename?: 'Currency';
  /** An array of three-letter currency codes accepted by the store, such as USD and EUR. */
  available_currency_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The base currency set for the store, such as USD. */
  base_currency_code?: Maybe<Scalars['String']>;
  /** The symbol for the specified base currency, such as $. */
  base_currency_symbol?: Maybe<Scalars['String']>;
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  default_display_currecy_code?: Maybe<Scalars['String']>;
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  default_display_currecy_symbol?: Maybe<Scalars['String']>;
  /** The currency that is displayed by default, such as USD. */
  default_display_currency_code?: Maybe<Scalars['String']>;
  /** The currency symbol that is displayed by default, such as $. */
  default_display_currency_symbol?: Maybe<Scalars['String']>;
  /** An array of exchange rates for currencies defined in the store. */
  exchange_rates?: Maybe<Array<Maybe<ExchangeRate>>>;
};

/** The list of available currency codes. */
export enum CurrencyEnum {
  Afn = 'AFN',
  All = 'ALL',
  Azn = 'AZN',
  Dzd = 'DZD',
  Aoa = 'AOA',
  Ars = 'ARS',
  Amd = 'AMD',
  Awg = 'AWG',
  Aud = 'AUD',
  Bsd = 'BSD',
  Bhd = 'BHD',
  Bdt = 'BDT',
  Bbd = 'BBD',
  Byn = 'BYN',
  Bzd = 'BZD',
  Bmd = 'BMD',
  Btn = 'BTN',
  Bob = 'BOB',
  Bam = 'BAM',
  Bwp = 'BWP',
  Brl = 'BRL',
  Gbp = 'GBP',
  Bnd = 'BND',
  Bgn = 'BGN',
  Buk = 'BUK',
  Bif = 'BIF',
  Khr = 'KHR',
  Cad = 'CAD',
  Cve = 'CVE',
  Czk = 'CZK',
  Kyd = 'KYD',
  Gqe = 'GQE',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Kmf = 'KMF',
  Cdf = 'CDF',
  Crc = 'CRC',
  Hrk = 'HRK',
  Cup = 'CUP',
  Dkk = 'DKK',
  Djf = 'DJF',
  Dop = 'DOP',
  Xcd = 'XCD',
  Egp = 'EGP',
  Svc = 'SVC',
  Ern = 'ERN',
  Eek = 'EEK',
  Etb = 'ETB',
  Eur = 'EUR',
  Fkp = 'FKP',
  Fjd = 'FJD',
  Gmd = 'GMD',
  Gek = 'GEK',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gtq = 'GTQ',
  Gnf = 'GNF',
  Gyd = 'GYD',
  Htg = 'HTG',
  Hnl = 'HNL',
  Hkd = 'HKD',
  Huf = 'HUF',
  Isk = 'ISK',
  Inr = 'INR',
  Idr = 'IDR',
  Irr = 'IRR',
  Iqd = 'IQD',
  Ils = 'ILS',
  Jmd = 'JMD',
  Jpy = 'JPY',
  Jod = 'JOD',
  Kzt = 'KZT',
  Kes = 'KES',
  Kwd = 'KWD',
  Kgs = 'KGS',
  Lak = 'LAK',
  Lvl = 'LVL',
  Lbp = 'LBP',
  Lsl = 'LSL',
  Lrd = 'LRD',
  Lyd = 'LYD',
  Ltl = 'LTL',
  Mop = 'MOP',
  Mkd = 'MKD',
  Mga = 'MGA',
  Mwk = 'MWK',
  Myr = 'MYR',
  Mvr = 'MVR',
  Lsm = 'LSM',
  Mro = 'MRO',
  Mur = 'MUR',
  Mxn = 'MXN',
  Mdl = 'MDL',
  Mnt = 'MNT',
  Mad = 'MAD',
  Mzn = 'MZN',
  Mmk = 'MMK',
  Nad = 'NAD',
  Npr = 'NPR',
  Ang = 'ANG',
  Ytl = 'YTL',
  Nzd = 'NZD',
  Nic = 'NIC',
  Ngn = 'NGN',
  Kpw = 'KPW',
  Nok = 'NOK',
  Omr = 'OMR',
  Pkr = 'PKR',
  Pab = 'PAB',
  Pgk = 'PGK',
  Pyg = 'PYG',
  Pen = 'PEN',
  Php = 'PHP',
  Pln = 'PLN',
  Qar = 'QAR',
  Rhd = 'RHD',
  Ron = 'RON',
  Rub = 'RUB',
  Rwf = 'RWF',
  Shp = 'SHP',
  Std = 'STD',
  Sar = 'SAR',
  Rsd = 'RSD',
  Scr = 'SCR',
  Sll = 'SLL',
  Sgd = 'SGD',
  Skk = 'SKK',
  Sbd = 'SBD',
  Sos = 'SOS',
  Zar = 'ZAR',
  Krw = 'KRW',
  Lkr = 'LKR',
  Sdg = 'SDG',
  Srd = 'SRD',
  Szl = 'SZL',
  Sek = 'SEK',
  Chf = 'CHF',
  Syp = 'SYP',
  Twd = 'TWD',
  Tjs = 'TJS',
  Tzs = 'TZS',
  Thb = 'THB',
  Top = 'TOP',
  Ttd = 'TTD',
  Tnd = 'TND',
  Tmm = 'TMM',
  Usd = 'USD',
  Ugx = 'UGX',
  Uah = 'UAH',
  Aed = 'AED',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vuv = 'VUV',
  Veb = 'VEB',
  Vef = 'VEF',
  Vnd = 'VND',
  Che = 'CHE',
  Chw = 'CHW',
  Xof = 'XOF',
  Wst = 'WST',
  Yer = 'YER',
  Zmk = 'ZMK',
  Zwd = 'ZWD',
  Try = 'TRY',
  Azm = 'AZM',
  Rol = 'ROL',
  Trl = 'TRL',
  Xpf = 'XPF'
}

/** Defines an array of custom attributes. */
export type CustomAttributeMetadata = {
  __typename?: 'CustomAttributeMetadata';
  /** An array of attributes. */
  items?: Maybe<Array<Maybe<Attribute>>>;
};

/** Customer defines the customer name and address and other details */
export type Customer = {
  __typename?: 'Customer';
  /** An array containing the customer's shipping and billing addresses. */
  addresses?: Maybe<Array<Maybe<CustomerAddress>>>;
  /** Indicates whether the customer has enabled remote shopping assistance. */
  allow_remote_shopping_assistance: Scalars['Boolean'];
  /** What is customers business type */
  business_type?: Maybe<Scalars['Int']>;
  /** The contents of the customer's compare list. */
  compare_list?: Maybe<CompareList>;
  /** Timestamp indicating when the account was created. */
  created_at?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['Int']>;
  /** The customer's date of birth. */
  date_of_birth?: Maybe<Scalars['String']>;
  /** The ID assigned to the billing address. */
  default_billing?: Maybe<Scalars['String']>;
  /** The ID assigned to the shipping address. */
  default_shipping?: Maybe<Scalars['String']>;
  /**
   * The customer's date of birth.
   * @deprecated Use `date_of_birth` instead.
   */
  dob?: Maybe<Scalars['String']>;
  /** The customer's email address. Required. */
  email?: Maybe<Scalars['String']>;
  fairfield_account_number?: Maybe<Scalars['String']>;
  /** The customer's first name. */
  firstname?: Maybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: Maybe<Scalars['Int']>;
  /** Add product images to download list */
  getImagesList: Imagelist;
  /** @deprecated Customer group should not be exposed in the storefront scenarios. */
  group_id?: Maybe<Scalars['Int']>;
  /** How did you hear about us? Select options */
  hear_about?: Maybe<Scalars['String']>;
  /**
   * The ID assigned to the customer.
   * @deprecated `id` is not needed as part of `Customer`, because on the server side, it can be identified based on the customer token used for authentication. There is no need to know customer ID on the client side.
   */
  id?: Maybe<Scalars['Int']>;
  /** What is customers industry type */
  industry_type?: Maybe<Scalars['Int']>;
  /** Customer's account is approved or not */
  is_approved?: Maybe<Scalars['Boolean']>;
  /** Is customer account new */
  is_new?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: Maybe<Scalars['Boolean']>;
  /** If its trade program registration, or not */
  is_trade?: Maybe<Scalars['Boolean']>;
  /** The customer's family name. */
  lastname?: Maybe<Scalars['String']>;
  /** The customer's middle name. */
  middlename?: Maybe<Scalars['String']>;
  orders?: Maybe<CustomerOrders>;
  /** Other specify */
  other_specify?: Maybe<Scalars['String']>;
  /** Phone number */
  phone?: Maybe<Scalars['String']>;
  /** If he customer placed order before */
  placed_order?: Maybe<Scalars['Boolean']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** Contains the customer's product reviews. */
  reviews: ProductReviews;
  /** Sign up for updates */
  sign_up_for_updates?: Maybe<Scalars['Boolean']>;
  /** How did you hear about us? Please specify */
  specified?: Maybe<Scalars['String']>;
  /** How many independent retail stores */
  stores_count?: Maybe<Scalars['Int']>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** Tax ID Doc */
  tax_id_doc?: Maybe<Scalars['String']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  taxvat?: Maybe<Scalars['String']>;
  /** Tradeshow specify */
  tradeshow_specify?: Maybe<Scalars['String']>;
  /**
   * Return a customer's wish lists.
   * @deprecated Use `Customer.wishlists` or `Customer.wishlist_v2` instead.
   */
  wishlist: Wishlist;
  /** Get customer wishlist by name */
  wishlistByName: WishlistJola;
  /** Retrieve the wish list identified by the unique ID for a `Wishlist` object. */
  wishlist_v2?: Maybe<Wishlist>;
  /**
   * An array of wishlists. In Magento Open Source, customers are limited to one
   * wish list. The number of wish lists is configurable for Adobe Commerce.
   */
  wishlists: Array<Maybe<Wishlist>>;
};


/** Customer defines the customer name and address and other details */
export type CustomerOrdersArgs = {
  filter?: Maybe<CustomerOrdersFilterInput>;
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sorting_attribute?: Maybe<Scalars['String']>;
  sorting_direction?: Maybe<Scalars['String']>;
};


/** Customer defines the customer name and address and other details */
export type CustomerReviewsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};


/** Customer defines the customer name and address and other details */
export type CustomerWishlistByNameArgs = {
  name?: Maybe<Scalars['String']>;
};


/** Customer defines the customer name and address and other details */
export type CustomerWishlist_V2Args = {
  id: Scalars['ID'];
};


/** Customer defines the customer name and address and other details */
export type CustomerWishlistsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

/** CustomerAddress contains detailed information about a customer's billing and shipping addresses */
export type CustomerAddress = {
  __typename?: 'CustomerAddress';
  /** The address label. */
  address_label?: Maybe<Scalars['String']>;
  /** The customer's city or town. */
  city?: Maybe<Scalars['String']>;
  /** The customer's company. */
  company?: Maybe<Scalars['String']>;
  /** Contact name */
  contact_name?: Maybe<Scalars['String']>;
  /** The customer's country. */
  country_code?: Maybe<CountryCodeEnum>;
  /**
   * The customer's country.
   * @deprecated Use `country_code` instead.
   */
  country_id?: Maybe<Scalars['String']>;
  country_name_billing?: Maybe<Scalars['String']>;
  country_name_shipping?: Maybe<Scalars['String']>;
  /** @deprecated Custom attributes should not be put into a container. */
  custom_attributes?: Maybe<Array<Maybe<CustomerAddressAttribute>>>;
  /**
   * The customer ID
   * @deprecated `customer_id` is not needed as part of `CustomerAddress`. The `id` is a unique identifier for the addresses.
   */
  customer_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the address is the customer's default billing address. */
  default_billing?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the address is the customer's default shipping address. */
  default_shipping?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  /** Contains any extension attributes for the address. */
  extension_attributes?: Maybe<Array<Maybe<CustomerAddressAttribute>>>;
  /** The customer's fax number. */
  fax?: Maybe<Scalars['String']>;
  /** The first name of the person associated with the shipping/billing address. */
  firstname?: Maybe<Scalars['String']>;
  /** The ID of a `CustomerAddress` object. */
  id?: Maybe<Scalars['Int']>;
  /** The family name of the person associated with the shipping/billing address. */
  lastname?: Maybe<Scalars['String']>;
  /** The middle name of the person associated with the shipping/billing address. */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's ZIP or postal code. */
  postcode?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** An object containing the region name, region code, and region ID. */
  region?: Maybe<CustomerAddressRegion>;
  /** The unique ID for a pre-defined region. */
  region_id?: Maybe<Scalars['Int']>;
  /** An array of strings that define the street number and name. */
  street?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's telephone number. */
  telephone?: Maybe<Scalars['String']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  vat_id?: Maybe<Scalars['String']>;
  /** Customer's website address */
  website?: Maybe<Scalars['String']>;
};

/** Specifies the attribute code and value of a customer address attribute. */
export type CustomerAddressAttribute = {
  __typename?: 'CustomerAddressAttribute';
  /** The name assigned to the customer address attribute. */
  attribute_code?: Maybe<Scalars['String']>;
  /** The valuue assigned to the customer address attribute. */
  value?: Maybe<Scalars['String']>;
};

/** Specifies the attribute code and value of a customer attribute. */
export type CustomerAddressAttributeInput = {
  /** The name assigned to the attribute. */
  attribute_code: Scalars['String'];
  /** The value assigned to the attribute. */
  value: Scalars['String'];
};

/** Contains details about a billing or shipping address. */
export type CustomerAddressInput = {
  /** The address label. */
  address_label?: Maybe<Scalars['String']>;
  /** The city or town */
  city?: Maybe<Scalars['String']>;
  /** The customer's company */
  company?: Maybe<Scalars['String']>;
  /** Contact name */
  contact_name?: Maybe<Scalars['String']>;
  /** The two-letter code representing the customer's country. */
  country_code?: Maybe<CountryCodeEnum>;
  /** The customer's country */
  country_id?: Maybe<CountryCodeEnum>;
  /** Deprecated: Custom attributes should not be put into container. */
  custom_attributes?: Maybe<Array<Maybe<CustomerAddressAttributeInput>>>;
  /** Indicates whether the address is the default billing address */
  default_billing?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the address is the default shipping address */
  default_shipping?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  /** The fax number */
  fax?: Maybe<Scalars['String']>;
  /** The first name of the person associated with the shipping/billing address */
  firstname?: Maybe<Scalars['String']>;
  /** The family name of the person associated with the shipping/billing address */
  lastname?: Maybe<Scalars['String']>;
  /** The middle name of the person associated with the shipping/billing address */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's ZIP or postal code */
  postcode?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** An object containing the region name, region code, and region ID */
  region?: Maybe<CustomerAddressRegionInput>;
  /** An array of strings that define the street number and name */
  street?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']>;
  /** The telephone number */
  telephone?: Maybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers) */
  vat_id?: Maybe<Scalars['String']>;
  /** Customer's website address */
  website?: Maybe<Scalars['String']>;
};

/** Defines the customer's state or province. */
export type CustomerAddressRegion = {
  __typename?: 'CustomerAddressRegion';
  /** The state or province name. */
  region?: Maybe<Scalars['String']>;
  /** The address region code. */
  region_code?: Maybe<Scalars['String']>;
  /** The unique ID for a pre-defined region. */
  region_id?: Maybe<Scalars['Int']>;
};

/** Defines the customer's state or province. */
export type CustomerAddressRegionInput = {
  /** The state or province name. */
  region?: Maybe<Scalars['String']>;
  /** The address region code. */
  region_code?: Maybe<Scalars['String']>;
  /** The unique ID for a pre-defined region. */
  region_id?: Maybe<Scalars['Int']>;
};

/** Identifies the filter to use for filtering claims. */
export type CustomerClaimsFilterInput = {
  /** Filters acknowledgement number. */
  acknowledgement_number?: Maybe<FilterStringTypeInput>;
  /** Filters by claim id. */
  claim_id?: Maybe<FilterStringTypeInput>;
  /** Filters by claim date. */
  created_at?: Maybe<FilterRangeTypeInput>;
  /** Filters by style number to name. */
  style_number?: Maybe<FilterStringTypeInput>;
};

/** An input object for creating a customer. */
export type CustomerCreateInput = {
  /** Indicates whether the customer has enabled remote shopping assistance. */
  allow_remote_shopping_assistance?: Maybe<Scalars['Boolean']>;
  /** The customer's date of birth. */
  date_of_birth?: Maybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: Maybe<Scalars['String']>;
  /** The customer's email address. */
  email: Scalars['String'];
  /** The customer's first name. */
  firstname: Scalars['String'];
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: Maybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: Maybe<Scalars['Boolean']>;
  /** The customer's family name. */
  lastname: Scalars['String'];
  /** The customer's middle name. */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's password. */
  password?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: Maybe<Scalars['String']>;
};

/** Contains details about a single downloadable product. */
export type CustomerDownloadableProduct = {
  __typename?: 'CustomerDownloadableProduct';
  /** The date and time the purchase was made. */
  date?: Maybe<Scalars['String']>;
  /** The fully qualified URL to the download file. */
  download_url?: Maybe<Scalars['String']>;
  /** The unique ID assigned to the item. */
  order_increment_id?: Maybe<Scalars['String']>;
  /** The remaining number of times the customer can download the product. */
  remaining_downloads?: Maybe<Scalars['String']>;
  /** Indicates when the product becomes available for download. Options are `Pending` and `Invoiced`. */
  status?: Maybe<Scalars['String']>;
};

/** Contains a list of downloadable products. */
export type CustomerDownloadableProducts = {
  __typename?: 'CustomerDownloadableProducts';
  /** An array of purchased downloadable items. */
  items?: Maybe<Array<Maybe<CustomerDownloadableProduct>>>;
};

export type CustomerFileInput = {
  tax_id_doc?: Maybe<WMediaGalleryEntriesContent>;
};

export type CustomerFileOutput = {
  __typename?: 'CustomerFileOutput';
  tax_id_doc?: Maybe<Scalars['String']>;
};

export type CustomerFirstnameByEmailOutput = {
  __typename?: 'CustomerFirstnameByEmailOutput';
  firstname?: Maybe<Scalars['String']>;
};

/** An input object that assigns or updates customer attributes. */
export type CustomerInput = {
  billing_address?: Maybe<CustomerAddressInput>;
  /** What is customers business type */
  business_type?: Maybe<Scalars['Int']>;
  /** The customer's date of birth. */
  date_of_birth?: Maybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: Maybe<Scalars['String']>;
  /** The customer's email address. Required when creating a customer. */
  email?: Maybe<Scalars['String']>;
  fairfield_account_number?: Maybe<Scalars['String']>;
  /** The customer's first name. */
  firstname?: Maybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: Maybe<Scalars['Int']>;
  /** How did you hear about us? Select options */
  hear_about?: Maybe<Scalars['String']>;
  /** What is customers industry type */
  industry_type?: Maybe<Scalars['Int']>;
  /** Is customer account new */
  is_new?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: Maybe<Scalars['Boolean']>;
  /** If its trade program registration, or not */
  is_trade?: Maybe<Scalars['Boolean']>;
  /** The customer's family name. */
  lastname?: Maybe<Scalars['String']>;
  /** The customer's middle name. */
  middlename?: Maybe<Scalars['String']>;
  /** Other specify */
  other_specify?: Maybe<Scalars['String']>;
  /** The customer's password. */
  password?: Maybe<Scalars['String']>;
  /** Phone number */
  phone?: Maybe<Scalars['String']>;
  /** If he customer placed order before */
  placed_order?: Maybe<Scalars['Boolean']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  shipping_address?: Maybe<CustomerAddressInput>;
  /** Sign up for updates */
  sign_up_for_updates?: Maybe<Scalars['Boolean']>;
  /** How did you hear about us? Please specify */
  specified?: Maybe<Scalars['String']>;
  /** How many independent retail stores */
  stores_count?: Maybe<Scalars['Int']>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** Tax ID Doc */
  tax_id_doc?: Maybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: Maybe<Scalars['String']>;
  /** Tradeshow specify */
  tradeshow_specify?: Maybe<Scalars['String']>;
};

/** Contains details about each of the customer's orders. */
export type CustomerOrder = {
  __typename?: 'CustomerOrder';
  /** The billing address for the order. */
  billing_address?: Maybe<OrderAddress>;
  /** The shipping carrier for the order delivery. */
  carrier?: Maybe<Scalars['String']>;
  /** Comments about the order. */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** @deprecated Use the `order_date` field instead. */
  created_at?: Maybe<Scalars['String']>;
  /** A list of credit memos. */
  credit_memos?: Maybe<Array<Maybe<CreditMemo>>>;
  /** The entered gift message for the order */
  gift_message?: Maybe<GiftMessage>;
  /** @deprecated Use the `totals.grand_total` field instead. */
  grand_total?: Maybe<Scalars['Float']>;
  /** The unique ID for a `CustomerOrder` object. */
  id: Scalars['ID'];
  /** @deprecated Use the `id` field instead. */
  increment_id?: Maybe<Scalars['String']>;
  /** A list of invoices for the order. */
  invoices: Array<Maybe<Invoice>>;
  /** An array containing the items purchased in this order. */
  items?: Maybe<Array<Maybe<OrderItemInterface>>>;
  /** The order number. */
  number: Scalars['String'];
  /** The date the order was placed. */
  order_date: Scalars['String'];
  /** @deprecated Use the `number` field instead. */
  order_number: Scalars['String'];
  /** Payment details for the order. */
  payment_methods?: Maybe<Array<Maybe<OrderPaymentMethod>>>;
  /** Project details for customer orders in account dashboard. */
  project_details?: Maybe<ProjectDetails>;
  /** A list of shipments for the order. */
  shipments?: Maybe<Array<Maybe<OrderShipment>>>;
  /** The shipping address for the order. */
  shipping_address?: Maybe<OrderAddress>;
  /** The delivery method for the order. */
  shipping_method?: Maybe<Scalars['String']>;
  /** The current status of the order. */
  status: Scalars['String'];
  /** Details about the calculated totals for this order. */
  total?: Maybe<OrderTotal>;
};

/** The collection of orders that match the conditions defined in the filter. */
export type CustomerOrders = {
  __typename?: 'CustomerOrders';
  /** An array of customer orders. */
  items: Array<Maybe<CustomerOrder>>;
  /** Contains pagination metadata. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The total count of customer orders. */
  total_count?: Maybe<Scalars['Int']>;
};

/** Identifies the filter to use for filtering orders. */
export type CustomerOrdersFilterInput = {
  /** Filters by order date. */
  created_at?: Maybe<FilterRangeTypeInput>;
  /** Filters by ship to name. */
  name?: Maybe<FilterStringTypeInput>;
  /** Filters by order number. */
  number?: Maybe<FilterStringTypeInput>;
  /** Filters by order status. */
  status?: Maybe<FilterStringTypeInput>;
};

/** Contains details about a newly-created or updated customer. */
export type CustomerOutput = {
  __typename?: 'CustomerOutput';
  /** Customer details after creating or updating a customer. */
  customer: Customer;
};

/** Contains payment tokens stored in the customer's vault. */
export type CustomerPaymentTokens = {
  __typename?: 'CustomerPaymentTokens';
  /** An array of payment tokens. */
  items: Array<Maybe<PaymentToken>>;
};

/** Contains a customer authorization token. */
export type CustomerToken = {
  __typename?: 'CustomerToken';
  /** The customer authorization token. */
  token?: Maybe<Scalars['String']>;
};

/** An input object for updating a customer. */
export type CustomerUpdateInput = {
  /** Indicates whether the customer has enabled remote shopping assistance. */
  allow_remote_shopping_assistance?: Maybe<Scalars['Boolean']>;
  /** The customer's date of birth. */
  date_of_birth?: Maybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: Maybe<Scalars['String']>;
  /** The customer's first name. */
  firstname?: Maybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: Maybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: Maybe<Scalars['Boolean']>;
  /** The customer's family name. */
  lastname?: Maybe<Scalars['String']>;
  /** The customer's middle name. */
  middlename?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: Maybe<Scalars['String']>;
};

/** Contains information about a text area that is defined as part of a customizable option. */
export type CustomizableAreaOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableAreaOption';
  category_options?: Maybe<Scalars['String']>;
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a text area. */
  value?: Maybe<CustomizableAreaValue>;
};

/** Defines the price and sku of a product whose page contains a customized text area. */
export type CustomizableAreaValue = {
  __typename?: 'CustomizableAreaValue';
  /** The maximum number of characters that can be entered for this customizable option. */
  max_characters?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableAreaValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a set of checkbox values that are defined as part of a customizable option. */
export type CustomizableCheckboxOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableCheckboxOption';
  category_options?: Maybe<Scalars['String']>;
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines a set of checkbox values. */
  value?: Maybe<Array<Maybe<CustomizableCheckboxValue>>>;
};

/** Defines the price and sku of a product whose page contains a customized set of checkbox values. */
export type CustomizableCheckboxValue = {
  __typename?: 'CustomizableCheckboxValue';
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the checkbox value is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableCheckboxValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a date picker that is defined as part of a customizable option. */
export type CustomizableDateOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableDateOption';
  category_options?: Maybe<Scalars['String']>;
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a date field in a customizable option. */
  value?: Maybe<CustomizableDateValue>;
};

/** Defines the customizable date type. */
export enum CustomizableDateTypeEnum {
  Date = 'DATE',
  DateTime = 'DATE_TIME',
  Time = 'TIME'
}

/** Defines the price and sku of a product whose page contains a customized date picker. */
export type CustomizableDateValue = {
  __typename?: 'CustomizableDateValue';
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** DATE, DATE_TIME or TIME */
  type?: Maybe<CustomizableDateTypeEnum>;
  /** The unique ID for a `CustomizableDateValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a drop down menu that is defined as part of a customizable option. */
export type CustomizableDropDownOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableDropDownOption';
  category_options?: Maybe<Scalars['String']>;
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines the set of options for a drop down menu. */
  value?: Maybe<Array<Maybe<CustomizableDropDownValue>>>;
};

/** Defines the price and sku of a product whose page contains a customized drop down menu. */
export type CustomizableDropDownValue = {
  __typename?: 'CustomizableDropDownValue';
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableDropDownValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a text field that is defined as part of a customizable option. */
export type CustomizableFieldOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableFieldOption';
  category_options?: Maybe<Scalars['String']>;
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a text field. */
  value?: Maybe<CustomizableFieldValue>;
};

/** Defines the price and sku of a product whose page contains a customized text field. */
export type CustomizableFieldValue = {
  __typename?: 'CustomizableFieldValue';
  /** The maximum number of characters that can be entered for this customizable option. */
  max_characters?: Maybe<Scalars['Int']>;
  /** The price of the custom value. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableFieldValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a file picker that is defined as part of a customizable option. */
export type CustomizableFileOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableFileOption';
  category_options?: Maybe<Scalars['String']>;
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a file value. */
  value?: Maybe<CustomizableFileValue>;
};

/** Defines the price and sku of a product whose page contains a customized file picker. */
export type CustomizableFileValue = {
  __typename?: 'CustomizableFileValue';
  /** The file extension to accept. */
  file_extension?: Maybe<Scalars['String']>;
  /** The maximum width of an image. */
  image_size_x?: Maybe<Scalars['Int']>;
  /** The maximum height of an image. */
  image_size_y?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableFileValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a multiselect that is defined as part of a customizable option. */
export type CustomizableMultipleOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableMultipleOption';
  category_options?: Maybe<Scalars['String']>;
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines the set of options for a multiselect. */
  value?: Maybe<Array<Maybe<CustomizableMultipleValue>>>;
};

/** Defines the price and sku of a product whose page contains a customized multiselect. */
export type CustomizableMultipleValue = {
  __typename?: 'CustomizableMultipleValue';
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableMultipleValue` object. */
  uid: Scalars['ID'];
};

/** Defines a customizable option. */
export type CustomizableOptionInput = {
  /** The customizable option ID of the product. */
  id?: Maybe<Scalars['Int']>;
  /** The string value of the option. */
  value_string: Scalars['String'];
};

/** Contains basic information about a customizable option. It can be implemented by several types of configurable options. */
export type CustomizableOptionInterface = {
  category_options?: Maybe<Scalars['String']>;
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
};

/** Contains information about customizable product options. */
export type CustomizableProductInterface = {
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
};

/** Contains information about a set of radio buttons that are defined as part of a customizable option. */
export type CustomizableRadioOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableRadioOption';
  category_options?: Maybe<Scalars['String']>;
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines a set of radio buttons. */
  value?: Maybe<Array<Maybe<CustomizableRadioValue>>>;
};

/** Defines the price and sku of a product whose page contains a customized set of radio buttons. */
export type CustomizableRadioValue = {
  __typename?: 'CustomizableRadioValue';
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the radio button is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableRadioValue` object. */
  uid: Scalars['ID'];
};

export type CustomStatuses = {
  __typename?: 'CustomStatuses';
  status_code?: Maybe<Scalars['String']>;
  status_label?: Maybe<Scalars['String']>;
};

/** Contains the results of the request to delete a compare list. */
export type DeleteCompareListOutput = {
  __typename?: 'DeleteCompareListOutput';
  /** Indicates whether the compare list was successfully deleted. */
  result: Scalars['Boolean'];
};

export type DeleteItemFromWishlistInput = {
  skus: Array<Maybe<Scalars['String']>>;
  wishlist?: Maybe<Scalars['String']>;
};

/** Indicates whether the request succeeded and returns the remaining customer payment tokens. */
export type DeletePaymentTokenOutput = {
  __typename?: 'DeletePaymentTokenOutput';
  /** A container for the customer's remaining payment tokens. */
  customerPaymentTokens?: Maybe<CustomerPaymentTokens>;
  /** Indicates whether the request succeeded. */
  result: Scalars['Boolean'];
};

export type DeleteSavedBuildFromWishlistInput = {
  item_ids: Array<Maybe<Scalars['Int']>>;
  wishlist?: Maybe<Scalars['String']>;
};

export type Designer = {
  __typename?: 'Designer';
  biography?: Maybe<Scalars['String']>;
  cms_blocks?: Maybe<Array<Maybe<CmsBlockObject>>>;
  id?: Maybe<Scalars['Int']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<ProductInterface>>>;
};

/** Defines an individual discount. A discount can be applied to the cart as a whole or to an item. */
export type Discount = {
  __typename?: 'Discount';
  /** The amount of the discount. */
  amount: Money;
  /** A description of the discount. */
  label: Scalars['String'];
};

export type DivisionData = {
  __typename?: 'DivisionData';
  /** Value 0 or 1 depending on whether the division is selected for the file. */
  contract?: Maybe<Scalars['Int']>;
  /** Value 0 or 1 depending on whether the division is selected for the file. */
  retail?: Maybe<Scalars['Int']>;
  /** Value 0 or 1 depending on whether the division is selected for the file. */
  senior?: Maybe<Scalars['Int']>;
};

export type DocumentNames = {
  __typename?: 'DocumentNames';
  file_name?: Maybe<Scalars['String']>;
  uploaded_file_name?: Maybe<Scalars['String']>;
};

export type DocumentNamesData = {
  __typename?: 'DocumentNamesData';
  file_name?: Maybe<Scalars['String']>;
  uploaded_file_name?: Maybe<Scalars['String']>;
};

export type DocumentNamesDataInput = {
  file_name?: Maybe<Scalars['String']>;
  uploaded_file_name?: Maybe<Scalars['String']>;
};

export type Dot = {
  __typename?: 'Dot';
  addInfo?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  left?: Maybe<Scalars['Int']>;
  sku?: Maybe<DotProductInfo>;
  top?: Maybe<Scalars['Int']>;
};

export type DotProductInfo = {
  __typename?: 'DotProductInfo';
  /** Query by description */
  description?: Maybe<Scalars['String']>;
  /** Query by dimensions */
  dimensions?: Maybe<Scalars['String']>;
  /** Query by id */
  id?: Maybe<Scalars['String']>;
  /** Query by image URL */
  image?: Maybe<Scalars['String']>;
  /** Query by link to PDP */
  linkToPDP?: Maybe<Scalars['String']>;
  /** Query by name */
  name?: Maybe<Scalars['String']>;
  /** Query by price */
  price?: Maybe<Scalars['String']>;
  /** Query by sku */
  sku?: Maybe<Scalars['String']>;
};

/** An implementation for downloadable product cart items. */
export type DownloadableCartItem = CartItemInterface & {
  __typename?: 'DownloadableCartItem';
  /** cart item comment */
  comment?: Maybe<Scalars['String']>;
  /** An array containing the customizable options the shopper selected. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** cart item document_names */
  document_names?: Maybe<Array<Maybe<DocumentNamesData>>>;
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<CartItemError>>>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** An array containing information about the links for the downloadable product added to the cart. */
  links?: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** An array containing information about samples of the selected downloadable product. */
  samples?: Maybe<Array<Maybe<DownloadableProductSamples>>>;
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Defines downloadable product options for `CreditMemoItemInterface`. */
export type DownloadableCreditMemoItem = CreditMemoItemInterface & {
  __typename?: 'DownloadableCreditMemoItem';
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** A list of downloadable links that are refunded from the downloadable product. */
  downloadable_links?: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of refunded items. */
  quantity_refunded?: Maybe<Scalars['Float']>;
};

export enum DownloadableFileTypeEnum {
  File = 'FILE',
  Url = 'URL'
}

/** Defines downloadable product options for `InvoiceItemInterface`. */
export type DownloadableInvoiceItem = InvoiceItemInterface & {
  __typename?: 'DownloadableInvoiceItem';
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** A list of downloadable links that are invoiced from the downloadable product. */
  downloadable_links?: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of invoiced items. */
  quantity_invoiced?: Maybe<Scalars['Float']>;
};

/** Defines characteristics of the links for downloadable product. */
export type DownloadableItemsLinks = {
  __typename?: 'DownloadableItemsLinks';
  /** A number indicating the sort order. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name of the link. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `DownloadableItemsLinks` object. */
  uid: Scalars['ID'];
};

/** Defines downloadable product options for `OrderItemInterface`. */
export type DownloadableOrderItem = OrderItemInterface & {
  __typename?: 'DownloadableOrderItem';
  /** order item comment */
  comment?: Maybe<Scalars['String']>;
  /** The final discount information for the product. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** order item document_names */
  document_names?: Maybe<Array<Maybe<DocumentNames>>>;
  /** A list of downloadable links that are ordered from the downloadable product. */
  downloadable_links?: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** The entered option for the base product, such as a logo or image. */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  item_total: Money;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']>;
  /** URL key of the base product. */
  product_url_key?: Maybe<Scalars['String']>;
  /** The number of canceled items. */
  quantity_canceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items. */
  quantity_invoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item. */
  quantity_ordered?: Maybe<Scalars['Float']>;
  /** The number of refunded items. */
  quantity_refunded?: Maybe<Scalars['Float']>;
  /** The number of returned items. */
  quantity_returned?: Maybe<Scalars['Float']>;
  /** The number of shipped items. */
  quantity_shipped?: Maybe<Scalars['Float']>;
  /** The selected options for the base product, such as color or size. */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item. */
  status?: Maybe<Scalars['String']>;
};

/** Defines a product that the shopper downloads. */
export type DownloadableProduct = ProductInterface & RoutableInterface & CustomizableProductInterface & {
  __typename?: 'DownloadableProduct';
  /**
   * Tearsheet PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  assembly_instructions?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  attribute_set_name?: Maybe<Scalars['String']>;
  /**
   * Availability date for the product.
   * @deprecated Use the `custom_attributes` field instead.
   */
  availability_date?: Maybe<Scalars['String']>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Products' is enabled.
   */
  canonical_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  catalog_page?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  /**
   * Order Form PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  collection_overview_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_caster_options?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_wheels_option?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_wood_finish?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  /** An array containing information about the links for this downloadable product. */
  downloadable_product_links?: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  /** An array containing information about samples of this downloadable product. */
  downloadable_product_samples?: Maybe<Array<Maybe<DownloadableProductSamples>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_apronh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_armh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_assem_req?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_avails?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_backconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_basefab?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_baselth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_bn_appl?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_casegd?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_casters?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cfgmodel?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_checkbox_test_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_collection?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_countryorg?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cubes?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cupholder?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_depth_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_designer?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_dftfabric?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_dftfinish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_duedate?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_extdesc2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_finish_req?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_finishopt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdepthgh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdeptlow?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimd?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimw?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_fulllftwal?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_fullyuph?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_height_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidebkh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestd?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestd_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw_test?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_introdat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_isvisible?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_lanect?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_laners?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_lanesl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leatheravl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_madeto?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_moistbarr?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_nail_opts?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhigharh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhighovh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhighsth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowarh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowovh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowsth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_onords?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_product_type_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_qty_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_reclindpt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_reclwallcl?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_riser?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seatconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seath?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seath_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_sprgconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_status?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdback?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdcushion?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdfinish?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdpllw1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tablet?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tariff?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tvrecline?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_uph_opts?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_wgtcar?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_wgtwrp?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_width_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_woodspec1?: Maybe<Scalars['Int']>;
  ffi_woodspec1_label?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_finish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_type_filterable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_abrasion?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_avail?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_avail_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_categories?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_category1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_collection?: Maybe<Scalars['String']>;
  ffifabrics_collection_label?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_colorways?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_country?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_cutyard?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_dec_cord?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_descr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_descript?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont9?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct9?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabric?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_finishtype?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fringe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fullcont?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_grade?: Maybe<Array<Maybe<Scalars['String']>>>;
  ffifabrics_grade_configurator_filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_hexclrgrp?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_hrepeat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_leather?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_pattern?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_std_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_tape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vendor?: Maybe<Array<Maybe<Scalars['String']>>>;
  ffifabrics_vendor_image?: Maybe<SwatchValues>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vinyl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vrepeat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_about?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_chm_distr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_cleaning?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_fairshield?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_nailhead?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_phy_distr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_sheen?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  file_upload?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  filterable_attribute_set_name?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  hotspots?: Maybe<Array<Maybe<ProductHotspotData>>>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** Add notification that loged user have added product in downloads in specific store */
  in_downloads?: Maybe<Scalars['String']>;
  /** Image is a part of in_real_life role. */
  in_real_life?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  in_stock_filter?: Maybe<Scalars['Int']>;
  industry_approval_attribute_option?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  introdat_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_featured?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_new?: Maybe<Scalars['Int']>;
  /** Adding NULL for this field because of React core. */
  lead_time?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_image role. */
  lifestyle_image?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_image2 role. */
  lifestyle_image2?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_video role. */
  lifestyle_video?: Maybe<Scalars['String']>;
  /** A value of 1 indicates that each link in the array must be purchased separately. */
  links_purchased_separately?: Maybe<Scalars['Int']>;
  /** The heading above the list of downloadable products. */
  links_title?: Maybe<Scalars['String']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer?: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  new_from_date?: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  new_to_date?: Maybe<Scalars['String']>;
  /** Number of throw/kidney pillows */
  num_of_pillows?: Maybe<Scalars['Int']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** The range of prices for the product */
  price_range: PriceRange;
  /** An array of `TierPrice` objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_features?: Maybe<Scalars['String']>;
  product_features_label?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of `ProductLinks` objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** @deprecated Use the `custom_attributes` field instead. */
  ready_to_ship_filter?: Maybe<Scalars['Int']>;
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirect_code: Scalars['Int'];
  /** An array of products to be displayed in a Related Products block. */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /**
   * Packing Fee List PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  revit_file?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shop_by_position?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * Thread Board PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  spec_sheet?: Maybe<Scalars['String']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /**
   * The uploaded video.
   * @deprecated Use the `custom_attributes` field instead.
   */
  video_upload?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
};


/** Defines a product that the shopper downloads. */
export type DownloadableProductReviewsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

/** Defines a single downloadable product. */
export type DownloadableProductCartItemInput = {
  /** The ID and value of the option. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** The quantity and SKU of the downloadable product. */
  data: CartItemInput;
  /** An array of objects containing the link_id of the downloadable product link. */
  downloadable_product_links?: Maybe<Array<Maybe<DownloadableProductLinksInput>>>;
};

/** Defines characteristics of a downloadable product. */
export type DownloadableProductLinks = {
  __typename?: 'DownloadableProductLinks';
  /** @deprecated This information should not be exposed on frontend. */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated This information should not be exposed on frontend. */
  is_shareable?: Maybe<Scalars['Boolean']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  link_type?: Maybe<DownloadableFileTypeEnum>;
  /** @deprecated This information should not be exposed on frontend. */
  number_of_downloads?: Maybe<Scalars['Int']>;
  /** The price of the downloadable product. */
  price?: Maybe<Scalars['Float']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_file?: Maybe<Scalars['String']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_type?: Maybe<DownloadableFileTypeEnum>;
  /** The full URL to the downloadable sample. */
  sample_url?: Maybe<Scalars['String']>;
  /** A number indicating the sort order. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name of the link. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `DownloadableProductLinks` object. */
  uid: Scalars['ID'];
};

/** Contains the link ID for the downloadable product. */
export type DownloadableProductLinksInput = {
  /** The unique ID of the downloadable product link. */
  link_id: Scalars['Int'];
};

/** Defines characteristics of a downloadable product. */
export type DownloadableProductSamples = {
  __typename?: 'DownloadableProductSamples';
  /** @deprecated This information should not be exposed on frontend. */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_file?: Maybe<Scalars['String']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_type?: Maybe<DownloadableFileTypeEnum>;
  /** The full URL to the downloadable sample. */
  sample_url?: Maybe<Scalars['String']>;
  /** A number indicating the sort order. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name of the sample. */
  title?: Maybe<Scalars['String']>;
};

/** A downloadable product wish list item. */
export type DownloadableWishlistItem = WishlistItemInterface & {
  __typename?: 'DownloadableWishlistItem';
  /** The date and time the item was added to the wish list. */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** An array containing information about the selected links. */
  links_v2?: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  /** Product details of the wish list item. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
  /** An array containing information about the selected samples. */
  samples?: Maybe<Array<Maybe<DownloadableProductSamples>>>;
};

/** Defines a customer-entered option. */
export type EnteredOptionInput = {
  /**
   * The unique ID for a `CustomizableOptionInterface` object, such as a
   * `CustomizableFieldOption`, `CustomizableFileOption`, or
   * `CustomizableAreaOption` object.
   */
  uid: Scalars['ID'];
  /** Text the customer entered. */
  value: Scalars['String'];
};

/** EntityUrl is an output object containing the `id`, `relative_url`, and `type` attributes */
export type EntityUrl = {
  __typename?: 'EntityUrl';
  attribute_set_id?: Maybe<Scalars['Int']>;
  attribute_set_name?: Maybe<Scalars['String']>;
  /** @deprecated Use `relative_url` instead. */
  canonical_url?: Maybe<Scalars['String']>;
  /**
   * The unique ID for a `ProductInterface`, `CategoryInterface`, `CmsPage`, or
   * similar object associated with the specified URL. This could be a product,
   * category, or CMS page UID.
   */
  entity_uid?: Maybe<Scalars['ID']>;
  /**
   * The ID assigned to the object associated with the specified url. This could be a product ID, category ID, or page ID.
   * @deprecated Use `entity_uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirectCode?: Maybe<Scalars['Int']>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** Returns sku if type is PRODUCT */
  sku?: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
};

export type ErrorInterface = {
  /** The returned error message. */
  message: Scalars['String'];
};

/** Lists the exchange rate. */
export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  /** Specifies the store’s default currency to exchange to. */
  currency_to?: Maybe<Scalars['String']>;
  /** The exchange rate for the store’s default currency. */
  rate?: Maybe<Scalars['Float']>;
};

export type FaqCategory = {
  __typename?: 'FAQCategory';
  /** Query by background image. */
  background_image?: Maybe<Scalars['String']>;
  /** Query by entity_id. */
  entity_id?: Maybe<Scalars['String']>;
  /** Query by  content. */
  items?: Maybe<Array<Maybe<FaqItem>>>;
  /** Query by  title. */
  title?: Maybe<Scalars['String']>;
};

export type FaqItem = {
  __typename?: 'FAQItem';
  /** FAQ category */
  category_id?: Maybe<Scalars['String']>;
  /** FAQ item answer */
  content?: Maybe<Scalars['String']>;
  /** FAQ item entity_id */
  entity_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type FileData = {
  __typename?: 'FileData';
  /** Selected divisions for the file */
  divisions?: Maybe<DivisionData>;
  /** Url of the file */
  file?: Maybe<Scalars['String']>;
  /** ID of the file */
  file_id?: Maybe<Scalars['String']>;
  /** Selected type for the file */
  file_type?: Maybe<FileType>;
  /** Name of the file */
  filename?: Maybe<Scalars['String']>;
  /** Size of the file */
  size?: Maybe<Scalars['String']>;
  /** Selected tags for the file */
  tags?: Maybe<Array<Maybe<TagData>>>;
  /** File Thumbnail */
  thumbnail?: Maybe<Scalars['String']>;
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Last update date of the file */
  updated_at?: Maybe<Scalars['String']>;
  /** Youtube URL */
  youtube_url?: Maybe<Scalars['String']>;
};

export type FileType = {
  __typename?: 'FileType';
  /** Value 0 or 1 depending on whether the documents is selected for the file. */
  documents?: Maybe<Scalars['Int']>;
  /** Value 0 or 1 depending on whether the marketing is selected for the file. */
  marketing?: Maybe<Scalars['Int']>;
  /** Value 0 or 1 depending on whether the videos is selected for the file. */
  videos?: Maybe<Scalars['Int']>;
};

export type FilterableAttribteSetOptions = {
  __typename?: 'FilterableAttribteSetOptions';
  option_id?: Maybe<Scalars['Int']>;
  option_label?: Maybe<Scalars['String']>;
};

/** Defines a filter that matches the input exactly. */
export type FilterEqualTypeInput = {
  /**
   * Use this attribute to exactly match the specified string. For example, to
   * filter on a specific category ID, specify a value such as `5`.
   */
  eq?: Maybe<Scalars['String']>;
  /**
   * Use this attribute to filter on an array of values. For example, to filter on
   * category IDs 4, 5, and 6, specify a value of `["4", "5", "6"]`.
   */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Defines a filter that performs a fuzzy search. */
export type FilterMatchTypeInput = {
  /**
   * Use this attribute to exactly match the specified string. For example, to
   * filter on a specific SKU, specify a value such as `24-MB01`.
   */
  match?: Maybe<Scalars['String']>;
};

/** Defines a filter that matches a range of values, such as prices or dates. */
export type FilterRangeTypeInput = {
  /** Use this attribute to specify the lowest possible value in the range. */
  from?: Maybe<Scalars['String']>;
  /** Use this attribute to specify the highest possible value in the range. */
  to?: Maybe<Scalars['String']>;
};

/** Defines a filter for an input string. */
export type FilterStringTypeInput = {
  /** Filters items that are exactly the same as the specified string. */
  eq?: Maybe<Scalars['String']>;
  /** Filters items that are exactly the same as entries specified in an array of strings. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Defines a filter that performs a fuzzy search using the specified string. */
  match?: Maybe<Scalars['String']>;
};

/** Defines the comparison operators that can be used in a filter. */
export type FilterTypeInput = {
  /** Equals. */
  eq?: Maybe<Scalars['String']>;
  finset?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** From. Must be used with the `to` field. */
  from?: Maybe<Scalars['String']>;
  /** Greater than. */
  gt?: Maybe<Scalars['String']>;
  /** Greater than or equal to. */
  gteq?: Maybe<Scalars['String']>;
  /** In. The value can contain a set of comma-separated values. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Like. The specified value can contain % (percent signs) to allow matching of 0 or more characters. */
  like?: Maybe<Scalars['String']>;
  /** Less than. */
  lt?: Maybe<Scalars['String']>;
  /** Less than or equal to. */
  lteq?: Maybe<Scalars['String']>;
  /** More than or equal to. */
  moreq?: Maybe<Scalars['String']>;
  /** Not equal to. */
  neq?: Maybe<Scalars['String']>;
  /** Not in. The value can contain a set of comma-separated values. */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not null. */
  notnull?: Maybe<Scalars['String']>;
  /** Is null. */
  null?: Maybe<Scalars['String']>;
  /** To. Must be used with the `from` field. */
  to?: Maybe<Scalars['String']>;
};

/** A single FPT that can be applied to a product price. */
export type FixedProductTax = {
  __typename?: 'FixedProductTax';
  /** The amount of the Fixed Product Tax. */
  amount?: Maybe<Money>;
  /** The display label assigned to the Fixed Product Tax. */
  label?: Maybe<Scalars['String']>;
};

/** Lists display settings for the Fixed Product Tax. */
export enum FixedProductTaxDisplaySettings {
  /**
   * The displayed price includes the FPT amount without displaying the
   * `ProductPrice.fixed_product_taxes` values. This value corresponds to
   * 'Including FPT only'.
   */
  IncludeFptWithoutDetails = 'INCLUDE_FPT_WITHOUT_DETAILS',
  /**
   * The displayed price includes the FPT amount while displaying the values of
   * `ProductPrice.fixed_product_taxes` separately. This value corresponds to
   * 'Including FPT and FPT description'.
   */
  IncludeFptWithDetails = 'INCLUDE_FPT_WITH_DETAILS',
  /**
   * The displayed price does not include the FPT amount. The values of
   * `ProductPrice.fixed_product_taxes` and the price including the FPT are
   * displayed separately. This value corresponds to 'Excluding FPT, Including FPT
   * description and final price.'
   */
  ExcludeFptAndIncludeWithDetails = 'EXCLUDE_FPT_AND_INCLUDE_WITH_DETAILS',
  /**
   * The displayed price does not include the FPT amount. The values from
   * `ProductPrice.fixed_product_taxes` are not displayed. This value corresponds
   * to 'Excluding FPT'.
   */
  ExcludeFptWithoutDetails = 'EXCLUDE_FPT_WITHOUT_DETAILS',
  /** The FPT feature is not enabled. You can omit `ProductPrice.fixed_product_taxes` from your query. */
  FptDisabled = 'FPT_DISABLED'
}

export type Frame = {
  __typename?: 'Frame';
  frame_id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GalleryCategoryData = {
  __typename?: 'GalleryCategoryData';
  /** Query by description. */
  description?: Maybe<Scalars['String']>;
  galleries?: Maybe<Array<Maybe<GetGalleryData>>>;
  /** Query by name. */
  name?: Maybe<Scalars['String']>;
};

export type GalleryDataWithProductInfo = {
  __typename?: 'GalleryDataWithProductInfo';
  /** Query by category ID */
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Query by  description. */
  description?: Maybe<Scalars['String']>;
  /** Query by hotspot. */
  hotspot?: Maybe<Array<Maybe<HotspotsWithProductInfo>>>;
  /** Query by  image. */
  image?: Maybe<Scalars['String']>;
  /** Query by name. */
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['String']>;
  /** Query by sku. */
  skuList?: Maybe<Scalars['String']>;
  /** Query by thumbnail. */
  thumbnail?: Maybe<Scalars['String']>;
};

/** Identifies which customer requires remote shopping assistance. */
export type GenerateCustomerTokenAsAdminInput = {
  /** The email address of the customer requesting remote shopping assistance. */
  customer_email: Scalars['String'];
};

/** Contains the generated customer token. */
export type GenerateCustomerTokenAsAdminOutput = {
  __typename?: 'GenerateCustomerTokenAsAdminOutput';
  /** The generated customer token. */
  customer_token: Scalars['String'];
};

export type GeneratedDownloadUrlOutput = {
  __typename?: 'generatedDownloadUrlOutput';
  generated_download_url_output: GenerateDownloadUrl;
};

export type GenerateDownloadUrl = {
  __typename?: 'generateDownloadUrl';
  /** Zip file URL */
  download_url?: Maybe<Scalars['String']>;
  /** File name */
  filename?: Maybe<Scalars['String']>;
  /** File name */
  status?: Maybe<Scalars['String']>;
};

export type GenerateDownloadUrlByCustomerIdImagesSamples = {
  images?: Maybe<Array<Maybe<ImageUrl>>>;
  product_sku?: Maybe<Scalars['String']>;
};

export type GetClickAction = {
  __typename?: 'GetClickAction';
  /** Query by clickAction. */
  action?: Maybe<Scalars['String']>;
  /** Query by getClickAction. */
  getClickAction?: Maybe<Scalars['String']>;
};

export type GetDots = {
  __typename?: 'GetDots';
  /** Query by addInfo. */
  addInfo?: Maybe<Scalars['String']>;
  /** Query by display_type. */
  display_type?: Maybe<Scalars['String']>;
  /** Query by left. */
  left?: Maybe<Scalars['Int']>;
  /** Query by sku. */
  sku?: Maybe<Scalars['String']>;
  /** Query by top. */
  top?: Maybe<Scalars['Int']>;
};

export type GetFirstIcon = {
  __typename?: 'GetFirstIcon';
  /** Query by getFirstIcon. */
  getFirstIcon?: Maybe<Scalars['String']>;
};

export type GetGalleryData = {
  __typename?: 'GetGalleryData';
  /** Query by banner image. */
  banner_image?: Maybe<Scalars['String']>;
  /** Query by category ID */
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Query by  description. */
  description?: Maybe<Scalars['String']>;
  /** Query by hotspot. */
  hotspot?: Maybe<Array<Maybe<Hotspots>>>;
  id?: Maybe<Scalars['String']>;
  /** Query by  image. */
  image?: Maybe<Scalars['String']>;
  /** Query by name. */
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['String']>;
  /** Query by sku. */
  skuList?: Maybe<Scalars['String']>;
  /** Query by thumbnail. */
  thumbnail?: Maybe<Scalars['String']>;
};

export type GetGridLayout = {
  __typename?: 'GetGridLayout';
  /** Query by gridLayout. */
  gridLayout?: Maybe<Scalars['String']>;
};

export type GetIconNumber = {
  __typename?: 'GetIconNumber';
  /** Query by getIconNumber. */
  getIconNumber?: Maybe<Scalars['String']>;
};

export type GetLineOptions = {
  __typename?: 'GetLineOptions';
  /** Query by getLineOptions. */
  getLineOptions?: Maybe<Scalars['String']>;
};

export type GetProductDescription = {
  __typename?: 'GetProductDescription';
  /** Query by getProductDescription. */
  getProductDescription?: Maybe<Scalars['String']>;
};

export type GetProductDisplay = {
  __typename?: 'GetProductDisplay';
  /** Query by productDisplay. */
  productDisplay?: Maybe<Scalars['String']>;
};

export type GetProductImage = {
  __typename?: 'GetProductImage';
  /** Query by getProductImage. */
  getProductImage?: Maybe<Scalars['String']>;
};

export type GetProductInfo = {
  __typename?: 'GetProductInfo';
  /** Query by description */
  description?: Maybe<Scalars['String']>;
  /** Query by image URL */
  image?: Maybe<Scalars['String']>;
  /** Query by name */
  name?: Maybe<Scalars['String']>;
  /** Query by price */
  price?: Maybe<Scalars['String']>;
  /** Query by sku */
  sku?: Maybe<Scalars['String']>;
};

export type GetProductName = {
  __typename?: 'GetProductName';
  /** Query by getProductName. */
  getProductName?: Maybe<Scalars['String']>;
};

export type GetProductPrice = {
  __typename?: 'GetProductPrice';
  /** Query by getProductPrice. */
  getProductPrice?: Maybe<Scalars['String']>;
};

export type GetSecondIcon = {
  __typename?: 'GetSecondIcon';
  /** Query by getSecondIcon. */
  getSecondIcon?: Maybe<Scalars['String']>;
};

export type GetStoreLocator = {
  __typename?: 'GetStoreLocator';
  /** Query by city. */
  city?: Maybe<Scalars['String']>;
  /** Query by country */
  country?: Maybe<Scalars['String']>;
  /** Query by latitude. */
  latitude?: Maybe<Scalars['String']>;
  /** Query by longitude. */
  longitude?: Maybe<Scalars['String']>;
  /** Query by name. */
  name?: Maybe<Scalars['String']>;
  /** Query by postcode */
  postcode?: Maybe<Scalars['String']>;
  /** Query by showroom */
  showroom?: Maybe<Scalars['String']>;
  /** Query by state. */
  state?: Maybe<Scalars['String']>;
  /** Query by store_id. */
  store_id?: Maybe<Scalars['String']>;
  /** Query by store type */
  store_type?: Maybe<Scalars['String']>;
  /** Query by street. */
  street?: Maybe<Scalars['String']>;
  /** Query by telephone */
  telephone?: Maybe<Scalars['String']>;
  /** Query by website. */
  website?: Maybe<Scalars['String']>;
};

export type GetTitlePosition = {
  __typename?: 'GetTitlePosition';
  /** Query by titlePossition. */
  titlePosition?: Maybe<Scalars['String']>;
};

export type GetWhatsNew = {
  __typename?: 'GetWhatsNew';
  /** Query by category_name. */
  category_name?: Maybe<Scalars['String']>;
  /** Query by product_image. */
  product_image?: Maybe<Scalars['String']>;
  /** Query by product_name. */
  product_name?: Maybe<Scalars['String']>;
  /** Query by product_url */
  product_url?: Maybe<Scalars['String']>;
};

/** Contains the text of a gift message, its sender, and recipient */
export type GiftMessage = {
  __typename?: 'GiftMessage';
  /** Sender name */
  from: Scalars['String'];
  /** Gift message text */
  message: Scalars['String'];
  /** Recipient name */
  to: Scalars['String'];
};

/** Contains the text of a gift message, its sender, and recipient */
export type GiftMessageInput = {
  /** Sender name */
  from: Scalars['String'];
  /** Gift message text */
  message: Scalars['String'];
  /** Recipient name */
  to: Scalars['String'];
};

export type GradeFilters = {
  /** fabric_pattern filter */
  fabric_color?: Maybe<Scalars['String']>;
  /** fabric_pattern filter */
  fabric_pattern?: Maybe<Scalars['String']>;
  /** fabric_pattern_like */
  fabric_pattern_like?: Maybe<Scalars['String']>;
  /** fabric_vendor filter */
  fabric_vendor?: Maybe<Scalars['String']>;
  /** fabric_pattern filter */
  fairfield_grade?: Maybe<Scalars['String']>;
  page_number?: Maybe<Scalars['Int']>;
  page_size?: Maybe<Scalars['Int']>;
  /** sorting attribute e.g. fabric_pattern */
  sorting_attribute?: Maybe<Scalars['String']>;
  /** sorting direction ASC DESC */
  sorting_direction?: Maybe<Scalars['String']>;
};

/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export type GroupedProduct = ProductInterface & RoutableInterface & PhysicalProductInterface & {
  __typename?: 'GroupedProduct';
  /**
   * Tearsheet PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  assembly_instructions?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  attribute_set_name?: Maybe<Scalars['String']>;
  /**
   * Availability date for the product.
   * @deprecated Use the `custom_attributes` field instead.
   */
  availability_date?: Maybe<Scalars['String']>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Products' is enabled.
   */
  canonical_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  catalog_page?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  /**
   * Order Form PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  collection_overview_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_caster_options?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_wheels_option?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_wood_finish?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_apronh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_armh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_assem_req?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_avails?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_backconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_basefab?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_baselth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_bn_appl?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_casegd?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_casters?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cfgmodel?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_checkbox_test_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_collection?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_countryorg?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cubes?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cupholder?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_depth_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_designer?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_dftfabric?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_dftfinish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_duedate?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_extdesc2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_finish_req?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_finishopt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdepthgh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdeptlow?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimd?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimw?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_fulllftwal?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_fullyuph?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_height_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidebkh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestd?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestd_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw_test?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_introdat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_isvisible?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_lanect?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_laners?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_lanesl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leatheravl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_madeto?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_moistbarr?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_nail_opts?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhigharh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhighovh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhighsth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowarh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowovh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowsth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_onords?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_product_type_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_qty_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_reclindpt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_reclwallcl?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_riser?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seatconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seath?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seath_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_sprgconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_status?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdback?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdcushion?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdfinish?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdpllw1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tablet?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tariff?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tvrecline?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_uph_opts?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_wgtcar?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_wgtwrp?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_width_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_woodspec1?: Maybe<Scalars['Int']>;
  ffi_woodspec1_label?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_finish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_type_filterable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_abrasion?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_avail?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_avail_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_categories?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_category1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_collection?: Maybe<Scalars['String']>;
  ffifabrics_collection_label?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_colorways?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_country?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_cutyard?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_dec_cord?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_descr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_descript?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont9?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct9?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabric?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_finishtype?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fringe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fullcont?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_grade?: Maybe<Array<Maybe<Scalars['String']>>>;
  ffifabrics_grade_configurator_filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_hexclrgrp?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_hrepeat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_leather?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_pattern?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_std_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_tape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vendor?: Maybe<Array<Maybe<Scalars['String']>>>;
  ffifabrics_vendor_image?: Maybe<SwatchValues>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vinyl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vrepeat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_about?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_chm_distr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_cleaning?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_fairshield?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_nailhead?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_phy_distr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_sheen?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  file_upload?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  filterable_attribute_set_name?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  hotspots?: Maybe<Array<Maybe<ProductHotspotData>>>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** Add notification that loged user have added product in downloads in specific store */
  in_downloads?: Maybe<Scalars['String']>;
  /** Image is a part of in_real_life role. */
  in_real_life?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  in_stock_filter?: Maybe<Scalars['Int']>;
  industry_approval_attribute_option?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  introdat_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_featured?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_new?: Maybe<Scalars['Int']>;
  /** An array containing grouped product items. */
  items?: Maybe<Array<Maybe<GroupedProductItem>>>;
  /** Adding NULL for this field because of React core. */
  lead_time?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_image role. */
  lifestyle_image?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_image2 role. */
  lifestyle_image2?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_video role. */
  lifestyle_video?: Maybe<Scalars['String']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer?: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  new_from_date?: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  new_to_date?: Maybe<Scalars['String']>;
  /** Number of throw/kidney pillows */
  num_of_pillows?: Maybe<Scalars['Int']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** The range of prices for the product */
  price_range: PriceRange;
  /** An array of `TierPrice` objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_features?: Maybe<Scalars['String']>;
  product_features_label?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of `ProductLinks` objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** @deprecated Use the `custom_attributes` field instead. */
  ready_to_ship_filter?: Maybe<Scalars['Int']>;
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirect_code: Scalars['Int'];
  /** An array of products to be displayed in a Related Products block. */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /**
   * Packing Fee List PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  revit_file?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shop_by_position?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * Thread Board PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  spec_sheet?: Maybe<Scalars['String']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /**
   * The uploaded video.
   * @deprecated Use the `custom_attributes` field instead.
   */
  video_upload?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
};


/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export type GroupedProductReviewsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

/** Contains information about an individual grouped product item. */
export type GroupedProductItem = {
  __typename?: 'GroupedProductItem';
  /** The relative position of this item compared to the other group items. */
  position?: Maybe<Scalars['Int']>;
  /** Details about this product option. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this grouped product item. */
  qty?: Maybe<Scalars['Float']>;
};

/** A grouped product wish list item. */
export type GroupedProductWishlistItem = WishlistItemInterface & {
  __typename?: 'GroupedProductWishlistItem';
  /** The date and time the item was added to the wish list. */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
};

/**
 * Contains a set of relative URLs that PayPal uses in response to various actions
 * during the authorization process. Magento prepends the base URL to this value to
 * create a full URL. For example, if the full URL is
 * https://www.example.com/path/to/page.html, the relative URL is
 * path/to/page.html. Use this input for Payments Pro Hosted Solution payment method.
 */
export type HostedProInput = {
  /**
   * The relative URL of the page that PayPal redirects to when the buyer cancels
   * the transaction in order to choose a different payment method. For example, if
   * the full URL to this page is
   * https://www.example.com/paypal/action/cancel.html, the relative URL is
   * paypal/action/cancel.html.
   */
  cancel_url: Scalars['String'];
  /**
   * The relative URL of the final confirmation page that PayPal redirects to upon
   * payment success. For example, if the full URL to this page is
   * https://www.example.com/paypal/action/return.html, the relative URL is
   * paypal/action/return.html.
   */
  return_url: Scalars['String'];
};

/** Contains the secure URL used for the Payments Pro Hosted Solution payment method. */
export type HostedProUrl = {
  __typename?: 'HostedProUrl';
  /** The secure URL generated by PayPal. */
  secure_form_url?: Maybe<Scalars['String']>;
};

/** Contains the required input to request the secure URL for Payments Pro Hosted Solution payment. */
export type HostedProUrlInput = {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String'];
};

export type HotspotData = {
  __typename?: 'HotspotData';
  /** Query by content. */
  content?: Maybe<Scalars['String']>;
  /** Query by hotspot_id. */
  hotspot_id?: Maybe<Scalars['String']>;
  /** Query by  image. */
  image?: Maybe<Scalars['String']>;
  /** Query by name. */
  name?: Maybe<Scalars['String']>;
};

export type Hotspots = {
  __typename?: 'Hotspots';
  content?: Maybe<Scalars['String']>;
  hotspot_id?: Maybe<Scalars['Int']>;
  /** Query by  image. */
  image?: Maybe<Scalars['String']>;
  /** Query by name. */
  name?: Maybe<Scalars['String']>;
};

export type HotspotsWithProductInfo = {
  __typename?: 'HotspotsWithProductInfo';
  content?: Maybe<Array<Maybe<Dot>>>;
  hotspot_id?: Maybe<Scalars['String']>;
  /** Query by  image. */
  image?: Maybe<Scalars['String']>;
  /** Query by name. */
  name?: Maybe<Scalars['String']>;
};

/** Contains target path parameters. */
export type HttpQueryParameter = {
  __typename?: 'HttpQueryParameter';
  /** A parameter name. */
  name?: Maybe<Scalars['String']>;
  /** A parameter value. */
  value?: Maybe<Scalars['String']>;
};

export type ImageDownloads = {
  __typename?: 'ImageDownloads';
  /** Customer Id */
  customer_id?: Maybe<Scalars['Int']>;
  /** Internal Id */
  id?: Maybe<Scalars['Int']>;
  /** Product Model */
  product?: Maybe<ProductInterface>;
  /** Product Id */
  product_id?: Maybe<Scalars['Int']>;
  /** Currently Not In Use */
  selection_id?: Maybe<Scalars['Int']>;
  /** Store Id */
  store_id?: Maybe<Scalars['Int']>;
};

export type ImageDownloadsFilterInput = {
  /** Product SKU for filtering */
  skuId?: Maybe<FilterTypeInput>;
};

export type ImageItem = {
  __typename?: 'ImageItem';
  /** The time when the customer added the item to the wish list */
  added_at?: Maybe<Scalars['String']>;
  /** Product image download status [downloaded = 1, not downloaded = 0] */
  download_status?: Maybe<Scalars['String']>;
  /** Product image download type [all, main] */
  download_type?: Maybe<Scalars['String']>;
  /** The image list item ID */
  id?: Maybe<Scalars['Int']>;
  product?: Maybe<ProductInterface>;
};

export type Imagelist = {
  __typename?: 'Imagelist';
  /** Wishlist unique identifier */
  id?: Maybe<Scalars['ID']>;
  /** An array of items in the customer's wish list */
  items?: Maybe<Array<Maybe<ImageItem>>>;
  /** The number of items in the wish list */
  items_count?: Maybe<Scalars['Int']>;
  /** The time of the last modification to the wish list */
  updated_at?: Maybe<Scalars['String']>;
};

export type Images = {
  __typename?: 'Images';
  file_name?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
};

export type Imageslist = {
  __typename?: 'Imageslist';
  images?: Maybe<Array<Maybe<Images>>>;
  product_id?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
};

export type ImageSwatchData = SwatchDataInterface & {
  __typename?: 'ImageSwatchData';
  /** The URL assigned to the thumbnail of the swatch image. */
  thumbnail?: Maybe<Scalars['String']>;
  /** The value can be represented as color (HEX code), image link, or text. */
  value?: Maybe<Scalars['String']>;
};

export type ImageUrl = {
  /** Image Url */
  url?: Maybe<Scalars['String']>;
};

/** Contains an error message when an internal error occurred. */
export type InternalError = ErrorInterface & {
  __typename?: 'InternalError';
  /** The returned error message. */
  message: Scalars['String'];
};

/** Contains invoice details. */
export type Invoice = {
  __typename?: 'Invoice';
  /** Comments on the invoice. */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `Invoice` object. */
  id: Scalars['ID'];
  /** Invoiced product details. */
  items?: Maybe<Array<Maybe<InvoiceItemInterface>>>;
  /** Sequential invoice number. */
  number: Scalars['String'];
  /** Invoice total amount details. */
  total?: Maybe<InvoiceTotal>;
};

export type InvoiceItem = InvoiceItemInterface & {
  __typename?: 'InvoiceItem';
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of invoiced items. */
  quantity_invoiced?: Maybe<Scalars['Float']>;
};

/** Contains detailes about invoiced items. */
export type InvoiceItemInterface = {
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of invoiced items. */
  quantity_invoiced?: Maybe<Scalars['Float']>;
};

/** Contains price details from an invoice. */
export type InvoiceTotal = {
  __typename?: 'InvoiceTotal';
  /** The final base grand total amount in the base currency. */
  base_grand_total: Money;
  /** The applied discounts to the invoice. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes. */
  grand_total: Money;
  /** Details about the shipping and handling costs for the invoice. */
  shipping_handling?: Maybe<ShippingHandling>;
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes. */
  subtotal: Money;
  /** The invoice tax details. */
  taxes?: Maybe<Array<Maybe<TaxItem>>>;
  /** The shipping amount for the invoice. */
  total_shipping: Money;
  /** The amount of tax applied to the invoice. */
  total_tax: Money;
};

/** Contains the result of the `isEmailAvailable` query. */
export type IsEmailAvailableOutput = {
  __typename?: 'IsEmailAvailableOutput';
  /** Indicates whether the specified email address can be used to create a customer. */
  is_email_available?: Maybe<Scalars['Boolean']>;
};

export type Item = {
  __typename?: 'Item';
  /** Customer Id */
  customer_id?: Maybe<Scalars['Int']>;
  /** Internal Id */
  id?: Maybe<Scalars['Int']>;
  /** Product SKU */
  product_sku?: Maybe<Scalars['String']>;
  /** Currently Not In Use */
  selection_id?: Maybe<Scalars['Int']>;
  /** Store Id */
  store_id?: Maybe<Scalars['Int']>;
};

/** A list of options of the selected bundle product. */
export type ItemSelectedBundleOption = {
  __typename?: 'ItemSelectedBundleOption';
  /**
   * The unique ID for a `ItemSelectedBundleOption` object.
   * @deprecated Use `uid` instead.
   */
  id: Scalars['ID'];
  /** The label of the option. */
  label: Scalars['String'];
  /** The unique ID for a `ItemSelectedBundleOption` object. */
  uid: Scalars['ID'];
  /** A list of products that represent the values of the parent option. */
  values?: Maybe<Array<Maybe<ItemSelectedBundleOptionValue>>>;
};

/** A list of values for the selected bundle product. */
export type ItemSelectedBundleOptionValue = {
  __typename?: 'ItemSelectedBundleOptionValue';
  /**
   * The unique ID for a `ItemSelectedBundleOptionValue` object.
   * @deprecated Use `uid` instead.
   */
  id: Scalars['ID'];
  /** The price of the child bundle product. */
  price: Money;
  /** The name of the child bundle product. */
  product_name: Scalars['String'];
  /** The SKU of the child bundle product. */
  product_sku: Scalars['String'];
  /** The number of this bundle product that were ordered. */
  quantity: Scalars['Float'];
  /** The unique ID for a `ItemSelectedBundleOptionValue` object. */
  uid: Scalars['ID'];
};

/** Contains a key-value pair. */
export type KeyValue = {
  __typename?: 'KeyValue';
  /** The name part of the key/value pair. */
  name?: Maybe<Scalars['String']>;
  /** The value part of the key/value pair. */
  value?: Maybe<Scalars['String']>;
};

export type Lanes = {
  __typename?: 'Lanes';
  contract?: Maybe<Scalars['Boolean']>;
  retail?: Maybe<Scalars['Boolean']>;
  senior?: Maybe<Scalars['Boolean']>;
};

/** Contains information for rendering layered navigation. */
export type LayerFilter = {
  __typename?: 'LayerFilter';
  /**
   * An array of filter items.
   * @deprecated Use `Aggregation.options` instead.
   */
  filter_items?: Maybe<Array<Maybe<LayerFilterItemInterface>>>;
  /**
   * The count of filter items in filter group.
   * @deprecated Use `Aggregation.count` instead.
   */
  filter_items_count?: Maybe<Scalars['Int']>;
  /**
   * The name of a layered navigation filter.
   * @deprecated Use `Aggregation.label` instead.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * The request variable name for a filter query.
   * @deprecated Use `Aggregation.attribute_code` instead.
   */
  request_var?: Maybe<Scalars['String']>;
};

export type LayerFilterItem = LayerFilterItemInterface & {
  __typename?: 'LayerFilterItem';
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  items_count?: Maybe<Scalars['Int']>;
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label?: Maybe<Scalars['String']>;
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  value_string?: Maybe<Scalars['String']>;
};

export type LayerFilterItemInterface = {
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  items_count?: Maybe<Scalars['Int']>;
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label?: Maybe<Scalars['String']>;
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  value_string?: Maybe<Scalars['String']>;
};

/** Defines characteristics about images and videos associated with a specific product. */
export type MediaGalleryEntry = {
  __typename?: 'MediaGalleryEntry';
  /** Details about the content of the media gallery item. */
  content?: Maybe<ProductMediaGalleryEntriesContent>;
  /** Indicates whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** The path of the image on the server. */
  file?: Maybe<Scalars['String']>;
  /**
   * The identifier assigned to the object.
   * @deprecated Use `uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The alt text displayed on the storefront when the user points to the image. */
  label?: Maybe<Scalars['String']>;
  /** Either `image` or `video`. */
  media_type?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** Array of image types. It can have the following values: image, small_image, thumbnail. */
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The unique ID for a `MediaGalleryEntry` object. */
  uid: Scalars['ID'];
  /** Details about the content of a video item. */
  video_content?: Maybe<ProductMediaGalleryEntriesVideoContent>;
};

/** Contains basic information about a product image or video. */
export type MediaGalleryInterface = {
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** True or false */
  is_lifestyle?: Maybe<Scalars['String']>;
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** aa */
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>;
};


/** Contains basic information about a product image or video. */
export type MediaGalleryInterfaceUrlArgs = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

/** Defines a monetary value, including a numeric value and a currency code. */
export type Money = {
  __typename?: 'Money';
  /** A three-letter currency code, such as USD or EUR. */
  currency?: Maybe<CurrencyEnum>;
  /** A number expressing a monetary value. */
  value?: Maybe<Scalars['Float']>;
};

/** Contains the results of a `monogram` query. */
export type Monogram = {
  __typename?: 'Monogram';
  /** An array of products that match the specified search criteria for monogram collection. */
  items?: Maybe<Array<Maybe<MonogramItem>>>;
};

/** Contains monogram collection products. */
export type MonogramItem = {
  __typename?: 'MonogramItem';
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** The product name. */
  name?: Maybe<Scalars['String']>;
  /** The range of prices for the product */
  price_range: PriceRange;
  /** A number or code assigned to a product to identify the product. */
  sku?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /** The unique ID for a `MonogramInterface` object. */
  uid: Scalars['ID'];
};

export type MpFilters = {
  /** Filter From Date. */
  from: Scalars['String'];
  /** Period. */
  period_type?: Maybe<Scalars['String']>;
  /** Show Empty Rows. */
  show_empty_rows?: Maybe<Scalars['Boolean']>;
  /** Filter Store Id. */
  store_id?: Maybe<Scalars['Int']>;
  /** Filter To Date. */
  to: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Add wish list item to cart */
  addAllToCart: WishlistJola;
  /** Add one or more bundle products to the specified cart. We recommend using `addProductsToCart` instead. */
  addBundleProductsToCart?: Maybe<AddBundleProductsToCartOutput>;
  /** Add one or more configurable products to the specified cart. We recommend using `addProductsToCart` instead. */
  addConfigurableProductsToCart?: Maybe<AddConfigurableProductsToCartOutput>;
  addCustomerOrderItemAttributes?: Maybe<Scalars['String']>;
  addCustomerQuoteItemAttributes?: Maybe<Scalars['String']>;
  /** Add one or more downloadable products to the specified cart. We recommend using `addProductsToCart` instead. */
  addDownloadableProductsToCart?: Maybe<AddDownloadableProductsToCartOutput>;
  /** Add items by SKU to the images list */
  addImagesToDownload?: Maybe<Imagelist>;
  /** Add all wish list items to cart */
  addItemToCart: WishlistJola;
  addItemToDownload?: Maybe<AddItemToDownloaderOutput>;
  /** Add items by SKU to the wish list */
  addItemsToWishlist?: Maybe<Array<Maybe<WishlistJola>>>;
  addMultipleItemsToDownload?: Maybe<AddMultipleItemsToDownloadOutput>;
  /** Add new wishlist */
  addNewWishlist: WishlistJola;
  /** Add any type of product to the cart. */
  addProductsToCart?: Maybe<AddProductsToCartOutput>;
  /** Add products to the specified compare list. */
  addProductsToCompareList?: Maybe<CompareList>;
  /** Add one or more products to the specified wish list. This mutation supports all product types. */
  addProductsToWishlist?: Maybe<AddProductsToWishlistOutput>;
  /** Add items by SKU and name to the saved builds */
  addSavedBuildToWishlist?: Maybe<Array<Maybe<WishlistJola>>>;
  /** Add one or more simple products to the specified cart. We recommend using `addProductsToCart` instead. */
  addSimpleProductsToCart?: Maybe<AddSimpleProductsToCartOutput>;
  /** Add one or more virtual products to the specified cart. We recommend using `addProductsToCart` instead. */
  addVirtualProductsToCart?: Maybe<AddVirtualProductsToCartOutput>;
  /** Add items in the specified wishlist to the customer's cart. */
  addWishlistItemsToCart?: Maybe<AddWishlistItemsToCartOutput>;
  /** Apply a pre-defined coupon code to the specified cart. */
  applyCouponToCart?: Maybe<ApplyCouponToCartOutput>;
  /** Assign the specified compare list to the logged in customer. */
  assignCompareListToCustomer?: Maybe<AssignCompareListToCustomerOutput>;
  /** Assign a logged-in customer to the specified guest shopping cart. */
  assignCustomerToGuestCart: Cart;
  /** Change the password for the logged-in customer. */
  changeCustomerPassword?: Maybe<Customer>;
  /** Contact us from a form on frontend. */
  contactUs?: Maybe<Scalars['String']>;
  copyItemToWishlist: WishlistJola;
  /** Creates Client Token for Braintree Javascript SDK initialization. */
  createBraintreeClientToken: Scalars['String'];
  /** Create a new compare list. The compare list is saved for logged in customers. */
  createCompareList?: Maybe<CompareList>;
  /** Create customer account */
  createCustomer?: Maybe<CustomerOutput>;
  /** Create a billing or shipping address for a customer or guest. */
  createCustomerAddress?: Maybe<CustomerAddress>;
  /** Create a customer account. */
  createCustomerV2?: Maybe<CustomerOutput>;
  /** Create an empty shopping cart for a guest or logged in user */
  createEmptyCart?: Maybe<Scalars['String']>;
  /** Initiate a transaction and receive a token. Use this mutation for Payflow Pro and Payments Pro payment methods */
  createPayflowProToken?: Maybe<CreatePayflowProTokenOutput>;
  /**
   * Initiate an Express Checkout transaction and receive a token. Use this
   * mutation for Express Checkout and Payments Standard payment methods.
   */
  createPaypalExpressToken?: Maybe<PaypalExpressTokenOutput>;
  /** Create a product review for the specified product. */
  createProductReview: CreateProductReviewOutput;
  /** Upload customer file */
  customerRegisterAdditionalUploadFile?: Maybe<CustomerFileOutput>;
  /** Delete all items from the images list */
  deleteAllImagesToDownload?: Maybe<Imagelist>;
  /** Delete the specified compare list. */
  deleteCompareList?: Maybe<DeleteCompareListOutput>;
  /** Delete the billing or shipping address of a customer. */
  deleteCustomerAddress?: Maybe<Scalars['Boolean']>;
  /** Delete items by SKU from the images list */
  deleteImagesToDownload?: Maybe<Imagelist>;
  /** Delete items From Wishlist by SKU */
  deleteItemsFromWishlist: WishlistJola;
  /** Delete items From Wishlist by SKU and Qty */
  deleteItemsFromWishlistQty: WishlistJola;
  /** Delete a customer's payment token. */
  deletePaymentToken?: Maybe<DeletePaymentTokenOutput>;
  /** Delete saved build items From Wishlist by SKU */
  deleteSavedBuildsFromWishlist: WishlistJola;
  /** Delete Wishlist by name */
  deleteWishlist: WishlistJola;
  /** Download images by SKU */
  downloadAllImages?: Maybe<Array<Maybe<Imageslist>>>;
  /** Download images by SKU */
  downloadImagesBySkus?: Maybe<Array<Maybe<Imageslist>>>;
  /** Change wishlist name */
  editWishlist: WishlistJola;
  /** Generates new cache */
  generateCache?: Maybe<Scalars['String']>;
  /** Generate a token for specified customer. */
  generateCustomerToken?: Maybe<CustomerToken>;
  /** Request a customer token so that an administrator can perform remote shopping assistance. */
  generateCustomerTokenAsAdmin?: Maybe<GenerateCustomerTokenAsAdminOutput>;
  generateDownloadUrlM?: Maybe<GeneratedDownloadUrlOutput>;
  /** Generates reset password token for customer based on email input. */
  generateResetPasswordToken?: Maybe<ResetPassword>;
  /**
   * Handle a payment response and save the payment in Quote. Use this mutation for
   * Payflow Pro and Payments Pro payment methods.
   */
  handlePayflowProResponse?: Maybe<PayflowProResponseOutput>;
  /** Transfer the contents of a guest cart into the cart of a logged-in customer. */
  mergeCarts: Cart;
  moveItemToWishlist: WishlistJola;
  /** Convert the quote into an order. */
  placeOrder?: Maybe<PlaceOrderOutput>;
  removeAllItemsFromCart?: Maybe<RemoveAllItemsFromCartOutput>;
  /** Remove a previously-applied coupon from the cart. The cart must contain at least one item in order to remove the coupon. */
  removeCouponFromCart?: Maybe<RemoveCouponFromCartOutput>;
  /**
   * Delete the entire quantity of a specified item from the cart. If you remove
   * all items from the cart, the cart continues to exist.
   */
  removeItemFromCart?: Maybe<RemoveItemFromCartOutput>;
  removeItemFromDownload?: Maybe<RemoveItemFromDownloaderOutput>;
  removeMultipleItemsFromDownload?: Maybe<RemoveMultipleItemsFromDownloaderOutput>;
  /** Remove products from the specified compare list. */
  removeProductsFromCompareList?: Maybe<CompareList>;
  /** Remove one or more products from the specified wish list. */
  removeProductsFromWishlist?: Maybe<RemoveProductsFromWishlistOutput>;
  /** Add all products from a customer's previous order to the cart. */
  reorderItems?: Maybe<ReorderItemsOutput>;
  /** Request an email with a reset password token for the registered customer identified by the specified email. */
  requestPasswordResetEmail?: Maybe<Scalars['Boolean']>;
  /**
   * Reset a customer's password using the reset password token that the customer
   * received in an email after requesting it using `requestPasswordResetEmail`.
   */
  resetPassword?: Maybe<Scalars['Boolean']>;
  /** Resets password if a valid reset password token, email and new password is provided. */
  resetPasswordJola?: Maybe<ResetPassword>;
  /** Revoke the customer token. */
  revokeCustomerToken?: Maybe<RevokeCustomerTokenOutput>;
  /** Save read news */
  saveReadNews?: Maybe<SaveReadNewsOutput>;
  /** send claims form email from a form on frontend. */
  sendClaimsFormEmail?: Maybe<Scalars['String']>;
  /** Sends email from a form on frontend. */
  sendEmail?: Maybe<Scalars['String']>;
  /** Send a message on behalf of a customer to the specified email addresses. */
  sendEmailToFriend?: Maybe<SendEmailToFriendOutput>;
  /** Set the billing address on a specific cart. */
  setBillingAddressOnCart?: Maybe<SetBillingAddressOnCartOutput>;
  /** Assign the email address of a guest to the cart. */
  setGuestEmailOnCart?: Maybe<SetGuestEmailOnCartOutput>;
  /**
   * Set the cart payment method and convert the cart into an order.
   * @deprecated Should use setPaymentMethodOnCart and placeOrder mutations in single request.
   */
  setPaymentMethodAndPlaceOrder?: Maybe<PlaceOrderOutput>;
  /** Apply a payment method to the cart. */
  setPaymentMethodOnCart?: Maybe<SetPaymentMethodOnCartOutput>;
  /** Set one or more shipping addresses on a specific cart. */
  setShippingAddressesOnCart?: Maybe<SetShippingAddressesOnCartOutput>;
  /** Set one or more delivery methods on a cart. */
  setShippingMethodsOnCart?: Maybe<SetShippingMethodsOnCartOutput>;
  /** Subscribe the specified email to the store's newsletter. */
  subscribeEmailToNewsletter?: Maybe<SubscribeEmailToNewsletterOutput>;
  /** Query by Mutation for subscriber. */
  subscriber?: Maybe<SubscribeEmailToNewsletterOutput>;
  /** Modify items in the cart. */
  updateCartItems?: Maybe<UpdateCartItemsOutput>;
  /** Use `updateCustomerV2` instead. */
  updateCustomer?: Maybe<CustomerOutput>;
  /** Update the billing or shipping address of a customer or guest. */
  updateCustomerAddress?: Maybe<CustomerAddress>;
  /** Change the email address for the logged-in customer. */
  updateCustomerEmail?: Maybe<CustomerOutput>;
  /** Update the customer's personal information. */
  updateCustomerV2?: Maybe<CustomerOutput>;
  /** Update one or more products in the specified wish list. */
  updateProductsInWishlist?: Maybe<UpdateProductsInWishlistOutput>;
  /** Update saved build name */
  updateSavedBuildName: WishlistJola;
  /** Update wish list product */
  updateWishlistItem: WishlistJola;
  /** Upload Claims Image */
  uploadClaimsImage?: Maybe<ClaimsFileOutput>;
  /** Upload Configurator Image */
  uploadConfiguratorImage?: Maybe<ConfiguratorFileOutput>;
  /** Upload Configurator Image for cart and wishlist */
  uploadQuoteConfiguratorImage?: Maybe<QuoteConfiguratorFileOutput>;
};


export type MutationAddAllToCartArgs = {
  name?: Maybe<Scalars['String']>;
};


export type MutationAddBundleProductsToCartArgs = {
  input?: Maybe<AddBundleProductsToCartInput>;
};


export type MutationAddConfigurableProductsToCartArgs = {
  input?: Maybe<AddConfigurableProductsToCartInput>;
};


export type MutationAddCustomerOrderItemAttributesArgs = {
  input?: Maybe<OrderItemAttributes>;
};


export type MutationAddCustomerQuoteItemAttributesArgs = {
  input?: Maybe<QuoteItemAttributes>;
};


export type MutationAddDownloadableProductsToCartArgs = {
  input?: Maybe<AddDownloadableProductsToCartInput>;
};


export type MutationAddImagesToDownloadArgs = {
  input?: Maybe<AddImagesToDownloadInput>;
};


export type MutationAddItemToCartArgs = {
  name?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
};


export type MutationAddItemToDownloadArgs = {
  input: AddByCustomerIdInput;
};


export type MutationAddItemsToWishlistArgs = {
  input?: Maybe<AddItemsToWishlistInput>;
};


export type MutationAddMultipleItemsToDownloadArgs = {
  input: Array<AddByCustomerIdInput>;
};


export type MutationAddNewWishlistArgs = {
  input?: Maybe<Scalars['String']>;
};


export type MutationAddProductsToCartArgs = {
  cartId: Scalars['String'];
  cartItems: Array<CartItemInput>;
};


export type MutationAddProductsToCompareListArgs = {
  input?: Maybe<AddProductsToCompareListInput>;
};


export type MutationAddProductsToWishlistArgs = {
  wishlistId: Scalars['ID'];
  wishlistItems: Array<WishlistItemInput>;
};


export type MutationAddSavedBuildToWishlistArgs = {
  input?: Maybe<AddSavedBuildToWishlistInput>;
};


export type MutationAddSimpleProductsToCartArgs = {
  input?: Maybe<AddSimpleProductsToCartInput>;
};


export type MutationAddVirtualProductsToCartArgs = {
  input?: Maybe<AddVirtualProductsToCartInput>;
};


export type MutationAddWishlistItemsToCartArgs = {
  wishlistId: Scalars['ID'];
  wishlistItemIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationApplyCouponToCartArgs = {
  input?: Maybe<ApplyCouponToCartInput>;
};


export type MutationAssignCompareListToCustomerArgs = {
  uid: Scalars['ID'];
};


export type MutationAssignCustomerToGuestCartArgs = {
  cart_id: Scalars['String'];
};


export type MutationChangeCustomerPasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationContactUsArgs = {
  input?: Maybe<ContactUsInput>;
};


export type MutationCopyItemToWishlistArgs = {
  fromWishlist?: Maybe<Scalars['String']>;
  toWishlist?: Maybe<Scalars['String']>;
  input?: Maybe<Array<Maybe<ProductInput>>>;
};


export type MutationCreateCompareListArgs = {
  input?: Maybe<CreateCompareListInput>;
};


export type MutationCreateCustomerArgs = {
  input: CustomerInput;
};


export type MutationCreateCustomerAddressArgs = {
  input: CustomerAddressInput;
};


export type MutationCreateCustomerV2Args = {
  input: CustomerCreateInput;
};


export type MutationCreateEmptyCartArgs = {
  input?: Maybe<CreateEmptyCartInput>;
};


export type MutationCreatePayflowProTokenArgs = {
  input: PayflowProTokenInput;
};


export type MutationCreatePaypalExpressTokenArgs = {
  input: PaypalExpressTokenInput;
};


export type MutationCreateProductReviewArgs = {
  input: CreateProductReviewInput;
};


export type MutationCustomerRegisterAdditionalUploadFileArgs = {
  input: CustomerFileInput;
};


export type MutationDeleteCompareListArgs = {
  uid: Scalars['ID'];
};


export type MutationDeleteCustomerAddressArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteImagesToDownloadArgs = {
  input?: Maybe<Skus>;
};


export type MutationDeleteItemsFromWishlistArgs = {
  input?: Maybe<DeleteItemFromWishlistInput>;
};


export type MutationDeleteItemsFromWishlistQtyArgs = {
  wishlist?: Maybe<Scalars['String']>;
  input?: Maybe<Array<Maybe<ProductInput>>>;
};


export type MutationDeletePaymentTokenArgs = {
  public_hash: Scalars['String'];
};


export type MutationDeleteSavedBuildsFromWishlistArgs = {
  input?: Maybe<DeleteSavedBuildFromWishlistInput>;
};


export type MutationDeleteWishlistArgs = {
  input?: Maybe<Scalars['String']>;
};


export type MutationDownloadImagesBySkusArgs = {
  input?: Maybe<AddImagesToDownloadInput>;
};


export type MutationEditWishlistArgs = {
  input?: Maybe<ChangeName>;
};


export type MutationGenerateCacheArgs = {
  input?: Maybe<Scalars['String']>;
};


export type MutationGenerateCustomerTokenArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationGenerateCustomerTokenAsAdminArgs = {
  input: GenerateCustomerTokenAsAdminInput;
};


export type MutationGenerateDownloadUrlMArgs = {
  input: Array<GenerateDownloadUrlByCustomerIdImagesSamples>;
};


export type MutationGenerateResetPasswordTokenArgs = {
  email: Scalars['String'];
};


export type MutationHandlePayflowProResponseArgs = {
  input: PayflowProResponseInput;
};


export type MutationMergeCartsArgs = {
  source_cart_id: Scalars['String'];
  destination_cart_id?: Maybe<Scalars['String']>;
};


export type MutationMoveItemToWishlistArgs = {
  fromWishlist?: Maybe<Scalars['String']>;
  toWishlist?: Maybe<Scalars['String']>;
  input?: Maybe<Array<Maybe<ProductInput>>>;
};


export type MutationPlaceOrderArgs = {
  input?: Maybe<PlaceOrderInput>;
};


export type MutationRemoveAllItemsFromCartArgs = {
  cart_id: Scalars['String'];
};


export type MutationRemoveCouponFromCartArgs = {
  input?: Maybe<RemoveCouponFromCartInput>;
};


export type MutationRemoveItemFromCartArgs = {
  input?: Maybe<RemoveItemFromCartInput>;
};


export type MutationRemoveItemFromDownloadArgs = {
  input: AddByCustomerIdInput;
};


export type MutationRemoveMultipleItemsFromDownloadArgs = {
  input: Array<AddByCustomerIdInput>;
};


export type MutationRemoveProductsFromCompareListArgs = {
  input?: Maybe<RemoveProductsFromCompareListInput>;
};


export type MutationRemoveProductsFromWishlistArgs = {
  wishlistId: Scalars['ID'];
  wishlistItemsIds: Array<Scalars['ID']>;
};


export type MutationReorderItemsArgs = {
  orderNumber: Scalars['String'];
};


export type MutationRequestPasswordResetEmailArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
  resetPasswordToken: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationResetPasswordJolaArgs = {
  input?: Maybe<ResetPasswordRequestInput>;
};


export type MutationSendClaimsFormEmailArgs = {
  input?: Maybe<ClaimsEmailInput>;
};


export type MutationSendEmailArgs = {
  input?: Maybe<SendEmailInput>;
};


export type MutationSendEmailToFriendArgs = {
  input?: Maybe<SendEmailToFriendInput>;
};


export type MutationSetBillingAddressOnCartArgs = {
  input?: Maybe<SetBillingAddressOnCartInput>;
};


export type MutationSetGuestEmailOnCartArgs = {
  input?: Maybe<SetGuestEmailOnCartInput>;
};


export type MutationSetPaymentMethodAndPlaceOrderArgs = {
  input?: Maybe<SetPaymentMethodAndPlaceOrderInput>;
};


export type MutationSetPaymentMethodOnCartArgs = {
  input?: Maybe<SetPaymentMethodOnCartInput>;
};


export type MutationSetShippingAddressesOnCartArgs = {
  input?: Maybe<SetShippingAddressesOnCartInput>;
};


export type MutationSetShippingMethodsOnCartArgs = {
  input?: Maybe<SetShippingMethodsOnCartInput>;
};


export type MutationSubscribeEmailToNewsletterArgs = {
  email: Scalars['String'];
};


export type MutationSubscriberArgs = {
  email?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
};


export type MutationUpdateCartItemsArgs = {
  input?: Maybe<UpdateCartItemsInput>;
};


export type MutationUpdateCustomerArgs = {
  input: CustomerInput;
};


export type MutationUpdateCustomerAddressArgs = {
  id: Scalars['Int'];
  input?: Maybe<CustomerAddressInput>;
};


export type MutationUpdateCustomerEmailArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateCustomerV2Args = {
  input: CustomerUpdateInput;
};


export type MutationUpdateProductsInWishlistArgs = {
  wishlistId: Scalars['ID'];
  wishlistItems: Array<WishlistItemUpdateInput>;
};


export type MutationUpdateSavedBuildNameArgs = {
  input?: Maybe<UpdateSavedBuildNameInput>;
};


export type MutationUpdateWishlistItemArgs = {
  name?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  item_name?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  super_attribute?: Maybe<Array<Maybe<Parameters>>>;
  bundle_option?: Maybe<Array<Maybe<Parameters>>>;
  bundle_option_qty?: Maybe<Array<Maybe<Parameters>>>;
  super_group?: Maybe<Array<Maybe<Parameters>>>;
  links?: Maybe<Array<Maybe<Parameters>>>;
  options?: Maybe<Array<Maybe<Parameters>>>;
};


export type MutationUploadClaimsImageArgs = {
  input: ClaimsFileInput;
};


export type MutationUploadConfiguratorImageArgs = {
  input: ConfiguratorFileInput;
};


export type MutationUploadQuoteConfiguratorImageArgs = {
  input: QuoteConfiguratorFileInput;
};

export type NewsInfo = {
  __typename?: 'NewsInfo';
  news?: Maybe<Array<Maybe<NewsItem>>>;
  unread_news_count?: Maybe<Scalars['Int']>;
  unread_news_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type NewsItem = {
  __typename?: 'NewsItem';
  /** News item content */
  content?: Maybe<Scalars['String']>;
  /** News item entity_id */
  entity_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** Contains an error message when an invalid UID was specified. */
export type NoSuchEntityUidError = ErrorInterface & {
  __typename?: 'NoSuchEntityUidError';
  /** The returned error message. */
  message: Scalars['String'];
  /** The specified invalid unique ID of an object. */
  uid: Scalars['ID'];
};

/** Contains the order ID. */
export type Order = {
  __typename?: 'Order';
  additional_details?: Maybe<Scalars['String']>;
  /** @deprecated Use `order_number` instead. */
  order_id?: Maybe<Scalars['String']>;
  /** The unique ID for an `Order` object. */
  order_number: Scalars['String'];
  project_due_date?: Maybe<Scalars['Float']>;
  project_name?: Maybe<Scalars['String']>;
  timeline?: Maybe<Scalars['String']>;
};

/** Contains detailed information about an order's billing and shipping addresses. */
export type OrderAddress = {
  __typename?: 'OrderAddress';
  /** The city or town. */
  city: Scalars['String'];
  /** The customer's company. */
  company?: Maybe<Scalars['String']>;
  /** The customer's country. */
  country_code?: Maybe<CountryCodeEnum>;
  /** The fax number. */
  fax?: Maybe<Scalars['String']>;
  /** The first name of the person associated with the shipping/billing address. */
  firstname: Scalars['String'];
  /** The family name of the person associated with the shipping/billing address. */
  lastname: Scalars['String'];
  /** The middle name of the person associated with the shipping/billing address. */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's ZIP or postal code. */
  postcode?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** The state or province name. */
  region?: Maybe<Scalars['String']>;
  /** The unique ID for a `Region` object of a pre-defined region. */
  region_id?: Maybe<Scalars['ID']>;
  /** An array of strings that define the street number and name. */
  street: Array<Maybe<Scalars['String']>>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The telephone number. */
  telephone?: Maybe<Scalars['String']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  vat_id?: Maybe<Scalars['String']>;
};

export type OrderItem = OrderItemInterface & {
  __typename?: 'OrderItem';
  /** order item comment */
  comment?: Maybe<Scalars['String']>;
  /** The final discount information for the product. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** order item document_names */
  document_names?: Maybe<Array<Maybe<DocumentNames>>>;
  /** The entered option for the base product, such as a logo or image. */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  item_total: Money;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']>;
  /** URL key of the base product. */
  product_url_key?: Maybe<Scalars['String']>;
  /** The number of canceled items. */
  quantity_canceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items. */
  quantity_invoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item. */
  quantity_ordered?: Maybe<Scalars['Float']>;
  /** The number of refunded items. */
  quantity_refunded?: Maybe<Scalars['Float']>;
  /** The number of returned items. */
  quantity_returned?: Maybe<Scalars['Float']>;
  /** The number of shipped items. */
  quantity_shipped?: Maybe<Scalars['Float']>;
  /** The selected options for the base product, such as color or size. */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item. */
  status?: Maybe<Scalars['String']>;
};

export type OrderItemAttributes = {
  order_id?: Maybe<Scalars['Int']>;
  order_item?: Maybe<Array<Maybe<OrderItemData>>>;
};

export type OrderItemData = {
  comment?: Maybe<Scalars['String']>;
  document_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_sku?: Maybe<Scalars['String']>;
};

/** Order item details */
export type OrderItemInterface = {
  /** order item comment */
  comment?: Maybe<Scalars['String']>;
  /** The final discount information for the product. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** order item document_names */
  document_names?: Maybe<Array<Maybe<DocumentNames>>>;
  /** The entered option for the base product, such as a logo or image. */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  item_total: Money;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']>;
  /** URL key of the base product. */
  product_url_key?: Maybe<Scalars['String']>;
  /** The number of canceled items. */
  quantity_canceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items. */
  quantity_invoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item. */
  quantity_ordered?: Maybe<Scalars['Float']>;
  /** The number of refunded items. */
  quantity_refunded?: Maybe<Scalars['Float']>;
  /** The number of returned items. */
  quantity_returned?: Maybe<Scalars['Float']>;
  /** The number of shipped items. */
  quantity_shipped?: Maybe<Scalars['Float']>;
  /** The selected options for the base product, such as color or size. */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item. */
  status?: Maybe<Scalars['String']>;
};

/** Represents order item options like selected or entered */
export type OrderItemOption = {
  __typename?: 'OrderItemOption';
  /** value with sku, name and grade */
  fabric_finish_value?: Maybe<Scalars['String']>;
  /** The name of the option */
  label: Scalars['String'];
  /** The value of the option */
  value: Scalars['String'];
};

/** Contains details about the payment method used to pay for the order. */
export type OrderPaymentMethod = {
  __typename?: 'OrderPaymentMethod';
  /** Additional data per payment method type. */
  additional_data?: Maybe<Array<Maybe<KeyValue>>>;
  /** The label that describes the payment method. */
  name: Scalars['String'];
  /** The payment method code that indicates how the order was paid for. */
  type: Scalars['String'];
};

/** Contains order shipment details. */
export type OrderShipment = {
  __typename?: 'OrderShipment';
  /** Comments added to the shipment. */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `OrderShipment` object. */
  id: Scalars['ID'];
  /** An array of items included in the shipment. */
  items?: Maybe<Array<Maybe<ShipmentItemInterface>>>;
  /** The sequential credit shipment number. */
  number: Scalars['String'];
  /** An array of shipment tracking details. */
  tracking?: Maybe<Array<Maybe<ShipmentTracking>>>;
};

/** Contains details about the sales total amounts used to calculate the final price. */
export type OrderTotal = {
  __typename?: 'OrderTotal';
  /** The final base grand total amount in the base currency. */
  base_grand_total: Money;
  /** The applied discounts to the order. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes. */
  grand_total: Money;
  /** Details about the shipping and handling costs for the order. */
  shipping_handling?: Maybe<ShippingHandling>;
  /** The subtotal of the order, excluding shipping, discounts, and taxes. */
  subtotal: Money;
  /** The order tax details. */
  taxes?: Maybe<Array<Maybe<TaxItem>>>;
  /** The shipping amount for the order. */
  total_shipping: Money;
  /** The amount of tax applied to the order. */
  total_tax: Money;
};

export type Parameters = {
  id?: Maybe<Scalars['String']>;
  value_string?: Maybe<Scalars['String']>;
};

/** Contains required input for Payflow Express Checkout payments. */
export type PayflowExpressInput = {
  /** The unique ID of the PayPal user. */
  payer_id: Scalars['String'];
  /** The token returned by the createPaypalExpressToken mutation. */
  token: Scalars['String'];
};

/**
 * A set of relative URLs that PayPal uses in response to various actions during
 * the authorization process. Adobe Commerce prepends the base URL to this value to
 * create a full URL. For example, if the full URL is
 * https://www.example.com/path/to/page.html, the relative URL is
 * path/to/page.html. Use this input for Payflow Link and Payments Advanced payment methods.
 */
export type PayflowLinkInput = {
  /**
   * The relative URL of the page that PayPal redirects to when the buyer cancels
   * the transaction in order to choose a different payment method. If the full URL
   * to this page is https://www.example.com/paypal/action/cancel.html, the
   * relative URL is paypal/action/cancel.html.
   */
  cancel_url: Scalars['String'];
  /**
   * The relative URL of the transaction error page that PayPal redirects to upon
   * payment error. If the full URL to this page is
   * https://www.example.com/paypal/action/error.html, the relative URL is
   * paypal/action/error.html.
   */
  error_url: Scalars['String'];
  /**
   * The relative URL of the order confirmation page that PayPal redirects to when
   * the payment is successful and additional confirmation is not needed. If the
   * full URL to this page is https://www.example.com/paypal/action/return.html,
   * the relative URL is paypal/action/return.html.
   */
  return_url: Scalars['String'];
};

/** Indicates the mode for payment. Applies to the Payflow Link and Payments Advanced payment methods. */
export enum PayflowLinkMode {
  Test = 'TEST',
  Live = 'LIVE'
}

/**
 * Contains information used to generate PayPal iframe for transaction. Applies to
 * Payflow Link and Payments Advanced payment methods.
 */
export type PayflowLinkToken = {
  __typename?: 'PayflowLinkToken';
  /** The mode for the Payflow transaction. */
  mode?: Maybe<PayflowLinkMode>;
  /** The PayPal URL used for requesting a Payflow form. */
  paypal_url?: Maybe<Scalars['String']>;
  /** The secure token generated by PayPal. */
  secure_token?: Maybe<Scalars['String']>;
  /** The secure token ID generated by PayPal. */
  secure_token_id?: Maybe<Scalars['String']>;
};

/** Contains information required to fetch payment token information for the Payflow Link and Payments Advanced payment methods. */
export type PayflowLinkTokenInput = {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String'];
};

/** Contains input for the Payflow Pro and Payments Pro payment methods. */
export type PayflowProInput = {
  /** Required input for credit card related information. */
  cc_details: CreditCardDetailsInput;
  /**
   * Indicates whether details about the shopper's credit/debit card should be
   * tokenized for later usage. Required only if Vault is enabled for the PayPal
   * Payflow Pro payment integration.
   */
  is_active_payment_token_enabler?: Maybe<Scalars['Boolean']>;
};

/** Input required to complete payment. Applies to Payflow Pro and Payments Pro payment methods. */
export type PayflowProResponseInput = {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String'];
  /** The payload returned from PayPal. */
  paypal_payload: Scalars['String'];
};

export type PayflowProResponseOutput = {
  __typename?: 'PayflowProResponseOutput';
  /** The cart with the updated selected payment method. */
  cart: Cart;
};

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export type PayflowProToken = {
  __typename?: 'PayflowProToken';
  /** The RESPMSG returned by PayPal. If the `result` is `0`, then `response_message` is `Approved`. */
  response_message: Scalars['String'];
  /** A non-zero value if any errors occurred. */
  result: Scalars['Int'];
  /** The RESULT returned by PayPal. A value of `0` indicates the transaction was approved. */
  result_code: Scalars['Int'];
  /** A secure token generated by PayPal. */
  secure_token: Scalars['String'];
  /** A secure token ID generated by PayPal. */
  secure_token_id: Scalars['String'];
};

/** Contains input required to fetch payment token information for the Payflow Pro and Payments Pro payment methods. */
export type PayflowProTokenInput = {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String'];
  /** A set of relative URLs that PayPal uses for callback. */
  urls: PayflowProUrlInput;
};

/**
 * Contains a set of relative URLs that PayPal uses in response to various actions
 * during the authorization process. Magento prepends the base URL to this value to
 * create a full URL. For example, if the full URL is
 * https://www.example.com/path/to/page.html, the relative URL is
 * path/to/page.html. Use this input for the Payflow Pro and Payment Pro payment methods.
 */
export type PayflowProUrlInput = {
  /**
   * The relative URL of the page that PayPal redirects to when the buyer cancels
   * the transaction in order to choose a different payment method. If the full URL
   * to this page is https://www.example.com/paypal/action/cancel.html, the
   * relative URL is paypal/action/cancel.html.
   */
  cancel_url: Scalars['String'];
  /**
   * The relative URL of the transaction error page that PayPal redirects to upon
   * payment error. If the full URL to this page is
   * https://www.example.com/paypal/action/error.html, the relative URL is
   * paypal/action/error.html.
   */
  error_url: Scalars['String'];
  /**
   * The relative URL of the final confirmation page that PayPal redirects to upon
   * payment success. If the full URL to this page is
   * https://www.example.com/paypal/action/return.html, the relative URL is
   * paypal/action/return.html.
   */
  return_url: Scalars['String'];
};

/** Defines the payment method. */
export type PaymentMethodInput = {
  braintree?: Maybe<BraintreeInput>;
  braintree_cc_vault?: Maybe<BraintreeCcVaultInput>;
  /** The internal name for the payment method. */
  code: Scalars['String'];
  /** Required input for PayPal Hosted pro payments. */
  hosted_pro?: Maybe<HostedProInput>;
  /** Required input for Payflow Express Checkout payments. */
  payflow_express?: Maybe<PayflowExpressInput>;
  /** Required input for PayPal Payflow Link and Payments Advanced payments. */
  payflow_link?: Maybe<PayflowLinkInput>;
  /** Required input for PayPal Payflow Pro and Payment Pro payments. */
  payflowpro?: Maybe<PayflowProInput>;
  /** Required input for PayPal Payflow Pro vault payments. */
  payflowpro_cc_vault?: Maybe<VaultTokenInput>;
  /** Required input for Express Checkout and Payments Standard payments. */
  paypal_express?: Maybe<PaypalExpressInput>;
  /** The purchase order number. Optional for most payment methods. */
  purchase_order_number?: Maybe<Scalars['String']>;
};

/** The stored payment method available to the customer. */
export type PaymentToken = {
  __typename?: 'PaymentToken';
  /** A description of the stored account details. */
  details?: Maybe<Scalars['String']>;
  /** The payment method code associated with the token. */
  payment_method_code: Scalars['String'];
  /** The public hash of the token. */
  public_hash: Scalars['String'];
  /** Specifies the payment token type. */
  type: PaymentTokenTypeEnum;
};

/** The list of available payment token types. */
export enum PaymentTokenTypeEnum {
  /** phpcs:ignore Magento2.GraphQL.ValidArgumentName */
  Card = 'card',
  /** phpcs:ignore Magento2.GraphQL.ValidArgumentName */
  Account = 'account'
}

/** Contains required input for Express Checkout and Payments Standard payments. */
export type PaypalExpressInput = {
  /** The unique ID of the PayPal user. */
  payer_id: Scalars['String'];
  /** The token returned by the `createPaypalExpressToken` mutation. */
  token: Scalars['String'];
};

/** Deprecated. Use `PaypalExpressTokenOutput` instead. */
export type PaypalExpressToken = {
  __typename?: 'PaypalExpressToken';
  /**
   * A set of URLs that allow the buyer to authorize payment and adjust checkout details.
   * @deprecated Use `PaypalExpressTokenOutput.paypal_urls` instead.
   */
  paypal_urls?: Maybe<PaypalExpressUrlList>;
  /**
   * The token returned by PayPal.
   * @deprecated Use `PaypalExpressTokenOutput.token` instead.
   */
  token?: Maybe<Scalars['String']>;
};

/** Defines the attributes required to receive a payment token for Express Checkout and Payments Standard payment methods. */
export type PaypalExpressTokenInput = {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String'];
  /** The payment method code. */
  code: Scalars['String'];
  /** Indicates whether the buyer selected the quick checkout button. The default value is false. */
  express_button?: Maybe<Scalars['Boolean']>;
  /** A set of relative URLs that PayPal uses in response to various actions during the authorization process. */
  urls: PaypalExpressUrlsInput;
  /** Indicates whether the buyer clicked the PayPal credit button. The default value is false. */
  use_paypal_credit?: Maybe<Scalars['Boolean']>;
};

/**
 * Contains the token returned by PayPal and a set of URLs that allow the buyer to
 * authorize payment and adjust checkout details. Applies to Express Checkout and
 * Payments Standard payment methods.
 */
export type PaypalExpressTokenOutput = {
  __typename?: 'PaypalExpressTokenOutput';
  /** A set of URLs that allow the buyer to authorize payment and adjust checkout details. */
  paypal_urls?: Maybe<PaypalExpressUrlList>;
  /** The token returned by PayPal. */
  token?: Maybe<Scalars['String']>;
};

/**
 * Contains a set of URLs that allow the buyer to authorize payment and adjust
 * checkout details for Express Checkout and Payments Standard transactions.
 */
export type PaypalExpressUrlList = {
  __typename?: 'PaypalExpressUrlList';
  /** The PayPal URL that allows the buyer to edit their checkout details. */
  edit?: Maybe<Scalars['String']>;
  /** The URL to the PayPal login page. */
  start?: Maybe<Scalars['String']>;
};

/**
 * Contains a set of relative URLs that PayPal uses in response to various actions
 * during the authorization process. Magento prepends the base URL to this value to
 * create a full URL. For example, if the full URL is
 * https://www.example.com/path/to/page.html, the relative URL is
 * path/to/page.html. Use this input for Express Checkout and Payments Standard
 * payment methods.
 */
export type PaypalExpressUrlsInput = {
  /**
   * The relative URL of the page that PayPal redirects to when the buyer cancels
   * the transaction in order to choose a different payment method. If the full URL
   * to this page is https://www.example.com/paypal/action/cancel.html, the
   * relative URL is paypal/action/cancel.html.
   */
  cancel_url: Scalars['String'];
  /**
   * The relative URL of the page that PayPal redirects to when the payment has
   * been put on hold for additional review. This condition mostly applies to ACH
   * transactions, and is not applicable to most PayPal solutions. If the full URL
   * to this page is https://www.example.com/paypal/action/success_pending.html,
   * the relative URL is paypal/action/success_pending.html.
   */
  pending_url?: Maybe<Scalars['String']>;
  /**
   * The relative URL of the final confirmation page that PayPal redirects to upon
   * payment success. If the full URL to this page is
   * https://www.example.com/paypal/action/return.html, the relative URL is
   * paypal/action/return.html.
   */
  return_url: Scalars['String'];
  /**
   * The relative URL of the order confirmation page that PayPal redirects to when
   * the payment is successful and additional confirmation is not needed. Not
   * applicable to most PayPal solutions. If the full URL to this page is
   * https://www.example.com/paypal/action/success.html, the relative URL is
   * paypal/action/success.html.
   */
  success_url?: Maybe<Scalars['String']>;
};

/** Contains attributes specific to tangible products. */
export type PhysicalProductInterface = {
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
};

/** Defines Pickup Location information. */
export type PickupLocation = {
  __typename?: 'PickupLocation';
  city?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pickup_location_code?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
};

/** PickupLocationFilterInput defines the list of attributes and filters for the search. */
export type PickupLocationFilterInput = {
  /** Filter by city. */
  city?: Maybe<FilterTypeInput>;
  /** Filter by country. */
  country_id?: Maybe<FilterTypeInput>;
  /** Filter by pickup location name. */
  name?: Maybe<FilterTypeInput>;
  /** Filter by pickup location code. */
  pickup_location_code?: Maybe<FilterTypeInput>;
  /** Filter by postcode. */
  postcode?: Maybe<FilterTypeInput>;
  /** Filter by region. */
  region?: Maybe<FilterTypeInput>;
  /** Filter by region id. */
  region_id?: Maybe<FilterTypeInput>;
  /** Filter by street. */
  street?: Maybe<FilterTypeInput>;
};

/** Top level object returned in a pickup locations search. */
export type PickupLocations = {
  __typename?: 'PickupLocations';
  /** An array of pickup locations that match the specific search request. */
  items?: Maybe<Array<Maybe<PickupLocation>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The number of products returned. */
  total_count?: Maybe<Scalars['Int']>;
};

/**
 * PickupLocationSortInput specifies attribute to use for sorting search results
 * and indicates whether the results are sorted in ascending or descending order.
 */
export type PickupLocationSortInput = {
  /** City where pickup location is placed. */
  city?: Maybe<SortEnum>;
  /** Name of the contact person. */
  contact_name?: Maybe<SortEnum>;
  /** Id of the country in two letters. */
  country_id?: Maybe<SortEnum>;
  /** Description of the pickup location. */
  description?: Maybe<SortEnum>;
  /**
   * Distance to the address, requested by distance filter. Applicable only with
   * distance filter. If distance sort order is present, all other sort orders will be ignored.
   */
  distance?: Maybe<SortEnum>;
  /** Contact email of the pickup location. */
  email?: Maybe<SortEnum>;
  /** Contact fax of the pickup location. */
  fax?: Maybe<SortEnum>;
  /** Geographic latitude where pickup location is placed. */
  latitude?: Maybe<SortEnum>;
  /** Geographic longitude where pickup location is placed. */
  longitude?: Maybe<SortEnum>;
  /** The pickup location name. Customer use this to identify the pickup location. */
  name?: Maybe<SortEnum>;
  /** Contact phone number of the pickup location. */
  phone?: Maybe<SortEnum>;
  /** A code assigned to pickup location to identify the source. */
  pickup_location_code?: Maybe<SortEnum>;
  /** Postcode where pickup location is placed. */
  postcode?: Maybe<SortEnum>;
  /** Name of the region. */
  region?: Maybe<SortEnum>;
  /** Id of the region. */
  region_id?: Maybe<SortEnum>;
  /** Street where pickup location is placed. */
  street?: Maybe<SortEnum>;
};

/** Specifies the quote to be converted to an order. */
export type PlaceOrderInput = {
  additional_details?: Maybe<Scalars['String']>;
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  project_due_date: Scalars['Float'];
  project_name: Scalars['String'];
  timeline: Scalars['String'];
};

/** Contains the results of the request to place an order. */
export type PlaceOrderOutput = {
  __typename?: 'PlaceOrderOutput';
  /** The ID of the order. */
  order: Order;
};

/** Deprecated. Use `ProductPrice` instead. Defines the price of a product as well as any tax-related adjustments. */
export type Price = {
  __typename?: 'Price';
  /**
   * An array that provides information about tax, weee, or weee_tax adjustments.
   * @deprecated Use `ProductPrice` instead.
   */
  adjustments?: Maybe<Array<Maybe<PriceAdjustment>>>;
  /**
   * The price of a product plus a three-letter currency code.
   * @deprecated Use `ProductPrice` instead.
   */
  amount?: Maybe<Money>;
};

/**
 * Deprecated. Taxes will be included or excluded in the price. Defines the amount
 * of money to apply as an adjustment, the type of adjustment to apply, and whether
 * the item is included or excluded from the adjustment.
 */
export type PriceAdjustment = {
  __typename?: 'PriceAdjustment';
  /** The amount of the price adjustment and its currency code. */
  amount?: Maybe<Money>;
  /**
   * Indicates whether the adjustment involves tax, weee, or weee_tax.
   * @deprecated `PriceAdjustment` is deprecated.
   */
  code?: Maybe<PriceAdjustmentCodesEnum>;
  /**
   * Indicates whether the entity described by the code attribute is included or excluded from the adjustment.
   * @deprecated `PriceAdjustment` is deprecated.
   */
  description?: Maybe<PriceAdjustmentDescriptionEnum>;
};

/** `PriceAdjustment.code` is deprecated. */
export enum PriceAdjustmentCodesEnum {
  Tax = 'TAX',
  Weee = 'WEEE',
  WeeeTax = 'WEEE_TAX'
}

/** `PriceAdjustmentDescriptionEnum` is deprecated. States whether a price adjustment is included or excluded. */
export enum PriceAdjustmentDescriptionEnum {
  Included = 'INCLUDED',
  Excluded = 'EXCLUDED'
}

export type PriceList = {
  __typename?: 'PriceList';
  name?: Maybe<Scalars['String']>;
  pricelist_id?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<ProductInterface>>>;
};

/** Contains the price range for a product. If the product has a single price, the minimum and maximum price will be the same. */
export type PriceRange = {
  __typename?: 'PriceRange';
  /** The highest possible price for the product. */
  maximum_price?: Maybe<ProductPrice>;
  /** The lowest possible price for the product. */
  minimum_price: ProductPrice;
};

/** Defines the price type. */
export enum PriceTypeEnum {
  Fixed = 'FIXED',
  Percent = 'PERCENT',
  Dynamic = 'DYNAMIC'
}

/** Defines whether a bundle product's price is displayed as the lowest possible value or as a range. */
export enum PriceViewEnum {
  PriceRange = 'PRICE_RANGE',
  AsLowAs = 'AS_LOW_AS'
}

/** Contains a product attribute code and value. */
export type ProductAttribute = {
  __typename?: 'ProductAttribute';
  /** The unique identifier for a product attribute code. */
  code: Scalars['String'];
  /** The display value of the attribute. */
  value: Scalars['String'];
};

/**
 * ProductAttributeFilterInput defines the filters to be used in the search. A
 * filter contains at least one attribute, a comparison operator, and the value
 * that is being searched for.
 */
export type ProductAttributeFilterInput = {
  /** Attribute label: Availability Date */
  availability_date?: Maybe<FilterRangeTypeInput>;
  /** Deprecated: use `category_uid` to filter product by category ID. */
  category_id?: Maybe<FilterEqualTypeInput>;
  /** Filter product by the unique ID for a `CategoryInterface` object. */
  category_uid?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Conf Caster Options */
  conf_caster_options?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Conf Material */
  conf_material?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Conf Wheels Option */
  conf_wheels_option?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Conf Wood Finish */
  conf_wood_finish?: Maybe<FilterEqualTypeInput>;
  created_at?: Maybe<FilterTypeInput>;
  /** Attribute label: Description */
  description?: Maybe<FilterMatchTypeInput>;
  /** Attribute label: ffi_assem_req */
  ffi_assem_req?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffi_checkbox_test_filter */
  ffi_checkbox_test_filter?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffi_collection */
  ffi_collection?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Depth Filter */
  ffi_depth_filter?: Maybe<FilterRangeTypeInput>;
  /** Attribute label: ffi_extdesc2 */
  ffi_extdesc2?: Maybe<FilterMatchTypeInput>;
  /** Attribute label: Height Filter */
  ffi_height_filter?: Maybe<FilterRangeTypeInput>;
  /** Attribute label: Inside Seat Depth Filter */
  ffi_insidestd_filter?: Maybe<FilterRangeTypeInput>;
  /** Attribute label: Inside Seat Width Filter */
  ffi_insidestw_filter?: Maybe<FilterRangeTypeInput>;
  /** Attribute label: ffi_insidestw_test */
  ffi_insidestw_test?: Maybe<FilterRangeTypeInput>;
  /** Attribute label: ffi_isvisible */
  ffi_isvisible?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffi_lanect */
  ffi_lanect?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffi_laners */
  ffi_laners?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffi_lanesl */
  ffi_lanesl?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffi_leatheravl */
  ffi_leatheravl?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Product Type */
  ffi_product_type_filter?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: QTY Filter */
  ffi_qty_filter?: Maybe<FilterRangeTypeInput>;
  /** Attribute label: Inside Seat Height Filter */
  ffi_seath_filter?: Maybe<FilterRangeTypeInput>;
  /** Attribute label: ffi_stdfinish */
  ffi_stdfinish?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Width Filter */
  ffi_width_filter?: Maybe<FilterRangeTypeInput>;
  /** Attribute label: ffi_woodspec1 */
  ffi_woodspec1?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: fficustopt_type_filterable */
  fficustopt_type_filterable?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Fabric/Leather Availability filter */
  ffifabrics_avail_filter?: Maybe<FilterRangeTypeInput>;
  /** Attribute label: ffifabrics_category1 */
  ffifabrics_category1?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffifabrics_collection */
  ffifabrics_collection?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffifabrics_cutyard */
  ffifabrics_cutyard?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffifabrics_dec_cord */
  ffifabrics_dec_cord?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffifabrics_fabric */
  ffifabrics_fabric?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffifabrics_finishtype */
  ffifabrics_finishtype?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffifabrics_fringe */
  ffifabrics_fringe?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffifabrics_grade */
  ffifabrics_grade?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffifabrics_hexclrgrp */
  ffifabrics_hexclrgrp?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffifabrics_leather */
  ffifabrics_leather?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffifabrics_pattern */
  ffifabrics_pattern?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffifabrics_tape */
  ffifabrics_tape?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffifabrics_vendor */
  ffifabrics_vendor?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffifabrics_vinyl */
  ffifabrics_vinyl?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: ffifinish_nailhead */
  ffifinish_nailhead?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Filterable Attribute Set Name */
  filterable_attribute_set_name?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: In Stock Filter */
  in_stock_filter?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Industry filter */
  industry_filter?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Date Filter */
  introdat_filter?: Maybe<FilterRangeTypeInput>;
  /** Attribute label: Is Featured */
  is_featured?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Is new */
  is_new?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Product Name */
  name?: Maybe<FilterMatchTypeInput>;
  /** Filter product by news_from_date. */
  news_from_date?: Maybe<FilterTypeInput>;
  /** Filter product by news_to_date. */
  news_to_date?: Maybe<FilterTypeInput>;
  /** Attribute label: Price */
  price?: Maybe<FilterRangeTypeInput>;
  /** Attribute label: Product Features */
  product_features?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Ready to ship filter */
  ready_to_ship_filter?: Maybe<FilterEqualTypeInput>;
  shop_by_position?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Short Description */
  short_description?: Maybe<FilterMatchTypeInput>;
  /** Attribute label: SKU */
  sku?: Maybe<FilterEqualTypeInput>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<FilterEqualTypeInput>;
};

/**
 * Specifies the attribute to use for sorting search results and indicates whether
 * the results are sorted in ascending or descending order. It's possible to sort
 * products using searchable attributes with enabled 'Use in Filter Options' option
 */
export type ProductAttributeSortInput = {
  /** Position of a product by catalog page */
  catalog_page?: Maybe<SortEnum>;
  /** Sort by introduction date */
  introdat_filter?: Maybe<SortEnum>;
  /** Attribute label: Is Featured */
  is_featured?: Maybe<SortEnum>;
  /** Attribute label: Product Name */
  name?: Maybe<SortEnum>;
  /** Sort by the position assigned to each product. */
  position?: Maybe<SortEnum>;
  /** Attribute label: Price */
  price?: Maybe<SortEnum>;
  /** Sort by the search relevance score (default). */
  relevance?: Maybe<SortEnum>;
  /** Position of a product in the SHOP BY multiselect. */
  shop_by_position?: Maybe<SortEnum>;
  /** Attribute label: SKU */
  sku?: Maybe<SortEnum>;
};

/** Contains the discount applied to a product price. */
export type ProductDiscount = {
  __typename?: 'ProductDiscount';
  /** The actual value of the discount. */
  amount_off?: Maybe<Scalars['Float']>;
  /** The discount expressed a percentage. */
  percent_off?: Maybe<Scalars['Float']>;
};

/**
 * ProductFilterInput is deprecated, use @ProductAttributeFilterInput instead.
 * ProductFilterInput defines the filters to be used in the search. A filter
 * contains at least one attribute, a comparison operator, and the value that is
 * being searched for.
 */
export type ProductFilterInput = {
  /** The category ID the product belongs to. */
  category_id?: Maybe<FilterTypeInput>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<FilterTypeInput>;
  /** The timestamp indicating when the product was created. */
  created_at?: Maybe<FilterTypeInput>;
  /** The name of a custom layout. */
  custom_layout?: Maybe<FilterTypeInput>;
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: Maybe<FilterTypeInput>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<FilterTypeInput>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<FilterTypeInput>;
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: Maybe<FilterTypeInput>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<FilterTypeInput>;
  /** The label assigned to a product image. */
  image_label?: Maybe<FilterTypeInput>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<FilterTypeInput>;
  /** The numeric maximal price of the product. Do not include the currency code. */
  max_price?: Maybe<FilterTypeInput>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<FilterTypeInput>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<FilterTypeInput>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<FilterTypeInput>;
  /** The numeric minimal price of the product. Do not include the currency code. */
  min_price?: Maybe<FilterTypeInput>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<FilterTypeInput>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: Maybe<FilterTypeInput>;
  /** The end date for new product listings. */
  news_to_date?: Maybe<FilterTypeInput>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<FilterTypeInput>;
  /** The keyword required to perform a logical OR comparison. */
  or?: Maybe<ProductFilterInput>;
  /** The price of an item. */
  price?: Maybe<FilterTypeInput>;
  /** Indicates whether the product has required options. */
  required_options?: Maybe<FilterTypeInput>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<FilterTypeInput>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<FilterTypeInput>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<FilterTypeInput>;
  /** The label assigned to a product's small image. */
  small_image_label?: Maybe<FilterTypeInput>;
  /** The beginning date that a product has a special price. */
  special_from_date?: Maybe<FilterTypeInput>;
  /** The discounted price of the product. Do not include the currency code. */
  special_price?: Maybe<FilterTypeInput>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<FilterTypeInput>;
  /** The file name of a swatch image. */
  swatch_image?: Maybe<FilterTypeInput>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<FilterTypeInput>;
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: Maybe<FilterTypeInput>;
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: Maybe<FilterTypeInput>;
  /** The timestamp indicating when the product was updated. */
  updated_at?: Maybe<FilterTypeInput>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<FilterTypeInput>;
  url_path?: Maybe<FilterTypeInput>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<FilterTypeInput>;
};

export type ProductHotspotData = {
  __typename?: 'ProductHotspotData';
  /** Query by content */
  content?: Maybe<Scalars['String']>;
  /** Query by hotspotid */
  hotspot_id?: Maybe<Scalars['String']>;
  /** Query by hotspot image */
  hotspot_image?: Maybe<Scalars['String']>;
};

/** Contains product image information, including the image URL and label. */
export type ProductImage = MediaGalleryInterface & {
  __typename?: 'ProductImage';
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** True or false */
  is_lifestyle?: Maybe<Scalars['String']>;
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** aa */
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>;
};


/** Contains product image information, including the image URL and label. */
export type ProductImageUrlArgs = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

/** Product Information used for Pickup Locations search. */
export type ProductInfoInput = {
  /** Product SKU. */
  sku: Scalars['String'];
};

export type ProductInput = {
  bundle_option?: Maybe<Array<Maybe<Parameters>>>;
  bundle_option_qty?: Maybe<Array<Maybe<Parameters>>>;
  /** Wishlist Item Id */
  item_id?: Maybe<Scalars['Int']>;
  item_name?: Maybe<Scalars['String']>;
  links?: Maybe<Array<Maybe<Parameters>>>;
  options?: Maybe<Array<Maybe<Parameters>>>;
  qty?: Maybe<Scalars['Float']>;
  sku?: Maybe<Scalars['String']>;
  super_attribute?: Maybe<Array<Maybe<Parameters>>>;
  super_group?: Maybe<Array<Maybe<Parameters>>>;
};

/**
 * The ProductInterface contains attributes that are common to all types of
 * products. Note that descriptions may not be available for custom and EAV attributes.
 */
export type ProductInterface = {
  /**
   * Tearsheet PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  assembly_instructions?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  attribute_set_name?: Maybe<Scalars['String']>;
  /**
   * Availability date for the product.
   * @deprecated Use the `custom_attributes` field instead.
   */
  availability_date?: Maybe<Scalars['String']>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Products' is enabled.
   */
  canonical_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  catalog_page?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  /**
   * Order Form PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  collection_overview_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_caster_options?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_wheels_option?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_wood_finish?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_apronh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_armh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_assem_req?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_avails?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_backconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_basefab?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_baselth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_bn_appl?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_casegd?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_casters?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cfgmodel?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_checkbox_test_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_collection?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_countryorg?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cubes?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cupholder?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_depth_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_designer?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_dftfabric?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_dftfinish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_duedate?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_extdesc2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_finish_req?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_finishopt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdepthgh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdeptlow?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimd?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimw?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_fulllftwal?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_fullyuph?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_height_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidebkh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestd?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestd_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw_test?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_introdat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_isvisible?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_lanect?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_laners?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_lanesl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leatheravl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_madeto?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_moistbarr?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_nail_opts?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhigharh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhighovh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhighsth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowarh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowovh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowsth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_onords?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_product_type_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_qty_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_reclindpt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_reclwallcl?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_riser?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seatconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seath?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seath_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_sprgconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_status?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdback?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdcushion?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdfinish?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdpllw1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tablet?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tariff?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tvrecline?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_uph_opts?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_wgtcar?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_wgtwrp?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_width_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_woodspec1?: Maybe<Scalars['Int']>;
  ffi_woodspec1_label?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_finish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_type_filterable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_abrasion?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_avail?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_avail_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_categories?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_category1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_collection?: Maybe<Scalars['String']>;
  ffifabrics_collection_label?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_colorways?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_country?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_cutyard?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_dec_cord?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_descr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_descript?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont9?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct9?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabric?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_finishtype?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fringe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fullcont?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_grade?: Maybe<Array<Maybe<Scalars['String']>>>;
  ffifabrics_grade_configurator_filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_hexclrgrp?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_hrepeat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_leather?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_pattern?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_std_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_tape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vendor?: Maybe<Array<Maybe<Scalars['String']>>>;
  ffifabrics_vendor_image?: Maybe<SwatchValues>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vinyl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vrepeat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_about?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_chm_distr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_cleaning?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_fairshield?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_nailhead?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_phy_distr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_sheen?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  file_upload?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  filterable_attribute_set_name?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  hotspots?: Maybe<Array<Maybe<ProductHotspotData>>>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** Add notification that loged user have added product in downloads in specific store */
  in_downloads?: Maybe<Scalars['String']>;
  /** Image is a part of in_real_life role. */
  in_real_life?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  in_stock_filter?: Maybe<Scalars['Int']>;
  industry_approval_attribute_option?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  introdat_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_featured?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_new?: Maybe<Scalars['Int']>;
  /** Adding NULL for this field because of React core. */
  lead_time?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_image role. */
  lifestyle_image?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_image2 role. */
  lifestyle_image2?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_video role. */
  lifestyle_video?: Maybe<Scalars['String']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer?: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  new_from_date?: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  new_to_date?: Maybe<Scalars['String']>;
  /** Number of throw/kidney pillows */
  num_of_pillows?: Maybe<Scalars['Int']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** The range of prices for the product */
  price_range: PriceRange;
  /** An array of `TierPrice` objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_features?: Maybe<Scalars['String']>;
  product_features_label?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of `ProductLinks` objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** @deprecated Use the `custom_attributes` field instead. */
  ready_to_ship_filter?: Maybe<Scalars['Int']>;
  /** An array of products to be displayed in a Related Products block. */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /**
   * Packing Fee List PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  revit_file?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shop_by_position?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * Thread Board PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  spec_sheet?: Maybe<Scalars['String']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /**
   * The uploaded video.
   * @deprecated Use the `custom_attributes` field instead.
   */
  video_upload?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
};


/**
 * The ProductInterface contains attributes that are common to all types of
 * products. Note that descriptions may not be available for custom and EAV attributes.
 */
export type ProductInterfaceReviewsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

/** An implementation of `ProductLinksInterface`. */
export type ProductLinks = ProductLinksInterface & {
  __typename?: 'ProductLinks';
  /** One of related, associated, upsell, or crosssell. */
  link_type?: Maybe<Scalars['String']>;
  /** The SKU of the linked product. */
  linked_product_sku?: Maybe<Scalars['String']>;
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linked_product_type?: Maybe<Scalars['String']>;
  /** The position within the list of product links. */
  position?: Maybe<Scalars['Int']>;
  /** The identifier of the linked product. */
  sku?: Maybe<Scalars['String']>;
};

/** Contains information about linked products, including the link type and product type of each item. */
export type ProductLinksInterface = {
  /** One of related, associated, upsell, or crosssell. */
  link_type?: Maybe<Scalars['String']>;
  /** The SKU of the linked product. */
  linked_product_sku?: Maybe<Scalars['String']>;
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linked_product_type?: Maybe<Scalars['String']>;
  /** The position within the list of product links. */
  position?: Maybe<Scalars['Int']>;
  /** The identifier of the linked product. */
  sku?: Maybe<Scalars['String']>;
};

/** Contains an image in base64 format and basic information about the image. */
export type ProductMediaGalleryEntriesContent = {
  __typename?: 'ProductMediaGalleryEntriesContent';
  /** The image in base64 format. */
  base64_encoded_data?: Maybe<Scalars['String']>;
  /** The file name of the image. */
  name?: Maybe<Scalars['String']>;
  /** The MIME type of the file, such as image/png. */
  type?: Maybe<Scalars['String']>;
};

/** Contains a link to a video file and basic information about the video. */
export type ProductMediaGalleryEntriesVideoContent = {
  __typename?: 'ProductMediaGalleryEntriesVideoContent';
  /** Must be external-video. */
  media_type?: Maybe<Scalars['String']>;
  /** A description of the video. */
  video_description?: Maybe<Scalars['String']>;
  /** Optional data about the video. */
  video_metadata?: Maybe<Scalars['String']>;
  /** Describes the video source. */
  video_provider?: Maybe<Scalars['String']>;
  /** The title of the video. */
  video_title?: Maybe<Scalars['String']>;
  /** The URL to the video. */
  video_url?: Maybe<Scalars['String']>;
};

/** Represents a product price. */
export type ProductPrice = {
  __typename?: 'ProductPrice';
  /** The price discount. Represents the difference between the regular and final price. */
  discount?: Maybe<ProductDiscount>;
  /** The final price of the product after applying discounts. */
  final_price: Money;
  /** An array of the multiple Fixed Product Taxes that can be applied to a product price. */
  fixed_product_taxes?: Maybe<Array<Maybe<FixedProductTax>>>;
  /** The regular price of the product. */
  regular_price: Money;
};

/**
 * Deprecated. Use `PriceRange` instead. Contains the regular price of an item, as
 * well as its minimum and maximum prices. Only composite products, which include
 * bundle, configurable, and grouped products, can contain a minimum and maximum price.
 */
export type ProductPrices = {
  __typename?: 'ProductPrices';
  /**
   * The highest possible final price for all the options defined within a
   * composite product. If you are specifying a price range, this would be the `to` value.
   * @deprecated Use `PriceRange.maximum_price` instead.
   */
  maximalPrice?: Maybe<Price>;
  /**
   * The lowest possible final price for all the options defined within a composite
   * product. If you are specifying a price range, this would be the `from` value.
   * @deprecated Use `PriceRange.minimum_price` instead.
   */
  minimalPrice?: Maybe<Price>;
  /**
   * The base price of a product.
   * @deprecated Use `regular_price` from `PriceRange.minimum_price` or `PriceRange.maximum_price` instead.
   */
  regularPrice?: Maybe<Price>;
};

/** Contains details of a product review. */
export type ProductReview = {
  __typename?: 'ProductReview';
  /** The average of all ratings for this product. */
  average_rating: Scalars['Float'];
  /** The date the review was created. */
  created_at: Scalars['String'];
  /** The customer's nickname. Defaults to the customer name, if logged in. */
  nickname: Scalars['String'];
  /** The reviewed product. */
  product: ProductInterface;
  /** An array of ratings by rating category, such as quality, price, and value. */
  ratings_breakdown: Array<Maybe<ProductReviewRating>>;
  /** The summary (title) of the review. */
  summary: Scalars['String'];
  /** The review text. */
  text: Scalars['String'];
};

/** Contains data about a single aspect of a product review. */
export type ProductReviewRating = {
  __typename?: 'ProductReviewRating';
  /** The label assigned to an aspect of a product that is being rated, such as quality or price. */
  name: Scalars['String'];
  /** The rating value given by customer. By default, possible values range from 1 to 5. */
  value: Scalars['String'];
};

/** Contains the reviewer's rating for a single aspect of a review. */
export type ProductReviewRatingInput = {
  /** An encoded rating ID. */
  id: Scalars['String'];
  /** An encoded rating value ID. */
  value_id: Scalars['String'];
};

/** Contains details about a single aspect of a product review. */
export type ProductReviewRatingMetadata = {
  __typename?: 'ProductReviewRatingMetadata';
  /** An encoded rating ID. */
  id: Scalars['String'];
  /** The label assigned to an aspect of a product that is being rated, such as quality or price. */
  name: Scalars['String'];
  /** List of product review ratings sorted by position. */
  values: Array<Maybe<ProductReviewRatingValueMetadata>>;
};

/** Contains an array of metadata about each aspect of a product review. */
export type ProductReviewRatingsMetadata = {
  __typename?: 'ProductReviewRatingsMetadata';
  /** An array of product reviews sorted by position. */
  items: Array<Maybe<ProductReviewRatingMetadata>>;
};

/** Contains details about a single value in a product review. */
export type ProductReviewRatingValueMetadata = {
  __typename?: 'ProductReviewRatingValueMetadata';
  /** A ratings scale, such as the number of stars awarded. */
  value: Scalars['String'];
  /** An encoded rating value ID. */
  value_id: Scalars['String'];
};

/** Contains an array of product reviews. */
export type ProductReviews = {
  __typename?: 'ProductReviews';
  /** An array of product reviews. */
  items: Array<Maybe<ProductReview>>;
  /** Metadata for pagination rendering. */
  page_info: SearchResultPageInfo;
};

/** Contains the results of a `products` query. */
export type Products = {
  __typename?: 'Products';
  /** A bucket that contains the attribute code and label for each filterable option. */
  aggregations?: Maybe<Array<Maybe<Aggregation>>>;
  /**
   * Layered navigation filters array.
   * @deprecated Use `aggregations` instead.
   */
  filters?: Maybe<Array<Maybe<LayerFilter>>>;
  /** An array of products that match the specified search criteria. */
  items?: Maybe<Array<Maybe<ProductInterface>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** Get search suggestions data */
  search_suggestions?: Maybe<SearchSuggestions>;
  /** An object that includes the default sort field and all available sort fields. */
  sort_fields?: Maybe<SortFields>;
  /** An array of search suggestions for case when search query have no results. */
  suggestions?: Maybe<Array<Maybe<SearchSuggestion>>>;
  /**
   * The number of products that are marked as visible. By default, in complex
   * products, parent products are visible, but their child products are not.
   */
  total_count?: Maybe<Scalars['Int']>;
};


/** Contains the results of a `products` query. */
export type ProductsAggregationsArgs = {
  filter?: Maybe<AggregationsFilterInput>;
};

/**
 * Deprecated. Use `ProductAttributeSortInput` instead. Specifies the attribute to
 * use for sorting search results and indicates whether the results are sorted in
 * ascending or descending order.
 */
export type ProductSortInput = {
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<SortEnum>;
  /** The timestamp indicating when the product was created. */
  created_at?: Maybe<SortEnum>;
  /** The name of a custom layout. */
  custom_layout?: Maybe<SortEnum>;
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: Maybe<SortEnum>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<SortEnum>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<SortEnum>;
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: Maybe<SortEnum>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<SortEnum>;
  /** The label assigned to a product image. */
  image_label?: Maybe<SortEnum>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<SortEnum>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<SortEnum>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<SortEnum>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<SortEnum>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<SortEnum>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: Maybe<SortEnum>;
  /** The end date for new product listings. */
  news_to_date?: Maybe<SortEnum>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<SortEnum>;
  /** The price of the item. */
  price?: Maybe<SortEnum>;
  /** Indicates whether the product has required options. */
  required_options?: Maybe<SortEnum>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<SortEnum>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<SortEnum>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<SortEnum>;
  /** The label assigned to a product's small image. */
  small_image_label?: Maybe<SortEnum>;
  /** The beginning date that a product has a special price. */
  special_from_date?: Maybe<SortEnum>;
  /** The discounted price of the product. */
  special_price?: Maybe<SortEnum>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<SortEnum>;
  /** Indicates the criteria to sort swatches. */
  swatch_image?: Maybe<SortEnum>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<SortEnum>;
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: Maybe<SortEnum>;
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: Maybe<SortEnum>;
  /** The timestamp indicating when the product was updated. */
  updated_at?: Maybe<SortEnum>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<SortEnum>;
  url_path?: Maybe<SortEnum>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<SortEnum>;
};

/** This enumeration states whether a product stock status is in stock or out of stock */
export enum ProductStockStatus {
  InStock = 'IN_STOCK',
  OutOfStock = 'OUT_OF_STOCK'
}

/**
 * Deprecated. Use `TierPrice` instead. Defines a tier price, which is a quantity
 * discount offered to a specific customer group.
 */
export type ProductTierPrices = {
  __typename?: 'ProductTierPrices';
  /**
   * The ID of the customer group.
   * @deprecated Not relevant for the storefront.
   */
  customer_group_id?: Maybe<Scalars['String']>;
  /**
   * The percentage discount of the item.
   * @deprecated Use `TierPrice.discount` instead.
   */
  percentage_value?: Maybe<Scalars['Float']>;
  /**
   * The number of items that must be purchased to qualify for tier pricing.
   * @deprecated Use `TierPrice.quantity` instead.
   */
  qty?: Maybe<Scalars['Float']>;
  /**
   * The price of the fixed price item.
   * @deprecated Use `TierPrice.final_price` instead.
   */
  value?: Maybe<Scalars['Float']>;
  /**
   * The ID assigned to the website.
   * @deprecated Not relevant for the storefront.
   */
  website_id?: Maybe<Scalars['Float']>;
};

/** Contains information about a product video. */
export type ProductVideo = MediaGalleryInterface & {
  __typename?: 'ProductVideo';
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** True or false */
  is_lifestyle?: Maybe<Scalars['String']>;
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** aa */
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>;
  /** Contains a `ProductMediaGalleryEntriesVideoContent` object. */
  video_content?: Maybe<ProductMediaGalleryEntriesVideoContent>;
};


/** Contains information about a product video. */
export type ProductVideoUrlArgs = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type ProjectDetails = {
  __typename?: 'ProjectDetails';
  additional_details?: Maybe<Scalars['String']>;
  project_due_date?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  timeline?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  anyWish?: Maybe<TotalWishes>;
  /** Get a list of available store views and their config information. */
  availableStores?: Maybe<Array<Maybe<StoreConfig>>>;
  /** Get PWA Cache Version */
  cache?: Maybe<Cache>;
  /** Return information about the specified shopping cart. */
  cart?: Maybe<Cart>;
  /** Return a list of categories that match the specified filter. */
  categories?: Maybe<CategoryResult>;
  /**
   * Search for categories that match the criteria specified in the `search` and `filter` attributes.
   * @deprecated Use `categoryList` instead.
   */
  category?: Maybe<CategoryTree>;
  /** Return an array of categories based on the specified filters. */
  categoryList?: Maybe<Array<Maybe<CategoryTree>>>;
  checkEmail?: Maybe<CheckEmailOutput>;
  /** Return Terms and Conditions configuration information. */
  checkoutAgreements?: Maybe<Array<Maybe<CheckoutAgreement>>>;
  /** Return information about CMS blocks. */
  cmsBlocks?: Maybe<CmsBlocks>;
  /** Return details about a CMS page. */
  cmsPage?: Maybe<CmsPage>;
  /** Return products that have been added to the specified compare list. */
  compareList?: Maybe<CompareList>;
  /** The countries query provides information for all countries. */
  countries?: Maybe<Array<Maybe<Country>>>;
  /** The countries query provides information for a single country. */
  country?: Maybe<Country>;
  /** Return information about the store's currency. */
  currency?: Maybe<Currency>;
  /** Return the attribute type, given an attribute code and entity type. */
  customAttributeMetadata?: Maybe<CustomAttributeMetadata>;
  /** Return detailed information about a customer account. */
  customer?: Maybe<Customer>;
  /** Return information about the customer's shopping cart. */
  customerCart: Cart;
  /** Return a list of downloadable products the customer has purchased. */
  customerDownloadableProducts?: Maybe<CustomerDownloadableProducts>;
  /** @deprecated Use the `customer` query instead. */
  customerOrders?: Maybe<CustomerOrders>;
  /** Return a list of customer payment tokens stored in the vault. */
  customerPaymentTokens?: Maybe<CustomerPaymentTokens>;
  /** Get list of products added to my downloads by the current customer */
  downloadimages?: Maybe<Array<Maybe<ImageDownloads>>>;
  expressRegistration?: Maybe<Scalars['Boolean']>;
  getAllDesigners?: Maybe<Array<Maybe<Designer>>>;
  getAllFiles?: Maybe<Array<Maybe<FileData>>>;
  getAllGalleryData?: Maybe<Array<Maybe<GetGalleryData>>>;
  getAllGalleryDataWithProductInfo?: Maybe<Array<Maybe<GalleryDataWithProductInfo>>>;
  getAllPriceLists?: Maybe<Array<Maybe<PriceList>>>;
  getAllTags?: Maybe<Array<Maybe<TagData>>>;
  /** Get Single Claim */
  getClaim?: Maybe<ClaimOutput>;
  getClaimPdf?: Maybe<Scalars['String']>;
  /** Get Claims */
  getClaims?: Maybe<ClaimsOutput>;
  getClickAction?: Maybe<GetClickAction>;
  getCustomOrderStatuses?: Maybe<Array<Maybe<CustomStatuses>>>;
  getCustomerFirstnameByEmail?: Maybe<CustomerFirstnameByEmailOutput>;
  getCustomerOrderPdf?: Maybe<Scalars['String']>;
  getDesigner?: Maybe<Designer>;
  getDesignerByName?: Maybe<Designer>;
  getDots?: Maybe<Array<Maybe<GetDots>>>;
  /** Query by Fetches FAQ's from Magento. */
  getFAQ?: Maybe<Array<Maybe<FaqCategory>>>;
  getFileData?: Maybe<FileData>;
  /** Get filterable attribute set option data for search filter */
  getFilterableAttributeSetOption?: Maybe<Array<Maybe<FilterableAttribteSetOptions>>>;
  getFirstIcon?: Maybe<GetFirstIcon>;
  getGalleryCategory?: Maybe<GalleryCategoryData>;
  getGalleryData?: Maybe<Array<Maybe<GetGalleryData>>>;
  getGridLayout?: Maybe<GetGridLayout>;
  /** Retrieve the secure PayPal URL for a Payments Pro Hosted Solution transaction. */
  getHostedProUrl?: Maybe<HostedProUrl>;
  getHotspots?: Maybe<Array<Maybe<HotspotData>>>;
  getIconNumber?: Maybe<GetIconNumber>;
  getIndustryApprovalAttribute?: Maybe<Array<Maybe<Scalars['String']>>>;
  getLineOptions?: Maybe<GetLineOptions>;
  /** Get News from Magento. */
  getNewsInfo?: Maybe<NewsInfo>;
  /** Retrieve payment credentials for a transaction. Use this query for Payflow Link and Payments Advanced payment methods. */
  getPayflowLinkToken?: Maybe<PayflowLinkToken>;
  getPriceList?: Maybe<PriceList>;
  getProductDescription?: Maybe<GetProductDescription>;
  getProductDisplay?: Maybe<GetProductDisplay>;
  getProductImage?: Maybe<GetProductImage>;
  getProductInfo?: Maybe<Array<Maybe<GetProductInfo>>>;
  getProductName?: Maybe<GetProductName>;
  getProductPrice?: Maybe<GetProductPrice>;
  getProductsByDesigner?: Maybe<Array<Maybe<ProductInterface>>>;
  getReplocator?: Maybe<ReplocatorOutput>;
  getReplocators?: Maybe<Array<Maybe<ReplocatorOutput>>>;
  getRoomScenes?: Maybe<Array<Maybe<GetGalleryData>>>;
  getSalesRepData?: Maybe<Array<Maybe<SalesRepData>>>;
  getSecondIcon?: Maybe<GetSecondIcon>;
  getStoreLocator?: Maybe<Array<Maybe<GetStoreLocator>>>;
  getTagData?: Maybe<TagData>;
  getTextileAllianceData?: Maybe<TextileAlliance>;
  getTextileAllianceGrades?: Maybe<TextileAllianceGrades>;
  getTextileAllianceLogoData?: Maybe<Array<Maybe<TextileAllianceLogoData>>>;
  getTextileAllianceVendors?: Maybe<TextileAllianceVendors>;
  getTitlePosition?: Maybe<GetTitlePosition>;
  getWhatsNew?: Maybe<Array<Maybe<GetWhatsNew>>>;
  getWishlists?: Maybe<Array<Maybe<WishlistJola>>>;
  /** Check whether the specified email has already been used to create a customer account. */
  isEmailAvailable?: Maybe<IsEmailAvailableOutput>;
  /** Query to Fetch monogram product collection. */
  monogram?: Maybe<Monogram>;
  /** Searches for Best Sellers information matches the filter. */
  mpSmtpBestsellers?: Maybe<SmtpBestsellersOutput>;
  /** The pickup locations query searches for locations that match the search request requirements. */
  pickupLocations?: Maybe<PickupLocations>;
  /** Return the active ratings attributes and the values each rating can have. */
  productReviewRatingsMetadata: ProductReviewRatingsMetadata;
  /** Search for products that match the criteria specified in the `search` and `filter` attributes. */
  products?: Maybe<Products>;
  /** Return the full details for a specified product, category, or CMS page. */
  route?: Maybe<RoutableInterface>;
  /** Return details about the store's configuration. */
  storeConfig?: Maybe<StoreConfig>;
  /**
   * Return the relative URL for a specified product, category or CMS page.
   * @deprecated Use the `route` query instead.
   */
  urlResolver?: Maybe<EntityUrl>;
  /**
   * Return the contents of a customer's wish list.
   * @deprecated Moved under `Customer.wishlist`.
   */
  wishlist?: Maybe<WishlistOutput>;
};


export type QueryAvailableStoresArgs = {
  useCurrentGroup?: Maybe<Scalars['Boolean']>;
};


export type QueryCartArgs = {
  cart_id: Scalars['String'];
};


export type QueryCategoriesArgs = {
  filters?: Maybe<CategoryFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};


export type QueryCategoryArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryCategoryListArgs = {
  filters?: Maybe<CategoryFilterInput>;
};


export type QueryCheckEmailArgs = {
  email: Scalars['String'];
};


export type QueryCmsBlocksArgs = {
  identifiers?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryCmsPageArgs = {
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['String']>;
};


export type QueryCompareListArgs = {
  uid: Scalars['ID'];
};


export type QueryCountryArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryCustomAttributeMetadataArgs = {
  attributes: Array<AttributeInput>;
};


export type QueryDownloadimagesArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<ImageDownloadsFilterInput>;
};


export type QueryExpressRegistrationArgs = {
  account_number?: Maybe<Scalars['String']>;
};


export type QueryGetClaimArgs = {
  claim_id: Scalars['String'];
};


export type QueryGetClaimPdfArgs = {
  claim_id: Scalars['String'];
};


export type QueryGetClaimsArgs = {
  input: ClaimsInput;
};


export type QueryGetCustomerFirstnameByEmailArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryGetCustomerOrderPdfArgs = {
  order_increment_id: Scalars['String'];
};


export type QueryGetDesignerArgs = {
  designer_id?: Maybe<Scalars['Int']>;
};


export type QueryGetDesignerByNameArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryGetDotsArgs = {
  hotspot_id?: Maybe<Scalars['Int']>;
};


export type QueryGetFileDataArgs = {
  file_id?: Maybe<Scalars['String']>;
};


export type QueryGetGalleryCategoryArgs = {
  category_id?: Maybe<Scalars['String']>;
};


export type QueryGetGalleryDataArgs = {
  gallery_id?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetHostedProUrlArgs = {
  input: HostedProUrlInput;
};


export type QueryGetIndustryApprovalAttributeArgs = {
  sku?: Maybe<Scalars['String']>;
};


export type QueryGetPayflowLinkTokenArgs = {
  input: PayflowLinkTokenInput;
};


export type QueryGetPriceListArgs = {
  pricelist_id?: Maybe<Scalars['String']>;
};


export type QueryGetProductInfoArgs = {
  sku?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetProductsByDesignerArgs = {
  designer_id?: Maybe<Scalars['Int']>;
};


export type QueryGetReplocatorArgs = {
  rep_id?: Maybe<Scalars['Int']>;
};


export type QueryGetRoomScenesArgs = {
  product_sku?: Maybe<Scalars['String']>;
};


export type QueryGetTagDataArgs = {
  tag_id?: Maybe<Scalars['String']>;
};


export type QueryGetTextileAllianceDataArgs = {
  input?: Maybe<GradeFilters>;
};


export type QueryGetTextileAllianceGradesArgs = {
  input?: Maybe<SearchGradeFilters>;
};


export type QueryIsEmailAvailableArgs = {
  email: Scalars['String'];
};


export type QueryMpSmtpBestsellersArgs = {
  app_id: Scalars['String'];
  secret_key: Scalars['String'];
  filters?: Maybe<MpFilters>;
};


export type QueryPickupLocationsArgs = {
  area?: Maybe<AreaInput>;
  filters?: Maybe<PickupLocationFilterInput>;
  sort?: Maybe<PickupLocationSortInput>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  productsInfo?: Maybe<Array<Maybe<ProductInfoInput>>>;
};


export type QueryProductsArgs = {
  search?: Maybe<Scalars['String']>;
  filter?: Maybe<ProductAttributeFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  sort?: Maybe<ProductAttributeSortInput>;
  show_all_aggregations?: Maybe<Scalars['Boolean']>;
  force_default?: Maybe<Scalars['Boolean']>;
};


export type QueryRouteArgs = {
  url: Scalars['String'];
};


export type QueryUrlResolverArgs = {
  url: Scalars['String'];
};

export type QuoteConfiguratorFileInput = {
  build_your_own_image?: Maybe<WMediaGalleryEntriesContent>;
};

export type QuoteConfiguratorFileOutput = {
  __typename?: 'QuoteConfiguratorFileOutput';
  build_your_own_image?: Maybe<Scalars['String']>;
};

export type QuoteItemAttributes = {
  quote_id?: Maybe<Scalars['String']>;
  quote_items?: Maybe<Array<Maybe<QuoteItemData>>>;
};

export type QuoteItemData = {
  comment?: Maybe<Scalars['String']>;
  document_names?: Maybe<Array<Maybe<DocumentNamesDataInput>>>;
  item_id?: Maybe<Scalars['Int']>;
};

export type Region = {
  __typename?: 'Region';
  /** The two-letter code for the region, such as TX for Texas. */
  code?: Maybe<Scalars['String']>;
  /** The unique ID for a `Region` object. */
  id?: Maybe<Scalars['Int']>;
  /** The name of the region, such as Texas. */
  name?: Maybe<Scalars['String']>;
};

export type RemoveAllItemsFromCartOutput = {
  __typename?: 'RemoveAllItemsFromCartOutput';
  cart?: Maybe<Cart>;
};

/** Specifies the cart from which to remove a coupon. */
export type RemoveCouponFromCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
};

/** Contains details about the cart after removing a coupon. */
export type RemoveCouponFromCartOutput = {
  __typename?: 'RemoveCouponFromCartOutput';
  /** The cart after removing a coupon. */
  cart?: Maybe<Cart>;
};

/** Specifies which items to remove from the cart. */
export type RemoveItemFromCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: Maybe<Scalars['Int']>;
  /** Required field. The unique ID for a `CartItemInterface` object. */
  cart_item_uid?: Maybe<Scalars['ID']>;
};

/** Contains details about the cart after removing an item. */
export type RemoveItemFromCartOutput = {
  __typename?: 'RemoveItemFromCartOutput';
  /** The cart after removing an item. */
  cart: Cart;
};

export type RemoveItemFromDownloaderOutput = {
  __typename?: 'removeItemFromDownloaderOutput';
  remove_item_from_collection: ImageDownloads;
};

export type RemoveMultipleItemsFromDownloaderOutput = {
  __typename?: 'removeMultipleItemsFromDownloaderOutput';
  remove_multiple_items_from_download?: Maybe<Array<Maybe<ImageDownloads>>>;
};

/** Defines which products to remove from a compare list. */
export type RemoveProductsFromCompareListInput = {
  /** An array of product IDs to remove from the compare list. */
  products: Array<Maybe<Scalars['ID']>>;
  /** The unique identifier of the compare list to modify. */
  uid: Scalars['ID'];
};

/** Contains the customer's wish list and any errors encountered. */
export type RemoveProductsFromWishlistOutput = {
  __typename?: 'RemoveProductsFromWishlistOutput';
  /** An array of errors encountered while deleting products from a wish list. */
  user_errors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with after items were successfully deleted. */
  wishlist: Wishlist;
};

/** Contains the cart and any errors after adding products. */
export type ReorderItemsOutput = {
  __typename?: 'ReorderItemsOutput';
  /** Detailed information about the customer's cart. */
  cart: Cart;
  /** An array of reordering errors. */
  userInputErrors: Array<Maybe<CheckoutUserInputError>>;
};

export type ReplocatorOutput = {
  __typename?: 'ReplocatorOutput';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  lanes?: Maybe<Lanes>;
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  zips?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ResetPassword = {
  __typename?: 'ResetPassword';
  /** Success or Error */
  code: Scalars['String'];
  /** Returned message (success or error message) */
  message: Scalars['String'];
};

export type ResetPasswordRequestInput = {
  /** Email address */
  email: Scalars['String'];
  /** New password */
  password: Scalars['String'];
  /** New password confirmation */
  password_confirm: Scalars['String'];
  /** Reset Password Token provided by a forgot password request */
  token: Scalars['String'];
};

/** Contains the result of a request to revoke a customer token. */
export type RevokeCustomerTokenOutput = {
  __typename?: 'RevokeCustomerTokenOutput';
  /** The result of a request to revoke a customer token. */
  result: Scalars['Boolean'];
};

/** Routable entities serve as the model for a rendered page. */
export type RoutableInterface = {
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirect_code: Scalars['Int'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
};

/** Comment item details */
export type SalesCommentItem = {
  __typename?: 'SalesCommentItem';
  /** The text of the message. */
  message: Scalars['String'];
  /** Current order status in comment */
  status: Scalars['String'];
  /** The timestamp of the comment. */
  timestamp: Scalars['String'];
};

export type SalesItemInterface = {
  __typename?: 'SalesItemInterface';
  /** The entered gift message for the order item */
  gift_message?: Maybe<GiftMessage>;
};

export type SalesRepData = {
  __typename?: 'SalesRepData';
  sales_rep_email?: Maybe<Scalars['String']>;
  sales_rep_name?: Maybe<Scalars['String']>;
};

export type SalesRepresentative = {
  __typename?: 'SalesRepresentative';
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
};

export type SavedBuildInput = {
  bundle_option?: Maybe<Array<Maybe<Parameters>>>;
  bundle_option_qty?: Maybe<Array<Maybe<Parameters>>>;
  item_name: Scalars['String'];
  links?: Maybe<Array<Maybe<Parameters>>>;
  options?: Maybe<Array<Maybe<Parameters>>>;
  qty?: Maybe<Scalars['Float']>;
  sku?: Maybe<Scalars['String']>;
  super_attribute?: Maybe<Array<Maybe<Parameters>>>;
  super_group?: Maybe<Array<Maybe<Parameters>>>;
};

export type SaveReadNewsOutput = {
  __typename?: 'saveReadNewsOutput';
  unread_news_count?: Maybe<Scalars['Int']>;
  unread_news_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type SearchGradeFilters = {
  page_number?: Maybe<Scalars['Int']>;
  page_size?: Maybe<Scalars['Int']>;
};

/** Provides navigation for the query response. */
export type SearchResultPageInfo = {
  __typename?: 'SearchResultPageInfo';
  /** The specific page to return. */
  current_page?: Maybe<Scalars['Int']>;
  /** The maximum number of items to return per page of results. */
  page_size?: Maybe<Scalars['Int']>;
  /** The total number of pages in the response. */
  total_pages?: Maybe<Scalars['Int']>;
};

/** Object that contains search suggestions data. */
export type SearchSuggestion = {
  __typename?: 'SearchSuggestion';
  /** Search suggestion */
  queryText?: Maybe<Scalars['String']>;
  /** Number of search suggestion results. */
  resultsCount?: Maybe<Scalars['String']>;
  /** The search suggestion of existing product. */
  search: Scalars['String'];
};

/** Object that contains list of search suggestions. */
export type SearchSuggestions = {
  __typename?: 'SearchSuggestions';
  /** An array of search suggestions. */
  items?: Maybe<Array<Maybe<SearchSuggestion>>>;
};

/** Contains details about a selected bundle option. */
export type SelectedBundleOption = {
  __typename?: 'SelectedBundleOption';
  /** @deprecated Use `uid` instead */
  id: Scalars['Int'];
  /** The display name of the selected bundle product option. */
  label: Scalars['String'];
  /** The type of selected bundle product option. */
  type: Scalars['String'];
  /** The unique ID for a `SelectedBundleOption` object */
  uid: Scalars['ID'];
  /** An array of selected bundle option values. */
  values: Array<Maybe<SelectedBundleOptionValue>>;
};

/** Contains details about a value for a selected bundle option. */
export type SelectedBundleOptionValue = {
  __typename?: 'SelectedBundleOptionValue';
  /** Use `uid` instead */
  id: Scalars['Int'];
  /** The display name of the value for the selected bundle product option. */
  label: Scalars['String'];
  /** The price of the value for the selected bundle product option. */
  price: Scalars['Float'];
  /** The quantity of the value for the selected bundle product option. */
  quantity: Scalars['Float'];
  /** The unique ID for a `SelectedBundleOptionValue` object */
  uid: Scalars['ID'];
};

/** Contains details about a selected configurable option. */
export type SelectedConfigurableOption = {
  __typename?: 'SelectedConfigurableOption';
  /** The unique ID for a `ConfigurableProductOptions` object. */
  configurable_product_option_uid: Scalars['ID'];
  /** The unique ID for a `ConfigurableProductOptionsValues` object. */
  configurable_product_option_value_uid: Scalars['ID'];
  /** @deprecated Use `SelectedConfigurableOption.configurable_product_option_uid` instead. */
  id: Scalars['Int'];
  /** The display text for the option. */
  option_label: Scalars['String'];
  /** @deprecated Use `SelectedConfigurableOption.configurable_product_option_value_uid` instead. */
  value_id: Scalars['Int'];
  /** The display name of the selected configurable option. */
  value_label: Scalars['String'];
};

/** Identifies a customized product that has been placed in a cart. */
export type SelectedCustomizableOption = {
  __typename?: 'SelectedCustomizableOption';
  /**
   * The unique ID for a specific `CustomizableOptionInterface` object, such as a
   * `CustomizableFieldOption`, `CustomizableFileOption`, or
   * `CustomizableAreaOption` object.
   */
  customizable_option_uid: Scalars['ID'];
  /** @deprecated Use `SelectedCustomizableOption.customizable_option_uid` instead. */
  id: Scalars['Int'];
  /** Indicates whether the customizable option is required. */
  is_required: Scalars['Boolean'];
  /** The display name of the selected customizable option. */
  label: Scalars['String'];
  /** A value indicating the order to display this option. */
  sort_order: Scalars['Int'];
  /** The type of `CustomizableOptionInterface` object. */
  type: Scalars['String'];
  /** An array of selectable values. */
  values: Array<Maybe<SelectedCustomizableOptionValue>>;
};

/** Identifies the value of the selected customized option. */
export type SelectedCustomizableOptionValue = {
  __typename?: 'SelectedCustomizableOptionValue';
  /** The unique ID for a value object that corresponds to the object represented by the `customizable_option_uid` attribute. */
  customizable_option_value_uid: Scalars['ID'];
  /** @deprecated Use `SelectedCustomizableOptionValue.customizable_option_value_uid` instead. */
  id: Scalars['Int'];
  /** The display name of the selected value. */
  label: Scalars['String'];
  /** The price of the selected customizable value. */
  price: CartItemSelectedOptionValuePrice;
  /** The text identifying the selected value. */
  value: Scalars['String'];
};

/** Describes the payment method the shopper selected. */
export type SelectedPaymentMethod = {
  __typename?: 'SelectedPaymentMethod';
  /** The payment method code. */
  code: Scalars['String'];
  /** The purchase order number. */
  purchase_order_number?: Maybe<Scalars['String']>;
  /** The payment method title. */
  title: Scalars['String'];
};

/** Contains details about the selected shipping method and carrier. */
export type SelectedShippingMethod = {
  __typename?: 'SelectedShippingMethod';
  /** The cost of shipping using this shipping method. */
  amount: Money;
  /** @deprecated The field should not be used on the storefront. */
  base_amount?: Maybe<Money>;
  /** A string that identifies a commercial carrier or an offline shipping method. */
  carrier_code: Scalars['String'];
  /** The label for the carrier code. */
  carrier_title: Scalars['String'];
  /** A shipping method code associated with a carrier. */
  method_code: Scalars['String'];
  /** The label for the method code. */
  method_title: Scalars['String'];
};

export type SendEmailAttribute = {
  /** Attribute code */
  code?: Maybe<Scalars['String']>;
  /** Attribute value */
  value?: Maybe<Scalars['String']>;
};

export type SendEmailInput = {
  /** Custom form attributes attributes. */
  attributes?: Maybe<Array<Maybe<SendEmailAttribute>>>;
  /** Populate string with yes or no if its return form endpoint */
  return_form?: Maybe<Scalars['Boolean']>;
  /** Email template identifier. */
  template?: Maybe<Scalars['String']>;
};

/** Defines the referenced product and the email sender and recipients. */
export type SendEmailToFriendInput = {
  /** The ID of the product that the sender is referencing. */
  product_id: Scalars['Int'];
  /** An array containing information about each recipient. */
  recipients: Array<Maybe<SendEmailToFriendRecipientInput>>;
  /** Information about the customer and the content of the message. */
  sender: SendEmailToFriendSenderInput;
};

/** Contains information about the sender and recipients. */
export type SendEmailToFriendOutput = {
  __typename?: 'SendEmailToFriendOutput';
  /** An array containing information about each recipient. */
  recipients?: Maybe<Array<Maybe<SendEmailToFriendRecipient>>>;
  /** Information about the customer and the content of the message. */
  sender?: Maybe<SendEmailToFriendSender>;
};

/** An output object that contains information about the recipient. */
export type SendEmailToFriendRecipient = {
  __typename?: 'SendEmailToFriendRecipient';
  /** The email address of the recipient. */
  email: Scalars['String'];
  /** The name of the recipient. */
  name: Scalars['String'];
};

/** Contains details about a recipient. */
export type SendEmailToFriendRecipientInput = {
  /** The email address of the recipient. */
  email: Scalars['String'];
  /** The name of the recipient. */
  name: Scalars['String'];
};

/** An output object that contains information about the sender. */
export type SendEmailToFriendSender = {
  __typename?: 'SendEmailToFriendSender';
  /** The email address of the sender. */
  email: Scalars['String'];
  /** The text of the message to be sent. */
  message: Scalars['String'];
  /** The name of the sender. */
  name: Scalars['String'];
};

/** Contains details about the sender. */
export type SendEmailToFriendSenderInput = {
  /** The email address of the sender. */
  email: Scalars['String'];
  /** The text of the message to be sent. */
  message: Scalars['String'];
  /** The name of the sender. */
  name: Scalars['String'];
};

/** Contains details about the configuration of the Email to a Friend feature. */
export type SendFriendConfiguration = {
  __typename?: 'SendFriendConfiguration';
  /** Indicates whether the Email to a Friend feature is enabled. */
  enabled_for_customers: Scalars['Boolean'];
  /** Indicates whether the Email to a Friend feature is enabled for guests. */
  enabled_for_guests: Scalars['Boolean'];
};

/** Sets the billing address. */
export type SetBillingAddressOnCartInput = {
  /** The billing address. */
  billing_address: BillingAddressInput;
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
};

/** Contains details about the cart after setting the billing address. */
export type SetBillingAddressOnCartOutput = {
  __typename?: 'SetBillingAddressOnCartOutput';
  /** The cart after setting the billing address. */
  cart: Cart;
};

/** Defines the guest email and cart. */
export type SetGuestEmailOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** The email address of the guest. */
  email: Scalars['String'];
};

/** Contains details about the cart after setting the email of a guest. */
export type SetGuestEmailOnCartOutput = {
  __typename?: 'SetGuestEmailOnCartOutput';
  /** The cart after setting the guest email. */
  cart: Cart;
};

/** Applies a payment method to the quote. */
export type SetPaymentMethodAndPlaceOrderInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** The payment method data to apply to the cart. */
  payment_method: PaymentMethodInput;
};

/** Applies a payment method to the cart. */
export type SetPaymentMethodOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** The payment method data to apply to the cart. */
  payment_method: PaymentMethodInput;
};

/** Contains details about the cart after setting the payment method. */
export type SetPaymentMethodOnCartOutput = {
  __typename?: 'SetPaymentMethodOnCartOutput';
  /** The cart after setting the payment method. */
  cart: Cart;
};

/** Specifies an array of addresses to use for shipping. */
export type SetShippingAddressesOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of shipping addresses. */
  shipping_addresses: Array<Maybe<ShippingAddressInput>>;
};

/** Contains details about the cart after setting the shipping addresses. */
export type SetShippingAddressesOnCartOutput = {
  __typename?: 'SetShippingAddressesOnCartOutput';
  /** The cart after setting the shipping addresses. */
  cart: Cart;
};

/** Applies one or shipping methods to the cart. */
export type SetShippingMethodsOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of shipping methods. */
  shipping_methods: Array<Maybe<ShippingMethodInput>>;
};

/** Contains details about the cart after setting the shipping methods. */
export type SetShippingMethodsOnCartOutput = {
  __typename?: 'SetShippingMethodsOnCartOutput';
  /** The cart after setting the shipping methods. */
  cart: Cart;
};

/** Defines whether bundle items must be shipped together. */
export enum ShipBundleItemsEnum {
  Together = 'TOGETHER',
  Separately = 'SEPARATELY'
}

export type ShipmentItem = ShipmentItemInterface & {
  __typename?: 'ShipmentItem';
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID'];
  /** The order item associated with the shipment item. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of shipped items. */
  quantity_shipped: Scalars['Float'];
};

/** Order shipment item details. */
export type ShipmentItemInterface = {
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID'];
  /** The order item associated with the shipment item. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of shipped items. */
  quantity_shipped: Scalars['Float'];
};

/** Contains order shipment tracking details. */
export type ShipmentTracking = {
  __typename?: 'ShipmentTracking';
  /** The shipping carrier for the order delivery. */
  carrier: Scalars['String'];
  /** The tracking number of the order shipment. */
  number?: Maybe<Scalars['String']>;
  /** The shipment tracking title. */
  title: Scalars['String'];
};

/** Defines a single shipping address. */
export type ShippingAddressInput = {
  /** Defines a shipping address. */
  address?: Maybe<CartAddressInput>;
  /** An ID from the customer's address book that uniquely identifies the address to be used for shipping. */
  customer_address_id?: Maybe<Scalars['Int']>;
  /** Text provided by the shopper. */
  customer_notes?: Maybe<Scalars['String']>;
  /** The code of Pickup Location which will be used for In-Store Pickup. */
  pickup_location_code?: Maybe<Scalars['String']>;
};

/** Contains shipping addresses and methods. */
export type ShippingCartAddress = CartAddressInterface & {
  __typename?: 'ShippingCartAddress';
  /** An array that lists the shipping methods that can be applied to the cart. */
  available_shipping_methods?: Maybe<Array<Maybe<AvailableShippingMethod>>>;
  /** @deprecated Use `cart_items_v2` instead. */
  cart_items?: Maybe<Array<Maybe<CartItemQuantity>>>;
  /** An array that lists the items in the cart. */
  cart_items_v2?: Maybe<Array<Maybe<CartItemInterface>>>;
  /** The city specified for the billing or shipping address. */
  city: Scalars['String'];
  /** The company specified for the billing or shipping address. */
  company?: Maybe<Scalars['String']>;
  /** An object containing the country label and code. */
  country: CartAddressCountry;
  /** Text provided by the shopper. */
  customer_notes?: Maybe<Scalars['String']>;
  /** The first name of the customer or guest. */
  firstname: Scalars['String'];
  /** @deprecated This information should not be exposed on the frontend. */
  items_weight?: Maybe<Scalars['Float']>;
  /** The last name of the customer or guest. */
  lastname: Scalars['String'];
  order_email?: Maybe<Scalars['String']>;
  pickup_location_code?: Maybe<Scalars['String']>;
  /** The ZIP or postal code of the billing or shipping address. */
  postcode?: Maybe<Scalars['String']>;
  /** An object containing the region label and code. */
  region?: Maybe<CartAddressRegion>;
  /** An object that describes the selected shipping method. */
  selected_shipping_method?: Maybe<SelectedShippingMethod>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']>>;
  /** The telephone number for the billing or shipping address. */
  telephone?: Maybe<Scalars['String']>;
};

/** Defines an individual shipping discount. This discount can be applied to shipping. */
export type ShippingDiscount = {
  __typename?: 'ShippingDiscount';
  /** The amount of the discount. */
  amount: Money;
};

/** Contains details about shipping and handling costs. */
export type ShippingHandling = {
  __typename?: 'ShippingHandling';
  /** The shipping amount, excluding tax. */
  amount_excluding_tax?: Maybe<Money>;
  /** The shipping amount, including tax. */
  amount_including_tax?: Maybe<Money>;
  /** The applied discounts to the shipping. */
  discounts?: Maybe<Array<Maybe<ShippingDiscount>>>;
  /** Details about taxes applied for shipping. */
  taxes?: Maybe<Array<Maybe<TaxItem>>>;
  /** The total amount for shipping. */
  total_amount: Money;
};

/** Defines the shipping carrier and method. */
export type ShippingMethodInput = {
  /** A string that identifies a commercial carrier or an offline delivery method. */
  carrier_code: Scalars['String'];
  /**
   * A string that indicates which service a commercial carrier will use to ship
   * items. For offline delivery methods, this value is similar to the label
   * displayed on the checkout page.
   */
  method_code: Scalars['String'];
};

/** An implementation for simple product cart items. */
export type SimpleCartItem = CartItemInterface & {
  __typename?: 'SimpleCartItem';
  /** cart item comment */
  comment?: Maybe<Scalars['String']>;
  /** An array containing the customizable options the shopper selected. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** cart item document_names */
  document_names?: Maybe<Array<Maybe<DocumentNamesData>>>;
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<CartItemError>>>;
  /** The entered gift message for the cart item */
  gift_message?: Maybe<GiftMessage>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export type SimpleProduct = ProductInterface & RoutableInterface & PhysicalProductInterface & CustomizableProductInterface & {
  __typename?: 'SimpleProduct';
  /**
   * Tearsheet PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  assembly_instructions?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  attribute_set_name?: Maybe<Scalars['String']>;
  /**
   * Availability date for the product.
   * @deprecated Use the `custom_attributes` field instead.
   */
  availability_date?: Maybe<Scalars['String']>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Products' is enabled.
   */
  canonical_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  catalog_page?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  /**
   * Order Form PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  collection_overview_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_caster_options?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_wheels_option?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_wood_finish?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_apronh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_armh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_assem_req?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_avails?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_backconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_basefab?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_baselth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_bn_appl?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_casegd?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_casters?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cfgmodel?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_checkbox_test_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_collection?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_countryorg?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cubes?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cupholder?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_depth_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_designer?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_dftfabric?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_dftfinish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_duedate?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_extdesc2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_finish_req?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_finishopt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdepthgh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdeptlow?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimd?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimw?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_fulllftwal?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_fullyuph?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_height_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidebkh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestd?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestd_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw_test?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_introdat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_isvisible?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_lanect?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_laners?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_lanesl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leatheravl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_madeto?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_moistbarr?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_nail_opts?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhigharh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhighovh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhighsth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowarh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowovh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowsth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_onords?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_product_type_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_qty_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_reclindpt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_reclwallcl?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_riser?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seatconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seath?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seath_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_sprgconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_status?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdback?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdcushion?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdfinish?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdpllw1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tablet?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tariff?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tvrecline?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_uph_opts?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_wgtcar?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_wgtwrp?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_width_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_woodspec1?: Maybe<Scalars['Int']>;
  ffi_woodspec1_label?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_finish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_type_filterable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_abrasion?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_avail?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_avail_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_categories?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_category1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_collection?: Maybe<Scalars['String']>;
  ffifabrics_collection_label?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_colorways?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_country?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_cutyard?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_dec_cord?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_descr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_descript?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont9?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct9?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabric?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_finishtype?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fringe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fullcont?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_grade?: Maybe<Array<Maybe<Scalars['String']>>>;
  ffifabrics_grade_configurator_filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_hexclrgrp?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_hrepeat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_leather?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_pattern?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_std_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_tape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vendor?: Maybe<Array<Maybe<Scalars['String']>>>;
  ffifabrics_vendor_image?: Maybe<SwatchValues>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vinyl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vrepeat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_about?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_chm_distr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_cleaning?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_fairshield?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_nailhead?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_phy_distr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_sheen?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  file_upload?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  filterable_attribute_set_name?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  hotspots?: Maybe<Array<Maybe<ProductHotspotData>>>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** Add notification that loged user have added product in downloads in specific store */
  in_downloads?: Maybe<Scalars['String']>;
  /** Image is a part of in_real_life role. */
  in_real_life?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  in_stock_filter?: Maybe<Scalars['Int']>;
  industry_approval_attribute_option?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  introdat_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_featured?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_new?: Maybe<Scalars['Int']>;
  /** Adding NULL for this field because of React core. */
  lead_time?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_image role. */
  lifestyle_image?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_image2 role. */
  lifestyle_image2?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_video role. */
  lifestyle_video?: Maybe<Scalars['String']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer?: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  new_from_date?: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  new_to_date?: Maybe<Scalars['String']>;
  /** Number of throw/kidney pillows */
  num_of_pillows?: Maybe<Scalars['Int']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** The range of prices for the product */
  price_range: PriceRange;
  /** An array of `TierPrice` objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_features?: Maybe<Scalars['String']>;
  product_features_label?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of `ProductLinks` objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** @deprecated Use the `custom_attributes` field instead. */
  ready_to_ship_filter?: Maybe<Scalars['Int']>;
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirect_code: Scalars['Int'];
  /** An array of products to be displayed in a Related Products block. */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /**
   * Packing Fee List PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  revit_file?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shop_by_position?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * Thread Board PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  spec_sheet?: Maybe<Scalars['String']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /**
   * The uploaded video.
   * @deprecated Use the `custom_attributes` field instead.
   */
  video_upload?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
};


/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export type SimpleProductReviewsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

/** Defines a single product to add to the cart. */
export type SimpleProductCartItemInput = {
  /** An array that defines customizable options for the product. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** An object containing the `sku`, `quantity`, and other relevant information about the product. */
  data: CartItemInput;
};

/** Contains a simple product wish list item. */
export type SimpleWishlistItem = WishlistItemInterface & {
  __typename?: 'SimpleWishlistItem';
  /** The date and time the item was added to the wish list. */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
};

export type SingleClaimItem = {
  __typename?: 'SingleClaimItem';
  acknowledgement_number: Scalars['String'];
  can_be_kept_at_discount?: Maybe<Scalars['String']>;
  can_be_repaired_locally: Scalars['String'];
  claim_damage_type: Scalars['String'];
  claim_type: Scalars['String'];
  damage_description: Scalars['String'];
  item_description: Scalars['String'];
  local_repair_quote?: Maybe<Scalars['String']>;
  original_carton: Scalars['String'];
  packaging_type: Scalars['String'];
  photo_upload: Scalars['String'];
  quantity: Scalars['String'];
  style_number: Scalars['String'];
  where_is_the_item_located: Scalars['String'];
};

export type Skus = {
  skus: Array<Maybe<Scalars['String']>>;
};

export type SmtpBestsellersOutput = {
  __typename?: 'SmtpBestsellersOutput';
  /** Bestsellers Product Information. */
  mpBestsellers?: Maybe<Array<Maybe<BestsellersProductOutput>>>;
};

/** Indicates whether to return results in ascending or descending order. */
export enum SortEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Defines a possible sort field. */
export type SortField = {
  __typename?: 'SortField';
  /** The label of the sort field. */
  label?: Maybe<Scalars['String']>;
  /** The attribute code of the sort field. */
  value?: Maybe<Scalars['String']>;
};

/** Contains a default value for sort fields and all available sort fields. */
export type SortFields = {
  __typename?: 'SortFields';
  /** The default sort field value. */
  default?: Maybe<Scalars['String']>;
  /** An array of possible sort fields. */
  options?: Maybe<Array<Maybe<SortField>>>;
};

/** The type contains information about a store config. */
export type StoreConfig = {
  __typename?: 'StoreConfig';
  /** Contains scripts that must be included in the HTML before the closing `<body>` tag. */
  absolute_footer?: Maybe<Scalars['String']>;
  /** Indicates whether guest users can write product reviews. Possible values: 1 (Yes) and 0 (No). */
  allow_guests_to_write_product_reviews?: Maybe<Scalars['String']>;
  /** The value of the Allow Gift Messages for Order Items option */
  allow_items?: Maybe<Scalars['String']>;
  /** The value of the Allow Gift Messages on Order Level option */
  allow_order?: Maybe<Scalars['String']>;
  /** Indicates whether to enable autocomplete on login and forgot password forms. */
  autocomplete_on_storefront?: Maybe<Scalars['Boolean']>;
  /** The base currency code. */
  base_currency_code?: Maybe<Scalars['String']>;
  /** A fully-qualified URL that is used to create relative links to the `base_url`. */
  base_link_url?: Maybe<Scalars['String']>;
  /** The fully-qualified URL that specifies the location of media files. */
  base_media_url?: Maybe<Scalars['String']>;
  /** The fully-qualified URL that specifies the location of static view files. */
  base_static_url?: Maybe<Scalars['String']>;
  /** The store’s fully-qualified base URL. */
  base_url?: Maybe<Scalars['String']>;
  /** Braintree cc vault status. */
  braintree_cc_vault_active?: Maybe<Scalars['String']>;
  /** The default sort order of the search results list. */
  catalog_default_sort_by?: Maybe<Scalars['String']>;
  /**
   * Corresponds to the 'Display Prices In Product Lists' field in the Admin. It
   * indicates how FPT information is displayed on category pages.
   */
  category_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>;
  /** The suffix applied to category pages, such as `.htm` or `.html`. */
  category_url_suffix?: Maybe<Scalars['String']>;
  /** Indicates whether only specific countries can use this payment method. */
  check_money_order_enable_for_specific_countries?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the Check/Money Order payment method is enabled. */
  check_money_order_enabled?: Maybe<Scalars['Boolean']>;
  /** The name of the party to whom the check must be payable. */
  check_money_order_make_check_payable_to?: Maybe<Scalars['String']>;
  /** The maximum order amount required to qualify for the Check/Money Order payment method. */
  check_money_order_max_order_total?: Maybe<Scalars['String']>;
  /** The minimum order amount required to qualify for the Check/Money Order payment method. */
  check_money_order_min_order_total?: Maybe<Scalars['String']>;
  /** The status of new orders placed using the Check/Money Order payment method. */
  check_money_order_new_order_status?: Maybe<Scalars['String']>;
  /** A comma-separated list of specific countries allowed to use the Check/Money Order payment method. */
  check_money_order_payment_from_specific_countries?: Maybe<Scalars['String']>;
  /** The full street address or PO Box where the checks are mailed. */
  check_money_order_send_check_to?: Maybe<Scalars['String']>;
  /**
   * A number indicating the position of the Check/Money Order payment method in
   * the list of available payment methods during checkout.
   */
  check_money_order_sort_order?: Maybe<Scalars['Int']>;
  /** The title of the Check/Money Order payment method displayed on the storefront. */
  check_money_order_title?: Maybe<Scalars['String']>;
  /** The name of the CMS page that identifies the home page for the store. */
  cms_home_page?: Maybe<Scalars['String']>;
  /** A specific CMS page that displays when cookies are not enabled for the browser. */
  cms_no_cookies?: Maybe<Scalars['String']>;
  /** A specific CMS page that displays when a 404 'Page Not Found' error occurs. */
  cms_no_route?: Maybe<Scalars['String']>;
  /**
   * A code assigned to the store to identify it.
   * @deprecated Use `store_code` instead.
   */
  code?: Maybe<Scalars['String']>;
  /** Indicates whether the `parent` or child (`itself`) thumbnail should be used in the cart for configurable products. */
  configurable_thumbnail_source?: Maybe<Scalars['String']>;
  /** The copyright statement that appears at the bottom of each page. */
  copyright?: Maybe<Scalars['String']>;
  /**
   * The description that provides a summary of your site for search engine
   * listings. It should not be more than 160 characters in length.
   */
  default_description?: Maybe<Scalars['String']>;
  /** The default display currency code. */
  default_display_currency_code?: Maybe<Scalars['String']>;
  /** A series of keywords that describe your store, each separated by a comma. */
  default_keywords?: Maybe<Scalars['String']>;
  /** The title that appears at the title bar of each page when viewed in a browser. */
  default_title?: Maybe<Scalars['String']>;
  /** Controls the display of the demo store notice at the top of the page. Options: 0 (No) or 1 (Yes). */
  demonotice?: Maybe<Scalars['Int']>;
  /** The landing page that is associated with the base URL. */
  front?: Maybe<Scalars['String']>;
  /** Query by general_general_module_status. */
  general_general_module_status?: Maybe<Scalars['String']>;
  google_api_key?: Maybe<Scalars['String']>;
  /** The default number of products per page in Grid View. */
  grid_per_page?: Maybe<Scalars['Int']>;
  /** A list of numbers that define how many products can be displayed in Grid View. */
  grid_per_page_values?: Maybe<Scalars['String']>;
  /** Scripts that must be included in the HTML before the closing `<head>` tag. */
  head_includes?: Maybe<Scalars['String']>;
  /** The small graphic image (favicon) that appears in the address bar and tab of the browser. */
  head_shortcut_icon?: Maybe<Scalars['String']>;
  /** The path to the logo that appears in the header. */
  header_logo_src?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the store.
   * @deprecated Use `store_code` instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** Indicates whether the store view has been designated as the default within the store group. */
  is_default_store?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the store group has been designated as the default within the website. */
  is_default_store_group?: Maybe<Scalars['Boolean']>;
  /** The format of the search results list. */
  list_mode?: Maybe<Scalars['String']>;
  /** The default number of products per page in List View. */
  list_per_page?: Maybe<Scalars['Int']>;
  /** A list of numbers that define how many products can be displayed in List View. */
  list_per_page_values?: Maybe<Scalars['String']>;
  /** The store locale. */
  locale?: Maybe<Scalars['String']>;
  /** The Alt text that is associated with the logo. */
  logo_alt?: Maybe<Scalars['String']>;
  /** The height of the logo image, in pixels. */
  logo_height?: Maybe<Scalars['Int']>;
  /** The width of the logo image, in pixels. */
  logo_width?: Maybe<Scalars['Int']>;
  /** Indicates whether wishlists are enabled (1) or disabled (0). */
  magento_wishlist_general_is_enabled?: Maybe<Scalars['String']>;
  matterport_url?: Maybe<Scalars['String']>;
  /** The minimum number of characters required for a valid password. */
  minimum_password_length?: Maybe<Scalars['String']>;
  /** The default page that displays when a 404 'Page not Found' error occurs. */
  no_route?: Maybe<Scalars['String']>;
  /** Payflow Pro vault status. */
  payment_payflowpro_cc_vault_active?: Maybe<Scalars['String']>;
  /**
   * Corresponds to the 'Display Prices On Product View Page' field in the Admin.
   * It indicates how FPT information is displayed on product pages.
   */
  product_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>;
  /** Indicates whether product reviews are enabled. Possible values: 1 (Yes) and 0 (No). */
  product_reviews_enabled?: Maybe<Scalars['String']>;
  /** The suffix applied to product pages, such as `.htm` or `.html`. */
  product_url_suffix?: Maybe<Scalars['String']>;
  /** The number of different character classes (lowercase, uppercase, digits, special characters) required in a password. */
  required_character_classes_number?: Maybe<Scalars['String']>;
  /**
   * The ID of the root category.
   * @deprecated Use `root_category_uid` instead.
   */
  root_category_id?: Maybe<Scalars['Int']>;
  /** The unique ID for a `CategoryInterface` object. */
  root_category_uid?: Maybe<Scalars['ID']>;
  /**
   * Corresponds to the 'Display Prices In Sales Modules' field in the Admin. It
   * indicates how FPT information is displayed on cart, checkout, and order pages.
   */
  sales_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>;
  /** A secure fully-qualified URL that is used to create relative links to the `base_url`. */
  secure_base_link_url?: Maybe<Scalars['String']>;
  /** The secure fully-qualified URL that specifies the location of media files. */
  secure_base_media_url?: Maybe<Scalars['String']>;
  /** The secure fully-qualified URL that specifies the location of static view files. */
  secure_base_static_url?: Maybe<Scalars['String']>;
  /** The store’s fully-qualified secure base URL. */
  secure_base_url?: Maybe<Scalars['String']>;
  /** Email to a Friend configuration. */
  send_friend?: Maybe<SendFriendConfiguration>;
  /** Indicates whether a breadcrumb trail appears on all CMS pages in the catalog. 0 (No) or 1 (Yes). */
  show_cms_breadcrumbs?: Maybe<Scalars['Int']>;
  /**
   * The unique ID of the store view. In the Admin, this is called the Store View
   * Code. When making a GraphQL call, assign this value to the `Store` header to
   * provide the scope.
   */
  store_code?: Maybe<Scalars['ID']>;
  /** The unique ID assigned to the store group. In the Admin, this is called the Store Name. */
  store_group_code?: Maybe<Scalars['ID']>;
  /** The label assigned to the store group. */
  store_group_name?: Maybe<Scalars['String']>;
  /** The label assigned to the store view. */
  store_name?: Maybe<Scalars['String']>;
  /** The store view sort order. */
  store_sort_order?: Maybe<Scalars['Int']>;
  /** The time zone of the store. */
  timezone?: Maybe<Scalars['String']>;
  /** A prefix that appears before the title to create a two- or three-part title. */
  title_prefix?: Maybe<Scalars['String']>;
  /** The character that separates the category name and subcategory in the browser title bar. */
  title_separator?: Maybe<Scalars['String']>;
  /** A suffix that appears after the title to create a two- or three-part title. */
  title_suffix?: Maybe<Scalars['String']>;
  /** Indicates whether the store code should be used in the URL. */
  use_store_in_url?: Maybe<Scalars['Boolean']>;
  /** The unique ID for the website. */
  website_code?: Maybe<Scalars['ID']>;
  /**
   * The ID number assigned to the website store.
   * @deprecated The field should not be used on the storefront.
   */
  website_id?: Maybe<Scalars['Int']>;
  /** The label assigned to the website. */
  website_name?: Maybe<Scalars['String']>;
  /** The unit of weight. */
  weight_unit?: Maybe<Scalars['String']>;
  /** Text that appears in the header of the page and includes the name of the logged in customer. */
  welcome?: Maybe<Scalars['String']>;
  /** Indicates whether only specific countries can use this payment method. */
  zero_subtotal_enable_for_specific_countries?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the Zero Subtotal payment method is enabled. */
  zero_subtotal_enabled?: Maybe<Scalars['Boolean']>;
  /** The status of new orders placed using the Zero Subtotal payment method. */
  zero_subtotal_new_order_status?: Maybe<Scalars['String']>;
  /**
   * When the new order status is 'Processing', this can be set to
   * `authorize_capture` to automatically invoice all items that have a zero balance.
   */
  zero_subtotal_payment_action?: Maybe<Scalars['String']>;
  /** A comma-separated list of specific countries allowed to use the Zero Subtotal payment method. */
  zero_subtotal_payment_from_specific_countries?: Maybe<Scalars['String']>;
  /**
   * A number indicating the position of the Zero Subtotal payment method in the
   * list of available payment methods during checkout.
   */
  zero_subtotal_sort_order?: Maybe<Scalars['Int']>;
  /** The title of the Zero Subtotal payment method displayed on the storefront. */
  zero_subtotal_title?: Maybe<Scalars['String']>;
};

/** Indicates where an attribute can be displayed. */
export type StorefrontProperties = {
  __typename?: 'StorefrontProperties';
  /** The relative position of the attribute in the layered navigation block. */
  position?: Maybe<Scalars['Int']>;
  /** Indicates whether the attribute is filterable with results, without results, or not at all. */
  use_in_layered_navigation?: Maybe<UseInLayeredNavigationOptions>;
  /** Indicates whether the attribute is displayed in product listings. */
  use_in_product_listing?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the attribute can be used in layered navigation on search results pages. */
  use_in_search_results_layered_navigation?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the attribute is displayed on product pages. */
  visible_on_catalog_pages?: Maybe<Scalars['Boolean']>;
};

/** Contains the result of the `subscribeEmailToNewsletter` operation. */
export type SubscribeEmailToNewsletterOutput = {
  __typename?: 'SubscribeEmailToNewsletterOutput';
  /** The status of the subscription request. */
  status?: Maybe<SubscriptionStatusesEnum>;
};

/** Indicates the status of the request. */
export enum SubscriptionStatusesEnum {
  NotActive = 'NOT_ACTIVE',
  Subscribed = 'SUBSCRIBED',
  Unsubscribed = 'UNSUBSCRIBED',
  Unconfirmed = 'UNCONFIRMED'
}

/** Describes the swatch type and a value. */
export type SwatchData = {
  __typename?: 'SwatchData';
  /** The type of swatch filter item: 1 - text; 2 - image. */
  type?: Maybe<Scalars['String']>;
  /** The value for the swatch item. It could be text or an image link. */
  value?: Maybe<Scalars['String']>;
};

export type SwatchDataInterface = {
  /** The value can be represented as color (HEX code), image link, or text. */
  value?: Maybe<Scalars['String']>;
};

export type SwatchLayerFilterItem = LayerFilterItemInterface & SwatchLayerFilterItemInterface & {
  __typename?: 'SwatchLayerFilterItem';
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  items_count?: Maybe<Scalars['Int']>;
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label?: Maybe<Scalars['String']>;
  /** Data required to render a swatch filter item. */
  swatch_data?: Maybe<SwatchData>;
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  value_string?: Maybe<Scalars['String']>;
};

export type SwatchLayerFilterItemInterface = {
  /** Data required to render a swatch filter item. */
  swatch_data?: Maybe<SwatchData>;
};

export type SwatchValues = {
  __typename?: 'SwatchValues';
  image?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type TagData = {
  __typename?: 'TagData';
  /** Category of the tag */
  category?: Maybe<Scalars['String']>;
  /** Selected type for the file */
  file_type?: Maybe<FileType>;
  /** Name of the tag */
  name?: Maybe<Scalars['String']>;
  /** ID of the tag */
  tag_id?: Maybe<Scalars['String']>;
};

/** Contains tax item details. */
export type TaxItem = {
  __typename?: 'TaxItem';
  /** The amount of tax applied to the item. */
  amount: Money;
  /** The rate used to calculate the tax. */
  rate: Scalars['Float'];
  /** A title that describes the tax. */
  title: Scalars['String'];
};

export type TextileAlliance = {
  __typename?: 'TextileAlliance';
  data?: Maybe<Array<Maybe<TextileAllianceData>>>;
  last_page_number?: Maybe<Scalars['Int']>;
  page_number?: Maybe<Scalars['Int']>;
  page_size?: Maybe<Scalars['Int']>;
  result_count?: Maybe<Scalars['Int']>;
};

export type TextileAllianceData = {
  __typename?: 'TextileAllianceData';
  /** fabric_color */
  fabric_color?: Maybe<Scalars['String']>;
  /** fabric_pattern */
  fabric_pattern?: Maybe<Scalars['String']>;
  /** fabric_vendor */
  fabric_vendor?: Maybe<Scalars['String']>;
  /** fairfield_grade */
  fairfield_grade?: Maybe<Scalars['String']>;
  /** railroad */
  railroad?: Maybe<Scalars['String']>;
};

export type TextileAllianceGrades = {
  __typename?: 'TextileAllianceGrades';
  grades?: Maybe<Array<Maybe<Scalars['String']>>>;
  last_page_number?: Maybe<Scalars['Int']>;
  page_number?: Maybe<Scalars['Int']>;
  page_size?: Maybe<Scalars['Int']>;
  result_count?: Maybe<Scalars['Int']>;
};

export type TextileAllianceLogoData = {
  __typename?: 'TextileAllianceLogoData';
  /** logo_file */
  logo_file?: Maybe<Scalars['String']>;
  /** pdf */
  pdf?: Maybe<Scalars['String']>;
  /** vendor */
  vendor?: Maybe<Scalars['String']>;
  /** website */
  website?: Maybe<Scalars['String']>;
};

export type TextileAllianceVendors = {
  __typename?: 'TextileAllianceVendors';
  vendors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TextSwatchData = SwatchDataInterface & {
  __typename?: 'TextSwatchData';
  /** The value can be represented as color (HEX code), image link, or text. */
  value?: Maybe<Scalars['String']>;
};

/** Defines a price based on the quantity purchased. */
export type TierPrice = {
  __typename?: 'TierPrice';
  /** The price discount that this tier represents. */
  discount?: Maybe<ProductDiscount>;
  /** The price of the product at this tier. */
  final_price?: Maybe<Money>;
  /** The minimum number of items that must be purchased to qualify for this price tier. */
  quantity?: Maybe<Scalars['Float']>;
};

export type TotalWishes = {
  __typename?: 'TotalWishes';
  totalitems?: Maybe<Scalars['String']>;
};

/** Modifies the specified items in the cart. */
export type UpdateCartItemsInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of items to be updated. */
  cart_items: Array<Maybe<CartItemUpdateInput>>;
};

/** Contains details about the cart after updating items. */
export type UpdateCartItemsOutput = {
  __typename?: 'UpdateCartItemsOutput';
  /** The cart after updating products. */
  cart: Cart;
};

/** Contains the customer's wish list and any errors encountered. */
export type UpdateProductsInWishlistOutput = {
  __typename?: 'UpdateProductsInWishlistOutput';
  /** An array of errors encountered while updating products in a wish list. */
  user_errors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with all items that were successfully updated. */
  wishlist: Wishlist;
};

export type UpdateSavedBuildNameInput = {
  item_id: Scalars['Int'];
  new_name?: Maybe<Scalars['String']>;
  wishlist: Scalars['String'];
};

/** Contains URL rewrite details. */
export type UrlRewrite = {
  __typename?: 'UrlRewrite';
  /** An array of request parameters. */
  parameters?: Maybe<Array<Maybe<HttpQueryParameter>>>;
  /** The request URL. */
  url?: Maybe<Scalars['String']>;
};

/** This enumeration defines the entity type. */
export enum UrlRewriteEntityTypeEnum {
  CmsPage = 'CMS_PAGE',
  Product = 'PRODUCT',
  Category = 'CATEGORY'
}

/** Defines whether the attribute is filterable in layered navigation. */
export enum UseInLayeredNavigationOptions {
  No = 'NO',
  FilterableWithResults = 'FILTERABLE_WITH_RESULTS',
  FilterableNoResult = 'FILTERABLE_NO_RESULT'
}

/** Contains required input for payment methods with Vault support. */
export type VaultTokenInput = {
  /** The public hash of the payment token. */
  public_hash: Scalars['String'];
};

/** An implementation for virtual product cart items. */
export type VirtualCartItem = CartItemInterface & {
  __typename?: 'VirtualCartItem';
  /** cart item comment */
  comment?: Maybe<Scalars['String']>;
  /** An array containing customizable options the shopper selected. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** cart item document_names */
  document_names?: Maybe<Array<Maybe<DocumentNamesData>>>;
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<CartItemError>>>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export type VirtualProduct = ProductInterface & RoutableInterface & CustomizableProductInterface & {
  __typename?: 'VirtualProduct';
  /**
   * Tearsheet PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  assembly_instructions?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  attribute_set_name?: Maybe<Scalars['String']>;
  /**
   * Availability date for the product.
   * @deprecated Use the `custom_attributes` field instead.
   */
  availability_date?: Maybe<Scalars['String']>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Products' is enabled.
   */
  canonical_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  catalog_page?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  /**
   * Order Form PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  collection_overview_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_caster_options?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_wheels_option?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  conf_wood_finish?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_apronh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_armh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_assem_req?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_avails?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_backconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_basefab?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_baselth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_bn_appl?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_casegd?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_casters?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cfgmodel?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_checkbox_test_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_collection?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_countryorg?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cubes?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_cupholder?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_depth_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_designer?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_dftfabric?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_dftfinish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_duedate?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_extdesc2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_finish_req?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_finishopt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdepthgh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdeptlow?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimd?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_frmdimw?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_fulllftwal?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_fullyuph?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_height_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidebkh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestd?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestd_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_insidestw_test?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_introdat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_isvisible?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_lanect?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_laners?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_lanesl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leather8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_leatheravl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_madeto?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_moistbarr?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_nail_opts?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhigharh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhighovh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offhighsth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowarh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowovh?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_offlowsth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_onords?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_product_type_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_qty_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_reclindpt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_reclwallcl?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_riser?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seatconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seath?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_seath_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_sprgconstr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_status?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdback?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdcushion?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdfinish?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_stdpllw1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tablet?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tariff?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_tvrecline?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_uph_opts?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_wgtcar?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_wgtwrp?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_width_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffi_woodspec1?: Maybe<Scalars['Int']>;
  ffi_woodspec1_label?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_finish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fficustopt_type_filterable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_abrasion?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_avail?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_avail_filter?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_categories?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_category1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_collection?: Maybe<Scalars['String']>;
  ffifabrics_collection_label?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_colorways?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_country?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_cutyard?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_dec_cord?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_descr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_descript?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabcont9?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct3?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct4?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct5?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct6?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct7?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct8?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabpct9?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fabric?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_finishtype?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fringe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_fullcont?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_grade?: Maybe<Array<Maybe<Scalars['String']>>>;
  ffifabrics_grade_configurator_filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_hexclrgrp?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_hrepeat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_leather?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_pattern?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_std_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_tape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vendor?: Maybe<Array<Maybe<Scalars['String']>>>;
  ffifabrics_vendor_image?: Maybe<SwatchValues>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vinyl?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifabrics_vrepeat?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_about?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_add_info?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_chm_distr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_cleaning?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_fairshield?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_nailhead?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_phy_distr?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ffifinish_sheen?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  file_upload?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  filterable_attribute_set_name?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  hotspots?: Maybe<Array<Maybe<ProductHotspotData>>>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** Add notification that loged user have added product in downloads in specific store */
  in_downloads?: Maybe<Scalars['String']>;
  /** Image is a part of in_real_life role. */
  in_real_life?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  in_stock_filter?: Maybe<Scalars['Int']>;
  industry_approval_attribute_option?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  introdat_filter?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_featured?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_new?: Maybe<Scalars['Int']>;
  /** Adding NULL for this field because of React core. */
  lead_time?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_image role. */
  lifestyle_image?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_image2 role. */
  lifestyle_image2?: Maybe<Scalars['String']>;
  /** Image is a part of lifestyle_video role. */
  lifestyle_video?: Maybe<Scalars['String']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer?: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  new_from_date?: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  new_to_date?: Maybe<Scalars['String']>;
  /** Number of throw/kidney pillows */
  num_of_pillows?: Maybe<Scalars['Int']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** The range of prices for the product */
  price_range: PriceRange;
  /** An array of `TierPrice` objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_features?: Maybe<Scalars['String']>;
  product_features_label?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of `ProductLinks` objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** @deprecated Use the `custom_attributes` field instead. */
  ready_to_ship_filter?: Maybe<Scalars['Int']>;
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirect_code: Scalars['Int'];
  /** An array of products to be displayed in a Related Products block. */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /**
   * Packing Fee List PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  revit_file?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shop_by_position?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * Thread Board PDF.
   * @deprecated Use the `custom_attributes` field instead.
   */
  spec_sheet?: Maybe<Scalars['String']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /**
   * The uploaded video.
   * @deprecated Use the `custom_attributes` field instead.
   */
  video_upload?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
};


/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export type VirtualProductReviewsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

/** Defines a single product to add to the cart. */
export type VirtualProductCartItemInput = {
  /** An array that defines customizable options for the product. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** An object containing the `sku`, `quantity`, and other relevant information about the product. */
  data: CartItemInput;
};

/** Contains a virtual product wish list item. */
export type VirtualWishlistItem = WishlistItemInterface & {
  __typename?: 'VirtualWishlistItem';
  /** The date and time the item was added to the wish list. */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
};

/** Deprecated. It should not be used on the storefront. Contains information about a website. */
export type Website = {
  __typename?: 'Website';
  /**
   * A code assigned to the website to identify it.
   * @deprecated The field should not be used on the storefront.
   */
  code?: Maybe<Scalars['String']>;
  /**
   * The default group ID of the website.
   * @deprecated The field should not be used on the storefront.
   */
  default_group_id?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the website.
   * @deprecated The field should not be used on the storefront.
   */
  id?: Maybe<Scalars['Int']>;
  /**
   * Indicates whether this is the default website.
   * @deprecated The field should not be used on the storefront.
   */
  is_default?: Maybe<Scalars['Boolean']>;
  /**
   * The website name. Websites use this name to identify it easier.
   * @deprecated The field should not be used on the storefront.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * The attribute to use for sorting websites.
   * @deprecated The field should not be used on the storefront.
   */
  sort_order?: Maybe<Scalars['Int']>;
};

/** Contains a customer wish list. */
export type Wishlist = {
  __typename?: 'Wishlist';
  /** The unique ID for a `Wishlist` object. */
  id?: Maybe<Scalars['ID']>;
  /** @deprecated Use the `items_v2` field instead. */
  items?: Maybe<Array<Maybe<WishlistItem>>>;
  /** The number of items in the wish list. */
  items_count?: Maybe<Scalars['Int']>;
  /** An array of items in the customer's wish list. */
  items_v2?: Maybe<WishlistItems>;
  /** An encrypted code that Magento uses to link to the wish list. */
  sharing_code?: Maybe<Scalars['String']>;
  /** The time of the last modification to the wish list. */
  updated_at?: Maybe<Scalars['String']>;
};


/** Contains a customer wish list. */
export type WishlistItems_V2Args = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** Contains details about errors encountered when a customer added wish list items to the cart. */
export type WishlistCartUserInputError = {
  __typename?: 'WishlistCartUserInputError';
  /** An error code that describes the error encountered. */
  code: WishlistCartUserInputErrorType;
  /** A localized error message. */
  message: Scalars['String'];
  /** The unique ID of the `Wishlist` object containing an error. */
  wishlistId: Scalars['ID'];
  /** The unique ID of the wish list item containing an error. */
  wishlistItemId: Scalars['ID'];
};

/** A list of possible error types. */
export enum WishlistCartUserInputErrorType {
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  NotSalable = 'NOT_SALABLE',
  InsufficientStock = 'INSUFFICIENT_STOCK',
  Undefined = 'UNDEFINED'
}

export type WishListCustomizableOption = {
  __typename?: 'WishListCustomizableOption';
  option_category?: Maybe<Scalars['String']>;
  option_id?: Maybe<Scalars['Int']>;
  option_title?: Maybe<Scalars['String']>;
  option_value_id?: Maybe<Scalars['Int']>;
  option_value_price?: Maybe<Scalars['String']>;
  option_value_title?: Maybe<Scalars['String']>;
};

/** Contains details about a wish list item. */
export type WishlistItem = {
  __typename?: 'WishlistItem';
  /** The time when the customer added the item to the wish list. */
  added_at?: Maybe<Scalars['String']>;
  /** The customer's comment about this item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItem` object. */
  id?: Maybe<Scalars['Int']>;
  /** Uploaded image for the product */
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** Details about the wish list item. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  qty?: Maybe<Scalars['Float']>;
};

/** Defines the items to add to a wish list. */
export type WishlistItemInput = {
  /** An array of options that the customer entered. */
  entered_options?: Maybe<Array<Maybe<EnteredOptionInput>>>;
  /** For complex product types, the SKU of the parent product. */
  parent_sku?: Maybe<Scalars['String']>;
  /** The amount or number of items to add. */
  quantity: Scalars['Float'];
  /** An array of strings corresponding to options the customer selected. */
  selected_options?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The SKU of the product to add. For complex product types, specify the child product SKU. */
  sku: Scalars['String'];
};

/** The interface for wish list items. */
export type WishlistItemInterface = {
  /** The date and time the item was added to the wish list. */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
};

export type WishlistItemJola = {
  __typename?: 'WishlistItemJola';
  added_at?: Maybe<Scalars['String']>;
  /** Is product configured for cart */
  cart_ready?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  item_name?: Maybe<Scalars['String']>;
  /** Reason for not adding to the cart */
  message?: Maybe<Scalars['String']>;
  product?: Maybe<ProductInterface>;
  qty?: Maybe<Scalars['String']>;
  selected_customizable_options?: Maybe<Array<Maybe<WishListCustomizableOption>>>;
};

/** Contains an array of items in a wish list. */
export type WishlistItems = {
  __typename?: 'WishlistItems';
  /** A list of items in the wish list. */
  items: Array<Maybe<WishlistItemInterface>>;
  /** Contains pagination metadata. */
  page_info?: Maybe<SearchResultPageInfo>;
};

/** Defines updates to items in a wish list. */
export type WishlistItemUpdateInput = {
  /** Customer-entered comments about the item. */
  description?: Maybe<Scalars['String']>;
  /** An array of options that the customer entered. */
  entered_options?: Maybe<Array<Maybe<EnteredOptionInput>>>;
  /** The new amount or number of this item. */
  quantity?: Maybe<Scalars['Float']>;
  /** An array of strings corresponding to options the customer selected. */
  selected_options?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The unique ID for a `WishlistItemInterface` object. */
  wishlist_item_id: Scalars['ID'];
};

export type WishlistJola = {
  __typename?: 'WishlistJola';
  /** Cart id after adding to cart */
  cartId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  /** An array of items in the customer's wish list */
  items?: Maybe<Array<Maybe<WishlistItemJola>>>;
  items_count?: Maybe<Scalars['String']>;
  /** Wish list name */
  name?: Maybe<Scalars['String']>;
  sharing_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type WishlistName = {
  name?: Maybe<Scalars['String']>;
};

/** Deprecated: Use the `Wishlist` type instead. */
export type WishlistOutput = {
  __typename?: 'WishlistOutput';
  /**
   * An array of items in the customer's wish list
   * @deprecated Use the `Wishlist.items` field instead.
   */
  items?: Maybe<Array<Maybe<WishlistItem>>>;
  /**
   * The number of items in the wish list.
   * @deprecated Use the `Wishlist.items_count` field instead.
   */
  items_count?: Maybe<Scalars['Int']>;
  /**
   * When multiple wish lists are enabled, the name the customer assigns to the wishlist.
   * @deprecated This field is related to Commerce functionality and is always `null` in Open Source.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * An encrypted code that links to the wish list.
   * @deprecated Use the `Wishlist.sharing_code` field instead.
   */
  sharing_code?: Maybe<Scalars['String']>;
  /**
   * The time of the last modification to the wish list.
   * @deprecated Use the `Wishlist.updated_at` field instead.
   */
  updated_at?: Maybe<Scalars['String']>;
};

/** An error encountered while performing operations with WishList. */
export type WishListUserInputError = {
  __typename?: 'WishListUserInputError';
  /** A wish list-specific error code. */
  code: WishListUserInputErrorType;
  /** A localized error message. */
  message: Scalars['String'];
};

/** A list of possible error types. */
export enum WishListUserInputErrorType {
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  Undefined = 'UNDEFINED'
}

/** MediaGalleryEntriesContent contains an file in base64 format and basic information about the file. */
export type WMediaGalleryEntriesContent = {
  /** The file in base64 format. */
  base64_encoded_data?: Maybe<Scalars['String']>;
  /** The file name of the file. */
  name?: Maybe<Scalars['String']>;
  /** The MIME type of the file, such as image/png. */
  type?: Maybe<Scalars['String']>;
};

export type DomainCartFragment = (
  { __typename?: 'Cart' }
  & Pick<Cart, 'id'>
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'BundleCartItem' }
    & Pick<BundleCartItem, 'id' | 'comment' | 'quantity'>
    & { document_names?: Maybe<Array<Maybe<(
      { __typename?: 'DocumentNamesData' }
      & Pick<DocumentNamesData, 'file_name' | 'uploaded_file_name'>
    )>>>, product: (
      { __typename?: 'BundleProduct' }
      & Pick<BundleProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'ConfigurableProduct' }
      & Pick<ConfigurableProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'SimpleProduct' }
      & Pick<SimpleProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'DownloadableProduct' }
      & Pick<DownloadableProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'GroupedProduct' }
      & Pick<GroupedProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'VirtualProduct' }
      & Pick<VirtualProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) }
  ) | (
    { __typename?: 'ConfigurableCartItem' }
    & Pick<ConfigurableCartItem, 'id' | 'comment' | 'quantity'>
    & { document_names?: Maybe<Array<Maybe<(
      { __typename?: 'DocumentNamesData' }
      & Pick<DocumentNamesData, 'file_name' | 'uploaded_file_name'>
    )>>>, product: (
      { __typename?: 'BundleProduct' }
      & Pick<BundleProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'ConfigurableProduct' }
      & Pick<ConfigurableProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'SimpleProduct' }
      & Pick<SimpleProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'DownloadableProduct' }
      & Pick<DownloadableProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'GroupedProduct' }
      & Pick<GroupedProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'VirtualProduct' }
      & Pick<VirtualProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) }
  ) | (
    { __typename?: 'DownloadableCartItem' }
    & Pick<DownloadableCartItem, 'id' | 'comment' | 'quantity'>
    & { document_names?: Maybe<Array<Maybe<(
      { __typename?: 'DocumentNamesData' }
      & Pick<DocumentNamesData, 'file_name' | 'uploaded_file_name'>
    )>>>, product: (
      { __typename?: 'BundleProduct' }
      & Pick<BundleProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'ConfigurableProduct' }
      & Pick<ConfigurableProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'SimpleProduct' }
      & Pick<SimpleProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'DownloadableProduct' }
      & Pick<DownloadableProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'GroupedProduct' }
      & Pick<GroupedProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'VirtualProduct' }
      & Pick<VirtualProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) }
  ) | (
    { __typename?: 'SimpleCartItem' }
    & Pick<SimpleCartItem, 'id' | 'quantity' | 'comment'>
    & { customizable_options: Array<Maybe<(
      { __typename?: 'SelectedCustomizableOption' }
      & Pick<SelectedCustomizableOption, 'id' | 'label' | 'type'>
      & { values: Array<Maybe<(
        { __typename?: 'SelectedCustomizableOptionValue' }
        & Pick<SelectedCustomizableOptionValue, 'id' | 'label' | 'value'>
        & { price: (
          { __typename?: 'CartItemSelectedOptionValuePrice' }
          & Pick<CartItemSelectedOptionValuePrice, 'value'>
        ) }
      )>> }
    )>>, product: (
      { __typename?: 'BundleProduct' }
      & Pick<BundleProduct, 'id' | 'sku' | 'name' | 'url_key' | 'ffi_collection' | 'ffi_frmdimw' | 'ffi_frmdimh' | 'ffi_frmdimd' | 'stock_status'>
      & { image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'url' | 'label'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'url' | 'label'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'ConfigurableProduct' }
      & Pick<ConfigurableProduct, 'id' | 'sku' | 'name' | 'url_key' | 'ffi_collection' | 'ffi_frmdimw' | 'ffi_frmdimh' | 'ffi_frmdimd' | 'stock_status'>
      & { image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'url' | 'label'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'url' | 'label'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'SimpleProduct' }
      & Pick<SimpleProduct, 'id' | 'sku' | 'name' | 'url_key' | 'ffi_collection' | 'ffi_frmdimw' | 'ffi_frmdimh' | 'ffi_frmdimd' | 'stock_status'>
      & { image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'url' | 'label'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'url' | 'label'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'DownloadableProduct' }
      & Pick<DownloadableProduct, 'id' | 'sku' | 'name' | 'url_key' | 'ffi_collection' | 'ffi_frmdimw' | 'ffi_frmdimh' | 'ffi_frmdimd' | 'stock_status'>
      & { image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'url' | 'label'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'url' | 'label'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'GroupedProduct' }
      & Pick<GroupedProduct, 'id' | 'sku' | 'name' | 'url_key' | 'ffi_collection' | 'ffi_frmdimw' | 'ffi_frmdimh' | 'ffi_frmdimd' | 'stock_status'>
      & { image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'url' | 'label'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'url' | 'label'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'VirtualProduct' }
      & Pick<VirtualProduct, 'id' | 'sku' | 'name' | 'url_key' | 'ffi_collection' | 'ffi_frmdimw' | 'ffi_frmdimh' | 'ffi_frmdimd' | 'stock_status'>
      & { image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'url' | 'label'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'url' | 'label'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ), prices?: Maybe<(
      { __typename?: 'CartItemPrices' }
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ) }
    )>, document_names?: Maybe<Array<Maybe<(
      { __typename?: 'DocumentNamesData' }
      & Pick<DocumentNamesData, 'file_name' | 'uploaded_file_name'>
    )>>> }
  ) | (
    { __typename?: 'VirtualCartItem' }
    & Pick<VirtualCartItem, 'id' | 'comment' | 'quantity'>
    & { document_names?: Maybe<Array<Maybe<(
      { __typename?: 'DocumentNamesData' }
      & Pick<DocumentNamesData, 'file_name' | 'uploaded_file_name'>
    )>>>, product: (
      { __typename?: 'BundleProduct' }
      & Pick<BundleProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'ConfigurableProduct' }
      & Pick<ConfigurableProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'SimpleProduct' }
      & Pick<SimpleProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'DownloadableProduct' }
      & Pick<DownloadableProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'GroupedProduct' }
      & Pick<GroupedProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) | (
      { __typename?: 'VirtualProduct' }
      & Pick<VirtualProduct, 'sku' | 'stock_status'>
      & { price_range: (
        { __typename?: 'PriceRange' }
        & { minimum_price: (
          { __typename?: 'ProductPrice' }
          & { final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'value' | 'currency'>
          ) }
        ) }
      ) }
    ) }
  )>>>, shipping_addresses: Array<Maybe<(
    { __typename?: 'ShippingCartAddress' }
    & Pick<ShippingCartAddress, 'firstname' | 'lastname' | 'telephone' | 'company' | 'city' | 'street' | 'postcode' | 'order_email'>
    & { country: (
      { __typename?: 'CartAddressCountry' }
      & Pick<CartAddressCountry, 'code' | 'label'>
    ), region?: Maybe<(
      { __typename?: 'CartAddressRegion' }
      & Pick<CartAddressRegion, 'code' | 'label'>
    )> }
  )>>, billing_address?: Maybe<(
    { __typename?: 'BillingCartAddress' }
    & Pick<BillingCartAddress, 'firstname' | 'lastname' | 'telephone' | 'company' | 'city' | 'street' | 'postcode' | 'order_email'>
    & { country: (
      { __typename?: 'CartAddressCountry' }
      & Pick<CartAddressCountry, 'code' | 'label'>
    ), region?: Maybe<(
      { __typename?: 'CartAddressRegion' }
      & Pick<CartAddressRegion, 'code' | 'label'>
    )> }
  )> }
);

export type DomainOrderFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'order_number' | 'project_name' | 'project_due_date' | 'timeline' | 'additional_details'>
);

type DomainProduct_BundleProduct_Fragment = (
  { __typename?: 'BundleProduct' }
  & Pick<BundleProduct, 'id' | 'sku' | 'name' | 'ffi_lanect' | 'ffi_laners' | 'ffi_lanesl' | 'ffi_isvisible' | 'fficustopt_type_filterable' | 'is_new' | 'ffi_casegd' | 'ffi_fullyuph' | 'url_key' | 'attribute_set_name' | 'ffi_frmdimw' | 'ffi_frmdimh' | 'ffi_frmdimd'>
  & { image?: Maybe<(
    { __typename?: 'ProductImage' }
    & Pick<ProductImage, 'url' | 'label'>
  )>, small_image?: Maybe<(
    { __typename?: 'ProductImage' }
    & Pick<ProductImage, 'url' | 'label'>
  )>, price_range: (
    { __typename?: 'PriceRange' }
    & { minimum_price: (
      { __typename?: 'ProductPrice' }
      & { final_price: (
        { __typename?: 'Money' }
        & Pick<Money, 'value'>
      ) }
    ) }
  ), categories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryTree' }
    & Pick<CategoryTree, 'uid' | 'name'>
  )>>>, description?: Maybe<(
    { __typename?: 'ComplexTextValue' }
    & Pick<ComplexTextValue, 'html'>
  )> }
);

type DomainProduct_ConfigurableProduct_Fragment = (
  { __typename?: 'ConfigurableProduct' }
  & Pick<ConfigurableProduct, 'id' | 'sku' | 'name' | 'ffi_lanect' | 'ffi_laners' | 'ffi_lanesl' | 'ffi_isvisible' | 'fficustopt_type_filterable' | 'is_new' | 'ffi_casegd' | 'ffi_fullyuph' | 'url_key' | 'attribute_set_name' | 'ffi_frmdimw' | 'ffi_frmdimh' | 'ffi_frmdimd'>
  & { image?: Maybe<(
    { __typename?: 'ProductImage' }
    & Pick<ProductImage, 'url' | 'label'>
  )>, small_image?: Maybe<(
    { __typename?: 'ProductImage' }
    & Pick<ProductImage, 'url' | 'label'>
  )>, price_range: (
    { __typename?: 'PriceRange' }
    & { minimum_price: (
      { __typename?: 'ProductPrice' }
      & { final_price: (
        { __typename?: 'Money' }
        & Pick<Money, 'value'>
      ) }
    ) }
  ), categories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryTree' }
    & Pick<CategoryTree, 'uid' | 'name'>
  )>>>, description?: Maybe<(
    { __typename?: 'ComplexTextValue' }
    & Pick<ComplexTextValue, 'html'>
  )> }
);

type DomainProduct_SimpleProduct_Fragment = (
  { __typename?: 'SimpleProduct' }
  & Pick<SimpleProduct, 'id' | 'sku' | 'name' | 'ffi_lanect' | 'ffi_laners' | 'ffi_lanesl' | 'ffi_isvisible' | 'fficustopt_type_filterable' | 'is_new' | 'ffi_casegd' | 'ffi_fullyuph' | 'url_key' | 'attribute_set_name' | 'ffi_frmdimw' | 'ffi_frmdimh' | 'ffi_frmdimd'>
  & { image?: Maybe<(
    { __typename?: 'ProductImage' }
    & Pick<ProductImage, 'url' | 'label'>
  )>, small_image?: Maybe<(
    { __typename?: 'ProductImage' }
    & Pick<ProductImage, 'url' | 'label'>
  )>, price_range: (
    { __typename?: 'PriceRange' }
    & { minimum_price: (
      { __typename?: 'ProductPrice' }
      & { final_price: (
        { __typename?: 'Money' }
        & Pick<Money, 'value'>
      ) }
    ) }
  ), categories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryTree' }
    & Pick<CategoryTree, 'uid' | 'name'>
  )>>>, description?: Maybe<(
    { __typename?: 'ComplexTextValue' }
    & Pick<ComplexTextValue, 'html'>
  )> }
);

type DomainProduct_DownloadableProduct_Fragment = (
  { __typename?: 'DownloadableProduct' }
  & Pick<DownloadableProduct, 'id' | 'sku' | 'name' | 'ffi_lanect' | 'ffi_laners' | 'ffi_lanesl' | 'ffi_isvisible' | 'fficustopt_type_filterable' | 'is_new' | 'ffi_casegd' | 'ffi_fullyuph' | 'url_key' | 'attribute_set_name' | 'ffi_frmdimw' | 'ffi_frmdimh' | 'ffi_frmdimd'>
  & { image?: Maybe<(
    { __typename?: 'ProductImage' }
    & Pick<ProductImage, 'url' | 'label'>
  )>, small_image?: Maybe<(
    { __typename?: 'ProductImage' }
    & Pick<ProductImage, 'url' | 'label'>
  )>, price_range: (
    { __typename?: 'PriceRange' }
    & { minimum_price: (
      { __typename?: 'ProductPrice' }
      & { final_price: (
        { __typename?: 'Money' }
        & Pick<Money, 'value'>
      ) }
    ) }
  ), categories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryTree' }
    & Pick<CategoryTree, 'uid' | 'name'>
  )>>>, description?: Maybe<(
    { __typename?: 'ComplexTextValue' }
    & Pick<ComplexTextValue, 'html'>
  )> }
);

type DomainProduct_GroupedProduct_Fragment = (
  { __typename?: 'GroupedProduct' }
  & Pick<GroupedProduct, 'id' | 'sku' | 'name' | 'ffi_lanect' | 'ffi_laners' | 'ffi_lanesl' | 'ffi_isvisible' | 'fficustopt_type_filterable' | 'is_new' | 'ffi_casegd' | 'ffi_fullyuph' | 'url_key' | 'attribute_set_name' | 'ffi_frmdimw' | 'ffi_frmdimh' | 'ffi_frmdimd'>
  & { image?: Maybe<(
    { __typename?: 'ProductImage' }
    & Pick<ProductImage, 'url' | 'label'>
  )>, small_image?: Maybe<(
    { __typename?: 'ProductImage' }
    & Pick<ProductImage, 'url' | 'label'>
  )>, price_range: (
    { __typename?: 'PriceRange' }
    & { minimum_price: (
      { __typename?: 'ProductPrice' }
      & { final_price: (
        { __typename?: 'Money' }
        & Pick<Money, 'value'>
      ) }
    ) }
  ), categories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryTree' }
    & Pick<CategoryTree, 'uid' | 'name'>
  )>>>, description?: Maybe<(
    { __typename?: 'ComplexTextValue' }
    & Pick<ComplexTextValue, 'html'>
  )> }
);

type DomainProduct_VirtualProduct_Fragment = (
  { __typename?: 'VirtualProduct' }
  & Pick<VirtualProduct, 'id' | 'sku' | 'name' | 'ffi_lanect' | 'ffi_laners' | 'ffi_lanesl' | 'ffi_isvisible' | 'fficustopt_type_filterable' | 'is_new' | 'ffi_casegd' | 'ffi_fullyuph' | 'url_key' | 'attribute_set_name' | 'ffi_frmdimw' | 'ffi_frmdimh' | 'ffi_frmdimd'>
  & { image?: Maybe<(
    { __typename?: 'ProductImage' }
    & Pick<ProductImage, 'url' | 'label'>
  )>, small_image?: Maybe<(
    { __typename?: 'ProductImage' }
    & Pick<ProductImage, 'url' | 'label'>
  )>, price_range: (
    { __typename?: 'PriceRange' }
    & { minimum_price: (
      { __typename?: 'ProductPrice' }
      & { final_price: (
        { __typename?: 'Money' }
        & Pick<Money, 'value'>
      ) }
    ) }
  ), categories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryTree' }
    & Pick<CategoryTree, 'uid' | 'name'>
  )>>>, description?: Maybe<(
    { __typename?: 'ComplexTextValue' }
    & Pick<ComplexTextValue, 'html'>
  )> }
);

export type DomainProductFragment = DomainProduct_BundleProduct_Fragment | DomainProduct_ConfigurableProduct_Fragment | DomainProduct_SimpleProduct_Fragment | DomainProduct_DownloadableProduct_Fragment | DomainProduct_GroupedProduct_Fragment | DomainProduct_VirtualProduct_Fragment;

export type DomainFileDataFragment = (
  { __typename?: 'FileData' }
  & Pick<FileData, 'file' | 'file_id' | 'filename' | 'size' | 'title' | 'thumbnail' | 'youtube_url' | 'updated_at'>
  & { divisions?: Maybe<(
    { __typename?: 'DivisionData' }
    & Pick<DivisionData, 'contract' | 'retail' | 'senior'>
  )>, file_type?: Maybe<(
    { __typename?: 'FileType' }
    & Pick<FileType, 'documents' | 'marketing' | 'videos'>
  )>, tags?: Maybe<Array<Maybe<(
    { __typename?: 'TagData' }
    & DomainTagDataFragment
  )>>> }
);

export type DomainTagDataFragment = (
  { __typename?: 'TagData' }
  & Pick<TagData, 'category' | 'name' | 'tag_id'>
  & { file_type?: Maybe<(
    { __typename?: 'FileType' }
    & Pick<FileType, 'documents' | 'marketing' | 'videos'>
  )> }
);

export type DomainWishlistFragment = (
  { __typename?: 'WishlistJola' }
  & Pick<WishlistJola, 'id' | 'items_count' | 'name'>
  & { items?: Maybe<Array<Maybe<(
    { __typename?: 'WishlistItemJola' }
    & DomainWishlistItemFragment
  )>>> }
);

export type DomainWishlistItemFragment = (
  { __typename?: 'WishlistItemJola' }
  & Pick<WishlistItemJola, 'id' | 'item_name'>
  & { selected_customizable_options?: Maybe<Array<Maybe<(
    { __typename?: 'WishListCustomizableOption' }
    & Pick<WishListCustomizableOption, 'option_title' | 'option_id' | 'option_value_title' | 'option_category' | 'option_value_id'>
  )>>>, product?: Maybe<(
    { __typename?: 'BundleProduct' }
    & DomainProduct_BundleProduct_Fragment
  ) | (
    { __typename?: 'ConfigurableProduct' }
    & DomainProduct_ConfigurableProduct_Fragment
  ) | (
    { __typename?: 'SimpleProduct' }
    & DomainProduct_SimpleProduct_Fragment
  ) | (
    { __typename?: 'DownloadableProduct' }
    & DomainProduct_DownloadableProduct_Fragment
  ) | (
    { __typename?: 'GroupedProduct' }
    & DomainProduct_GroupedProduct_Fragment
  ) | (
    { __typename?: 'VirtualProduct' }
    & DomainProduct_VirtualProduct_Fragment
  )> }
);

export type AddCustomerOrderItemAttributesMutationVariables = Exact<{
  input?: Maybe<OrderItemAttributes>;
}>;


export type AddCustomerOrderItemAttributesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addCustomerOrderItemAttributes'>
);

export type AddSimpleProductsToCartMutationVariables = Exact<{
  input?: Maybe<AddSimpleProductsToCartInput>;
}>;


export type AddSimpleProductsToCartMutation = (
  { __typename?: 'Mutation' }
  & { addSimpleProductsToCart?: Maybe<(
    { __typename?: 'AddSimpleProductsToCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & DomainCartFragment
    ) }
  )> }
);

export type PlaceOrderMutationVariables = Exact<{
  input: PlaceOrderInput;
}>;


export type PlaceOrderMutation = (
  { __typename?: 'Mutation' }
  & { placeOrder?: Maybe<(
    { __typename?: 'PlaceOrderOutput' }
    & { order: (
      { __typename?: 'Order' }
      & DomainOrderFragment
    ) }
  )> }
);

export type RemoveAllItemsFromCartMutationVariables = Exact<{
  cart_id: Scalars['String'];
}>;


export type RemoveAllItemsFromCartMutation = (
  { __typename?: 'Mutation' }
  & { removeAllItemsFromCart?: Maybe<(
    { __typename?: 'RemoveAllItemsFromCartOutput' }
    & { cart?: Maybe<(
      { __typename?: 'Cart' }
      & DomainCartFragment
    )> }
  )> }
);

export type RemoveItemFromCartMutationVariables = Exact<{
  input: RemoveItemFromCartInput;
}>;


export type RemoveItemFromCartMutation = (
  { __typename?: 'Mutation' }
  & { removeItemFromCart?: Maybe<(
    { __typename?: 'RemoveItemFromCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & DomainCartFragment
    ) }
  )> }
);

export type SetBillingAddressOnCartMutationVariables = Exact<{
  input: SetBillingAddressOnCartInput;
}>;


export type SetBillingAddressOnCartMutation = (
  { __typename?: 'Mutation' }
  & { setBillingAddressOnCart?: Maybe<(
    { __typename?: 'SetBillingAddressOnCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & Pick<Cart, 'email'>
    ) }
  )> }
);

export type SetCartAddressesMutationVariables = Exact<{
  cart_id: Scalars['String'];
  shipping_address: ShippingAddressInput;
}>;


export type SetCartAddressesMutation = (
  { __typename?: 'Mutation' }
  & { setShippingAddressesOnCart?: Maybe<(
    { __typename?: 'SetShippingAddressesOnCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & DomainCartFragment
    ) }
  )> }
);

export type SetCartMethodsAndPlaceOrderMutationVariables = Exact<{
  cart_id: Scalars['String'];
  shipping_methods: Array<Maybe<ShippingMethodInput>> | Maybe<ShippingMethodInput>;
  payment_method: PaymentMethodInput;
  placeOrderInput: PlaceOrderInput;
}>;


export type SetCartMethodsAndPlaceOrderMutation = (
  { __typename?: 'Mutation' }
  & { setShippingMethodsOnCart?: Maybe<(
    { __typename?: 'SetShippingMethodsOnCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & DomainCartFragment
    ) }
  )>, setPaymentMethodOnCart?: Maybe<(
    { __typename?: 'SetPaymentMethodOnCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & DomainCartFragment
    ) }
  )>, placeOrder?: Maybe<(
    { __typename?: 'PlaceOrderOutput' }
    & { order: (
      { __typename?: 'Order' }
      & DomainOrderFragment
    ) }
  )> }
);

export type SetPaymentMethodOnCartMutationVariables = Exact<{
  input: SetPaymentMethodOnCartInput;
}>;


export type SetPaymentMethodOnCartMutation = (
  { __typename?: 'Mutation' }
  & { setPaymentMethodOnCart?: Maybe<(
    { __typename?: 'SetPaymentMethodOnCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & DomainCartFragment
    ) }
  )> }
);

export type SetShippingAddressesOnCartMutationVariables = Exact<{
  cart_id: Scalars['String'];
  customer_address_id?: Maybe<Scalars['Int']>;
}>;


export type SetShippingAddressesOnCartMutation = (
  { __typename?: 'Mutation' }
  & { setShippingAddressesOnCart?: Maybe<(
    { __typename?: 'SetShippingAddressesOnCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & Pick<Cart, 'email'>
    ) }
  )> }
);

export type SetShippingMethodsOnCartMutationVariables = Exact<{
  input: SetShippingMethodsOnCartInput;
}>;


export type SetShippingMethodsOnCartMutation = (
  { __typename?: 'Mutation' }
  & { setShippingMethodsOnCart?: Maybe<(
    { __typename?: 'SetShippingMethodsOnCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & DomainCartFragment
    ) }
  )> }
);

export type UpdateCartItemsMutationVariables = Exact<{
  input: UpdateCartItemsInput;
}>;


export type UpdateCartItemsMutation = (
  { __typename?: 'Mutation' }
  & { updateCartItems?: Maybe<(
    { __typename?: 'UpdateCartItemsOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & DomainCartFragment
    ) }
  )> }
);

export type CreateCustomerAddressMutationVariables = Exact<{
  input: CustomerAddressInput;
}>;


export type CreateCustomerAddressMutation = (
  { __typename?: 'Mutation' }
  & { createCustomerAddress?: Maybe<(
    { __typename?: 'CustomerAddress' }
    & AddressFragment
  )> }
);

export type UpdateCustomerAddressMutationVariables = Exact<{
  id: Scalars['Int'];
  input?: Maybe<CustomerAddressInput>;
}>;


export type UpdateCustomerAddressMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomerAddress?: Maybe<(
    { __typename?: 'CustomerAddress' }
    & AddressFragment
  )> }
);

export type AddCustomerQuoteItemAttributesMutationVariables = Exact<{
  input?: Maybe<QuoteItemAttributes>;
}>;


export type AddCustomerQuoteItemAttributesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addCustomerQuoteItemAttributes'>
);

export type AddProductsToCartMutationVariables = Exact<{
  cart_id: Scalars['String'];
  items: Array<Maybe<SimpleProductCartItemInput>> | Maybe<SimpleProductCartItemInput>;
}>;


export type AddProductsToCartMutation = (
  { __typename?: 'Mutation' }
  & { addSimpleProductsToCart?: Maybe<(
    { __typename?: 'AddSimpleProductsToCartOutput' }
    & { cart: (
      { __typename?: 'Cart' }
      & Pick<Cart, 'id' | 'email'>
      & { prices?: Maybe<(
        { __typename?: 'CartPrices' }
        & { subtotal_including_tax?: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'value'>
        )>, subtotal_excluding_tax?: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'value'>
        )>, grand_total?: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'value'>
        )>, applied_taxes?: Maybe<Array<Maybe<(
          { __typename?: 'CartTaxItem' }
          & Pick<CartTaxItem, 'label'>
          & { amount: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>>>, discounts?: Maybe<Array<Maybe<(
          { __typename?: 'Discount' }
          & Pick<Discount, 'label'>
          & { amount: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>>> }
      )>, available_payment_methods?: Maybe<Array<Maybe<(
        { __typename?: 'AvailablePaymentMethod' }
        & Pick<AvailablePaymentMethod, 'code' | 'title'>
      )>>>, items?: Maybe<Array<Maybe<(
        { __typename?: 'BundleCartItem' }
        & Pick<BundleCartItem, 'id' | 'quantity'>
        & { prices?: Maybe<(
          { __typename?: 'CartItemPrices' }
          & { discounts?: Maybe<Array<Maybe<(
            { __typename?: 'Discount' }
            & Pick<Discount, 'label'>
            & { amount: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ) }
          )>>>, price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), row_total: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), row_total_including_tax: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), total_item_discount?: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          )> }
        )>, product: (
          { __typename?: 'BundleProduct' }
          & Pick<BundleProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'ConfigurableProduct' }
          & Pick<ConfigurableProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'SimpleProduct' }
          & Pick<SimpleProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'DownloadableProduct' }
          & Pick<DownloadableProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'GroupedProduct' }
          & Pick<GroupedProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'VirtualProduct' }
          & Pick<VirtualProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) }
      ) | (
        { __typename?: 'ConfigurableCartItem' }
        & Pick<ConfigurableCartItem, 'id' | 'quantity'>
        & { prices?: Maybe<(
          { __typename?: 'CartItemPrices' }
          & { discounts?: Maybe<Array<Maybe<(
            { __typename?: 'Discount' }
            & Pick<Discount, 'label'>
            & { amount: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ) }
          )>>>, price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), row_total: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), row_total_including_tax: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), total_item_discount?: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          )> }
        )>, product: (
          { __typename?: 'BundleProduct' }
          & Pick<BundleProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'ConfigurableProduct' }
          & Pick<ConfigurableProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'SimpleProduct' }
          & Pick<SimpleProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'DownloadableProduct' }
          & Pick<DownloadableProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'GroupedProduct' }
          & Pick<GroupedProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'VirtualProduct' }
          & Pick<VirtualProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) }
      ) | (
        { __typename?: 'DownloadableCartItem' }
        & Pick<DownloadableCartItem, 'id' | 'quantity'>
        & { prices?: Maybe<(
          { __typename?: 'CartItemPrices' }
          & { discounts?: Maybe<Array<Maybe<(
            { __typename?: 'Discount' }
            & Pick<Discount, 'label'>
            & { amount: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ) }
          )>>>, price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), row_total: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), row_total_including_tax: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), total_item_discount?: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          )> }
        )>, product: (
          { __typename?: 'BundleProduct' }
          & Pick<BundleProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'ConfigurableProduct' }
          & Pick<ConfigurableProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'SimpleProduct' }
          & Pick<SimpleProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'DownloadableProduct' }
          & Pick<DownloadableProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'GroupedProduct' }
          & Pick<GroupedProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'VirtualProduct' }
          & Pick<VirtualProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) }
      ) | (
        { __typename?: 'SimpleCartItem' }
        & Pick<SimpleCartItem, 'id' | 'quantity'>
        & { customizable_options: Array<Maybe<(
          { __typename?: 'SelectedCustomizableOption' }
          & Pick<SelectedCustomizableOption, 'label'>
          & { values: Array<Maybe<(
            { __typename?: 'SelectedCustomizableOptionValue' }
            & Pick<SelectedCustomizableOptionValue, 'label' | 'value'>
            & { price: (
              { __typename?: 'CartItemSelectedOptionValuePrice' }
              & Pick<CartItemSelectedOptionValuePrice, 'type' | 'units' | 'value'>
            ) }
          )>> }
        )>>, prices?: Maybe<(
          { __typename?: 'CartItemPrices' }
          & { discounts?: Maybe<Array<Maybe<(
            { __typename?: 'Discount' }
            & Pick<Discount, 'label'>
            & { amount: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ) }
          )>>>, price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), row_total: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), row_total_including_tax: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), total_item_discount?: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          )> }
        )>, product: (
          { __typename?: 'BundleProduct' }
          & Pick<BundleProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'ConfigurableProduct' }
          & Pick<ConfigurableProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'SimpleProduct' }
          & Pick<SimpleProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'DownloadableProduct' }
          & Pick<DownloadableProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'GroupedProduct' }
          & Pick<GroupedProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'VirtualProduct' }
          & Pick<VirtualProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) }
      ) | (
        { __typename?: 'VirtualCartItem' }
        & Pick<VirtualCartItem, 'id' | 'quantity'>
        & { prices?: Maybe<(
          { __typename?: 'CartItemPrices' }
          & { discounts?: Maybe<Array<Maybe<(
            { __typename?: 'Discount' }
            & Pick<Discount, 'label'>
            & { amount: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ) }
          )>>>, price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), row_total: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), row_total_including_tax: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), total_item_discount?: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          )> }
        )>, product: (
          { __typename?: 'BundleProduct' }
          & Pick<BundleProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'ConfigurableProduct' }
          & Pick<ConfigurableProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'SimpleProduct' }
          & Pick<SimpleProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'DownloadableProduct' }
          & Pick<DownloadableProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'GroupedProduct' }
          & Pick<GroupedProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename?: 'VirtualProduct' }
          & Pick<VirtualProduct, 'url_key' | 'name' | 'sku' | 'stock_status'>
          & { small_image?: Maybe<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'url'>
          )>, crosssell_products?: Maybe<Array<Maybe<(
            { __typename?: 'BundleProduct' }
            & Pick<BundleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'ConfigurableProduct' }
            & Pick<ConfigurableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'SimpleProduct' }
            & Pick<SimpleProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'DownloadableProduct' }
            & Pick<DownloadableProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'GroupedProduct' }
            & Pick<GroupedProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'VirtualProduct' }
            & Pick<VirtualProduct, 'url_key' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'ProductImage' }
              & Pick<ProductImage, 'url' | 'label'>
            )>, price_range: (
              { __typename?: 'PriceRange' }
              & { minimum_price: (
                { __typename?: 'ProductPrice' }
                & { discount?: Maybe<(
                  { __typename?: 'ProductDiscount' }
                  & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
                )>, final_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ), fixed_product_taxes?: Maybe<Array<Maybe<(
                  { __typename?: 'FixedProductTax' }
                  & Pick<FixedProductTax, 'label'>
                  & { amount?: Maybe<(
                    { __typename?: 'Money' }
                    & Pick<Money, 'currency' | 'value'>
                  )> }
                )>>>, regular_price: (
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                ) }
              ) }
            ) }
          )>>>, price_range: (
            { __typename?: 'PriceRange' }
            & { minimum_price: (
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            ), maximum_price?: Maybe<(
              { __typename?: 'ProductPrice' }
              & { discount?: Maybe<(
                { __typename?: 'ProductDiscount' }
                & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
              )>, final_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ), fixed_product_taxes?: Maybe<Array<Maybe<(
                { __typename?: 'FixedProductTax' }
                & Pick<FixedProductTax, 'label'>
                & { amount?: Maybe<(
                  { __typename?: 'Money' }
                  & Pick<Money, 'currency' | 'value'>
                )> }
              )>>>, regular_price: (
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              ) }
            )> }
          ) }
        ) }
      )>>>, billing_address?: Maybe<(
        { __typename?: 'BillingCartAddress' }
        & Pick<BillingCartAddress, 'city' | 'firstname' | 'lastname' | 'postcode' | 'street' | 'telephone'>
        & { country: (
          { __typename?: 'CartAddressCountry' }
          & Pick<CartAddressCountry, 'code' | 'label'>
        ) }
      )>, shipping_addresses: Array<Maybe<(
        { __typename?: 'ShippingCartAddress' }
        & Pick<ShippingCartAddress, 'city' | 'firstname' | 'lastname' | 'postcode' | 'street' | 'telephone'>
        & { country: (
          { __typename?: 'CartAddressCountry' }
          & Pick<CartAddressCountry, 'code' | 'label'>
        ), region?: Maybe<(
          { __typename?: 'CartAddressRegion' }
          & Pick<CartAddressRegion, 'code' | 'label'>
        )>, available_shipping_methods?: Maybe<Array<Maybe<(
          { __typename?: 'AvailableShippingMethod' }
          & Pick<AvailableShippingMethod, 'available' | 'carrier_code' | 'carrier_title' | 'error_message' | 'method_code' | 'method_title'>
          & { amount: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), price_excl_tax: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), price_incl_tax: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>>>, selected_shipping_method?: Maybe<(
          { __typename?: 'SelectedShippingMethod' }
          & Pick<SelectedShippingMethod, 'carrier_code' | 'carrier_title' | 'method_code' | 'method_title'>
          & { amount: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )> }
      )>>, selected_payment_method?: Maybe<(
        { __typename?: 'SelectedPaymentMethod' }
        & Pick<SelectedPaymentMethod, 'title' | 'purchase_order_number' | 'code'>
      )>, applied_coupons?: Maybe<Array<Maybe<(
        { __typename?: 'AppliedCoupon' }
        & Pick<AppliedCoupon, 'code'>
      )>>> }
    ) }
  )> }
);

export type Unnamed_1_MutationVariables = Exact<{ [key: string]: never; }>;


export type Unnamed_1_Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createEmptyCart'>
);

export type GenerateResetPasswordTokenMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type GenerateResetPasswordTokenMutation = (
  { __typename?: 'Mutation' }
  & { generateResetPasswordToken?: Maybe<(
    { __typename?: 'ResetPassword' }
    & Pick<ResetPassword, 'message' | 'code'>
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
  email: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changeCustomerPassword?: Maybe<(
    { __typename?: 'Customer' }
    & DomainCustomerFragment
  )>, generateCustomerToken?: Maybe<(
    { __typename?: 'CustomerToken' }
    & Pick<CustomerToken, 'token'>
  )> }
);

export type SendClaimsFormEmailMutationVariables = Exact<{
  claim_items_info?: Maybe<Array<Maybe<ClaimItem>> | Maybe<ClaimItem>>;
  contact_consignee_name: Scalars['String'];
  contact_email: Scalars['String'];
  contact_fairfield_account_number: Scalars['String'];
  contact_fax: Scalars['String'];
  contact_first_name: Scalars['String'];
  contact_last_name: Scalars['String'];
  contact_phone: Scalars['String'];
  contact_industry: Scalars['String'];
  contact_zip: Scalars['String'];
  remit_address1: Scalars['String'];
  remit_address2?: Maybe<Scalars['String']>;
  remit_city: Scalars['String'];
  remit_first_name: Scalars['String'];
  remit_last_name: Scalars['String'];
  remit_title: Scalars['String'];
  remit_zip: Scalars['String'];
  remit_state?: Maybe<Scalars['String']>;
  remit_country: Scalars['String'];
}>;


export type SendClaimsFormEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendClaimsFormEmail'>
);

export type UpdateCustomerMutationVariables = Exact<{
  input: CustomerInput;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer?: Maybe<(
    { __typename?: 'CustomerOutput' }
    & { customer: (
      { __typename?: 'Customer' }
      & DomainCustomerFragment
    ) }
  )> }
);

export type UpdateCustomerAadChangePasswordMutationVariables = Exact<{
  input: CustomerInput;
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
  email: Scalars['String'];
}>;


export type UpdateCustomerAadChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer?: Maybe<(
    { __typename?: 'CustomerOutput' }
    & { customer: (
      { __typename?: 'Customer' }
      & DomainCustomerFragment
    ) }
  )>, changeCustomerPassword?: Maybe<(
    { __typename?: 'Customer' }
    & { id: Customer['customer_id'] }
  )>, generateCustomerToken?: Maybe<(
    { __typename?: 'CustomerToken' }
    & Pick<CustomerToken, 'token'>
  )> }
);

export type UpdateCustomerEmailMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type UpdateCustomerEmailMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomerEmail?: Maybe<(
    { __typename?: 'CustomerOutput' }
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'email'>
    ) }
  )> }
);

export type UploadClaimsImageMutationVariables = Exact<{
  base64_encoded_data: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
}>;


export type UploadClaimsImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadClaimsImage?: Maybe<(
    { __typename?: 'ClaimsFileOutput' }
    & Pick<ClaimsFileOutput, 'claims_image'>
  )> }
);

export type ResetPasswordJolaMutationVariables = Exact<{
  input: ResetPasswordRequestInput;
}>;


export type ResetPasswordJolaMutation = (
  { __typename?: 'Mutation' }
  & { resetPasswordJola?: Maybe<(
    { __typename?: 'ResetPassword' }
    & Pick<ResetPassword, 'message' | 'code'>
  )> }
);

export type GenerateCustomerTokenMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type GenerateCustomerTokenMutation = (
  { __typename?: 'Mutation' }
  & { generateCustomerToken?: Maybe<(
    { __typename?: 'CustomerToken' }
    & Pick<CustomerToken, 'token'>
  )> }
);

export type RevokeCustomerTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RevokeCustomerTokenMutation = (
  { __typename?: 'Mutation' }
  & { revokeCustomerToken?: Maybe<(
    { __typename?: 'RevokeCustomerTokenOutput' }
    & Pick<RevokeCustomerTokenOutput, 'result'>
  )> }
);

export type GenerateCacheMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type GenerateCacheMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateCache'>
);

export type GenerateDownloadUrlMMutationVariables = Exact<{
  input: Array<GenerateDownloadUrlByCustomerIdImagesSamples> | GenerateDownloadUrlByCustomerIdImagesSamples;
}>;


export type GenerateDownloadUrlMMutation = (
  { __typename?: 'Mutation' }
  & { generateDownloadUrlM?: Maybe<(
    { __typename?: 'generatedDownloadUrlOutput' }
    & { generated_download_url_output: (
      { __typename?: 'generateDownloadUrl' }
      & Pick<GenerateDownloadUrl, 'download_url' | 'filename' | 'status'>
    ) }
  )> }
);

export type SendEmailMutationVariables = Exact<{
  input?: Maybe<SendEmailInput>;
}>;


export type SendEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendEmail'>
);

export type SubscriberMutationVariables = Exact<{
  email: Scalars['String'];
  division: Scalars['String'];
}>;


export type SubscriberMutation = (
  { __typename?: 'Mutation' }
  & { subscriber?: Maybe<(
    { __typename?: 'SubscribeEmailToNewsletterOutput' }
    & Pick<SubscribeEmailToNewsletterOutput, 'status'>
  )> }
);

export type AddItemsToWishlistsMutationVariables = Exact<{
  input?: Maybe<AddItemsToWishlistInput>;
}>;


export type AddItemsToWishlistsMutation = (
  { __typename?: 'Mutation' }
  & { addItemsToWishlist?: Maybe<Array<Maybe<(
    { __typename?: 'WishlistJola' }
    & DomainWishlistFragment
  )>>> }
);

export type AddNewWishlistMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type AddNewWishlistMutation = (
  { __typename?: 'Mutation' }
  & { addNewWishlist: (
    { __typename?: 'WishlistJola' }
    & DomainWishlistFragment
  ) }
);

export type DeleteItemsFromWishlistMutationVariables = Exact<{
  input?: Maybe<DeleteItemFromWishlistInput>;
}>;


export type DeleteItemsFromWishlistMutation = (
  { __typename?: 'Mutation' }
  & { deleteItemsFromWishlist: (
    { __typename?: 'WishlistJola' }
    & DomainWishlistFragment
  ) }
);

export type DeleteWishlistMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type DeleteWishlistMutation = (
  { __typename?: 'Mutation' }
  & { deleteWishlist: (
    { __typename?: 'WishlistJola' }
    & DomainWishlistFragment
  ) }
);

export type EditWishlistMutationVariables = Exact<{
  fromName: Scalars['String'];
  toName: Scalars['String'];
}>;


export type EditWishlistMutation = (
  { __typename?: 'Mutation' }
  & { editWishlist: (
    { __typename?: 'WishlistJola' }
    & DomainWishlistFragment
  ) }
);

export type UpdateWishlistItemMutationVariables = Exact<{
  itemId: Scalars['String'];
  name: Scalars['String'];
  item_name: Scalars['String'];
}>;


export type UpdateWishlistItemMutation = (
  { __typename?: 'Mutation' }
  & { updateWishlistItem: (
    { __typename?: 'WishlistJola' }
    & DomainWishlistFragment
  ) }
);

export type AddAllToCartMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type AddAllToCartMutation = (
  { __typename?: 'Mutation' }
  & { addAllToCart: (
    { __typename?: 'WishlistJola' }
    & Pick<WishlistJola, 'cartId' | 'id' | 'name' | 'items_count'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'WishlistItemJola' }
      & Pick<WishlistItemJola, 'id' | 'item_name'>
    )>>> }
  ) }
);

export type AddItemToCartMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
}>;


export type AddItemToCartMutation = (
  { __typename?: 'Mutation' }
  & { addItemToCart: (
    { __typename?: 'WishlistJola' }
    & Pick<WishlistJola, 'id'>
  ) }
);

export type DeleteSavedBuildsFromWishlistMutationVariables = Exact<{
  input?: Maybe<DeleteSavedBuildFromWishlistInput>;
}>;


export type DeleteSavedBuildsFromWishlistMutation = (
  { __typename?: 'Mutation' }
  & { deleteSavedBuildsFromWishlist: (
    { __typename?: 'WishlistJola' }
    & Pick<WishlistJola, 'id' | 'items_count'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'WishlistItemJola' }
      & Pick<WishlistItemJola, 'id' | 'item_name'>
    )>>> }
  ) }
);

export type CountryOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type CountryOptionsQuery = (
  { __typename?: 'Query' }
  & { countries?: Maybe<Array<Maybe<(
    { __typename?: 'Country' }
    & { value: Country['id'], label: Country['full_name_english'] }
    & { regions?: Maybe<Array<Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'code'>
      & { value: Region['id'], label: Region['name'] }
    )>>> }
  )>>> }
);

export type CustomerCartQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerCartQuery = (
  { __typename?: 'Query' }
  & { customerCart: (
    { __typename?: 'Cart' }
    & DomainCartFragment
  ) }
);

export type CategoryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CategoryQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'CategoryTree' }
    & Pick<CategoryTree, 'id' | 'name' | 'include_in_menu' | 'url_path'>
    & { children?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryTree' }
      & Pick<CategoryTree, 'id' | 'name' | 'image' | 'url_key' | 'url_path' | 'include_in_menu'>
    )>>> }
  )> }
);

export type CategoryProductsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CategoryProductsQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'CategoryTree' }
    & Pick<CategoryTree, 'id' | 'name' | 'include_in_menu' | 'url_path'>
    & { children?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryTree' }
      & Pick<CategoryTree, 'id' | 'name' | 'image' | 'url_key' | 'url_path' | 'include_in_menu'>
    )>>>, products?: Maybe<(
      { __typename?: 'CategoryProducts' }
      & Pick<CategoryProducts, 'total_count'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'BundleProduct' }
        & Pick<BundleProduct, 'id' | 'name' | 'sku' | 'url_key'>
        & { categories?: Maybe<Array<Maybe<(
          { __typename?: 'CategoryTree' }
          & Pick<CategoryTree, 'id'>
        )>>>, image?: Maybe<(
          { __typename?: 'ProductImage' }
          & Pick<ProductImage, 'label' | 'url'>
        )>, small_image?: Maybe<(
          { __typename?: 'ProductImage' }
          & Pick<ProductImage, 'label' | 'url'>
        )>, price_range: (
          { __typename?: 'PriceRange' }
          & { maximum_price?: Maybe<(
            { __typename?: 'ProductPrice' }
            & { discount?: Maybe<(
              { __typename?: 'ProductDiscount' }
              & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
            )>, final_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ), fixed_product_taxes?: Maybe<Array<Maybe<(
              { __typename?: 'FixedProductTax' }
              & Pick<FixedProductTax, 'label'>
              & { amount?: Maybe<(
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              )> }
            )>>>, regular_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ) }
          )>, minimum_price: (
            { __typename?: 'ProductPrice' }
            & { discount?: Maybe<(
              { __typename?: 'ProductDiscount' }
              & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
            )>, final_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ), fixed_product_taxes?: Maybe<Array<Maybe<(
              { __typename?: 'FixedProductTax' }
              & Pick<FixedProductTax, 'label'>
              & { amount?: Maybe<(
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              )> }
            )>>>, regular_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ) }
          ) }
        ), tier_prices?: Maybe<Array<Maybe<(
          { __typename?: 'ProductTierPrices' }
          & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
        )>>> }
      ) | (
        { __typename?: 'ConfigurableProduct' }
        & Pick<ConfigurableProduct, 'id' | 'name' | 'sku' | 'url_key'>
        & { categories?: Maybe<Array<Maybe<(
          { __typename?: 'CategoryTree' }
          & Pick<CategoryTree, 'id'>
        )>>>, image?: Maybe<(
          { __typename?: 'ProductImage' }
          & Pick<ProductImage, 'label' | 'url'>
        )>, small_image?: Maybe<(
          { __typename?: 'ProductImage' }
          & Pick<ProductImage, 'label' | 'url'>
        )>, price_range: (
          { __typename?: 'PriceRange' }
          & { maximum_price?: Maybe<(
            { __typename?: 'ProductPrice' }
            & { discount?: Maybe<(
              { __typename?: 'ProductDiscount' }
              & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
            )>, final_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ), fixed_product_taxes?: Maybe<Array<Maybe<(
              { __typename?: 'FixedProductTax' }
              & Pick<FixedProductTax, 'label'>
              & { amount?: Maybe<(
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              )> }
            )>>>, regular_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ) }
          )>, minimum_price: (
            { __typename?: 'ProductPrice' }
            & { discount?: Maybe<(
              { __typename?: 'ProductDiscount' }
              & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
            )>, final_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ), fixed_product_taxes?: Maybe<Array<Maybe<(
              { __typename?: 'FixedProductTax' }
              & Pick<FixedProductTax, 'label'>
              & { amount?: Maybe<(
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              )> }
            )>>>, regular_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ) }
          ) }
        ), tier_prices?: Maybe<Array<Maybe<(
          { __typename?: 'ProductTierPrices' }
          & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
        )>>> }
      ) | (
        { __typename?: 'SimpleProduct' }
        & Pick<SimpleProduct, 'id' | 'name' | 'sku' | 'url_key'>
        & { categories?: Maybe<Array<Maybe<(
          { __typename?: 'CategoryTree' }
          & Pick<CategoryTree, 'id'>
        )>>>, image?: Maybe<(
          { __typename?: 'ProductImage' }
          & Pick<ProductImage, 'label' | 'url'>
        )>, small_image?: Maybe<(
          { __typename?: 'ProductImage' }
          & Pick<ProductImage, 'label' | 'url'>
        )>, price_range: (
          { __typename?: 'PriceRange' }
          & { maximum_price?: Maybe<(
            { __typename?: 'ProductPrice' }
            & { discount?: Maybe<(
              { __typename?: 'ProductDiscount' }
              & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
            )>, final_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ), fixed_product_taxes?: Maybe<Array<Maybe<(
              { __typename?: 'FixedProductTax' }
              & Pick<FixedProductTax, 'label'>
              & { amount?: Maybe<(
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              )> }
            )>>>, regular_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ) }
          )>, minimum_price: (
            { __typename?: 'ProductPrice' }
            & { discount?: Maybe<(
              { __typename?: 'ProductDiscount' }
              & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
            )>, final_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ), fixed_product_taxes?: Maybe<Array<Maybe<(
              { __typename?: 'FixedProductTax' }
              & Pick<FixedProductTax, 'label'>
              & { amount?: Maybe<(
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              )> }
            )>>>, regular_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ) }
          ) }
        ), tier_prices?: Maybe<Array<Maybe<(
          { __typename?: 'ProductTierPrices' }
          & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
        )>>> }
      ) | (
        { __typename?: 'DownloadableProduct' }
        & Pick<DownloadableProduct, 'id' | 'name' | 'sku' | 'url_key'>
        & { categories?: Maybe<Array<Maybe<(
          { __typename?: 'CategoryTree' }
          & Pick<CategoryTree, 'id'>
        )>>>, image?: Maybe<(
          { __typename?: 'ProductImage' }
          & Pick<ProductImage, 'label' | 'url'>
        )>, small_image?: Maybe<(
          { __typename?: 'ProductImage' }
          & Pick<ProductImage, 'label' | 'url'>
        )>, price_range: (
          { __typename?: 'PriceRange' }
          & { maximum_price?: Maybe<(
            { __typename?: 'ProductPrice' }
            & { discount?: Maybe<(
              { __typename?: 'ProductDiscount' }
              & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
            )>, final_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ), fixed_product_taxes?: Maybe<Array<Maybe<(
              { __typename?: 'FixedProductTax' }
              & Pick<FixedProductTax, 'label'>
              & { amount?: Maybe<(
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              )> }
            )>>>, regular_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ) }
          )>, minimum_price: (
            { __typename?: 'ProductPrice' }
            & { discount?: Maybe<(
              { __typename?: 'ProductDiscount' }
              & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
            )>, final_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ), fixed_product_taxes?: Maybe<Array<Maybe<(
              { __typename?: 'FixedProductTax' }
              & Pick<FixedProductTax, 'label'>
              & { amount?: Maybe<(
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              )> }
            )>>>, regular_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ) }
          ) }
        ), tier_prices?: Maybe<Array<Maybe<(
          { __typename?: 'ProductTierPrices' }
          & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
        )>>> }
      ) | (
        { __typename?: 'GroupedProduct' }
        & Pick<GroupedProduct, 'id' | 'name' | 'sku' | 'url_key'>
        & { categories?: Maybe<Array<Maybe<(
          { __typename?: 'CategoryTree' }
          & Pick<CategoryTree, 'id'>
        )>>>, image?: Maybe<(
          { __typename?: 'ProductImage' }
          & Pick<ProductImage, 'label' | 'url'>
        )>, small_image?: Maybe<(
          { __typename?: 'ProductImage' }
          & Pick<ProductImage, 'label' | 'url'>
        )>, price_range: (
          { __typename?: 'PriceRange' }
          & { maximum_price?: Maybe<(
            { __typename?: 'ProductPrice' }
            & { discount?: Maybe<(
              { __typename?: 'ProductDiscount' }
              & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
            )>, final_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ), fixed_product_taxes?: Maybe<Array<Maybe<(
              { __typename?: 'FixedProductTax' }
              & Pick<FixedProductTax, 'label'>
              & { amount?: Maybe<(
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              )> }
            )>>>, regular_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ) }
          )>, minimum_price: (
            { __typename?: 'ProductPrice' }
            & { discount?: Maybe<(
              { __typename?: 'ProductDiscount' }
              & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
            )>, final_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ), fixed_product_taxes?: Maybe<Array<Maybe<(
              { __typename?: 'FixedProductTax' }
              & Pick<FixedProductTax, 'label'>
              & { amount?: Maybe<(
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              )> }
            )>>>, regular_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ) }
          ) }
        ), tier_prices?: Maybe<Array<Maybe<(
          { __typename?: 'ProductTierPrices' }
          & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
        )>>> }
      ) | (
        { __typename?: 'VirtualProduct' }
        & Pick<VirtualProduct, 'id' | 'name' | 'sku' | 'url_key'>
        & { categories?: Maybe<Array<Maybe<(
          { __typename?: 'CategoryTree' }
          & Pick<CategoryTree, 'id'>
        )>>>, image?: Maybe<(
          { __typename?: 'ProductImage' }
          & Pick<ProductImage, 'label' | 'url'>
        )>, small_image?: Maybe<(
          { __typename?: 'ProductImage' }
          & Pick<ProductImage, 'label' | 'url'>
        )>, price_range: (
          { __typename?: 'PriceRange' }
          & { maximum_price?: Maybe<(
            { __typename?: 'ProductPrice' }
            & { discount?: Maybe<(
              { __typename?: 'ProductDiscount' }
              & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
            )>, final_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ), fixed_product_taxes?: Maybe<Array<Maybe<(
              { __typename?: 'FixedProductTax' }
              & Pick<FixedProductTax, 'label'>
              & { amount?: Maybe<(
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              )> }
            )>>>, regular_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ) }
          )>, minimum_price: (
            { __typename?: 'ProductPrice' }
            & { discount?: Maybe<(
              { __typename?: 'ProductDiscount' }
              & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
            )>, final_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ), fixed_product_taxes?: Maybe<Array<Maybe<(
              { __typename?: 'FixedProductTax' }
              & Pick<FixedProductTax, 'label'>
              & { amount?: Maybe<(
                { __typename?: 'Money' }
                & Pick<Money, 'currency' | 'value'>
              )> }
            )>>>, regular_price: (
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            ) }
          ) }
        ), tier_prices?: Maybe<Array<Maybe<(
          { __typename?: 'ProductTierPrices' }
          & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type CategoryTreeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CategoryTreeQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'CategoryTree' }
    & Pick<CategoryTree, 'id' | 'name' | 'description' | 'include_in_menu' | 'url_path' | 'path'>
    & { products?: Maybe<(
      { __typename?: 'CategoryProducts' }
      & Pick<CategoryProducts, 'total_count'>
    )>, children?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryTree' }
      & Pick<CategoryTree, 'id' | 'name' | 'description' | 'url_key' | 'url_path' | 'include_in_menu' | 'path'>
      & { products?: Maybe<(
        { __typename?: 'CategoryProducts' }
        & Pick<CategoryProducts, 'total_count'>
      )>, children?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id' | 'name' | 'description' | 'url_key' | 'url_path' | 'include_in_menu' | 'path'>
        & { products?: Maybe<(
          { __typename?: 'CategoryProducts' }
          & Pick<CategoryProducts, 'total_count'>
        )>, children?: Maybe<Array<Maybe<(
          { __typename?: 'CategoryTree' }
          & Pick<CategoryTree, 'id' | 'name' | 'description' | 'url_key' | 'url_path' | 'include_in_menu' | 'path'>
          & { products?: Maybe<(
            { __typename?: 'CategoryProducts' }
            & Pick<CategoryProducts, 'total_count'>
          )> }
        )>>> }
      )>>> }
    )>>> }
  )> }
);

export type CmsBlockQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CmsBlockQuery = (
  { __typename?: 'Query' }
  & { cmsBlocks?: Maybe<(
    { __typename?: 'CmsBlocks' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'CmsBlock' }
      & Pick<CmsBlock, 'content' | 'identifier' | 'title'>
    )>>> }
  )> }
);

export type CmsPageQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CmsPageQuery = (
  { __typename?: 'Query' }
  & { cmsPage?: Maybe<(
    { __typename?: 'CmsPage' }
    & Pick<CmsPage, 'content' | 'content_heading' | 'page_layout' | 'title' | 'url_key'>
  )> }
);

export type CountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesQuery = (
  { __typename?: 'Query' }
  & { countries?: Maybe<Array<Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'full_name_locale' | 'full_name_english'>
    & { available_regions?: Maybe<Array<Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'id' | 'name' | 'code'>
    )>>> }
  )>>> }
);

export type CustomerQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'Customer' }
    & DomainCustomerFragment
  )> }
);

export type DomainCustomerFragment = (
  { __typename?: 'Customer' }
  & CreationCustomerFragment
);

export type CreationCustomerFragment = (
  { __typename?: 'Customer' }
  & Pick<Customer, 'firstname' | 'lastname' | 'email' | 'phone' | 'sign_up_for_updates' | 'is_new' | 'taxvat' | 'industry_type' | 'business_type' | 'hear_about' | 'tradeshow_specify' | 'other_specify' | 'is_subscribed'>
  & { id: Customer['customer_id'] }
  & { addresses?: Maybe<Array<Maybe<(
    { __typename?: 'CustomerAddress' }
    & AddressFragment
  )>>>, orders?: Maybe<(
    { __typename?: 'CustomerOrders' }
    & CustomerOrdersFragment
  )> }
);

export type AddressFragment = (
  { __typename?: 'CustomerAddress' }
  & Pick<CustomerAddress, 'id' | 'company' | 'city' | 'country_code' | 'firstname' | 'lastname' | 'email' | 'postcode' | 'address_label' | 'street' | 'telephone' | 'default_billing' | 'default_shipping'>
  & { region?: Maybe<(
    { __typename?: 'CustomerAddressRegion' }
    & Pick<CustomerAddressRegion, 'region' | 'region_code' | 'region_id'>
  )> }
);

export type CustomerOrdersFragment = (
  { __typename?: 'CustomerOrders' }
  & Pick<CustomerOrders, 'total_count'>
  & { items: Array<Maybe<(
    { __typename?: 'CustomerOrder' }
    & Pick<CustomerOrder, 'order_date' | 'number' | 'status'>
    & { total?: Maybe<(
      { __typename?: 'OrderTotal' }
      & { grand_total: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'value'>
      ) }
    )>, shipping_address?: Maybe<(
      { __typename?: 'OrderAddress' }
      & Pick<OrderAddress, 'city' | 'street' | 'firstname' | 'lastname'>
    )> }
  )>>, page_info?: Maybe<(
    { __typename?: 'SearchResultPageInfo' }
    & Pick<SearchResultPageInfo, 'current_page' | 'page_size' | 'total_pages'>
  )> }
);

export type ExpressRegistrationQueryVariables = Exact<{
  account_number: Scalars['String'];
}>;


export type ExpressRegistrationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'expressRegistration'>
);

export type GetCustomerFirstnameByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetCustomerFirstnameByEmailQuery = (
  { __typename?: 'Query' }
  & { getCustomerFirstnameByEmail?: Maybe<(
    { __typename?: 'CustomerFirstnameByEmailOutput' }
    & Pick<CustomerFirstnameByEmailOutput, 'firstname'>
  )> }
);

export type Unnamed_2_QueryVariables = Exact<{ [key: string]: never; }>;


export type Unnamed_2_Query = (
  { __typename?: 'Query' }
  & { getSalesRepData?: Maybe<Array<Maybe<(
    { __typename?: 'SalesRepData' }
    & Pick<SalesRepData, 'sales_rep_email' | 'sales_rep_name'>
  )>>> }
);

export type IsEmailAvailableQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type IsEmailAvailableQuery = (
  { __typename?: 'Query' }
  & { isEmailAvailable?: Maybe<(
    { __typename?: 'IsEmailAvailableOutput' }
    & { isEmailAvailable: IsEmailAvailableOutput['is_email_available'] }
  )> }
);

export type CustomerCheckTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerCheckTokenQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
  )> }
);

export type GetProductInfoQueryVariables = Exact<{
  sku?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GetProductInfoQuery = (
  { __typename?: 'Query' }
  & { getProductInfo?: Maybe<Array<Maybe<(
    { __typename?: 'GetProductInfo' }
    & Pick<GetProductInfo, 'image' | 'name' | 'price' | 'description'>
  )>>> }
);

export type Unnamed_3_QueryVariables = Exact<{ [key: string]: never; }>;


export type Unnamed_3_Query = (
  { __typename?: 'Query' }
  & { cmsPage?: Maybe<(
    { __typename?: 'CmsPage' }
    & Pick<CmsPage, 'title' | 'url_key' | 'content'>
  )> }
);

export type ProductQueryVariables = Exact<{
  filters: ProductAttributeFilterInput;
}>;


export type ProductQuery = (
  { __typename?: 'Query' }
  & { products?: Maybe<(
    { __typename?: 'Products' }
    & Pick<Products, 'total_count'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'BundleProduct' }
      & Pick<BundleProduct, 'id' | 'name' | 'sku' | 'url_key'>
      & { description?: Maybe<(
        { __typename?: 'ComplexTextValue' }
        & Pick<ComplexTextValue, 'html'>
      )>, media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    ) | (
      { __typename?: 'ConfigurableProduct' }
      & Pick<ConfigurableProduct, 'id' | 'name' | 'sku' | 'url_key'>
      & { description?: Maybe<(
        { __typename?: 'ComplexTextValue' }
        & Pick<ComplexTextValue, 'html'>
      )>, media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    ) | (
      { __typename?: 'SimpleProduct' }
      & Pick<SimpleProduct, 'id' | 'name' | 'sku' | 'url_key'>
      & { description?: Maybe<(
        { __typename?: 'ComplexTextValue' }
        & Pick<ComplexTextValue, 'html'>
      )>, media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    ) | (
      { __typename?: 'DownloadableProduct' }
      & Pick<DownloadableProduct, 'id' | 'name' | 'sku' | 'url_key'>
      & { description?: Maybe<(
        { __typename?: 'ComplexTextValue' }
        & Pick<ComplexTextValue, 'html'>
      )>, media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    ) | (
      { __typename?: 'GroupedProduct' }
      & Pick<GroupedProduct, 'id' | 'name' | 'sku' | 'url_key'>
      & { description?: Maybe<(
        { __typename?: 'ComplexTextValue' }
        & Pick<ComplexTextValue, 'html'>
      )>, media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    ) | (
      { __typename?: 'VirtualProduct' }
      & Pick<VirtualProduct, 'id' | 'name' | 'sku' | 'url_key'>
      & { description?: Maybe<(
        { __typename?: 'ComplexTextValue' }
        & Pick<ComplexTextValue, 'html'>
      )>, media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    )>>> }
  )> }
);

export type ProductsQueryVariables = Exact<{
  filters: ProductAttributeFilterInput;
  sort?: Maybe<ProductAttributeSortInput>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
}>;


export type ProductsQuery = (
  { __typename?: 'Query' }
  & { products?: Maybe<(
    { __typename?: 'Products' }
    & Pick<Products, 'total_count'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'BundleProduct' }
      & Pick<BundleProduct, 'id' | 'name' | 'sku' | 'url_key' | 'attribute_set_name'>
      & { media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    ) | (
      { __typename?: 'ConfigurableProduct' }
      & Pick<ConfigurableProduct, 'id' | 'name' | 'sku' | 'url_key' | 'attribute_set_name'>
      & { media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    ) | (
      { __typename?: 'SimpleProduct' }
      & Pick<SimpleProduct, 'id' | 'name' | 'sku' | 'url_key' | 'attribute_set_name'>
      & { media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    ) | (
      { __typename?: 'DownloadableProduct' }
      & Pick<DownloadableProduct, 'id' | 'name' | 'sku' | 'url_key' | 'attribute_set_name'>
      & { media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    ) | (
      { __typename?: 'GroupedProduct' }
      & Pick<GroupedProduct, 'id' | 'name' | 'sku' | 'url_key' | 'attribute_set_name'>
      & { media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    ) | (
      { __typename?: 'VirtualProduct' }
      & Pick<VirtualProduct, 'id' | 'name' | 'sku' | 'url_key' | 'attribute_set_name'>
      & { media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    )>>>, page_info?: Maybe<(
      { __typename?: 'SearchResultPageInfo' }
      & Pick<SearchResultPageInfo, 'page_size' | 'total_pages' | 'current_page'>
    )>, sort_fields?: Maybe<(
      { __typename?: 'SortFields' }
      & Pick<SortFields, 'default'>
      & { options?: Maybe<Array<Maybe<(
        { __typename?: 'SortField' }
        & Pick<SortField, 'label' | 'value'>
      )>>> }
    )> }
  )> }
);

export type AllFilesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllFilesQuery = (
  { __typename?: 'Query' }
  & { getAllFiles?: Maybe<Array<Maybe<(
    { __typename?: 'FileData' }
    & DomainFileDataFragment
  )>>> }
);

export type CustomerOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerOrdersQuery = (
  { __typename?: 'Query' }
  & { customerOrders?: Maybe<(
    { __typename?: 'CustomerOrders' }
    & { items: Array<Maybe<(
      { __typename?: 'CustomerOrder' }
      & Pick<CustomerOrder, 'id' | 'order_number' | 'created_at' | 'grand_total' | 'status'>
    )>> }
  )> }
);

export type SearchQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<ProductAttributeSortInput>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}>;


export type SearchQuery = (
  { __typename?: 'Query' }
  & { products?: Maybe<(
    { __typename?: 'Products' }
    & Pick<Products, 'total_count'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'BundleProduct' }
      & Pick<BundleProduct, 'id' | 'name' | 'sku' | 'url_key'>
      & { media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    ) | (
      { __typename?: 'ConfigurableProduct' }
      & Pick<ConfigurableProduct, 'id' | 'name' | 'sku' | 'url_key'>
      & { media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    ) | (
      { __typename?: 'SimpleProduct' }
      & Pick<SimpleProduct, 'id' | 'name' | 'sku' | 'url_key'>
      & { media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    ) | (
      { __typename?: 'DownloadableProduct' }
      & Pick<DownloadableProduct, 'id' | 'name' | 'sku' | 'url_key'>
      & { media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    ) | (
      { __typename?: 'GroupedProduct' }
      & Pick<GroupedProduct, 'id' | 'name' | 'sku' | 'url_key'>
      & { media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    ) | (
      { __typename?: 'VirtualProduct' }
      & Pick<VirtualProduct, 'id' | 'name' | 'sku' | 'url_key'>
      & { media_gallery_entries?: Maybe<Array<Maybe<(
        { __typename?: 'MediaGalleryEntry' }
        & Pick<MediaGalleryEntry, 'id' | 'file' | 'disabled'>
        & { content?: Maybe<(
          { __typename?: 'ProductMediaGalleryEntriesContent' }
          & Pick<ProductMediaGalleryEntriesContent, 'base64_encoded_data' | 'name' | 'type'>
        )> }
      )>>>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryTree' }
        & Pick<CategoryTree, 'id'>
      )>>>, image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, small_image?: Maybe<(
        { __typename?: 'ProductImage' }
        & Pick<ProductImage, 'label' | 'url'>
      )>, price_range: (
        { __typename?: 'PriceRange' }
        & { maximum_price?: Maybe<(
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        )>, minimum_price: (
          { __typename?: 'ProductPrice' }
          & { discount?: Maybe<(
            { __typename?: 'ProductDiscount' }
            & Pick<ProductDiscount, 'amount_off' | 'percent_off'>
          )>, final_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ), fixed_product_taxes?: Maybe<Array<Maybe<(
            { __typename?: 'FixedProductTax' }
            & Pick<FixedProductTax, 'label'>
            & { amount?: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'currency' | 'value'>
            )> }
          )>>>, regular_price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'value'>
          ) }
        ) }
      ), tier_prices?: Maybe<Array<Maybe<(
        { __typename?: 'ProductTierPrices' }
        & Pick<ProductTierPrices, 'customer_group_id' | 'percentage_value' | 'qty' | 'value' | 'website_id'>
      )>>> }
    )>>>, filters?: Maybe<Array<Maybe<(
      { __typename?: 'LayerFilter' }
      & Pick<LayerFilter, 'filter_items_count' | 'name' | 'request_var'>
      & { filter_items?: Maybe<Array<Maybe<(
        { __typename?: 'LayerFilterItem' }
        & Pick<LayerFilterItem, 'items_count' | 'label' | 'value_string'>
      ) | (
        { __typename?: 'SwatchLayerFilterItem' }
        & Pick<SwatchLayerFilterItem, 'items_count' | 'label' | 'value_string'>
      )>>> }
    )>>>, page_info?: Maybe<(
      { __typename?: 'SearchResultPageInfo' }
      & Pick<SearchResultPageInfo, 'page_size' | 'total_pages' | 'current_page'>
    )>, sort_fields?: Maybe<(
      { __typename?: 'SortFields' }
      & Pick<SortFields, 'default'>
      & { options?: Maybe<Array<Maybe<(
        { __typename?: 'SortField' }
        & Pick<SortField, 'label' | 'value'>
      )>>> }
    )> }
  )> }
);

export type Unnamed_4_QueryVariables = Exact<{ [key: string]: never; }>;


export type Unnamed_4_Query = (
  { __typename?: 'Query' }
  & { storeConfig?: Maybe<(
    { __typename?: 'StoreConfig' }
    & Pick<StoreConfig, 'secure_base_url' | 'secure_base_link_url' | 'secure_base_media_url' | 'secure_base_static_url' | 'base_currency_code' | 'code' | 'copyright' | 'default_description' | 'default_keywords' | 'default_title' | 'head_shortcut_icon' | 'header_logo_src' | 'welcome' | 'website_id' | 'google_api_key'>
  )> }
);

export type UrlResolverQueryVariables = Exact<{
  url: Scalars['String'];
}>;


export type UrlResolverQuery = (
  { __typename?: 'Query' }
  & { urlResolver?: Maybe<(
    { __typename?: 'EntityUrl' }
    & Pick<EntityUrl, 'id' | 'relative_url' | 'canonical_url' | 'sku' | 'attribute_set_id' | 'type'>
  )> }
);

export type WishlistsQueryVariables = Exact<{ [key: string]: never; }>;


export type WishlistsQuery = (
  { __typename?: 'Query' }
  & { getWishlists?: Maybe<Array<Maybe<(
    { __typename?: 'WishlistJola' }
    & DomainWishlistFragment
  )>>> }
);

export const DomainCartFragmentDoc = gql`
    fragment DomainCart on Cart {
  id
  items {
    id
    document_names {
      file_name
      uploaded_file_name
    }
    product {
      sku
      stock_status
      price_range {
        minimum_price {
          final_price {
            value
            currency
          }
        }
      }
    }
    comment
    quantity
    ... on SimpleCartItem {
      customizable_options {
        id
        label
        type
        values {
          id
          label
          price {
            value
          }
          value
        }
      }
      id
      quantity
      product {
        id
        sku
        name
        url_key
        ffi_collection
        ffi_frmdimw
        ffi_frmdimh
        ffi_frmdimd
        image {
          url(width: 700, height: 700)
          label
        }
        small_image {
          url(width: 265, height: 265)
          label
        }
        stock_status
        price_range {
          minimum_price {
            final_price {
              value
              currency
            }
          }
        }
      }
      prices {
        price {
          currency
          value
        }
      }
    }
  }
  shipping_addresses {
    firstname
    lastname
    telephone
    company
    country {
      code
      label
    }
    region {
      code
      label
    }
    city
    street
    postcode
    order_email
  }
  billing_address {
    firstname
    lastname
    telephone
    company
    country {
      code
      label
    }
    region {
      code
      label
    }
    city
    street
    postcode
    order_email
  }
}
    `;
export const DomainOrderFragmentDoc = gql`
    fragment DomainOrder on Order {
  order_number
  project_name
  project_due_date
  timeline
  additional_details
}
    `;
export const DomainTagDataFragmentDoc = gql`
    fragment DomainTagData on TagData {
  category
  name
  tag_id
  file_type {
    documents
    marketing
    videos
  }
}
    `;
export const DomainFileDataFragmentDoc = gql`
    fragment DomainFileData on FileData {
  divisions {
    contract
    retail
    senior
  }
  file
  file_id
  filename
  size
  title
  file_type {
    documents
    marketing
    videos
  }
  thumbnail
  youtube_url
  tags {
    ...DomainTagData
  }
  updated_at
}
    ${DomainTagDataFragmentDoc}`;
export const DomainProductFragmentDoc = gql`
    fragment DomainProduct on ProductInterface {
  id
  sku
  name
  ffi_lanect
  ffi_laners
  ffi_lanesl
  ffi_isvisible
  fficustopt_type_filterable
  is_new
  ffi_casegd
  ffi_fullyuph
  url_key
  attribute_set_name
  image {
    url(width: 700, height: 700)
    label
  }
  small_image {
    url(width: 700, height: 700)
    label
  }
  price_range {
    minimum_price {
      final_price {
        value
      }
    }
  }
  ffi_frmdimw
  ffi_frmdimh
  ffi_frmdimd
  categories {
    uid
    name
  }
  description {
    html
  }
}
    `;
export const DomainWishlistItemFragmentDoc = gql`
    fragment DomainWishlistItem on WishlistItemJola {
  id
  item_name
  selected_customizable_options {
    option_title
    option_id
    option_value_title
    option_category
    option_value_id
  }
  product {
    ...DomainProduct
  }
}
    ${DomainProductFragmentDoc}`;
export const DomainWishlistFragmentDoc = gql`
    fragment DomainWishlist on WishlistJola {
  id
  items_count
  name
  items {
    ...DomainWishlistItem
  }
}
    ${DomainWishlistItemFragmentDoc}`;
export const AddressFragmentDoc = gql`
    fragment Address on CustomerAddress {
  id
  company
  city
  country_code
  firstname
  lastname
  email
  postcode
  address_label
  region {
    region
    region_code
    region_id
  }
  street
  telephone
  default_billing
  default_shipping
}
    `;
export const CustomerOrdersFragmentDoc = gql`
    fragment CustomerOrders on CustomerOrders {
  items {
    order_date
    number
    status
    total {
      grand_total {
        currency
        value
      }
    }
    shipping_address {
      city
      street
      firstname
      lastname
    }
  }
  page_info {
    current_page
    page_size
    total_pages
  }
  total_count
}
    `;
export const CreationCustomerFragmentDoc = gql`
    fragment CreationCustomer on Customer {
  id: customer_id
  firstname
  lastname
  email
  phone
  sign_up_for_updates
  is_new
  taxvat
  industry_type
  business_type
  hear_about
  tradeshow_specify
  other_specify
  is_subscribed
  addresses {
    ...Address
  }
  orders {
    ...CustomerOrders
  }
}
    ${AddressFragmentDoc}
${CustomerOrdersFragmentDoc}`;
export const DomainCustomerFragmentDoc = gql`
    fragment DomainCustomer on Customer {
  ...CreationCustomer
}
    ${CreationCustomerFragmentDoc}`;
export const AddCustomerOrderItemAttributesDocument = gql`
    mutation AddCustomerOrderItemAttributes($input: OrderItemAttributes) {
  addCustomerOrderItemAttributes(input: $input)
}
    `;
export type AddCustomerOrderItemAttributesMutationFn = ApolloReactCommon.MutationFunction<AddCustomerOrderItemAttributesMutation, AddCustomerOrderItemAttributesMutationVariables>;
export type AddCustomerOrderItemAttributesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddCustomerOrderItemAttributesMutation, AddCustomerOrderItemAttributesMutationVariables>, 'mutation'>;

    export const AddCustomerOrderItemAttributesComponent = (props: AddCustomerOrderItemAttributesComponentProps) => (
      <ApolloReactComponents.Mutation<AddCustomerOrderItemAttributesMutation, AddCustomerOrderItemAttributesMutationVariables> mutation={AddCustomerOrderItemAttributesDocument} {...props} />
    );
    

/**
 * __useAddCustomerOrderItemAttributesMutation__
 *
 * To run a mutation, you first call `useAddCustomerOrderItemAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomerOrderItemAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomerOrderItemAttributesMutation, { data, loading, error }] = useAddCustomerOrderItemAttributesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCustomerOrderItemAttributesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCustomerOrderItemAttributesMutation, AddCustomerOrderItemAttributesMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCustomerOrderItemAttributesMutation, AddCustomerOrderItemAttributesMutationVariables>(AddCustomerOrderItemAttributesDocument, baseOptions);
      }
export type AddCustomerOrderItemAttributesMutationHookResult = ReturnType<typeof useAddCustomerOrderItemAttributesMutation>;
export type AddCustomerOrderItemAttributesMutationResult = ApolloReactCommon.MutationResult<AddCustomerOrderItemAttributesMutation>;
export type AddCustomerOrderItemAttributesMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCustomerOrderItemAttributesMutation, AddCustomerOrderItemAttributesMutationVariables>;
export const AddSimpleProductsToCartDocument = gql`
    mutation AddSimpleProductsToCart($input: AddSimpleProductsToCartInput) {
  addSimpleProductsToCart(input: $input) {
    cart {
      ...DomainCart
    }
  }
}
    ${DomainCartFragmentDoc}`;
export type AddSimpleProductsToCartMutationFn = ApolloReactCommon.MutationFunction<AddSimpleProductsToCartMutation, AddSimpleProductsToCartMutationVariables>;
export type AddSimpleProductsToCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddSimpleProductsToCartMutation, AddSimpleProductsToCartMutationVariables>, 'mutation'>;

    export const AddSimpleProductsToCartComponent = (props: AddSimpleProductsToCartComponentProps) => (
      <ApolloReactComponents.Mutation<AddSimpleProductsToCartMutation, AddSimpleProductsToCartMutationVariables> mutation={AddSimpleProductsToCartDocument} {...props} />
    );
    

/**
 * __useAddSimpleProductsToCartMutation__
 *
 * To run a mutation, you first call `useAddSimpleProductsToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSimpleProductsToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSimpleProductsToCartMutation, { data, loading, error }] = useAddSimpleProductsToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSimpleProductsToCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSimpleProductsToCartMutation, AddSimpleProductsToCartMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSimpleProductsToCartMutation, AddSimpleProductsToCartMutationVariables>(AddSimpleProductsToCartDocument, baseOptions);
      }
export type AddSimpleProductsToCartMutationHookResult = ReturnType<typeof useAddSimpleProductsToCartMutation>;
export type AddSimpleProductsToCartMutationResult = ApolloReactCommon.MutationResult<AddSimpleProductsToCartMutation>;
export type AddSimpleProductsToCartMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSimpleProductsToCartMutation, AddSimpleProductsToCartMutationVariables>;
export const PlaceOrderDocument = gql`
    mutation PlaceOrder($input: PlaceOrderInput!) {
  placeOrder(input: $input) {
    order {
      ...DomainOrder
    }
  }
}
    ${DomainOrderFragmentDoc}`;
export type PlaceOrderMutationFn = ApolloReactCommon.MutationFunction<PlaceOrderMutation, PlaceOrderMutationVariables>;
export type PlaceOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PlaceOrderMutation, PlaceOrderMutationVariables>, 'mutation'>;

    export const PlaceOrderComponent = (props: PlaceOrderComponentProps) => (
      <ApolloReactComponents.Mutation<PlaceOrderMutation, PlaceOrderMutationVariables> mutation={PlaceOrderDocument} {...props} />
    );
    

/**
 * __usePlaceOrderMutation__
 *
 * To run a mutation, you first call `usePlaceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeOrderMutation, { data, loading, error }] = usePlaceOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PlaceOrderMutation, PlaceOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<PlaceOrderMutation, PlaceOrderMutationVariables>(PlaceOrderDocument, baseOptions);
      }
export type PlaceOrderMutationHookResult = ReturnType<typeof usePlaceOrderMutation>;
export type PlaceOrderMutationResult = ApolloReactCommon.MutationResult<PlaceOrderMutation>;
export type PlaceOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<PlaceOrderMutation, PlaceOrderMutationVariables>;
export const RemoveAllItemsFromCartDocument = gql`
    mutation RemoveAllItemsFromCart($cart_id: String!) {
  removeAllItemsFromCart(cart_id: $cart_id) {
    cart {
      ...DomainCart
    }
  }
}
    ${DomainCartFragmentDoc}`;
export type RemoveAllItemsFromCartMutationFn = ApolloReactCommon.MutationFunction<RemoveAllItemsFromCartMutation, RemoveAllItemsFromCartMutationVariables>;
export type RemoveAllItemsFromCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveAllItemsFromCartMutation, RemoveAllItemsFromCartMutationVariables>, 'mutation'>;

    export const RemoveAllItemsFromCartComponent = (props: RemoveAllItemsFromCartComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveAllItemsFromCartMutation, RemoveAllItemsFromCartMutationVariables> mutation={RemoveAllItemsFromCartDocument} {...props} />
    );
    

/**
 * __useRemoveAllItemsFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveAllItemsFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAllItemsFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAllItemsFromCartMutation, { data, loading, error }] = useRemoveAllItemsFromCartMutation({
 *   variables: {
 *      cart_id: // value for 'cart_id'
 *   },
 * });
 */
export function useRemoveAllItemsFromCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAllItemsFromCartMutation, RemoveAllItemsFromCartMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAllItemsFromCartMutation, RemoveAllItemsFromCartMutationVariables>(RemoveAllItemsFromCartDocument, baseOptions);
      }
export type RemoveAllItemsFromCartMutationHookResult = ReturnType<typeof useRemoveAllItemsFromCartMutation>;
export type RemoveAllItemsFromCartMutationResult = ApolloReactCommon.MutationResult<RemoveAllItemsFromCartMutation>;
export type RemoveAllItemsFromCartMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAllItemsFromCartMutation, RemoveAllItemsFromCartMutationVariables>;
export const RemoveItemFromCartDocument = gql`
    mutation RemoveItemFromCart($input: RemoveItemFromCartInput!) {
  removeItemFromCart(input: $input) {
    cart {
      ...DomainCart
    }
  }
}
    ${DomainCartFragmentDoc}`;
export type RemoveItemFromCartMutationFn = ApolloReactCommon.MutationFunction<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>;
export type RemoveItemFromCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>, 'mutation'>;

    export const RemoveItemFromCartComponent = (props: RemoveItemFromCartComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables> mutation={RemoveItemFromCartDocument} {...props} />
    );
    

/**
 * __useRemoveItemFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveItemFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemFromCartMutation, { data, loading, error }] = useRemoveItemFromCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveItemFromCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>(RemoveItemFromCartDocument, baseOptions);
      }
export type RemoveItemFromCartMutationHookResult = ReturnType<typeof useRemoveItemFromCartMutation>;
export type RemoveItemFromCartMutationResult = ApolloReactCommon.MutationResult<RemoveItemFromCartMutation>;
export type RemoveItemFromCartMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>;
export const SetBillingAddressOnCartDocument = gql`
    mutation SetBillingAddressOnCart($input: SetBillingAddressOnCartInput!) {
  setBillingAddressOnCart(input: $input) {
    cart {
      email
    }
  }
}
    `;
export type SetBillingAddressOnCartMutationFn = ApolloReactCommon.MutationFunction<SetBillingAddressOnCartMutation, SetBillingAddressOnCartMutationVariables>;
export type SetBillingAddressOnCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetBillingAddressOnCartMutation, SetBillingAddressOnCartMutationVariables>, 'mutation'>;

    export const SetBillingAddressOnCartComponent = (props: SetBillingAddressOnCartComponentProps) => (
      <ApolloReactComponents.Mutation<SetBillingAddressOnCartMutation, SetBillingAddressOnCartMutationVariables> mutation={SetBillingAddressOnCartDocument} {...props} />
    );
    

/**
 * __useSetBillingAddressOnCartMutation__
 *
 * To run a mutation, you first call `useSetBillingAddressOnCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBillingAddressOnCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBillingAddressOnCartMutation, { data, loading, error }] = useSetBillingAddressOnCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetBillingAddressOnCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetBillingAddressOnCartMutation, SetBillingAddressOnCartMutationVariables>) {
        return ApolloReactHooks.useMutation<SetBillingAddressOnCartMutation, SetBillingAddressOnCartMutationVariables>(SetBillingAddressOnCartDocument, baseOptions);
      }
export type SetBillingAddressOnCartMutationHookResult = ReturnType<typeof useSetBillingAddressOnCartMutation>;
export type SetBillingAddressOnCartMutationResult = ApolloReactCommon.MutationResult<SetBillingAddressOnCartMutation>;
export type SetBillingAddressOnCartMutationOptions = ApolloReactCommon.BaseMutationOptions<SetBillingAddressOnCartMutation, SetBillingAddressOnCartMutationVariables>;
export const SetCartAddressesDocument = gql`
    mutation SetCartAddresses($cart_id: String!, $shipping_address: ShippingAddressInput!) {
  setShippingAddressesOnCart(input: {cart_id: $cart_id, shipping_addresses: [$shipping_address]}) {
    cart {
      ...DomainCart
    }
  }
}
    ${DomainCartFragmentDoc}`;
export type SetCartAddressesMutationFn = ApolloReactCommon.MutationFunction<SetCartAddressesMutation, SetCartAddressesMutationVariables>;
export type SetCartAddressesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetCartAddressesMutation, SetCartAddressesMutationVariables>, 'mutation'>;

    export const SetCartAddressesComponent = (props: SetCartAddressesComponentProps) => (
      <ApolloReactComponents.Mutation<SetCartAddressesMutation, SetCartAddressesMutationVariables> mutation={SetCartAddressesDocument} {...props} />
    );
    

/**
 * __useSetCartAddressesMutation__
 *
 * To run a mutation, you first call `useSetCartAddressesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCartAddressesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCartAddressesMutation, { data, loading, error }] = useSetCartAddressesMutation({
 *   variables: {
 *      cart_id: // value for 'cart_id'
 *      shipping_address: // value for 'shipping_address'
 *   },
 * });
 */
export function useSetCartAddressesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetCartAddressesMutation, SetCartAddressesMutationVariables>) {
        return ApolloReactHooks.useMutation<SetCartAddressesMutation, SetCartAddressesMutationVariables>(SetCartAddressesDocument, baseOptions);
      }
export type SetCartAddressesMutationHookResult = ReturnType<typeof useSetCartAddressesMutation>;
export type SetCartAddressesMutationResult = ApolloReactCommon.MutationResult<SetCartAddressesMutation>;
export type SetCartAddressesMutationOptions = ApolloReactCommon.BaseMutationOptions<SetCartAddressesMutation, SetCartAddressesMutationVariables>;
export const SetCartMethodsAndPlaceOrderDocument = gql`
    mutation SetCartMethodsAndPlaceOrder($cart_id: String!, $shipping_methods: [ShippingMethodInput]!, $payment_method: PaymentMethodInput!, $placeOrderInput: PlaceOrderInput!) {
  setShippingMethodsOnCart(input: {cart_id: $cart_id, shipping_methods: $shipping_methods}) {
    cart {
      ...DomainCart
    }
  }
  setPaymentMethodOnCart(input: {cart_id: $cart_id, payment_method: $payment_method}) {
    cart {
      ...DomainCart
    }
  }
  placeOrder(input: $placeOrderInput) {
    order {
      ...DomainOrder
    }
  }
}
    ${DomainCartFragmentDoc}
${DomainOrderFragmentDoc}`;
export type SetCartMethodsAndPlaceOrderMutationFn = ApolloReactCommon.MutationFunction<SetCartMethodsAndPlaceOrderMutation, SetCartMethodsAndPlaceOrderMutationVariables>;
export type SetCartMethodsAndPlaceOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetCartMethodsAndPlaceOrderMutation, SetCartMethodsAndPlaceOrderMutationVariables>, 'mutation'>;

    export const SetCartMethodsAndPlaceOrderComponent = (props: SetCartMethodsAndPlaceOrderComponentProps) => (
      <ApolloReactComponents.Mutation<SetCartMethodsAndPlaceOrderMutation, SetCartMethodsAndPlaceOrderMutationVariables> mutation={SetCartMethodsAndPlaceOrderDocument} {...props} />
    );
    

/**
 * __useSetCartMethodsAndPlaceOrderMutation__
 *
 * To run a mutation, you first call `useSetCartMethodsAndPlaceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCartMethodsAndPlaceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCartMethodsAndPlaceOrderMutation, { data, loading, error }] = useSetCartMethodsAndPlaceOrderMutation({
 *   variables: {
 *      cart_id: // value for 'cart_id'
 *      shipping_methods: // value for 'shipping_methods'
 *      payment_method: // value for 'payment_method'
 *      placeOrderInput: // value for 'placeOrderInput'
 *   },
 * });
 */
export function useSetCartMethodsAndPlaceOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetCartMethodsAndPlaceOrderMutation, SetCartMethodsAndPlaceOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<SetCartMethodsAndPlaceOrderMutation, SetCartMethodsAndPlaceOrderMutationVariables>(SetCartMethodsAndPlaceOrderDocument, baseOptions);
      }
export type SetCartMethodsAndPlaceOrderMutationHookResult = ReturnType<typeof useSetCartMethodsAndPlaceOrderMutation>;
export type SetCartMethodsAndPlaceOrderMutationResult = ApolloReactCommon.MutationResult<SetCartMethodsAndPlaceOrderMutation>;
export type SetCartMethodsAndPlaceOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<SetCartMethodsAndPlaceOrderMutation, SetCartMethodsAndPlaceOrderMutationVariables>;
export const SetPaymentMethodOnCartDocument = gql`
    mutation SetPaymentMethodOnCart($input: SetPaymentMethodOnCartInput!) {
  setPaymentMethodOnCart(input: $input) {
    cart {
      ...DomainCart
    }
  }
}
    ${DomainCartFragmentDoc}`;
export type SetPaymentMethodOnCartMutationFn = ApolloReactCommon.MutationFunction<SetPaymentMethodOnCartMutation, SetPaymentMethodOnCartMutationVariables>;
export type SetPaymentMethodOnCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetPaymentMethodOnCartMutation, SetPaymentMethodOnCartMutationVariables>, 'mutation'>;

    export const SetPaymentMethodOnCartComponent = (props: SetPaymentMethodOnCartComponentProps) => (
      <ApolloReactComponents.Mutation<SetPaymentMethodOnCartMutation, SetPaymentMethodOnCartMutationVariables> mutation={SetPaymentMethodOnCartDocument} {...props} />
    );
    

/**
 * __useSetPaymentMethodOnCartMutation__
 *
 * To run a mutation, you first call `useSetPaymentMethodOnCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPaymentMethodOnCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPaymentMethodOnCartMutation, { data, loading, error }] = useSetPaymentMethodOnCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPaymentMethodOnCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetPaymentMethodOnCartMutation, SetPaymentMethodOnCartMutationVariables>) {
        return ApolloReactHooks.useMutation<SetPaymentMethodOnCartMutation, SetPaymentMethodOnCartMutationVariables>(SetPaymentMethodOnCartDocument, baseOptions);
      }
export type SetPaymentMethodOnCartMutationHookResult = ReturnType<typeof useSetPaymentMethodOnCartMutation>;
export type SetPaymentMethodOnCartMutationResult = ApolloReactCommon.MutationResult<SetPaymentMethodOnCartMutation>;
export type SetPaymentMethodOnCartMutationOptions = ApolloReactCommon.BaseMutationOptions<SetPaymentMethodOnCartMutation, SetPaymentMethodOnCartMutationVariables>;
export const SetShippingAddressesOnCartDocument = gql`
    mutation SetShippingAddressesOnCart($cart_id: String!, $customer_address_id: Int) {
  setShippingAddressesOnCart(input: {cart_id: $cart_id, shipping_addresses: [{customer_address_id: $customer_address_id}]}) {
    cart {
      email
    }
  }
}
    `;
export type SetShippingAddressesOnCartMutationFn = ApolloReactCommon.MutationFunction<SetShippingAddressesOnCartMutation, SetShippingAddressesOnCartMutationVariables>;
export type SetShippingAddressesOnCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetShippingAddressesOnCartMutation, SetShippingAddressesOnCartMutationVariables>, 'mutation'>;

    export const SetShippingAddressesOnCartComponent = (props: SetShippingAddressesOnCartComponentProps) => (
      <ApolloReactComponents.Mutation<SetShippingAddressesOnCartMutation, SetShippingAddressesOnCartMutationVariables> mutation={SetShippingAddressesOnCartDocument} {...props} />
    );
    

/**
 * __useSetShippingAddressesOnCartMutation__
 *
 * To run a mutation, you first call `useSetShippingAddressesOnCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShippingAddressesOnCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShippingAddressesOnCartMutation, { data, loading, error }] = useSetShippingAddressesOnCartMutation({
 *   variables: {
 *      cart_id: // value for 'cart_id'
 *      customer_address_id: // value for 'customer_address_id'
 *   },
 * });
 */
export function useSetShippingAddressesOnCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetShippingAddressesOnCartMutation, SetShippingAddressesOnCartMutationVariables>) {
        return ApolloReactHooks.useMutation<SetShippingAddressesOnCartMutation, SetShippingAddressesOnCartMutationVariables>(SetShippingAddressesOnCartDocument, baseOptions);
      }
export type SetShippingAddressesOnCartMutationHookResult = ReturnType<typeof useSetShippingAddressesOnCartMutation>;
export type SetShippingAddressesOnCartMutationResult = ApolloReactCommon.MutationResult<SetShippingAddressesOnCartMutation>;
export type SetShippingAddressesOnCartMutationOptions = ApolloReactCommon.BaseMutationOptions<SetShippingAddressesOnCartMutation, SetShippingAddressesOnCartMutationVariables>;
export const SetShippingMethodsOnCartDocument = gql`
    mutation SetShippingMethodsOnCart($input: SetShippingMethodsOnCartInput!) {
  setShippingMethodsOnCart(input: $input) {
    cart {
      ...DomainCart
    }
  }
}
    ${DomainCartFragmentDoc}`;
export type SetShippingMethodsOnCartMutationFn = ApolloReactCommon.MutationFunction<SetShippingMethodsOnCartMutation, SetShippingMethodsOnCartMutationVariables>;
export type SetShippingMethodsOnCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetShippingMethodsOnCartMutation, SetShippingMethodsOnCartMutationVariables>, 'mutation'>;

    export const SetShippingMethodsOnCartComponent = (props: SetShippingMethodsOnCartComponentProps) => (
      <ApolloReactComponents.Mutation<SetShippingMethodsOnCartMutation, SetShippingMethodsOnCartMutationVariables> mutation={SetShippingMethodsOnCartDocument} {...props} />
    );
    

/**
 * __useSetShippingMethodsOnCartMutation__
 *
 * To run a mutation, you first call `useSetShippingMethodsOnCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShippingMethodsOnCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShippingMethodsOnCartMutation, { data, loading, error }] = useSetShippingMethodsOnCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetShippingMethodsOnCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetShippingMethodsOnCartMutation, SetShippingMethodsOnCartMutationVariables>) {
        return ApolloReactHooks.useMutation<SetShippingMethodsOnCartMutation, SetShippingMethodsOnCartMutationVariables>(SetShippingMethodsOnCartDocument, baseOptions);
      }
export type SetShippingMethodsOnCartMutationHookResult = ReturnType<typeof useSetShippingMethodsOnCartMutation>;
export type SetShippingMethodsOnCartMutationResult = ApolloReactCommon.MutationResult<SetShippingMethodsOnCartMutation>;
export type SetShippingMethodsOnCartMutationOptions = ApolloReactCommon.BaseMutationOptions<SetShippingMethodsOnCartMutation, SetShippingMethodsOnCartMutationVariables>;
export const UpdateCartItemsDocument = gql`
    mutation UpdateCartItems($input: UpdateCartItemsInput!) {
  updateCartItems(input: $input) {
    cart {
      ...DomainCart
    }
  }
}
    ${DomainCartFragmentDoc}`;
export type UpdateCartItemsMutationFn = ApolloReactCommon.MutationFunction<UpdateCartItemsMutation, UpdateCartItemsMutationVariables>;
export type UpdateCartItemsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCartItemsMutation, UpdateCartItemsMutationVariables>, 'mutation'>;

    export const UpdateCartItemsComponent = (props: UpdateCartItemsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCartItemsMutation, UpdateCartItemsMutationVariables> mutation={UpdateCartItemsDocument} {...props} />
    );
    

/**
 * __useUpdateCartItemsMutation__
 *
 * To run a mutation, you first call `useUpdateCartItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemsMutation, { data, loading, error }] = useUpdateCartItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartItemsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCartItemsMutation, UpdateCartItemsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCartItemsMutation, UpdateCartItemsMutationVariables>(UpdateCartItemsDocument, baseOptions);
      }
export type UpdateCartItemsMutationHookResult = ReturnType<typeof useUpdateCartItemsMutation>;
export type UpdateCartItemsMutationResult = ApolloReactCommon.MutationResult<UpdateCartItemsMutation>;
export type UpdateCartItemsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCartItemsMutation, UpdateCartItemsMutationVariables>;
export const CreateCustomerAddressDocument = gql`
    mutation CreateCustomerAddress($input: CustomerAddressInput!) {
  createCustomerAddress(input: $input) {
    ...Address
  }
}
    ${AddressFragmentDoc}`;
export type CreateCustomerAddressMutationFn = ApolloReactCommon.MutationFunction<CreateCustomerAddressMutation, CreateCustomerAddressMutationVariables>;
export type CreateCustomerAddressComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCustomerAddressMutation, CreateCustomerAddressMutationVariables>, 'mutation'>;

    export const CreateCustomerAddressComponent = (props: CreateCustomerAddressComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCustomerAddressMutation, CreateCustomerAddressMutationVariables> mutation={CreateCustomerAddressDocument} {...props} />
    );
    

/**
 * __useCreateCustomerAddressMutation__
 *
 * To run a mutation, you first call `useCreateCustomerAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerAddressMutation, { data, loading, error }] = useCreateCustomerAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerAddressMutation, CreateCustomerAddressMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomerAddressMutation, CreateCustomerAddressMutationVariables>(CreateCustomerAddressDocument, baseOptions);
      }
export type CreateCustomerAddressMutationHookResult = ReturnType<typeof useCreateCustomerAddressMutation>;
export type CreateCustomerAddressMutationResult = ApolloReactCommon.MutationResult<CreateCustomerAddressMutation>;
export type CreateCustomerAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomerAddressMutation, CreateCustomerAddressMutationVariables>;
export const UpdateCustomerAddressDocument = gql`
    mutation UpdateCustomerAddress($id: Int!, $input: CustomerAddressInput) {
  updateCustomerAddress(id: $id, input: $input) {
    ...Address
  }
}
    ${AddressFragmentDoc}`;
export type UpdateCustomerAddressMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomerAddressMutation, UpdateCustomerAddressMutationVariables>;
export type UpdateCustomerAddressComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCustomerAddressMutation, UpdateCustomerAddressMutationVariables>, 'mutation'>;

    export const UpdateCustomerAddressComponent = (props: UpdateCustomerAddressComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCustomerAddressMutation, UpdateCustomerAddressMutationVariables> mutation={UpdateCustomerAddressDocument} {...props} />
    );
    

/**
 * __useUpdateCustomerAddressMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerAddressMutation, { data, loading, error }] = useUpdateCustomerAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerAddressMutation, UpdateCustomerAddressMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomerAddressMutation, UpdateCustomerAddressMutationVariables>(UpdateCustomerAddressDocument, baseOptions);
      }
export type UpdateCustomerAddressMutationHookResult = ReturnType<typeof useUpdateCustomerAddressMutation>;
export type UpdateCustomerAddressMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerAddressMutation>;
export type UpdateCustomerAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomerAddressMutation, UpdateCustomerAddressMutationVariables>;
export const AddCustomerQuoteItemAttributesDocument = gql`
    mutation addCustomerQuoteItemAttributes($input: QuoteItemAttributes) {
  addCustomerQuoteItemAttributes(input: $input)
}
    `;
export type AddCustomerQuoteItemAttributesMutationFn = ApolloReactCommon.MutationFunction<AddCustomerQuoteItemAttributesMutation, AddCustomerQuoteItemAttributesMutationVariables>;
export type AddCustomerQuoteItemAttributesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddCustomerQuoteItemAttributesMutation, AddCustomerQuoteItemAttributesMutationVariables>, 'mutation'>;

    export const AddCustomerQuoteItemAttributesComponent = (props: AddCustomerQuoteItemAttributesComponentProps) => (
      <ApolloReactComponents.Mutation<AddCustomerQuoteItemAttributesMutation, AddCustomerQuoteItemAttributesMutationVariables> mutation={AddCustomerQuoteItemAttributesDocument} {...props} />
    );
    

/**
 * __useAddCustomerQuoteItemAttributesMutation__
 *
 * To run a mutation, you first call `useAddCustomerQuoteItemAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomerQuoteItemAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomerQuoteItemAttributesMutation, { data, loading, error }] = useAddCustomerQuoteItemAttributesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCustomerQuoteItemAttributesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCustomerQuoteItemAttributesMutation, AddCustomerQuoteItemAttributesMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCustomerQuoteItemAttributesMutation, AddCustomerQuoteItemAttributesMutationVariables>(AddCustomerQuoteItemAttributesDocument, baseOptions);
      }
export type AddCustomerQuoteItemAttributesMutationHookResult = ReturnType<typeof useAddCustomerQuoteItemAttributesMutation>;
export type AddCustomerQuoteItemAttributesMutationResult = ApolloReactCommon.MutationResult<AddCustomerQuoteItemAttributesMutation>;
export type AddCustomerQuoteItemAttributesMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCustomerQuoteItemAttributesMutation, AddCustomerQuoteItemAttributesMutationVariables>;
export const AddProductsToCartDocument = gql`
    mutation addProductsToCart($cart_id: String!, $items: [SimpleProductCartItemInput]!) {
  addSimpleProductsToCart(input: {cart_id: $cart_id, cart_items: $items}) {
    cart {
      id
      prices {
        subtotal_including_tax {
          currency
          value
        }
        subtotal_excluding_tax {
          currency
          value
        }
        grand_total {
          currency
          value
        }
        applied_taxes {
          amount {
            currency
            value
          }
          label
        }
        discounts {
          amount {
            currency
            value
          }
          label
        }
      }
      available_payment_methods {
        code
        title
      }
      items {
        id
        prices {
          discounts {
            amount {
              currency
              value
            }
            label
          }
          price {
            currency
            value
          }
          row_total {
            currency
            value
          }
          row_total_including_tax {
            currency
            value
          }
          total_item_discount {
            currency
            value
          }
        }
        ... on SimpleCartItem {
          customizable_options {
            label
            values {
              label
              value
              price {
                type
                units
                value
              }
            }
          }
        }
        product {
          url_key
          name
          sku
          stock_status
          small_image {
            url
          }
          crosssell_products {
            url_key
            name
            image {
              url
              label
            }
            price_range {
              minimum_price {
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  currency
                  value
                }
                fixed_product_taxes {
                  amount {
                    currency
                    value
                  }
                  label
                }
                regular_price {
                  currency
                  value
                }
              }
            }
          }
          price_range {
            minimum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }
              fixed_product_taxes {
                amount {
                  currency
                  value
                }
                label
              }
              regular_price {
                currency
                value
              }
            }
            maximum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }
              fixed_product_taxes {
                amount {
                  currency
                  value
                }
                label
              }
              regular_price {
                currency
                value
              }
            }
          }
        }
        quantity
      }
      billing_address {
        city
        country {
          code
          label
        }
        firstname
        lastname
        postcode
        street
        telephone
      }
      shipping_addresses {
        city
        country {
          code
          label
        }
        firstname
        lastname
        postcode
        street
        telephone
        region {
          code
          label
        }
        available_shipping_methods {
          amount {
            currency
            value
          }
          available
          carrier_code
          carrier_title
          error_message
          method_code
          method_title
          price_excl_tax {
            currency
            value
          }
          price_incl_tax {
            currency
            value
          }
        }
        selected_shipping_method {
          amount {
            currency
            value
          }
          carrier_code
          carrier_title
          method_code
          method_title
        }
      }
      selected_payment_method {
        title
        purchase_order_number
        code
      }
      billing_address {
        city
        country {
          code
          label
        }
        firstname
        lastname
        postcode
        street
        telephone
      }
      email
      applied_coupons {
        code
      }
    }
  }
}
    `;
export type AddProductsToCartMutationFn = ApolloReactCommon.MutationFunction<AddProductsToCartMutation, AddProductsToCartMutationVariables>;
export type AddProductsToCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddProductsToCartMutation, AddProductsToCartMutationVariables>, 'mutation'>;

    export const AddProductsToCartComponent = (props: AddProductsToCartComponentProps) => (
      <ApolloReactComponents.Mutation<AddProductsToCartMutation, AddProductsToCartMutationVariables> mutation={AddProductsToCartDocument} {...props} />
    );
    

/**
 * __useAddProductsToCartMutation__
 *
 * To run a mutation, you first call `useAddProductsToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductsToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductsToCartMutation, { data, loading, error }] = useAddProductsToCartMutation({
 *   variables: {
 *      cart_id: // value for 'cart_id'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useAddProductsToCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddProductsToCartMutation, AddProductsToCartMutationVariables>) {
        return ApolloReactHooks.useMutation<AddProductsToCartMutation, AddProductsToCartMutationVariables>(AddProductsToCartDocument, baseOptions);
      }
export type AddProductsToCartMutationHookResult = ReturnType<typeof useAddProductsToCartMutation>;
export type AddProductsToCartMutationResult = ApolloReactCommon.MutationResult<AddProductsToCartMutation>;
export type AddProductsToCartMutationOptions = ApolloReactCommon.BaseMutationOptions<AddProductsToCartMutation, AddProductsToCartMutationVariables>;
export const GenerateResetPasswordTokenDocument = gql`
    mutation generateResetPasswordToken($email: String!) {
  generateResetPasswordToken(email: $email) {
    message
    code
  }
}
    `;
export type GenerateResetPasswordTokenMutationFn = ApolloReactCommon.MutationFunction<GenerateResetPasswordTokenMutation, GenerateResetPasswordTokenMutationVariables>;
export type GenerateResetPasswordTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GenerateResetPasswordTokenMutation, GenerateResetPasswordTokenMutationVariables>, 'mutation'>;

    export const GenerateResetPasswordTokenComponent = (props: GenerateResetPasswordTokenComponentProps) => (
      <ApolloReactComponents.Mutation<GenerateResetPasswordTokenMutation, GenerateResetPasswordTokenMutationVariables> mutation={GenerateResetPasswordTokenDocument} {...props} />
    );
    

/**
 * __useGenerateResetPasswordTokenMutation__
 *
 * To run a mutation, you first call `useGenerateResetPasswordTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateResetPasswordTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateResetPasswordTokenMutation, { data, loading, error }] = useGenerateResetPasswordTokenMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGenerateResetPasswordTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateResetPasswordTokenMutation, GenerateResetPasswordTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateResetPasswordTokenMutation, GenerateResetPasswordTokenMutationVariables>(GenerateResetPasswordTokenDocument, baseOptions);
      }
export type GenerateResetPasswordTokenMutationHookResult = ReturnType<typeof useGenerateResetPasswordTokenMutation>;
export type GenerateResetPasswordTokenMutationResult = ApolloReactCommon.MutationResult<GenerateResetPasswordTokenMutation>;
export type GenerateResetPasswordTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateResetPasswordTokenMutation, GenerateResetPasswordTokenMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($currentPassword: String!, $newPassword: String!, $email: String!) {
  changeCustomerPassword(currentPassword: $currentPassword, newPassword: $newPassword) {
    ...DomainCustomer
  }
  generateCustomerToken(email: $email, password: $newPassword) {
    token
  }
}
    ${DomainCustomerFragmentDoc}`;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;
export type ChangePasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangePasswordMutation, ChangePasswordMutationVariables>, 'mutation'>;

    export const ChangePasswordComponent = (props: ChangePasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ChangePasswordMutation, ChangePasswordMutationVariables> mutation={ChangePasswordDocument} {...props} />
    );
    

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const SendClaimsFormEmailDocument = gql`
    mutation sendClaimsFormEmail($claim_items_info: [ClaimItem], $contact_consignee_name: String!, $contact_email: String!, $contact_fairfield_account_number: String!, $contact_fax: String!, $contact_first_name: String!, $contact_last_name: String!, $contact_phone: String!, $contact_industry: String!, $contact_zip: String!, $remit_address1: String!, $remit_address2: String, $remit_city: String!, $remit_first_name: String!, $remit_last_name: String!, $remit_title: String!, $remit_zip: String!, $remit_state: String, $remit_country: String!) {
  sendClaimsFormEmail(input: {contact_consignee_name: $contact_consignee_name, contact_fax: $contact_fax, contact_phone: $contact_phone, contact_email: $contact_email, contact_industry: $contact_industry, contact_zip: $contact_zip, remit_first_name: $remit_first_name, remit_last_name: $remit_last_name, remit_address1: $remit_address1, remit_address2: $remit_address2, remit_city: $remit_city, remit_title: $remit_title, remit_zip: $remit_zip, contact_fairfield_account_number: $contact_fairfield_account_number, contact_first_name: $contact_first_name, contact_last_name: $contact_last_name, remit_state: $remit_state, remit_country: $remit_country, claim_items_info: $claim_items_info})
}
    `;
export type SendClaimsFormEmailMutationFn = ApolloReactCommon.MutationFunction<SendClaimsFormEmailMutation, SendClaimsFormEmailMutationVariables>;
export type SendClaimsFormEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendClaimsFormEmailMutation, SendClaimsFormEmailMutationVariables>, 'mutation'>;

    export const SendClaimsFormEmailComponent = (props: SendClaimsFormEmailComponentProps) => (
      <ApolloReactComponents.Mutation<SendClaimsFormEmailMutation, SendClaimsFormEmailMutationVariables> mutation={SendClaimsFormEmailDocument} {...props} />
    );
    

/**
 * __useSendClaimsFormEmailMutation__
 *
 * To run a mutation, you first call `useSendClaimsFormEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendClaimsFormEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendClaimsFormEmailMutation, { data, loading, error }] = useSendClaimsFormEmailMutation({
 *   variables: {
 *      claim_items_info: // value for 'claim_items_info'
 *      contact_consignee_name: // value for 'contact_consignee_name'
 *      contact_email: // value for 'contact_email'
 *      contact_fairfield_account_number: // value for 'contact_fairfield_account_number'
 *      contact_fax: // value for 'contact_fax'
 *      contact_first_name: // value for 'contact_first_name'
 *      contact_last_name: // value for 'contact_last_name'
 *      contact_phone: // value for 'contact_phone'
 *      contact_industry: // value for 'contact_industry'
 *      contact_zip: // value for 'contact_zip'
 *      remit_address1: // value for 'remit_address1'
 *      remit_address2: // value for 'remit_address2'
 *      remit_city: // value for 'remit_city'
 *      remit_first_name: // value for 'remit_first_name'
 *      remit_last_name: // value for 'remit_last_name'
 *      remit_title: // value for 'remit_title'
 *      remit_zip: // value for 'remit_zip'
 *      remit_state: // value for 'remit_state'
 *      remit_country: // value for 'remit_country'
 *   },
 * });
 */
export function useSendClaimsFormEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendClaimsFormEmailMutation, SendClaimsFormEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendClaimsFormEmailMutation, SendClaimsFormEmailMutationVariables>(SendClaimsFormEmailDocument, baseOptions);
      }
export type SendClaimsFormEmailMutationHookResult = ReturnType<typeof useSendClaimsFormEmailMutation>;
export type SendClaimsFormEmailMutationResult = ApolloReactCommon.MutationResult<SendClaimsFormEmailMutation>;
export type SendClaimsFormEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendClaimsFormEmailMutation, SendClaimsFormEmailMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($input: CustomerInput!) {
  updateCustomer(input: $input) {
    customer {
      ...DomainCustomer
    }
  }
}
    ${DomainCustomerFragmentDoc}`;
export type UpdateCustomerMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export type UpdateCustomerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>, 'mutation'>;

    export const UpdateCustomerComponent = (props: UpdateCustomerComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCustomerMutation, UpdateCustomerMutationVariables> mutation={UpdateCustomerDocument} {...props} />
    );
    

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, baseOptions);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const UpdateCustomerAadChangePasswordDocument = gql`
    mutation UpdateCustomerAadChangePassword($input: CustomerInput!, $currentPassword: String!, $newPassword: String!, $email: String!) {
  updateCustomer(input: $input) {
    customer {
      ...DomainCustomer
    }
  }
  changeCustomerPassword(currentPassword: $currentPassword, newPassword: $newPassword) {
    id: customer_id
  }
  generateCustomerToken(email: $email, password: $newPassword) {
    token
  }
}
    ${DomainCustomerFragmentDoc}`;
export type UpdateCustomerAadChangePasswordMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomerAadChangePasswordMutation, UpdateCustomerAadChangePasswordMutationVariables>;
export type UpdateCustomerAadChangePasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCustomerAadChangePasswordMutation, UpdateCustomerAadChangePasswordMutationVariables>, 'mutation'>;

    export const UpdateCustomerAadChangePasswordComponent = (props: UpdateCustomerAadChangePasswordComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCustomerAadChangePasswordMutation, UpdateCustomerAadChangePasswordMutationVariables> mutation={UpdateCustomerAadChangePasswordDocument} {...props} />
    );
    

/**
 * __useUpdateCustomerAadChangePasswordMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerAadChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerAadChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerAadChangePasswordMutation, { data, loading, error }] = useUpdateCustomerAadChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateCustomerAadChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerAadChangePasswordMutation, UpdateCustomerAadChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomerAadChangePasswordMutation, UpdateCustomerAadChangePasswordMutationVariables>(UpdateCustomerAadChangePasswordDocument, baseOptions);
      }
export type UpdateCustomerAadChangePasswordMutationHookResult = ReturnType<typeof useUpdateCustomerAadChangePasswordMutation>;
export type UpdateCustomerAadChangePasswordMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerAadChangePasswordMutation>;
export type UpdateCustomerAadChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomerAadChangePasswordMutation, UpdateCustomerAadChangePasswordMutationVariables>;
export const UpdateCustomerEmailDocument = gql`
    mutation updateCustomerEmail($email: String!, $password: String!) {
  updateCustomerEmail(email: $email, password: $password) {
    customer {
      email
    }
  }
}
    `;
export type UpdateCustomerEmailMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomerEmailMutation, UpdateCustomerEmailMutationVariables>;
export type UpdateCustomerEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCustomerEmailMutation, UpdateCustomerEmailMutationVariables>, 'mutation'>;

    export const UpdateCustomerEmailComponent = (props: UpdateCustomerEmailComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCustomerEmailMutation, UpdateCustomerEmailMutationVariables> mutation={UpdateCustomerEmailDocument} {...props} />
    );
    

/**
 * __useUpdateCustomerEmailMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerEmailMutation, { data, loading, error }] = useUpdateCustomerEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateCustomerEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerEmailMutation, UpdateCustomerEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomerEmailMutation, UpdateCustomerEmailMutationVariables>(UpdateCustomerEmailDocument, baseOptions);
      }
export type UpdateCustomerEmailMutationHookResult = ReturnType<typeof useUpdateCustomerEmailMutation>;
export type UpdateCustomerEmailMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerEmailMutation>;
export type UpdateCustomerEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomerEmailMutation, UpdateCustomerEmailMutationVariables>;
export const UploadClaimsImageDocument = gql`
    mutation UploadClaimsImage($base64_encoded_data: String!, $name: String!, $type: String!) {
  uploadClaimsImage(input: {claims_image: {base64_encoded_data: $base64_encoded_data, name: $name, type: $type}}) {
    claims_image
  }
}
    `;
export type UploadClaimsImageMutationFn = ApolloReactCommon.MutationFunction<UploadClaimsImageMutation, UploadClaimsImageMutationVariables>;
export type UploadClaimsImageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UploadClaimsImageMutation, UploadClaimsImageMutationVariables>, 'mutation'>;

    export const UploadClaimsImageComponent = (props: UploadClaimsImageComponentProps) => (
      <ApolloReactComponents.Mutation<UploadClaimsImageMutation, UploadClaimsImageMutationVariables> mutation={UploadClaimsImageDocument} {...props} />
    );
    

/**
 * __useUploadClaimsImageMutation__
 *
 * To run a mutation, you first call `useUploadClaimsImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadClaimsImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadClaimsImageMutation, { data, loading, error }] = useUploadClaimsImageMutation({
 *   variables: {
 *      base64_encoded_data: // value for 'base64_encoded_data'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUploadClaimsImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadClaimsImageMutation, UploadClaimsImageMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadClaimsImageMutation, UploadClaimsImageMutationVariables>(UploadClaimsImageDocument, baseOptions);
      }
export type UploadClaimsImageMutationHookResult = ReturnType<typeof useUploadClaimsImageMutation>;
export type UploadClaimsImageMutationResult = ApolloReactCommon.MutationResult<UploadClaimsImageMutation>;
export type UploadClaimsImageMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadClaimsImageMutation, UploadClaimsImageMutationVariables>;
export const ResetPasswordJolaDocument = gql`
    mutation resetPasswordJola($input: ResetPasswordRequestInput!) {
  resetPasswordJola(input: $input) {
    message
    code
  }
}
    `;
export type ResetPasswordJolaMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordJolaMutation, ResetPasswordJolaMutationVariables>;
export type ResetPasswordJolaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetPasswordJolaMutation, ResetPasswordJolaMutationVariables>, 'mutation'>;

    export const ResetPasswordJolaComponent = (props: ResetPasswordJolaComponentProps) => (
      <ApolloReactComponents.Mutation<ResetPasswordJolaMutation, ResetPasswordJolaMutationVariables> mutation={ResetPasswordJolaDocument} {...props} />
    );
    

/**
 * __useResetPasswordJolaMutation__
 *
 * To run a mutation, you first call `useResetPasswordJolaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordJolaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordJolaMutation, { data, loading, error }] = useResetPasswordJolaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordJolaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordJolaMutation, ResetPasswordJolaMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordJolaMutation, ResetPasswordJolaMutationVariables>(ResetPasswordJolaDocument, baseOptions);
      }
export type ResetPasswordJolaMutationHookResult = ReturnType<typeof useResetPasswordJolaMutation>;
export type ResetPasswordJolaMutationResult = ApolloReactCommon.MutationResult<ResetPasswordJolaMutation>;
export type ResetPasswordJolaMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordJolaMutation, ResetPasswordJolaMutationVariables>;
export const GenerateCustomerTokenDocument = gql`
    mutation generateCustomerToken($email: String!, $password: String!) {
  generateCustomerToken(email: $email, password: $password) {
    token
  }
}
    `;
export type GenerateCustomerTokenMutationFn = ApolloReactCommon.MutationFunction<GenerateCustomerTokenMutation, GenerateCustomerTokenMutationVariables>;
export type GenerateCustomerTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GenerateCustomerTokenMutation, GenerateCustomerTokenMutationVariables>, 'mutation'>;

    export const GenerateCustomerTokenComponent = (props: GenerateCustomerTokenComponentProps) => (
      <ApolloReactComponents.Mutation<GenerateCustomerTokenMutation, GenerateCustomerTokenMutationVariables> mutation={GenerateCustomerTokenDocument} {...props} />
    );
    

/**
 * __useGenerateCustomerTokenMutation__
 *
 * To run a mutation, you first call `useGenerateCustomerTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCustomerTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCustomerTokenMutation, { data, loading, error }] = useGenerateCustomerTokenMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGenerateCustomerTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateCustomerTokenMutation, GenerateCustomerTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateCustomerTokenMutation, GenerateCustomerTokenMutationVariables>(GenerateCustomerTokenDocument, baseOptions);
      }
export type GenerateCustomerTokenMutationHookResult = ReturnType<typeof useGenerateCustomerTokenMutation>;
export type GenerateCustomerTokenMutationResult = ApolloReactCommon.MutationResult<GenerateCustomerTokenMutation>;
export type GenerateCustomerTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateCustomerTokenMutation, GenerateCustomerTokenMutationVariables>;
export const RevokeCustomerTokenDocument = gql`
    mutation revokeCustomerToken {
  revokeCustomerToken {
    result
  }
}
    `;
export type RevokeCustomerTokenMutationFn = ApolloReactCommon.MutationFunction<RevokeCustomerTokenMutation, RevokeCustomerTokenMutationVariables>;
export type RevokeCustomerTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RevokeCustomerTokenMutation, RevokeCustomerTokenMutationVariables>, 'mutation'>;

    export const RevokeCustomerTokenComponent = (props: RevokeCustomerTokenComponentProps) => (
      <ApolloReactComponents.Mutation<RevokeCustomerTokenMutation, RevokeCustomerTokenMutationVariables> mutation={RevokeCustomerTokenDocument} {...props} />
    );
    

/**
 * __useRevokeCustomerTokenMutation__
 *
 * To run a mutation, you first call `useRevokeCustomerTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeCustomerTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeCustomerTokenMutation, { data, loading, error }] = useRevokeCustomerTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRevokeCustomerTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeCustomerTokenMutation, RevokeCustomerTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<RevokeCustomerTokenMutation, RevokeCustomerTokenMutationVariables>(RevokeCustomerTokenDocument, baseOptions);
      }
export type RevokeCustomerTokenMutationHookResult = ReturnType<typeof useRevokeCustomerTokenMutation>;
export type RevokeCustomerTokenMutationResult = ApolloReactCommon.MutationResult<RevokeCustomerTokenMutation>;
export type RevokeCustomerTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeCustomerTokenMutation, RevokeCustomerTokenMutationVariables>;
export const GenerateCacheDocument = gql`
    mutation generateCache($input: String!) {
  generateCache(input: $input)
}
    `;
export type GenerateCacheMutationFn = ApolloReactCommon.MutationFunction<GenerateCacheMutation, GenerateCacheMutationVariables>;
export type GenerateCacheComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GenerateCacheMutation, GenerateCacheMutationVariables>, 'mutation'>;

    export const GenerateCacheComponent = (props: GenerateCacheComponentProps) => (
      <ApolloReactComponents.Mutation<GenerateCacheMutation, GenerateCacheMutationVariables> mutation={GenerateCacheDocument} {...props} />
    );
    

/**
 * __useGenerateCacheMutation__
 *
 * To run a mutation, you first call `useGenerateCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCacheMutation, { data, loading, error }] = useGenerateCacheMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateCacheMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateCacheMutation, GenerateCacheMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateCacheMutation, GenerateCacheMutationVariables>(GenerateCacheDocument, baseOptions);
      }
export type GenerateCacheMutationHookResult = ReturnType<typeof useGenerateCacheMutation>;
export type GenerateCacheMutationResult = ApolloReactCommon.MutationResult<GenerateCacheMutation>;
export type GenerateCacheMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateCacheMutation, GenerateCacheMutationVariables>;
export const GenerateDownloadUrlMDocument = gql`
    mutation generateDownloadUrlM($input: [generateDownloadUrlByCustomerIdImagesSamples!]!) {
  generateDownloadUrlM(input: $input) {
    generated_download_url_output {
      download_url
      filename
      status
    }
  }
}
    `;
export type GenerateDownloadUrlMMutationFn = ApolloReactCommon.MutationFunction<GenerateDownloadUrlMMutation, GenerateDownloadUrlMMutationVariables>;
export type GenerateDownloadUrlMComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GenerateDownloadUrlMMutation, GenerateDownloadUrlMMutationVariables>, 'mutation'>;

    export const GenerateDownloadUrlMComponent = (props: GenerateDownloadUrlMComponentProps) => (
      <ApolloReactComponents.Mutation<GenerateDownloadUrlMMutation, GenerateDownloadUrlMMutationVariables> mutation={GenerateDownloadUrlMDocument} {...props} />
    );
    

/**
 * __useGenerateDownloadUrlMMutation__
 *
 * To run a mutation, you first call `useGenerateDownloadUrlMMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateDownloadUrlMMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateDownloadUrlMMutation, { data, loading, error }] = useGenerateDownloadUrlMMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateDownloadUrlMMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateDownloadUrlMMutation, GenerateDownloadUrlMMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateDownloadUrlMMutation, GenerateDownloadUrlMMutationVariables>(GenerateDownloadUrlMDocument, baseOptions);
      }
export type GenerateDownloadUrlMMutationHookResult = ReturnType<typeof useGenerateDownloadUrlMMutation>;
export type GenerateDownloadUrlMMutationResult = ApolloReactCommon.MutationResult<GenerateDownloadUrlMMutation>;
export type GenerateDownloadUrlMMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateDownloadUrlMMutation, GenerateDownloadUrlMMutationVariables>;
export const SendEmailDocument = gql`
    mutation sendEmail($input: SendEmailInput) {
  sendEmail(input: $input)
}
    `;
export type SendEmailMutationFn = ApolloReactCommon.MutationFunction<SendEmailMutation, SendEmailMutationVariables>;
export type SendEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendEmailMutation, SendEmailMutationVariables>, 'mutation'>;

    export const SendEmailComponent = (props: SendEmailComponentProps) => (
      <ApolloReactComponents.Mutation<SendEmailMutation, SendEmailMutationVariables> mutation={SendEmailDocument} {...props} />
    );
    

/**
 * __useSendEmailMutation__
 *
 * To run a mutation, you first call `useSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailMutation, { data, loading, error }] = useSendEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendEmailMutation, SendEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendEmailMutation, SendEmailMutationVariables>(SendEmailDocument, baseOptions);
      }
export type SendEmailMutationHookResult = ReturnType<typeof useSendEmailMutation>;
export type SendEmailMutationResult = ApolloReactCommon.MutationResult<SendEmailMutation>;
export type SendEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendEmailMutation, SendEmailMutationVariables>;
export const SubscriberDocument = gql`
    mutation subscriber($email: String!, $division: String!) {
  subscriber(email: $email, division: $division) {
    status
  }
}
    `;
export type SubscriberMutationFn = ApolloReactCommon.MutationFunction<SubscriberMutation, SubscriberMutationVariables>;
export type SubscriberComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubscriberMutation, SubscriberMutationVariables>, 'mutation'>;

    export const SubscriberComponent = (props: SubscriberComponentProps) => (
      <ApolloReactComponents.Mutation<SubscriberMutation, SubscriberMutationVariables> mutation={SubscriberDocument} {...props} />
    );
    

/**
 * __useSubscriberMutation__
 *
 * To run a mutation, you first call `useSubscriberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriberMutation, { data, loading, error }] = useSubscriberMutation({
 *   variables: {
 *      email: // value for 'email'
 *      division: // value for 'division'
 *   },
 * });
 */
export function useSubscriberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubscriberMutation, SubscriberMutationVariables>) {
        return ApolloReactHooks.useMutation<SubscriberMutation, SubscriberMutationVariables>(SubscriberDocument, baseOptions);
      }
export type SubscriberMutationHookResult = ReturnType<typeof useSubscriberMutation>;
export type SubscriberMutationResult = ApolloReactCommon.MutationResult<SubscriberMutation>;
export type SubscriberMutationOptions = ApolloReactCommon.BaseMutationOptions<SubscriberMutation, SubscriberMutationVariables>;
export const AddItemsToWishlistsDocument = gql`
    mutation AddItemsToWishlists($input: AddItemsToWishlistInput) {
  addItemsToWishlist(input: $input) {
    ...DomainWishlist
  }
}
    ${DomainWishlistFragmentDoc}`;
export type AddItemsToWishlistsMutationFn = ApolloReactCommon.MutationFunction<AddItemsToWishlistsMutation, AddItemsToWishlistsMutationVariables>;
export type AddItemsToWishlistsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddItemsToWishlistsMutation, AddItemsToWishlistsMutationVariables>, 'mutation'>;

    export const AddItemsToWishlistsComponent = (props: AddItemsToWishlistsComponentProps) => (
      <ApolloReactComponents.Mutation<AddItemsToWishlistsMutation, AddItemsToWishlistsMutationVariables> mutation={AddItemsToWishlistsDocument} {...props} />
    );
    

/**
 * __useAddItemsToWishlistsMutation__
 *
 * To run a mutation, you first call `useAddItemsToWishlistsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemsToWishlistsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemsToWishlistsMutation, { data, loading, error }] = useAddItemsToWishlistsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddItemsToWishlistsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddItemsToWishlistsMutation, AddItemsToWishlistsMutationVariables>) {
        return ApolloReactHooks.useMutation<AddItemsToWishlistsMutation, AddItemsToWishlistsMutationVariables>(AddItemsToWishlistsDocument, baseOptions);
      }
export type AddItemsToWishlistsMutationHookResult = ReturnType<typeof useAddItemsToWishlistsMutation>;
export type AddItemsToWishlistsMutationResult = ApolloReactCommon.MutationResult<AddItemsToWishlistsMutation>;
export type AddItemsToWishlistsMutationOptions = ApolloReactCommon.BaseMutationOptions<AddItemsToWishlistsMutation, AddItemsToWishlistsMutationVariables>;
export const AddNewWishlistDocument = gql`
    mutation AddNewWishlist($name: String!) {
  addNewWishlist(input: $name) {
    ...DomainWishlist
  }
}
    ${DomainWishlistFragmentDoc}`;
export type AddNewWishlistMutationFn = ApolloReactCommon.MutationFunction<AddNewWishlistMutation, AddNewWishlistMutationVariables>;
export type AddNewWishlistComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddNewWishlistMutation, AddNewWishlistMutationVariables>, 'mutation'>;

    export const AddNewWishlistComponent = (props: AddNewWishlistComponentProps) => (
      <ApolloReactComponents.Mutation<AddNewWishlistMutation, AddNewWishlistMutationVariables> mutation={AddNewWishlistDocument} {...props} />
    );
    

/**
 * __useAddNewWishlistMutation__
 *
 * To run a mutation, you first call `useAddNewWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewWishlistMutation, { data, loading, error }] = useAddNewWishlistMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddNewWishlistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddNewWishlistMutation, AddNewWishlistMutationVariables>) {
        return ApolloReactHooks.useMutation<AddNewWishlistMutation, AddNewWishlistMutationVariables>(AddNewWishlistDocument, baseOptions);
      }
export type AddNewWishlistMutationHookResult = ReturnType<typeof useAddNewWishlistMutation>;
export type AddNewWishlistMutationResult = ApolloReactCommon.MutationResult<AddNewWishlistMutation>;
export type AddNewWishlistMutationOptions = ApolloReactCommon.BaseMutationOptions<AddNewWishlistMutation, AddNewWishlistMutationVariables>;
export const DeleteItemsFromWishlistDocument = gql`
    mutation DeleteItemsFromWishlist($input: DeleteItemFromWishlistInput) {
  deleteItemsFromWishlist(input: $input) {
    ...DomainWishlist
  }
}
    ${DomainWishlistFragmentDoc}`;
export type DeleteItemsFromWishlistMutationFn = ApolloReactCommon.MutationFunction<DeleteItemsFromWishlistMutation, DeleteItemsFromWishlistMutationVariables>;
export type DeleteItemsFromWishlistComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteItemsFromWishlistMutation, DeleteItemsFromWishlistMutationVariables>, 'mutation'>;

    export const DeleteItemsFromWishlistComponent = (props: DeleteItemsFromWishlistComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteItemsFromWishlistMutation, DeleteItemsFromWishlistMutationVariables> mutation={DeleteItemsFromWishlistDocument} {...props} />
    );
    

/**
 * __useDeleteItemsFromWishlistMutation__
 *
 * To run a mutation, you first call `useDeleteItemsFromWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemsFromWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemsFromWishlistMutation, { data, loading, error }] = useDeleteItemsFromWishlistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteItemsFromWishlistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteItemsFromWishlistMutation, DeleteItemsFromWishlistMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteItemsFromWishlistMutation, DeleteItemsFromWishlistMutationVariables>(DeleteItemsFromWishlistDocument, baseOptions);
      }
export type DeleteItemsFromWishlistMutationHookResult = ReturnType<typeof useDeleteItemsFromWishlistMutation>;
export type DeleteItemsFromWishlistMutationResult = ApolloReactCommon.MutationResult<DeleteItemsFromWishlistMutation>;
export type DeleteItemsFromWishlistMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteItemsFromWishlistMutation, DeleteItemsFromWishlistMutationVariables>;
export const DeleteWishlistDocument = gql`
    mutation DeleteWishlist($name: String!) {
  deleteWishlist(input: $name) {
    ...DomainWishlist
  }
}
    ${DomainWishlistFragmentDoc}`;
export type DeleteWishlistMutationFn = ApolloReactCommon.MutationFunction<DeleteWishlistMutation, DeleteWishlistMutationVariables>;
export type DeleteWishlistComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteWishlistMutation, DeleteWishlistMutationVariables>, 'mutation'>;

    export const DeleteWishlistComponent = (props: DeleteWishlistComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteWishlistMutation, DeleteWishlistMutationVariables> mutation={DeleteWishlistDocument} {...props} />
    );
    

/**
 * __useDeleteWishlistMutation__
 *
 * To run a mutation, you first call `useDeleteWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWishlistMutation, { data, loading, error }] = useDeleteWishlistMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDeleteWishlistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteWishlistMutation, DeleteWishlistMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteWishlistMutation, DeleteWishlistMutationVariables>(DeleteWishlistDocument, baseOptions);
      }
export type DeleteWishlistMutationHookResult = ReturnType<typeof useDeleteWishlistMutation>;
export type DeleteWishlistMutationResult = ApolloReactCommon.MutationResult<DeleteWishlistMutation>;
export type DeleteWishlistMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteWishlistMutation, DeleteWishlistMutationVariables>;
export const EditWishlistDocument = gql`
    mutation EditWishlist($fromName: String!, $toName: String!) {
  editWishlist(input: {fromName: $fromName, toName: $toName}) {
    ...DomainWishlist
  }
}
    ${DomainWishlistFragmentDoc}`;
export type EditWishlistMutationFn = ApolloReactCommon.MutationFunction<EditWishlistMutation, EditWishlistMutationVariables>;
export type EditWishlistComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditWishlistMutation, EditWishlistMutationVariables>, 'mutation'>;

    export const EditWishlistComponent = (props: EditWishlistComponentProps) => (
      <ApolloReactComponents.Mutation<EditWishlistMutation, EditWishlistMutationVariables> mutation={EditWishlistDocument} {...props} />
    );
    

/**
 * __useEditWishlistMutation__
 *
 * To run a mutation, you first call `useEditWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWishlistMutation, { data, loading, error }] = useEditWishlistMutation({
 *   variables: {
 *      fromName: // value for 'fromName'
 *      toName: // value for 'toName'
 *   },
 * });
 */
export function useEditWishlistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditWishlistMutation, EditWishlistMutationVariables>) {
        return ApolloReactHooks.useMutation<EditWishlistMutation, EditWishlistMutationVariables>(EditWishlistDocument, baseOptions);
      }
export type EditWishlistMutationHookResult = ReturnType<typeof useEditWishlistMutation>;
export type EditWishlistMutationResult = ApolloReactCommon.MutationResult<EditWishlistMutation>;
export type EditWishlistMutationOptions = ApolloReactCommon.BaseMutationOptions<EditWishlistMutation, EditWishlistMutationVariables>;
export const UpdateWishlistItemDocument = gql`
    mutation UpdateWishlistItem($itemId: String!, $name: String!, $item_name: String!) {
  updateWishlistItem(itemId: $itemId, name: $name, item_name: $item_name) {
    ...DomainWishlist
  }
}
    ${DomainWishlistFragmentDoc}`;
export type UpdateWishlistItemMutationFn = ApolloReactCommon.MutationFunction<UpdateWishlistItemMutation, UpdateWishlistItemMutationVariables>;
export type UpdateWishlistItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateWishlistItemMutation, UpdateWishlistItemMutationVariables>, 'mutation'>;

    export const UpdateWishlistItemComponent = (props: UpdateWishlistItemComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateWishlistItemMutation, UpdateWishlistItemMutationVariables> mutation={UpdateWishlistItemDocument} {...props} />
    );
    

/**
 * __useUpdateWishlistItemMutation__
 *
 * To run a mutation, you first call `useUpdateWishlistItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWishlistItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWishlistItemMutation, { data, loading, error }] = useUpdateWishlistItemMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      name: // value for 'name'
 *      item_name: // value for 'item_name'
 *   },
 * });
 */
export function useUpdateWishlistItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWishlistItemMutation, UpdateWishlistItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWishlistItemMutation, UpdateWishlistItemMutationVariables>(UpdateWishlistItemDocument, baseOptions);
      }
export type UpdateWishlistItemMutationHookResult = ReturnType<typeof useUpdateWishlistItemMutation>;
export type UpdateWishlistItemMutationResult = ApolloReactCommon.MutationResult<UpdateWishlistItemMutation>;
export type UpdateWishlistItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWishlistItemMutation, UpdateWishlistItemMutationVariables>;
export const AddAllToCartDocument = gql`
    mutation addAllToCart($name: String) {
  addAllToCart(name: $name) {
    cartId
    id
    name
    items_count
    items {
      id
      item_name
    }
  }
}
    `;
export type AddAllToCartMutationFn = ApolloReactCommon.MutationFunction<AddAllToCartMutation, AddAllToCartMutationVariables>;
export type AddAllToCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddAllToCartMutation, AddAllToCartMutationVariables>, 'mutation'>;

    export const AddAllToCartComponent = (props: AddAllToCartComponentProps) => (
      <ApolloReactComponents.Mutation<AddAllToCartMutation, AddAllToCartMutationVariables> mutation={AddAllToCartDocument} {...props} />
    );
    

/**
 * __useAddAllToCartMutation__
 *
 * To run a mutation, you first call `useAddAllToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAllToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAllToCartMutation, { data, loading, error }] = useAddAllToCartMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddAllToCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAllToCartMutation, AddAllToCartMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAllToCartMutation, AddAllToCartMutationVariables>(AddAllToCartDocument, baseOptions);
      }
export type AddAllToCartMutationHookResult = ReturnType<typeof useAddAllToCartMutation>;
export type AddAllToCartMutationResult = ApolloReactCommon.MutationResult<AddAllToCartMutation>;
export type AddAllToCartMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAllToCartMutation, AddAllToCartMutationVariables>;
export const AddItemToCartDocument = gql`
    mutation addItemToCart($name: String, $itemId: String) {
  addItemToCart(name: $name, itemId: $itemId) {
    id
  }
}
    `;
export type AddItemToCartMutationFn = ApolloReactCommon.MutationFunction<AddItemToCartMutation, AddItemToCartMutationVariables>;
export type AddItemToCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddItemToCartMutation, AddItemToCartMutationVariables>, 'mutation'>;

    export const AddItemToCartComponent = (props: AddItemToCartComponentProps) => (
      <ApolloReactComponents.Mutation<AddItemToCartMutation, AddItemToCartMutationVariables> mutation={AddItemToCartDocument} {...props} />
    );
    

/**
 * __useAddItemToCartMutation__
 *
 * To run a mutation, you first call `useAddItemToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemToCartMutation, { data, loading, error }] = useAddItemToCartMutation({
 *   variables: {
 *      name: // value for 'name'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useAddItemToCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddItemToCartMutation, AddItemToCartMutationVariables>) {
        return ApolloReactHooks.useMutation<AddItemToCartMutation, AddItemToCartMutationVariables>(AddItemToCartDocument, baseOptions);
      }
export type AddItemToCartMutationHookResult = ReturnType<typeof useAddItemToCartMutation>;
export type AddItemToCartMutationResult = ApolloReactCommon.MutationResult<AddItemToCartMutation>;
export type AddItemToCartMutationOptions = ApolloReactCommon.BaseMutationOptions<AddItemToCartMutation, AddItemToCartMutationVariables>;
export const DeleteSavedBuildsFromWishlistDocument = gql`
    mutation deleteSavedBuildsFromWishlist($input: DeleteSavedBuildFromWishlistInput) {
  deleteSavedBuildsFromWishlist(input: $input) {
    id
    items {
      id
      item_name
    }
    items_count
  }
}
    `;
export type DeleteSavedBuildsFromWishlistMutationFn = ApolloReactCommon.MutationFunction<DeleteSavedBuildsFromWishlistMutation, DeleteSavedBuildsFromWishlistMutationVariables>;
export type DeleteSavedBuildsFromWishlistComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSavedBuildsFromWishlistMutation, DeleteSavedBuildsFromWishlistMutationVariables>, 'mutation'>;

    export const DeleteSavedBuildsFromWishlistComponent = (props: DeleteSavedBuildsFromWishlistComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSavedBuildsFromWishlistMutation, DeleteSavedBuildsFromWishlistMutationVariables> mutation={DeleteSavedBuildsFromWishlistDocument} {...props} />
    );
    

/**
 * __useDeleteSavedBuildsFromWishlistMutation__
 *
 * To run a mutation, you first call `useDeleteSavedBuildsFromWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSavedBuildsFromWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSavedBuildsFromWishlistMutation, { data, loading, error }] = useDeleteSavedBuildsFromWishlistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSavedBuildsFromWishlistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSavedBuildsFromWishlistMutation, DeleteSavedBuildsFromWishlistMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSavedBuildsFromWishlistMutation, DeleteSavedBuildsFromWishlistMutationVariables>(DeleteSavedBuildsFromWishlistDocument, baseOptions);
      }
export type DeleteSavedBuildsFromWishlistMutationHookResult = ReturnType<typeof useDeleteSavedBuildsFromWishlistMutation>;
export type DeleteSavedBuildsFromWishlistMutationResult = ApolloReactCommon.MutationResult<DeleteSavedBuildsFromWishlistMutation>;
export type DeleteSavedBuildsFromWishlistMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSavedBuildsFromWishlistMutation, DeleteSavedBuildsFromWishlistMutationVariables>;
export const CountryOptionsDocument = gql`
    query CountryOptions {
  countries {
    value: id
    regions: available_regions {
      value: id
      label: name
      code
    }
    label: full_name_english
  }
}
    `;
export type CountryOptionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CountryOptionsQuery, CountryOptionsQueryVariables>, 'query'>;

    export const CountryOptionsComponent = (props: CountryOptionsComponentProps) => (
      <ApolloReactComponents.Query<CountryOptionsQuery, CountryOptionsQueryVariables> query={CountryOptionsDocument} {...props} />
    );
    

/**
 * __useCountryOptionsQuery__
 *
 * To run a query within a React component, call `useCountryOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountryOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CountryOptionsQuery, CountryOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<CountryOptionsQuery, CountryOptionsQueryVariables>(CountryOptionsDocument, baseOptions);
      }
export function useCountryOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountryOptionsQuery, CountryOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CountryOptionsQuery, CountryOptionsQueryVariables>(CountryOptionsDocument, baseOptions);
        }
export type CountryOptionsQueryHookResult = ReturnType<typeof useCountryOptionsQuery>;
export type CountryOptionsLazyQueryHookResult = ReturnType<typeof useCountryOptionsLazyQuery>;
export type CountryOptionsQueryResult = ApolloReactCommon.QueryResult<CountryOptionsQuery, CountryOptionsQueryVariables>;
export const CustomerCartDocument = gql`
    query CustomerCart {
  customerCart {
    ...DomainCart
  }
}
    ${DomainCartFragmentDoc}`;
export type CustomerCartComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CustomerCartQuery, CustomerCartQueryVariables>, 'query'>;

    export const CustomerCartComponent = (props: CustomerCartComponentProps) => (
      <ApolloReactComponents.Query<CustomerCartQuery, CustomerCartQueryVariables> query={CustomerCartDocument} {...props} />
    );
    

/**
 * __useCustomerCartQuery__
 *
 * To run a query within a React component, call `useCustomerCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCartQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerCartQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomerCartQuery, CustomerCartQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomerCartQuery, CustomerCartQueryVariables>(CustomerCartDocument, baseOptions);
      }
export function useCustomerCartLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerCartQuery, CustomerCartQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomerCartQuery, CustomerCartQueryVariables>(CustomerCartDocument, baseOptions);
        }
export type CustomerCartQueryHookResult = ReturnType<typeof useCustomerCartQuery>;
export type CustomerCartLazyQueryHookResult = ReturnType<typeof useCustomerCartLazyQuery>;
export type CustomerCartQueryResult = ApolloReactCommon.QueryResult<CustomerCartQuery, CustomerCartQueryVariables>;
export const CategoryDocument = gql`
    query Category($id: Int!) {
  category(id: $id) {
    id
    name
    include_in_menu
    url_path
    children {
      id
      name
      image
      url_key
      url_path
      include_in_menu
    }
  }
}
    `;
export type CategoryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CategoryQuery, CategoryQueryVariables>, 'query'> & ({ variables: CategoryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CategoryComponent = (props: CategoryComponentProps) => (
      <ApolloReactComponents.Query<CategoryQuery, CategoryQueryVariables> query={CategoryDocument} {...props} />
    );
    

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, baseOptions);
      }
export function useCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, baseOptions);
        }
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategoryQueryResult = ApolloReactCommon.QueryResult<CategoryQuery, CategoryQueryVariables>;
export const CategoryProductsDocument = gql`
    query CategoryProducts($id: Int!) {
  category(id: $id) {
    id
    name
    include_in_menu
    url_path
    children {
      id
      name
      image
      url_key
      url_path
      include_in_menu
    }
    products(sort: {name: ASC}) {
      total_count
      items {
        id
        name
        sku
        url_key
        categories {
          id
        }
        image {
          label
          url
        }
        small_image {
          label
          url
        }
        price_range {
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            fixed_product_taxes {
              amount {
                currency
                value
              }
              label
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            fixed_product_taxes {
              amount {
                currency
                value
              }
              label
            }
            regular_price {
              currency
              value
            }
          }
        }
        tier_prices {
          customer_group_id
          percentage_value
          qty
          value
          website_id
        }
      }
    }
  }
}
    `;
export type CategoryProductsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CategoryProductsQuery, CategoryProductsQueryVariables>, 'query'> & ({ variables: CategoryProductsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CategoryProductsComponent = (props: CategoryProductsComponentProps) => (
      <ApolloReactComponents.Query<CategoryProductsQuery, CategoryProductsQueryVariables> query={CategoryProductsDocument} {...props} />
    );
    

/**
 * __useCategoryProductsQuery__
 *
 * To run a query within a React component, call `useCategoryProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryProductsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoryProductsQuery, CategoryProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<CategoryProductsQuery, CategoryProductsQueryVariables>(CategoryProductsDocument, baseOptions);
      }
export function useCategoryProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoryProductsQuery, CategoryProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CategoryProductsQuery, CategoryProductsQueryVariables>(CategoryProductsDocument, baseOptions);
        }
export type CategoryProductsQueryHookResult = ReturnType<typeof useCategoryProductsQuery>;
export type CategoryProductsLazyQueryHookResult = ReturnType<typeof useCategoryProductsLazyQuery>;
export type CategoryProductsQueryResult = ApolloReactCommon.QueryResult<CategoryProductsQuery, CategoryProductsQueryVariables>;
export const CategoryTreeDocument = gql`
    query CategoryTree($id: Int!) {
  category(id: $id) {
    id
    name
    description
    include_in_menu
    url_path
    path
    products {
      total_count
    }
    children {
      id
      name
      description
      url_key
      url_path
      include_in_menu
      path
      products {
        total_count
      }
      children {
        id
        name
        description
        url_key
        url_path
        include_in_menu
        path
        products {
          total_count
        }
        children {
          id
          name
          description
          url_key
          url_path
          include_in_menu
          path
          products {
            total_count
          }
        }
      }
    }
  }
}
    `;
export type CategoryTreeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CategoryTreeQuery, CategoryTreeQueryVariables>, 'query'> & ({ variables: CategoryTreeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CategoryTreeComponent = (props: CategoryTreeComponentProps) => (
      <ApolloReactComponents.Query<CategoryTreeQuery, CategoryTreeQueryVariables> query={CategoryTreeDocument} {...props} />
    );
    

/**
 * __useCategoryTreeQuery__
 *
 * To run a query within a React component, call `useCategoryTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryTreeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryTreeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoryTreeQuery, CategoryTreeQueryVariables>) {
        return ApolloReactHooks.useQuery<CategoryTreeQuery, CategoryTreeQueryVariables>(CategoryTreeDocument, baseOptions);
      }
export function useCategoryTreeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoryTreeQuery, CategoryTreeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CategoryTreeQuery, CategoryTreeQueryVariables>(CategoryTreeDocument, baseOptions);
        }
export type CategoryTreeQueryHookResult = ReturnType<typeof useCategoryTreeQuery>;
export type CategoryTreeLazyQueryHookResult = ReturnType<typeof useCategoryTreeLazyQuery>;
export type CategoryTreeQueryResult = ApolloReactCommon.QueryResult<CategoryTreeQuery, CategoryTreeQueryVariables>;
export const CmsBlockDocument = gql`
    query cmsBlock($id: String!) {
  cmsBlocks(identifiers: [$id]) {
    items {
      content
      identifier
      title
    }
  }
}
    `;
export type CmsBlockComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CmsBlockQuery, CmsBlockQueryVariables>, 'query'> & ({ variables: CmsBlockQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CmsBlockComponent = (props: CmsBlockComponentProps) => (
      <ApolloReactComponents.Query<CmsBlockQuery, CmsBlockQueryVariables> query={CmsBlockDocument} {...props} />
    );
    

/**
 * __useCmsBlockQuery__
 *
 * To run a query within a React component, call `useCmsBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmsBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmsBlockQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCmsBlockQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CmsBlockQuery, CmsBlockQueryVariables>) {
        return ApolloReactHooks.useQuery<CmsBlockQuery, CmsBlockQueryVariables>(CmsBlockDocument, baseOptions);
      }
export function useCmsBlockLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CmsBlockQuery, CmsBlockQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CmsBlockQuery, CmsBlockQueryVariables>(CmsBlockDocument, baseOptions);
        }
export type CmsBlockQueryHookResult = ReturnType<typeof useCmsBlockQuery>;
export type CmsBlockLazyQueryHookResult = ReturnType<typeof useCmsBlockLazyQuery>;
export type CmsBlockQueryResult = ApolloReactCommon.QueryResult<CmsBlockQuery, CmsBlockQueryVariables>;
export const CmsPageDocument = gql`
    query cmsPage($id: Int!) {
  cmsPage(id: $id) {
    content
    content_heading
    page_layout
    title
    url_key
  }
}
    `;
export type CmsPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CmsPageQuery, CmsPageQueryVariables>, 'query'> & ({ variables: CmsPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CmsPageComponent = (props: CmsPageComponentProps) => (
      <ApolloReactComponents.Query<CmsPageQuery, CmsPageQueryVariables> query={CmsPageDocument} {...props} />
    );
    

/**
 * __useCmsPageQuery__
 *
 * To run a query within a React component, call `useCmsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmsPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCmsPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CmsPageQuery, CmsPageQueryVariables>) {
        return ApolloReactHooks.useQuery<CmsPageQuery, CmsPageQueryVariables>(CmsPageDocument, baseOptions);
      }
export function useCmsPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CmsPageQuery, CmsPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CmsPageQuery, CmsPageQueryVariables>(CmsPageDocument, baseOptions);
        }
export type CmsPageQueryHookResult = ReturnType<typeof useCmsPageQuery>;
export type CmsPageLazyQueryHookResult = ReturnType<typeof useCmsPageLazyQuery>;
export type CmsPageQueryResult = ApolloReactCommon.QueryResult<CmsPageQuery, CmsPageQueryVariables>;
export const CountriesDocument = gql`
    query countries {
  countries {
    id
    available_regions {
      id
      name
      code
    }
    full_name_locale
    full_name_english
  }
}
    `;
export type CountriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CountriesQuery, CountriesQueryVariables>, 'query'>;

    export const CountriesComponent = (props: CountriesComponentProps) => (
      <ApolloReactComponents.Query<CountriesQuery, CountriesQueryVariables> query={CountriesDocument} {...props} />
    );
    

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        return ApolloReactHooks.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, baseOptions);
      }
export function useCountriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, baseOptions);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = ApolloReactCommon.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const CustomerDocument = gql`
    query Customer {
  customer {
    ...DomainCustomer
  }
}
    ${DomainCustomerFragmentDoc}`;
export type CustomerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CustomerQuery, CustomerQueryVariables>, 'query'>;

    export const CustomerComponent = (props: CustomerComponentProps) => (
      <ApolloReactComponents.Query<CustomerQuery, CustomerQueryVariables> query={CustomerDocument} {...props} />
    );
    

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, baseOptions);
      }
export function useCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, baseOptions);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = ApolloReactCommon.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const ExpressRegistrationDocument = gql`
    query ExpressRegistration($account_number: String!) {
  expressRegistration(account_number: $account_number)
}
    `;
export type ExpressRegistrationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExpressRegistrationQuery, ExpressRegistrationQueryVariables>, 'query'> & ({ variables: ExpressRegistrationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ExpressRegistrationComponent = (props: ExpressRegistrationComponentProps) => (
      <ApolloReactComponents.Query<ExpressRegistrationQuery, ExpressRegistrationQueryVariables> query={ExpressRegistrationDocument} {...props} />
    );
    

/**
 * __useExpressRegistrationQuery__
 *
 * To run a query within a React component, call `useExpressRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpressRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpressRegistrationQuery({
 *   variables: {
 *      account_number: // value for 'account_number'
 *   },
 * });
 */
export function useExpressRegistrationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExpressRegistrationQuery, ExpressRegistrationQueryVariables>) {
        return ApolloReactHooks.useQuery<ExpressRegistrationQuery, ExpressRegistrationQueryVariables>(ExpressRegistrationDocument, baseOptions);
      }
export function useExpressRegistrationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExpressRegistrationQuery, ExpressRegistrationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExpressRegistrationQuery, ExpressRegistrationQueryVariables>(ExpressRegistrationDocument, baseOptions);
        }
export type ExpressRegistrationQueryHookResult = ReturnType<typeof useExpressRegistrationQuery>;
export type ExpressRegistrationLazyQueryHookResult = ReturnType<typeof useExpressRegistrationLazyQuery>;
export type ExpressRegistrationQueryResult = ApolloReactCommon.QueryResult<ExpressRegistrationQuery, ExpressRegistrationQueryVariables>;
export const GetCustomerFirstnameByEmailDocument = gql`
    query GetCustomerFirstnameByEmail($email: String!) {
  getCustomerFirstnameByEmail(email: $email) {
    firstname
  }
}
    `;
export type GetCustomerFirstnameByEmailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCustomerFirstnameByEmailQuery, GetCustomerFirstnameByEmailQueryVariables>, 'query'> & ({ variables: GetCustomerFirstnameByEmailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCustomerFirstnameByEmailComponent = (props: GetCustomerFirstnameByEmailComponentProps) => (
      <ApolloReactComponents.Query<GetCustomerFirstnameByEmailQuery, GetCustomerFirstnameByEmailQueryVariables> query={GetCustomerFirstnameByEmailDocument} {...props} />
    );
    

/**
 * __useGetCustomerFirstnameByEmailQuery__
 *
 * To run a query within a React component, call `useGetCustomerFirstnameByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerFirstnameByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerFirstnameByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetCustomerFirstnameByEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerFirstnameByEmailQuery, GetCustomerFirstnameByEmailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerFirstnameByEmailQuery, GetCustomerFirstnameByEmailQueryVariables>(GetCustomerFirstnameByEmailDocument, baseOptions);
      }
export function useGetCustomerFirstnameByEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerFirstnameByEmailQuery, GetCustomerFirstnameByEmailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerFirstnameByEmailQuery, GetCustomerFirstnameByEmailQueryVariables>(GetCustomerFirstnameByEmailDocument, baseOptions);
        }
export type GetCustomerFirstnameByEmailQueryHookResult = ReturnType<typeof useGetCustomerFirstnameByEmailQuery>;
export type GetCustomerFirstnameByEmailLazyQueryHookResult = ReturnType<typeof useGetCustomerFirstnameByEmailLazyQuery>;
export type GetCustomerFirstnameByEmailQueryResult = ApolloReactCommon.QueryResult<GetCustomerFirstnameByEmailQuery, GetCustomerFirstnameByEmailQueryVariables>;
export const IsEmailAvailableDocument = gql`
    query IsEmailAvailable($email: String!) {
  isEmailAvailable(email: $email) {
    isEmailAvailable: is_email_available
  }
}
    `;
export type IsEmailAvailableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IsEmailAvailableQuery, IsEmailAvailableQueryVariables>, 'query'> & ({ variables: IsEmailAvailableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const IsEmailAvailableComponent = (props: IsEmailAvailableComponentProps) => (
      <ApolloReactComponents.Query<IsEmailAvailableQuery, IsEmailAvailableQueryVariables> query={IsEmailAvailableDocument} {...props} />
    );
    

/**
 * __useIsEmailAvailableQuery__
 *
 * To run a query within a React component, call `useIsEmailAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmailAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmailAvailableQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsEmailAvailableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsEmailAvailableQuery, IsEmailAvailableQueryVariables>) {
        return ApolloReactHooks.useQuery<IsEmailAvailableQuery, IsEmailAvailableQueryVariables>(IsEmailAvailableDocument, baseOptions);
      }
export function useIsEmailAvailableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsEmailAvailableQuery, IsEmailAvailableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsEmailAvailableQuery, IsEmailAvailableQueryVariables>(IsEmailAvailableDocument, baseOptions);
        }
export type IsEmailAvailableQueryHookResult = ReturnType<typeof useIsEmailAvailableQuery>;
export type IsEmailAvailableLazyQueryHookResult = ReturnType<typeof useIsEmailAvailableLazyQuery>;
export type IsEmailAvailableQueryResult = ApolloReactCommon.QueryResult<IsEmailAvailableQuery, IsEmailAvailableQueryVariables>;
export const CustomerCheckTokenDocument = gql`
    query CustomerCheckToken {
  customer {
    id
  }
}
    `;
export type CustomerCheckTokenComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CustomerCheckTokenQuery, CustomerCheckTokenQueryVariables>, 'query'>;

    export const CustomerCheckTokenComponent = (props: CustomerCheckTokenComponentProps) => (
      <ApolloReactComponents.Query<CustomerCheckTokenQuery, CustomerCheckTokenQueryVariables> query={CustomerCheckTokenDocument} {...props} />
    );
    

/**
 * __useCustomerCheckTokenQuery__
 *
 * To run a query within a React component, call `useCustomerCheckTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCheckTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCheckTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerCheckTokenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomerCheckTokenQuery, CustomerCheckTokenQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomerCheckTokenQuery, CustomerCheckTokenQueryVariables>(CustomerCheckTokenDocument, baseOptions);
      }
export function useCustomerCheckTokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerCheckTokenQuery, CustomerCheckTokenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomerCheckTokenQuery, CustomerCheckTokenQueryVariables>(CustomerCheckTokenDocument, baseOptions);
        }
export type CustomerCheckTokenQueryHookResult = ReturnType<typeof useCustomerCheckTokenQuery>;
export type CustomerCheckTokenLazyQueryHookResult = ReturnType<typeof useCustomerCheckTokenLazyQuery>;
export type CustomerCheckTokenQueryResult = ApolloReactCommon.QueryResult<CustomerCheckTokenQuery, CustomerCheckTokenQueryVariables>;
export const GetProductInfoDocument = gql`
    query getProductInfo($sku: [String]) {
  getProductInfo(sku: $sku) {
    image
    name
    price
    description
  }
}
    `;
export type GetProductInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProductInfoQuery, GetProductInfoQueryVariables>, 'query'>;

    export const GetProductInfoComponent = (props: GetProductInfoComponentProps) => (
      <ApolloReactComponents.Query<GetProductInfoQuery, GetProductInfoQueryVariables> query={GetProductInfoDocument} {...props} />
    );
    

/**
 * __useGetProductInfoQuery__
 *
 * To run a query within a React component, call `useGetProductInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductInfoQuery({
 *   variables: {
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function useGetProductInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductInfoQuery, GetProductInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductInfoQuery, GetProductInfoQueryVariables>(GetProductInfoDocument, baseOptions);
      }
export function useGetProductInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductInfoQuery, GetProductInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductInfoQuery, GetProductInfoQueryVariables>(GetProductInfoDocument, baseOptions);
        }
export type GetProductInfoQueryHookResult = ReturnType<typeof useGetProductInfoQuery>;
export type GetProductInfoLazyQueryHookResult = ReturnType<typeof useGetProductInfoLazyQuery>;
export type GetProductInfoQueryResult = ApolloReactCommon.QueryResult<GetProductInfoQuery, GetProductInfoQueryVariables>;
export const ProductDocument = gql`
    query Product($filters: ProductAttributeFilterInput!) {
  products(filter: $filters) {
    total_count
    items {
      id
      name
      description {
        html
      }
      sku
      url_key
      media_gallery_entries {
        id
        file
        content {
          base64_encoded_data
          name
          type
        }
        disabled
      }
      categories {
        id
      }
      image {
        label
        url
      }
      small_image {
        label
        url
      }
      price_range {
        maximum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            amount {
              currency
              value
            }
            label
          }
          regular_price {
            currency
            value
          }
        }
        minimum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            amount {
              currency
              value
            }
            label
          }
          regular_price {
            currency
            value
          }
        }
      }
      tier_prices {
        customer_group_id
        percentage_value
        qty
        value
        website_id
      }
    }
  }
}
    `;
export type ProductComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProductQuery, ProductQueryVariables>, 'query'> & ({ variables: ProductQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProductComponent = (props: ProductComponentProps) => (
      <ApolloReactComponents.Query<ProductQuery, ProductQueryVariables> query={ProductDocument} {...props} />
    );
    

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, baseOptions);
      }
export function useProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, baseOptions);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = ApolloReactCommon.QueryResult<ProductQuery, ProductQueryVariables>;
export const ProductsDocument = gql`
    query Products($filters: ProductAttributeFilterInput!, $sort: ProductAttributeSortInput, $pageSize: Int, $currentPage: Int, $search: String) {
  products(filter: $filters, sort: $sort, pageSize: $pageSize, currentPage: $currentPage, search: $search) {
    total_count
    items {
      id
      name
      sku
      url_key
      attribute_set_name
      media_gallery_entries {
        id
        file
        content {
          base64_encoded_data
          name
          type
        }
        disabled
      }
      categories {
        id
      }
      image {
        label
        url
      }
      small_image {
        label
        url
      }
      price_range {
        maximum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            amount {
              currency
              value
            }
            label
          }
          regular_price {
            currency
            value
          }
        }
        minimum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            amount {
              currency
              value
            }
            label
          }
          regular_price {
            currency
            value
          }
        }
      }
      tier_prices {
        customer_group_id
        percentage_value
        qty
        value
        website_id
      }
    }
    page_info {
      page_size
      total_pages
      current_page
    }
    sort_fields {
      options {
        label
        value
      }
      default
    }
  }
}
    `;
export type ProductsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProductsQuery, ProductsQueryVariables>, 'query'> & ({ variables: ProductsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProductsComponent = (props: ProductsComponentProps) => (
      <ApolloReactComponents.Query<ProductsQuery, ProductsQueryVariables> query={ProductsDocument} {...props} />
    );
    

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *      pageSize: // value for 'pageSize'
 *      currentPage: // value for 'currentPage'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions);
      }
export function useProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = ApolloReactCommon.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const AllFilesDocument = gql`
    query AllFiles {
  getAllFiles {
    ...DomainFileData
  }
}
    ${DomainFileDataFragmentDoc}`;
export type AllFilesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllFilesQuery, AllFilesQueryVariables>, 'query'>;

    export const AllFilesComponent = (props: AllFilesComponentProps) => (
      <ApolloReactComponents.Query<AllFilesQuery, AllFilesQueryVariables> query={AllFilesDocument} {...props} />
    );
    

/**
 * __useAllFilesQuery__
 *
 * To run a query within a React component, call `useAllFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFilesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllFilesQuery, AllFilesQueryVariables>) {
        return ApolloReactHooks.useQuery<AllFilesQuery, AllFilesQueryVariables>(AllFilesDocument, baseOptions);
      }
export function useAllFilesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllFilesQuery, AllFilesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllFilesQuery, AllFilesQueryVariables>(AllFilesDocument, baseOptions);
        }
export type AllFilesQueryHookResult = ReturnType<typeof useAllFilesQuery>;
export type AllFilesLazyQueryHookResult = ReturnType<typeof useAllFilesLazyQuery>;
export type AllFilesQueryResult = ApolloReactCommon.QueryResult<AllFilesQuery, AllFilesQueryVariables>;
export const CustomerOrdersDocument = gql`
    query CustomerOrders {
  customerOrders {
    items {
      id
      order_number
      created_at
      grand_total
      status
    }
  }
}
    `;
export type CustomerOrdersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CustomerOrdersQuery, CustomerOrdersQueryVariables>, 'query'>;

    export const CustomerOrdersComponent = (props: CustomerOrdersComponentProps) => (
      <ApolloReactComponents.Query<CustomerOrdersQuery, CustomerOrdersQueryVariables> query={CustomerOrdersDocument} {...props} />
    );
    

/**
 * __useCustomerOrdersQuery__
 *
 * To run a query within a React component, call `useCustomerOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomerOrdersQuery, CustomerOrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomerOrdersQuery, CustomerOrdersQueryVariables>(CustomerOrdersDocument, baseOptions);
      }
export function useCustomerOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerOrdersQuery, CustomerOrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomerOrdersQuery, CustomerOrdersQueryVariables>(CustomerOrdersDocument, baseOptions);
        }
export type CustomerOrdersQueryHookResult = ReturnType<typeof useCustomerOrdersQuery>;
export type CustomerOrdersLazyQueryHookResult = ReturnType<typeof useCustomerOrdersLazyQuery>;
export type CustomerOrdersQueryResult = ApolloReactCommon.QueryResult<CustomerOrdersQuery, CustomerOrdersQueryVariables>;
export const SearchDocument = gql`
    query Search($search: String, $sort: ProductAttributeSortInput, $pageSize: Int, $currentPage: Int) {
  products(search: $search, sort: $sort, pageSize: $pageSize, currentPage: $currentPage) {
    total_count
    items {
      id
      name
      sku
      url_key
      media_gallery_entries {
        id
        file
        content {
          base64_encoded_data
          name
          type
        }
        disabled
      }
      categories {
        id
      }
      image {
        label
        url
      }
      small_image {
        label
        url
      }
      price_range {
        maximum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            amount {
              currency
              value
            }
            label
          }
          regular_price {
            currency
            value
          }
        }
        minimum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            amount {
              currency
              value
            }
            label
          }
          regular_price {
            currency
            value
          }
        }
      }
      tier_prices {
        customer_group_id
        percentage_value
        qty
        value
        website_id
      }
    }
    filters {
      filter_items {
        items_count
        label
        value_string
      }
      filter_items_count
      name
      request_var
    }
    page_info {
      page_size
      total_pages
      current_page
    }
    sort_fields {
      options {
        label
        value
      }
      default
    }
  }
}
    `;
export type SearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchQuery, SearchQueryVariables>, 'query'>;

    export const SearchComponent = (props: SearchComponentProps) => (
      <ApolloReactComponents.Query<SearchQuery, SearchQueryVariables> query={SearchDocument} {...props} />
    );
    

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      pageSize: // value for 'pageSize'
 *      currentPage: // value for 'currentPage'
 *   },
 * });
 */
export function useSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, baseOptions);
      }
export function useSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, baseOptions);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = ApolloReactCommon.QueryResult<SearchQuery, SearchQueryVariables>;
export const UrlResolverDocument = gql`
    query urlResolver($url: String!) {
  urlResolver(url: $url) {
    id
    relative_url
    canonical_url
    sku
    attribute_set_id
    type
  }
}
    `;
export type UrlResolverComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UrlResolverQuery, UrlResolverQueryVariables>, 'query'> & ({ variables: UrlResolverQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UrlResolverComponent = (props: UrlResolverComponentProps) => (
      <ApolloReactComponents.Query<UrlResolverQuery, UrlResolverQueryVariables> query={UrlResolverDocument} {...props} />
    );
    

/**
 * __useUrlResolverQuery__
 *
 * To run a query within a React component, call `useUrlResolverQuery` and pass it any options that fit your needs.
 * When your component renders, `useUrlResolverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUrlResolverQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useUrlResolverQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UrlResolverQuery, UrlResolverQueryVariables>) {
        return ApolloReactHooks.useQuery<UrlResolverQuery, UrlResolverQueryVariables>(UrlResolverDocument, baseOptions);
      }
export function useUrlResolverLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UrlResolverQuery, UrlResolverQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UrlResolverQuery, UrlResolverQueryVariables>(UrlResolverDocument, baseOptions);
        }
export type UrlResolverQueryHookResult = ReturnType<typeof useUrlResolverQuery>;
export type UrlResolverLazyQueryHookResult = ReturnType<typeof useUrlResolverLazyQuery>;
export type UrlResolverQueryResult = ApolloReactCommon.QueryResult<UrlResolverQuery, UrlResolverQueryVariables>;
export const WishlistsDocument = gql`
    query Wishlists {
  getWishlists {
    ...DomainWishlist
  }
}
    ${DomainWishlistFragmentDoc}`;
export type WishlistsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<WishlistsQuery, WishlistsQueryVariables>, 'query'>;

    export const WishlistsComponent = (props: WishlistsComponentProps) => (
      <ApolloReactComponents.Query<WishlistsQuery, WishlistsQueryVariables> query={WishlistsDocument} {...props} />
    );
    

/**
 * __useWishlistsQuery__
 *
 * To run a query within a React component, call `useWishlistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWishlistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWishlistsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWishlistsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WishlistsQuery, WishlistsQueryVariables>) {
        return ApolloReactHooks.useQuery<WishlistsQuery, WishlistsQueryVariables>(WishlistsDocument, baseOptions);
      }
export function useWishlistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WishlistsQuery, WishlistsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WishlistsQuery, WishlistsQueryVariables>(WishlistsDocument, baseOptions);
        }
export type WishlistsQueryHookResult = ReturnType<typeof useWishlistsQuery>;
export type WishlistsLazyQueryHookResult = ReturnType<typeof useWishlistsLazyQuery>;
export type WishlistsQueryResult = ApolloReactCommon.QueryResult<WishlistsQuery, WishlistsQueryVariables>;