import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";

const SkeletonProduct = (props) => {
  let isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <>
      {!isMobile ? (
        <>
          <MDBContainer fluid className="px-0 my-5 pb-3 overflow-hidden">
            <MDBRow>
              <MDBCol>
                <Skeleton height={`67.4rem`} width={`100%`} />
              </MDBCol>
              <MDBCol>
                <Skeleton height={`67.4rem`} width={`100%`} />
              </MDBCol>
              <MDBCol>
                <Skeleton height={`67.4rem`} width={`100%`} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBContainer fluid className="px-0 my-5 pb-3">
            <MDBRow>
              <MDBCol>
                <Skeleton height={`10rem`} width={`100%`} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBContainer className="my-5 py-5">
            <MDBRow>
              <MDBCol>
                <Skeleton height={`25.7rem`} width={`100%`} />
              </MDBCol>
              <MDBCol>
                <Skeleton height={`25.7rem`} width={`100%`} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBContainer className="py-5">
            <MDBRow>
              <MDBCol>
                <Skeleton height={`54.5rem`} width={`100%`} />
              </MDBCol>
              <MDBCol>
                <Skeleton height={`54.5rem`} width={`100%`} />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <Skeleton height={`54.5rem`} width={`100%`} />
              </MDBCol>
              <MDBCol>
                <Skeleton height={`54.5rem`} width={`100%`} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBContainer fluid className="p-0">
            <MDBRow>
              <MDBCol>
                <Skeleton height={`112.4rem`} width={`100%`} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </>
      ) : (
        <>
          <MDBContainer fluid className="px-0 my-5 pb-3 overflow-hidden">
            <MDBRow>
              <MDBCol>
                <Skeleton height={`36.8rem`} width={`100%`} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBContainer fluid className="px-0 my-5 pb-3">
            <MDBRow>
              <MDBCol>
                <Skeleton height={`24.8rem`} width={`100%`} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBContainer className="my-5 py-5">
            <MDBRow>
              <MDBCol>
                <Skeleton height={`69.5rem`} width={`100%`} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBContainer className="py-5">
            <MDBRow>
              <MDBCol size="12">
                <Skeleton height={`32rem`} width={`100%`} />
              </MDBCol>
              <MDBCol size="12">
                <Skeleton height={`32rem`} width={`100%`} />
              </MDBCol>
              <MDBCol size="12">
                <Skeleton height={`32rem`} width={`100%`} />
              </MDBCol>
              <MDBCol size="12">
                <Skeleton height={`32rem`} width={`100%`} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBContainer fluid className="p-0">
            <MDBRow>
              <MDBCol>
                <Skeleton height={`74rem`} width={`100%`} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </>
      )}
    </>
  );
};
export default SkeletonProduct;
