import { useGallery } from "app/pages/Gallery/hooks/useGallery";
import React, { useState } from "react";
import GalleryDetail from "./galleryDetail";

const GalleryDetailsDataComponent = ({
  galleryName,
  toggleModal,
  popupVisible,
  bottomGalleryImages,
  mainImage,
}) => {
  const { galleries } = useGallery();

  const path = galleryName; //galleryName
  let [isDescriptionOpen, setIsDescriptionOpen] = useState();
  let toggleDescription = () => {
    setIsDescriptionOpen(!isDescriptionOpen);
  };

  if (galleries === undefined) {
    return <></>;
  }

  const currentGallery = galleries.find((gallery) => {
    let convertedName = gallery?.name
      ? `/inspirations/${gallery.name
          .split(" ")
          .join("-")
          .toLowerCase()
          .replace(/,/, "")
          .replace(/-$/, "")}`
      : "/inspirations/";
    return path === convertedName && gallery?.parent_id === "0";
  });

  if (currentGallery === undefined) {
    return <></>;
  }

  let image = currentGallery.image;
  if (currentGallery.hotspot.length > 0) {
    image = currentGallery.hotspot[0]?.image;
  }
  let hotspots = currentGallery.hotspot;

  return (
    <GalleryDetail
      category={currentGallery?.category}
      description={currentGallery?.description}
      hotspots={hotspots}
      image={image}
      name={currentGallery?.name}
      isOpen={isDescriptionOpen}
      toggle={toggleDescription}
      toggleModal={toggleModal}
      popupVisible={popupVisible}
      bottomGalleryImages={bottomGalleryImages}
      mainImage={mainImage}
    />
  );
};

export default GalleryDetailsDataComponent;
