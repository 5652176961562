// TODO add back // @ts-heck

import Breadcrumbs from "app/layout/breadcrumbs";
import {
  MDBBox,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import Skeleton from "react-loading-skeleton";
import React, { useCallback, useState } from "react";
import GalleryWidget from "./galleryWidget";
import { useMediaQuery } from "react-responsive";
import Icon from "app/assets/icon/icon";

const GalleryDisplay = ({
  title = "",
  categories = [],
  galleries = [],
  setCategoryFilter = (cat) => {},
  category = "",
}) => {
  const [modal, setModal] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const toggleOne = useCallback(
    () => setModal((currentModal) => !currentModal),
    []
  );

  const categoryIncludes = (category, word) =>
    category?.toLowerCase()?.includes(word);

  return (
    <MDBContainer fluid className="gallery-container">
      <MDBContainer>
        <MDBRow>
          <MDBCol>
            <Breadcrumbs />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <MDBTypography tag="h1" className="my-4">
              {title}
            </MDBTypography>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBRow
        className="bg-gray-lighten-4"
        style={{ whiteSpace: "nowrap", overflowX: "auto" }}
      >
        <MDBCol>
          <MDBContainer className="overflow-auto">
            <MDBRow>
              <MDBCol>
                {categories.sort().map((cat, index) => {
                  return (
                    <MDBBox
                      key={cat}
                      // @ts-ignore
                      onClick={() => {
                        setCategoryFilter(cat);
                      }}
                      tag="div"
                      className={`tab horisontal-tab pt-3 h-100 mr-5 ${
                        category === cat ? "active" : ""
                      }`.trim()}
                    >
                      {cat}
                    </MDBBox>
                  );
                })}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              {(galleries.length !== 0 && (
                <MDBBox className="py-5 gallery-items-wrapper">
                  {galleries.map((gallery) => {
                    if (gallery.category.includes(category)) {
                      if (
                        gallery.parent_id === null ||
                        gallery.parent_id === "0"
                      )
                        return (
                          <GalleryWidget {...gallery} galleries={galleries} />
                        );
                    }
                    return null;
                  })}
                </MDBBox>
              )) || (
                <MDBBox className={`mb-5 mt-5`}>
                  <Skeleton width={`100%`} height={`50rem`} />
                </MDBBox>
              )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBRow>
      {isMobile && modal && (
        <MDBModal
          isOpen={modal}
          toggle={toggleOne}
          centered
          className="modal-wrapper main-modal main-modal-hotspots"
        >
          <MDBModalHeader
            onClick={toggleOne}
            className="d-flex bg-default mx-0 p-4 justify-content-center align-items-center position-relative"
          >
            <MDBBox
              className="ml-4 pl-2 position-absolute"
              style={{
                left: "0",
                top: "0",
                bottom: "0",
                transform: "translate(-15%, 15%)",
              }}
            >
              <Icon icon="closeWhite" />
            </MDBBox>
            <MDBRow style={{ position: "static" }}>
              <MDBBox>
                <MDBTypography
                  tag="h2"
                  variant="display-2"
                  className="text-white font-weight-light text-center"
                >
                  For Best Viewing
                </MDBTypography>
              </MDBBox>
            </MDBRow>
          </MDBModalHeader>

          <MDBModalBody className="text-center py-5">
            <MDBBox>
              <MDBTypography tag="span" className="body-2">
                For best viewing on mobile, rotate your phone to the landscape
                view
              </MDBTypography>
            </MDBBox>
            <MDBBox>
              <Icon className="mt-4" icon={"rotatePhoneIcon"} />
            </MDBBox>
          </MDBModalBody>
        </MDBModal>
      )}
    </MDBContainer>
  );
};

export default GalleryDisplay;
