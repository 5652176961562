import React from "react";
import {
  MDBBox,
  MDBCol,
  MDBContainer,
  MDBMask,
  MDBRow,
  MDBTypography,
  MDBView,
} from "mdbreact";
import CmsBlock from "app/layout/cmsBlock";
import { useMediaQuery } from "react-responsive";

function Vdc() {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <MDBContainer className="homepage-vdc-wrapper p-0 p-lg-2 mt-4">
      <MDBContainer className="homepage-vdc-image-wrapper">
        <MDBRow className="mb-5">
          <MDBCol
            className="d-none d-lg-block px-0"
            size={isMobile ? "2" : "3"}
          >
            <MDBBox className="ml-3 h-50 border-bottom border-default" />
          </MDBCol>
          <MDBCol className="d-flex justify-content-center p-0 align-items-center">
            <MDBTypography tag="h2" variant="display-2" className="ml-5 mr-5">
              Virtual Design Challenge
            </MDBTypography>
          </MDBCol>
          <MDBCol
            className="d-none d-lg-block px-0"
            size={isMobile ? "2" : "3"}
          >
            <MDBBox className="h-50 border-bottom border-default mr-3" />
          </MDBCol>
        </MDBRow>
        <MDBView>
          <CmsBlock
            id="virtual_design_challenge_image"
            skeletonProps={{ height: "60rem", width: "100%" }}
          />
        </MDBView>
      </MDBContainer>
    </MDBContainer>
  );
}

export default Vdc;
