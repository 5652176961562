import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { modelsArray } from "app/pages/Custom/monogram/data";
import { selectIsLoggedIn } from "app/state/redux/data/customer/selectors";
import { useSelector } from "react-redux";

export const MONOGRAM_QUERY = gql`
  {
    monogram {
      items {
        id
        sku
        # uid
        name
        image {
          url(width: 700, height: 700)
          label
        }
        small_image {
          url(width: 265, height: 265)
          label
        }
        thumbnail {
          url(width: 150, height: 150)
          label
        }
      }
    }
  }
`;

export const MONOGRAM_LOGGED_IN_QUERY = gql`
  {
    monogram {
      items {
        id
        sku
        # uid
        name
        price_range {
          minimum_price {
            final_price {
              value
              currency
            }
          }
        }
        image {
          url(width: 700, height: 700)
          label
        }
        small_image {
          url(width: 265, height: 265)
          label
        }
        thumbnail {
          url(width: 150, height: 150)
          label
        }
      }
    }
  }
`;

function useMonogramHook(props) {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { data } = useQuery(
    isLoggedIn ? MONOGRAM_LOGGED_IN_QUERY : MONOGRAM_QUERY,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const findModelBySku = (sku) =>
    modelsArray?.find((model) => model.sku === sku);

  let results =
    data?.monogram?.items
      ?.filter((model) => modelsArray?.some((item) => item?.sku === model?.sku))
      ?.map((res) => ({
        ...res,
        image: res.image?.url,
        id: res?.sku,
        parentName: "Frame",
        finishIDs: findModelBySku(res?.sku)?.finishIDs || [],
        contrastDrawers: findModelBySku(res?.sku)?.contrastDrawers || false,
        striping: findModelBySku(res?.sku)?.striping || false,
      })) || [];

  // for (let i = 0; i < modelsArray.length; i++) {
  //   const element = modelsArray[i];
  //   if (!results?.find((res) => res?.sku === element?.sku))
  //     results.push({ ...element, parentName: "Frame" });
  // }

  return { monogramData: results };
}

export default useMonogramHook;
