import { CustomerCartQuery } from "app/generated/graphql";
import React, { useContext, useEffect } from "react";
import { useQuantityForm, useRemoveItemFromCart } from "../../hooks";
import { ItemProps } from "../../models";
import Item from "./Item";
import { CartDataContext } from "../../context/CartDataContext";

export interface DataContainerProps
  extends Omit<ItemProps, "form" | "removeItemFromCart"> {
  cart: CustomerCartQuery["customerCart"];
}

const DataContainer: React.FC<DataContainerProps> = (props) => {
  const input = React.useMemo(
    () => ({ cart: props.cart, cart_item_id: Number(props.item.id) }),
    [props.cart, props.item]
  );
  // @ts-ignore
  const { refetchData, setRefetchData } = useContext(CartDataContext);

  const [removeItemFromCart, result] = useRemoveItemFromCart(input);

  useEffect(() => {
    if (result.called) {
      setRefetchData(true);
    }
  }, [result]);

  const quantityForm = useQuantityForm(
    React.useMemo(() => ({ ...input, quantity: props.item.quantity }), [
      input,
      props.item.quantity,
    ])
  );

  return (
    <Item
      {...props}
      form={quantityForm}
      removeItemFromCart={removeItemFromCart}
    />
  );
};

export default DataContainer;
