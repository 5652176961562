import ProductWidget from "app/layout/productWidget";
import { getBadges, getIsFavorite } from "app/pages/collections/utils";
import { MDBRow } from "mdbreact";
import React from "react";
import { useLocation } from "react-router-dom";
import { useWishlists } from "app/pages/collections/hooks";
import themeConfiguration from "config/themeConfiguration";
import CollectionsContext from "app/layout/collections/CollectionsContext";

const Listing = ({
  items,
  favorites,
  onToggleFavorite,
  isBuildYourOwn = false,
  isFabricOrFinishesCategory,
  isFinish,
  categoryName,
  isSearchPage,
}) => {
  let location = useLocation();
  const wishlists = useWishlists();
  const { setSelectedProduct } = React.useContext(CollectionsContext);

  return (
    <MDBRow>
      {items
        ?.filter(
          (item) =>
            !item?.name?.toLowerCase()?.includes("-mg") &&
            !item?.name?.toLowerCase()?.includes("-fs")
        )
        ?.map((item) => {
          const badges = getBadges(item);
          const isFavorite = getIsFavorite({
            favorites: favorites ?? [],
            sku: item.sku,
          });
          let arr = [];
          wishlists.map((wishlist) => {
            return wishlist.items?.filter((wishistitem) => {
              if (wishistitem?.product?.sku === item?.sku) {
                arr.push(wishistitem);
                return wishistitem;
              }
            });
          });

          let price =
            item?.price_range?.minimum_price?.final_price?.value || undefined;
          const handleToggleFavorite = () => {
            if (!isFavorite) {
              setSelectedProduct(item);
            } else {
              setSelectedProduct(null);
            }
            onToggleFavorite({
              product: {
                ...item,
              },
              checked: !isFavorite,
            });
          };

          return (
            <ProductWidget
              key={item.sku}
              image={item.image}
              name={item.name}
              sku={item.sku}
              product={item}
              url={
                //Product that have the same sku as BYO products from collections (Haven Reserve, Urban Living, Senior Living Solutions - Products category) redirected to BYO PDP
                item?.ffi_collection && item?.ffi_cfgmodel
                  ? item?.ffi_collection?.[0] !== false &&
                    item?.ffi_collection?.includes("Haven Reserve")
                    ? `build-your-own/haven-reserve/${item.sku}`
                    : item.ffi_collection?.includes("Urban Living")
                    ? `build-your-own/urban-living/${item.sku}`
                    : item.ffi_collection?.includes("Senior Living Solutions")
                    ? `build-your-own/senior-living-solutions/${item.sku}`
                    : item?.ffi_collection?.includes("Monogram")
                    ? `build-your-own/monogram/${item.sku}`
                    : location.pathname === "/search-results"
                    ? item.url_key
                    : location.pathname.substring(1) + "/" + item.url_key
                  : item.url_key
              }
              colSize="6"
              thumbnail={item.thumbnail}
              price={price}
              mdSize="3"
              className={"px-5 py-3 mb-4"}
              isFavorite={isFavorite}
              is_new={item.is_new}
              onToggleFavorite={handleToggleFavorite}
              badges={badges}
              isBuildYourOwn={isBuildYourOwn}
              //Remove heart (favorites) when the product is in one of these collections
              showFavorites={
                isBuildYourOwn
                  ? false
                  : item?.ffi_collection?.[0] === "Haven Reserve"
                  ? false
                  : item?.ffi_collection?.[0] === "Urban Living"
                  ? false
                  : item?.ffi_collection?.[0] === "Senior Living Solutions"
                  ? false
                  : true
              }
              isFinish={isFinish}
              grade={item.ffifabrics_grade}
              items={items}
              isFullHeart={arr.length > 0 && !isFavorite ? true : false}
              categoryName={categoryName}
              isSearchPage={isSearchPage}
            />
          );
        })}
    </MDBRow>
  );
};

export default React.memo(Listing);
