import React, { useMemo } from "react";
import UpPart from "./UpPart";
import { MDBTypography } from "mdbreact";
import Table from "../Table";

function SingleOrder({
  customer,
  orderNumber,
  formatDate,
  isTablet,
  ordersHead,
  isMobile,
  location,
}) {
  let order = useMemo(
    () =>
      customer?.orders?.items?.find(
        (item) => item?.number === orderNumber?.trim()
      ),
    [customer?.orders?.items]
  );

  if (!order) return <p>No quote request found.</p>;

  return (
    <div className="order-page">
      <UpPart formatDate={formatDate} order={order} isTablet={isTablet} />
      <MDBTypography tag="h3" className="mb-3 mt-3">
        Item(s) Ordered
      </MDBTypography>
      <Table
        ordersHead={ordersHead}
        location={location}
        data={order?.items}
        isTablet={isTablet}
        grand_total={order?.grand_total}
      />
    </div>
  );
}

export default SingleOrder;
