import React from "react";
import Icon from "app/assets/icon/icon";
import clsx from "clsx";
import _ from "lodash";
import { MDBCol, MDBRow, MDBTypography, MDBCollapse } from "mdbreact";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const AdditionalInformations = ({
  info,
  setAdditionalInfo,
  additionalInfo,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const toggleCollapse = (selected) => {
    let additionalInfoCopy = [...additionalInfo];
    const infoIndex = additionalInfoCopy.findIndex(
      (item) => item.title === selected.title
    );
    if (infoIndex > -1) {
      if (additionalInfoCopy[infoIndex].isOpen) {
        additionalInfoCopy[infoIndex].isOpen = false;
      } else {
        additionalInfoCopy[infoIndex].isOpen = true;
      }
    }
    setAdditionalInfo(additionalInfoCopy);
  };
  return (
    <MDBCol
      size="12"
      className="sub-options-section-wrapper addition-info pr-lg-0 pr-4"
    >
      <MDBRow
        onClick={() => toggleCollapse(info)}
        className={`align-items-center sub-options-title ${
          // info?.title === "Description" && "mt-5"
          info?.title === "Details" && "mt-5"
        }`}
        style={{ marginLeft: isMobile && "0px" }}
      >
        <MDBCol size="10">
          <MDBTypography
            tag="h4"
            className={clsx("cursor-pointer body-2 fwregular text-darken-gray")}
          >
            {info?.title}
          </MDBTypography>
        </MDBCol>
        <MDBCol size="2" className="sub-options-title-icon cursor-pointer">
          {info?.isOpen && <Icon icon="minusBlue" />}
          {!info?.isOpen && <Icon icon="plusBlue" />}
        </MDBCol>
      </MDBRow>
      <MDBRow className="py-3">
        <MDBCol className="pl-0 sub-options">
          <MDBCollapse isOpen={info?.isOpen}>
            <MDBRow className="py-2">
              <MDBCol
                size="12"
                className={`py-3 ${!isMobile ? "pl-0" : ""} pl-lg-5`}
              >
                {info?.title === "Description" && (
                  <>
                    <MDBTypography tag="p" className="body-2 fwlight mb-3">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Illo, expedita dolores delectus quaerat cum deleniti!
                    </MDBTypography>
                    <Link className="link-text">Read More</Link>
                  </>
                )}
                {info?.title === "Dimensions (in)" && (
                  <>
                    {info?.dimensions.map(
                      (dimension) =>
                        dimension?.value &&
                        dimension?.value !== 0 &&
                        dimension?.value !== ".00" &&
                        dimension?.value !== ".000" &&
                        dimension?.value !== "N/A" && (
                          <MDBRow key={dimension?.title}>
                            <MDBCol size="6">
                              <MDBTypography
                                tag="p"
                                className="body-2 fwlight mb-3"
                              >
                                {dimension?.title}
                              </MDBTypography>
                            </MDBCol>
                            <MDBCol size="6">
                              <MDBTypography
                                tag="p"
                                className="body-2 fwlight mb-3"
                              >
                                {dimension?.value}
                              </MDBTypography>
                            </MDBCol>
                          </MDBRow>
                        )
                    )}
                  </>
                )}
                {info?.title === "Details" && (
                  <>
                    {info?.details.map(
                      (detail) =>
                        detail?.value &&
                        detail?.value !== 0 &&
                        detail?.value !== ".00" &&
                        detail?.value !== ".000" &&
                        detail?.value !== "N/A" && (
                          <MDBRow key={detail?.title}>
                            <MDBCol size="6">
                              <MDBTypography
                                tag="p"
                                className="body-2 fwlight mb-3"
                              >
                                {detail?.title}
                              </MDBTypography>
                            </MDBCol>
                            <MDBCol size="6">
                              <MDBTypography
                                tag="p"
                                className="body-2 fwlight mb-3"
                              >
                                {detail?.value}
                              </MDBTypography>
                            </MDBCol>
                          </MDBRow>
                        )
                    )}
                  </>
                )}
                {info?.title === "Specifications" && (
                  <>
                    {info?.specifications.map(
                      (specification) =>
                        specification?.value &&
                        specification?.value !== 0 &&
                        specification?.value !== ".00" &&
                        specification?.value !== ".000" &&
                        specification?.value !== "N/A" && (
                          <MDBRow key={specification?.title}>
                            <MDBCol size="6">
                              <MDBTypography
                                tag="p"
                                className="body-2 fwlight mb-3"
                              >
                                {specification?.title}
                              </MDBTypography>
                            </MDBCol>
                            <MDBCol size="6">
                              <MDBTypography
                                tag="p"
                                className="body-2 fwlight mb-3"
                              >
                                {specification?.value}
                              </MDBTypography>
                            </MDBCol>
                          </MDBRow>
                        )
                    )}
                  </>
                )}
              </MDBCol>
            </MDBRow>
          </MDBCollapse>
        </MDBCol>
      </MDBRow>
    </MDBCol>
  );
};

export default AdditionalInformations;
