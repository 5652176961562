import { useGetCustomerFirstnameByEmailLazyQuery } from "app/generated/graphql";
import { customerPaths } from "app/pages/customer/routes";
import { messagesActions } from "core/state/redux/data/messages";
import { FormikConfig, useFormik } from "formik";
import qs from "qs";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  emailValidationSchema,
  EmailValues,
  initialEmailValues,
} from "./config";
import EmailAndPassword from "./EmailAndPassword";

export interface DataContainerProps {
  onClose: () => void;
  setUserData: () => void;
}

const DataContainer: React.FC<DataContainerProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [getCustomerFirstnameByEmail] = useGetCustomerFirstnameByEmailLazyQuery(
    {
      onCompleted: ({ getCustomerFirstnameByEmail }) => {
        if (getCustomerFirstnameByEmail?.firstname) {
          // @ts-ignore
          props?.setUserData((prevProps) => ({
            ...prevProps,
            firstName: getCustomerFirstnameByEmail?.firstname,
          }));
        } else {
          dispatch(
            messagesActions.addMessage("Please register before logging in.")
          );
          history.push(customerPaths.children.register.path);
        }
      },
      onError: ({ graphQLErrors: [{ message }] }) => {
        if (
          message.toLowerCase() === "customer with this email does not exist"
        ) {
          form.setFieldError(
            "email",
            "Welcome! Please complete the New Registration below."
          );
        } else {
          form.setFieldError("email", message);
        }
      },
    }
  );

  const config: FormikConfig<EmailValues> = React.useMemo(
    () => ({
      initialValues: initialEmailValues,
      validationSchema: emailValidationSchema,
      onSubmit: ({ email }) => {
        // @ts-ignore
        props?.setUserData((prevProps) => ({
          ...prevProps,
          email,
        }));
        getCustomerFirstnameByEmail({ variables: { email } });
      },
    }),
    [getCustomerFirstnameByEmail]
  );
  const form = useFormik(config);

  const handleExpressSetup = React.useCallback(() => {
    history.push({ search: qs.stringify({ setup: "express" }) });
  }, [history]);

  return <EmailAndPassword form={form} onExpressSetup={handleExpressSetup} />;
};

export default DataContainer;
