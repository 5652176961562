import * as yup from "yup";

export interface AccountNumberValues {
  accountNumber: string;
}

export const accountNumberValues: AccountNumberValues = {
  accountNumber: "",
};

export const accountNumberSchema: yup.ObjectSchema<AccountNumberValues> = yup
  .object()
  .required()
  .shape({
    accountNumber: yup.string().required("Account number is required."),
  });
