import React from "react";
import Logo from "../../../logo";
import { MDBCol } from "mdbreact";
const LogoMobile = (props) => {
  return (
    <MDBCol size="12" id={`fairfield-logo-mobile`}>
      <Logo {...props} />
    </MDBCol>
  );
};

export default LogoMobile;
