import { useCountriesQuery } from "app/generated/graphql";
import React from "react";
import { isCountryDefined } from "../../utils";
import Addresses, { AddressesProps } from "./Addresses";
import queryLoader from "app/graphql/queryLoader";
import { useQuery } from "react-apollo-hooks";
import ReactLoader from "app/layout/loader";

export interface DataContainerProps
  extends Omit<AddressesProps, "countries" | "addresses"> {}

const DataContainer: React.FC<DataContainerProps> = () => {
  const countriesQuery = useCountriesQuery();

  const { data, loading } = useQuery(queryLoader("customer"), {
    fetchPolicy: "cache-and-network",
  });

  if (loading)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <ReactLoader />
      </div>
    );

  return (
    <Addresses
      countries={(countriesQuery.data?.countries ?? []).filter(
        isCountryDefined
      )}
      addresses={data?.customer?.addresses}
    />
  );
};

export default DataContainer;
