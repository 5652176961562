import React, { useContext, useEffect, useState } from "react";
import Icon from "app/assets/icon/icon";
import Tooltip from "app/layout/tooltip";
import {
  TooltipContent,
  TooltipTriggerElement,
} from "app/layout/tooltip/tooltip";
import { MDBCol, MDBRow } from "mdbreact";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "app/state/redux/data/customer/selectors";
import { CheckBoxContext } from "../../checkboxContext";
import themeConfiguration from "config/themeConfiguration";

const FinishItem = (props) => {
  let {
    option,
    preselection,
    setPreselection,
    index,
    isMobile,
    setCustomizationOption,
    // hideModal,
    optId,
    isNailHead,
    isNailSize,
    nailData,
    isCustom,
    openedOption,
    customer,
  } = props;

  let imageUrl =
    isNailHead || isNailSize ? option?.map : option?.thumbnail?.url;
  // const [hasBagde, setHasBadge] = useState(false);
  // const [showNotification, setShowNotification] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [currentOptionSelected, setCurrentOptionSelected] = useState(false);
  let urlBuild = window.location.href.indexOf("build-your-own") > -1;

  let setPlayerOption = () => {};

  if (isNailSize && isCustom) {
    setPlayerOption = nailData.setNailSize;
  }
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const {
    // checkBoxOptions,
    // setCheckBoxOptions,
    // viewMoreOptions,
    // setViewMoreOptions,
    selectedFinish,
    setSelectedFinish,
    setNailPrice,
  } = useContext(CheckBoxContext);
  useEffect(() => {
    setCurrentOptionSelected(
      (!isNailSize &&
        option.id ===
          selectedFinish?.[
            openedOption.title.toLowerCase().split(" ").join("_")
          ]?.id) ||
        (isNailSize &&
          option.name ===
            selectedFinish?.[
              openedOption.title.toLowerCase().split(" ").join("_")
            ]?.name)
    );
  }, [isNailSize, option, selectedFinish]);

  {
    /*
  React.useEffect(() => {
    const badge = finishArray.find((item) => item.id === option.sku);
    if (badge) {
      setHasBadge(true);
    } else {
      setHasBadge(false);
    }
  }, [option]);
  React.useEffect(() => {
    if (showNotification === true) {
      setTimeout(() => {
        setShowNotification(false);
        setIsFabricOnceClicked(false);
      }, 3000);
    }
  }, [isSelected]);
  
  */
  }

  const addClass =
    index % 7 === 0 ? "align-left" : (index + 1) % 7 === 0 ? "align-right" : "";
  return isMobile ? (
    <div>
      <MDBCol size="2" className={`fabric-item`}>
        <MDBRow>
          <MDBCol
            className="mobile-fabric-item"
            // onClick={() => {
            //   let val = {
            //     id: option.option_id,
            //     value: preselection.sku,
            //     finish: preselection,
            //   };
            //   setCustomizationOption(val);
            //   setPreselection(option);
            //   if (isNailSize && isCustom) {
            //     setPlayerOption(
            //       `${option.name} \u00A0\u00A0  ${
            //         option.price > 0 && isLoggedIn ? `$(${option.price})` : ""
            //       }`
            //     );
            //   }
            //   // if (!isNailSize) {
            //   let localSelectedFinish = JSON.parse(
            //     JSON.stringify(selectedFinish)
            //   );
            //   localSelectedFinish[
            //     openedOption.title.toLowerCase().split(" ").join("_")
            //   ] = option;
            //   setSelectedFinish(localSelectedFinish);
            //   // }
            //   // hideModal();
            // }}
            onClick={() => {
              if (
                option.name ===
                selectedFinish?.[
                  openedOption.title.toLowerCase().split(" ").join("_")
                ]?.name
              ) {
                setPreselection(false);
                let localSelectedFinish = JSON.parse(
                  JSON.stringify(selectedFinish)
                );
                localSelectedFinish[
                  openedOption.title.toLowerCase().split(" ").join("_")
                ] = false;
                setTimeout(() => {
                  setSelectedFinish(localSelectedFinish);
                  if (isNailSize) {
                    setNailPrice(0);
                  }
                  if (isNailSize && isCustom) setPlayerOption(undefined);
                }, 0);
              } else {
                let val = {
                  id: !isNailSize ? optId : option.id,
                  value: !isNailSize ? option.sku : option.value_string,
                  finish: option,
                };
                setCustomizationOption(val);
                setPreselection(option);
                if (isNailSize) {
                  setNailPrice(option.price);
                }
                if (isNailSize && isCustom) {
                  setPlayerOption(
                    `${option.name} \u00A0\u00A0  ${
                      option.price > 0 && isLoggedIn ? `$(${option.price})` : ""
                    }`
                  );
                }
              }

              let localSelectedFinish = JSON.parse(
                JSON.stringify(selectedFinish)
              );
              localSelectedFinish[
                openedOption.title.toLowerCase().split(" ").join("_")
              ] = option;
              setSelectedFinish(localSelectedFinish);
            }}
          >
            <img
              src={imageUrl}
              alt={option.name}
              className={`img-fluid border ${
                currentOptionSelected
                  ? "border-default option-selected"
                  : "border"
              }`.trim()}
              style={{
                width: "5.6rem",
                height: "5.6rem",
                position: "relative",
              }}
            />
            {currentOptionSelected && (
              <Icon
                icon="checked"
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  height: "1.5rem",
                  width: "1.5rem",
                  borderRadius: "5px 0px 5px 0px",
                }}
              />
            )}
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </div>
  ) : (
    <>
      <Tooltip key={option.id} className={`finish-item-tooltip ${addClass}`}>
        <div className="tooltip-elements-container">
          <TooltipContent
            setVisibleContent={setOpenTooltip}
            visibleContent={openTooltip}
            hasTickArrow={true}
            positionY={"top"}
            positionX={"center"}
          >
            <div>
              <div className="image-container border-tooltip-img">
                <img src={imageUrl} alt={option.name} />
              </div>
              <div className="info-container">
                <p className="text-darken-gray f14">{option.name}</p>
                {themeConfiguration.magento_url !==
                  "https://fairfieldm2.joladev.com/" &&
                  typeof option.price === "number" &&
                  customer && (
                    <p className="text-darken-gray f14">
                      {option.price > 0 ? "+ $" + option.price : "(standard)"}
                    </p>
                  )}
              </div>
            </div>
          </TooltipContent>
          <TooltipTriggerElement setVisibleContent={setOpenTooltip}>
            <div>
              <MDBCol size="2" className={`finish-item`}>
                <MDBRow>
                  <MDBCol
                    size="12"
                    onClick={() => {
                      if (
                        option.name ===
                        selectedFinish?.[
                          openedOption.title.toLowerCase().split(" ").join("_")
                        ]?.name
                      ) {
                        setPreselection(false);
                        let localSelectedFinish = JSON.parse(
                          JSON.stringify(selectedFinish)
                        );
                        localSelectedFinish[
                          openedOption.title.toLowerCase().split(" ").join("_")
                        ] = false;
                        setTimeout(() => {
                          setSelectedFinish(localSelectedFinish);
                          if (isNailSize) {
                            setNailPrice(0);
                          }
                          if (isNailSize && isCustom)
                            setPlayerOption(undefined);
                        }, 0);
                      } else {
                        let val = {
                          id: !isNailSize ? optId : option.id,
                          value: !isNailSize ? option.sku : option.value_string,
                          finish: option,
                        };
                        setCustomizationOption(val);
                        setPreselection(option);
                        if (isNailSize) {
                          setNailPrice(option.price);
                        }
                        if (isNailSize && isCustom) {
                          setPlayerOption(
                            `${option.name} \u00A0\u00A0  ${
                              option.price > 0 && isLoggedIn
                                ? `$(${option.price})`
                                : ""
                            }`
                          );
                        }
                      }

                      let localSelectedFinish = JSON.parse(
                        JSON.stringify(selectedFinish)
                      );
                      localSelectedFinish[
                        openedOption.title.toLowerCase().split(" ").join("_")
                      ] = option;
                      setSelectedFinish(localSelectedFinish);
                    }}
                  >
                    <img
                      src={imageUrl}
                      alt={option.name}
                      className={`img-fluid border ${
                        currentOptionSelected
                          ? "border-default border option-selected"
                          : "border"
                      }`.trim()}
                      style={{
                        width: "5.6rem",
                        height: "5.6rem",
                      }}
                    />

                    {currentOptionSelected && (
                      <Icon
                        icon="checked"
                        style={{
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          height: "1.5rem",
                          width: "1.5rem",
                          borderRadius: "5px 0px 5px 0px",
                        }}
                      />
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </div>
          </TooltipTriggerElement>
        </div>
      </Tooltip>
    </>
  );
};

export default FinishItem;
