export const chunkArray = <A extends {}>(
  array: Array<A>,
  chunkLength: number
) => {
  return array.reduce((resultArray, _, i, a) => {
    if (i % chunkLength === 0) {
      return [...resultArray, a.slice(i, i + chunkLength)];
    } else {
      return resultArray;
    }
  }, [] as Array<Array<A>>);
};
