import React, { memo } from "react";
import { Link } from "react-router-dom";
import { MDBCol, MDBRow } from "mdbreact";
import Badge from "app/layout/Badge";
import TopMenu from "app/layout/header/desktop/headerBottom/topMenu";
import Logo from "app/layout/logo";
import { customerPaths } from "app/pages/customer/routes";
import Icon from "app/assets/icon/icon";
import DropdownMenu from "./dropdownMenu";
import Search from "../../right/search";

/**
 * @typedef {{ onCartClick: () => void, favoritesCount: import('app/layout/Badge/Badge').BadgeProps['countDisplay'], cartItemsCount: import('app/layout/Badge/Badge').BadgeProps['countDisplay'], children: React.ReactNode, isLoggedIn: () => boolean }} Props
 *
 * @param {Props} props
 */
const HeaderBottom = ({
  onCartClick,
  cartItemsCount,
  favoritesCount,
  children,
  subItems,
  setActiveTopMenu,
  activeTopMenu,
  isLoggedIn,
  collectionsMenu,
  fabricsFinishesMenu,
  productsMenu,
  customOptionsMenu,
  setActiveSubMenu,
  newsInfo,
  clearUnreadNews,
  activeMenu,
}) => {
  return (
    <>
      <MDBRow className="bg-gray-darken-3 py-0 px-5 position-relative z-index-5">
        {/* <MDBCol
          md={`2`}
          className="ml-0 d-none d-xl-block ml-xl-5 logo-wrapper"
          onClick={() => {
            setActiveTopMenu(false);
          }}
        >
        </MDBCol> */}
        <MDBCol className="ml-auto d-flex justify-content-center">
          {/* @ts-ignore */}
          <TopMenu
            isLoggedIn={isLoggedIn}
            items={children}
            setActiveTopMenu={setActiveTopMenu}
            setActiveSubMenu={setActiveSubMenu}
            activeTopMenu={activeTopMenu}
            customOptionsMenu={customOptionsMenu}
            subItems={subItems}
            activeMenu={activeMenu}
          />
        </MDBCol>
        <MDBCol
          md={`auto`}
          className=" d-flex justify-content-end mr-0 mr-xl-5 icons-wrapper"
        >
          <MDBRow className="d-flex py-4 justify-content-center">
            {isLoggedIn && (
              <>
                <MDBCol className="d-flex align-items-center">
                  <Badge countDisplay={favoritesCount}>
                    <Link to={`/customer/collections/lists`}>
                      <Icon icon="heart" />
                    </Link>
                  </Badge>
                </MDBCol>
                <MDBCol className="d-flex align-items-center">
                  <Badge countDisplay={cartItemsCount}>
                    <Icon
                      icon="quote"
                      className="cursor-pointer"
                      onClick={onCartClick}
                    />
                  </Badge>
                </MDBCol>
                <MDBCol className="d-flex align-items-center notification">
                  <Badge
                    className={newsInfo?.unread_news_count && "news-alert"}
                    countDisplay={newsInfo?.unread_news_count ?? 0}
                  >
                    <Link onClick={clearUnreadNews} to={`/announcements`}>
                      <Icon
                        icon="notification-icon"
                        className="cursor-pointer"
                      />
                    </Link>
                  </Badge>
                </MDBCol>
              </>
            )}
            <MDBCol className="d-flex align-items-center">
              <Search />
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
      <DropdownMenu
        collectionsMenu={collectionsMenu}
        productsMenu={productsMenu}
        fabricsFinishesMenu={fabricsFinishesMenu}
        customOptionsMenu={customOptionsMenu}
        subItems={subItems}
        setActiveTopMenu={setActiveTopMenu}
        setActiveSubMenu={setActiveSubMenu}
      />
    </>
  );
};

export default memo(HeaderBottom);
