import gql from "graphql-tag";

//query for finish subcategories
export const FINISHES_CATEGORIES = gql`
  query FinishesCategories($id: String!) {
    categoryList(filters: { ids: { eq: $id } }) {
      id
      name
      url_key
      include_in_menu
      url_path
      children {
        id
        name
        product_count
        jola_product_count
        url_key
        url_path
        include_in_menu
      }
    }
  }
`;

//query for fabric products
export const FINISHES = gql`
  query Finishes($filters: ProductAttributeFilterInput!) {
    products(filter: $filters, pageSize: 30, currentPage: 1) {
      items {
        id
        name
        sku
        url_key
        image {
          url(width: 700, height: 700)
          label
        }
        thumbnail {
          url(width: 150, height: 150)
          label
        }
        small_image {
          url(width: 265, height: 265)
          label
        }
      }
    }
  }
`;
