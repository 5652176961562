export default (state, action) => {
  let currentFilters = { ...state.items };
  let removeFilter = action.filter;
  let newFilters = {};

  if (typeof removeFilter === "object") {
    let filter_code = removeFilter.filter_code;
    let filter_value = removeFilter.filter_value;
    let filterValues = currentFilters[filter_code].filter_value.reduce(
      (accumulator, current) => {
        if (current !== filter_value) {
          accumulator.push(current);

          return accumulator;
        }
        return accumulator;
      },
      []
    );
    newFilters[filter_code] = currentFilters[filter_code].filter_value = [
      ...filterValues,
    ];
  } else {
    let newFilters = {};
    Object.keys(currentFilters).reduce((accumulator, current) => {
      if (current !== removeFilter) {
        accumulator.push(currentFilters[current]);
        newFilters = { ...newFilters, [current]: currentFilters[current] };
        return accumulator;
      }
      return accumulator;
    }, []);
    return Object.assign({}, state, {
      items: { ...newFilters },
    });
  }

  return Object.assign({}, state, {
    items: { ...state.items, ...currentFilters },
  });
};
