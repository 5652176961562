import React from "react";
import { MDBTypography } from "mdbreact";
import Select from "app/layout/Select";

const PerPage = ({ pageSize, totalCount, pageSizeOptions, setPageSize }) => {
  let pageOptionsLessItems = [];
  if (totalCount / 96 >= 1) {
    pageOptionsLessItems = pageSizeOptions;
  } else if (totalCount / 48 >= 1) {
    pageOptionsLessItems = pageSizeOptions;
  } else if (totalCount / 24 >= 1) {
    pageOptionsLessItems = pageSizeOptions?.filter(function (option) {
      return option.value !== 96 && option.value !== 48;
    });
  } else if (totalCount / 12 >= 1) {
    pageOptionsLessItems = pageSizeOptions?.filter(function (option) {
      return option.value !== 96 && option.value !== 48 && option.value !== 24;
    });
  }

  return (
    <>
      <MDBTypography
        tag="span"
        className="body-6 mr-3 d-none d-lg-block text-gray-darken-3"
      >
        View:
      </MDBTypography>
      <div className="w-100 counter-select">
        <Select
          value={pageSize}
          options={pageOptionsLessItems}
          onChange={setPageSize}
          isSearchable={false}
          placeholder={pageSize}
        />
      </div>
    </>
  );
};

export default PerPage;
