import {
  default as GlobalAppPages,
  DefaultPage as AppDefaultPage,
  ErrorPage as AppErrorPage,
  TemporaryPage as AppTemporaryPage
} from "app/config/routing/pages/globalPages";

export default {
  ...GlobalAppPages
};

export const ErrorPage = AppErrorPage;

export const DefaultPage = AppDefaultPage;

export const TemporaryPage = AppTemporaryPage;
