import React from "react";
import { ErrorPage } from "core/config/routing/pages/globalPages";
// import ReactLoader from "../utils/loader";
import { default as PageTypes } from "core/config/routing/pageTypes";
import { isUndefined } from "core/helpers/functions";

const DynamicRouter = (props) => {

  if (!props.data || !props.data.urlResolver) {
    if (!props.isCached) return <div>{/* <ReactLoader /> */}</div>;
  }

  if (!isUndefined(props.data) && !isUndefined(props.data.urlResolver)) {
    const urlResolver = props.data.urlResolver;
    if (urlResolver !== null && PageTypes.hasOwnProperty(urlResolver.type)) {
      const Page = PageTypes[urlResolver.type];
      return (
        <Page
          {...props}
          id={urlResolver.id}
          url={props.variables.url}
          variables={{ id: urlResolver.id, url: props.variables.url }}
        />
      );
    }
  }
  if (isUndefined(props.data)) {
    return <>Loading...</>;
  }

  return <ErrorPage />;
};

export default DynamicRouter;
