import withServiceWorkerUpdater from "../hocServiceWorker";
import React from "react";

const NewVersion = ({
  newServiceWorkerDetected,
  onLoadNewServiceWorkerAccept,
}) => {
  return (
    <>
      {newServiceWorkerDetected ? (
        <div id={`new-version-popup`}>
          <p>A new version is ready.</p>
          <button
            className={`btn btn-default`}
            onClick={onLoadNewServiceWorkerAccept}
          >
            REFRESH!
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default withServiceWorkerUpdater(NewVersion);
