// Add a new query
export default (state, action) => {
  let currentQueries = state.urlResolver ? state.urlResolver : {};
  let newQueries = { ...currentQueries };

  if (action.identifier === action.data.urlResolver.relative_url) {
    newQueries[action.identifier] = {
      ...action.data.urlResolver,
      urlResolver: {
        id: action.data.urlResolver.id,
        relative_url: action.data.urlResolver.relative_url,
        type: action.data.urlResolver.type,
        sku: action.data.urlResolver.sku,
      },
      key: action.key,
    };
  }

  return Object.assign({}, state, { urlResolver: newQueries });
};
