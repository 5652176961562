import { loader } from "graphql.macro";

// perhaps this needs to be deprecated?
export default (query) => {
  if (query === "customerOrders") {
    return loader("./queries/sales/customerOrders.graphql");
  }

  if (query === "category") {
    return loader("./queries/category.graphql");
  }

  if (query === "categoryProducts") {
    return loader("./queries/categoryProducts.graphql");
  }

  if (query === "products") {
    return loader("./queries/products.graphql");
  }

  if (query === "product") {
    return loader("./queries/product.graphql");
  }

  if (query === "categoryTree") {
    return loader("./queries/categoryTree.graphql");
  }

  if (query === "cmsPage") {
    return loader("./queries/cmsPage.graphql");
  }

  if (query === "getProductInfoBySku") {
    return loader("./queries/getProductInfoBySku.graphql");
  }

  if (query === "Hotspots") {
    return loader("./queries/hotspots.graphql");
  }

  if (query === "cmsBlock") {
    return loader("./queries/cmsBlock.graphql");
  }

  // if (query === "urlResolver") {
  //   return loader("./queries/urlResolver.graphql");
  // }

  if (query === "userLogin") {
    return loader("./mutations/customer/session/loginMutation.graphql");
  }

  if (query === "userLogout") {
    return loader("./mutations/customer/session/logoutMutation.graphql");
  }

  if (query === "getCustomer") {
    return loader("../../app/graphql/queries/customer/customer.graphql");
  }

  if (query === "validateCustomerToken") {
    return loader("./queries/customer/validateCustomerToken.graphql");
  }

  if (query === "checkCustomerAuthorization") {
    return loader("./queries/customerCheckToken.graphql");
  }

  // if (query === "getCountries") {
  //   return loader("./queries/countries.graphql");
  // }
  // if (query === "searchProducts") {
  //   return loader("./queries/searchProducts.graphql");
  // }

  if (query === "registerCustomer") {
    return loader("./mutations/customer/createCustomerMutation.graphql");
  }

  if (query === "createCustomerAddress") {
    return loader(
      "./mutations/customer/address/createCustomerAddressMutation.graphql"
    );
  }

  if (query === "updateCustomerInformation") {
    return loader(
      "./mutations/customer/information/updateCustomerInformation.graphql"
    );
  }

  if (query === "newsletterCustomerSubscribe") {
    return loader("./mutations/customer/subscribe/newsletterSubscribe.graphql");
  }
  if (query === "editCustomerAddress") {
    return loader("./mutations/customer/address/editCustomerAddress.graphql");
  }

  if (query === "changeCustomerPassword") {
    return loader(
      "./mutations/customer/information/changeCustomerPassword.graphql"
    );
  }

  if (query === "generateCache") {
    return loader("./mutations/generateCache.graphql");
  }

  // if (query === "customerExportList") {
  //   return loader("./queries/customerExportList.graphql");
  // }

  // if (query === "createExportList") {
  //   return loader("./mutations/exportList/createExportList.graphql");
  // }

  // if (query === "createFreightRequest") {
  //   return loader(
  //     "./mutations/exportList/createQuoteRequest.graphql"
  //   );
  // }

  // if (query === "changeExportListName") {
  //   return loader(
  //     "./mutations/exportList/information/changeExportListName.graphql"
  //   );
  // }

  // if (query === "deleteExportList") {
  //   return loader("./mutations/exportList/deleteExportList.graphql");
  // }

  // if (query === "addSkuToList")import queryLoader from "./queryLoader";

  // }

  // if (query === "removeSkuFromList") {
  //   return loader(
  //     "./mutations/exportList/items/removeSkuFromList.graphql"
  //   );
  // }

  if (query === "generateResetPasswordToken") {
    return loader("./mutations/customer/generateResetPasswordToken.graphql");
  }

  // if (query === "exportListQuery") {
  //   return loader("./queries/exportListQuery.graphql");
  // }

  if (query === "resetCustomerPassword") {
    return loader("./mutations/customer/information/resetPassword.graphql");
  }

  // if (query === "removeAllItemsFromList") {
  //   return loader(
  //     "./mutations/exportList/removeAllItemsFromList.graphql"
  //   );
  // }

  if (query === "sendEmail") {
    return loader("./mutations/sendEmail.graphql");
  }

  // if (query === "storeConfig") {
  //   return loader("./queries/store/storeConfig.graphql");
  // }

  // if (query === "getDynamicContent") {
  //   return loader(
  //     "./queries/content/getDynamicContentByContentType.graphql"
  //   );
  // }

  // if (query === "getFilters") {
  //   return loader("./queries/getFilters.graphql");
  // }

  if (query === "createEmptyCart") {
    return loader("./mutations/customer/cart/customerCreateEmptyCart.graphql");
  }

  if (query === "cartData") {
    return loader("./queries/customer/cart/cart.graphql");
  }

  if (query === "customerCart") {
    return loader("./queries/customer/cart/customerCart.graphql");
  }

  if (query === "addProduct") {
    return loader("./mutations/customer/cart/addProductToCart.graphql");
  }

  if (query === "removeProduct") {
    return loader("./mutations/customer/cart/removeItemsFromCart.graphql");
  }

  if (query === "updateCart") {
    return loader("./mutations/customer/cart/updateCartItems.graphql");
  }

  if (query === "mergeCarts") {
    return loader("./mutations/customer/cart/mergeCarts.graphql");
  }

  if (query === "placeOrder") {
    return loader("./mutations/customer/cart/placeOrder.graphql");
  }

  if (query === "setPaymentMethod") {
    return loader("./mutations/customer/cart/setPaymentMethodOnCart.graphql");
  }
  if (query === "addToCompare") {
    return loader("./mutations/compare/addToCompare.graphql");
  }

  if (query === "removeCompare") {
    return loader("./mutations/compare/removeCompare.graphql");
  }

  // WISHLIST
  if (query === "getWishlists") {
    return loader("./queries/wishlists/getWishlists.graphql");
  }
  if (query === "addAllToCart") {
    return loader("./mutations/wishlists/addAllToCart.graphql");
  }
  if (query === "addItemsToWishlist") {
    return loader("./mutations/wishlists/addItemsToWishlist.graphql");
  }
  if (query === "addItemToCart") {
    return loader("./mutations/wishlists/addItemToCart.graphql");
  }
  if (query === "addNewWishlist") {
    return loader("./mutations/wishlists/addNewWishlist.graphql");
  }
  if (query === "copyItemToWishlist") {
    return loader("./mutations/wishlists/copyItemToWishlist.graphql");
  }
  if (query === "deleteItemsFromWishlist") {
    return loader("./mutations/wishlists/deleteItemsFromWishlist.graphql");
  }
  if (query === "deleteItemsFromWishlistQty") {
    return loader("./mutations/wishlists/deleteItemsFromWishlistQty.graphql");
  }
  if (query === "deleteWishlist") {
    return loader("./mutations/wishlists/deleteWishlist.graphql");
  }
  if (query === "editWishlist") {
    return loader("./mutations/wishlists/editWishlist.graphql");
  }
  if (query === "moveItemToWishlist") {
    return loader("./mutations/wishlists/moveItemToWishlist.graphql");
  }
  if (query === "updateWishlistItem") {
    return loader("./mutations/wishlists/updateWishlistItem.graphql");
  }
  if (query === "moveItemToWishlist") {
    return loader("./mutations/wishlists/moveItemToWishlist.graphql");
  }

  if (query === "checkEmail") {
    return loader("./mutations/wishlists/checkEmail.graphql");
  }

  throw new Error(
    "Unknown query, you probably forgot to define it in state/graphql/queryLoader.js."
  );
};
