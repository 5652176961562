import Icon from "app/assets/icon/icon";
import Breadcrumbs from "app/layout/breadcrumbs";
import CmsBlock from "app/layout/cmsBlock";
import themeConfiguration from "config/themeConfiguration";
import {
  MDBBox,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBMask,
  MDBRow,
  MDBTypography,
  MDBView,
} from "mdbreact";
import React from "react";

const Careers = (props) => {
  let { tabProps } = props;
  return (
    <MDBContainer fluid className="careers-page-wrapper">
      <MDBRow>
        <MDBCol size="12" className="p-0 careers-image-and-text-wrapper">
          <MDBView>
            <CmsBlock id={"careers_main_image"} />
            <MDBMask></MDBMask>
          </MDBView>
          <h1>
            Be Part of <br /> Our Team
          </h1>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12" className="p-0">
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12" className="mt-5">
                <Breadcrumbs />
              </MDBCol>
              {/* ODAVDE IDE BLOCK */}
              <MDBContainer className="mb-5">
                <MDBRow>
                  <MDBCol>
                    <MDBTypography tag="h1">Careers</MDBTypography>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol className="mt-5" size="12">
                    <MDBTypography
                      tag="h2"
                      variant="display-3"
                      className="font-weight-light"
                    >
                      Click below to view our available opportunities at
                      Fairfield{" "}
                    </MDBTypography>
                  </MDBCol>
                  <MDBCol>
                    <MDBBtn
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          "https://recruiting.ultipro.com/FAI1004FIFC/JobBoard/189f1bf7-f29f-430e-93af-537c0531a0cfwhich",
                          "_blank"
                        );
                      }}
                      className={"view-opportunities-button"}
                    >
                      View Opportunities
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol size="12">
                    <MDBContainer fluid className="p-0">
                      <MDBRow>
                        {/* <MDBCol lg="7" size="12">
                          <MDBBox className="my-5">
                            {tabProps.tabs.map((item, index) => (
                              <MDBBox
                                onClick={(e) => {
                                  tabProps.setCurrentTab(index);
                                }}
                                tag="div"
                                key={`${item}-${index}`}
                                className={`tab horisontal-tab mr-5 ${
                                  tabProps.currentTab === index
                                    ? "active"
                                    : "not-active"
                                }`}
                              >
                                {item.name}
                              </MDBBox>
                            ))}
                          </MDBBox>
                          <MDBRow>
                            {tabProps.tabs.map((item, index) => (
                              <MDBCol key={item.items} size="12">
                                {tabProps.currentTab === index && (
                                  <> {item.items}</>
                                )}
                              </MDBCol>
                            ))}
                          </MDBRow>
                        </MDBCol> */}
                        {/* <CmsBlock id="careers_apply_and_downloadpdf" /> */}
                        {/* <MDBTypography tag="h2">
                          Check our work opportunists
                        </MDBTypography> */}
                        <MDBCol lg="6" size="12" className="my-5">
                          <MDBBox className=" d-flex justify-content-left align-items-center">
                            <MDBBox className="">
                              <MDBBox>
                                <MDBTypography
                                  tag="h2"
                                  variant="display-2"
                                  className="font-weight-light"
                                >
                                  Address
                                </MDBTypography>
                              </MDBBox>
                              <MDBBox className="text-left">
                                <MDBBox className="d-flex  align-items-baseline my-4">
                                  <Icon icon="address" />
                                  <MDBTypography
                                    tag="p"
                                    className="body-1 ml-4"
                                  >
                                    1331 Harper Ave SW <br />
                                    Lenoir North Carolina 28645
                                  </MDBTypography>
                                </MDBBox>
                                <MDBBox className="d-flex align-items-baseline my-4">
                                  <Icon icon="phone" />
                                  <a
                                    href="phone: 828.758.5571"
                                    className="body-1 ml-4"
                                  >
                                    {" "}
                                    828.758.5571
                                  </a>
                                </MDBBox>
                                <MDBBox className="d-flex align-items-baseline my-4">
                                  <Icon icon="fax" />

                                  <a
                                    href="fax: 828.758.5571"
                                    className="body-1 ml-4"
                                  >
                                    828.758.5571
                                  </a>
                                </MDBBox>
                                <MDBBox className="d-flex align-items-baseline my-4">
                                  <Icon icon="email" />
                                  <a
                                    href={
                                      "mailto:fairfieldhr@fairfield.joladev.com"
                                    }
                                    className="link-2 body-1 ml-4"
                                  >
                                    fairfieldhr@fairfield.joladev.com
                                  </a>
                                </MDBBox>
                              </MDBBox>
                            </MDBBox>
                          </MDBBox>

                          {/* <MDBBox className="bg-gray-lighten-4 d-flex text-center justify-content-center align-items-center p-5 my-5">
                            <MDBBox>
                              <MDBBox className="my-4">
                                <MDBTypography
                                  tag="h2"
                                  variant="display-2"
                                  className="font-weight-light"
                                >
                                  Download Application
                                </MDBTypography>
                              </MDBBox>
                              <MDBBox className="mb-4">
                                <a
                                  className="btn-default btn button"
                                  href={`
                                  ${
                                    themeConfiguration.magento_url.substring(
                                      0,
                                      themeConfiguration.magento_url.length - 1
                                    ) +
                                    themeConfiguration.media_url +
                                    "/wysiwyg/job_application.pdf"
                                  }
                                `}
                                  download="newfilename"
                                  target={`_blank`}
                                >
                                  <Icon className="mr-4" icon="downloadWhite" />
                                  Download PDF
                                </a>
                              </MDBBox>
                            </MDBBox>
                          </MDBBox> */}
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                  <MDBCol>
                    <CmsBlock id="careers_us_eq" />
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
              {/* KRAJ OD BLOCK */}
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};
export default Careers;
