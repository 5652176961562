const getGradeFromUpholsteryOptions = (upholsteryOption) => {
  if (!upholsteryOption) {
    return "";
  } else {
    return upholsteryOption.title.replace("grade", "");
  }
};

const getPriceFromUpholsteryForCustomOption = (
  upholsteryOptions,
  fabricGrade
) => {
  let currentFabric = JSON.parse(window.localStorage.getItem("ff_pdp_options"));
  if (currentFabric) {
    fabricGrade = currentFabric["main_fabric"]?.ffifabrics_grade;
  }
  const upholsteryOptionMatch = upholsteryOptions?.find((option) => {
    const grade = getGradeFromUpholsteryOptions(option);
    if (grade !== "" && fabricGrade) {
      return (
        getGradeFromUpholsteryOptions(option)?.toLowerCase() ===
        fabricGrade[0].toLowerCase()
      );
    } else {
      return 0;
    }
  });

  if (upholsteryOptionMatch) {
    return upholsteryOptionMatch.price;
  } else {
    return 0;
  }
};

export const calculateUpholsteryOptionsPrice = (upholsteryOptions, fabrics) => {
  return fabrics.reduce((accumulator, currentCustomizationOption) => {
    let fabricGrade;
    if (
      currentCustomizationOption?.hasOwnProperty("fabric") &&
      currentCustomizationOption?.fabric?.grade?.[0] !== "false"
    ) {
      fabricGrade = currentCustomizationOption?.fabric?.grade?.[0].toLowerCase();
    } else {
      fabricGrade = currentCustomizationOption?.grade?.[0].toLowerCase();
    }

    const currentCustomizationOptionPriceFromUpholstery = getPriceFromUpholsteryForCustomOption(
      upholsteryOptions,
      fabricGrade
    );

    if (currentCustomizationOptionPriceFromUpholstery > accumulator) {
      return currentCustomizationOptionPriceFromUpholstery;
    } else {
      return accumulator;
    }
  }, 0);
};

export const calculateUpholsteryOptionSelectorPrice = (
  upholsteryOptions,
  fabric
) => {
  let fabricGrade;
  if (fabric?.hasOwnProperty("fabric")) {
    fabricGrade = fabric?.fabric?.grade?.[0].toLowerCase();
  } else {
    fabricGrade = fabric?.grade?.[0].toLowerCase();
  }

  const currentCustomizationOptionPriceFromUpholstery = getPriceFromUpholsteryForCustomOption(
    upholsteryOptions,
    fabricGrade
  );

  if (currentCustomizationOptionPriceFromUpholstery > 0) {
    return (
      "+$" + currentCustomizationOptionPriceFromUpholstery.toFixed(2).toString()
    );
  } else {
    return "(standard)";
  }
};

export const getGradeFromAllOptions = (allOptions, currentSelections) => {
  const upholsteryOption = allOptions.find((o) => {
    return o.title === "Upholstery Options";
  });
  const options = upholsteryOption.value;

  let currentOptions = options.filter((o) => {
    let isIn = false;
    currentSelections.forEach((cS) => {
      if (
        o.title.toLowerCase() ===
        "grade" + cS?.fabric?.grade[0].toLowerCase()
      ) {
        isIn = true;
      }
    });
    return isIn;
  });

  let sortedOptions = currentOptions.sort((a, b) => {
    return b.price - a.price;
  });

  return {
    id: upholsteryOption.option_id,
    value: sortedOptions[0].option_type_id.toString(),
  };
};

export const calculateViewMorePdpPrice = (viewMoreOptions) => {
  if (viewMoreOptions?.length >= 0) {
    let totalPrice = 0;
    viewMoreOptions.forEach((option) => {
      if (option.hasOwnProperty("checked")) {
        if (option["checked"] === true) {
          totalPrice += option.value[0].price;
        }
      }
    });
    return totalPrice;
  } else {
    return 0;
  }
};
