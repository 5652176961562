import { trackColor } from "app/pages/catalog/category/utils/price";
import { getOnlyPrice } from "core/components/price";
import { MDBTypography } from "mdbreact";
import React, { useCallback } from "react";

export interface PriceSliderProps {
  handleDragStartFrom: React.MouseEventHandler;
  handleDragStartTo: React.MouseEventHandler;
  minPrice: number;
  maxPrice: number;
  fromPercentage: number;
  toPercentage: number;
  fromPrice: number;
  toPrice: number;
  className?: string;
  sizeSuffix?: string;
  placeholder?: string;
  isUniversal?: boolean;
}

const PriceSlider: React.ForwardRefRenderFunction<
  HTMLDivElement,
  PriceSliderProps
> = (props, trackRef) => {
  const fromPrice = !isNaN(props.fromPrice) ? props?.fromPrice : props.minPrice;
  const toPrice = !isNaN(props.toPrice) ? props.toPrice : props.maxPrice;

  return (
    <div className={props.className}>
      <MDBTypography className="mb-0 d-flex justify-content-between fs-14 w-100">
        <span>
          {props?.sizeSuffix || props.sizeSuffix === ""
            ? !props.isUniversal
              ? (props?.minPrice || "0") + " " + props?.sizeSuffix
              : props?.minPrice || "0"
            : getOnlyPrice({ price: props.minPrice })}
        </span>
        <span>
          {props?.sizeSuffix || props.sizeSuffix === ""
            ? !props.isUniversal
              ? props?.maxPrice + " " + props?.sizeSuffix
              : props?.maxPrice
            : getOnlyPrice({ price: props.maxPrice })}
        </span>
      </MDBTypography>
      <div
        ref={trackRef}
        className="mt-3 position-relative d-flex align-items-center height-5 border-gray-1 border-radius-05"
        style={{
          background: `linear-gradient(90deg, #fff ${props.fromPercentage}%, ${trackColor} ${props.fromPercentage}%, ${trackColor} ${props.toPercentage}%, #fff ${props.toPercentage}%)`,
        }}
      >
        <div
          className="cursor-grabbing position-absolute ml-n7 size-14 border-radius-50 shadow-2 bg-default"
          style={{ left: `${props.fromPercentage}%` }}
          onMouseDown={props.handleDragStartFrom}
          // @ts-ignore
          onTouchMove={props.handleDragStartFrom}
          tabIndex={0}
          role="button"
          id="test"
        />
        <div
          className="cursor-grabbing position-absolute ml-n7 size-14 border-radius-50 shadow-2 bg-default"
          style={{ left: `${props.toPercentage}%` }}
          onMouseDown={props.handleDragStartTo}
          // @ts-ignore
          onTouchMove={props.handleDragStartTo}
          tabIndex={0}
          role="button"
        />
      </div>
      <div className="mt-3 d-flex justify-content-center align-items-center">
        <MDBTypography className="mb-0 mr-2 text-default fs-14">
          {props?.sizeSuffix || props.sizeSuffix === ""
            ? (fromPrice || "0") + " " + props?.sizeSuffix
            : getOnlyPrice({
                price: fromPrice,
              })}
          &nbsp;-&nbsp;
          {props?.sizeSuffix || props.sizeSuffix === ""
            ? props.isUniversal
              ? props?.toPrice
              : props?.toPrice + " " + props?.sizeSuffix
            : getOnlyPrice({
                price: toPrice,
              })}
        </MDBTypography>
      </div>
    </div>
  );
};

export default React.forwardRef(PriceSlider);
