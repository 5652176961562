import { customerPaths } from "app/pages/customer/routes";
import { MDBCol } from "mdbreact";
import React from "react";
import { Route, Switch } from "react-router-dom";
import Title from "../../components/Title";
import DataContainer from "./DataContainer";
import Form from "./Form";

export interface RouteContainerProps {}

const RouteContainer: React.FC<RouteContainerProps> = () => {
  return (
    <MDBCol>
      <Title>Address Book</Title>
      <Switch>
        <Route
          path={`${customerPaths.children.account.children.addresses.path}/:category`}
          exact
          component={Form}
        />
        <Route component={DataContainer} />
      </Switch>
    </MDBCol>
  );
};

export default RouteContainer;
