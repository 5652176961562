// TODO add back // @ts-heck

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { RepLocatorDisplay } from "./repLocator";

const REP_LISTING_QUERY = gql`
  query {
    getReplocators {
      # address
      email
      fax
      lanes {
        contract
        retail
        senior
      }
      # mobile
      name
      phone
      zips
    }
  }
`;

const RepLocator = () => {
  let industryOptions = [
    { label: "Residential", value: "retail" },
    { label: "Senior Living", value: "senior" },
    { label: "Commercial", value: "contract" },
  ];

  let [zipCode, setZipCode] = useState("");
  let [industry, setIndustry] = useState({ value: "" });
  let [reps, setReps] = useState([]);
  let [tempReps, setTempReps] = useState([]);
  let [isResult, setIsResult] = useState(false);

  const { loading, error, data } = useQuery(REP_LISTING_QUERY);

  useEffect(() => {
    if (data !== null && data !== undefined && !error && !loading) {
      setReps(data.getReplocators);
    }
  }, [data, loading, error]);

  let industryMap = {
    retail: 0,
    senior: 1,
    contract: 2,
  };

  let searchHandler = () => {
    setTempReps(
      reps.filter((rep) => {
        return rep.zips.some(
          (zip) => zip === `${zipCode}:${industryMap[industry.value]}`
        );
      })
    );
    setIsResult(true);
  };

  let displayProps = {
    zipCode,
    setZipCode,
    industry,
    setIndustry,
    industryOptions,
    searchHandler,
    tempReps,
    isResult,
    canSearch: industry.value !== "" && zipCode !== "",
  };
  return <RepLocatorDisplay {...displayProps} />;
};

export default RepLocator;
