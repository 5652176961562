import { applyMiddleware, compose } from "redux";
import { default as customerMiddleware } from "./data/customer/middleware";
import { default as commonMiddleware } from "./data/common/middleware";
import { default as queriesMiddleware } from "./data/queries/middleware";
import { default as filterMiddleware } from "./data/filters/middleware";
import { default as cacheMiddleware } from "./data/cache/middleware";

// project specific middleware
import projectMiddleware, {
  overwritteMapper,
} from "app/state/redux/middleware";

// core middleware array
let middlewares = [
  customerMiddleware,
  commonMiddleware,
  queriesMiddleware,
  filterMiddleware,
  cacheMiddleware,
];

// remapping or adding new middleware to list of middlewares
const remapMiddlewareIfApplicable = () => {
  Object.keys(overwritteMapper).forEach((middleware) => {
    let newMiddleware = overwritteMapper[middleware];
    if (typeof middlewares[middleware] !== "undefined") {
      middlewares[middleware] = newMiddleware;
    } else {
      middlewares.push(newMiddleware);
    }
  });
};

remapMiddlewareIfApplicable();
const globalMiddleware = compose(
  applyMiddleware(...middlewares, ...projectMiddleware)
);

export default globalMiddleware;
