import { default as commonActionTypes } from "../../common/types";
import { pendingTask, end } from "react-redux-spinner";
const ROUTE_CHANGE_SUCCESS = commonActionTypes.ROUTE_CHANGE_SUCCESS;

export default (store, action) => {
  store.dispatch({
    type: ROUTE_CHANGE_SUCCESS,
    [pendingTask]: end
  });
};
