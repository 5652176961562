import React from "react";
import { MDBCol, MDBRow } from "mdbreact";
import Icon from "app/assets/icon/icon";

const FabricItemMobile = (props) => {
  let { option, handleItemSelect, isSelected, hasBagde } = props;
  return (
    <div>
      <MDBCol size="2" className={`fabric-item`}>
        <MDBRow>
          <MDBCol
            className={`mobile-fabric-item fabric-inner fabric-has-badge-${hasBagde} d-flex align-items-center justify-content-center`}
            onClick={() => {
              handleItemSelect(option);
            }}
          >
            <img
              src={option?.image?.url}
              alt={option?.image?.label}
              className={`img-fluid border-gray-1 ${
                option.id === isSelected
                  ? "border-default option-selected"
                  : "border-gray-1"
              }`.trim()}
              style={{
                width: "5rem",
                height: "5rem",
                position: "relative",
              }}
            />
            {option.id === isSelected && (
              <Icon
                icon="checked"
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  height: "1.5rem",
                  width: "1.5rem",
                  borderRadius: "5px 0px 5px 0px",
                }}
              />
            )}
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </div>
  );
};

export default FabricItemMobile;
