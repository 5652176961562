// @ts-check

import { ErrorPage } from "app/config/routing/pages/guestPages";
import { useAddItemToCart } from "app/helpers/cart/hooks";
import queryString from "query-string";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ProductQueryContainer from "./query-container";

/**
 *
 * @type {React.FC<*>}
 */
const ProductStateContainer = (props) => {
  let { productSku } = props;
  /**
   * @typedef {{ id: any; value_string: string;}} CustomizationOption
   */
  /**
   * @type {CustomizationOption[]}
   */
  const initialCustomizationOptions = [];
  /**
   * @type {[CustomizationOption[], React.Dispatch<React.SetStateAction<CustomizationOption[]>>]}
   */

  let [customizationOptions, setCustomizationOptions] = useState(
    initialCustomizationOptions
  );

  let [
    fabricOrFinishSelectedOptions,
    setFabricOrFinishSelectedOptions,
  ] = useState(initialCustomizationOptions);
  /**
   *
   * @param {any}option
   * @param {boolean}remove
   */
  let setCustomizationOption = React.useCallback(
    (option, remove = false) => {
      let newCustomizationOptions = [];
      let fabricOrFinishOptions = [];
      fabricOrFinishOptions.push({
        id: option.id,
        value_string: String(option.value),
        // @ts-ignore
        fabric: option.fabric,
        // @ts-ignore
        finish: option.finish,
      });
      if (option.contrastingIds) {
        //@ts-ignore
        option.contrastingIds.forEach((id) => {
          fabricOrFinishOptions.push({
            id: id,
            value_string: String(option.value),
            // @ts-ignore
            fabric: option.fabric,
            // @ts-ignore
            finish: option.finish,
          });
        });
      }

      if (!remove) {
        newCustomizationOptions.push({
          id: option.id,
          value_string: String(option.value),
        });
        if (option.contrastingIds) {
          //@ts-ignore
          option.contrastingIds.forEach((id) => {
            newCustomizationOptions.push({
              id,
              value_string: String(option.value),
            });
          });
        }
      }

      customizationOptions.forEach((customizationOption) => {
        if (
          (!option.contrastingIds && customizationOption.id !== option.id) ||
          (option.contrastingIds &&
            option.contrastingIds.indexOf(customizationOption.id) === -1 &&
            customizationOption.id !== option.id)
        ) {
          newCustomizationOptions.push(customizationOption);
        }
      });

      fabricOrFinishSelectedOptions.forEach((customizationOption) => {
        if (
          (!option.contrastingIds && customizationOption.id !== option.id) ||
          (option.contrastingIds &&
            option.contrastingIds.indexOf(customizationOption.id) === -1 &&
            customizationOption.id !== option.id)
        ) {
          fabricOrFinishOptions.push(customizationOption);
        }
      });

      setCustomizationOptions(newCustomizationOptions);
      // @ts-ignore
      setFabricOrFinishSelectedOptions(fabricOrFinishOptions);
    },
    [customizationOptions, fabricOrFinishSelectedOptions]
  );

  /**
   * @param {any} option
   */
  // @ts-ignore
  const removeCustomizationOption = React.useCallback((option) => {
    //TO DO
  }, []);

  const [addItemToCart, addItemToCartResult] = useAddItemToCart();

  /**
   *
   * @param {any=}cO
   */
  const addToCart = (cO) => {
    /**
     * @type {import('app/helpers/cart/hooks').CartItemInput}
     */
    let itemInput = {
      data: {
        sku: productSku,
        quantity: 1,
      },
      customizable_options: cO ? cO : customizationOptions,
    };

    addItemToCart(itemInput);
  };

  let customizationProps = React.useMemo(
    () => ({
      setCustomizationOption,
      setCustomizationOptions,
      customizationOptions,
      removeCustomizationOption,
    }),
    [customizationOptions, removeCustomizationOption, setCustomizationOption]
  );

  const { search } = useLocation();
  const values = React.useMemo(() => {
    if (search) {
      const localSearch = JSON.parse(
        JSON.stringify(search.replace(/\&amp;/g, "&").replace(/\&quot;/g, `"`))
      );
      return queryString.parse(localSearch);
    } else {
      return {};
    }
  }, [search]);

  if (!productSku) return <ErrorPage />;

  return (
    <ProductQueryContainer
      {...props}
      values={values}
      productSku={productSku}
      addToCart={addToCart}
      isAddingToCart={addItemToCartResult.loading}
      customizationProps={customizationProps}
      fabricOrFinishSelectedOptions={fabricOrFinishSelectedOptions}
      setFabricOrFinishSelectedOptions={setFabricOrFinishSelectedOptions}
      queryPathFabrics={values}
    />
  );
};

export default ProductStateContainer;
