import React, { useState } from "react";
import GalleryDisplay from "./gallery";
import { useGallery } from "./hooks/useGallery";

export const Gallery = () => {
  let [category, setCategoryFilter] = useState("All");
  const displayProps = {
    setCategoryFilter,
    category,
    ...useGallery(),
  };

  return <GalleryDisplay {...displayProps} />;
};
