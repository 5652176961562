import {
  calculateUpholsteryOptionsPrice,
  calculateViewMorePdpPrice,
} from "app/pages/catalog/product/options/functions";
import {
  useFavorites,
  useToggleFavoriteProductParam,
} from "app/pages/collections/hooks";
import { getIsFavorite } from "app/pages/collections/utils";
import { isEmpty } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  details as detailsMapping,
  dimensions as dimensionsMapping,
  specifications as specificationsMapping,
  finishesDetails as finishesMapping,
} from "./attrMapping";
import Product from "./product";
import useDeepCompareEffect from "use-deep-compare-effect";
import { CheckBoxContext } from "./checkboxContext";
import { CustomPriceContext } from "./customPriceContext";
import MagicSliderDots from "react-magic-slider-dots";
import { FABRICS_WITHOUT_AGGREGATIONS } from "./queriesFabric";
import { useQuery } from "@apollo/react-hooks";

const ProductDataContainer = ({
  values,
  player,
  addMessage,
  lock,
  unLock,
  data,
  customer,
  variables,
  noImage,
  isCustom,
  defaults,
  fabricOrFinishesSelectedOptions,
  customizationProps,
  addToCart,
  isAddingToCart,
  pickSectional,
  allowSectional,
  currentSectionals,
  fabricsSelections,
  frame,
  nailData,
  setFabricOrFinishSelectedOptions,
  selectionsAndSelectors,
  ffifinish_cleaning,
  about_fairshield,
  productImageUrl,
  queryPathFabrics,
  checkBoxOptionsCustom = {},
  setCheckBoxOptionsCustom,
  ...props
}) => {
  let controlProps = {
    addMessage,
    lock,
    unLock,
  };
  let customerData = {
    customer,
  };
  let productData = data?.products?.items?.[0] || {};
  productData.url = variables?.url || "";
  const categories = productData.categories;
  const favorites = useFavorites();
  const {
    checkBoxOptions,
    setCheckBoxOptions,
    viewMoreOptions,
    setViewMoreOptions,
    selectedFinish,
    setSelectedFinish,
    selectedContrastWelt,
    setSelectedContrastWelt,
    selectedContrastButtons,
    setSelectedContrastButtons,
    selectedDecorativeCord,
    selectedFringe,
    selectedThrowPillowContrastWelt,
    selectedThrowPillowDecorativeCord,
    selectedThrowPillowFringe,
    selectedKidneyPillowContrastWelt,
    selectedKidneyPillowDecorativeCord,
    selectedKidneyPillowFringe,
    preselection,
    setPreselection,
  } = useContext(CheckBoxContext);

  let [viewMorePdpPrice, setViewMorePdpPrice] = useState(0);

  const options = React.useMemo(() => productData.options || [], [
    productData.options,
  ]);

  const pillowOptions = React.useMemo(() => {
    return options?.filter((option) => {
      return (
        option?.category_options &&
        option?.title &&
        (!option?.category_options?.toLowerCase()?.includes("pillow") ||
          option?.title?.toLowerCase().search(/contrast (welt|buttons)/) ===
            -1 ||
          [
            "nail band",
            "decorative tape frame",
            "decorative cord",
            "throw pillow decorative cord",
            "kidney pillow decorative cord",
            "throw pillow fringe",
            "kidney pillow fringe",
            "base trim",
            "skirt trim",
            "fringe",
          ].includes(option.title))
      );
    });
  }, [options]);

  const skus = React.useMemo(() => {
    return Object.values(queryPathFabrics).slice(0, 10);
  }, [queryPathFabrics]);

  const foundFabrics = useQuery(FABRICS_WITHOUT_AGGREGATIONS, {
    variables: {
      filters: {
        sku: { in: skus },
      },
      pageSize: 100,
      currentPage: 1,
    },
  });

  const [price, setPrice] = useState(false);

  const [initialMount, setInitialMount] = useState(false);

  const initSelectedFinish = React.useMemo(() => {
    const keysAndSkus = Object.entries(queryPathFabrics);
    return keysAndSkus.length && foundFabrics.data?.products?.items.length
      ? keysAndSkus.reduce((items, [key, sku]) => {
          const item = foundFabrics.data.products.items.find(
            (item) => item.sku === sku
          );
          return item ? { ...items, [key]: item } : items;
        }, {})
      : {};
  }, [foundFabrics.data, queryPathFabrics]);

  const [dropdownOptions, setDropdownOptions] = useState({});

  const dropdownPrice = React.useMemo(() => {
    const foundOptions = options.reduce((opts, option) => {
      const key = option.title.toLowerCase().split(" ").join("_");
      return key in dropdownOptions ? opts.concat(option) : opts;
    }, []);

    let dP = 0;
    let priceResetFlag = false;
    if (dropdownOptions["preselectedPrice"] > 0) {
      dP += dropdownOptions["preselectedPrice"];
    }
    foundOptions.forEach((foundOption) => {
      if (
        dropdownOptions[foundOption?.title.toLowerCase().split(" ").join("_")]
      ) {
        foundOption.value.forEach((value) => {
          if (
            dropdownOptions[
              foundOption?.title.toLowerCase().split(" ").join("_")
            ] === value.option_type_id
          ) {
            dP += value.price;
            priceResetFlag = true;
          }
        });
      }
    });
    if (priceResetFlag) {
      dP -= dropdownOptions["preselectedPrice"];
    }

    return dP;
  }, [dropdownOptions, options]);

  const [trimPrice, setTrimPrice] = useState(0);

  const [pillowDropdownSelector, setPillowDropdownSelector] = useState({
    throw_pillow: {},
    kidney_pillow: {},
  });

  const mainFabric = React.useMemo(() => {
    const mainFabricOptionId = options.find(
      (option) => option.title === "Main Fabric"
    )?.id;
    // ? is it possible to find `customizationOption` without first searching for the `mainFabricOptionId`
    return customizationProps.customizationOptions.find(
      (option) => option.id === mainFabricOptionId
    );
  }, [customizationProps.customizationOptions, options]);

  const mainFabricValue = mainFabric?.value_string;
  const contrastWeltPrice = React.useMemo(() => {
    if (
      checkBoxOptions &&
      Object.keys(checkBoxOptions).length &&
      selectedContrastWelt?.sku !== mainFabricValue
    ) {
      const title = selectedContrastWelt?.grade[0].includes("leather")
        ? "leather contrast welt"
        : "fabric contrast welt";
      return options.find((option) => option?.title.toLowerCase() === title)
        .value[0].price;
    } else {
      return 0;
    }
  }, [checkBoxOptions, mainFabricValue, options, selectedContrastWelt]);
  const contrastButtonsPrice = React.useMemo(() => {
    if (
      checkBoxOptions &&
      Object.keys(checkBoxOptions).length &&
      selectedContrastButtons?.sku !== mainFabricValue
    ) {
      return options?.find(
        (option) => option?.title?.toLowerCase() === "contrasting buttons"
      ).value[0].price;
    } else {
      return 0;
    }
  }, [checkBoxOptions, mainFabricValue, options, selectedContrastButtons]);

  const decorativeCordPrice = React.useMemo(() => {
    if (
      checkBoxOptions &&
      Object.keys(checkBoxOptions).length &&
      selectedDecorativeCord?.sku !== mainFabricValue
    ) {
      return options.find(
        (option) => option.title.toLowerCase() === "decorative cord"
      ).value[0].price;
    } else {
      return 0;
    }
  }, [checkBoxOptions, mainFabricValue, options, selectedDecorativeCord]);

  const fringePrice = React.useMemo(() => {
    if (
      checkBoxOptions &&
      Object.keys(checkBoxOptions).length &&
      selectedFringe?.sku !== mainFabricValue
    ) {
      return options.find((option) => option.title.toLowerCase() === "fringe")
        .value[0].price;
    } else {
      return 0;
    }
  }, [checkBoxOptions, mainFabricValue, options, selectedFringe]);

  const throwPillowContrastWeltPrice = React.useMemo(() => {
    if (
      checkBoxOptions &&
      Object.keys(checkBoxOptions).length &&
      selectedThrowPillowContrastWelt?.sku !== mainFabricValue &&
      pillowDropdownSelector.throw_pillow["throw_pillow_contrast_welt"]
    ) {
      const title = selectedThrowPillowContrastWelt.grade[0].includes("leather")
        ? "throw pillow leather contrast welt upcharge"
        : "throw pillow fabric contrast welt upcharge";

      return options.find((option) => option.title.toLowerCase() === title)
        .value[0].price;
    } else {
      return 0;
    }
  }, [
    checkBoxOptions,
    mainFabricValue,
    options,
    pillowDropdownSelector.throw_pillow,
    selectedThrowPillowContrastWelt,
  ]);

  const throwPillowDecorativeCordPrice = React.useMemo(() => {
    if (
      checkBoxOptions &&
      Object.keys(checkBoxOptions).length &&
      selectedThrowPillowDecorativeCord?.sku !== mainFabricValue &&
      pillowDropdownSelector.throw_pillow["throw_pillow_decorative_cord"]
    ) {
      return options.find(
        (option) =>
          option.title.toLowerCase() === "throw pillow decorative cord upcharge"
      ).value[0].price;
    } else {
      return 0;
    }
  }, [
    checkBoxOptions,
    mainFabricValue,
    options,
    pillowDropdownSelector.throw_pillow,
    selectedThrowPillowDecorativeCord,
  ]);

  const throwPillowFringePrice = React.useMemo(() => {
    if (
      checkBoxOptions &&
      Object.keys(checkBoxOptions).length &&
      selectedThrowPillowFringe?.sku !== mainFabricValue &&
      pillowDropdownSelector.throw_pillow["throw_pillow_fringe"]
    ) {
      return options.find(
        (option) =>
          option.title.toLowerCase() === "throw pillow fringe upcharge"
      ).value[0].price;
    } else {
      return 0;
    }
  }, [
    checkBoxOptions,
    mainFabricValue,
    options,
    pillowDropdownSelector.throw_pillow,
    selectedThrowPillowFringe,
  ]);

  const kidneyPillowContrastWeltPrice = React.useMemo(() => {
    if (
      checkBoxOptions &&
      Object.keys(checkBoxOptions).length &&
      selectedKidneyPillowContrastWelt?.sku !== mainFabricValue &&
      pillowDropdownSelector.kidney_pillow["kidney_pillow_contrast_welt"]
    ) {
      const title = selectedKidneyPillowContrastWelt.grade[0].includes(
        "leather"
      )
        ? "kidney pillow leather contrast welt upcharge"
        : "kidney pillow fabric contrast welt upcharge";
      return options.find((option) => option.title.toLowerCase() === title)
        .value[0].price;
    } else {
      return 0;
    }
  }, [
    checkBoxOptions,
    mainFabricValue,
    options,
    pillowDropdownSelector.kidney_pillow,
    selectedKidneyPillowContrastWelt,
  ]);

  const kidneyPillowDecorativeCordPrice = React.useMemo(() => {
    if (
      checkBoxOptions &&
      Object.keys(checkBoxOptions).length &&
      selectedKidneyPillowDecorativeCord?.sku !== mainFabricValue &&
      pillowDropdownSelector.kidney_pillow["kidney_pillow_decorative_cord"]
    ) {
      return options.find(
        (option) =>
          option.title.toLowerCase() ===
          "kidney pillow decorative cord upcharge"
      ).value[0].price;
    } else {
      return 0;
    }
  }, [
    checkBoxOptions,
    mainFabricValue,
    options,
    pillowDropdownSelector.kidney_pillow,
    selectedKidneyPillowDecorativeCord,
  ]);

  const kidneyPillowFringePrice = React.useMemo(() => {
    if (
      checkBoxOptions &&
      Object.keys(checkBoxOptions).length &&
      selectedKidneyPillowFringe?.sku !== mainFabricValue &&
      pillowDropdownSelector.kidney_pillow["kidney_pillow_fringe"]
    ) {
      return options.find(
        (option) =>
          option.title.toLowerCase() === "kidney pillow fringe upcharge"
      ).value[0].price;
    } else {
      return 0;
    }
  }, [
    checkBoxOptions,
    mainFabricValue,
    options,
    pillowDropdownSelector.kidney_pillow,
    selectedKidneyPillowFringe,
  ]);

  const contrastingIds = React.useMemo(() => {
    return options.reduce(
      (ids, option) =>
        option.title &&
        [
          "contrasting welt",
          "contrasting buttons",
          "inside back",
          "outside back",
          "cushion",
          "tight seat",
          "contrast welt",
          "back pillow",
        ].includes(option.title.toLowerCase())
          ? ids.concat(option.option_id)
          : ids,
      []
    );
  }, [options]);
  const [viewMoreDropdownSelector, setViewMoreDropdownSelector] = useState({});

  const isFavorite = useMemo(
    () => getIsFavorite({ favorites: favorites ?? [], sku: productData.sku }),
    [favorites, productData.sku]
  );

  const toggleFavorite = useToggleFavoriteProductParam();
  const handleToggleFavorite = useCallback(() => toggleFavorite(productData), [
    toggleFavorite,
    productData,
  ]);

  const showMainFabric = React.useMemo(() => {
    return options.find((option) => option.title === "Upholstery Options");
  }, [options]);

  /**
   * Here lies HARDCODED logic.
   * First if block make the preselection array used in UpholsteryOption component.
   * Second if block makes the pillowOptions array which is used to determine if a fabric should be included in price calc or not.
   * Third if block makes the contrastingId array which is used to preselect fabrics when changing the main fabric.
   * Forth and fifth if blocks are used to make the initial checkBox and dropDown values from the queryPath if present, or they will just setting everything to null.
   */
  useEffect(() => {
    let localPreselection = JSON.parse(JSON.stringify(preselection));
    let initCheckBox = {};
    let initDropdown = {};
    options.forEach((option) => {
      if (
        (option.category_options &&
          option.category_options.toLowerCase().indexOf("upholstery") !== -1 &&
          !preselection[option.title.toLowerCase().split(" ").join("_")]) ||
        (option.title.toLowerCase() === "nail band" &&
          !preselection[option.title.toLowerCase().split(" ").join("_")])
      ) {
        localPreselection[option.title.toLowerCase().split(" ").join("_")] =
          option?.title?.toLowerCase() !== "base trim" &&
          option?.title?.toLowerCase() !== "decorative tape frame" &&
          option?.title?.toLowerCase() !== "skirt trim" &&
          option?.title?.toLowerCase() !== "nail band" &&
          option?.title?.toLowerCase() !== "throw pillow contrast welt" &&
          option?.title?.toLowerCase() !== "throw pillow decorative cord" &&
          option?.title?.toLowerCase() !== "throw pillow fringe" &&
          option?.title?.toLowerCase() !== "kidney pillow contrast welt" &&
          option?.title?.toLowerCase() !== "kidney pillow decorative cord" &&
          option?.title?.toLowerCase() !== "kidney pillow fringe"
            ? defaults.defaultFabric
            : null;
      }

      if (option?.__typename === "CustomizableCheckboxOption") {
        initCheckBox[
          option.title.toLowerCase().split(" ").join("_")
        ] = !!queryPathFabrics?.[
          option?.title?.toLowerCase().split(" ").join("_")
        ];
      }
      if (
        option?.__typename === "CustomizableDropDownOption" &&
        option?.title?.toLowerCase() !== "upholstery options"
      ) {
        if (
          queryPathFabrics?.[option?.title?.toLowerCase().split(" ").join("_")]
        ) {
          initDropdown[option.title.toLowerCase().split(" ").join("_")] =
            queryPathFabrics[option.title.toLowerCase().split(" ").join("_")];
        } else {
          initDropdown[option.title.toLowerCase().split(" ").join("_")] = null;
        }
      }
    });
    setTimeout(() => {
      setPreselection(localPreselection);
      setCheckBoxOptions(initCheckBox);
      setDropdownOptions(initDropdown);
    }, 0);
  }, [queryPathFabrics]);

  useEffect(() => {
    setTrimPrice(0);
  }, []);

  useDeepCompareEffect(() => {
    if (checkBoxOptionsCustom !== {}) {
      setCheckBoxOptions(checkBoxOptionsCustom);
    }
  }, [checkBoxOptionsCustom]);

  useEffect(() => {
    if (mainFabric) {
      setTimeout(() => {
        let localCheckBoxOptions = JSON.parse(JSON.stringify(checkBoxOptions));
        if (Object.keys(localCheckBoxOptions).length > 0) {
          if (selectedContrastWelt) {
            if (selectedContrastWelt.sku !== mainFabric.value_string) {
              if (selectedContrastWelt.grade[0].indexOf("leather") !== -1) {
                localCheckBoxOptions.leather_contrast_welt = true;
                localCheckBoxOptions.fabric_contrast_welt = false;
              } else {
                localCheckBoxOptions.leather_contrast_welt = false;
                localCheckBoxOptions.fabric_contrast_welt = true;
              }
            }
          } else {
            localCheckBoxOptions.leather_contrast_welt = false;
            localCheckBoxOptions.fabric_contrast_welt = false;
          }

          setCheckBoxOptions(localCheckBoxOptions);
        }
      }, 6);
    }
  }, [selectedContrastWelt, mainFabric]);

  useEffect(() => {
    if (mainFabric) {
      setTimeout(() => {
        let localCheckBoxOptions = JSON.parse(JSON.stringify(checkBoxOptions));
        if (Object.keys(localCheckBoxOptions).length > 0) {
          if (selectedThrowPillowContrastWelt) {
            if (
              selectedThrowPillowContrastWelt.sku !== mainFabric.value_string &&
              pillowDropdownSelector.throw_pillow["throw_pillow_contrast_welt"]
            ) {
              if (
                selectedThrowPillowContrastWelt.grade[0].indexOf("leather") !==
                -1
              ) {
                localCheckBoxOptions.throw_pillow_leather_contrast_welt_upcharge = true;
                localCheckBoxOptions.throw_pillow_fabric_contrast_welt_upcharge = false;
              } else {
                localCheckBoxOptions.throw_pillow_leather_contrast_welt_upcharge = false;
                localCheckBoxOptions.throw_pillow_fabric_contrast_welt_upcharge = true;
              }
            }
          } else {
            localCheckBoxOptions.throw_pillow_leather_contrast_welt_upcharge = false;
            localCheckBoxOptions.throw_pillow_fabric_contrast_welt_upcharge = false;
          }

          setCheckBoxOptions(localCheckBoxOptions);
        }
      }, 7);
    }
  }, [selectedThrowPillowContrastWelt, mainFabric, pillowDropdownSelector]);

  useEffect(() => {
    if (mainFabric) {
      setTimeout(() => {
        let localCheckBoxOptions = JSON.parse(JSON.stringify(checkBoxOptions));
        if (Object.keys(localCheckBoxOptions).length > 0) {
          if (selectedKidneyPillowContrastWelt) {
            if (
              selectedKidneyPillowContrastWelt.sku !==
                mainFabric.value_string &&
              pillowDropdownSelector.kidney_pillow[
                "kidney_pillow_contrast_welt"
              ]
            ) {
              if (
                selectedKidneyPillowContrastWelt.grade[0].indexOf("leather") !==
                -1
              ) {
                localCheckBoxOptions.kidney_pillow_leather_contrast_welt_upcharge = true;
                localCheckBoxOptions.kidney_pillow_fabric_contrast_welt_upcharge = false;
              } else {
                localCheckBoxOptions.kidney_pillow_leather_contrast_welt_upcharge = false;
                localCheckBoxOptions.kidney_pillow_fabric_contrast_welt_upcharge = true;
              }
            }
          } else {
            localCheckBoxOptions.kidney_pillow_leather_contrast_welt_upcharge = false;
            localCheckBoxOptions.kidney_pillow_fabric_contrast_welt_upcharge = false;
          }

          setCheckBoxOptions(localCheckBoxOptions);
        }
      }, 8);
    }
  }, [selectedKidneyPillowContrastWelt, mainFabric, pillowDropdownSelector]);

  useEffect(() => {
    if (mainFabric) {
      setTimeout(() => {
        let localCheckBoxOptions = JSON.parse(JSON.stringify(checkBoxOptions));
        if (Object.keys(localCheckBoxOptions).length > 0) {
          if (selectedContrastButtons) {
            if (selectedContrastButtons.sku !== mainFabric.value_string) {
              localCheckBoxOptions.contrasting_buttons = true;
            }
          } else {
            localCheckBoxOptions.contrasting_buttons = false;
          }
          setCheckBoxOptions(localCheckBoxOptions);
        }
      }, 9);
    }
  }, [selectedContrastButtons, mainFabric]);

  useEffect(() => {
    if (mainFabric) {
      setTimeout(() => {
        let localCheckBoxOptions = JSON.parse(JSON.stringify(checkBoxOptions));
        if (Object.keys(localCheckBoxOptions).length > 0) {
          if (
            selectedDecorativeCord &&
            selectedDecorativeCord?.sku !== mainFabric.value_string
          ) {
            localCheckBoxOptions.decorative_cord = true;
          } else {
            localCheckBoxOptions.decorative_cord = false;
          }

          setCheckBoxOptions(localCheckBoxOptions);
        }
      }, 10);
    }
  }, [selectedDecorativeCord, mainFabric]);

  useEffect(() => {
    if (mainFabric) {
      setTimeout(() => {
        let localCheckBoxOptions = JSON.parse(JSON.stringify(checkBoxOptions));
        if (Object.keys(localCheckBoxOptions).length > 0) {
          if (
            selectedThrowPillowDecorativeCord &&
            selectedThrowPillowDecorativeCord?.sku !==
              mainFabric.value_string &&
            pillowDropdownSelector.throw_pillow["throw_pillow_decorative_cord"]
          ) {
            localCheckBoxOptions.throw_pillow_decorative_cord_upcharge = true;
          } else {
            localCheckBoxOptions.throw_pillow_decorative_cord_upcharge = false;
          }

          setCheckBoxOptions(localCheckBoxOptions);
        }
      }, 11);
    }
  }, [selectedThrowPillowDecorativeCord, mainFabric, pillowDropdownSelector]);

  useEffect(() => {
    if (mainFabric) {
      setTimeout(() => {
        let localCheckBoxOptions = JSON.parse(JSON.stringify(checkBoxOptions));
        if (Object.keys(localCheckBoxOptions).length > 0) {
          if (
            selectedKidneyPillowDecorativeCord &&
            selectedKidneyPillowDecorativeCord?.sku !==
              mainFabric.value_string &&
            pillowDropdownSelector.kidney_pillow[
              "kidney_pillow_decorative_cord"
            ]
          ) {
            localCheckBoxOptions.kidney_pillow_decorative_cord_upcharge = true;
          } else {
            localCheckBoxOptions.kidney_pillow_decorative_cord_upcharge = false;
          }

          setCheckBoxOptions(localCheckBoxOptions);
        }
      }, 12);
    }
  }, [selectedKidneyPillowDecorativeCord, mainFabric, pillowDropdownSelector]);

  useEffect(() => {
    if (mainFabric) {
      setTimeout(() => {
        let localCheckBoxOptions = JSON.parse(JSON.stringify(checkBoxOptions));
        if (Object.keys(localCheckBoxOptions).length > 0) {
          if (
            selectedFringe &&
            selectedFringe?.sku !== mainFabric.value_string
          ) {
            localCheckBoxOptions.fringe = true;
          } else {
            localCheckBoxOptions.fringe = false;
          }

          setCheckBoxOptions(localCheckBoxOptions);
        }
      }, 13);
    }
  }, [selectedFringe, mainFabric]);

  useEffect(() => {
    if (mainFabric) {
      setTimeout(() => {
        let localCheckBoxOptions = JSON.parse(JSON.stringify(checkBoxOptions));
        if (Object.keys(localCheckBoxOptions).length > 0) {
          if (
            selectedThrowPillowFringe &&
            selectedThrowPillowFringe?.sku !== mainFabric.value_string &&
            pillowDropdownSelector.throw_pillow["throw_pillow_fringe"]
          ) {
            localCheckBoxOptions.throw_pillow_fringe_upcharge = true;
          } else {
            localCheckBoxOptions.throw_pillow_fringe_upcharge = false;
          }

          setCheckBoxOptions(localCheckBoxOptions);
        }
      }, 14);
    }
  }, [selectedThrowPillowFringe, mainFabric, pillowDropdownSelector]);

  useEffect(() => {
    if (mainFabric) {
      setTimeout(() => {
        let localCheckBoxOptions = JSON.parse(JSON.stringify(checkBoxOptions));
        if (Object.keys(localCheckBoxOptions).length > 0) {
          if (
            selectedKidneyPillowFringe &&
            selectedKidneyPillowFringe?.sku !== mainFabric.value_string &&
            pillowDropdownSelector.kidney_pillow["kidney_pillow_fringe"]
          ) {
            localCheckBoxOptions.kidney_pillow_fringe_upcharge = true;
          } else {
            localCheckBoxOptions.kidney_pillow_fringe_upcharge = false;
          }

          setCheckBoxOptions(localCheckBoxOptions);
        }
      }, 15);
    }
  }, [selectedKidneyPillowFringe, mainFabric, pillowDropdownSelector]);

  useEffect(() => {
    let foundOptions = [];
    Object.keys(checkBoxOptions).forEach((key) => {
      foundOptions.push(
        options.find((o) => o.title.toLowerCase().split(" ").join("_") === key)
      );
    });
    let cO = [];
    for (let option of customizationProps.customizationOptions) {
      foundOptions.forEach((foundOption) => {
        if (option.id === foundOption?.option_id) {
          Object.keys(checkBoxOptions).forEach((key) => {
            if (queryPathFabrics?.[key]) {
              option.value_string = queryPathFabrics[key];
            } else if (
              foundOption?.title.toLowerCase().split(" ").join("_") === key
            ) {
              option.value_string = checkBoxOptions[key]
                ? foundOption.value[0].option_type_id.toString()
                : null;
            }
          });
        }
      });

      cO.push(option);
    }

    customizationProps.setCustomizationOptions(cO);
    if (setCheckBoxOptionsCustom) {
      setCheckBoxOptionsCustom(checkBoxOptions);
    }
  }, [checkBoxOptions]);

  useEffect(() => {
    let foundOptions = [];

    Object.keys(selectedFinish).forEach((key) => {
      foundOptions.push(
        options.find((o) => o.title.toLowerCase().split(" ").join("_") === key)
      );
    });

    let cO = [];

    for (let option of customizationProps.customizationOptions) {
      foundOptions.forEach((foundOption) => {
        if (option.id === foundOption?.option_id) {
          Object.keys(selectedFinish).forEach((key) => {
            if (queryPathFabrics?.[key]) {
              option.value_string = queryPathFabrics[key];
            } else if (
              foundOption.title.toLowerCase().split(" ").join("_") === key
            ) {
              if (key === "nail_size") {
                foundOption.value.forEach((val) => {
                  if (val.title === selectedFinish[key].name) {
                    option.value_string = val.option_type_id.toString();
                  }
                });
              } else {
                option.value_string = selectedFinish[key].sku
                  ? selectedFinish[key].sku.toString()
                  : selectedFinish[key].name;
              }
            }
          });
        }
      });
      cO.push(option);
    }

    customizationProps.setCustomizationOptions(cO);
  }, [selectedFinish, queryPathFabrics, foundFabrics]);

  useDeepCompareEffect(() => {
    let foundOptions = [];
    Object.keys(dropdownOptions).forEach((key) => {
      foundOptions.push(
        options.find((o) => o.title.toLowerCase().split(" ").join("_") === key)
      );
    });
    let cO = [];
    for (let option of customizationProps.customizationOptions) {
      foundOptions.forEach((foundOption) => {
        if (option.id === foundOption?.option_id) {
          Object.keys(dropdownOptions).forEach((key) => {
            if (queryPathFabrics?.[key]) {
              option.value_string = queryPathFabrics[key];
            } else if (
              foundOption.title.toLowerCase().split(" ").join("_") === key
            ) {
              option.value_string = dropdownOptions[key]?.toString() || null;
            }
          });
        }
      });

      cO.push(option);
    }
    customizationProps.setCustomizationOptions(cO);
  }, [dropdownOptions]);

  useEffect(() => {
    let localCheckBoxOptions = checkBoxOptions;
    Object.keys(checkBoxOptions).forEach((key) => {
      if (queryPathFabrics?.[key]) {
        localCheckBoxOptions[key] = queryPathFabrics[key];
      }
    });
    setCheckBoxOptions(localCheckBoxOptions);
  }, [queryPathFabrics]);

  // TEMPORARY, REMOVE WHEN MAGENTO STARTS RETURNING true/false
  Object.keys(productData).forEach((key) => {
    let value = productData[key];
    if (value === "N") {
      productData[key] = false;
    }
    if (value === "Y") {
      productData[key] = true;
    }
  });
  //fabric pdp variable
  let fabricPDP = productData?.attribute_set_id === 9;

  //finishes pdp pdp var
  let finishesPdp = productData?.attribute_set_id === 10;

  let sliderProps = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "25%",
    slidesToShow: 1,
    slidesToScroll: 1,
    controls: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          className: "",
          centerMode: false,
          infinite: true,
          centerPadding: "5px",
          slidesToShow: 1,
          slidesToScroll: 1,
          controls: false,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
          dots: true,
          appendDots: (dots) => {
            return (
              <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={30} />
            );
          },
        },
      },
    ],
  };

  let mediaGallery = productData.media_gallery || [];
  mediaGallery.forEach((image) => {
    if (image.url.includes("placeholder")) {
      image.url = "https://via.placeholder.com/800";
    }
  });

  let details = [];
  let specifications = [];
  let dimensions = [];
  let finishesDetails = {};

  const upholsteryOptionsFabrics = React.useMemo(() => {
    return options.find((option) => option.title === "Upholstery Options")
      ?.value;
  }, [options]);

  const mainFabricId = mainFabric?.id;
  const customizationOptionPrice = React.useMemo(() => {
    let filteredFabrics = [];
    if (fabricOrFinishesSelectedOptions.length) {
      filteredFabrics = fabricOrFinishesSelectedOptions.filter((item) => {
        if (pillowOptions.some((pillowOption) => pillowOption === item.id))
          return false;
        else return item.fabric;
      });
      if (filteredFabrics.length === 0) {
        filteredFabrics = [
          {
            fabric: { ...defaults.defaultFabric },
            id: mainFabricId || 0,
          },
        ];
      }
    } else {
      filteredFabrics = [
        {
          fabric: { ...defaults.defaultFabric },
          id: mainFabricId,
        },
      ];
    }

    let fabrics = JSON.parse(JSON.stringify(filteredFabrics));

    //Highest grade calculation happens here.
    const optionsPrice = calculateUpholsteryOptionsPrice(
      upholsteryOptionsFabrics,
      fabrics
    );

    return optionsPrice;
  }, [
    defaults.defaultFabric,
    fabricOrFinishesSelectedOptions,
    mainFabricId,
    pillowOptions,
    upholsteryOptionsFabrics,
  ]);

  Object.keys(productData).forEach((attrName) => {
    const attr = productData[attrName];
    if (attr && attr !== ".00" && attr !== "N/A") {
      if (detailsMapping.includes(attrName)) {
        details.push({ [attrName]: attr });
      }
      if (specificationsMapping.includes(attrName)) {
        specifications.push({ [attrName]: attr });
      }
      if (dimensionsMapping.includes(attrName)) {
        dimensions.push({ [attrName]: attr });
      }
      if (finishesMapping.includes(attrName)) {
        finishesDetails[attrName] = attr;
      }
    }
  });

  /**
   * We're doing this after the initial app mount and then we timeout this function so it'd go to the bottom of
   * the event stack. This is very important otherwise random bugs will eventually happen.
   */
  useEffect(() => {
    if (initialMount) {
      setTimeout(() => {
        setDefaults(
          options,
          customizationProps.customizationOptions,
          (options) => customizationProps.setCustomizationOptions(options),
          defaults,
          queryPathFabrics
        );
        // This array which we for some reason shallow copy is used to determine what fabrics are there for the highest grade calculation.

        const viewMoreOptionPriceFn = calculateViewMorePdpPrice(
          viewMoreOptions
        );
        setViewMorePdpPrice(viewMoreOptionPriceFn);
      }, 1);
    }
  }, [customizationProps, viewMoreOptions, defaults, options, initialMount]);

  useEffect(() => {
    setTimeout(() => {
      let cO = JSON.parse(
        JSON.stringify(customizationProps.customizationOptions)
      );
      const foundOption = options.find(
        (option) => option.title === "Upholstery Options"
      );
      const changeGrade = foundOption?.value.find(
        (option) => option.price === customizationOptionPrice
      );
      cO.forEach((option) => {
        if (option.id === foundOption?.option_id) {
          option.value_string = customizationOptionPrice
            ? changeGrade.option_type_id
            : null;
        }
      });
      // console.log(changeGrade);
      // console.log(cO);
      customizationProps.setCustomizationOptions(cO);
    }, 10);
  }, [customizationOptionPrice]);

  let displayProps = {
    fabricsSelections,
    player,
    customizationProps,
    controlProps,
    customerData,
    sliderProps,
    mediaGallery,
    productData,
    noImage,
    showMainFabric,
    details,
    specifications,
    dimensions,
    isCustom,
    addToCart,
    isAddingToCart,
    fabricPDP,
    allowSectional,
    pickSectional,
    currentSectionals,
    defaults,
    frame,
    fabricOrFinishesSelectedOptions,
    customizationOptionPrice,
    viewMorePdpPrice,
    setViewMorePdpPrice,
    viewMoreOptions,
    setViewMoreOptions,
    setFabricOrFinishSelectedOptions,
    nailData,
    ffifinish_cleaning,
    finishesDetails,
    about_fairshield,
    categories,
    productImageUrl,
    queryPathFabrics: values,
    checkBoxOptions,
    setCheckBoxOptions,
    dropdownOptions,
    setDropdownOptions,
    finishesPdp,
    upholsteryOptionsFabrics,
    ...props,
  };

  useEffect(() => {
    setInitialMount(true);
    return () => {
      //Garbage Collection
      setSelectedFinish({});
      setFabricOrFinishSelectedOptions([]);
      setPrice(false);
      setViewMorePdpPrice(0);
      setCheckBoxOptions({});
      setViewMoreOptions([{}]);
      setDropdownOptions({});
    };
  }, []);

  const memoizedCustomizationProps = React.useMemo(() => {
    return { ...customizationProps, options };
  }, [customizationProps, options]);

  return (
    <>
      <CustomPriceContext.Provider
        value={{
          customizationOptionPrice,
          upholsteryOptionsFabrics,
          customer,
          price,
          setPrice,
          dropdownPrice,
          viewMorePdpPrice,
          contrastWeltPrice,
          trimPrice,
          setTrimPrice,
          contrastButtonsPrice,
          contrastingIds,
          decorativeCordPrice,
          fringePrice,
          throwPillowContrastWeltPrice,
          throwPillowDecorativeCordPrice,
          throwPillowFringePrice,
          kidneyPillowContrastWeltPrice,
          kidneyPillowDecorativeCordPrice,
          kidneyPillowFringePrice,
          initSelectedFinish,
          pillowDropdownSelector,
          setPillowDropdownSelector,
          viewMoreDropdownSelector,
          setViewMoreDropdownSelector,
          loading: false,
        }}
      >
        <Product
          {...props}
          {...displayProps}
          customizationProps={memoizedCustomizationProps}
          favorites={favorites}
          isFavorite={isFavorite}
          toggleFavorite={toggleFavorite}
          onToggleFavorite={handleToggleFavorite}
          selectionsAndSelectors={selectionsAndSelectors}
          checkBoxOptions={checkBoxOptions}
          setCheckBoxOptions={setCheckBoxOptions}
          fabricPDP={fabricPDP}
          foundFabrics={foundFabrics}
        />
      </CustomPriceContext.Provider>
    </>
  );
};

/**
 * Sets default values based either on query path or back-end returned values.
 *
 * @param {Array<Object>} items - All the customization options returned by the back-end
 * @param {Array<Object>} current - Currently selected customization options - This will get overwritten by this function
 * @param      {Function} setCustomizationOptions - Setter for current
 * @param        {Object} defaults - Default fabric from the back-end
 * @param         queryPathFabrics - USED FOR BYO - Every option from the query path
 */
const setDefaults = (
  items,
  current,
  setCustomizationOptions,
  defaults,
  queryPathFabrics
) => {
  let rerenderCheck;
  if (!isEmpty(current)) {
    let localItems = JSON.parse(JSON.stringify(items));
    localItems = localItems.filter(
      (i) => i.title.toLowerCase().indexOf("build") === -1
    );
    rerenderCheck = current.length === localItems.length;
  }

  if (!rerenderCheck) {
    let defaultState = [];
    if (defaults.defaultFabric) {
      items
        .filter((i) => i.title.toLowerCase().indexOf("build") === -1)
        .forEach((item) => {
          let value = defaults.defaultFabric.sku;
          if (item.__typename === "CustomizableCheckboxOption") {
            value = null;
          }
          if (item.__typename === "CustomizableDropDownOption") {
            value = null;
          }
          if (
            item.title.toLowerCase() === "nail size" ||
            item.title.toLowerCase() === "finish" ||
            item.title.toLowerCase() === "nail finish"
          ) {
            value = null;
          }
          if (
            item.title.toLowerCase() === "base trim" ||
            item.title.toLowerCase() === "decorative tape frame" ||
            item.title.toLowerCase() === "skirt trim" ||
            item.title.toLowerCase() === "decorative cord" ||
            item.title.toLowerCase() === "fringe" ||
            item.title.toLowerCase() === "nail band" ||
            item.title.toLowerCase() === "throw pillow contrast welt" ||
            item.title.toLowerCase() === "throw pillow decorative cord" ||
            item.title.toLowerCase() === "throw pillow fringe" ||
            item.title.toLowerCase() === "kidney pillow contrast welt" ||
            item.title.toLowerCase() === "kidney pillow decorative cord" ||
            item.title.toLowerCase() === "kidney pillow fringe"
          ) {
            value = null;
          }
          if (
            queryPathFabrics &&
            queryPathFabrics[item.title.toLowerCase().split(" ").join("_")]
          ) {
            value =
              queryPathFabrics[item.title.toLowerCase().split(" ").join("_")];
          }
          defaultState.push({
            id: item.option_id,
            value_string: value,
          });
        });
    }

    if (!isEmpty(defaultState)) {
      setCustomizationOptions(defaultState);
    }
  }
};

export default ProductDataContainer;
