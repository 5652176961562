import Icon from "app/assets/icon/icon";
import { ModalConsumer } from "core/components/modal/ModalContext";
import { MDBBox, MDBBtn, MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React, { useCallback, useEffect, useRef, useState } from "react";

export const Sidebar = ({
  current,
  options,
  setOption,
  hideModal,
  title = "Select Base Option",
}) => {
  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);
  let [preselection, setPreselection] = useState(current);

  const reference = useRef(null);
  const onClickOutside = useCallback(
    (e) => {
      if (!reference.current.contains(e.target)) {
        hideModal();
      }
    },
    [hideModal]
  );
  useEffect(() => {
    document.addEventListener("click", onClickOutside);
    return () => {
      document.removeEventListener("click", onClickOutside);
    };
  }, [onClickOutside]);
  return (
    <div ref={reference} style={{ zIndex: "10001" }}>
      <MDBRow className="base-options-container" style={{ zIndex: "100001" }}>
        <MDBCol style={{ maxHeight: "100%", height: "100%" }}>
          <MDBRow
            style={{
              zIndex: "2",
            }}
            className="base-options-header"
          >
            <MDBCol className="bg-default flex-center mb-5 px-0">
              <Icon icon="closeWhite" size="4" onClick={hideModal} />
              <MDBTypography tag="h2" className="body-3 base-options-title">
                {title}
              </MDBTypography>
            </MDBCol>
          </MDBRow>
          <MDBRow
            className="p-3 flex-grow-1 items-container"
            style={{
              height: "calc(100% - 2rem)",
              maxHeigh: "calc(100% - 2rem)",
            }}
          >
            <MDBCol>
              <MDBRow style={{ paddingBottom: "18rem" }}>
                {options.map((option) => {
                  return (
                    <MDBCol
                      key={option.id + Math.random() * 100}
                      size="6"
                      className={`p-3 base-option`}
                    >
                      <MDBRow className="mb-2">
                        <MDBCol
                          size="12"
                          onClick={() => {
                            setPreselection(option);
                            setOption(option);
                          }}
                          className="px-3"
                        >
                          <img
                            src={option.image || option.icon || option.map}
                            alt={option.name}
                            className={`img-fluid border  ${
                              option.name === preselection.name
                                ? "border-default "
                                : "border-white"
                            }`.trim()}
                          />

                          <Icon
                            icon="checked"
                            className={`select-option-icon ${
                              option.name === preselection.name && "selected"
                            }`}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="option-title">
                        <MDBCol className="flex-center">
                          <MDBTypography tag="h4" className="text-darken-gray">
                            {option.name}
                          </MDBTypography>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  );
                })}
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBRow className="bg-gray-lighten-4 py-3 d-flex sidebar-footer align-items-center">
            <MDBCol size="12">
              <MDBRow>
                <MDBCol size="2" style={{ minWidth: "7rem" }}>
                  <MDBTypography tag="h4" className="body-2 text-default fs-16">
                    Selected
                  </MDBTypography>
                </MDBCol>
                <MDBCol>
                  <MDBTypography
                    tag="h4"
                    className="body-2 text-darken-gray fs-16"
                  >
                    {preselection.name}
                  </MDBTypography>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol size="12">
              <MDBRow>
                {/* <MDBCol>
                   TO DO : View details 
                  </MDBCol> */}
                <MDBCol className="d-flex justify-content-center align-items-center">
                  <MDBBtn
                    onClick={() => {
                      hideModal();
                    }}
                  >
                    Continue
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export const Step = ({ step = 1, current, options, setOption, title }) => {
  return (
    <MDBRow className={`step-${step}-container p-3`}>
      <ModalConsumer value={{ useWrapper: false }}>
        {({ showModal, hideModal }) => {
          return (
            <MDBCol
              className="bg-gray-lighten-4 py-3 px-xl-5 px-3 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                showModal(() => {
                  return (
                    <Sidebar
                      current={current}
                      options={options}
                      setOption={setOption}
                      hideModal={hideModal}
                      title={title}
                    />
                  );
                });
              }}
            >
              <MDBRow className="align-items-center">
                <MDBCol xl="2" size="3">
                  <MDBBox
                    className="border border-default flex-center"
                    style={{
                      width: "4rem",
                      height: "4rem",
                      borderRadius: "100%",
                      overflow: "hidden",
                    }}
                  >
                    {!current && (
                      <MDBTypography
                        tag="h4"
                        className="body-3 text-default"
                        style={{
                          fontSize: "3.3rem",
                          transform: "translateY(2px)",
                        }}
                      >
                        {step}
                      </MDBTypography>
                    )}
                    {current && (
                      <img
                        src={current.image || current.icon}
                        alt={current.name}
                        className="img-fluid"
                      />
                    )}
                  </MDBBox>
                </MDBCol>
                <MDBCol
                  size="7"
                  xl="8"
                  className="px-4 d-flex flex-column justify-content-center"
                >
                  <MDBRow className="px-2 mb-3 configurator-sidebar fs-24">
                    <MDBCol>
                      <MDBTypography tag="h2" className="body-3 text-default">
                        {title}
                      </MDBTypography>
                    </MDBCol>
                  </MDBRow>
                  {current && (
                    <MDBRow className="px-2">
                      <MDBCol>
                        <MDBTypography
                          tag="h2"
                          className="body-3 text-default fs-16"
                        >
                          {current.name}
                        </MDBTypography>
                      </MDBCol>
                    </MDBRow>
                  )}
                </MDBCol>
                <MDBCol lg="2" size="2" className="p-xl-3 p-0">
                  <Icon icon="rightMobile" size="3" />
                </MDBCol>
              </MDBRow>
            </MDBCol>
          );
        }}
      </ModalConsumer>
    </MDBRow>
  );
};
