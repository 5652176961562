// import React from "react";
// import {
//   STORE_CONFIGURATION_QUERY,
//   GET_ATTRIBUTE_ASSETS_QUERY,
//   GET_ATTRIBUTE_INFORMATION,
// } from "./query";
// import GraphQlWrapper from "core/components/graphQlWrapper";

const ConfigurationLoader = (props) => {
  // const attributeMetadataInformationVariables = [
  //   {
  //     attribute_code: "sku",
  //     entity_type: "4",
  //   },
  // ];
  return null;
  // return (
  //   <>
  //     <GraphQlWrapper
  //       query={STORE_CONFIGURATION_QUERY}
  //       variables={{ id: `store_configuration_data` }}
  //       saveQuery={true}
  //       noWrapper={true}
  //     >
  //       <></>
  //     </GraphQlWrapper>
  //     <GraphQlWrapper
  //       query={GET_ATTRIBUTE_ASSETS_QUERY}
  //       variables={{ id: `attribute_assets_configuration` }}
  //       noWrapper={true}
  //       saveQuery={true}
  //     >
  //       <></>
  //     </GraphQlWrapper>
  //     <GraphQlWrapper
  //       query={GET_ATTRIBUTE_INFORMATION}
  //       variables={{
  //         id: `attributes_metadata_information`,
  //         attributeInput: attributeMetadataInformationVariables,
  //       }}
  //       noWrapper={true}
  //       saveQuery={true}
  //     >
  //       <></>
  //     </GraphQlWrapper>
  //   </>
  // );
};

export default ConfigurationLoader;
