import { loader } from "graphql.macro";

// perhaps this needs to be deprecated?

export default (query) => {
  if (query === "category") {
    return loader("../graphql/queries/category.graphql");
  }

  if (query === "getSalesRepData") {
    return loader("../graphql/queries/customer/getSalesRepData.graphql");
  }

  if (query === "categoryProducts") {
    return loader("../graphql/queries/categoryProducts.graphql");
  }

  if (query === "products") {
    return loader("../graphql/queries/products.graphql");
  }

  if (query === "generateDownloadUrlM") {
    return loader("../graphql/mutations/generateDownloadUrlM.graphql");
  }

  if (query === "product") {
    return loader("../graphql/queries/product.graphql");
  }

  if (query === "categoryTree") {
    return loader("../graphql/queries/categoryTree.graphql");
  }

  if (query === "cmsPage") {
    return loader("../graphql/queries/cmsPage.graphql");
  }

  if (query === "getProductInfoBySku") {
    return loader("../graphql/queries/getProductInfoBySku.graphql");
  }

  if (query === "cmsBlock") {
    return loader("../graphql/queries/cmsBlock.graphql");
  }

  if (query === "urlResolver") {
    return loader("../graphql/queries/urlResolver.graphql");
  }

  if (query === "userLogin") {
    return loader(
      "../graphql/mutations/customer/session/loginMutation.graphql"
    );
  }

  if (query === "userLogout") {
    return loader(
      "../graphql/mutations/customer/session/logoutMutation.graphql"
    );
  }

  if (query === "checkCustomerAuthorization") {
    return loader("../graphql/queries/customerCheckToken.graphql");
  }

  if (query === "updateCustomerEmail")
    return loader(
      "../graphql/mutations/customer/information/UpdateCustomerEmail.graphql"
    );

  // if (query === "getCountries") {
  //   return loader("../graphql/queries/countries.graphql");
  // }
  // if (query === "searchProducts") {
  //   return loader("../graphql/queries/searchProducts.graphql");
  // }

  if (query === "generateCache") {
    return loader("../graphql/mutations/generateCache.graphql");
  }
  if (query === "SetShippingAddressesOnCart") {
    return loader(
      "../graphql/mutations/cart/SetShippingAddressesOnCart.graphql"
    );
  }

  if (query === "SetBillingAddressOnCart") {
    return loader("../graphql/mutations/cart/SetBillingAddressOnCart.graphql");
  }

  if (query === "customer")
    return loader("../graphql/queries/customer/customer.graphql");

  // if (query === "customerExportList") {
  //   return loader("../graphql/queries/customerExportList.graphql");
  // }

  // if (query === "createExportList") {
  //   return loader("../graphql/mutations/exportList/createExportList.graphql");
  // }

  // if (query === "createFreightRequest") {
  //   return loader(
  //     "../graphql/mutations/exportList/createQuoteRequest.graphql"
  //   );
  // }

  // if (query === "changeExportListName") {
  //   return loader(
  //     "../graphql/mutations/exportList/information/changeExportListName.graphql"
  //   );
  // }

  // if (query === "deleteExportList") {
  //   return loader("../graphql/mutations/exportList/deleteExportList.graphql");
  // }

  // if (query === "addSkuToList") {
  //   return loader(
  //     "../graphql/mutations/exportList/items/addSkuToList.graphql"
  //   );
  // }

  // if (query === "removeSkuFromList") {
  //   return loader(
  //     "../graphql/mutations/exportList/items/removeSkuFromList.graphql"
  //   );
  // }

  if (query === "generateResetPasswordToken") {
    return loader(
      "../graphql/mutations/customer/generateResetPasswordToken.graphql"
    );
  }
  if (query === "addCustomerQuoteItemAttributes") {
    return loader(
      "../graphql/mutations/customer/cart/addCustomerQuoteItemAttributes.graphql"
    );
  }

  // if (query === "getWishlists") {
  //   return loader("./queries/wishlists/wishlists.graphql");
  // }
  if (query === "resetCustomerPassword") {
    return loader("./mutations/customer/information/resetPasswordJola.graphql");
  }

  if (query === "deleteSavedBuildsFromWishlist") {
    return loader(
      "../graphql/mutations/wishlists/deleteSavedBuildsFromWishlist.graphql"
    );
  }
  // if (query === "addAllToCart") {
  //   return loader("../graphql/mutations/wishlists/addAllToCart.graphql");
  // }
  // if (query === "addItemToCart") {
  //   return loader("../graphql/mutations/wishlists/addItemToCart.graphql");
  // }
  // if (query === "exportListQuery") {
  //   return loader("../graphql/queries/exportListQuery.graphql");
  // }

  // if (query === "removeAllItemsFromList") {
  //   return loader(
  //     "../graphql/mutations/exportList/removeAllItemsFromList.graphql"
  //   );
  // }

  if (query === "sendEmail") {
    return loader("../graphql/mutations/sendEmail.graphql");
  }

  // if (query === "storeConfig") {
  //   return loader("../graphql/queries/store/storeConfig.graphql");
  // }

  // if (query === "getDynamicContent") {
  //   return loader(
  //     "../graphql/queries/content/getDynamicContentByContentType.graphql"
  //   );
  // }

  // if (query === "getFilters") {
  //   return loader("../graphql/queries/getFilters.graphql");
  // }

  if (query === "createEmptyCart") {
    return loader(
      "../graphql/mutations/customer/cart/customerCreateEmptyCart.graphql"
    );
  }

  if (query === "addProduct") {
    return loader(
      "../graphql/mutations/customer/cart/addProductToCart.graphql"
    );
  }
  if (query === "subscriber") {
    return loader("../graphql/mutations/subscribe/newsletterSubscribe.graphql");
  }
  throw new Error(
    "Unknown query, you probably forgot to define it in state/graphql/queryLoader.js."
  );
};
