import PriceSlider, {
  PriceSliderProps,
} from "app/layout/PriceSlider/PriceSlider";
import Select from "app/layout/Select";
import { getOnlyPrice } from "core/components/price";
import React from "react";
import { components } from "react-select";

export interface SelectPriceProps extends PriceSliderProps {
  handleDragStop: () => void;
  handleDrag: React.MouseEventHandler;
}

const SelectPrice: React.ForwardRefRenderFunction<
  HTMLDivElement,
  SelectPriceProps
> = (props, trackRef) => {
  const MenuList: typeof components["MenuList"] = React.useCallback(
    () => (
      <div
        className="mt-3 mb-3 d-flex justify-content-center"
        onMouseUp={props.handleDragStop}
        // onMouseLeave={handleDragStop}
        onMouseMove={props.handleDrag}
        // @ts-ignore
        onTouchMove={props.handleDrag}
        // @ts-ignore
        onTouchEnd={props.handleDragStop}
        tabIndex={0}
        role="button"
      >
        <PriceSlider ref={trackRef} {...props} />
      </div>
    ),
    [props, trackRef]
  );
  const fromPrice = !isNaN(props.fromPrice) ? props?.fromPrice : props.minPrice;
  const toPrice = !isNaN(props.toPrice) ? props.toPrice : props.maxPrice;
  const options = React.useMemo(() => {
    return [
      {
        label:
          props.sizeSuffix || props.sizeSuffix === "" ? (
            props.placeholder &&
            (isNaN(fromPrice) ||
              (fromPrice === props.minPrice && toPrice === props.maxPrice)) ? (
              <div className="price-slider-placeholder">
                {props.placeholder}
              </div>
            ) : (
              <>
                {(fromPrice || "0") + " " + props?.sizeSuffix} -{" "}
                {props.isUniversal
                  ? toPrice
                  : toPrice + " " + props?.sizeSuffix}
              </>
            )
          ) : (
            <>
              {getOnlyPrice({
                price: fromPrice,
              })}{" "}
              -{" "}
              {getOnlyPrice({
                price: toPrice,
              })}
            </>
          ),
        value: null,
      },
    ];
  }, [props]);

  return (
    <Select
      className="md"
      value={options[0]}
      options={options}
      onChange={React.useCallback(() => {}, [])}
      isSearchable={false}
      components={React.useMemo(() => ({ MenuList }), [MenuList])}
    />
  );
};

export default React.forwardRef(SelectPrice);
