import { ApolloError } from "apollo-client";
import {
  Maybe,
  ProductInterface,
  useAddItemsToWishlistsMutation,
  useAddNewWishlistMutation,
  useDeleteItemsFromWishlistMutation,
  useDeleteWishlistMutation,
  useWishlistsQuery,
  WishlistJola,
  WishlistsDocument,
  WishlistsQuery,
} from "app/generated/graphql";
import CollectionsContext from "app/layout/collections/CollectionsContext";
import { selectIsLoggedIn } from "app/state/redux/data/customer/selectors";
import ModalContext from "core/components/modal/ModalContext";
import { messagesActions } from "core/state/redux/data/messages";
import { FormikConfig, useFormik } from "formik";
import { MDBTypography } from "mdbreact";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Login from "../customer/login-favorites";
import { customerPaths } from "../customer/routes";
import AddedToProjectsSnackbar from "./components/AddedToProjectsSnackbar";
import { checkIfItemIsChecked } from "./components/WishlistsModal/wishlistHelper";
import {
  creationWishlistSchema,
  CreationWishlistValues,
  creationWishlistValues,
  savedBuildsName,
} from "./config";
import {
  OnAddToProjects,
  ToggleWishlistItemConfig,
  ToggleWishlistItemsConfig,
} from "./models";
import {
  getFavorites,
  getIsFavorite,
  getWishlistResponse,
  isWishlistDefined,
  isWishlistItemDefined,
} from "./utils";
import queryLoader from "app/graphql/queryLoader";
import { useMutation } from "react-apollo-hooks";
import { find } from "lodash";

export const useCreationWishlistForm = () => {
  const dispatch = useDispatch();
  const [addNewWishlist] = useAddNewWishlistMutation({
    onError: (error) => {
      let messageWithoutGl = error.message.replace("GraphQL error: ", "");
      dispatch(messagesActions.addMessage(messageWithoutGl, "error"));
    },
  });

  const handleSubmit = React.useCallback(
    (values: CreationWishlistValues) =>
      addNewWishlist({
        variables: values,
        optimisticResponse: {
          __typename: "Mutation",
          addNewWishlist: {
            __typename: "WishlistJola",
            id: null,
            items: [],
            items_count: "0",
            name: values.name,
          },
        },
        update: (cache, result) => {
          if (result.data?.addNewWishlist) {
            const data = cache.readQuery<WishlistsQuery>({
              query: WishlistsDocument,
            });

            const getWishlists = data?.getWishlists
              ? data.getWishlists.concat(result.data.addNewWishlist)
              : [result.data.addNewWishlist];

            cache.writeQuery<WishlistsQuery>({
              query: WishlistsDocument,
              data: {
                ...data,
                getWishlists,
              },
            });
          } else {
            console.error(
              "The newly created wishlist",
              result.data?.addNewWishlist,
              "has not id."
            );
          }
        },
      }),
    [addNewWishlist]
  );

  const creationWishlistConfig: FormikConfig<CreationWishlistValues> = React.useMemo(
    () => ({
      initialValues: creationWishlistValues,
      validationSchema: creationWishlistSchema,
      onSubmit: (values, { resetForm, setErrors, setFieldError }) => {
        setErrors({});

        return handleSubmit(values)
          .then(() => {
            resetForm();
          })
          .catch((error: ApolloError) => {
            setFieldError("name", error.graphQLErrors?.[0].message);
          });
      },
    }),
    [handleSubmit]
  );

  const creationWishlistForm = useFormik(creationWishlistConfig);

  return creationWishlistForm;
};

export const useDeleteWishlist = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [deleteWishlist] = useDeleteWishlistMutation({
    // onCompleted: () => {
    //   history.replace(customerPaths.children.collections.path);
    // },
    onError: (error) => {
      dispatch(messagesActions.addMessage(error.message, "error"));
    },
  });

  return (wishlist: Maybe<WishlistJola>) => {
    if (wishlist?.name) {
      return deleteWishlist({
        variables: { name: wishlist.name },
        optimisticResponse: {
          __typename: "Mutation",
          deleteWishlist: { __typename: "WishlistJola", ...wishlist },
        },
        update: (cache, result) => {
          const data = cache.readQuery<WishlistsQuery>({
            query: WishlistsDocument,
          });

          cache.writeQuery<WishlistsQuery>({
            query: WishlistsDocument,
            data: {
              getWishlists: data?.getWishlists?.filter(
                (wishlist) => wishlist?.id !== result.data?.deleteWishlist.id
              ),
            },
          });
        },
      });
    } else {
      console.error("Cannot delete wishlist because it does not have a name.");
    }
  };
};

export const useWishlists = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const result = useWishlistsQuery({
    skip: !isLoggedIn,
    // fetchPolicy: "cache-and-network",
  });

  return React.useMemo(
    () => result.data?.getWishlists?.filter(isWishlistDefined) ?? [],
    [result]
  );
};

export const useFavorites = () => {
  const wishlists = useWishlists();

  return React.useMemo(() => getFavorites(wishlists), [wishlists]);
};
export const useIsFavorite = (sku: ProductInterface["sku"]): boolean => {
  const favorites = useFavorites();

  return React.useMemo(() => !!favorites && getIsFavorite({ favorites, sku }), [
    favorites,
    sku,
  ]);
};

export const useToggleWishlistItems = (onAddToProjects?: OnAddToProjects) => {
  const dispatch = useDispatch();
  const onError = (error: ApolloError) => {
    dispatch(messagesActions.addMessage(error.message, "error"));
  };
  const { wishlistName } = React.useContext(CollectionsContext);
  const [addItemsToWishlists] = useAddItemsToWishlistsMutation({
    onError,
    onCompleted: () => {
      if (document.querySelector(".favorites-modal")) {
        dispatch(
          messagesActions.addMessage(
            "",
            "success",
            undefined,
            undefined,
            <>
              <MDBTypography className="mb-0 mr-3 pr-2 fs-16 fwregular text-white">
                Product added to {wishlistName}
              </MDBTypography>
            </>
          )
        );
      }
    },
  });
  const [deleteItemsFromWishlist] = useDeleteItemsFromWishlistMutation({
    onCompleted: () => {
      dispatch(
        messagesActions.addMessage(
          "",
          "success",
          undefined,
          undefined,
          <>
            <MDBTypography className="mb-0 mr-3 pr-2 fs-16 fwregular text-white">
              Product removed from {wishlistName}
            </MDBTypography>
          </>
        )
      );
    },
    onError,
  });

  const [deleteSavedBuildsFromWishlist] = useMutation(
    queryLoader("deleteSavedBuildsFromWishlist")
  );
  // @ts-ignore
  const makeProductOptions = (selectedOptions) => {
    // @ts-ignore
    const isByo = selectedOptions?.some((option) =>
      option?.value_string?.includes("build-your-own")
    );
    const index = isByo ? 2 : 1;
    if (!selectedOptions) return;
    const getBuildUrl = selectedOptions?.[
      selectedOptions?.length - index
    ]?.option_value_title
      ?.split("?")?.[1]
      ?.split("&");

    let options: any[] = [];
    // @ts-ignore
    selectedOptions.forEach((option) => {
      // @ts-ignore
      getBuildUrl.forEach((element) => {
        let splitedElement = element.split("=");
        if (
          option?.option_title?.toLowerCase()?.split(" ")?.join("_") ===
          splitedElement?.[0]
        ) {
          options.push({
            id: option?.option_id,
            value_string: splitedElement?.[index],
          });
        }
      });
    });
    options.push({
      id: selectedOptions?.[selectedOptions?.length - 1]?.option_id,
      value_string:
        selectedOptions?.[selectedOptions?.length - 1]?.option_value_title,
    });
    return options;
  };

  const getDefaultOptions = (options: any[]) => [
    ...options?.map((option) => ({
      id: option?.option_id,
      value_string: option?.option_value_id || option?.option_value_title,
    })),
  ];

  const { setWishlistName, isFavorite, setShowModal } = React.useContext(
    CollectionsContext
  );
  const wishlists = useWishlists();
  return React.useCallback(
    // @ts-ignore
    ({
      wishlist,
      product,
      formattedOptions,
      deleteProducts,
    }: ToggleWishlistItemsConfig) => {
      const pathname = window.location.pathname;
      // @ts-ignore
      const isCustom = product?.product ? true : false;
      const itemExistsOnWishlist = wishlist?.items?.some((item) => {
        return (
          item?.product?.sku ===
            // @ts-ignore
            (isCustom ? product?.product?.sku : product?.sku) &&
          checkIfItemIsChecked(product, item)
        );
      });

      // // @ts-ignore
      if (pathname.includes("customer/collections/lists") && product) {
        let wishlist = wishlists.find((wishlist) =>
          // @ts-ignore
          wishlist?.items?.some((item) => item?.id === product?.productId)
        );
        const selectedOptions = wishlist?.items?.find(
          // @ts-ignore
          (item) => item?.id === product?.productId
        )?.selected_customizable_options;
        // @ts-ignore
        product.options = makeProductOptions(selectedOptions);
      }

      setWishlistName(wishlist?.name ?? "My Favorites");

      if (deleteProducts) {
        let ids = wishlist?.items?.reduce(
          // @ts-ignore
          (acc, curr) => [...acc, curr?.id],
          []
        );
        return new Promise((resolve, reject) => {
          resolve(
            deleteSavedBuildsFromWishlist({
              variables: {
                input: {
                  // @ts-ignore
                  item_ids: ids,
                  wishlist: wishlist?.name,
                },
              },
              optimisticResponse: {
                __typename: "Mutation",
                deleteItemsFromWishlist: {
                  __typename: "WishlistJola",
                  ...wishlist,
                  items_count: "0",
                  items: [],
                },
              },
            })
          );
        }).then(() =>
          dispatch(
            messagesActions.addMessage(
              "",
              "success",
              undefined,
              undefined,
              <>
                <MDBTypography className="mb-0 mr-3 pr-2 fs-16 fwregular text-white">
                  Product removed from {wishlist?.name}
                </MDBTypography>
              </>
            )
          )
        );
      } else if (
        itemExistsOnWishlist &&
        (isFavorite ||
          wishlist?.name !== "My Favorites" ||
          // @ts-ignore
          (!product?.options && !product?.defaultOptions))
      ) {
        let option = checkIfItemIsChecked(product, null, false);
        let productId = wishlist?.items?.find(
          (item) =>
            item?.selected_customizable_options?.find(
              (customOption) =>
                customOption?.option_title === "Build your own configurator url"
            )?.option_value_title ===
            (option?.option_value_title
              ? option?.option_value_title
              : option?.value_string)
        )?.id;
        return new Promise((resolve, reject) => {
          resolve(
            deleteSavedBuildsFromWishlist({
              variables: {
                input: {
                  // @ts-ignore
                  item_ids: [+productId],
                  wishlist: wishlist?.name,
                },
              },
              optimisticResponse: {
                __typename: "Mutation",
                deleteItemsFromWishlist: {
                  __typename: "WishlistJola",
                  ...wishlist,
                  items_count: (
                    Number(wishlist?.items_count ?? "1") - 1
                  ).toString(),
                  items: (wishlist?.items ?? []).filter(
                    (wishlistitem) => wishlistitem?.id === productId
                  ),
                },
              },
            })
          );
        }).then(() =>
          dispatch(
            messagesActions.addMessage(
              "",
              "success",
              undefined,
              undefined,
              <>
                <MDBTypography className="mb-0 mr-3 pr-2 fs-16 fwregular text-white">
                  Product removed from {wishlist?.name}
                </MDBTypography>
              </>
            )
          )
        );
      } else {
        // @ts-ignore
        return addItemsToWishlists({
          variables: {
            input: {
              products: [
                {
                  sku: isCustom
                    ? // @ts-ignore
                      product?.product?.sku
                    : product?.sku,
                  //@ts-ignore
                  options: formattedOptions?.length
                    ? formattedOptions
                    : //@ts-ignore
                    product?.options
                    ? //@ts-ignore
                      product?.options
                    : //@ts-ignore
                    product?.defaultOptions?.length
                    ? //@ts-ignore
                      getDefaultOptions(product?.defaultOptions)
                    : [],
                },
              ],
              wishlists: [
                {
                  name: wishlist?.name ? wishlist?.name : "My Favorites",
                },
              ],
            },
          },
          //@ts-ignore
          optimisticResponse: getWishlistResponse(wishlist, [product]),
        }).then(() => {
          if (onAddToProjects) {
            dispatch(
              messagesActions.addMessage(
                "",
                "success",
                undefined,
                undefined,
                // @ts-ignore
                <AddedToProjectsSnackbar
                  onAddToProjects={() => {
                    //@ts-ignore
                    onAddToProjects([product, formattedOptions]);
                    setShowModal(true);
                  }}
                  firstOption={
                    <Link
                      to={
                        customerPaths.children.collections.children.wishlists
                          .path
                      }
                      className="fs-14"
                    >
                      <span className="pointer fwregular underline text-white">
                        View
                      </span>
                    </Link>
                  }
                />
              )
            );
          }
        });
      }
    },
    [addItemsToWishlists, deleteItemsFromWishlist, dispatch, onAddToProjects]
  );
};

export const useToggleWishlistItem = (onAddToProjects?: OnAddToProjects) => {
  const toggleWishlistItems = useToggleWishlistItems(onAddToProjects);
  return React.useCallback(
    ({ product, formattedOptions, ...config }: ToggleWishlistItemConfig) => {
      //@ts-ignore
      return toggleWishlistItems({
        ...config,
        //@ts-ignore
        product: product,
        formattedOptions,
      });
    },
    [toggleWishlistItems]
  );
};
export const useToggleFavorite = (
  favorites: Maybe<WishlistJola>,
  onAddToProjects?: OnAddToProjects
) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { showModal, hideModal } = React.useContext(ModalContext);
  const toggleWishlistItem = useToggleWishlistItem(onAddToProjects);

  return React.useCallback(
    (
      config: Pick<
        ToggleWishlistItemConfig,
        "product" | "checked" | "formattedOptions"
      >
    ) => {
      if (isLoggedIn) {
        toggleWishlistItem({ wishlist: favorites, ...config });
      } else {
        // @ts-ignore
        showModal(() => {
          return (
            <Login
              hideModal={(isLoggedIn) => {
                hideModal();

                if (isLoggedIn) {
                  toggleWishlistItem({
                    wishlist: favorites,
                    product: config.product,
                    formattedOptions: config.formattedOptions,
                    checked: true, // * to avoid removing the item from the wishlist if it is already present
                  });
                }
              }}
            />
          );
        });
      }
    },
    [toggleWishlistItem, favorites, isLoggedIn, showModal, hideModal]
  );
};
// TODO find a more concise name
export const useToggleFavoriteProductParam = () => {
  const favorites = useFavorites();
  const { setSelectedProduct } = React.useContext(CollectionsContext);
  const { setFormattedOptions } = React.useContext(CollectionsContext);
  const toggleFavorite = useToggleFavorite(
    favorites,
    //@ts-ignore
    ([product, formattedOptions]) => {
      //@ts-ignore
      setSelectedProduct(product);
      //@ts-ignore
      setFormattedOptions(formattedOptions);
    }
  );

  return React.useCallback(
    (
      product: ToggleWishlistItemConfig["product"],
      formattedOptions: Array<{}>
    ) =>
      toggleFavorite({
        product,
        formattedOptions,
        checked: !!favorites && !getIsFavorite({ favorites, sku: product.sku }),
      }),
    [toggleFavorite, favorites]
  );
};
export const useToggleFavoriteProduct = (
  product: ToggleWishlistItemConfig["product"]
) => {
  const toggleFavorite = useToggleFavoriteProductParam();

  return React.useCallback(() => {
    //@ts-ignore
    toggleFavorite(product, formattedOptions);
    //@ts-ignore
  }, [product, formattedOptions, toggleFavorite]);
};

export const useDeleteWishlistItem = (wishlist: Maybe<WishlistJola>) => {
  const toggleWishlistItem = useToggleWishlistItem();

  return React.useCallback(
    (config: Pick<ToggleWishlistItemConfig, "product">) => {
      if (wishlist) {
        toggleWishlistItem({ ...config, wishlist, checked: false });
      } else {
        console.error("Cannot delete item from wishlist", wishlist);
      }
    },
    [toggleWishlistItem, wishlist]
  );
};

export const useRemoveAllItemsFromWishlist = (
  wishlist: Maybe<WishlistJola>
) => {
  const toggleWishlistItems = useToggleWishlistItems();

  const removeAll = React.useCallback(
    ({ wishlist }: Pick<ToggleWishlistItemsConfig, "wishlist">) => {
      const products = wishlist?.items
        ?.filter(isWishlistItemDefined)
        .map(({ product }) => {
          return product.sku;
        });

      if (products?.length) {
        toggleWishlistItems({
          wishlist,
          //@ts-ignore
          deleteProducts: products,
          checked: false,
        });
      } else {
        console.error("Cannot remove all items since none are present.");
      }
    },
    [toggleWishlistItems]
  );

  return React.useCallback(() => {
    if (wishlist) {
      removeAll({ wishlist: wishlist });
    } else {
      console.error("Cannot delete items from wishlist since it is", wishlist);
    }
  }, [removeAll, wishlist]);
};

export const useSavedBuilds = () => {
  const wishlists = useWishlists();

  return React.useMemo(
    () => wishlists.find((wishlist) => wishlist.name === savedBuildsName),
    [wishlists]
  );
};
