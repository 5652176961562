import React, { useState, useEffect } from "react";
import { SortEnum } from "app/generated/graphql";
import { useLocation } from "react-router-dom";
import SearchResultsPageQueryContainer from "./searchResultsPage-query-container";
import Config from "config/themeConfiguration";
import { fromPairs, isEqual } from "lodash";
import { getParamValue } from "app/utils/utils";
import qs from "qs";
import {
  filterSchema,
  getQueryFilter,
  initialFilter,
  initialSort,
  selectFabrics,
} from "app/pages/catalog/category/utils/utils.tsx";
import StateContainer from "app/pages/catalog/category";

const SearchResultsPageStateContainer = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  let queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let searchVal = urlParams.get("search");
  let optionsVal = urlParams.get("options");
  let categoryName = urlParams.get("category");
  let categoryTypeId = urlParams.get("categoryTypeId");
  let [currentPage, setCurrentPage] = useState(getParamValue("page", 1));
  let [pageSize, setPageSize] = useState(
    getParamValue("pageSize", Config.pageSizeOptions[3].value)
  );
  const [initLaneLoaded, setInitLaneLoaded] = useState(false);

  /**
   * Returns Sort by url params, if it does not exist returns initial sort
   * @returns {import('./utils/utils').Sort}
   */
  const getInitialSort = () => {
    let key = getParamValue("sortKey", null);
    let direction = getParamValue("sortDirection", null);
    /** @type {import('./utils/utils').Sort} */
    let urlSort = initialSort;
    /**
     * @type {boolean}
     */
    let urlSortExists = false;
    if (
      (key === "name" ||
        key === "price" ||
        key === "ffi_introdat" ||
        key === "sku") &&
      (direction === SortEnum.Asc || direction === SortEnum.Desc)
    ) {
      urlSortExists = true;
      urlSort = { key, direction };
    }
    return urlSortExists ? urlSort : initialSort;
  };

  const [sort, setSort] = React.useState(getInitialSort());

  let pageSizeOptions = Config.pageSizeOptions;
  /**
   *
   * @param {{ value: number }} pageSize
   */
  const handleSetPageSize = (pageSize) => {
    setPageSize(pageSize.value);
  };

  // React.useEffect(() => {
  //   if (currentPage && pageSize) {
  //     let params = [
  //       {
  //         name: "page",
  //         value: currentPage,
  //       },
  //       {
  //         name: "pageSize",
  //         value: pageSize,
  //       },
  //     ];
  //     updateParams(params);
  //   }
  // }, [currentPage, pageSize]);

  /**
   * Updates search paramater with new value
   */
  function updateParams(paramArray) {
    const urlParams = new URLSearchParams(window.location.search);
    paramArray.forEach((param) => {
      urlParams.set(param.name, param.value);
    });

    window.history.pushState(
      null,
      "",
      `${window.location.pathname}?${urlParams.toString()}`
    );
  }
  return (
    <>
      {/* <SearchResultsPageQueryContainer
        {...props}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        setPageSize={handleSetPageSize}
        pageSizeOptions={pageSizeOptions}
        setPriceFilter={setPriceFilter}
        setHexclrgrpFilter={setHexclrgrpFilter}
        setPatternFilter={setPatternFilter}
        setGradeFilter={setGradeFilter}
        setFabricsCollectionFilter={setFabricsCollectionFilter}
        clearFilter={clearFilter}
        clearLaneFilter={clearLaneFilter}
        setLaneFilter={setLaneFilter}
        toggleLeatherAvailableFilter={toggleLeatherAvailableFilter}
        setCategoryFilter={setCategoryFilter}
        toggleIsNewFilter={toggleIsNewFilter}
        toggleInStockFilter={toggleInStockFilter}
        toggleAssemblyFilter={toggleAssemblyFilter}
        setCustomTypeFilter={setCustomTypeFilter}
        setCollectionFilter={setCollectionFilter}
        filter={filter}
        sort={sort}
        setSort={setSort}
        toggleIsAsc={toggleIsAsc}
        isFabricOrFinishesCategory={false}
        isFinish={false}
      /> */}
      <StateContainer
        category={{
          id: 2,
          url_path: "/search-results",
          hero_image: 'PLP_AccentChairs_920x500px_96dpi_1.jpg',
          name: categoryName === 'fabrics-and-leather' ? 'Fabrics and Leather Search' : 'Product Search'
        }}
        isSearchPage={true}
      />
    </>
  );
};
export default SearchResultsPageStateContainer;
