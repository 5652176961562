// @ts-check

import clsx from "clsx";
import { MDBTypography } from "mdbreact";
import React from "react";

/**
 *
 * @typedef {import('../Switch').SwitchProps} RadioButtonProps
 * @template Value
 */

/**
 * @template Value
 *
 * @param {RadioButtonProps<Value>} props
 */
const RadioButton = (props) => {
  return (
    <label
      className={clsx(
        "radio-button pointer d-inline-flex align-items-center",
        props.className
      )}
      style={props.style}
    >
      <input {...props} type="radio" className={clsx("hide")} />
      <div
        className={
          "radio-button-body border-default-1 border-radius-50 bg-white mr-3"
        }
      >
        <div
          className={clsx(
            "radio-button-indicator",
            "border-radius-50",
            "bg-default",
            { hidden: !props.checked }
          )}
        />
      </div>
      {/* @ts-ignore */}
      <MDBTypography tag="span" variant="body-6">
        {props.label}
      </MDBTypography>
    </label>
  );
};

export default RadioButton;
