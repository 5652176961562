import React, { useCallback } from "react";
import FabricPdp from "../../catalog/product/fabricPdp/fabricPdp";
import FinishesPdp from "../../catalog/product/finishesPdp";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "graphql.macro";
import Skeleton from "react-loading-skeleton";
import { finishesDetails as finishesMapping } from "../../catalog/product/attrMapping";
import { useToggleFavoriteProductParam } from "app/pages/collections/hooks";
import CustomPdp from "../../catalog/product/customPdp";
import useCustomer from "app/state/hooks/customer/useCustomer";

const COLORWAYS = gql`
  query Colorways($skus: [String]) {
    products(filter: { sku: { in: $skus } }) {
      items {
        name
        url_key
        sku
        url_rewrites {
          url
          parameters {
            name
            value
          }
        }
        thumbnail {
          url
        }
      }
    }
  }
`;

const FabricFinishPDP = ({
  product,
  fabric,
  aggregations,
  customOpt,
  calculateOptions,
}) => {
  let colorwaysSkus = product?.colorways && product.colorways.split(",");
  const { data: colorwaysData, loading: colorwaysLoading } = useQuery(
    COLORWAYS,
    {
      skip: !colorwaysSkus,
      variables: { skus: colorwaysSkus },
    }
  );

  let finishesDetails = {};
  const customer = useCustomer().isLoggedIn();

  Object.keys(product).forEach((attrName) => {
    const attr = product[attrName];
    if (attr && attr !== ".00" && attr !== "N/A") {
      if (finishesMapping.includes(attrName)) {
        finishesDetails[attrName] = attr;
      }
    }
    // This will add stock for finishes if ever needed.
    // if (attrName === "stockQty") {
    //   finishesDetails["stock"] =
    //     attr && attr > 15
    //       ? customer
    //         ? attr +
    //           `${product.ffifabrics_fabric === 1 ? " yards" : " sq. ft."}`
    //         : "In Stock"
    //       : "Out of Stock";
    // }
  });
  const toggleFavorite = useToggleFavoriteProductParam();
  const handleToggleFavorite = useCallback(() => toggleFavorite(product), [
    toggleFavorite,
    product,
  ]);

  if (colorwaysLoading) return <Skeleton />;

  if (fabric) {
    return (
      <div className={"fabric-finish-container"}>
        <FabricPdp
          productData={product}
          colorwaysData={colorwaysData}
          aggregations={aggregations}
          toggleFavorite={toggleFavorite}
          onToggleFavorite={handleToggleFavorite}
          calculateOptions={calculateOptions}
        />
      </div>
    );
  } else if (customOpt) {
    return (
      <div className={"fabric-finish-container"}>
        <CustomPdp
          {...product}
          productData={product}
          toggleFavorite={toggleFavorite}
          onToggleFavorite={handleToggleFavorite}
          calculateOptions={calculateOptions}
        />
      </div>
    );
  } else {
    return (
      <div className={"fabric-finish-container"}>
        <FinishesPdp
          ffifinish_fairshield={product.ffifinish_fairshield}
          add_info={product?.add_info}
          finishesDetails={finishesDetails}
          finishes_cleaning={product.finishes_cleaning}
          about_fairshield_plus={product.about_fairshield}
          small_image={product.small_image}
          productData={product}
          toggleFavorite={toggleFavorite}
          onToggleFavorite={handleToggleFavorite}
          calculateOptions={calculateOptions}
          name={product.name}
        />
      </div>
    );
  }
};

export default FabricFinishPDP;
