import { customerPaths } from "app/pages/customer/routes";
import { NavigationItem } from "../../models";

export const navigationItems: NavigationItem[] = [
  {
    label: customerPaths.children.account.label,
    pathname: customerPaths.children.account.path,
    itemClass: "",
  },
  {
    label: customerPaths.children.account.children.info.label,
    pathname: customerPaths.children.account.children.info.path,
    itemClass: "",
  },
  {
    label: customerPaths.children.account.children.addresses.label,
    pathname: customerPaths.children.account.children.addresses.path,
    itemClass: "",
  },
  {
    label: customerPaths.children.account.children.newsletter.label,
    pathname: customerPaths.children.account.children.newsletter.path,
    itemClass: "account-newsletter-item",
  },
  {
    label: customerPaths.children.account.children.quote.label,
    pathname: "/quote",
    itemClass: "",
  },
  {
    label: customerPaths.children.account.children.orders.label,
    pathname: customerPaths.children.account.children.orders.path,
    itemClass: "",
  },
  // {
  //   label: customerPaths.children.account.children.claims.label,
  //   pathname: customerPaths.children.account.children.claims.path,
  //   itemClass: "quote-history",
  // },
  {
    label: customerPaths.children.account.children.projects.label,
    pathname: "/customer/collections/lists",
    itemClass: "lists",
  },
];
