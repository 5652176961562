// @ts-check
// @ts-ignore
import { JolaViewer as Player } from "@jola_interactive/jola_viewer";
import { useBoolean } from "ahooks";
import Icon from "app/assets/icon/icon";
import {
  // CustomerToken,
  CustomizableCheckboxOption,
  CustomizableOptionInterface,
  DomainProductFragment,
  useAddItemsToWishlistsMutation,
  useAddNewWishlistMutation,
  useWishlistsQuery,
  // WishlistsDocument,
  // WishlistsQuery,
  Parameters,
} from "app/generated/graphql";
import Breadcrumbs from "app/layout/breadcrumbs";
import NameFormModal, {
  buildNameSchema,
  buildNameValues,
} from "app/layout/NameFormModal";
import { savedBuildsName } from "app/pages/collections/config";
import { getSavedBuilds, isWishlistDefined } from "app/pages/collections/utils";
import Login from "app/pages/customer/login";
import { customerPaths } from "app/pages/customer/routes";
import { selectCustomerToken } from "app/state/redux/data/customer/selectors";
// import { savedBuildsUpload } from "app/utils/upload";
// import { formatFileName } from "app/utils/utils";
import ModalContext, {
  ModalConsumer,
} from "core/components/modal/ModalContext";
import Price from "core/components/price";
import { messagesActions } from "core/state/redux/data/messages";
import { useFormik } from "formik";
import { useToggleFavorite, useWishlists } from "app/pages/collections/hooks";
import {
  MDBBox,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBLink,
  MDBRow,
  MDBTooltip,
  MDBTypography,
} from "mdbreact";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import DownloadTearsheet from "./downloadTearsheet";
import { DownloadImage, mapOption } from "./utils";
import themeConfiguration from "config/themeConfiguration";
import { gql } from "graphql.macro";
import { useMutation } from "react-apollo-hooks";
import { CustomPriceContext } from "../customPriceContext";
import { CheckBoxContext } from "../checkboxContext";
import { favorites } from "app/pages/collections/components/Wishlist/mock";
import CollectionsContext from "app/layout/collections/CollectionsContext";

type CustomizationOptionsType = {
  id: number;
  value_string: string;
};

const UPLOAD_CONFIGURATOR_IMAGE = gql`
  mutation uploadConfiguratorImage($name: String!, $data: String!) {
    uploadConfiguratorImage(
      input: {
        configurator_image: {
          name: $name
          type: "image/png"
          base64_encoded_data: $data
        }
      }
    ) {
      configurator_image
    }
  }
`;

const UPLOAD_QUOTE_CONFIGURATOR_IMAGE = gql`
  mutation uploadQuoteConfiguratorImage($name: String!, $data: String!) {
    uploadQuoteConfiguratorImage(
      input: {
        build_your_own_image: {
          name: $name
          type: "image/png"
          base64_encoded_data: $data
        }
      }
    ) {
      build_your_own_image
    }
  }
`;

const getCustomizationOptionFullInformation = (
  customizationOption: CustomizationOptionsType,
  options: Array<CustomizableOptionInterface>
): CustomizableOptionInterface | undefined => {
  const option = options.find(
    (option) => option.option_id === customizationOption.id
  );

  return option;
};

const generateCustomizationOptions = (
  data: any,
  options: Array<CustomizableOptionInterface>,
  customizationOptions: Array<CustomizationOptionsType>,
  setCustomizationOptions: any,
  selectionsAndSelectors: any,
  sku: string
) => {
  let optionIdImage = 0;
  let optionIdOptions = 0;
  let optionIdUrl = 0;
  let customUrl =
    Object.keys(data).length > 0
      ? window.location.pathname.substr(
          0,
          window.location.pathname.length - 7
        ) +
        sku +
        "?"
      : `${window.location.pathname}?`;

  options.forEach((option) => {
    if (option.title === "Build your own image") {
      optionIdImage = option.option_id || 0;
    } else if (option.title === "Build your own options") {
      optionIdOptions = option.option_id || 0;
    } else if (option.title === "Build your own configurator url") {
      optionIdUrl = option.option_id || 0;
    }
  });
  let tempCustomizationOptions = [];
  if (customizationOptions) {
    for (let option of customizationOptions) {
      if (option.value_string) {
        tempCustomizationOptions.push(option);
      }
    }
  }

  //Ottoman - Removed arm, back, seat
  let ottomanSelections = Object.assign({}, selectionsAndSelectors);
  if (ottomanSelections) {
    delete ottomanSelections?.arm;
    delete ottomanSelections?.back;
    // delete ottomanSelections?.seat;
  }
  let removeCushionSelections = Object.assign({}, selectionsAndSelectors);
  if (removeCushionSelections) {
    delete removeCushionSelections?.seat;
  }
  let tempSelectionsAndSelectors: any;
  if (
    selectionsAndSelectors?.frame?.name !== "Ottoman" &&
    selectionsAndSelectors?.frame?.id === "50"
  ) {
    tempSelectionsAndSelectors = selectionsAndSelectors;
  } else if (selectionsAndSelectors?.frame?.name === "Ottoman") {
    tempSelectionsAndSelectors = ottomanSelections;
  } else if (selectionsAndSelectors?.frame?.id !== "50") {
    tempSelectionsAndSelectors = removeCushionSelections;
  } else {
    tempSelectionsAndSelectors = selectionsAndSelectors;
  }
  if (tempSelectionsAndSelectors === {}) {
    Object.keys(selectionsAndSelectors).forEach((key) => {
      if (key !== "arm" && key !== "back") {
        tempSelectionsAndSelectors[key] = selectionsAndSelectors[key];
      }
    });
  }
  if (Object.keys(data).length > 0) {
    tempCustomizationOptions.push({
      id: optionIdImage,
      value_string: data.data.uploadQuoteConfiguratorImage.build_your_own_image,
    });
  }
  tempCustomizationOptions.push({
    id: optionIdOptions,
    value_string: JSON.stringify(tempSelectionsAndSelectors),
  });
  tempCustomizationOptions.forEach((option: any) => {
    let optionName: string = "";
    const foundOption = options.find((customOption) => {
      return customOption.option_id === option.id;
    });
    optionName = foundOption?.title?.toLowerCase().split(" ").join("_") || "";
    if (optionName.length) {
      customUrl = customUrl + optionName + "=" + option.value_string + "&";
    }
  });
  customUrl = customUrl.substring(0, customUrl.length - 1);
  tempCustomizationOptions.push({
    id: optionIdUrl,
    value_string: customUrl,
  });
  // setCustomizationOptions(tempCustomizationOptions);
  return tempCustomizationOptions;
};

const getLabelForDropdownValues = (
  customizationOptionFullInformation: CustomizableOptionInterface | undefined,
  options: Array<CustomizableCheckboxOption>,
  customizationOption: CustomizationOptionsType
): string | undefined | null => {
  if (
    customizationOptionFullInformation === undefined ||
    options === undefined ||
    options.length === 0
  ) {
    return "";
  }

  const optionWithTitle = options.find(
    (option) => option.title === customizationOptionFullInformation.title
  );

  if (optionWithTitle !== null && optionWithTitle !== undefined) {
    const valueWithId = optionWithTitle?.value?.find(
      (optionValue) =>
        optionValue?.option_type_id?.toString() ===
        customizationOption?.value_string.toString()
    );
    if (valueWithId) {
      return valueWithId.title;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const getCustomizationOptionValues = (
  customizationOption: CustomizationOptionsType,
  options: Array<CustomizableOptionInterface>,
  customizationOptionFullInformation: CustomizableOptionInterface | undefined
): string | undefined | null => {
  if (customizationOption === undefined) {
    return "";
  }

  const customizationOptionParts = customizationOption.value_string
    ?.toString()
    .split("-") || ["null"];
  const customizationOptionFirstPartContainsAtLeastOneLetter = customizationOptionParts[0].match(
    /[A-z]/g
  );

  if (customizationOptionParts.length > 1) {
    // Fabric
    return customizationOption.value_string;
  } else {
    // Finish
    if (
      customizationOptionFirstPartContainsAtLeastOneLetter !== null &&
      customizationOptionFirstPartContainsAtLeastOneLetter.length > 0
    ) {
      return customizationOption.value_string;
    }
    // Dropdown Value
    const optionLabel = getLabelForDropdownValues(
      customizationOptionFullInformation,
      options,
      customizationOption
    );
    return optionLabel;
  }
};
const generateCustomizationOptionsQueryParameters = (
  customizationOptions: Array<CustomizationOptionsType>,
  options: Array<CustomizableOptionInterface>,
  selectionsAndSelectors: Object | undefined,
  collectionName: String
) => {
  if (
    (customizationOptions === undefined ||
      customizationOptions.length === 0 ||
      options === undefined ||
      options.length === 0) &&
    collectionName !== "Monogram"
  ) {
    return "";
  }

  let customizationOptionsQueryParameters = ``;

  const upholsteryPrefix = "upholstery_";
  const pillowUpholsteryPrefix = "pillow_upholstery_";
  const nailHeadPrefix = "nail_head_";
  const additionalOptionsPrefix = "additional_options_";
  const moreOptionsPrefix = "more_options_";
  const buildOverViewPrefix = "build_overview_";
  const finishPrefix = "selected_finish";

  let customizationOptionQueryParameter = "";

  let filteredSelectionsAndSelectors;
  // Build Your Own Custom Player Options, filter functions from array
  if (selectionsAndSelectors !== undefined) {
    filteredSelectionsAndSelectors = Object.values(
      selectionsAndSelectors
    ).filter((selection) => {
      return !(selection instanceof Function);
    });

    filteredSelectionsAndSelectors = Object.values(
      filteredSelectionsAndSelectors
    ).filter((selection) => {
      return selection.hasOwnProperty("id");
    });
    let findItemSpecId;
    findItemSpecId = filteredSelectionsAndSelectors.filter(
      (x) => x.id === "50"
    );

    if (findItemSpecId.length === 0) {
      filteredSelectionsAndSelectors = filteredSelectionsAndSelectors.filter(
        (item) => item.parentName !== "Cushion"
      );
    }
    if (filteredSelectionsAndSelectors.some((e) => e.name === "Ottoman")) {
      filteredSelectionsAndSelectors = filteredSelectionsAndSelectors.filter(
        (item) => item.parentName !== "Back Cushion"
      );
      filteredSelectionsAndSelectors = filteredSelectionsAndSelectors.filter(
        (item) => item.parentName !== "Arm"
      );
      filteredSelectionsAndSelectors = filteredSelectionsAndSelectors.filter(
        (item) => item.parentName !== "Cushion"
      );
    }
    filteredSelectionsAndSelectors = filteredSelectionsAndSelectors.filter(
      (item) => item.parentName !== "Frame"
    );
    filteredSelectionsAndSelectors.forEach((element, index) => {
      // if (index === 0) {
      customizationOptionsQueryParameters = customizationOptionsQueryParameters.concat(
        buildOverViewPrefix,
        element.parentName,
        "=",
        element.name,
        "&"
      );
      // } else if (index === 1) {
      //   customizationOptionsQueryParameters = customizationOptionsQueryParameters.concat(
      //     buildOverViewPrefix,
      //     "Cushion",
      //     "=",
      //     element.name,
      //     "&"
      //   );
      // } else if (index === 2) {
      //   customizationOptionsQueryParameters = customizationOptionsQueryParameters.concat(
      //     buildOverViewPrefix,
      //     "Arm",
      //     "=",
      //     element.name,
      //     "&"
      //   );
      // } else if (index === 3) {
      //   customizationOptionsQueryParameters = customizationOptionsQueryParameters.concat(
      //     buildOverViewPrefix,
      //     "Base",
      //     "=",
      //     element.name,
      //     "&"
      //   );
      // }
    });
  }

  customizationOptions.forEach(
    (customizationOption: CustomizationOptionsType) => {
      customizationOptionQueryParameter = ``;
      const customizationOptionFullInformation = getCustomizationOptionFullInformation(
        customizationOption,
        options
      );

      const customizationOptionCategoryOption =
        customizationOptionFullInformation?.category_options;
      const customizationOptionValue = getCustomizationOptionValues(
        customizationOption,
        options,
        customizationOptionFullInformation
      );

      if (
        customizationOptionValue &&
        customizationOptionFullInformation?.title
      ) {
        switch (customizationOptionCategoryOption) {
          case "Finish":
            customizationOptionQueryParameter = customizationOptionQueryParameter.concat(
              finishPrefix,
              customizationOptionFullInformation?.title,
              "=",
              customizationOptionValue
            );
            break;
          case "Upholstery Options":
            customizationOptionQueryParameter = customizationOptionQueryParameter.concat(
              upholsteryPrefix,
              customizationOptionFullInformation?.title,
              "=",
              customizationOptionValue
            );
            break;
          case "Pillow Upholstery Options":
            customizationOptionQueryParameter = customizationOptionQueryParameter.concat(
              pillowUpholsteryPrefix,
              customizationOptionFullInformation?.title,
              "=",
              customizationOptionValue
            );
            break;
          case "Nailhead Trim Options":
            customizationOptionQueryParameter = customizationOptionQueryParameter.concat(
              nailHeadPrefix,
              customizationOptionFullInformation?.title,
              "=",
              customizationOptionValue
            );
            break;
          case "Additional Options":
            customizationOptionQueryParameter = customizationOptionQueryParameter.concat(
              additionalOptionsPrefix,
              customizationOptionFullInformation?.title,
              "=",
              customizationOptionValue
            );
            break;
          case "View More Options":
            customizationOptionQueryParameter = customizationOptionQueryParameter.concat(
              moreOptionsPrefix,
              customizationOptionFullInformation?.title,
              "=",
              customizationOptionValue
            );
            break;
          default:
            break;
        }
      }
      customizationOptionsQueryParameters = customizationOptionsQueryParameters.concat(
        customizationOptionQueryParameter,
        "&"
      );
    }
  );

  customizationOptionsQueryParameters = customizationOptionsQueryParameters.slice(
    0,
    -1
  );

  return customizationOptionsQueryParameters;
};

declare global {
  interface Window {
    player: any;
  }
}
const Toolbar: React.FC<
  Pick<
    DomainProductFragment,
    "id" | "name" | "sku" | "image" | "price_range"
  > & {
    player: Player;
    addToCart: (cO?: any) => void;
    isAddingToCart: boolean;
    isFavorite: boolean;
    onToggleFavorite: () => void;
    options: Array<CustomizableOptionInterface>;
    fabricPDP: boolean;
    isCustom: boolean;
    setViewMorePdpPrice(): any;
    viewMoreOptions: object;
    setViewMoreOptions(): any;
    customizationOptions: Array<CustomizationOptionsType>;
    setCustomizationOptions: (
      customizationOptions: Array<CustomizationOptionsType>
    ) => void;
    selectionsAndSelectors: any;
    defaults: any;
    isFinish: boolean;
    queryPathFabrics: any;
    attribute_set_name: string;
    collection: any;
  }
> = ({
  player,
  id,
  name,
  sku,
  image,
  price_range,
  addToCart,
  isAddingToCart = false,
  isFavorite,
  onToggleFavorite,
  options,
  fabricPDP,
  isCustom,
  customizationOptions,
  setCustomizationOptions,
  selectionsAndSelectors,
  defaults,
  isFinish = false,
  queryPathFabrics,
  attribute_set_name,
  collection,
}) => {
  // const client = useApolloClient();
  let defaultSku = defaults?.defaultFabric?.sku;
  const wishlists = useWishlists();
  const [uploadConfiguratorImage] = useMutation(UPLOAD_CONFIGURATOR_IMAGE);
  const [uploadQuoteConfiguratorImage] = useMutation(
    UPLOAD_QUOTE_CONFIGURATOR_IMAGE
  );

  const { setSelectedProduct } = React.useContext(CollectionsContext);

  const onToggleFavoriteSaveBuilds = useToggleFavorite(
    favorites,
    //@ts-ignore
    ([product]) => {
      setSelectedProduct(product);
    }
  );
  const customizationOptionsQueryParameters = generateCustomizationOptionsQueryParameters(
    customizationOptions,
    options,
    selectionsAndSelectors,
    collection[0]
  );

  const [removePriceLabel, setRemovePriceLabel] = useState(false); // After some custom option is clicked remove price label
  useEffect(() => {
    if (
      customizationOptions?.some(
        (customizationOptions) =>
          customizationOptions.value_string !== defaultSku
      )
    ) {
      setRemovePriceLabel(true);
    } else {
      setRemovePriceLabel(false);
    }
  }, [customizationOptions]);
  const token = useSelector(selectCustomerToken);
  // @ts-ignore
  let customerToken = useSelector((state) => state?.customer?.data?.token);
  // @ts-ignore

  const isMobile = useMediaQuery({ maxWidth: 992 });

  const { showModal, hideModal } = useContext(ModalContext);
  // @ts-ignore
  const {
    // @ts-ignore
    customizationOptionPrice,
    //@ts-ignore
    upholsteryOptionsFabrics,
    //@ts-ignore
    customer,
    //@ts-ignore
    price,
    //@ts-ignore
    setPrice,
    //@ts-ignore
    dropdownPrice,
    //@ts-ignore
    viewMorePdpPrice,
    //@ts-ignore
    contrastWeltPrice,
    //@ts-ignore
    trimPrice,
    //@ts-ignore
    setTrimPrice,
    //@ts-ignore
    contrastButtonsPrice,
    //@ts-ignore
    decorativeCordPrice,
    //@ts-ignore
    fringePrice,
    //@ts-ignore
    throwPillowContrastWeltPrice,
    //@ts-ignore
    throwPillowDecorativeCordPrice,
    //@ts-ignore
    throwPillowFringePrice,
    //@ts-ignore
    kidneyPillowContrastWeltPrice,
    //@ts-ignore
    kidneyPillowDecorativeCordPrice,
    //@ts-ignore
    kidneyPillowFringePrice,
    //@ts-ignore
    loading,
  } = useContext(CustomPriceContext);
  // @ts-ignore
  const {
    // @ts-ignore
    nailPrice,
  } = useContext(CheckBoxContext);
  const [priceSet, setPriceSet] = useState(false);
  const [startingPrice, setStartingPrice] = useState(0);

  useEffect(() => {
    if (price && price !== 0 && !priceSet) {
      setPriceSet(true);
      setStartingPrice(price);
    }
  }, [price]);

  const handleShowModal = useCallback(() => {
    // @ts-ignore
    showModal(() => {
      return <Login hideModal={hideModal} />;
    });
  }, [showModal, hideModal]);

  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: { name: name ?? buildNameValues.name },
    validationSchema: buildNameSchema,
    onSubmit: (): Promise<void> | void => {
      return saveBuild();
    },
  });
  const [byoMobileToolbar, setByoMobileToolbar] = useState(false);
  useEffect(() => {
    if (isCustom && window.innerWidth < 992) {
      setByoMobileToolbar(true);
    } else {
      setByoMobileToolbar(false);
    }
  }, [window.innerWidth]);

  const [saveProductToBuild] = useAddItemsToWishlistsMutation({
    variables: {
      input: {
        products: [
          {
            sku,
            item_name: form.values.name,
            options: options?.map(mapOption),
          },
        ],
        wishlists: [{ name: savedBuildsName }],
      },
    },
    onError: (error) => {
      dispatch(messagesActions.addMessage(error.message, "error"));
    },
  });

  const [createSavedBuilds] = useAddNewWishlistMutation({
    variables: {
      name: savedBuildsName,
    },
    onError: (error) => {
      dispatch(messagesActions.addMessage(error.message, "error"));
    },
  });

  const wishlistsQuery = useWishlistsQuery({ skip: !customer });
  const savedBuilds = useMemo(
    () =>
      wishlistsQuery.data?.getWishlists
        ? getSavedBuilds(
            wishlistsQuery.data.getWishlists.filter(isWishlistDefined)
          )
        : null,
    [wishlistsQuery]
  );

  const [modalOpen, setModalOpen] = useBoolean();

  async function generate3DImage(): Promise<string | undefined> {
    if (!player) {
      return undefined;
    }
    let initColor = player.scene.background;
    let newColor = "#ffffff"; //change for different color
    player.scene.background.set(newColor);
    if (typeof player.shadowPlane !== "undefined") {
      player.shadowPlane.visible = false;
    }
    if (typeof player.plane !== "undefined") {
      player.plane.visible = false;
    }

    player.update();

    player.scene.background.set(initColor);

    if (typeof player.shadowPlane !== "undefined") {
      player.shadowPlane.visible = true;
    }
    if (typeof player.plane !== "undefined") {
      player.plane.visible = true;
    }

    const rtrVal = await player.container
      .getElementsByTagName("canvas")[0]
      .toDataURL();

    if (rtrVal) {
      return rtrVal;
    } else {
      return undefined;
    }
  }

  const saveProductAndUploadSnapshot = useCallback(() => {
    return generate3DImage().then((data: any) => {
      if (data) {
        data = data.substring(22, data.length);
        uploadQuoteConfiguratorImage({
          variables: {
            name,
            data,
          },
        }).then((data: any) => {
          if (data?.data) {
            let formattedOptions: Array<Parameters> = [];
            generateCustomizationOptions(
              data,
              options,
              customizationOptions,
              setCustomizationOptions,
              selectionsAndSelectors,
              sku as string
            ).forEach((option: any) => {
              formattedOptions.push({
                id: option.id.toString(),
                value_string: option.value_string,
              });
            });
            const imageUrl = formattedOptions.find((option) =>
              // @ts-ignore
              option.value_string.startsWith("media/")
            )?.value_string;

            return saveProductToBuild({
              variables: {
                input: {
                  products: [
                    {
                      sku,
                      item_name: form.values.name,
                      options: formattedOptions,
                    },
                  ],
                  wishlists: [{ name: savedBuildsName }],
                },
              },
            }).then(() => {
              dispatch(
                messagesActions.addMessage(
                  "",
                  "success",
                  undefined,
                  undefined
                  // <div className="d-flex align-items-center">
                  //   <MDBTypography className="mb-0 mr-5 pr-4 fs-16 fwregular text-white">
                  //     Saved for later
                  //   </MDBTypography>
                  //   <Link
                  //     to={
                  //       customerPaths.children.collections.children.savedBuilds
                  //         .path
                  //     }
                  //     className={`pointer fs-14 fwregular underline text-white ${
                  //       isMobile && "mb-4 mt-4"
                  //     }`}
                  //   >
                  //     View Saved Builds
                  //   </Link>
                  // </div>
                )
              );

              setModalOpen.setFalse();
            });
          } else {
            console.error("Something went wrong.");
          }
        });
      }
    });
  }, [
    saveProductToBuild,
    player,
    dispatch,
    savedBuilds,
    sku,
    token,
    form.values.name,
    setModalOpen,
    customizationOptions,
    setCustomizationOptions,
  ]);

  const saveBuild = useCallback(() => {
    if (savedBuilds) {
      return saveProductAndUploadSnapshot();
    } else {
      if (!wishlistsQuery.loading) {
        return createSavedBuilds().then(() => {
          saveProductAndUploadSnapshot();
        });
      } else {
        console.error(
          "Cannot save build since wishlists query has yet to be loaded."
        );
      }
    }
  }, [
    createSavedBuilds,
    savedBuilds,
    saveProductAndUploadSnapshot,
    wishlistsQuery,
  ]);

  const CreateImageLink = (image: any, sku: string) => {
    DownloadImage(image, sku);
    // var a = document.createElement("a");
    // a.href = image;
    // a.rel = "noopener noreferrer";
    // a.target = "_blank";
    // a.download = sku + ".png";
    // a.click();
  };

  const save3DImage = (e: Event, backupImage: any, sku: string) => {
    if (player) {
      let initColor = player.scene.background;
      let newColor = "#ffffff"; //change for different color
      player.scene.background.set(newColor);

      let initPosition = player.getCameraPosition();
      player.setCameraPosition({ x: -30, y: 10, z: 50 });

      player.shadowPlane.visible = false;
      player.plane.visible = false;

      player.update();
      e.stopPropagation();
      new Promise((resolve) => {
        let dataUrl = undefined;
        try {
          dataUrl =
            player.container.getElementsByTagName("canvas")[0].toDataURL() ||
            undefined;
        } catch (e) {
          resolve(null);
        }
        resolve(dataUrl);
      }).then((blob) => {
        if (blob) {
          CreateImageLink(blob, sku);
        }
      });
      player.scene.background.set(initColor);
      player.setCameraPosition(initPosition);
      player.shadowPlane.visible = true;
      player.plane.visible = true;
    } else {
      CreateImageLink(backupImage, sku);
    }
  };

  const handleSave = useCallback(() => {
    if (customer) {
      setModalOpen.setTrue();
    } else {
      // @ts-ignore
      showModal(() => {
        return (
          <Login
            hideModal={(isLoggedIn) => {
              hideModal();
              if (isLoggedIn) {
                setModalOpen.setTrue();
              }
            }}
          />
        );
      });
    }
  }, [customer, showModal, hideModal, setModalOpen]);

  useEffect(() => {
    setPrice(
      customer && price_range?.minimum_price?.final_price?.value
        ? (
            price_range?.minimum_price?.final_price?.value +
            (customizationOptionPrice ? customizationOptionPrice : 0) +
            (viewMorePdpPrice ? viewMorePdpPrice : 0) +
            (nailPrice ? nailPrice : 0) +
            (dropdownPrice ? dropdownPrice : 0) +
            (contrastWeltPrice ? contrastWeltPrice : 0) +
            (trimPrice ? trimPrice : 0) +
            (contrastButtonsPrice ? contrastButtonsPrice : 0) +
            (decorativeCordPrice ? decorativeCordPrice : 0) +
            (fringePrice ? fringePrice : 0) +
            (throwPillowContrastWeltPrice ? throwPillowContrastWeltPrice : 0) +
            (throwPillowDecorativeCordPrice
              ? throwPillowDecorativeCordPrice
              : 0) +
            (throwPillowFringePrice ? throwPillowFringePrice : 0) +
            (kidneyPillowContrastWeltPrice
              ? kidneyPillowContrastWeltPrice
              : 0) +
            (kidneyPillowDecorativeCordPrice
              ? kidneyPillowDecorativeCordPrice
              : 0) +
            (kidneyPillowFringePrice ? kidneyPillowFringePrice : 0)
          ).toFixed(2)
        : false
    );
  }, [
    customizationOptionPrice,
    viewMorePdpPrice,
    nailPrice,
    dropdownPrice,
    contrastWeltPrice,
    trimPrice,
    contrastButtonsPrice,
    fringePrice,
    decorativeCordPrice,
    throwPillowContrastWeltPrice,
    throwPillowDecorativeCordPrice,
    throwPillowFringePrice,
    kidneyPillowContrastWeltPrice,
    kidneyPillowDecorativeCordPrice,
    kidneyPillowFringePrice,
  ]);

  useEffect(() => {
    setPrice(
      customer ? price_range?.minimum_price?.final_price?.value || 0 : false
    );
    // }, [customer, price_range]);
  }, []);

  const formattedName = useMemo(() => {
    return name?.replace(/ *\([^)]*\) */g, "");
  }, [name]);

  let [downloadLoading, setDownloadLoading] = useState(false);
  // @ts-ignore
  let arr = [];
  wishlists.map((wishlist) => {
    return wishlist.items?.filter((wishistitem) => {
      if (wishistitem?.product?.sku === sku) {
        arr.push(wishistitem);
        return wishistitem;
      }
    });
  });
  return (
    <>
      <MDBRow
        className={`${
          (!byoMobileToolbar && "bg-gray-lighten-4") || "transparent"
        } py-3 border-bottom px-lg-5 border-default toolbar-wrapper " 
            ${isCustom && "custom-pdp-toolbar"}`}
      >
        <MDBCol className="px-lg-5">
          <MDBContainer fluid className="px-lg-5">
            <MDBRow>
              <MDBCol size="12">
                <Breadcrumbs wrapperClass={"d-none d-lg-block"} sku={sku} />
              </MDBCol>
              <MDBCol size="12" md="12" lg="4" xl="6">
                <MDBRow>
                  {!byoMobileToolbar && (
                    <MDBCol size="12" className="mb-3">
                      {/* @ts-ignore */}
                      <MDBTypography tag="h1">{formattedName}</MDBTypography>
                    </MDBCol>
                  )}
                  {!isFinish && !byoMobileToolbar && (
                    <MDBCol
                      size="auto"
                      lg="12"
                      className="d-flex align-items-center"
                    >
                      <MDBTypography tag="h2" className="body-2">
                        {sku}
                      </MDBTypography>
                    </MDBCol>
                  )}
                  {isMobile && !isCustom && (
                    <MDBCol
                      size="auto"
                      onClick={() => {
                        let formattedOptions: Array<Parameters> = [];
                        generateCustomizationOptions(
                          {},
                          options,
                          customizationOptions,
                          setCustomizationOptions,
                          selectionsAndSelectors,
                          sku as string
                        ).forEach((option: any) => {
                          formattedOptions.push({
                            id: option.id.toString(),
                            value_string: option.value_string,
                          });
                        });
                        // let addToFavoritesNotLogedIn = JSON.stringify({
                        //   title: "Add to Favorites",
                        //   productName: name,
                        //   productImage: image,
                        //   formattedOptions,
                        // });
                        // window.localStorage.setItem(
                        //   "favorites",
                        //   addToFavoritesNotLogedIn
                        // );
                        onToggleFavorite();
                      }}
                    >
                      <Icon
                        icon={
                          isFavorite || (arr.length > 0 && !isFavorite)
                            ? "heartFull"
                            : "heartGray"
                        }
                      />
                    </MDBCol>
                  )}
                </MDBRow>
              </MDBCol>
              <MDBCol
                size="12"
                md="10"
                lg="8"
                xl="6"
                className="mt-4 mt-lg-0 pt-md-4 pt-lg-0 d-flex flex-column flex-md-row align-items-md-center justify-content-lg-end"
              >
                {price && price > 0 ? (
                  themeConfiguration.magento_url ===
                  "https://fairfieldm2.joladev.com/" ? (
                    <MDBBox
                      tag="div"
                      className={`mb-4 mb-md-0 d-flex align-items-center`}
                    >
                      {(!removePriceLabel && collection[0] !== "Monogram" && (
                        <MDBBox
                          tag="span"
                          className="body-1 starting-wrapper mr-3"
                        >
                          Starting at
                        </MDBBox>
                      )) || <></>}
                      <MDBTypography
                        tag="h2"
                        className="text-default price-pdp"
                      >
                        <Price
                          price={startingPrice as number | undefined}
                          type={"fixed"}
                        />
                      </MDBTypography>
                    </MDBBox>
                  ) : (
                    <MDBBox
                      tag="div"
                      className={`mb-4 mb-md-0 d-flex align-items-center`}
                    >
                      {/* @ts-ignore */}
                      {(!removePriceLabel && collection[0] !== "Monogram" && (
                        <MDBBox
                          tag="span"
                          className="body-1 starting-wrapper mr-3"
                        >
                          Starting at
                        </MDBBox>
                      )) || <></>}
                      <MDBTypography
                        tag="h2"
                        className="text-default price-pdp"
                      >
                        <Price
                          price={price as number | undefined}
                          type={"fixed"}
                        />
                      </MDBTypography>
                    </MDBBox>
                  )
                ) : (
                  ""
                )}
                <div className="ml-md-4 ml-lg-5 pl-lg-3 d-flex align-items-center">
                  {!fabricPDP &&
                    !isFinish &&
                    (customer ? (
                      <>
                        {attribute_set_name !== "CustOptProducts" && (
                          <MDBBtn
                            onClick={() => {
                              if (isCustom) {
                                generate3DImage().then((data) => {
                                  if (data) {
                                    data = data.substring(22, data.length);
                                    uploadQuoteConfiguratorImage({
                                      variables: {
                                        name,
                                        data,
                                      },
                                    }).then((data: any) => {
                                      if (data?.data) {
                                        let cO = generateCustomizationOptions(
                                          data,
                                          options,
                                          customizationOptions,
                                          setCustomizationOptions,
                                          selectionsAndSelectors,
                                          sku as string
                                        );
                                        addToCart(cO);
                                      } else {
                                        console.error("Something went wrong.");
                                      }
                                    });
                                  }
                                });
                              } else {
                                generate3DImage().then(() => {
                                  let tempCustomizationOptions = [];
                                  if (customizationOptions) {
                                    console.log(customizationOptions);
                                    for (let option of customizationOptions) {
                                      if (option.value_string) {
                                        tempCustomizationOptions.push(option);
                                      }
                                    }
                                  }
                                  if (tempCustomizationOptions.length) {
                                    console.log(tempCustomizationOptions);
                                    addToCart(tempCustomizationOptions);
                                  } else {
                                    addToCart();
                                  }
                                });
                              }
                            }}
                            disabled={isAddingToCart || loading}
                            className="m-0 quote-req-btn icon icon-left"
                          >
                            <Icon icon="plusWhite" /> Quote Request
                          </MDBBtn>
                        )}

                        {isCustom && (
                          <div className="ml-4 pl-2">
                            <MDBBtn
                              color="secondary"
                              size="sm"
                              className="m-0 icon icon-right"
                              onClick={handleSave}
                            >
                              <Icon icon="save" />
                            </MDBBtn>
                          </div>
                        )}
                      </>
                    ) : !isMobile ? (
                      <MDBBtn className="m-0" onClick={handleShowModal}>
                        Login or Register
                      </MDBBtn>
                    ) : (
                      <MDBBtn className="m-0 w-100" onClick={handleShowModal}>
                        Login or Register
                      </MDBBtn>
                    ))}
                  {!isMobile && !isCustom && (
                    <MDBBtn
                      className="ml-4 m-0"
                      size="sm"
                      color="secondary"
                      onClick={() => {
                        let formattedOptions: Array<Parameters> = [];
                        generateCustomizationOptions(
                          {},
                          options,
                          customizationOptions,
                          setCustomizationOptions,
                          selectionsAndSelectors,
                          sku as string
                        ).forEach((option: any) => {
                          formattedOptions.push({
                            id: option.id.toString(),
                            value_string: option.value_string,
                          });
                        });
                        // let addToFavoritesNotLogedIn = JSON.stringify({
                        //   title: "Add to Favorites",
                        //   productName: name,
                        //   sku,
                        //   productImage: image,
                        //   formattedOptions,
                        // });
                        // window.localStorage.setItem(
                        //   "favorites",
                        //   addToFavoritesNotLogedIn
                        // );
                        onToggleFavorite();
                      }}
                    >
                      <Icon
                        icon={
                          isFavorite || (arr.length > 0 && !isFavorite)
                            ? "heartFull"
                            : "heartGray"
                        }
                      />
                    </MDBBtn>
                  )}
                </div>
                <div
                  className="tooltip-container
                 mt-3 mt-md-0"
                >
                  {!isMobile ? (
                    <MDBCol
                      size="12"
                      lg="12"
                      className="tooltip-container d-flex align-items-center justify-content-center"
                    >
                      <MDBTooltip
                        className="tooltip-pdp"
                        placement="top"
                        clickable
                      >
                        <MDBBtn className="tooltip-btn btn-default">
                          <Icon icon="downloadGray" />
                        </MDBBtn>
                        <span id={"tooltipSpan"}>
                          <MDBRow className="w-100 m-0 py-2 px-2">
                            <MDBCol
                              size="12"
                              lg="12"
                              className="mb-lg-2 px-lg-0 py-1"
                            >
                              <MDBBox>
                                <MDBTypography
                                  tag="h2"
                                  className="d-flex align-items-center ml-1 link-text-3"
                                >
                                  <ModalConsumer>
                                    {({ showModal }) => (
                                      <>
                                        <Icon
                                          icon="downloadSmall"
                                          className={
                                            downloadLoading ? "loadingIcon" : ""
                                          }
                                        />
                                        <MDBLink
                                          className="d-inline-block ml-1 p-0 link-text-3"
                                          onClick={(event: any) => {
                                            if (token) {
                                              if (!isCustom) {
                                                // @ts-ignore
                                                showModal(() => {
                                                  return (
                                                    <DownloadTearsheet
                                                      productId={id}
                                                      productName={name}
                                                      customizationOptionsQueryParameters={
                                                        customizationOptionsQueryParameters
                                                      }
                                                      // @ts-ignore
                                                      price={price}
                                                    />
                                                  );
                                                });
                                              } else {
                                                setDownloadLoading(true);
                                                generate3DImage().then(
                                                  (data) => {
                                                    if (data) {
                                                      data = data.substring(
                                                        22,
                                                        data.length
                                                      );
                                                      uploadConfiguratorImage({
                                                        variables: {
                                                          name,
                                                          data,
                                                        },
                                                      }).then((data: any) => {
                                                        if (data?.data) {
                                                          let custom =
                                                            customizationOptionsQueryParameters +
                                                            `&configurator_pdf_image=${data.data.uploadConfiguratorImage.configurator_image}`;
                                                          setDownloadLoading(
                                                            false
                                                          );
                                                          // @ts-ignore
                                                          showModal(() => {
                                                            return (
                                                              <DownloadTearsheet
                                                                productId={id}
                                                                productName={
                                                                  name
                                                                }
                                                                customizationOptionsQueryParameters={
                                                                  custom
                                                                }
                                                                price={price}
                                                              />
                                                            );
                                                          });
                                                        } else {
                                                          console.error(
                                                            "Something went wrong."
                                                          );
                                                        }
                                                      });
                                                    } else {
                                                      console.error(
                                                        "Something went wrong."
                                                      );
                                                    }
                                                  }
                                                );
                                              }
                                            } else {
                                              let downloadLink = "";
                                              if (!isCustom) {
                                                downloadLink =
                                                  themeConfiguration.magento_url +
                                                  `productpdf/download/file/1?id=${id}&name=${name}&price_select=hidde&mark_up_value=&custom_price=` +
                                                  `&${customizationOptionsQueryParameters}&customer_token=${customerToken}`;

                                                window.open(
                                                  downloadLink,
                                                  "_blank"
                                                );
                                              } else {
                                                setDownloadLoading(true);
                                                generate3DImage().then(
                                                  (data) => {
                                                    if (data) {
                                                      data = data.substring(
                                                        22,
                                                        data.length
                                                      );
                                                      uploadConfiguratorImage({
                                                        variables: {
                                                          name,
                                                          data,
                                                        },
                                                      }).then((data: any) => {
                                                        if (data?.data) {
                                                          setDownloadLoading(
                                                            false
                                                          );
                                                          downloadLink =
                                                            themeConfiguration.magento_url +
                                                            `productpdf/download/file/1?id=${id}&name=${name}&price_select=hidde&mark_up_value=&custom_price=` +
                                                            `&${customizationOptionsQueryParameters}` +
                                                            `&configurator_pdf_image=${data.data.uploadConfiguratorImage.configurator_image}&customer_token=${customerToken}`;

                                                          window.open(
                                                            downloadLink,
                                                            "_blank"
                                                          );
                                                        } else {
                                                          console.error(
                                                            "Something went wrong."
                                                          );
                                                        }
                                                      });
                                                    } else {
                                                      console.error(
                                                        "Something went wrong."
                                                      );
                                                    }
                                                  }
                                                );
                                              }
                                            }
                                            event.preventDefault();
                                          }}
                                        >
                                          Tearsheet
                                        </MDBLink>
                                      </>
                                    )}
                                  </ModalConsumer>
                                </MDBTypography>
                              </MDBBox>
                            </MDBCol>
                            <MDBCol size="6" lg="12" className="px-lg-0 py-1 ">
                              <MDBBox>
                                <MDBTypography
                                  tag="h2"
                                  className="ml-1 d-flex align-items-center link-text-3"
                                >
                                  <Icon icon="downloadSmall" />
                                  <MDBLink
                                    className="d-inline-block ml-1 p-0 link-text-3"
                                    onClick={(event: any) => {
                                      if (image?.url) {
                                        // @ts-ignore
                                        save3DImage(event, image.url, sku);
                                        event.preventDefault();
                                      }
                                    }}
                                  >
                                    Image
                                  </MDBLink>
                                </MDBTypography>
                              </MDBBox>
                            </MDBCol>
                          </MDBRow>
                        </span>
                      </MDBTooltip>
                    </MDBCol>
                  ) : (
                    <MDBRow className="w-100 d-flex justify-content-between m-0 px-md-4">
                      <MDBBox className="mb-lg-2 d-flex px-0 py-1 ">
                        <ModalConsumer>
                          {({ showModal }) => (
                            <>
                              <Icon icon="downloadSmall" />
                              <MDBLink
                                className="d-inline-block ml-1 p-0 link-text-3"
                                onClick={(event: any) => {
                                  if (token) {
                                    if (!isCustom) {
                                      // @ts-ignore
                                      showModal(() => {
                                        return (
                                          <DownloadTearsheet
                                            productId={id}
                                            productName={name}
                                            customizationOptionsQueryParameters={
                                              customizationOptionsQueryParameters
                                            }
                                            price={price}
                                          />
                                        );
                                      });
                                    } else {
                                      setDownloadLoading(true);
                                      generate3DImage().then((data) => {
                                        if (data) {
                                          data = data.substring(
                                            22,
                                            data.length
                                          );
                                          uploadConfiguratorImage({
                                            variables: {
                                              name,
                                              data,
                                            },
                                          }).then((data: any) => {
                                            if (data?.data) {
                                              let custom =
                                                customizationOptionsQueryParameters +
                                                `&configurator_pdf_image=${data.data.uploadConfiguratorImage.configurator_image}`;
                                              setDownloadLoading(false);
                                              // @ts-ignore
                                              showModal(() => {
                                                return (
                                                  <DownloadTearsheet
                                                    productId={id}
                                                    productName={name}
                                                    customizationOptionsQueryParameters={
                                                      custom
                                                    }
                                                    price={price}
                                                  />
                                                );
                                              });
                                            } else {
                                              console.error(
                                                "Something went wrong."
                                              );
                                            }
                                          });
                                        } else {
                                          console.error(
                                            "Something went wrong."
                                          );
                                        }
                                      });
                                    }
                                  } else {
                                    let downloadLink = "";
                                    if (!isCustom) {
                                      downloadLink =
                                        themeConfiguration.magento_url +
                                        `productpdf/download/file/1?id=${id}&name=${name}&price_select=hidde&mark_up_value=&custom_price=` +
                                        `&${customizationOptionsQueryParameters}&customer_token=${customerToken}`;

                                      window.open(downloadLink, "_blank");
                                    } else {
                                      setDownloadLoading(true);
                                      generate3DImage().then((data) => {
                                        if (data) {
                                          data = data.substring(
                                            22,
                                            data.length
                                          );
                                          uploadConfiguratorImage({
                                            variables: {
                                              name,
                                              data,
                                            },
                                          }).then((data: any) => {
                                            if (data?.data) {
                                              setDownloadLoading(false);
                                              downloadLink =
                                                themeConfiguration.magento_url +
                                                `productpdf/download/file/1?id=${id}&name=${name}&price_select=hidde&mark_up_value=&custom_price=` +
                                                `&${customizationOptionsQueryParameters}` +
                                                `&configurator_pdf_image=${data.data.uploadConfiguratorImage.configurator_image}&customer_token=${customerToken}`;

                                              window.open(
                                                downloadLink,
                                                "_blank"
                                              );
                                            } else {
                                              console.error(
                                                "Something went wrong."
                                              );
                                            }
                                          });
                                        } else {
                                          console.error(
                                            "Something went wrong."
                                          );
                                        }
                                      });
                                    }
                                  }
                                }}
                              >
                                Tearsheet
                              </MDBLink>
                            </>
                          )}
                        </ModalConsumer>
                      </MDBBox>
                      <MDBBox className="ml-4 px-0 d-flex py-1 justify-content-right">
                        <Icon icon="downloadSmall" />
                        <MDBLink
                          className="d-inline-block ml-1 p-0 link-text-3"
                          onClick={(event: any) => {
                            if (image?.url) {
                              DownloadImage(image.url);
                            }
                            event.preventDefault();
                          }}
                        >
                          Image
                        </MDBLink>
                      </MDBBox>
                    </MDBRow>
                  )}
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
      <NameFormModal
        isOpen={modalOpen}
        onClose={setModalOpen.setFalse}
        form={form}
        title="Save for later"
      />
    </>
  );
};

export default React.memo(Toolbar);
