import React, { useState } from "react";
import MenuItem from "../menuItem";
import { MDBBtn } from "mdbreact";
const DropdownItems = ({ items, isHovered, hoveredItem }) => {
  // const [showMore, setShowMore] = useState(false);

  return (
    <div
      className={`dropdown-items ${isHovered ? "hovered" : ""} ${
        !hoveredItem ? "hoveredFirstLevel" : ""
      }`}
    >
      {items?.map((item, index) => (
        <MenuItem item={item} key={`${item.name}-${index}`} />
      ))}
      {/* {items?.slice(0, showMore ? items.length : 10).map((item, index) => (
        <MenuItem item={item} key={`${item.name}-${index}`} />
      ))}
      {!showMore && items?.length > 10 && !!items.include_in_menu && (
        <div className="d-flex justify-content-center">
          <MDBBtn className="btn" onClick={() => setShowMore(true)}>
            Show more
          </MDBBtn>
        </div>
      )} */}
    </div>
  );
};

export default DropdownItems;
