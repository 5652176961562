import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import { cacheActions } from "core/state/redux/data/cache";
import JolaCache from "./jolaCache";
import { isEqual } from "lodash";

const CacheReduxContainer = memo(props => {
  useEffect(() => {
    if(props.flag === "ready"){
      props.getCacheVersion(props.cacheVersion, props.flag);
    }
  }, [props.flag, props.cacheVersion])


  return <JolaCache {...props} />;
}, (prevProps, nextProps) => {
  if(isEqual(prevProps, nextProps)){
    return true;
  }

  return false;
});

const mapStateToProps = state => ({
  cacheVersion: state.cache.cache,
  flag: state.cache.flag
});

const mapDispatchToProps = dispatch => {
  return {
    getCacheVersion: (version, flag) => {
      dispatch(cacheActions.getCacheVersion(version, flag));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CacheReduxContainer);
