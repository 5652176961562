import React from "react";
import Loader from "react-loader-spinner";
import themeConfiguration from "config/themeConfiguration";
import "./loader.scss";

const ReactLoader = (props) => {
  return (
    <div
      className={
        "loading-container d-flex justify-content-center align-items-center h-100 " +
        props.className
      }
    >
      <Loader
        visible={typeof props.visible !== "undefined" ? props.visible : true}
        type={
          typeof props.type === "undefined"
            ? themeConfiguration.loader.type
            : props.type
        }
        color={
          typeof props.color !== "undefined"
            ? props.color
            : themeConfiguration.loader.color
        }
        secondaryColor={
          typeof props.secondaryColor !== "undefined"
            ? props.secondaryColor
            : themeConfiguration.loader.color
        }
        height={
          typeof props.height === "undefined"
            ? themeConfiguration.loader.height
            : props.height
        }
        width={
          typeof props.width === "undefined"
            ? themeConfiguration.loader.width
            : props.width
        }
      />
    </div>
  );
};

export default ReactLoader;
