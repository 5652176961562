import { FormikProps } from "formik";
import { MDBBtn, MDBInput, MDBModal, MDBModalBody } from "mdbreact";
import React from "react";
import { DeepRequired } from "utility-types";
import FieldLength from "../FieldLength";
import ModalHeader from "../ModalHeader";
import { BuildNameLength } from "./config";

export interface NameFormModalProps
  extends DeepRequired<
    Pick<React.ComponentProps<typeof MDBModal>, "isOpen" | "onClose">
  > {
  form: FormikProps<{ name: string }>;
  title: string;
}

const NameFormModal: React.FC<NameFormModalProps> = (props) => {
  return (
    <MDBModal
      isOpen={props.isOpen}
      toggle={props.onClose}
      inline={false}
      overflowScroll
      noClickableBodyWithoutBackdrop={false}
      centered
      className="wide-modal save-builds-modal"
    >
      <MDBModalBody className="p-0">
        <ModalHeader onClose={props.onClose}>{props.title}</ModalHeader>
        <form className="mt-5 mx-5 px-sm-5 mb-5 py-5 d-flex align-items-center">
          <div className="position-relative">
            <div className="position-relative">
              <MDBInput
                {...props.form.getFieldProps("name")}
                required
                label="Build Name"
                size="lg"
                hint="Build Name"
                labelClass="label-4"
              />
              <FieldLength
                length={props.form.values.name.length}
                maxLength={BuildNameLength.max}
              />
            </div>
            <div className="position-absolute text-red">
              {props.form.touched.name && props.form.errors.name}
            </div>
          </div>
          <div className="ml-4 pl-2">
            <MDBBtn
              onClick={props.form.submitForm}
              disabled={props.form.isSubmitting}
              className="m-0"
              size="sm"
            >
              Save
            </MDBBtn>
          </div>
        </form>
      </MDBModalBody>
    </MDBModal>
  );
};

export default NameFormModal;
