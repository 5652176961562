/**
 * Here we map initial state for each Redux state (if necessary)
 * The function name is mappped in'persistedState.js', where the key is the name of the reducer
 * This can be readjusted to possibly use one function to try and find the initial state
 */

import { testInitialState } from "./data/test";
import { searchInitalState } from "./data/search";
import { isEmpty } from "core/helpers/functions";

export const getTestState = (storeState) => {
  let testState = {};
  if (!isEmpty(storeState) && !isEmpty(storeState.test)) {
    testState = storeState.test;
  } else {
    testState = testInitialState;
  }
  return testState;
};

export const getSearchState = (storeState) => {
  let searchState = {};
  if (!isEmpty(storeState) && !isEmpty(storeState.searchPage)) {
    searchState = storeState.searchPage;
  } else {
    searchState = searchInitalState;
  }
  return searchState;
};
