import React from "react";
import { isUndefined } from "core/helpers/functions";

const FormatDate = (props) => {
  let { date, type } = props;
  let formattedDate;
  if (isUndefined(type)) {
    type = "short";
  }
  if (type === "short") {
    var dateParts = date.substring(0, 10).split("-");
    formattedDate = dateParts[1] + "/" + dateParts[2] + "/" + dateParts[0];
  }
  return <>{formattedDate}</>;
};

export default FormatDate;
