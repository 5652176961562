import apolloClient from "core/graphql/apolloClient";
import customerActions from "../actions";
import commonActions from "../../common/actions";
import queryLoader from "core/graphql/queryLoader";
import messagesActions from "../../messages/actions";
import queryActions from "../../queries/actions";

export default async (store, action) => {
  try {
    let cart_id = store.getState().customer.data.cartToken;

    const { data } = await apolloClient.mutate({
      mutation: queryLoader("removeProduct"),
      variables: {
        cart_item_id: action.item_id,
        cart_id
      }
    });
    if (data.removeItemFromCart !== null) {
      store.dispatch(
        customerActions.setCartInformation(data.removeItemFromCart.cart)
      );
      store.dispatch(
        messagesActions.addMessage(
          "successfully removed item from cart",
          "success"
        )
      );
    }
  } catch (error) {
    if (error.message.includes("Could not find a cart with ID")) {
      store.dispatch(customerActions.createEmptyCart());
      store.dispatch(customerActions.removeProductFromCart(action.item_id));
    } else {
      store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    }
    store.dispatch(commonActions.unlock());
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
