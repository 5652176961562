export function isQueryCached(props) {
  let isCached = false;
  if (
    typeof props.queries !== "undefined" &&
    typeof props.queries[props.variables.url] !== "undefined"
  )
    isCached = props.queries[props.variables.url];

  return isCached;
}

export function isQueryDataCached(props) {
  let data = [];
  if (
    typeof props.data !== "undefined" &&
    typeof props.variables !== "undefined" &&
    typeof props.data[props.variables.id] !== "undefined" &&
    typeof props.variables.identifier === "undefined"
  ) {
    data = props.data[props.variables.id].data;
  } else if (
    typeof props.data !== "undefined" &&
    typeof props.variables !== "undefined" &&
    typeof props.variables.identifier !== "undefined" &&
    typeof props.data[props.variables.identifier + "-" + props.variables.id] !==
      "undefined"
  ) {
    data =
      props.data[props.variables.identifier + "-" + props.variables.id].data;
  }
  return data;
}

export function isQueryInTemp(props) {
  let data = [];
  if (
    typeof props.temp !== "undefined" &&
    typeof props.variables !== "undefined" &&
    typeof props.temp[props.variables.id] !== "undefined" &&
    typeof props.variables.identifier === "undefined"
  ) {
    data = props.temp[props.variables.id].data;
  } else if (
    typeof props.temp !== "undefined" &&
    typeof props.variables !== "undefined" &&
    typeof props.variables.identifier !== "undefined" &&
    typeof props.temp[props.variables.identifier + "-" + props.variables.id] !==
      "undefined"
  ) {
    data =
      props.temp[props.variables.identifier + "-" + props.variables.id].data;
  }
  return data;
}
