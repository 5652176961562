import JolaViewer from "@jola_interactive/jola_viewer";
import {
  TextureLoader,
  PlaneBufferGeometry,
  MeshBasicMaterial,
  ShadowMaterial,
  RepeatWrapping,
  Mesh,
  Vector3,
  Box3,
  LineBasicMaterial,
  Color,
  Group,
  BufferGeometry,
  Line,
  FontLoader,
  TextGeometry,
  sRGBEncoding,
  Fog,
  Euler,
} from "three";
import themeConfiguration from "../../../config/themeConfiguration";

export const UrbanModels = [
  "back-B-01.gltf",
  "back-B-26.gltf",
  "back-B-27.gltf",
  "back-B-29-C.gltf",
  "back-B-29-H.gltf",
  "back-B-29.gltf",
  "back-B-50-C.gltf",
  "back-B-50-H.gltf",
  "back-B-50.gltf",
  "back-B-51-C.gltf",
  "back-B-51-H.gltf",
  "back-B-51.gltf",
  "back-B-52-C.gltf",
  "back-B-52-H.gltf",
  "back-B-52.gltf",
  "back-B-53-C.gltf",
  "back-B-53-H.gltf",
  "back-B-53.gltf",
  "back-B-54-C.gltf",
  "back-B-54-H.gltf",
  "back-B-54.gltf",
  "back-B-60-C.gltf",
  "back-B-60-H.gltf",
  "back-B-60.gltf",
  "back-B-75.gltf",
  "back-B-76.gltf",
  "back-B-78.gltf",
  "back-M-01.gltf",
  "back-M-26.gltf",
  "back-M-27.gltf",
  "back-M-29.gltf",
  "back-M-50.gltf",
  "back-M-51.gltf",
  "back-M-52.gltf",
  "back-M-53.gltf",
  "back-M-54.gltf",
  "back-M-60.gltf",
  "back-M-75.gltf",
  "back-M-76.gltf",
  "back-M-78.gltf",
  "cushion-left.gltf",
  "cushion-right.gltf",
  "frame-01-B-P.gltf",
  "frame-01-B.gltf",
  "frame-01-C-P.gltf",
  "frame-01-C.gltf",
  "frame-01-H-P.gltf",
  "frame-01-H.gltf",
  "frame-01-S-P.gltf",
  "frame-01-S.gltf",
  "frame-20-P.gltf",
  "frame-20.gltf",
  "frame-26-B-P.gltf",
  "frame-26-B.gltf",
  "frame-26-C-P.gltf",
  "frame-26-C.gltf",
  "frame-26-H-P.gltf",
  "frame-26-H.gltf",
  "frame-26-S-P.gltf",
  "frame-26-S.gltf",
  "frame-27-B-P.gltf",
  "frame-27-B.gltf",
  "frame-27-C-P.gltf",
  "frame-27-C.gltf",
  "frame-27-H-P.gltf",
  "frame-27-H.gltf",
  "frame-27-S-P.gltf",
  "frame-27-S.gltf",
  "frame-29-B-P.gltf",
  "frame-29-B.gltf",
  "frame-29-C-P.gltf",
  "frame-29-C.gltf",
  "frame-29-H-P.gltf",
  "frame-29-H.gltf",
  "frame-29-S-P.gltf",
  "frame-29-S.gltf",
  "frame-50-B-P.gltf",
  "frame-50-B.gltf",
  "frame-50-C-P.gltf",
  "frame-50-C.gltf",
  "frame-50-H-P.gltf",
  "frame-50-H.gltf",
  "frame-50-S-P.gltf",
  "frame-50-S.gltf",
  "frame-51-B-P.gltf",
  "frame-51-B.gltf",
  "frame-51-C-P.gltf",
  "frame-51-C.gltf",
  "frame-51-H-P.gltf",
  "frame-51-H.gltf",
  "frame-51-S-P.gltf",
  "frame-51-S.gltf",
  "frame-52-B-P.gltf",
  "frame-52-B.gltf",
  "frame-52-C-P.gltf",
  "frame-52-C.gltf",
  "frame-52-H-P.gltf",
  "frame-52-H.gltf",
  "frame-52-S-P.gltf",
  "frame-52-S.gltf",
  "frame-53-B-P.gltf",
  "frame-53-B.gltf",
  "frame-53-C-P.gltf",
  "frame-53-C.gltf",
  "frame-53-H-P.gltf",
  "frame-53-H.gltf",
  "frame-53-S-P.gltf",
  "frame-53-S.gltf",
  "frame-54-B-P.gltf",
  "frame-54-B.gltf",
  "frame-54-C-P.gltf",
  "frame-54-C.gltf",
  "frame-54-H-P.gltf",
  "frame-54-H.gltf",
  "frame-54-S-P.gltf",
  "frame-54-S.gltf",
  "frame-60-B-P.gltf",
  "frame-60-B.gltf",
  "frame-60-C-P.gltf",
  "frame-60-C.gltf",
  "frame-60-H-P.gltf",
  "frame-60-H.gltf",
  "frame-60-S-P.gltf",
  "frame-60-S.gltf",
  "frame-75-B-P.gltf",
  "frame-75-B.gltf",
  "frame-75-C-P.gltf",
  "frame-75-C.gltf",
  "frame-75-H-P.gltf",
  "frame-75-H.gltf",
  "frame-75-S-P.gltf",
  "frame-75-S.gltf",
  "frame-76-B-P.gltf",
  "frame-76-B.gltf",
  "frame-76-C-P.gltf",
  "frame-76-C.gltf",
  "frame-76-H-P.gltf",
  "frame-76-H.gltf",
  "frame-76-S-P.gltf",
  "frame-76-S.gltf",
  "frame-78-B-P.gltf",
  "frame-78-B.gltf",
  "frame-78-C-P.gltf",
  "frame-78-C.gltf",
  "frame-78-H-P.gltf",
  "frame-78-H.gltf",
  "frame-78-S-P.gltf",
  "frame-78-S.gltf",
  "leg-A.gltf",
  "leg-M.gltf",
  "leg-P.gltf",
  "leg-W.gltf",
  "seat-01.gltf",
  "seat-20.gltf",
  "seat-26.gltf",
  "seat-27.gltf",
  "seat-29-C.gltf",
  "seat-29-H.gltf",
  "seat-29.gltf",
  "seat-50-BENCH.gltf",
  "seat-50-C.gltf",
  "seat-50-H.gltf",
  "seat-50.gltf",
  "seat-51-C.gltf",
  "seat-51-H.gltf",
  "seat-51.gltf",
  "seat-52-C.gltf",
  "seat-52-H.gltf",
  "seat-52.gltf",
  "seat-53-C.gltf",
  "seat-53-H.gltf",
  "seat-53.gltf",
  "seat-54-C.gltf",
  "seat-54-H.gltf",
  "seat-54.gltf",
  "seat-60-BENCH-C.gltf",
  "seat-60-BENCH-H.gltf",
  "seat-60-BENCH.gltf",
  "seat-60-C.gltf",
  "seat-60-H.gltf",
  "seat-60.gltf",
  "seat-75.gltf",
  "seat-76.gltf",
  "seat-78.gltf",
  "side-B-26-P.gltf",
  "side-B-26.gltf",
  "side-B-27-P.gltf",
  "side-B-27.gltf",
  "side-B-75-P.gltf",
  "side-B-75.gltf",
  "side-B-76-P.gltf",
  "side-B-76.gltf",
  "side-B-left-P.gltf",
  "side-B-left.gltf",
  "side-B-right-P.gltf",
  "side-B-right.gltf",
  "side-C-26-P.gltf",
  "side-C-26.gltf",
  "side-C-27-P.gltf",
  "side-C-27.gltf",
  "side-C-75-P.gltf",
  "side-C-75.gltf",
  "side-C-76-P.gltf",
  "side-C-76.gltf",
  "side-C-left-P.gltf",
  "side-C-left.gltf",
  "side-C-right-P.gltf",
  "side-C-right.gltf",
  "side-H-26-P.gltf",
  "side-H-26.gltf",
  "side-H-27-P.gltf",
  "side-H-27.gltf",
  "side-H-75-P.gltf",
  "side-H-75.gltf",
  "side-H-76-P.gltf",
  "side-H-76.gltf",
  "side-H-left-P.gltf",
  "side-H-left.gltf",
  "side-H-right-P.gltf",
  "side-H-right.gltf",
  "side-S-26-P.gltf",
  "side-S-26.gltf",
  "side-S-27-P.gltf",
  "side-S-27.gltf",
  "side-S-75-P.gltf",
  "side-S-75.gltf",
  "side-S-76-P.gltf",
  "side-S-76.gltf",
  "side-S-left-P.gltf",
  "side-S-left.gltf",
  "side-S-right-P.gltf",
  "side-S-right.gltf",
];

export const zoomOptions = {
  min: -3,
  current: 0,
  max: 8,
};

export class Player extends JolaViewer {
  constructor(containerID, options) {
    super(containerID, options);
    this.collectionPath = options.collectionPath
      ? options.collectionPath
      : "urban-living";
    this.hdrPath = `${themeConfiguration.model_url}/3d/${this.collectionPath}/webgl/hdr/hdr.hdr`;
    this.loadHDR(this.hdrPath, 0.2);

    this.scene.background = new Color("#fbfbfb");
    this.mainFabricCounter = 0;
    this.init = false;
    this.model = new Group();
    this.model.name = "model";
    this.modelFrame = new Group();
    this.modelArm = new Group();
    this.modelSeat = new Group();
    this.modelBack = new Group();
    this.modelBase = new Group();
    this.modelCushion = new Group();
    this.modelDimensions = new Group();

    this.forcedChange = false;

    this.framePin = "small_pins";
    this.sidePin = "side_pins";

    this.materials = [];
    this.ignoredMaterials = ["AcrylicMAT"];

    this.textureLoader = new TextureLoader();

    this.frameObject = {
      id: "01",
      name: "Chair",
      image: `${themeConfiguration.model_url}/3d/urban-living/images/chair.jpg`,
    };
    this.armObject = {
      id: "B",
      name: "Border Track Arm",
      image: `${themeConfiguration.model_url}/3d/urban-living/images/side-B.jpg`,
      type: "normal",
    };
    this.backObject = {
      id: "B",
      name: "Boxed",
      image: `${themeConfiguration.model_url}/3d/urban-living/images/back-B.jpg`,
    };
    this.seatObject = {
      id: "1",
    };
    this.baseObject = {
      id: "A",
      name: "Leg A",
      image: `${themeConfiguration.model_url}/3d/urban-living/images/leg-J.jpg`,
      type: "leg",
    };
    this.pinsObject = {};

    this.modelsPath = `${themeConfiguration.model_url}/3d/${this.collectionPath}/webgl/models/`;
    this.cushionEnabled = true;
    this.nailsEnabled = false;

    this.productDimensions = {};
  }

  async Init() {
    this.model.add(this.modelFrame);
    this.model.add(this.modelArm);
    this.model.add(this.modelSeat);
    this.model.add(this.modelBack);
    this.model.add(this.modelBase);
    this.model.add(this.modelCushion);
    this.model.add(this.modelDimensions);

    this.scene.add(this.model);

    await this.SetFloor();

    await this.LoadFrame(this.frameObject, true);
  }

  async SetFloor() {
    let parquetMap = await this.LoadTexture(
      `${themeConfiguration.model_url}/3d/textures/floor/grid.jpg`
    );
    parquetMap.repeat.set(500, 500);
    parquetMap.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    parquetMap.wrapT = RepeatWrapping;
    parquetMap.wrapS = RepeatWrapping;

    let geo = new PlaneBufferGeometry(100, 100);
    let mat = new MeshBasicMaterial({
      map: parquetMap,
    });

    this.plane = new Mesh(geo, mat);
    //plane.visible = false;
    this.plane.receiveShadow = true;
    this.plane.position.set(0, 0, 0);
    this.plane.rotation.set(Math.PI / -2, 0, 0);

    this.scene.fog = new Fog(0xfbfbfb, 10, 20);
    this.scene.add(this.plane);

    let shadowMat = new ShadowMaterial({ opacity: 0.2 });
    this.shadowPlane = new Mesh(geo, shadowMat);
    this.shadowPlane.receiveShadow = true;
    this.shadowPlane.position.set(0, 0, 0);
    this.shadowPlane.rotation.set(Math.PI / -2, 0, 0);
    this.scene.add(this.shadowPlane);
  }

  async LoadFloor(map, repeat) {
    let newMap = await this.LoadTexture(map);
    newMap.repeat.set(repeat, repeat);
    newMap.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    newMap.wrapS = RepeatWrapping;
    newMap.wrapT = RepeatWrapping;

    this.plane.material.map = newMap;
  }

  LoadObject(url) {
    return new Promise((resolve, reject) => {
      if (url) {
        this.loader.load(url, (result) => {
          this.updateMap(result.scene);
          resolve(result);
        });
      } else {
        reject();
      }
    });
  }

  async Reset() {
    this.modelFrame.children = [];
    this.modelArm.children = [];
    this.modelBack.children = [];
    this.modelSeat.children = [];
    this.modelBase.children = [];
    this.modelCushion.children = [];
    this.modelDimensions.children = [];
  }

  async LoadAll() {
    await this.Reset();

    await this.LoadFrame(this.frameObject);
    await this.LoadArm(this.armObject);
    await this.LoadBack(this.backObject);
    await this.LoadSeat(this.seatObject);
    await this.LoadBase(this.baseObject);
  }

  async LoadFrame(frameobject, updateCameraPosition = false) {
    await this.Reset();
    await this.HideDimensions();

    this.frameObject = frameobject;

    if (Array.isArray(this.frameObject.id)) {
      for (const id of this.frameObject.id) {
        let componentPath;

        let FrameArmBase =
          "frame-" +
          id +
          "-" +
          this.armObject.id +
          "-" +
          this.baseObject.id +
          ".gltf";
        let FrameArm = "frame-" + id + "-" + this.armObject.id + ".gltf";
        let Frame = "frame-" + id + ".gltf";

        if (
          this.armObject &&
          this.baseObject &&
          this.ModelExist(FrameArmBase)
        ) {
          componentPath = this.modelsPath + FrameArmBase;
        } else if (this.armObject && this.ModelExist(FrameArm)) {
          componentPath = this.modelsPath + FrameArm;
        } else {
          componentPath = this.modelsPath + Frame;
        }
        let componentModel = await this.LoadObject(componentPath);

        let component = componentModel.scene;

        if (this.modelFrame.children.length > 0) {
          let frame = this.modelFrame.children[
            this.modelFrame.children.length - 1
          ];

          let componentR = frame.getObjectByName("component_r");

          componentR.getWorldQuaternion(component.quaternion);
          component.updateMatrixWorld();

          let componentL = component.getObjectByName("component_l");

          component.rotateX(-componentL.rotation.x);
          component.rotateY(-componentL.rotation.y);
          component.rotateZ(-componentL.rotation.z);

          component.updateMatrixWorld();

          //Position
          let componentRWP = new Vector3();
          componentR.getWorldPosition(componentRWP);
          let componentLWP = new Vector3();
          componentL.getWorldPosition(componentLWP);

          component.position.add(componentRWP);
          component.position.add(componentLWP.negate());

          component.updateMatrixWorld();

          component.remove(componentL);
        }

        component.name = id;
        this.modelFrame.add(component);
        this.modelFrame.updateMatrixWorld();
      }
    } else {
      if (frameobject.id === "60") {
        if (this.seatObject.id !== "B") {
          this.armObject = {
            id: "S",
            name: "Small Track",
            image: `${themeConfiguration.model_url}/3d/urban-living/images/side-S.jpg`,
            parentName: "Arm",
          };
        }

        this.baseObject = {
          id: "P",
          name: "Plinth Base",
          image: `${themeConfiguration.model_url}/3d/urban-living/images/leg-P.jpg`,
          type: "leg",
          parentName: "Base",
        };
      }

      let framePath;

      let FrameArmBase =
        "frame-" +
        this.frameObject.id +
        "-" +
        this.armObject.id +
        "-" +
        this.baseObject.id +
        ".gltf";
      const FrameArm =
        "frame-" + this.frameObject.id + "-" + this.armObject.id + ".gltf";
      const Frame = "frame-" + this.frameObject.id + ".gltf";
      if (this.armObject && this.baseObject && this.ModelExist(FrameArmBase)) {
        framePath = this.modelsPath + FrameArmBase;
      } else if (this.armObject && this.ModelExist(FrameArm)) {
        framePath = this.modelsPath + FrameArm;
      } else {
        framePath = this.modelsPath + Frame;
      }

      let frameData = await this.LoadObject(framePath);

      frameData.scene.name = this.frameObject.id;
      this.modelFrame.add(frameData.scene);
      this.modelFrame.updateMatrixWorld();
    }

    await this.LoadArm(this.armObject);

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async LoadArm(armobject, updateCameraPosition = false) {
    this.modelArm.children = [];

    if (armobject !== this.armObject) {
      this.armObject = armobject;
      await this.LoadFrame(this.frameObject, updateCameraPosition);
      return;
    }

    for (const frame of this.modelFrame.children) {
      const exclusionFrames = ["26", "27", "75", "76"];

      let leftArmPath;
      let rightArmPath;
      if (exclusionFrames.includes(frame.name)) {
        if (this.baseObject.id === "P") {
          leftArmPath =
            "side-" +
            this.armObject.id +
            "-" +
            frame.name +
            "-" +
            "P" +
            ".gltf";

          rightArmPath =
            "side-" +
            this.armObject.id +
            "-" +
            frame.name +
            "-" +
            "P" +
            ".gltf";
        } else {
          leftArmPath =
            "side-" + this.armObject.id + "-" + frame.name + ".gltf";

          rightArmPath =
            "side-" + this.armObject.id + "-" + frame.name + ".gltf";
        }
      } else if (this.baseObject.id === "P") {
        leftArmPath =
          "side-" + this.armObject.id + "-" + "left" + "-" + "P" + ".gltf";

        rightArmPath =
          "side-" + this.armObject.id + "-" + "right" + "-" + "P" + ".gltf";
      } else {
        leftArmPath = "side-" + this.armObject.id + "-" + "left" + ".gltf";

        rightArmPath = "side-" + this.armObject.id + "-" + "right" + ".gltf";
      }

      if (frame.getObjectByName("side_l")) {
        let leftArm = await this.LoadObject(this.modelsPath + leftArmPath);
        let leftSide = leftArm.scene;

        leftSide.rotation.copy(frame.rotation);
        leftSide.updateMatrixWorld();

        let offsetL = new Vector3();
        leftSide
          .getObjectByName("sockets")
          .getObjectByName("side_r")
          .getWorldPosition(offsetL);

        let frameSocketPositionL = new Vector3();
        frame
          .getObjectByName("sockets")
          .getObjectByName("side_l")
          .getWorldPosition(frameSocketPositionL);

        leftSide.position.copy(frameSocketPositionL);
        leftSide.position.add(offsetL.negate());

        leftSide.updateMatrixWorld();

        leftSide.name = "model-side-l";
        this.modelArm.add(leftSide);
      }

      if (frame.getObjectByName("side_r")) {
        let rightArm = await this.LoadObject(this.modelsPath + rightArmPath);
        let rightSide = rightArm.scene;

        rightSide.rotation.copy(frame.rotation);
        rightSide.updateMatrixWorld();

        let offsetR = new Vector3();
        rightSide
          .getObjectByName("sockets")
          .getObjectByName("side_r")
          .getWorldPosition(offsetR);

        let frameSocketPositionR = new Vector3();
        frame
          .getObjectByName("sockets")
          .getObjectByName("side_r")
          .getWorldPosition(frameSocketPositionR);

        rightSide.position.copy(frameSocketPositionR);
        rightSide.position.add(offsetR.negate());

        rightSide.updateMatrixWorld();

        rightSide.name = "model-side-r";
        this.modelArm.add(rightSide);
      }
    }

    await this.ShowFramePins(
      this.framePin,
      `${themeConfiguration.model_url}/3d/textures/nail_finish/nail_small`,
      "Small"
    );
    await this.ShowSidePins(
      this.sidePin,
      `${themeConfiguration.model_url}/3d/textures/nail_finish/nail_small`,
      "Small"
    );

    if (this?.modelFrame?.children?.[0]?.name !== "20") {
      await this.LoadBack(this.backObject);
    }

    await this.LoadSeat(this.seatObject);
    await this.LoadBase(this.baseObject);
    await this.LoadCushion();

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async LoadBack(backobject, updateCameraPosition = false) {
    this.modelBack.children = [];

    this.backObject = backobject;

    for (const frame of this.modelFrame.children) {
      let backPath;

      if (
        this.ModelExist(
          "back-" +
            this.backObject.id +
            "-" +
            this.armObject.type +
            "-" +
            frame.name +
            "-" +
            this.armObject.id
        )
      ) {
        backPath =
          this.modelsPath +
          "back-" +
          this.backObject.id +
          "-" +
          this.armObject.type +
          "-" +
          frame.name +
          "-" +
          this.armObject.id +
          ".gltf";
      } else if (
        this.ModelExist(
          "back-" +
            this.backObject.id +
            "-" +
            frame.name +
            "-" +
            this.armObject.id +
            ".gltf"
        )
      ) {
        backPath =
          this.modelsPath +
          "back-" +
          this.backObject.id +
          "-" +
          frame.name +
          "-" +
          this.armObject.id +
          ".gltf";
      } else if (
        this.ModelExist(
          "back-" + this.backObject.id + "-" + frame.name + ".gltf"
        )
      ) {
        backPath =
          this.modelsPath +
          "back-" +
          this.backObject.id +
          "-" +
          frame.name +
          ".gltf";
      } else {
        backPath = this.modelsPath + "back-" + this.backObject.id + ".gltf";
      }

      let backLoader = await this.LoadObject(backPath);
      let back = backLoader.scene;

      back.rotation.copy(frame.rotation);
      back.updateMatrixWorld();

      let offset = new Vector3();
      back
        .getObjectByName("sockets")
        .getObjectByName("back_pillow")
        .getWorldPosition(offset);

      let frameSocketPosition = new Vector3();
      frame
        .getObjectByName("sockets")
        .getObjectByName("back_pillow")
        .getWorldPosition(frameSocketPosition);

      back.position.copy(frameSocketPosition);
      back.position.add(offset.negate());

      back.updateMatrixWorld();

      back.name = "model-back";
      this.modelBack.add(back);
    }

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async LoadSeat(seatobject, updateCameraPosition = false) {
    this.modelSeat.children = [];

    this.seatObject = seatobject;

    for (const frame of this.modelFrame.children) {
      let seatPath;

      if (seatobject.type == "bench") {
        if (this.ModelExist("seat-" + frame.name + "-BENCH.gltf")) {
          seatPath = this.modelsPath + "seat-" + frame.name + "-BENCH.gltf";
        }
      } else {
        if (
          this.ModelExist(
            "seat-" + frame.name + "-" + this.armObject.id + ".gltf"
          )
        ) {
          seatPath =
            this.modelsPath +
            "seat-" +
            frame.name +
            "-" +
            this.armObject.id +
            ".gltf";
        } else if (this.ModelExist("seat-" + frame.name + ".gltf")) {
          seatPath = this.modelsPath + "seat-" + frame.name + ".gltf";
        }
      }

      var seatLoader = await this.LoadObject(seatPath);
      let seat = seatLoader.scene;

      frame
        .getObjectByName("sockets")
        .getObjectByName("seat_pillow")
        .getWorldQuaternion(seat.quaternion);
      seat.updateMatrixWorld();

      let offset = new Vector3();
      seat
        .getObjectByName("sockets")
        .getObjectByName("seat_pillow")
        .getWorldPosition(offset);
      let frameSocketPosition = new Vector3();
      frame
        .getObjectByName("sockets")
        .getObjectByName("seat_pillow")
        .getWorldPosition(frameSocketPosition);

      seat.position.copy(frameSocketPosition);
      seat.position.add(offset.negate());

      seat.updateMatrixWorld();

      seat.name = "model-seat";
      this.modelSeat.add(seat);
    }

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async LoadBase(baseobject, updateCameraPosition = false) {
    this.modelBase.children = [];

    if (baseobject.id !== this.baseObject.id) {
      this.baseObject = baseobject;
      await this.LoadFrame(this.frameObject, updateCameraPosition);
      return;
    }

    let basePath;

    if (this.baseObject.type == "leg") {
      basePath = this.modelsPath + "leg-" + this.baseObject.id + ".gltf";

      let legLoader = await this.LoadObject(basePath);
      let leg = legLoader.scene;

      if (this.baseObject.material) {
        leg.traverse((o) => {
          if (o.isMesh) {
            if (o.material.name == "metal") {
              o.material.color = new Color(baseobject.material.color);

              o.material.metalness = baseobject.material.metalness;

              o.material.roughness = baseobject.material.roughness;
            }
          }
        });
      }

      for (const arm of this.modelArm.children) {
        let localScale = arm.scale;
        let sockets = arm.getObjectByName("sockets");
        for (const socket of sockets.children) {
          if (socket.name.startsWith("leg")) {
            let newLeg = leg.clone();

            newLeg.scale.set(localScale.x, localScale.y, localScale.z);

            socket.getWorldQuaternion(newLeg.quaternion);
            newLeg.updateMatrixWorld();

            let offset = newLeg
              .getObjectByName("sockets")
              .getObjectByName("leg_b").position;

            socket.getWorldPosition(newLeg.position);

            newLeg.position.add(offset.negate());
            newLeg.updateMatrixWorld();

            this.modelBase.add(newLeg);
          }
        }
      }
      for (const frame of this.modelFrame.children) {
        let localScale = frame.scale;
        let sockets = frame.getObjectByName("sockets");
        for (const socket of sockets.children) {
          if (socket.name.startsWith("leg")) {
            let newLeg = leg.clone();

            newLeg.scale.set(localScale.x, localScale.y, localScale.z);

            socket.getWorldQuaternion(newLeg.quaternion);
            newLeg.updateMatrixWorld();

            let offset = newLeg
              .getObjectByName("sockets")
              .getObjectByName("leg_b").position;

            socket.getWorldPosition(newLeg.position);

            newLeg.position.add(offset.negate());
            newLeg.updateMatrixWorld();

            this.modelBase.add(newLeg);
          }
        }
      }
    } else if (this.baseObject.type == "skirt") {
      for (const frame of this.modelFrame.children) {
        basePath =
          this.modelsPath +
          "leg-" +
          this.baseObject.id +
          "-" +
          frame.name +
          ".gltf";

        let skirtLoader = await this.LoadObject(basePath);
        let skirt = skirtLoader.scene;

        skirt.rotation.copy(frame.rotation);
        skirt.updateMatrixWorld();

        let offset = new Vector3();
        skirt
          .getObjectByName("sockets")
          .getObjectByName("skirt_base")
          .getWorldPosition(offset);

        let frameSkirtSocket = new Vector3();
        frame
          .getObjectByName("sockets")
          .getObjectByName("skirt_base")
          .getWorldPosition(frameSkirtSocket);

        skirt.position.copy(frameSkirtSocket);
        skirt.position.add(offset.negate());

        skirt.updateMatrixWorld();

        skirt.name = "model-skirt";
        this.modelBase.add(skirt);
      }
    }

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async LoadCushion() {
    this.modelCushion.children = [];

    if (!this.cushionEnabled) {
      return;
    }

    for (const frame of this.modelFrame.children) {
      if (frame.getObjectByName("cushion_l")) {
        let cushionPath = this.modelsPath + "cushion-left.gltf";
        let cushionLoader = await this.LoadObject(cushionPath);

        let cushion = cushionLoader.scene;

        frame
          .getObjectByName("sockets")
          .getObjectByName("cushion_l")
          .getWorldQuaternion(cushion.quaternion);
        cushion.updateMatrixWorld();

        let offset = new Vector3();
        cushion
          .getObjectByName("sockets")
          .getObjectByName("cushion_base")
          .getWorldPosition(offset);

        let frameSocketPosition = new Vector3();
        frame
          .getObjectByName("sockets")
          .getObjectByName("cushion_l")
          .getWorldPosition(frameSocketPosition);

        cushion.position.copy(frameSocketPosition);
        cushion.position.add(offset.negate());

        cushion.updateMatrixWorld();
        this.modelCushion.add(cushion);
      }

      if (frame.getObjectByName("cushion_r")) {
        let cushionPath = this.modelsPath + "cushion-right.gltf";
        let cushionLoader = await this.LoadObject(cushionPath);

        let cushion = cushionLoader.scene;

        frame
          .getObjectByName("sockets")
          .getObjectByName("cushion_r")
          .getWorldQuaternion(cushion.quaternion);
        cushion.updateMatrixWorld();

        let offset = new Vector3();
        cushion
          .getObjectByName("sockets")
          .getObjectByName("cushion_base")
          .getWorldPosition(offset);

        let frameSocketPosition = new Vector3();
        frame
          .getObjectByName("sockets")
          .getObjectByName("cushion_r")
          .getWorldPosition(frameSocketPosition);

        cushion.position.copy(frameSocketPosition);
        cushion.position.add(offset.negate());

        cushion.updateMatrixWorld();
        this.modelCushion.add(cushion);
      }
    }
  }

  LoadTexture(url) {
    return new Promise((resolve) => {
      this.textureLoader.load(url, (result) => resolve(result));
    });
  }

  async LoadFinish(
    type,
    map,
    icon,
    name,
    repeat = 1,
    roughness,
    metalness,
    updateCameraPosition = false
  ) {
    if (type === "main" && !this.init) {
      this.mainFabricCounter += 1;
    }
    if (type === "main" && this.mainFabricCounter > 1) {
      this.mainFabricCounter = 0;
      this.init = true;
      return;
    }
    let newMap = null;
    if (map) {
      var finishLoader = await this.LoadTexture(map);

      newMap = finishLoader;
      newMap.wrapS = RepeatWrapping;
      newMap.wrapT = RepeatWrapping;
      newMap.repeat.set(repeat, repeat);
    }

    let materialObject = {
      type: type,
      map: newMap,
      icon: icon,
      name: name,
      roughness: roughness || null,
      metalness: metalness || null,
    };

    let found = false;
    this.materials.forEach((material) => {
      if (material.type === type) {
        found = true;

        material.map = newMap;
        material.icon = icon;
        material.name = name;

        material.roughness = roughness || null;
        material.metalness = metalness || null;
      }
    });

    if (!found) {
      this.materials.push(materialObject);
    }

    this.updateMap(this.model);

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  setCushionsVisible(newState) {
    this.cushionEnabled = newState;
    this.LoadCushion();
  }

  setPlaneVisible(newState) {
    if (this.plane) {
      this.plane.visible = newState;
    }
  }

  setNailsVisible(newState) {
    this.nailsEnabled = newState;
    this.ShowFramePins(this.framePin);
    this.ShowSidePins(this.sidePin);
  }

  async setDimensions(productDimensions) {
    this.productDimensions = productDimensions;
  }

  setDimensionsVisible(newState) {
    this.dimensionsToggle = newState;

    this.HideDimensions();

    if (this.dimensionsToggle) {
      this.ShowDimensions();
    }
  }

  async HideDimensions() {
    this.modelDimensions.children = [];
  }

  async ShowDimensions() {
    await this.HideDimensions();

    let box = new Box3().setFromObject(this.model);

    let lineMaterial = new LineBasicMaterial({
      color: 0x000000,
    });

    let lineOffset = 0.02;

    let x = [];
    x.push(
      new Vector3(box.min.x, box.max.y + lineOffset, box.min.z - lineOffset)
    );
    x.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.min.z - lineOffset
      )
    );

    let y = [];
    y.push(
      new Vector3(box.max.x + lineOffset, box.min.y, box.max.z + lineOffset)
    );
    y.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.max.z + lineOffset
      )
    );

    let z = [];
    z.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.max.z + lineOffset
      )
    );
    z.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.min.z - lineOffset
      )
    );

    let geometryX = new BufferGeometry().setFromPoints(x);
    let geometryY = new BufferGeometry().setFromPoints(y);
    let geometryZ = new BufferGeometry().setFromPoints(z);

    let lineX = new Line(geometryX, lineMaterial);
    lineX.name = "lineX";
    let lineY = new Line(geometryY, lineMaterial);
    lineY.name = "lineY";
    let lineZ = new Line(geometryZ, lineMaterial);
    lineZ.name = "lineZ";

    lineX.computeLineDistances();

    this.modelDimensions.add(lineX);
    this.modelDimensions.add(lineY);
    this.modelDimensions.add(lineZ);

    let swapDimensions = false;
    let boxWidth = box.max.x - box.min.x;
    let boxHeight = box.max.y - box.min.y;
    let boxDepth = box.max.z - box.min.z;
    if (
      (boxWidth < boxDepth &&
        Number(this.productDimensions.width) >
          Number(this.productDimensions.depth)) ||
      (boxWidth > boxDepth &&
        Number(this.productDimensions.width) <
          Number(this.productDimensions.depth))
    ) {
      swapDimensions = true;
    }
    let distanceX = swapDimensions
      ? this.productDimensions.depth
      : this.productDimensions.width;
    let distanceY = this.productDimensions.height;
    let distanceZ = swapDimensions
      ? this.productDimensions.width
      : this.productDimensions.depth;

    const fontLoader = new FontLoader();

    fontLoader.load(
      `${themeConfiguration.model_url}/3d/fonts/helvetiker_regular.typeface.json`,
      (font) => {
        const fontGeometryX = new TextGeometry(distanceX?.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });

        fontGeometryX.translate(-0.1, 0, 0);

        let fontMeshX = new Mesh(
          fontGeometryX,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        let fontOffset = 0.02;

        fontMeshX.position.copy(
          new Vector3(
            box.min.x + (box.max.x - box.min.x) / 2,
            box.max.y + fontOffset + lineOffset,
            box.min.z
          )
        );
        fontMeshX.updateMatrixWorld();

        fontMeshX.name = "fontMeshX";
        this.modelDimensions.add(fontMeshX);

        const fontGeometryY = new TextGeometry(distanceY.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });

        fontGeometryY.translate(0.05, 0, 0);

        let fontMeshY = new Mesh(
          fontGeometryY,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        fontMeshY.rotateY(-Math.PI / 2);
        fontMeshY.position.copy(
          new Vector3(
            box.max.x,
            box.min.y + (box.max.y - box.min.y) / 2,
            box.max.z
          )
        );
        fontMeshY.updateMatrixWorld();

        fontMeshY.name = "fontMeshY";
        this.modelDimensions.add(fontMeshY);

        const fontGeometryZ = new TextGeometry(distanceZ.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });
        fontGeometryZ.translate(-0.05, 0, 0);

        let fontMeshZ = new Mesh(
          fontGeometryZ,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        fontMeshZ.rotateY(-Math.PI / 2);
        fontMeshZ.position.copy(
          new Vector3(
            box.max.x,
            box.max.y + fontOffset + lineOffset,
            box.min.z + (box.max.z - box.min.z) / 2
          )
        );
        fontMeshZ.updateMatrixWorld();

        fontMeshZ.name = "fontMeshZ";
        this.modelDimensions.add(fontMeshZ);
      }
    );
  }

  async ShowFramePins(pinsobject, pinImage, pinName) {
    this.framePin = pinsobject;
    for (const frame of this.modelFrame.children) {
      let smallPins = frame.getObjectByName("small_pins");
      let mediumPins = frame.getObjectByName("medium_pins");
      let largePins = frame.getObjectByName("large_pins");

      if (smallPins) smallPins.visible = false;
      if (mediumPins) mediumPins.visible = false;
      if (largePins) largePins.visible = false;

      if (frame.getObjectByName(pinsobject) && this.nailsEnabled)
        frame.getObjectByName(pinsobject).visible = true;
    }

    for (const arm of this.modelArm.children) {
      let smallPins = arm.getObjectByName("small_pins");
      let mediumPins = arm.getObjectByName("medium_pins");
      let largePins = arm.getObjectByName("large_pins");

      if (smallPins) smallPins.visible = false;
      if (mediumPins) mediumPins.visible = false;
      if (largePins) largePins.visible = false;

      if (arm.getObjectByName(pinsobject) && this.nailsEnabled)
        arm.getObjectByName(pinsobject).visible = true;
    }
  }

  async ShowSidePins(pinsobject, pinImage, pinName) {
    this.sidePin = pinsobject;
    for (const arm of this.modelArm.children) {
      let sidePins = arm.getObjectByName("side_pins");

      if (sidePins) sidePins.visible = false;

      if (arm.getObjectByName(pinsobject) && this.nailsEnabled)
        arm.getObjectByName(pinsobject).visible = true;
    }
  }

  async UpdateCameraPosition() {
    let box = new Box3().setFromObject(this.model);
    let size = box.getSize(new Vector3()).length();
    let center = box.getCenter(new Vector3());

    if (this.plane) {
      this.plane.position.y = box.min.y;
      this.shadowPlane.position.y = box.min.y;
    }

    this.controls.minDistance = size * 0.75;
    this.controls.maxDistance = size * 1.5;

    this.camera.position.copy(center);
    this.camera.position.x += 0; //this.size * 0.1;
    this.camera.position.y += size * 0.2;
    this.camera.position.z += size * 5.3;
    this.camera.updateMatrixWorld();

    this.controls.target = center;
    this.camera.lookAt(center);

    this.camera.updateProjectionMatrix();

    if (this.dimensionsToggle) {
      await this.ShowDimensions();
    }

    document.getElementById("loading-screen").classList.add("fade-out");
  }

  updateEnvMap() {
    var object = this.scene.getObjectByName("model");

    object.traverse((o) => {
      if (o.isMesh) {
        o.castShadow = true;
        o.receiveShadow = true;
        if (this.envMap != null) {
          o.material.envMap = this.envMap;
          o.material.envMapIntensity = this.envMapIntensity;
        }

        o.material.needsUpdate = true;
      }
    });
  }

  updateMap(object) {
    object.traverse((o) => {
      if (o.isMesh) {
        o.castShadow = true;
        o.receiveShadow = true;

        if (o.material.name === "metal") {
          return;
        }

        if (this.ignoredMaterials.includes(o.material.name)) return;

        let found = false;
        this.materials.forEach((material) => {
          if (material.type == o.material.name) {
            found = true;
            if (material.map) o.material.map = material.map;
            if (material.roughness) o.material.roughness = material.roughness;
            if (material.metalness) o.material.metalness = material.metalness;
          }
        });

        if (!found && this.materials.length != 0) {
          if (this.materials[0].map) o.material.map = this.materials[0].map;
          if (this.materials[0].roughness)
            o.materials.roughness = this.materials[0].roughness;
          if (this.materials[0].metalness)
            o.material.metalness = this.materials[0].metalness;
        }

        if (this.envMap) {
          o.material.envMap = this.envMap;
          o.material.envMapIntensity = this.envMapIntensity;
        }

        if (o.material) {
          if (o.material.map) {
            o.material.map.encoding = sRGBEncoding;
          }

          if (o.material.ao) {
            o.material.ao.encoding = sRGBEncoding;
          }
        }

        o.material.needsUpdate = true;
      }
    });
  }

  degreesToRadians = (degrees) => {
    var pi = Math.PI;
    return degrees * (pi / 180);
  };

  determinCameraDistance = (modelSize) => {
    let cameraDistance;
    let halfFOVInRadians = this.degreesToRadians(
      (this.camera.fov * this.camera.aspect) / 4
    );
    let height = modelSize.height;
    cameraDistance = height / 2 / Math.tan(halfFOVInRadians);
    return cameraDistance;
  };

  updateZoom(zoom, diff = 0) {
    let newZoom = 1 + (zoom + diff) / 10;

    if (
      newZoom >= 1 + zoomOptions.min / 10 &&
      newZoom <= 1 + zoomOptions.max / 10
    ) {
      this.controls.object.zoom = newZoom;
      this.controls.object.updateProjectionMatrix();
      return {
        ...zoomOptions,
        current: zoom + diff,
      };
    } else {
      return { ...zoomOptions, current: zoom };
    }
  }

  getCameraAngle = () => {
    const euler = new Euler();
    const rotation = euler.setFromQuaternion(this.camera.quaternion);
    const radians = rotation._z > 0 ? rotation._z : 2 * Math.PI + rotation._z;
    return radians * (180 / Math.PI);
  };

  update() {
    requestAnimationFrame(this.update);
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    if (this.dimensionsToggle) {
      let fontMeshX = this.modelDimensions.getObjectByName("fontMeshX");
      let fontMeshY = this.modelDimensions.getObjectByName("fontMeshY");
      let fontMeshZ = this.modelDimensions.getObjectByName("fontMeshZ");
      fontMeshX && fontMeshX.lookAt(this.camera.position);
      fontMeshY && fontMeshY.lookAt(this.camera.position);
      fontMeshZ && fontMeshZ.lookAt(this.camera.position);
    }
    this.cameraAngle = this.getCameraAngle();
  }

  ModelExist(modelName) {
    return UrbanModels.includes(modelName);
  }

  getCameraPosition() {
    return {
      x: this.camera.position.x,
      y: this.camera.position.y,
      z: this.camera.position.z,
    };
  }
  setCameraPosition({ x = 50, y = -1, z = 50 }) {
    this.camera.position.x = x;
    this.camera.position.y = y;
    this.camera.position.z = z;
  }
}
