import clsx from "clsx";
import { FormikProps } from "formik";
import {
  MDBBox,
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";
import { EmailValues } from "../EmailAndPassword/config";
import { AccountNumberValues } from "./config";

export interface ExpressProps {
  accountNumberForm: FormikProps<AccountNumberValues>;
  accountNumberFound: boolean;
  emailForm: FormikProps<EmailValues>;
}

const Express: React.FC<ExpressProps> = ({
  accountNumberForm,
  accountNumberFound,
  emailForm,
}) => {
  return (
    <MDBRow className={`express-registration`}>
      <MDBCol>
        <div className="pt-4 px-5 pb-5">
          <div className="pt-2 px-5">
            <Link to={{ search: "" }}>
              <MDBTypography
                // @ts-ignore
                variant="body-1"
                className="underline text-center mt-4"
              >
                Login with Email and Password
              </MDBTypography>
            </Link>
            <MDBBox className="mt-5 dark separator" />
            <div className="mt-5">
              <MDBTypography
                tag="h2"
                variant="display-2"
                className="font-weight-light"
              >
                Login/Register
              </MDBTypography>
              {/* @ts-ignore */}
              <MDBTypography className="mt-2 text-primary" variant="body-2 ">
                Please provide us with the requested information below so that
                we setup your account.
              </MDBTypography>
            </div>
            <form
              className="mt-5 pt-4"
              onSubmit={accountNumberForm.handleSubmit}
            >
              <div className="position-relative">
                <MDBInput
                  {...accountNumberForm.getFieldProps("accountNumber")}
                  label="Account Number"
                  required
                  hint="Enter Account Number"
                  containerClass="m-0 w-100"
                  className={clsx({
                    error:
                      accountNumberForm.touched.accountNumber &&
                      accountNumberForm.errors.accountNumber,
                  })}
                />
                <div className="position-absolute text-red">
                  {accountNumberForm.touched.accountNumber && accountNumberForm.errors.accountNumber && <span dangerouslySetInnerHTML={{ __html: accountNumberForm.errors.accountNumber }} />
                  }
                </div>
              </div>
              {accountNumberFound ? (
                <form className="mt-5 pt-5">
                  <div className="position-relative">
                    <MDBInput
                      {...emailForm.getFieldProps("email")}
                      label="Email"
                      required
                      hint="email@email.com"
                      containerClass="m-0 w-100"
                      className={clsx({
                        error:
                          emailForm.touched.email && emailForm.errors.email,
                      })}
                    />
                    <div className="position-absolute text-red">
                      {emailForm.touched.email && emailForm.errors.email}
                    </div>
                  </div>
                  <MDBBtn
                    type="submit"
                    className="mt-5 m-0 w-100"
                    disabled={emailForm.isSubmitting}
                    onClick={emailForm.submitForm}
                  >
                    Create Account
                  </MDBBtn>
                </form>
              ) : (
                <div className="mt-5">
                  <MDBBtn
                    type="submit"
                    className={`w-100 m-0 ${accountNumberForm.errors.accountNumber && "mt-4" || ""}`}
                    disabled={accountNumberForm.isSubmitting}
                    onClick={accountNumberForm.submitForm}
                  >
                    Find Account
                  </MDBBtn>
                </div>
              )}
            </form>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default Express;
