import { triangleStyle } from "app/pages/collections/pages/Project/styles";
import { MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React from "react";
import { Link, LinkProps } from "react-router-dom";

export interface BackSectionProps extends Pick<LinkProps, "to"> {}

const BackSection: React.FC<BackSectionProps> = (props) => {
  return (
    <MDBRow className={`mb-5 mt-5 pt-4 back-section-wrapper`}>
      <MDBCol>
        <Link to={props.to}>
          <div className="text-default pb-5 border-bottom-1 border-gray d-flex align-items-center">
            <div className="mr-3 pr-2" style={triangleStyle} />
            <MDBTypography
              // @ts-ignore
              variant="body-6"
              className="fs-14 f2-medium mb-0"
            >
              {props.children}
            </MDBTypography>
          </div>
        </Link>
      </MDBCol>
    </MDBRow>
  );
};

export default BackSection;
