import React, { useState } from "react";
import Logo from "./logo";
const LogoStateContainer = ({ type = "desktop", ...rest }) => {
  const [logoType, setLogoType] = useState(type);

  return (
    <Logo {...rest} type={type} logoType={logoType} setLogoType={setLogoType} />
  );
};

export default LogoStateContainer;
