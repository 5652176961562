import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonPDP = () => {
  return (
    <div className={"skeleton-container"}>
      <div className={"skeleton-picture"}>
        <Skeleton
          height={`${window.screen.width > 768 ? "70rem" : "30rem"}`}
          width={`${
            window.screen.width > 768 ? "calc(33% - 20px)" : "calc(100% - 20px)"
          }`}
        />
        {window.screen.width > 768 && (
          <>
            <Skeleton height={`70rem`} width={`calc(33% - 20px)`} />
            <Skeleton height={`70rem`} width={`calc(33% - 20px)`} />
          </>
        )}
      </div>
      <Skeleton height={`10rem`} width={`100%`} />
      <div className={"skeleton-main-container"}>
        <div className={"skeleton-left"}>
          <Skeleton height={`44.0rem`} width={`100%`} />
          <Skeleton height={`44.0rem`} width={`100%`} />
        </div>
        <div className={"skeleton-right"}>
          <Skeleton height={`44.0rem`} width={`100%`} />
          <Skeleton height={`44.0rem`} width={`100%`} />
        </div>
      </div>
      <Skeleton height={`112.4rem`} width={`100%`} />
    </div>
  );
};

export default SkeletonPDP;
