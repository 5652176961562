import React, { FC } from "react";
import InputField from "core/components/inputField";
import Button from "core/components/button";
import { createEnterHandler } from "app/utils/utils";
import Icon from "app/assets/icon/icon";
export interface SearchFieldProps {
  isExact: boolean;
  setExact: (value: boolean) => void;
  unconfirmedSearchVal: string;
  setUnconfirmedSearchVal: (value: string) => void;
  handleSearchBtn: () => void;
  isSidebar: boolean;
  setSearchVal: (value: any) => void;
  searchVal: string;
  setCurrentPage: (value: number) => void;
}
const SearchField: FC<SearchFieldProps> = (props) => {
  const resetSearch = () => {
    props.setUnconfirmedSearchVal("");
    props.setSearchVal("");
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      props.handleSearchBtn();
      props?.setCurrentPage(1);
    }
  };

  return (
    <div
      className={`d-flex align-items-center search-container ${
        props.isSidebar ? "mr-auto" : "ml-auto"
      }`}
    >
      <div className="search-field-container-plp">
        <InputField
          className={`custom-input`}
          hint="Search"
          // label={`Search`}
          type="text"
          value={props.unconfirmedSearchVal}
          onChange={(e: any) => {
            props.setUnconfirmedSearchVal(e.target.value);
          }}
          onKeyPress={handleKeyPress}
        />
        {(props.searchVal || props.unconfirmedSearchVal) && (
          <div
            className="clear-search-plp"
            onClick={() => resetSearch()}
            onKeyUp={createEnterHandler(resetSearch)}
            tabIndex={0}
            role="button"
          >
            <Icon icon="closeDefault" />
          </div>
        )}
      </div>

      <Button
        color="default"
        className={"btn-default"}
        // direction={"mr-2"}
        children={<span>Search</span>}
        onClick={() => {
          props.setCurrentPage(1);
          props.handleSearchBtn();
        }}
      />
    </div>
  );
};

export default SearchField;
