import React, { memo } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import Input from "core/components/input";
import { isEqual } from "lodash";

const OrderNotes = memo(
  ({ orderNotes, setOrderNotes, submit, originalOrderNotes, loading }) => {
    return (
      <MDBCol size="12" className="order-comment">
        <MDBRow>
          <MDBCol size="12" className={"order-comment-wrapper"}>
            <Input
              disabled={loading}
              hint="Comments"
              placeholder={`Comments`}
              type="textarea"
              value={orderNotes}
              onChange={(e) => {
                setOrderNotes(e.target.value);
              }}
              onBlur={(e) => {
                if (
                  orderNotes !== null &&
                  orderNotes.length > 2 &&
                  orderNotes !== originalOrderNotes
                ) {
                  submit(orderNotes);
                }
              }}
              className={`order-notes w-100 custom-input`}
            />
          </MDBCol>
        </MDBRow>
      </MDBCol>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default OrderNotes;
