import { MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";
import { customerPaths } from "../../routes";

export interface AddressesSubtitleProps {}

const AddressesSubtitle: React.FC<AddressesSubtitleProps> = (props) => {
  return (
    <MDBRow className="mb-5">
      <MDBCol className="d-flex align-items-center">
        <MDBTypography tag="h3" className="mr-4 pr-4">
          Address Book
        </MDBTypography>
        <Link to={customerPaths.children.account.children.addresses.path}>
          {/* @ts-ignore */}
          <MDBTypography variant="body-1" className="underline">
            Manage Addresses
          </MDBTypography>
        </Link>
      </MDBCol>
    </MDBRow>
  );
};

export default AddressesSubtitle;
