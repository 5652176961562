import gql from "graphql-tag";

export const getRoomScenesQuery = gql`
  query getRoomScenes($product_sku: String) {
    getRoomScenes(product_sku: $product_sku) {
      category
      description
      id
      hotspot {
        content
        image
        name
      }
      parent_id
      image
      thumbnail
      name
      skuList
    }
  }
`;
