/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { determineCurrency } from "config/currency";
import { MDBCol, MDBInput, MDBRow, MDBBox, MDBTypography } from "mdbreact";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Icon from "app/assets/icon/icon";
import { headers } from "../../config";
import { ColSize, ItemProps } from "../../models";
import { getOptionsPrice } from "../../utils";
import CommentSection from "../CommentSection";
import CustomizableOptions from "../CustomizableOptions";
import DocumentInput from "../DocumentInput";
import ItemTitle from "../ItemTitle";
import OptionsPrice from "../OptionsPrice";
import RemoveItem from "../RemoveItem";
import Subtotal from "../Subtotal";
import UnitPrice from "../UnitPrice";
import themeConfiguration from "config/themeConfiguration";
import { CartDataContext } from "../../context/CartDataContext";
import { powerOptionFunction, monogramLogic } from "../utils";

const Item: React.FC<ItemProps> = ({
  index,
  item,
  removeItemFromCart,
  form,
  comment,
  onCommentChange,
  documentNames,
  onDocumentUpload,
  isUploading,
  onRemoveDocument,
  cartQuery,
}) => {
  const currency = React.useMemo(
    () => determineCurrency(item.prices?.price.currency),
    [item]
  );

  // @ts-ignore
  const { refetchData, setRefetchData } = useContext(CartDataContext);

  const fabricPrice = React.useMemo(() => {
    return (
      item.customizable_options.find(
        (option) => option?.label.toLowerCase() === "upholstery options"
      )?.values?.[0]?.price.value || 0
    );
  }, [item]);

  const optionsPrice = React.useMemo(() => {
    return getOptionsPrice(item.customizable_options);
  }, [item]);

  const unitPrice = React.useMemo(
    () =>
      item.prices?.price.value
        ? item.prices?.price.value - optionsPrice - fabricPrice
        : 0,
    [item, optionsPrice, fabricPrice]
  );

  const subtotal = React.useMemo(
    () => (item.prices?.price.value ?? 1) * item.quantity,
    [item]
  );
  const quantityProps = form.getFieldProps("quantity");
  let foundImage: any;
  let foundUrl: any;
  let powerOption = "";
  let foundUrlEdit: any;
  const BYOvalue = item?.customizable_options?.find(
    // @ts-ignore
    (option) => option.label === "Build your own options"
  )?.values?.[0]?.value;

  let parsedValues = BYOvalue
    ? JSON.parse(BYOvalue?.replace(/\&quot;/g, `"`))
    : "";

  item.customizable_options?.forEach((option) => {
    if (
      // @ts-ignore
      option?.label === "Build your own options" &&
      option.values &&
      option?.values[0]?.value !== "{}"
    ) {
      powerOption = powerOptionFunction(option);
    }
    if (option?.label === "Build your own image") {
      foundImage = option.values?.[0]?.value;
    } else if (option?.label === "Build your own configurator url") {
      let url = option.values?.[0]?.value
        ?.replace(/&quot;/g, '\\"')
        .replace(/&amp;/g, "&");
      if (typeof url === "string" && url?.includes("monogram")) {
        url = monogramLogic(item);
      }
      if (powerOption) url = `${url}${powerOption}`;
      if (parsedValues?.base)
        url = `${url}&baseName=${parsedValues?.base?.name}`;

      foundUrl = {
        pathname: url?.substr(0, url.indexOf("?")),
        search: url?.substring(url.indexOf("?"), url.length),
      };

      foundUrlEdit = {
        pathname: url?.substr(0, url.indexOf("?")),
        search:
          url?.substring(url.indexOf("?"), url.length) +
          `&edit=true&item_id=${item?.id}`,
      };
    }
  });

  const foundUrlSearch = () => {
    // @ts-ignore
    if (foundUrlEdit?.search) {
      // @ts-ignore
      foundUrlEdit.search = `${foundUrlEdit?.search}?edit=true&item_id=${item.id}`;
    }
  };

  return (
    <MDBCol size="12" className="mt-4 pt-2 pb-5 border-bottom">
      <MDBRow className="item-container">
        <MDBCol
          className={"item-image-container"}
          size={headers[0].size.toString() as ColSize}
        >
          <MDBRow>
            <MDBCol size="12">
              {foundUrl && foundImage ? (
                <Link
                  to={
                    foundUrl
                    // //@ts-ignore
                    // item?.product?.ffi_collection[0] === "Monogram"
                    //   ? `/build-your-own/monogram/${item?.product?.sku}`
                    //   : foundUrl
                  }
                >
                  <div className="position-relative">
                    <img
                      className={`img-fluid ${
                        foundImage?.includes("build_your_own_image")
                          ? "item-image-container item-image-cart build-own"
                          : "item-image-container"
                      }`}
                      src={themeConfiguration.magento_url + foundImage}
                      alt={item.product?.small_image?.label || ""}
                    />
                  </div>
                </Link>
              ) : (
                item.product?.small_image?.url &&
                item.product.small_image?.label && (
                  <Link to={foundUrl ? foundUrl : item.product.url_key || "#"}>
                    <div className="position-relative">
                      <img
                        src={item.product.small_image.url}
                        className="img-fluid"
                        alt={item.product.small_image.label}
                      />
                    </div>
                  </Link>
                )
              )}
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol size={(12 - headers[0].size).toString() as ColSize}>
          <MDBRow className="h-100">
            <MDBCol
              className="d-flex align-items-center"
              size={headers[1].size.toString() as ColSize}
            >
              <MDBRow className="h-100">
                <MDBCol size="12" className="d-flex flex-column">
                  <ItemTitle item={item} foundUrl={foundUrl} />
                  <div className="ml-n2">
                    <RemoveItem onRemove={removeItemFromCart} />
                    {/*FIXME: Until issues are fixed.*/}
                    <Link
                      to={
                        foundUrlEdit
                          ? foundUrlEdit
                          : item.product.url_key +
                              `?edit=true&item_id=${item.id}` || "#"
                      }
                      style={{ float: "left" }}
                      onClick={foundUrlSearch}
                    >
                      <div
                        className="cursor-pointer d-flex align-items-center"
                        tabIndex={0}
                        role="button"
                      >
                        <Icon
                          icon="edit"
                          style={{ width: "32px", margin: "5px" }}
                        />
                        {/*    /!* @ts-ignore *!/*/}
                        <MDBTypography className="m-0 fs-13 fwregular">
                          Edit
                        </MDBTypography>
                      </div>
                    </Link>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            {themeConfiguration.magento_url !==
              "https://fairfieldm2.joladev.com/" && (
              <MDBCol size={headers[2].size.toString() as ColSize}>
                <UnitPrice
                  currency={currency}
                  price={unitPrice + fabricPrice}
                />
              </MDBCol>
            )}
            {
              <MDBCol size={headers[3].size.toString() as ColSize}>
                {themeConfiguration.magento_url !==
                  "https://fairfieldm2.joladev.com/" && (
                  <OptionsPrice currency={currency} price={optionsPrice} />
                )}
              </MDBCol>
            }
            <MDBCol size={headers[4].size.toString() as ColSize}>
              <div className={`qty-wrapper d-flex justify-content-center`}>
                <div
                  className="qty-click qty-click-minus cursor-pointer"
                  onClick={() => {
                    if (quantityProps.value > 1) {
                      form.setFieldValue("quantity", quantityProps.value - 1);
                      form.submitForm();
                    }
                    setRefetchData(true);
                  }}
                >
                  <Icon icon={"minusBlue"} />
                </div>
                <MDBInput
                  id="quantity_input"
                  value={quantityProps.value}
                  hint="Quantity"
                  containerClass={`m-0 w-auto d-flex`}
                  type={`number`}
                  onFocus={(e) => e.currentTarget.select()}
                  onChange={(e) => {
                    if (parseInt(e.currentTarget.value) > 0) {
                      form.setFieldValue("quantity", +e.currentTarget.value);
                      form.submitForm();
                    }
                  }}
                />
                <div
                  className="qty-click qty-click-plus cursor-pointer"
                  onClick={() => {
                    form.setFieldValue("quantity", quantityProps.value + 1);
                    form.submitForm();
                    setRefetchData(true);
                  }}
                >
                  <Icon icon={"plusBlue"} />
                </div>
              </div>
              {(quantityProps.value.toString().length > 4 && (
                <MDBBox className={`fwregular text-red`}>
                  max number of characters is 4
                </MDBBox>
              )) || <></>}
            </MDBCol>
            {themeConfiguration.magento_url !==
              "https://fairfieldm2.joladev.com/" && (
              <MDBCol
                className="d-flex justify-content-end"
                size={headers[5].size.toString() as ColSize}
              >
                <Subtotal currency={currency} price={subtotal} />
              </MDBCol>
            )}
          </MDBRow>
        </MDBCol>
      </MDBRow>
      <CustomizableOptions
        cartQuery={cartQuery}
        item={item}
        showOptionsWithCollapse={true}
        index={index}
        pdfBorderState={false}
      />

      <CommentSection
        lg="7"
        comment={comment}
        onCommentChange={onCommentChange}
      />
      <MDBRow className="mt-4 pt-4 mb-3">
        <MDBCol>
          <DocumentInput
            documentNames={documentNames}
            onDocumentUpload={onDocumentUpload}
            isUploading={isUploading}
            onRemoveDocument={onRemoveDocument}
          />
        </MDBCol>
        <MDBCol />
      </MDBRow>
    </MDBCol>
  );
};

// @ts-ignore
export default Item;
