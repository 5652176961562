import React from "react";
import InfoDisplay from "./info";
import MagicSliderDots from "react-magic-slider-dots";

export const InfoContainer = (props) => {
  let {
    name,
    contract,
    residential,
    senior,
    description,
    designer,
    related_products,
    details,
    specifications,
    dimensions,
    favorites,
    toggleFavorite,
    onToggleFavorite,
    showRelatedProducts,
    fabricPDP,
    customizationProps,
    withMaxWidth,
    collection,
  } = props;

  let showLanes = false;
  if (contract) {
    showLanes = true;
  }
  if (residential) {
    showLanes = true;
  }
  if (senior) {
    showLanes = true;
  }

  let laneData = {
    contract,
    retail: residential,
    senior,
  };

  let sliderProps = {
    className: "related-slider",
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    controls: false,
    arrows: false,
    dots: true,
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={30} />;
    },
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          controls: false,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
          dots: true,
        },
      },
    ],
  };

  let filteredDetails = details?.map((d) => {
    if (d.value !== "N/A" && d.value !== "") {
      return d;
    } else return null;
  });

  let displayProps = {
    name,
    showLanes,
    laneData,
    details: filteredDetails,
    specifications,
    dimensions,
    description: description?.html || "",
    designer,
    sliderProps,
    relatedProducts: related_products,
    favorites,
    toggleFavorite,
    onToggleFavorite,
    showRelatedProducts,
    fabricPDP,
    customizationProps,
    contract,
    residential,
    senior,
    related_products,
    withMaxWidth,
    collection,
  };

  return <InfoDisplay {...displayProps} />;
};

export const Info = React.memo(InfoContainer);
