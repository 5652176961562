import { AddNewWishlistMutationVariables } from "app/generated/graphql";
import * as yup from "yup";

export const favoritesName = "My Favorites";
export const savedBuildsName = "Saved Builds";

export interface CreationWishlistValues
  extends AddNewWishlistMutationVariables {}

export const creationWishlistValues: CreationWishlistValues = {
  name: "",
};

export enum WishlistNameLength {
  max = 50,
}

export const creationWishlistSchema: yup.ObjectSchema<CreationWishlistValues> = yup
  .object()
  .required()
  .shape({
    name: yup
      .string()
      .required("Name is required.")
      .min(0)
      .max(
        WishlistNameLength.max,
        "Name may not be longer than 50 characters."
      ),
  });

export type ModificationWishlistValues = CreationWishlistValues;

export const modificationWishlistValues: ModificationWishlistValues = creationWishlistValues;

export const ModificationWishlistSchema: yup.ObjectSchema<ModificationWishlistValues> = creationWishlistSchema;
