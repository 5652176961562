import {
  default as CustomerAppPages,
  DefaultPage as AppDefaultPage,
  ErrorPage as AppErrorPage,
} from "app/config/routing/pages/customerPages";
import WishlistCore from "core/pages/customer/account/pages/wishlistCore";
import WishlistPageListing from "core/pages/customer/account/pages/wishlistCore/wishlistPageListing";

const coreCustomerPages = [
  {
    url: "customer/wishlist",
    component: WishlistCore,
    separated: true,
    exact: true,
    inSidebar: true,
    dropdownText: "my wishlist",
    menuText: "my wishlist",
    inDropdown: true,
    disabled: false,
  },
  {
    url: "customer/wishlist/edit",
    component: WishlistPageListing,
    separated: true,
    exact: false,
    inSidebar: false,
    dropdownText: "wishlist edit",
    menuText: "wishlist edit",
    inDropdown: false,
    disabled: false,
  },
];
export default [...coreCustomerPages, ...CustomerAppPages];

export const ErrorPage = AppErrorPage;

export const DefaultPage = AppDefaultPage;
