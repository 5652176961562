import { emailSchema, phoneSchema } from "app/pages/customer/config";
import * as yup from "yup";

export interface ContactInformationValues {
  name: string;
  email: string;
  companyName: string;
  phone: string;
}

export const contactInformationValues: ContactInformationValues = {
  name: "",
  email: "",
  companyName: "",
  phone: "",
};

export const contactInformationSchema = yup
  .object()
  .required()
  .shape<ContactInformationValues>({
    name: yup.string().required(),
    email: emailSchema,
    companyName: yup.string().required(),
    phone: phoneSchema,
  });

export const quantitiesPerPageRecord = {
  "1": 1,
  "2": 2,
  "4": 4,
  "9": 9,
} as const;
export const quantitiesPerPage = Object.values(quantitiesPerPageRecord);
export type QuantitiesPerPage = typeof quantitiesPerPage;
export type IQuantityPerPage = QuantitiesPerPage[number];

export const markupPricingValues = [1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5] as const;
export type MarkupPricingValue = typeof markupPricingValues[number];
export type Pricing = boolean | MarkupPricingValue;
export const markupPricingOptions = [
  { label: "Select...", value: null },
  ...markupPricingValues.map((value) => ({
    value,
    label: value.toString(),
  })),
];

export const printConfig = {
  hasDimensions: false,
  colSize: 12,
  prodDisplay: "column",
  heading: 2,
  imageHeight: "auto",
  smallFont: "1.6rem",
  largeFont: "2rem",
};
export type PrintConfig = typeof printConfig;
