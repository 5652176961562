import FabricPatternWidget from "app/layout/fabricPatternWidget";
import { getBadges } from "app/pages/collections/utils";
import { MDBContainer, MDBTypography } from "mdbreact";
import React, { useRef } from "react";
import Slider from "react-slick";
import SeoContainer from "../seoContainer";
import FabricMediaGallery from "./fabricMediaGallery";
import Toolbar from "./fabricPdpToolbar";
import MagicSliderDots from "react-magic-slider-dots";

const FabricPdp = (props) => {
  let { productData, aggregations, colorwaysData } = props;
  let colorwaysRef = useRef();
  let colorwaysItems = colorwaysData?.products?.items;
  return (
    <>
      <SeoContainer
        productData={productData}
        type={"fabric"}
        jsonLdType={"Fabric"}
      />
      <FabricMediaGallery
        productData={productData}
        aggregations={aggregations}
        colorwaysRef={colorwaysRef}
        colorwaysItems={colorwaysItems}
      />
      <Toolbar
        {...productData}
        toggleFavorite={props.toggleFavorite}
        onToggleFavorite={props.onToggleFavorite}
        isFavorite={props.isFavorite}
        productData={productData}
        calculateOptions={props.calculateOptions}
      />
      {colorwaysItems?.length > 0 && (
        <div className="container colorways-container" ref={colorwaysRef}>
          <MDBContainer className="mb-3 px-4 py-3">
            <MDBTypography tag="h3" className={`display-3 text-default`}>
              Colorways
            </MDBTypography>
          </MDBContainer>
          <MDBContainer>
            <Slider
              className={`colorways-slider ${
                colorwaysItems?.length < 4 && "slide-max-width"
              }`}
              {...{
                centerMode: false,
                infinite: false,
                slidesToShow:
                  colorwaysItems?.length > 4 ? 4 : colorwaysItems?.length,
                slidesToScroll: 1,
                controls: false,
                arrows: true,
                dots: true,
                appendDots: (dots) => {
                  return (
                    <MagicSliderDots
                      dots={dots}
                      numDotsToShow={5}
                      dotWidth={30}
                    />
                  );
                },
                responsive: [
                  {
                    breakpoint: 768,
                    settings: {
                      className: "",
                      centerMode: false,
                      infinite: true,
                      slidesToShow: (colorwaysItems?.length > 1 && 2) || 1,
                      slidesToScroll: 1,
                      controls: false,
                      arrows: true,
                      dots: false,
                    },
                  },
                ],
              }}
            >
              {colorwaysItems?.map((colorway) => {
                const badges = getBadges(colorway);
                return (
                  <FabricPatternWidget
                    key={colorway.sku}
                    {...colorway}
                    colSize={(colorwaysItems?.length > 1 && "11") || "12"}
                    is_new={colorway.is_new}
                    badges={badges}
                    image={colorway.thumbnail}
                  />
                );
              })}
            </Slider>
          </MDBContainer>
        </div>
      )}
    </>
  );
};

export default FabricPdp;
