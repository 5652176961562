import React from "react";
import { MDBCol, MDBLink } from "mdbreact";
import CmsBlock from "app/layout/cmsBlock";
import FeaturedCollections from "./featuredCollections";

const CollectionsMenu = ({ subItems, setActiveTopMenu }) => {
  return (
    <>
      <MDBCol size="2" className={`col-with-cms-block`}>
        <div onClick={() => setActiveTopMenu(false)}>
          <CmsBlock id="header-craftsmanship-quality" />
        </div>
      </MDBCol>
      <MDBCol size="2">
        <FeaturedCollections
          subItems={subItems}
          setActiveTopMenu={setActiveTopMenu}
        />
      </MDBCol>
      <MDBCol
        size="8"
        onClick={() => {
          setActiveTopMenu(false);
        }}
      >
        <CmsBlock id="header-collections" />
      </MDBCol>
    </>
  );
};
export default CollectionsMenu;
