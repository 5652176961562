import { removeItem } from "../functions";

export default (state, action) => {
  let currentQueries = state.data ? state.data : {};
  let newQueries = { ...currentQueries };

  newQueries = removeItem(newQueries, [], true);

  return Object.assign({}, state, { data: newQueries });
};
