import { MDBCol, MDBRow } from "mdbreact";
import React, { useCallback, useEffect, useRef, useState } from "react";
import VizSensor from "react-visibility-sensor";
import FabricItem from "./fabricItem";
import FabricItemsSkeleton from "./fabricItemsSidebarSkeleton";
import FabricSidebarToolbar from "./fabricSidebarToolbar";
import InfiniteScrollSkeleton from "./infiniteScrollSkeleton";
import { fabricArray } from "app/pages/Custom/haven/data";
// import FilterOptionsMobile from "./filters/filterTypes/multiSelectFilterItem/filterOptions/filterOption-redux-container";

const FabricSidebarItemContainer = (props) => {
  let {
    fabricsArray,
    fabricsCategories,
    selectedCategory,
    setSelectedCategory,
    productImage,
    activeFilters,
    setActiveFilters,
    initialAggregations,
    loadingFabricItems,
    selectedFilters,
    setSelectedFilters,
    totalPagesNumber,
    allItems,
    currentPage,
    setCurrentPage,
    frame,
    isMobile,
    configFabricProps,
    setCustomizationOption,
    fabricOrFinishesSelectedOptions,
    isSelected,
    setIsSelected,
    removeCustomizationOption,
    setFabricOrFinishSelectedOptions,
    defaultFabric,
    setSelectedFabric,
    selectedFabric,
    customizationOptions,
    customizationOptionPrice,
    calculatedUpholsteryOptionsFabrics,
    price,
    setSelectedOption,
    currentOption,
    selectedOption,
    previousOption,
    setPreviousOption,
    hideModal,
    selectedFabricOption,
  } = props;

  useEffect(() => {
    if (isSelected !== defaultFabric?.id) {
      hideModal(false);
    }
  }, [isSelected]);

  const [targetElementVisible, setTargetElementVisible] = useState(false);
  const [isFabricOnceClicked, setIsFabricOnceClicked] = useState(true);
  const scrollingElement = useRef(null);
  const targetElement = useRef(null);
  const onScroll = useCallback(
    (e) => {
      if (!loadingFabricItems) {
        if (totalPagesNumber > currentPage) {
          if (targetElementVisible) {
            setCurrentPage(currentPage + 1);
            setTargetElementVisible(false);
          }
        } else {
          setTargetElementVisible(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      totalPagesNumber,
      scrollingElement,
      currentPage,
      targetElementVisible,
      loadingFabricItems,
      selectedCategory,
    ]
  );
  useEffect(() => {
    scrollingElement.current.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, [selectedCategory]);

  useEffect(() => {
    if (scrollingElement !== null && targetElement !== null) {
      scrollingElement.current.addEventListener("scroll", onScroll);
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        scrollingElement.current.removeEventListener("scroll", onScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    totalPagesNumber,
    targetElementVisible,
    loadingFabricItems,
    currentPage,
    selectedCategory,
  ]);

  const hasPlaceholderImage = React.useMemo(
    () => productImage?.url.includes("placeholder"),
    [productImage]
  );

  return (
    <div className="row fabric-sidebar-main-content" ref={scrollingElement}>
      <MDBCol className="fabric-sidebar-main-column">
        {!isMobile && (
          <MDBRow>
            <MDBCol className="fabrics-sidebar-image-container">
              <img
                src={hasPlaceholderImage ? frame?.image : productImage?.url}
                alt={hasPlaceholderImage ? frame?.name : productImage?.label}
              />
            </MDBCol>
          </MDBRow>
        )}
        <FabricSidebarToolbar
          fabricsArray={fabricsArray}
          fabricsCategories={fabricsCategories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          initialAggregations={initialAggregations}
          loadingFabricItems={loadingFabricItems}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          defaultFabric={defaultFabric}
          customizationOptions={customizationOptions}
          allItems={allItems}
        />
        <MDBRow className="flex-grow-1 items-container">
          {loadingFabricItems && currentPage === 1 ? (
            <FabricItemsSkeleton />
          ) : (
            <MDBCol className="items-column">
              {allItems && allItems.length > 0 ? (
                <MDBRow className="items-row">
                  {allItems.map((option, index) => {
                    return (
                      <FabricItem
                        key={option.id}
                        option={option}
                        fabricArray={fabricArray}
                        index={index}
                        isMobile={isMobile}
                        configFabricProps={configFabricProps}
                        setCustomizationOption={setCustomizationOption}
                        isSelected={isSelected}
                        setIsSelected={setIsSelected}
                        removeCustomizationOption={removeCustomizationOption}
                        fabricOrFinishesSelectedOptions={
                          fabricOrFinishesSelectedOptions
                        }
                        setFabricOrFinishSelectedOptions={
                          setFabricOrFinishSelectedOptions
                        }
                        defaultFabric={defaultFabric}
                        selectedFabric={selectedFabric}
                        setSelectedFabric={setSelectedFabric}
                        customizationOptions={customizationOptions}
                        setIsFabricOnceClicked={setIsFabricOnceClicked}
                        isFabricOnceClicked={isFabricOnceClicked}
                        customizationOptionPrice={customizationOptionPrice}
                        calculatedUpholsteryOptionsFabrics={
                          calculatedUpholsteryOptionsFabrics
                        }
                        price={price}
                        setSelectedOption={setSelectedOption}
                        currentOption={currentOption}
                        selectedOption={selectedOption}
                        previousOption={previousOption}
                        setPreviousOption={setPreviousOption}
                        preselection={{}}
                        setPreselection={() => {}}
                        selectedFabricOption={selectedFabricOption}
                      />
                    );
                  })}
                  <VizSensor
                    partialVisibility={true}
                    onChange={(isVisible) => {
                      if (isVisible) {
                        if (!loadingFabricItems) {
                          setTargetElementVisible(true);
                        }
                      } else {
                        setTargetElementVisible(false);
                      }
                    }}
                    offset={{ top: 100 }}
                  >
                    <div
                      style={{
                        width: "100%",
                        marginTop: "-3rem",
                      }}
                    >
                      {totalPagesNumber === currentPage ? (
                        <></>
                      ) : (
                        !isMobile && (
                          <MDBCol className="px-2">
                            <InfiniteScrollSkeleton />
                          </MDBCol>
                        )
                      )}
                    </div>
                  </VizSensor>
                </MDBRow>
              ) : (
                <MDBRow className="empty-fabric-array">
                  <MDBCol>
                    <h4 className="text-primary fwregular text-center">
                      There are currently no fabrics available for this
                      selection.
                    </h4>
                  </MDBCol>
                </MDBRow>
              )}
            </MDBCol>
          )}
        </MDBRow>
      </MDBCol>
    </div>
  );
};
export default FabricSidebarItemContainer;
