export const getObjectWithOptionIds = (string) => {
  let arr = string?.split("&");
  if (!arr?.length) return {};
  let obj = {};
  // @ts-ignore
  arr.forEach((item) => {
    let splitItem = item.split("=");
    obj = { ...obj, [splitItem[0]]: splitItem[1] };
  });
  return obj;
};
