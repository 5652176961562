// @ts-check

import clsx from "clsx";
import { MDBBox, MDBBtn, MDBInput } from "mdbreact";
import React from "react";
import Icon from "app/assets/icon/icon";

/**
 * @type {React.CSSProperties}
 */
const inputStyle = {
  display: "none",
};

/**
 *
 * @typedef {object} FileInputProps
 * @prop {string} value
 * @prop {(e: React.ChangeEvent<HTMLInputElement>) => void} onChange
 * @prop {string[]} accept
 * @prop {string} [hint]
 * @prop {boolean} [removeIcon]
 * @prop {string} [label]
 * @prop {boolean} [required]
 * @prop {boolean} [multiple]
 * @prop {string} [className]
 * @prop {string} [text]
 * @prop {boolean} [disabled]
 * @prop {any} [onClick]
 * @prop {React.ComponentProps<typeof MDBBtn>['size']} [buttonSize]
 * @prop {React.CSSProperties['flexDirection']} [flexDirection]
 * @prop {React.ComponentProps<typeof MDBInput>['size']} [inputSize]
 *
 * @param {FileInputProps} props
 */
const FileInput = ({
  value,
  onChange,
  accept,
  label,
  hint,
  required = false,
  multiple = false,
  className,
  text = "Browse",
  disabled = false,
  buttonSize,
  flexDirection = "row",
  inputSize,
  removeIcon = false,
  onClick = () => {},
}) => {
  /**
   * @type {React.MutableRefObject<HTMLInputElement | null>}
   */
  const inputRef = React.useRef(null);
  /**
   * @type {React.EventHandler<React.SyntheticEvent>}
   */
  const handleChooseFile = React.useCallback(
    (e) => {
      if (inputRef.current) inputRef.current.disabled = false;
      if (inputRef.current && !disabled) {
        inputRef.current.click();
      } else {
        e.preventDefault();
      }
    },
    [disabled, value]
  );

  React.useEffect(() => {
    const closeIcon = document.querySelector(
      ".col.upload-reseller-cert___step-one > label > div > img"
    );
    if (closeIcon && inputRef.current) {
      inputRef.current.disabled = true;
    }
  }, [value]);

  /**
   * @type {React.CSSProperties}
   */
  const wrapperStyle = React.useMemo(
    () => ({
      display: "flex",
      flexDirection,
      alignItems: "center",
      cursor: disabled ? "default" : "pointer",
    }),
    [disabled, flexDirection]
  );

  return (
    <MDBBox className="d-flex upload-input-wrapper">
      <input
        ref={inputRef}
        type="file"
        style={inputStyle}
        accept={accept.join(",")}
        onChange={onChange}
        multiple={multiple}
      />
      <MDBInput
        value={value}
        readOnly
        required={required}
        label={label}
        hint={hint}
        className={className}
        containerClass="my-3"
        size={inputSize}
      />
      <div
        style={wrapperStyle}
        onClick={handleChooseFile}
        onKeyUp={handleChooseFile}
        tabIndex={0}
        role="button"
      >
        <MDBBox
          className={clsx(
            flexDirection === "column" ? "w-100 mt-3" : "ml-md-4 pl-md-2 ml-2"
          )}
        >
          <MDBBtn
            className={
              `fwregular ` +
              clsx("m-0", { "w-100": flexDirection === "column" })
            }
            disabled={disabled}
            size={buttonSize}
          >
            {text}
          </MDBBtn>
        </MDBBox>
      </div>
      {removeIcon && value && (
        <Icon
          icon="closeDefault"
          className="cursor-pointer"
          onClick={onClick}
        />
      )}
    </MDBBox>
  );
};

export default FileInput;
