import { useSelector } from "react-redux";

function useCustomer() {
  const customer = useSelector((state) => state.customer.data);

  const getCustomer = () => {
    return customer;
  };

  const isLoggedIn = () => {
    if (customer.token === false) {
      return false;
    }

    return customer.token;
  };

  return {getCustomer, isLoggedIn};
}

export default useCustomer;
