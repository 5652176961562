import Icon from "app/assets/icon/icon";
import Label from "app/layout/Label";
import Select from "app/layout/Select";
import Switch from "app/layout/Switch";
import { useNewsletter } from "app/state/hooks/newsletterHook/useNewsletter";
import { ErrorMessage, Field, Formik } from "formik";
import {
  MDBBox,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React, { useState } from "react";
import * as yup from "yup";
const NewsLetterModal = (props) => {
  const { subscribeTo: subscribeCustomer } = useNewsletter();
  // const [email, { data, error, loading }] = useMutation(NEWSLETTER_QUERY, {
  //   errorPolicy: "all",
  // });

  const [toggleModal, setToggleModal] = useState(true);
  const divisionList = [
    { label: "Designer", value: "a" },
    { label: "Retail", value: "b" },
    { label: "Senior", value: "c" },
  ];

  const [division, setDivision] = useState({});
  const [switched, setSwitched] = useState(true);
  const [email, setEmail] = useState("");
  const [divisonError, setDivisonError] = useState(false);
  let loading = false;
  const toggleFn = () => {
    setToggleModal(!toggleModal);
  };
  // if (loading) {
  //   return "Loading...";
  // }
  const [termsAndPolicy, setTermsAndPolicy] = useState(false);
  return (
    <MDBContainer className="sign-in-modal">
      <MDBModal
        className="modal-wrapper"
        isOpen={toggleModal}
        centered
        toggle={() => {
          toggleFn();
        }}
      >
        <MDBModalHeader className="d-flex bg-default mx-0 p-4 justify-content-center align-items-center position-relative ">
          <MDBBox
            className="ml-4 pl-2 position-absolute modal-title-pdf cursor-pointer"
            onClick={() => toggleFn()}
            style={{ left: 0 }}
          >
            <Icon icon="closeWhite" />
          </MDBBox>
          <MDBRow style={{ position: "static" }}>
            <MDBBox>
              <MDBTypography
                tag="h2"
                variant="display-2"
                className="text-white f fwlight text-center"
              >
                Sign up for Updates
              </MDBTypography>
            </MDBBox>
          </MDBRow>
        </MDBModalHeader>
        <MDBModalBody>
          <MDBContainer className="px-5">
            <MDBRow>
              <MDBCol size="12">
                <MDBBox tag="h1" className="text-center my-lg-5 my-3">
                  To stay up to date on Fairfield Chair, please complete the
                  form below.
                </MDBBox>
              </MDBCol>
              <MDBCol size="12" className="d-flex justify-content-center">
                <MDBBox>
                  <Formik
                    initialValues={{
                      companyName: "",
                      firstName: "",
                      lastName: "",
                      zip: "",
                      email: "",
                    }}
                    validationSchema={yup.object({
                      email: yup
                        .string()
                        .email("Invalid email address.")
                        .required(`Email is required.`),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        // alert(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                      }, 400);
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <form className="" onSubmit={handleSubmit}>
                        <MDBRow
                          className="mx-auto first-column-inputs"
                          style={{ maxWidth: "32rem", width: "100%" }}
                        >
                          <MDBCol className="my-4" size="12" lg="12">
                            <Field
                              as={MDBInput}
                              label="Email"
                              hint="jane@doe.com"
                              name="email"
                              type="email"
                              value={values.email}
                              className={errors?.email && "error"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              name="email"
                              render={(msg) => (
                                <div className="text-red">{msg}</div>
                              )}
                            />
                          </MDBCol>
                          <MDBCol
                            size="12"
                            lg="12"
                            className=" my-4 position-relative"
                          >
                            <Label
                              className="custom-label"
                              required
                              text="Business Type"
                            />
                            <Select
                              placeholder="Select Business Type"
                              options={divisionList}
                              className={divisonError && "error"}
                              onChange={(e) => {
                                setDivisonError(false);
                                setDivision(e);
                              }}
                              wrapperClass={`my-4`}
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol size="12" className="mb-4">
                            <MDBTypography tag="span" className="body-4">
                              We will be using your contact information for you
                              to receive updates straight to your inbox related
                              to your account and purchases along with news
                              about our latest products, promotions and special
                              events. You may unsubscribe at any time.
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol className="my-4" size="12">
                            <Switch
                              label={
                                <>
                                  I accept{" "}
                                  <a
                                    href="/privacy-policy"
                                    target="_blank"
                                    className="d-inline-flex "
                                  >
                                    <MDBTypography
                                      tag="span"
                                      className="body-4 underline"
                                    >
                                      Privacy Policy Conditions
                                    </MDBTypography>
                                  </a>
                                </>
                              }
                              checked={switched}
                              onChange={(e) => {
                                setSwitched(e.target.checked);
                              }}
                            />
                          </MDBCol>
                          <MDBCol
                            className="mt-lg-4 mb-lg-5 mt-4 mb-4"
                            size="12"
                            lg="12"
                          >
                            {termsAndPolicy && (
                              <MDBBox>
                                <p className={`text-red`}>
                                  In order to subscribe to out Newsletter, you
                                  first need to accept out Privacy Policy
                                  Condition.
                                </p>
                              </MDBBox>
                            )}
                            <MDBBox className="w-50 m-auto">
                              <MDBBtn
                                className="w-100 font-weight-normal"
                                type="submit"
                                disabled={!switched}
                                onClick={(e) => {
                                  if (Object.keys(division).length === 0) {
                                    setDivisonError(true);
                                    return;
                                  } else {
                                    setDivisonError(false);
                                  }
                                  if (
                                    Object.keys(errors).length > 0 ||
                                    !values.email
                                  ) {
                                    return;
                                  }
                                  if (switched) {
                                    setTermsAndPolicy(false);
                                    subscribeCustomer(
                                      values.email,
                                      division.label
                                    ).then((result) => {
                                      if (values.email && division?.label) {
                                        toggleFn();
                                      } else {
                                        console.error("ERORR");
                                      }
                                    });
                                  } else {
                                    setTermsAndPolicy(true);
                                  }
                                }}
                              >
                                {loading ? "...Subscribing" : "Subscribe"}
                              </MDBBtn>
                            </MDBBox>
                          </MDBCol>
                        </MDBRow>
                      </form>
                    )}
                  </Formik>
                </MDBBox>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default NewsLetterModal;
