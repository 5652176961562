// @ts-check

import clsx from "clsx";
import { MDBTypography } from "mdbreact";
import React from "react";
import Checkbox from "app/layout/Checkbox";

/**
 * @typedef {{ label?: React.ReactNode } & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'type'>} SwitchProps
 *
 * @param {SwitchProps} props
 */
const Switch = (props) => {
  return <Checkbox {...props} />;
  return (
    <label
      className={clsx(
        props.className,
        "switch",
        "d-inline-flex",
        "align-items-center",
        props.disabled ? "cursor-default" : "cursor-pointer"
      )}
    >
      <input
        {...props}
        type="checkbox"
        className={clsx(props.className, "hide")}
      />
      <div
        className={clsx(
          "slider-body",
          "content-box",
          "mr-3",
          props.checked ? "bg-gray-lighten-4" : "bg-white",
          props.checked && !props.disabled
            ? "border-default-1"
            : "border-transparent-1"
        )}
      >
        <div
          className={clsx(
            "slider-indicator size-14 border-radius-50",
            props.disabled ? "bg-primary" : "bg-default"
          )}
        />
      </div>
      {/* @ts-ignore */}
      <MDBTypography tag="span" variant="body-4">
        {props.label}
      </MDBTypography>
    </label>
  );
};

export default Switch;
