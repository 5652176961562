import queryLoader from "app/graphql/queryLoader";
import data from "app/layout/header/mobile/data";
import apolloClient from "core/graphql/apolloClient";
import { messagesActions } from "core/state/redux/data/messages";
import { useDispatch } from "react-redux";

function useNewsletter() {
  const dispatch = useDispatch();
  const subscribeTo = async (request, action) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("subscriber"),
        variables: {
          email: request,
          division: action,
        },
      });
      if (
        data &&
        data.subscriber !== null &&
        data &&
        data.subscriber !== "undefined"
      ) {
        dispatch(
          messagesActions.addMessage(
            "You have succesfully subscribed",
            "success"
          )
        );
        return data;
      }
    } catch (err) {
      if (data && data.subscriber !== "") {
        dispatch(messagesActions.addMessage("Email is empty", "danger"));
      } else {
        dispatch(messagesActions.addMessage("Already subscribed", "danger"));
      }
    }
  };
  return { subscribeTo };
}

export { useNewsletter };
