import WishlistsModal from "app/pages/collections/components/WishlistsModal";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CollectionsContext, { ICollectionsContext } from "./CollectionsContext";

export interface CollectionsContextProviderProps {}

const CollectionsProvider: React.FC<CollectionsContextProviderProps> = (
  props
) => {
  const dispatch = useDispatch();

  const [selectedProduct, setSelectedProduct] = useState<
    ICollectionsContext["selectedProduct"]
  >(null);

  const [formattedOptions, setFormattedOptions] = useState<
    ICollectionsContext["formattedOptions"]
  >([]);

  const [wishlistName, setWishlistName] = useState<
    ICollectionsContext["wishlistName"]
  >("");
  const [isFavorite, setIsFavorite] = useState<
    ICollectionsContext["isFavorite"]
  >(false);
  const [showModal, setShowModal] = useState<ICollectionsContext["showModal"]>(
    false
  );
  return (
    <CollectionsContext.Provider
      value={React.useMemo(
        () => ({
          selectedProduct,
          setSelectedProduct,
          formattedOptions,
          setFormattedOptions,
          wishlistName,
          setWishlistName,
          isFavorite,
          setIsFavorite,
          showModal,
          setShowModal,
        }),
        [
          selectedProduct,
          setSelectedProduct,
          formattedOptions,
          setFormattedOptions,
          wishlistName,
          setWishlistName,
          isFavorite,
          setIsFavorite,
          showModal,
          setShowModal,
        ]
      )}
    >
      {props.children}
      {showModal ? (
        <WishlistsModal
          product={selectedProduct}
          formattedOptions={formattedOptions}
          isOpen={selectedProduct !== null}
          onClose={(ids) => {
            setSelectedProduct(null);
          }}
        />
      ) : null}
    </CollectionsContext.Provider>
  );
};

export default CollectionsProvider;
