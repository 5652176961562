import axios from "axios";
import themeConfiguration from "config/themeConfiguration";

export const getImagePath = (imageName: string) => {
  return `${themeConfiguration.magento_url}/media/wishlist/${imageName}`;
};

export type ToPromise = <A>(
  fn: (resolve: (a: A) => void) => void
) => Promise<A>;
export const toPromise: ToPromise = (fn) =>
  new Promise((resolve) => {
    fn(resolve);
  });

export const DownloadImage = async (urls: string) => {
  const url =
    themeConfiguration.magento_url +
    "rest/V1/jola-filedownloader/downloadFiles";
  var filesArray = [];
  filesArray = [
    {
      url: urls,
    },
  ];
  var rawRequest = JSON.stringify({ type: "images", files: filesArray });
  var config = {
    method: "post",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: rawRequest,
  } as const;

  axios(config)
    .then((data) => {
      if (data.status === 200) {
        CreateImageLink(data.data);
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const CreateImageLink = (data: string) => {
  var json_data = JSON.parse(data);
  var a = document.createElement("a");
  a.href = json_data.base64;
  a.download = json_data.image_name;
  a.click();
};
