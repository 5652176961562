// Action Creators
import types from "./types";

const getCacheVersion = (cache, flag) => {
  return {
    type: types.GET_CACHE_VERSION,
    version: cache,
    flag: flag
  };
};

const setCacheVersion = cache => {
  return {
    type: types.SET_CACHE_VERSION,
    version: cache
  };
};

const setFlag = flag => {
  return {
    type: types.SET_FLAG,
    flag: flag
  };
};

export default {
  getCacheVersion,
  setCacheVersion,
  setFlag
};
