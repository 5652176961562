import Icon from "app/assets/icon/icon";
import clsx from "clsx";
import {
  MDBCol,
  MDBCollapse,
  MDBRow,
  MDBTypography,
  // MDBTooltip,
  // MDBBox,
} from "mdbreact";
import React, { useEffect, useMemo, useState } from "react";
import { DropdownOption } from "./DropdownOption";
import { MoreOptionsSection } from "./MoreOptionsSection";
import { NailHeadOptions } from "./NailHeadOptions";
import SubOptionsSection from "./SubOptionsSection";
import { UpholsteryOption } from "./UpholsteryOption";
import Select from "../../../../layout/Select";
import useDeepCompareEffect from "use-deep-compare-effect";
import uuid from "react-uuid";

export const mainFabricName = "Main Fabric";

export const OptionSection = ({
  title = "",
  options = [],
  customizationProps,
  isCustom,
  isOpen = false,
  productImage,
  defaults,
  fabricsSelections,
  titleClassName,
  frame,
  fabricOrFinishesSelectedOptions,
  subOptions = [],
  viewMoreOptionsPrice,
  nailData,
  viewMorePdpPrice,
  setViewMorePdpPrice,
  viewMoreOptions,
  allowedFinishes,
  setViewMoreOptions,
  setFabricOrFinishSelectedOptions,
  categories,
  queryPathFabrics,
  checkBoxOptions,
  setCheckBoxOptions,
  dropdownOptions,
  setDropdownOptions,
  customizationOptionPrice,
  upholsteryOptionsFabrics,
  customer,
  foundFabrics,
  initSelectedFinish,
  pillowDropdownSelector,
  setPillowDropdownSelector,
  viewMoreDropdownSelector,
  setViewMoreDropdownSelector,
}) => {
  const [collapse, setCollapse] = useState(isOpen);
  let isSubOptionsOpen = false;

  const [selectedDropdownValues, setSelectedDropdownValues] = useState({});

  useEffect(() => {
    if (title.toLowerCase().indexOf("pillow") !== -1) {
      let foundSelectedDropdownValues = {};
      Object.keys(pillowDropdownSelector).forEach((pillowKey) => {
        let found = false;
        Object.keys(pillowDropdownSelector[pillowKey]).forEach((key) => {
          if (pillowDropdownSelector[pillowKey][key]) {
            found = true;
            const foundOption = options.find(
              (option) =>
                option.title.toLowerCase().split(" ").join("_") === key
            );
            foundSelectedDropdownValues[pillowKey] = {
              label: foundOption.title,
              value: foundOption,
            };
          }
        });

        if (!found) {
          foundSelectedDropdownValues[pillowKey] = null;
        }
      });

      setSelectedDropdownValues(foundSelectedDropdownValues);
    }
  }, [pillowDropdownSelector, title]);

  useDeepCompareEffect(() => {
    if (subOptions) {
      const localViewMoreDropdownSelector = JSON.parse(
        JSON.stringify(viewMoreDropdownSelector)
      );
      subOptions.forEach((option) => {
        if (
          option.title.toLowerCase() === "contrasting welt" ||
          option.title.toLowerCase() === "decorative cord" ||
          option.title.toLowerCase() === "fringe"
        ) {
          localViewMoreDropdownSelector[
            option.title.toLowerCase().split(" ").join("_")
          ] = false;
        }
      });

      setTimeout(() => {
        if (Object.keys(viewMoreDropdownSelector).length === 0) {
          setViewMoreDropdownSelector(localViewMoreDropdownSelector);
        }
      }, 0);
    }
  }, [subOptions, viewMoreDropdownSelector]);

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };
  if (title === "More Options") {
    let moreOptionsSectionProps = {
      title,
      options,
      customizationProps,
      isCustom,
      viewMoreOptionsPrice,
      viewMorePdpPrice,
      setViewMorePdpPrice,
      viewMoreOptions,
      setViewMoreOptions,
      setFabricOrFinishSelectedOptions,
      defaults,
      categories,
      allowedFinishes,
      checkBoxOptions,
      setCheckBoxOptions,
      queryPathFabrics,
    };
    return customizationProps?.options?.find(
      (opt) =>
        opt?.category_options?.toLowerCase() ===
        "Additional Options".toLocaleLowerCase()
    ) ? (
      <MoreOptionsSection {...moreOptionsSectionProps} />
    ) : (
      <></>
    );
  }
  return (
    <MDBRow className="options-section-wrapper">
      <MDBCol size="12" className={`${isCustom ? "px-0" : "px-0"}`.trim()}>
        {options.length === 1 && typeof options[0] === "undefined" ? (
          <></>
        ) : (
          <MDBRow
            onClick={toggleCollapse}
            className="ml-lg-4 ml-0 align-items-center mr-0"
          >
            <MDBCol size="10">
              <MDBTypography
                tag="h3"
                className={clsx(
                  titleClassName || "cursor-pointer fwlight text-default"
                )}
              >
                {title}
              </MDBTypography>
            </MDBCol>
            <MDBCol
              size="2"
              className={
                "d-flex justify-content-center justify-content-xl-end pr-xl-5"
              }
            >
              {collapse && <Icon icon="minusBlue" />}
              {!collapse && <Icon icon="plusBlue" />}
            </MDBCol>
          </MDBRow>
        )}
        <MDBRow className="py-4">
          <MDBCol>
            <MDBCollapse isOpen={collapse} className="ml-5 options-selections">
              {options
                .filter((option) => {
                  let rtrBool = true;
                  Object.keys(pillowDropdownSelector).forEach((pillowKey) => {
                    Object.keys(pillowDropdownSelector[pillowKey]).forEach(
                      (key) => {
                        if (
                          option?.title.toLowerCase().split(" ").join("_") ===
                          key
                        ) {
                          rtrBool = false;
                        }
                      }
                    );
                  });
                  return rtrBool;
                })
                .map((opt) => {
                  const configFabricProps = (() => {
                    if (fabricsSelections) {
                      switch (opt?.title) {
                        case mainFabricName:
                          return {
                            configFabric: fabricsSelections.mainFabric,
                            setConfigFabric: fabricsSelections.setMainFabric,
                          };
                        case "Cushion":
                          return {
                            configFabric: fabricsSelections.cushionFabric,
                            setConfigFabric: fabricsSelections.setCushionFabric,
                          };
                        case "Tight Seat":
                          return {
                            configFabric: fabricsSelections.cushionFabric,
                            setConfigFabric: fabricsSelections.setCushionFabric,
                          };
                        case "Back Pillow":
                          return {
                            configFabric: fabricsSelections.backFabric,
                            setConfigFabric: fabricsSelections.setBackFabric,
                          };
                        case "Contrast Welt":
                          if (title !== "Pillow Upholstery Options") {
                            return {
                              configFabric: fabricsSelections.weltFabric,
                              setConfigFabric: fabricsSelections.setWeltFabric,
                            };
                          }
                          break;
                        case "Decorative Cord":
                          return {
                            configFabric:
                              fabricsSelections.decorativeCordFabric,
                            setConfigFabric:
                              fabricsSelections.setDecorativeCordFabric,
                          };
                        case "Throw Pillow Contrast":
                          return {
                            configFabric: fabricsSelections.throwPillowFabric,
                            setConfigFabric:
                              fabricsSelections.setThrowPillowFabric,
                          };
                        default:
                          return undefined;
                      }
                    }
                  })();

                  return (
                    <div key={opt?.title}>
                      {opt && typeof opt !== "undefined" ? (
                        <MDBRow className="py-2 options-selections">
                          <MDBCol
                            size="12"
                            lg="3"
                            className="py-3 px-0 px-lg-4"
                          >
                            <MDBTypography tag="h2" className="body-2 fwlight">
                              {opt?.title}
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol className="cursor-pointer">
                            <OptionSelector
                              configFabricProps={configFabricProps}
                              opt={opt}
                              mainFabric
                              customizationProps={customizationProps}
                              productImage={productImage}
                              defaults={defaults}
                              frame={frame}
                              fabricOrFinishesSelectedOptions={
                                fabricOrFinishesSelectedOptions
                              }
                              allowedFinishes={allowedFinishes}
                              isCustom={isCustom}
                              nailData={nailData}
                              viewMorePdpPrice={viewMorePdpPrice}
                              setViewMorePdpPrice={setViewMorePdpPrice}
                              viewMoreOptions={viewMoreOptions}
                              setViewMoreOptions={setViewMoreOptions}
                              setFabricOrFinishSelectedOptions={
                                setFabricOrFinishSelectedOptions
                              }
                              categories={categories}
                              fabricsSelections={fabricsSelections}
                              checkBoxOptions={checkBoxOptions}
                              setCheckBoxOptions={setCheckBoxOptions}
                              dropdownOptions={dropdownOptions}
                              setDropdownOptions={setDropdownOptions}
                              customizationOptionPrice={
                                customizationOptionPrice
                              }
                              upholsteryOptionsFabrics={
                                upholsteryOptionsFabrics
                              }
                              customer={customer}
                              queryPathFabrics={queryPathFabrics}
                              foundFabrics={foundFabrics}
                            />
                          </MDBCol>
                        </MDBRow>
                      ) : (
                        <></>
                      )}
                      {subOptions && subOptions.length !== 0 && (
                        <SubOptionsSection
                          title={
                            title === "Upholstery Options"
                              ? "View More Upholstery Applications"
                              : title
                          }
                          options={subOptions}
                          customizationProps={customizationProps}
                          isCustom={isCustom}
                          isOpen={isSubOptionsOpen}
                          productImage={productImage}
                          defaults={defaults}
                          fabricsSelections={fabricsSelections}
                          titleClassName={titleClassName}
                          frame={frame}
                          allowedFinishes={allowedFinishes}
                          fabricOrFinishesSelectedOptions={
                            fabricOrFinishesSelectedOptions
                          }
                          setFabricOrFinishSelectedOptions={
                            setFabricOrFinishSelectedOptions
                          }
                          queryPathFabrics={queryPathFabrics}
                          checkBoxOptions={checkBoxOptions}
                          setCheckBoxOptions={setCheckBoxOptions}
                          dropdownOptions={dropdownOptions}
                          setDropdownOptions={setDropdownOptions}
                          upholsteryOptionsFabrics={upholsteryOptionsFabrics}
                          customer={customer}
                        />
                      )}
                    </div>
                  );
                })}
              {Object.keys(pillowDropdownSelector).map((pillowKey) => {
                if (
                  Object.keys(pillowDropdownSelector[pillowKey]).length > 0 &&
                  title.toLowerCase().indexOf("pillow") !== -1
                ) {
                  let pillowDropdownOptions = [];
                  pillowDropdownOptions.push({
                    label: "None",
                    value: null,
                  });
                  Object.keys(pillowDropdownSelector[pillowKey]).forEach(
                    (key) => {
                      let foundOption = options.find(
                        (option) =>
                          option.title.toLowerCase().split(" ").join("_") ===
                          key
                      );
                      if (foundOption) {
                        pillowDropdownOptions.push({
                          label: foundOption.title,
                          value: foundOption,
                        });
                      }
                    }
                  );

                  let pillowTitle = [];
                  pillowKey.split("_").forEach((key) => {
                    pillowTitle.push(
                      key.substr(0, 1).toUpperCase() + key.substr(1)
                    );
                  });
                  pillowTitle = pillowTitle.join(" ");
                  const configFabricProps = (() => {
                    if (fabricsSelections) {
                      switch (selectedDropdownValues[pillowKey]?.value.title) {
                        case mainFabricName:
                          return {
                            configFabric: fabricsSelections.mainFabric,
                            setConfigFabric: fabricsSelections.setMainFabric,
                          };
                        case "Cushion":
                          return {
                            configFabric: fabricsSelections.cushionFabric,
                            setConfigFabric: fabricsSelections.setCushionFabric,
                          };
                        case "Tight Seat":
                          return {
                            configFabric: fabricsSelections.cushionFabric,
                            setConfigFabric: fabricsSelections.setCushionFabric,
                          };
                        case "Back Pillow":
                          return {
                            configFabric: fabricsSelections.backFabric,
                            setConfigFabric: fabricsSelections.setBackFabric,
                          };
                        case "Contrast Welt":
                          if (title !== "Pillow Upholstery Options") {
                            return {
                              configFabric: fabricsSelections.weltFabric,
                              setConfigFabric: fabricsSelections.setWeltFabric,
                            };
                          }
                          break;
                        case "Decorative Cord":
                          return {
                            configFabric:
                              fabricsSelections.decorativeCordFabric,
                            setConfigFabric:
                              fabricsSelections.setDecorativeCordFabric,
                          };
                        case "Throw Pillow Contrast":
                          return {
                            configFabric: fabricsSelections.throwPillowFabric,
                            setConfigFabric:
                              fabricsSelections.setThrowPillowFabric,
                          };
                        default:
                          return undefined;
                      }
                    }
                  })();
                  return (
                    <div key={uuid()}>
                      <MDBRow className="py-2 options-selections">
                        <MDBCol size="12" lg="3" className="py-3 px-0 px-lg-4">
                          <MDBTypography tag="h2" className="body-2 fwlight">
                            {pillowTitle} Details
                          </MDBTypography>
                        </MDBCol>
                        <MDBCol
                          className="cursor-pointer"
                          style={{ paddingLeft: 0, paddingRight: "30px" }}
                        >
                          <Select
                            placeholder="Select option..."
                            value={selectedDropdownValues[pillowKey]}
                            options={pillowDropdownOptions}
                            onChange={(e) => {
                              let localPillowDropdownSelector = JSON.parse(
                                JSON.stringify(pillowDropdownSelector)
                              );
                              Object.keys(
                                localPillowDropdownSelector[pillowKey]
                              ).forEach((key) => {
                                localPillowDropdownSelector[pillowKey][key] =
                                  key ===
                                  e.label.toLowerCase().split(" ").join("_");
                              });

                              setPillowDropdownSelector(
                                localPillowDropdownSelector
                              );
                            }}
                          />
                        </MDBCol>
                      </MDBRow>
                      {selectedDropdownValues[pillowKey]?.value && (
                        <MDBRow className="py-2 options-selections">
                          <MDBCol
                            size="12"
                            lg="3"
                            className="py-3 px-0 px-lg-4"
                          >
                            <MDBTypography tag="h2" className="body-2 fwlight">
                              {selectedDropdownValues[pillowKey]?.value.title}
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol className="cursor-pointer">
                            <OptionSelector
                              configFabricProps={configFabricProps}
                              opt={selectedDropdownValues[pillowKey].value}
                              mainFabric
                              customizationProps={customizationProps}
                              productImage={productImage}
                              defaults={defaults}
                              frame={frame}
                              fabricOrFinishesSelectedOptions={
                                fabricOrFinishesSelectedOptions
                              }
                              allowedFinishes={allowedFinishes}
                              isCustom={isCustom}
                              nailData={nailData}
                              viewMorePdpPrice={viewMorePdpPrice}
                              setViewMorePdpPrice={setViewMorePdpPrice}
                              viewMoreOptions={viewMoreOptions}
                              setViewMoreOptions={setViewMoreOptions}
                              setFabricOrFinishSelectedOptions={
                                setFabricOrFinishSelectedOptions
                              }
                              categories={categories}
                              queryPathFabrics={queryPathFabrics}
                              fabricsSelections={fabricsSelections}
                              checkBoxOptions={checkBoxOptions}
                              setCheckBoxOptions={setCheckBoxOptions}
                              dropdownOptions={dropdownOptions}
                              setDropdownOptions={setDropdownOptions}
                              customizationOptionPrice={
                                customizationOptionPrice
                              }
                              upholsteryOptionsFabrics={
                                upholsteryOptionsFabrics
                              }
                              customer={customer}
                              foundFabrics={foundFabrics}
                            />
                          </MDBCol>
                        </MDBRow>
                      )}
                    </div>
                  );
                } else {
                  return <></>;
                }
              })}
            </MDBCollapse>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export const OptionSelector = ({
  opt,
  customizationProps,
  productImage,
  defaults,
  configFabricProps,
  frame,
  fabricOrFinishesSelectedOptions,
  isCustom,
  viewMorePdpPrice,
  viewMoreOptions,
  setViewMoreOptions,
  allowedFinishes,
  setFabricOrFinishSelectedOptions,
  nailData,
  optionClassNames,
  categories,
  queryPathFabrics,
  fabricsSelections,
  // checkBoxOptions,
  // setCheckBoxOptions,
  dropdownOptions,
  setDropdownOptions,
  customizationOptionPrice,
  upholsteryOptionsFabrics,
  customer,
  foundFabrics,
}) => {
  let current =
    fabricOrFinishesSelectedOptions.filter((option) => {
      return option.id === opt?.option_id;
    })[0] || null;
  let displayProps = {
    option: opt,
    current,
    customizationOptions: customizationProps.options,
    setCustomizationOption: customizationProps.setCustomizationOption,
    removeCustomizationOption: customizationProps.removeCustomizationOption,
    defaults: defaults,
    isCustom,
    viewMorePdpPrice,
    viewMoreOptions,
    setViewMoreOptions,
    configFabricProps,
    setFabricOrFinishSelectedOptions,
    nailData,
    optionClassNames,
    categories,
    allowedFinishes,
    queryPathFabrics,
    fabricsSelections,
    dropdownOptions,
    setDropdownOptions,
    customizationOptionPrice,
    customer,
  };
  switch (opt?.__typename) {
    case "CustomizableFieldOption":
      let isFinish =
        (!Object.keys(opt).includes("value") &&
          (opt?.title.toLowerCase().includes("finish") ||
            opt?.title.toLowerCase().includes("size"))) ||
        opt?.title.toLowerCase() === "nail size";
      if (isFinish) {
        displayProps.value = [];
        return (
          <NailHeadOptions
            {...displayProps}
            allowedFinishes={allowedFinishes}
            productImage={productImage}
            nailData={nailData}
            isCustom={isCustom}
            frame={frame}
          />
        );
      }
      return customizationProps?.options?.find(
        (opt) =>
          opt?.category_options?.toLowerCase() ===
          "Upholstery Options".toLocaleLowerCase()
      ) ? (
        <UpholsteryOption
          {...displayProps}
          productImage={productImage}
          allowedFinishes={allowedFinishes}
          frame={frame}
          fabricOrFinishesSelectedOptions={fabricOrFinishesSelectedOptions}
          setFabricOrFinishSelectedOptions={setFabricOrFinishSelectedOptions}
          customizationOptionPrice={customizationOptionPrice}
          viewMorePdpPrice={viewMorePdpPrice}
          upholsteryOptionsFabrics={upholsteryOptionsFabrics}
          foundFabrics={foundFabrics}
        />
      ) : (
        <></>
      );
    case "CustomizableDropDownOption":
      return <DropdownOption {...displayProps} />;
    default:
      return "Unknown Option Type";
  }
};
