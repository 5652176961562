import React, { useState, memo } from "react";
import PoNumber from "./poNumber";
import { isEqual } from "lodash";

const PoNumberStateContainer = memo(
  ({ cart_id, po_number, addPoNumberToCart, lock }) => {
    const [poNumber, setPoNumber] = useState(po_number);
    const [loading, setLoading] = useState(false);

    const sumbitPoNumberChange = (newPoNumber) => {
      setLoading(true);
      lock({
        always: () => {},
        success: () => {
          setLoading(false);
        },
        fail: () => {
          setLoading(false);
        },
      });
      addPoNumberToCart({ cart_id: cart_id, po_number: newPoNumber });
    };
    return (
      <PoNumber
        submit={sumbitPoNumberChange}
        poNumber={poNumber}
        setPoNumber={setPoNumber}
        originalPoNumber={po_number}
        loading={loading}
      />
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default PoNumberStateContainer;
