export const findConfigUrlFunction = (options) => {
  // @ts-ignore
  let isBYOurl = options?.find((option) => {
    if (
      window.location.pathname.includes("build-your-own") &&
      typeof option?.value_string === "string"
    )
      return option?.value_string?.startsWith("/build-your-own/");
    return option?.option_title?.includes("Build your own configurator url");
  });

  if (isBYOurl) return isBYOurl;
  return options?.[options?.length - 1];
};

export const checkIfItemIsChecked = (
  product,
  wishistitem = null,
  compare = true
) => {
  if (
    !product?.defaultOptions &&
    !product?.options &&
    !product?.selected_customizable_options
  )
    return true;
  let findConfigUrl = findConfigUrlFunction(
    product?.selected_customizable_options
      ? product?.selected_customizable_options
      : product?.custom_image
      ? product?.options
      : product?.defaultOptions
      ? product?.defaultOptions
      : product?.options
  );

  let wishlistItemConfigUrl = findConfigUrlFunction(
    wishistitem?.selected_customizable_options
  );
  let urlToCompare = findConfigUrl?.value_string
    ? findConfigUrl?.value_string?.split("?")[1]
    : findConfigUrl?.option_value_title?.split("?")[1];
  if (compare) {
    if (
      wishlistItemConfigUrl?.option_value_title?.split("?")[1] === urlToCompare
    )
      return true;
    return false;
  }
  return findConfigUrl;
};
