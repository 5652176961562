import { Player } from "app/layout/Player";
import ProductPage from "app/pages/catalog/product";
import { MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdbreact";
import React from "react";
import { PromosBYO } from "app/pages/catalog/product/PromosBYO";
import { useMediaQuery } from "react-responsive";
import { Step } from "app/pages/Custom";

const _CustomDisplay = (
  {
    player,
    setPlayer,
    finishesArray,
    stripingArray,
    modelsArray,
    floorArray,
    selectionsAndSelectors,
    sku,
    showPdp,
    productName,
    queryPathFabrics,
    productDimensions,
    availableOptions,
    setAvailableOptions,
  },
  canvasRef
) => {
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [checkBoxOptionsCustom, setCheckBoxOptionsCustom] = React.useState({});

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const isMobile = useMediaQuery({ maxWidth: 992 });
  const isLaptop = useMediaQuery({ maxHeight: 768 });

  return (
    <>
      <MDBContainer fluid className="position-relative py-4 px-0">
        <MDBRow className="h-100 mx-0">
          <MDBCol
            size="12"
            lg="9"
            className={`px-${isMobile ? "0" : "5"} player d-block ${
              isFullscreen ? "fullscreen-canvas" : ""
            }`.trim()}
            style={{
              top: isMobile ? "0" : "4rem",
              minHeight: isMobile ? "33.7vh" : isLaptop ? "50.2vh" : "65.2vh",
              maxHeight: isMobile
                ? "33.7rem"
                : isLaptop
                ? "50.2rem"
                : "65.2rem",
              height: isMobile ? "33.7rem" : isLaptop ? "50.2rem" : "65.2rem",
              marginBottom: isMobile ? "3rem" : "0",
              overflowY: "visible",
              zIndex: 1,
              position: "sticky",
            }}
          >
            <Player
              ref={canvasRef}
              isFullscreen={isFullscreen}
              toggleFullscreen={toggleFullscreen}
              player={player}
              setPlayer={setPlayer}
              frame={selectionsAndSelectors.model}
              striping={selectionsAndSelectors.striping}
              finish={selectionsAndSelectors.finish}
              floor={selectionsAndSelectors.floor}
              floorOptions={floorArray}
              setFloor={selectionsAndSelectors.setFloor}
              sku={sku}
              collectionPath={"monogram"}
              checkBoxOptionsCustom={checkBoxOptionsCustom}
              setCheckBoxOptionsCustom={setCheckBoxOptionsCustom}
              productDimensions={productDimensions}
            />
          </MDBCol>
          <MDBCol size="12" lg="3" className={"controls px-5"}>
            <MDBRow>
              <MDBCol className="my-3 px-0">
                <MDBTypography
                  className={`${((isMobile && "fwregular") || "").trim()}`}
                  tag="h1"
                >
                  Monogram
                </MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className={`${(!isMobile && "my-3") || ""} px-0`}>
                <MDBRow className={`mb-3`}>
                  <MDBCol size="12" lg="6">
                    {(isMobile && (
                      <MDBTypography tag="h1" className="mb-3">
                        {productName}
                      </MDBTypography>
                    )) || (
                      <MDBTypography tag="h3" className="text-default fwthin">
                        Product Sku
                      </MDBTypography>
                    )}
                  </MDBCol>
                  <MDBCol
                    size="12"
                    lg="6"
                    className={`${(isMobile && "text-left") || "text-right"}`}
                  >
                    {(isMobile && (
                      <MDBTypography
                        tag="h3"
                        className="fwregular text-gray-darken-3 mb-3"
                      >
                        {sku}
                      </MDBTypography>
                    )) || (
                      <MDBTypography
                        tag="h1"
                        className="text-default fwregular"
                      >
                        {sku}
                      </MDBTypography>
                    )}
                  </MDBCol>
                </MDBRow>
                {/* <MDBRow className="my-3">
                  <MDBCol size="6">
                    <MDBTypography tag="h1" className="text-default fwregular">
                     $X,XXX 
                    </MDBTypography>
                  </MDBCol>
                </MDBRow> */}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol
                size="12"
                className="border border-default border-thic rounded"
                style={{
                  boxShadow: "0px 6px 4px #00000033",
                }}
              >
                <MDBRow>
                  <MDBCol size="12">
                    <Step
                      step="1"
                      title="Select Model"
                      current={selectionsAndSelectors.model}
                      options={modelsArray}
                      setOption={selectionsAndSelectors.setModel}
                    />
                    <>
                      {selectionsAndSelectors.model?.striping && (
                        <Step
                          step="2"
                          title="Select Striping"
                          current={selectionsAndSelectors.striping}
                          options={stripingArray}
                          setOption={selectionsAndSelectors.setStriping}
                        />
                      )}
                      <Step
                        step="3"
                        title="Select Finish"
                        current={selectionsAndSelectors.finish}
                        options={finishesArray}
                        setOption={selectionsAndSelectors.setFinish}
                      />
                      {/*<Step*/}
                      {/*  step="4"*/}
                      {/*  title="Select Floor"*/}
                      {/*  current={selectionsAndSelectors.floor}*/}
                      {/*  options={floorArray}*/}
                      {/*  setOption={selectionsAndSelectors.setFloor}*/}
                      {/*/>*/}
                    </>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            {showPdp && (
              <>
                <ProductPage
                  data={{ urlResolver: { sku: sku } }}
                  isCustom={true}
                  player={player}
                  frame={selectionsAndSelectors.model}
                  noImage={true}
                  selectionsAndSelectors={selectionsAndSelectors}
                  queryPathFabrics={queryPathFabrics}
                  checkBoxOptionsCustom={checkBoxOptionsCustom}
                  selectBoxOptionsCustom={setCheckBoxOptionsCustom}
                />
              </>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <PromosBYO />
    </>
  );
};

export const CustomDisplay = React.forwardRef(_CustomDisplay);
