import types from "./types";
import getCacheVersion from "./reducers/getCacheVersion";
import setCacheVersion from "./reducers/setCacheVersion";
import setFlag from "./reducers/setFlag";

// Main reducer
const commonReducer = (state, action) => {
  if (state === undefined) {
    return {};
  }

  if (action.type === types.GET_CACHE_VERSION) {
    return getCacheVersion(state, action);
  }

  if (action.type === types.SET_CACHE_VERSION) {
    return setCacheVersion(state, action);
  }

  if (action.type === types.SET_FLAG) {
    return setFlag(state, action);
  }

  return state;
};

export default commonReducer;
