import { customerInitialState } from "./data/customer";
import { queriesInitialState } from "./data/queries";
import { cacheInitialState } from "./data/cache";
import { searchInitialState } from "./data/search";
import { productInitialState } from "./data/product";
import { compareInitialState } from "./data/compare";
import { wishlistInitialState } from "./data/wishlist";
import { isEmpty } from "core/helpers/functions";

// defining initial state
let customerState,
  queriesState,
  cacheState,
  searchState,
  productState,
  compareState,
  wishlistState;

export function getCustomerState(storeState) {
  if (!isEmpty(storeState) && !isEmpty(storeState.customer)) {
    customerState = storeState.customer;
  } else {
    customerState = customerInitialState;
  }
  return customerState;
}

export function getQueriesState(storeState) {
  if (!isEmpty(storeState) && !isEmpty(storeState.queries)) {
    queriesState = storeState.queries;
  } else {
    queriesState = queriesInitialState;
  }
  return queriesState;
}

export function getCacheState(storeState) {
  if (!isEmpty(storeState) && !isEmpty(storeState.cache)) {
    cacheState = storeState.cache;
  } else {
    cacheState = cacheInitialState;
  }

  return cacheState;
}

export function getSearchState(storeState) {
  if (!isEmpty(storeState) && !isEmpty(storeState.search)) {
    searchState = storeState.search;
  } else {
    searchState = searchInitialState;
  }

  return searchState;
}

export function getProductState(storeState) {
  if (!isEmpty(storeState) && !isEmpty(storeState.product)) {
    productState = storeState.product;
  } else {
    productState = productInitialState;
  }

  return productState;
}

export function getCompareState(storeState) {
  if (!isEmpty(storeState) && !isEmpty(storeState.compare)) {
    compareState = storeState.compare;
  } else {
    compareState = compareInitialState;
  }

  return compareState;
}

export function getWishlistState(storeState) {
  if (!isEmpty(storeState) && !isEmpty(storeState.wishlist)) {
    wishlistState = storeState.wishlist;
  } else {
    wishlistState = wishlistInitialState;
  }

  return wishlistState;
}
