import Icon from "app/assets/icon/icon";
import apolloClient from "core/graphql/apolloClient";
import { useBoolean } from "ahooks";
import { number_format } from "core/components/price/price";
import { determineCurrency } from "config/currency";
import { CustomerCartQuery, ProductInterface } from "app/generated/graphql";
import { ICartContext } from "app/layout/cart/CartContext/CartContext";
import ModalHeader from "app/layout/ModalHeader";
import clsx from "clsx";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
  MDBModal,
  MDBModalBody,
} from "mdbreact";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DownloadPDF from "./components/DownloadPDF";
import Item from "./components/Item";
import MobileCartItem from "./components/MobileItem";
import { cartPaths, headers } from "./config";
import { DomainCartFragment, ItemProps } from "./models";
import { isDomainCartItem } from "./utils";
import queryLoader from "app/graphql/queryLoader";
import themeConfiguration from "config/themeConfiguration";

type Size = React.ComponentProps<typeof MDBCol>["size"];

const Cart: React.FC<
  {
    cart: CustomerCartQuery["customerCart"];
    isMobile: boolean;
    onRemoveAll: () => void;
    isRemovingAll: boolean;
    comment: any;
    onCommentChange: (
      sku: ProductInterface["sku"]
    ) => ItemProps["onCommentChange"];
    onDocumentUpload: (
      sku: ProductInterface["sku"]
    ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    isUploadingDocument: boolean;
    cartQuery: any;
    removeDocumentTrigger: boolean;
    onRemoveDocument: (
      sku: ProductInterface["sku"]
    ) => ItemProps["onRemoveDocument"];
  } & Pick<ICartContext, "orderItemDataRecord">
> = (props) => {
  const buttonSize = React.useMemo(() => (props.isMobile ? "lg" : undefined), [
    props.isMobile,
  ]);
  let comment: any = Object.values(props.orderItemDataRecord).map(
    (item: any) => {
      return item.comment;
    }
  );

  const history = useHistory();
  const addCustomerQuoteItemAttributes = () => {
    let orderItemDataRecordCopy: any = props.orderItemDataRecord;
    let quote_items = [];
    for (const i in orderItemDataRecordCopy) {
      const isInArray = quote_items.some(
        // @ts-ignore
        (item) => item.item_id === orderItemDataRecordCopy
      );

      const itemInCart = props.cart.items?.find(
        (item) => item?.product.sku === i
      );

      if (!isInArray) {
        let document_names = [...orderItemDataRecordCopy[i].document_names];
        document_names.map((dn) => {
          if (dn.__typename) {
            delete dn.__typename;
          }
          return dn;
        });

        let item = {
          // @ts-ignore
          item_id: +i,
          comment: orderItemDataRecordCopy[i].comment,
          document_names,
        };

        quote_items.push(item);
      }
    }

    let input1 = {
      quote_id: props.cart.id,
      quote_items,
    };

    if (input1.quote_items.length > 0) {
      apolloClient.mutate({
        mutation: queryLoader("addCustomerQuoteItemAttributes"),
        variables: { input: input1 },
      });
    }
  };

  useEffect(() => {
    addCustomerQuoteItemAttributes();
  }, [props.removeDocumentTrigger]);

  const submit = (
    <MDBBtn
      size={buttonSize}
      className={`fwregular m-0`}
      onClick={async () => {
        await addCustomerQuoteItemAttributes();
        try {
          history.push(cartPaths.children.submit.path);
        } catch (error) {
          console.log(error);
        }
      }}
    >
      Submit Quote
    </MDBBtn>
  );
  const [removeItemModal, setRemoveItemModal] = useBoolean();
  let [subTotal, setSubTotal] = useState(0);
  let total = 0;
  let currency = 0;
  let itemQty;
  useEffect(() => {
    props?.cart?.items?.map((item) => {
      itemQty = item?.quantity;
      //@ts-ignore
      currency = item?.prices?.price?.currency;
      //@ts-ignore
      total += item?.prices?.price?.value * itemQty;
    });
    setSubTotal(determineCurrency(currency) + number_format(total, 2));
  }, [props?.cart?.items]);
  return (
    <MDBContainer className="cart-page mt-4 pb-5 mb-5 py-4 py-lg-0">
      {(props.cart.items !== undefined && props.cart.items?.length !== 0 && (
        <>
          <div className="mb-lg-5 pb-lg-4 ml-lg-0 d-flex cart-page-toolbar">
            <div className="grow">
              <MDBTypography
                className="text-nowrap mb-5 mb-lg-0"
                tag="h1"
                variant="h1"
              >
                Quote Request
              </MDBTypography>
            </div>
            {props.cart.items?.length !== 0 && (
              <div className="cart-page-toolbar-buttons">
                <MDBRow className="cart-page-toolbar-buttons-container align-items-center">
                  <MDBCol size="12" md="6">
                    <DownloadPDF
                      comment={comment}
                      size={buttonSize}
                      items={
                        ({
                          items: props.cart.items?.map((item, index) => ({
                            ...item,
                            comment: comment[index],
                          })),
                        } as unknown) as DomainCartFragment
                      }
                      quantityPerPage={props.cart.items?.length}
                    />
                  </MDBCol>
                  <MDBCol size="12" md="6" className="continue-btn-wrapper">
                    {submit}
                  </MDBCol>
                </MDBRow>
              </div>
            )}
          </div>
          <MDBRow className="content-container">
            <MDBCol size="12" className="items-table">
              {!props.isMobile && (
                <>
                  <div className="border-bottom border-default mb-3">
                    <MDBRow>
                      <MDBCol size={headers[0].size.toString() as Size}>
                        {/* @ts-ignore */}
                        <MDBTypography className="pb-4" variant="body-2">
                          {headers[0].title}
                        </MDBTypography>
                      </MDBCol>
                      <MDBCol size={(12 - headers[0].size).toString() as Size}>
                        <MDBRow className="h-100">
                          {headers
                            .filter((header) => {
                              if (
                                themeConfiguration.magento_url ===
                                "https://fairfieldm2.joladev.com/"
                              ) {
                                return (
                                  header.title !== "Unit Price" &&
                                  header.title !== "Subtotal"
                                );
                              }
                              return header;
                            })
                            .slice(1)
                            .map((header, i, a) => {
                              return (
                                <MDBCol
                                  key={header.title}
                                  size={header.size.toString() as Size}
                                  className={clsx("d-flex", {
                                    "justify-content-end": a.length - 1 === i,
                                  })}
                                >
                                  {/* @ts-ignore */}
                                  <MDBTypography
                                    // @ts-ignore
                                    variant="body-2"
                                    className="pb-4"
                                  >
                                    {header.title}
                                  </MDBTypography>
                                </MDBCol>
                              );
                            })}
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </div>
                  <MDBRow className="cart-items-listing-container">
                    {props.cart.items
                      ?.filter(isDomainCartItem)

                      .map((item, index) => {
                        const orderItemData = item.id
                          ? props.orderItemDataRecord[item.id] ?? {}
                          : {};
                        return (
                          //@ts-ignore
                          <Item
                            index={index}
                            key={item.id}
                            item={item}
                            cart={props.cart}
                            comment={orderItemData.comment ?? undefined}
                            onCommentChange={props.onCommentChange(item.id)}
                            documentNames={
                              orderItemData.document_names?.filter(
                                (name): name is string => !!name
                              ) ?? []
                            }
                            onDocumentUpload={props.onDocumentUpload(item.id)}
                            onRemoveDocument={props.onRemoveDocument(item.id)}
                            isUploading={props.isUploadingDocument}
                            cartQuery={props.cartQuery}
                          />
                        );
                      })}
                  </MDBRow>
                </>
              )}
              {props.isMobile && (
                <>
                  <MDBRow>
                    <MDBCol
                      size="12"
                      className="mobile-title pb-5 border-bottom"
                    />
                  </MDBRow>
                  <MDBRow className="cart-items-listing-container">
                    {props.cart.items
                      ?.filter(isDomainCartItem)
                      .map((item, index) => {
                        const orderItemData = item.id
                          ? props.orderItemDataRecord[item.id] ?? {}
                          : {};
                        return (
                          //@ts-ignore
                          <MobileCartItem
                            index={index}
                            key={item.id}
                            cart={props.cart}
                            item={item}
                            comment={orderItemData.comment ?? undefined}
                            onCommentChange={props.onCommentChange(item.id)}
                            // @ts-ignore
                            documentNames={
                              orderItemData.document_names?.filter(
                                // @ts-ignore
                                (name) => !!name.file_name
                              ) ?? []
                            }
                            onDocumentUpload={props.onDocumentUpload(item.id)}
                            onRemoveDocument={props.onRemoveDocument(item.id)}
                            isUploading={props.isUploadingDocument}
                            cartQuery={props.cartQuery}
                          />
                        );
                      })}
                  </MDBRow>
                </>
              )}
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol className={`border-bottom py-5`}>
              <MDBRow className={`mb-4 justify-content-end`}>
                <MDBCol size="6" md="3">
                  <MDBTypography
                    tag="h2"
                    className={`text-gray-darken-2 text-right`}
                  >
                    Item(s)
                  </MDBTypography>
                </MDBCol>
                <MDBCol size="6" md="3">
                  <MDBTypography
                    tag="h2"
                    className={`text-gray-darken-2 text-center`}
                  >
                    QTY {props?.cart?.items?.length}
                  </MDBTypography>
                </MDBCol>
              </MDBRow>
              {themeConfiguration.magento_url !==
                "https://fairfieldm2.joladev.com/" && (
                <MDBRow className={`justify-content-end`}>
                  <MDBCol size="6" md="3">
                    <MDBTypography tag="h2" className={`text-right`}>
                      Quote Subtotal
                    </MDBTypography>
                  </MDBCol>
                  <MDBCol size="6" md="3">
                    <MDBTypography tag="h2" className={`text-center`}>
                      {subTotal}
                    </MDBTypography>
                  </MDBCol>
                </MDBRow>
              )}
            </MDBCol>
          </MDBRow>
          {props.cart.items !== undefined && props.cart.items?.length !== 0 && (
            <>
              <MDBRow className="mt-5 pt-3 mb-md-5 pb-5">
                <MDBCol className="mb-5 d-flex justify-content-between flex-wrap flex-column flex-md-row">
                  <MDBRow>
                    <MDBCol>
                      <MDBBtn
                        size={buttonSize}
                        className="icon icon-left remove-all-items m-0"
                        color="secondary"
                        onClick={setRemoveItemModal.setTrue}
                        disabled={props.isRemovingAll}
                      >
                        <Icon icon="closeDefault" /> Remove All
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="align-items-center flex-wrap pdf_button">
                    <MDBCol size="12" md="6">
                      <DownloadPDF
                        comment={comment}
                        size={buttonSize}
                        items={
                          ({
                            items: props.cart.items?.map((item, index) => ({
                              ...item,
                              comment: comment[index],
                            })),
                          } as unknown) as DomainCartFragment
                        }
                        quantityPerPage={props.cart.items?.length}
                      />
                    </MDBCol>
                    <MDBCol size="12" md="6" className="continue-btn-wrapper">
                      {submit}
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBModal
                className={`remove-from-quote-modal`}
                isOpen={removeItemModal}
                inline={false}
                overflowScroll
                noClickableBodyWithoutBackdrop={false}
                centered
              >
                <MDBModalBody className="p-0">
                  <ModalHeader
                    //@ts-ignore
                    onClose={setRemoveItemModal.setFalse}
                  >
                    Remove from Quote Request
                  </ModalHeader>
                  <MDBRow className="mt-5 mx-5 px-sm-5 mb-5 pb-5">
                    <MDBCol>
                      {/* @ts-ignore */}
                      <MDBTypography
                        className="text-center mb-5"
                        // @ts-ignore
                        variant="body-2"
                      >
                        Are you sure that you want to remove all products from
                        the Quote Request?
                      </MDBTypography>
                      <MDBRow>
                        <MDBCol size="6">
                          <MDBBtn
                            onClick={setRemoveItemModal.setFalse}
                            className="m-0 w-100"
                            color="secondary"
                          >
                            Cancel
                          </MDBBtn>
                        </MDBCol>
                        <MDBCol size="6">
                          <MDBBtn
                            className="m-0 w-100"
                            onClick={() => {
                              props.onRemoveAll();
                              setRemoveItemModal.setFalse();
                            }}
                            onKeyUp={() => {
                              props.onRemoveAll();
                              setRemoveItemModal.setFalse();
                            }}
                          >
                            Confirm
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                </MDBModalBody>
              </MDBModal>
            </>
          )}
        </>
      )) || (
        <MDBRow>
          <MDBCol>
            <MDBTypography
              className="text-nowrap mb-5 mb-lg-0"
              tag="h1"
              variant="h1"
            >
              Quote Request
            </MDBTypography>
            <MDBTypography tag="h4" className={`fwregular text-default my-5`}>
              There are no products currently in Quote Request
            </MDBTypography>
          </MDBCol>
        </MDBRow>
      )}
    </MDBContainer>
  );
};

export default Cart;
