import React, { useMemo, useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/react-hooks";
import { MDBBox, MDBCol, MDBRow, MDBTypography, MDBContainer } from "mdbreact";
import GalleryWidget from "app/pages/Gallery/galleryWidget";
import { getRoomScenesQuery } from "./query";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import MagicSliderDots from "react-magic-slider-dots";
import GalleryModal from "app/pages/Gallery/GalleryModal";

function RoomScenes({ product }) {
  const {
    data: getRoomScenesData,
    loading: roomScenesLoading,
    error,
  } = useQuery(getRoomScenesQuery, {
    variables: { product_sku: product?.sku },
    skip: !product?.sku,
  });

  let [category] = useState("All");
  const [selectedGallery, setSelectedGallery] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const mainSlider = useRef(Slider);
  let galleryData = useMemo(() => {
    if (!getRoomScenesData) return [];
    let galleries = getRoomScenesData?.getRoomScenes;
    let categories = [];
    if (galleries) {
      galleries.forEach((g) => {
        g.category.forEach((c) => {
          if (!categories.includes(c)) {
            categories.push(c);
          }
        });
      });
    }
    return { galleries, categories };
  }, [getRoomScenesData]);

  useEffect(() => {
    if (selectedGallery) setPopupVisible(true);
  }, [selectedGallery]);

  useEffect(() => {
    if (!popupVisible) setSelectedGallery(null);
  }, [popupVisible]);

  const sliderProps = {
    className: "room-scenes-slider",
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    controls: false,
    arrows: true,
    dots: true,
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={30} />;
    },
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  let galleriesData = galleryData?.galleries?.filter((gallery, i) => {
    if (gallery.category.includes(category)) {
      if (gallery.parent_id === null || gallery.parent_id === "0")
        return gallery;
    }
  });

  return (
    <>
      {getRoomScenesData?.getRoomScenes?.length ? (
        <MDBContainer className="room-scenes-container mb-5">
          <MDBRow className="w-100 m-0 mb-4">
            <MDBCol className="d-none d-lg-block px-0">
              <MDBBox className="h-50 border-bottom border-default w-100" />
            </MDBCol>
            <MDBCol className="d-flex justify-content-center p-0 align-items-center">
              <MDBTypography
                className="font-weight-light"
                tag="h2"
                variant="display-2"
              >
                Room Scenes
              </MDBTypography>
            </MDBCol>
            <MDBCol className="d-none d-lg-block px-0">
              <MDBBox className="h-50 border-bottom border-default w-100" />
            </MDBCol>
          </MDBRow>
          {(galleryData?.galleries?.length !== 0 && (
            <MDBRow className="py-5 gallery-items-wrapper ">
              <>
                <MDBCol size="12">
                  <Slider {...sliderProps} ref={mainSlider}>
                    {galleriesData?.map((gallery, i) => (
                      <>
                        <GalleryWidget
                          {...gallery}
                          galleries={galleryData?.galleries}
                          key={gallery?.id}
                          pdp={true}
                          setSelectedGallery={setSelectedGallery}
                        />
                      </>
                    ))}
                  </Slider>
                  {/* <div className="gallery-images">
                    <Slider {...imagesSliderProps}>
                      {galleriesData?.map((galleryItem, i) => (
                        <img
                          onClick={() =>
                            mainSlider?.current &&
                            mainSlider.current.slickGoTo(i)
                          }
                          src={galleryItem?.image}
                          key={galleryItem?.id}
                          alt={galleryItem?.name}
                        />
                      ))}
                    </Slider>
                  </div> */}
                </MDBCol>
              </>
            </MDBRow>
          )) || (
            <MDBBox className={`mb-5 mt-5`}>
              <Skeleton width={`100%`} height={`50rem`} />
            </MDBBox>
          )}
        </MDBContainer>
      ) : null}
      <GalleryModal
        popupVisible={popupVisible}
        setPopupVisible={setPopupVisible}
        path={selectedGallery?.path}
        bottomGalleryImages={selectedGallery?.bottomGalleryImages}
        image={selectedGallery?.image}
      />
    </>
  );
}

export default RoomScenes;
