import { MDBContainer } from "mdbreact";
import React, { useRef } from "react";

export const TooltipContent = (props) => {
  const ref = useRef(null);
  // const onClickOutside = useCallback(
  //   (e) => {
  //     if (!ref.current?.contains(e.target)) props.setVisibleContent(false);
  //   },
  //   [props]
  // );

  // useEffect(() => {
  //   document.addEventListener("onmouseleave", onClickOutside);
  //   return () => {
  //     document.removeEventListener("onmouseleave", onClickOutside);
  //   };
  // }, [onClickOutside]);
  return (
    <>
      {props.visibleContent && (
        <div
          className={`tooltip-content ${props.positionY && props.positionY} ${
            props.positionX && props.positionX
          }`}
          ref={ref}
        >
          {React.cloneElement(props.children)}
          {props.hasTickArrow && (
            <div className="tooltip-tick-arrow">
              <div className="inner-tick"></div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const TooltipTriggerElement = (props) => {
  return (
    <MDBContainer
      className="p-0 tooltip-trigger-element"
      onMouseEnter={() => {
        props.setVisibleContent(true);
      }}
      onMouseLeave={() => props.setVisibleContent(false)}
    >
      {React.cloneElement(props.children)}
    </MDBContainer>
  );
};

const Tooltip = (props) => {
  return (
    <MDBContainer className={`p-0 tooltip-container ${props.className}`}>
      {React.cloneElement(props.children)}
    </MDBContainer>
  );
};

export default Tooltip;
