import types from "./types";
import searchPageSize from "./reducers/searchPageSize";
import searchCurrentPage from "./reducers/searchCurrentPage";
import searchTotalPages from "./reducers/searchTotal";
import searchSetData from "./reducers/searchSetData";

const searchReducer = (state, action) => {
  if (state === undefined) {
    return {};
  }
  if (action.type === types.SEARCH_PAGE_SIZE) {
    return searchPageSize(state, action);
  }

  if (action.type === types.SEARCH_CURRENT_PAGE) {
    return searchCurrentPage(state, action);
  }

  if (action.type === types.SEARCH_TOTAL_PAGES) {
    return searchTotalPages(state, action);
  }
  if (action.type === types.SEARCH_SET_DATA) {
    return searchSetData(state, action);
  }
  return state;
};

export default searchReducer;
