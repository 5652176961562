// @ts-check

import completeIcon from "app/assets/icon/complete.svg";
import clsx from "clsx";
import { MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React from "react";

/**
 * @typedef {object} StepProps
 * @property {string} icon
 * @property {string} caption
 * @property {() => void} onClick
 * @property {"active" | "complete"} [status]
 *
 * @param {StepProps} props
 */
const Step = ({ icon, caption, status, onClick }) => {
  const active = status === "active";
  const complete = status === "complete";
  return (
    <MDBRow
      className={clsx("step", "d-flex", "py-4", "h-100", "cursor-pointer", {
        active,
      })}
      onClick={onClick}
    >
      <MDBCol
        className={clsx(
          "d-flex",
          "justify-content-center",
          "align-items-center",
          "flex-column",
          "flex-md-row",
          "h-100"
        )}
      >
        <img src={complete ? completeIcon : icon} alt="Step icon" />
        <MDBTypography
          tag="h3"
          className={clsx("mt-3 mt-md-0 ml-md-4 text-center", {
            "text-white": active,
          })}
        >
          {caption}
        </MDBTypography>
      </MDBCol>
    </MDBRow>
  );
};

export default Step;
