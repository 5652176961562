import { customerOrdersUpload } from "app/utils/upload";
import { AxiosResponse } from "axios";
import { messagesActions } from "core/state/redux/data/messages";
import React from "react";
import { MutationResult } from "react-apollo-hooks";

type ResultState<Data> =
  | null
  | { data: Data }
  | { error: Error }
  | { loading: true };

export type Result<Data> = Pick<
  MutationResult<Data>,
  "data" | "error" | "loading"
>;

export type CustomerOrdersUploadData = AxiosResponse<string>;

export const useCustomerOrdersUpload = () => {
  const [result, setResult] = React.useState<
    ResultState<CustomerOrdersUploadData>
  >(null);

  return [
    React.useCallback((file: File) => {
      setResult({ loading: true });

      return customerOrdersUpload(file)
        .then((data: CustomerOrdersUploadData) => {
          messagesActions.addMessage("File uploaded.", "success");

          setResult({ data });

          return data;
        })
        .catch((error: Error) => {
          messagesActions.addMessage("File upload failed.", "error");

          setResult({ error });

          throw error;
        });
    }, []),
    {
      data: result && "data" in result ? result.data : null,
      loading: !!result && "loading" in result,
      error: result && "error" in result ? result.error : null,
    } as Result<CustomerOrdersUploadData>,
  ] as const;
};
