import { gql } from "graphql.macro";

export const GET_STORE_LOCATOR_QUERY = gql`
  {
    getStoreLocator {
      city
      country
      latitude
      longitude
      name
      postcode
      showroom
      state
      store_id
      store_type
      street
      telephone
      website
    }
  }
`;

export const GET_GOOGLE_API_KEY = gql`
  query getGoogleApiKey {
    storeConfig {
      google_api_key
    }
  }
`;
