import React from "react";
import { MDBTypography } from "mdbreact";

function SalesRap({ data }) {
  return (
    <div className="mb-5 pb-5">
      <MDBTypography tag="h4" className="mb-4 pb-2">
        {`Sales Representative(s)`}
      </MDBTypography>
      <div className="data-wrapper">
        {data?.length ? (
          data?.map((input) => (
            <div key={input?.sales_rep_email}>
              <MDBTypography variant="body-6" className="mb-3">
                {input?.sales_rep_name}
              </MDBTypography>
              <MDBTypography variant="body-6" className="mb-3">
                {input?.sales_rep_email}
              </MDBTypography>
            </div>
          ))
        ) : (
          <MDBTypography variant="body-6" className="mb-3">
            No Sales Representative assigned
          </MDBTypography>
        )}
      </div>
    </div>
  );
}

export default SalesRap;
