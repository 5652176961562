import React from "react";
import FinishSidebarQueryContainer from "./finishSidebar-query-container";

export const FinishSidebar = ({
  categories,
  allowedFinishes,
  fabrics,
  ...rest
}) => {
  let displayProps = {
    fabrics,
    categories,
    allowedFinishes,
  };
  return <FinishSidebarQueryContainer {...rest} {...displayProps} />;
};
