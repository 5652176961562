import React, { useEffect } from "react";
import fabrics from "generation/fabrics";
import finishes from "generation/finishes";
import {
  armArray as havenArmArray,
  backArray as havenBackArray,
  baseArray as havenBaseArray,
  frameArray as havenFrameArray,
} from "app/pages/Custom/haven/data";

import {
  armArray as urbanArmArray,
  backArray as urbanBackArray,
  baseArray as urbanBaseArray,
  seatArray as urbanSeatArray,
  frameArray as urbanFrameArray,
} from "app/pages/Custom/urban/data";

import {
  armArray as seniorArmArray,
  baseArray as seniorBaseArray,
  seatAndBackArray as seniorSeatAndBackArray,
  frameArray as seniorFrameArray,
  accessoryArrayLeft as seniorAccessoryArrayLeft,
  accessoryArrayRight as seniorAccessoryArrayRight,
  powerArray as seniorPowerArray,
} from "app/pages/Custom/senior/data";
import {
  modelsArray as monogramModelsArray,
  stripingArray as monogramStripingArray,
  finishesArray as monogramFinishArray,
} from "app/pages/Custom/monogram/data";

import {
  styles as benchMadeBenchesStyle,
  bases as benchMadeBenchesBases,
} from "app/pages/Custom/benchmade-benches/data";

import {
  styles as benchMadeSOStyles,
  bases as benchMadeSOBases,
  tops as benchMadeSOTops,
  nails as benchMadeSONails,
  sizes as benchMadeSOSizes,
} from "app/pages/Custom/benchmade-square-ottomans/data";

import {
  styles as benchMadeROStyles,
  bases as benchMadeROBases,
  tops as benchMadeROTops,
  nails as benchMadeRONails,
} from "app/pages/Custom/benchmade-round-ottomans/data";
import { tops, bases } from "app/pages/Custom/live-edge/data";

const useGetFabricOrFinishFromSku = (sku, callback = () => {}) => {
  const fabricOrFinish =
    fabrics.reduce(
      (fabric, category) =>
        category?.products?.items.find((fab) => fab.sku === sku) || fabric,
      null
    ) ||
    finishes.reduce(
      (fabric, category) =>
        category?.products?.items.find((fab) => fab.sku === sku) || fabric,
      null
    );
  callback(fabricOrFinish);

  return fabricOrFinish;
};

const useOnClickOutside = (ref, setVisible) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

const generateSkuAndProductFromUrl = (url, type) => {
  let urlSku =
    url.indexOf("/") !== -1 ? url.substring(url.lastIndexOf("/") + 1) : url;
  let sku = "";
  let initialProduct = null;

  switch (type) {
    case "Haven":
      const initialHaven = () => {
        const defaultFrame = havenFrameArray[0];
        const defaultBack = havenBackArray[0];
        const defaultArm = havenArmArray[0];
        const defaultBase = havenBaseArray[0];

        const [sku] = urlSku.split("&");
        const [, armId, backId, baseId, , ...frameIds] = sku.split("");
        const frameId = frameIds.join("");
        return {
          frame:
            havenFrameArray.find((frame) => frame.sku === frameId) ??
            defaultFrame,
          back:
            havenBackArray.find((back) => back.id === backId) ?? defaultBack,
          arm: havenArmArray.find((arm) => arm.id === armId) ?? defaultArm,
          base:
            havenBaseArray.find((base) => base.id === baseId) ??
            (frameId === "31" || frameId === "32"
              ? havenBaseArray.find((base) => base.id === "S")
              : defaultBase),
        };
      };
      initialProduct = initialHaven();

      sku =
        "1" +
        initialProduct.arm.id +
        initialProduct.back.id +
        initialProduct.base.id +
        "-" +
        initialProduct.frame.sku;

      break;

    case "Urban":
      const initialUrban = () => {
        const defaultSeat = urbanSeatArray[0];
        const defaultFrame = urbanFrameArray[0];
        const defaultBack = urbanBackArray[0];
        const defaultArm = urbanArmArray[0];
        const defaultBase = urbanBaseArray[0];

        const [sku] = urlSku.split("&");
        const [seatId, armId, backId, baseId, , ...frameIds] = sku.split("");

        const frameId = frameIds.join("");
        return {
          seat:
            urbanSeatArray.find((seat) => seat.id === seatId) ?? defaultSeat,
          frame:
            urbanFrameArray.find((frame) => frame.sku === frameId) ??
            defaultFrame,
          back:
            urbanBackArray.find((back) => back.id === backId) ??
            (frameId === "QS"
              ? urbanBackArray.find((back) => back.id === "B")
              : defaultBack),
          arm:
            urbanArmArray.find((arm) => arm.id === armId) ??
            (frameId === "QS"
              ? urbanArmArray.find((arm) => arm.id === "S")
              : defaultArm),
          base:
            urbanBaseArray.find((base) => base.id === baseId) ??
            (frameId === "QS"
              ? urbanBaseArray.find((base) => base.id === "P")
              : defaultBase),
        };
      };
      initialProduct = initialUrban();

      sku =
        initialProduct.seat?.id +
        initialProduct.arm.id +
        initialProduct.back.id +
        initialProduct.base.id +
        "-" +
        initialProduct.frame.sku;

      break;

    case "Senior":
      const initialSenior = () => {
        const defaultSeatAndBack = seniorSeatAndBackArray[0];
        const defaultFrame = seniorFrameArray[0];
        const defaultArm = seniorArmArray[0];
        const defaultBase = seniorBaseArray[0];
        const defaultAccessoryLeft = "X";
        const defaultAccessoryRight = "X";
        const defaultPowerOption = seniorPowerArray[0];

        let sku;
        let seatAndBackId;
        let armId;
        let baseId;
        let accessId;
        let frameIds;
        let powerOptionId;
        let frameId;

        const findUrlParam = (param) =>
          url?.find((item) => item.includes(param))?.split("=")?.[1];

        if (urlSku.indexOf("S-") === -1) {
          [sku] = urlSku.split("&");
          [seatAndBackId, armId, baseId, accessId, , ...frameIds] = sku.split(
            ""
          );
          frameId = frameIds.join("").substr(0, 2);
        } else {
          sku = urlSku.substr(2);
          frameId = sku.substr(3, 1);
          armId = sku.substr(0, 3);
          powerOptionId = sku.substr(5);
        }

        const frame =
          seniorFrameArray.find((frame) => frame.sku === frameId) ??
          defaultFrame;

        let arm =
          seniorArmArray.find((arm) => {
            if (armId?.length === 1) {
              return arm.id === armId;
            } else {
              return arm.reclinerId === +armId;
            }
          }) ?? defaultArm;

        let powerOption =
          seniorPowerArray.find(
            (powerOption) => powerOption.id === powerOptionId
          ) ?? defaultPowerOption;

        if (frame?.name === "Wall Hugger Recliner with UV-C") {
          arm = urbanArmArray.find((option) => option.id === "M");
          powerOption = seniorPowerArray.find((option) => option.id === "SR");
        } else if (frame?.id === "Z") {
          powerOption = seniorPowerArray.find((option) => option.id === "MR");
        } else if (frame?.id === "K") {
          powerOption = seniorPowerArray.find((option) => option.id === "MR");
        } else if (frame?.id === "F") {
          powerOption = seniorPowerArray.find((option) => option.id === "PL");
        }
        const url = window.location.search?.split("?")?.[1]?.split("&");

        const accessoryLeftId = findUrlParam("accessoryLeft");
        const accessoryRightId = findUrlParam("accessoryRight");
        const powerOptionUrlId = findUrlParam("powerOption");

        return {
          seatAndBack:
            seniorSeatAndBackArray.find(
              (seatAndBack) => seatAndBack.id === seatAndBackId
            ) ?? defaultSeatAndBack,
          frame,
          arm,
          base:
            seniorBaseArray.find((base) => base.id === baseId) ?? defaultBase,
          accessoryLeft:
            accessId === "B" || accessId === "L" || accessoryLeftId
              ? seniorAccessoryArrayLeft?.find(
                  (opt) => opt.id === accessoryLeftId
                ) ?? seniorAccessoryArrayLeft[0]
              : defaultAccessoryLeft,
          accessoryRight:
            accessId === "B" || accessId === "R" || accessoryRightId
              ? seniorAccessoryArrayRight?.find(
                  (opt) => opt.id === accessoryRightId
                ) ?? seniorAccessoryArrayRight[0]
              : defaultAccessoryRight,
          powerOption: powerOptionUrlId
            ? seniorPowerArray.find((option) => option.id === powerOptionUrlId)
            : powerOption,
        };
      };

      initialProduct = initialSenior();

      let accessory =
        (initialProduct.accessoryRight !== "X" &&
          initialProduct.accessoryLeft !== "X" &&
          "B") ||
        (initialProduct.accessoryLeft !== "X" && "L") ||
        (initialProduct.accessoryRight !== "X" && "R") ||
        "X";

      sku = initialProduct.frame.recliner
        ? "S-" +
          (initialProduct.powerOption?.id === "SR"
            ? initialProduct.frame.uvc
              ? 449
              : initialProduct.arm.reclinerId + 5
            : initialProduct.arm.reclinerId) +
          initialProduct.frame.sku +
          "-" +
          initialProduct.powerOption?.id
        : initialProduct.base?.id === "R" || initialProduct.base?.id === "Q"
        ? initialProduct.seatAndBack?.seat?.id +
          initialProduct.arm?.id +
          initialProduct.base?.id +
          accessory +
          "-" +
          initialProduct.frame.sku +
          "-" +
          initialProduct.base.finishId
        : initialProduct.seatAndBack?.seat?.id +
          initialProduct.arm?.id +
          initialProduct.base?.id +
          accessory +
          "-" +
          initialProduct.frame.sku;
      break;

    case "Monogram":
      const initialMonogram = () => {
        const defaultModel = monogramModelsArray[0];

        const modelId = urlSku;
        return {
          model:
            monogramModelsArray.find((model) => model.sku === modelId) ??
            defaultModel,
          striping: monogramStripingArray[0],
          finish: monogramFinishArray[0],
        };
      };

      initialProduct = initialMonogram();

      sku = initialProduct.model.sku;

      break;

    case "BenchmadeRoundOttomans":
      const initialBenchmadeRoundOttomans = () => {
        const defaultTop = benchMadeROTops[0];
        const defaultBase = benchMadeROBases[0];
        const defaultNails = benchMadeRONails[0];
        const defaultFrame = benchMadeROStyles[0];

        const [sku] = urlSku.split("&");
        const [frameId, topId, nailId, baseId, , ...sizeId] = sku.split("");
        const size = sizeId.join("");

        return {
          frame:
            benchMadeROStyles.find((frame) => frame.sku === frameId) ??
            defaultFrame,
          top: benchMadeROTops.find((top) => top.sku === topId) ?? defaultTop,
          nail:
            benchMadeRONails.find((nail) => nail.sku === nailId) ??
            defaultNails,
          base:
            benchMadeROBases.find((base) => base.sku === baseId) ?? defaultBase,
          size: size ?? "36",
        };
      };
      initialProduct = initialBenchmadeRoundOttomans();

      sku =
        initialProduct.frame.id +
        initialProduct.top.id +
        initialProduct.nail.id +
        initialProduct.base.id +
        "-" +
        initialProduct.size;

      break;

    case "BenchmadeSquareOttomans":
      const initialBenchmadeSquareOttomans = () => {
        const defaultFrame = benchMadeSOStyles[0];
        const defaultTop = benchMadeSOTops[0];
        const defaultBase = benchMadeSOBases[0];
        const defaultSize = benchMadeSOSizes?.find(
          (size) => size.sku === defaultFrame?.sizes?.[0]
        );
        const defaultNail = benchMadeSONails[0];

        const [sku] = urlSku.split("&");
        let [frameId, topId, nailId, baseId, ...size] = sku.split("");
        size.shift();

        return {
          frame:
            benchMadeSOStyles.find((frame) => frame.sku === frameId) ??
            defaultFrame,
          top:
            benchMadeSOTops.find((frame) => frame.sku === topId) ?? defaultTop,
          size:
            benchMadeSOSizes.find((frame) => frame.sku === size?.join("")) ??
            defaultSize,
          nail:
            benchMadeSONails.find((frame) => frame.sku === nailId) ??
            defaultNail,
          base:
            benchMadeSOBases.find((frame) => frame.sku === baseId) ??
            defaultBase,
        };
      };
      initialProduct = initialBenchmadeSquareOttomans();
      sku =
        initialProduct.frame?.id +
        initialProduct.top?.id +
        initialProduct.nail?.id +
        initialProduct.base?.id +
        "-" +
        initialProduct?.size?.id;

      break;

    case "BenchmadeBenches":
      const initialBenchmadeBenches = () => {
        const defaultFrame = benchMadeBenchesStyle[0];
        const defaultBase = benchMadeBenchesBases[0];

        const [sku] = urlSku.split("&");
        const frameId = sku.substring(0, 3);

        const baseId = sku?.[3];

        return {
          frame:
            benchMadeBenchesStyle.find(
              (frame) => frame.sku.replace("-", "") === frameId
            ) ?? defaultFrame,
          base: sku?.includes("X")
            ? defaultBase
            : benchMadeBenchesBases.find((base) => base.sku === baseId) ??
              defaultBase,
        };
      };
      initialProduct = initialBenchmadeBenches();

      sku = `${initialProduct?.frame?.id.replace("-", "")}${
        initialProduct?.base?.id
      }-10`;

      break;
    case "LiveEdge":
      const initLiveEdge = () => {
        const defaultTop = urlSku
          ? tops?.find((top) => top.sku === urlSku)
          : tops[0];
        const search = new URLSearchParams(window.location.search);
        const baseSku = search.get("sku") || defaultTop?.relatedsku[0];
        const defaultBase = bases?.find((base) => base.sku === baseSku);

        return {
          top: defaultTop,
          base: defaultBase,
        };
      };

      initialProduct = initLiveEdge();
      sku = `${initialProduct?.top?.sku}`;
      break;
  }

  return { sku, initialProduct };
};

const generate3DImage = async (player, isQuote = false) => {
  if (!player) {
    return undefined;
  }
  let initColor = player.scene.background;
  if (!isQuote) player.updateZoom(4, -1);
  let newColor = "#ffffff"; //change for different color
  player.scene.background.set(newColor);
  if (typeof player.shadowPlane !== "undefined") {
    player.shadowPlane.visible = false;
  }
  if (typeof player.plane !== "undefined") {
    player.plane.visible = false;
  }

  player.update();

  player.scene.background.set(initColor);

  if (typeof player.shadowPlane !== "undefined") {
    player.shadowPlane.visible = true;
  }
  if (typeof player.plane !== "undefined") {
    player.plane.visible = true;
  }

  const rtrVal = await player.container
    .getElementsByTagName("canvas")[0]
    .toDataURL();

  if (rtrVal) {
    return rtrVal;
  } else {
    return undefined;
  }
};

export {
  useGetFabricOrFinishFromSku,
  useOnClickOutside,
  generateSkuAndProductFromUrl,
  generate3DImage,
};
