import gql from "graphql-tag";

/**
 * @typedef {{ company: string; website: string | null, telephone: string, contact_name: string, firstname: string, lastname: string, country_id?: string, street: [string, ...string[]], region: CustomerAddressRegionInput, city: string, postcode: string; default_billing?: boolean; default_shipping?: boolean; email?: string; }} CustomerAddressInput
 */
/**
 * @typedef {{input:{
 *   is_new: boolean,
 *   taxvat: string,
 *   industry_type: number,
 *   business_type: number,
 *   hear_about: string,
 *   tradeshow_specify: string | null,
 *   other_specify: string | null,
 *   firstname: string,
 *   lastname: string,
 *   email: string,
 *   phone: string,
 *   password: string,
 *   is_subscribed: boolean,
 *   taxIdDoc: string,
 *   billing_address:CustomerAddressInput,
 *   shipping_address:CustomerAddressInput,
 * }} CustomerInput
 */

export const createCustomerMutation = gql`
  mutation createCustomer(
    $input: CustomerInput!
  ) {
    createCustomer(
      input: $input
    ) {
      customer {
        email
      }
    }
  }
`;
