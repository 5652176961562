import Icon from "app/assets/icon/icon";
import { SortEnum } from "app/generated/graphql";
// import clsx from "clsx";
import React from "react";
import { SortOrder } from "../../models";

export interface SortIconProps {
  sortOrder: SortOrder;
}

const SortIcon: React.FC<SortIconProps> = (props) => {
  return (
    <Icon
      className={`ml-3`}
      // className={clsx("ml-3", { hidden: props.sortOrder === null })}
      icon={props.sortOrder === SortEnum.Asc ? "arrowUp" : "arrowDown"}
    />
  );
};

export default SortIcon;
