import Icon from "app/assets/icon/icon";
import { useBoolean } from "ahooks";
import ModalHeader from "app/layout/ModalHeader";
import {
  MDBTypography,
  MDBModal,
  MDBModalBody,
  MDBCol,
  MDBRow,
  MDBBtn,
} from "mdbreact";
import React from "react";

export interface RemoveItemProps {
  onRemove: () => void;
}

const RemoveItem: React.FC<RemoveItemProps> = (props) => {
  const [removeItemModal, setRemoveItemModal] = useBoolean();
  return (
    <>
      <div
        className="cursor-pointer d-flex align-items-center"
        tabIndex={0}
        onClick={setRemoveItemModal.setTrue}
        role="button"
        style={{ float: "left", marginRight: "20px" }}
      >
        <Icon icon="close" />
        {/* @ts-ignore */}
        <MDBTypography variant="body-6" className="m-0 fs-13 fwregular">
          Delete
        </MDBTypography>
      </div>
      <MDBModal
        className={`remove-from-quote-modal`}
        isOpen={removeItemModal}
        inline={false}
        overflowScroll
        noClickableBodyWithoutBackdrop={false}
        centered
      >
        <MDBModalBody className="p-0">
          <ModalHeader
            //@ts-ignore
            onClose={setRemoveItemModal.setFalse}
          >
            Remove from Quote Request
          </ModalHeader>
          <MDBRow className="mt-5 mx-5 px-sm-5 mb-5 pb-5">
            <MDBCol>
              {/* @ts-ignore */}
              <MDBTypography className="text-center mb-5" variant="body-2">
                Are you sure that you want to remove this product from the Quote
                Request?
              </MDBTypography>
              <MDBRow>
                <MDBCol size="6">
                  <MDBBtn
                    onClick={setRemoveItemModal.setFalse}
                    className="m-0 w-100"
                    color="secondary"
                  >
                    Cancel
                  </MDBBtn>
                </MDBCol>
                <MDBCol size="6">
                  <MDBBtn
                    className="m-0 w-100"
                    onClick={() => {
                      props.onRemove();
                      setRemoveItemModal.setFalse();
                    }}
                    onKeyUp={() => {
                      props.onRemove();
                      setRemoveItemModal.setFalse();
                    }}
                  >
                    Confirm
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
      </MDBModal>
    </>
  );
};

export default RemoveItem;
