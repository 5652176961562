import { AddressCategory } from "./pages/Addresses/Form/config";

export const addressCategories = Object.keys(AddressCategory);

export function isAddressCategory(
  category: string
): category is AddressCategory {
  return addressCategories.includes(category);
}

export function isBillingCategory(
  category: string
): category is AddressCategory.mailing {
  return category === AddressCategory.mailing;
}

export function isShippingCategory(
  category: string
): category is typeof AddressCategory.shipping {
  return category === AddressCategory.shipping;
}
