import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import Skeleton from "react-loading-skeleton";

const FabricPdpSkeleton = () => {
  return (
    <MDBContainer className="fabric-pdp-skeleton-wrapper my-5">
      <MDBRow className="fabric-pdp-skeleton-image-and-details-wrapper">
        <MDBCol lg="7" className="fabric-pdp-skeleton-image">
          <Skeleton height={`56rem`} width={`90%`} />
        </MDBCol>
        <MDBCol lg="5" className="fabric-pdp-skeleton-details pl-5">
          <MDBRow className="fabric-pdp-skeleton-details-name">
            <MDBCol>
              <Skeleton height={`3.2rem`} width={`100%`} />
            </MDBCol>
          </MDBRow>
          <MDBRow className="fabric-pdp-skeleton-details-stock pt-3">
            <MDBCol>
              <Skeleton height={`1.8rem`} width={`100%`} />
            </MDBCol>
          </MDBRow>
          <MDBRow className="fabric-pdp-skeleton-details-pricing_yard pt-3">
            <MDBCol>
              <Skeleton height={`1.8rem`} width={`100%`} />
            </MDBCol>
          </MDBRow>
          <MDBRow className="fabric-pdp-skeleton-details-overall pt-3">
            <MDBCol>
              <Skeleton height={`4rem`} width={`100%`} />
            </MDBCol>
          </MDBRow>
          <MDBRow className="fabric-pdp-skeleton-details-details pt-3">
            <MDBCol>
              <Skeleton height={`4rem`} width={`100%`} />
            </MDBCol>
          </MDBRow>
          <MDBRow className="fabric-pdp-skeleton-slider">
            <MDBCol>
              <Skeleton height={`28.4rem`} width={`100%`} />
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
      <MDBRow className="fabric-pdp-skeleton-action-bar">
        <MDBCol>
          <Skeleton height={`10.3rem`} width={`100%`} />
        </MDBCol>
      </MDBRow>
      <MDBContainer className="fabric-pdp-skeleton-colorways">
        <MDBRow>
          <MDBCol className="fabric-pdp-skeleton-related-title pl-0 pr-0">
            <Skeleton height={`3.2rem`} width={`100%`} />
          </MDBCol>
        </MDBRow>
        <MDBRow className="fabric-pdp-skeleton-colorways-items">
          <MDBCol lg="3" className="fabric-pdp-skeleton-colorways-item">
            <MDBContainer className="fabric-pdp-skeleton-colorways-item-image">
              <MDBCol className="p-0">
                <Skeleton height={`27rem`} width={`100%`} />
              </MDBCol>
            </MDBContainer>
            <MDBContainer className="fabric-pdp-skeleton-colorways-item-sku pt-4">
              <MDBCol className="p-0">
                <Skeleton height={`3.2rem`} width={`100%`} />
              </MDBCol>
            </MDBContainer>
          </MDBCol>
          <MDBCol lg="3" className="fabric-pdp-skeleton-colorways-item">
            <MDBContainer className="fabric-pdp-skeleton-colorways-item-image">
              <MDBCol className="p-0">
                <Skeleton height={`27rem`} width={`100%`} />
              </MDBCol>
            </MDBContainer>
            <MDBContainer className="fabric-pdp-skeleton-colorways-item-sku pt-4">
              <MDBCol className="p-0">
                <Skeleton height={`3.2rem`} width={`100%`} />
              </MDBCol>
            </MDBContainer>
          </MDBCol>
          <MDBCol lg="3" className="fabric-pdp-skeleton-colorways-item">
            <MDBContainer className="fabric-pdp-skeleton-colorways-item-image">
              <MDBCol className="p-0">
                <Skeleton height={`27rem`} width={`100%`} />
              </MDBCol>
            </MDBContainer>
            <MDBContainer className="fabric-pdp-skeleton-colorways-item-sku pt-4">
              <MDBCol className="p-0">
                <Skeleton height={`3.2rem`} width={`100%`} />
              </MDBCol>
            </MDBContainer>
          </MDBCol>
          <MDBCol lg="3" className="fabric-pdp-skeleton-colorways-item">
            <MDBContainer className="fabric-pdp-skeleton-colorways-item-image">
              <MDBCol className="p-0">
                <Skeleton height={`27rem`} width={`100%`} />
              </MDBCol>
            </MDBContainer>
            <MDBContainer className="fabric-pdp-skeleton-colorways-item-sku pt-4">
              <MDBCol className="p-0">
                <Skeleton height={`3.2rem`} width={`100%`} />
              </MDBCol>
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBContainer>
  );
};
export default FabricPdpSkeleton;
