import Icon from "app/assets/icon/icon";
import Iframe from "react-iframe";
import { useMediaQuery } from "react-responsive";
import { useBoolean } from "ahooks";
import { DomainFileDataFragment } from "app/generated/graphql";
import themeConfiguration from "config/themeConfiguration";
import thumbnailPDFPlaceholder from "app/assets/icon/pdf.svg";
import thumbnailImagePlaceholder from "app/assets/images/productPlaceholderSmall.png";
import modalImagePlaceholder from "app/assets/images/productPlaceholderBig.png";
import Image from "app/layout/image";
import {
  MDBCol,
  MDBRow,
  MDBTypography,
  MDBModalBody,
  MDBModal,
  MDBBox,
} from "mdbreact";
import React, { useState } from "react";

export interface FileDataProps {
  fileData: DomainFileDataFragment;
  isSelected: boolean;
  onToggleFile: () => void;
  onDownloadFile: (id: any) => void;
}

const FileData: React.FC<FileDataProps> = (props) => {
  var date = props.fileData.updated_at;
  let icon =
    (props?.fileData?.thumbnail !== null &&
      themeConfiguration.magento_url + props?.fileData?.thumbnail) ||
    props?.fileData?.thumbnail;

  let title = props.fileData.title;
  let image = props.fileData.file;
  let video = props.fileData.youtube_url;
  //Convert to kB,MB,GB,TB,PB,EB,ZB,YB
  //@ts-ignore
  // function fileSizeSI(size) {
  //   if (size.includes("KB")) return size;
  //   var e = (Math.log(size) / Math.log(1e3)) | 0;
  //   return (
  //     +(size / Math.pow(1e3, e)).toFixed() +
  //     " " +
  //     ("kMGTPEZY"[e - 1] || "") +
  //     "B"
  //   );
  // }

  date = date?.split(" ")[0];
  const [isOpen, { toggle }] = useBoolean();
  const handleToggle = React.useCallback(() => {
    toggle();
  }, [toggle]);
  let isMobile = useMediaQuery({ maxWidth: 767.98 });

  let videoId = video?.substring(video.lastIndexOf("/") + 1, video.length);

  let embedlink = "https://www.youtube.com/embed/" + videoId;

  const [iconActive, setIconActive] = useState(false);
  let fileExtension = image?.split(".").pop();

  return (
    <>
      <MDBRow id="fileData">
        <MDBCol className="mb-5 mb-lg-0">
          <div
            className="pb-5 pb-lg-4 border-bottom-1 file-data-row"
            onMouseEnter={() => setIconActive(true)}
            onMouseLeave={() => setIconActive(false)}
          >
            <MDBRow className="pt-5 pt-lg-4 align-items-center">
              <MDBCol
                size="12"
                md="5"
                lg="5"
                xl="6"
                className={`d-flex justify-content-between resources-item-name-img-column`}
              >
                <div
                  className={`d-flex align-items-center w-100 ${
                    // !props.fileData?.file?.includes("fileUpload") || !videoId
                    !props.fileData?.file?.includes("fileUpload") && !video
                      ? "disabled"
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      fileExtension === "jpg" ||
                      fileExtension === "jpeg" ||
                      fileExtension === "png" ||
                      props.fileData?.youtube_url
                    ) {
                      handleToggle();
                    } else {
                      props.onDownloadFile(props.fileData.file_id);
                    }
                  }}
                >
                  {(icon === null && (
                    <Image
                      //@ts-ignore
                      wrapperClassName={`mr-4 cursor-pointer`}
                      source={
                        (fileExtension === "pdf" && thumbnailPDFPlaceholder) ||
                        thumbnailImagePlaceholder
                      }
                      className={`resources-item-thumbnail`}
                      alt={"placeholder"}
                      loaderAsSkeleton={true}
                    />
                  )) || (
                    <Image
                      //@ts-ignore
                      wrapperClassName={`mr-4 cursor-pointer ${
                        // fileExtension === "mp4" ||
                        (props.fileData?.youtube_url &&
                          "play-button-over-img") ||
                        ""
                      }`.trim()}
                      source={icon}
                      className={`resources-item-thumbnail`}
                      alt={icon}
                      loaderAsSkeleton={true}
                    />
                  )}
                  <MDBBox className={`w-100 mr-3`}>
                    {(title && (
                      <MDBTypography
                        tag="h5"
                        className={`resources-item-title text-primary  cursor-pointer fs-16`}
                      >
                        {title}
                      </MDBTypography>
                    )) || <></>}
                    {(isMobile && (
                      <MDBBox className={`d-flex justify-content-between mt-4`}>
                        {/* @ts-ignore */}
                        <MDBTypography variant="body-1 text-primary">
                          ({props.fileData.size})
                        </MDBTypography>
                        {/* <MDBBox
                          className={`d-flex ${
                            (isMobile && "justify-content-end") ||
                            "justify-content-center"
                          }`}
                        >
                          {props.fileData.divisions?.retail ? (
                            <Icon icon="retail" />
                          ) : null}
                          {props.fileData.divisions?.senior ? (
                            <Icon className="ml-sm-4 ml-1" icon="senior" />
                          ) : null}
                          {props.fileData.divisions?.contract ? (
                            <Icon className="ml-sm-4 ml-1" icon="contract" />
                          ) : null}
                        </MDBBox> */}
                      </MDBBox>
                    )) || <></>}
                  </MDBBox>
                </div>
                {((fileExtension === "jpg" ||
                  fileExtension === "jpeg" ||
                  fileExtension === "png") && (
                  <Icon
                    onClick={handleToggle}
                    className="preview-icon cursor-pointer"
                    icon={(iconActive && "previewActive") || "previewInactive"}
                  />
                )) || <></>}
              </MDBCol>
              <MDBCol size="12" md="7" lg="7" xl="6">
                <MDBRow>
                  <MDBCol
                    className={`d-flex align-items-center ${
                      (isMobile && "justify-content-start") ||
                      "justify-content-end"
                    }`}
                  >
                    {props?.fileData?.file?.includes("fileUpload") ? (
                      <>
                        {(!isMobile &&
                          props.fileData?.file?.includes("fileUpload") && (
                            <Icon
                              onClick={() =>
                                props.onDownloadFile(props.fileData.file_id)
                              }
                              className="mr-4 download-icon cursor-pointer"
                              icon={
                                (iconActive && "downloadDark") || "downloadGray"
                              }
                            />
                          )) || <></>}
                        {(!isMobile && (
                          //@ts-ignore
                          <MDBTypography variant="body-1 text-primary">
                            {props.fileData.size}
                          </MDBTypography>
                        )) || <></>}
                      </>
                    ) : null}
                  </MDBCol>
                  {(!isMobile && (
                    <>
                      {/* <MDBCol
                        className={`d-flex ${
                          (isMobile && "justify-content-end") ||
                          "justify-content-center"
                        }`}
                      >
                        {props.fileData.divisions?.retail ? (
                          <Icon icon="retail" />
                        ) : null}
                        {props.fileData.divisions?.senior ? (
                          <Icon className="ml-sm-4 ml-1" icon="senior" />
                        ) : null}
                        {props.fileData.divisions?.contract ? (
                          <Icon className="ml-sm-4 ml-1" icon="contract" />
                        ) : null}
                      </MDBCol> */}

                      <MDBCol
                        className={`d-flex justify-content-end align-items-center`}
                      >
                        {/* @ts-ignore */}
                        <MDBTypography variant="body-1 text-primary">
                          {date}
                        </MDBTypography>
                      </MDBCol>
                    </>
                  )) || <></>}
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBModal
        inline={false}
        noClickableBodyWithoutBackdrop
        overflowScroll
        isOpen={isOpen}
        toggle={toggle}
        className="resources-preview-modal"
        centered
      >
        <MDBModalBody className="h-100 d-flex flex-column p-0">
          <MDBRow>
            <MDBCol className={`d-flex justify-content-end mr-3`}>
              <MDBBox
                className={`position-absolute z-index-1 cursor-pointer close-btn`}
              >
                <Icon icon="closeWhite" onClick={() => handleToggle()} />
              </MDBBox>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <MDBBox className="p-4 p-sm-5 position-relative d-flex align-items-center justify-content-center">
                {(video !== null && (
                  <Iframe
                    //@ts-ignore
                    url={(video?.includes("embed") && video) || embedlink}
                    //@ts-ignore
                    frameborder="0"
                    allowfullscreen
                    allowtransparency
                    className={`resources-modal-video`}
                  />
                )) || <></>}
                {(image !== themeConfiguration.magento_url &&
                  video === null && (
                    <Image
                      //@ts-ignore
                      source={
                        //@ts-ignore
                        (image?.indexOf(".pdf") !== image?.length - 4 &&
                          image) ||
                        modalImagePlaceholder
                      }
                      className="resources-modal-image"
                      alt={image}
                      loaderAsSkeleton={true}
                    />
                  )) || <></>}
              </MDBBox>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
      </MDBModal>
    </>
  );
};

export default FileData;
