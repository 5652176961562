import { selectLatestLoginMessageText } from "app/state/redux/data/common/selectors";
import { customerActions } from "app/state/redux/data/customer";
import { FormikConfig, useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  initialPasswordValues,
  passwordValidationSchema,
  PasswordValues,
} from "./config";
import WelcomeBack, { WelcomeBackProps } from "./WelcomeBack";

export interface DataContainerProps
  extends Pick<WelcomeBackProps, "emailAndFirstname"> {
  login: () => void;
  onClose: () => void;
}

const DataContainer: React.FC<DataContainerProps> = (props) => {
  const dispatch = useDispatch();
  const loginMessage = useSelector(selectLatestLoginMessageText);

  const passwordFormConfig: FormikConfig<PasswordValues> = React.useMemo(
    () => ({
      initialValues: initialPasswordValues,
      validationSchema: passwordValidationSchema,
      onSubmit: ({ password }, { setSubmitting }) => {
        if (props.emailAndFirstname?.email) {
          dispatch(
            customerActions.loginCustomer(
              props.emailAndFirstname?.email,
              password
            )
          );
        }
        setSubmitting(false);
      },
    }),
    [props, dispatch]
  );
  const form = useFormik(passwordFormConfig);
  const { setFieldError, setSubmitting } = form;

  if (!form.errors.password && loginMessage) {
    setFieldError("password", loginMessage);
  }

  return <WelcomeBack {...props} form={form} />;
};

export default DataContainer;
