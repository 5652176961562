import { MDBTypography } from "mdbreact";
import React from "react";
import ReactLoader from "app/layout/loader";

export interface LoadingWishlistProps { }

const LoadingWishlist: React.FC<LoadingWishlistProps> = (props) => {
  return <MDBTypography><ReactLoader /></MDBTypography>;
};

export default LoadingWishlist;
