import Icon from "app/assets/icon/icon";
import Login from "app/pages/customer/login";
import { createEnterHandler } from "app/utils/utils";
import { ModalConsumer } from "core/components/modal/ModalContext";
import { MDBCol, MDBRow, MDBTypography } from "mdbreact";
import { default as React } from "react";

/**
 *
 * @typedef {{ isLoggedIn: () => boolean, onAccountClick: React.MouseEventHandler, rest: import('app/pages/customer/login/ReduxContainer').LoginReduxContainerProps }} HeaderTopProps
 *
 * @param {HeaderTopProps} props
 */
const Account = ({ isLoggedIn, onAccountClick, ...rest }) => {
  // @ts-check

  return (
    <MDBRow>
      <MDBCol>
        {isLoggedIn() && (
          <div
            className="d-flex align-items-center"
            onClick={onAccountClick}
            onKeyUp={createEnterHandler(onAccountClick)}
            role="button"
            tabIndex={0}
          >
            <Icon icon="account" />
            <MDBTypography
              style={{ cursor: "pointer" }}
              tag="h2"
              className="text-uppercase ml-3 d-inline"
              // @ts-ignore
              variant="display-5"
            />
          </div>
        )}
        {!isLoggedIn() && (
          <>
            <ModalConsumer>
              {({ showModal, hideModal }) => {
                return (
                  <MDBTypography
                    style={{ cursor: "pointer" }}
                    tag="h2"
                    className="text-uppercase mr-3 d-inline"
                    // @ts-ignore
                    variant="display-5"
                    onClick={(/** @type {React.MouseEvent} */ e) => {
                      e.preventDefault();
                      // @ts-ignore
                      showModal(() => {
                        return <Login {...rest} hideModal={hideModal} />;
                      });
                    }}
                  >
                    <Icon icon="lock" />
                  </MDBTypography>
                );
              }}
            </ModalConsumer>
          </>
        )}
      </MDBCol>
    </MDBRow>
  );
};
export default Account;
