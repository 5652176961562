import Icon from "app/assets/icon/icon";
import { MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMediaQuery } from "react-responsive";
import FabricDetail from "./fabricDetail";
import FabricSidebarFooter from "./fabricSidebarFooter";
import FabricSidebarItemContainer from "./fabricSidebarItemContainer";
import FilterOptionsMobile from "./filters/filterTypes/multiSelectFilterItem/filterOptions";
import { CheckBoxContext } from "../checkboxContext";
import { CustomPriceContext } from "../customPriceContext";

export const FabricSidebar = ({
  hideModal,
  updateProps,
  setCustomizationOption,
  removeCustomizationOption,
  option,
  fabricsArray,
  fabricsCategories,
  selectedCategory,
  setSelectedCategory,
  productImage,
  activeFilters,
  setActiveFilters,
  initialAggregations,
  loadingFabricItems,
  selectedFilters,
  setSelectedFilters,
  totalPagesNumber,
  allItems,
  currentPage,
  setCurrentPage,
  configFabricProps,
  frame,
  isCustom,
  optionId,
  fabricOrFinishesSelectedOptions,
  setFabricOrFinishSelectedOptions,
  defaultFabric,
  // fabric,
  setFabric,
  customizationOptions,
  calculatedUpholsteryOptionsFabrics,
  currentOption,
  contrastingIds,
  selectedOption,
  setSelectedOption: selectedOptionState,
}) => {
  const {
    // checkBoxOptions,
    // setCheckBoxOptions,
    // viewMoreOptions,
    // setViewMoreOptions,
    // selectedFinish,
    // setSelectedFinish,
    // selectedContrastWelt,
    setSelectedContrastWelt,
    // selectedContrastButtons,
    setSelectedContrastButtons,
    setSelectedDecorativeCord,
    setSelectedFringe,
    setSelectedThrowPillowContrastWelt,
    setSelectedThrowPillowDecorativeCord,
    setSelectedThrowPillowFringe,
    setSelectedKidneyPillowContrastWelt,
    setSelectedKidneyPillowDecorativeCord,
    setSelectedKidneyPillowFringe,
    preselection,
    setPreselection,
  } = useContext(CheckBoxContext);
  const {
    customizationOptionPrice,
    // upholsteryOptionsFabrics,
    // customer,
    price,
    // setPrice,
    // dropdownPrice,
    // viewMorePdpPrice,
    // contrastWeltPrice,
    trimPrice,
    setTrimPrice,
  } = useContext(CustomPriceContext);
  const [viewDetails, setViewDetails] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: "600px" });
  const reference = useRef(null);
  const [initialMount, setInitialMount] = useState(false);
  const [previousOption, setPreviousOption] = useState(false);
  const [isSelected, setIsSelected] = useState(
    !preselection?.[currentOption.title.toLowerCase().split(" ").join("_")]
      ? false
      : preselection[currentOption.title.toLowerCase().split(" ").join("_")].id
  );

  const selectedFabricOption = React.useMemo(() => {
    if (!option) {
      return null;
    }
    return selectedOption;
  }, [selectedOption]);

  useEffect(() => {
    setSelectedOption(
      preselection?.[option?.title?.toLowerCase().split(" ").join("_") || ""]
        ? preselection[option?.title?.toLowerCase().split(" ").join("_") || ""]
        : option?.title.toLowerCase() !== "base trim" &&
          option?.title.toLowerCase() !== "decorative tape frame" &&
          option?.title.toLowerCase() !== "skirt trim" &&
          option?.title.toLowerCase() !== "nail band" &&
          option?.title.toLowerCase() !== "throw pillow contrast welt" &&
          option?.title.toLowerCase() !== "throw pillow decorative cord" &&
          option?.title.toLowerCase() !== "throw pillow fringe" &&
          option?.title.toLowerCase() !== "kidney pillow contrast welt" &&
          option?.title.toLowerCase() !== "kidney pillow decorative cord" &&
          option?.title.toLowerCase() !== "kidney pillow fringe"
        ? defaultFabric
        : null
    );
  }, []);
  const setSelectedOption = useCallback(
    (option) => {
      updateProps({ selectedOption: option });
      selectedOptionState(option);
    },
    [selectedOptionState, updateProps]
  );
  // const [selectedOption, setSelectedOption] = useState(
  //   preselection
  //     ? preselection
  //     : currentOption?.title.toLowerCase() !== "base trim" &&
  //       currentOption?.title.toLowerCase() !== "decorative type frame" &&
  //       currentOption?.title.toLowerCase() !== "skirt trim" &&
  //       currentOption?.title.toLowerCase() !== "decorative cord"
  //     ? defaultFabric
  //     : null
  // );

  useEffect(() => {
    if (option.title.toLowerCase() === "contrasting welt") {
      setSelectedContrastWelt(selectedOption);
    }
    if (option.title.toLowerCase() === "contrasting buttons") {
      setSelectedContrastButtons(selectedOption);
    }
    if (option.title.toLowerCase() === "decorative cord") {
      setSelectedDecorativeCord(selectedOption);
    }
    if (option.title.toLowerCase() === "fringe") {
      setSelectedFringe(selectedOption);
    }
    if (option.title.toLowerCase() === "throw pillow contrast welt") {
      setSelectedThrowPillowContrastWelt(selectedOption);
    }
    if (option.title.toLowerCase() === "throw pillow decorative cord") {
      setSelectedThrowPillowDecorativeCord(selectedOption);
    }
    if (option.title.toLowerCase() === "throw pillow fringe") {
      setSelectedThrowPillowFringe(selectedOption);
    }
    if (option.title.toLowerCase() === "kidney pillow contrast welt") {
      setSelectedKidneyPillowContrastWelt(selectedOption);
    }
    if (option.title.toLowerCase() === "kidney pillow decorative cord") {
      setSelectedKidneyPillowDecorativeCord(selectedOption);
    }
    if (option.title.toLowerCase() === "kidney pillow fringe") {
      setSelectedKidneyPillowFringe(selectedOption);
    }
    setTimeout(() => {
      if (
        currentOption.field_value.price &&
        selectedOption &&
        !preselection?.[currentOption.title.toLowerCase().split(" ").join("_")]
      ) {
        if (selectedFabricOption?.name !== selectedOption?.name) {
          setTrimPrice(trimPrice + currentOption.field_value.price);
        } else {
          setTrimPrice(currentOption.field_value.price);
        }
      } else if (currentOption.field_value.price) {
        if (!selectedFabricOption) {
          setTrimPrice(0);
        } else {
          setTrimPrice(trimPrice);
        }
      }
      if (
        !selectedOption &&
        preselection?.[
          currentOption.title.toLowerCase().split(" ").join("_")
        ] &&
        currentOption.field_value.price
      ) {
        if (selectedFabricOption?.name !== selectedOption?.name) {
          setTrimPrice(trimPrice - currentOption.field_value.price);
        } else {
          setTrimPrice(0);
        }
      }
    }, 100);
  }, [selectedOption]);

  const onClose = useCallback(
    (hideModalFlag = true) => {
      // if (isSelected === selectedOption?.id) {
      //   removeCustomizationOption(option, fabricOrFinishesSelectedOptions);
      // }
      // setPreselection(selectedOption);
      // if (selectedOption) {
      //   let val = {
      //     id: option.option_id,
      //     value: selectedOption.sku,
      //     fabric: selectedOption,
      //     contrastingIds:
      //       currentOption.title.toLowerCase() === "main fabric"
      //         ? contrastingIds
      //         : false,
      //   };
      //   setCustomizationOption(val);
      //   if (configFabricProps) {
      //     configFabricProps.setConfigFabric(selectedOption.sku);
      //   }
      // } else {
      //   setFabric(null);
      // }
      if (isSelected === selectedOption?.id) {
        removeCustomizationOption(option, fabricOrFinishesSelectedOptions);
      }
      let localPreselection = JSON.parse(JSON.stringify(preselection));
      localPreselection[
        currentOption.title.toLowerCase().split(" ").join("_")
      ] = selectedOption;
      setPreselection(localPreselection);
      if (selectedOption) {
        let val = {
          id: option.option_id,
          value: selectedOption?.sku,
          fabric: selectedOption,
          contrastingIds:
            currentOption.title.toLowerCase() === "main fabric"
              ? contrastingIds
              : false,
        };
        setCustomizationOption(val);
        if (configFabricProps) {
          configFabricProps.setConfigFabric(selectedOption?.sku);
        }
      } else {
        setFabric(null);
        if (previousOption) {
          let localFabricOrFinishesSelectedOptions = [];

          fabricOrFinishesSelectedOptions.forEach((option) => {
            if (option.id !== previousOption) {
              localFabricOrFinishesSelectedOptions.push(option);
            }
          });

          setFabricOrFinishSelectedOptions(
            localFabricOrFinishesSelectedOptions
          );
          setPreviousOption(false);
        }
      }
      if (hideModalFlag) {
        setTimeout(() => {
          hideModal();
        }, 0);
      }
    },
    [
      isSelected,
      selectedOption,
      setPreselection,
      hideModal,
      removeCustomizationOption,
      option,
      fabricOrFinishesSelectedOptions,
      currentOption.title,
      contrastingIds,
      setCustomizationOption,
      configFabricProps,
      setFabric,
      previousOption,
    ]
  );

  useEffect(() => {
    /**
     *  initialMount here might not be needed, but at 17:30 I really am not sure if even I exist anymore.
     *  MOST IMPORTANT: This code works. DO NOT TOUCH THE PREKIDAC OCE STRUJA KILL :D
     */
    if (initialMount) {
      if (option.title.toLowerCase() === "contrasting welt") {
        setSelectedContrastWelt(selectedOption);
      }
      if (option.title.toLowerCase() === "contrasting buttons") {
        setSelectedContrastButtons(selectedOption);
      }
      setTimeout(() => {
        if (
          currentOption.field_value.price &&
          selectedOption &&
          !preselection?.[
            currentOption.title.toLowerCase().split(" ").join("_")
          ]
        ) {
          setTrimPrice(trimPrice + currentOption.field_value.price);
        } else if (currentOption.field_value.price) {
          setTrimPrice(trimPrice);
        }
        if (
          !selectedOption &&
          preselection?.[
            currentOption.title.toLowerCase().split(" ").join("_")
          ] &&
          currentOption.field_value.price
        ) {
          setTrimPrice(trimPrice - currentOption.field_value.price);
        }
      }, 0);
    }
  }, [
    currentOption.field_value.price,
    initialMount,
    option.title,
    preselection?.[currentOption.title.toLowerCase().split(" ").join("_")],
    selectedOption,
  ]);

  useEffect(() => {
    setInitialMount(true);
    return () => {
      setInitialMount(false);
    };
  }, []);

  useEffect(() => {
    const onClickOutside = (e) => {
      window.localStorage.removeItem("ff_pdp_options");
      if (
        e.target.type !== "checkbox" &&
        !isMobile &&
        e.target.getAttribute("id") !== "back-icon-text" &&
        e.target.getAttribute("id") !== "back-icon-img" &&
        e.target.getAttribute("id") !== "filter-close-icon" &&
        !e?.target?.getAttribute("class")?.includes("view-details")
      ) {
        if (!reference.current.contains(e.target)) {
          onClose();
        }
      }
    };
    document.addEventListener("click", onClickOutside);
    return () => {
      document.removeEventListener("click", onClickOutside);
    };
  }, [isMobile, onClose]);

  let nuberOfCharOptionName = option.title.length;
  return (
    <div ref={reference}>
      <MDBRow id="fabric-sidebar">
        <MDBCol className="d-flex flex-column">
          <MDBRow>
            <MDBCol
              className={`bg-default flex-center py-5 sidebar-header ${
                nuberOfCharOptionName > 18 ? "header-bigger-title" : ""
              }`.trim()}
            >
              {viewDetails || (isMobile && selectedFilters) ? (
                <p
                  id="back-icon-text"
                  className="body-3"
                  style={{
                    position: "absolute",
                    top: "calc(50% - 15px)",
                    left: "6%",
                    cursor: "pointer",
                    fontWeight: "400",
                  }}
                >
                  <Icon
                    id="back-icon-img"
                    onClick={() => {
                      if (viewDetails) {
                        setViewDetails(false);
                      } else {
                        setSelectedFilters(false);
                      }
                    }}
                    icon="leftMobileWhite"
                    size="3"
                    style={{
                      marginRight: "1rem",
                    }}
                  />
                  Back
                </p>
              ) : (
                <Icon
                  icon="closeWhite"
                  size="4"
                  style={{
                    position: "absolute",
                    top: "calc(50% - 20px)",
                    left: "5%",
                    cursor: "pointer",
                  }}
                  onClick={onClose}
                />
              )}
              <MDBTypography
                tag="h2"
                className={`body-3 sidebar-title ${
                  nuberOfCharOptionName > 18 ? "bigger-title" : ""
                }`.trim()}
              >
                {viewDetails
                  ? "Details"
                  : selectedFilters && isMobile
                  ? "Filter by"
                  : `Select ${option.title}`}
              </MDBTypography>
            </MDBCol>
          </MDBRow>
          {isMobile && viewDetails && selectedOption ? (
            <FabricDetail fabric={selectedOption} hideModal={onClose} />
          ) : isMobile && selectedFilters ? (
            <FilterOptionsMobile
              selectedFilters={selectedFilters}
              fabricsAggregations={initialAggregations}
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              currentPage={{ currentPage }}
              setCurrentPage={{ setCurrentPage }}
            />
          ) : (
            <FabricSidebarItemContainer
              fabricsArray={fabricsArray}
              fabricsCategories={fabricsCategories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              productImage={productImage}
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              initialAggregations={initialAggregations}
              loadingFabricItems={loadingFabricItems}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              totalPagesNumber={totalPagesNumber}
              allItems={allItems}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              frame={frame}
              isMobile={isMobile}
              preselection={preselection}
              setPreselection={setPreselection}
              isCustom={isCustom}
              configFabricProps={configFabricProps}
              setCustomizationOption={setCustomizationOption}
              hideModal={onClose}
              optionId={optionId}
              fabricOrFinishesSelectedOptions={fabricOrFinishesSelectedOptions}
              isSelected={isSelected}
              setIsSelected={setIsSelected}
              removeCustomizationOption={removeCustomizationOption}
              setFabricOrFinishSelectedOptions={
                setFabricOrFinishSelectedOptions
              }
              defaultFabric={defaultFabric}
              customizationOptions={customizationOptions}
              customizationOptionPrice={customizationOptionPrice}
              calculatedUpholsteryOptionsFabrics={
                calculatedUpholsteryOptionsFabrics
              }
              price={price}
              setSelectedOption={setSelectedOption}
              currentOption={currentOption}
              selectedOption={selectedOption}
              previousOption={previousOption}
              setPreviousOption={setPreviousOption}
              selectedFabricOption={selectedFabricOption}
            />
          )}

          <FabricSidebarFooter
            setViewDetails={setViewDetails}
            isSelected={isSelected}
            allItems={allItems}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            hideModal={onClose}
            defaultFabric={defaultFabric}
            calculatedUpholsteryOptionsFabrics={
              calculatedUpholsteryOptionsFabrics
            }
            price={price}
            selectedFabricOption={selectedFabricOption}
            currentOption={currentOption}
            selectedFabric={selectedOption}
          />
        </MDBCol>
      </MDBRow>
    </div>
  );
};
