import React, { useState } from "react";
import CreateWishlistCore from "./createWishlistCore";

const CreateWishlistCoreStateContainer = (props) => {
  const [project, setProject] = useState("");
  const [loading, setLoading] = useState(false);
  const [projectName, setProjectName] = useState("wishlist");
  return (
    <CreateWishlistCore
      activeWishlistName={props.activeWishlistName}
      project={project}
      setProject={setProject}
      loading={loading}
      setLoading={setLoading}
      projectName={projectName}
      setProjectName={setProjectName}
    />
  );
};
export default CreateWishlistCoreStateContainer;
