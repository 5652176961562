// @ts-check

import { useQuery } from "@apollo/react-hooks";
import Checkbox from "app/layout/Checkbox";
import Steps from "app/layout/registration/Steps";
import Switch from "app/layout/Switch";
import clsx from "clsx";
import {
  MDBAlert,
  MDBBox,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React from "react";
import { initialValues } from "./config";
import Form from "./Form";
import { countriesQuery } from "./graphql";
import { useHistory } from "react-router-dom";
import Login from "app/pages/customer/login";
import { ModalConsumer } from "core/components/modal/ModalContext";

/**
 * @typedef {object} StepThreeProps
 * @prop {import('app/layout/registration/Steps').StepsProps['setStep']} setStep
 * @prop {import('formik').FormikProps<import('./config').MailingAndShippingValues>} form
 * @prop {boolean} isSubmitting
 * @prop {Error | null} error
 * @prop {object} stepTwoData
 *
 * @param {StepThreeProps} props
 */
const StepThree = (props) => {
  /**
   * @type {import('react-apollo').QueryResult<{ countries: import('../config').CountryOption[] }>}
   */
  const { data, loading } = useQuery(countriesQuery);

  /**
   * @type {React.MutableRefObject<HTMLDivElement | null>}
   */
  const containerRef = React.useRef(null);
  const history = useHistory();

  const submit = React.useCallback(() => {
    props.form.submitForm();
    if (
      containerRef.current &&
      (!props.form.isValid || !props.form.submitCount)
    ) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [props]);
  // @ts-ignore
  return (
    <>
      {!loading && (
        <>
          <MDBRow className="my-5 pb-3">
            <MDBCol className="pb-3">
              <Steps step={2} setStep={props.setStep} />
            </MDBCol>
          </MDBRow>
          <div ref={containerRef}>
            <MDBContainer>
              <MDBRow>
                <MDBCol className="col-12 col-lg-10 offset-lg-1">
                  <MDBRow>
                    <MDBCol className="col-12 col-md-6">
                      <MDBTypography className="mb-5" tag="h1">
                        Billing
                      </MDBTypography>
                      <Form
                        formKey="mailing"
                        form={props.form}
                        countries={data?.countries}
                        stepTwoData={props.stepTwoData}
                      />
                    </MDBCol>
                    <MDBCol className="col-12 col-md-6">
                      <MDBRow className="mb-5">
                        <MDBCol>
                          <MDBTypography tag="h1">Shipping</MDBTypography>
                        </MDBCol>
                        <MDBCol className="d-flex justify-md-content-end">
                          <Checkbox
                            {...props.form.getFieldProps("sameAsMailing")}
                            checked={props.form.values.sameAsMailing}
                            onChange={(e) => {
                              props.form.setFieldValue(
                                "sameAsMailing",
                                e.target.checked
                              );
                              props.form.setFieldValue(
                                "shipping",
                                e.target.checked ? null : initialValues
                              );
                              props.form.setFieldTouched("shipping", false);
                            }}
                            label="Same as billing"
                          />
                        </MDBCol>
                      </MDBRow>
                      <Form
                        formKey={
                          props.form.values.sameAsMailing
                            ? "mailing"
                            : "shipping"
                        }
                        disabled={props.form.values.sameAsMailing}
                        form={props.form}
                        countries={data?.countries}
                        className={clsx("d-md-block", {
                          "d-none": props.form.values.sameAsMailing,
                        })}
                        stepTwoData={props.stepTwoData}
                      />
                      <MDBRow className="pt-4 pt-md-0 mb-50">
                        <MDBCol className="d-flex flex-column">
                          <Switch
                            {...props.form.getFieldProps("updates")}
                            checked={props.form.values.updates}
                            label="I would like to receive emails from Fairfield pertaining to product launches, market events and special promotions."
                            className="mb-5"
                          />
                          {/* <Switch
                            {...props.form.getFieldProps("termsAndConditions")}
                            checked={props.form.values.termsAndConditions}
                            // disabled
                            label={
                              <>
                                I have read the{" "}
                                <a
                                  href="/terms-and-conditions"
                                  target="_blank"
                                  className="d-inline-flex"
                                >
                                  <MDBTypography
                                    // @ts-ignore
                                    variant="body-6"
                                    tag="p"
                                    className="mb-0 d-inline underline"
                                  >
                                    terms and conditions
                                  </MDBTypography>
                                </a>
                              </>
                            }
                          />
                          <div className="text-red">
                            {props.form.touched.termsAndConditions &&
                              props.form.errors.termsAndConditions}
                          </div> */}
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              {props.error && (
                <MDBBox mb={5}>
                  <MDBAlert color="danger" dismiss>
                    {props.error.message}
                  </MDBAlert>
                </MDBBox>
              )}
              {props.error?.message.includes(
                "You are an existing Fairfield user!"
              ) ? (
                <div className="mb-3">
                  <MDBBtn
                    className="mb-0"
                    onClick={() => history.push("/forgot-password")}
                  >
                    Reset your password
                  </MDBBtn>
                  <ModalConsumer>
                    {({ showModal, hideModal }) => {
                      return (
                        <MDBBtn
                          className="mb-0"
                          onClick={(e) => {
                            e.preventDefault();
                            // @ts-ignore
                            showModal(() => {
                              return <Login hideModal={hideModal} />;
                            });
                          }}
                        >
                          Login
                        </MDBBtn>
                      );
                    }}
                  </ModalConsumer>
                </div>
              ) : null}
              <MDBRow className="border-top-1 pt-5 mb-100">
                <MDBCol className="d-flex justify-content-between">
                  <MDBBtn
                    className="mb-0"
                    color="secondary"
                    onClick={() => props.setStep(1)}
                  >
                    Back
                  </MDBBtn>
                  <MDBBtn
                    className="mb-0"
                    disabled={props.isSubmitting}
                    onClick={submit}
                    title={props.isSubmitting ? "Submitting form" : ""}
                  >
                    Submit
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </>
      )}
    </>
  );
};

export default StepThree;
