import React, { useEffect, useState } from "react";
import Icon from "../../../../assets/icon/icon";

const Specs = ({
  availableIn,
  mappedSpecs,
  isCustom = false,
  collections = [],
  productSku,
  sku = false,
  isMobileOrTablet,
  isLiveEdge,
}) => {
  const [tabs, setTabs] = useState([
    { title: "Dimensions (in)" },
    { title: "Details" },
  ]);

  const [showMore, setShowMore] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabChild, setActiveTabChild] = useState(null);

  const settingActiveTab = (tab) => {
    let data = mappedSpecs?.find((spec) => spec?.name === tabs[tab].title);
    if (tab === 1) {
      data = mappedSpecs?.find((spec) => spec?.name === "Specifications");
      let detailsOptions = mappedSpecs.find((spec) => spec.name === "Details")
        ?.options;
      data.options = [...detailsOptions, ...data.options];
    }
    data.options = data?.options?.filter(
      (v, i, a) => a.findIndex((t) => t.name === v.name) === i
    );
    data.options = data?.options?.filter((option) => {
      if (option.value && option.value !== ".00" && option.value !== "N/A")
        return option;
    });
    setActiveTab(tab);
    return { data, dataToShow: 4 };
  };

  useEffect(() => {
    let data = settingActiveTab(0);
    const description = mappedSpecs?.find(
      (spec) => spec?.name === "Description"
    );
    const descriptionTab = tabs.find((tab) => tab.title === "Description");

    if (description?.value?.html && !descriptionTab) {
      setTabs([...tabs, { title: "Description" }]);
    }
    setActiveTabChild(data);
  }, [mappedSpecs]);

  useEffect(() => {
    setShowMore(false);
    let data = settingActiveTab(activeTab);
    setActiveTabChild(data);
  }, [activeTab]);

  useEffect(() => {
    if (showMore)
      setActiveTabChild((prevProps) => ({
        ...prevProps,
        dataToShow: prevProps?.data?.options?.length,
      }));
    else
      setActiveTabChild((prevProps) => {
        return {
          ...prevProps,
          dataToShow: 4,
        };
      });
  }, [showMore, activeTab]);

  return (
    <div className={"specs-container"}>
      {isLiveEdge ? (
        <div>
          <h4>Build Your Own</h4>
          <h2 className="mt-3 mb-5">Live Edge Collection</h2>
        </div>
      ) : (
        <>
          {!isMobileOrTablet ? (
            <div className="d-flex justify-content-between">
              <h2 className={"product-sku"}>{!isCustom ? productSku : sku}</h2>
              {/* {(availableIn.length > 0 ||
                (collections.length > 0 && collections[0] !== false)) && (
                <div
                  className={`collections-and-available-in-container ${
                    collections.length > 0 &&
                    collections[0] !== "false" &&
                    "available"
                  }`}
                >
                  {availableIn.length > 0 && (
                    <div className={"available-in-container"}>
                      <p>Industry:</p>
                      <div
                        className="d-flex justify-content-center mb-3 mx-auto"
                        style={{ bottom: "1%", left: 0, right: 0, zIndex: 2 }}
                      >
                        {availableIn}
                      </div>
                    </div>
                  )}
                </div>
              )} */}
            </div>
          ) : null}
          <div className="tabs">
            {tabs?.map((tab, key) => {
              return (
                <div key={key}>
                  <p
                    onClick={() => {
                      setActiveTab(key);
                    }}
                    className={`tab-item text-center cursor-pointer ${
                      activeTab === key ? "active" : "inactive"
                    }`}
                  >
                    {tab.title}
                  </p>
                </div>
              );
            })}
          </div>
          <div className={`mapped-specs-container`}>
            {activeTabChild?.data?.value?.html ? (
              <div className={"description"}>
                {activeTabChild?.data?.value?.html}
              </div>
            ) : (
              <div className={`specs-options-container`}>
                {activeTabChild?.data?.options
                  ? activeTabChild?.data?.options
                      ?.slice(0, activeTabChild?.dataToShow)
                      ?.map((option, index) => {
                        return (
                          <div key={index}>
                            <span className={"specs-options-name"}>
                              {option.name}:&nbsp;
                            </span>
                            <span className={"specs-options-value"}>
                              {option.value}
                            </span>
                          </div>
                        );
                      })
                  : null}
                {activeTabChild?.data?.options?.length > 4 ? (
                  <p
                    className={"show-more mt-3"}
                    onClick={() => setShowMore(!showMore)}
                  >{`Show ${showMore ? "Less" : "More"}`}</p>
                ) : null}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Specs;
