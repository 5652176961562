import React, { useState } from "react";
import Mobile from "./mobile";
import { toggleHamburger } from "./functions";

const MobileStateContainer = (props) => {
  let { menu, open, setOpen, setMenu, token, logoutCustomer } = props;
  const [collapseId, setCollapseId] = useState([]);
  const [menuToggle, setMenuToggle] = useState(0);

  let menuItems = typeof menu !== "undefined" ? menu : {};

  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const [categoryIndex, setCategoryIndex] = useState(undefined);

  const openMobileDropdown = (index) => {
    if (categoryIndex === index) {
      if (showMobileDropdown === true) {
        setShowMobileDropdown(false);
      } else {
        setShowMobileDropdown(true);
      }
    } else {
      setCategoryIndex(index);
      setShowMobileDropdown(true);
    }
  };
  const toggleHamburgerFn = () => {
    toggleHamburger(props);
  };

  return (
    <Mobile
      {...props}
      open={open}
      setOpen={setOpen}
      menuItems={menuItems}
      setMenu={setMenu}
      categoryIndex={categoryIndex}
      setCategoryIndex={setCategoryIndex}
      setCollapseId={setCollapseId}
      collapseId={collapseId}
      openMobileDropdown={openMobileDropdown}
      token={token}
      menuToggle={menuToggle}
      setMenuToggle={setMenuToggle}
      logoutCustomer={logoutCustomer}
      toggleHamburger={toggleHamburgerFn}
    />
  );
};

export default MobileStateContainer;
