import React, { FC, useMemo } from "react";

import { Aggregation, Maybe } from "app/generated/graphql";
import {
  AttributeCode,
  IFilter,
  getAggregation,
} from "app/pages/catalog/category/utils/utils";
import MultiRangeSlider from "multi-range-slider-react";

export interface SelectDimensionProps {
  filter: IFilter;
  aggregations: Array<Maybe<Aggregation>>;
  setDimensionFilter: (value: any) => void;
  dimension:
    | "ffi_depth_filter_bucket"
    | "ffi_width_filter_bucket"
    | "ffi_height_filter_bucket";
  isMobile: boolean;
}

const SelectDimension: FC<SelectDimensionProps> = (props) => {
  const { aggregations, setDimensionFilter, dimension, filter } = props;

  const dimensionAggregation = React.useMemo(
    () => getAggregation(dimension, aggregations),
    [aggregations, dimension]
  );
  const minQty: number = useMemo(() => {
    if (dimensionAggregation)
      return parseInt(
        dimensionAggregation?.options?.[0]?.value.split("_")?.[0] || ""
      );
    else return 0;
  }, [dimensionAggregation]);
  const maxQty: number = useMemo(() => {
    const options = dimensionAggregation?.options;
    if (options) {
      let mP = 0;
      return Math.max(
        mP,
        parseInt(options?.[options.length - 1]?.value.split("_")?.[1] || "")
      );
    } else return 0;
  }, [dimensionAggregation]);

  const dimensionPlaceholder = useMemo(() => {
    let dm = dimension?.split("_")?.[1];
    return `${dm?.[0]?.toUpperCase()}${dm?.slice(1)}`;
  }, [dimension]);

  const [dynamicValues, setDynamicValues] = React.useState({
    from: minQty,
    to: maxQty,
  });
  const currentValue = filter[dimension];

  return (
    <div className="dimensions-wrapper">
      <p className="fs-14 fwmedium ">{dimensionPlaceholder}</p>
      <div className="static-values">
        <p>{minQty}</p>
        <p>{maxQty}</p>
      </div>
      <MultiRangeSlider
        min={minQty}
        max={maxQty}
        baseClassName="multi-range-slider"
        step={1}
        ruler={false}
        label={false}
        preventWheel={true}
        minValue={currentValue?.from || minQty}
        maxValue={currentValue?.to || maxQty}
        // onInput={(e) => {
        //   setDynamicValues({
        //     from: e.minValue,
        //     to: e.maxValue,
        //   });
        // }}
        id={dimension}
        onChange={(e) => {
          if (
            dimension &&
            (!currentValue ||
              !currentValue.from ||
              !currentValue.to ||
              filter?.[dimension]?.from !== e.minValue ||
              filter?.[dimension]?.to !== e.maxValue)
          ) {
            setDimensionFilter({
              from: e.minValue,
              to: e.maxValue,
            });
          }
        }}
      />
      <div className="dynamic-values">
        <p>{currentValue?.from || dynamicValues?.from}</p>
        <p>{currentValue?.to || dynamicValues?.to}</p>
      </div>
    </div>
  );
};

export default SelectDimension;
