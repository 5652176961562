import Icon from "app/assets/icon/icon";
import { ModalConsumer } from "core/components/modal/ModalContext";
import {
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBTypography,
  MDBModal,
  MDBModalBody,
  MDBInput,
} from "mdbreact";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { finishesArray as monogramFinishArray } from "app/pages/Custom/monogram/data";
import LiveEdgeModal from "./LiveEdgeModal";
import { pathnameIncludes } from "app/utils/utils";

export const Sidebar = ({
  current,
  options,
  setOption,
  hideModal,
  title = "Select Base Option",
  sku,
}) => {
  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => document.body.classList.remove("overflow-hidden");
  }, []);

  const [searchValue, setSearchValue] = useState("");

  let [preselection, setPreselection] = useState(current);

  useEffect(() => {
    const findFinish = (option) =>
      current?.name === "Tobacco"
        ? option?.sku === "XD"
        : option?.finishId === current?.id || option?.sku === current?.sku;
    if (title.trim() === "Finish")
      setPreselection(options?.find((option) => findFinish(option)));
    else if (title.trim() === "Contrast Drawer Finish")
      setPreselection(options?.find((option) => findFinish(option)));
    return () => setPreselection(null);
  }, [options]);

  const [showNotification, setShowNotification] = useState(false);
  const isTablet = useMediaQuery({ maxWidth: 992 });

  const reference = useRef(null);
  const onClickOutside = useCallback(
    (e) => {
      if (
        title.trim() === "Finish" ||
        title.trim() === "Contrast Drawer Finish" ||
        title.trim() === "Select Top" ||
        title.trim() === "Select Base"
      )
        return;
      if (!reference.current.contains(e.target)) {
        hideModal();
      }
    },
    [hideModal]
  );

  useEffect(() => {
    document.addEventListener("click", onClickOutside);
    return () => {
      document.removeEventListener("click", onClickOutside);
    };
  }, [onClickOutside]);

  const findSelectableFinishes = useCallback(
    (option) =>
      monogramFinishArray.find(
        (finish) =>
          // finish.name.includes(option.name?.split("-")?.[0])
          // finish.sku === option?.sku
          option?.name?.toLowerCase()?.includes("mg") &&
          option?.name?.toLowerCase()?.includes(finish?.name?.toLowerCase())
      ),
    []
  );

  let optionsArray = useMemo(() => {
    if (window.location.pathname.includes("monogram"))
      return options?.filter(
        (option) =>
          option.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          option.sku.toLowerCase().includes(searchValue.toLowerCase())
      );
    return options;
  }, [options, searchValue]);

  const sortMonogramFinishes = useCallback(
    (options) =>
      pathnameIncludes("monogram")
        ? options
            ?.filter(
              (option) =>
                !monogramFinishArray?.find(
                  (finish) => finish.name === option?.name
                )
            )
            ?.map((option) => ({
              ...option,
              selectable: monogramFinishArray.find(
                (finish) =>
                  // finish.name.includes(option.name?.split("-")?.[0])
                  // finish?.sku === option?.sku
                  option?.name?.toLowerCase()?.includes("mg") &&
                  option?.name
                    ?.toLowerCase()
                    ?.includes(finish?.name?.toLowerCase())
              ),
            }))
            ?.sort((option) => (option?.selectable ? -1 : 1))
        : options?.sort((a, b) => {
            return monogramFinishArray.find((fabric) => fabric.sku === a.sku) &&
              !monogramFinishArray.find((fabric) => fabric.sku === b.sku)
              ? -1
              : monogramFinishArray.find((fabric) => fabric.sku === a.sku) &&
                monogramFinishArray.find((fabric) => fabric.sku === b.sku)
              ? 0
              : 1;
          }),
    [title, options]
  );

  return (
    <>
      <div ref={reference} style={{ zIndex: "10001" }}>
        <MDBRow className="base-options-container" style={{ zIndex: "100" }}>
          <MDBCol style={{ maxHeight: "100%", height: "100%" }}>
            <MDBRow
              style={{
                zIndex: "2",
              }}
              className="base-options-header"
            >
              <MDBCol className="bg-default flex-center mb-5 px-0">
                <Icon icon="closeWhite" size="4" onClick={hideModal} />
                <MDBTypography
                  tag="h2"
                  className={`body-3 base-options-title ${
                    title.trim() === "Contrast Drawer Finish"
                      ? "drawer-finish"
                      : ""
                  }`}
                >
                  {pathnameIncludes("monogram") &&
                  (title.trim() === "Finish" ||
                    title.trim() === "Contrast Drawer Finish")
                    ? `Select ${title}`
                    : title}
                </MDBTypography>
              </MDBCol>
            </MDBRow>
            {pathnameIncludes("monogram") && title.trim() === "Model" ? (
              <MDBRow className="mt-4 position-relative px-4">
                <MDBInput
                  className="search-input"
                  hint={`Search by name or SKU`}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                >
                  <Icon icon="search" />
                </MDBInput>
              </MDBRow>
            ) : null}
            <MDBRow
              className="p-3 flex-grow-1 items-container"
              style={
                pathnameIncludes("monogram") &&
                (title.trim() === "Finish" ||
                  title.trim() === "Contrast Drawer Finish")
                  ? {}
                  : {
                      height: "calc(100% - 2rem)",
                      maxHeigh: "calc(100% - 2rem)",
                    }
              }
            >
              {pathnameIncludes("live-edge") ? (
                <LiveEdgeModal
                  options={options}
                  setOption={setOption}
                  setPreselection={setPreselection}
                  preselection={preselection}
                  sku={sku}
                />
              ) : pathnameIncludes("monogram") &&
                (title.trim() === "Finish" ||
                  title.trim() === "Contrast Drawer Finish") &&
                preselection?.name ? (
                <>
                  <div className="selected-option-details">
                    <div>
                      <img
                        src={
                          preselection?.image?.url ??
                          options?.find((option) =>
                            option.name.includes("Almond Buff")
                          )?.image?.url
                        }
                        alt={preselection?.name}
                      />
                    </div>
                    <div className="details-holder">
                      <div className="d-grid">
                        <p>
                          <b>Name</b>
                        </p>
                        <p>{preselection?.name?.replace("-MG", "")}</p>
                      </div>
                      {preselection?.add_info ? (
                        <div className="d-grid">
                          <p>Additional information</p>
                          <p>{preselection?.add_info}</p>
                        </div>
                      ) : null}
                      {preselection ? (
                        <div className="d-grid">
                          <p>More Details</p>
                          <Link
                            to={
                              preselection?.id === "almond_buff"
                                ? `/almond-buff-al`
                                : `/${preselection?.url_key}`
                            }
                            target="_blank"
                          >
                            Click Here
                          </Link>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="d-grid-options mt-5">
                    {sortMonogramFinishes(options)?.map((option) => (
                      <div
                        key={option.id + Math.random() * 100}
                        className={`option ${
                          preselection &&
                          option?.sku === preselection?.sku &&
                          "selected"
                        }`}
                        onClick={() => {
                          let foundOption = findSelectableFinishes(option);
                          if (!foundOption) setShowNotification(true);
                          setOption(foundOption ? foundOption : option);
                          setPreselection(option);
                        }}
                      >
                        <div
                          className={`image-container ${
                            findSelectableFinishes(option)
                              ? "player-display-badge"
                              : ""
                          }`}
                        >
                          <img
                            src={option.image?.url || option?.image}
                            alt={option.name}
                            className={`img-fluid`}
                          />
                        </div>
                        {preselection &&
                          option?.sku === preselection?.sku &&
                          "selected" && (
                            <Icon icon={"checked"} className={"selected"} />
                          )}
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <MDBCol>
                  <MDBRow style={{ paddingBottom: "18rem" }}>
                    {optionsArray?.map((option) => {
                      return (
                        <MDBCol
                          key={option.id + Math.random() * 100}
                          size="6"
                          className={`p-3 base-option`}
                        >
                          <MDBRow
                            className={`mb-2 ${
                              pathnameIncludes("monogram") && "monogram-col"
                            }`}
                          >
                            <MDBCol
                              size="12"
                              onClick={() => {
                                if (
                                  option === preselection &&
                                  title.toLowerCase().indexOf("accessory") !==
                                    -1
                                ) {
                                  setPreselection(null);
                                  setOption("X");
                                } else {
                                  setPreselection(option);
                                  setOption(option);
                                }
                              }}
                              className="px-3"
                            >
                              <img
                                src={
                                  title.toLowerCase().indexOf("left") !== -1
                                    ? option.imageLeft
                                    : title.toLowerCase().indexOf("right") !==
                                      -1
                                    ? option.imageRight
                                    : option.image || option.icon || option.map
                                }
                                alt={option.name}
                                className={`img-fluid border  ${
                                  preselection &&
                                  option.name === preselection.name
                                    ? "border-default "
                                    : "border-white"
                                }`.trim()}
                              />

                              <Icon
                                icon="checked"
                                className={`select-option-icon ${
                                  preselection &&
                                  option.name === preselection.name &&
                                  "selected"
                                }`}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className="option-title">
                            <MDBCol className="flex-center flex-column">
                              <MDBTypography
                                tag="h4"
                                className="text-darken-gray mt-3 mb-3"
                              >
                                {option.sku}
                              </MDBTypography>
                              <MDBTypography
                                tag="h4"
                                className="text-darken-gray"
                              >
                                {option.name}
                              </MDBTypography>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      );
                    })}
                  </MDBRow>
                </MDBCol>
              )}
            </MDBRow>
            <MDBRow className="bg-gray-lighten-4 py-3 d-flex sidebar-footer align-items-center">
              <MDBCol
                size="12"
                className={`${
                  window.location.pathname.includes("monogram") &&
                  (title.trim() === "Finish" ||
                    title.trim() === "Contrast Drawer Finish") &&
                  isTablet
                    ? "p-0"
                    : ""
                }`}
              >
                <MDBRow>
                  <MDBCol size="2" style={{ minWidth: "7rem" }}>
                    <MDBTypography
                      tag="h4"
                      className="body-2 text-default fs-16"
                    >
                      Selected
                    </MDBTypography>
                  </MDBCol>
                  <MDBCol>
                    <MDBTypography
                      tag="h4"
                      className="body-2 text-darken-gray fs-16"
                    >
                      {preselection?.name?.replace("-MG", "") || ""}
                    </MDBTypography>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol size="12" className={`${isTablet ? "p-0" : ""}`}>
                <MDBRow
                  className={`${
                    window.location.pathname.includes("monogram") &&
                    (title.trim() === "Finish" ||
                      title.trim() === "Contrast Drawer Finish") &&
                    "modal-footer"
                  }`}
                >
                  {window.location.pathname.includes("monogram") &&
                  (title.trim() === "Finish" ||
                    title.trim() === "Contrast Drawer Finish") ? (
                    <>
                      <MDBCol
                        className={`d-flex justify-content-start align-items-center p-0`}
                      >
                        <MDBBtn
                          className="btn-secondary"
                          onClick={() => {
                            let initialOption = options.find((option) =>
                              option.name.includes("Almond Buff")
                            );
                            setPreselection(initialOption);
                            setOption(monogramFinishArray?.[0]);
                          }}
                        >
                          Reset to Default
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol
                        className={`d-flex justify-content-end align-items-center p-0`}
                      >
                        <MDBBtn onClick={hideModal}>Save Selection</MDBBtn>
                      </MDBCol>
                    </>
                  ) : (
                    <>
                      <MDBCol className="d-flex justify-content-center align-items-center">
                        <MDBBtn
                          onClick={() => {
                            hideModal();
                          }}
                        >
                          Continue
                        </MDBBtn>
                      </MDBCol>
                    </>
                  )}
                  {/* <MDBCol>
                   TO DO : View details
                  </MDBCol> */}
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </div>
      {(showNotification && (
        <MDBModal
          className={`notification-modal`}
          backdropClassName={`notification-modal-backdrop`}
          centered
          isOpen={showNotification}
          toggle={() => {
            setShowNotification(!showNotification);
          }}
        >
          <MDBModalBody>
            <MDBTypography tag="p" className={`body-1 text-center`}>
              This selected option is not viewable
            </MDBTypography>
          </MDBModalBody>
        </MDBModal>
      )) || <></>}
    </>
  );
};

export const Step = ({ step = 1, current, options, setOption, title, sku }) => {
  let currentMemo = useMemo(() => {
    if (pathnameIncludes("monogram") && window.location.search && !current) {
      const url = new URL(window.location.href);
      const finishSku = url.searchParams.get("finish");
      const contrastDrawerSku = url.searchParams.get("contrastDrawers");
      if (title.trim() === "Finish")
        return options.find((finish) => finish.id == finishSku);
      if (title.trim() === "Contrast Drawer Finish")
        return options.find((finish) => finish.id == contrastDrawerSku);
    }
    return current;
  }, [current]);

  return (
    <MDBRow className={`step-${step}-container`}>
      <ModalConsumer value={{ useWrapper: false }}>
        {({ showModal, hideModal }) => {
          return (
            <div
              className={` w-100`}
              onClick={(e) => {
                e.preventDefault();
                showModal(() => {
                  return (
                    <Sidebar
                      current={
                        !current &&
                        window.location.pathname.includes("monogram") &&
                        window.location.search
                          ? currentMemo
                          : current
                      }
                      options={options}
                      setOption={setOption}
                      hideModal={hideModal}
                      title={title}
                      sku={sku}
                    />
                  );
                });
              }}
            >
              <div className="selector-container">
                <h2 className="body-2 selector-title">{title}</h2>
                <div className="selected-option">
                  <div className="found-option">
                    <img
                      src={
                        currentMemo?.image?.url
                          ? currentMemo?.image?.url
                          : title.toLowerCase().indexOf("left") !== -1
                          ? currentMemo?.imageLeft
                          : title.toLowerCase().indexOf("right") !== -1
                          ? currentMemo?.imageRight
                          : currentMemo?.image
                          ? currentMemo?.image
                          : currentMemo?.modelImage
                          ? currentMemo?.modelImage
                          : currentMemo?.icon
                      }
                      alt={
                        currentMemo?.modelImage && !currentMemo?.name
                          ? `${currentMemo?.size} ${currentMemo?.finish}`
                          : currentMemo?.name
                      }
                      className="img-fluid"
                    />
                    <p>
                      {currentMemo?.modelImage && !currentMemo?.name
                        ? `${currentMemo?.size} ${currentMemo?.finish}`
                        : currentMemo?.name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </ModalConsumer>
    </MDBRow>
  );
};

export default Step;
