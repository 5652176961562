import gql from "graphql-tag";

export const PRODUCT = gql`
  query products($filter: ProductAttributeFilterInput!, $search: String) {
    products(filter: $filter, search: $search, pageSize: 10, currentPage: 1) {
      items {
        id
        uid
        ffi_fullyuph
        ffi_uph_opts
        ffi_casegd
        ffi_duedate
        ffi_avails
        ffi_status
        ffi_madeto
        ffi_onords
      }
    }
  }
`;

export const CUSTOMER_PRODUCT = gql`
  query products($filter: ProductAttributeFilterInput!, $search: String) {
    customer {
      email
    }
    products(filter: $filter, search: $search) {
      items {
        id
        uid
        ffi_fullyuph
        ffi_uph_opts
        ffi_casegd
        ffi_duedate
        ffi_avails
        ffi_status
        ffi_madeto
        ffi_onords
      }
    }
  }
`;
