export default (countries) => {
  const usa = countries.find((country) => country.value === "US");
  const canada = countries.find((country) => country.value === "CA");
  const filteredCountries = countries.filter(
    (country) => country.value !== "CA" && country.value !== "US"
  );
  // @ts-ignore
  filteredCountries.unshift(canada);
  // @ts-ignore
  filteredCountries.unshift(usa);
  return filteredCountries;
};
