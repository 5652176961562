import React, { useRef, useState, useCallback, Fragment } from "react";
import DownloadTearsheet from "./components/downloadTearsheet/downloadTearsheet";
import useCustomer from "app/state/hooks/customer/useCustomer";
import { gql } from "graphql.macro";
import { useMutation } from "react-apollo-hooks";
import DownloadImage from "./components/downloadImage";
import Icon from "../../../../../../assets/icon/icon";
import { useOnClickOutside } from "../../../../utils";
import { pathnameIncludes } from "app/utils/utils";
import Button from "core/components/button";

const UPLOAD_CONFIGURATOR_IMAGE = gql`
  mutation uploadConfiguratorImage($name: String!, $data: String!) {
    uploadConfiguratorImage(
      input: {
        configurator_image: {
          name: $name
          type: "image/png"
          base64_encoded_data: $data
        }
      }
    ) {
      configurator_image
    }
  }
`;

const Downloads = ({
  id,
  name,
  price,
  customizationOptionsQueryParameters,
  player,
  isCustom,
  image,
  sku,
  product,
}) => {
  const customer = useCustomer().isLoggedIn();
  const [uploadConfiguratorImage] = useMutation(UPLOAD_CONFIGURATOR_IMAGE);
  const [clicked, setClicked] = useState(false);
  const clickOutsideRef = useRef(null);
  useOnClickOutside(clickOutsideRef, setClicked);
  const {
    collection_overview_info,
    revit_file,
    assembly_instructions,
    spec_sheet,
  } = product;
  const filesArray = [
    { file: collection_overview_info, name: "Collection PDF" },
    { file: revit_file, name: "Revit File" },
    { file: assembly_instructions, name: "Assembly Instructions" },
    { file: spec_sheet, name: "Spec Sheet" },
  ].filter((file) => typeof file.file === "string");

  const downloadFiles = useCallback(() => {
    return filesArray.map((file) => (
      <Fragment key={file.file}>
        <div
          className="download-link"
          onClick={() => window.open(file.file, "_blank")}
        >
          <Icon icon="downloadSmall" />
          <h2 className="ml-1 download-h2">{file.name}</h2>
        </div>
      </Fragment>
    ));
  }, [product]);

  const getDownloadLinksLength = document.querySelectorAll(".download-link")
    ?.length;

  return (
    <>
      {window.innerWidth > 992 ? (
        <div ref={clickOutsideRef}>
          <div className={"downloads-button mt-2"}>
            {/* <Icon
              icon={"downloadGray"}
              onClick={() => {
                setClicked(!clicked);
              }}
            /> */}
            <Button
              className={`btn-secondary btn w-100`}
              onClick={() => {
                setClicked(!clicked);
              }}
            >
              Downloads
            </Button>
            <div
              className={`downloads-container ${clicked ? "opened" : ""}`}
              style={{
                top:
                  getDownloadLinksLength === 4
                    ? "-121px"
                    : getDownloadLinksLength === 3
                    ? "-96px"
                    : "-71px",
              }}
            >
              {!pathnameIncludes("live-edge") && (
                <DownloadTearsheet
                  customer={customer}
                  isCustom={isCustom}
                  customizationOptionsQueryParameters={
                    customizationOptionsQueryParameters
                  }
                  uploadConfiguratorImage={uploadConfiguratorImage}
                  id={id}
                  name={name}
                  price={price}
                  player={player}
                />
              )}
              {product?.media_gallery?.length ? (
                <DownloadImage
                  player={player}
                  image={image}
                  sku={sku}
                  isCustom={isCustom}
                  allImages={true}
                  product={product}
                />
              ) : null}
              {isCustom ? (
                <DownloadImage
                  player={player}
                  image={image}
                  sku={sku}
                  isCustom={isCustom}
                  allImages={false}
                  product={product}
                />
              ) : null}
              {downloadFiles()}
            </div>
            <div
              className={`downloads-container-arrow ${clicked ? "opened" : ""}`}
            />
            <div
              className={`downloads-container-arrow-border ${
                clicked ? "opened" : ""
              }`}
            />
          </div>
        </div>
      ) : (
        <div className={`downloads-container-mobile`}>
          {!pathnameIncludes("live-edge") && (
            <div>
              <DownloadTearsheet
                customer={customer}
                isCustom={isCustom}
                customizationOptionsQueryParameters={
                  customizationOptionsQueryParameters
                }
                uploadConfiguratorImage={uploadConfiguratorImage}
                id={id}
                name={name}
                price={price}
                player={player}
              />
            </div>
          )}
          {product?.media_gallery?.length ? (
            <div className="d-flex">
              <DownloadImage
                player={player}
                image={image}
                sku={sku}
                isCustom={isCustom}
                allImages={true}
                product={product}
              />
            </div>
          ) : null}
          {isCustom ? (
            <div className="d-flex">
              <DownloadImage
                player={player}
                image={image}
                sku={sku}
                isCustom={isCustom}
                allImages={false}
                product={product}
              />
            </div>
          ) : null}
          {downloadFiles()}
        </div>
      )}
    </>
  );
};

export default Downloads;
