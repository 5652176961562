export default (state, action) => {
  return Object.assign({}, state, {
    data: {
      ...state.data,
      ...action.customer,
      token: state.data.token,
      email: action.customer.email,
      is_subscribed: action.customer.is_subscribed,
      is_trade: action.customer.is_trade
    },
    loading: false
  });
};
