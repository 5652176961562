import clsx from "clsx";
import { ModalConsumer } from "core/components/modal/ModalContext";
import { FormikProps } from "formik";
import { MDBBtn, MDBCol, MDBInput, MDBRow, MDBTypography } from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";
import { customerPaths } from "../../../routes";
import { EmailValues } from "./config";

export interface EmailAndPasswordProps {
  onExpressSetup: () => void;
  form: FormikProps<EmailValues>;
}

const EmailAndPassword: React.FC<EmailAndPasswordProps> = ({
  onExpressSetup,
  form,
}) => {
  return (
    <MDBRow className="flex-grow-1 pb-4">
      <MDBCol className="mb-2 p-0 d-flex flex-column ">
        <div className="bg-gray-lighten-4 pb-5">
          <div className="mt-4 mb-5 mx-4 px-5">
            <div>
              <MDBTypography className="fs-40 login-title mb-0">
                Login
              </MDBTypography>
              {/* @ts-ignore */}
              {/*<MDBTypography className="mt-4 pt-2" variant="body-4">*/}
              {/*  If you're an existing Fairfield Chair customer, enter your email*/}
              {/*  below*/}
              {/*</MDBTypography>*/}
            </div>
            <form className="mt-5 pt-4" onSubmit={form.handleSubmit}>
              <div
                className={`position-relative ${form.errors.email && "mb-5"}`}
              >
                <MDBInput
                  {...form.getFieldProps("email")}
                  label="Email"
                  required
                  hint="email@email.com"
                  containerClass="m-0 w-100"
                  className={clsx({
                    error: form.touched.email && form.errors.email,
                  })}
                />
                <div className={`position-absolute text-red`}>
                  {form.touched.email && form.errors.email}
                </div>
              </div>
              <div className="mt-4 pt-2">
                <MDBBtn type="submit" className={`w-100 m-0`}>
                  Continue
                </MDBBtn>
              </div>
            </form>
          </div>
        </div>
        <div className="px-5 d-flex flex-grow-1 flex-column mx-4">
          {/* <div className="mb-5">
            <MDBTypography className="fs-40 login-title mb-0">
              Express Registration
            </MDBTypography>
            <MDBTypography
              className="mt-4 pt-2 mb-2"
              // @ts-ignore
              variant="body-4"
            >
              If you have an account with us, please click below to gain site
              access.
            </MDBTypography>
            <MDBBtn
              className="m-0 mt-4 w-100"
              color="secondary"
              onClick={onExpressSetup}
            >
              Setup Account
            </MDBBtn>
          </div> */}
          <div className="mt-5 dark separator" />
          <div className="mt-5 pt-2">
            <MDBTypography className="fs-40 login-title mb-0">
              Register Here
            </MDBTypography>
             {/*   <MDBTypography
              className="mt-3"
              // @ts-ignore
              variant="body-4"
            >
         Create an account today and start exploring the new website. 
            </MDBTypography>*/}

            <ModalConsumer>
              {({ hideModal }) => {
                return (
                  <Link to={customerPaths.children.register.path}>
                    <MDBBtn
                      className="m-0 mt-5 w-100"
                      color="secondary"
                      onClick={(/** @type {React.MouseEvent} */ e) => {
                        // @ts-ignore
                        hideModal(() => {});
                      }}
                    >
                      Register
                    </MDBBtn>
                  </Link>
                );
              }}
            </ModalConsumer>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default EmailAndPassword;
