import React from "react";
import StoreLocator from "./storeLocator";

const StoreLocatorDataContainer = (props) => {
  if (props.data === undefined) {
    return null;
  }
  let stores = [];
  let etailers = [];

  props.data.getStoreLocator.map((store) =>
    stores.push({
      store_id: store.store_id,
      name: store.name,
      state: store.state,
      city: store.city,
      street: store.street,
      lat: parseFloat(store.latitude),
      lng: parseFloat(store.longitude),
      store_type: store.store_type,
      website: store.website,
      telephone: store.telephone,
      email: store.email,
      postcode: store.postcode,
    })
  );
  props.data.getStoreLocator
    .filter((store) => {
      return store.store_type !== "Store";
    })
    .map((store) =>
      etailers.push({
        store_id: store.store_id,
        name: store.name,
        state: store.state,
        city: store.city,
        street: store.street,
        lat: parseFloat(store.latitude),
        lng: parseFloat(store.longitude),
        store_type: store.store_type,
        website: store.website,
        telephone: store.telephone,
        email: store.email,
        postcode: store.postcode,
      })
    );
  return <StoreLocator {...props} stores={stores} etailers={etailers} />;
};

export default StoreLocatorDataContainer;
