import React, { useCallback, useMemo } from "react";
import { useBoolean } from "ahooks";
import {
  BillingCartAddress,
  CurrencyEnum,
  CustomerAddress,
  DomainCartFragment,
  Money,
  ShippingCartAddress,
  useAddCustomerOrderItemAttributesMutation,
  useCountryOptionsQuery,
  useCustomerCartQuery,
  useCustomerQuery,
  useSetCartAddressesMutation,
  useSetCartMethodsAndPlaceOrderMutation,
} from "app/generated/graphql";
import CartContext from "app/layout/cart";
import ModalHeader from "app/layout/ModalHeader";
import { decodePhone, encodePhone } from "app/pages/customer/account/utils";
import { messagesActions } from "core/state/redux/data/messages";
import { FormikConfig, useFormik } from "formik";
import {
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { cartPaths } from "../../config";
import { isDomainCartItem } from "../../utils";
import { cartSchema, cartValues, CartValues } from "./config";
import { CountryOption } from "./models";
import Submit from "./Submit";
import { customerActions } from "../../../../state/redux/data/customer";
import queryLoader from "app/graphql/queryLoader";
import { useMutation } from "react-apollo-hooks";

export interface DataContainerProps {}

const DataContainer: React.FC<DataContainerProps> = (props) => {
  const { orderItemDataRecord } = React.useContext(CartContext);

  const history = useHistory();

  const dispatch = useDispatch();

  const cartQuery = useCustomerCartQuery({ fetchPolicy: "network-only" });

  const countryOptionsQuery = useCountryOptionsQuery();

  const [setShippingAddressOnCart] = useMutation(
    queryLoader("SetShippingAddressesOnCart")
  );

  // const [setBillingAddressOnCart] = useMutation(
  //   queryLoader("SetBillingAddressOnCart")
  // );

  const [setCartAddresses] = useSetCartAddressesMutation({
    optimisticResponse: ({ cart_id, shipping_address: { address } }) => {
      const countryOption = countryOptionsQuery.data?.countries?.find(
        (country) => country?.value === address?.country_code
      );

      if (address && countryOption?.label) {
        const { country_code, region_id, ...cartAddressRest } = address;

        const region = countryOption.regions?.find(
          (regionOption) => regionOption?.value === region_id
        );

        const cartAddress: ShippingCartAddress & BillingCartAddress = {
          ...cartAddressRest,
          country: {
            __typename: "CartAddressCountry",
            code: country_code,
            label: countryOption.label,
          },
          region: {
            __typename: "CartAddressRegion",
            code: region?.code,
            region_id: region_id,
            label: region?.label,
          },
        };

        const cart: DomainCartFragment = {
          ...cartQuery.data?.customerCart,
          __typename: "Cart",
          id: cart_id,
          shipping_addresses: [
            {
              __typename: "ShippingCartAddress",
              ...cartAddress,
            },
          ],
          billing_address: {
            __typename: "BillingCartAddress",
            ...cartAddress,
          },
        };

        return {
          __typename: "Mutation",
          setShippingAddressesOnCart: {
            __typename: "SetShippingAddressesOnCartOutput",
            cart,
          },
          setBillingAddressOnCart: {
            __typename: "SetBillingAddressOnCartOutput",
            cart,
          },
        };
      } else {
        return {};
      }
    },
    onCompleted: () => {
      dispatch(
        messagesActions.addMessage("Address has been stored.", "success")
      );
      setModalOpen.setTrue();
    },
    onError: (error) => {
      if (!error.graphQLErrors) return;
      dispatch(
        messagesActions.addMessage(
          error.graphQLErrors[0]?.message ??
            "Failed to save address, please retry.",
          "error"
        )
      );
    },
  });

  const [
    addCustomerOrderItemAttributes,
  ] = useAddCustomerOrderItemAttributesMutation({
    onCompleted: () => {},
    onError: (error) => {
      dispatch(
        messagesActions.addMessage(
          error.graphQLErrors[0]?.message ??
            "Failed to add comments and documents for items."
        )
      );
    },
  });

  const [
    setMethodsAndPlaceOrder,
    setMethodsAndPlaceOrderResult,
  ] = useSetCartMethodsAndPlaceOrderMutation({
    onCompleted: (result) => {
      if (result.placeOrder?.order.order_number) {
        dispatch(
          messagesActions.addMessage("Quote request submitted", "success")
        );
        // addCustomerOrderItemAttributes({
        //   variables: {
        //     input: {
        //       order_id: Number(result.placeOrder?.order.order_number),
        //       order_item: Object.entries(
        //         orderItemDataRecord
        //       ).map(([product_sku, data]) => ({ ...data, product_sku })),
        //     },
        //   },
        // });
      } else {
        dispatch(
          messagesActions.addMessage("Order number is missing", "error")
        );
      }
    },
    onError: (error) => {
      dispatch(
        messagesActions.addMessage(
          error.graphQLErrors[0]?.message ??
            "Failed to place order, please retry.",
          "error"
        )
      );
    },
  });

  const [modalOpen, setModalOpen] = useBoolean();

  const customerQuery = useCustomerQuery();

  const customerAddresses = useMemo(() => {
    let arr = customerQuery.data?.customer?.addresses
      ? [
          // @ts-ignore
          ...customerQuery.data?.customer?.addresses?.map((address) => ({
            // @ts-ignore
            value: address.id,
            // @ts-ignore
            label: address?.address_label
              ? address.address_label
              : // @ts-ignore
                `${address.firstname} ${address.lastname}, ${
                  // @ts-ignore
                  address.street?.[0]
                } ${address?.street?.[1] || ""}, ${address?.city}, ${
                  address?.postcode
                }, ${address?.country_code}`,
          })),
        ]
      : [];
    // @ts-ignore
    arr.unshift({ value: "add-new-address", label: "Add New Address" });
    return arr;
  }, [customerQuery.data?.customer?.addresses]);

  const defaultShippingAddress = useMemo(
    () =>
      customerQuery.data?.customer?.addresses
        ?.filter((address): address is CustomerAddress => !!address)
        .find(({ default_shipping }) => default_shipping),
    [customerQuery.data?.customer?.addresses]
  );

  const config = React.useMemo(() => {
    const customerShippingAddress = defaultShippingAddress;

    const customerShippingCountry = countryOptionsQuery.data?.countries?.find(
      (country) => country?.value === customerShippingAddress?.country_code
    );

    return {
      enableReinitialize: true,
      initialValues: {
        ...cartValues,
        contactInformation: {
          company:
            customerShippingAddress?.company ??
            cartValues.contactInformation.company,
          firstname:
            customerShippingAddress?.firstname ??
            cartValues.contactInformation.firstname,
          lastname:
            customerShippingAddress?.lastname ??
            cartValues.contactInformation.lastname,
          telephone: decodePhone(
            customerShippingAddress?.telephone ??
              cartValues.contactInformation.telephone
          ),
          order_email:
            customerShippingAddress?.email ??
            cartValues.contactInformation.order_email,
        },
        address: {
          country: customerShippingCountry ?? cartValues.address.country,
          // region_id:
          //   customerShippingAddress?.region?.region_id ??
          //   cartValues.address.region_id,
          city: customerShippingAddress?.city ?? cartValues.address.city,
          postcode:
            customerShippingAddress?.postcode ?? cartValues.address.postcode,
          address1:
            customerShippingAddress?.street?.[0] ?? cartValues.address.address1,
          address2:
            customerShippingAddress?.street?.[1] ?? cartValues.address.address2,
          save_in_address_book: false,
          customer_address_id: defaultShippingAddress?.id,
          address_label: "",
        },
      },
      validationSchema: cartSchema,
      onSubmit: ({
        // @ts-ignore
        contactInformation: { telephone, ...companyInformation },
        // @ts-ignore
        address: { country, address1, address2, ...address },
      }) => {
        if (cartQuery.data?.customerCart.id) {
          const cartAddress = {
            ...companyInformation,
            ...address,
            telephone: encodePhone(telephone),
            country_code: country?.value!, // * assumes that the respective schema field is required
            street: [address1, address2 ?? null],
          };

          // @ts-ignore
          const { customer_address_id, ...restAddress } = cartAddress;

          const customerAddressesId =
            customer_address_id === ""
              ? customerAddresses?.[0]?.value
              : customer_address_id;

          if (customerAddressesId) {
            new Promise((resolve, reject) =>
              resolve(
                setShippingAddressOnCart({
                  variables: {
                    cart_id: cartQuery?.data?.customerCart?.id,
                    customer_address_id: customerAddressesId,
                  },
                })
              )
            ).then(() => setModalOpen.setTrue());

            // setBillingAddressOnCart({
            //   variables: {
            //     input: {
            //       cart_id: cartQuery?.data?.customerCart?.id,
            //       billing_address: {
            //         same_as_shipping: true,
            //         address: restAddress,
            //       },
            //     },
            //   },
            // });
          } else {
            // @ts-ignore
            if (!restAddress?.region_id) delete restAddress.region_id;
            setCartAddresses({
              variables: {
                cart_id: cartQuery.data.customerCart.id,
                // @ts-ignore
                shipping_address: { address: restAddress },
                // billing_address: {
                //   // @ts-ignore
                //   address: cartQuery.data?.customerCart?.billing_address,
                // },
              },
            });
          }
        } else {
          console.error("Cannot adds billing address to a cart without an id.");
        }
      },
    };
  }, [
    customerQuery,
    countryOptionsQuery,
    cartQuery,
    setCartAddresses,
    defaultShippingAddress,
  ]);
  const form = useFormik(config);

  const handlePlaceOrder = React.useCallback(() => {
    if (cartQuery.data?.customerCart.id) {
      setMethodsAndPlaceOrder({
        variables: {
          cart_id: cartQuery.data.customerCart.id,
          shipping_methods: [
            { carrier_code: "freeshipping", method_code: "freeshipping" },
          ],
          payment_method: {
            code: "checkmo",
          },
          placeOrderInput: {
            cart_id: cartQuery.data.customerCart.id,
            project_name: form.values.projectDetails.project_name,
            project_due_date: form.values.projectDetails.project_due_date,
            timeline: form.values.projectDetails.timeline,
            additional_details: form.values.projectDetails.additional_details,
          },
        },
      })
        .then(() => {
          dispatch(customerActions.createEmptyCart());
        })
        .finally(() => {
          cartQuery.refetch().then(() => {
            history.push(cartPaths.children.thankYou.path);
          });
        });
    } else {
      console.error(
        "Cannot place order since cart id is",
        cartQuery.data?.customerCart.id
      );
    }
  }, [setMethodsAndPlaceOrder, cartQuery, form]);

  const cartItems = cartQuery.data?.customerCart.items?.filter(
    isDomainCartItem
  );
  const quantity = cartItems?.reduce(
    (quantity, item) => quantity + item.quantity,
    0
  );

  const money: Money = React.useMemo(
    () => ({
      value: cartItems?.reduce(
        (subtotal, item) =>
          subtotal + (item.prices?.price.value ?? 0) * item.quantity,
        0
      ),
      currency: cartItems?.[0]?.prices?.price.currency ?? CurrencyEnum.Usd,
    }),
    [cartItems]
  );

  const [isSummaryOpen, setIsSummaryOpen] = useBoolean();

  const [isCalendarOpen, setCalendarOpen] = useBoolean();

  return (
    <>
      {cartItems && quantity ? (
        <Submit
          quantity={quantity}
          money={money}
          countryOptions={
            countryOptionsQuery.data?.countries?.filter(
              (option): option is CountryOption => typeof option !== "undefined"
            ) ?? []
          }
          // @ts-ignore
          form={form}
          isSummaryOpen={isSummaryOpen}
          onToggleSummary={() => setIsSummaryOpen.toggle()}
          isCalendarOpen={isCalendarOpen}
          onToggleCalendar={() => setCalendarOpen.toggle()}
          //@ts-ignore
          cartItems={cartItems}
          customerAddresses={customerAddresses}
          defaultShippingAddress={defaultShippingAddress}
          //@ts-ignore
          customerQueryAddresses={customerQuery.data?.customer?.addresses}
        />
      ) : null}
      <MDBModal
        isOpen={modalOpen}
        inline={false}
        overflowScroll
        noClickableBodyWithoutBackdrop={false}
        centered
        className="wide-modal"
      >
        <MDBModalBody className="p-0">
          <ModalHeader onClose={setModalOpen.setFalse}>
            Before You Send
          </ModalHeader>
          <MDBRow className="mt-5 mx-5 px-sm-4 mb-5 pb-5">
            <MDBCol>
              {/* @ts-ignore */}
              <MDBTypography variant="body-2 text-center">
                Once you submit your Quote Request you will no longer be able to
                edit and/or add to this Quote Request. A copy of your Quote
                Request will be emailed to your sales representative and to you.
              </MDBTypography>
              <div className="mt-5 pt-3 d-flex">
                <MDBBtn
                  className="m-0 mr-4"
                  color="secondary"
                  onClick={setModalOpen.setFalse}
                >
                  Go Back
                </MDBBtn>
                <MDBBtn
                  className="m-0"
                  onClick={handlePlaceOrder}
                  disabled={setMethodsAndPlaceOrderResult.loading}
                >
                  Submit
                </MDBBtn>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
      </MDBModal>
    </>
  );
};

export default DataContainer;
