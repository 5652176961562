import { MDBTypography } from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";
import { DomainCartItem } from "../models";

export interface ItemTitleProps {
  item: DomainCartItem;
  foundUrl: any;
  onClick?: () => string | void;
}

const ItemTitle: React.FC<ItemTitleProps> = (props) => {
  return props.item.product.url_key ? (
    <Link
      to={props.foundUrl ? props.foundUrl : props.item.product.url_key}
      className="grow"
      onClick={props.onClick}
    >
      <MDBTypography tag="h4" className="item-name mb-3">
        {props.item.product.sku}
      </MDBTypography>
      <MDBTypography tag="h5" className="item-name">
        {props.item.product.name}
      </MDBTypography>
    </Link>
  ) : null;
};

export default ItemTitle;
