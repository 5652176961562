import { Player } from "app/layout/Player";
import ProductPage from "app/pages/catalog/product";
import { MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdbreact";
import React, { useEffect } from "react";
import { PromosBYO } from "app/pages/catalog/product/PromosBYO";
import { useMediaQuery } from "react-responsive";
import { Step } from "app/pages/Custom";
import { baseFinishArray } from "app/pages/Custom/haven/data";
// import useDeepCompareEffect from "use-deep-compare-effect";

const _CustomDisplay = (
  {
    player,
    setPlayer,
    frameArray,
    backArray,
    armArray,
    baseArray,
    floorArray,
    fabricArray,
    sku,
    showPdp,
    selectionsAndSelectors,
    fabricsSelections,
    allowSectional,
    pickSectional,
    currentSectionals,
    nailData,
    availableOptions,
    setAvailableOptions,
    queryPathFabrics,
    productName,
    productDimensions,
  },
  canvasRef
) => {
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [checkBoxOptionsCustom, setCheckBoxOptionsCustom] = React.useState({});

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };
  // let filtered;
  // if (fabricsSelections.mainFabric !== null) {
  //   filtered = fabricArray.filter((fabricItem) => {
  //     return fabricItem.id === fabricsSelections.mainFabric.id;
  //   });
  // }
  const [baseArrayLocal, setBaseArrayLocal] = React.useState(baseArray);

  useEffect(() => {
    if (sku.indexOf("-31") !== -1 || sku.indexOf("-32") !== -1) {
      let x = [];
      baseArray.forEach((base) => {
        if (base.name === "Skirt") {
          x.push(base);
        }
      });
      setBaseArrayLocal(x);
      selectionsAndSelectors.setBase(x[0]);
    } else if (baseArrayLocal.length === 1) {
      setBaseArrayLocal(baseArray);
    }
  }, [sku]);

  const isMobile = useMediaQuery({ maxWidth: 992 });
  const isLaptop = useMediaQuery({ maxHeight: 768 });
  let selectedBaseFinishName;

  if (nailData?.nailFinish) {
    selectedBaseFinishName = nailData?.nailFinish?.finish?.name;
  }

  let selectedBaseFinish = baseFinishArray.find((x) => {
    return x.name === selectedBaseFinishName;
  });

  if (typeof selectedBaseFinish === "undefined") {
    selectedBaseFinish = [];
  }
  return (
    <>
      <MDBContainer fluid className="position-relative py-4 px-0">
        <MDBRow className="h-100 mx-0">
          <MDBCol
            size="12"
            lg="9"
            className={`px-${isMobile ? "0" : "5"} player d-block ${
              isFullscreen ? "fullscreen-canvas" : ""
            }`.trim()}
            style={{
              top: isMobile ? "0" : "4rem",
              minHeight: isMobile ? "33.7vh" : isLaptop ? "50.2vh" : "65.2vh",
              maxHeight: isMobile
                ? "33.7rem"
                : isLaptop
                ? "50.2rem"
                : "65.2rem",
              height: isMobile ? "33.7rem" : isLaptop ? "50.2rem" : "65.2rem",
              marginBottom: isMobile ? "3rem" : "0",
              overflowY: "visible",
              zIndex: 1,
              position: "sticky",
            }}
          >
            <Player
              ref={canvasRef}
              isFullscreen={isFullscreen}
              toggleFullscreen={toggleFullscreen}
              player={player}
              setPlayer={setPlayer}
              frame={selectionsAndSelectors.frame}
              arm={selectionsAndSelectors.arm}
              back={selectionsAndSelectors.back}
              base={selectionsAndSelectors.base}
              floor={selectionsAndSelectors.floor}
              floorOptions={floorArray}
              setFloor={selectionsAndSelectors.setFloor}
              mainFabric={fabricsSelections.mainFabric}
              backFabric={fabricsSelections.backFabric}
              weltFabric={fabricsSelections.weltFabric}
              pillowWeltFabric={fabricsSelections.decorativeCordFabric}
              cushionFabric={fabricsSelections.cushionFabric}
              throwPillowFabric={fabricsSelections.throwPillowFabric}
              nailSize={nailData.nailSize}
              nailFinish={nailData.nailFinish}
              sku={sku}
              selectedBaseFinish={selectedBaseFinish}
              collectionPath={"haven-reserve"}
              checkBoxOptionsCustom={checkBoxOptionsCustom}
              setCheckBoxOptionsCustom={setCheckBoxOptionsCustom}
              productDimensions={productDimensions}
            />
          </MDBCol>
          <MDBCol size="12" lg="3" className={"controls px-5"}>
            <MDBRow>
              <MDBCol className="my-3 px-0">
                <MDBTypography
                  className={`${((isMobile && "fwregular") || "").trim()}`}
                  tag="h1"
                >
                  Haven Reserve
                </MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className={`${(!isMobile && "my-3") || ""} px-0`}>
                <MDBRow className={`mb-3`}>
                  <MDBCol size="12" lg="6">
                    {(isMobile && (
                      <MDBTypography tag="h1" className="mb-3">
                        {productName}
                      </MDBTypography>
                    )) || (
                      <MDBTypography tag="h3" className="text-default fwthin">
                        Product Sku
                      </MDBTypography>
                    )}
                  </MDBCol>
                  <MDBCol
                    size="12"
                    lg="6"
                    className={`${(isMobile && "text-left") || "text-right"}`}
                  >
                    {(isMobile && (
                      <MDBTypography
                        tag="h3"
                        className="fwregular text-gray-darken-3 mb-3"
                      >
                        {sku}
                      </MDBTypography>
                    )) || (
                      <MDBTypography
                        tag="h1"
                        className="text-default fwregular"
                      >
                        {sku}
                      </MDBTypography>
                    )}
                  </MDBCol>
                </MDBRow>
                {/* <MDBRow className="my-3">
                  <MDBCol size="6">
                    <MDBTypography tag="h1" className="text-default fwregular">
                     $X,XXX 
                    </MDBTypography>
                  </MDBCol>
                </MDBRow> */}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol
                size="12"
                className="border border-default border-thic rounded"
                style={{
                  boxShadow: "0px 6px 4px #00000033",
                }}
              >
                <MDBRow>
                  <MDBCol size="12">
                    <Step
                      step="1"
                      title="Select Frame"
                      current={selectionsAndSelectors.frame}
                      options={frameArray}
                      setOption={selectionsAndSelectors.setFrame}
                    />
                    {selectionsAndSelectors?.frame?.name !== "Ottoman" && (
                      <>
                        <Step
                          step="2"
                          title="Select Back Cushion"
                          current={selectionsAndSelectors.back}
                          options={backArray}
                          setOption={selectionsAndSelectors.setBack}
                        />
                        <Step
                          step="3"
                          title="Select Arm"
                          current={selectionsAndSelectors.arm}
                          options={armArray}
                          setOption={selectionsAndSelectors.setArm}
                        />
                      </>
                    )}
                    <Step
                      step="4"
                      title="Select Base"
                      current={selectionsAndSelectors.base}
                      options={baseArrayLocal}
                      setOption={selectionsAndSelectors.setBase}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            {showPdp && (
              <>
                <ProductPage
                  data={{ urlResolver: { sku: sku } }}
                  isCustom={true}
                  player={player}
                  allowSectional={allowSectional}
                  pickSectional={pickSectional}
                  currentSectionals={currentSectionals}
                  fabricsSelections={fabricsSelections}
                  frame={selectionsAndSelectors.frame}
                  nailData={nailData}
                  noImage={true}
                  selectionsAndSelectors={selectionsAndSelectors}
                  queryPathFabrics={queryPathFabrics}
                  checkBoxOptionsCustom={checkBoxOptionsCustom}
                  selectBoxOptionsCustom={setCheckBoxOptionsCustom}
                />
              </>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <PromosBYO />
    </>
  );
};

export const CustomDisplay = React.forwardRef(_CustomDisplay);
