import JolaViewer from "@jola_interactive/jola_viewer";
import * as items from "../../pages/Custom/live-edge/data.js";

import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { USDZExporter } from "three/examples/jsm/exporters/USDZExporter";

// import QRCode from "../src/qrcode";

import {
  TextureLoader,
  sRGBEncoding,
  RepeatWrapping,
  Color,
  PlaneBufferGeometry,
  MeshBasicMaterial,
  ShadowMaterial,
  Mesh,
  Fog,
  Euler,
  Box3,
  Vector3,
  LineBasicMaterial,
  BufferGeometry,
  Line,
  FontLoader,
  TextGeometry,
  Group,
  Quaternion,
} from "three";
import themeConfiguration from "../../../config/themeConfiguration";

export const zoomOptions = {
  min: -3,
  current: 0,
  max: 8,
};

export class Player extends JolaViewer {
  constructor(containerID, options) {
    super(containerID, options);
    this.collectionPath = options.collectionPath
      ? options.collectionPath
      : "live-edge";

    this.hdrPath = `${themeConfiguration.model_url}/3d/${this.collectionPath}/webgl/hdr/hdr.hdr`;
    this.loadHDR(this.hdrPath, 0.3);

    this.modelDimensions = new Group();

    this.scene.background = new Color("#fbfbfb");
    this.materials = [];

    this.numOfBases = 0;

    this.modelsPath = `${themeConfiguration.model_url}/3d/${this.collectionPath}/webgl/models`;

    this.textureLoader = new TextureLoader();
    this.nailsEnabled = false;
    this.dracoLoader.setDecoderPath("/decoder/");
    this.loader.setDRACOLoader(this.dracoLoader);
  }

  LoadTexture(url) {
    return new Promise((resolve) => {
      this.textureLoader.load(url, (result) => resolve(result));
    });
  }

  LoadObject(url) {
    return new Promise((resolve) => {
      this.loader.load(url, (result) => {
        resolve(result);
      });
    });
  }

  async Init() {
    await this.SetFloor();

    await this.Load();
  }

  async setTop(top, load = true) {
    this.topSku = top?.sku;
    if (load) await this.Load();
  }

  async setBase(base, load = true) {
    this.baseSku = base?.sku;
    if (load) await this.Load(true);
  }

  copySocketRotationPositionModelAddBase(socket, baseResult, baseChanged) {
    let topSocketWorldPosition = new Vector3();
    socket.getWorldPosition(topSocketWorldPosition);

    // top socket rotation
    let topSocketRotation = new Quaternion();
    socket.getWorldQuaternion(topSocketRotation);

    //Base socket
    let baseSocket;
    baseResult.traverse((element) => {
      if (element.name.startsWith("leg_b")) {
        baseSocket = element;
      }
    });

    //base rotation copied form top socket
    baseResult.quaternion.copy(topSocketRotation);
    baseResult.updateMatrixWorld();

    // base socket world position
    let baseSocketWorldPosition = new Vector3();
    baseSocket.getWorldPosition(baseSocketWorldPosition);

    baseResult.quaternion.copy(topSocketRotation);
    baseResult.updateMatrixWorld();

    //baseResult position and offset
    baseResult.position.copy(topSocketWorldPosition);
    baseResult.updateMatrixWorld();

    baseResult.position.add(baseSocketWorldPosition.negate());

    baseResult.updateMatrixWorld();

    this.model.add(baseResult);
    this.model.updateWorldMatrix();
    if (!baseChanged) this.numOfBases++;
  }

  filterSockets(sockets, position) {
    for (const socket of sockets) {
      if (position === "center" && socket.name === "leg_c") {
      }
    }
  }

  addNumOfBasesToAsUrlParams() {
    const urlParams = new URLSearchParams(window.location.search);
    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?${
        urlParams.toString()?.includes("numOfBases")
          ? urlParams.toString()?.split("&")?.[0]
          : urlParams.toString()
      }&numOfBases=${this.numOfBases}`
    );
  }

  async Load(baseChanged = false) {
    this.numOfBases = 0;
    this.model.children = [];
    this.model.add(this.modelDimensions);
    let topResult = (
      await this.LoadObject(`${this.modelsPath}/tops/${this.topSku}.gltf`)
    ).scene;

    this.model.add(topResult);

    let sockets = [];

    let wideMediumLegs = ["LE16-3B", "YOYO-14-1", "YOYO-14-7", "LE20-3B"];
    let mediumSmallOuterSockets = ["leg_f_2", "leg_f_3"];
    let mediumSmallInnerSockets = ["leg_f", "leg_f_1"];

    topResult.traverse((element) => {
      if (element.name.startsWith("leg_")) {
        sockets.push(element);
      }
    });

    let topItem = items.tops.find((x) => x.sku === this.topSku);
    let baseItem = items.bases.find((x) => x.sku === this.baseSku);

    let baseResult = (
      await this.LoadObject(`${this.modelsPath}/legs/${this.baseSku}.gltf`)
    ).scene;

    if (topItem.large) {
      if (baseItem.single) {
        let baseClone = baseResult.clone();

        for (const socket of sockets.filter((x) => x.name === "leg_c")) {
          this.copySocketRotationPositionModelAddBase(
            socket,
            baseClone,
            baseChanged
          );
          this.addNumOfBasesToAsUrlParams();
        }
      } else {
        for (const socket of sockets.filter((x) => x.name !== "leg_c")) {
          let baseClone = baseResult.clone();
          this.copySocketRotationPositionModelAddBase(
            socket,
            baseClone,
            baseChanged
          );
          this.addNumOfBasesToAsUrlParams();
        }
      }
    } else if (topItem.medium) {
      let baseClone;

      switch (baseItem.topPairing.medium) {
        case "center":
          for (const socket of sockets.filter((x) => x.name === "leg_c")) {
            wideMediumLegs.includes(this.baseSku)
              ? (baseClone = (
                  await this.LoadObject(
                    `${this.modelsPath}/legs/${this.baseSku}-wide.gltf`
                  )
                ).scene)
              : (baseClone = baseResult.clone());

            this.copySocketRotationPositionModelAddBase(
              socket,
              baseClone,
              baseChanged
            );
            this.addNumOfBasesToAsUrlParams();
          }
          break;

        case "inner":
          for (const socket of sockets.filter((x) =>
            mediumSmallInnerSockets.includes(x.name)
          )) {
            baseClone = baseResult.clone();
            this.copySocketRotationPositionModelAddBase(
              socket,
              baseClone,
              baseChanged
            );
            this.addNumOfBasesToAsUrlParams();
          }
          break;
        case "sides":
        default:
          for (const socket of sockets.filter((x) =>
            mediumSmallOuterSockets.includes(x.name)
          )) {
            baseClone = baseResult.clone();
            this.copySocketRotationPositionModelAddBase(
              socket,
              baseClone,
              baseChanged
            );
            this.addNumOfBasesToAsUrlParams();
          }
          break;
      }
    } else if (topItem.small) {
      let baseClone = baseResult.clone();
      switch (baseItem.topPairing.small) {
        case "center":
          for (const socket of sockets.filter((x) => x.name === "leg_c")) {
            baseClone = baseResult.clone();

            this.copySocketRotationPositionModelAddBase(
              socket,
              baseClone,
              baseChanged
            );
            this.addNumOfBasesToAsUrlParams();
          }
          break;
        case "sides":
        default:
          for (const socket of sockets.filter((x) =>
            mediumSmallInnerSockets.includes(x.name)
          )) {
            baseClone = baseResult.clone();
            this.copySocketRotationPositionModelAddBase(
              socket,
              baseClone,
              baseChanged
            );
            this.addNumOfBasesToAsUrlParams();
          }
          break;
      }
    }

    this.updateMap(this.model);

    await this.UpdateCameraPosition();

    document.getElementById("loading-screen").classList.add("fade-out");
  }

  setNailsVisible(value) {
    this.nailsEnabled = value;
    this.updateNails();
  }

  updateNails() {
    this.model.traverse((child) => {
      if (child.name === "side_pins") {
        child.visible = this.nailsEnabled;
      }
    });
  }

  async LoadFinish(
    type,
    map,
    icon,
    name,
    repeat = 1,
    roughness,
    metalness,
    updateCameraPosition = false
  ) {
    let newMap = null;

    if (map) {
      let finishLoader = await this.LoadTexture(map);

      newMap = finishLoader;
      newMap.wrapS = RepeatWrapping;
      newMap.wrapT = RepeatWrapping;
      newMap.repeat.set(repeat, repeat);
    }

    let materialObject = {
      type: type,
      map: newMap,
      icon: icon,
      name: name,
      roughness: roughness || null,
      metalness: metalness || null,
    };

    let found = false;
    this.materials.forEach((material) => {
      if (material.type === type) {
        found = true;

        material.map = newMap;
        material.icon = icon;
        material.name = name;

        material.roughness = 1;
        material.metalness = metalness || null;
      }
    });

    if (!found) {
      this.materials.push(materialObject);
    }

    this.updateMap(this.model);

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async setDimensions(productDimensions) {
    this.productDimensions = productDimensions;
  }

  async setDimensionsVisible(newState) {
    this.dimensionsToggle = newState;

    if (this.dimensionsToggle) {
      await this.ShowDimensions();
    } else {
      await this.HideDimensions();
    }
  }

  async HideDimensions() {
    this.modelDimensions.children = [];
  }

  async ShowDimensions() {
    await this.HideDimensions();

    let box = new Box3().setFromObject(this.model);

    let lineMaterial = new LineBasicMaterial({
      color: 0x000000,
    });

    let lineOffset = 0.02;

    let x = [];
    x.push(
      new Vector3(box.min.x, box.max.y + lineOffset, box.min.z - lineOffset)
    );
    x.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.min.z - lineOffset
      )
    );

    let y = [];
    y.push(
      new Vector3(box.max.x + lineOffset, box.min.y, box.max.z + lineOffset)
    );
    y.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.max.z + lineOffset
      )
    );

    let z = [];
    z.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.max.z + lineOffset
      )
    );
    z.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.min.z - lineOffset
      )
    );

    let geometryX = new BufferGeometry().setFromPoints(x);
    let geometryY = new BufferGeometry().setFromPoints(y);
    let geometryZ = new BufferGeometry().setFromPoints(z);

    let lineX = new Line(geometryX, lineMaterial);
    lineX.name = "lineX";
    let lineY = new Line(geometryY, lineMaterial);
    lineY.name = "lineY";
    let lineZ = new Line(geometryZ, lineMaterial);
    lineZ.name = "lineZ";

    lineX.computeLineDistances();

    this.modelDimensions.add(lineX);
    this.modelDimensions.add(lineY);
    this.modelDimensions.add(lineZ);

    let swapDimensions = false;

    let boxWidth = box.max.x - box.min.x;
    let boxHeight = box.max.y - box.min.y;
    let boxDepth = box.max.z - box.min.z;

    if (
      (boxWidth < boxDepth &&
        Number(this.productDimensions.width) >
          Number(this.productDimensions.depth)) ||
      (boxWidth > boxDepth &&
        Number(this.productDimensions.width) <
          Number(this.productDimensions.depth))
    ) {
      swapDimensions = true;
    }

    let distanceX = swapDimensions
      ? this.productDimensions.depth
      : this.productDimensions.width;
    let distanceY = this.productDimensions.height;
    let distanceZ = swapDimensions
      ? this.productDimensions.width
      : this.productDimensions.depth;

    const fontLoader = new FontLoader();

    fontLoader.load(
      `${themeConfiguration.model_url}/3d/fonts/helvetiker_regular.typeface.json`,
      (font) => {
        const fontGeometryX = new TextGeometry(distanceX.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });

        fontGeometryX.translate(-0.1, 0, 0);

        let fontMeshX = new Mesh(
          fontGeometryX,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        let fontOffset = 0.02;

        fontMeshX.position.copy(
          new Vector3(
            box.min.x + (box.max.x - box.min.x) / 2,
            box.max.y + fontOffset + lineOffset,
            box.min.z
          )
        );
        fontMeshX.updateMatrixWorld();

        fontMeshX.name = "fontMeshX";
        this.modelDimensions.add(fontMeshX);

        const fontGeometryY = new TextGeometry(distanceY.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });

        fontGeometryY.translate(0.05, 0, 0);

        let fontMeshY = new Mesh(
          fontGeometryY,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        fontMeshY.rotateY(-Math.PI / 2);
        fontMeshY.position.copy(
          new Vector3(
            box.max.x,
            box.min.y + (box.max.y - box.min.y) / 2,
            box.max.z
          )
        );
        fontMeshY.updateMatrixWorld();

        fontMeshY.name = "fontMeshY";
        this.modelDimensions.add(fontMeshY);

        const fontGeometryZ = new TextGeometry(distanceZ.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });
        fontGeometryZ.translate(-0.05, 0, 0);

        let fontMeshZ = new Mesh(
          fontGeometryZ,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        fontMeshZ.rotateY(-Math.PI / 2);
        fontMeshZ.position.copy(
          new Vector3(
            box.max.x,
            box.max.y + fontOffset + lineOffset,
            box.min.z + (box.max.z - box.min.z) / 2
          )
        );
        fontMeshZ.updateMatrixWorld();

        fontMeshZ.name = "fontMeshZ";
        this.modelDimensions.add(fontMeshZ);
      }
    );
  }

  update() {
    requestAnimationFrame(this.update);
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    if (this.dimensionsToggle) {
      let fontMeshX = this.modelDimensions.getObjectByName("fontMeshX");
      let fontMeshY = this.modelDimensions.getObjectByName("fontMeshY");
      let fontMeshZ = this.modelDimensions.getObjectByName("fontMeshZ");
      fontMeshX && fontMeshX.lookAt(this.camera.position);
      fontMeshY && fontMeshY.lookAt(this.camera.position);
      fontMeshZ && fontMeshZ.lookAt(this.camera.position);
    }
    this.cameraAngle = this.getCameraAngle();
  }

  updateMap(object) {
    object.traverse((o) => {
      if (o.isMesh) {
        o.castShadow = true;
        o.receiveShadow = true;

        if (o.material.name === "wood") {
          o.material.roughness = 0.45;
          // o.material.metalness = 1;
        }

        let material = this.materials.find(
          (material) => material.type === o.material.name
        );

        if (material) {
          if (material.map) {
            o.material.map = material.map;
            o.material.map.encoding = sRGBEncoding;
          } else {
            o.material.map = undefined;
          }

          // if (material.roughness) o.material.roughness = material.roughness;
          if (material.metalness) o.material.metalness = material.metalness;
          if (material.color) o.material.color = new Color(material.color);
        }

        if (this.envMap) {
          o.material.envMap = this.envMap;
          o.material.envMapIntensity = this.envMapIntensity;
        }

        o.material.needsUpdate = true;
      }
    });
  }

  async UpdateCameraPosition() {
    let box = new Box3().setFromObject(this.model);
    let size = box.getSize(new Vector3()).length();
    let center = box.getCenter(new Vector3());

    if (this.plane) {
      this.plane.position.y = box.min.y;
      this.shadowPlane.position.y = box.min.y;
    }

    this.controls.minDistance = size * 0.75;
    this.controls.maxDistance = size * 1.75;
    this.controls.minPolarAngle = 0.6;
    this.controls.maxPolarAngle = 1.3;

    this.camera.position.copy(center);

    if (size < 1.5) {
      this.controls.maxDistance = size * 2;
      this.camera.position.x -= 4.1; //this.size * 0.1;
      this.camera.position.y += size * 2; // 0.2
      this.camera.position.z += size * 2; //5.3
      this.camera.updateMatrixWorld();
    } else if (size < 2) {
      this.camera.position.x -= 4.1; //this.size * 0.1;
      this.camera.position.y += size * 1.7; // 0.2
      this.camera.position.z += size * 1.8; //5.3
      this.camera.updateMatrixWorld();
    } else {
      this.camera.position.x -= 10; //this.size * 0.1;
      this.camera.position.y += size * 1.6; // 0.2
      this.camera.position.z += size * 2.56; //5.3
      this.camera.updateMatrixWorld();
    }

    this.controls.target = center;
    this.camera.lookAt(center);

    this.camera.updateProjectionMatrix();

    if (this.dimensionsToggle) {
      await this.ShowDimensions();
    }
  }

  setPlaneVisible(newState) {
    if (this.plane) {
      this.plane.visible = newState;
    }
  }

  async SetFloor() {
    let parquetMap = await this.LoadTexture(
      `${themeConfiguration.model_url}/3d/textures/floor/grid.jpg`
    );
    parquetMap.repeat.set(500, 500);
    parquetMap.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    parquetMap.wrapT = RepeatWrapping;
    parquetMap.wrapS = RepeatWrapping;

    let geo = new PlaneBufferGeometry(100, 100);
    let mat = new MeshBasicMaterial({
      map: parquetMap,
    });

    this.plane = new Mesh(geo, mat);
    //plane.visible = false;
    this.plane.receiveShadow = true;
    this.plane.position.set(0, 0, 0);
    this.plane.rotation.set(Math.PI / -2, 0, 0);

    this.scene.fog = new Fog(0xfbfbfb, 10, 20);
    this.scene.add(this.plane);

    let shadowMat = new ShadowMaterial({ opacity: 0.2 });
    this.shadowPlane = new Mesh(geo, shadowMat);
    this.shadowPlane.receiveShadow = true;
    this.shadowPlane.position.set(0, 0, 0);
    this.shadowPlane.rotation.set(Math.PI / -2, 0, 0);
    this.scene.add(this.shadowPlane);
  }

  async LoadFloor(map, repeat) {
    let newMap = await this.LoadTexture(map);
    newMap.repeat.set(repeat, repeat);
    newMap.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    newMap.wrapS = RepeatWrapping;
    newMap.wrapT = RepeatWrapping;

    this.plane.material.map = newMap;
  }

  degreesToRadians = (degrees) => {
    let pi = Math.PI;
    return degrees * (pi / 180);
  };

  determinCameraDistance = (modelSize) => {
    let cameraDistance;
    let halfFOVInRadians = this.degreesToRadians(
      (this.camera.fov * this.camera.aspect) / 4
    );
    let height = modelSize.height;
    cameraDistance = height / 2 / Math.tan(halfFOVInRadians);
    return cameraDistance;
  };

  updateZoom(zoom, diff = 0) {
    let newZoom = 1 + (zoom + diff) / 10;

    if (
      newZoom >= 1 + zoomOptions.min / 10 &&
      newZoom <= 1 + zoomOptions.max / 10
    ) {
      this.controls.object.zoom = newZoom;
      this.controls.object.updateProjectionMatrix();
      return {
        ...zoomOptions,
        current: zoom + diff,
      };
    } else {
      return { ...zoomOptions, current: zoom };
    }
  }

  getCameraAngle = () => {
    const euler = new Euler();
    const rotation = euler.setFromQuaternion(this.camera.quaternion);
    const radians = rotation._z > 0 ? rotation._z : 2 * Math.PI + rotation._z;
    return radians * (180 / Math.PI);
  };

  getCameraPosition() {
    return {
      x: this.camera.position.x,
      y: this.camera.position.y,
      z: this.camera.position.z,
    };
  }

  setCameraPosition({ x = 4, y = -1, z = 4 }) {
    this.camera.position.x = x;
    this.camera.position.y = y;
    this.camera.position.z = z;
  }

  async uploadBlob(name, blob) {
    try {
      return new Promise((resolve) => {
        let xmlhttp = new XMLHttpRequest();
        xmlhttp.open(
          "POST",
          `${themeConfiguration.magento_url}jola_ar/index/index?fileName=${name}`,
          true
        );

        xmlhttp.onload = function () {
          resolve(true);
        };
        xmlhttp.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xmlhttp.send(blob);
      });
    } catch (error) {}
  }

  createQR(input) {
    return new Promise((resolve) => {
      resolve(input);
    });
  }

  viewInRoom() {
    let copyModel;
    this.model.updateMatrixWorld();
    copyModel = this.model.clone();
    let idList = [];
    copyModel.traverse((o) => {
      if (!o.visible) {
        idList.push(o.id);
      }

      if (o.material) {
        o.material.aoMap = null;
        o.material.normalMap = null;
      }
    });
    idList.forEach((result) => {
      let test = copyModel.getObjectById(result);
      let parent = test.parent;
      parent.remove(test);
    });
    let uniqueHash = Date.now();
    let userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.match(/Android/i);
    const isApple = userAgent.match(/iPhone|iPad|iPod/i);

    if (isAndroid) {
      this.createBlob(copyModel, "GLTF").then((gltf) => {
        let glbName = uniqueHash.toString() + ".glb";

        this.uploadBlob(glbName, gltf).then(
          () =>
            (window.location.href = `${themeConfiguration.app_url}build-your-own/live-edge?file_name=${uniqueHash}&ar=true`)
        );
      });
    } else if (isApple) {
      this.createBlob(copyModel, "USDZ").then((usdz) => {
        let usdzName = uniqueHash.toString() + ".usdz";
        this.uploadBlob(usdzName, usdz).then(
          () =>
            (window.location.href = `${themeConfiguration.app_url}build-your-own/live-edge?file_name=${uniqueHash}&ar=true`)
        );
      });
    } else {
      this.createBlob(copyModel, "GLTF").then((gltf) => {
        let glbName = uniqueHash.toString() + ".glb";
        this.uploadBlob(glbName, gltf).then(() => {
          this.createBlob(copyModel, "USDZ").then((usdz) => {
            let usdzName = uniqueHash.toString() + ".usdz";
            this.uploadBlob(usdzName, usdz).then(() => {
              this.createQR(
                `${themeConfiguration.app_url}build-your-own/live-edge?file_name=${uniqueHash}&ar=true`
              ).then((url) => {
                copyModel.clear();
                return url;
              });
            });
          });
        });
      });
    }
    return uniqueHash;
  }
}
