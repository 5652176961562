import React from "react";
import useFilterTypes from "./hook";

const FilterTypes = (props) => {
  let {
    filters,
    activeFilters,
    setActiveFilters,
    selectedFilters,
    setSelectedFilters,
    currentPage,
    setCurrentPage,
    allItems,
  } = props;
  const {
    GetMultiSelectFilter,
    // , GetSwitchFilter
  } = useFilterTypes();

  return (
    <>
      {Object.entries(filters)
        .sort()
        .map((filterType) => {
          if (filterType[0] === "boolean") {
            // return GetSwitchFilter(
            //   filterType,
            //   activeFilters,
            //   setActiveFilters,
            //   currentPage,
            //   setCurrentPage,
            //   allItems
            // );
          } else {
            return GetMultiSelectFilter(
              filterType,
              activeFilters,
              setActiveFilters,
              selectedFilters,
              setSelectedFilters,
              (currentPage = { currentPage }),
              (setCurrentPage = { setCurrentPage }),
              allItems
            );
          }
        })}
    </>
  );
};
export default FilterTypes;
