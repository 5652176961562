import React from "react";
import { MDBBox, MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdbreact";
import Image from "core/components/image";
import { Collapse } from "app/layout/Collapse";
import CmsBlock from "app/layout/cmsBlock";
import fairShieldImg from "app/assets/images/fairShieldPlus.jpg";
import _ from "lodash";

const FinishesInfo = ({
  finishesDetails,
  ffifinish_fairshield,
  name,
  finishes_cleaning,
  about_fairshield_plus,
  add_info,
}) => {
  let urlMonogram = window.location.href.indexOf("monogram") > -1;
  let ulrStandardUpholstery =
    window.location.href.indexOf("standard-upholstery-finishes") > -1;
  return (
    <MDBCol
      size="12"
      lg="6"
      className="px-lg-5 px-0 mt-lg-0 mt-5 finishes-info-wrapper"
    >
      <MDBContainer>
        <MDBRow>
          <MDBCol size="12">
            <MDBBox className="mb-5">
              {(ffifinish_fairshield === 1 && (
                <MDBBox
                  style={{ maxWidth: "15rem" }}
                  className="d-inline-block"
                >
                  <Image className="img-fluid" source={fairShieldImg} />
                </MDBBox>
              )) || <></>}
            </MDBBox>

            <MDBBox className="my-4">
              <MDBTypography tag="h1">{name}</MDBTypography>
            </MDBBox>
            <Collapse className="my-2" title="Details" isOpen={true}>
              <MDBContainer className="my-4">
                <>
                  {finishesDetails ? (
                    Object.keys(finishesDetails).map((name, index) => {
                      const attrName = _.capitalize(name).replace(/_/g, " ");
                      return (
                        <React.Fragment key={index}>
                          <MDBRow className="py-2">
                            <MDBCol size="6" className="">
                              <MDBTypography
                                tag="h4"
                                className="text-primary fwlight"
                              >
                                {attrName}
                              </MDBTypography>
                            </MDBCol>
                            <MDBCol size="6">
                              <MDBTypography
                                tag="h4"
                                className="text-primary fwthin"
                              >
                                {finishesDetails[name]}
                              </MDBTypography>
                            </MDBCol>
                          </MDBRow>
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </>
              </MDBContainer>
            </Collapse>
            <MDBBox>
              <MDBTypography tag="span" className="body-2 ">
                <CmsBlock id="finishes_info" />
              </MDBTypography>
            </MDBBox>
          </MDBCol>
          {about_fairshield_plus ? (
            <MDBCol size="12">
              <Collapse
                className="my-2 custom-collapse"
                title={
                  !urlMonogram && !ulrStandardUpholstery
                    ? `About`
                    : urlMonogram
                    ? "About Monogram Finishes"
                    : ulrStandardUpholstery
                    ? "About Upholstery Finishes"
                    : "About"
                }
              >
                <MDBTypography tag="p" className="body-2 pl-4 pr-4">
                  {about_fairshield_plus ? (
                    about_fairshield_plus
                  ) : (
                    <></>
                    // <CmsBlock id="finishes_about" />
                  )}
                </MDBTypography>
              </Collapse>
            </MDBCol>
          ) : (
            <></>
          )}
          {add_info ? (
            <MDBCol size="12">
              <Collapse
                className="my-2 custom-collapse"
                title="Additional Info"
              >
                <MDBTypography tag="p" className="body-2 pl-4 pr-4">
                  {add_info ? add_info : <></>}
                </MDBTypography>
              </Collapse>
            </MDBCol>
          ) : null}
          {finishes_cleaning ? (
            <MDBCol size="12">
              <Collapse
                className="my-2 custom-collapse"
                title="Cleaning and Care"
              >
                <MDBTypography tag="p" className="body-2 pl-4 pr-4">
                  {finishes_cleaning ? finishes_cleaning : <></>}
                </MDBTypography>
              </Collapse>
            </MDBCol>
          ) : null}
        </MDBRow>
      </MDBContainer>
    </MDBCol>
  );
};
export default FinishesInfo;
