import React, { useMemo } from "react";
import { Link } from "react-router-dom";

const SelectedOption = ({ option, isLoggedIn, optionTitle = "" }) => {
  const checkIfFalse = (item) => (item === "false" ? null : item);
  const showStock = useMemo(() => {
    if (option) {
      if ("stockQty" in option && option?.stockQty !== null) return true;
      return false;
    }
    return false;
  }, [option]);
  return (
    <div className="container selected-option">
      <div className="row">
        <div className="col-lg-5 col-6">
          <img src={option?.image?.url} alt={option?.name} />
        </div>
        <div className="col-lg-7 col-6">
          {option?.pattern && checkIfFalse(option?.pattern?.[0]) ? (
            <div className="d-grid">
              <p>Pattern</p>
              <p>{option?.pattern?.[0]}</p>
            </div>
          ) : null}
          {option?.color && checkIfFalse(option?.color) ? (
            <div className="d-grid">
              <p>Name</p>
              <p>{option?.name}</p>
            </div>
          ) : null}
          {option?.grade && checkIfFalse(option?.grade?.[0]) ? (
            <div className="d-grid">
              <p>Grade</p>
              <p>{option?.grade?.[0]}</p>
            </div>
          ) : null}
          {option?.collection_label ? (
            <div className="d-grid">
              <p>Collection</p>
              <p>{option?.collection_label}</p>
            </div>
          ) : null}
          {option?.add_info && optionTitle === "Finish" ? (
            <div className="d-grid">
              <p>Additional information</p>
              <p>{option?.add_info}</p>
            </div>
          ) : null}
          {option?.url_key && optionTitle !== "Nail Finish" ? (
            <div className="d-grid">
              <p>More Details</p>
              <Link to={`/${option?.url_key}`} target="_blank">
                Click Here
              </Link>
            </div>
          ) : null}
          {isLoggedIn && showStock ? (
            <div className="d-grid stock">
              <p>Stock</p>
              {option?.stockQty && Number(option?.stockQty) > 0 ? (
                <p>
                  {option?.stockQty}{" "}
                  {`${option.ffifabrics_fabric === 1 ? " yards" : " sq. ft."}`}
                </p>
              ) : (
                <p>Out of stock</p>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SelectedOption;
