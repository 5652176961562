const lock = store => {
  const currentState = store.getState();
  if (currentState.common.locked === true) {
    return;
  }

  return new Promise(function(resolve, reject) {
    return resolve(true);
  });
};

export default lock;
