import { MDBCol, MDBRow, MDBTypography, MDBBox } from "mdbreact";
import React from "react";
import { FileId, FilesAndTag, FileTypes, SelectedFiles } from "../../models";
import FileData from "../FileData/FileData";
import Icon from "app/assets/icon/icon";
import Headers, { HeadersProps } from "../Headers";

export interface FilesByTagProps extends HeadersProps {
  filesAndTag: FilesAndTag;
  onDownloadFile: (id: FileId) => void;
  selectedFiles: SelectedFiles;
  onToggleFileById: (id: FileId) => void;
  fileTypesItem: FileTypes;
  onDownloadAll: ([]) => void;
}

const FilesByTag: React.FC<FilesByTagProps> = (props) => {
  let filteredItems = props.filesAndTag.files.filter((file) => {
    //@ts-ignore
    return file?.file_type[props.fileTypesItem] === 1;
  });

  return (
    (filteredItems.length !== 0 && (
      <MDBRow className="resources-item mb-md-5 mb-4 pb-md-5">
        <MDBCol>
          <MDBBox className="pb-4 border-bottom-1 d-flex justify-content-between">
            {/*@ts-ignore*/}
            <MDBTypography tag="h1">{props.filesAndTag.tag.name}</MDBTypography>
            <MDBBox
              className="d-none d-lg-flex align-items-center justify-content-end  cursor-pointer"
              //@ts-ignore
              onClick={() => {
                props.onDownloadAll(props.filesAndTag.files);
              }}
            >
              <Icon className="mr-4 download-icon" icon={"downloadGray"} />
              <MDBTypography className="link-2 fwregular m-0">
                Download All
              </MDBTypography>
            </MDBBox>
          </MDBBox>
          <Headers
            sortOrders={props.sortOrders}
            onSortOrderChange={props.onSortOrderChange}
          />
          <div className="mt-4 d-none d-lg-block border-bottom-1" />
          {props.filesAndTag.files
            .filter((file) => {
              //@ts-ignore
              return file?.file_type[props.fileTypesItem] === 1;
            })
            .map((file) => {
              if (file?.size?.toLowerCase()?.includes("kb")) {
                let size = file?.size?.split(" ")?.[0];
                // @ts-ignore
                file.size = `${(size / 1024)?.toFixed(2)} MB`;
              }

              return (
                <FileData
                  key={file.file}
                  fileData={file}
                  onDownloadFile={() => props.onDownloadFile(file.file)}
                  isSelected={props.selectedFiles[file.file]}
                  onToggleFile={() => props.onToggleFileById(file.file)}
                />
              );
            })}
        </MDBCol>
      </MDBRow>
    )) || <></>
  );
};

export default FilesByTag;
