import types from "./types";
import addToCompare from "./reducers/addToCompare";
import removeCompare from "./reducers/removeCompare";
import clearCompare from "./reducers/clearCompare";
import setCompare from "./reducers/setCompare";
import setOpenState from "./reducers/setOpenState";

// Main reducer
const compareReducer = (state, action) => {
  if (state === undefined) {
    return {};
  }

  if (action.type === types.ADD_TO_COMPARE) {
    return addToCompare(state, action);
  }

  if (action.type === types.REMOVE_COMPARE) {
    return removeCompare(state, action);
  }

  if (action.type === types.CLEAR_COMPARE) {
    return clearCompare(state, action);
  }

  if (action.type === types.SET_COMPARE) {
    return setCompare(state, action);
  }

  if (action.type === types.SET_OPEN_STATE) {
    return setOpenState(state, action);
  }

  return state;
};

export default compareReducer;
