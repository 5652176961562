export const powerOptionFunction = (option) => {
  let parsedValues = option?.values[0]?.value
    ? JSON.parse(option?.values[0]?.value?.replace(/\&quot;/g, `"`))
    : "";
  if (parsedValues?.powerOption) {
    return `&powerOption=${parsedValues?.powerOption?.id}`;
  } else return "";
};

export const monogramLogic = (item) => {
  let monogramString = item?.customizable_options
    ?.find((option) => option?.values?.[0]?.value?.includes("{"))
    ?.values?.[0]?.value?.replace(/\&quot;/g, `"`);
  let obj = monogramString && JSON.parse(monogramString);
  let str = Object.keys(obj)
    .map((key) => `${key}=${obj[key]?.id}`)
    .join("&");
  return `/build-your-own/monogram/${obj?.model?.id}?${str}&item_id=${item?.id}`;
};
