// @ts-check

import clsx from "clsx";
import React from "react";
import ReactMaskedInput from "react-text-mask";
import Label from "../Label";

/**
 * @typedef {{ label: string } & import('react-text-mask').MaskedInputProps} MaskedInputProps
 */
/**
 * @type {React.FC<MaskedInputProps>}
 */
const MaskedInput = ({ className, guide = false, ...props }) => {
  return (
    <div>
      <Label
        text={props.label}
        required={props.required}
        disabled={props.disabled}
        className="mb-3"
      />
      <ReactMaskedInput
        {...props}
        guide={guide}
        className={clsx(className, "masked-input", "w-100")}
      />
    </div>
  );
};

export default MaskedInput;
