import { MDBCol, MDBInput, MDBRow } from "mdbreact";
import React from "react";
import { ItemProps } from "../models";

export interface CommentSectionProps
  extends Pick<ItemProps, "comment" | "onCommentChange">,
    Pick<React.ComponentProps<typeof MDBCol>, "lg"> {}

const CommentSection: React.FC<CommentSectionProps> = ({
  lg,
  comment,
  onCommentChange,
}) => {
  return (
    <MDBRow className="mb-5 pb-5">
      <MDBCol lg={lg}>
        <MDBInput
          containerClass="w-100"
          type="textarea"
          hint="Comments"
          rows="4"
          value={comment}
          // @ts-ignore
          onChange={onCommentChange}
        />
      </MDBCol>
    </MDBRow>
  );
};

export default CommentSection;
