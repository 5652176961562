import { ModalConsumer } from "core/components/modal/ModalContext";
import { MDBBox, MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React, { useState, useEffect, useContext } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { FabricSidebar } from "../fabricSidebar";
// import { fabricArray } from "../../../Custom/haven/data";
// import { mainFabricName } from "./OptionSection";
import { calculateUpholsteryOptionSelectorPrice } from "./functions";
import { CustomPriceContext } from "../customPriceContext";
import { CheckBoxContext } from "../checkboxContext";

export const UpholsteryOption = ({
  current,
  setCustomizationOption,
  removeCustomizationOption,
  option = { title: "", value: [{ title: "", option_type_id: 1 }] },
  values = [],
  productImage,
  defaults,
  configFabricProps,
  frame,
  isCustom,
  fabricOrFinishesSelectedOptions,
  setFabricOrFinishSelectedOptions,
  customizationOptions,
  optionClassNames,
  queryPathFabrics,
  fabricsSelections,
  foundFabrics,
}) => {
  let [allItems, setAllItems] = useState([]);
  let [initLoaded, setInitLoaded] = useState(false);

  let sidebarProps = {
    setCustomizationOption,
    configFabricProps,
    removeCustomizationOption,
    fabrics: values,
    current,
    option,
    frame,
    defaults,
    customizationOptions,
    allItems,
    setAllItems,
  };
  const {
    customizationOptionPrice,
    upholsteryOptionsFabrics,
    customer,
    price,
    setPrice,
    dropdownPrice,
    viewMorePdpPrice,
    trimPrice,
    setTrimPrice,
    contrastingIds,
    // setMainFabric,
  } = useContext(CustomPriceContext);
  const {
    setSelectedFinish,
    selectedFinish,
    preselection,
    setPreselection,
  } = useContext(CheckBoxContext);
  const [localPrice, setLocalPrice] = useState(false);
  const initFabric = JSON.parse(
    window.localStorage.getItem("ff_pdp_options")
  )?.["mainFabric"];
  useEffect(() => {
    setLocalPrice(price);
  }, [price]);
  let [skus, setSkus] = useState([]);
  // const foundFabrics = useQuery(FABRICS_WITHOUT_AGGREGATIONS, {
  //   variables: {
  //     filters: {
  //       sku: { in: skus },
  //     },
  //     pageSize: 100,
  //     currentPage: 1,
  //   },
  // });

  const [fabric, setFabric] = useState();
  const [calculatedPrice, setCalculatedPrice] = useState("");
  const { defaultFabric } = defaults;
  const [
    calculatedUpholsteryOptionsFabrics,
    setCalculatedUpholsteryOptionsFabrics,
  ] = useState(null);

  const [selectedOption, setSelectedOption] = useState(
    preselection?.[option?.title?.toLowerCase().split(" ").join("_") || ""]
      ? preselection[option?.title?.toLowerCase().split(" ").join("_") || ""]
      : option?.title.toLowerCase() !== "base trim" &&
        option?.title.toLowerCase() !== "decorative tape frame" &&
        option?.title.toLowerCase() !== "skirt trim" &&
        option?.title.toLowerCase() !== "nail band" &&
        option?.title.toLowerCase() !== "throw pillow contrast welt" &&
        option?.title.toLowerCase() !== "throw pillow decorative cord" &&
        option?.title.toLowerCase() !== "throw pillow fringe" &&
        option?.title.toLowerCase() !== "kidney pillow contrast welt" &&
        option?.title.toLowerCase() !== "kidney pillow decorative cord" &&
        option?.title.toLowerCase() !== "kidney pillow fringe"
      ? defaultFabric
      : null
  );

  useEffect(() => {
    if (
      customer &&
      option?.category_options?.toLowerCase().indexOf("pillow") === -1 &&
      option?.title?.toLowerCase().search("(contras)\\w+ welt|buttons") ===
        -1 &&
      option?.title?.toLowerCase() !== "nail band"
    ) {
      setCalculatedPrice();
      calculateUpholsteryOptionSelectorPrice(upholsteryOptionsFabrics, fabric);
    }
  }, [fabric, upholsteryOptionsFabrics, price, queryPathFabrics]);
  useEffect(() => {
    if (
      customer &&
      option?.category_options?.toLowerCase().indexOf("pillow") === -1 &&
      option.title?.toLowerCase().search("(contras)\\w+ welt|buttons") === -1 &&
      option?.title?.toLowerCase() !== "nail band" &&
      option?.title?.toLowerCase() !== "decorative cord" &&
      option?.title?.toLowerCase() !== "fringe"
    ) {
      setCalculatedUpholsteryOptionsFabrics(upholsteryOptionsFabrics);
    }
  }, [calculatedPrice]);

  const findPreselectedOption = (arrayOfOptions, option) => {
    return arrayOfOptions.find((item) => item.sku === option);
  };

  useEffect(() => {
    let queryFabric;
    if (queryPathFabrics) {
      queryFabric = foundFabrics?.data?.products?.items
        .filter((fabric) => fabric.sku !== defaults.defaultFabric?.sku)
        .find(
          (fabric) =>
            fabric.sku ===
            queryPathFabrics?.[
              option.title.toLowerCase().split(" ").join("_")
            ]?.toString()
        );

      if (queryFabric && isCustom) {
        switch (option.title) {
          case "Main Fabric":
            fabricsSelections.setMainFabric(queryPathFabrics.main_fabric);
            break;
          case "Cushion":
            fabricsSelections.setCushionFabric(queryPathFabrics.cushion);
            fabricsSelections.setSeatFabric(queryPathFabrics.cushion);
            break;
          case "Tight Seat":
            fabricsSelections.setCushionFabric(queryPathFabrics.tight_seat);
            fabricsSelections.setSeatFabric(queryPathFabrics.tight_seat);
            break;
          case "Back Pillow":
            fabricsSelections.setBackFabric(queryPathFabrics.back_pillow);
            break;
          case "Throw Pillow Contrast":
            fabricsSelections.setPillowFabric(queryPathFabrics.contrast);
            fabricsSelections.setThrowPillowFabric(queryPathFabrics.contrast);
            break;
          case "Contrast Welt":
            fabricsSelections.setPillowWeltFabric(
              queryPathFabrics.contrast_welt
            );
            break;
          case "Fabric Contrast Welt":
            fabricsSelections.setWeltFabric(
              queryPathFabrics.fabric_constrast_welt
            );
            break;
          case "Decorative Cord":
            fabricsSelections.setDecorativeCordFabric(
              queryPathFabrics.decorative_cord
            );
            break;
        }
      } else {
        if (
          queryPathFabrics[
            option?.title?.toLowerCase().split(" ").join("_") || ""
          ] &&
          !preselection[option?.title?.toLowerCase().split(" ").join("_") || ""]
        ) {
          let localPreselection = JSON.parse(JSON.stringify(preselection));
          localPreselection[option.title.toLowerCase().split(" ").join("_")] =
            queryPathFabrics[
              option?.title?.toLowerCase().split(" ").join("_") || ""
            ];
          setPreselection(localPreselection);
        }
      }
    }

    if (typeof queryFabric === "object" && !initLoaded) {
      setInitLoaded(true);
      let optionsObj = {};
      if (Object.keys(queryPathFabrics).length > 0) {
        for (const key in queryPathFabrics) {
          optionsObj[key] = findPreselectedOption(
            foundFabrics?.data?.products?.items,
            queryPathFabrics?.[key]
          );
        }
      }
      if (optionsObj?.["finish"]) {
        setSelectedFinish({
          ...selectedFinish,
          finish: optionsObj?.["finish"],
        });
      }

      let fabricOrFinishOptions = [];
      for (const key in optionsObj) {
        fabricOrFinishOptions.push({
          id: optionsObj?.[key]?.id,
          value_string: optionsObj?.[key]?.sku,
          // @ts-ignore
          fabric: optionsObj?.[key],
          // @ts-ignore
          finish: optionsObj?.["finish"] ? optionsObj?.["finish"] : undefined,
          key,
        });
      }

      let correctionFabricsAndFinishesArray = [];
      customizationOptions.forEach((opt) => {
        fabricOrFinishOptions.map((ff_option) => {
          if (opt.title.toLowerCase().split(" ").join("_") === ff_option.key) {
            correctionFabricsAndFinishesArray.push({
              ...ff_option,
              id: opt.option_id,
            });
          }
        });
      });

      setFabricOrFinishSelectedOptions(correctionFabricsAndFinishesArray);
      window.localStorage.setItem("ff_pdp_options", JSON.stringify(optionsObj));
    }

    if (
      option.title.toLowerCase() === "base trim" ||
      option.title.toLowerCase() === "decorative tape frame" ||
      option.title.toLowerCase() === "skirt trim" ||
      option.title.toLowerCase() === "decorative cord" ||
      option.title.toLowerCase() === "fringe" ||
      option.title.toLowerCase() === "nail band" ||
      option.title.toLowerCase() === "throw pillow contrast welt" ||
      option.title.toLowerCase() === "throw pillow decorative cord" ||
      option.title.toLowerCase() === "throw pillow fringe" ||
      option.title.toLowerCase() === "kidney pillow contrast welt" ||
      option.title.toLowerCase() === "kidney pillow decorative cord" ||
      option.title.toLowerCase() === "kidney pillow fringe"
    ) {
      setFabric(current?.fabric ?? queryFabric ?? null);
    } else {
      setFabric(
        current?.fabric ?? queryFabric ?? defaults.defaultFabric ?? null
      );
    }
  }, [
    configFabricProps,
    current,
    defaults,
    foundFabrics,
    option,
    queryPathFabrics,
    defaultFabric,
    preselection,
    fabricsSelections,
  ]);

  useEffect(() => {
    return () => {
      window.localStorage.removeItem("ff_pdp_options");
    };
  }, []);

  return (
    <ModalConsumer value={{ useWrapper: false, price }}>
      {({ showModal, hideModal, updateProps }) => {
        return (
          <MDBRow
            className={`w-100 height-40 border rounded upholstery-selections d-flex align-items-center ${optionClassNames}`}
            onClick={(e) => {
              e.preventDefault();
              showModal(({ selectedOption }) => {
                return (
                  <RemoveScroll>
                    <FabricSidebar
                      hideModal={hideModal}
                      updateProps={updateProps}
                      {...sidebarProps}
                      productImage={productImage}
                      isCustom={isCustom}
                      optionId={option.option_id}
                      fabricOrFinishesSelectedOptions={
                        fabricOrFinishesSelectedOptions
                      }
                      setFabricOrFinishSelectedOptions={
                        setFabricOrFinishSelectedOptions
                      }
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      fabric={fabric}
                      setFabric={setFabric}
                      customizationOptions={customizationOptions}
                      customizationOptionPrice={customizationOptionPrice}
                      calculatedUpholsteryOptionsFabrics={
                        calculatedUpholsteryOptionsFabrics
                      }
                      price={localPrice}
                      upholsteryOptionsFabrics={upholsteryOptionsFabrics}
                      customer={customer}
                      setPrice={setPrice}
                      currentOption={option}
                      dropdownPrice={dropdownPrice}
                      viewMorePdpPrice={viewMorePdpPrice}
                      trimPrice={trimPrice}
                      setTrimPrice={setTrimPrice}
                      contrastingIds={contrastingIds}
                    />
                  </RemoveScroll>
                );
              });
            }}
          >
            {fabric ? (
              <>
                <MDBCol size="1">
                  <MDBBox
                    className="border rounded border-default"
                    style={{
                      width: "25px",
                      height: "25px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      className="img-fluid"
                      src={
                        initFabric?.image?.url ??
                        fabric?.image?.url ??
                        defaults?.fabric?.image?.url
                      }
                      alt={
                        initFabric?.image?.label ??
                        fabric?.image?.label ??
                        defaults?.defaultFabric.image.name
                      }
                    />
                  </MDBBox>
                </MDBCol>
                <MDBCol size="8">
                  <MDBTypography tag="p" className="m-0 body-6">
                    {initFabric ? initFabric?.name : fabric?.name}, Pattern{" "}
                    {initFabric ? initFabric?.pattern : fabric?.pattern}, Grade{" "}
                    {initFabric ? initFabric?.grade : fabric?.grade}
                  </MDBTypography>
                </MDBCol>
                {/*<MDBCol size="1" style={{ fontSize: "1.4rem" }}>*/}
                {/*  {calculatedPrice}*/}
                {/*</MDBCol>*/}
              </>
            ) : (
              <MDBCol>
                <MDBTypography tag="p" className="m-0 body-6">
                  Please select a {option.title}
                </MDBTypography>
              </MDBCol>
            )}
          </MDBRow>
        );
      }}
    </ModalConsumer>
  );
};
