import React from "react";
import { connect } from "react-redux";
import { default as queriesActions } from "core/state/redux/data/queries/actions";
import Home from "./home";

const HomeReduxContainer = (props) => {
  let token = false;
  let data = false;

  //   token = customerIsLoggedIn(props.customer);
  //   let loading = !(typeof props.data !== "undefined" && !props.data[props.id]);

  return <Home {...props} token={token} data={data} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    addQueryData: (data, identifier, key) => {
      dispatch(queriesActions.addQueryData(data, identifier, key));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeReduxContainer);
