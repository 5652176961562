import React, { useMemo } from "react";
import { useCountryOptionsQuery } from "app/generated/graphql";

function UpPart({ order, formatDate, isTablet }) {
  const countryOptionsQuery = useCountryOptionsQuery();
  const {
    project_name,
    project_due_date,
    timeline,
    additional_details,
  } = order?.project_details;

  const {
    city: billing_city,
    street: billing_street,
    firstname: billing_firstname,
    lastname: billing_lastname,
    country_code: billing_country_code,
    postcode: billing_postcode,
  } = order?.billing_address;
  const {
    city: shipping_city,
    street: shipping_street,
    firstname: shipping_firstname,
    lastname: shipping_lastname,
    country_code: shipping_country_code,
    postcode: shipping_postcode,
  } = order?.shipping_address;

  const shippingCountry = useMemo(
    () =>
      countryOptionsQuery.data?.countries?.find(
        (country) => country?.value === shipping_country_code
      ),
    [countryOptionsQuery, shipping_country_code]
  );
  const billingCountry = useMemo(
    () =>
      countryOptionsQuery.data?.countries?.find(
        (country) => country?.value === billing_country_code
      ),
    [countryOptionsQuery, billing_country_code]
  );

  return (
    <>
      <div className={`d-flex mb-4 ${isTablet && "flex-column"}`}>
        <div className="flex-1">
          <p className="font-weight-bold mb-5">Quote Request Details</p>
          <div className="d-flex">
            <p>Quote date</p>
            <p>{formatDate(new Date(order?.order_date))}</p>
          </div>
          <div className="d-flex">
            <p>Quote status</p>
            <p>{order?.status}</p>
          </div>
        </div>
        <div className="flex-1">
          <p className="font-weight-bold mb-5">Project Information</p>
          <div className="d-flex">
            <p>Project Name</p>
            <p>{project_name}</p>
          </div>
          <div className="d-flex">
            <p>Project Due Date</p>
            <p>{formatDate(new Date(project_due_date))}</p>
          </div>
          <div className="d-flex">
            <p>Timeline</p>
            <p>{timeline}</p>
          </div>
          {additional_details ? (
            <div className="d-flex">
              <p>Additional Details</p>
              <p>{additional_details}</p>
            </div>
          ) : null}
        </div>
      </div>
      <div className="d-flex">
        {/* <div className="flex-1">
          <p className="font-weight-bold mb-5">Billing Address</p>
          <div className="d-flex">
            <p>{`${billing_firstname} ${billing_lastname}`}</p>
          </div>
          <div className="d-flex">
            <p>{billing_street?.[0]}</p>
          </div>
          {!!billing_street?.[1] && <div className="d-flex">
            <p>{billing_street?.[1]}</p>
          </div>}
          <div className="d-flex">
            <p>{`${billing_city}, ${billing_country_code} ${billing_postcode}`}</p>
          </div>
          <div className="d-flex">
            <p>{billingCountry?.label}</p>
          </div>
        </div> */}
        <div className="flex-1">
          <p className="font-weight-bold mb-5">Ship to Address</p>
          <div className="d-flex">
            <p>{`${shipping_firstname} ${shipping_lastname}`}</p>
          </div>
          <div className="d-flex">
            <p>{shipping_street?.[0]}</p>
          </div>
          {!!shipping_street?.[1] && (
            <div className="d-flex">
              <p>{shipping_street?.[1]}</p>
            </div>
          )}
          <div className="d-flex">
            <p>{`${shipping_city}, ${shipping_country_code} ${shipping_postcode}`}</p>
          </div>
          <div className="d-flex">
            <p>{shippingCountry?.label}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpPart;
