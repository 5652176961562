import gql from "graphql-tag";

export const countriesQuery = gql`
  query {
    countries {
      value: id
      regions: available_regions {
        value: id
        label: name
      }
      label: full_name_english
    }
  }
`;
