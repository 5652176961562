import { MDBBtn, MDBCol, MDBTypography } from "mdbreact";
import React from "react";
import { WishlistNameLength } from "../../config";
import { useCreationWishlistForm } from "../../hooks";
import { overviewWrapperStyle } from "../../styles";
import NameField from "../NameField";

export interface CreateWishlistProps {
  form: any | undefined;
}

const CreateWishlist: React.FC<CreateWishlistProps> = (props) => {
  return (
    <MDBCol className="col-12 mb-3 create-project-block">
      <div className="position-relative create-new-project">
        {/* <div className="position-absolute w-100 bg-gray-lighten-4 py-3">
          <MDBTypography tag="h3" variant="h3" className="text-center mb-0">
            Create New List
          </MDBTypography>
        </div> */}
        <form className="d-flex flex-column align-items-center justify-content-center create-new-project-form">
          <NameField
            wrapperClass={"create-new-project-name mb-4 w-100"}
            fieldProps={props.form.getFieldProps("name")}
            error={props.form.touched.name && props.form.errors.name}
            length={props.form.values.name.length}
            maxLength={WishlistNameLength.max}
            // size="lg"
            hint="Create new list"
          />
        </form>
      </div>
    </MDBCol>
  );
};

export default CreateWishlist;
