import clsx from "clsx";
import { ModalConsumer } from "core/components/modal/ModalContext";
import { FormikProps } from "formik";
import { MDBBtn, MDBCol, MDBInput, MDBRow, MDBTypography } from "mdbreact";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { customerPaths } from "../../../routes";
import { EmailValues } from "./config";
import { useMediaQuery } from "react-responsive";

interface smallImage {
  url?: string;
  label?: string;
}

interface selectedProductInterface {
  custom_image?: string;
  small_image?: smallImage;
  sku?: string;
  name?: string;
}

export interface EmailAndPasswordProps {
  onExpressSetup: () => void;
  form: FormikProps<EmailValues>;
  windowY?: number;
  selectedProduct: selectedProductInterface;
}

const EmailAndPassword: React.FC<EmailAndPasswordProps> = ({
  onExpressSetup,
  form,
  windowY,
  selectedProduct,
}) => {
  React.useEffect(() => {
    if (windowY && windowY > 0) {
      window.scrollTo({ top: windowY });
    }
  }, []);

  const history = useHistory();
  let isMobile = useMediaQuery({ maxWidth: 480 });

  return (
    <MDBRow
      className={`flex-grow-1 pb-4 ${!selectedProduct && "bg-gray-lighten-4"}`}
    >
      {selectedProduct && (
        <>
          <MDBCol size="6" md="6" className="mb-3 mt-5 text-center">
            <img
              style={{ maxWidth: "100%", maxHeight: "150px" }}
              src={
                selectedProduct?.custom_image
                  ? selectedProduct?.custom_image
                  : selectedProduct?.small_image?.url
              }
              alt={selectedProduct?.small_image?.label}
            />
          </MDBCol>
          <MDBCol
            size="6"
            md="6"
            className="mb-3 mt-5 d-flex flex-column justify-content-center"
          >
            {/* @ts-ignore */}
            <h4 className="mb-2">{selectedProduct?.sku}</h4>
            {/* @ts-ignore */}
            <h4 className="mb-3">{selectedProduct?.name}</h4>
          </MDBCol>
          <MDBCol size="12">
            <div className="mt-4 mb-5 mx-4 px-5">
              <div className="mt-5 dark separator" />
              <MDBCol className="text-center mx-auto mb-3 mt-5">
                <h2>Before you do that...</h2>
              </MDBCol>
              <MDBCol size="12" className="text-center favorites-subtitle">
                <h3>Sign in with your email</h3>
              </MDBCol>
            </div>
          </MDBCol>
        </>
      )}
      <MDBCol className="mb-2 p-0 d-flex flex-column ">
        <div
          className={`${!selectedProduct && "bg-gray-lighten-4"} pb-5`}
          style={{
            width: !isMobile ? "450px" : "100%",
            /* @ts-ignore */
            margin: "0 auto",
          }}
        >
          <div className="mt-4 mb-5 mx-4 px-5">
            {!selectedProduct && (
              <div>
                <MDBTypography className="fs-40 login-title mb-0">
                  Login
                </MDBTypography>
                {/* @ts-ignore */}
                {/*<MDBTypography className="mt-4 pt-2" variant="body-4">*/}
                {/*  If you're an existing Fairfield Chair customer, enter your*/}
                {/*  email below*/}
                {/*</MDBTypography>*/}
              </div>
            )}
            <form className="mt-5 pt-4" onSubmit={form.handleSubmit}>
              <div className="position-relative">
                <MDBInput
                  {...form.getFieldProps("email")}
                  label="Email"
                  required
                  hint="email@email.com"
                  containerClass="m-0 w-100"
                  className={clsx({
                    error: form.touched.email && form.errors.email,
                  })}
                />
                <div className="position-absolute text-red">
                  {form.touched.email && form.errors.email}
                </div>
              </div>
              <div className="mt-4 pt-2">
                <MDBBtn type="submit" className={`w-100 m-0`}>
                  Continue
                </MDBBtn>
              </div>
            </form>
            {selectedProduct && (
              <MDBCol size="12" className="text-center mt-4">
                <h4 className="favorites-bottom-message mb-3 mt-5">
                  Don't have an account with us?
                </h4>
                <h4 className="favorites-bottom-message">
                  Please{" "}
                  <span
                    onClick={() => {
                      // @ts-ignore
                      document.querySelector(".close-icon")?.click();
                      history.push("/customer/register");
                    }}
                  >
                    Register here
                  </span>{" "}
                </h4>
              </MDBCol>
            )}
          </div>
        </div>
        {!selectedProduct && (
          <div className="px-5 d-flex flex-grow-1 flex-column mx-4">
            {/* <div className="mb-5">
              <MDBTypography className="fs-40 login-title mb-0">
                Express Registration
              </MDBTypography>
              <MDBTypography
                className="mt-4 pt-2 mb-2"
                // @ts-ignore
                variant="body-4"
              >
                If you have an account with us, please click below to gain site
                access.
              </MDBTypography>
              <MDBBtn
                className="m-0 mt-4 w-100"
                color="secondary"
                onClick={onExpressSetup}
              >
                Setup Account
              </MDBBtn>
            </div> */}
            <div className="mt-5 dark separator" />
            <div className="mt-5 pt-2">
              <MDBTypography className="fs-40 login-title mb-0">
                Register Here
              </MDBTypography>
              <MDBTypography
                className="mt-3"
                // @ts-ignore
                variant="body-4"
              >
                Create an account today and start exploring the new website.
              </MDBTypography>

              <ModalConsumer>
                {({ hideModal }) => {
                  return (
                    <Link to={customerPaths.children.register.path}>
                      <MDBBtn
                        className="m-0 mt-5 w-100"
                        color="secondary"
                        onClick={(/** @type {React.MouseEvent} */ e) => {
                          // @ts-ignore
                          hideModal(() => {});
                        }}
                      >
                        Register
                      </MDBBtn>
                    </Link>
                  );
                }}
              </ModalConsumer>
            </div>
          </div>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default EmailAndPassword;
