import clsx from "clsx";
import React from "react";

export interface BadgeProps
  extends Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    "children"
  > {
  badgeSize?: number;
  countDisplay: string;
}

const Badge: React.FC<BadgeProps> = ({
  children,
  badgeSize = 15,
  ...props
}) => {
  const halfSize = React.useMemo(() => badgeSize / 2, [badgeSize]);
  return (
    <div className="position-relative">
      {children}
      <div
        {...props}
        className={clsx(
          "position-absolute border-radius-50 bg-default text-white d-flex align-items-center justify-content-center",
          props.className
        )}
        style={React.useMemo(
          () => ({
            ...props.style,
            height: badgeSize,
            width: badgeSize,
            top: -halfSize,
            right: -halfSize,
          }),
          [props.style, halfSize, badgeSize]
        )}
      >
        {props.countDisplay}
      </div>
    </div>
  );
};

export default Badge;
