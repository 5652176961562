// @ts-check

import Icon from "app/assets/icon/icon";
import { createEnterHandler } from "app/utils/utils";
import {
  MDBBox,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React from "react";
import EmailAndPassword from "./components/EmailAndPassword";
import Express from "./components/Express";
import WelcomeBack from "./components/WelcomeBack";

/**
 * @typedef {Pick<import('./components/WelcomeBack/DataContainer').DataContainerProps, 'login'> & { setup: import('./models').Setup; isOpen: boolean; userData: { email: string, firstName: string}; setUserData: any; onClose: () => void; isLoggedIn: boolean; fallback?: React.ReactNode; }} LoginProps
 *
 * @param {LoginProps} props
 */
const Login = ({
  isLoggedIn,
  isOpen,
  onClose,
  fallback,
  setup,
  login,
  userData = { email: "", firstName: "" },
  setUserData = () => {},
}) => {
  return isLoggedIn ? (
    <>{fallback}</>
  ) : (
    <MDBModal
      inline={false}
      noClickableBodyWithoutBackdrop
      overflowScroll={false}
      isOpen={isOpen}
      toggle={onClose}
      backdropClassName="d-none"
      className="login-modal h-100 m-0 ml-auto"
      contentClassName="h-100"
    >
      <MDBModalBody className="p-0 d-flex flex-column">
        <MDBContainer className="p-0">
          <MDBRow
            style={{ position: "static" }}
            className="d-flex bg-default mx-0 p-4 justify-content-center align-items-center position-relative"
          >
            <div
              className="left-0 ml-4 pl-2 position-absolute"
              onClick={onClose}
              onKeyUp={createEnterHandler(onClose)}
              tabIndex={0}
              role="button"
            >
              <Icon icon="closeWhite" />
            </div>
            <MDBBox>
              <MDBTypography
                tag="h2"
                variant="display-2"
                className="text-white font-weight-light text-center"
              >
                Login/Register
              </MDBTypography>
            </MDBBox>
          </MDBRow>
        </MDBContainer>
        <MDBContainer className="bg-white">
          {setup.type === "password" ? (
            <WelcomeBack
              emailAndFirstname={setup?.payload || userData}
              login={login}
              onClose={onClose}
              setUserData={setUserData}
            />
          ) : setup.type === "express" ? (
            <Express onClose={onClose} />
          ) : (
            <EmailAndPassword onClose={onClose} setUserData={setUserData} />
          )}
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );
};

export default Login;
