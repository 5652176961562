import React from "react";
import { MDBRow, MDBCol, MDBLink } from "mdbreact";
import CmsBlock from "app/layout/cmsBlock";
import ProductsFabricsFinishesMenu from "./productFabricsFinishesMenu";
import CollectionsMenu from "./collectionsMenu";

const DropdownMenu = ({
  collectionsMenu,
  productsMenu,
  fabricsFinishesMenu,
  customOptionsMenu,
  subItems,
  setActiveTopMenu,
}) => {
  return (
    <MDBRow
      className={`dropdown-menu-wrapper bg-white position-absolute z-index-4 w-100 ${
        ((subItems.length === 0 || customOptionsMenu) && "hide") || "show"
      }`}
      onMouseLeave={() => {
        setActiveTopMenu(false);
      }}
    >
      <MDBCol>
        <MDBRow className={`dropdown-items-wrapper`}>
          {collectionsMenu && (
            <CollectionsMenu
              subItems={subItems}
              setActiveTopMenu={setActiveTopMenu}
            />
          )}
          {((productsMenu || fabricsFinishesMenu) && (
            <>
              {/* <ProductsFabricsFinishesMenu
                subItems={subItems}
                setActiveTopMenu={setActiveTopMenu}
              /> */}
              {/* {(productsMenu && (
                <MDBCol size="2" className={`col-with-cms-block`}>
                  <MDBLink
                    to={`/company-story`}
                    onClick={() => {
                      setActiveTopMenu(false);
                    }}
                  >
                    <CmsBlock id="header-craftsmanship-quality" />
                  </MDBLink>
                </MDBCol>
              )) ||
                (fabricsFinishesMenu && (
                  <MDBCol size="2" className={`col-with-cms-block`}>
                    <MDBLink
                      to={`/fabrics-finishes/fabrics/graded-in`}
                      onClick={() => {
                        setActiveTopMenu(false);
                      }}
                    >
                      <CmsBlock id="header-fabrics-finishes" />
                    </MDBLink>
                  </MDBCol>
                )) || <></>} */}
            </>
          )) || <></>}
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};
export default DropdownMenu;
