import * as yup from "yup";

export enum BuildNameLength {
  max = 100,
}

export interface BuildNameValues {
  name: string;
}
export const buildNameValues: BuildNameValues = {
  name: "",
};
export const buildNameSchema = yup
  .object()
  .required()
  .shape<BuildNameValues>({
    name: yup.string().required().max(BuildNameLength.max),
  });
