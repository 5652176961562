import { loader } from "graphql.macro";

/**
 *  uncomment necessary urlResolver based on your project requirements
 **/

export default (query, additionalMapping = []) => {
  // this is the app urlResolver
  if (query === "urlResolver") {
    return loader("../../graphql/queries/urlResolver.graphql");
  }
  // this is the core urlResolver
  // if (query === "urlResolver") {
  //   return loader("core/graphql/queries/urlResolver.graphql");
  // }

  throw new Error(
    "Unknown query, you probably forgot to define it in state/graphql/queryLoader.js."
  );
};
