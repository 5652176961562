import React from "react";
import ProductWidget from "app/layout/productWidget";
import { MDBRow } from "mdbreact";

function ProductsList({ data, loading, error, onClick }) {
  if (loading) return <></>;
  if (error) return <p>Something went wrong</p>;
  const {
    products: { items },
  } = data;
  return (
    <MDBRow>
      {items?.map((product, index) => {
        let price =
          product?.price_range?.minimum_price?.final_price?.value || undefined;
        return (
          <React.Fragment key={index + product.sku}>
            <ProductWidget
              showFavorites={false}
              colSize="12"
              lgSize="6"
              onClick={(e) => onClick(product)}
              url={"#"}
              key={index}
              price={price}
              {...product}
              className={`px-5 mb-4 ${index < 2 ? "pb-1" : "pb-0"}`.trim()}
              category={"2764"}
            />
          </React.Fragment>
        );
      })}
      ;
    </MDBRow>
  );
}

export default ProductsList;
