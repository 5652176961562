import React, { memo } from "react";
import Icon from "../../../../assets/icon/icon";

const IconContainer = memo(({ opened }) => {
  return (
    <>
      {opened ? (
        <Icon icon={"minusBlue"} className={"minus"} />
      ) : (
        <Icon icon={"plusBlue"} className={"plus"} />
      )}
    </>
  );
});

export default IconContainer;
