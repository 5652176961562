import React, { useCallback, useState } from "react";
import Icon from "app/assets/icon/icon";
import BackSection from "app/layout/BackSection";
import { Collapse } from "app/layout/Collapse";
import Label from "app/layout/Label";
import MaskedInput from "app/layout/MaskedInput";
import Select from "app/layout/Select";
import { phoneMask, usAndCanZipCodeMask } from "app/pages/customer/config";
import clsx from "clsx";
import { FormikProps } from "formik";
import {
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import DatePicker from "react-date-picker";
import CartSummary, { CartSummaryProps } from "../../components/CartSummary";
import CartSummaryModal from "../../components/CartSummaryModal";
import { cartPaths } from "../../config";
import { formatDate } from "../../utils";
import { CartValues, TimelineOption, timelineOptions } from "./config";
import { CountryOption, RegionOption } from "./models";
import { DomainCartFragment } from "../../models";
import sortCountries from "app/state/hooks/sortCountries";
import Checkbox from "app/layout/Checkbox";
import { decodePhone } from "app/pages/customer/account/utils";

type CartSummaryModalProps = React.ComponentProps<typeof CartSummaryModal>;

export interface SubmitProps
  extends Pick<CartSummaryProps, "quantity" | "money"> {
  form: FormikProps<CartValues>;
  countryOptions: CountryOption[];
  isSummaryOpen: CartSummaryModalProps["isOpen"];
  onToggleSummary: CartSummaryModalProps["toggle"];
  isCalendarOpen: boolean;
  onToggleCalendar: () => void;
  cartItems: DomainCartFragment;
  customerAddresses: any[];
  defaultShippingAddress: any;
  customerQueryAddresses: any[];
}

const Submit: React.FC<SubmitProps> = ({
  countryOptions,
  form,
  isSummaryOpen,
  onToggleSummary,
  isCalendarOpen,
  onToggleCalendar,
  cartItems,
  customerAddresses,
  defaultShippingAddress,
  customerQueryAddresses,
  ...quoteSummaryProps
}) => {
  const countryOption = React.useMemo(
    () =>
      countryOptions.find(
        (country) => country?.value === form.values.address.country?.value
      ),
    [countryOptions, form]
  );

  const [newAddress, setNewAddress] = useState(false);
  const [addressLabelCheckbox, setAddressLabelCheckbox] = useState(false);

  const [countriesSorted, setNewCountries] = React.useState(countryOptions);

  React.useEffect(() => setNewCountries(sortCountries(countryOptions)), [
    countryOptions,
  ]);

  const timelineOption = React.useMemo(
    () =>
      timelineOptions.find(
        ({ value }) => value === form.values.projectDetails.timeline
      ),
    [form.values.projectDetails.timeline]
  );

  const regionOption = React.useMemo(
    () =>
      countryOption?.regions?.find(
        (region) => region?.value === form.values.address.region_id
      ),
    [countryOption, form.values.address.region_id]
  );

  const handleContactIfno = useCallback(
    (addressId) => {
      if (addressId !== "add-new-address") {
        const address = customerQueryAddresses?.find(
          (address) => address.id === addressId
        );
        // contactinfo
        form.setFieldValue(
          "contactInformation.company",
          address?.company || ""
        );
        form.setFieldValue(
          "contactInformation.lastname",
          address?.lastname || ""
        );
        form.setFieldValue(
          "contactInformation.firstname",
          address?.firstname || ""
        );
        form.setFieldValue(
          "contactInformation.order_email",
          address?.email || ""
        );
        form.setFieldValue(
          "contactInformation.telephone",
          decodePhone(address?.telephone || "")
        );

        if (address?.region)
          form.setFieldValue("address.region_id", address?.region?.region_id);
        form.setFieldValue(
          "address.country",
          countryOptions?.find(
            (country) => country?.value === address?.country_code
          ) || "US"
        );

        form.setFieldValue("address.city", address?.city);
        form.setFieldValue("address.address_label", address?.address_label);
        form.setFieldValue("address.postcode", address?.postcode);
        form.setFieldValue("address.address1", address?.street?.[0]);
        form.setFieldValue("address.address2", address?.street?.[1] || "");
      } else {
        form.setFieldValue(
          "address.country",
          countryOptions?.find((country) => country?.value === "US")
        );
        form.setFieldValue("address.city", "");
        form.setFieldValue("address.address_label", "");
        form.setFieldValue("address.postcode", "");
        form.setFieldValue("address.address1", "");
        form.setFieldValue("address.address2", "");
      }
    },
    [form, customerQueryAddresses]
  );

  const regionEnabled = React.useMemo(() => !!countryOption?.regions, [
    countryOption,
  ]);

  const projectDueDate = React.useMemo(
    () => new Date(form.values.projectDetails.project_due_date),
    [form]
  );

  React.useEffect(
    () =>
      handleContactIfno(
        customerAddresses?.find(
          (address) => address.value === defaultShippingAddress?.id
        )?.value
      ),
    [customerAddresses, defaultShippingAddress]
  );

  return (
    <MDBContainer className="cart-submit">
      <BackSection to={cartPaths.path}>Back to Quote Request</BackSection>
      <MDBTypography tag="h1" variant="h1" className="mb-3 pb-2">
        Submit Quote Request
      </MDBTypography>
      <MDBRow>
        <MDBCol lg="8">
          <form onSubmit={form.handleSubmit}>
            <MDBRow>
              <Collapse isOpen title="Project Details">
                <MDBRow className="mt-3 mb-5 pb-5">
                  <MDBCol>
                    <MDBRow>
                      <MDBCol xl="5" className="mr-xl-5 mt-5">
                        <div className="position-relative">
                          <MDBInput
                            size="lg"
                            {...form.getFieldProps(
                              "projectDetails.project_name"
                            )}
                            label="Project Name"
                            hint="Project Name"
                            required
                            className={clsx({
                              error:
                                form.touched.projectDetails?.project_name &&
                                form.errors.projectDetails?.project_name,
                            })}
                          />
                          <div className="text-red position-absolute">
                            {form.touched.projectDetails?.project_name &&
                              form.errors.projectDetails?.project_name}
                          </div>
                        </div>
                      </MDBCol>
                      <MDBCol className="mt-5">
                        <div className="position-relative">
                          <MDBInput
                            size="lg"
                            value={formatDate(projectDueDate)}
                            label="Project Due Date"
                            hint="Select Due Date"
                            required
                            className={clsx({
                              error:
                                form.touched.projectDetails?.project_due_date &&
                                form.errors.projectDetails?.project_due_date,
                            })}
                          />
                          <div
                            className="cursor-pointer position-absolute calendar-icon"
                            style={{ top: 10, right: 65 }}
                          >
                            <Icon icon="calendar" onClick={onToggleCalendar} />
                          </div>
                          <div className="text-red position-absolute">
                            {form.touched.projectDetails?.project_due_date &&
                              form.errors.projectDetails?.project_due_date}
                          </div>
                          <DatePicker
                            isOpen={isCalendarOpen}
                            value={projectDueDate}
                            onChange={React.useCallback(
                              (dates: Date | Date[]) => {
                                form.setFieldValue(
                                  "projectDetails.project_due_date",
                                  dates instanceof Date
                                    ? dates.getTime()
                                    : dates[0]?.getTime()
                                );
                              },
                              [form]
                            )}
                          />
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <div className="position-relative mt-3">
                      <Label textClassName="mb-2" text="Timeline" required>
                        <Select
                          placeholder="Select estimated timeline"
                          value={timelineOption}
                          options={timelineOptions}
                          onChange={(option: TimelineOption) => {
                            form.setFieldValue(
                              "projectDetails.timeline",
                              option.value
                            );
                          }}
                          onBlur={() => {
                            form.setFieldTouched("projectDetails.timeline");
                          }}
                          className={clsx("lg", {
                            error:
                              form.touched.projectDetails?.timeline &&
                              form.errors.projectDetails?.timeline,
                          })}
                        />
                      </Label>
                      <div className="text-red position-absolute">
                        {form.touched.projectDetails?.timeline &&
                          form.errors.projectDetails?.timeline}
                      </div>
                    </div>
                    <MDBRow className="mt-5 pt-4">
                      <MDBCol xl="10">
                        <MDBInput
                          size="lg"
                          {...form.getFieldProps(
                            "projectDetails.additional_details"
                          )}
                          containerClass="w-100"
                          rows="4"
                          hint="Details"
                          type="textarea"
                          label="Additional Details"
                          required
                          className={clsx({
                            error:
                              form.touched.projectDetails?.additional_details &&
                              form.errors.projectDetails?.additional_details,
                          })}
                        />
                        <div className="text-red position-absolute">
                          {form.touched.projectDetails?.additional_details &&
                            form.errors.projectDetails?.additional_details}
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </Collapse>
            </MDBRow>
            <MDBRow className="mt-5 pt-3">
              <Collapse isOpen title="Contact Information">
                <MDBRow className="mt-3">
                  <MDBCol xl="5" className="mt-5 mr-xl-5">
                    <div className="position-relative">
                      <MDBInput
                        size="lg"
                        {...form.getFieldProps("contactInformation.firstname")}
                        label="First Name"
                        hint="John"
                        required
                        className={clsx({
                          error:
                            form.touched.contactInformation?.firstname &&
                            form.errors.contactInformation?.firstname,
                        })}
                      />
                      <div className="text-red position-absolute">
                        {form.touched.contactInformation?.firstname &&
                          form.errors.contactInformation?.firstname}
                      </div>
                    </div>
                    <div className="position-relative mt-5 pt-3">
                      <MDBInput
                        size="lg"
                        {...form.getFieldProps("contactInformation.lastname")}
                        label="Last Name"
                        hint="Doe"
                        required
                        className={clsx({
                          error:
                            form.touched.contactInformation?.lastname &&
                            form.errors.contactInformation?.lastname,
                        })}
                      />
                      <div className="text-red position-absolute">
                        {form.touched.contactInformation?.lastname &&
                          form.errors.contactInformation?.lastname}
                      </div>
                    </div>
                    <div className="position-relative mt-5 pt-3">
                      <MDBInput
                        size="lg"
                        {...form.getFieldProps("contactInformation.company")}
                        label="Company"
                        hint="Acme"
                        required
                        className={clsx({
                          error:
                            form.touched.contactInformation?.company &&
                            form.errors.contactInformation?.company,
                        })}
                      />
                      <div className="text-red position-absolute">
                        {form.touched.contactInformation?.company &&
                          form.errors.contactInformation?.company}
                      </div>
                    </div>
                  </MDBCol>
                  <MDBCol className="mt-5 mt-xl-2">
                    <div className="position-relative">
                      <MaskedInput
                        {...form.getFieldProps("contactInformation.telephone")}
                        label="Phone"
                        placeholder="xxx-xxx-xxxx"
                        required
                        mask={phoneMask}
                        className={clsx("lg", {
                          error:
                            form.touched.contactInformation?.telephone &&
                            form.errors.contactInformation?.telephone,
                        })}
                      />
                      <div className="mt-2 text-red position-absolute">
                        {form.touched.contactInformation?.telephone &&
                          form.errors.contactInformation?.telephone}
                      </div>
                    </div>
                    <div className="position-relative mt-5 pt-3">
                      <MDBInput
                        size="lg"
                        {...form.getFieldProps(
                          "contactInformation.order_email"
                        )}
                        label="Email"
                        hint="email@email.com"
                        required
                        className={clsx({
                          error:
                            form.touched.contactInformation?.order_email &&
                            form.errors.contactInformation?.order_email,
                        })}
                      />
                      <div className="text-red position-absolute">
                        {form.touched.contactInformation?.order_email &&
                          form.errors.contactInformation?.order_email}
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
              </Collapse>
            </MDBRow>
            <MDBRow className="mt-5 pt-3 mb-3 pb-lg-5">
              <Collapse isOpen title="Ship to Address" className="mb-5">
                <MDBRow className="mt-4">
                  <MDBCol>
                    <Label textClassName="mb-4" text="">
                      <Select
                        placeholder={
                          customerAddresses?.find(
                            (address) =>
                              address.value === defaultShippingAddress?.id
                          )?.label
                        }
                        options={customerAddresses}
                        onChange={(option: any) => {
                          handleContactIfno(option?.value);
                          if (option?.value === "add-new-address") {
                            setNewAddress(true);
                            form.setFieldValue(
                              "address.customer_address_id",
                              false
                            );
                          } else {
                            setNewAddress(false);
                            form.setFieldValue(
                              "address.customer_address_id",
                              option?.value
                            );
                          }
                        }}
                      />
                    </Label>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mt-3 pb-5 border-bottom border-gray">
                  {newAddress ? (
                    <>
                      <MDBCol xl="5" className="mt-4 mr-xl-5">
                        <div className="position-relative">
                          <Label textClassName="mb-2" required text="Country">
                            <Select
                              placeholder="Select country"
                              value={countryOption}
                              options={countriesSorted}
                              onChange={(option: CountryOption) => {
                                form.setFieldValue("address.country", option);
                              }}
                              onBlur={() => {
                                form.setFieldTouched("address.country");
                              }}
                              className={clsx("lg", {
                                error:
                                  form.touched.address?.country &&
                                  form.errors.address?.country,
                              })}
                            />
                          </Label>
                          <div className="text-red position-absolute">
                            {form.touched.address?.country &&
                              form.errors.address?.country}
                          </div>
                        </div>
                        <div className="position-relative mt-5 pt-3">
                          <MDBInput
                            size="lg"
                            {...form.getFieldProps("address.address1")}
                            label="Address 1"
                            hint="123 Street Name"
                            required={newAddress ? true : false}
                            className={clsx({
                              error:
                                form.touched.address?.address1 &&
                                form.errors.address?.address1,
                            })}
                          />
                          <div className="text-red position-absolute">
                            {form.touched.address?.address1 &&
                              form.errors.address?.address1}
                          </div>
                        </div>
                        <div className="position-relative mt-5 pt-3">
                          <MDBInput
                            size="lg"
                            {...form.getFieldProps("address.address2")}
                            label="Address 2"
                            hint="123 Street Name"
                            className={clsx({
                              error:
                                form.touched.address?.address2 &&
                                form.errors.address?.address2,
                            })}
                          />
                          <div className="text-red position-absolute">
                            {form.touched.address?.address2 &&
                              form.errors.address?.address2}
                          </div>
                        </div>
                      </MDBCol>
                      <MDBCol className="mt-5">
                        <div className="position-relative">
                          <MDBInput
                            size="lg"
                            {...form.getFieldProps("address.city")}
                            label="City"
                            hint="City"
                            required
                            className={clsx({
                              error:
                                form.touched.address?.city &&
                                form.errors.address?.city,
                            })}
                          />
                          <div className="text-red position-absolute">
                            {form.touched.address?.city &&
                              form.errors.address?.city}
                          </div>
                        </div>
                        <div className="position-relative mt-4 pt-3">
                          <Label
                            textClassName="mb-2"
                            required={regionEnabled}
                            text="State"
                            title={
                              regionEnabled
                                ? ""
                                : form.values?.address?.country
                                ? `${countryOption?.label} does not have state options.`
                                : "First select a country."
                            }
                          >
                            <Select
                              placeholder="Select state"
                              // value={regionOption}
                              options={countryOption?.regions}
                              onChange={(option: RegionOption) => {
                                form.setFieldValue(
                                  "address.region_id",
                                  option.value
                                );
                              }}
                              onBlur={() => {
                                form.setFieldTouched("address.region_id");
                              }}
                              isDisabled={!regionEnabled}
                              className={clsx("lg", {
                                error:
                                  form.touched.address?.region_id &&
                                  form.errors.address?.region_id,
                              })}
                            />
                          </Label>
                          <div className="text-red position-absolute">
                            {form.touched.address?.region_id &&
                              form.errors.address?.region_id}
                          </div>
                        </div>
                        <div className="position-relative mt-3 pt-2">
                          <MaskedInput
                            {...form.getFieldProps("address.postcode")}
                            required
                            label="Zip Code/Postal Code"
                            placeholder="Zip or postal code"
                            mask={
                              form.values?.address.country?.value === "US" ||
                              form.values?.address.country?.value === "CA"
                                ? usAndCanZipCodeMask(
                                    form.values?.address.country?.value
                                  )
                                : false
                            }
                            className={clsx("lg", {
                              error:
                                form.touched.address?.postcode &&
                                form.errors.address?.postcode,
                            })}
                          />
                          <div className="mt-2 text-red position-absolute">
                            {form.touched.address?.postcode &&
                              form.errors.address?.postcode}
                          </div>
                        </div>
                        <div className="position-relative mt-3 pt-2">
                          <Checkbox
                            label="Save this address to Address Book"
                            checked={addressLabelCheckbox}
                            onChange={(e) => {
                              setAddressLabelCheckbox(e.target.checked);
                              form.setFieldValue(
                                "address.save_in_address_book",
                                // @ts-ignore
                                e.target.checked
                              );
                            }}
                          />
                          {addressLabelCheckbox && (
                            <div className="position-relative mt-5 pt-2">
                              <MDBInput
                                size="lg"
                                {...form.getFieldProps("address.address_label")}
                                label="Address Title"
                                onChange={(e) =>
                                  form.setFieldValue(
                                    "address.address_label",
                                    // @ts-ignore
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      </MDBCol>
                    </>
                  ) : null}
                </MDBRow>
              </Collapse>
            </MDBRow>
          </form>
        </MDBCol>
        <MDBCol className={`mb-5`}>
          <CartSummary
            {...quoteSummaryProps}
            onSubmit={() => {
              !regionEnabled && form.setFieldValue("address.region_id", "");
              form.submitForm();
            }}
            disabled={form.isSubmitting}
            onOpenSummary={onToggleSummary}
            //@ts-ignore
            cartItems={cartItems}
          />
        </MDBCol>
      </MDBRow>
      <CartSummaryModal isOpen={isSummaryOpen} toggle={onToggleSummary} />
    </MDBContainer>
  );
};

export default Submit;
