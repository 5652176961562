import React from "react";
import { useWishlist } from "core/state/hooks/wishlistHooks/wishlistHookAddRemove";
import Item from "../itemsWishlist/item";
import { Redirect } from "react-router-dom";

const WishlistPageListing = (props) => {
  const [, , , getWishlistItems, , , , , , ,] = useWishlist();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let wishlistUrl = urlParams.get("wishlist");
  let myWishlistsArr = getWishlistItems();
  let selectedWishlist = myWishlistsArr.filter(function (wishlist) {
    return wishlist.id === wishlistUrl;
  });
  return (
    <div>
      {typeof selectedWishlist[0] !== "undefined" &&
      typeof selectedWishlist[0].items !== "undefined" ? (
        selectedWishlist[0].items.length > 0 &&
        selectedWishlist[0].items.map((item) => {
          return (
            <Item
              item={item}
              activeWishlistName={selectedWishlist[0].name}
              allWishlists={myWishlistsArr}
            />
          );
        })
      ) : (
        <Redirect to={"/customer/wishlist"} />
      )}
    </div>
  );
};
export default WishlistPageListing;
