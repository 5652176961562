import React from "react";
import { MDBBtn, MDBTypography } from "mdbreact";
import Icon from "app/assets/icon/icon";
import { useBoolean } from "ahooks";
import CreateListModal from "./CreateListModal";
import { useMediaQuery } from "react-responsive";
export interface CollectionHeaderProps {
  name: string;
  count: number;
  editButton: React.ReactNode;
}

const CollectionHeader: React.FC<CollectionHeaderProps> = (props) => {
  const [isOpen, setIsOpen] = useBoolean();
  const isMobile = useMediaQuery({ maxWidth: 480 });

  return (
    <>
      <div
        className={`grow d-flex  align-items-center justify-content-between`}
      >
        <div
          className={`d-flex align-items-baseline ${
            props.editButton && "justify-content-between w-100"
          }`}
        >
          {props.editButton ? (
            <div className="d-flex align-items-baseline">
              <MDBTypography
                className={`${isMobile ? "mr-2" : "mr-4"} mb-0`}
                tag="h1"
                variant="h1"
              >
                {props.name}
              </MDBTypography>
              {props?.name !== "My Favorites" && props.editButton}
            </div>
          ) : (
            <>
              <MDBTypography
                className={`${isMobile ? "mr-2" : "mr-4"} mb-0`}
                tag="h1"
                variant="h1"
              >
                {props.name}
              </MDBTypography>
              {props?.name !== "My Favorites" && props.editButton}
            </>
          )}
          <MDBTypography
            // @ts-ignore
            variant="body-2"
            className={`d-flex align-items-center`}
          >
            {props.count > 1
              ? `${props.count} ${
                  (props.name === "Your Projects" && "Project(s)") || "Item(s)"
                }`
              : `${props.count} ${
                  (props.name === "Your Projects" && "Project") || "Item"
                }`}
          </MDBTypography>
        </div>
        {props.editButton ? null : (
          <MDBBtn
            className="icon icon-left create-newlist-btn"
            onClick={setIsOpen.setTrue}
          >
            <Icon icon={`plusCircleWhite`} className="create-list-icon" />{" "}
            Create New List
          </MDBBtn>
        )}
      </div>
      <CreateListModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default CollectionHeader;
