import { gql } from "graphql.macro";

export const WHATS_NEW = gql`
  query Whats_New($id: String!) {
    categoryList(filters: { ids: { eq: $id } }) {
      products {
        items {
          name
          url_key
          sku
          url_key
          is_new
          image {
            url(width: 700, height: 700)
            label
          }
          thumbnail {
            url(width: 150, height: 150)
            label
          }
          price_range {
            minimum_price {
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
            maximum_price {
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
          }
        }
      }
    }
  }
`;
