import { determineCurrency } from "config/currency";
import { isUndefined } from "core/helpers/functions";
import React from "react";

const Price = ({
  currency = "USD",
  price = 0,
  type = "regular",
  onlyValues = false,
  style = {},
}) => {
  let symbol = determineCurrency(currency);
  if (type === "fixed") {
    price = number_format(price, 2);
  }
  if (type === "regular") {
    price = number_format(price);
  }
  if (onlyValues) {
    return <>{symbol + price}</>;
  }
  return (
    <>
      <span className="price" style={style}>
        <span className="price-symbol">{symbol}</span>
        {price}
      </span>
    </>
  );
};

export const getOnlyPrice = (props) => {
  let { currency, price, type } = props;
  if (isUndefined(currency)) {
    currency = "USD";
  }
  if (isUndefined(type)) {
    type = "regular";
  }
  let symbol = determineCurrency(currency);
  if (type === "fixed") {
    price = number_format(price, 2);
  }
  if (type === "regular") {
    price = number_format(price);
  }
  return <>{symbol + price}</>;
};

export function number_format(number, decimals, dec_point, thousands_sep) {
  // Strip all characters but numerical ones.
  number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    s = "",
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return "" + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
}

export default Price;
