import React from "react";
import ReactDOM from "react-dom";
import { hot } from "react-hot-loader";
import App from "./app/app";
import "./polyfills";
import * as serviceWorker from "./serviceWorker";
import swConfig from "swConfig";

const Root = hot(module)(App);

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.register(swConfig);
