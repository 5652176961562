import {
  default as guestPages,
  DefaultPage as AppDefaultPage,
  ErrorPage as AppErrorPage
} from "app/config/routing/pages/guestPages";

export default {
  ...guestPages
};

export const ErrorPage = AppErrorPage;

export const DefaultPage = AppDefaultPage;
