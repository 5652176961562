import React, { useState, useCallback } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  // MDBBtn,
  // MDBInput,
  MDBBox,
} from "mdbreact";
// import Select from "app/layout/Select";
// import Label from "app/layout/Label";
// import Switch from "app/layout/Switch";
import CmsBlock from "app/layout/cmsBlock";
import { validate, shouldMarkError } from "./validation";
import InputField from "core/components/inputField";
import { useContactUs } from "app/state/hooks/contactHook/useContactHook";
import Button from "core/components/button";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router";

const ContactForm = (props) => {
  // const options = [
  //   { label: "Designer", value: "a" },
  //   { label: "Retail", value: "b" },
  // ];

  const contactHook = useContactUs();
  const history = useHistory();

  // const inquiry = [
  //   { label: "Lorem Ipsum", value: "a" },
  //   { label: "Retail", value: "b" },
  // ];

  // const [option, setOption] = useState(options[0]);
  // const [typeInquiry, setTypeInquiry] = useState(options[0]);
  // const [switched, setSwitched] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const [zip, setZip] = useState("");
  const [subject, setSubject] = useState("");
  const [selectOption, setSelectOption] = useState("");
  const [selectInquiry, setSelectInquiry] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(null);

  const zipValidation = useCallback(() => {
    if (zip.length > 0 && zip.length < 5) return true;
    return false;
  }, [zip]);

  const setFirstNameFn = (e) => {
    setFirstName(e.target.value);
  };
  const setLastNameFn = (e) => {
    setLastName(e.target.value);
  };
  const setEmailFn = (e) => {
    setEmail(e.target.value);
  };
  const setMessageFn = (e) => {
    setMessage(e.target.value);
  };
  const setCompanyFn = (e) => {
    setCompany(e.target.value);
  };
  const setZipCodeFn = (e) => {
    if (e.target.value.length > 7) return;
    setZip(e.target.value);
  };

  const setSubjectFn = (e) => {
    setSubject(e.target.value);
  };

  let errors = validate(
    firstName,
    lastName,
    email,
    company,
    message,
    zip,
    subject
  );
  const [loading, setLoading] = useState(false);

  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    email: false,
    company: false,
    message: false,
    zip: false,
    subject: false,
    selectOption: false,
    selectInquiry: false,
  });

  const _handleSendEmail = (e) => {
    let isTrue = true;
    setTouched({
      firstName: true,
      lastName: true,
      email: true,
      company: true,
      message: true,
      zip: true,
      subject: true,
      selectOption: true,
      selectInquiry: true,
    });

    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
      return item;
    });
    if (!isCaptchaValid) return;
    if (isTrue) {
      setIsCaptchaValid(null);
      setLoading(true);
      props.lock({
        always: () => {
          setLoading(true);
        },
        success: () => {
          setLoading(false);
          setFirstName("");
          setLastName("");
          setEmail("");
          setMessage("");
          setCompany("");
          setZip("");
          setSubject("");
          setSelectInquiry("");
          setSelectOption("");
          setTouched({
            firstName: false,
            lastName: false,
            email: false,
            message: false,
            company: false,
            zip: false,
            subject: false,
            selectOption: false,
            selectInquiry: true,
          });
          history.push("/contact-thank-you");
        },
        fail: () => {
          setLoading(false);
        },
      });
      // props.sendEmail({
      //   input: {
      //     attributes: [
      //       { code: "email", value: email },
      //       { code: "firstname", value: firstName },
      //       { code: "lastname", value: lastName },
      //       { code: "company", value: company },
      //       { code: "message", value: message },
      //       { code: "zip", value: zip },
      //       { code: "subject", value: subject },
      //     ],
      //     return_form: false,
      //     template: "17",
      //   },
      // });
      contactHook.contact(
        company,
        email,
        selectOption.label,
        "Yes",
        firstName,
        lastName,
        message,
        subject,
        selectInquiry.label,
        zip
      );
    } else {
      props.addMessage("Please fill all required fields.");
    }
  };

  function onChange(value) {
    setIsCaptchaValid(true);
  }

  return (
    <MDBContainer className="p-lg-0 contact-us-form">
      <MDBRow>
        <CmsBlock id="contact_form_img" />
        <MDBCol className="my-4 mt-4" size="12" lg="7">
          <MDBContainer className="">
            <MDBRow className="mb-5 first-column-inputs">
              <MDBCol className="my-4" size="12" lg="6">
                <InputField
                  className={`custom-input ${
                    shouldMarkError("company", errors, touched)
                      ? "validation-error"
                      : ""
                  }`}
                  label={`Company Name`}
                  group
                  type="text"
                  // validate
                  error="wrong"
                  success="right"
                  value={company !== undefined ? company : ""}
                  onChange={setCompanyFn}
                  hint="Company Name"
                />
                {/* {shouldMarkError("company", errors, touched) ? (
                  <div className="text-red position-absolute">
                    Company Name is required.
                  </div>
                ) : (
                  <></>
                )} */}
              </MDBCol>
              <MDBCol className="my-4" size="12" lg="6">
                <InputField
                  className={`custom-input ${
                    shouldMarkError("email", errors, touched)
                      ? "validation-error"
                      : ""
                  }`}
                  label={`Email*`}
                  group
                  type="email"
                  validate
                  error="wrong"
                  success="right"
                  value={email !== undefined ? email : ""}
                  onChange={setEmailFn}
                  hint="email@email.com"
                  onBlur={(e) => {
                    setTouched({ ...touched, email: true });
                  }}
                />
                {shouldMarkError("email", errors, touched) ? (
                  email.length === 0 ? (
                    <div className="text-red position-absolute">
                      Email is required.
                    </div>
                  ) : (
                    <div className="text-red position-absolute">
                      Email is invalid.
                    </div>
                  )
                ) : (
                  <></>
                )}
              </MDBCol>
              <MDBCol className="my-4" size="12" lg="6">
                <InputField
                  className={`custom-input ${
                    shouldMarkError("firstName", errors, touched)
                      ? "validation-error"
                      : ""
                  }`}
                  label={`First Name*`}
                  group
                  type="text"
                  validate
                  error="wrong"
                  success="right"
                  value={firstName !== undefined ? firstName : ""}
                  onChange={setFirstNameFn}
                  hint="First Name"
                />
                {shouldMarkError("firstName", errors, touched) ? (
                  <div className="text-red position-absolute">
                    First Name is required.
                  </div>
                ) : (
                  <></>
                )}
              </MDBCol>

              <MDBCol className="my-4" size="12" lg="6">
                <InputField
                  className={`custom-input ${
                    shouldMarkError("zip", errors, touched) || zipValidation()
                      ? "validation-error"
                      : ""
                  }`}
                  label={`Zip Code/Postal Code*`}
                  group
                  type="text"
                  validate
                  error="wrong"
                  success="right"
                  value={zip !== undefined ? zip : ""}
                  onChange={setZipCodeFn}
                  hint="Zip code/Postal Code"
                />
                {shouldMarkError("zip", errors, touched) ? (
                  <div className="text-red position-absolute">
                    Zip Code/Postal Code is required.
                  </div>
                ) : (
                  <></>
                )}
                {zipValidation() && (
                  <div className="text-red">
                    Zip Code/Postal Code must have at least 5 characters.
                  </div>
                )}
              </MDBCol>
              <MDBCol className="my-4" size="12" lg="6">
                <InputField
                  className={`custom-input ${
                    shouldMarkError("lastName", errors, touched)
                      ? "validation-error"
                      : ""
                  }`}
                  label={`Last Name*`}
                  group
                  type="text"
                  validate
                  error="wrong"
                  success="right"
                  value={lastName !== undefined ? lastName : ""}
                  onChange={setLastNameFn}
                  hint="Last Name"
                />
                {shouldMarkError("lastName", errors, touched) ? (
                  <div className="text-red position-absolute">
                    Last Name is required.
                  </div>
                ) : (
                  <></>
                )}
              </MDBCol>
              {/* <MDBCol size="12" lg="6" className=" my-4 position-relative">
                <Label className="custom-label" text="Customer Type" />
                <Select
                  placeholder="Select option..."
                  options={options}
                  value={selectOption}
                  onChange={(e) => setSelectOption(e)}
                  wrapperClass="my-4"
                />
              </MDBCol>
              <MDBCol className="my-4" size="12">
                <Switch
                  label="I am an existing customer"
                  checked={switched}
                  onChange={(e) => {
                    setSwitched(e.target.checked);
                  }}
                />
              </MDBCol> */}
            </MDBRow>
            {/* NEED TO CHECK TYPE OF INQUIRY */}
            <MDBRow className="mt-5 pt-5">
              {/* <MDBCol size="12" lg="6" className="my-4 position-relative">
                <Label
                  className="custom-label"
                  required
                  text="Type of Inquiry"
                />
                <Select
                  placeholder="Select option..."
                  options={inquiry}
                  value={selectInquiry}
                  onChange={(e) => setSelectInquiry(e)}
                  wrapperClass="my-4"
                />
              </MDBCol> */}
              <MDBCol className="my-4" size="12" lg="12">
                <InputField
                  className={`custom-input ${
                    shouldMarkError("subject", errors, touched)
                      ? "validation-error"
                      : ""
                  }`}
                  label={`Subject*`}
                  group
                  type="text"
                  validate
                  error="wrong"
                  success="right"
                  wrapperClass={`subject-input-wrapper`}
                  value={subject !== undefined ? subject : ""}
                  onChange={setSubjectFn}
                  hint="Subject"
                />
                {shouldMarkError("subject", errors, touched) ? (
                  <div className="text-red position-absolute">
                    Subject is required.
                  </div>
                ) : (
                  <></>
                )}
              </MDBCol>
              <MDBCol className="my-4 message-form-field" size="12">
                <InputField
                  className={`custom-input ${
                    shouldMarkError("message", errors, touched)
                      ? "validation-error"
                      : ""
                  }`}
                  type="textarea"
                  id="exampleFormControlTextarea1"
                  validate
                  error="wrong"
                  label="Message*"
                  success="right"
                  value={message !== undefined ? message : ""}
                  onChange={setMessageFn}
                  hint="Message"
                />
                {shouldMarkError("message", errors, touched) ? (
                  <div className="text-red">Message is required.</div>
                ) : (
                  <></>
                )}
              </MDBCol>
              <MDBCol className="my-4" size="12" lg="12">
                <ReCAPTCHA
                  sitekey="6LcxhDscAAAAAEWKXQkp56niF5Q61yn8_gWOV0o4"
                  onChange={onChange}
                  className={`mb-4`}
                />
                {isCaptchaValid === false && (
                  <div className="text-red position-absolute">
                    Captcha is required.
                  </div>
                )}
              </MDBCol>
              <MDBCol className="my-4" size="12" lg="4">
                <Button
                  leftIcon={true}
                  direction={"mr-2"}
                  disabled={loading}
                  className="w-100"
                  type="submit"
                  onClick={() => {
                    if (isCaptchaValid === null) {
                      setIsCaptchaValid(false);
                    }
                    if (zipValidation()) return;
                    _handleSendEmail();
                  }}
                  text={
                    loading ? (
                      <MDBBox tag="span">Submitting...</MDBBox>
                    ) : (
                      <MDBBox tag="span">Submit</MDBBox>
                    )
                  }
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ContactForm;
