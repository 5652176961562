// import desktopLogoDark from "app/assets/logo/desktopLogo.png";
// import desktopLogoLight from "app/assets/logo/desktopLogoLight.png";
// import mobileLogoDark from "app/assets/logo/mobileLogo.png";
// import mobileLogoLight from "app/assets/logo/mobile_footer_logo.png";
// import mobileLogoLight from "app/assets/logo/mobile-light-logo.png";
// import mobileLogoDark from "app/assets/logo/mobile-dark-logo.png";
import newLogoDark from "app/assets/logo/Fairfield-Logo-NEW_black.png";
import newLogoLight from "app/assets/logo/Fairfield-Logo-NEW_white.png";
import newMobileLogoLight from "app/assets/logo/mobile-light-logo.png";
import newMobileLogoDark from "app/assets/logo/mobile-dark-logo.png";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
const Logo = ({
  light = false,
  logoType,
  setLogoType,
  style,
  url,
  setActiveMenu,
}) => {
  let isMobile = useMediaQuery({ maxWidth: 992 });
  // const resizeLogo = useCallback(() => {
  //   let clientWidth = document.body.clientWidth;
  //   if (clientWidth <= 992) {
  //     if (logoType !== "mobile") {
  //       setLogoType("mobile");
  //     }
  //   } else {
  //     if (logoType !== "desktop") {
  //       setLogoType("desktop");
  //     }
  //   }
  // }, [logoType, setLogoType]);

  // useEffect(() => {
  //   window.addEventListener("resize", resizeLogo);
  //   return () => {
  //     window.removeEventListener("resize", resizeLogo);
  //   };
  // }, [resizeLogo]);

  return (
    <>
      {!isMobile ? (
        <Link to={url ? url : "/"}>
          <img
            className="logo img-fluid"
            {...style}
            src={`${
              light && logoType === "desktop" ? newLogoLight : newLogoDark
            }`}
            alt="Logo"
            onClick={() => setActiveMenu("")}
          />
        </Link>
      ) : (
        <Link to={url ? url : "/"}>
          <img
            className="logo img-fluid"
            {...style}
            src={`${
              light && logoType !== "mobile"
                ? newMobileLogoLight
                : newMobileLogoDark
            }`}
            alt="Logo"
          />
        </Link>
      )}
    </>
  );
};

export default Logo;
