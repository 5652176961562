import { selectLatestLoginMessageText } from "app/state/redux/data/common/selectors";
import { customerActions } from "app/state/redux/data/customer";
import { FormikConfig, useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  initialPasswordValues,
  passwordValidationSchema,
  PasswordValues,
} from "./config";
import WelcomeBack, { WelcomeBackProps } from "./WelcomeBack";
import messagesActions from "core/state/redux/data/messages/actions";
import actions from "core/state/redux/data/messages/actions";
export interface DataContainerProps
  extends Pick<WelcomeBackProps, "emailAndFirstname"> {
  login: () => void;
  onClose: () => void;
  setUserData: (data: { email: string; firstName: string }) => void;
}

const DataContainer: React.FC<DataContainerProps> = (props) => {
  const [isLoginClicked, setIsLoginClicked] = React.useState(false);
  const dispatch = useDispatch();
  let loginMessage = useSelector(selectLatestLoginMessageText);
  const [isSubmited, setIsSubmited] = React.useState(false);

  const passwordFormConfig: FormikConfig<PasswordValues> = React.useMemo(
    () => ({
      initialValues: initialPasswordValues,
      validationSchema: passwordValidationSchema,
      onSubmit: ({ password }, { setSubmitting }) => {
        setIsLoginClicked(false);
        setFieldError("password", "");
        setIsSubmited(false);
        dispatch(actions.removeMessage(loginMessage, "danger"));
        // @ts-ignore
        if (props.emailAndFirstname?.email) {
          new Promise((resolve, reject) =>
            resolve(
              dispatch(
                customerActions.loginCustomer(
                  // @ts-ignore
                  props.emailAndFirstname?.email || "",
                  password
                )
              )
            )
          ).then((data) => {
            setIsSubmited(true);
            setIsLoginClicked(true);
            // props?.setUserData({ email: "", firstName: "" });
          });
        }
        setSubmitting(false);
      },
    }),
    [props, dispatch]
  );
  const form = useFormik(passwordFormConfig);
  const { setFieldError, setSubmitting } = form;

  React.useEffect(() => {
    if (loginMessage)
      setFieldError(
        "password",
        loginMessage ||
          'This password is incorrect. Please click "Forgot Password?" bellow to reset your password.'
      );
  }, [setFieldError, loginMessage, isSubmited]);

  return (
    <WelcomeBack
      {...props}
      form={form}
      isLoginClicked={isLoginClicked}
      // @ts-ignore
      setIsLoginClicked={setIsLoginClicked}
    />
  );
};

export default DataContainer;
