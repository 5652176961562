import Icon from "app/assets/icon/icon";
import { MDBBtn } from "mdbreact";
import { useReactToPrint } from "react-to-print";
import { useCustomerCartQuery } from "app/generated/graphql";
import React from "react";
import { ItemProps } from "../models";
import Print, { PrintProps } from "./Print";

export interface DownloadPDFProps
  extends Omit<React.ComponentProps<typeof MDBBtn>, "color"> {
  items: PrintProps["itemsWithPrintableProducts"];
  comment: ItemProps["comment"];
}

const DownloadPDF: React.FC<DownloadPDFProps> = (props) => {
  const cartQuery = useCustomerCartQuery({ fetchPolicy: "network-only" });
  const printRef = React.useRef<Print>(null);
  const handleSave = useReactToPrint({ content: () => printRef.current });
  return (
    <>
      <MDBBtn
        color="secondary"
        onClick={handleSave}
        className="pdf_button icon-left icon m-0"
        {...props}
      >
        <Icon
          style={{
            display: "flex",
            alignSelf: "center",
            width: "20px",
            height: "20px",
          }}
          icon="downloadGray"
        />
        Download PDF
      </MDBBtn>
      <div className="d-none">
        <Print
          comment={props.comment}
          ref={printRef}
          itemsWithPrintableProducts={props.items}
          quantityPerPage={1}
          cartQuery={cartQuery}
          showOptionsWithCollapse={false}
          classNameNotes={"notes-pdf"}
        />
      </div>
    </>
  );
};

export default DownloadPDF;
