import React, { useState, useEffect } from "react";
import Checkbox from "app/layout/Checkbox";
import { addFilter, removeFilter, isOptionActive } from "../../functions";
import { useLocation } from "react-router-dom";

const FilterOptionItem = ({
  option,
  activeFilters,
  setActiveFilters,
  filterCode,
  filterType,
  setCurrentPage,
  isGradeFilter,
  selectedGrades,
}) => {
  const [optionChecked, setOptionChecked] = useState(false);
  const location = useLocation();
  const handleChange = (e) => {
    if (location.pathname.includes("graded-in")) {
      let gradesCopy = [...selectedGrades];
      let labelValue = e.target.labels?.[0].children?.[2].innerHTML;
      if (optionChecked) {
        setOptionChecked(false);
        gradesCopy = gradesCopy.filter((grade) => grade !== labelValue);
        setActiveFilters(gradesCopy);
      } else {
        setOptionChecked(true);
        let grades = [...selectedGrades];
        if (grades.length === 0) {
          grades = [labelValue];
          setActiveFilters(grades);
        } else {
          if (!gradesCopy.includes(labelValue)) {
            gradesCopy.push(labelValue);
            setActiveFilters(gradesCopy);
          }
        }
      }
    } else {
      setCurrentPage.setCurrentPage(1);
      if (optionChecked) {
        setOptionChecked(false);
        removeFilter(
          option,
          activeFilters,
          setActiveFilters,
          filterCode,
          filterType
        );
      } else {
        setOptionChecked(true);
        addFilter(
          option,
          activeFilters,
          setActiveFilters,
          filterCode,
          filterType
        );
      }
    }
  };
  useEffect(() => {
    if (!location.pathname.includes("graded-in")) {
      if (isOptionActive(option, activeFilters, filterCode))
        setOptionChecked(true);
      else setOptionChecked(false);
    }
  }, [activeFilters, filterCode, filterType, option]);

  let isPatternFilter = filterCode.includes("ffifabrics_category1");
  let optionTitle = location.pathname.includes("graded-in")
    ? option.label
    : option.label.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
  return (
    <div>
      <Checkbox
        checked={optionChecked}
        onChange={(e) => handleChange(e)}
        label={
          location.pathname.includes("graded-in") ? (
            option.label
          ) : (
            <p className="multi-select-filter-checkbox-label">
              {/* {isGradeFilter && "Grade "} */}
              {isPatternFilter ? optionTitle.split(",")[0] : option.label}
            </p>
          )
        }
      />
    </div>
  );
};

export default FilterOptionItem;
