import React, { useState, memo } from "react";
import OrderNotes from "./orderNotes";
import { isEqual } from "lodash";

const OrderNotesStateContainer = memo(
  ({ cart_id, order_comment, addOrderNotesToCart, lock }) => {
    const [orderNotes, setOrderNotes] = useState(order_comment);
    const [loading, setLoading] = useState(false);

    const submitOrderNotesChange = (newOrderNotes) => {
      setLoading(true);
      lock({
        always: () => {},
        success: () => {
          setLoading(false);
        },
        fail: () => {
          setLoading(false);
        },
      });
      addOrderNotesToCart({ cart_id: cart_id, order_comment: newOrderNotes });
    };
    return (
      <OrderNotes
        submit={submitOrderNotesChange}
        orderNotes={orderNotes}
        setOrderNotes={setOrderNotes}
        originalOrderNotes={order_comment}
        loading={loading}
      />
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default OrderNotesStateContainer;
