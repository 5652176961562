import gql from "graphql-tag";

export const PRODUCTS = gql`
  query Products(
    $search: String
    $filter: ProductAttributeFilterInput
    $pageSize: Int
    $currentPage: Int
    $sort: ProductAttributeSortInput
  ) {
    products(
      search: $search
      filter: $filter
      sort: $sort
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      aggregations {
        attribute_code
        count
        label
        options {
          count
          label
          value
        }
      }
      items {
        canonical_url
        color
        description {
          html
        }
        ffi_lanect
        ffi_laners
        ffi_lanesl
        ffi_isvisible
        ffifabrics_grade
        ffi_collection
        ffi_name
        short_description {
          html
        }
        ffi_extdesc2
        in_stock_filter
        ... on CustomizableProductInterface {
          options {
            title
            category_options
            option_id
            ... on CustomizableDropDownOption {
              value {
                title
                price
                option_type_id
              }
            }
            ... on CustomizableCheckboxOption {
              value {
                title
                price
                option_type_id
              }
            }
            ... on CustomizableFieldOption {
              field_value: value {
                price
              }
            }
          }
        }

        default_fabric: ffi_dftfabric
        uid
        image {
          url(width: 700, height: 700)
          label
        }
        small_image {
          url(width: 265, height: 265)
          label
          disabled
        }
        url_key
        thumbnail {
          label
          url(width: 150, height: 150)
        }
        stock_status
        sku
        name
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      sort_fields {
        default
        options {
          label
          value
        }
      }
      total_count
    }
  }
`;
export const CUSTOMER_PRODUCTS = gql`
  query Products(
    $search: String
    $filter: ProductAttributeFilterInput
    $pageSize: Int
    $currentPage: Int
    $sort: ProductAttributeSortInput
  ) {
    products(
      search: $search
      filter: $filter
      pageSize: $pageSize
      currentPage: $currentPage
      sort: $sort
    ) {
      aggregations {
        attribute_code
        count
        label
        options {
          count
          label
          value
        }
      }
      items {
        canonical_url
        color
        description {
          html
        }
        ffi_lanect
        ffi_laners
        ffi_lanesl
        ffi_isvisible
        ffifabrics_grade
        ffi_collection
        ffi_name
        short_description {
          html
        }
        ffi_extdesc2
        in_stock_filter
        uid
        image {
          url(width: 700, height: 700)
          label
        }
        small_image {
          url(width: 265, height: 265)
          label
          disabled
        }
        url_key
        thumbnail {
          label
          url(width: 150, height: 150)
        }
        stock_status
        sku
        name
        price_range {
          minimum_price {
            final_price {
              value
              currency
            }
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      sort_fields {
        default
        options {
          label
          value
        }
      }
      total_count
    }
  }
`;
