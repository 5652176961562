import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";

const SkeletonResults = () => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <>
      {!isMobile ? (
        <MDBContainer>
          <MDBRow>
            <MDBCol size="6">
              <Skeleton
                duration={2}
                width={`23.5rem`}
                count={`2`}
                height={`33.2rem`}
              />
            </MDBCol>
            <MDBCol size="6">
              <Skeleton
                duration={2}
                width={`23.5rem`}
                count={`2`}
                height={`33.2rem`}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      ) : (
        <MDBContainer>
          <MDBRow>
            <MDBCol size="12">
              <Skeleton
                duration={2}
                width={`100%`}
                count={`1`}
                height={`33.2rem`}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      )}
    </>
  );
};

export default SkeletonResults;
