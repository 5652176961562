import Switch from "app/layout/Switch";
import clsx from "clsx";
import React, { useEffect, useState, useCallback } from "react";
import { IFilter } from "../../utils/utils";
import Multiselect from "multiselect-react-dropdown";
import Triangle from "app/assets/icon/triangle.svg";
import { useMediaQuery } from "react-responsive";
import { useLocation, useHistory } from "react-router-dom";
import { MDBBtn } from "mdbreact";
export interface SwitchFiltersProps {
  hasRetail: boolean;
  hasSenior: boolean;
  hasContract: boolean;
  vertical: boolean;
  filter: IFilter;
  setLaneFilter: (lane: IFilter["lane"]) => void;
  clearLaneFilter: () => void;
  clearFilter: () => void;
  onSaveSelection: () => void;
  className?: string;
  listingProps: any;
  industryFilter: {
    attribute_code: string;
    count: number;
    label: string;
    options: any[];
  };
  selectedValues: { label: string; value: string }[];
  setSelectedValues: (array: { label: string; value: string }[]) => void;
}

const SwitchFilters: React.FC<SwitchFiltersProps> = (props) => {
  const { selectedValues, setSelectedValues } = props;
  const history = useHistory();
  const [initLoad, setInitLoad] = useState(false);
  let isMobile = useMediaQuery({ maxWidth: 992 });
  const [options, setOptions] = useState<any>([]);

  let optionsArr = [
    { value: "", label: "All" },
    { oldValue: "ffi_laners", label: "Residential", value: "7438" },
    { oldValue: "ffi_lanesl", label: "Senior Living", value: "7439" },
    {
      oldValue: "ffi_lanect",
      label: "Commercial",
      value: "7440",
    },
  ];

  useEffect(() => {
    if (
      selectedValues.length === 3 &&
      // @ts-ignore
      document.querySelector(".placeholder")?.style
    )
      // @ts-ignore
      document.querySelector(".placeholder").style.display = "none";
    if (
      (!selectedValues.length ||
        (selectedValues?.length === 1 &&
          selectedValues?.[0]?.label === "All")) &&
      // @ts-ignore
      document.querySelector(".placeholder")?.style
    )
      // @ts-ignore
      document.querySelector(".placeholder").style.display = "block";
  }, [selectedValues]);

  const filterOptions = (
    options: any[],
    retail: boolean,
    senior: boolean,
    contract: boolean
  ) => {
    return options?.filter((opt: { label: string; value: string }) => {
      if (retail) {
        return opt.value !== "7438" || opt.value !== undefined;
      } else if (senior) {
        return opt.value !== "7439" || opt.value !== undefined;
      } else if (contract) {
        return opt.value !== "7440" || opt.value !== undefined;
      } else {
        return opt;
      }
    });
  };

  useEffect(() => {
    let retail = checkFilterExits("ffi_laners");
    let senior = checkFilterExits("ffi_lanesl");
    let contract = checkFilterExits("ffi_lanect");
    let industryFilterCopy = { ...props.industryFilter };

    let options = filterOptions(
      industryFilterCopy?.options,
      retail,
      senior,
      contract
    );
    if (options) options.unshift({ value: "", label: "All" });
    setOptions(options);
  }, [props.industryFilter]);

  const checkFilterExits = (type: string) => {
    return props.listingProps.every((list: any) => list[type] === 0);
  };

  const createPlaceholder = () => {
    const param = document.createElement("span");
    const node = document.createTextNode("Select industry");
    param.classList.add("placeholder");
    param.appendChild(node);
    return param;
  };

  useEffect(() => {
    return history.listen((location) => {
      props.clearFilter();
      let retail = checkFilterExits("ffi_laners");
      let senior = checkFilterExits("ffi_lanesl");
      let contract = checkFilterExits("ffi_lanect");

      optionsArr = optionsArr.filter((opt) => {
        if (retail) {
          return opt.oldValue !== "ffi_laners";
        } else if (senior) {
          return opt.oldValue !== "ffi_lanesl";
        } else if (contract) {
          return opt.oldValue !== "ffi_lanect";
        } else {
          return opt;
        }
      });
      setOptions(optionsArr);
    });
  }, [history]);

  let multiSelect = document.getElementById("multiselectContainerReact");
  let dropdownArrow = document.querySelector("img.icon_cancel.icon_down_dir");
  let isOpen = document.querySelector(".optionListContainer");

  useEffect(() => {
    let chips = document.querySelectorAll("span.chip");
    dropdownArrow = document.querySelector("img.icon_cancel.icon_down_dir");
    isOpen = document.querySelector(".optionListContainer");
    multiSelect = document.getElementById("multiselectContainerReact");
    let searchWrapper = document.querySelector(".search-wrapper");
    let placeholder = document.querySelector(".placeholder");
    if (
      chips.length > 0 &&
      // @ts-ignore
      !chips?.[0]?.innerText === "All" &&
      chips.length < 3
    ) {
      multiSelect?.classList.add("chip-added");
      searchWrapper?.classList.remove("height-extend");
      // @ts-ignore
    } else if (chips.length === 3 && props.filter.lane !== "") {
      searchWrapper?.classList.add("height-extend");
    } else {
      if (
        chips.length === 0 ||
        // @ts-ignore
        (chips.length === 1 && chips?.[0]?.innerText === "All")
      ) {
        if (placeholder && selectedValues?.length === 0) {
          // @ts-ignore
          placeholder.style.display = "block";
        }
      } else {
        if (
          placeholder &&
          (chips.length > 1 || chips.length === 1) &&
          // @ts-ignore
          chips?.[chips.length - 1]?.innerText !== "All"
        ) {
          // @ts-ignore
          placeholder.style.display = "none";
        }
      }
      // @ts-ignore
      if (chips?.[0]?.innerText === "All") {
        let param = createPlaceholder();
        if (document.querySelectorAll(".placeholder").length < 1) {
          searchWrapper?.appendChild(param);
        }
      }
      multiSelect?.classList.remove("chip-added");
      searchWrapper?.classList.remove("height-extend");
    }
    if (!props.filter.lane && placeholder && selectedValues?.length === 0) {
      // @ts-ignore
      placeholder.style.display = "block";
    }
    if (dropdownArrow) {
      // @ts-ignore
      dropdownArrow.src = Triangle;
    }
    multiSelect?.addEventListener("click", (e) => {
      // @ts-ignore
      e.currentTarget.classList.add("focus");
      if (isOpen?.classList.contains("displayBlock")) {
        // @ts-ignore
        dropdownArrow?.classList.add("rotate");
      } else {
        // @ts-ignore
        isOpen?.classList.remove("displayBlock");
      }
    });
    const removeClasses = (event: any) => {
      if (
        event.target.id === "search_input" ||
        event.target.classList.contains("search-wrapper") ||
        event.target.classList.contains("icon_down_dir")
      )
        return;
      dropdownArrow?.classList.remove("rotate");
      // @ts-ignore
      multiSelect?.classList.remove("focus");
    };
    multiSelect?.classList.remove("focus");
    dropdownArrow?.classList.remove("rotate");
    document.body.addEventListener("click", removeClasses);
    document.body.addEventListener("mousedown", removeClasses);
  });

  const switchClassName = clsx("px-4", {
    "pt-4 pb-4 border-bottom": props.vertical,
  });

  useEffect(() => {
    let chip = document.querySelector("span.chip");
    if (props.filter.lane === "") {
      setSelectedValues([{ value: "", label: "All" }]);
      if (chip) {
        // @ts-ignore
        chip.style.display = "none";
      }
    } else {
      if (Array.isArray(props.filter.lane) && !initLoad) {
        props.setLaneFilter(props.filter.lane);
        let options = optionsArr.filter((option) =>
          // @ts-ignore
          props.filter.lane.some((lane) => option.value === lane)
        );
        setSelectedValues(options);
        setInitLoad(true);
      }
      document
        .querySelectorAll("span.chip")
        .forEach((el: any) => (el.style.display = "block"));
    }
  }, [props.filter.lane]);

  // @ts-ignore
  const onSelect = (selectedList, selectedItem) => {
    // @ts-ignore
    setSelectedValues(selectedList.filter((data) => data.value !== ""));
    let data: any[] = [];
    // @ts-ignore
    selectedList.map((list: any) => {
      data.push(list.value);
    });
    if (selectedItem.value === "") {
      data = [""];
      props.clearLaneFilter();
      setSelectedValues([options[0]]);
      return;
    } else {
      data = data.filter((d) => d !== "");
    }
    // @ts-ignore
    props.setLaneFilter(data);
  };
  // @ts-ignore
  const onRemove = (selectedList, removedItem) => {
    setSelectedValues(selectedList);
    let data: any[] = [];
    // @ts-ignore
    selectedList.map((list: any) => {
      data.push(list.value);
    });
    // @ts-ignore
    props.setLaneFilter(data);
    dropdownArrow?.classList.add("rotate");
    // @ts-ignore
    multiSelect?.classList.add("focus");
  };

  const onChangeIndustryMobile = (
    selectedValues: any[],
    value: { label: string; value: string }
  ) => {
    let arr = [...selectedValues];
    let itemFound = arr.find((v) => v.label === value.label);

    if (itemFound) {
      onRemove(
        selectedValues.filter((slvalue) => slvalue.value !== value.value),
        null
      );
    } else {
      arr.push(value);
      onSelect(arr, value);
    }
  };

  const checkFilterExitsMobile = useCallback((type: string) => {
    return props.listingProps.every((list: any) => list[type] === 0);
  }, []);

  const checkIfIndustryFilterSelected = useCallback(
    (value: string) =>
      selectedValues.find((v) => v.value === value) ? true : false,
    [selectedValues]
  );

  return props.hasRetail || props.hasSenior || props.hasContract ? (
    <div
      className={clsx(
        "d-flex w-100",
        { "flex-column": props.vertical },
        props.className
      )}
    >
      {isMobile ? (
        <>
          <div
            className="my-4 flex-grow-1 overflow-auto"
            style={{ height: "80vh" }}
          >
            <Switch
              className={props.vertical ? switchClassName : ""}
              label="All"
              checked={
                props.filter.lane === "" &&
                selectedValues.find((v) => v.value === "")
                  ? true
                  : false
              }
              onChange={() => {
                props.clearLaneFilter();
              }}
            />
            {props.hasRetail && !checkFilterExitsMobile("ffi_laners") && (
              <Switch
                className={switchClassName}
                label="Residential"
                checked={
                  props.filter.lane.includes("7438") &&
                  checkIfIndustryFilterSelected("7438")
                }
                onChange={() =>
                  onChangeIndustryMobile(selectedValues, {
                    label: "Retail",
                    value: "7438",
                  })
                }
              />
            )}
            {props.hasSenior && !checkFilterExitsMobile("ffi_lanesl") && (
              <Switch
                className={switchClassName}
                label="Senior Living"
                checked={
                  props.filter.lane.includes("7439") &&
                  checkIfIndustryFilterSelected("7439")
                }
                onChange={() =>
                  onChangeIndustryMobile(selectedValues, {
                    label: "Senior Living",
                    value: "7439",
                  })
                }
              />
            )}
            {props.hasContract && !checkFilterExitsMobile("ffi_lanect") && (
              <Switch
                className={switchClassName}
                label="Commercial"
                checked={
                  props.filter.lane.includes("7440") &&
                  checkIfIndustryFilterSelected("7440")
                }
                onChange={() =>
                  onChangeIndustryMobile(selectedValues, {
                    label: "Hospitality + Contract",
                    value: "7440",
                  })
                }
              />
            )}
          </div>
          <div className="m-4 p-2">
            <MDBBtn className="m-0 w-100" onClick={props.onSaveSelection}>
              Save Selection
            </MDBBtn>
          </div>
        </>
      ) : (
        <> </>
        // <Multiselect
        //   options={options ?? []}
        //   selectedValues={selectedValues}
        //   onSelect={onSelect}
        //   // @ts-ignore
        //   onSearch={function noRefCheck() {}}
        //   onRemove={onRemove}
        //   displayValue="label"
        //   showCheckbox={true}
        //   showArrow={true}
        //   placeholder={
        //     !selectedValues?.find((v) => v.label === "All")
        //       ? ""
        //       : "Select industry"
        //   }
        //   onKeyPressFn={function noRefCheck() {}}
        // />
      )}
    </div>
  ) : null;
};

export default SwitchFilters;
