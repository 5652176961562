import Icon from "app/assets/icon/icon";
import clsx from "clsx";
import { MDBCol, MDBCollapse, MDBRow, MDBTypography } from "mdbreact";
import React, { useContext, useEffect, useState } from "react";
import { OptionSelector } from "./OptionSection";
import { CustomPriceContext } from "../customPriceContext";
import Select from "../../../../layout/Select";
import uuid from "react-uuid";

export const mainFabricName = "Main Fabric";

export const SubOptionsSection = ({
  title = "",
  options = [],
  customizationProps,
  isCustom,
  isOpen = false,
  productImage,
  defaults,
  fabricsSelections,
  titleClassName,
  frame,
  fabricOrFinishesSelectedOptions,
  setFabricOrFinishSelectedOptions,
  allowedFinishes,
  queryPathFabrics,
  checkBoxOptions,
  setCheckBoxOptions,
  dropdownOptions,
  setDropdownOptions,
  upholsteryOptionsFabrics,
  customer,
}) => {
  const [collapse, setCollapse] = useState(isOpen);
  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  const { viewMoreDropdownSelector, setViewMoreDropdownSelector } = useContext(
    CustomPriceContext
  );

  const [selectedDropdownValues, setSelectedDropdownValues] = useState({});
  useEffect(() => {
    let foundSelectedDropdownValues = {};
    let found = false;
    Object.keys(viewMoreDropdownSelector).forEach((key) => {
      if (viewMoreDropdownSelector[key]) {
        found = true;
        const foundOption = options.find(
          (option) => option.title.toLowerCase().split(" ").join("_") === key
        );
        foundSelectedDropdownValues = {
          label: foundOption.title,
          value: foundOption,
        };
      }
    });

    if (!found) {
      foundSelectedDropdownValues = null;
    }

    setSelectedDropdownValues(foundSelectedDropdownValues);
  }, [viewMoreDropdownSelector]);

  return (
    <MDBCol size="12" className="sub-options-section-wrapper mt-5 pr-lg-0 pr-4">
      <MDBRow
        onClick={toggleCollapse}
        className="align-items-center  sub-options-title"
      >
        <MDBCol size="10">
          <MDBTypography
            tag="h4"
            className={clsx(
              titleClassName ||
                "cursor-pointer body-2 fwregular text-darken-gray"
            )}
          >
            {title}
          </MDBTypography>
        </MDBCol>
        <MDBCol size="2" className="sub-options-title-icon">
          {collapse && <Icon icon="minusBlue" />}
          {!collapse && <Icon icon="plusBlue" />}
        </MDBCol>
      </MDBRow>
      <MDBRow className="py-4">
        <MDBCol className="pl-0 sub-options">
          <MDBCollapse isOpen={collapse}>
            {options
              .filter((option) => {
                let rtrBool = true;
                Object.keys(viewMoreDropdownSelector).forEach((key) => {
                  if (option.title.toLowerCase().split(" ").join("_") === key) {
                    rtrBool = false;
                  }
                });
                return rtrBool;
              })
              .map((opt) => {
                const configFabricProps = (() => {
                  if (fabricsSelections) {
                    switch (opt.title) {
                      case mainFabricName:
                        return {
                          configFabric: fabricsSelections.mainFabric,
                          setConfigFabric: fabricsSelections.setMainFabric,
                        };
                      case "Back Pillow":
                        return {
                          configFabric: fabricsSelections.backFabric,
                          setConfigFabric: fabricsSelections.setBackFabric,
                        };
                      case "Cushion":
                        return {
                          configFabric: fabricsSelections.cushionFabric,
                          setConfigFabric: fabricsSelections.setCushionFabric,
                        };
                      case "Tight Seat":
                        return {
                          configFabric: fabricsSelections.cushionFabric,
                          setConfigFabric: fabricsSelections.setCushionFabric,
                        };
                      case "Contrasting Welt":
                        return {
                          configFabric: fabricsSelections.weltFabric,
                          setConfigFabric: fabricsSelections.setWeltFabric,
                        };
                      case "Decorative Cord - Throw Pillows":
                        return {
                          configFabric: fabricsSelections.decorativeCordFabric,
                          setConfigFabric:
                            fabricsSelections.setDecorativeCordFabric,
                        };
                      case "Throw Pillow":
                        return {
                          configFabric: fabricsSelections.throwPillowFabric,
                          setConfigFabric:
                            fabricsSelections.setThrowPillowFabric,
                        };
                      default:
                        return undefined;
                    }
                  }
                })();

                return (
                  <MDBRow
                    key={opt.title}
                    className="py-2 options-selection-select"
                  >
                    <MDBCol size="12" lg="3" className="py-3 pl-0 pl-lg-4">
                      <MDBTypography tag="h2" className="body-2 fwlight">
                        {opt.title}
                      </MDBTypography>
                    </MDBCol>
                    <MDBCol className="cursor-pointer">
                      <OptionSelector
                        configFabricProps={configFabricProps}
                        opt={opt}
                        allowedFinishes={allowedFinishes}
                        mainFabric
                        customizationProps={customizationProps}
                        productImage={productImage}
                        defaults={defaults}
                        frame={frame}
                        fabricOrFinishesSelectedOptions={
                          fabricOrFinishesSelectedOptions
                        }
                        fabricsSelections={fabricsSelections}
                        isCustom={isCustom}
                        setFabricOrFinishSelectedOptions={
                          setFabricOrFinishSelectedOptions
                        }
                        queryPathFabrics={queryPathFabrics}
                        checkBoxOptions={checkBoxOptions}
                        setCheckBoxOptions={setCheckBoxOptions}
                        dropdownOptions={dropdownOptions}
                        setDropdownOptions={setDropdownOptions}
                        upholsteryOptionsFabrics={upholsteryOptionsFabrics}
                        customer={customer}
                      />
                    </MDBCol>
                  </MDBRow>
                );
              })}
            {Object.keys(viewMoreDropdownSelector).map(() => {
              if (Object.keys(viewMoreDropdownSelector).length > 0) {
                let viewMoreDropdownSelected = [];
                viewMoreDropdownSelected.push({
                  label: "None",
                  value: null,
                });
                Object.keys(viewMoreDropdownSelector).forEach((key) => {
                  let foundOption = options.find(
                    (option) =>
                      option.title.toLowerCase().split(" ").join("_") === key
                  );
                  if (foundOption) {
                    viewMoreDropdownSelected.push({
                      label: foundOption.title,
                      value: foundOption,
                    });
                  }
                });
                const configFabricProps = (() => {
                  if (fabricsSelections) {
                    switch (selectedDropdownValues?.value.title) {
                      case mainFabricName:
                        return {
                          configFabric: fabricsSelections.mainFabric,
                          setConfigFabric: fabricsSelections.setMainFabric,
                        };
                      case "Cushion":
                        return {
                          configFabric: fabricsSelections.cushionFabric,
                          setConfigFabric: fabricsSelections.setCushionFabric,
                        };
                      case "Tight Seat":
                        return {
                          configFabric: fabricsSelections.cushionFabric,
                          setConfigFabric: fabricsSelections.setCushionFabric,
                        };
                      case "Back Pillow":
                        return {
                          configFabric: fabricsSelections.backFabric,
                          setConfigFabric: fabricsSelections.setBackFabric,
                        };
                      case "Contrast Welt":
                        if (title !== "Pillow Upholstery Options") {
                          return {
                            configFabric: fabricsSelections.weltFabric,
                            setConfigFabric: fabricsSelections.setWeltFabric,
                          };
                        }
                        break;
                      case "Decorative Cord":
                        return {
                          configFabric: fabricsSelections.decorativeCordFabric,
                          setConfigFabric:
                            fabricsSelections.setDecorativeCordFabric,
                        };
                      case "Throw Pillow Contrast":
                        return {
                          configFabric: fabricsSelections.throwPillowFabric,
                          setConfigFabric:
                            fabricsSelections.setThrowPillowFabric,
                        };
                      default:
                        return undefined;
                    }
                  }
                })();
                return (
                  <div key={uuid()}>
                    <MDBRow className="py-2 options-selections">
                      <MDBCol size="12" lg="3" className="py-3 px-0 px-lg-4">
                        <MDBTypography tag="h2" className="body-2 fwlight">
                          Upholstery Details
                        </MDBTypography>
                      </MDBCol>
                      <MDBCol
                        className="cursor-pointer"
                        style={{ paddingLeft: 0, paddingRight: "30px" }}
                      >
                        <Select
                          placeholder="Select option..."
                          value={selectedDropdownValues}
                          options={viewMoreDropdownSelected}
                          onChange={(e) => {
                            let localViewMoreDropdownSelector = JSON.parse(
                              JSON.stringify(viewMoreDropdownSelector)
                            );
                            Object.keys(localViewMoreDropdownSelector).forEach(
                              (key) => {
                                localViewMoreDropdownSelector[key] =
                                  key ===
                                  e.label.toLowerCase().split(" ").join("_");
                              }
                            );
                            setViewMoreDropdownSelector(
                              localViewMoreDropdownSelector
                            );
                          }}
                        />
                      </MDBCol>
                    </MDBRow>
                    {selectedDropdownValues?.value && (
                      <MDBRow className="py-2 options-selections">
                        <MDBCol size="12" lg="3" className="py-3 px-0 px-lg-4">
                          <MDBTypography tag="h2" className="body-2 fwlight">
                            {selectedDropdownValues?.value.title}
                          </MDBTypography>
                        </MDBCol>
                        <MDBCol className="cursor-pointer">
                          <OptionSelector
                            configFabricProps={configFabricProps}
                            opt={selectedDropdownValues?.value}
                            allowedFinishes={allowedFinishes}
                            mainFabric
                            customizationProps={customizationProps}
                            productImage={productImage}
                            defaults={defaults}
                            frame={frame}
                            fabricOrFinishesSelectedOptions={
                              fabricOrFinishesSelectedOptions
                            }
                            fabricsSelections={fabricsSelections}
                            isCustom={isCustom}
                            setFabricOrFinishSelectedOptions={
                              setFabricOrFinishSelectedOptions
                            }
                            queryPathFabrics={queryPathFabrics}
                            checkBoxOptions={checkBoxOptions}
                            setCheckBoxOptions={setCheckBoxOptions}
                            dropdownOptions={dropdownOptions}
                            setDropdownOptions={setDropdownOptions}
                            upholsteryOptionsFabrics={upholsteryOptionsFabrics}
                            customer={customer}
                          />
                        </MDBCol>
                      </MDBRow>
                    )}
                  </div>
                );
              } else {
                return <></>;
              }
            })}
          </MDBCollapse>
        </MDBCol>
      </MDBRow>
    </MDBCol>
  );
};
export default SubOptionsSection;
