import React, { memo, useEffect, useRef, useMemo, useState } from "react";
import _ from "lodash";
import Icon from "app/assets/icon/icon";
import Select from "app/layout/Select";
import { MDBBox, MDBInput } from "mdbreact";
import { useSearch } from "app/state/hooks/searchHooks/useSearch";
import SearchBuildCollection from "./searchBuildCollection";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Checkbox from "app/layout/Checkbox";

const CATEGORY_LIST_QUERY = gql`
  query categoryList($filter: CategoryFilterInput) {
    categoryList(filters: $filter) {
      id
      name
      children {
        id
        name
        children {
          id
          name
          children {
            id
            name
          }
        }
      }
    }
  }
`;

const SearchInput = memo(
  ({
    searchVal,
    setSearchVal,
    option,
    setOption,
    toggleFn,
    productType,
    setProductType,
    category,
    isExact,
    setExact,
  }) => {
    const searchInputRef = useRef();
    const [categoryValue, setCategoryValue] = React.useState("");

    useEffect(() => {
      setTimeout(() => {
        searchInputRef.current.inputElementRef.current.focus();
      }, 500);
    }, []);

    useEffect(() => {
      if (!categoryValue || categoryValue.label !== "All") {
        setCategoryValue(categoryListDropdownDynamic?.[0]);
        setProductType(categoryListDropdownDynamic?.[0]);
        setOption({ label: "All", value: "" });
      }
    }, [searchVal]);

    const { data: categoryDataQuery } = useQuery(CATEGORY_LIST_QUERY, {
      fetchPolicy: "no-cache",
      variables: { filters: { ids: { eq: "2375" } } },
    });

    let searchResult = useSearch(
      searchVal,
      option.label,
      productType?.value,
      isExact
    );

    let filteredOptions = useMemo(() => {
      let options = searchResult?.products?.aggregations
        ?.find(
          (aggregation) => aggregation.attribute_code === "industry_filter"
        )
        ?.options?.filter(
          (option) =>
            !(option.label === "0" && option.value === "0")
        );

      if (options?.length && !options.find((option) => option.label === "All"))
        options.unshift({ value: "", label: "All" });

      return options ?? [];
    }, [searchResult?.products?.aggregations]);

    let categoryListDropdownDynamic = useMemo(() => {
      if (categoryDataQuery?.categoryList) {
        let categories = categoryDataQuery?.categoryList?.[0]?.children
          ?.find((level) => level?.name === "Products")
          ?.children?.find((level1) => level1?.name === "Shop By Categories")
          ?.children.map((val) => ({
            value: val?.id,
            label: val?.name,
          }))
          .filter((val) => val.label !== "Custom Options");
        if (searchResult?.products?.aggregations) {
          const foundCategory = searchResult?.products?.aggregations.find(
            (option) => option?.attribute_code === "category_id"
          );

          categories = categories?.filter((el) => {
            return foundCategory?.options.some((f) => {
              return +f?.value === el?.value;
            });
          });
        }
        if (categories) {
          categories.unshift({
            label: "All",
            value: 2,
          });
        } else {
          categories = [
            {
              label: "All",
              value: 2,
            },
          ];
        }

        return categories;
      }
      return null;
    }, [categoryDataQuery, searchResult?.products?.aggregations]);

    return (
      <>
        <MDBBox className="position-relative">
          <MDBInput
            ref={searchInputRef}
            className="search-input"
            hint={`${
              category === "Products"
                ? "Enter a SKU, Name, Material, or a Category"
                : "Enter a SKU, Name, or Color"
            }`}
            value={searchVal}
            onChange={(e) => {
              setSearchVal(e.target.value);
            }}
          >
            <Icon icon="search" />
          </MDBInput>
          {/* <Checkbox
                className=""
                checked={isExact}
                label={`Exact match`}
                onChange={() => setExact(!isExact)}
              /> */}
          {category === "Products" && (
            <MDBBox className="search-bottom-fields">
              <Select
                options={categoryListDropdownDynamic}
                onChange={(e) => {
                  setProductType(e);
                  setCategoryValue(e);
                  setOption({ label: "All", value: "" });
                }}
                placeholder="Category Type"
                wrapperClass="my-4 text-left"
                isSearchable={false}
                value={categoryValue}
              />
              <div className="industry-holder">
                <Select
                  value={
                    option.value === ""
                      ? { label: "Select industry", value: "" }
                      : option
                  }
                  options={filteredOptions}
                  onChange={(e) => {
                    setOption(e);
                  }}
                  wrapperClass="my-4 text-left"
                  isSearchable={false}
                />
              </div>
            </MDBBox>
          )}
        </MDBBox>
        <MDBBox className="border-bottom my-5" />
        <SearchBuildCollection
          searchVal={searchVal}
          toggleFn={toggleFn}
          category={category}
          searchResult={searchResult}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }
    return false;
  }
);
export default SearchInput;
