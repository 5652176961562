import React from "react";
import { MDBContainer } from "mdbreact";
import ReactLoader from "app/layout/loader";

const Temporary = (props) => {
  return (
    <MDBContainer
      id={`temporary`}
      className={`d-flex flex-column align-items-center justify-content-center center`}
    >
      <ReactLoader width={30} height={30} type={`ThreeDots`} />
    </MDBContainer>
  );
};

export default Temporary;
