import Icon from "app/assets/icon/icon";
import { useBoolean } from "ahooks";
import { ICartContext } from "app/layout/cart/CartContext/CartContext";
import ModalHeader from "app/layout/ModalHeader";
import { createEnterHandler } from "app/utils/utils";
import { omit } from "lodash";
import {
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBTypography,
  MDBModalFooter,
} from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";
import { cartPaths } from "../../config";
import { RemoveCartItemInput } from "../../hooks";
import { DomainCartItem } from "../../models";
import CartSummaryItem from "../CartSummaryItem";
import CartContext from "app/layout/cart";
export interface MiniCartProps
  extends Required<Pick<React.ComponentProps<typeof MDBModal>, "isOpen">>,
    Pick<ICartContext, "closeModal"> {
  isMobile: boolean;
  items: DomainCartItem[];
  onRemoveItem: (cartItemId: RemoveCartItemInput["cart_item_id"]) => void;
}
const MiniCart: React.FC<MiniCartProps> = (props) => {
  const [removeItemModal, setRemoveItemModal] = useBoolean();
  const { closeModal } = React.useContext(CartContext);
  const { isModalOpen } = React.useContext(CartContext);
  const [autoClose, setautoClose] = React.useState(true);
  const [selectedItemId, setSelectedItemId] = React.useState<null | string>(
    null
  );

  const [timeId, settimeId] = React.useState(0);
  let time: any;
  const modal = document.querySelector(".mini-cart");

  const handleMouseOver = () => {
    // @ts-ignore
    modal?.classList.add("hovered");
    const clearId: any = clearInterval(time);
    settimeId(clearId);
    setautoClose(false);
  };

  const handleMouseLeave = () => {
    // @ts-ignore
    modal?.classList.remove("hovered");
    setautoClose(true);
    if (!removeItemModal) {
      time = setTimeout(() => {
        closeModal();
      }, 5000);
      settimeId(time);
    }
  };

  React.useEffect(() => {
    if (autoClose && isModalOpen && !removeItemModal) {
      time = setTimeout(() => {
        closeModal();
      }, 5000);
      settimeId(time);
    } else {
      const clearId: any = clearInterval(time);
      settimeId(clearId);
    }
    return () => {
      clearInterval(time);
    };
  }, [autoClose]);

  React.useEffect(() => {
    if (!props.isOpen) {
      setRemoveItemModal.setFalse();
    }
  });

  return (
    <>
      <MDBModal
        inline={false}
        noClickableBodyWithoutBackdrop
        overflowScroll={false}
        isOpen={props.isOpen}
        toggle={props.closeModal}
        className="h-100 m-0 ml-auto"
        backdropClassName="d-none"
        contentClassName="h-100"
      >
        <MDBModalBody
          className="p-0 h-100 d-flex flex-column mini-cart"
          onMouseOver={handleMouseOver}
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          <ModalHeader onClose={props.closeModal}>
            {(props.items.length === 0 && "Quote Request") || " Quote Request"}
          </ModalHeader>
          {(props.items.length === 0 && (
            <MDBRow className="my-5 mx-3 grow text-center">
              <MDBCol>
                <MDBTypography
                  tag="h4"
                  className={`fwregular text-default my-5`}
                >
                  There are no products currently in Quote Request
                </MDBTypography>
              </MDBCol>
            </MDBRow>
          )) || (
            <>
              <MDBRow className="my-5 mx-3">
                <MDBCol>
                  <MDBTypography className="mb-0 fs-24 text-default">
                    {props.items.length} Item(s)
                  </MDBTypography>
                </MDBCol>
                <MDBCol className="d-flex justify-content-end align-items-center">
                  <Link
                    to={cartPaths.path}
                    onClick={props.closeModal}
                    className="link-2 body-1"
                  >
                    View and Edit Quote Request
                  </Link>
                </MDBCol>
              </MDBRow>
              <div className="grow overflow-y-auto overflow-x-hidden">
                {props.items.map((item) => {
                  const handleRemove = () => {
                    props.onRemoveItem(Number(selectedItemId));
                  };
                  return (
                    <>
                      <MDBRow
                        key={item.id}
                        className="pt-4 mb-4 pb-2 border-top-1 border-gray-lighten-4"
                      >
                        <MDBCol className="mt-3 position-relative">
                          <CartSummaryItem
                            key={item.id}
                            item={omit(item, "quantity")}
                          />
                          <MDBRow className="position-absolute bottom-0 right-0">
                            <MDBCol className="d-flex justify-content-end">
                              <div
                                className="mr-4 pr-3 d-flex align-items-center cursor-pointer"
                                onClick={() => {
                                  setRemoveItemModal.setTrue();
                                  setSelectedItemId(item.id);
                                }}
                                tabIndex={0}
                                role="button"
                              >
                                <Icon icon="closeDefault" />
                                <MDBTypography className="m-0 underline text-default fs-13">
                                  Remove
                                </MDBTypography>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                      <MDBModal
                        className={`remove-from-quote-modal`}
                        isOpen={removeItemModal}
                        inline={false}
                        overflowScroll
                        noClickableBodyWithoutBackdrop={false}
                        centered
                      >
                        <MDBModalBody className="p-0">
                          <ModalHeader
                            //@ts-ignore
                            onClose={setRemoveItemModal.setFalse}
                          >
                            Remove from Quote Request
                          </ModalHeader>
                          <MDBRow className="mt-5 mx-5 px-sm-5 mb-5 pb-5">
                            <MDBCol>
                              {/* @ts-ignore */}
                              <MDBTypography
                                className="text-center mb-5"
                                // @ts-ignore
                                variant="body-2"
                              >
                                Are you sure that you want to remove this
                                product from the Quote Request?
                              </MDBTypography>
                              <MDBRow>
                                <MDBCol size="6">
                                  <MDBBtn
                                    onClick={() => {
                                      setRemoveItemModal.setFalse();
                                      // time = setTimeout(() => {
                                      //   closeModal();
                                      // }, 5000);
                                    }}
                                    className="m-0 w-100"
                                    color="secondary"
                                  >
                                    Cancel
                                  </MDBBtn>
                                </MDBCol>
                                <MDBCol size="6">
                                  <MDBBtn
                                    className="m-0 w-100"
                                    onClick={() => {
                                      handleRemove();
                                      setRemoveItemModal.setFalse();
                                      // time = setTimeout(() => {
                                      //   closeModal();
                                      // }, 5000);
                                    }}
                                    onKeyUp={() => {
                                      createEnterHandler(handleRemove);
                                      setRemoveItemModal.setFalse();
                                      // time = setTimeout(() => {
                                      //   closeModal();
                                      // }, 5000);
                                    }}
                                  >
                                    Confirm
                                  </MDBBtn>
                                </MDBCol>
                              </MDBRow>
                            </MDBCol>
                          </MDBRow>
                        </MDBModalBody>
                      </MDBModal>
                    </>
                  );
                })}
              </div>
            </>
          )}
          <MDBModalFooter
            onClick={props.closeModal}
            className="py-4 d-flex justify-content-center z-index-1 shadow-1"
          >
            <Link to={cartPaths.path} className="my-3 mx-5 px-5 w-100">
              <MDBBtn className="m-0 w-100">Go to Quote Request</MDBBtn>
            </Link>
          </MDBModalFooter>
        </MDBModalBody>
      </MDBModal>
    </>
  );
};

export default MiniCart;
