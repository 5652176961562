import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import queryString from "query-string";
import {
  armArray,
  seatAndBackArray,
  baseArray,
  fabricArray,
  floorArray,
  frameArray,
  frameNailSizeArray,
  nailFinishArray,
  accessoryArrayLeft,
  accessoryArrayRight,
  powerArray,
} from "./data";
import { CustomDisplay } from "./Custom";

export const PRODUCT_PROBE = gql`
  query Product($filters: ProductAttributeFilterInput!, $search: String) {
    products(filter: $filters, search: $search) {
      items {
        id
        name
        sku
        price_range {
          minimum_price {
            final_price {
              value
            }
          }
        }
        depth: ffi_frmdimd
        height: ffi_frmdimh
        width: ffi_frmdimw
      }
    }
  }
`;

type MaybeFabric = typeof fabricArray[number] | null;
type MaybeSize = typeof frameNailSizeArray[number] | null | undefined;
type MaybeNailFinish = typeof nailFinishArray[number] | null | undefined;

export const Senior = () => {
  const match = useRouteMatch<{ collection: string; params: string }>({
    path: "/build-your-own/:collection/:params",
    strict: true,
    sensitive: true,
  });

  const { search } = useLocation();
  const [values, setValues] = useState({});
  useEffect(() => {
    if (search) {
      const localSearch = JSON.parse(
        JSON.stringify(search.replace(/\&amp;/g, "&").replace(/\&quot;/g, `"`))
      );
      setValues(queryString.parse(localSearch));
    }
  }, [search]);

  let queryPathOptions: any;

  // @ts-ignore
  if (values && values.build_your_own_options) {
    // @ts-ignore
    queryPathOptions = JSON.parse(values.build_your_own_options);
  }
  const initial: {
    seatAndBack: typeof seatAndBackArray[number];
    frame: typeof frameArray[number];
    arm: typeof armArray[number];
    base: typeof baseArray[number];
    floor: typeof floorArray[number];
    mainFabric: typeof fabricArray[number];
    accessoryLeft: typeof accessoryArrayLeft[number] | null;
    accessoryRight: typeof accessoryArrayRight[number] | null;
    powerOption: typeof powerArray[number];
  } = React.useMemo(() => {
    const defaultSeatAndBack = seatAndBackArray[0];
    const defaultFrame = frameArray[0];
    const defaultArm = armArray[0];
    const defaultBase = baseArray[0];
    const defaultFloor = floorArray.filter((f) => f.default)[0];
    const defaultFabric = fabricArray[2];
    const defaultAccessoryLeft = null;
    const defaultAccessoryRight = null;
    const defaultPowerOption = powerArray[0];

    if (match?.params.params) {
      let sku: any;
      let mainFabricId: any;
      let seatAndBackId: any;
      let armId: any;
      let baseId: any;
      let accessId: any;
      let frameIds: any[] | string = [];
      let powerOptionId: any;
      let frameId: any;
      if (match.params.params.indexOf("S-XXX") === -1) {
        [sku, mainFabricId] = match.params.params.split("&");
        [seatAndBackId, armId, baseId, accessId, , ...frameIds] = sku.split("");
        frameId = frameIds.join("");
      } else {
        sku = match.params.params.substr(2);
        frameId = sku.substr(3, 1);
        armId = sku.substr(0, 3);
        powerOptionId = sku.substr(5);
      }

      return {
        seatAndBack:
          seatAndBackArray.find(
            (seatAndBack) => seatAndBack.id === seatAndBackId
          ) ?? defaultSeatAndBack,
        frame:
          frameArray.find((frame) => frame.sku === frameId) ?? defaultFrame,
        arm: armArray.find((arm) => arm.id === armId) ?? defaultArm,
        base: baseArray.find((base) => base.id === baseId) ?? defaultBase,
        accessoryLeft:
          accessoryArrayLeft.find(
            (accessoryLeft) => accessoryLeft.id === accessId
          ) ?? defaultAccessoryLeft,
        accessoryRight:
          accessoryArrayRight.find(
            (accessoryRight) => accessoryRight.id === accessId
          ) ?? defaultAccessoryRight,
        mainFabric:
          fabricArray.find((mainFabric) => mainFabric.id === mainFabricId) ??
          defaultFabric,
        floor: defaultFloor,
        powerOption:
          powerArray.find((powerOption) => powerOption.id === powerOptionId) ??
          defaultPowerOption,
      };
    } else {
      return {
        seatAndBack: defaultSeatAndBack,
        frame: defaultFrame,
        arm: defaultArm,
        base: defaultBase,
        floor: defaultFloor,
        mainFabric: defaultFabric,
        accessoryLeft: defaultAccessoryLeft,
        accessoryRight: defaultAccessoryRight,
        powerOption: defaultPowerOption,
      };
    }
  }, [match]);

  let [player, setPlayer] = React.useState(null);
  const canvasRef = React.useRef(null);

  let [seatAndBack, setSeatAndBack] = useState(initial.seatAndBack);
  let [frame, setFrame] = useState(initial.frame);
  let [arm, setArm] = useState(initial.arm);
  let [base, setBase] = useState(initial.base);
  let [accessoryLeft, setAccessoryLeft] = useState("X");
  let [accessoryRight, setAccessoryRight] = useState("X");
  let [powerOption, setPowerOption] = useState(initial.powerOption);
  let [floor, setFloor] = useState(initial.floor);
  let [mainFabric, setMainFabric] = useState<MaybeFabric>(null);
  let [backFabric, setBackFabric] = useState<MaybeFabric>(null);
  let [seatFabric, setSeatFabric] = useState<MaybeFabric>(null);
  let [pillowFabric, setPillowFabric] = useState<MaybeFabric>(null);
  let [cushionFabric, setCushionFabric] = useState<MaybeFabric>(null);
  let [pillowWeltFabric, setPillowWeltFabric] = useState<MaybeFabric>(null);
  let [throwPillowFabric, setThrowPillowFabric] = useState<MaybeFabric>(null);
  let [decorativeCordFabric, setDecorativeCordFabric] = useState<MaybeFabric>(
    null
  );
  let [weltFabric, setWeltFabric] = useState<MaybeFabric>(null);
  let [nailSize, setNailSize] = useState<MaybeSize>(null);
  let [nailFinish, setNailFinish] = useState<MaybeNailFinish>(null);
  let selectionsAndSelectors = {
    seatAndBack,
    setSeatAndBack,
    frame,
    setFrame,
    arm,
    setArm,
    base,
    setBase,
    floor,
    setFloor,
    accessoryLeft,
    setAccessoryLeft,
    accessoryRight,
    setAccessoryRight,
    powerOption,
    setPowerOption,
  };

  const nailData = {
    nailSize,
    setNailSize: (label: string | undefined) => {
      const size = label
        ? frameNailSizeArray.find((i) => {
            return i.name
              .toLowerCase()
              .includes(label.split(" ")[0].toLowerCase().trim());
          })
        : null;
      setNailSize(size);
    },
    nailFinish,
    setNailFinish,
  };

  const fabricsSelections = React.useMemo(
    () => ({
      mainFabric,
      setMainFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setMainFabric(fabric);
        }
      },
      cushionFabric,
      setCushionFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setCushionFabric(fabric);
        }
      },
      backFabric,
      setBackFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setBackFabric(fabric);
        }
      },
      seatFabric,
      setSeatFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setSeatFabric(fabric);
        }
      },
      pillowFabric,
      setPillowFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setPillowFabric(fabric);
        }
      },
      pillowWeltFabric,
      setPillowWeltFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setPillowWeltFabric(fabric);
        }
      },
      weltFabric,
      setWeltFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setWeltFabric(fabric);
        }
      },
      throwPillowFabric,
      setThrowPillowFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setThrowPillowFabric(fabric);
        }
      },
      decorativeCordFabric,
      setDecorativeCordFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setDecorativeCordFabric(fabric);
        }
      },
    }),
    [
      backFabric,
      mainFabric,
      pillowFabric,
      pillowWeltFabric,
      seatFabric,
      weltFabric,
      cushionFabric,
      throwPillowFabric,
      decorativeCordFabric,
    ]
  );
  // Accessory logic - L -> Left, R -> Right, B -> Both, X -> None (Initial)
  let accessory =
    (accessoryRight !== "X" && accessoryLeft !== "X" && "B") ||
    (accessoryLeft !== "X" && "L") ||
    (accessoryRight !== "X" && "R") ||
    "X";

  let frameId = frame.sku;

  //When the R of Q is in the third position then the SKU has 3 parts, third part is finish
  const sku = frame.recliner
    ? "S-" +
      (powerOption?.id === "SR"
        ? frame.uvc
          ? 449
          : arm.reclinerId + 5
        : arm.reclinerId) +
      frameId +
      "-" +
      powerOption?.id
    : base?.id === "R" || base?.id === "Q"
    ? seatAndBack?.seat?.id +
      arm?.id +
      base?.id +
      accessory +
      "-" +
      frameId +
      "-" +
      base.finishId
    : seatAndBack?.seat?.id + arm?.id + base?.id + accessory + "-" + frameId;
  let showPdp = false;
  let price = 0;

  let filter = {
    filters: { sku: { eq: sku } },
    search: sku,
    pageSize: 1,
    currentPage: 1,
  };

  const { data } = useQuery(PRODUCT_PROBE, {
    variables: filter,
    fetchPolicy: "cache-first",
  });
  let productName;
  let productDimensions = {};
  if (data) {
    showPdp = true;
    price =
      data?.products?.items[0]?.price_range?.minimum_price?.final_price?.value;
    productName = data?.products?.items[0]?.name;
    productDimensions = {
      width: data?.products?.items[0]?.width,
      height: data?.products?.items[0]?.height,
      depth: data?.products?.items[0]?.depth,
    };
  }

  const displayProps = {
    seatAndBackArray,
    frameArray,
    armArray,
    baseArray,
    floorArray,
    fabricArray,
    selectionsAndSelectors,
    fabricsSelections,
    accessoryLeftArray: accessoryArrayLeft,
    accessoryRightArray: accessoryArrayRight,
    nailData,
    sku,
    showPdp,
    price,
    queryPathFabrics: values,
    productName,
    productDimensions,
    powerOptionsArray: powerArray,
  };

  return (
    // @ts-ignore
    <CustomDisplay
      ref={canvasRef}
      player={player}
      setPlayer={setPlayer}
      {...displayProps}
    />
  );
};
