import { MDBModal, MDBModalBody } from "mdbreact";
import React from "react";
import ModalHeader from "./ModalHeader";

export interface ModalProps
  extends Pick<React.ComponentProps<typeof MDBModal>, "isOpen" | "onClose"> {
  title: string;
}

const Modal: React.FC<ModalProps> = (props) => {
  return (
    <MDBModal
      inline={false}
      noClickableBodyWithoutBackdrop
      overflowScroll={false}
      backdropClassName="d-none"
      className="h-100 m-0 ml-auto"
      contentClassName="h-100"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <MDBModalBody className="p-0">
        <ModalHeader onClose={props.onClose}>{props.title}</ModalHeader>
        {props.children}
      </MDBModalBody>
    </MDBModal>
  );
};

export default Modal;
