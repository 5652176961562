export function removeItem(array, action, dynamic = false) {
  if (dynamic) {
    delete array.dynamic_page;
    return array;
  }
  return Object.keys(array).reduce(function(result, key) {
    if (key === action.id) result.splice(key, 1);
    return result;
  }, {});
}
