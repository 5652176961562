const inStockMessageMethod = (product, loggedIn = false, data) => {
  if (!product?.ffi_fullyuph && product?.ffi_uph_opts)
    return loggedIn ? `Frames Available: ${data}` : "Frame in Stock";
  else if (product?.ffi_casegd && !product?.finish_req)
    return loggedIn ? `As Shown Available: ${data}` : "As Shown In Stock";
  else return loggedIn ? `Available: ${data}` : "In stock";
};

export const inStockLogicMethod = (product, customer) => {
  let date = product?.ffi_duedate && product?.ffi_duedate?.split("/");
  if (+date?.[1] > 26) {
    date[1] = "1";
    if (+date[0] === 12) {
      date[0] = 1;
      date[2] = +date[2] + 1;
    } else {
      date[0] = +date[0] + 1;
    }
  }
  date = date && `${date?.[0]}/${date?.[2]}`;
  if (+product?.ffi_avails > 3) {
    if (!+product?.ffi_madeto) {
      if (customer)
        return inStockMessageMethod(product, customer, product?.ffi_avails);
      else inStockMessageMethod(product, false, product?.ffi_avails);
    } else if (product?.ffi_status === "NEW NOT APPROVE") return "Coming Soon";
    return inStockMessageMethod(product, false, product?.ffi_avails);
  } else {
    if (!+product?.ffi_madeto) {
      if (+product?.ffi_onords > 0 && date)
        return inStockMessageMethod(product, true, date);
      return "Out of Stock";
    } else {
      return product?.ffi_status === "NEW NOT APPROVE"
        ? "Coming Soon"
        : inStockMessageMethod(product, false, date);
    }
  }
};

export const quoteRequestButton = (
  isCustom,
  generate3DImage,
  player,
  uploadQuoteConfiguratorImage,
  name,
  isEdit,
  product,
  addItemToCart,
  sku,
  calculateOptions,
  updateItemOnCart,
  top,
  base,
  concatToString,
  numOfBases = 1
) => {
  if (isCustom && !window.location.pathname.includes("live-edge")) {
    generate3DImage(player, true).then((data) => {
      if (data) {
        data = data.substring(22, data.length);
        uploadQuoteConfiguratorImage({
          variables: {
            name,
            data,
          },
        }).then((data) => {
          if (data?.data) {
            const foundOption = product.options.find(
              (option) => option.title.toLowerCase() === "build your own image"
            );
            if (!isEdit) {
              addItemToCart({
                data: {
                  sku: sku,
                  quantity: 1,
                },
                customizable_options: [
                  ...calculateOptions(),
                  {
                    id: foundOption.option_id,
                    value_string:
                      data.data.uploadQuoteConfiguratorImage
                        .build_your_own_image,
                  },
                ],
              });
            } else {
              updateItemOnCart({
                customizable_options: [
                  ...calculateOptions(),
                  {
                    id: foundOption.option_id,
                    value_string:
                      data.data.uploadQuoteConfiguratorImage
                        .build_your_own_image,
                  },
                ],
                sku: sku,
              });
            }
          } else {
            console.error("Something went wrong.");
          }
        });
      }
    });
  } else if (top && base) {
    if (top?.sku === base?.sku)
      addItemToCart({
        data: {
          sku: top?.sku,
          quantity: 1,
        },
        customizable_options: calculateOptions(),
      });
    else
      addItemToCart([
        {
          data: { sku: top?.sku, quantity: 1 },
          customizable_options: calculateOptions(),
        },
        {
          data: {
            sku: base?.sku,
            quantity:
              new URLSearchParams(window.location.search).get("numOfBases") ||
              numOfBases ||
              1,
          },
          customizable_options: calculateOptions(),
        },
      ]);
  } else {
    if (!isEdit) {
      addItemToCart({
        data: {
          sku: sku,
          quantity: 1,
        },
        customizable_options: calculateOptions(),
      });
    } else {
      let options = calculateOptions();

      let getJSONData = !window.location.pathname.includes("build-your-own")
        ? false
        : JSON.parse(
            options?.find(
              (option) =>
                typeof option?.value_string === "string" &&
                option?.value_string?.includes("frame")
            )?.value_string
          );
      if (getJSONData?.powerOption?.id)
        options[options.length - 1].value_string = concatToString(
          "powerOption",
          getJSONData?.powerOption?.id,
          options[options.length - 1].value_string
        );
      updateItemOnCart({
        customizable_options: options,
      });
    }
  }
};
