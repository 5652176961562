import React from "react";
import { MDBLink } from "mdbreact";
import { generate3DImage } from "../../../../../../utils";
import themeConfiguration from "../../../../../../../../../config/themeConfiguration";
import DownloadTearsheetFromOldPDP from "app/pages/catalog/product/toolbar/downloadTearsheet/downloadTearsheet";
import Icon from "../../../../../../../../assets/icon/icon";
import { ModalConsumer } from "core/components/modal/ModalContext";

const DownloadTearsheet = ({
  customer,
  isCustom,
  customizationOptionsQueryParameters,
  uploadConfiguratorImage,
  id,
  name,
  price,
  player,
}) => {
  return (
    <ModalConsumer>
      {({ showModal }) => (
        <div className="download-link">
          <Icon icon="downloadSmall" className={"loadingIcon"} />
          <MDBLink
            className="d-inline-block ml-1 p-0 link-text-3"
            onClick={(event) => {
              if (customer) {
                if (!isCustom) {
                  showModal(() => {
                    return (
                      <DownloadTearsheetFromOldPDP
                        productId={id}
                        productName={name}
                        price={Number.parseFloat(price)}
                        customizationOptionsQueryParameters={
                          customizationOptionsQueryParameters
                        }
                      />
                    );
                  });
                } else {
                  generate3DImage(player).then((data) => {
                    if (data) {
                      data = data.substring(22, data.length);
                      uploadConfiguratorImage({
                        variables: {
                          name,
                          data,
                        },
                      }).then((data) => {
                        if (data?.data) {
                          let custom =
                            customizationOptionsQueryParameters +
                            `&configurator_pdf_image=${data.data.uploadConfiguratorImage.configurator_image}`;

                          showModal(() => {
                            return (
                              <DownloadTearsheetFromOldPDP
                                productId={id}
                                productName={name}
                                customizationOptionsQueryParameters={custom}
                                price={price}
                              />
                            );
                          });
                        } else {
                          console.error("Something went wrong.");
                        }
                      });
                    } else {
                      console.error("Something went wrong.");
                    }
                  });
                }
              } else {
                let downloadLink = "";
                if (!isCustom) {
                  downloadLink =
                    themeConfiguration.magento_url +
                    `productpdf/download/file/1?id=${id}&name=${name}&price_select=hidde&mark_up_value=&custom_price=` +
                    `&${customizationOptionsQueryParameters}&customer_token=${customer}`;

                  window.open(downloadLink, "_blank");
                } else {
                  generate3DImage(player).then((data) => {
                    if (data) {
                      data = data.substring(22, data.length);
                      uploadConfiguratorImage({
                        variables: {
                          name,
                          data,
                        },
                      }).then((data) => {
                        if (data?.data) {
                          downloadLink =
                            themeConfiguration.magento_url +
                            `productpdf/download/file/1?id=${id}&name=${name}&price_select=hidde&mark_up_value=&custom_price=` +
                            `&${customizationOptionsQueryParameters}` +
                            `&configurator_pdf_image=${data.data.uploadConfiguratorImage.configurator_image}&customer_token=${customer}`;

                          window.open(downloadLink, "_blank");
                        } else {
                          console.error("Something went wrong.");
                        }
                      });
                    } else {
                      console.error("Something went wrong.");
                    }
                  });
                }
              }
              event.preventDefault();
            }}
          >
            Tearsheet
          </MDBLink>
        </div>
      )}
    </ModalConsumer>
  );
};

export default DownloadTearsheet;
