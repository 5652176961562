import { isUndefined } from "core/helpers/functions";
import {
  MDBBox,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBMask,
  MDBTypography,
  MDBView,
  MDBBtn,
} from "mdbreact";
import Icon from "app/assets/icon/icon";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

function useClickOutsideCallback(ref, callBackFunction) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callBackFunction();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callBackFunction]);
}

// This is just test image - on dev its slider with hotspots
const CardComp = ({ isHome, dot, customer, toggleModal }) => {
  const [isVisible, setIsVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  let loggedIn = false;
  const wrapperRef = useRef(null);
  useClickOutsideCallback(wrapperRef, () => {
    setIsVisibleFn(true);
  });
  if (typeof customer !== "undefined") loggedIn = false;
  if (typeof customer === "object") {
    if ("token" in customer) {
      if (customer.token && customer.token !== null) {
        loggedIn = true;
      }
    }
  }

  let containerClassName = "cardContainer";

  if (dot.top > 45 && dot.left > 55) {
    containerClassName = "cardContainerBottomRight";
  }
  if (dot.top < 45 && dot.left > 55) {
    containerClassName = "cardContainerRight";
  }
  if (dot.top > 45 && dot.left < 55) {
    containerClassName = "cardContainerBottom";
  }

  const setIsVisibleFn = (overwriteVisibility) => {
    setIsVisible(overwriteVisibility ? false : !isVisible);
  };

  return (
    <div
      ref={wrapperRef}
      style={{
        position: "absolute",
        top: dot.top + "%",
        left: dot.left + "%",
      }}
    >
      {isHome ? (
        <>
          <MDBMask
            onClick={() => {
              setIsVisibleFn();
            }}
            className={`hotSpot ${
              (isVisible && "hotspot-active") || "hotspot-inactive"
            }`}
          >
            {dot.url ? (
              <Link
                to={"/" + dot.url}
                onClick={() => {
                  toggleModal(false);
                  if (document.body.classList.contains("hidden-overflow")) {
                    document.body.classList.remove("hidden-overflow");
                  }
                }}
              >
                {isVisible && (
                  <MDBCard className={`${containerClassName} hotspot-card`}>
                    {dot.display_type === "product" && (
                      <MDBCardImage
                        className="img-hotspotdetail img-fluid"
                        src={dot.image}
                        alt="hot spot picture"
                      />
                    )}
                    <MDBCardBody>
                      {dot.display_type === "product" && (
                        <>
                          <MDBCardTitle className="product-name text-dark">
                            {dot.sku}
                          </MDBCardTitle>
                          <MDBCardTitle className="product-name"></MDBCardTitle>
                          <MDBTypography
                            tag="h5"
                            className="text-gray-darken-3"
                          >
                            {dot.name}
                          </MDBTypography>
                        </>
                      )}
                      {dot.display_type === "text" && (
                        <MDBTypography tag="h5" className="text-gray-darken-3">
                          {dot.addInfo}
                        </MDBTypography>
                      )}
                    </MDBCardBody>
                  </MDBCard>
                )}
              </Link>
            ) : (
              <>
                {isVisible && (
                  <MDBCard className={`${containerClassName} hotspot-card`}>
                    {dot.display_type === "product" && (
                      <MDBCardImage
                        className="img-hotspotdetail img-fluid peraaaa"
                        src={dot.image}
                        alt="hot spot picture"
                      />
                    )}
                    <MDBCardBody>
                      {dot.display_type === "product" && (
                        <>
                          <MDBCardTitle className="product-name text-dark">
                            {dot.sku}
                          </MDBCardTitle>
                          <MDBCardTitle className="product-name"></MDBCardTitle>
                          <MDBTypography
                            tag="h5"
                            className="text-gray-darken-3"
                          >
                            {dot.name}
                          </MDBTypography>
                        </>
                      )}
                      {dot.display_type === "text" && (
                        <MDBTypography tag="h5" className="text-gray-darken-3">
                          {dot.addInfo}
                        </MDBTypography>
                      )}
                    </MDBCardBody>
                  </MDBCard>
                )}
              </>
            )}

            <MDBBox className={`hotspot-circle`} />
          </MDBMask>
        </>
      ) : (
        <MDBMask className="detailContainer">
          <MDBCardText>{dot.content}</MDBCardText>
        </MDBMask>
      )}
    </div>
  );
  // cardTitle and cardText are passed with GraphQL if its homepage
  // span is filled with price
};
const Hotspot = ({
  dots,
  contentArray,
  isHome,
  customer,
  toggleModal,
  DownloadImages = () => {},
  showMask = false,
}) => {
  let image = isUndefined(contentArray.image)
    ? contentArray.hotspot_image
    : contentArray.image;

  return (
    <div>
      <MDBBox className="hotspotContentContainer">
        <MDBView>
          <img src={image} className="img-slider w-100" alt="" />
          {showMask && (
            <div className="custom-mask">
              <MDBBtn
                className={"download-image-small-button float-right"}
                onClick={() => DownloadImages([image])}
              >
                <Icon icon="downloadWhite" className="test" />
              </MDBBtn>
            </div>
          )}
          {dots.map((dot, index) => {
            return (
              <div key={`card-comp-` + index}>
                <CardComp
                  index={index}
                  dot={dot}
                  dots={dots}
                  isHome={isHome}
                  isVisible={dot.isVisible}
                  customer={customer}
                  toggleModal={toggleModal}
                />
              </div>
            );
          })}
        </MDBView>
      </MDBBox>
    </div>
  );
};

export default Hotspot;
