import CmsBlock from "app/layout/cmsBlock";
import Toolbar from "../fabricPdp/fabricPdpToolbar";
import Image from "core/components/image";
import _ from "lodash";
import {
  MDBBox,
  MDBCol,
  MDBContainer,
  // MDBLink,
  MDBRow,
  // MDBTypography,
} from "mdbreact";
import React from "react";
// import { useMediaQuery } from "react-responsive";
// import Slider from "react-slick";
// import { sliderSettings } from "./sliderSetting";
import FinishesInfo from "./finishesInfo";

const FinishesPdp = (props) => {
  let {
    name,
    ffifinish_fairshield,
    finishesDetails,
    finishes_cleaning,
    about_fairshield_plus,
    small_image,
    productData,
    toggleFavorite,
    onToggleFavorite,
    calculateOptions,
    add_info,
  } = props;

  return (
    <>
      <MDBContainer className="my-5" fluid>
        <MDBRow className="no-gutters">
          <MDBCol style={{ marginBottom: "9rem" }}>
            <MDBContainer>
              <MDBRow>
                <MDBCol size="12" lg="6" className="pr-lg-5 pr-3">
                  <MDBBox>
                    {!_.isEmpty(productData?.image) && (
                      <Image
                        className="img-fluid w-100"
                        source={productData?.image.url}
                      />
                    )}
                  </MDBBox>
                </MDBCol>
                <FinishesInfo
                  finishesDetails={finishesDetails}
                  ffifinish_fairshield={ffifinish_fairshield}
                  add_info={add_info}
                  name={name}
                  finishes_cleaning={finishes_cleaning}
                  about_fairshield_plus={about_fairshield_plus}
                />
              </MDBRow>
            </MDBContainer>
          </MDBCol>
          <Toolbar
            {...productData}
            onToggleFavorite={onToggleFavorite}
            toggleFavorite={toggleFavorite}
            productData={productData}
            calculateOptions={calculateOptions}
          />
          <MDBCol size="12">
            <MDBContainer className="promo-container py-5">
              <MDBRow>
                <MDBCol>
                  <CmsBlock id="pdp-promo-1" />
                  <CmsBlock id="pdp-promo-2" />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCol>
          {/* <MDBCol>
            {!_.isEmpty(related_products) && (
              <MDBContainer className="related-container">
                <MDBRow>
                  <MDBCol size="12" className="mb-5">
                    <MDBTypography tag="h3">Related Finishes</MDBTypography>
                  </MDBCol>
                  {!isMobile ? (
                    related_products.slice(0, 4).map((item, index) => (
                      <MDBCol size="3" key={index} className="related-wrapper">
                        <MDBBox className="related-img">
                          <MDBLink to={item.url_key} className="p-0">
                            <Image
                              source={item.image.url}
                              className="img-fluid"
                            />
                          </MDBLink>
                        </MDBBox>
                        <MDBBox className="text-center related-info">
                          <MDBTypography className="py-4" tag="h4">
                            {item.name}
                          </MDBTypography>
                          <MDBBox className="h-50 border-bottom border-default w-100" />
                        </MDBBox>
                      </MDBCol>
                    ))
                  ) : (
                    <MDBCol size="12">
                      <Slider {...sliderSettings}>
                        {related_products.map((item, index) => (
                          <Image
                            key={index}
                            source={item.image.url}
                            className="img-fluid"
                          />
                        ))}
                      </Slider>
                    </MDBCol>
                  )}
                </MDBRow>
              </MDBContainer>
            )}
          </MDBCol> */}
        </MDBRow>
      </MDBContainer>
    </>
  );
};
export default FinishesPdp;
