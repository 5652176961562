const CLEAR_FILTERS = "jola/filter/CLEAR_FILTERS";
const ADD_FILTER = "jola/filter/ADD_FILTER";
const REMOVE_FILTER = "jola/filter/REMOVE_FILTER";
const ADD_FILTERED_DATA = "jola/filter/ADD_FILTERED_DATA";
const ADD_INITIAL_CATEGORY_DATA = "jola/filter/ADD_INITIAL_CATEGORY_DATA";
const CHANGE_SORT_FILTER = "jola/filter/CHANGE_SORT_FILTER";
const CHANGE_PAGE_NUMBER = "jola/filter/CHANGE_PAGE_NUMBER";
const REDUCE_PAGE_DATA = "jola/filter/REDUCE_PAGE_DATA";

export default {
  CLEAR_FILTERS,
  ADD_FILTER,
  REMOVE_FILTER,
  ADD_FILTERED_DATA,
  ADD_INITIAL_CATEGORY_DATA,
  CHANGE_SORT_FILTER,
  CHANGE_PAGE_NUMBER,
  REDUCE_PAGE_DATA
};
