import { CountryCodeEnum, CustomerAddress } from "app/generated/graphql";

export function hasStreet(
  street: CustomerAddress["street"]
  // * [string, ...string[]] was causing a syntax error
): street is string[] {
  return !!street?.length;
}

export function isCountryCode(code: string): code is CountryCodeEnum {
  return Object.values(CountryCodeEnum).includes(code as CountryCodeEnum);
}
