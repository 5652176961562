import { Collapse } from "app/layout/Collapse";
import Toolbar from "../fabricPdp/fabricPdpToolbar";
import Image from "core/components/image";
import _ from "lodash";
import {
  MDBBox,
  MDBCol,
  MDBContainer,
  MDBLink,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React from "react";
import Slider from "react-slick";
import MagicSliderDots from "react-magic-slider-dots";

const CustomPdp = (props) => {
  let {
    image,
    name,
    related_products,
    // crosssell_products,
    custom_options_finish,
    custom_options_type,
    custom_option_other,
    small_image,
    productData,
    onToggleFavorite,
    toggleFavorite,
    calculateOptions,
  } = props;

  let options = [
    { label: "Type", value: custom_options_type },
    { label: "Finish", value: custom_options_finish },
    { label: "Additional Information", value: custom_option_other },
  ];

  let sliderSettings = {
    className: "",
    centerMode: false,
    infinite: true,
    centerPadding: "5px",
    slidesToShow: 4,
    slidesToScroll: 1,
    controls: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 3000,
    dots: true,
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />;
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          appendDots: (dots) => {
            return (
              <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />
            );
          },
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          appendDots: (dots) => {
            return (
              <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />
            );
          },
        },
      },
    ],
  };

  return (
    <>
      <MDBContainer className="custom-container" fluid>
        <MDBRow>
          <MDBCol style={{ marginBottom: "9rem" }}>
            <MDBContainer>
              <MDBRow>
                <MDBCol size="12" lg="6">
                  <MDBBox>
                    {!_.isEmpty(image) && (
                      <Image className="img-fluid w-100" source={image.url} />
                    )}
                  </MDBBox>
                </MDBCol>
                <MDBCol size="12" lg="6" className="px-lg-5 px-0 mt-lg-0 mt-5">
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol size="12" className="my-3">
                        <MDBBox className="my-4">
                          <MDBTypography tag="h1">{name}</MDBTypography>
                        </MDBBox>
                        <Collapse
                          className="my-2"
                          title="Overall"
                          isOpen={true}
                        >
                          <MDBContainer className="my-4">
                            <MDBRow>
                              {options.map((option, index) => (
                                <React.Fragment key={index}>
                                  <MDBCol size="6" className="mb-3">
                                    <MDBTypography
                                      tag="h2"
                                      className="body-1 text-gray-darken-3"
                                    >
                                      {option.value !== null
                                        ? option.label
                                        : false}
                                    </MDBTypography>
                                  </MDBCol>
                                  <MDBCol size="6">
                                    <MDBTypography
                                      tag="h2"
                                      className="body-1 text-primary"
                                    >
                                      {option.value}
                                    </MDBTypography>
                                  </MDBCol>
                                </React.Fragment>
                              ))}
                            </MDBRow>
                          </MDBContainer>
                        </Collapse>
                      </MDBCol>
                      {/* <MDBCol size="12">
                        {!_.isEmpty(crosssell_products) && (
                          <>
                            <MDBTypography tag="h3" className="mb-4">
                              Also Available in
                            </MDBTypography>
                            <MDBContainer>
                              <MDBRow>
                                {crosssell_products
                                  .slice(0, 3)
                                  .map((item, index) => (
                                    <MDBCol
                                      size="2"
                                      key={index}
                                      className="p-0"
                                    >
                                      <MDBLink to={item.url_key}>
                                        <Image
                                          className="custom-related text-left img-fluid"
                                          source={item.image.url}
                                        />
                                      </MDBLink>
                                    </MDBCol>
                                  ))}
                              </MDBRow>
                            </MDBContainer>
                          </>
                        )}
                      </MDBCol> */}
                    </MDBRow>
                  </MDBContainer>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCol>
          <MDBCol size="12">
            <Toolbar
              {...productData}
              onToggleFavorite={onToggleFavorite}
              toggleFavorite={toggleFavorite}
              productData={productData}
              calculateOptions={calculateOptions}
            />
          </MDBCol>
          <MDBCol>
            {/* {!_.isEmpty(related_products) && (
              <MDBContainer className="related-container">
                <MDBRow>
                  <MDBCol size="12" className="mb-5">
                    <MDBTypography tag="h3">Related Options</MDBTypography>
                  </MDBCol>
                  <MDBCol size="12">
                    <Slider {...sliderSettings}>
                      {related_products.map((item, index) => (
                        <MDBCol key={index} className="related-wrapper">
                          <MDBLink to={item.url_key} className="p-0">
                            <MDBBox className="related-img">
                              <Image
                                source={item.small_image.url}
                                className="img-fluid"
                              />
                            </MDBBox>
                            <MDBBox className="text-center related-info">
                              <MDBTypography className="py-4" tag="h4">
                                {item.name}
                              </MDBTypography>
                              <MDBBox className="h-50 border-bottom border-default w-100" />
                            </MDBBox>
                          </MDBLink>
                        </MDBCol>
                      ))}
                    </Slider>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            )} */}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};
export default CustomPdp;
