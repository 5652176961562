import Icon from "app/assets/icon/icon";
import CmsBlock from "app/layout/cmsBlock";
import Label from "app/layout/Label";
import _ from "lodash";
import Select from "app/layout/Select";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBTypography,
  MDBBox,
} from "mdbreact";
import React from "react";
import FilesByTag from "./components/FilesByTag";
import { FilesByTagProps } from "./components/FilesByTag/FilesByTag";
import Filter from "./components/Filter";
import { FilterProps } from "./components/Filter/Filter";
import { divisionOptions } from "./config";
import {
  Division,
  FilesAndTagByTagId,
  SortOrdersByTag,
  TagId,
  TagsByCategory,
  FileTypes,
} from "./models";
import useCustomer from "core/state/hooks/customerHooks/useCustomer";
import { Redirect } from "react-router-dom";

export interface ResourcesProps
  extends Required<
      Pick<React.ComponentProps<typeof MDBModal>, "isOpen" | "toggle">
    >,
    Pick<FilterProps, "selectedTags" | "onToggleTag">,
    Pick<
      FilesByTagProps,
      "selectedFiles" | "onToggleFileById" | "onDownloadFile"
    > {
  onDownloadAll: ([]) => void;
  onSelectAllTags: (category: any) => void;
  onSelectAllFiles: () => void;
  filesAndTagByTagId: FilesAndTagByTagId;
  tagsByCategory: TagsByCategory;
  division: Division;
  fileTypes: FileTypes[];
  fileTypesItem: FileTypes;
  setFileTypesItem: (file_type: FileTypes["documents"]) => void;
  onDivisionChange: (division: Division) => void;
  sortOrdersByTag: SortOrdersByTag;
  onSortOrderByTagChange: (
    tagId: TagId
  ) => FilesByTagProps["onSortOrderChange"];
  onClearAll: (category: any) => void;
  fileData: any;
  selectedCategory: string;
}

const Resources: React.FC<ResourcesProps> = (props) => {
  const divisionOption = React.useMemo(
    () => divisionOptions.find((option) => option.value === props.division),
    [props.division]
  );
  let [, isLoggedIn] = useCustomer();
  const loggedIn = isLoggedIn();

  if (!loggedIn) return <Redirect to="/" />;

  //@ts-ignore
  const CustomSelectOption = (props) => {
    const { innerProps, innerRef } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={`select-custom-option react-select__option ${
          props.isSelected === true && "react-select__option--is-selected"
        }`}
      >
        {/* <Icon icon={props.data.icon} className={`size-20 mr-2`} /> */}
        {props.data.label}
      </div>
    );
  };
  const filters = Object.entries(props.tagsByCategory)
    .filter(([category, _]) => {
      let count = 0;
      Object.entries(props.filesAndTagByTagId).forEach((tag: any) => {
        let specificFile = tag[1] as any;
        if (
          specificFile.files.some((file: any) => {
            //@ts-ignore
            return file.file_type[props?.fileTypesItem];
          })
        ) {
          count++;
        }
      });
      return count;
    })
    .map(([category, tags], i, a) => {
      // const handleSelectAllCategoryTags = () => props.onSelectAllTags(category);
      return (
        <Filter
          key={category}
          selectedTags={props.selectedTags}
          onToggleTag={props.onToggleTag}
          tagCategory={category}
          tags={tags}
          //@ts-ignore
          selectedTab={props?.fileTypesItem}
          allFiles={props.filesAndTagByTagId}
          hasDivider={i !== a.length - 1}
          selectedCategory={props.selectedCategory}
        />
      );
    });
  return (
    <div className="resources-main-container">
      <div className="resources-banner-block">
        <CmsBlock id={"resources_main_image"} />
      </div>
      <div className="mt-5 pt-4 mb-2" />
      <MDBContainer className={`mb-5`}>
        <CmsBlock id={"resources_text"} />
      </MDBContainer>
      <MDBContainer fluid>
        <MDBRow className={`bg-gray-lighten-4`}>
          <MDBCol size="12">
            <MDBContainer className={`resources-tabs`}>
              {props.fileTypes.map((item, index) => {
                return (
                  <MDBBox
                    //@ts-ignore
                    onClick={() => {
                      //@ts-ignore
                      props.setFileTypesItem(props.fileTypes[index]);
                      //@ts-ignore
                      props.onClearAll();
                    }}
                    className={`d-inline-flex align-items-center pt-4 resources-tab 
                    pb-3 cursor-pointer text-capitalize position-relative ${
                      (props.fileTypesItem === props.fileTypes[index] &&
                        "active") ||
                      "inactive"
                    }`}
                  >
                    <MDBTypography tag="h2">
                      {
                        //@ts-ignore
                        props.fileTypes?.[index]
                      }
                    </MDBTypography>
                  </MDBBox>
                );
              })}
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer>
        {(filters.length === 0 && (
          <MDBRow className="mt-5 pt-3">
            <MDBCol>
              <MDBTypography tag="h4" className={`fwregular text-default mb-5`}>
                There are no files for selected category
              </MDBTypography>
            </MDBCol>
          </MDBRow>
        )) || (
          <>
            <MDBRow className="mt-5">
              <MDBCol className="col-lg-3 col-12 mb-5 pb-lg-5 d-flex flex-column">
                <MDBBox className="mb-lg-5 mb-4 select-industry">
                  <Label required={false} text="Select Industry">
                    <Select
                      value={divisionOption}
                      options={divisionOptions}
                      components={{ Option: CustomSelectOption }}
                      isSearchable={false}
                      //@ts-ignore
                      onChange={(option) => {
                        props?.onDivisionChange(option.value);
                      }}
                      //useCallback removed because of condition above
                      // onChange={React.useCallback(
                      //   (option) => props?.onDivisionChange(option.value),
                      //   [props]
                      // )}
                    />
                  </Label>
                </MDBBox>
                <MDBBtn
                  color="light-blue"
                  className="m-0 w-100 d-block d-lg-none"
                  onClick={props.toggle}
                >
                  Filter Resources
                </MDBBtn>
                <div className="w-100 d-lg-block d-none bg-gray-lighten-4 py-4 px-4 align-self-start resources-sidebar">
                  <MDBBox className={`d-flex justify-content-between`}>
                    <MDBTypography className="fwmedium text-gray-darken-3 fs-16 m-0">
                      Refine Categories
                    </MDBTypography>
                    <div
                      onClick={props.onSelectAllTags}
                      onKeyUp={props.onSelectAllTags}
                      className="cursor-pointer mb-0 d-flex justify-content-end"
                      tabIndex={0}
                      role="button"
                    >
                      <MDBTypography className="link-2 fwregular m-0">
                        Select All
                      </MDBTypography>
                    </div>
                  </MDBBox>
                  {filters}
                  <div
                    onClick={props.onClearAll}
                    onKeyUp={props.onClearAll}
                    className="cursor-pointer mb-0 d-flex justify-content-end"
                    tabIndex={0}
                    role="button"
                  >
                    <MDBTypography className="link-2 fwregular">
                      Deselect All
                    </MDBTypography>
                  </div>
                </div>
              </MDBCol>
              <MDBCol className="col-12 col-lg-9">
                {Object.entries(props.filesAndTagByTagId).map(
                  ([tagId, filesAndTag]) => (
                    <FilesByTag
                      key={tagId}
                      filesAndTag={filesAndTag}
                      onDownloadFile={props.onDownloadFile}
                      selectedFiles={props.selectedFiles}
                      onToggleFileById={props.onToggleFileById}
                      sortOrders={props.sortOrdersByTag[tagId]}
                      onSortOrderChange={props.onSortOrderByTagChange(tagId)}
                      fileTypesItem={props.fileTypesItem}
                      onDownloadAll={props.onDownloadAll}
                    />
                  )
                )}
              </MDBCol>
            </MDBRow>
          </>
        )}
      </MDBContainer>
      <MDBModal
        inline={false}
        noClickableBodyWithoutBackdrop
        overflowScroll
        isOpen={props.isOpen}
        toggle={props.toggle}
        className="h-100 m-0 ml-auto"
        backdropClassName="d-none"
        contentClassName="h-100"
      >
        <MDBModalBody className="p-0 h-100 d-flex flex-column">
          <MDBRow>
            <MDBCol>
              <div className="bg-default py-4 position-relative d-flex align-items-center justify-content-center">
                <div
                  className="position-absolute left-0 ml-4 pl-2 d-flex align-items-center cursor-pointer"
                  onClick={props.toggle}
                  onKeyUp={props.toggle}
                  tabIndex={0}
                  role="button"
                >
                  <Icon icon="leftMobileWhite" />
                  <MDBTypography className="mb-0 ml-4 text-white fs-16">
                    Back
                  </MDBTypography>
                </div>
                <MDBTypography className="mb-0 fs-24 text-white text-center">
                  Filter By
                </MDBTypography>
              </div>
            </MDBCol>
          </MDBRow>
          <div className="grow overflow-y-auto my-4 py-3 mx-4 px-2">
            <MDBBox className={`d-flex justify-content-between`}>
              <MDBTypography className="fwmedium text-gray-darken-3 fs-16 m-0">
                Refine Categories
              </MDBTypography>
              <div
                onClick={props.onSelectAllTags}
                onKeyUp={props.onSelectAllTags}
                className="cursor-pointer mb-0 d-flex justify-content-end"
                tabIndex={0}
                role="button"
              >
                <MDBTypography className="link-2 fwregular m-0">
                  Select All
                </MDBTypography>
              </div>
            </MDBBox>
            {filters}
          </div>
          <div
            className="py-5 px-4 d-flex justify-content-center"
            style={{
              boxShadow: "0px -1px 5px #aaa",
            }}
          >
            <MDBBtn
              onClick={props.onClearAll}
              color="secondary"
              className="m-0"
            >
              Clear All
            </MDBBtn>
            <MDBBtn
              className="m-0 ml-3"
              onClick={(e) => {
                props.toggle();
              }}
            >
              Save Selection
            </MDBBtn>
          </div>
        </MDBModalBody>
      </MDBModal>
    </div>
  );
};

export default Resources;
