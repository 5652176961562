// @ts-check

import qs from "qs";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Login from "./Login";

/**
 *
 * @typedef {Pick<import('./Login').LoginProps, 'isLoggedIn' | 'login'> & { hideModal: (isLoggedIn: boolean) => void; }} LoginStateContainerProps
 *
 * @type {React.FC<LoginStateContainerProps>}
 */
const LoginDataContainer = (props) => {
  // const token = useSelector(selectCustomerToken);
  // @ts-ignore

  const location = useLocation();

  const [isOpen, setIsOpen] = useState(true);
  const [userData, setUserData] = useState({
    email: "",
    firstName: "",
  });
  const toggleIsOpen = React.useCallback((
    /** @type {boolean} */ isLoggedIn
  ) => {
    setIsOpen((value) => !value);
    props.hideModal(isLoggedIn);
  }, []);

  const query = React.useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location.search]
  );

  React.useEffect(() => {
    return () => setUserData({ email: "", firstName: "" });
  }, []);

  const isExpress = React.useMemo(() => {
    return typeof query.setup === "string" ? query.setup === "express" : null;
  }, [query]);

  /**
   * @type {import('./Login').LoginProps['setup']}
   */
  // @ts-ignore
  const setup = React.useMemo(
    () =>
      userData?.email && userData?.firstName
        ? { type: "password", payload: userData }
        : isExpress
        ? { type: "express" }
        : { type: "emailAndPassword" },
    [isExpress, userData]
  );

  const onClose = React.useCallback(() => {
    toggleIsOpen(false);
  }, [toggleIsOpen]);

  return (
    <Login
      {...props}
      userData={userData}
      setUserData={setUserData}
      isOpen={isOpen}
      onClose={onClose}
      setup={setup}
    />
  );
};

export default LoginDataContainer;
