import { Customer } from "app/generated/graphql";
import { passwordSchema } from "app/pages/customer/config";
import * as yup from "yup";

interface ChangePasswordValues {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface ChangeEmailValues {
  currentPassword: string;
  email: string;
}

export interface AccountInfoValues
  extends Pick<Customer, "firstname" | "lastname"> {
  changePassword: null | ChangePasswordValues;
  changeEmail: null | ChangeEmailValues;
}

export const initialChangePasswordValues: ChangePasswordValues = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export const initialChangeEmailValues: ChangeEmailValues = {
  currentPassword: "",
  email: "",
};

export const initialAccountInfoValues: AccountInfoValues = {
  firstname: "",
  lastname: "",
  changePassword: null,
  changeEmail: null,
};

export const accountInfoSchema = yup
  .object()
  .required()
  .shape<AccountInfoValues>({
    firstname: yup.string().required("First name is required."),
    lastname: yup.string().required("Last name is required."),
    changeEmail: yup
      .object()
      .defined()
      .nullable()
      .shape<ChangeEmailValues>({
        currentPassword: passwordSchema,
        email: yup
          .string()
          .email("Invalid email address.")
          .required(`Email is required.`),
      }),
    changePassword: yup
      .object()
      .defined()
      .nullable()
      .shape<ChangePasswordValues>({
        currentPassword: passwordSchema,
        newPassword: passwordSchema,
        confirmPassword: yup
          .string()
          .required("Please confirm your password.")
          .oneOf([yup.ref("newPassword")], "Passwords must match."),
      }),
  });
