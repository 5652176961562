import React, { useState } from "react";
import { useWishlist } from "core/state/hooks/wishlistHooks/wishlistHookAddRemove";
import {
  MDBBox,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBLink,
} from "mdbreact";
import Image from "core/components/image/image";
import CreateWishlistCore from "../createWishlistCore";
import InputField from "core/components/inputField/inputField";
import { Link } from "react-router-dom";

const ItemsWishlist = (props) => {
  const [
    ,
    ,
    fetchWishlistData,
    getWishlistItems,
    ,
    editWishlist,
    ,
    ,
    ,
    deleteWishlist,
  ] = useWishlist();
  let {
    project,
    setProject,
    setCurrentSelectedProject,
    currentSelectedProject,
  } = props;
  const [currentIndex] = useState(0);
  const [toggleEdit, setToggleEdit] = useState(false);

  const toggleEditFunc = () => {
    setToggleEdit(!toggleEdit);
  };
  let myWishlistsArr = getWishlistItems();
  fetchWishlistData();
  if (typeof myWishlistsArr[currentIndex] !== "undefined")
    props.setActiveWishlistName(myWishlistsArr[currentIndex].name);
  return (
    <>
      <MDBBox
        className={
          "flex-item flex-wrap wishlist-listing d-flex flex-row justify-content-between"
        }
      >
        {myWishlistsArr.length > 0 &&
          myWishlistsArr.map((item, index) => {
            return (
              <MDBContainer className={"wishlist-list-item list-item "}>
                <MDBRow>
                  <MDBCol>
                    <MDBLink
                      to={`/customer/wishlist/edit?wishlist=${item.id}`}
                      className="d-flex flex-wrap"
                    >
                      {item.items.map((fItem, index) => {
                        return (
                          <>
                            {index <= 3 && (
                              <div
                                style={{ flex: "0 0 50%", cursor: "pointer" }}
                                className={"list-names"}
                              >
                                <Image source={fItem.product.image.url} />
                              </div>
                            )}
                          </>
                        );
                      })}
                    </MDBLink>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBBox>
                      <MDBLink
                        className="wishlist-btn text-center m-4"
                        // onClick={(e) => {
                        //   setCurrentIndex(index);

                        // }}
                        to={`/customer/wishlist/edit?wishlist=${item.id}`}
                      >
                        {item.name}
                      </MDBLink>
                    </MDBBox>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBBox className="text-center">
                      {item.items_count > 1
                        ? `${item.items_count} item(s)`
                        : `${item.items_count} item`}
                    </MDBBox>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBBox className="text-center my-4">
                      <Link
                        to="#"
                        className="wishlist-btn edit-btn"
                        onClick={(e) => {
                          setCurrentSelectedProject(myWishlistsArr[index].name);
                          setProject(myWishlistsArr[index].name);
                          toggleEditFunc();
                        }}
                      >
                        EDIT
                      </Link>
                    </MDBBox>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            );
          })}
        <MDBBox className={"wishlist-list-item list-item"}>
          <CreateWishlistCore {...props} />
        </MDBBox>
      </MDBBox>
      {/* 
      <MDBBox>
        {typeof myWishlistsArr[currentIndex] !== "undefined" ? (
          myWishlistsArr[currentIndex].items.length > 0 &&
          myWishlistsArr[currentIndex].items.map((item) => {
            return (
              <Item
                item={item}
                activeWishlistName={myWishlistsArr[currentIndex].name}
                allWishlists={myWishlistsArr}
              />
            );
          })
        ) : (
          <></>
        )}
      </MDBBox> */}
      <MDBBox>
        <MDBModal
          isOpen={toggleEdit}
          toggle={toggleEditFunc}
          className={"modal-wrapper wishlist-wrapper edit-wishlist-modal"}
          centered
          project={project}
        >
          <MDBModalHeader>
            <div className="close-btn">
              <button onClick={toggleEditFunc} type="button">
                <i className="close-icon"></i>
              </button>
            </div>
          </MDBModalHeader>
          <MDBModalBody>
            <InputField
              className={"wishlist-input"}
              label={
                typeof project !== "undefined" && project.length === 0
                  ? "edit project"
                  : "project name"
              }
              group
              type="text"
              value={project}
              onChange={(e) => {
                if (e.target.value.length <= 24) {
                  setProject(e.target.value);
                }
              }}
            />
            <MDBBox className="d-flex  flex-wrap justify-content-between edit-wishlist-modal-content">
              <MDBBtn
                className={"wishlist-btn edit one"}
                onClick={(e) => {
                  let request = {
                    fromName: currentSelectedProject,
                    toName: project,
                  };
                  editWishlist(request);
                  toggleEditFunc();
                }}
              >
                Edit wishlist
              </MDBBtn>
              <MDBBtn
                className="wishlist-btn edit two"
                onClick={(e) => {
                  deleteWishlist(currentSelectedProject);
                  toggleEditFunc();
                }}
              >
                Delete Wishlist
              </MDBBtn>
            </MDBBox>
          </MDBModalBody>
        </MDBModal>
      </MDBBox>
    </>
  );
};

export default ItemsWishlist;
