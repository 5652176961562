import { ModalConsumer } from "core/components/modal/ModalContext";
import { MDBBox, MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React, { useContext, useCallback } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { FinishSidebar } from "../finishSidebar";
import { CheckBoxContext } from "../checkboxContext";
import { CustomPriceContext } from "../customPriceContext";

export const NailHeadOptions = ({
  current,
  customizationOptions,
  setCustomizationOption,
  option = { title: "", value: [{ title: "", option_type_id: 1 }] },
  values = [],
  allowedFinishes,
  productImage,
  defaults,
  setFabricOrFinishSelectedOptions,
  optionClassNames,
  nailData,
  frame,
  isCustom,
  categories,
  customer,
  // queryPathFabrics,
}) => {
  let sidebarProps = {
    customizationOptions,
    setCustomizationOption,
    fabrics: values,
    current,
    option,
    setFabricOrFinishSelectedOptions,
    defaults,
    categories,
    allowedFinishes,
    customer,
  };
  const { initSelectedFinish } = useContext(CustomPriceContext);

  const {
    // checkBoxOptions,
    // setCheckBoxOptions,
    // viewMoreOptions,
    // setViewMoreOptions,
    selectedFinish,
    // setSelectedFinish,
  } = useContext(CheckBoxContext);

  let selectedFinishUpdated =
    Object.keys(selectedFinish).length === 0
      ? initSelectedFinish
      : selectedFinish;

  if (
    Object.keys(selectedFinish).length > 0 &&
    Object.keys(initSelectedFinish).length > 0
  ) {
    Object.keys(initSelectedFinish).map((key) => {
      if (!(key in selectedFinish)) {
        selectedFinishUpdated[key] = initSelectedFinish[key];
      }
    });
  }

  let displayCurrent = null;
  if (current && current.finish) {
    displayCurrent = current.finish;
  } else {
    displayCurrent = defaults && defaults.defaultFinish;
  }

  let setPlayerOption = () => {};

  if (nailData) {
    setPlayerOption = nailData.setNailFinish;
  }

  if (typeof displayCurrent !== "undefined") {
    setPlayerOption(current);
  }

  return (
    <ModalConsumer value={{ useWrapper: false }}>
      {({ showModal, hideModal }) => {
        return (
          <MDBRow
            className={`w-100 height-40 nailhead-options border rounded d-flex align-items-center ${optionClassNames}`.trim()}
            onClick={(e) => {
              e.preventDefault();
              showModal(() => {
                return (
                  <RemoveScroll>
                    <FinishSidebar
                      hideModal={hideModal}
                      {...sidebarProps}
                      frame={frame}
                      allowedFinishes={allowedFinishes}
                      productImage={productImage}
                      isCustom={isCustom}
                      nailData={nailData}
                      customizationOptions={customizationOptions}
                    />
                  </RemoveScroll>
                );
              });
            }}
          >
            {selectedFinishUpdated?.[
              option.title.toLowerCase().split(" ").join("_")
            ] && (
              <>
                <MDBCol size="1">
                  <MDBBox
                    className="border rounded border-default"
                    style={{
                      width: "25px",
                      height: "25px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      className="img-fluid"
                      src={
                        selectedFinishUpdated?.[
                          option.title.toLowerCase().split(" ").join("_")
                        ]?.thumbnail?.url ||
                        selectedFinishUpdated?.[
                          option.title.toLowerCase().split(" ").join("_")
                        ]?.map
                      }
                      alt={
                        selectedFinishUpdated?.[
                          option.title.toLowerCase().split(" ").join("_")
                        ]?.name
                      }
                    />
                  </MDBBox>
                </MDBCol>
                <MDBCol size="6">
                  <MDBTypography tag="p" className="m-0 body-6">
                    {
                      selectedFinishUpdated[
                        option.title.toLowerCase().split(" ").join("_")
                      ].name
                    }
                  </MDBTypography>
                </MDBCol>
              </>
            )}
            {!selectedFinishUpdated?.[
              option.title.toLowerCase().split(" ").join("_")
            ] && (
              <MDBCol>
                <MDBTypography tag="p" className="m-0 body-6">
                  Please select a {option.title}
                </MDBTypography>
              </MDBCol>
            )}
          </MDBRow>
        );
      }}
    </ModalConsumer>
  );
};
