import React, { useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

export const GALLERY_CATEGORY_DATA = gql`
  query($category_id: String) {
    getGalleryCategory(category_id: $category_id) {
      name
      description
      galleries {
        id
        name
        image
        description
        banner_image
      }
    }
  }
`;

function useVirtualDesignChallengeHook() {
  const { data: vdcGalleryData } = useQuery(GALLERY_CATEGORY_DATA, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    variables: {
      category_id: "6",
    },
  });

  const { data: virtualDesignersGalleryData } = useQuery(
    GALLERY_CATEGORY_DATA,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-only",
      variables: {
        category_id: "7",
      },
    }
  );

  const { data: virtualDesignChallengeWinners2022GalleryData } = useQuery(
    GALLERY_CATEGORY_DATA,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-only",
      variables: {
        category_id: "8",
      },
    }
  );

  const { data: virtualDesignChallengeWinners2021GalleryData } = useQuery(
    GALLERY_CATEGORY_DATA,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-only",
      variables: {
        category_id: "9",
      },
    }
  );

  const vdcGalleryItems = useMemo(() => {
    let items = vdcGalleryData?.getGalleryCategory?.galleries;
    if (!items?.length) return [];
    let mobileItem = items?.find((item) => item?.name?.includes("mobile"));
    items = items?.filter((item) => !item?.name?.includes("mobile"));
    items.unshift(mobileItem);
    return items;
  }, [vdcGalleryData?.getGalleryCategory?.galleries]);

  const virtualDesignersGalleryDataItems = useMemo(
    () => virtualDesignersGalleryData?.getGalleryCategory?.galleries,
    [virtualDesignersGalleryData?.getGalleryCategory?.galleries]
  );

  const virtualDesignChallengeWinners2022GalleryDataItems = useMemo(
    () =>
      virtualDesignChallengeWinners2022GalleryData?.getGalleryCategory
        ?.galleries,
    [
      virtualDesignChallengeWinners2022GalleryData?.getGalleryCategory
        ?.galleries,
    ]
  );

  const virtualDesignChallengeWinners2021GalleryDataItems = useMemo(
    () =>
      virtualDesignChallengeWinners2021GalleryData?.getGalleryCategory
        ?.galleries,
    [
      virtualDesignChallengeWinners2021GalleryData?.getGalleryCategory
        ?.galleries,
    ]
  );

  return {
    vdcGalleryItems,
    virtualDesignersGalleryDataItems,
    virtualDesignChallengeWinners2022GalleryDataItems,
    virtualDesignChallengeWinners2021GalleryDataItems,
  };
}

export default useVirtualDesignChallengeHook;
