import apolloClient from "core/graphql/apolloClient";
import customerActions from "../actions";
import queryLoader from "core/graphql/queryLoader";
import messagesActions from "../../messages/actions";
import commonActions from "../../common/actions";

const CREATE_CUSTOMER_ADDRESS = "createCustomerAddress";

export default async (store, action) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: queryLoader(CREATE_CUSTOMER_ADDRESS),
      variables: { input: action.request },
    });

    if (data && data.createCustomerAddress) {
      // let token = store.getState().state.customer.token;
      await store
        .dispatch(
          customerActions._reduceCustomerAddress(data.createCustomerAddress)
        )
        .then(
          store.dispatch(
            messagesActions.addMessage(
              "Successfully created new address.",
              "success"
            )
          )
        )
        .then(store.dispatch(commonActions.unlock("success")));
    }
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock("fail"));
    return;
  }
};
