import { storage } from "config/themeConfiguration";

export const loadState = (key) => {
  if (!key) key = storage.local_storage_key;
  return JSON.parse(localStorage.getItem(key));
};

export const saveState = (state, key = storage.local_storage_key) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch {
    // ignore write errors
  }
};
