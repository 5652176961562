// @ts-check
import React, { useEffect } from "react";
// @ts-ignore
import { Link, useHistory } from "react-router-dom";
import { MDBCol, MDBLink, MDBRow, MDBTypography, MDBBox } from "mdbreact";
import { ModalConsumer } from "core/components/modal/ModalContext";
import Icon from "app/assets/icon/icon";
import Login from "app/pages/customer/login";
import { createEnterHandler } from "app/utils/utils";
import ModalContext from "core/components/modal/ModalContext";
import Logo from "app/layout/logo";
/**
 *
 * @typedef {{ isLoggedIn: () => boolean, onAccountClick: React.MouseEventHandler, setActiveMenu: function, activeMenu: string,  rest: import('app/pages/customer/login/ReduxContainer').LoginReduxContainerProps }} HeaderTopProps
 *
 * @param {HeaderTopProps} props
 */
const HeaderTop = ({
  isLoggedIn,
  onAccountClick,
  setActiveMenu,
  activeMenu,
  ...rest
}) => {
  let loggedIn = isLoggedIn();
  const history = useHistory();
  const { hideModal } = React.useContext(ModalContext);

  useEffect(() => {
    if (!loggedIn) hideModal();
  }, [loggedIn]);

  return (
    <MDBRow className="header-top px-5">
      {/* <MDBCol className="ml-5 d-flex align-items-center ">
        <Icon icon="globe" />
        <Link to="/where-to-buy">
          <MDBTypography
            tag="h2"
            className="text-uppercase ml-3 d-inline text-default"
            // @ts-ignore
            variant="display-5"
          >
            Where to buy
          </MDBTypography>
        </Link>
      </MDBCol> */}
      <MDBCol className="ml-5 d-flex align-items-center ">
        <div
          className={`top-toggle-menu ${
            activeMenu === "RESIDENTIAL" ? "top-toggle-menu-active" : ""
          }`}
          onClick={() => {
            activeMenu === "RESIDENTIAL"
              ? setActiveMenu("")
              : setActiveMenu("RESIDENTIAL");
            history.push("/residential");
          }}
        >
          <MDBTypography
            // @ts-ignore
            variant="display-5"
            tag="h2"
            className="text-uppercase d-inline text-default cursor-pointer"
          >
            RESIDENTIAL
          </MDBTypography>
        </div>
        <div
          className={`top-toggle-menu ${
            activeMenu === "COMMERCIAL" ? "top-toggle-menu-active" : ""
          }`}
          onClick={() => {
            activeMenu === "COMMERCIAL"
              ? setActiveMenu("")
              : setActiveMenu("COMMERCIAL");
            history.push("/commercial");
          }}
        >
          <MDBTypography
            // @ts-ignore
            variant="display-5"
            tag="h2"
            className="text-uppercase d-inline text-default cursor-pointer"
          >
            Commercial / Senior Living
          </MDBTypography>
        </div>
      </MDBCol>
      <Logo setActiveMenu={setActiveMenu} />
      <MDBCol className="mr-5 d-flex align-items-center justify-content-end">
        <>
          {(loggedIn && (
            <MDBLink
              to={`/resources`}
              className={`d-flex align-items-center pl-4 pr-4`}
            >
              <Icon icon="resource_icon" />
              <MDBTypography
                style={{ cursor: "pointer" }}
                tag="h2"
                className="text-uppercase ml-3 d-inline text-default"
                // @ts-ignore
                variant="display-5"
              >
                Resources
              </MDBTypography>
            </MDBLink>
          )) || <></>}
          {/* <MDBLink
            to={`/contact`}
            className={`d-flex align-items-center pl-4 pr-4`}
          >
            <Icon icon="customerServiceIcon" />
            <MDBTypography
              style={{ cursor: "pointer" }}
              tag="h2"
              className="text-uppercase ml-3 d-inline text-default"
              // @ts-ignore
              variant="display-5"
            >
              Contact Us
            </MDBTypography>
          </MDBLink> */}
          {(!loggedIn && (
            <MDBBox
              className={`d-flex align-items-center pl-4 pr-4 login-register`}
            >
              <Icon icon="login_lock" />
              <ModalConsumer>
                {({ showModal, hideModal }) => {
                  return (
                    <MDBTypography
                      style={{ cursor: "pointer" }}
                      tag="h2"
                      className="text-uppercase ml-3 d-inline text-default"
                      // @ts-ignore
                      variant="display-5"
                      onClick={(/** @type {React.MouseEvent} */ e) => {
                        e.preventDefault();
                        // @ts-ignore
                        // @ts-ignore
                        showModal((e) => (
                          <Login {...rest} hideModal={hideModal} />
                        ));
                      }}
                    >
                      Login/Register
                    </MDBTypography>
                  );
                }}
              </ModalConsumer>
            </MDBBox>
          )) || (
            <div
              className="d-flex align-items-center ml-4"
              onClick={onAccountClick}
              onKeyUp={createEnterHandler(onAccountClick)}
              role="button"
              tabIndex={0}
            >
              <Icon icon="account1" />
              <MDBTypography
                style={{ cursor: "pointer" }}
                tag="h2"
                className="text-uppercase ml-3 d-inline text-default"
                // @ts-ignore
                variant="display-5"
              >
                My Account
              </MDBTypography>
            </div>
          )}
        </>
      </MDBCol>
    </MDBRow>
  );
};

export default HeaderTop;
