import React from "react";
import { MDBCol } from "mdbreact";

const MultiSelectFilterItem = (props) => {
  let {
    filter,
    selectedFilters,
    setSelectedFilters,
    openFilterOptionsContainer,
    setOpenFilterOptionsContainer,
  } = props;

  return (
    <div
      className={`multi-select-filter-container ${
        openFilterOptionsContainer && "active"
      }`}
    >
      <MDBCol
        size="12"
        className={`multi-select-filter-item ${
          openFilterOptionsContainer &&
          selectedFilters === filter.attribute_code &&
          "selected-filter"
        }`}
        onClick={() => {
          if (
            openFilterOptionsContainer &&
            selectedFilters === filter.attribute_code
          ) {
            setOpenFilterOptionsContainer(false);
            setSelectedFilters(false);
          } else {
            setOpenFilterOptionsContainer(true);
            setSelectedFilters(filter.attribute_code);
          }
        }}
      >
        <p>
          {filter.label.includes("category1")
            ? "Pattern"
            : filter.label.includes("grade")
            ? "Grade"
            : filter.label.includes("collection")
            ? "Collection"
            : filter.label.includes("hexclrgrp")
            ? "Color"
            : filter.label}
          <span className="toggle-arrow"></span>
        </p>
      </MDBCol>
    </div>
  );
};

export default MultiSelectFilterItem;
