import Breadcrumbs from "app/layout/breadcrumbs";
import CardImage from "app/layout/cardImage";
import CmsBlock from "app/layout/cmsBlock";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import MagicSliderDots from "react-magic-slider-dots";

const Craftsmanship = (props) => {
  let settings = {
    className: "",
    centerMode: false,
    infinite: true,
    centerPadding: "5px",
    slidesToShow: 1,
    slidesToScroll: 1,
    controls: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />;
    },
  };
  const isMobile = useMediaQuery({ maxWidth: 992 });

  return (
    <MDBContainer className="static-page craftsmanship mt-5" fluid>
      <MDBRow>
        <MDBCol>
          <MDBRow className={!isMobile && "mx-5"}>
            <MDBCol size="12" className="banner-image-container">
              <div className="overlay"></div>
              <CmsBlock
                skeletonProps={{ height: "69.7rem", width: "100%" }}
                id="craftsmanship_main_img"
              />
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol size="12">
          <MDBContainer className="p-0">
            <MDBRow>
              <MDBCol size="12" className="mt-5">
                <Breadcrumbs />
              </MDBCol>
            </MDBRow>

            <CmsBlock
              id="craftsmanship_title_and_descr"
              skeletonProps={{ height: "13.6rem", width: "100%" }}
            />
            <CmsBlock
              id="craftsmanship_cushion_title_and_descr"
              skeletonProps={{ height: "13.6rem", wwidth: "100%" }}
            />

            {(isMobile && (
              <MDBRow className="d-block py-5 my-5">
                <Slider
                  {...settings}
                  className="d-flex flex-wrap custom-card-inner"
                >
                  <MDBCol size="12" lg="4" className="text-center">
                    <CmsBlock id="craftsmanship_pillow_1" />
                    <CardImage
                      className="card-image  p-0 p-lg-5"
                      imageTitle={
                        <CmsBlock id="craftsmanship_pillow_1_title" />
                      }
                      imageLinkText={"Read More"}
                      imageLinkLessText={"Read Less"}
                      imageDescription={
                        "This five-layered cushion uses a soft grade of 1.8 lb. HR foam for the cushion core. The foam core is then capped with a 1-3/4” thick, soft convoluted foam on each side and wrapped with a 1-1/4 ounce fiber crown. The cushion is then encased in a white non-woven ticking. This combination of foams and fiber is designed to create a soft plush cushion yet will still maintain positive “memory” of its original shape. This cushion is standard on most sofas and lounge chairs."
                      }
                      useCmsBlockImage={true}
                    />
                  </MDBCol>
                  <MDBCol size="12" lg="4" className="text-center">
                    <CmsBlock id="craftsmanship_pillow_2" />
                    <CardImage
                      className="card-image  p-0 p-lg-5"
                      imageTitle={
                        <CmsBlock id="craftsmanship_pillow_2_title" />
                      }
                      imageLinkText={"Read More"}
                      imageLinkLessText={"Read Less"}
                      imageDescription={
                        "This is an “all foam” cushion (no fiber crown) constructed with five layers of Premium HRv foam. The core foam is a firm compression which is wrapped on both sides with two layers of medium compression foam. The two outer layers provide a softer initial feel, while the inner core provides the firm support. This Premium “all foam” cushion is designed for high shape retention and will resist fatigue and sagging. It is especially recommended for use in Commercial/Contract applications."
                      }
                      useCmsBlockImage={true}
                    />
                  </MDBCol>
                  <MDBCol size="12" lg="4" className="text-center">
                    <CmsBlock id="craftsmanship_pillow_3" />
                    <CardImage
                      className="card-image third-card  p-0 p-lg-5"
                      imageTitle={
                        <CmsBlock id="craftsmanship_pillow_3_title" />
                      }
                      imageLinkText={"Read More"}
                      imageLinkLessText={"Read Less"}
                      imageDescription={
                        "Both the seat and back pillows have a filling combination of 50% Ultima SupremeTM polyester fiber/45% fowl feather/ 5% fowl down. The feather and down fill has been cleaned using biodegradable emulsifiers, then rinsed eight times, in accordance with Pacific Coast Feather Cushion Company’s exclusive UltracleanTM process. Afterwards, PCFCC steam dries the feather, enhancing the natural curl in the feather for optimum loft and resiliency. The result is a debris free, hypo-allergenic natural fill with exceptional performance. All feather and down adheres to the standard of the bedding industry for maximum comfort and luxury."
                      }
                      useCmsBlockImage={true}
                    />
                  </MDBCol>
                </Slider>
              </MDBRow>
            )) || (
              <MDBRow className="align-items-center">
                <MDBCol size="12" lg="4" className="text-center mt-lg-5 mt-3">
                  <CmsBlock
                    id="craftsmanship_pillow_1"
                    skeletonProps={{ height: "42rem", wwidth: "100%" }}
                  />
                  <CardImage
                    className="card-image  p-0 p-lg-5"
                    imageTitle={<CmsBlock id="craftsmanship_pillow_1_title" />}
                    imageLinkText={"Read More"}
                    imageLinkLessText={"Read Less"}
                    imageDescription={
                      "This five-layered cushion uses a soft grade of 1.8 lb. HR foam for the cushion core. The foam core is then capped with a 1-3/4” thick, soft convoluted foam on each side and wrapped with a 1-1/4 ounce fiber crown. The cushion is then encased in a white non-woven ticking. This combination of foams and fiber is designed to create a soft plush cushion yet will still maintain positive “memory” of its original shape. This cushion is standard on most sofas and lounge chairs."
                    }
                    useCmsBlockImage={true}
                  />
                </MDBCol>
                <MDBCol size="12" lg="4" className="text-center  mt-lg-5 mt-3">
                  <CmsBlock
                    id="craftsmanship_pillow_2"
                    skeletonProps={{ height: "42rem", wwidth: "100%" }}
                  />
                  <CardImage
                    className="card-image  p-0 p-lg-5"
                    imageTitle={<CmsBlock id="craftsmanship_pillow_2_title" />}
                    imageLinkText={"Read More"}
                    imageLinkLessText={"Read Less"}
                    imageDescription={
                      "This is an “all foam” cushion (no fiber crown) constructed with five layers of Premium HRv foam. The core foam is a firm compression which is wrapped on both sides with two layers of medium compression foam. The two outer layers provide a softer initial feel, while the inner core provides the firm support. This Premium “all foam” cushion is designed for high shape retention and will resist fatigue and sagging. It is especially recommended for use in Commercial/Contract applications."
                    }
                    useCmsBlockImage={true}
                  />
                </MDBCol>
                <MDBCol size="12" lg="4" className="text-center  mt-lg-5 mt-3">
                  <CmsBlock
                    id="craftsmanship_pillow_3"
                    skeletonProps={{ height: "42rem", wwidth: "100%" }}
                  />
                  <CardImage
                    className="card-image third-card  p-0 p-lg-5"
                    imageTitle={<CmsBlock id="craftsmanship_pillow_3_title" />}
                    imageLinkText={"Read More"}
                    imageLinkLessText={"Read Less"}
                    imageDescription={
                      "Both the seat and back pillows have a filling combination of 50% Ultima SupremeTM polyester fiber/45% fowl feather/ 5% fowl down. The feather and down fill has been cleaned using biodegradable emulsifiers, then rinsed eight times, in accordance with Pacific Coast Feather Cushion Company’s exclusive UltracleanTM process. Afterwards, PCFCC steam dries the feather, enhancing the natural curl in the feather for optimum loft and resiliency. The result is a debris free, hypo-allergenic natural fill with exceptional performance. All feather and down adheres to the standard of the bedding industry for maximum comfort and luxury."
                    }
                    useCmsBlockImage={true}
                  />
                </MDBCol>
              </MDBRow>
            )}

            <CmsBlock id="craftsmanship_foam_text" />

            <MDBRow className="frame-container">
              <CmsBlock
                id="craftsmanship_frame_constr_title_descr"
                skeletonProps={{ height: "8rem", wwidth: "100%" }}
              />
              <MDBCol size="9">
                <MDBRow>
                  <MDBCol
                    size="12"
                    lg="6"
                    className="box mt-lg-3 mb-lg-0 my-3 frame-image-container"
                  >
                    <CmsBlock
                      id="craftsmanship_frame_img_1"
                      skeletonProps={{ height: "55rem", wwidth: "100%" }}
                    />
                    <CardImage
                      className="card-image  p-0 p-lg-5"
                      imageTitle={
                        <CmsBlock id="craftsmanship_frame_img_1_title" />
                      }
                      imageDescription="Fiber Backs & Arm Pillows Most loose and semi-attached backs and arm pillows are all fiber. The fiber is enclosed in a non-woven fabric with partitions inside to keep the fiber from shifting. The fiber used is a hollow conjugate with a silicone finish to provide superior loft and resiliency."
                      imageLinkLessText="Read Less"
                      imageLinkText="Read More"
                      useCmsBlockImage={true}
                    />
                  </MDBCol>
                  <MDBCol
                    size="12"
                    lg="6"
                    className="box mt-lg-3 mb-lg-0 my-3 frame-image-container"
                  >
                    <CmsBlock
                      id="craftsmanship_frame_img_2"
                      skeletonProps={{ height: "55rem", wwidth: "100%" }}
                    />
                    <CardImage
                      className="card-image  p-0 p-lg-5"
                      imageTitle={
                        <CmsBlock id="craftsmanship_frame_img_2_title" />
                      }
                      imageDescription="Both the seat and back pillows have a filling combination of 50% Ultima SupremeTM polyester fiber/45% fowl feather/ 5% fowl down. The feather and down fill has been cleaned using biodegradable emulsifiers, then rinsed eight times, in accordance with Pacific Coast Feather Cushion Company’s exclusive UltracleanTM process. Afterwards, PCFCC steam dries the feather, enhancing the natural curl in the feather for optimum loft and resiliency. The result is a debris free, hypo-allergenic natural fill with exceptional performance. All feather and down adheres to the standard of the bedding industry for maximum comfort and luxury."
                      imageLinkLessText="Read Less"
                      imageLinkText="Read More"
                      useCmsBlockImage={true}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <CmsBlock
              id="craftsmanship_spring_const"
              skeletonProps={{ height: "17rem", wwidth: "100%" }}
            />
          </MDBContainer>
          <MDBRow className="bg-gray-lighten-4 py-4 my-5 explore-block">
            <MDBCol>
              <CmsBlock
                id="company_explore"
                skeletonProps={{ height: "69.7rem", width: "100%" }}
              />
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Craftsmanship;
