import {
  DomainFileDataFragment,
  DomainTagDataFragment,
  SortEnum,
} from "app/generated/graphql";
import Maybe from "graphql/tsutils/Maybe";
import axios from "axios";
import { isEmpty } from "lodash";
import { filePropsToOrderBy } from "./config";
import {
  Division,
  DomainFile,
  DomainTag,
  FilePropToOrderBy,
  FilesAndTagByTagId,
  SelectedTags,
  SortableFileData,
  SortOrders,
  SortOrdersByTag,
  TagsByCategory,
} from "./models";
import themeConfiguration from "config/themeConfiguration";

export const toggleSelected = <K extends string>(key: K) => <
  R extends Record<K, true>
>(
  record: R
) => {
  const { [key]: isSelected, ...filtered } = record;

  return isSelected ? filtered : { ...filtered, [key]: true };
};

export const downloadFiles = (files: DomainFile[]) => {
  var filesArray: Object[];
  var config: Object;
  filesArray = [];
  const url =
    themeConfiguration.magento_url +
    "rest/V1/jola-filedownloader/downloadFiles";
  files.map((item, index) => {
    return (filesArray[index] = {
      url: item.file,
    });
  });
  var rawRequest = JSON.stringify({ type: "files", files: filesArray });

  config = {
    method: "post",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: rawRequest,
  };

  axios(config)
    .then((data) => {
      if (data.status === 200) {
        // @ts-ignore
        let fileExtension = data.data?.split(".").pop();
        if (fileExtension === "zip") {
          // @ts-ignore
          window.open(data.data, "_blank");
        } else {
          // @ts-ignore
          CreateDownloadLink(data.data);
        }
      }
    })
    .catch((error) => {
      console.error(error);
    });

  // files.forEach((currentFile) => {
  // const a = document.createElement("a");

  // toDataUrl(currentFile.file).then((href) => {
  //   a.href = href;

  //   a.download = currentFile.filename;

  //   document.body.append(a);

  //   a.click();

  //   document.body.removeChild(a);
  // });

  // });
};
export const CreateDownloadLink = (data: string) => {
  var a = document.createElement("a");
  if (
    themeConfiguration.magento_url === "https://fairfieldm2.joladev.com/"
  ) {
    a.href = data;
    a.target = "_blank";
  } else {
    var json_data = JSON.parse(data);
    a.href = json_data.base64;
    a.download = json_data.file_name;
  }
  a.click();
};

export const isDomainFileData = (
  file: Maybe<DomainFileDataFragment>
): file is DomainFile =>
  (!!file?.file && !!file.filename) || (!!file?.youtube_url && !!file.title);

export const isDomainTag = (
  tag: Maybe<DomainTagDataFragment>
): tag is DomainTag => !!(tag?.tag_id && tag.name && tag.category);

export const groupFilesByTagId = (files: DomainFile[]): FilesAndTagByTagId => {
  return files.reduce((filesByTagId, file) => {
    return file.tags
      ? file.tags.filter(isDomainTag).reduce(
          (files, tag) => ({
            ...files,
            [tag.tag_id]: {
              tag,
              files: [...(files[tag.tag_id]?.files ?? []), file],
            },
          }),
          filesByTagId
        )
      : filesByTagId;
  }, {} as FilesAndTagByTagId);
};

export const groupTagsByCategory = (
  filesAndTagByTagId: FilesAndTagByTagId
): TagsByCategory => {
  return Object.entries(filesAndTagByTagId).reduce(
    (tags, [tagId, { tag }]) => ({
      ...tags,
      //@ts-ignore
      [tag.category]: [
        //@ts-ignore
        ...(tags[tag.category] ?? []),
        //@ts-ignore
        { tag_id: tagId, name: tag.name, file_type: tag.file_type },
      ],
    }),
    {} as TagsByCategory
  );
};

type SortablePair = {
  [key in FilePropToOrderBy]: { prop: key; value: SortableFileData[key] };
}[FilePropToOrderBy];
const getProp = (pair: SortablePair) => {
  switch (pair.prop) {
    case "size":
      return Number(pair.value?.split(" ")?.[0]);
    case "updated_at":
      return new Date(pair.value).getTime();
    default:
      return pair.value;
  }
};
export const sortFiles = (
  filesAndTagsByTagId: FilesAndTagByTagId,
  sortOrdersByTag: SortOrdersByTag
) => {
  return Object.entries(filesAndTagsByTagId).reduce(
    (accumulator, [tagId, { tag, files }]) => {
      const sortOrders: SortOrders | undefined = sortOrdersByTag[tagId];
      let keys;
      if (typeof sortOrders === "object") keys = Reflect.ownKeys(sortOrders);
      if (sortOrders && Object.keys(sortOrders).length > 1)
        // @ts-ignore
        delete sortOrders[keys[0]];
      const sortedFiles = files
        .filter((fileData): fileData is SortableFileData => {
          // @ts-ignore
          if (!fileData.file && fileData.youtube_url) return fileData;
          return !!Object.entries(fileData).every(
            ([key, value]) =>
              !filePropsToOrderBy.includes(key as FilePropToOrderBy) || !!value
          );
        })
        .sort((a, b) =>
          !sortOrders
            ? 0
            : Object.entries(sortOrders).reduce((order, [prop, sortOrder]) => {
                if (prop === "filename") prop = "title";
                const key = prop as FilePropToOrderBy;
                // @ts-ignore
                const aProp = getProp({
                  prop: key,
                  value: a[key],
                } as SortablePair);
                const bProp = getProp({
                  prop: key,
                  value: b[key],
                } as SortablePair);

                if (order !== 0) {
                  return order;
                } else if (sortOrder === null) {
                  return 0;
                } else if (aProp === bProp) {
                  return 0;
                } else if (sortOrder === SortEnum.Asc) {
                  return aProp > bProp ? -1 : 1;
                } else {
                  return aProp > bProp ? 1 : -1;
                }
              }, 0)
        );

      return {
        ...accumulator,
        [tagId]: { tag, files: sortedFiles },
      };
    },
    {} as FilesAndTagByTagId
  );
};
export const filterFiles = (
  filesAndTagByTagId: FilesAndTagByTagId,
  division: Division,
  selectedTags: SelectedTags
): FilesAndTagByTagId => {
  return Object.fromEntries(
    Object.entries(filesAndTagByTagId)
      .map(
        ([tagId, { tag, files }]) =>
          [
            tagId,
            {
              tag,
              files: files.filter(
                (fileData) =>
                  division === "all" || fileData.divisions?.[division] === 1
                // (fileData && isEmpty(selectedTags)) || selectedTags[tagId]
              ),
            },
          ] as const
      )
      .filter(
        ([tagId, { files }]) =>
          files.length && (isEmpty(selectedTags) || selectedTags[tagId])
      )
  );
};

export const formatDate = (
  updatedAt: NonNullable<DomainFileDataFragment["updated_at"]>
) => {
  const date = new Date(updatedAt);

  const lang = "en-US";

  const years = new Intl.DateTimeFormat(lang, { year: "numeric" }).format(date);
  const months = new Intl.DateTimeFormat(lang, { month: "2-digit" }).format(
    date
  );
  const days = new Intl.DateTimeFormat(lang, { day: "2-digit" }).format(date);

  return `${years}-${months}-${days}`;
};
