import Switch, { SwitchProps } from "app/layout/Switch";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import { isEmpty, omit } from "lodash";
import {
  MDBAlert,
  MDBBox,
  MDBCol,
  MDBInput,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React from "react";
import { useMediaQuery } from "react-responsive";
import SaveSection from "../../components/SaveSection";
import Title from "../../components/Title";
import {
  AccountInfoValues,
  initialAccountInfoValues,
  initialChangePasswordValues,
  initialChangeEmailValues,
} from "./config";

export interface AccountInfoProps {
  form: FormikProps<AccountInfoValues>;
  passwordChanged?: boolean;
  location?: any;
}

const AccountInfo: React.FC<AccountInfoProps> = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const changePasswordProps: Omit<SwitchProps, "label"> = React.useMemo(() => {
    const fieldProps = props.form.getFieldProps("changePassword");
    return {
      ...omit(fieldProps, "value"),
      checked:
        (props?.location?.state?.changePassword === true && true) ||
        !isEmpty(fieldProps.value), // * circumvents type error associated with value
      onChange: (e) => {
        const changePassword = e.target.checked
          ? initialChangePasswordValues
          : initialAccountInfoValues.changePassword;
        props.form.setFieldValue("changePassword", changePassword);
      },
    };
  }, [props]);
  const changeEmailProps: Omit<SwitchProps, "label"> = React.useMemo(() => {
    const fieldProps = props.form.getFieldProps("changeEmail");
    return {
      ...omit(fieldProps, "value"),
      checked:
        (props?.location?.state?.changeEmail === true && true) ||
        !isEmpty(fieldProps.value), // * circumvents type error associated with value
      onChange: (e) => {
        const changeEmail = e.target.checked
          ? initialChangeEmailValues
          : initialAccountInfoValues.changeEmail;
        props.form.setFieldValue("changeEmail", changeEmail);
      },
    };
  }, [props]);
  const changePasswordTouched = (props.form.touched
    .changePassword as unknown) as
    | undefined
    | FormikTouched<AccountInfoValues["changePassword"]>;
  const changePasswordErrors = (props.form.errors.changePassword as unknown) as
    | undefined
    | FormikErrors<AccountInfoValues["changePassword"]>;

  const changeEmailTouched = (props.form.touched.changeEmail as unknown) as
    | undefined
    | FormikTouched<AccountInfoValues["changeEmail"]>;
  const changeEmailErrors = (props.form.errors.changeEmail as unknown) as
    | undefined
    | FormikErrors<AccountInfoValues["changeEmail"]>;

  React.useEffect(() => {
    if (changePasswordProps?.checked && !props.form.values.changePassword)
      props.form.values.changePassword = initialChangePasswordValues;
  }, [changePasswordProps]);

  return (
    <form onSubmit={props.form.handleSubmit}>
      <Title>Edit Account Information</Title>
      <MDBRow>
        <MDBCol>
          <MDBTypography tag="h3" className="mb-5 pb-5">
            Account Information
          </MDBTypography>
          <div className={`pb-5`}>
            <div className="position-relative">
              <MDBInput
                {...props.form.getFieldProps("firstname")}
                size="lg"
                label="First Name"
                hint="ex: John"
                required
              />
              <div className="text-red position-absolute">
                {props.form.touched.firstname && props.form.errors.firstname}
              </div>
            </div>
          </div>
          <div className={`pb-5`}>
            <div className="position-relative">
              <MDBInput
                {...props.form.getFieldProps("lastname")}
                size="lg"
                label="Last Name"
                hint="ex: Smith"
                required
              />
              <div className="text-red position-absolute">
                {props.form.touched.lastname && props.form.errors.lastname}
              </div>
            </div>
          </div>
          <div className={`mb-5 pb-5`}>
            <div>
              <Switch label="Change Password" {...changePasswordProps} />
              <Switch
                className="mt-3"
                label="Change Email"
                {...changeEmailProps}
              />
            </div>
          </div>
        </MDBCol>
        <MDBCol>
          <MDBTypography tag="h3" className="mb-5 pb-5">
            {(props.form.values.changePassword ||
              changePasswordProps?.checked) &&
            (props.form.values.changeEmail || changeEmailProps?.checked)
              ? "Change Email and Password"
              : props.form.values.changePassword || changePasswordProps?.checked
              ? "Change Password"
              : props.form.values.changeEmail || changeEmailProps?.checked
              ? "Change Email"
              : null}
          </MDBTypography>
          {(!isEmpty(props.form.values.changePassword) ||
            props?.location?.state?.changePassword ||
            !isEmpty(props.form.values.changeEmail)) && (
            <>
              {!isEmpty(props.form.values.changeEmail) ? (
                <div className={`mb-5 pb-5`}>
                  <div className="position-relative">
                    <MDBInput
                      {...props.form.getFieldProps("changeEmail.email")}
                      type="email"
                      size="lg"
                      label="Email"
                      required
                    />
                    <div
                      className="position-relative text-red"
                      style={{ bottom: "0", top: "-12px" }}
                    >
                      {changeEmailTouched?.email && changeEmailErrors?.email}
                    </div>
                  </div>
                </div>
              ) : null}
              <div className={`mb-5 pb-5`}>
                <div className="position-relative">
                  {changePasswordProps?.checked ? (
                    <MDBInput
                      {...props.form.getFieldProps(
                        "changePassword.currentPassword"
                      )}
                      type="password"
                      size="lg"
                      label="Current Password"
                      required
                      hint="XXXXXX"
                      onChange={(e) => {
                        if (!isEmpty(props.form.values.changeEmail))
                          props?.form?.setFieldValue(
                            "changeEmail.currentPassword",
                            // @ts-ignore
                            e?.target?.value
                          );
                        props?.form?.setFieldValue(
                          "changePassword.currentPassword",
                          // @ts-ignore
                          e?.target?.value
                        );
                      }}
                    />
                  ) : (
                    <MDBInput
                      {...props.form.getFieldProps(
                        "changeEmail.currentPassword"
                      )}
                      type="password"
                      size="lg"
                      label="Current Password"
                      required
                      hint="XXXXXX"
                    />
                  )}

                  <div
                    className="position-relative text-red"
                    style={{ bottom: "0", top: "-12px" }}
                  >
                    {(changePasswordTouched?.currentPassword &&
                      changePasswordErrors?.currentPassword) ||
                      (changeEmailTouched?.currentPassword &&
                        changeEmailErrors?.currentPassword)}
                  </div>
                </div>
              </div>
              {!isEmpty(props.form.values.changePassword) ||
              props?.location?.state?.changePassword ? (
                <>
                  <div className="mb-5 pb-5">
                    <div className="position-relative">
                      <MDBInput
                        {...props.form.getFieldProps(
                          "changePassword.newPassword"
                        )}
                        type="password"
                        size="lg"
                        label="New Password"
                        required
                        hint="XXXXXX"
                      />
                      <div
                        className="position-relative text-red"
                        style={{ bottom: "0", top: "-12px" }}
                      >
                        {changePasswordTouched?.newPassword &&
                          changePasswordErrors?.newPassword}
                      </div>
                    </div>
                  </div>
                  <div className="mb-5 pb-5">
                    <div className="position-relative">
                      <MDBInput
                        {...props.form.getFieldProps(
                          "changePassword.confirmPassword"
                        )}
                        type="password"
                        size="lg"
                        label="Confirm Password"
                        required
                        hint="XXXXXX"
                      />
                      <div
                        className="position-relative text-red"
                        style={{ bottom: "0", top: "-12px" }}
                      >
                        {changePasswordTouched?.confirmPassword &&
                          changePasswordErrors?.confirmPassword}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}
        </MDBCol>
      </MDBRow>
      {props.passwordChanged && (
        <MDBBox mb={5}>
          <MDBAlert color="success" dismiss>
            Successfully updated.
          </MDBAlert>
        </MDBBox>
      )}
      <SaveSection
        onClick={props.form.submitForm}
        disabled={props.form.isSubmitting}
      />
    </form>
  );
};

export default AccountInfo;
