import apolloClient from "core/graphql/apolloClient";
import commonActions from "../../common/actions";
import messagesActions from "../../messages/actions";
import { gql } from "graphql.macro";

const SHARE_WISHLIST_QUERY = gql`
  mutation shareWishilist(
    $emails: String
    $message: String
    $wishlist_id: String
    $multiwishlist_id: String
  ) {
    shareWishilist(
      emails: $emails
      message: $message
      wishlist_id: $wishlist_id
      multiwishlist_id: $multiwishlist_id
    )
  }
`;

export default async (store, action) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: SHARE_WISHLIST_QUERY,
      variables: action,
    });

    if (data) {
      store.dispatch(
        messagesActions.addMessage("successfully shared", "success")
      );
    }
    store.dispatch(commonActions.unlock("success"));
  } catch (error) {
    store.dispatch(
      messagesActions.addMessage(error.graphQLErrors[0].debugMessage, "danger")
    );

    store.dispatch(commonActions.unlock("fail"));
  }
};
