import React from "react";
import FooterStateContainer from "./footer-state-container";
import SkeletonFooter from "./skeleton-footer";

const FooterDataContainer = (props) => {
  if (typeof props.data === "undefined") return <SkeletonFooter />;
  return <FooterStateContainer {...props} />;
};

export default FooterDataContainer;
