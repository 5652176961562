// @ts-check

import clsx from "clsx";
import { MDBTypography } from "mdbreact";
import React from "react";

/**
 * @typedef {{ text: React.ReactNode; textClassName?: string; required?: boolean; disabled?: boolean; } & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>} LabelProps
 *
 */
/**
 * @type {React.FC<LabelProps>}
 */
const Label = ({
  required = false,
  disabled = false,
  textClassName: textClass,
  ...props
}) => {
  return (
    <label {...props} className={clsx("label", props.className)}>
      <MDBTypography
        tag="h2"
        className={clsx(
          "label-text",
          { required, label__disabled: disabled },
          textClass
        )}
      >
        {props.text}
      </MDBTypography>
      {props.children}
    </label>
  );
};

export default Label;
