// @ts-check

import React from "react";
/**
 * @typedef {{ icon: string; size?: string; } & React.ImgHTMLAttributes<HTMLImageElement>} IconProps
 * @param {IconProps} props
 */
const Icon = (props) => {
  try {
    const ReturnIcon = require("./" + props.icon + ".svg");
    let style = props.style;
    if (props.size) {
      style = {
        ...style,
        height: props.size + "rem",
        width: props.size + "rem",
      };
    }
    return <img src={ReturnIcon} {...props} style={style} alt={props.icon} />;
  } catch (e) {
    console.warn("Icon " + props.icon + " does not exist");
  }
  return null;
};

export default Icon;
