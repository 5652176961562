import { MDBTypography } from "mdbreact";
import React from "react";

export interface DocumentInputInfoProps
  extends Omit<React.ComponentProps<typeof MDBTypography>, "variant"> { }

const DocumentInputInfo: React.FC<DocumentInputInfoProps> = (props) => {
  return (
    // @ts-ignore
    <MDBTypography {...props} variant="body-6 fwregular text-primary">
      max upload of 2MB (JPG, PDF)
    </MDBTypography>
  );
};

export default DocumentInputInfo;
