/**
 * 0-100
 */
export type Percentage = number;

export const priceToPercentage = (maxPrice: number, price: number) => {
  return Math.round((price / maxPrice) * 100);
};
export const percentageToPrice = (maxPrice: number, percentage: number) => {
  return Math.round((percentage / 100) * maxPrice);
};

export const minimumPercentageRange = 10;
export const minPercentage = 0;
export const maxPercentage = 100;

export const eventToPercentage = ({
  minPercentage,
  maxPercentage,
  event: e,
  trackElement,
}: {
  minPercentage: number;
  maxPercentage: number;
  event: any;
  trackElement: HTMLDivElement;
}): Percentage | -1 => {
  const trackRect = trackElement.getBoundingClientRect();
  e.persist();

  if (trackRect) {
    let diff;
    if (!e.clientX) {
      diff = e.touches[0].clientX - trackRect.left;
    } else {
      diff = e.clientX - trackRect.left;
    }
    const percentage = Math.round((diff / trackRect.width) * 100);
    return Math.min(maxPercentage, Math.max(minPercentage, percentage));
  } else {
    return -1;
  }
};

export enum DragTarget {
  none = "none",
  from = "from",
  to = "to",
}

export const trackColor = "rgb(108, 128, 139, 0.6)";
