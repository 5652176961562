import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { messagesActions } from "core/state/redux/data/messages";
import apolloClient from "core/graphql/apolloClient";
import queryLoader from "app/graphql/queryLoader";

function useDownloadImages() {
  // return compare from state
  const downloadimages = useSelector((state) => state.downloadImages);
  const dispatch = useDispatch();

  const getDownloadImages = () => {
    return Object.keys(downloadimages)?.length === 0
      ? []
      : downloadimages?.data?.downloadImages
      ? [...downloadimages?.data?.downloadImages]
      : [];
  };

  const downloadImagesMethod = async (request) => {
    try {
      let filteredRequest = checkIfImageIsAvailable(request);
      if (filteredRequest.length === 0) {
        dispatch(
          messagesActions.addMessage(
            "No images available for download",
            "danger"
          )
        );
        return;
      }

      const { data } = await apolloClient.mutate({
        mutation: queryLoader("generateDownloadUrlM"),
        variables: { input: filteredRequest },
        fetchPolicy: "no-cache",
      });

      if (data.generateDownloadUrlM.generated_download_url_output !== null) {
        const {
          status,
          download_url,
        } = data.generateDownloadUrlM.generated_download_url_output;
        if (status === "ok") {
          window.open(download_url, "_blank");
        }
      }
    } catch (err) {
      console.log(err);
      dispatch(messagesActions.addMessage("Error downloading image", "danger"));
      return err.message;
    }
  };

  const checkIfImageIsAvailable = (request) => {
    let filteredData = [];
    if (!Array.isArray(request)) {
      filteredData.push({
        product_sku: request.product_sku,
        // images: [
        //   ...request.images.filter((img) => !img.url.includes("missing_image")),
        // ],
      });
    } else {
      filteredData = [
        ...request.map((data) => {
          return {
            ...data,
          };
        }),
      ];
    }
    // filteredData = filteredData.filter((data) => data.images.length > 0);

    return filteredData;
  };

  return {
    getDownloadImages,
    downloadImagesMethod,
    // checkIfProductIsInCompareById,
  };
}
export { useDownloadImages };
