import Icon from "app/assets/icon/icon";
import themeConfiguration from "config/themeConfiguration";
import axios from "axios";
import {
  MDBBox,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React, { createContext, useContext, useState } from "react";

const DownloadContext = createContext({});

export const DownloadProvider = (props) => {
  const [images, setImages] = useState([]);

  return (
    <DownloadContext.Provider value={[images, setImages]}>
      {props.children}
    </DownloadContext.Provider>
  );
};

export const useDownloader = (image) => {
  const [images, setImages] = useContext(DownloadContext);
  const isAdded = images.includes(image);
  const addImage = () => {
    if (!isAdded) {
      setImages((images) => [...images, image]);
    }
  };
  const removeImage = () => {
    if (isAdded) {
      setImages((images) => {
        return images.filter((item) => item !== image);
      });
    }
  };

  return { isAdded, addImage, removeImage };
};

export const Downloader = ({
  image = "https://via.placeholder.com/370x420",
  icon = "downloadWhite",
  addedIcon = "downloadDark",
}) => {
  const { isAdded, addImage, removeImage } = useDownloader(image);

  return (
    <Icon
      onClick={() => {
        if (isAdded) {
          removeImage();
        } else {
          addImage();
        }
      }}
      className={`downloader ${isAdded ? "in" : "out"}`.trim()}
      icon={isAdded ? addedIcon : icon}
    />
  );
};

const DownloadImages = async (urls) => {
  const url =
    themeConfiguration.magento_url +
    "rest/V1/jola-filedownloader/downloadFiles";
  var filesArray = [];
  urls.map((item, index) => {
    return (filesArray[index] = {
      url: item,
    });
  });
  var rawRequest = JSON.stringify({ type: "images", files: filesArray });
  var config = {
    method: "post",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: rawRequest,
  };

  axios(config)
    .then((data) => {
      if (data.status === 200 && urls.length > 1) {
        window.open(data.data, "_blank");
      } else {
        CreateImageLink(data.data);
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

const CreateImageLink = (data) => {
  var json_data = JSON.parse(data);
  var a = document.createElement("a");
  a.href = json_data.base64;
  a.download = json_data.image_name;
  a.click();
};

export const DownloadBar = () => {
  const [images] = useContext(DownloadContext);
  const [confirmModal, setConfirmModal] = useState(false);
  const toggleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };
  if (images.length > 0) {
    return (
      <>
        <MDBBox
          tag="div"
          className="download-bar bg-gray-lighten-4 py-4 border-top border-default"
          style={{
            position: "sticky",
            bottom: 0,
          }}
        >
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12" lg="2" className="d-flex align-items-center">
                <MDBTypography tag="h2" className="body-2 fs-16">
                  {images.length} Image(s) Selected
                </MDBTypography>
              </MDBCol>
              <MDBCol size="12" lg="3" className="offset-lg-3">
                <MDBBtn
                  color="default"
                  onClick={() => {
                    setConfirmModal(1);
                  }}
                  className="icon icon-left"
                >
                  <Icon icon="downloadWhite" /> Download Selected
                </MDBBtn>
              </MDBCol>
              <MDBCol
                size="12"
                lg="2"
                className="d-flex offset-lg-2 align-items-center"
              >
                <MDBTypography
                  tag="a"
                  onClick={() => {
                    document
                      .querySelectorAll(".downloader.out")
                      .forEach((e) => {
                        e.click();
                      });
                  }}
                  className="link-3 mr-4"
                >
                  Select All
                </MDBTypography>
                <MDBTypography
                  tag="a"
                  onClick={() => {
                    document.querySelectorAll(".downloader.in").forEach((e) => {
                      e.click();
                    });
                  }}
                  className="link-3"
                >
                  Deselect All
                </MDBTypography>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBBox>
        <MDBModal isOpen={confirmModal} toggle={toggleConfirmModal} centered>
          <MDBModalBody className="px-4 py-0">
            <MDBRow className="bg-default py-2">
              <MDBCol className="flex-center position-relative">
                <Icon
                  icon="closeWhite"
                  onClick={toggleConfirmModal}
                  className="mr-4 close close-left"
                />
                <MDBTypography
                  tag="h2"
                  variant="display-2"
                  className="text-white"
                >
                  Download Options
                </MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="px-5 flex-center flex-column">
                <MDBRow className="py-4">
                  <MDBCol>
                    <MDBTypography tag="h2" className="body-2">
                      Choose Download Image to download the selected image(s)
                    </MDBTypography>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="py-2">
                  <MDBCol className="flex-center">
                    <MDBBtn
                      size="lg"
                      onClick={() => {
                        DownloadImages(images);
                      }}
                      color="default"
                    >
                      Download Image
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="py-2">
                  <MDBCol className="flex-center">
                    <MDBBtn
                      size="lg"
                      onClick={toggleConfirmModal}
                      color="secondary"
                    >
                      Cancel
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="py-4 pb-5 mb-3">
                  <MDBCol>
                    <MDBTypography tag="h2" className="body-2 text-center">
                      Note: Download maybe large and take a few minutes
                      depending on the selection of images
                    </MDBTypography>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
      </>
    );
  }
  return null;
};
