// TODO add back // @ts-heck

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const ALL_GALLERIES = gql`
  query {
    getAllGalleryData {
      category
      description
      id
      hotspot {
        content
        image
        name
      }
      parent_id
      image
      thumbnail
      name
      skuList
    }
  }
`;

export const useGallery = () => {
  const { data, loading, error } = useQuery(ALL_GALLERIES, {
    variables: { id: "gallery-details" },
  });
  if (loading) {
    return {};
  }
  if (error) {
    console.error(error);
    return {};
  }

  let galleries = data.getAllGalleryData;
  let categories = [];
  if (galleries) {
    galleries.forEach((g) => {
      if (!g?.skuList) return;
      g.category.forEach((c) => {
        if (!categories.includes(c)) {
          categories.push(c);
        }
      });
    });
  }

  return { galleries, categories };
};
