import apolloClient from "core/graphql/apolloClient";
import customerActions from "../actions";
// import customerActionTypes from "../types";
import queryLoader from "core/graphql/queryLoader";
import messagesActions from "../../messages/actions";
import queryActions from "../../queries/actions";
import { commonActions } from "../../common";
// const LOGOUT_CUSTOMER = customerActionTypes.LOGOUT_CUSTOMER;
const customerMutation = queryLoader("registerCustomer");
const addressMutation = queryLoader("createCustomerAddress");

export default async (store, action) => {
  let { address, address2 } = action.address;
  try {
    const { data } = await apolloClient.mutate({
      mutation: customerMutation,
      variables: {
        input: action.request
      },
      fetchPolicy: "no-cache"
    });

    if (!data.createCustomer || !data.createCustomer.customer) {
      console.log("GraphQL response", data);
      throw new Error("No customer information retrieved.");
    }

    const tokenResult = await apolloClient.mutate({
      mutation: queryLoader("userLogin"),
      variables: {
        email: action.request.email,
        password: action.request.password
      }
    });

    if (
      typeof tokenResult !== "undefined" &&
      typeof tokenResult.data !== "undefined" &&
      typeof tokenResult.data.generateCustomerToken !== "undefined"
    ) {
      await store.dispatch(
        customerActions._setCustomerToken(
          tokenResult.data.generateCustomerToken.token
        )
      );

      if (typeof address === "undefined") {
        address = action.address;
      }
      const addressResult = await apolloClient.mutate({
        mutation: addressMutation,
        variables: { input: address }
      });

      if (!addressResult.data || !addressResult.data.createCustomerAddress) {
        console.log("GraphQL response", data);
        throw new Error("No address information retrieved.");
      }
      if (typeof address2 !== "undefined") {
        const address2Result = await apolloClient.mutate({
          mutation: addressMutation,
          variables: { input: address2 }
        });
        if (
          !address2Result.data ||
          !address2Result.data.createCustomerAddress
        ) {
          console.log("GraphQL response", data);
          throw new Error("No address information retrieved.");
        }
      }

      store.dispatch(customerActions.logoutCustomer(true));
    }

    store.dispatch(queryActions.removeQuery());

    return new Promise(function(resolve, reject) {
      resolve(true);
    });
  } catch (error) {
    store.dispatch(commonActions.unlock("fail"));
    store.dispatch(
      messagesActions.addMessage(error.toString(), "danger", "register")
    );
    store.dispatch(customerActions._reduceCustomer());
    return new Promise(function(resolve, reject) {
      reject(error.toString());
    });
  }
};
