import { useCustomerCartQuery } from "app/generated/graphql";
import CartContext from "app/layout/cart";
import useCustomer from "app/state/hooks/customer/useCustomer";
import { selectIsLoggedIn } from "app/state/redux/data/customer/selectors";
import React from "react";
import { useSelector } from "react-redux";
import { useIsMobile, useParameterizedRemoveItemFromCart } from "../../hooks";
import { isDomainCartItem } from "../../utils";
import MiniCart from "./MiniCart";

export interface DataContainerProps {}

const DataContainer: React.FC<DataContainerProps> = (props) => {
  const { isLoggedIn } = useCustomer();
  const loggedIn = isLoggedIn();
  const cartQuery = useCustomerCartQuery({
    skip: !loggedIn,
    fetchPolicy: "network-only",
  });

  const [removeItemFromCart] = useParameterizedRemoveItemFromCart();

  const cartContext = React.useContext(CartContext);
  // @ts-ignores
  const cart = useSelector((state) => state.customer.data.cart);

  React.useEffect(() => {
    if (!loggedIn) return;
    cartQuery.refetch();
  }, [loggedIn, cart]);

  const isMobile = useIsMobile();

  return cartQuery.data?.customerCart.items ? (
    <MiniCart
      isMobile={isMobile}
      isOpen={cartContext.isModalOpen}
      closeModal={cartContext.closeModal}
      items={cartQuery.data?.customerCart.items.filter(isDomainCartItem)}
      onRemoveItem={(cartItemId) => {
        // * TypeScript does not pick up on the fact that cartQuery.data.customerCart must be defined
        if (cartQuery.data?.customerCart) {
          return removeItemFromCart({
            cart: cartQuery.data.customerCart,
            cart_item_id: cartItemId,
          });
        }
      }}
    />
  ) : null;
};

export default DataContainer;
