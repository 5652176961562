import _ from "lodash";
import { MDBBox } from "mdbreact";
import React, { useState } from "react";

const CardImage = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className={props.className}>
      {!_.isEmpty && (
        <div className={`card-image-content ${props.cardClass}`}>
          {props.useCmsBlockImage && typeof props.bottomImage === "object" ? (
            props.bottomImage
          ) : (
            <img src={props.bottomImage} className={`img-fluid`} alt="" />
          )}
        </div>
      )}

      <h3>{props.imageTitle}</h3>
      <MDBBox
        tag="p"
        className={`expandable-text-${isOpen} expandable-text ${
          (props.cardImgOnGallery === true &&
            (props.numOfLinesInImagDes > 5 ||
              (props.numOfLinesInImagDes === 0 && props.checkForDot > 5)) &&
            "bigger-height") ||
          "" ||
          ""
        }`}
      >
        {props.imageDescription}
      </MDBBox>
      {(props.cardImgOnGallery === true &&
        (((props.numOfLinesInImagDes > 1 ||
          (props.numOfLinesInImagDes === 0 && props.checkForDot > 5)) &&
          ((isOpen && (
            <div onClick={toggle} onKeyUp={toggle} tabIndex={0} role="button">
              <p className={`clicked-${isOpen} click-to-show`}>
                {props.imageLinkLessText}
              </p>
            </div>
          )) || (
            <div
              onKeyUp={toggle}
              tabIndex={0}
              role="button"
              onClick={(e) => {
                toggle();
              }}
            >
              <p className={`clicked-${isOpen} click-to-show`}>
                {props.imageLinkText}
              </p>
            </div>
          ))) || <></>)) ||
        (isOpen && (
          <div onClick={toggle} onKeyUp={toggle} tabIndex={0} role="button">
            <p className={`clicked-${isOpen} click-to-show`}>
              {props.imageLinkLessText}
            </p>
          </div>
        )) || (
          <div
            onKeyUp={toggle}
            tabIndex={0}
            role="button"
            onClick={(e) => {
              toggle();
            }}
          >
            <p className={`clicked-${isOpen} click-to-show`}>
              {props.imageLinkText}
            </p>
          </div>
        )}
    </div>
  );
};

export default CardImage;
