import React from "react";
import Component from "./dynamicGraphQlWrapper";

const DynamicGraphQlWrapperCachedQueryContainer = (props) => {
  let data = props.isCached;

  const children = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, {
      ...props,
      data: data,
      urlResolver: { ...data },
    });
  });
  return <Component {...props}>{children}</Component>;
};

export default DynamicGraphQlWrapperCachedQueryContainer;
