import React, { useState } from "react";

import { MDBContainer, MDBRow } from "mdbreact";
import Icon from "app/assets/icon/icon";

const Accordion = (props: any) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <MDBContainer
      fluid
      className={`${props.noBorders ? "accordionNoBorders" : "accordion"}`}
    >
      <MDBRow
        onClick={() => toggleAccordion()}
        className={`d-flex justify-content-between ${
          isOpen ? "accordion-header-active" : " accordion-header"
        }`}
      >
        {props.title} <Icon icon={`${isOpen ? "minusGray" : "plusGray"}`} />
      </MDBRow>
      <MDBRow
        className={`${
          isOpen ? "accordion-content-active" : "accordion-content"
        }`}
      >
        {props.children}
      </MDBRow>
    </MDBContainer>
  );
};

export default Accordion;
