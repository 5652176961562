import React from "react";
import CollectionCustom from "./collectionCustom";

const CollectionCustomDataContainer = ({
  howItWorksId,
  imageAndVideo,
  ...props
}) => {
  const catVideo = imageAndVideo?.category_video;
  const catImages = imageAndVideo?.media_gallery_entries;

  return (
    <CollectionCustom
      catVideo={catVideo}
      howItWorksId={howItWorksId}
      {...props}
      catImages={catImages}
    />
  );
};

export default CollectionCustomDataContainer;
