// @ts-check

import qs from "qs";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Login from "./Login";

/**
 *
 * @typedef {Pick<import('./Login').LoginProps, 'isLoggedIn' | 'login'> & { hideModal: (isLoggedIn: boolean) => void; }} LoginStateContainerProps
 *
 * @type {React.FC<LoginStateContainerProps>}
 */
const LoginDataContainer = (props) => {
  const history = useHistory();

  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const toggleIsOpen = React.useCallback(
    (/** @type {boolean} */ isLoggedIn) => {
      setIsOpen((value) => !value);

      // history.push({ search: "" });
      props.hideModal(isLoggedIn);
    },
    [props, history]
  );

  const query = React.useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location.search]
  );

  const emailAndFirstName = React.useMemo(
    () =>
      typeof query.email === "string" && typeof query.firstname === "string"
        ? { email: query.email, firstname: query.firstname }
        : null,
    [query]
  );

  React.useEffect(() => {
    if (props.isLoggedIn) {
      toggleIsOpen(true);
      history.push({ search: "" });
    }
  }, [props.isLoggedIn, toggleIsOpen, history]);

  const isExpress = React.useMemo(() => {
    return typeof query.setup === "string" ? query.setup === "express" : null;
  }, [query]);

  /**
   * @type {import('./Login').LoginProps['setup']}
   */
  const setup = React.useMemo(
    () =>
      emailAndFirstName
        ? { type: "password", payload: emailAndFirstName }
        : isExpress
        ? { type: "express" }
        : { type: "emailAndPassword" },
    [emailAndFirstName, isExpress]
  );

  const onClose = React.useCallback(() => {
    toggleIsOpen(false);
  }, [toggleIsOpen]);

  return <Login {...props} isOpen={isOpen} onClose={onClose} setup={setup} />;
};

export default LoginDataContainer;
