import GraphQlWrapper from "core/components/graphQlWrapper";
import gql from "graphql-tag";
import React from "react";
import FooterDataContainer from "./footer-data-container";

/**
 * Refactor GraphQl Wrapper to use this approach? addQuery middleware
 */
const MENU_QUERY = gql`
  query Category($id: String!) {
    categoryList(filters: { ids: { eq: $id } }) {
      id
      name
      product_count
      include_in_menu
      url_path
      hero_image
      promo_image
      description
      position
      isCollection
      isCustom
      children {
        id
        name
        product_count
        image
        url_path
        include_in_menu
        hero_image
        promo_image
        description
        position
        isCollection
        isCustom
        children {
          id
          name
          product_count
          url_path
          include_in_menu
          hero_image
          promo_image
          description
          position
          isCollection
          isCustom
          children {
            id
            name
            product_count
            url_path
            include_in_menu
            hero_image
            promo_image
            description
            position
            isCollection
            isCustom
            children {
              id
              name
              product_count
              url_path
              include_in_menu
              hero_image
              promo_image
              description
              position
              isCollection
              isCustom
              children {
                id
                name
                product_count
                url_path
                include_in_menu
                hero_image
                promo_image
                description
                position
                isCollection
                isCustom
              }
            }
          }
        }
      }
    }
  }
`;

const FooterQueryContainer = (props) => {
  return (
    <GraphQlWrapper query={MENU_QUERY} variables={{ id: "2" }} saveQuery={true}>
      <FooterDataContainer {...props} />
    </GraphQlWrapper>
  );
};

export default FooterQueryContainer;
