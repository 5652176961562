// Action types
const LOCK = "jola/common/LOCK";
const UNLOCK = "jola/common/UNLOCK";
const CHECK_CUSTOMER_AUTHORIZATION = "jola/common/CHECK_CUSTOMER_AUTHORIZATION";
const FORCE_CHECK_CUSTOMER_AUTHORIZATION =
  "jola/common/FORCE_CHECK_CUSTOMER_AUTHORIZATION";
const CLOSE_ALL_MODALS = "jola/common/CLOSE_ALL_MODALS";
const SHOW_MODAL = "jola/common/SHOW_MODAL";
const CLOSE_MODAL = "jola/common/CLOSE_MODAL";
const ADD_QUERY = "jola/common/ADD_QUERY";
const ADD_QUERY_SUCCESS = "jola/common/ADD_QUERY_SUCCESS";
const ROUTE_CHANGE = "jola/common/ROUTE_CHANGE";
const ROUTE_CHANGE_SUCCESS = "jola/common/ROUTE_CHANGE_SUCCESS";
const SEND_EMAIL = "jola/common/SEND_EMAIl";
const SET_APP_VERSION = "jola/common/SET_APP_VERSION";
const ADD_TO_COMPARE = "jola/common/ADD_TO_COMPARE";
const REMOVE_COMPARE = "jola/common/REMOVE_COMPARE";
const CLEAR_COMPARE = "jola/common/CLEAR_COMPARE";
const SET_COMPARE = "jola/common/SET_COMPARE";
export default {
  LOCK,
  UNLOCK,
  CHECK_CUSTOMER_AUTHORIZATION,
  FORCE_CHECK_CUSTOMER_AUTHORIZATION,
  CLOSE_ALL_MODALS,
  SHOW_MODAL,
  CLOSE_MODAL,
  ADD_QUERY,
  ADD_QUERY_SUCCESS,
  ROUTE_CHANGE,
  ROUTE_CHANGE_SUCCESS,
  SEND_EMAIL,
  SET_APP_VERSION,
  ADD_TO_COMPARE,
  REMOVE_COMPARE,
  CLEAR_COMPARE,
  SET_COMPARE,
};
