import apolloClient from "core/graphql/apolloClient";
import customerActions from "../actions";
import commonActions from "../../common/actions";
import queryLoader from "core/graphql/queryLoader";
import messagesActions from "../../messages/actions";
import queryActions from "../../queries/actions";

export default async (store, action) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("createEmptyCart"),
      variables: action
    });

    store.dispatch(
      customerActions.setCartToken(data.createEmptyCart, action.oldCartToken)
    );
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
