import React, { useReducer, createContext } from "react";
import produce from "immer";

const initialState = {
  price: 0,
  customizationOptionPrice: 0,
  minPrice: 0,
  nailPrice: 0,
  dropdownPrice: 0,
  viewMorePdpPrice: 0,
  trimPrice: 0,
  contrastButtonsPrice: 0,
  contrastWeltPrice: 0,
  decorativeCordPrice: 0,
  fringePrice: 0,
  throwPillowContrastWeltPrice: 0,
  throwPillowDecorativeCordPrice: 0,
  throwPillowFringePrice: 0,
  kidneyPillowContrastWeltPrice: 0,
  kidneyPillowDecorativeCordPrice: 0,
  kidneyPillowFringePrice: 0,
  baseTrimPrice: 0,
  skirtTrimPrice: 0,
  decorativeTapeFramePrice: 0,
  nailBandPrice: 0,
};

const calculatePrice = (state) =>
  (state.minPrice || 0)
    // TODO: When FF wants add real price
    // (state.customizationOptionPrice || 0) +
    // (state.viewMorePdpPrice || 0) +
    // (state.nailPrice || 0) +
    // (state.dropdownPrice || 0) +
    // (state.contrastWeltPrice || 0) +
    // (state.trimPrice || 0) +
    // (state.contrastButtonsPrice || 0) +
    // (state.decorativeCordPrice || 0) +
    // (state.fringePrice || 0) +
    // (state.throwPillowContrastWeltPrice || 0) +
    // (state.throwPillowDecorativeCordPrice || 0) +
    // (state.throwPillowFringePrice || 0) +
    // (state.kidneyPillowContrastWeltPrice || 0) +
    // (state.kidneyPillowDecorativeCordPrice || 0) +
    // (state.kidneyPillowFringePrice || 0) +
    // (state.baseTrimPrice || 0) +
    // (state.skirtTrimPrice || 0) +
    // (state.decorativeTapeFramePrice || 0) +
    // (state.nailBandPrice || 0)
    .toFixed(2);

const actions = {
  setMinPrice: (state, { minPrice }) => {
    state.minPrice = minPrice;
    state.price = calculatePrice(state);
  },
  setTrimPrice: (state, { trimPrice }) => {
    state.trimPrice = trimPrice;
    state.price = calculatePrice(state);
  },
  setCustomizationOptionPrice: (state, { customizationOptionPrice }) => {
    state.customizationOptionPrice = customizationOptionPrice;
    state.price = calculatePrice(state);
  },
  setViewMorePdpPrice: (state, { viewMorePdpPrice }) => {
    state.viewMorePdpPrice = viewMorePdpPrice;
    state.price = calculatePrice(state);
  },
  setNailPrice: (state, { nailPrice }) => {
    state.nailPrice = nailPrice;
    state.price = calculatePrice(state);
  },
  setDropdownPrice: (state, { dropdownPrice }) => {
    state.dropdownPrice = dropdownPrice;
    state.price = calculatePrice(state);
  },
  setContrastWeltPrice: (state, { contrastWeltPrice }) => {
    state.contrastWeltPrice = contrastWeltPrice;
    state.price = calculatePrice(state);
  },
  setContrastButtonsPrice: (state, { contrastButtonsPrice }) => {
    state.contrastButtonsPrice = contrastButtonsPrice;
    state.price = calculatePrice(state);
  },
  setDecorativeCordPrice: (state, { decorativeCordPrice }) => {
    state.decorativeCordPrice = decorativeCordPrice;
    state.price = calculatePrice(state);
  },
  setFringePrice: (state, { fringePrice }) => {
    state.fringePrice = fringePrice;
    state.price = calculatePrice(state);
  },
  setThrowPillowContrastWeltPrice: (
    state,
    { throwPillowContrastWeltPrice }
  ) => {
    state.throwPillowContrastWeltPrice = throwPillowContrastWeltPrice;
    state.price = calculatePrice(state);
  },
  setThrowPillowDecorativeCordPrice: (
    state,
    { throwPillowDecorativeCordPrice }
  ) => {
    state.throwPillowDecorativeCordPrice = throwPillowDecorativeCordPrice;
    state.price = calculatePrice(state);
  },
  setThrowPillowFringePrice: (state, { throwPillowFringePrice }) => {
    state.throwPillowFringePrice = throwPillowFringePrice;
    state.price = calculatePrice(state);
  },
  setKidneyPillowContrastWeltPrice: (
    state,
    { kidneyPillowContrastWeltPrice }
  ) => {
    state.kidneyPillowContrastWeltPrice = kidneyPillowContrastWeltPrice;
    state.price = calculatePrice(state);
  },
  setKidneyPillowDecorativeCordPrice: (
    state,
    { kidneyPillowDecorativeCordPrice }
  ) => {
    state.kidneyPillowDecorativeCordPrice = kidneyPillowDecorativeCordPrice;
    state.price = calculatePrice(state);
  },
  setKidneyPillowFringePrice: (state, { kidneyPillowFringePrice }) => {
    state.kidneyPillowFringePrice = kidneyPillowFringePrice;
    state.price = calculatePrice(state);
  },
  setBaseTrimPrice: (state, { baseTrimPrice }) => {
    state.baseTrimPrice = baseTrimPrice;
    state.price = calculatePrice(state);
  },
  setSkirtTrimPrice: (state, { skirtTrimPrice }) => {
    state.skirtTrimPrice = skirtTrimPrice;
    state.price = calculatePrice(state);
  },
  setDecorativeTapeFramePrice: (state, { decorativeTapeFramePrice }) => {
    state.decorativeTapeFramePrice = decorativeTapeFramePrice;
    state.price = calculatePrice(state);
  },
  setNailBandPrice: (state, { nailBandPrice }) => {
    state.nailBandPrice = nailBandPrice;
    state.price = calculatePrice(state);
  },
};

const reducer = produce((state, action) =>
  actions.hasOwnProperty(action.type)
    ? actions[action.type](state, action)
    : state
);

const CustomPriceContext = createContext();

const CustomPriceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const contextValue = React.useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return (
    <CustomPriceContext.Provider value={contextValue}>
      {children}
    </CustomPriceContext.Provider>
  );
};

export { CustomPriceProvider };
export default CustomPriceContext;
