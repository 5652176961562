import {
  useCustomerQuery,
  useUpdateCustomerAadChangePasswordMutation,
  useUpdateCustomerMutation,
} from "app/generated/graphql";
import React from "react";
import StateContainer, { StateContainerProps } from "./StateContainer";

const DataContainer: React.FC<
  Omit<StateContainerProps, "customer" | "updateCustomer" | "changePassword">
> = (props) => {
  const { data } = useCustomerQuery({ fetchPolicy: "cache-and-network" });
  const [updateCustomer] = useUpdateCustomerMutation();
  const [
    updateCustomerAndChangePassword,
    { data: customerUpdateAndPasswordData },
  ] = useUpdateCustomerAadChangePasswordMutation();

  return (
    <StateContainer
      {...props}
      customer={data?.customer ?? null}
      updateCustomer={updateCustomer}
      updateCustomerAndChangePassword={updateCustomerAndChangePassword}
      passwordChanged={!!customerUpdateAndPasswordData}
    />
  );
};

export default DataContainer;
