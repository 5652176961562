import { useQuery } from "@apollo/react-hooks";
import React, { useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import useCustomer from "../customer/useCustomer";
import { generateLaneFilerString } from "./functions";
import { CUSTOMER_SEARCH_QUERY, SEARCH_QUERY } from "./queries";

export const useSearch = (
  queryString,
  lane = "",
  productType = 2,
  isExact = false
) => {
  const { isLoggedIn } = useCustomer();
  const token = isLoggedIn();

  const getCategoryName = useCallback(() => {
    const activeTab = document.querySelector(
      "#search-modal-id .horisontal-tab.active"
    );
    return activeTab?.innerText;
  }, [queryString]);
  const searchData = useSelector((state) => state.searchPage.data);

  let pageSize = searchData.searchPageSize;
  let filterLanePart = generateLaneFilerString(lane);
  const filterLanePartJSON = JSON.parse(filterLanePart)
    ? JSON.parse(filterLanePart)
    : null;
  let filters;
  let arrAttributes =
    getCategoryName() === "Products" || getCategoryName() === ""
      ? ["7059"]
      : ["7060"];

  let filterable_attribute_set_name = { in: arrAttributes };

  if (getCategoryName() === "Products") {
    filters = {
      ...filterLanePartJSON,
      ffi_isvisible: { eq: "1" },
      filterable_attribute_set_name: filterable_attribute_set_name,
      category_id: {
        eq: getCategoryName() === "Fabrics and Finishes" ? 2 : productType,
      },
    };
  } else {
    filters = {
      filterable_attribute_set_name: filterable_attribute_set_name,
      category_id: {
        eq: getCategoryName() === "Fabrics and Finishes" ? 2 : productType,
      },
    };
  }

  const FINAL_QUERY = token ? CUSTOMER_SEARCH_QUERY : SEARCH_QUERY;
  const { loading, error, data } = useQuery(FINAL_QUERY, {
    variables: {
      //toLowerCase was added because search value was uppercase even if the typed character is lowercase -
      // so the result in modal and Search results page wasn't the same
      search:
        queryString !== ""
          ? isExact
            ? '"' + queryString.toLowerCase() + '"'
            : queryString.toLowerCase()
          : null,
      // filter: filterLanePart !== "" ? JSON.parse(filterLanePart) : null,
      filter: filters,
      currentPage: 1,
      pageSize: pageSize,
    },
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return <Skeleton width={"100%"} height={"100%"} />;
  }
  if (error) {
    return console.error(error);
  }

  return data;
};
