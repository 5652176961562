import Icon from "app/assets/icon/icon";
import { MDBBtn, MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMediaQuery } from "react-responsive";
import FinishDetail from "./finishDetail";
import FinishItem from "./finishItem";
import FinishItemsSkeleton from "./finishItemsSidebarSkeleton";
import FinishSidebarToolbar from "./finishSidebarToolbar";
import { nailFinishArray } from "app/pages/Custom/haven/data";
import { frameNailSizeArray } from "app/pages/Custom/haven/data";
import { CheckBoxContext } from "../checkboxContext";

export const FinishSidebar = ({
  hideModal,
  setCustomizationOption,
  option,
  finishesArray,
  finishesCategories,
  selectedCategory,
  setSelectedCategory,
  productImage,
  loadingFinishItems,
  nailData,
  defaults,
  isCustom,
  frame,
  categories,
  subId,
  customer,
}) => {
  let [preselection, setPreselection] = React.useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: "600px" });
  const optId = option.option_id;

  const reference = useRef(null);
  const onClickOutside = useCallback(
    (e) => {
      if (!isMobile) {
        if (!reference.current.contains(e.target)) {
          hideModal();
        }
      }
    },
    [isMobile, hideModal]
  );
  const {
    checkBoxOptions,
    setCheckBoxOptions,
    viewMoreOptions,
    setViewMoreOptions,
    selectedFinish,
    setSelectedFinish,
  } = useContext(CheckBoxContext);
  const hasPlaceholderImage = React.useMemo(
    () => productImage?.url.includes("placeholder"),
    [productImage]
  );
  useEffect(() => {
    document.addEventListener("click", onClickOutside);
    return () => {
      document.removeEventListener("click", onClickOutside);
    };
  }, [onClickOutside]);

  const [nailArray, setNailArray] = useState([]);

  const isNailHead = option.title === "Nail Finish";

  useEffect(() => {
    if (option.title.toLowerCase() === "nail size") {
      let localNailArray = [];
      option.value.forEach((val) => {
        const foundNailSize = frameNailSizeArray.find(
          (size) => size.name.toLowerCase() === val.title.toLowerCase()
        );
        foundNailSize.price = parseFloat(parseFloat(val.price).toFixed(2));
        foundNailSize.id = option.option_id;
        foundNailSize.value_string = val.option_type_id;
        localNailArray.push(foundNailSize);
      });
      setNailArray(localNailArray);
    }
  }, [option]);

  return (
    <div ref={reference}>
      <MDBRow id="finish-sidebar">
        <MDBCol className="d-flex flex-column">
          <MDBRow>
            <MDBCol className="bg-default flex-center py-5 sidebar-header">
              {viewDetails ? (
                <p
                  className="body-3"
                  style={{
                    position: "absolute",
                    top: "calc(50% - 15px)",
                    left: "6%",
                    cursor: "pointer",
                    fontWeight: "400",
                  }}
                >
                  <Icon
                    icon="leftMobileWhite"
                    size="3"
                    onClick={() => {
                      setViewDetails(false);
                    }}
                    style={{
                      marginRight: "1rem",
                    }}
                  />
                  Back
                </p>
              ) : (
                <Icon
                  icon="closeWhite"
                  size="4"
                  style={{
                    position: "absolute",
                    top: "calc(50% - 20px)",
                    left: "5%",
                    cursor: "pointer",
                  }}
                  onClick={hideModal}
                />
              )}
              <MDBTypography tag="h2" className="body-3">
                Select {option.title.replace("Base Option", "Floor Option")}
              </MDBTypography>
            </MDBCol>
          </MDBRow>
          {viewDetails && preselection ? (
            <FinishDetail finish={preselection} defaults={defaults} />
          ) : (
            <div className="row finish-sidebar-main-content">
              <MDBCol className="finish-sidebar-main-column">
                {!isMobile && (
                  <MDBRow>
                    <MDBCol className="finish-sidebar-image-container">
                      <img
                        src={
                          hasPlaceholderImage ? frame?.image : productImage?.url
                        }
                        alt={
                          hasPlaceholderImage
                            ? frame?.name
                            : productImage?.label
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                )}
                {!isNailHead && option.title !== "Nail Size" && (
                  <FinishSidebarToolbar
                    categories={categories}
                    finishesCategories={finishesCategories}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    subId={subId}
                  />
                )}
                <MDBRow className="flex-grow-1 items-container">
                  {loadingFinishItems ? (
                    <FinishItemsSkeleton />
                  ) : (
                    <MDBCol>
                      {finishesArray &&
                      finishesArray.items &&
                      finishesArray.items.length > 0 ? (
                        <MDBRow
                          className={`justify-content-center justify-content-sm-start`}
                        >
                          {option.title !== "Nail Finish" &&
                          option.title !== "Nail Size" ? (
                            <>
                              {finishesArray.items.map((item, index) => {
                                return (
                                  <FinishItem
                                    isNailHead={isNailHead}
                                    openedOption={option}
                                    key={item.id}
                                    option={item}
                                    optId={optId}
                                    preselection={preselection}
                                    setPreselection={setPreselection}
                                    index={index}
                                    isMobile={isMobile}
                                    setCustomizationOption={
                                      setCustomizationOption
                                    }
                                    isCustom={isCustom}
                                    hideModal={hideModal}
                                    customer={customer}
                                  />
                                );
                              })}
                            </>
                          ) : option.title !== "Nail Size" ? (
                            <>
                              {nailFinishArray.map((item, index) => {
                                return (
                                  <FinishItem
                                    isNailHead={isNailHead}
                                    key={item.id}
                                    option={item}
                                    optId={optId}
                                    openedOption={option}
                                    preselection={preselection}
                                    setPreselection={setPreselection}
                                    index={index}
                                    setCustomizationOption={
                                      setCustomizationOption
                                    }
                                    isCustom={isCustom}
                                    hideModal={hideModal}
                                    isMobile={isMobile}
                                    customer={customer}
                                  />
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {nailArray.map((item, index) => {
                                return (
                                  <FinishItem
                                    isNailHead={isNailHead}
                                    isNailSize={true}
                                    key={item.id}
                                    option={item}
                                    optId={optId}
                                    openedOption={option}
                                    preselection={preselection}
                                    setPreselection={setPreselection}
                                    nailData={nailData}
                                    index={index}
                                    setCustomizationOption={
                                      setCustomizationOption
                                    }
                                    hideModal={hideModal}
                                    isCustom={isCustom}
                                    isMobile={isMobile}
                                    customer={customer}
                                  />
                                );
                              })}
                            </>
                          )}
                        </MDBRow>
                      ) : (
                        <MDBRow className="empty-fabric-array">
                          <MDBCol>
                            <h4 className="text-primary fwregular text-center">
                              There are currently no fabrics available for this
                              selection.
                            </h4>
                          </MDBCol>
                        </MDBRow>
                      )}
                    </MDBCol>
                  )}
                </MDBRow>
              </MDBCol>
            </div>
          )}
          <MDBRow className="bg-gray-lighten-4 py-4 d-flex align-items-center sidebar-footer">
            <MDBCol
              style={{
                height: "100%",
              }}
              className="sidebar-footer-column"
            >
              <MDBRow className="selected-item">
                <MDBCol>
                  <MDBRow>
                    <MDBCol size="2">
                      <MDBTypography
                        tag="h4"
                        className="body-1 text-default fs-16"
                      >
                        Selected
                      </MDBTypography>
                    </MDBCol>
                    <MDBCol>
                      <MDBTypography
                        tag="h4"
                        className="body-2 text-gray-darken fs-16"
                      >
                        {selectedFinish?.[
                          option.title.toLowerCase().split(" ").join("_")
                        ]?.name
                          ? selectedFinish?.[
                              option.title.toLowerCase().split(" ").join("_")
                            ].name
                          : "None Selected"}
                      </MDBTypography>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBRow className={`w-100`}>
                <MDBCol
                  className={`d-flex justify-content-center align-items-center`}
                >
                  <MDBBtn
                    className="btn-primary continue"
                    onClick={() => hideModal(true)}
                  >
                    Apply
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </div>
  );
};
