export default (state, action) => {
  let { data } = action;
  let customerState = state;
  customerState.data.addresses.forEach((address, index) => {
    if (address.id === data.id) {
      customerState.data.addresses[index] = data;
    }
  });
  return Object.assign({}, state, {
    ...customerState,
  });
};
