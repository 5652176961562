import Icon from "app/assets/icon/icon";
import {
  MDBCol,
  MDBContainer,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React from "react";
import ToggleMenu from "../toggle-menu-state-container";

const Items = (props) => {
  const {
    menuItem,
    items,
    closeBurger,
    toggleHamburger,
    collapseId,
    setCollapseId,
    menuItemSelectedById,
    setMenuItemSelectedById,
  } = props;

  const includedChildren = menuItem.children?.filter(
    (child) => child.include_in_menu === 1
  );
  return (
    <>
      {" "}
      <MDBContainer fluid className="mobile-menu-back-wrapper">
        <MDBRow
          className={`d-flex m-0 toggle-dropdown-heading w-100 align-center align-items-center`}
        >
          <MDBCol
            size="6"
            onClick={(e) => {
              let newMenuItemSelectedById = [...menuItemSelectedById];
              newMenuItemSelectedById.pop();
              setMenuItemSelectedById([...newMenuItemSelectedById]);
              let newCollapseId = [...collapseId];
              newCollapseId.pop();
              setCollapseId([...newCollapseId]);
            }}
          >
            <Icon icon="leftMobile" />
          </MDBCol>
          <MDBCol size="6" className="text-right">
            <MDBTypography tag="span" className="body-1">
              {menuItemSelectedById.length > 0
                ? menuItemSelectedById.length === 1
                  ? menuItemSelectedById[0]?.name
                  : menuItemSelectedById[menuItemSelectedById.length - 1]?.name
                : menuItem.name}
              {/* {menuItem.name} */}
            </MDBTypography>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      {Object.keys(items).map((element, i) => {
        const includedChildren = items[i].children?.filter(
          (child) => child.include_in_menu === 1
        );
        return (
          <MDBNavItem
            key={`mobile-navigation-item-${items[i].id}-${items[i].url_path}`}
          >
            {typeof items[i] !== "undefined" &&
            typeof items[i].children === "undefined" &&
            items[i].include_in_menu ? (
              <MDBNavLink
                className={`text-decoration-none text-uppercase link-mobile`}
                to={`/${items[i].url_path}`}
                onClick={(e) => {
                  if (
                    collapseId.includes(menuItem.id) &&
                    collapseId[collapseId.length - 1] !== menuItem.id
                  ) {
                    e.preventDefault();
                  } else {
                    toggleHamburger(props);
                    closeBurger();
                  }
                }}
              >
                <MDBTypography tag="span" className="body-1">
                  {items[i].name}
                </MDBTypography>
              </MDBNavLink>
            ) : (
              <></>
            )}
            {typeof items[i] !== "undefined" &&
            typeof items[i].children !== "undefined" &&
            (items[i].children.length === 0 || !includedChildren.length) &&
            items[i].include_in_menu ? (
              <MDBNavLink
                className={`text-decoration-none text-uppercase link-mobile`}
                // to={`/${items[i].url_path}`}
                to={`/${
                  //This code was added because the client wanted to redirect Haven Reserve, Urban Living and Senior Living Solutions collections (belong to Upholstery or Casegoods) to build your own collections with the same name
                  // items[i].url_path.includes("haven-reserve")
                  //   ? "collections/build-your-own/haven-reserve"
                  //   : items[i].url_path.includes("urban-living")
                  //   ? "collections/build-your-own/urban-living"
                  //   : items[i].url_path.includes("senior-living-solutions")
                  //   ? "collections/build-your-own/senior-living-solutions"
                  //   : items[i].url_path.includes("monogram")
                  //   ? "collections/build-your-own/monogram"
                  //   : items[i].url_path
                  items[i].url_path
                }`}
                onClick={(e) => {
                  if (
                    collapseId.includes(menuItem.id) &&
                    collapseId[collapseId.length - 1] !== menuItem.id
                  ) {
                    e.preventDefault();
                  } else {
                    toggleHamburger(props);
                    closeBurger();
                  }
                }}
              >
                <MDBTypography tag="span" className="body-1">
                  {items[i].name}
                </MDBTypography>
              </MDBNavLink>
            ) : (
              <></>
            )}
            {typeof items[i] !== "undefined" &&
            typeof items[i].children !== "undefined" &&
            items[i].children.length > 0 &&
            items[i].include_in_menu &&
            includedChildren.length ? (
              <ToggleMenu
                items={items[i].children}
                menuItem={items[i]}
                closeBurger={closeBurger}
                toggleHamburger={toggleHamburger}
                setCollapseId={setCollapseId}
                collapseId={collapseId}
                setMenuItemSelectedById={setMenuItemSelectedById}
                menuItemSelectedById={menuItemSelectedById}
              />
            ) : (
              <></>
            )}
          </MDBNavItem>
        );
      })}
    </>
  );
};

export default Items;
