import Icon from "app/assets/icon/icon";
import { createEnterHandler } from "app/utils/utils";
import clsx from "clsx";
import { MDBTypography } from "mdbreact";
import React from "react";

export interface FilterItemProps {
  onSelect: React.EventHandler<React.SyntheticEvent>;
  disabled?: boolean;
}

const FilterItem: React.FC<FilterItemProps> = ({
  disabled = false,
  ...props
}) => {
  const handleSelect: typeof props.onSelect = React.useCallback(
    (e) => {
      if (!disabled) {
        props.onSelect(e);
      }
    },
    [props, disabled]
  );
  return (
    <div
      className={clsx("px-5 py-4 border-bottom d-flex align-items-center", {
        "opacity-05": disabled,
      })}
      onClick={handleSelect}
      onKeyUp={createEnterHandler(handleSelect)}
      tabIndex={disabled ? -1 : 0}
      role="button"
    >
      <MDBTypography className="m-0 fs-14 fwregular">
        {props.children}
      </MDBTypography>
      <Icon className="ml-auto" icon="rightMobile" />
    </div>
  );
};

export default FilterItem;
