import * as yup from "yup";

export const emailSchema = yup
  .string()
  .required("Email is required.")
  .email("Please enter a valid email address.");

export const phoneSchema = yup
  .string()
  .required("Phone is required.")
  .matches(
    /^\d{3}-\d{3}-\d{4}$/,
    "Phone number must be in the following form: xxx-xxx-xxxx"
  );

export const isEmptyRegex = /^\s*\S[\s\S]*$/;

export const phoneMask = [
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

let countryValue = "";

export const usAndCanZipCodeMask = (selectedCountry: string) => {
  let mask: any = false;

  if (selectedCountry === "US") {
    mask = [/\d/, /\d/, /\d/, /\d/, /\d/];
  }
  if (selectedCountry === "CA") {
    mask = [/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/];
  }
  return mask;
};

export const passwordRequirements = [/[a-z]/, /[A-Z]/, /\d/];

export const passwordMinLength = 10;

export const passwordSchema = yup
  .string()
  .required("Password is required.")
  .min(passwordMinLength, "Password must be at least 10 characters")
  .test(
    "3DifferentClasses",
    "Password must include 3 different classes of characters: lower case, upper case, digits, and/or special characters",
    (value) => {
      return (
        passwordRequirements.filter(
          (requirement) => value && requirement.test(value)
        ).length >= 3
      );
    }
  );

export const contactNameSchema = yup
  .string()
  .required("First name is required.");

export const firstName = yup.string().required("First name is required.");

export const lastName = yup.string().required("Last name is required.");

export const zipCodeSchema = yup
  .string()
  .required("Zip code is required")
  .when("country", {
    is: (country) => {
      countryValue = country?.value;
      return country && (country.value === "US" || country.value === "CA");
    },
    then: () => {
      if (countryValue === "US") {
        return yup
          .string()
          .required("Zip code is required")
          .matches(/^\d{5}$/, "Zip code must consist of 5 digits.");
      } else {
        return yup
          .string()
          .required("Zip code is required")
          .matches(
            /^[a-zA-Z0-9\-\s]{7}$/,
            "Zip code must consist of at least 6 characters."
          );
      }
    },
    //   countryValue === "US"
    //     ? yup.string().matches(/^\d{5}$/, "Zip code must consist of 5 digits.")
    //     : yup
    //         .string()
    //         .matches(
    //           /^[a-zA-Z0-9\-\s]{7}$/,
    //           "Zip code must consist of at least 6 characters."
    //         ),
    otherwise: yup.string(),
  });
