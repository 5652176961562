import Icon from "app/assets/icon/icon";
import { hasId, isDefined } from "app/assets/utils/utils";
import {
  AddItemsToWishlistsMutation,
  DomainProductFragment,
  DomainWishlistFragment,
  DomainWishlistItemFragment,
  ProductInterface,
  WishlistJola,
} from "app/generated/graphql";
import Maybe from "graphql/tsutils/Maybe";
import React from "react";
import { favoritesName, savedBuildsName } from "./config";
import { ProductWithSku, WishlistItemWithProduct } from "./models";

export const getBadges = (product?: Maybe<DomainProductFragment>) =>
  product
    ? Object.entries({
        senior: product?.ffi_lanesl,
        retail: product?.ffi_laners,
        contract: product?.ffi_lanect,
      })
        .filter(([, value]) => !!value)
        .map(([key], i, a) => (
          <div key={key} className={i !== a.length - 1 ? "mr-5" : ""}>
            <Icon icon={key} />
          </div>
        ))
    : null;

export const getFavorites = (wishlists: DomainWishlistFragment[]) =>
  wishlists.find((wishlist) => wishlist?.name === favoritesName) ?? null;
export const getSavedBuilds = (wishlists: DomainWishlistFragment[]) =>
  wishlists.find((wishlist) => wishlist.name === savedBuildsName) ?? null;

export const getIsFavorite = ({
  favorites,
  sku,
}: {
  favorites: WishlistJola;
  sku: ProductInterface["sku"];
}) => !!favorites.items?.find((favorite) => favorite?.product?.sku === sku);

export const getCounts = (wishlists: WishlistJola[]) => {
  const favorites = getFavorites(wishlists);
  const savedBuilds = getSavedBuilds(wishlists);

  return {
    wishlists: wishlists.length ?? 0,
  };
};

export function isWishlistDefined(
  wishlist?: Maybe<DomainWishlistFragment>
): wishlist is DomainWishlistFragment {
  return isDefined(wishlist);
}

export const isWishlistItemDefined = (
  wishlistItem?: Maybe<DomainWishlistItemFragment>
): wishlistItem is WishlistItemWithProduct => {
  return (
    isDefined(wishlistItem) &&
    hasId(wishlistItem) &&
    !!wishlistItem?.product?.sku
  );
};


export const getWishlistResponse = (
  wishlist: Maybe<DomainWishlistFragment>,
  products: ProductWithSku[]
): AddItemsToWishlistsMutation => {
  return {
    __typename: "Mutation",
    addItemsToWishlist: [
      {
        __typename: "WishlistJola",
        ...wishlist,
        items_count: (Number(wishlist?.items_count ?? "0") + 1).toString(),
        items: [
          ...(wishlist?.items ?? []),
          ...products.map(
            (product) =>
              ({
                __typename: "WishlistItemJola",
                id: null,
                product: { __typename: "SimpleProduct", ...product },
              } as const)
          ),
        ],
      },
    ],
  };
};

export const isQuotable = (attribSet: string | undefined | null) => {
  const notQuotableAttribs = ["Fabrics", "Finish", "CustOptProducts"];
  return attribSet && !notQuotableAttribs.includes(attribSet);
}
