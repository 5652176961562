import React from "react";
import { gql } from "graphql.macro";
import CollectionDataContainer from "./collectionCustom-data-container";
import { useQuery } from "@apollo/react-hooks";
import SkeletonCategory from "../../skeleton-category";

const MEDIA_GALLERY_ENTRY = gql`
  query Category($id: String!) {
    categoryList(filters: { ids: { eq: $id } }) {
      how_it_works
      category_video
      media_gallery_entries {
        image_url
      }
    }
  }
`;
const CollectionCustomQueryContainer = (props) => {
  let categoryId = props?.queryProps?.filter?.category_id?.eq;
  const { data, loading, error } = useQuery(MEDIA_GALLERY_ENTRY, {
    variables: { id: categoryId },
  });
  if (loading) return <SkeletonCategory />;
  if (error) return <>ERROR</>;

  let imageAndVideo = data?.categoryList[0];
  let howItWorksId = data?.categoryList[0].how_it_works;
  return (
    <CollectionDataContainer
      howItWorksId={howItWorksId}
      imageAndVideo={imageAndVideo}
      {...props}
    />
  );
};

export default CollectionCustomQueryContainer;
