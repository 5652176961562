import React, { useState } from "react";
import { MDBInput, MDBTypography } from "mdbreact";
import Icon from "app/assets/icon/icon";
import FilterOptionItem from "./filterOptionItem";

const FilterOptionsMobile = (props) => {
  let {
    selectedFilters,
    fabricsAggregations,
    addFilter,
    activeFilters,
    setActiveFilters,
    currentPage,
    setCurrentPage,
  } = props;
  const filter =
    fabricsAggregations &&
    fabricsAggregations.find((filter) => {
      return filter.attribute_code === selectedFilters ? filter : false;
    });

  const [searchValue, setSearchValue] = useState("");
  let filteredOptions = filter.options
    .sort((oldVal, newVal) => {
      return oldVal.label < newVal.label
        ? -1
        : oldVal.label > newVal.label
        ? 1
        : 0;
    })
    .filter((option) => {
      return option.label.toLowerCase().includes(searchValue.toLowerCase());
    });
  return (
    <div className="filter-options-mobile">
      <MDBTypography tag="h5" className="darken-gray-3 filter-title">
        {filter.label.includes("category1")
          ? "Pattern"
          : filter.label.includes("grade")
          ? "Grade"
          : filter.label.includes("collection")
          ? "Collection"
          : filter.label.includes("hexclrgrp")
          ? "Color"
          : filter.label}
      </MDBTypography>
      <MDBInput
        className="filter-search-input"
        hint={`Search ${(
          (selectedFilters === "ffifabrics_collection" && "Collection") ||
          ""
        ).trim()}${(
          (selectedFilters === "ffifabrics_grade" && "Grade") ||
          ""
        ).trim()}${(
          (selectedFilters === "ffifabrics_category1" && "Pattern") ||
          ""
        ).trim()}${(
          (selectedFilters === "ffifabrics_hexclrgrp" && "Color") ||
          ""
        ).trim()}
        `}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      >
        <Icon icon="search" />
      </MDBInput>

      {filteredOptions.length > 0 &&
        filteredOptions.map((option, index) => {
          return (
            <div
              key={`filter-option-${index}`}
              className="filter-option-mobile"
            >
              <FilterOptionItem
                option={option}
                addFilter={addFilter}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                filterCode={filter.attribute_code}
                filterType={filter.type}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          );
        })}
    </div>
  );
};

export default FilterOptionsMobile;
