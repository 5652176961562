import types from "./types";

const changeSearch = search => {
  return {
    type: types.CHANGE_SEARCH,
    search: search
  };
};

const toggleSearch = isShown => {
  return {
    type: types.TOGGLE_SEARCH,
    show: isShown
  };
};

const saveSearch = data => {
  return {
    type: types.SAVE_SEARCH,
    data: data
  };
};

const loadMoreItems = data => {
  return {
    type: types.LOAD_MORE_ITEMS,
    data: data
  };
};

export default {
  changeSearch,
  toggleSearch,
  saveSearch,
  loadMoreItems
};
