import { Action } from "redux";
import { createSelector } from "reselect";

interface NetworkStatusState {
  isOnline: boolean;
}

export const initialNetworkStatusState: NetworkStatusState = {
  isOnline: true,
};

export const toggleIsOnlineType = "jola/networkStatus/online/toggle";
export const createToggleIsOnline = (): Action<typeof toggleIsOnlineType> => ({
  type: toggleIsOnlineType,
});
export type CreateToggleIsOnline = typeof createToggleIsOnline;
export type ToggleIsOnline = ReturnType<CreateToggleIsOnline>;

export type NetworkStatusAction = ToggleIsOnline;

export const networkStatus = (
  state = initialNetworkStatusState,
  action: NetworkStatusAction
): NetworkStatusState => {
  switch (action.type) {
    case toggleIsOnlineType:
      return { ...state, isOnline: !state.isOnline };

    default:
      return state;
  }
};

export const selectNetworkStatus = (state: {
  networkStatus: NetworkStatusState;
}) => state.networkStatus;

export const selectIsOnline = createSelector(
  selectNetworkStatus,
  (networkStatus) => networkStatus.isOnline
);
