import Checkbox from "app/layout/Checkbox";
import React from "react";
import _ from "lodash";
import { SelectedTags, TagCategory, TagId, TagNameAndId } from "../../models";

export interface FilterProps {
  tagCategory: TagCategory;
  tags: TagNameAndId[];
  selectedTags: SelectedTags;
  onToggleTag: (tagId: TagId) => void;
  selectAll: React.ReactNode;
  clearAll: React.ReactNode;
  hasDivider: boolean;
  allFiles: any;
  selectedTab: string;
  selectedCategory: string;
}

const Filter: React.FC<FilterProps> = (props) => {
  return (
    <div>
      {props.tags
        .filter((tag) => {
          //@ts-ignore
          return tag?.file_type[props.selectedTab] === 1;
        })
        .map((tag) => (
          <div key={tag.tag_id} className="mt-4 pt-2 d-flex align-items-center">
            <Checkbox
              checked={!!props.selectedTags[tag.tag_id]}
              onChange={() => {
                props.onToggleTag(tag.tag_id);
              }}
              label={tag.name}
            />
          </div>
        ))}
    </div>
  );
};

export default Filter;
