export const details = [
  "finish",
  "additional_info",
  "materials",
  "available_in_leather",
  "standard_pillows",
  "fabric_base_yardage",
  "leather_base_square_feet",
  "nailhead_options",
  "nailhead_application",
  "tablet",
  "cupholder",
];

export const specifications = [
  "standard_seat",
  "standard_back",
  "weight",
  "cube",
  "spring_construction",
  "casters",
  "moisture_barrier_standard",
  "seat_construction",
  "back_conctruction",
  "country_of_origin",
];

export const dimensions = [
  "width",
  "height",
  "depth",
  "seat_height",
  "inside_seat_depth",
  "inside_seat_width",
  "inside_back_height",
  "office_highest_seat_height",
  "office_lowest_seat_height",
  "office_highest_arm_height",
  "office_lowest_arm_height",
  "reclined_depth",
  "office_highest_overall_height",
  "office_lowest_overall_height",
  "apron_height",
  "full_lift_wall_clearance",
  "tv_recline",
  "frame_highest_depth",
  "frame_lowest_depth",
  "arm_height",
];
export const finishesDetails = [
  "physicial_distressing",
  "chemical_distressing",
  "sheen",
];
