import fabrics from "generation/fabrics";
import { frameNailSizeArray } from "app/pages/Custom/haven/data";
import finishes from "generation/finishes";
import { boolean, string } from "yup";

const nailLogic = (option, optionState, value) => {
  let optionTitle = option?.title ? option.title : option?.option_title;
  let valueTitle =
    optionTitle === "Nail Size"
      ? option?.title
        ? option?.value?.find((val) => val.option_type_id === value)?.title
        : option?.option_value_title
      : optionState["nail_finish"];

  let arr =
    optionTitle === "Nail Size"
      ? frameNailSizeArray
      : finishes.find((finish) => finish.name === "Nail Finish")?.products
          ?.items;

  let findNail = arr?.find((size) => size.name === valueTitle);

  if (findNail?.image) {
    return findNail;
  } else if (findNail) {
    return {
      name: findNail?.name,
      image: { url: findNail?.icon },
    };
  }
};

const findItem = (option, value) => {
  if (typeof value !== "string") return null;

  const prefix = "fabric-";

  return fabrics
    .flatMap((fabric) => fabric.products?.items || [])
    .find((item) => {
      if (!item.sku.includes("fabric-")) {
        return item.sku === prefix + value;
      } else {
        return item.sku === value;
      }
    });
};

const findFinish = (array, value) => {
  if (array.length === 0) {
    var result;
    array.some(
      (o) =>
        (result =
          o.sku === value ? o : findFinish(o?.products?.items || [], value))
    );
    return result;
  } else {
    return array.items.find((item) => item.sku === value);
  }
};

export const showingDataLogic = (optionState, option, key, optionsUpdated) => {
  let optionTitle = option?.title ? option.title : option?.option_title;
  let value = optionState[optionTitle?.toLowerCase()?.split(" ")?.join("_")];

  if (
    key === "upholstery_options" ||
    key === "nailhead_trim_options" ||
    key === "finish_options" ||
    key.includes("pillow")
  ) {
    let notFabric = fabrics.find((fabric) => fabric?.name === optionTitle);
    if (
      (!notFabric &&
        !optionTitle.includes("Nail") &&
        optionTitle !== "Finish") ||
      optionTitle.includes("Nail Band")
    ) {
      return findItem(
        {
          title:
            optionState?.[optionTitle?.toLowerCase()?.split(" ")?.join("_")] &&
            optionState?.[
              optionTitle?.toLowerCase()?.split(" ")?.join("_")
            ].startsWith("11")
              ? "Leather"
              : "Fabric",
        },
        value
      );
    } else {
      if (optionTitle === "Nail Size" || optionTitle === "Nail Finish") {
        return nailLogic(option, optionState, value);
      } else {
        if (optionTitle === "Finish" && optionState["finish"]) {
          let selectedFinishArr = finishes.filter((finish) => {
            if (window.location.pathname.includes("monogram"))
              return finish.id === 2763;
            // return finish.id === 2762 || finish.id === 2774;
          });
          if (option.category_options === "Upholstery Options") {
            let selectedFinishArr1 = finishes.filter((finish) => {
              return finish;
            })[0].products;
            return findFinish(selectedFinishArr1, value);
          } else {
            return findFinish(selectedFinishArr, value);
          }
        } else {
          if (value === undefined) {
            return findItem(option, optionState.contrasting_welt);
          }
          return findItem(option, value);
        }
      }
    }
  } else {
    let foundValue;
    optionsUpdated.forEach((optionUpdated) => {
      if (optionUpdated[key]) {
        optionUpdated[key].map((opt) => {
          if (opt?.value?.length)
            return opt?.value?.find((val) => {
              if (val?.option_type_id === value) {
                foundValue = val;
                return;
              }
            });
          else if (opt?.option_value_id) {
            if (opt?.option_value_id == value) {
              foundValue = opt;
              return;
            }
          }
        });
      }
    });
    return foundValue;
  }
};
