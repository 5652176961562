import { number_format } from "core/components/price/price";
import { MDBTypography } from "mdbreact";
import React from "react";
import { PriceProps } from "../models";

const OptionsPrice: React.FC<PriceProps> = (props) => {
  return (
    // @ts-ignore
    <MDBTypography variant="body-1">
      {props.price !== 0
        ? props.currency + number_format(props.price, 2)
        : props.price === 0
        ? "-"
        : props.price}
    </MDBTypography>
  );
};

export default OptionsPrice;
