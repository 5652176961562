// logic is inversed, true means that an error exists
import { emailValidation } from "app/config/customer/validationRules";

export const validate = (
  firstName,
  lastName,
  email,
  company,
  message,
  zip,
  subject
) => {
  return {
    firstName: firstName?.length === 0,
    lastName: lastName?.length === 0,
    email: email?.length === 0 || ValidateEmail(email),
    // company: company?.length === 0,
    message: message?.length === 0,
    zip: zip?.length === 0,
    subject: subject.length === 0,
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];

  return hasError ? shouldShow : false;
};

function ValidateEmail(mail) {
  if (emailValidation.test(mail)) {
    return false;
  }
  return true;
}
