import React, { useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { Customer } from "app/generated/graphql";
import FilterBy from "./FilterBy";
import Toolbar from "./Toolbar";
import Table from "./Table";
import { MDBTypography } from "mdbreact";
import SingleOrder from "./SingleOrder";
import { useHistory } from "react-router-dom";
import { formatDate } from "app/pages/Cart/utils";
import { QueryLazyOptions } from "@apollo/react-hooks";
import { OperationVariables } from "react-apollo";

export interface CustomerOrdersProps extends Pick<Customer, "firstname"> {
  orders: Customer["orders"];
  form: any;
  currentPage: number;
  setCurrentPage(page: number): void;
  totalPages: number;
  setPageSize(page: number): void;
  pageSize: number;
  pageSizeOptions: { label: string; value: number }[];
  setSortingValues: () => {};
  location: {
    hash: string;
    key: string;
    pathname: string;
    search: string;
    state: any;
  };
  setSortData: { sorting_attribute: string; sorting_direction: string };
  customer: any;
  resetFilters: void;
  getOrderPdf: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void;
  orderNumber: string;
}

const AccountCustomerOrders: React.FC<CustomerOrdersProps> = (props) => {
  let isMobile = useMediaQuery({ maxWidth: 575.98 });
  let isTablet = useMediaQuery({ maxWidth: 991 });
  const history = useHistory();
  const {
    currentPage,
    setCurrentPage,
    totalPages,
    pageSize,
    setPageSize,
    pageSizeOptions,
    location,
    setSortData,
    resetFilters,
    getOrderPdf,
    orderNumber,
  } = props;

  const ordersHead = useMemo(() => {
    if (location?.search)
      return [
        { title: "" },
        { title: "SKU" },
        { title: "Product Name" },
        { title: "Starting At" },
        { title: "QTY Ordered" },
        // { title: "QTY Shipped" },
        { title: "Starting At Subtotal" },
      ];
    return [
      {
        title: "Quote Request #",
        attribute: "number",
      },
      {
        title: "Date",
        attribute: "created_at",
      },
      {
        title: "Ship To",
        attribute: "name",
      },
      {
        title: "Quote Total",
        attribute: "grand_total",
      },
      {
        title: "Status",
        attribute: "status",
      },
      {
        title: "Action",
        attribute: false,
      },
    ];
  }, [location]);

  return (
    <div className="quote-request-history">
      <div className="d-flex border-bottom-1 pb-3 mb-5 justify-content-between align-items-center">
        <MDBTypography tag="h1">
          {`Quote Request ${location.search ? `#${orderNumber}` : "History"}`}
        </MDBTypography>
        {location.search ? (
          <div className="d-flex">
            <MDBTypography
              // @ts-ignore
              variant="body-1"
              className="underline text-center cursor-pointer"
              onClick={() =>
                getOrderPdf({ variables: { order_increment_id: orderNumber } })
              }
            >
              Print Request
            </MDBTypography>
            <MDBTypography
              // @ts-ignore
              variant="body-1"
              className="underline text-center ml-4 cursor-pointer"
              onClick={() =>
                history.push("/customer/account/request-quote-history")
              }
            >
              Back
            </MDBTypography>
          </div>
        ) : null}
      </div>
      {location.search ? (
        <SingleOrder
          customer={props?.customer}
          orderNumber={orderNumber}
          formatDate={formatDate}
          isTablet={isTablet}
          isMobile={isMobile}
          ordersHead={ordersHead}
          location={location}
        />
      ) : (
        <>
          <FilterBy
            form={props.form}
            formatDate={formatDate}
            resetFilters={resetFilters}
          />
          <Toolbar
            // @ts-ignore
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageSizeOptions={pageSizeOptions}
            totalCount={props?.customer?.orders?.total_count}
            isTablet={isTablet}
          />
          <Table
            data={props?.orders}
            // @ts-ignore
            setSortData={setSortData}
            ordersHead={ordersHead}
            location={location}
            wrapperClassName="table-wrapper"
          />
          <Toolbar
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageSizeOptions={pageSizeOptions}
            totalCount={props?.customer?.orders?.total_count}
            isTablet={isTablet}
          />
        </>
      )}
    </div>
  );
};
export default AccountCustomerOrders;
