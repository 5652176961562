import themeConfiguration from "../../../../config/themeConfiguration";

export const tops = [
  {
    height:
      "Large Bistro Tables, Large Pub Tables, Large Dining Tables, Large Adjustable Height Dining Tables",
    sku: "CW09-DT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/CW09-DT.jpg`,
    size: '109" Dining Table Top',
    finish: "Crotch Walnut",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Crotch Walnut.jpg`,
    large: true,
    relatedsku: [
      "LEAS-DT",
      "LEAS-BT",
      "LEAS-PT",
      "LEAW-DT",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-DT-1",
      "LEMW-PT-2",
      "LETZ-DT-1",
      "LETZ-BT-1",
      "LETZ-PT-2",
      "LETZ-PT-1",
      "LETZ-DT-2",
      "LETZ-DT-3",
      "LETZ-BT-3",
      "LETZ-BT-2",
      "LETZ-PT-3",
      "8186-TB",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "LEDT-DT",
      "LESH-DT-3",
      "LEDX-DT",
      "8185-BB",
      "L002-TB",
    ],
  },
  {
    height:
      "Large Bistro Tables, Large Pub Tables, Large Dining Tables, Large Adjustable Height Dining Tables",
    sku: "LE09-DT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE09-DT.jpg`,
    size: '109" Dining Table Top',
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    large: true,
    relatedsku: [
      "LEAS-DT",
      "LEAS-BT",
      "LEAS-PT",
      "LEAW-DT",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-DT-1",

      "LEMW-PT-3",
      "LETZ-DT-1",
      "LETZ-BT-1",
      "LETZ-PT-3",
      "LETZ-PT-1",
      "LETZ-DT-3",
      "LETZ-DT-3",
      "LETZ-BT-3",
      "LETZ-BT-3",
      "LETZ-PT-3",
      "8186-TB",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "LEDT-DT",
      "LESH-DT-3",
      "LEDX-DT",
      "8185-BB",

      "L002-TB",
    ],
  },
  {
    height:
      "Large Bistro Tables, Large Pub Tables, Large Dining Tables, Large Adjustable, Height Dining Tables",
    sku: "CW09-DT-R",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/CW09-DT-R.jpg`,
    size: '109" River Dining Table Top',

    finish: "Crotch Walnut",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Crotch Walnut.jpg`,
    large: true,
    relatedsku: [
      "LEAS-DT",
      "LEAS-BT",
      "LEAS-PT",
      "LEAW-DT",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-DT-1",

      "LEMW-PT-2",
      "LETZ-DT-1",
      "LETZ-BT-1",
      "LETZ-PT-2",

      "LETZ-DT-3",
      "LETZ-BT-3",

      "LETZ-PT-3",
      "8186-TB",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "LEDT-DT",
      "LETW-DT",

      "LEDX-DT",
      "8185-BB",
    ],
  },
  {
    height:
      "Large Bistro Tables, Large Pub Tables, Large Dining Tables, Large Adjustable Height Dining Tables",
    sku: "CW09-DT-A",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/CW09-DT-A.jpg`,
    size: '109" River Arctic Dining Table Top',
    finish: "Crotch Walnut",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Crotch Walnut.jpg`,
    large: true,
    relatedsku: [
      "LEAS-DT",
      "LEAS-BT",
      "LEAS-PT",
      "LEAW-DT",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-DT-1",
      "LEMW-PT-2",
      "LETZ-DT-1",
      "LETZ-BT-1",
      "LETZ-PT-2",
      "LETZ-DT-3",
      "LETZ-BT-3",
      "LETZ-PT-3",
      "8186-TB",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "LEDT-DT",
      "LETW-DT",

      "LEDX-DT",
      "8185-BB",
    ],
  },
  {
    height:
      "Large Bistro Tables, Large Pub Tables, Large Dining Tables, Large Adjustable Height Dining Tables",
    sku: "HG09-DT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HG09-DT.jpg`,
    size: '109" Dining Table Top',
    finish: "Heritage Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Heritage Guanacaste.jpg`,
    large: true,
    relatedsku: [
      "LEAS-DT",
      "LEAS-BT",
      "LEAS-PT",
      "LEAW-DT",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-DT-1",

      "LEMW-PT-4",
      "LETZ-DT-1",
      "LETZ-BT-1",
      "LETZ-PT-4",
      "LETZ-PT-1",
      "LETZ-DT-4",
      "LETZ-DT-3",
      "LETZ-BT-3",
      "LETZ-BT-4",
      "LETZ-PT-3",
      "8186-TB",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "LEDT-DT",
      "LESH-DT-3",
      "LEDX-DT",
      "8185-BB",

      "L002-TB",
    ],
  },
  {
    height:
      "Large Bistro Tables, Large Pub Tables, Large Dining Tables, Large Adjustable Height Dining Tables",
    sku: "CW84-DT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/CW84-DT.jpg`,
    size: '84" Dining Table Top',
    finish: "Crotch Walnut",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Crotch Walnut.jpg`,
    large: true,
    relatedsku: [
      "LEAS-DT",
      "LEAS-BT",
      "LEAS-PT",
      "LEAW-DT",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-DT-1",

      "LEMW-PT-5",
      "LETZ-DT-1",
      "LETZ-BT-1",
      "LETZ-PT-5",
      "LETZ-PT-1",
      "LETZ-DT-5",
      "LETZ-DT-3",
      "LETZ-BT-3",
      "LETZ-BT-5",
      "LETZ-PT-3",
      "8186-TB",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "LEDT-DT",
      "LESH-DT-3",
      "LEDX-DT",
      "8185-BB",
    ],
  },
  {
    height:
      "Large Bistro Tables, Large Pub Tables, Large Dining Tables, Large Adjustable Height Dining Tables",
    sku: "LE84-DT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE84-DT.jpg`,
    size: '84" Dining Table Top',
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    large: true,
    relatedsku: [
      "LEAS-DT",
      "LEAS-BT",
      "LEAS-PT",
      "LEAW-DT",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-DT-1",
      "LEMW-PT-6",
      "LETZ-DT-1",
      "LETZ-BT-1",
      "LETZ-PT-6",
      "LETZ-PT-1",
      "LETZ-DT-6",
      "LETZ-DT-3",
      "LETZ-BT-3",
      "LETZ-BT-6",
      "LETZ-PT-3",
      "8186-TB",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "LEDT-DT",
      "LESH-DT-3",
      "LEDX-DT",
      "8185-BB",
      "LETW-DT",
    ],
  },
  {
    height:
      "Large Bistro Tables, Large Pub Tables, Large Dining Tables, Large Adjustable Height Dining Tables",
    sku: "HG84-DT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HG84-DT.jpg`,
    size: '84" Dining Table Top',
    finish: "Heritage Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Heritage Guanacaste.jpg`,
    large: true,
    relatedsku: [
      "LEAS-DT",
      "LEAS-BT",
      "LEAS-PT",
      "LEAW-DT",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-DT-1",

      "LEMW-PT-7",
      "LETZ-DT-1",
      "LETZ-BT-1",
      "LETZ-PT-7",
      "LETZ-PT-1",
      "LETZ-DT-7",
      "LETZ-DT-3",
      "LETZ-BT-3",
      "LETZ-BT-7",
      "LETZ-PT-3",
      "8186-TB",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "LEDT-DT",
      "LESH-DT-3",
      "LEDX-DT",
      "8185-BB",
      "LETW-DT",
    ],
  },
  {
    height:
      "Large Bistro Tables, Large Pub Tables, Large Dining Tables, Large Adjustable Height Dining Tables",
    sku: "CW84-DT-R",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/CW84-DT-R.jpg`,
    size: '84" River Dining Table Top',
    finish: "Crotch Walnut",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Crotch Walnut.jpg`,
    large: true,
    relatedsku: [
      "LEAS-DT",
      "LEAS-BT",
      "LEAS-PT",
      "LEAW-DT",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-DT-1",
      "LEMW-PT-5",
      "LETZ-DT-1",
      "LETZ-BT-1",
      "LETZ-PT-5",
      "LETZ-DT-3",
      "LETZ-BT-3",
      "LETZ-PT-3",
      "8186-TB",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "LEDT-DT",
      "LEDX-DT",
      "8185-BB",
      "LETW-DT",
    ],
  },
  {
    height:
      "Large Bistro Tables, Large Pub Tables, Large Dining Tables, Large Adjustable Height Dining Tables",
    sku: "CW84-DT-A",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/CW84-DT-A.jpg`,
    size: '84" River Arctic Dining Table Top',
    finish: "Crotch Walnut",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Crotch Walnut.jpg`,
    large: true,
    relatedsku: [
      "LEAS-DT",
      "LEAS-BT",
      "LEAS-PT",
      "LEAW-DT",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-DT-1",

      "LEMW-PT-5",
      "LETZ-DT-1",
      "LETZ-BT-1",
      "LETZ-PT-5",

      "LETZ-DT-3",
      "LETZ-BT-3",

      "LETZ-PT-3",
      "8186-TB",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "LEDT-DT",

      "LEDX-DT",
      "8185-BB",
      "LETW-DT",
    ],
  },
  {
    height: "Large Bistro Tables, Large Pub Tables, Large Dining Tables",
    sku: "CW72-BT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/CW72-BT.jpg`,
    size: '72" Dining Table Top',
    finish: "Crotch Walnut",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Crotch Walnut.jpg`,
    large: true,
    relatedsku: [
      "LEAS-DT",
      "LEAS-BT",
      "LEAS-PT",
      "LEAW-DT",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-DT-1",

      "LEMW-PT-8",
      "LETZ-DT-1",
      "LETZ-BT-1",
      "LETZ-PT-8",
      "LETZ-PT-1",
      "LETZ-DT-8",
      "LETZ-DT-3",
      "LETZ-BT-3",
      "LETZ-BT-8",
      "LETZ-PT-3",
      "8186-TB",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "LEDT-DT",
      "LESH-DT-3",
      "LEDX-DT",
      "8185-BB",
    ],
  },
  {
    height: "Large Bistro Tables, Large Pub Tables, Large Dining Tables",
    sku: "LE72-BT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE72-BT.jpg`,
    size: '72" Rectangular Bar Table Top',
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    large: true,
    relatedsku: [
      "LEAS-DT",
      "LEAS-BT",
      "LEAS-PT",
      "LEAW-DT",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-DT-1",

      "LEMW-PT-9",
      "LETZ-DT-1",
      "LETZ-BT-1",
      "LETZ-PT-9",
      "LETZ-PT-1",
      "LETZ-DT-9",
      "LETZ-DT-3",
      "LETZ-BT-3",
      "LETZ-BT-9",
      "LETZ-PT-3",
      "8186-TB",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "LEDT-DT",
      "LESH-DT-3",
      "LEDX-DT",
      "8185-BB",
    ],
  },
  {
    height: "Large Bistro Tables, Large Pub Tables, Large Dining Tables",
    sku: "HG72-BT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HG72-BT.jpg`,
    size: '72" Dining Table Top',
    finish: "Heritage Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Heritage Guanacaste.jpg`,
    large: true,
    relatedsku: [
      "LEAS-DT",
      "LEAS-BT",
      "LEAS-PT",
      "LEAW-DT",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-DT-1",

      "LEMW-PT-10",
      "LETZ-DT-1",
      "LETZ-BT-1",
      "LETZ-PT-10",
      "LETZ-PT-1",
      "LETZ-DT-10",
      "LETZ-DT-3",
      "LETZ-BT-3",
      "LETZ-BT-10",
      "LETZ-PT-3",
      "8186-TB",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "LEDT-DT",
      "LESH-DT-3",
      "LEDX-DT",
      "8185-BB",
    ],
  },
  {
    height: "Cocktail Tables, Console Tables",
    sku: "CW64-TP",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/CW64-TP.jpg`,
    size: '64" Table Top',
    finish: "Crotch Walnut",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Crotch Walnut.jpg`,
    large: true,
    relatedsku: ["HP16-4B", "HP16-4G", "HP32-4B", "HP32-4G"],
  },
  {
    height: "Cocktail Tables, Console Tables",
    sku: "HG54-RC",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HG54-RC.jpg`,
    size: '54" Rectangular Cocktail Table Top',
    finish: "Heritage Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Heritage Guanacaste.jpg`,
    large: true,
    relatedsku: ["HP16-4B", "HP16-4G", "HP32-4B", "HP32-4G"],
  },
  {
    height: "Cocktail Tables, Console Tables",
    sku: "LE54-RC",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE54-RC.jpg`,
    size: '54" Rectangular Cocktail Table Top',
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    large: true,
    relatedsku: ["HP16-4B", "HP16-4G", "HP32-4B", "HP32-4G"],
  },
  // {
  //   height:
  //     'Large Adjustable Height Dining Tables, Adjustable Height Desks, Stationary Desks',
  //   sku: 'CW54-DK',
  //   modelImage: 'images/models/CW54-DK.jpg',
  //   size: '54" Desk Top',
  //   finish: 'Crotch Walnut',
  //   finishImage: 'images/materials/Crotch Walnut.jpg',
  //   relatedsku: ['L003-TB-2', 'L003-TB-4', 'L003-TB-5', 'LEMB-FD', 'L001-TB'],
  // },
  // {
  //   height:
  //     'Large Adjustable Height Dining Tables, Adjustable Height Desks, Stationary Desks',
  //   sku: 'HG54-DK',
  //   modelImage: 'images/models/HG54-DK.jpg',
  //   size: '54" Desk Top',
  //   finish: 'Heritage Guanacaste',
  //   finishImage: 'images/materials/Heritage Guanacaste.jpg',
  //   relatedsku: ['L003-TB-2', 'L003-TB-4', 'L003-TB-5', 'LEMB-FD', 'L001-TB'],
  // },
  // {
  //   height:
  //     'Large Adjustable Height Dining Tables, Adjustable Height Desks, Stationary Desks',
  //   sku: 'LE54-DK',
  //   modelImage: 'images/models/LE54-DK.jpg',
  //   size: '54" Desk Top',
  //   finish: 'Natural Guanacaste',
  //   finishImage: 'images/materials/Natural Guanacaste.jpg',
  //   relatedsku: ['L003-TB-2', 'L003-TB-4', 'L003-TB-5', 'LEMB-FD', 'L001-TB'],
  // },
  {
    sku: "CW54-WC",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/CW54-WC.jpg`,
    height: "NON-CONFIG",
    size: '54" Waterfall Cocktail Table with Acrylic Square Base',
    finish: "Crotch Walnut",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Crotch Walnut.jpg`,
    large: true,
    relatedsku: ["CW54-WC"],
  },
  {
    sku: "HG54-WC",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HG54-WC.jpg`,
    height: "NON-CONFIG",
    size: '54" Waterfall Cocktail Table with Acrylic Square Base',
    finish: "Heritage Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Heritage Guanacaste.jpg`,
    large: true,
    relatedsku: ["HG54-WC"],
  },
  {
    sku: "LE54-WC",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE54-WC.jpg`,
    height: "NON-CONFIG",
    size: '54" Waterfall Cocktail Table with Acrylic Square Base',
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    large: true,
    relatedsku: ["LE54-WC"],
  },
  {
    sku: "T-CW54-WC",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/T-CW54-WC.jpg`,
    height: "NON-CONFIG",
    size: '54" Waterfall Cocktail with Trapezoid Base',
    finish: "Crotch Walnut",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Crotch Walnut.jpg`,
    large: true,
    relatedsku: ["T-CW54-WC"],
  },
  {
    sku: "T-HG54-WC",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/T-HG54-WC.jpg`,
    height: "NON-CONFIG",
    size: '54" Waterfall Cocktail with Trapezoid Base',
    finish: "Heritage Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Heritage Guanacaste.jpg`,
    large: true,
    relatedsku: ["T-HG54-WC"],
  },
  {
    sku: "T-LE54-WC",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/T-LE54-WC.jpg`,
    height: "NON-CONFIG",
    size: '54" Waterfall Cocktail with Trapezoid Base',
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    large: true,
    relatedsku: ["T-LE54-WC"],
  },

  {
    sku: "CW54-WD",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/CW54-WD.jpg`,
    height: "NON-CONFIG",
    size: '54" Waterfall Desk with Acrylic Square Base',
    finish: "Crotch Walnut",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Crotch Walnut.jpg`,
    large: true,
    relatedsku: ["CW54-WD"],
  },
  {
    sku: "HG54-WD",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HG54-WD.jpg`,
    height: "NON-CONFIG",
    size: '54" Waterfall Desk with Acrylic Square Base',
    finish: "Heritage Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Heritage Guanacaste.jpg`,
    large: true,
    relatedsku: ["HG54-WD"],
  },
  {
    sku: "LE54-WD",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE54-WD.jpg`,
    height: "NON-CONFIG",
    size: '54" Waterfall Desk with Acrylic Square Base',
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    large: true,
    relatedsku: ["LE54-WD"],
  },
  {
    sku: "T-CW54-WD",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/T-CW54-WD.jpg`,
    height: "NON-CONFIG",
    size: '54" Waterfall Desk with Trapezoid Base',
    finish: "Crotch Walnut",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Crotch Walnut.jpg`,
    large: true,
    relatedsku: ["T-CW54-WD"],
  },
  {
    sku: "T-HG54-WD",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/T-HG54-WD.jpg`,
    height: "NON-CONFIG",
    size: '54" Waterfall Desk with Trapezoid Base',
    finish: "Heritage Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Heritage Guanacaste.jpg`,
    large: true,
    relatedsku: ["T-HG54-WD"],
  },
  {
    sku: "T-LE54-WD",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/T-LE54-WD.jpg`,
    height: "NON-CONFIG",
    size: '54" Waterfall Desk with Trapezoid Base',
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    large: true,
    relatedsku: ["T-LE54-WD"],
  },
  {
    sku: "NM54-DK",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/NM54-DK.jpg`,
    height: "NON-CONFIG",
    size: '54" Natural Maple Desk Top',
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    large: true,
    relatedsku: ["L001-TB"],
  },
  {
    sku: "NM54-WD",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/NM54-WD.jpg`,
    height: "NON-CONFIG",
    size: '54" Natural Maple Waterfall Desk',
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    large: true,
    relatedsku: ["LEAS-DT"],
  },
  {
    height: "Round Dining Tables, Round Pub Tables, Round Bistro Tables",
    sku: "LE48-RT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE48-RT.jpg`,
    size: '48" Round Table Top',
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    medium: true,
    relatedsku: [
      "8192-TB",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-BT",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "8185-BB",
      "8186-TB",
      "8186-CB",
    ],
  },
  {
    height: "Round Dining Tables, Round Pub Tables, Round Bistro Tables",
    sku: "HG48-RT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HG48-RT.jpg`,
    size: '48" Round Table Top',
    finish: "Heritage Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Heritage Guanacaste.jpg`,
    medium: true,
    relatedsku: [
      "8192-TB",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-BT",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "8185-BB",
      "8186-TB",
      "8186-CB",
    ],
  },
  {
    height: "Square Dining Tables, Square Pub Tables, Square Bistro Tables",
    sku: "LE48-CT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE48-CT.jpg`,
    size: '48" Café Table Top',
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    medium: true,
    relatedsku: [
      "8192-TB",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-BT",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "8185-BB",
      "8186-TB",
      "8186-CB",
    ],
  },
  {
    height: "Square Dining Tables, Square Pub Tables, Square Bistro Tables",
    sku: "HG48-CT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HG48-CT.jpg`,
    size: '48" Café Table Top',
    finish: "Heritage Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Heritage Guanacaste.jpg`,
    medium: true,
    relatedsku: [
      "8192-TB",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-BT",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "8185-BB",
      "8186-TB",
      "8186-CB",
    ],
  },
  {
    height:
      "Round Dining Tables, Round Pub Tables, Round Bistro Tables, End Tables, Cocktail Tables, Console Tables",
    sku: "LE38-RT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE38-RT.jpg`,
    size: '38" Round Table Top',
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    medium: true,
    relatedsku: [
      "8192-TB",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-BT",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "8185-BB",
      "8186-TB",
      "8186-CB",
      "HP16-4B",
      "HP16-4G",
      "HP32-4B",
      "HP32-4G",
      "YOYO-14-1",
      "YOYO-14-7",
      "LE16-3B",
      "LE20-3B",
      "LEMW-BT-1",
      "LEMW-PT-1",
    ],
  },
  {
    height: "Round Dining Tables, Round Pub Tables, Round Bistro Tables",
    sku: "HG38-RT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HG38-RT.jpg`,
    size: '38" Round Table Top',
    finish: "Heritage Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Heritage Guanacaste.jpg`,
    medium: true,
    relatedsku: [
      "8192-TB",
      "LEAW-BT",
      "LEAW-PT",
      "LEMW-BT",
      "8188-TB",
      "8184-TB",
      "8184-BB",
      "8185-TB",
      "8185-BB",
      "8186-TB",
      "8186-CB",
      "HP16-4B",
      "HP16-4G",
      "HP32-4B",
      "HP32-4G",
      "YOYO-14-1",
      "YOYO-14-7",
      "LE16-3B",
      "LE20-3B",
    ],
  },
  {
    sku: "LE38-SR",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE38-SR.jpg`,
    height: "NON-CONFIG",
    size: '38" Diameter Two Piece Cocktail Table',
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    medium: true,
    relatedsku: ["LE38-SR"],
  },

  {
    height: "End Tables, Cocktail Tables, Console Tables",
    sku: "HG22-AT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HG22-AT.jpg`,
    size: '22" Round Accent Table Top',
    finish: "Heritage Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Heritage Guanacaste.jpg`,
    small: true,
    relatedsku: [
      "HP16-4B",
      "HP16-4G",
      "HP32-4B",
      "HP32-4G",
      "YOYO-14-1",
      "YOYO-14-7",
      "LE16-3B",
      "LE20-3B",
    ],
  },
  {
    height: "End Tables, Cocktail Tables, Console Tables",
    sku: "HG22-ET",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HG22-ET.jpg`,
    size: '22" Square Lamp Table Top',
    finish: "Heritage Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Heritage Guanacaste.jpg`,
    small: true,
    relatedsku: ["HP16-4B", "HP16-4G", "HP32-4B", "HP32-4G"],
  },
  {
    height: "End Tables, Cocktail Tables, Console Tables",
    sku: "LE22-AT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE22-AT.jpg`,
    size: '22" Round Accent Table Top',
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    small: true,
    relatedsku: [
      "HP16-4B",
      "HP16-4G",
      "HP32-4B",
      "HP32-4G",
      "YOYO-14-1",
      "YOYO-14-7",
      "LE16-3B",
      "LE20-3B",
    ],
  },
  {
    height: "End Tables, Cocktail Tables, Console Tables",
    sku: "LE22-ET",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE22-ET.jpg`,
    size: '22" Square Lamp Table Top',
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    small: true,
    relatedsku: ["HP16-4B", "HP16-4G", "HP32-4B", "HP32-4G"],
  },

  {
    height: "End Tables, Cocktail Tables, Console Tables",
    sku: "LE14-AT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE14-AT.jpg`,
    size: '14" Round Accent Table Top',
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    small: true,
    relatedsku: ["YOYO-14-1", "YOYO-14-7", "LE16-3B", "LE20-3B"],
  },
  {
    height: "Console Tables",
    sku: "LE38-96",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE38-96.jpg`,
    size: "Demilune Top",
    finish: "Natural Guanacaste",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Natural Guanacaste.jpg`,
    small: true,
    relatedsku: ["HP32-3G", "HP32-3B"],
  },

  {
    sku: "CW56-96",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/CW56-96.jpg`,
    height: "NON-CONFIG",
    size: "Crotch Walnut Console Table",
    finish: "Crotch Walnut",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Crotch Walnut.jpg`,
    large: true,
    relatedsku: ["CW56-96"],
  },
];

export const bases = [
  {
    height: "Bistro",
    sku: "LEAS-BT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LEAS-BT.jpg`,
    name: "Bistro Height Acrylic Square Base",
    finish: "Acrylic",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Acrylic.jpg`,
    topPairing: {
      large: "sides",
    },
  },

  {
    height: "Dining",
    sku: "LEAS-DT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LEAS-DT.jpg`,
    name: "Dining Height Acrylic Square Base",
    finish: "Acrylic",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Acrylic.jpg`,
    topPairing: {
      large: "sides",
    },
  },
  {
    height: "Pub",
    sku: "LEAS-PT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LEAS-PT.jpg`,
    name: "Pub Height Acrylic Square Base",
    finish: "Acrylic",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Acrylic.jpg`,
    topPairing: {
      large: "sides",
    },
  },
  {
    height: "Bistro",
    sku: "LEAW-BT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LEAW-BT.jpg`,
    name: "Bistro Height Acrylic Wedge Base",
    finish: "Acrylic",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Acrylic.jpg`,
    topPairing: {
      large: "sides",
      medium: "inner",
    },
  },
  {
    height: "Dining",
    sku: "LEAW-DT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LEAW-DT.jpg`,
    name: "Dining Height Acrylic Wedge Base",
    finish: "Acrylic",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Acrylic.jpg`,
    topPairing: {
      large: "sides",
      medium: "inner",
    },
  },
  {
    height: "Pub",
    sku: "LEAW-PT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LEAW-PT.jpg`,
    name: "Pub Height Acrylic Wedge Base",
    finish: "Acrylic",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Acrylic.jpg`,
    topPairing: {
      large: "sides",
      medium: "inner",
    },
  },
  // {
  //   height: 'Pub',
  //   sku: 'LEMW-BT-1',
  //   modelImage: 'images/models/LEMW-BT-1.jpg',
  //   name: 'Pub Height Metal Wedge Base',
  //   finish: 'Bronze',
  //   finishImage: 'images/materials/Bronze.jpg',
  // },
  {
    height: "Pub",
    sku: "LEMW-DT-1",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LEMW-DT-1.jpg`,
    name: "Pub Height Metal Wedge Base",
    finish: "Bronze",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Bronze.jpg`,
    topPairing: {
      large: "sides",
    },
  },
  {
    height: "Pub",
    sku: "LEMW-PT-2",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LEMW-PT-2.jpg`,
    name: "Pub Height Metal Wedge Base",
    finish: "Nickel",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Nickel.jpg`,
    topPairing: {
      large: "sides",
    },
  },
  {
    height: "Bistro",
    sku: "LETZ-BT-1",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LETZ-BT-1.jpg`,
    name: "Bistro Height Metal Trapezoid Base",
    finish: "Bronze",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Bronze.jpg`,
    topPairing: {
      large: "sides",
    },
  },
  // {
  //   height: 'Bistro',
  //   sku: 'LETZ-BT-2',
  //   modelImage: 'images/models/LETZ-BT-2.jpg',
  //   name: 'Bistro Height Metal Trapezoid Base',
  //   finish: 'Nickel',
  //   finishImage: 'images/materials/Nickel.jpg',
  // },
  {
    height: "Bistro",
    sku: "LETZ-BT-3",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LETZ-BT-3.jpg`,
    name: "Bistro Height Metal Trapezoid Base",
    finish: "Black Nickel",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Black Nickel.jpg`,
    topPairing: {
      large: "sides",
    },
  },
  {
    height: "Dining",
    sku: "LETZ-DT-1",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LETZ-DT-1.jpg`,
    name: "Dining Height Metal Trapezoid Base",
    finish: "Bronze",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Bronze.jpg`,
    topPairing: {
      large: "sides",
    },
  },
  // {
  //   height: 'Dining',
  //   sku: 'LETZ-DT-2',
  //   modelImage: 'images/models/LETZ-DT-2.jpg',
  //   name: 'Dining Height Metal Trapezoid Base',
  //   finish: 'Nickel',
  //   finishImage: 'images/materials/Nickel.jpg',
  // },
  {
    height: "Dining",
    sku: "LETZ-DT-3",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LETZ-DT-3.jpg`,
    name: "Dining Height Metal Trapezoid Base",
    finish: "Black Nickel",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Black Nickel.jpg`,
    topPairing: {
      large: "sides",
    },
  },
  // {
  //   height: 'Pub',
  //   sku: 'LETZ-PT-1',
  //   modelImage: 'images/models/LETZ-PT-1.jpg',
  //   name: 'Pub Height Metal Trapezoid Base',
  //   finish: 'Bronze',
  //   finishImage: 'images/materials/Bronze.jpg',
  // },
  {
    height: "Pub",
    sku: "LETZ-PT-2",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LETZ-PT-2.jpg`,
    name: "Pub Height Metal Trapezoid Base",
    finish: "Nickel",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Nickel.jpg`,
    topPairing: {
      large: "sides",
    },
  },
  {
    height: "Pub",
    sku: "LETZ-PT-3",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LETZ-PT-3.jpg`,
    name: "Pub Height Metal Trapezoid Base",
    finish: "Black Nickel",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Black Nickel.jpg`,
    topPairing: {
      large: "sides",
    },
  },
  {
    height: "Dining",
    sku: "LEDT-DT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LEDT-DT.jpg`,
    name: "Dining Height Double T Base",
    finish: "Tobacco",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Tobacco.jpg`,
    topPairing: {
      large: "sides",
    },
  },
  {
    height: "Dining",
    sku: "LEDX-DT",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LEDX-DT.jpg`,
    name: "Dining Height Double X Base",
    single: true,
    finish: "Tobacco",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Tobacco.jpg`,
    topPairing: {
      large: "center",
    },
  },
  // {
  //   height: 'Dining',
  //   sku: 'LESH-DT-3',
  //   modelImage: 'images/models/LESH-DT-3.jpg',
  //   name: 'Black Nickel Metal Saw Horse',
  //   finish: 'Black Nickel',
  //   finishImage: 'images/materials/Black Nickel.jpg',
  // },

  // izbacen (Zamenjen sa LETW-DT-2 , takodje LETW-DT-2 renamed u LETW-DT)
  // {
  //   height: "Dining",
  //   sku: "LETW-DT",
  //   modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LETW-DT.jpg`,
  //   name: "Dining Height Metal Twist Base",
  //   single: true,
  //   finish: "Iron",
  //   finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Iron.jpg`,
  //   topPairing: {
  //     large: "center",
  //   },
  // },
  // {
  //   height: 'Bistro',
  //   sku: '8184-BB',
  //   modelImage: 'images/models/8184-BB.jpg',
  //   name: 'Bistro Height Trumpet Base',
  //   finish: 'Blanca',
  //   finishImage: 'images/materials/Blanca.jpg',
  // },
  {
    height: "Dining",
    sku: "8184-TB",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/8184-TB.jpg`,
    name: "Dining Height Trumpet Base",
    finish: "Blanca",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Blanca.jpg`,
    topPairing: {
      large: "sides",
      medium: "center",
    },
  },

  // {
  //   height: 'Bistro',
  //   sku: '8185-BB',
  //   modelImage: 'images/models/8185-BB.jpg',
  //   name: 'Bistro Height Trumpet Base',
  //   finish: 'Oro',
  //   finishImage: 'images/materials/Oro.jpg',
  // },
  {
    height: "Dining",
    sku: "8185-TB",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/8185-TB.jpg`,
    name: "Dining Height Trumpet Base",
    finish: "Oro",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Oro.jpg`,
    topPairing: {
      large: "sides",
      medium: "center",
    },
  },
  {
    height: "Dining",
    sku: "8186-TB",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/8186-TB.jpg`,
    name: "Dining Height Titan Base",
    finish: "Black Metal",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Black Metal.jpg`,
    topPairing: {
      large: "sides",
      medium: "center",
    },
  },
  {
    height: "Café",
    sku: "8186-CB",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/8186-CB.jpg`,
    name: "Titan Café Base",
    finish: "Black Metal",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Black Metal.jpg`,
    topPairing: {
      medium: "center",
    },
  },

  {
    height: "Large Adjustable Height Dining Tables",
    sku: "L001-TB",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/L001-TB.jpg`,
    name: "Work Space Crank Dining Base",
    finish: "Black Metal",
    single: true,
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Black Metal.jpg`,
    topPairing: {
      medium: "center",
    },
  },
  // {
  //   height: 'Large Adjustable Height Dining Tables',
  //   sku: 'L002-TB',
  //   modelImage: 'images/models/L002-TB.jpg',
  //   name: 'Large Work Space Crank Dining Base',
  //   single: true,
  //   finish: 'Black Metal',
  //   finishImage: 'images/materials/Black Metal.jpg',
  // },

  {
    height: "Dining",
    sku: "8192-TB",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/8192-TB.jpg`,
    name: "Dining Height Cracked Ice Base",
    finish: "Cracked Ice Metal",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Cracked Ice Metal.jpg`,
    topPairing: {
      medium: "center",
    },
  },

  {
    height: "Dining",
    sku: "8188-TB",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/8188-TB.jpg`,
    name: "Dining Height Armstrong Base",
    finish: "Bronze",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Bronze.jpg`,
    topPairing: {
      medium: "center",
    },
  },

  {
    height: "Console Tables, End Tables, Cocktail Tables",
    sku: "HP16-4B",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HP16-4B.jpg`,
    name: '16" Hair Pin Legs',
    finish: "Bronze",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Bronze.jpg`,
    topPairing: {
      large: "sides",
      medium: "sides",
      small: "sides",
    },
  },
  {
    height: "Console Tables, End Tables, Cocktail Tables",
    sku: "HP16-4G",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HP16-4G.jpg`,
    name: '16" Hair Pin Legs',
    finish: "Gold",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Gold.jpg`,
    topPairing: {
      large: "sides",
      medium: "sides",
      small: "sides",
    },
  },
  {
    height: "Console Tables, End Tables, Cocktail Tables",
    sku: "HP32-4B",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HP32-4B.jpg`,
    name: '32" Hair Pin Legs',
    finish: "Bronze",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Bronze.jpg`,
    topPairing: {
      large: "sides",
      medium: "sides",
      small: "sides",
    },
  },
  {
    height: "Console Tables, End Tables, Cocktail Tables",
    sku: "HP32-4G",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HP32-4G.jpg`,
    name: '32" Hair Pin Legs',
    finish: "Gold",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Gold.jpg`,
    topPairing: {
      large: "sides",
      medium: "sides",
      small: "sides",
    },
  },
  {
    height: "Console Tables, End Tables, Cocktail Tables",
    sku: "YOYO-14-1",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/YOYO-14-1.jpg`,
    name: '14" Yoyo Metal Legs',
    finish: "Bronze",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Bronze.jpg`,
    topPairing: {
      medium: "center",
      small: "center",
    },
  },
  {
    height: "Console Tables, End Tables, Cocktail Tables",
    sku: "YOYO-14-7",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/YOYO-14-7.jpg`,
    name: '14" Yoyo Metal Legs',
    finish: "Bright Brass",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Bright Brass.jpg`,
    topPairing: {
      medium: "center",
      small: "center",
    },
  },
  {
    height: "Console Tables, End Tables, Cocktail Tables",
    sku: "LE16-3B",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE16-3B.jpg`,
    name: '16" Metal Legs',
    finish: "Bronze",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Bronze.jpg`,
    topPairing: {
      medium: "center",
      small: "center",
    },
  },
  {
    height: "Console Tables, End Tables, Cocktail Tables",
    sku: "LE20-3B",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE20-3B.jpg`,
    name: '20" Metal Legs',
    finish: "Bronze",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Bronze.jpg`,
    topPairing: {
      medium: "center",
      small: "center",
    },
  },
  {
    height: "Console Tables, End Tables, Cocktail Tables",
    sku: "HP32-3B",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HP32-3B.jpg`,
    name: '32" Hair Pin Legs',
    finish: "Bronze",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Bronze.jpg`,
    topPairing: {
      medium: "center",
      small: "center",
    },
  },
  {
    height: "Console Tables, End Tables, Cocktail Tables",
    sku: "HP32-3G",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HP32-3G.jpg`,
    name: '32" Hair Pin Legs',
    finish: "Gold",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Gold.jpg`,
    topPairing: {
      medium: "center",
      small: "center",
    },
  },
  {
    height: "Adjustable Height Desks",
    sku: "L003-TB-2",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/L003-TB-2.jpg`,
    name: "Electric Lift Desk Base",
    single: true,
    finish: "Nickel",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Nickel.jpg`,
    topPairing: {
      large: "center",
    },
  },
  // {
  //   height: 'Adjustable Height Desks',
  //   sku: 'L003-TB-4',
  //   modelImage: 'images/models/L003-TB-4.jpg',
  //   name: 'Electric Lift Desk Base',
  //   single: true,
  //   finish: 'Black',
  //   finishImage: 'images/materials/Black.jpg',
  // },
  // {
  //   height: 'Adjustable Height Desks',
  //   sku: 'L003-TB-5',
  //   modelImage: 'images/models/L003-TB-5.jpg',
  //   name: 'Electric Lift Desk Base',
  //   single: true,
  //   finish: 'White',
  //   finishImage: 'images/materials/White.jpg',
  // },
  {
    height: "Stationary Desks",
    sku: "LEMB-FD",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LEMB-FD.jpg`,
    name: "Metal File Desk Base",
    single: true,
    finish: "Black Nickel Metal & Rustique Wood Finish",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Black Nickel Metal & Rustique Wood Finish.jpg`,
    topPairing: {
      large: "center",
    },
  },
  {
    sku: "CW54-WC",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/CW54-WC.jpg`,
    height: "NON-CONFIG",
    name: "Acrylic Square Base",
    finish: "Acrylic",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Acrylic.jpg`,
  },
  {
    sku: "HG54-WC",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HG54-WC.jpg`,
    height: "NON-CONFIG",
    name: "Acrylic Square Base",
    finish: "Acrylic",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Acrylic.jpg`,
  },
  {
    sku: "LE54-WC",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE54-WC.jpg`,
    height: "NON-CONFIG",
    name: "Acrylic Square Base",
    finish: "Acrylic",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Acrylic.jpg`,
  },
  {
    sku: "T-CW54-WC",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/T-CW54-WC.jpg`,
    height: "NON-CONFIG",
    name: "Trapezoid Base",
    finish: "Black Nickel",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Black Nickel.jpg`,
  },
  {
    sku: "T-HG54-WC",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/T-HG54-WC.jpg`,
    height: "NON-CONFIG",
    name: "Trapezoid Base",
    finish: "Black Nickel",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Black Nickel.jpg`,
  },
  {
    sku: "T-LE54-WC",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/T-LE54-WC.jpg`,
    height: "NON-CONFIG",
    name: "Trapezoid Base",
    finish: "Black Nickel",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Black Nickel.jpg`,
  },

  {
    sku: "CW54-WD",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/CW54-WD.jpg`,
    height: "NON-CONFIG",
    name: "Acrylic Square Base",
    finish: "Acrylic",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Acrylic.jpg`,
  },
  {
    sku: "HG54-WD",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/HG54-WD.jpg`,
    height: "NON-CONFIG",
    name: "Acrylic Square Base",
    finish: "Acrylic",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Acrylic.jpg`,
  },
  {
    sku: "LE54-WD",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE54-WD.jpg`,
    height: "NON-CONFIG",
    name: "Acrylic Square Base",
    finish: "Acrylic",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Acrylic.jpg`,
  },
  {
    sku: "T-CW54-WD",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/T-CW54-WD.jpg`,
    height: "NON-CONFIG",
    name: "Trapezoid Base",
    finish: "Black Nickel",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Black Nickel.jpg`,
  },
  {
    sku: "T-HG54-WD",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/T-HG54-WD.jpg`,
    height: "NON-CONFIG",
    name: "Trapezoid Base",
    finish: "Black Nickel",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Black Nickel.jpg`,
  },
  {
    sku: "T-LE54-WD",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/T-LE54-WD.jpg`,
    height: "NON-CONFIG",
    name: "Trapezoid Base",
    finish: "Black Nickel",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Black Nickel.jpg`,
  },
  {
    sku: "LE38-SR",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LE38-SR.jpg`,
    height: "NON-CONFIG",
    size: '38" Diameter Two Piece Cocktail Table',
    finish: "Nickel",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Nickel.jpg`,
    topPairing: {
      medium: "center",
    },
  },
  {
    sku: "CW56-96",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/CW56-96.jpg`,
    height: "NON-CONFIG",
    name: "Console Table",
    finish: "Crotch Walnut",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Crotch Walnut.jpg`,
  },
  {
    sku: "LETW-DT",
    height: "Dining",
    name: "Dining Height Metal Twist Base",
    modelImage: `${themeConfiguration.model_url}/3d/live-edge/images/models/LETW-DT.jpg`,
    single: true,
    finish: "Iron",
    finishImage: `${themeConfiguration.model_url}/3d/live-edge/images/materials/Iron.jpg`,
    topPairing: {
      large: "center",
    },
  },
];
