import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";

const FinishDetail = (props) => {
  let { finish } = props;

  return (
    <MDBContainer className="finish-detail-container">
      <MDBRow className="finish-detail-image-row">
        <MDBCol className="finish-detail-image">
          <Link to={finish.url_key}>
            <img src={finish.image.url} alt={finish.image.label} />
          </Link>
        </MDBCol>
      </MDBRow>
      <MDBRow className="finish-detail-info">
        <MDBCol>
          <h3>{finish.pattern}</h3>
          <h3>{finish.color}</h3>
          <h3>{finish.grade}</h3>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};
export default FinishDetail;
