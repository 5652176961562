import gql from "graphql-tag";

export const SEARCH_QUERY = gql`
  query Product(
    $search: String
    $filter: ProductAttributeFilterInput
    $pageSize: Int
    $currentPage: Int
  ) {
    products(
      search: $search
      filter: $filter
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      aggregations {
        attribute_code
        label
        options {
          label
          value
        }
      }
      total_count
      items {
        name
        sku
        url_key
        ffi_lanect
        ffi_laners
        ffi_lanesl
        ffi_isvisible
        ffi_collection
        ffi_cfgmodel
        ffifabrics_grade
        attribute_set_name
        ffi_extdesc2
        ffi_name
        short_description {
          html
        }
        image {
          url(width: 700, height: 700)
          label
        }
        small_image {
          url(width: 265, height: 265)
          label
        }

        description {
          __typename
          html
        }
      }
      page_info {
        page_size
        total_pages
        current_page
      }
    }
  }
`;

export const CUSTOMER_SEARCH_QUERY = gql`
  query Product(
    $search: String
    $filter: ProductAttributeFilterInput
    $pageSize: Int
    $currentPage: Int
  ) {
    customer {
      email
    }
    products(
      search: $search
      filter: $filter
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      aggregations {
        attribute_code
        label
        options {
          label
          value
        }
      }
      total_count
      items {
        name
        sku
        url_key
        attribute_set_name
        ffi_extdesc2
        ffi_name
        short_description {
          html
        }
        ffi_lanect
        ffi_cfgmodel
        ffifabrics_grade
        ffi_laners
        ffi_lanesl
        ffi_isvisible
        ffi_collection
        image {
          url(width: 700, height: 700)
          label
        }
        small_image {
          url(width: 265, height: 265)
          label
        }
        price_range {
          minimum_price {
            final_price {
              value
              currency
            }
          }
        }
        description {
          __typename
          html
        }
      }
      page_info {
        page_size
        total_pages
        current_page
      }
    }
  }
`;
