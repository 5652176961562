import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  armArray,
  backArray,
  baseArray,
  fabricArray,
  floorArray,
  frameArray,
  frameNailSizeArray,
  nailFinishArray,
} from "./data";
import { CustomDisplay } from "./Custom";

export const PRODUCT_PROBE = gql`
  query Product($filters: ProductAttributeFilterInput!, $search: String) {
    products(filter: $filters, search: $search) {
      items {
        id
        name
        sku
        price_range {
          minimum_price {
            final_price {
              value
            }
          }
        }
        depth: ffi_frmdimd
        height: ffi_frmdimh
        width: ffi_frmdimw
      }
    }
  }
`;

type MaybeFabric = typeof fabricArray[number] | null;
type MaybeSize = typeof frameNailSizeArray[number] | null | undefined;
type MaybeNailFinish = typeof nailFinishArray[number] | null | undefined;

// @ts-ignore
export const Haven = () => {
  const match = useRouteMatch<{ collection: string; params: string }>({
    path: "/build-your-own/:collection/:params",
    strict: true,
    sensitive: true,
  });
  let [availableOptions, setAvailableOptions] = useState([]);

  const { search } = useLocation();
  const [values, setValues] = useState({});
  useEffect(() => {
    if (search) {
      const localSearch = JSON.parse(
        JSON.stringify(search.replace(/\&amp;/g, "&").replace(/\&quot;/g, `"`))
      );
      setValues(queryString.parse(localSearch));
    }
  }, [search]);

  useEffect(() => {
    // console.log(values);
  }, [values]);

  let queryPathOptions: any;

  // @ts-ignore
  if (values && values.build_your_own_options) {
    // @ts-ignore
    queryPathOptions = JSON.parse(values.build_your_own_options);
  }

  const initial: {
    frame: typeof frameArray[number];
    back: typeof backArray[number];
    arm: typeof armArray[number];
    base: typeof baseArray[number];
    floor: typeof floorArray[number];
    mainFabric: typeof fabricArray[number];
  } = React.useMemo(() => {
    const defaultFrame = frameArray[0];
    const defaultBack = backArray[0];
    const defaultArm = armArray[0];
    const defaultBase = baseArray[0];
    const defaultFloor = floorArray.filter((f) => f.default)[0];
    const defaultFabric = fabricArray[2];

    // if (queryPathOptions) {
    //   return {
    //     frame:
    //       frameArray.find(
    //         (frame) => frame.sku === queryPathOptions.frame?.sku
    //       ) ?? defaultFrame,
    //     back:
    //       backArray.find((back) => back.id === queryPathOptions.back?.id) ??
    //       defaultBack,
    //     arm:
    //       armArray.find((arm) => arm.id === queryPathOptions.arm?.id) ??
    //       defaultArm,
    //     base:
    //       baseArray.find((base) => base.id === queryPathOptions.base?.id) ??
    //       defaultBase,
    //     mainFabric:
    //       fabricArray.find(
    //         (mainFabric) => mainFabric.id === queryPathOptions.mainFabric?.id
    //       ) ?? defaultFabric,
    //     floor:
    //       floorArray.find(
    //         (floor) => floor.name === queryPathOptions.floor?.name
    //       ) ?? defaultFloor,
    //   };
    // } else
    if (match?.params.params) {
      const [sku, mainFabricId] = match.params.params.split("&");
      const [, armId, backId, baseId, , ...frameIds] = sku.split("");
      const frameId = frameIds.join("");
      return {
        frame:
          frameArray.find((frame) => frame.sku === frameId) ?? defaultFrame,
        back: backArray.find((back) => back.id === backId) ?? defaultBack,
        arm: armArray.find((arm) => arm.id === armId) ?? defaultArm,
        base: baseArray.find((base) => base.id === baseId) ?? defaultBase,
        mainFabric:
          fabricArray.find((mainFabric) => mainFabric.id === mainFabricId) ??
          defaultFabric,
        floor: defaultFloor,
      };
    } else {
      return {
        frame: defaultFrame,
        back: defaultBack,
        arm: defaultArm,
        base: defaultBase,
        floor: defaultFloor,
        mainFabric: defaultFabric,
      };
    }
  }, [match]);

  let [player, setPlayer] = React.useState(null);

  const canvasRef = React.useRef(null);

  let [frame, setFrame] = useState(initial.frame);
  let [back, setBack] = useState(initial.back);
  let [arm, setArm] = useState(initial.arm);
  let [base, setBase] = useState(initial.base);
  let [floor, setFloor] = useState(initial.floor);
  let [mainFabric, setMainFabric] = useState<MaybeFabric>(null);
  let [backFabric, setBackFabric] = useState<MaybeFabric>(null);
  let [seatFabric, setSeatFabric] = useState<MaybeFabric>(null);
  let [pillowFabric, setPillowFabric] = useState<MaybeFabric>(null);
  let [cushionFabric, setCushionFabric] = useState<MaybeFabric>(null);
  let [pillowWeltFabric, setPillowWeltFabric] = useState<MaybeFabric>(null);
  let [throwPillowFabric, setThrowPillowFabric] = useState<MaybeFabric>(null);
  let [decorativeCordFabric, setDecorativeCordFabric] = useState<MaybeFabric>(
    null
  );
  let [weltFabric, setWeltFabric] = useState<MaybeFabric>(null);
  let [nailSize, setNailSize] = useState<MaybeSize>(null);
  let [nailFinish, setNailFinish] = useState<MaybeNailFinish>(null);

  let selectionsAndSelectors = {
    frame,
    setFrame,
    back,
    setBack,
    arm,
    setArm,
    base,
    setBase,
    floor,
    setFloor,
  };
  const nailData = {
    nailSize,
    setNailSize: (label: string | undefined) => {
      const size = label
        ? frameNailSizeArray.find((i) => {
            return i.name
              .toLowerCase()
              .includes(label.split(" ")[0].toLowerCase().trim());
          })
        : null;
      setNailSize(size);
    },
    nailFinish,
    setNailFinish,
  };

  const fabricsSelections = React.useMemo(
    () => ({
      mainFabric,
      setMainFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setMainFabric(fabric);
        }
      },
      cushionFabric,
      setCushionFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setCushionFabric(fabric);
        }
      },
      backFabric,
      setBackFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setBackFabric(fabric);
        }
      },
      seatFabric,
      setSeatFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setSeatFabric(fabric);
        }
      },
      pillowFabric,
      setPillowFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setPillowFabric(fabric);
        }
      },
      pillowWeltFabric,
      setPillowWeltFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setPillowWeltFabric(fabric);
        }
      },
      weltFabric,
      setWeltFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setWeltFabric(fabric);
        }
      },
      throwPillowFabric,
      setThrowPillowFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setThrowPillowFabric(fabric);
        }
      },
      decorativeCordFabric,
      setDecorativeCordFabric: (sku: string) => {
        const fabric = fabricArray.find((item) => item.id === sku);
        if (fabric) {
          setDecorativeCordFabric(fabric);
        }
      },
    }),
    [
      backFabric,
      mainFabric,
      pillowFabric,
      pillowWeltFabric,
      seatFabric,
      weltFabric,
      cushionFabric,
      throwPillowFabric,
      decorativeCordFabric,
    ]
  );

  let frameId = frame.sku;

  const sku = "1" + arm.id + back.id + base.id + "-" + frameId;
  let showPdp = false;
  let price = 0;

  let filter = {
    filters: { sku: { eq: sku } },
    search: sku,
    pageSize: 1,
    currentPage: 1,
  };
  const { data } = useQuery(PRODUCT_PROBE, {
    variables: filter,
    fetchPolicy: "cache-first",
  });

  let productName;
  let productDimensions = {};
  if (data) {
    showPdp = true;
    price =
      data?.products?.items[0]?.price_range?.minimum_price?.final_price?.value;
    productName = data?.products?.items[0]?.name;
    productDimensions = {
      width: data?.products?.items[0]?.width,
      height: data?.products?.items[0]?.height,
      depth: data?.products?.items[0]?.depth,
    };
  }
  const displayProps = {
    frameArray,
    backArray,
    armArray,
    baseArray,
    floorArray,
    fabricArray,
    selectionsAndSelectors,
    fabricsSelections,
    nailData,
    sku,
    showPdp,
    price,
    productName,
    queryPathFabrics: values,
    productDimensions,
  };
  return (
    // @ts-ignore
    <CustomDisplay
      ref={canvasRef}
      player={player}
      setPlayer={setPlayer}
      availableOptions={availableOptions}
      setAvailableOptions={setAvailableOptions}
      {...displayProps}
    />
  );
};
