import themeConfiguration from "config/themeConfiguration";
import { MDBBox, MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React, { memo } from "react";
import { Link } from "react-router-dom";

const CategoryWidget = memo(
  ({
    name,
    heroImage,
    placeHolder,
    url_key = "#",
    url = url_key,
    className,
    setImageError,
  }) => {
    if (name === null) {
      name = "Placeholder";
    }
    const onError = () => {
      setImageError(true);
    };

    return (
      <MDBBox className={`category-widget-container px-3 ${className}`.trim()}>
        <MDBBox className="category-widget">
          <img
            className="img-fluid"
            onError={onError}
            src={
              heroImage === null
                ? placeHolder
                : themeConfiguration.magento_url +
                  themeConfiguration.media_url +
                  themeConfiguration.category_url +
                  "/" +
                  heroImage
            }
            alt={name}
          />
          <MDBRow className="category-widget-content">
            <MDBCol size="12" className="text-center pb-3">
              <MDBTypography tag="h3" className="text-white">
                {name}
              </MDBTypography>
            </MDBCol>
            <MDBCol size="12" className="pb-5">
              <MDBBox className="separator mx-auto" />
            </MDBCol>
            <MDBCol className="category-widget-button-container pb-3 text-center">
              <Link to={url} className={`btn-default btn btn-sm`}>
                View All
              </Link>
            </MDBCol>
          </MDBRow>
        </MDBBox>
      </MDBBox>
    );
  }
);

export default CategoryWidget;
