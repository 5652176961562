export default (state, action) => {
  if (typeof action.payload !== "undefined" && action.payload === true) {
    return Object.assign({}, state, { temp: {}, data: {} });
  }
  return Object.assign({}, state, {
    temp: state.data,
    data: {},
    urlResolver: {},
  });
};
