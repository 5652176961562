import { useBoolean } from "ahooks";
import { WishlistJola } from "app/generated/graphql";
import ModalHeader from "app/layout/ModalHeader";
import { customerPaths } from "app/pages/customer/routes";
import clsx from "clsx";
import { fromPairs, kebabCase } from "lodash";
import {
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";
import {
  overviewWrapperHeight,
  overviewWrapperWidth,
  mobileSizeWidth,
} from "../../styles";
import EditCollection, { EditCollectionProps } from "../EditCollection";
import EditLink from "../EditLink";
import { boxes } from "./utils";
import { useMediaQuery } from "react-responsive";
import NameField from "../NameField";
import themeConfiguration from "config/themeConfiguration";

export interface WishlistOverviewProps
  extends Pick<EditCollectionProps, "onDelete"> {
  wishlist: WishlistJola;
}

const WishlistOverview: React.FC<WishlistOverviewProps> = (props) => {
  const [isOpen, setIsOpen] = useBoolean();
  const [edit, setEdit] = useBoolean();
  const editLink = React.useMemo(
    () =>
      props.wishlist.name
        ? `${
            customerPaths.children.collections.children.wishlists.path
          }/${kebabCase(props.wishlist.name)}`
        : "",
    [props]
  );
  let isMobile = useMediaQuery({ maxWidth: 1024 });
  return (
    <>
      <div className="mb-5 pb-3">
        <div>
          <Link to={editLink}>
            <div
              className="border-default-1 d-flex flex-wrap mb-5"
              style={overviewWrapperHeight}
            >
              {boxes.map((box, i) => {
                const hasBorderRight = i % 2 === 0;
                const hasBorderBottom = Math.floor(i / 2) % 2 === 0;

                const item = props.wishlist.items?.[i];

                return (
                  <div
                    key={box}
                    className={clsx(
                      "border-gray w-50 h-50 d-flex justify-content-center align-items-center"
                    )}
                    style={{
                      borderRightWidth: hasBorderRight ? 1 : 0,
                      borderRightStyle: hasBorderRight ? "solid" : "none",
                      borderBottomWidth: hasBorderBottom ? 1 : 0,
                      borderBottomStyle: hasBorderBottom ? "solid" : "none",
                    }}
                  >
                    {item?.product?.small_image?.url &&
                    item?.product?.small_image?.label ? (
                      <img
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                        key={item.id}
                        src={item.product.small_image.url}
                        alt={item.product.small_image.label}
                      />
                    ) : null}
                  </div>
                );
              })}
            </div>
          </Link>
          <div
            className="d-flex justify-content-between align-items-center  border-bottom-3 pt-3 pb-4 pl-4 pr-4"
            onMouseEnter={(e) =>
              e.currentTarget.classList.add("bg-gray-lighten-4")
            }
            onMouseLeave={(e) =>
              e.currentTarget.classList.remove("bg-gray-lighten-4")
            }
          >
            <Link to={editLink}>
              <MDBTypography tag="h4" variant="h4">
                {props.wishlist.name === "My Favorites"
                  ? "Favorites"
                  : props.wishlist.name}
              </MDBTypography>
              <MDBTypography tag="h5" variant="h5">
                {props.wishlist.items_count} item(s)
              </MDBTypography>
            </Link>
            <EditCollection
              edit={<EditLink editLink={editLink} />}
              // @ts-ignore
              wishlistName={props.wishlist.name}
              onDelete={setIsOpen.setTrue}
            />
          </div>
        </div>
      </div>
      {/* <MDBModal
        isOpen={edit}
        inline={false}
        overflowScroll
        noClickableBodyWithoutBackdrop={false}
        centered
      >
        <MDBModalBody className="p-0">
          <ModalHeader onClose={setEdit.setFalse}>Edit Project</ModalHeader>
          <MDBRow className="mt-5 mx-5 px-5 mb-5 pb-5">
            <MDBCol>
              <MDBRow className="mt-5">
                <MDBCol className="d-flex">
                  <MDBBtn
                    onClick={(e) => {
                      console.log(e);
                    }}
                    className="mr-4"
                    color="secondary"
                  >
                    Delete Project
                  </MDBBtn>
                  <MDBBtn
                    onClick={(e) => {
                      console.log(e);
                    }}
                    type="submit"
                    disabled={false}
                  >
                    Save
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
      </MDBModal> */}

      <MDBModal
        isOpen={isOpen}
        inline={false}
        overflowScroll
        noClickableBodyWithoutBackdrop={false}
        centered
      >
        <MDBModalBody className="p-0">
          <ModalHeader onClose={setIsOpen.setFalse}>Remove List</ModalHeader>
          <MDBRow className="mt-5 mx-5 px-5 mb-5 pb-5">
            <MDBCol>
              {/* @ts-ignore */}
              <MDBTypography className="text-center" variant="body-2">
                Are you sure that you want to remove list {props.wishlist.name}?
              </MDBTypography>
              <MDBRow className="mt-5">
                <MDBCol className="d-flex">
                  <MDBBtn
                    onClick={setIsOpen.setFalse}
                    className="mr-4"
                    color="secondary"
                  >
                    Cancel
                  </MDBBtn>
                  <MDBBtn onClick={props.onDelete}>Confirm</MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
      </MDBModal>
    </>
  );
};

export default WishlistOverview;
