import React from "react";
import { useToggleWishlistItem, useWishlists } from "../../hooks";
import StateContainer, { StateContainerProps } from "./StateContainer";

export interface DataContainerProps
  extends Omit<StateContainerProps, "onSubmit" | "onCheck" | "wishlists"> {}

const DataContainer: React.FC<DataContainerProps> = (props) => {
  const wishlists = useWishlists();

  const toggleWishlistItem = useToggleWishlistItem();

  return (
    <StateContainer
      {...props}
      onCheck={toggleWishlistItem}
      wishlists={wishlists}
    />
  );
};

export default DataContainer;
