import { useCustomerCartQuery } from "app/generated/graphql";
import React from "react";
import { useIsMobile } from "../../hooks";
import { isDomainCartItem } from "../../utils";
import CartSummaryModal, { CartSummaryModalProps } from "./CartSummaryModal";

export interface DataContainerProps
  extends Pick<CartSummaryModalProps, "isOpen" | "toggle"> {}

const DataContainer: React.FC<DataContainerProps> = (props) => {
  const cartQuery = useCustomerCartQuery({ fetchPolicy: "network-only" });

  const isMobile = useIsMobile();

  return cartQuery.data?.customerCart.items ? (
    <CartSummaryModal
      {...props}
      isMobile={isMobile}
      items={cartQuery.data?.customerCart.items.filter(isDomainCartItem)}
    />
  ) : null;
};

export default DataContainer;
