import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React, { memo, useEffect, useState } from "react";
import HeaderBottom from "./desktop/headerBottom";
import HeaderTop from "./desktop/headerTop";
import MobileHeader from "./mobile";
import Right from "./right";
import Account from "./right/account";
import Search from "./right/search";
import { getNewsQuery, SaveReadNews } from "app/pages/SiteUpdates";
import { useLazyQuery } from "@apollo/react-hooks";
import { useMutation } from "react-apollo-hooks";
import Badge from "../Badge";
import { Link, useHistory, useLocation } from "react-router-dom";
import Icon from "app/assets/icon/icon";
import { selectIsLoggedIn } from "app/state/redux/data/customer/selectors";
import { useSelector } from "react-redux";
// --------------------------------------------------------------------------------------------------------------------------------------------------------
// This is the layout definition for the website header
// this file should only be used to bootstrap new header components and transfer props, it should not contain logic
// You can also include an scss file that would contain CSS for the header container
// Keep in mind that this is loaded on every page of the website, so try to keep it and it's children light
// --------------------------------------------------------------------------------------------------------------------------------------------------------

const Header = ({
  menu,
  setMenu,
  open,
  setOpen,
  setActiveTopMenu,
  activeTopMenu,
}) => {
  const [saveReadNesMutation] = useMutation(SaveReadNews);

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [getNewsLazyQuery, { data: newsData }] = useLazyQuery(getNewsQuery, {
    fetchPolicy: "network-only",
  });

  const clearUnreadNews = async () => {
    await saveReadNesMutation();
    try {
      getNewsLazyQuery();
    } catch (error) {}
  };
  const [activeMenu, setActiveMenu] = useState("");

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/residential") {
      setActiveMenu("RESIDENTIAL");
    } else if (location.pathname === "/commercial") {
      setActiveMenu("COMMERCIAL");
    }
  }, [location]);

  return (
    <>
      <MDBContainer fluid className="header-container d-none d-lg-block">
        <MDBRow className={`position-relative z-index-6`}>
          <MDBCol className="header-top-container">
            <HeaderTop setActiveMenu={setActiveMenu} activeMenu={activeMenu} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol
            className="header-bottom-container"
            onMouseLeave={() => {
              setActiveTopMenu(false);
            }}
          >
            <HeaderBottom
              menu={menu}
              setActiveTopMenu={setActiveTopMenu}
              activeTopMenu={activeTopMenu}
              newsData={newsData}
              getNewsLazyQuery={getNewsLazyQuery}
              clearUnreadNews={clearUnreadNews}
              activeMenu={activeMenu}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer fluid className="header-mobile-container d-block d-lg-none">
        <MDBRow
          className={`${
            open
              ? "active d-flex mobile-menu-container align-items-center mx-0 h-100"
              : "not-active d-flex mobile-menu-container align-items-center mx-0 h-100"
          }`}
        >
          <MDBCol
            size="2"
            className={`${
              open
                ? "active  mobile-outside-wrapper position-absolute"
                : "not-active p-0 mobile-outside-wrapper"
            }`}
          >
            <MobileHeader
              {...menu}
              setMenu={setMenu}
              open={open}
              setOpen={setOpen}
            />
          </MDBCol>
          <MDBCol size="8" className="d-flex p-0 justify-content-center">
            <Right />
          </MDBCol>
          <MDBCol size="2" className="d-flex p-0  justify-content-end">
            {isLoggedIn && (
              <div className="mr-4 notification">
                <Badge
                  className={
                    newsData?.getNewsInfo?.unread_news_count && "news-alert"
                  }
                  countDisplay={newsData?.getNewsInfo?.unread_news_count ?? 0}
                >
                  <Link onClick={clearUnreadNews} to={`/announcements`}>
                    <Icon icon="notification-icon" className="cursor-pointer" />
                  </Link>
                </Badge>
              </div>
            )}
            <Account />
            <Search />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default memo(Header);
