import { useEffect, useMemo, useState } from "react";
import {
  PRODUCT_LIVE_EDGE,
  CUSTOMER_PRODUCT_LIVE_EDGE,
} from "./liveEdgeQueries";
import { bases } from "app/pages/Custom/live-edge/data";
import { useQuery } from "@apollo/react-hooks";

function useLiveEdgeHook({ isLoggedIn, sku, base }) {
  let skuArray = useMemo(() => [sku, base?.sku], [sku, base?.sku]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("sku")) {
      urlParams.delete("sku");
      urlParams.set("sku", base?.sku);
    }
    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?sku=${urlParams.get("sku")}${
        urlParams.get("numOfBases")
          ? `&numOfBases=${urlParams.get("numOfBases")}`
          : ""
      }`
    );
  }, [base?.sku, sku]);

  const [numOfBases, setNumOfBases] = useState(1);

  const { data: productData } = useQuery(
    isLoggedIn ? CUSTOMER_PRODUCT_LIVE_EDGE : PRODUCT_LIVE_EDGE,
    {
      fetchPolicy: "cache-and-network",
      variables: { filter: { sku: { in: skuArray } } },
      skip: !window.location.pathname.includes("live-edge"),
    }
  );

  const getDepthDimension = (product) =>
    !Number(product?.depth) ? product?.frame_highest_depth : product?.depth;

  let liveEdgeDimensions = useMemo(() => {
    if (productData?.products?.items?.length === 1) {
      const product = productData?.products?.items?.[0];
      return {
        height: product?.height,
        width: product?.width,
        depth: getDepthDimension(product),
      };
    }
    let height = productData?.products?.items
      ?.reduce((total, curr) => total + Number(curr?.height), 0)
      ?.toFixed(2);
    const pathnameSplit = window.location.pathname.split("/");

    const topProduct = productData?.products?.items?.find(
      (item) =>
        item?.name?.toLowerCase()?.includes("top") ||
        item?.sku === pathnameSplit?.[pathnameSplit?.length - 1]
    );

    return {
      height,
      width: topProduct?.width,
      depth: getDepthDimension(topProduct),
    };
  }, [productData]);

  const liveEdgePrice = useMemo(() => {
    if (productData) {
      let total = 0;
      const getSkus = productData?.products?.items?.reduce(
        (acc, curr) => [...acc, curr?.sku],
        []
      );

      if (getSkus?.length === 1 || !numOfBases)
        return productData?.products?.items?.[0]?.price_range?.minimum_price
          ?.final_price?.value;
      for (let i = 0; i < productData?.products?.items.length; i++) {
        const element = productData?.products?.items[i];
        let baseItem = bases?.find((base) => base?.sku === element?.sku);
        if (baseItem)
          total +=
            element?.price_range?.minimum_price?.final_price?.value *
            numOfBases;
        else total += element?.price_range?.minimum_price?.final_price?.value;
      }
      return total;
    }
    return 0;
  }, [productData, numOfBases]);

  return {
    liveEdgeDimensions,
    liveEdgePrice,
    numOfBases,
    setNumOfBases,
  };
}

export default useLiveEdgeHook;
