import { MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React from "react";
import { headers } from "../../config";
import { FilePropToOrderBy, SortOrder, SortOrders } from "../../models";
import SortIcon from "./SortIcon";
import { reverseSortOrder } from "./utils";

export interface HeadersProps {
  sortOrders?: SortOrders;
  onSortOrderChange: (
    filePropToOrderBy: FilePropToOrderBy,
    sortOrder: SortOrder
  ) => void;
}
const Headers: React.FC<HeadersProps> = (props) => {
  return (
    <MDBRow className="mt-4 d-none d-lg-flex justify-content-between">
      <MDBCol className="col-6">
        <MDBTypography
          // @ts-ignore
          variant="body-2"
          className="cursor-pointer"
          onClick={React.useCallback(
            () =>
              props.onSortOrderChange(
                "filename",
                reverseSortOrder(props.sortOrders?.filename ?? null)
              ),
            [props]
          )}
        >
          {headers.filename}
          <SortIcon sortOrder={props.sortOrders?.filename ?? null} />
        </MDBTypography>
      </MDBCol>
      <MDBCol className="col-2 text-right">
        <MDBTypography
          // @ts-ignore
          variant="body-2"
          className="cursor-pointer"
          onClick={React.useCallback(
            () =>
              props.onSortOrderChange(
                "size",
                reverseSortOrder(props.sortOrders?.size ?? null)
              ),
            [props]
          )}
        >
          {headers.size}
          <SortIcon sortOrder={props.sortOrders?.size ?? null} />
        </MDBTypography>
      </MDBCol>
      {/* <MDBCol className="col-2 text-center">
        <MDBTypography
          // @ts-ignore
          variant="body-2"
          className="cursor-pointer"
          onClick={React.useCallback(
            () =>
              props.onSortOrderChange(
                "divisions",
                reverseSortOrder(props.sortOrders?.divisions ?? null)
              ),
            [props]
          )}
        >
          {headers.divisions}
           <SortIcon sortOrder={props.sortOrders?.divisions ?? null} />
        </MDBTypography>
      </MDBCol> */}
      <MDBCol className="col-2 text-right">
        <MDBTypography
          // @ts-ignore
          variant="body-2"
          className="cursor-pointer"
          onClick={React.useCallback(
            () =>
              props.onSortOrderChange(
                "updated_at",
                reverseSortOrder(props.sortOrders?.updated_at ?? null)
              ),
            [props]
          )}
        >
          {headers.updated_at}
          <SortIcon sortOrder={props.sortOrders?.updated_at ?? null} />
        </MDBTypography>
      </MDBCol>
    </MDBRow>
  );
};

export default Headers;
