import React, { memo, useState } from "react";
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtn,
  MDBTypography,
} from "mdbreact";
import { isEqual } from "lodash";
import Icon from "app/assets/icon/icon";
import Toolbar from "app/pages/catalog/category/toolbar";
import { useMediaQuery } from "react-responsive";
import themeConfiguration from "config/themeConfiguration";

const Table = memo(
  ({
    grades,
    pageSize,
    currentPage,
    setCurrentPage,
    setPageSize,
    pageSizeOptions,
    setSortingValues,
    selectedGrades,
  }) => {
    const { data, result_count, totalPages } = grades;
    const isTablet = useMediaQuery({ maxWidth: 992 });

    const [grade, setGrade] = useState("arrowDown");
    const [vendor, setVendor] = useState("arrowDown");
    const [pattern, setPattern] = useState("arrowDown");
    const [railroad, setRailroad] = useState("arrowDown");

    const downloadVendorList = () => {
      if (selectedGrades.length === 0) return;
      window.location.href = `${
        themeConfiguration.magento_url
      }gradespdf?grades=${selectedGrades.join(",")}`;
    };

    return (
      <div className="grades-list">
        <div className="d-flex justify-content-between align-items-center mt-5 mb-5">
          <h3>Selected Grades List</h3>
          <MDBBtn
            className="btn-grade-list"
            color="secondary"
            onClick={downloadVendorList}
          >
            <Icon icon={`downloadGray`} size="3" className="mr-3" />
            <span>Download Selected Grade List</span>
          </MDBBtn>
        </div>
        <MDBTable className="table-grid">
          <MDBTableHead>
            <tr>
              <th
                className="cursor-pointer"
                onClick={() => {
                  if (grade === "arrowDown") {
                    setGrade("arrowUp");
                  } else {
                    setGrade("arrowDown");
                  }
                  setSortingValues({
                    attr: "fairfield_grade",
                    direction: grade === "arrowDown" ? "ASC" : "DESC",
                  });
                }}
              >
                Grade <Icon icon={grade} className="ml-2" />
              </th>
              <th
                className="cursor-pointer"
                onClick={() => {
                  if (vendor === "arrowDown") {
                    setVendor("arrowUp");
                  } else {
                    setVendor("arrowDown");
                  }
                  setSortingValues({
                    attr: "fabric_vendor",
                    direction: vendor === "arrowDown" ? "ASC" : "DESC",
                  });
                }}
              >
                Vendor <Icon icon={vendor} className="ml-2" />
              </th>
              <th
                className="cursor-pointer"
                onClick={() => {
                  if (pattern === "arrowDown") {
                    setPattern("arrowUp");
                  } else {
                    setPattern("arrowDown");
                  }
                  setSortingValues({
                    attr: "fabric_pattern",
                    direction: pattern === "arrowDown" ? "ASC" : "DESC",
                  });
                }}
              >
                Pattern <Icon icon={pattern} className="ml-2" />
              </th>
              <th
                className="cursor-pointer"
                onClick={() => {
                  if (railroad === "arrowDown") {
                    setRailroad("arrowUp");
                  } else {
                    setRailroad("arrowDown");
                  }
                  setSortingValues({
                    attr: "railroad",
                    direction: railroad === "arrowDown" ? "ASC" : "DESC",
                  });
                }}
              >
                Railroad <Icon icon={railroad} className="ml-2" />
              </th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {data !== null ? (
              data?.map((d, i) => {
                return (
                  <>
                    <tr key={i}>
                      <td>
                        {isTablet && <p className="tablet-heading">Grade</p>}{" "}
                        <span>Grade: {d.fairfield_grade}</span>
                      </td>
                      <td>
                        {isTablet && <p className="tablet-heading">Vendor</p>}{" "}
                        <span>Vendor Name: {d.fabric_vendor}</span>
                      </td>
                      <td>
                        {isTablet && <p className="tablet-heading">Pattern</p>}{" "}
                        <span>Pattern: {d.fabric_pattern}</span>
                      </td>
                      <td>
                        {isTablet && <p className="tablet-heading">Railroad</p>}{" "}
                        <span>Railroad: {d.railroad}</span>
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <>
                <tr>
                  <td colspan="4" className="text-center">
                    No results found
                  </td>
                </tr>
              </>
            )}
          </MDBTableBody>
        </MDBTable>

        {result_count >= 12 ? (
          <Toolbar
            pageSize={pageSize}
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            totalCount={result_count}
            setPageSize={setPageSize}
            pageSizeOptions={pageSizeOptions}
          />
        ) : (
          <MDBTypography tag="span" className="d-flex body-6 my-5 py-md-5">
            {result_count} Item(s)
          </MDBTypography>
        )}
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Table;
