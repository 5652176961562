import { ApolloError } from "apollo-client";
import { UpdateCustomerMutation } from "app/generated/graphql";
import Switch from "app/layout/Switch";
import { MDBTypography } from "mdbreact";
import React from "react";
import { ExecutionResult } from "react-apollo";
import Title from "../../components/Title";

export interface NewsletterProps {
  subscribed: boolean;
  toggle: () => Promise<ExecutionResult<UpdateCustomerMutation>> | void;
  loading: boolean;
  error?: ApolloError;
}

const Newsletter: React.FC<NewsletterProps> = (props) => {
  return (
    <>
      <Title>Newsletter Subscription</Title>
      <MDBTypography className="mb=5" tag="h3">
        Subscription Option
      </MDBTypography>
      <Switch
        disabled={props.loading}
        className="position-relative mt-5 pt-2"
        label="General Subscription"
        checked={props.subscribed}
        onChange={props.toggle}
      />
      <div className="position-absolute text-red">
        {props.error?.graphQLErrors[0]?.message}
      </div>
    </>
  );
};

export default Newsletter;
