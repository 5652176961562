// @ts-check
import MaskedInput from "app/layout/MaskedInput";
import Steps from "app/layout/registration/Steps";
import { phoneMask } from "app/pages/customer/config";
import clsx from "clsx";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React from "react";

/**
 * @typedef {object} StepOneProps
 * @prop {import('app/layout/registration/Steps').StepsProps['setStep']} setStep
 * @prop {import('formik').FormikProps<import('./config').ContactInfoValues>} form
 *
 * @param {StepOneProps} props
 */
const StepTwo = ({ form, setStep }) => {
  return (
    <>
      <MDBRow className="my-5 pb-5">
        <MDBCol className="pb-3">
          <Steps step={1} setStep={setStep} validateStep={form.submitForm} />
        </MDBCol>
      </MDBRow>
      <MDBContainer>
        <MDBRow>
          <MDBCol className="col-12 col-lg-10 offset-lg-1">
            <form onSubmit={form.handleSubmit}>
              <MDBRow>
                <MDBCol className="col-12 col-md-6">
                  <MDBRow>
                    <MDBCol>
                      <MDBRow>
                        <MDBCol className="mb-35">
                          <MDBInput
                            containerClass="w-100"
                            {...form.getFieldProps("firstName")}
                            label="First Name"
                            hint="ex: John"
                            required
                            className={clsx("min-width-xv", {
                              error:
                                form.touched.firstName && form.errors.firstName,
                            })}
                          />
                          <div className="text-red position-absolute">
                            {form.touched.firstName && form.errors.firstName}
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol className="mb-4">
                          <MDBInput
                            containerClass="w-100"
                            {...form.getFieldProps("lastName")}
                            label="Last Name"
                            hint="ex: Smith"
                            required
                            className={clsx("min-width-xv", {
                              error:
                                form.touched.lastName && form.errors.lastName,
                            })}
                          />
                          <div className="text-red position-absolute">
                            {form.touched.lastName && form.errors.lastName}
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol className="mb-35">
                          <MaskedInput
                            {...form.getFieldProps("phone")}
                            label="Phone"
                            placeholder="ex: xxx-xxx-xxxx"
                            required
                            mask={phoneMask}
                            className={clsx("min-width-xv", "mb-4", {
                              error: form.touched.phone && form.errors.phone,
                            })}
                          />
                          <div className="text-red position-absolute">
                            {form.touched.phone && form.errors.phone}
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol className="mb-5 mb-md-0 pb-2 pb-md-0">
                          <MDBInput
                            containerClass="w-100"
                            {...form.getFieldProps("email")}
                            label="Email"
                            hint="email@email.com"
                            required
                            className={clsx("min-width-xv", {
                              error: form.touched.email && form.errors.email,
                            })}
                          />
                          <div className="text-red position-absolute">
                            {form.touched.email && form.errors.email}
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>

                <MDBCol className="col-12 col-md-6">
                  <MDBRow>
                    <MDBCol className="mb-35">
                      <MDBInput
                        containerClass="w-100"
                        {...form.getFieldProps("password")}
                        type="password"
                        label="Password"
                        hint="minimum of 10 characters"
                        required
                        className={clsx("min-width-xv", {
                          error: form.touched.password && form.errors.password,
                        })}
                      />
                      <div className="text-red position-absolute">
                        {form.touched.password && form.errors.password}
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mb-md-2">
                    <MDBCol className="mb-4 mb-md-5">
                      <MDBInput
                        containerClass="w-100"
                        {...form.getFieldProps("confirmPassword")}
                        type="password"
                        label="Confirm Password"
                        hint="minimum of 10 characters"
                        required
                        className={clsx("min-width-xv", {
                          error:
                            form.touched.confirmPassword &&
                            form.errors.confirmPassword,
                        })}
                      />
                      <div className="text-red position-absolute">
                        {form.touched.confirmPassword &&
                          form.errors.confirmPassword}
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      {/* @ts-ignore */}
                      <MDBTypography variant="body-1">
                        Password must include 3 different classes of characters:
                        lower case, upper case, digits, and/or special
                        characters
                      </MDBTypography>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-5 pt-md-4 mb-100">
          <MDBCol>
            <div className="border-top-1 pt-5 d-flex justify-content-between">
              <MDBBtn
                className="m-0"
                color="secondary"
                onClick={() => setStep(0)}
              >
                Back
              </MDBBtn>
              <MDBBtn
                className="ml-3 m-0"
                onClick={() => {
                  form.submitForm();
                  window.scrollTo(0, 0);
                }}
              >
                Next
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default StepTwo;
