// logic is inversed, true means that an error exists

export const validate = (description) => {
  return {
    description: description.length > 200,
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];

  return hasError ? shouldShow : false;
};
