import Icon from "app/assets/icon/icon";
import Checkbox from "app/layout/Checkbox";
import MaskedInput from "app/layout/MaskedInput";
import ModalHeader from "app/layout/ModalHeader";
import Select from "app/layout/Select";
import { phoneMask } from "app/pages/customer/config";
import clsx from "clsx";
import { FormikProps } from "formik";
import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React from "react";
import QuantityPerPage from "./components/QuantityPerPage";
import {
  ContactInformationValues,
  IQuantityPerPage,
  markupPricingOptions,
  markupPricingValues,
  Pricing,
  quantitiesPerPage,
} from "./config";

export interface DownloadButtonProps {
  modalOpen: boolean;
  toggleModalOpen: () => void;
  withContactInformation: boolean;
  toggleWithContactInformation: () => void;
  form: FormikProps<ContactInformationValues>;
  quantityPerPage: IQuantityPerPage;
  setQuantityPerPage: (quantity: IQuantityPerPage) => void;
  pricing: Pricing;
  setPricing: (pricing: Pricing) => void;
  message: string;
  setMessage: (message: string) => void;
  onSave: () => void;
}

const DownloadButton: React.FC<DownloadButtonProps> = (props) => {
  return (
    <>
      <MDBBtn
        color="secondary"
        className="position-relative mt-5 d-flex align-items-center"
        onClick={props.toggleModalOpen}
      >
        <div className="position-absolute ml-3 left-0 scale-066">
          <Icon icon="downloadGray" />
        </div>
        Download PDF
      </MDBBtn>
      <MDBModal
        isOpen={props.modalOpen}
        centered
        inline={false}
        overflowScroll
        noClickableBodyWithoutBackdrop={false}
        toggle={() => {}}
        className="wide-modal download-project-modal"
      >
        <MDBModalBody className="p-0">
          <ModalHeader onClose={props.toggleModalOpen}>
            Download Favorites
          </ModalHeader>
          <div className="mx-sm-5 px-sm-5 mx-2 mt-sm-5 pt-4 mb-5 pb-3">
            <MDBRow className="mb-2">
              <MDBCol className="d-flex align-items-center">
                <Checkbox
                  checked={props.withContactInformation}
                  onChange={props.toggleWithContactInformation}
                  label="Add Contact Information"
                />
              </MDBCol>
            </MDBRow>

            <form
              className={clsx(
                "contact-information mt-sm-4 pt-4 transition-all overflow-y-hidden",
                {
                  invisible: !props.withContactInformation,
                }
              )}
              onSubmit={props.form.handleSubmit}
            >
              <MDBRow>
                <MDBCol className="col-12 col-sm-6">
                  <MDBInput
                    {...props.form.getFieldProps("name")}
                    label="Name"
                    hint="Name"
                  />
                  {/* <div className="position-absolute text-red">
                    {props.form.touched.name && props.form.errors.name}
                  </div> */}
                </MDBCol>
                <MDBCol className="col-12 col-sm-6">
                  <MDBInput
                    {...props.form.getFieldProps("email")}
                    label="Email"
                    hint="email@email.com"
                  />
                  {/* <div className="position-absolute text-red">
                    {props.form.touched.name && props.form.errors.name}
                  </div> */}
                </MDBCol>
                <MDBCol className="col-12 col-sm-6 mt-4 pt-3">
                  <MDBInput
                    {...props.form.getFieldProps("companyName")}
                    label="Company Name"
                    hint="Company Name"
                  />
                  {/* <div className="position-absolute text-red">
                    {props.form.touched.name && props.form.errors.name}
                  </div> */}
                </MDBCol>
                <MDBCol className="col-12 col-sm-6 pr-sm-2 mb-5 mb-sm-3 phone-input pt-1">
                  <MaskedInput
                    {...props.form.getFieldProps("phone")}
                    label="Phone"
                    placeholder="999-999-9999"
                    mask={phoneMask}
                  />
                  {/* <div className="position-absolute text-red">
                    {props.form.touched.name && props.form.errors.name}
                  </div> */}
                </MDBCol>
              </MDBRow>
            </form>
            <MDBTypography
              className="mt-sm-4 pt-3 border-top-1 border-gray"
              tag="h1"
              variant="h1"
            >
              Quantity of Items Per Page
            </MDBTypography>
            <MDBRow className="mt-4">
              <MDBCol className="d-flex justify-content-between">
                {quantitiesPerPage.map((quantity) => (
                  <QuantityPerPage
                    key={quantity}
                    current={props.quantityPerPage}
                    onSelect={() => props.setQuantityPerPage(quantity)}
                    quantity={quantity}
                  />
                ))}
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-4 pt-2">
              <MDBCol className="d-flex align-items-center">
                <Checkbox
                  checked={props.pricing === true}
                  onClick={React.useCallback(() => {
                    props.setPricing(true);
                  }, [props])}
                  label="Show Pricing"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-4 pt-2">
              <MDBCol className="d-flex align-items-center">
                <Checkbox
                  checked={!props.pricing}
                  onClick={React.useCallback(() => {
                    props.setPricing(false);
                  }, [props])}
                  label="Hide Pricing"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-4">
              <MDBCol className="col-4 d-flex align-items-center pr-0">
                <Checkbox
                  checked={typeof props.pricing === "number"}
                  onChange={React.useCallback(
                    (e) => {
                      props.setPricing(
                        e.target.checked ? markupPricingValues[0] : false
                      );
                    },
                    [props]
                  )}
                  label="Markup Pricing"
                />
              </MDBCol>
              <MDBCol className="col-4 col-sm-3 pl-sm-0">
                <Select
                  disabled={typeof props.pricing === "boolean"}
                  value={React.useMemo(
                    () =>
                      markupPricingOptions.find(
                        ({ value }) => value === props.pricing
                      ),
                    [props.pricing]
                  )}
                  options={markupPricingOptions}
                  onChange={React.useCallback(
                    (option) => {
                      props.setPricing(option.value);
                    },
                    [props]
                  )}
                />
              </MDBCol>
              <MDBCol className="pl-0 d-flex align-items-center">
                <MDBTypography className="mb-0 fs-14">times</MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBInput
              containerClass="mt-5 w-100 h-auto"
              rows="4"
              hint="Message"
              type="textarea"
              value={props.message}
              onChange={React.useCallback(
                (e) =>
                  e.target.value.length <= 150 &&
                  props.setMessage(e.target.value),
                [props]
              )}
            />
            <div className="mt-5 d-flex">
              <MDBBtn className="m-0 mr-2" onClick={props.onSave}>
                Save PDF
              </MDBBtn>
              <MDBBtn
                className="m-0 ml-4"
                color="secondary"
                onClick={props.toggleModalOpen}
              >
                Cancel
              </MDBBtn>
            </div>
          </div>
        </MDBModalBody>
      </MDBModal>
    </>
  );
};

export default DownloadButton;
