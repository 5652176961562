import { DomainWishlistFragment, ProductImage } from "app/generated/graphql";
import ProductWidget from "app/layout/productWidget";
import ModalHeader from "app/layout/ModalHeader";
import {
  MDBCol,
  MDBRow,
  MDBTypography,
  MDBModal,
  MDBModalBody,
  MDBBtn,
} from "mdbreact";
import React, { useState, useCallback } from "react";
import { EditSectionProps, ToggleWishlistItemConfig } from "../../models";
import { getBadges, getIsFavorite, isQuotable, isWishlistItemDefined } from "../../utils";
import CollectionHeader, { CollectionHeaderProps } from "../CollectionHeader";
import EmptyWishlist from "../EmptyWishlist";
import themeConfiguration from "../../../../../config/themeConfiguration";
import { useMutation } from "react-apollo-hooks";
import queryLoader from "app/graphql/queryLoader";
import Icon from "app/assets/icon/icon";
import { useWishlistAddToCart } from "app/state/hooks/wishlistHooks/wishlistHookAddToCart";
import Summary from "app/pages/product/components/toolbar/components/summary";
import { getObjectWithOptionIds } from "./helper";

export interface WishlistProps
  extends Pick<CollectionHeaderProps, "editButton"> {
  wishlist: DomainWishlistFragment;
  favorites: DomainWishlistFragment;
  onToggleFavorite: (
    config: Pick<
      ToggleWishlistItemConfig,
      "product" | "checked" | "formattedOptions"
    >
  ) => void;
  onDelete: (config: Pick<ToggleWishlistItemConfig, "product">) => void;
  EditSection: React.ComponentType<EditSectionProps>;
  wishlistName?: string;
}

const Wishlist: React.FC<WishlistProps> = ({
  wishlist,
  favorites,
  onToggleFavorite,
  editButton,
  EditSection,
  wishlistName,
}) => {
  const [deleteItemModal, setDeleteItemModal] = useState(false);
  const [deleteSavedBuildsFromWishlist] = useMutation(
    queryLoader("deleteSavedBuildsFromWishlist")
  );
  const [addToCartWishlist] = useWishlistAddToCart();

  const [buildSummary, setBuildSummary] = useState(false);

  const [buildSummaryOptions, setBuildSummaryOptions] = useState({});

  // @ts-ignore
  const mappedOptions = useCallback((options) => {
    return options?.reduce(
      // @ts-ignore
      (accumulator, option) =>
        // @ts-ignore
        option.option_category
          ? {
              ...accumulator,
              // @ts-ignore
              [option.option_category.toLowerCase().split(" ").join("_")]: (
                accumulator?.[
                  option?.option_category?.toLowerCase().split(" ").join("_")
                ] || []
              ).concat(option),
            }
          : accumulator,
      {}
    );
  }, []);

  const summaryMethod = useCallback((item) => {
    const options = mappedOptions(item?.selected_customizable_options);
    const BYOurl = item?.selected_customizable_options
      // @ts-ignore
      .find((option) =>
        option?.option_title?.includes("Build your own configurator url")
      )
      ?.option_value_title?.split("?")?.[1];
    let obj = getObjectWithOptionIds(BYOurl);
    // @ts-ignore
    obj.build_your_own_options = item?.selected_customizable_options?.find(
      // @ts-ignore
      (option) => option?.option_title === "Build your own options"
    )?.option_value_title;

    setBuildSummaryOptions((prevProps) => ({
      ...prevProps,
      options,
      optionState: obj,
    }));
    setBuildSummary(true);
  }, []);

  // @ts-ignore
  const showSummary = (item) =>
    !isQuotable(item?.product?.attribute_set_name) ||
    item?.product?.ffi_casegd === 1 || item?.product?.ffi_fullyuph === 1
      ? false
      : true;

  return (
    <>
      <MDBRow>
        <MDBCol>
          <CollectionHeader
            name={wishlist.name ?? ""}
            count={Number(wishlist.items_count) || 0}
            editButton={editButton}
          />
          <MDBRow>
            {wishlist.items?.length ? (
              wishlist.items?.filter(isWishlistItemDefined).map((item, i) => {
                const isFavorite = getIsFavorite({
                  favorites,
                  sku: item.product.sku,
                });

                const formattedOptions = item?.selected_customizable_options?.map(
                  (option) => {
                    return {
                      //@ts-ignore
                      id: option?.option_id,
                      value_string: option?.option_value_title,
                    };
                  }
                );

                const handleToggleFavorite = () => {
                  onToggleFavorite({
                    product: item.product,
                    checked: !isFavorite,
                    formattedOptions,
                  });
                };

                const badges = getBadges(item.product);

                let foundImage;
                let foundUrl;
                let valuesString = item?.selected_customizable_options?.find(
                  // @ts-ignore
                  (option) => option?.option_value_title?.includes("{")
                  // @ts-ignore
                )?.option_value_title;
                const base = valuesString && JSON.parse(valuesString)?.base;

                item.selected_customizable_options?.forEach((option) => {
                  if (option?.option_title === "Build your own image") {
                    foundImage = option.option_value_title;
                  } else if (
                    option?.option_title === "Build your own configurator url"
                  ) {
                    let url = option.option_value_title
                      ?.replace(/&quot;/g, '\\"')
                      .replace(/&amp;/g, "&");

                    if (typeof url === "string" && url?.includes("monogram")) {
                      let monogramString = item?.selected_customizable_options?.find(
                        // @ts-ignore
                        (option) => option?.option_value_title?.includes("{")
                        // @ts-ignore
                      )?.option_value_title;

                      let obj = monogramString && JSON.parse(monogramString);
                      let str = Object.keys(obj)
                        .map((key) => `${key}=${obj[key]?.id}`)
                        .join("&");
                      url = `/build-your-own/monogram/${obj?.model?.id}?${str}`;
                    }

                    if (base && !url?.includes("baseName"))
                      url = `${url}&baseName=${base?.name}`;

                    foundUrl = {
                      pathname: url?.substr(0, url.indexOf("?")),
                      search: url?.substring(url.indexOf("?"), url.length),
                    };
                  }
                });

                return (
                  <>
                    <MDBCol
                      size="12"
                      sm="6"
                      lg="3"
                      key={item.id ?? i}
                      className="mt-4 pt-2 mb-5 d-flex flex-column"
                    >
                      <MDBRow className="flex-grow-1">
                        <ProductWidget
                          // @ts-ignore
                          image={
                            foundImage
                              ? ({
                                  url:
                                    themeConfiguration.magento_url + foundImage,
                                } as ProductImage)
                              : item?.product?.image
                              ? ({
                                  url: item?.product?.image?.url,
                                } as ProductImage)
                              : item.product.small_image
                          }
                          sku={item.product.sku}
                          name={
                            item?.product?.name ??
                            item.product.name ??
                            undefined
                          }
                          url={foundUrl ? foundUrl : item?.product?.url_key}
                          colSize={12}
                          mdSize="12"
                          lgSize="12"
                          className={"px-5 py-3"}
                          isFavorite={isFavorite}
                          is_new={item.product.is_new}
                          onToggleFavorite={handleToggleFavorite}
                          badges={badges}
                          isSavedBuild={true}
                          savedBuildTitle={item.item_name || ""}
                          product={item}
                          formattedOptions={formattedOptions}
                        />
                      </MDBRow>
                      <div className="d-flex justify-content-center">
                        {showSummary(item) ? (
                          <div
                            className="mr-3 cursor-pointer"
                            onClick={() => summaryMethod(item)}
                          >
                            <Icon icon={"buildsummary_icon_wishlist"} />
                          </div>
                        ) : null}
                        {isQuotable(item?.product?.attribute_set_name) && (
                          <div
                            className="quote-btn"
                            onClick={() => {
                              addToCartWishlist({
                                name: wishlistName,
                                itemId: item?.id,
                              });
                              // .then(() =>
                              //   deleteSavedBuildsFromWishlist({
                              //     variables: {
                              //       input: {
                              //         // @ts-ignore
                              //         item_ids: [+item?.id],
                              //         wishlist: wishlist?.name,
                              //       },
                              //     },
                              //   })
                              // );
                            }}
                          >
                            <Icon
                              icon={"add_to_QR_icon"}
                              style={{ width: "auto" }}
                            />
                          </div>
                        )}
                        <EditSection
                          id={item?.product?.id}
                          wishlistId={wishlist.id}
                          name={item?.product?.name}
                          item_name={item?.item_name || ""}
                          editLink={
                            foundUrl ? foundUrl : item?.product?.url_key
                          }
                          //@ts-ignore
                          onDelete={() =>
                            //@ts-ignore
                            setDeleteItemModal(item?.id)
                          }
                          // onDelete={setDeleteItemModal(item?.product?.id)}
                          // onDelete={handleDelete}
                        />
                      </div>
                    </MDBCol>
                    <MDBModal
                      //@ts-ignore
                      isOpen={deleteItemModal === item?.id}
                      //@ts-ignore
                      toggle={(e) => {
                        //@ts-ignore
                        setDeleteItemModal(false);
                      }}
                      inline={false}
                      overflowScroll
                      noClickableBodyWithoutBackdrop={false}
                      centered
                      className="wide-modal favorites-item-modal"
                    >
                      <MDBModalBody
                        className={`p-0 ${
                          //@ts-ignore
                          wishlist?.name?.length > 11
                            ? "favorites-modal-bigger-title"
                            : ""
                        }`.trim()}
                      >
                        <ModalHeader
                          //@ts-ignore
                          onClose={(e) => {
                            //@ts-ignore
                            setDeleteItemModal(false);
                          }}
                        >
                          {`Remove from ${wishlist.name}`}
                        </ModalHeader>
                        <MDBRow className="mt-5 mx-5 px-sm-5 mb-5 pb-5">
                          <MDBCol className="d-flex flex-column align-items-center">
                            <MDBTypography className="mb-0 text-center fwregular fs-16 text-gray-darken-3">
                              {`Are you sure you want to remove selected product
                            from ${wishlist.name}?`}
                            </MDBTypography>
                            <MDBRow className="mt-5 pt-2 w-100">
                              <MDBCol size="6">
                                <MDBBtn
                                  className="m-0 w-100"
                                  color="secondary"
                                  onClick={(e) => {
                                    //@ts-ignore
                                    setDeleteItemModal(false);
                                  }}
                                >
                                  Cancel
                                </MDBBtn>
                              </MDBCol>
                              <MDBCol size="6">
                                <MDBBtn
                                  className="m-0 w-100"
                                  onClick={(e) => {
                                    //@ts-ignore
                                    new Promise(
                                      (resolve, reject) =>
                                        resolve(
                                          deleteSavedBuildsFromWishlist({
                                            variables: {
                                              input: {
                                                // @ts-ignore
                                                item_ids: [+item?.id],
                                                wishlist: wishlist?.name,
                                              },
                                            },
                                            optimisticResponse: {
                                              __typename: "Mutation",
                                              deleteItemsFromWishlist: {
                                                __typename: "WishlistJola",
                                                ...wishlist,
                                                items_count: (
                                                  Number(
                                                    wishlist?.items_count ?? "1"
                                                  ) - 1
                                                ).toString(),
                                                items: (
                                                  wishlist?.items ?? []
                                                ).filter(
                                                  (wishlistitem) =>
                                                    wishlistitem?.id ===
                                                    item?.id
                                                ),
                                              },
                                            },
                                          })
                                        )
                                      // onDelete({ product: item?.product })
                                    ).then(() => setDeleteItemModal(false));
                                  }}
                                >
                                  Confirm
                                </MDBBtn>
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                      </MDBModalBody>
                    </MDBModal>
                  </>
                );
              })
            ) : (
              <EmptyWishlist />
            )}
          </MDBRow>
        </MDBCol>
      </MDBRow>
      {buildSummary ? (
        <Summary
          // @ts-ignore
          options={buildSummaryOptions?.options}
          setBuildSummary={setBuildSummary}
          buildSummary={buildSummary}
          // @ts-ignore
          optionState={buildSummaryOptions?.optionState}
        />
      ) : null}
    </>
  );
};

export default Wishlist;
