import { ifHourPassed } from "../functions";

export default (state, actions) => {
  let shouldAuthorize = ifHourPassed(state.timestamp, new Date().getTime());

  if (shouldAuthorize)
    return Object.assign({}, state, {
      timestamp: actions.timestamp,
      authorize: true,
    });

  return Object.assign({}, state, {
    timestamp: state.timestamp,
    authorize: false,
  });
};
