// @ts-ignore

import { useQuery } from "@apollo/react-hooks";
import themeConfiguration from "config/themeConfiguration";
import { isEmpty } from "core/helpers/functions";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  FABRICS,
  FABRICS_CATEGORIES,
  FABRICS_WITHOUT_AGGREGATIONS,
} from "../queriesFabric";
import { FabricSidebar as FabricSidebarDisplay } from "./fabricSidebar";
import { fabricArray } from "app/pages/Custom/haven/data";

const getFiltersByTypeOfFabric = (option, selectedCategory) => {
  switch (option.title) {
    case "Decorative Tape Frame":
      return { ffifabrics_tape: { eq: 1 } };
    case "Skirt Trim":
      return { ffifabrics_tape: { eq: 1 } };
    case "Base Trim":
      return { ffifabrics_tape: { eq: 1 } };
    case "Decorative Cord":
      return { ffifabrics_dec_cord: { eq: 1 } };
    case "Throw Pillow Decorative Cord":
      return { ffifabrics_dec_cord: { eq: 1 } };
    case "Kidney Pillow Decorative Cord":
      return { ffifabrics_dec_cord: { eq: 1 } };
    case "Fringe":
      return { ffifabrics_fringe: { eq: 1 } };
    case "Throw Pillow Fringe":
      return { ffifabrics_fringe: { eq: 1 } };
    case "Kidney Pillow Fringe":
      return { ffifabrics_fringe: { eq: 1 } };
    // case "Nail Band":
    //   return { ffifabrics_tape: { eq: 1 } };
    default:
      return { category_id: { eq: selectedCategory } };
  }
};

const FabricSidebarQueryContainer = ({ allItems, setAllItems, ...props }) => {
  let {
    defaults: { defaultFabric },
    fabric,
    customizationOptions,
    optionId,
    customizationOptionPrice,
  } = props;
  let filterByTypeOfFabric = {};
  const pageSize = 97;
  let fabricsCategories = [];
  let totalPagesNumber = 0;
  const [initialAggregations, setInitialAggregations] = useState(false);
  let [threesixtyItems, setThreesixtyItems] = useState({});
  let [arrayItems, setArrayItems] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [activeFilters, setActiveFilters] = useState({});
  const [selectedFilters, setSelectedFilters] = useState(false);
  const { data, loading } = useQuery(FABRICS_CATEGORIES, {
    variables: { id: themeConfiguration.fabric_category_id },
    fetchPolicy: "cache-first",
  });
  let { setFabricOrFinishSelectedOptions } = props;
  fabricsCategories = loading ? false : data?.categoryList[0]?.children;
  //filtered categories by jola_product_count field
  fabricsCategories =
    fabricsCategories &&
    fabricsCategories.filter((category) => {
      return category.jola_product_count > 0;
    });
  //preselected fabric category
  const [selectedCategory, setSelectedCategory] = useState(
    themeConfiguration.fabric_subcategory_id
  );

  customizationOptions.forEach((option) => {
    if (option.option_id === optionId) {
      filterByTypeOfFabric = getFiltersByTypeOfFabric(option, selectedCategory);
    }
  });

  const FabricFilter = {
    filters: { ...filterByTypeOfFabric, ...activeFilters },
    pageSize: pageSize,
    currentPage: currentPage,
  };
  var result = fabricArray.map(function (a) {
    return a.id;
  });
  const ThreeSixtyItems = {
    filters: {
      ...filterByTypeOfFabric,
      sku: { in: result },
      ...activeFilters,
    },
    pageSize: pageSize,
    currentPage: currentPage,
  };

  const FINAL_FABRIC_QUERY = isEmpty(activeFilters)
    ? FABRICS
    : FABRICS_WITHOUT_AGGREGATIONS;

  const fabricRes = useQuery(FINAL_FABRIC_QUERY, {
    variables: FabricFilter,
    fetchPolicy: "cache-first",
  });

  const fabricThreeSixtyRes = useQuery(FINAL_FABRIC_QUERY, {
    variables: ThreeSixtyItems,
    fetchPolicy: "cache-first",
  });

  const loadingFabricItems = fabricRes.loading;
  let fabricsArray = loadingFabricItems ? false : fabricRes?.data?.products;
  let threeSixtyArray = loadingFabricItems
    ? false
    : fabricThreeSixtyRes?.data?.products;
  //initially setting aggregations array
  useEffect(() => {
    if (
      !loadingFabricItems &&
      fabricsArray !== undefined &&
      fabricsArray.aggregations !== undefined
    ) {
      setInitialAggregations(fabricsArray?.aggregations);
      setArrayItems(fabricsArray?.items);
      setThreesixtyItems(threeSixtyArray?.items);
    }
  }, [selectedCategory, fabricsArray, loadingFabricItems, threeSixtyArray]);

  //reset filters when selected fabrics subcategory is changed
  useEffect(() => {
    setActiveFilters({});
    setCurrentPage(1);
    setArrayItems(fabricsArray?.items);
    setThreesixtyItems(threeSixtyArray?.items);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  //setting items array for infinite scroll
  useEffect(() => {
    if (
      !loadingFabricItems &&
      fabricsArray !== undefined &&
      fabricsArray.items !== undefined
    ) {
      if (!isEmpty(arrayItems)) {
        if (currentPage > 1) {
          setArrayItems([...arrayItems, ...fabricsArray.items]);
          // setThreesixtyItems([...threesixtyItems, ...threeSixtyArray.items]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, loadingFabricItems, fabricsArray, threeSixtyArray]);

  useEffect(() => {
    if (!isEmpty(activeFilters)) {
      if (!loadingFabricItems) {
        if (currentPage === 1) {
          setArrayItems(fabricsArray?.items);
          setThreesixtyItems(threeSixtyArray?.items);
        } else {
          setArrayItems([...arrayItems, ...fabricsArray?.items]);
          // setThreesixtyItems([...threesixtyItems, ...threeSixtyArray.items]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, loadingFabricItems]);

  totalPagesNumber = loadingFabricItems
    ? 0
    : Math.ceil(fabricsArray?.total_count / pageSize);
  let removeSameItems;

  //remove items with the same sku
  arrayItems &&
    arrayItems.length > 0 &&
    (removeSameItems = arrayItems.filter(
      (o1) =>
        typeof threesixtyItems !== "undefined" &&
        !threesixtyItems.some((o2) => o1.sku === o2.sku)
    ));

  arrayItems &&
    arrayItems.length > 0 &&
    (allItems =
      typeof threesixtyItems !== "undefined" &&
      threesixtyItems.concat(removeSameItems));

  return (
    <FabricSidebarDisplay
      {...props}
      fabricsArray={fabricsArray}
      fabricsCategories={fabricsCategories}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      activeFilters={activeFilters}
      setActiveFilters={setActiveFilters}
      initialAggregations={initialAggregations}
      loadingFabricItems={loadingFabricItems}
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      totalPagesNumber={totalPagesNumber}
      allItems={allItems}
      setAllItems={setAllItems}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      setFabricOrFinishSelectedOptions={setFabricOrFinishSelectedOptions}
      defaultFabric={defaultFabric}
      fabric={fabric}
      customizationOptions={customizationOptions}
      customizationOptionPrice={customizationOptionPrice}
    />
  );
};

export default withRouter(FabricSidebarQueryContainer);
