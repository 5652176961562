import React from "react";
import { MDBInput } from "mdbreact";

const InputField = props => {
  return (
    <MDBInput
      {...props}
      ref={props.innerRef}
      containerClass={`default-input-field-container ${props.wrapperClass}`.trim()}
      className={`default-input-field ${props.className}`.trim()}
      onBlur={props.onBlur}
    />
  );
};

export default InputField;
