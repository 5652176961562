import Grade from "./grade";
import React, { useState, useEffect, useCallback } from "react";
import apolloClient from "core/graphql/apolloClient";
import { useLocation } from "react-router-dom";
import Config from "config/themeConfiguration";
import { DATA, LOGODATA, VENDORS, GRADES, GRADES_ALL } from "./query";
import SkeletonCategory from "../skeleton-category";
import themeConfiguration from "config/themeConfiguration";

const GradeQueryContainer = (props) => {
  let location = useLocation();
  const categories = ["Grade Lookup", "Search by Grade"];
  let [category, setCategoryFilter] = useState("Grade Lookup");
  let [pageSize, setPageSize] = useState(Config.pageSizeOptions[0].value);
  let [currentPage, setCurrentPage] = useState(1);
  const [vendor, setVendor] = useState("");
  const [pattern, setPattern] = useState("");
  const [patternLike, setPatternLike] = useState("");
  const [patterns, setPatterns] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [logoData, setLogotData] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [grades, setGrades] = useState({});
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [allGrades, setAllGrades] = useState();
  const [sortingValues, setSortingValues] = useState({});
  const [foundVendor, setFoundVendor] = useState({});
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [error, setError] = useState(null);

  const handleSetPageSize = (pageSize) => {
    setPageSize(pageSize.value);
  };

  const fetchAllGrades = useCallback(async () => {
    const { data } = await apolloClient.query({
      query: GRADES_ALL,
    });
    try {
      const {
        getTextileAllianceGrades: { grades },
      } = data;
      let gradesArray = [];
      grades.map((grade) => {
        gradesArray.push({ label: grade, value: grade });
      });
      setAllGrades(gradesArray);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, []);

  const searchGrades = useCallback(
    async (networkOnly = false, patternLikeValue = "") => {
      let inputData = {
        fabric_vendor: selectedGrades.length === 0 ? vendor : "",
        fabric_pattern: pattern && selectedGrades.length === 0 ? pattern : "",
        fabric_color: "",
        fairfield_grade:
          selectedGrades.length === 0 ? "" : selectedGrades.join(","),
        page_size: vendor ? 1500 : pageSize,
        page_number: currentPage,
        fabric_pattern_like: patternLikeValue,
        sorting_attribute:
          Object.keys(sortingValues).length === 0 ? "" : sortingValues?.attr,
        sorting_direction:
          Object.keys(sortingValues).length === 0
            ? ""
            : sortingValues?.direction,
      };
      const { data: fetchData } = await apolloClient.query({
        query: DATA,
        variables: { input: inputData },
        fetchPolicy: networkOnly ? "network-only" : "cache-first",
      });
      try {
        let fetchedData = fetchData;
        if (!fetchData?.getTextileAllianceData?.data) {
          fetchedData.getTextileAllianceData.data = [];
        }
        const {
          getTextileAllianceData: { data, result_count },
        } = fetchData;
        if (selectedGrades.length > 0) {
          let totalPages = Math.ceil((result_count || 1) / (pageSize || 1));
          setGrades({ data, result_count, totalPages });
        } else if (!pattern) {
          setupPatterns(data);
        } else {
          const vendorImg = logoData.find((data) => data?.vendor === vendor)
            ?.logo_file;
          if (data && data.length > 0) {
            data[0].file = `${themeConfiguration.magento_url}${vendorImg}`;
            setFoundVendor(data[0]);
          }
        }
        if(networkOnly && pattern) {
          setupPatterns(data);
        }
      } catch (error) {
        setError(error);
      }
    },
    [
      vendor,
      pattern,
      grades,
      pageSize,
      currentPage,
      sortingValues,
      selectedGrades,
    ]
  );

  function setupPatterns(data) {
    let fabricPatterns = [];
    data.map(({ fabric_pattern }) => {
      fabricPatterns.push({
        value: fabric_pattern,
        label: fabric_pattern,
      });
    });
    setPatterns(fabricPatterns);
  }

  const onInit = () => {
    const fetchLogoData = async () => {
      setLoading(true);
      const { data } = await apolloClient.query({
        query: LOGODATA,
        // fetchPolicy: "no-cache",
      });
      try {
        const { getTextileAllianceLogoData } = data;
        setLogotData(getTextileAllianceLogoData);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    const fetchVendors = async () => {
      const { data } = await apolloClient.query({
        query: VENDORS,
        // fetchPolicy: "no-cache",
      });
      try {
        const {
          getTextileAllianceVendors: { vendors },
        } = data;
        let vendorsData = [];
        vendors.map((vendor) => {
          vendorsData.push({ label: vendor, value: vendor });
        });
        setVendors(vendorsData);
      } catch (error) {
        setError(error);
      }
    };
    fetchVendors();
    fetchLogoData();
  };

  useEffect(() => {
    onInit();
    return () => {
      setSelectedGrades([]);
    };
  }, []);

  useEffect(() => {
    // if (!vendor) return;
    searchGrades();
  }, [currentPage, vendor, sortingValues, pattern]);

  useEffect(() => {
    searchGrades(true, patternLike);
  }, [patternLike]);

  useEffect(() => {
    if (category === "Search by Grade") {
      fetchAllGrades();
    }
  }, [category]);

  const setActiveFilters = (grade) => {
    setSelectedGrades(grade);
  };

  if (loading) return <SkeletonCategory />;

  if (error) return <>ERROR</>;

  let pageSizeOptions = Config.pageSizeOptions;
  let totalPages = Math.ceil((allGrades?.length || 1) / (pageSize || 1));
  return (
    <Grade
      categories={categories}
      category={category}
      setCategoryFilter={setCategoryFilter}
      currentPage={currentPage}
      vendor={vendor}
      setVendor={setVendor}
      setCurrentPage={setCurrentPage}
      pattern={pattern}
      setPattern={setPattern}
      setPatternLike={setPatternLike}
      totalPages={totalPages}
      totalCount={logoData?.length}
      setPageSize={handleSetPageSize}
      pageSizeOptions={pageSizeOptions}
      pageSize={pageSize}
      searchVal={searchVal}
      setSearchVal={setSearchVal}
      vendors={vendors}
      searchGrades={searchGrades}
      logoData={logoData}
      patterns={patterns}
      foundVendor={foundVendor}
      allGrades={allGrades}
      grades={grades}
      setShowTable={setShowTable}
      showTable={showTable}
      setPatterns={setPatterns}
      setGrades={setGrades}
      setSortingValues={setSortingValues}
      setActiveFilters={setActiveFilters}
      selectedGrades={selectedGrades}
      setSelectedGrades={setSelectedGrades}
      {...props}
    />
  );
};

export default GradeQueryContainer;
