import Icon from "app/assets/icon/icon";
import MediaGallery from "app/layout/mediaGallery";
import Switch from "app/layout/Switch";
import Toolbar from "app/pages/catalog/product/toolbar";
import { Sidebar } from "app/pages/Custom";
import { ModalConsumer } from "core/components/modal/ModalContext";
import _ from "lodash";
import { MDBBox, MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdbreact";
import React from "react";
import { useMediaQuery } from "react-responsive";
import CustomPdp from "./customPdp";
import FabricPdp from "./fabricPdp";
import FinishesPdp from "./finishesPdp";
import { Info } from "./info";
import { Options } from "./options";
import { Promos } from "./Promos";
import SeoContainer from "./seoContainer";
const Product = ({
  player,
  customizationProps,
  customerData,
  sliderProps,
  mediaGallery,
  productData,
  details,
  specifications,
  dimensions,
  noImage = false,
  isCustom = false,
  showMainFabric = false,
  fabrics,
  addToCart,
  defaultFabric,
  favorites,
  isFavorite,
  toggleFavorite,
  onToggleFavorite,
  isAddingToCart,
  fabricPDP,
  allowSectional,
  pickSectional,
  currentSectionals,
  defaults,
  fabricsSelections,
  frame,
  fabricOrFinishesSelectedOptions,
  customizationOptionPrice,
  viewMoreOptionsPrice,
  viewMorePdpPrice,
  setViewMorePdpPrice,
  viewMoreOptions,
  setViewMoreOptions,
  setFabricOrFinishSelectedOptions,
  nailData,
  selectionsAndSelectors,
  finishesDetails,
  categories,
  productImageUrl,
  queryPathFabrics,
  checkBoxOptions,
  setCheckBoxOptions,
  dropdownOptions,
  setDropdownOptions,
  finishesPdp,
  upholsteryOptionsFabrics,
  foundFabrics,
  ...props
}) => {
  let finishes_cleaning_text = productData.finishes_cleaning;
  let about_fairshield_plus = productData.about_fairshield;
  let attributeSet = productData.attribute_set_name;
  let attributeSetNameCustom =
    productData.attribute_set_name === "CustOptProducts";
  let attributeSetNameFinish = productData.attribute_set_name === "Finish";
  let attributeSetNameFabrics = productData.attribute_set_name === "Fabrics";
  const isMobile = useMediaQuery({ maxWidth: 992 });
  let toolbarStyle = {
    position: isCustom ? "fixed" : "sticky",
    bottom: 0,
    zIndex: 2,
    left: 0,
    width: "100vw",
  };
  let containerStyle = {};
  let collection = productData.collection;
  let toolbarCustomMobileStyle = { position: "static" };

  return (
    <>
      <SeoContainer
        productData={productData}
        type={"product"}
        jsonLdType={"Product"}
      />
      {(() => {
        switch (attributeSet) {
          case "CustOptProducts":
            return (
              <CustomPdp
                {...productData}
                {...customerData}
                attributeSetName={attributeSetNameCustom}
                player={player}
                isFavorite={isFavorite}
                onToggleFavorite={onToggleFavorite}
                isCustom={isCustom}
                addToCart={addToCart}
                allowedFinishes={productData.allowed_finishes}
                options={customizationProps.options}
                setFabricOrFinishSelectedOptions={
                  setFabricOrFinishSelectedOptions
                }
                isAddingToCart={isAddingToCart}
              />
            );
          case "Finish":
            return (
              <FinishesPdp
                {...productData}
                {...customerData}
                attributeSetName={attributeSetNameFinish}
                player={player}
                isFavorite={isFavorite}
                onToggleFavorite={onToggleFavorite}
                isCustom={isCustom}
                options={customizationProps.options}
                setFabricOrFinishSelectedOptions={
                  setFabricOrFinishSelectedOptions
                }
                finishesDetails={finishesDetails}
                finishes_cleaning={finishes_cleaning_text}
                about_fairshield_plus={about_fairshield_plus}
                allowedFinishes={productData.allowed_finishes}
                finishesPdp={finishesPdp}
              />
            );
          case "Fabrics":
            return (
              <FabricPdp
                {...props}
                {...customerData}
                productData={productData}
                attributeSetName={attributeSetNameFabrics}
                isFavorite={isFavorite}
                onToggleFavorite={onToggleFavorite}
                toggleFavorite={toggleFavorite}
              />
            );

          default:
            return (
              <>
                {(!_.isEmpty(mediaGallery) && !noImage && (
                  <MDBContainer
                    className="product-media-container px-0 my-5 pb-4"
                    fluid
                  >
                    <MediaGallery
                      settings={sliderProps}
                      images={mediaGallery}
                    />
                  </MDBContainer>
                )) ||
                  (!noImage && (
                    <MDBContainer>
                      <MDBRow>
                        <MDBCol
                          className={`d-flex justify-content-center pdp-main-image`}
                        >
                          <img
                            className={`my-5`}
                            src={productImageUrl}
                            alt={"main"}
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  ))}
                <MDBContainer
                  fluid
                  className={`px-0 position-relative ${
                    isCustom ? "mt-lg--25" : ""
                  }`.trim()}
                  style={containerStyle}
                >
                  {!isCustom && (
                    <>
                      <MDBContainer
                        className="controls-container"
                        style={toolbarStyle}
                        fluid
                      >
                        <Toolbar
                          player={player}
                          {...productData}
                          {...customerData}
                          isFavorite={isFavorite}
                          onToggleFavorite={onToggleFavorite}
                          isCustom={isCustom}
                          addToCart={addToCart}
                          isAddingToCart={isAddingToCart}
                          options={customizationProps.options}
                          customizationOptionPrice={customizationOptionPrice}
                          viewMoreOptionsPrice={viewMoreOptionsPrice}
                          viewMorePdpPrice={viewMorePdpPrice}
                          setViewMorePdpPrice={setViewMorePdpPrice}
                          viewMoreOptions={viewMoreOptions}
                          customizationOptions={
                            customizationProps?.customizationOptions
                          }
                          setCustomizationOptions={
                            customizationProps?.setCustomizationOptions
                          }
                          defaults={defaults}
                          queryPathFabrics={queryPathFabrics}
                          attribute_set_name={productData?.attribute_set_name}
                        />
                      </MDBContainer>
                      <MDBContainer className={"options-container my-4 py-4"}>
                        <MDBRow>
                          {customizationProps &&
                            (!customizationProps?.options?.find(
                              (opt) =>
                                opt?.category_options?.toLowerCase() ===
                                  "Additional Options".toLowerCase() ||
                                opt?.category_options?.toLowerCase() ===
                                  "Upholstery Options".toLowerCase()
                            ) &&
                            customizationProps?.customizationOptions.length ===
                              0 ? (
                              <>
                                {productData.related_products &&
                                productData.related_products.length === 0 ? (
                                  <MDBCol
                                    size="12"
                                    lg="12"
                                    className="info-fddf"
                                  >
                                    <Info
                                      {...productData}
                                      favorites={favorites}
                                      toggleFavorite={toggleFavorite}
                                      onToggleFavorite={onToggleFavorite}
                                      details={details}
                                      specifications={specifications}
                                      dimensions={dimensions}
                                      showRelatedProducts={false}
                                      fabricPDP={fabricPDP}
                                      customizationProps={customizationProps}
                                      collection={collection}
                                    />
                                  </MDBCol>
                                ) : (
                                  <>
                                    <MDBCol
                                      size="12"
                                      lg="6"
                                      className="border-right-lg info-one"
                                    >
                                      <Info
                                        {...productData}
                                        favorites={favorites}
                                        toggleFavorite={toggleFavorite}
                                        onToggleFavorite={onToggleFavorite}
                                        details={details}
                                        specifications={specifications}
                                        dimensions={dimensions}
                                        showRelatedProducts={false}
                                        fabricPDP={fabricPDP}
                                        customizationProps={customizationProps}
                                        collection={collection}
                                      />
                                    </MDBCol>
                                    <MDBCol
                                      size="12"
                                      lg="6"
                                      className="info-two"
                                    >
                                      <Info
                                        {...productData}
                                        favorites={favorites}
                                        toggleFavorite={toggleFavorite}
                                        onToggleFavorite={onToggleFavorite}
                                        details={details}
                                        specifications={specifications}
                                        dimensions={dimensions}
                                        showRelatedProducts={true}
                                        fabricPDP={fabricPDP}
                                        collection={collection}
                                      />
                                    </MDBCol>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <MDBCol
                                  lg="6"
                                  className="border-right-lg test2"
                                >
                                  <Info
                                    {...productData}
                                    favorites={favorites}
                                    toggleFavorite={toggleFavorite}
                                    onToggleFavorite={onToggleFavorite}
                                    details={details}
                                    specifications={specifications}
                                    dimensions={dimensions}
                                    showRelatedProducts={true}
                                    fabricPDP={fabricPDP}
                                    customizationProps={customizationProps}
                                    withMaxWidth={true}
                                    collection={collection}
                                  />
                                </MDBCol>
                                <MDBCol
                                  lg="6"
                                  className="pt-3 pt-lg-5 d-flex flex-column align-items-end"
                                >
                                  <Options
                                    showMainFabric={showMainFabric}
                                    queryPathFabrics={queryPathFabrics}
                                    customizationProps={customizationProps}
                                    fabrics={fabrics}
                                    defaultFabric={defaultFabric}
                                    productImage={
                                      productData && productData.image
                                    }
                                    defaults={defaults}
                                    frame={frame}
                                    fabricOrFinishesSelectedOptions={
                                      fabricOrFinishesSelectedOptions
                                    }
                                    viewMorePdpPrice={viewMorePdpPrice}
                                    viewMoreOptionsPrice={viewMoreOptionsPrice}
                                    setViewMorePdpPrice={setViewMorePdpPrice}
                                    viewMoreOptions={viewMoreOptions}
                                    setViewMoreOptions={setViewMoreOptions}
                                    setFabricOrFinishSelectedOptions={
                                      setFabricOrFinishSelectedOptions
                                    }
                                    allowedFinishes={
                                      productData.allowed_finishes
                                    }
                                    categories={categories}
                                    checkBoxOptions={checkBoxOptions}
                                    setCheckBoxOptions={setCheckBoxOptions}
                                    dropdownOptions={dropdownOptions}
                                    setDropdownOptions={setDropdownOptions}
                                    customizationOptionPrice={
                                      customizationOptionPrice
                                    }
                                    upholsteryOptionsFabrics={
                                      upholsteryOptionsFabrics
                                    }
                                    foundFabrics={foundFabrics}
                                    {...customerData}
                                    productData={productData}
                                  />
                                </MDBCol>
                              </>
                            ))}
                        </MDBRow>
                      </MDBContainer>
                    </>
                  )}
                  {isCustom && (
                    <>
                      <MDBContainer
                        fluid
                        className={`options-container my-lg-4 py-lg-4 px-0 custom-option-container`}
                      >
                        <MDBRow className={!isMobile ? "mr-0" : "mx-0"}>
                          <MDBCol style={{ padding: 0 }}>
                            <MDBRow className={!isMobile && "mr-0"}>
                              <Options
                                fabricsSelections={fabricsSelections}
                                showMainFabric={showMainFabric}
                                customizationProps={customizationProps}
                                fabrics={fabrics}
                                isCustom={isCustom}
                                defaultFabric={defaultFabric}
                                productImage={productData && productData.image}
                                defaults={defaults}
                                frame={frame}
                                allowedFinishes={productData.allowed_finishes}
                                fabricOrFinishesSelectedOptions={
                                  fabricOrFinishesSelectedOptions
                                }
                                nailData={nailData}
                                setFabricOrFinishSelectedOptions={
                                  setFabricOrFinishSelectedOptions
                                }
                                addToCart={addToCart}
                                isAddingToCart={isAddingToCart}
                                categories={categories}
                                queryPathFabrics={queryPathFabrics}
                                checkBoxOptions={checkBoxOptions}
                                setCheckBoxOptions={setCheckBoxOptions}
                                dropdownOptions={dropdownOptions}
                                setDropdownOptions={setDropdownOptions}
                                customizationOptionPrice={
                                  customizationOptionPrice
                                }
                                foundFabrics={foundFabrics}
                                productData={productData}
                              />
                            </MDBRow>
                            <MDBContainer className="pl-0">
                              <Info
                                {...productData}
                                toggleFavorite={toggleFavorite}
                                favorites={favorites}
                                onToggleFavorite={onToggleFavorite}
                                showRelatedProducts={false}
                                collection={collection}
                              />
                            </MDBContainer>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </>
                  )}
                </MDBContainer>
                {/* {!isCustom && <Promos />} */}
                {isCustom && (
                  <MDBContainer
                    className="controls-container"
                    style={
                      (isMobile && toolbarCustomMobileStyle) || toolbarStyle
                    }
                    fluid
                  >
                    <Toolbar
                      player={player}
                      {...productData}
                      {...customerData}
                      isFavorite={isFavorite}
                      onToggleFavorite={onToggleFavorite}
                      isCustom={isCustom}
                      addToCart={addToCart}
                      options={customizationProps.options}
                      customizationOptions={
                        customizationProps?.customizationOptions
                      }
                      setCustomizationOptions={
                        customizationProps?.setCustomizationOptions
                      }
                      defaults={defaults}
                      selectionsAndSelectors={selectionsAndSelectors}
                      isAddingToCart={isAddingToCart}
                      attribute_set_name={productData?.attribute_set_name}
                      collection={collection}
                    />
                  </MDBContainer>
                )}
              </>
            );
        }
      })()}
    </>
  );
};

export const Sectional = ({ options, pickSectional, currentSectionals }) => {
  const [isSectionalChecked, setIsSectionalChecked] = React.useState(true);
  const toggleIsSectionalChecked = () => {
    setIsSectionalChecked(!isSectionalChecked);
  };

  return (
    <>
      <MDBRow className="my-3">
        <MDBCol className="py-4 border rounded border-default">
          <MDBContainer>
            <MDBRow>
              <MDBCol xl="2" size="3">
                <MDBBox
                  className="border flex-center rounded-circle border-default"
                  style={{ height: "4rem", width: "4rem" }}
                >
                  <Icon icon="sectional" />
                </MDBBox>
              </MDBCol>
              <MDBCol xl="6" size="4" className="d-flex align-items-center">
                <MDBTypography tag="h4" className="text-default fwlight">
                  Make it a sectional?
                </MDBTypography>
              </MDBCol>
              <MDBCol xl="4" size="5" className="d-flex align-items-center">
                <Switch
                  label={`${isSectionalChecked ? "Yes" : "No"}`.trim()}
                  checked={isSectionalChecked}
                  onChange={(e) => {
                    toggleIsSectionalChecked();
                  }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
      {isSectionalChecked && (
        <>
          {currentSectionals.map((cs, i) => {
            return (
              <AdditionalComponentOption
                key={cs.id}
                current={cs}
                option={{ title: "" }}
                pickSectional={(frame) => {
                  pickSectional(frame, i);
                }}
                values={options}
              />
            );
          })}
          <AdditionalComponentOption
            option={{ title: "" }}
            pickSectional={(frame) => {
              pickSectional(frame, currentSectionals.length);
            }}
            values={options}
          />
        </>
      )}
    </>
  );
};

export const AdditionalComponentOption = ({
  current = false,
  values = [],
  pickSectional,
}) => {
  return (
    <ModalConsumer value={{ useWrapper: false }}>
      {({ showModal, hideModal }) => {
        return (
          <MDBRow
            className="row py-4 border rounded d-flex align-items-center"
            onClick={(e) => {
              e.preventDefault();
              showModal(() => {
                return (
                  <Sidebar
                    options={values}
                    current={values[0]}
                    setOption={pickSectional}
                    hideModal={hideModal}
                  />
                );
              });
            }}
          >
            {current && (
              <>
                <MDBCol size="1">
                  <MDBBox
                    className="border rounded border-default"
                    style={{
                      width: "25px",
                      height: "25px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      className="img-fluid"
                      src={current.thumbnail}
                      alt={current.name}
                    />
                  </MDBBox>
                </MDBCol>
                <MDBCol size="6">
                  <MDBTypography tag="p" className="m-0 body-6">
                    {current.name}
                  </MDBTypography>
                </MDBCol>
              </>
            )}
            {!current && (
              <MDBCol>
                <MDBTypography tag="p" className="m-0 body-6">
                  Please select an additional component
                </MDBTypography>
              </MDBCol>
            )}
          </MDBRow>
        );
      }}
    </ModalConsumer>
  );
};

export default Product;
