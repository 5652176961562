import React, { useState, useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import { CUSTOMER_PRODUCT, PRODUCT } from "app/pages/catalog/product/queries";
import { selectIsLoggedIn } from "app/state/redux/data/customer/selectors";
import { useSelector } from "react-redux";
import ReactLoader from "app/layout/loader";
import ProductInfo from "./ProductInfo";
import Filters from "./Filters";
import ProductsList from "./ProductsList";
import {
  CUSTOMER_PRODUCTS_QUERY,
  PRODUCTS_QUERY,
} from "app/pages/catalog/category/query-container";
import { bases } from "app/pages/Custom/live-edge/data";

function LiveEdgeModal({
  options,
  setOption,
  setPreselection,
  preselection,
  sku,
}) {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  let skus = useMemo(
    () => [...options?.reduce((acc, curr) => [...acc, curr.sku], [])],
    [preselection]
  );

  const [searchVal, setSearchVal] = useState("");
  const [stockToggle, setStockToggle] = useState(false);

  const { data: productData, loading, error } = useQuery(
    isLoggedIn ? CUSTOMER_PRODUCT : PRODUCT,
    {
      fetchPolicy: "cache-and-network",
      variables: { filter: { sku: { eq: preselection?.sku } } },
    }
  );

  const setLiveEdgeOption = (product) => {
    const isBase = bases.find((base) => base.sku === product?.sku);
    const optionFound = options?.find((option) => option?.sku === product?.sku);
    const finalProduct = {
      ...optionFound,
      options: product?.options,
      id: product.id,
    };
    if (!isBase)
      window.history.replaceState(
        null,
        "",
        `/build-your-own/live-edge/${product?.sku}`
      );
    setPreselection(finalProduct);
    setOption(finalProduct);
    // hideModal();
  };

  let productsFilters = useMemo(
    () =>
      stockToggle
        ? {
            category_id: { eq: "2" },
            sku: { in: skus },
            in_stock_filter: { eq: "1" },
          }
        : { category_id: { eq: "2" }, sku: { in: skus } },
    [stockToggle, skus]
  );

  const {
    data: productsData,
    loading: loadingProducts,
    error: errorProducts,
  } = useQuery(isLoggedIn ? CUSTOMER_PRODUCTS_QUERY : PRODUCTS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      filter: productsFilters,
      search: searchVal?.toLowerCase(),
      pageSize: 100,
    },
  });

  if (loading)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <ReactLoader />
      </div>
    );
  if (error) return <p>Something went wrong;</p>;
  const {
    products: { items },
  } = productData;

  return (
    <div className="live-edge">
      <ProductInfo product={items?.[0]} isLoggedIn={isLoggedIn} sku={sku} />
      <Filters
        searchVal={searchVal}
        setSearchVal={setSearchVal}
        stockToggle={stockToggle}
        setStockToggle={setStockToggle}
      />
      <div className="col">
        <ProductsList
          data={productsData}
          loading={loadingProducts}
          error={errorProducts}
          onClick={setLiveEdgeOption}
        />
      </div>
    </div>
  );
}

export default LiveEdgeModal;
