import React, { useState } from "react";
import Breadcrumbs from "app/layout/breadcrumbs";
import {
  MDBBox,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBMask,
  MDBView,
} from "mdbreact";
import CmsBlock from "app/layout/cmsBlock";

const CompanyStory = (props) => {
  const [open, setOpen] = useState(false);
  const toggleFn = (props) => {
    setOpen(!open);
  };

  let iframeVideo = (
    <iframe
      title="Video"
      width="100%"
      height="629"
      src="https://www.youtube.com/embed/TshiUVn02-k"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  );

  return (
    <MDBContainer fluid className="company-story-container">
      <MDBRow>
        <MDBCol size="12" className="p-0 company-story-text-and-img-wrapper">
          <MDBView>
            <CmsBlock
              skeletonProps={{ height: "69.7rem", width: "100%" }}
              id={"company_main_image"}
            />
            <MDBMask></MDBMask>
          </MDBView>
          <CmsBlock
            skeletonProps={{ height: "69.7rem", width: "100%" }}
            id={"company_story_hero_image_text"}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12" className="mt-5">
                <Breadcrumbs />
              </MDBCol>
              <MDBCol size="12" className="company-title-desc">
                <CmsBlock
                  skeletonProps={{ height: "65rem", width: "100%" }}
                  id={"company_title_desc"}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
      <CmsBlock
        skeletonProps={{ height: "65.6rem", width: "100%" }}
        id="company_difference_block"
      />
      <MDBRow className="company-story-block">
        <MDBBox onClick={toggleFn} className="w-100">
          {/* <CmsBlock
            skeletonProps={{ height: "65.6rem", width: "100%" }}
            id="company_modal_image"
          /> */}
          <CmsBlock id="homepage_v2_i-am-fairfield" />
        </MDBBox>
        {/* {open && (
          <MDBModal
            isOpen={open}
            onClick={open}
            toggle={toggleFn}
            className={"modal-wrapper m-auto w-100 company-story-modal"}
            centered
          >
            <MDBModalBody>
              <MDBBox>{iframeVideo}</MDBBox>
            </MDBModalBody>
          </MDBModal>
        )} */}
      </MDBRow>
      <div className="company-store-last-row-wrapper">
        <CmsBlock
          skeletonProps={{
            height: "27.6rem",
            width: "40rem",
            marginRight: "5rem",
          }}
          id="company_story_it_starts"
        />
        <CmsBlock
          skeletonProps={{
            height: "27.6rem",
            width: "40rem",
            marginRight: "5rem",
          }}
          id="company_choices"
        />
        <CmsBlock
          skeletonProps={{
            height: "27.6rem",
            width: "40rem",
            marginRight: "5rem",
          }}
          id="company_explore"
        />
        <CmsBlock
          skeletonProps={{
            height: "27.6rem",
            width: "40rem",
            marginRight: "5rem",
          }}
          id="company_banners"
        />
      </div>
    </MDBContainer>
  );
};

export default CompanyStory;
