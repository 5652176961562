import { Country, Customer, Maybe } from "app/generated/graphql";
import { customerPaths } from "app/pages/customer/routes";
import { MDBBtn, MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";
import AddressOverview from "../../components/AddressOverview";
import { Address } from "../../models";
import { addCountryNameToAddress, isAddressDefined } from "../../utils";

export interface AddressesProps {
  countries: Country[];
  addresses: Customer["addresses"];
}

const Addresses: React.FC<AddressesProps> = (props) => {
  const billingAddress: Maybe<Address> = React.useMemo(() => {
    const address =
      props?.addresses?.find((address) => address?.default_billing) ?? null;
    return address && addCountryNameToAddress(props.countries)(address);
  }, [props]);
  const shippingAddress: Maybe<Address> = React.useMemo(() => {
    const address =
      props?.addresses?.find((address) => address?.default_shipping) ?? null;
    return address && addCountryNameToAddress(props.countries)(address);
  }, [props]);
  const otherAddresses = React.useMemo(
    () =>
      props.addresses
        ?.filter(
          (address) => !address?.default_billing && !address?.default_shipping
        )
        .filter(isAddressDefined)
        .map(addCountryNameToAddress(props.countries)),
    [props]
  );
  return (
    <>
      <MDBTypography tag="h3" className="mb-5">
        Address Book
      </MDBTypography>
      <MDBRow>
        {billingAddress && (
          <AddressOverview
            address={billingAddress}
            title="Default Billing Address"
            editLink={
              customerPaths.children.account.children.addresses.children.mailing
                .path
            }
          />
        )}
        {shippingAddress && (
          <AddressOverview
            address={shippingAddress}
            title="Default Shipping Address"
            editLink={
              customerPaths.children.account.children.addresses.children
                .shipping.path
            }
          />
        )}
      </MDBRow>
      <MDBRow className="mt-5">
        <MDBCol>
          <MDBTypography className="mb-4" tag="h3">
            Additional Address Entries
          </MDBTypography>
          {/* @ts-ignore */}
          <MDBRow>
            {otherAddresses?.length ? (
              otherAddresses.map((address, i) => (
                <AddressOverview
                  key={address.id}
                  address={address}
                  title={``}
                  editLink={`${customerPaths.children.account.children.addresses.path}/${address?.id}`}
                />
              ))
            ) : (
              <MDBCol>
                You have no new address entries in your address book
              </MDBCol>
            )}
          </MDBRow>
          <Link
            to={
              customerPaths.children.account.children.addresses.children.new
                .path
            }
            className="d-inline-block mt-5"
          >
            <MDBBtn size="lg">Add New Address</MDBBtn>
          </Link>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default Addresses;
