import { number_format } from "core/components/price/price";
import { MDBTypography } from "mdbreact";
import React from "react";
import { PriceProps } from "../models";

const Subtotal: React.FC<PriceProps> = (props) => {
  return (
    // @ts-ignore */}
    <MDBTypography variant="body-1">
      {props.currency} {number_format(props.price, 2)}
    </MDBTypography>
  );
};

export default Subtotal;
