import JolaViewer from "@jola_interactive/jola_viewer";
import {
  TextureLoader,
  sRGBEncoding,
  RepeatWrapping,
  Color,
  PlaneBufferGeometry,
  MeshBasicMaterial,
  ShadowMaterial,
  Mesh,
  Fog,
  Euler,
  Box3,
  Vector3,
  LineBasicMaterial,
  BufferGeometry,
  Line,
  FontLoader,
  TextGeometry,
  Group,
} from "three";
import themeConfiguration from "../../../config/themeConfiguration";

export const zoomOptions = {
  min: -3,
  current: 0,
  max: 8,
};

export class Player extends JolaViewer {
  constructor(containerID, options) {
    super(containerID, options);

    this.collectionPath = options.collectionPath
      ? options.collectionPath
      : "benchmade-square-ottomans";

    this.hdrPath = `${themeConfiguration.model_url}/3d/${this.collectionPath}/webgl/hdr/hdr.hdr`;
    this.loadHDR(this.hdrPath, 0.3);

    this.modelDimensions = new Group();

    this.scene.background = new Color("#fbfbfb");

    this.materials = [];

    this.style = {
      id: "H",
      sku: "H",
      name: "Bench",
      image: `images/style-H.jpg`,
      parentName: "Style",
      sizes: ["48", "52", "60"],
    };
    this.top = {
      id: "P",
      sku: "P",
      name: "Plain",
      image: `images/top-P.jpg`,
      parentName: "Top",
    };
    this.nail = {
      id: "S",
      sku: "S",
      name: "Standard (no nails)",
      image: `images/nail-S.jpg`,
    };
    this.baseObject = {
      id: "A",
      sku: "A",
      name: "Acrylic Leg",
      image: "images/base-A.jpg",
    };
    this.size = {
      id: "60",
      sku: "60",
      name: "60",
      image: "images/60.jpg",
    };
    this.mainFinish = {
      id: "3100-72",
      name: "Linen",
      map: `${themeConfiguration.model_url}/3d/textures/fabric/3100_linen_seamless.jpg`,
      normal: `${themeConfiguration.model_url}/3d/textures/fabric/3100_linen_seamless_normal.jpg`,
      icon: `${themeConfiguration.model_url}/3d/textures/fabric/3100_linen_seamless_icon.jpg`,
      repeat: "8",
    };
    this.baseFinish = {
      id: "CE",
      name: "Cerused Espresso",
      map: `${themeConfiguration.model_url}/3d/textures/wood/cerused_espresso_seamless.jpg`,
      normal: `${themeConfiguration.model_url}/3d/textures/wood/cerused_espresso_seamless_normal.jpg`,
      icon: `${themeConfiguration.model_url}/3d/textures/wood/cerused_espresso_seamless_icon.jpg`,
    };

    this.modelsPath = `${themeConfiguration.model_url}/3d/${this.collectionPath}/webgl/models`;

    this.textureLoader = new TextureLoader();

    this.dracoLoader.setDecoderPath("/decoder/");
    this.loader.setDRACOLoader(this.dracoLoader);
  }

  LoadTexture(url) {
    return new Promise((resolve) => {
      this.textureLoader.load(url, (result) => resolve(result));
    });
  }

  LoadObject(url) {
    return new Promise((resolve) => {
      this.loader.load(url, (result) => {
        this.updateMap(result.scene);
        resolve(result);
      });
    });
  }

  async setStyle(object, load = true) {
    this.style = object;

    if (object.sizes && !object.sizes.includes(this.size.sku)) {
      await this.setSizes(
        {
          id: object.sizes[0],
          sku: object.sizes[0],
          name: object.sizes[0],
        },
        false
      );
    }

    if (load) await this.Load();
  }

  async setTop(object, load = true) {
    this.top = object;
    if (load) await this.Load();
  }

  async setNail(object, load = true) {
    this.nail = object;
    if (load) await this.Load();
  }

  async setBase(object, load = true) {
    this.baseObject = object;
    if (load) await this.Load();
  }

  async setSizes(object, load = true) {
    this.size = object;
    if (load) await this.Load();
  }

  async Init() {
    this.scene.add(this.modelDimensions);

    await this.SetFloor();

    if (this.mainFinish) {
      await this.LoadFinish(
        "main",
        this.mainFinish.map,
        this.mainFinish.icon,
        this.mainFinish.name,
        this.mainFinish.repeat,
        this.mainFinish.roughness,
        this.mainFinish.metalness
      );
    }

    if (this.baseFinish) {
      await this.LoadFinish(
        "woodMAT",
        this.baseFinish.map,
        this.baseFinish.icon,
        this.baseFinish.name,
        this.baseFinish.repeat,
        this.baseFinish.roughness,
        this.baseFinish.metalness
      );
    }

    await this.Load();
  }

  async Load() {
    this.model.children = [];

    let frame = await this.LoadObject(
      `${this.modelsPath}/frame-${this.size.sku}-${this.style.sku}.gltf`
    );
    frame.scene.getObjectByName("Top").traverse((top) => {
      if (top.isMesh && top.name !== `${this.top.sku}${this.size.sku}`) {
        top.visible = false;
      }
    });
    if (this.baseObject.sku === "S") {
      let skirt = await this.LoadObject(
        `${this.modelsPath}/leg-${this.baseObject.sku}-${this.size.sku}-${this.style.sku}.gltf`
      );
      this.model.add(skirt.scene);
    } else {
      let base = await this.LoadObject(
        `${this.modelsPath}/leg-${this.baseObject.sku}.gltf`
      );
      frame.scene.getObjectByName("sockets").traverse((socket) => {
        if (socket.name.startsWith("leg")) {
          let baseClone = base.scene.clone();
          socket.getWorldPosition(baseClone.position);
          baseClone.position.add(
            baseClone.getObjectByName("leg_b").position.negate()
          );
          socket.getWorldQuaternion(baseClone.rotation);
          this.model.add(baseClone);
        }
      });
    }

    this.model.add(frame.scene);
    if (this.nail.sku !== "S" && this.baseObject.sku !== "S") {
      let nails = await this.LoadObject(
        `${this.modelsPath}/nail-${this.nail.sku}-${this.size.sku}-${this.style.sku}.gltf`
      );
      this.model.add(nails.scene);
    }

    await this.updateMap(this.model);
    await this.UpdateCameraPosition();
  }

  async LoadFinish(
    type,
    map,
    icon,
    name,
    repeat = 1,
    roughness,
    metalness,
    updateCameraPosition = false
  ) {
    let newMap = null;

    if (map) {
      var finishLoader = await this.LoadTexture(map);

      newMap = finishLoader;
      newMap.wrapS = RepeatWrapping;
      newMap.wrapT = RepeatWrapping;
      newMap.repeat.set(repeat, repeat);
    }

    let materialObject = {
      type: type,
      map: newMap,
      icon: icon,
      name: name,
      roughness: roughness || null,
      metalness: metalness || null,
    };

    let found = false;
    this.materials.forEach((material) => {
      if (material.type === type) {
        found = true;

        material.map = newMap;
        material.icon = icon;
        material.name = name;

        material.roughness = roughness || null;
        material.metalness = metalness || null;
      }
    });

    if (!found) {
      this.materials.push(materialObject);
    }

    this.updateMap(this.model);

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async setDimensions(productDimensions) {
    this.productDimensions = productDimensions;
  }

  async setDimensionsVisible(newState) {
    this.dimensionsToggle = newState;

    if (this.dimensionsToggle) {
      await this.ShowDimensions();
    } else {
      await this.HideDimensions();
    }
  }

  async HideDimensions() {
    this.modelDimensions.children = [];
  }

  async ShowDimensions() {
    await this.HideDimensions();

    let box = new Box3().setFromObject(this.model);

    let lineMaterial = new LineBasicMaterial({
      color: 0x000000,
    });

    let lineOffset = 0.02;

    let x = [];
    x.push(
      new Vector3(box.min.x, box.max.y + lineOffset, box.min.z - lineOffset)
    );
    x.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.min.z - lineOffset
      )
    );

    let y = [];
    y.push(
      new Vector3(box.max.x + lineOffset, box.min.y, box.max.z + lineOffset)
    );
    y.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.max.z + lineOffset
      )
    );

    let z = [];
    z.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.max.z + lineOffset
      )
    );
    z.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.min.z - lineOffset
      )
    );

    let geometryX = new BufferGeometry().setFromPoints(x);
    let geometryY = new BufferGeometry().setFromPoints(y);
    let geometryZ = new BufferGeometry().setFromPoints(z);

    let lineX = new Line(geometryX, lineMaterial);
    lineX.name = "lineX";
    let lineY = new Line(geometryY, lineMaterial);
    lineY.name = "lineY";
    let lineZ = new Line(geometryZ, lineMaterial);
    lineZ.name = "lineZ";

    lineX.computeLineDistances();

    this.modelDimensions.add(lineX);
    this.modelDimensions.add(lineY);
    this.modelDimensions.add(lineZ);

    let swapDimensions = false;

    let boxWidth = box.max.x - box.min.x;
    let boxHeight = box.max.y - box.min.y;
    let boxDepth = box.max.z - box.min.z;

    if (
      (boxWidth < boxDepth &&
        Number(this.productDimensions.width) >
          Number(this.productDimensions.depth)) ||
      (boxWidth > boxDepth &&
        Number(this.productDimensions.width) <
          Number(this.productDimensions.depth))
    ) {
      swapDimensions = true;
    }

    let distanceX = swapDimensions
      ? this.productDimensions.depth
      : this.productDimensions.width;
    let distanceY = this.productDimensions.height;
    let distanceZ = swapDimensions
      ? this.productDimensions.width
      : this.productDimensions.depth;

    const fontLoader = new FontLoader();

    fontLoader.load(
      `${themeConfiguration.model_url}/3d/fonts/helvetiker_regular.typeface.json`,
      (font) => {
        const fontGeometryX = new TextGeometry(distanceX.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });

        fontGeometryX.translate(-0.1, 0, 0);

        let fontMeshX = new Mesh(
          fontGeometryX,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        let fontOffset = 0.02;

        fontMeshX.position.copy(
          new Vector3(
            box.min.x + (box.max.x - box.min.x) / 2,
            box.max.y + fontOffset + lineOffset,
            box.min.z
          )
        );
        fontMeshX.updateMatrixWorld();

        fontMeshX.name = "fontMeshX";
        this.modelDimensions.add(fontMeshX);

        const fontGeometryY = new TextGeometry(distanceY.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });

        fontGeometryY.translate(0.05, 0, 0);

        let fontMeshY = new Mesh(
          fontGeometryY,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        fontMeshY.rotateY(-Math.PI / 2);
        fontMeshY.position.copy(
          new Vector3(
            box.max.x,
            box.min.y + (box.max.y - box.min.y) / 2,
            box.max.z
          )
        );
        fontMeshY.updateMatrixWorld();

        fontMeshY.name = "fontMeshY";
        this.modelDimensions.add(fontMeshY);

        const fontGeometryZ = new TextGeometry(distanceZ.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });
        fontGeometryZ.translate(-0.05, 0, 0);

        let fontMeshZ = new Mesh(
          fontGeometryZ,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        fontMeshZ.rotateY(-Math.PI / 2);
        fontMeshZ.position.copy(
          new Vector3(
            box.max.x,
            box.max.y + fontOffset + lineOffset,
            box.min.z + (box.max.z - box.min.z) / 2
          )
        );
        fontMeshZ.updateMatrixWorld();

        fontMeshZ.name = "fontMeshZ";
        this.modelDimensions.add(fontMeshZ);
      }
    );
  }

  update() {
    requestAnimationFrame(this.update);
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    if (this.dimensionsToggle) {
      let fontMeshX = this.modelDimensions.getObjectByName("fontMeshX");
      let fontMeshY = this.modelDimensions.getObjectByName("fontMeshY");
      let fontMeshZ = this.modelDimensions.getObjectByName("fontMeshZ");
      fontMeshX && fontMeshX.lookAt(this.camera.position);
      fontMeshY && fontMeshY.lookAt(this.camera.position);
      fontMeshZ && fontMeshZ.lookAt(this.camera.position);
    }
    this.cameraAngle = this.getCameraAngle();
  }

  updateMap(object) {
    object.traverse((o) => {
      if (o.isMesh) {
        o.castShadow = true;
        o.receiveShadow = true;

        let material = this.materials.find(
          (material) => material.type === o.material.name
        );

        if (material) {
          if (material.map) {
            o.material.map = material.map;
            o.material.map.encoding = sRGBEncoding;
          } else {
            o.material.map = undefined;
          }

          if (material.roughness) o.material.roughness = material.roughness;
          if (material.metalness) o.material.metalness = material.metalness;
          if (material.color) o.material.color = new Color(material.color);

          if (material.type === "nail_finish") {
            o.material.color = new Color("#ffffff");
          }
        }
        if (this.baseObject.material) {
          if (o.material.name === "A_metal" || o.material.name === "metal") {
            o.material.color = new Color(this.baseObject.material.color);
            o.material.roughness = this.baseObject.material.roughness;
            o.material.metalness = this.baseObject.material.metalness;
          }
        }

        if (this.envMap) {
          o.material.envMap = this.envMap;
          o.material.envMapIntensity = this.envMapIntensity;
        }

        o.material.needsUpdate = true;
      }
    });
  }

  async UpdateCameraPosition() {
    let box = new Box3().setFromObject(this.model);
    let size = box.getSize(new Vector3()).length();
    let center = box.getCenter(new Vector3());

    if (this.plane) {
      this.plane.position.y = box.min.y;
      this.shadowPlane.position.y = box.min.y;
    }

    this.controls.minDistance = size * 0.75;
    this.controls.maxDistance = size * 1.5;

    this.camera.position.copy(center);
    this.camera.position.x += 0; //this.size * 0.1;
    this.camera.position.y += size * 0.2;
    this.camera.position.z += size * 5.3;
    this.camera.updateMatrixWorld();

    this.controls.target = center;
    this.camera.lookAt(center);

    this.camera.updateProjectionMatrix();

    if (this.dimensionsToggle) {
      await this.ShowDimensions();
    }

    document.getElementById("loading-screen").classList.add("fade-out");
  }

  setPlaneVisible(newState) {
    if (this.plane) {
      this.plane.visible = newState;
    }
  }

  async SetFloor() {
    let parquetMap = await this.LoadTexture(
      `${themeConfiguration.model_url}/3d/textures/floor/grid.jpg`
    );
    parquetMap.repeat.set(500, 500);
    parquetMap.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    parquetMap.wrapT = RepeatWrapping;
    parquetMap.wrapS = RepeatWrapping;

    let geo = new PlaneBufferGeometry(100, 100);
    let mat = new MeshBasicMaterial({
      map: parquetMap,
    });

    this.plane = new Mesh(geo, mat);
    //plane.visible = false;
    this.plane.receiveShadow = true;
    this.plane.position.set(0, 0, 0);
    this.plane.rotation.set(Math.PI / -2, 0, 0);

    this.scene.fog = new Fog(0xfbfbfb, 10, 20);
    this.scene.add(this.plane);

    let shadowMat = new ShadowMaterial({ opacity: 0.2 });
    this.shadowPlane = new Mesh(geo, shadowMat);
    this.shadowPlane.receiveShadow = true;
    this.shadowPlane.position.set(0, 0, 0);
    this.shadowPlane.rotation.set(Math.PI / -2, 0, 0);
    this.scene.add(this.shadowPlane);
  }

  async LoadFloor(map, repeat) {
    let newMap = await this.LoadTexture(map);
    newMap.repeat.set(repeat, repeat);
    newMap.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    newMap.wrapS = RepeatWrapping;
    newMap.wrapT = RepeatWrapping;

    this.plane.material.map = newMap;
  }

  degreesToRadians = (degrees) => {
    var pi = Math.PI;
    return degrees * (pi / 180);
  };

  determinCameraDistance = (modelSize) => {
    let cameraDistance;
    let halfFOVInRadians = this.degreesToRadians(
      (this.camera.fov * this.camera.aspect) / 4
    );
    let height = modelSize.height;
    cameraDistance = height / 2 / Math.tan(halfFOVInRadians);
    return cameraDistance;
  };

  updateZoom(zoom, diff = 0) {
    let newZoom = 1 + (zoom + diff) / 10;

    if (
      newZoom >= 1 + zoomOptions.min / 10 &&
      newZoom <= 1 + zoomOptions.max / 10
    ) {
      this.controls.object.zoom = newZoom;
      this.controls.object.updateProjectionMatrix();
      return {
        ...zoomOptions,
        current: zoom + diff,
      };
    } else {
      return { ...zoomOptions, current: zoom };
    }
  }

  getCameraAngle = () => {
    const euler = new Euler();
    const rotation = euler.setFromQuaternion(this.camera.quaternion);
    const radians = rotation._z > 0 ? rotation._z : 2 * Math.PI + rotation._z;
    return radians * (180 / Math.PI);
  };

  getCameraPosition() {
    return {
      x: this.camera.position.x,
      y: this.camera.position.y,
      z: this.camera.position.z,
    };
  }

  setCameraPosition({ x = 50, y = -1, z = 50 }) {
    this.camera.position.x = x;
    this.camera.position.y = y;
    this.camera.position.z = z;
  }
}
