import { ApolloError } from "apollo-client";
import {
  useExpressRegistrationLazyQuery,
  useGenerateResetPasswordTokenMutation,
} from "app/generated/graphql";
import { messagesActions } from "core/state/redux/data/messages";
import { FormikConfig, useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  emailValidationSchema,
  EmailValues,
  initialEmailValues,
} from "../EmailAndPassword/config";
import {
  accountNumberSchema,
  accountNumberValues,
  AccountNumberValues,
} from "./config";
import Express from "./Express";

export interface DataContainerProps {
  onClose: () => void;
}

const DataContainer: React.FC<DataContainerProps> = (props) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [
    expressRegistration,
    expressRegistrationQuery,
  ] = useExpressRegistrationLazyQuery({
    onCompleted: ({ expressRegistration }) => {
      accountNumberForm.setSubmitting(false);
      if (!expressRegistration) {
        accountNumberForm.setFieldError(
          "accountNumber",
          `We are not able to locate your account, please make sure that you entered the right account information. <a class="body-1 underline" href="/customer/register">Register</a> or <a class="body-1 underline" href="/contact">Contact Us</a> for further assistance.`
        );
      }
    },
    onError: (error) => {
      dispatch(messagesActions.addMessage(error.graphQLErrors[0]?.message));
    },
  });
  const accountNumberConfig: FormikConfig<AccountNumberValues> = React.useMemo(
    () => ({
      initialValues: accountNumberValues,
      validationSchema: accountNumberSchema,
      onSubmit: (values) => {
        return expressRegistration({
          variables: { account_number: values.accountNumber },
        });
      },
    }),
    [expressRegistration]
  );
  const accountNumberForm = useFormik(accountNumberConfig);
  const [generateResetPasswordToken] = useGenerateResetPasswordTokenMutation();

  const emailConfig: FormikConfig<EmailValues> = React.useMemo(
    () => ({
      initialValues: initialEmailValues,
      validationSchema: emailValidationSchema,
      onSubmit: (values) => {
        return generateResetPasswordToken({
          variables: { email: values.email },
        })
          .then((result) => {
            if (result.data?.generateResetPasswordToken?.message) {
              dispatch(
                messagesActions.addMessage(
                  result.data?.generateResetPasswordToken?.message,
                  "success"
                )
              );
              props.onClose();
              history.push({ search: "" });
            }
          })
          .catch((error: ApolloError) => {
            console.log(error)
            dispatch(
              messagesActions.addMessage(
                error.graphQLErrors[0]?.message,
                "error"
              )
            );
          });
      },
    }),
    [generateResetPasswordToken, dispatch, props, history]
  );
  const emailForm = useFormik(emailConfig);

  return (
    <Express
      accountNumberForm={accountNumberForm}
      accountNumberFound={!!expressRegistrationQuery.data?.expressRegistration}
      emailForm={emailForm}
    />
  );
};

export default DataContainer;
