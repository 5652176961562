// @ts-check

// Global application style
import "@fortawesome/fontawesome-free/css/all.min.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Icon from "app/assets/icon/icon";
import { CartContextProvider } from "app/layout/cart";
import CollectionsProvider from "app/layout/collections/CollectionsContext/CollectionsProvider";
import ConfigurationLoader from "app/layout/configurationLoader";
import { DownloadBar, DownloadProvider } from "app/layout/Downloader";
import ModalWrapper from "app/layout/modal/ModalWrapper";
import Modal from "core/components/modal";
import { ModalProvider } from "core/components/modal/ModalContext";
import { default as DynamicRouter } from "app/overrides/router";
import SessionMessages from "app/layout/sessionMessages";
import React, { useEffect } from "react";
import { withApollo } from "react-apollo";
import "slick-carousel/slick/slick-theme.css";
// Import css files
import "slick-carousel/slick/slick.css";
import "react-magic-slider-dots/dist/magic-dots.css";
import Footer from "./footer";
import Header from "./header";
import NetworkDetector from "./NetworkDetector";
import { useSelector, useDispatch } from "react-redux";
import { customerActions } from "core/state/redux/data/customer";
import NewVersion from "./newVersion";
import ReactGa from "react-ga";
import { useHistory } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";

library.add(fab, fas);

// --------------------------------------------------------------------------------------------------------------------------------------------------------
// This is the layout definition for every page on the website, it's content controls how any page will be rendered.
// It is used to bootstrap the header, footer, modals, and misc components, as well as provide a point of insertion for the content of any page based on
// the current URL.
// This file should not be edited unless you are making a change that should effect the layout of all pages
// Keep in mind that this is loaded on every page of the website, so try to keep it and it's children light
// --------------------------------------------------------------------------------------------------------------------------------------------------------

/**
 *
 * @param {*} props
 */
const Layout = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // @ts-ignore

  return (
    <ModalProvider {...props}>
      <CollectionsProvider>
        <DownloadProvider>
          <CartContextProvider>
            <ConfigurationLoader />
            <NetworkDetector />
            <Header />
            <div className={`session-container`}>
              {/* SESSION MESSAGES */}
              <SessionMessages
                target="global"
                successIcon={<Icon icon="success" />}
                errorIcon={<Icon icon="error" />}
              />
            </div>
            <div className={"main"}>
              <DynamicRouter /> {/* CONTENT */}
            </div>
            <Modal ModalWrapper={ModalWrapper} />
            <Footer />
            <DownloadBar />
            <NewVersion />
          </CartContextProvider>
        </DownloadProvider>
      </CollectionsProvider>
    </ModalProvider>
  );
};

export default withApollo(Layout);
