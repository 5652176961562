import CmsBlock from "app/layout/cmsBlock";
import ProductWidget from "app/layout/productWidget";
import { MDBBox, MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";
import SkeletonResults from "./skeletonResults";

const SearchResults = ({ toggleFn, category, searchResult }) => {
  return (
    <>
      <MDBContainer className="p-0">
        <MDBRow className="my-4">
          <MDBCol className="text-left">
            <MDBTypography tag="span" className="body-4">
              {category}
            </MDBTypography>
          </MDBCol>
          {typeof searchResult?.products !== "undefined" &&
          searchResult?.products.total_count > 3 ? (
            <MDBCol className="text-right">
              <MDBTypography tag="span" className="body-4">
                4 of{" "}
                {typeof searchResult?.products !== "undefined" &&
                  searchResult?.products.total_count}{" "}
                results
              </MDBTypography>
            </MDBCol>
          ) : (
            <MDBCol className="text-right">
              <MDBTypography tag="span" className="body-4">
                {typeof searchResult?.products !== "undefined" &&
                  searchResult?.products.total_count}{" "}
                results
              </MDBTypography>
            </MDBCol>
          )}
        </MDBRow>
        <MDBRow>
          {typeof searchResult?.products !== "undefined" ? (
            searchResult?.products.items.length > 0 ? (
              searchResult?.products.items.map((product, index) => {
                let price =
                  product?.price_range?.minimum_price?.final_price?.value ||
                  undefined;
                return (
                  <React.Fragment key={index + product.sku}>
                    {index <= 3 && (
                      <ProductWidget
                        showFavorites={false}
                        grade={product.ffifabrics_grade}
                        colSize="12"
                        lgSize="6"
                        onClick={toggleFn}
                        product={product}
                        url={
                          //Product that have the same sku as BYO products from collections (Haven Reserve, Urban Living, Senior Living Solutions - Products category) redirected to BYO PDP
                          (typeof product?.ffi_collection !== "undefined" &&
                            product?.ffi_collection?.[0] !== false &&
                            product?.ffi_cfgmodel &&
                            (product?.ffi_collection?.includes("Haven Reserve")
                              ? `build-your-own/haven-reserve/${product.sku}`
                              : product?.ffi_collection?.includes(
                                  "Urban Living"
                                )
                              ? `build-your-own/urban-living/${product.sku}`
                              : product?.ffi_collection?.includes(
                                  "Senior Living Solutions"
                                )
                              ? `build-your-own/senior-living-solutions/${product.sku}`
                              : product?.ffi_collection?.includes("Monogram")
                              ? `build-your-own/monogram/${product.sku}`
                              : product?.url_key)) ||
                          product?.url_key
                        }
                        isSearchPage
                        key={index}
                        price={price}
                        {...product}
                        className={`px-5 mb-4 ${
                          index < 2 ? "pb-1" : "pb-0"
                        }`.trim()}
                        category={category}
                      />
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <MDBBox className="w-100">
                <MDBBox className="text-left">
                  <MDBTypography tag="h3">Results not found.</MDBTypography>
                  <MDBTypography tag="span" className="body-4">
                    Be sure to search by Product Type, Material Type, or SKU
                    Number
                  </MDBTypography>
                </MDBBox>
                <MDBBox className="build-content">
                  <div
                    className="col-12 my-5 build-content-link"
                    onClick={toggleFn}
                    onKeyDown={toggleFn}
                    role="button"
                    tabIndex={0}
                  >
                    <Link
                      to={"/collections/build-your-own"}
                      className="btn-light-blue btn"
                    >
                      View Collection
                    </Link>
                  </div>
                  <CmsBlock
                    id="search_build_own"
                    skeletonProps={{ height: "50rem", width: "100%" }}
                  />
                </MDBBox>
              </MDBBox>
            )
          ) : (
            <MDBBox style={{ width: "100%", height: "100%" }}>
              <SkeletonResults />
            </MDBBox>
          )}
        </MDBRow>
      </MDBContainer>
    </>
  );
};
export default SearchResults;
