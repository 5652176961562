import React from "react";
import { MDBBtn, MDBCol, MDBModal, MDBModalBody, MDBRow } from "mdbreact";
import ModalHeader from "app/layout/ModalHeader";
import CreateWishlist from "./CreateWishlist";
import { useCreationWishlistForm } from "../hooks";

const CreateListModal = ({ isOpen, setIsOpen }) => {
  const form = useCreationWishlistForm();
  return (
    <MDBModal
      isOpen={isOpen}
      inline={false}
      overflowScroll
      noClickableBodyWithoutBackdrop={false}
      centered
      className="create-list-modal"
    >
      <MDBModalBody className="p-0">
        <ModalHeader onClose={setIsOpen.setFalse}>New List Name</ModalHeader>
        <MDBRow className="mt-5 mx-5 mb-5">
          <MDBCol>
            <MDBRow className="mt-3">
              <CreateWishlist form={form} />
              <MDBCol className="d-flex">
                <MDBBtn
                  onClick={setIsOpen.setFalse}
                  className="mr-4"
                  color="secondary"
                >
                  Cancel
                </MDBBtn>
                <MDBBtn
                  onClick={() =>
                    new Promise((resolve, reject) =>
                      resolve(form.submitForm())
                    ).then(() => setIsOpen.setFalse())
                  }
                  className={`create-btn`}
                  disabled={form.isSubmitting}
                >
                  Save
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBModalBody>
    </MDBModal>
  );
};

export default CreateListModal;
