import {
  createToggleIsOnline,
  selectIsOnline,
} from "app/state/redux/data/networkStatus/networkStatus";
import { MDBTypography } from "mdbreact";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

interface NetworkDetectorProps {
  sticky?: boolean;
}

const stickyStyle: React.CSSProperties = {
  position: "sticky",
  top: 0,
  zIndex: 999999,
};

const NetworkDetector: React.FC<NetworkDetectorProps> = ({
  sticky = false,
}) => {
  const isOnline = useSelector(selectIsOnline);
  const dispatch = useDispatch();

  const toggleIsOnline = React.useCallback(
    () => dispatch(createToggleIsOnline()),
    [dispatch]
  );

  React.useEffect(() => {
    window.addEventListener("offline", toggleIsOnline);

    window.addEventListener("online", toggleIsOnline);

    return () => {
      window.removeEventListener("offline", toggleIsOnline);
      window.removeEventListener("online", toggleIsOnline);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isOnline ? null : (
    <div
      style={sticky ? stickyStyle : {}}
      className="bg-black d-flex align-items-center justify-content-center"
    >
      <MDBTypography className="my-2 text-white fwregular fs-13">
        YOU ARE CURRENTLY BROWSING IN OFFLINE MODE
      </MDBTypography>
    </div>
  );
};

export default NetworkDetector;
