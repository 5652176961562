import React, { useState } from "react";
import Title from "../Title";

function DesignersAndWinners({
  title,
  inRow,
  items,
  getName,
  setSelectedImage = () => {},
}) {
  const [hoverItem, setHoverItem] = useState(null);
  const getDescription = (desc) => {
    const num = desc[0];
    const superscript = desc[1] + desc[2];
    return (
      <h3>
        {num}
        <span className="superscript">{superscript}</span> Place
      </h3>
    );
  };
  return (
    <>
      <Title text={title} />
      <div className={`${inRow}-items`}>
        {items?.map((item) => (
          <div
            key={item?.id}
            className={`position-relative ${
              inRow === "four" && "cursor-pointer"
            }`}
            onClick={() => {
              if (inRow === "four") setSelectedImage(item?.banner_image);
            }}
          >
            {item?.description && (
              <div className="name-wrapper-top">
                {getDescription(item?.description)}
              </div>
            )}
            <div
              onMouseOver={() => inRow === "four" && setHoverItem(item)}
              onMouseLeave={() => inRow === "four" && setHoverItem(item?.image)}
              className="d-flex align-items-center h-100"
            >
              <img
                src={
                  hoverItem?.id === item?.id
                    ? hoverItem?.banner_image
                    : item?.image
                }
                alt={item?.name}
                className={`img-fluid ${
                  hoverItem?.id === item?.id && "hover-image"
                }`}
              />
            </div>
            <div className="name-wrapper-bottom">
              <h3 className="font-weight-bold text-center">
                {getName(item?.name, 0)}
              </h3>
              <a href={getName(item?.name, 2)} target="_blank">
                <h3 className="font-weight-light text-center">
                  {getName(item?.name, 1)}
                </h3>
              </a>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default DesignersAndWinners;
