/**
 * If you want your redux state to be persistant, you will need to add a initialState function and map to the key accordingly
 * The key should match what is listed in the persistorConfigurator and also the Redux reducer
 */
import { persistList, getStateByKey } from "./persistorConfigurator";
import { getSearchState } from "app/state/redux/initialStates";

export const projectStates = {
  // test: getTestState
  searchPage: getSearchState,
};

export const generateAppPersistedState = (store, list = persistList) => {
  let persistedList = {};
  Object.keys(list).map((key, value) => {
    let currentListValue = list[key];
    if (currentListValue) {
      let data = getStateByKey(store, key, projectStates);
      return (persistedList[key] = data);
    }
    return false;
  });
  return persistedList;
};

export default projectStates;
