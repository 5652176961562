import React, { useEffect, useState } from "react";
import FabricSidebarQueryContainer from "./fabricSidebar-query-container";
import { CustomPriceContext } from "../customPriceContext";

export const FabricSidebar = ({
  fabrics,
  setFabricOrFinishSelectedOptions,
  customizationOptionPrice,
  customizationOptions,
  calculatedUpholsteryOptionsFabrics,
  price,
  upholsteryOptionsFabrics,
  customer,
  setPrice,
  dropdownPrice,
  viewMorePdpPrice,
  contrastWeltPrice,
  trimPrice,
  setTrimPrice,
  ...rest
}) => {
  let displayProps = {
    fabrics,
    customizationOptionPrice,
    customizationOptions,
    setFabricOrFinishSelectedOptions,
    calculatedUpholsteryOptionsFabrics,
  };

  const [localTrimPrice, setLocalTrimPrice] = useState(trimPrice);
  useEffect(() => {
    setTrimPrice(localTrimPrice);
  }, [localTrimPrice]);

  return (
    <CustomPriceContext.Provider
      value={{
        customizationOptionPrice,
        upholsteryOptionsFabrics,
        customer,
        price,
        setPrice,
        dropdownPrice,
        viewMorePdpPrice,
        contrastWeltPrice,
        trimPrice: localTrimPrice,
        setTrimPrice: setLocalTrimPrice,
      }}
    >
      {" "}
      <FabricSidebarQueryContainer {...rest} {...displayProps} />{" "}
    </CustomPriceContext.Provider>
  );
};
