import React from "react";
import { useHistory } from "react-router";
import { useMediaQuery } from "react-responsive";
import CmsBlock from "app/layout/cmsBlock";
import SeoHelmet from "app/layout/seoHelmet";
import Collection from "app/pages/home/collection";
import Explore from "app/pages/home/explore";
import ExploreIndustry from "app/pages/home/exploreIndustry";
import Showroom from "app/pages/home/showroom";
import WhatsNew from "app/pages/home/whatsNew";
import { MDBContainer } from "mdbreact";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Vdc from "./Vdc";
import HomepageBanner from "./homepageBanner";
import HomeContainerV2 from "./HomeContainerV2";
import useTagboxHelper from "app/layout/Tagbox/useTagboxHelper";

const Home = () => {
  const history = useHistory();
  const isDesktop = useMediaQuery({ maxWidth: 1440 });
  const isTablet = useMediaQuery({ maxWidth: 992 });
  const iframe = `<iframe
  src="https://player.vimeo.com/video/623602799?h=8430f02f06&autoplay=1&loop=1&showinfo=0&controls=0&muted=1"
  frameborder="0"
  style="position:absolute;top:0;left:0;width:100%;height:100%;"
></iframe>`;

  const { tag } = useTagboxHelper();

  return (
    <>
      <SeoHelmet title={""} url={""} />
      <MDBContainer className={"no-header-offset home-container p-0"} fluid>
        {/* {!isTablet ? (
          <div className="plr-6">
            <div className="video-wrapper d-flex flex-column justify-content-center align-items-center">
              <div
                dangerouslySetInnerHTML={{ __html: iframe }}
                className={`iframe`}
              ></div>
            </div>
            <div className="building-wrapper">
              <p className="mb-0 mr-5">Furniture personalized by you.</p>
              <button
                class={`btn-white btn Ripple-parent button-type-2-text `}
                type="button"
                data-test="button"
                onClick={() => history.push("/collections/build-your-own")}
              >
                Start Building
              </button>
            </div>
          </div>
        ) : null}
        {isTablet ? (
          <MDBRow className="px-4">
            <MDBCol className="px-lg-5 px-0 homepage-hero-banner">
              <MDBRow className="row w-100 d-flex flex-column-reverse flex-lg-row mx-0 mb-0 mb-lg-5">
                <CmsBlock id="homepage_hero_baner" />
              </MDBRow>
            </MDBCol>
          </MDBRow>
        ) : null} */}
        {/* <HomepageBanner /> */}
        {/* <Explore /> */}
        {/* <WhatsNew /> */}
        {/* <Showroom /> */}
        {/* <Vdc /> */}
        {/* <ExploreIndustry /> */}
        {/* <Collection /> */}
        <HomeContainerV2 />
        {tag}
      </MDBContainer>
    </>
  );
};

export default Home;
