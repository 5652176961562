import React from "react";
import Skeleton from "react-loading-skeleton";

const StoreLocatorSkeleton = (props) => {
  return (
    <div>
      <Skeleton height={`20rem`} width={`100%`} />
    </div>
  );
};

export default StoreLocatorSkeleton;
