import { getPaths, PathsConfig } from "app/utils/utils";
import { kebabCase } from "lodash";
import { favoritesName } from "../collections/config";
import { AddressCategory } from "./account/pages/Addresses/Form/config";

const addressesChildren: Record<AddressCategory, PathsConfig> = {
  mailing: { label: "Mailing", path: "mailing", children: {} },
  shipping: { label: "Shipping", path: "shipping", children: {} },
  new: { label: "New", path: "new", children: {} },
};

const customer = {
  label: "Customer",
  path: "customer",
  children: {
    register: {
      label: "Register",
      path: "register",
      children: {
        thankYou: {
          label: "Thank You",
          path: "thank-you",
          children: {},
        },
      },
    },
    account: {
      label: "Account Dashboard",
      path: "account",
      children: {
        info: {
          label: "Account Info",
          path: "info",
          children: {},
        },
        addresses: {
          label: "Address Book",
          path: "addresses",
          children: addressesChildren,
        },
        newsletter: {
          label: "Newsletter Subscription",
          path: "newsletter",
          children: {},
        },
        orders: {
          label: "Quote Request History",
          path: "request-quote-history",
          children: {},
        },
        quote: {
          label: "Quote Request",
          path: "quote",
          children: {},
        },
        // claims: {
        //   label: "File a claim",
        //   path: "claims",
        //   children: {},
        // },
        projects: {
          label: "Lists",
          path: "lists",
          children: {},
        },
      },
    },
    collections: {
      label: "Collections",
      path: "collections",
      children: {
        wishlists: {
          label: "Lists",
          path: "lists",
          children: {},
        },
      },
    },
  },
} as const;

export const customerPaths = getPaths("")(customer);
export type CustomerPaths = typeof customerPaths;

export type CollectionChildren = CustomerPaths["children"]["collections"]["children"];
export const collectionChildren: CollectionChildren =
  customerPaths.children.collections.children;
