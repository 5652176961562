import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";
import uuid from "react-uuid";

const Breadcrumbs = (props) => {
  let links = props.links;

  if (typeof props.links === "undefined") links = props.backupLinks;

  if (props.sku) links[links.length - 1].title += ` ${props.sku}`;

  if (props?.pathnameIncludes("live-edge")) {
    links[links.length - 1].title = links[links.length - 1]?.title?.split(
      " "
    )?.[0];
  }
  return (
    <div className={`breadcrumbs ${props.wrapperClass}`.trim()}>
      <MDBBreadcrumb className={props.className}>
        {links
          ? links.map((link) => {
              return (
                <MDBBreadcrumbItem
                  key={uuid()}
                  className={`${props.itemClass}`.trim()}
                >
                  <Link to={link.url} className={`${props.linkClass}`.trim()}>
                    {link.title}
                  </Link>
                </MDBBreadcrumbItem>
              );
            })
          : ""}
      </MDBBreadcrumb>
    </div>
  );
};

export default Breadcrumbs;
