// @ts-check

import CmsBlock from "app/layout/cmsBlock";
import React from "react";

/**
 * @typedef {object} ThankYouProps
 */
/**
 * @type {React.FC<ThankYouProps>}
 */
const ThankYou = (props) => {
  return <CmsBlock id="register-thank-you" />;
};

export default ThankYou;
