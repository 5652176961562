import React from "react";
import { connect } from "react-redux";
import { filterActions } from "core/state/redux/data/filters";
import FilterOption from "./filterOptions";
import { useMediaQuery } from "react-responsive";
import FilterOptionsMobile from "./filterOptionsMobile";

const FilterOptionReduxContainer = (props) => {
  const isMobile = useMediaQuery({ maxWidth: "600px" });
  if (isMobile) return <FilterOptionsMobile {...props} />;
  return <FilterOption {...props} />;
};

const mapStateToProps = (state) => ({
  filters: state.filters,
  filtered_category: state.filters.data,
  locked: state.common.locked,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addFilter: (filter) => {
      dispatch(filterActions.addFilter(filter));
    },
    removeFilter: (filter) => {
      dispatch(filterActions.removeFilter(filter));
    },
    clearFilters: () => {
      dispatch(filterActions.clearFilters());
    },
    resetPageNumber: () => {
      dispatch(filterActions.changePageNumber(1));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterOptionReduxContainer);
