import gql from "graphql-tag";

export const PRODUCT_LIVE_EDGE = gql`
  query products($filter: ProductAttributeFilterInput!, $search: String) {
    products(filter: $filter, search: $search, pageSize: 10, currentPage: 1) {
      items {
        id
        uid
        name
        sku
        height: ffi_frmdimh
        width: ffi_frmdimw
        depth: ffi_frmdimd
        frame_highest_depth: ffi_frmdepthgh
        frame_lowest_depth: ffi_frmdeptlow
      }
    }
  }
`;

export const CUSTOMER_PRODUCT_LIVE_EDGE = gql`
  query products($filter: ProductAttributeFilterInput!, $search: String) {
    customer {
      email
    }
    products(filter: $filter, search: $search) {
      items {
        id
        uid
        name
        sku
        height: ffi_frmdimh
        width: ffi_frmdimw
        depth: ffi_frmdimd
        frame_highest_depth: ffi_frmdepthgh
        frame_lowest_depth: ffi_frmdeptlow
        price_range {
          minimum_price {
            final_price {
              value
              currency
            }
          }
        }
      }
    }
  }
`;
