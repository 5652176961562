import React from "react";
import { MDBRow, MDBCol, MDBLink, MDBBox, MDBTypography } from "mdbreact";

const FeaturedCollections = ({ subItems, setActiveTopMenu }) => {
  return (
    <>
      <MDBRow>
        <MDBCol>
          <MDBBox>
            <MDBTypography
              tag="h2"
              variant="display-4"
              className="text-uppercase text-default mb-3"
            >
              Featured Collections
            </MDBTypography>
          </MDBBox>
        </MDBCol>
      </MDBRow>
      {subItems
        .filter((item) => item.include_in_menu)
        .map((item, index) => {
          return (
            <MDBRow className={`flex-column`} key={index}>
              {item.children.map((innerItem, index) => {
                return (
                  (innerItem.isFeatured === 1 && (
                    <MDBCol size={`12`}>
                      <MDBLink
                        className={`pr-0 pl-0`}
                        to={`/${innerItem.url_path}`}
                        onClick={() => {
                          setActiveTopMenu(false);
                        }}
                        key={index}
                      >
                        <MDBTypography tag="h2" variant="display-4">
                          {innerItem.name}
                        </MDBTypography>
                      </MDBLink>
                    </MDBCol>
                  )) || <></>
                );
              })}
            </MDBRow>
          );
        })}
      <MDBRow>
        <MDBCol>
          <MDBLink
            className={`link-2 pl-0 pr-0`}
            to="/collections"
            onClick={() => {
              setActiveTopMenu(false);
            }}
          >
            View All
          </MDBLink>
        </MDBCol>
      </MDBRow>
    </>
  );
};
export default FeaturedCollections;
