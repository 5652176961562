import Icon from "app/assets/icon/icon";
import Checkbox from "app/layout/Checkbox";
import Select from "app/layout/Select";
import themeConfiguration from "config/themeConfiguration";
import Button from "core/components/button";
import InputField from "core/components/inputField";
import { selectIsLoggedIn } from "app/state/redux/data/customer/selectors";
import { useSelector } from "react-redux";
// import useCustomer from "core/state/hooks/customerHooks/useCustomer";

import {
  MDBBox,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React, { useState } from "react";
import { shouldMarkError, validate } from "./validation";

const DownloadTearsheet = ({
  productId,
  productName,
  customizationOptionsQueryParameters,
  price,
}) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const pricingOptions = [
    { label: "1.5", value: "1.5" },
    { label: "2", value: "2" },
    { label: "2.5", value: "2.5" },
    { label: "3", value: "3" },
  ];
  const [pricingOptionState, setPricingOptionState] = useState(
    pricingOptions[0]
  );

  // const [getCustomer] = useCustomer();
  // let customer = getCustomer();
  const [toggleModal, setToggleModal] = useState(true);
  const [description, setDescription] = useState("");
  const [selectedOption, setSelectedOption] = useState("display-my-cost");
  // const [markupPrice, setMarkupPrice] = useState("Enter Value");
  const [newPrice, setNewPrice] = useState("Enter Value");
  const [touched, setTouched] = useState({
    description: false,
  });
  let errors = validate(description);
  const toggle = () => {
    setToggleModal(!toggleModal);
  };
  const setDescriptionFn = (e) => {
    setDescription(e.target.value);
  };
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };
  const validateDownload = (e) => {
    let isTrue = true;
    setTouched({
      description: true,
    });
    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
      return item;
    });
    if (isTrue) {
      generateDownloadLink(e);
    }
  };

  const handleClose = () => {
    setDescription("");
    setSelectedOption("");
    setToggleModal(false);
  };
  // @ts-ignore
  let customerToken = useSelector((state) => state?.customer?.data?.token);

  const generateDownloadLink = () => {
    let downloadLink =
      themeConfiguration.magento_url +
      `productpdf/download/file/1?id=${productId}&customer_token=${customerToken}&name=${productName}&price_select=hidde&mark_up_value=&custom_price=`;
    if (selectedOption === "no-pricing")
      // price_select=hidde
      downloadLink =
        themeConfiguration.magento_url +
        `productpdf/download/file/1?id=${productId}&customer_token=${customerToken}&name=${productName}&price_select=hidde&mark_up_value=&custom_price=`;
    if (selectedOption === "display-my-cost")
      // price_select=wholesale
      downloadLink =
        themeConfiguration.magento_url +
        `productpdf/download/file/1?id=${productId}&customer_token=${customerToken}&name=${productName}&price_select=wholesale&fe_price=${price}&mark_up_value=&custom_price=`;
    if (selectedOption === "markup-up-my-cost")
      // price_select=mark_up&mark_up_value=12
      downloadLink =
        themeConfiguration.magento_url +
        `productpdf/download/file/1?id=${productId}&customer_token=${customerToken}&name=${productName}&fe_price=${price}&price_select=mark_up&mark_up_value=${pricingOptionState.value}=&custom_price=`;
    if (selectedOption === "new-price")
      // price_select=custom&custom_price=123
      downloadLink =
        themeConfiguration.magento_url +
        `productpdf/download/file/1?id=${productId}&customer_token=${customerToken}&name=${productName}&fe_price=${price}&price_select=custom&custom_price=${newPrice}`;
    if (description !== "") {
      downloadLink = downloadLink + `&notes=${description}`;
    }
    downloadLink = downloadLink + `&${customizationOptionsQueryParameters}`;
    window.open(downloadLink, "_blank");
  };

  return (
    <>
      <MDBContainer id={`download-tearsheet`}>
        <MDBModal
          className="modal-wrapper"
          isOpen={toggleModal}
          centered
          toggle={(e) => {
            toggle();
          }}
        >
          <MDBModalHeader className="d-flex bg-default mx-0 p-4 justify-content-center align-items-center position-relative">
            <MDBBox
              className="ml-4 pl-2 position-absolute modal-title-pdf"
              onClick={() => toggle()}
              style={{ left: 0 }}
            >
              <Icon icon="closeWhite" />
            </MDBBox>
            <MDBRow style={{ position: "static" }}>
              <MDBBox>
                <MDBTypography
                  tag="h2"
                  variant="display-2"
                  className="text-white f font-weight-regular text-center"
                >
                  Download Tearsheet
                </MDBTypography>
              </MDBBox>
            </MDBRow>
          </MDBModalHeader>
          <MDBModalBody>
            {isLoggedIn ? (
              <MDBContainer fluid>
                <MDBRow>
                  <MDBCol md="12" className="modal-inner-content">
                    <MDBBox tag="h1" className="text-center my-lg-5 my-3">
                      Select an Option Below
                    </MDBBox>

                    <MDBRow className="modal-check-buttons">
                      <MDBCol
                        md="12"
                        className="check-content checkbox-wrapper"
                      >
                        <Checkbox
                          containerClass={"checkbox-container check-button"}
                          label={"Show Pricing"}
                          type={"checkbox"}
                          value={"no"}
                          checked={selectedOption === "display-my-cost"}
                          onChange={(e) =>
                            handleOptionChange("display-my-cost")
                          }
                          wrapperClass={"checkmark gray"}
                        />
                      </MDBCol>
                      {/* {isLoggedIn ? <div></div> : ""} */}
                      <MDBCol
                        md="12"
                        className="checkbox-content checkbox-wrapper"
                      >
                        <Checkbox
                          containerClass={"checkbox-container check-button"}
                          label={"Hide Pricing"}
                          type={"checkbox"}
                          value={"no"}
                          checked={selectedOption === "no-pricing"}
                          onChange={(e) => handleOptionChange("no-pricing")}
                          wrapperClass={"checkmark gray"}
                        />
                      </MDBCol>
                      <MDBCol
                        md="12"
                        className="check-with-input first checkbox-wrapper"
                      >
                        <MDBRow className={`align-items-center`}>
                          <MDBCol size="4">
                            <Checkbox
                              containerClass={"checkbox-container check-button"}
                              label={`Markup Pricing`}
                              type={"checkbox"}
                              value={"no"}
                              checked={selectedOption === "markup-up-my-cost"}
                              onChange={(e) =>
                                handleOptionChange("markup-up-my-cost")
                              }
                              wrapperClass={"checkmark gray"}
                            />
                          </MDBCol>
                          <MDBCol className="col-3 pl-0">
                            <Select
                              disabled={true}
                              placeholder="Select option..."
                              value={pricingOptionState}
                              options={pricingOptions}
                              onChange={setPricingOptionState}
                              wrapperClass={`m-0 ${
                                selectedOption !== "markup-up-my-cost"
                                  ? "disabled"
                                  : "not-disabled"
                              }`}
                            />
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol
                        md="12"
                        className="radio-with-input second checkbox-wrapper"
                      >
                        <MDBRow className={`align-items-center`}>
                          <MDBCol size="4">
                            <Checkbox
                              containerClass={"checkbox-container check-button"}
                              label={"Custom Pricing"}
                              type={"checkbox"}
                              value={"no"}
                              checked={selectedOption === "new-price"}
                              onChange={(e) => handleOptionChange("new-price")}
                            />
                          </MDBCol>

                          <MDBCol size="5" className="p-0">
                            <MDBInput
                              type="number"
                              validate
                              error="wrong"
                              success="right"
                              hint="Enter Value"
                              value={newPrice !== undefined ? newPrice : ""}
                              onChange={(e) => setNewPrice(e.target.value)}
                              className={`w-100 m-0 text-align-left ${
                                selectedOption !== "new-price"
                                  ? "disabled"
                                  : "not-disabled"
                              }`}
                            />
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="modal-textarea">
                      <MDBCol md="12">
                        <InputField
                          className={`custom-input ${
                            shouldMarkError("description", errors, touched)
                              ? "validation-error"
                              : ""
                          }`}
                          group
                          type="textarea"
                          validate
                          error="wrong"
                          success="right"
                          hint="Notes"
                          labelClass="text-area-label modal-text-label"
                          value={description !== undefined ? description : ""}
                          onChange={setDescriptionFn}
                          maxlength="200"
                        />
                      </MDBCol>
                      {/* <MDBCol md="12">
                             <MDBBox
                             className={`text-uppercase textarea-char-num ${
                               shouldMarkError("description", errors, touched)
                               ? "validation-error"
                               : ""
                             }`}
                             >
                             Max length 200 characters
                             </MDBBox>
                           </MDBCol> */}
                    </MDBRow>
                    <MDBRow className="modal-buttons">
                      <MDBCol md="6">
                        <Button
                          className={"btn-with-icon w-100"}
                          direction={"mr-2"}
                          leftIcon={true}
                          onClick={(e) => {
                            validateDownload(e);
                            handleClose();
                          }}
                          text={<span>download</span>}
                        />
                      </MDBCol>
                      <MDBCol md="6">
                        <Button
                          className={"btn-with-icon w-100 btn-secondary mb-5"}
                          leftIcon={true}
                          direction={"mr-2"}
                          onClick={() => toggle()}
                          text={<span>cancel</span>}
                        />
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            ) : (
              <MDBContainer fluid>
                <MDBRow>
                  <MDBCol md="12" className="modal-inner-content">
                    <MDBBox tag="h1" className="text-center my-lg-5 my-3">
                      Select an Option Below
                    </MDBBox>

                    <MDBRow className="modal-check-buttons">
                      <MDBCol
                        md="12"
                        className="checkbox-content checkbox-wrapper"
                      >
                        <Checkbox
                          containerClass={"checkbox-container check-button"}
                          label={"Hide Pricing"}
                          type={"checkbox"}
                          value={"no"}
                          checked={selectedOption === "no-pricing"}
                          onChange={(e) => handleOptionChange("no-pricing")}
                          wrapperClass={"checkmark gray"}
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow className="modal-buttons">
                      <MDBCol md="6">
                        <Button
                          className={"btn-with-icon w-100"}
                          direction={"mr-2"}
                          leftIcon={true}
                          onClick={(e) => {
                            validateDownload(e);
                          }}
                          text={<span>download</span>}
                        />
                      </MDBCol>
                      <MDBCol md="6">
                        <Button
                          className={"btn-with-icon w-100 btn-secondary mb-5"}
                          leftIcon={true}
                          direction={"mr-2"}
                          onClick={() => toggle()}
                          text={<span>cancel</span>}
                        />
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            )}
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    </>
  );
};

export default DownloadTearsheet;
