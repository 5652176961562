import Image from "app/layout/image";
import { MDBBox, MDBCol, MDBLink, MDBRow, MDBTypography } from "mdbreact";
import React from "react";
import Config from "config/themeConfiguration";
import CollectionLandingPlaceholder from "app/assets/images/collectionLandingPlaceholder.png";
import themeConfiguration from "config/themeConfiguration";
import Icon from "app/assets/icon/icon";

const CollectionListingItem = ({
  item,
  data,
  productsInCollection,
  currentPage,
  setCurrentPage,
  handleSetPageSize,
  pageSizeOptions,
  pageSize,
  setPageSize,
  totalPages,
  totalCount,
  isGridView,
  ...props
}) => {
  let magentoUrl = Config.magento_url;
  let mediaUrl = Config.media_url;
  let categoryUrl = Config.category_url;
  let heroImage = item.hero_image;
  let image = item.image;

  let heroImageUrl =
    image !== null
      ? image
      : heroImage === null || heroImage !== ""
      ? CollectionLandingPlaceholder
      : magentoUrl + mediaUrl + categoryUrl + "/" + heroImage;

  const specialCharacterName = item.name.replace(/&ntilde;/g, "ñ");

  return (
    <MDBRow className={` py-1 py-xl-4 ${isGridView ? "mb-auto my-3" : "my-5"}`}>
      <MDBCol
        size="12"
        lg={isGridView ? `12` : `7`}
        className="first-column my-5 my-lg-0 px-3"
      >
        <MDBLink to={`/${item.url_path}`} className="relative collection-image-wrap">
          <Image source={heroImageUrl} className="img-collection w-100" />
          {isGridView && (
            <div className="grid-btn-wrap">
            <MDBLink
              to={`/${
                //This code was added because the client wanted to redirect Haven Reserve, Urban Living and Senior Living Solutions collections (belong to Upholstery or Casegoods) to build your own collections with the same name
                item.url_path
              }`}
              className={`btn btn-primary btn-default ${
                isGridView ? "grid-view-button" : ""
              }`}
            >
              View Collection
            </MDBLink>
            </div>
          )}
        </MDBLink>
      </MDBCol>
      <MDBCol
        size="12"
        lg={isGridView ? `12` : `5`}
        className="text-center px-3  second-column"
      >
        <MDBBox
          className={`outer-wrapper h-100 p-2 ${
            !isGridView ? "p-xl-5" : " d-flex flex-column align-items-center"
          }`}
        >
          <MDBTypography
            className={`text-center  mb-4 ${
              !isGridView ? "title mt-5 pt-md-5" : "mt-4"
            }`}
            variant="display-3"
          >
            {specialCharacterName ? specialCharacterName : item.name}
          </MDBTypography>
          {/* {!isGridView && (
            <MDBTypography tag="span" className="body-2 description my-4">
              {item.description}
            </MDBTypography>
          )} */}
          {!isGridView &&
            ((item.isCustom && (
              <MDBLink
                to={`/${item.url_path}`}
                className={`btn my-5 btn-primary btn-default`}
              >
                Customize
              </MDBLink>
            )) || (
              <MDBLink
                to={`/${
                  //This code was added because the client wanted to redirect Haven Reserve, Urban Living and Senior Living Solutions collections (belong to Upholstery or Casegoods) to build your own collections with the same name
                  item.url_path
                }`}
                className={`btn my-5 btn-primary btn-default ${
                  isGridView ? "mt-auto" : ""
                }`}
              >
                View Collection
              </MDBLink>
            ))}
          {isGridView && item?.catalog_pdf ? (
            <MDBLink
              className="collection-tearsheet-link"
              onClick={() => {
                window.open(
                  themeConfiguration.magento_url +
                    themeConfiguration.media_url +
                    themeConfiguration.category_url +
                    "/" +
                    item?.catalog_pdf,
                  "_blank"
                );
              }}
            >
              <Icon icon={"docIcon"}></Icon>
              Download Tearsheet
            </MDBLink>
          ) : (
            <div></div>
          )}
        </MDBBox>
      </MDBCol>
    </MDBRow>
  );
};

export default CollectionListingItem;
