import apolloClient from "core/graphql/apolloClient";
import customerActions from "../../actions";
import commonActions from "../../../common/actions";
import messagesActions from "../../../messages/actions";
import queryActions from "../../../queries/actions";
import { gql } from "graphql.macro";

const APPLY_COUPON_TO_CART_MUTATION = gql`
  mutation ApplyCouponToCart($input: ApplyCouponToCartInput) {
    applyCouponToCart(input: $input) {
      cart {
        id
        prices {
          subtotal_including_tax {
            currency
            value
          }
          subtotal_excluding_tax {
            currency
            value
          }
          grand_total {
            currency
            value
          }
          applied_taxes {
            amount {
              currency
              value
            }
            label
          }
          discounts {
            amount {
              value
              currency
            }
            label
          }
        }
        applied_coupons {
          code
        }
      }
    }
  }
`;
export default async (store, action) => {
  try {
    let cart_id = store.getState().customer.data.cartToken;
    let input = { cart_id: cart_id, coupon_code: action.coupon };

    const { data } = await apolloClient.mutate({
      mutation: APPLY_COUPON_TO_CART_MUTATION,
      variables: { input: input },
      fetchPolicy: "no-cache",
    });
    if (data.applyCouponToCart) {
      let newCart = {
        ...store.getState().customer.data.cart,
        ...data.applyCouponToCart.cart,
      };
      store.dispatch(customerActions.setCartInformation(newCart));
      store.dispatch(
        messagesActions.addMessage("updated cart information", "success")
      );
      store.dispatch(commonActions.unlock("success"));
    }
  } catch (error) {
    if (error.message.includes("Could not find a cart with ID")) {
      store.dispatch(customerActions.createEmptyCart());
    } else {
      store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    }
    store.dispatch(commonActions.unlock("fail"));
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
