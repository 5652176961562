import { MDBCol, MDBContainer, MDBRow, Button } from "mdbreact";
import React, { useMemo, useState } from "react";
import HeroBanner from "../../heroBanner";
import CollectionListingItem from "./collectionListingItem";
import FabricFinishesPlp from "./FabricFinishesPlp";

const CollectionsListing = ({
  category,
  heroBannerProps,
  queryProps,
  listingProps,
  data,
  productsInCollection,
  currentPage,
  setCurrentPage,
  handleSetPageSize,
  pageSizeOptions,
  pageSize,
  totalPages,
  totalCount,
  ...props
}) => {
  const heroBannerExtendedProps = {
    ...heroBannerProps,
    categoryInfo: category,
    showPDFNotCustom: true,
  };
  const isGridView = !!category?.isGrid;
  return (
    <MDBContainer fluid className="collections-container">
      <MDBRow className="img-full">
        <HeroBanner {...heroBannerExtendedProps} className="mx-auto" />
      </MDBRow>
      {/* <MDBRow>
        <MDBCol>
          <MDBContainer> */}
      {/* {isCollectionPage && (
        <MDBContainer className="plp-collection-filters">
          <Select
            placeholder="Select Category"
            options={collectionOptions}
            onChange={(e) => {
              setSelectedCategory(e);
            }}
            value={selectedCategory}
          />
          <InputField
            className={`custom-input mt-5 w-100`}
            placeholder={`Search Collections`}
            type="text"
            value={searchVal}
            onChange={(e) => {
              setSearchVal(e?.target?.value);
            }}
          />
          <Button onClick={() => {}}>Search</Button>
        </MDBContainer>
      )} */}

      {window.location.pathname.startsWith(`/fabrics-finishes/finishes`) ||
      window.location.pathname.startsWith(`/fabrics-finishes/fabric`) ? (
        <FabricFinishesPlp
          categories={category?.children}
          mainCategory={category}
        />
      ) : (
        <MDBContainer className={isGridView ? `collection-items` : ``}>
          {category?.children?.map((item) => (
            <CollectionListingItem
              key={item.url_path}
              item={item}
              heroBannerProps={heroBannerProps}
              productsInCollection={productsInCollection}
              {...props}
              data={data}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              totalCount={totalCount}
              setPageSize={handleSetPageSize}
              pageSizeOptions={pageSizeOptions}
              pageSize={pageSize}
              isGridView={isGridView}
            />
          ))}
        </MDBContainer>
      )}
      {/* <MDBContainer className={isGridView ? `collection-items` : ``}>
        {category?.children?.map((item) => (
          <CollectionListingItem
            key={item.url_path}
            item={item}
            heroBannerProps={heroBannerProps}
            productsInCollection={productsInCollection}
            {...props}
            data={data}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            totalCount={totalCount}
            setPageSize={handleSetPageSize}
            pageSizeOptions={pageSizeOptions}
            pageSize={pageSize}
            isGridView={isGridView}
          />
        ))}
      </MDBContainer> */}
      {/* </MDBContainer>
        </MDBCol>
      </MDBRow> */}
    </MDBContainer>
  );
};

export default CollectionsListing;
