// @ts-check

import { useQuery } from "@apollo/react-hooks";
import _, { iteratee } from "lodash";
import React from "react";
import ProductDataContainer from "./data-container";
import {
  CUSTOMER_PRODUCT,
  CUSTOMER_PRODUCT_ADD_INFO,
  PRODUCT,
  PRODUCT_ADD_INFO,
} from "./queries";
import { FABRICS_WITHOUT_AGGREGATIONS } from "./queriesFabric";
import SkeletonProduct from "./skeleton-product";

/**
 *
 * @type {React.FC<*>}
 */
const ProductQueryContainer = (props) => {
  let { customer, productSku } = props;

  const FINAL_QUERY = customer ? CUSTOMER_PRODUCT : PRODUCT;
  let { data, loading } = useQuery(FINAL_QUERY, {
    variables: { search: productSku },
    fetchPolicy: "cache-and-network",
  });

  const FINAL_PRODUCT_ADD_INFO_QUERY = customer
    ? CUSTOMER_PRODUCT_ADD_INFO
    : PRODUCT_ADD_INFO;

  const product_add_info = useQuery(FINAL_PRODUCT_ADD_INFO_QUERY, {
    variables: { search: productSku },
    fetchPolicy: "cache-and-network",
  });

  _.merge(data, product_add_info.data);

  let defaultFabricSku = data?.products?.items[0]?.default_fabric || "null";

  let productImageUrl = data?.products?.items[0]?.image?.url;
  let MainFabricFilter = {
    filters: { sku: { eq: defaultFabricSku } },
    pageSize: 1,
    currentPage: 1,
  };

  const mainFabricRes = useQuery(FABRICS_WITHOUT_AGGREGATIONS, {
    variables: MainFabricFilter,
    fetchPolicy: "cache-and-network",
  });

  const mainFabricData = React.useMemo(() => mainFabricRes?.data || {}, [
    mainFabricRes.data,
  ]);
  const defaultFabric = mainFabricData?.products?.items[0] || undefined;

  let defaults = React.useMemo(
    () => ({
      defaultFabric,
      standard_seat: data?.products?.items[0]?.standard_seat || undefined,
    }),
    [data, defaultFabric]
  );
  if (loading) {
    return <SkeletonProduct />;
  }

  return (
    <>
      <ProductDataContainer
        {...props}
        data={data}
        defaults={defaults}
        productImageUrl={productImageUrl}
        fabricOrFinishesSelectedOptions={props.fabricOrFinishSelectedOptions}
        setFabricOrFinishSelectedOptions={
          props.setFabricOrFinishSelectedOptions
        }
      />
    </>
  );
};

export default ProductQueryContainer;
