import apolloClient from "core/graphql/apolloClient";
import customerActions from "../actions";
import commonActions from "../../common/actions";
import messagesActions from "../../messages/actions";
import { gql } from "graphql.macro";

const ADD_ITEM_TO_WISHLIST = gql`
  mutation AddWishlistItem(
    $wishlist_id: Int
    $product_id: Int
    $qty: Int
    $options: [ProductOptions]
  ) {
    addWishlistItem(
      input: {
        product_id: $product_id
        wishlist_id: $wishlist_id
        qty: $qty
        options: $options
      }
    ) {
      id
      multi_wishlist_id
      wishlist_item_id
      product {
        id
        name
        sku
        small_image {
          url
        }
        image {
          url
        }
        url_key
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
          }
        }
        ... on CustomizableProductInterface {
          options {
            title
            required
            sort_order
            option_id
            ... on CustomizableDropDownOption {
              option_id
              title
              value {
                title
                option_type_id
                price
                price_type
                sku
              }
            }
          }
        }
      }
      qty
    }
  }
`;
export default async (store, action) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: ADD_ITEM_TO_WISHLIST,
      variables: { ...action, timestamp: Date.now() },
    });

    let wishlists = { ...store.getState().customer.data.wishlist };
    if (data) {
      wishlists.items.push(data.addWishlistItem);
      if (action.wishlist_id !== parseInt(wishlists.id)) {
        let filtered = wishlists.multiwishlist_data.findIndex((x) => {
          return x.id === parseInt(action.wishlist_id);
        });
        wishlists.multiwishlist_data[filtered].items.push(
          data.addWishlistItem.id
        );
      }
      store.dispatch(customerActions._reduceWishlist(wishlists));
      store.dispatch(
        messagesActions.addMessage(
          `successfully added product to project`,
          "success",
          "wishlist-popup"
        )
      );
    }
    store.dispatch(commonActions.unlock("success"));
  } catch (error) {
    store.dispatch(
      messagesActions.addMessage(error.graphQLErrors[0].debugMessage, "danger")
    );
    store.dispatch(commonActions.unlock("fail"));
  }
};
