// @ts-check

import Label from "app/layout/Label";
import MaskedInput from "app/layout/MaskedInput";
import Select from "app/layout/Select";
import { phoneMask, usAndCanZipCodeMask } from "app/pages/customer/config";
import sortCountries from "app/state/hooks/sortCountries";
import clsx from "clsx";
import { MDBBox, MDBCol, MDBInput, MDBRow } from "mdbreact";
import React from "react";

/**
 *
 * @typedef {object} FormProps
 * @prop {import('./config').MailingAndShippingFormKey} formKey
 * @prop {import('formik').FormikProps<import('./config').MailingAndShippingValues>} form
 * @prop {import('../config').CountryOption[]} [countries]
 * @prop {boolean} [disabled]
 * @prop {string} [className]
 * @prop {any} [stepTwoData]
 */
/**
 * @type {React.FC<FormProps>}
 */
const Form = ({
  formKey: key,
  form,
  countries = [],
  disabled = false,
  className,
  stepTwoData,
}) => {
  const regionEnabled = !!form.values?.[key]?.country?.regions;

  const regionOption = React.useMemo(
    () =>
      form.values?.[key]?.country?.regions?.find(
        ({ value }) => value === form.values?.[key]?.region
      ),
    [form.values, key]
  );

  const [countriesSorted, setNewCountries] = React.useState(countries);

  React.useEffect(() => {
    form.initialValues.mailing.contactName = `${form.initialValues.mailing.firstName.trim()} ${form.initialValues.mailing.lastName.trim()}`;
  }, [form.initialValues.mailing]);

  React.useEffect(() => {
    setNewCountries(sortCountries(countries));
  }, [countries]);

  return (
    <MDBRow className={className}>
      <MDBCol>
        <MDBRow>
          <MDBCol className="mb-35">
            <MDBInput
              disabled={disabled}
              containerClass="w-100"
              {...form.getFieldProps(`${key}.firstName`)}
              required
              onBlur={(e) => {
                // @ts-ignore
                form.initialValues.mailing.firstName = e.target.value;
              }}
              label="First Name"
              hint="ex: John"
              className={clsx("min-width-xv", {
                error:
                  // @ts-ignore
                  form.touched?.[key]?.firstName &&
                  // @ts-ignore
                  form.errors?.[key]?.firstName,
              })}
            />
            <div className="text-red position-absolute">
              {!disabled &&
                // @ts-ignore
                form.touched?.[key]?.firstName &&
                // @ts-ignore
                form.errors?.[key]?.firstName}
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="mb-4">
            <MDBInput
              disabled={disabled}
              containerClass="w-100"
              {...form.getFieldProps(`${key}.lastName`)}
              required
              label="Last Name"
              hint="ex: Smith"
              onBlur={(e) => {
                // @ts-ignore
                form.initialValues.mailing.lastName = e.target.value;
              }}
              className={clsx("min-width-xv", {
                error:
                  // @ts-ignore
                  form.touched?.[key]?.lastName &&
                  // @ts-ignore
                  form.errors?.[key]?.lastName,
              })}
            />
            <div className="text-red position-absolute">
              {!disabled &&
                // @ts-ignore
                form.touched?.[key]?.lastName &&
                // @ts-ignore
                form.errors?.[key]?.lastName}
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="mb-50">
            <Label disabled={disabled} required text="Country" className="mb-0">
              <MDBBox className="mt-3">
                <Select
                  isDisabled={disabled || countriesSorted.length === 0}
                  placeholder="Select Country"
                  value={form.values?.[key]?.country}
                  options={countriesSorted}
                  // @ts-ignore
                  onChange={(option) => {
                    // @ts-ignore
                    form.initialValues.mailing.country = option;
                    form.setFieldValue(`${key}.country`, option);
                    form.setFieldValue(`${key}.region`, null);
                  }}
                  onBlur={() => {
                    form.setFieldTouched("country");
                  }}
                  className={clsx("min-width-xv", {
                    error:
                      // @ts-ignore
                      form.touched?.[key]?.country &&
                      // @ts-ignore
                      form.errors?.[key]?.country,
                  })}
                />
              </MDBBox>
            </Label>

            <div className="text-red position-absolute">
              {!disabled &&
                // @ts-ignore
                form.touched?.[key]?.country &&
                // @ts-ignore
                typeof form.errors?.[key]?.country === "string" &&
                // @ts-ignore
                form.errors?.[key]?.country}
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="mb-35">
            <MDBInput
              disabled={disabled}
              containerClass="w-100"
              {...form.getFieldProps(`${key}.address1`)}
              required
              label="Address 1"
              onBlur={(e) => {
                // @ts-ignore
                form.initialValues.mailing.address1 = e.target.value;
              }}
              hint="123 Street Name"
              className={clsx("min-width-xv", {
                error:
                  // @ts-ignore
                  form.touched?.[key]?.address1 && form.errors?.[key]?.address1,
              })}
            />
            <div className="text-red position-absolute">
              {!disabled &&
                // @ts-ignore
                form.touched?.[key]?.address1 &&
                // @ts-ignore
                form.errors?.[key]?.address1}
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="mb-35">
            <MDBInput
              disabled={disabled}
              containerClass="w-100"
              {...form.getFieldProps(`${key}.address2`)}
              label="Address 2"
              onBlur={(e) => {
                // @ts-ignore
                form.initialValues.mailing.address2 = e.target.value;
              }}
              hint="apartment or suite no."
              className={clsx("min-width-xv", {
                error:
                  // @ts-ignore
                  form.touched?.[key]?.address2 && form.errors?.[key]?.address2,
              })}
            />
            <div className="text-red position-absolute">
              {!disabled &&
                // @ts-ignore
                form.touched?.[key]?.address2 &&
                // @ts-ignore
                form.errors?.[key]?.address2}
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="mb-4">
            <MDBInput
              disabled={disabled}
              containerClass="w-100"
              {...form.getFieldProps(`${key}.city`)}
              required
              label="City"
              hint="city name"
              onBlur={(e) => {
                // @ts-ignore
                form.initialValues.mailing.city = e.target.value;
              }}
              className={clsx("min-width-xv", {
                // @ts-ignore
                error: form.touched?.[key]?.city && form.errors?.[key]?.city,
              })}
            />
            <div className="text-red position-absolute">
              {!disabled &&
                // @ts-ignore
                form.touched?.[key]?.city &&
                // @ts-ignore
                form.errors?.[key]?.city}
            </div>
          </MDBCol>
        </MDBRow>
        {regionEnabled && (
          <MDBRow>
            <MDBCol
              className="mb-5"
              title={
                !regionEnabled
                  ? form.values?.[key]?.country
                    ? // @ts-ignore
                      `${form.values?.[key]?.country.label} does not have state options.`
                    : "First select a country."
                  : ""
              }
            >
              <Label
                disabled={disabled}
                required={regionEnabled}
                text="State"
                className="mb-0"
              >
                <MDBBox className="mt-3">
                  <Select
                    isDisabled={disabled || !regionEnabled}
                    placeholder="Select State"
                    value={regionOption}
                    options={form.values?.[key]?.country?.regions}
                    // @ts-ignore
                    onChange={(option) => {
                      form.initialValues.mailing.region = option.value;
                      form.setFieldValue(`${key}.region`, option.value);
                    }}
                    onBlur={() => {
                      form.setFieldTouched("region");
                    }}
                    className={clsx("min-width-xv", {
                      error:
                        // @ts-ignore
                        form.touched?.[key]?.region &&
                        // @ts-ignore
                        form.errors?.[key]?.region,
                    })}
                  />
                </MDBBox>
              </Label>

              <div className="text-red position-absolute">
                {!disabled &&
                  // @ts-ignore
                  form.touched?.[key]?.region &&
                  // @ts-ignore
                  form.errors?.[key]?.region}
              </div>
            </MDBCol>
          </MDBRow>
        )}
        <MDBRow>
          <MDBCol className="mb-35">
            <MaskedInput
              {...form.getFieldProps(`${key}.zipCode`)}
              required
              label="Zip Code/Postal Code"
              placeholder="zip or postal code"
              mask={
                form.values[key]?.country?.value === "US" ||
                form.values[key]?.country?.value === "CA"
                  ? // @ts-ignore
                    usAndCanZipCodeMask(form.values[key]?.country?.value)
                  : false
              }
              onBlur={(e) => {
                // @ts-ignore
                form.initialValues.mailing.zipCode = e.target.value;
              }}
              disabled={disabled}
              className={clsx("min-width-xv", "mb-4", {
                error:
                  // @ts-ignore
                  form.touched?.[key]?.zipCode && form.errors?.[key]?.zipCode,
              })}
            />
            <div className="text-red position-absolute">
              {!disabled &&
                // @ts-ignore
                form.touched?.[key]?.zipCode &&
                // @ts-ignore
                form.errors?.[key]?.zipCode}
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="mb-4">
            <MDBInput
              disabled={disabled}
              containerClass="w-100"
              {...form.getFieldProps(`${key}.email`)}
              required
              label="Email"
              hint="email@email.com"
              onBlur={(e) => {
                // @ts-ignore
                form.initialValues.mailing.email = e.target.value;
              }}
              className={clsx("min-width-xv", {
                // @ts-ignore
                error: form.touched?.[key]?.email && form.errors?.[key]?.email,
              })}
            />
            <div className="text-red position-absolute">
              {!disabled &&
                // @ts-ignore
                form.touched?.[key]?.email &&
                // @ts-ignore
                form.errors?.[key]?.email}
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="mb-5 pb-3 mb-md-4 pb-md-2">
            <MaskedInput
              {...form.getFieldProps(`${key}.phone`)}
              label="Phone"
              placeholder="ex: xxx-xxx-xxxx"
              required
              mask={phoneMask}
              disabled={disabled}
              onBlur={(e) => {
                // @ts-ignore
                form.initialValues.mailing.phone = e.target.value;
              }}
              className={clsx("min-width-xv", "mb-3", {
                // @ts-ignore
                error: form.touched[key]?.phone && form.errors[key]?.phone,
              })}
            />
            <div className="text-red position-absolute">
              {!disabled &&
                // @ts-ignore
                form.touched?.[key]?.phone &&
                // @ts-ignore
                form.errors?.[key]?.phone}
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default Form;
