import { getPaths } from "app/utils/utils";
import { Division, FilePropToOrderBy, SortOrders, FileTypes } from "./models";

export const headers: Record<FilePropToOrderBy, string> = {
  filename: "Title name",
  size: "Size",
  divisions: "Division",
  updated_at: "Updated",
};

export const filePropsToOrderBy = Object.keys(headers) as FilePropToOrderBy[];
export const initialSortOrders = filePropsToOrderBy.reduce(
  (sortOrders, prop) => ({
    ...sortOrders,
    [prop]: null,
  }),
  {} as SortOrders
);

export const resourcesPaths = getPaths("")({
  label: "Resources",
  path: "resources",
  children: {},
} as const);

export const divisionRecord: Record<Division, string> = {
  all: "All",
  retail: "Residential",
  contract: "Commercial",
  senior: "Senior Living",
};

//@ts-ignore
export const fileTypeObject: Record<FileTypes, string> = {
  documents: "Documents",
  marketing: "Marketing",
  videos: "Videos",
};
export const fileTypes = (Object.keys(
  fileTypeObject
) as unknown) as FileTypes[];
export const divisions = Object.keys(divisionRecord) as Division[];
export type DivisionOption = { value: Division; label: string; icon: Division };
//@ts-ignore
export const divisionOptions: DivisionOption[] = Object.entries(
  divisionRecord
  //@ts-ignore
).map(([value, label, icon]) => ({
  value: value as Division,
  label,
  icon: value as Division,
}));
