import apolloClient from "core/graphql/apolloClient";
import customerActions from "../actions";
import commonActions from "../../common/actions";
import messagesActions from "../../messages/actions";
import { gql } from "graphql.macro";

const COPY_WISHLIST_ITEM_QUERY = gql`
  mutation CopyWishlistItem($wishlist_item_id: Int, $wishlist_id: Int) {
    copyWishlistItem(
      input: { wishlist_item_id: $wishlist_item_id, wishlist_id: $wishlist_id }
    ) {
      id
      multi_wishlist_id
      wishlist_item_id
      product {
        id
        name
        sku
        small_image {
          url
        }
        image {
          url
        }
        url_key
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
          }
        }
        ... on CustomizableProductInterface {
          options {
            title
            required
            sort_order
            option_id
            ... on CustomizableDropDownOption {
              option_id
              title
              value {
                title
                option_type_id
                price
                price_type
                sku
              }
            }
          }
        }
      }
      qty
    }
  }
`;
export default async (store, action) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: COPY_WISHLIST_ITEM_QUERY,
      variables: action,
    });

    let wishlists = { ...store.getState().customer.data.wishlist };
    if (data) {
      wishlists.items.push(data.copyWishlistItem);
      if (parseInt(action.wishlist_id) !== parseInt(wishlists.id)) {
        let filtered = wishlists.multiwishlist_data.findIndex(
          (x) => x.id === action.wishlist_id
        );
        wishlists.multiwishlist_data[filtered].items.push(
          data.copyWishlistItem.id
        );
      }
      store.dispatch(customerActions._reduceWishlist(wishlists));
      store.dispatch(
        messagesActions.addMessage(
          `successfully copied wishlist item`,
          "success"
        )
      );
    }
    store.dispatch(commonActions.unlock("success"));
  } catch (error) {
    store.dispatch(
      messagesActions.addMessage(error.graphQLErrors[0].debugMessage, "danger")
    );
    store.dispatch(commonActions.unlock("fail"));
  }
};
