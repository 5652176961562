import * as yup from "yup";
import { emailSchema } from "../../../config";

export interface EmailValues {
  email: string;
}

export const initialEmailValues: EmailValues = {
  email: "",
};

export const emailValidationSchema: yup.ObjectSchema<EmailValues> = yup
  .object()
  .required()
  .shape({
    email: emailSchema,
  });
