import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import queryString from "query-string";
import { finishesArray, floorArray, modelsArray, stripingArray } from "./data";
import { CustomDisplay } from "./Custom";

export const PRODUCT_PROBE = gql`
  query Product($filters: ProductAttributeFilterInput!, $search: String) {
    products(filter: $filters, search: $search) {
      items {
        id
        name
        sku
        price_range {
          minimum_price {
            final_price {
              value
            }
          }
        }
        depth: ffi_frmdimd
        height: ffi_frmdimh
        width: ffi_frmdimw
      }
    }
  }
`;

export const Monogram = () => {
  const match = useRouteMatch<{ collection: string; params: string }>({
    path: "/build-your-own/:collection/:params",
    strict: true,
    sensitive: true,
  });
  let [availableOptions, setAvailableOptions] = useState([]);

  const { search } = useLocation();
  const [values, setValues] = useState({});
  useEffect(() => {
    if (search) {
      const localSearch = JSON.parse(
        JSON.stringify(search.replace(/\&amp;/g, "&").replace(/\&quot;/g, `"`))
      );
      setValues(queryString.parse(localSearch));
    }
  }, [search]);

  useEffect(() => {
    // console.log(values);
  }, [values]);

  let queryPathOptions: any;

  // @ts-ignore
  if (values && values.build_your_own_options) {
    // @ts-ignore
    queryPathOptions = JSON.parse(values.build_your_own_options);
  }

  const initial: {
    finish: typeof finishesArray[number];
    striping: typeof stripingArray[number];
    models: typeof modelsArray[number];
    floor: typeof floorArray[number];
  } = React.useMemo(() => {
    const defaultFinish = finishesArray[0];
    const defaultStriping = stripingArray[0];
    const defaultModel = modelsArray[0];
    const defaultFloor = floorArray.filter((f) => f.default)[0];
    if (match?.params.params) {
      const modelId = match.params.params;
      return {
        models:
          modelsArray.find((model) => model.sku === modelId) ?? defaultModel,
        striping: defaultStriping,
        finish: defaultFinish,
        floor: defaultFloor,
      };
    } else {
      return {
        models: defaultModel,
        striping: defaultStriping,
        finish: defaultFinish,
        floor: defaultFloor,
      };
    }
  }, [match]);

  let [player, setPlayer] = React.useState(null);

  const canvasRef = React.useRef(null);

  let [model, setModel] = useState(initial.models);
  let [striping, setStriping] = useState(initial.striping);
  let [finish, setFinish] = useState(initial.finish);
  let [floor, setFloor] = useState(initial.floor);

  let selectionsAndSelectors = {
    model,
    setModel,
    striping,
    setStriping,
    finish,
    setFinish,
    floor,
    setFloor,
  };

  const sku = model.sku;
  let showPdp = false;
  let price = 0;

  let filter = {
    filters: { sku: { eq: sku } },
    search: sku,
    pageSize: 1,
    currentPage: 1,
  };
  const { data } = useQuery(PRODUCT_PROBE, {
    variables: filter,
    fetchPolicy: "cache-first",
  });

  let productName;
  let productDimensions = {};
  if (data) {
    showPdp = true;
    price =
      data?.products?.items[0]?.price_range?.minimum_price?.final_price?.value;
    productName = data?.products?.items[0]?.name;
    productDimensions = {
      width: data?.products?.items[0]?.width,
      height: data?.products?.items[0]?.height,
      depth: data?.products?.items[0]?.depth,
    };
  }
  const displayProps = {
    finishesArray,
    stripingArray,
    modelsArray,
    floorArray,
    selectionsAndSelectors,
    sku,
    showPdp,
    productName,
    queryPathFabrics: values,
    productDimensions,
  };
  return (
    // @ts-ignore
    <CustomDisplay
      ref={canvasRef}
      player={player}
      setPlayer={setPlayer}
      availableOptions={availableOptions}
      setAvailableOptions={setAvailableOptions}
      {...displayProps}
    />
  );
};
