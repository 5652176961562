import { FormikProps } from "formik";
import clsx from "clsx";
import { MDBBox, MDBBtn, MDBInput, MDBTypography, MDBRow } from "mdbreact";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { forgotPasswordPath } from "../../config";
import { PasswordValues } from "./config";

export interface WelcomeBackProps {
  emailAndFirstname: { email: string; firstname: string };
  form: FormikProps<PasswordValues>;
  onClose: () => void;
  windowY?: number;
  setWindowY?: any;
}

const WelcomeBack: React.FC<WelcomeBackProps> = (props) => {
  React.useEffect(() => {
    if (props.windowY === 0) return;
    window.scrollTo({ top: props.windowY });
    return () => {
      props.setWindowY(window.scrollY);
    };
  }, []);

  const passwordError =
    (props.form.touched.password || "") && props.form.errors.password;
  let customerEmail = props.emailAndFirstname.email;
  const isFromAddToFavorites = JSON.parse(
    // @ts-ignore
    window.localStorage.getItem("favorites")
  );
  return (
    <div className="mt-5 flex-grow-1 d-flex flex-column">
      <div className="mx-5 px-2">
        <div className="password-form-wrapper">
          <MDBTypography
            className={`text-center ${
              (customerEmail.length > 26 && "long-email") || ""
            }`.trim()}
            tag="h2"
          >
            {customerEmail}
          </MDBTypography>
          <Link to={{ search: "" }}>
            <MDBTypography
              // @ts-ignore
              variant="body-1"
              className="underline text-center mt-4"
            >
              Not your email? Go back
            </MDBTypography>
          </Link>
          <MDBBox className="mt-5 dark separator" />
          <div className="wlecome-holder">
            <MDBTypography
              tag="h2"
              variant="display-2"
              className="font-weight-light text-center mt-5 mb-4 pb-2"
            >
              Welcome back, {props.emailAndFirstname.firstname}
            </MDBTypography>

            {/* @ts-ignore */}
            <MDBTypography variant="body-2" className="text-center mb-5 pb-5">
              Great to see you again. Enter your password to continue.
            </MDBTypography>
            <form
              className="mb-5 welcome-back-form"
              onSubmit={props.form.handleSubmit}
            >
              <div>
                <div className="position-relative mb-4 pb-2">
                  <MDBInput
                    {...props.form.getFieldProps("password")}
                    hint="Enter password"
                    label="Password"
                    required
                    type="password"
                    size="lg"
                    containerClass="w-100"
                    className={clsx({
                      error:
                        props.form.touched.password &&
                        props.form.errors.password,
                    })}
                  />
                  <div
                    className="text-red position-absolute ellipsis"
                    title={passwordError}
                  >
                    {passwordError}
                  </div>
                </div>
                <Link
                  to={forgotPasswordPath + "?email=" + customerEmail}
                  onClick={() => {
                    props.onClose();
                  }}
                >
                  <MDBTypography
                    // @ts-ignore
                    variant="body-6"
                    className="text-default underline mb-4 pb-2"
                  >
                    Forgot Password?
                  </MDBTypography>
                </Link>
              </div>
              <MDBBtn
                type="submit"
                disabled={props.form.isSubmitting}
                size="lg"
                className="m-0"
              >
                Login
              </MDBBtn>
            </form>
          </div>
        </div>
      </div>
      <MDBRow>
        <div className="flex-grow-1 bg-gray-lighten-4 d-flex flex-column align-items-center px-5">
          <div className="password-form-wrapper d-flex flex-column align-items-center mt-5">
            <MDBTypography
              variant="display-3"
              className="text-center mb-4 pb-2"
            >
              {isFromAddToFavorites
                ? `Have an Existing Account, But Logging in for the First Time?`
                : `Existing Account, First Time Login?`}
            </MDBTypography>
            {/* @ts-ignore */}
            <MDBTypography variant="body-5" className="text-center mb-4 pb-2">
              If you had an account with us on our previous site, please reset
              your password below.
            </MDBTypography>
            <Link to={forgotPasswordPath}>
              <MDBBtn color="secondary" className="mb-5">
                Reset Your Password
              </MDBBtn>
            </Link>
          </div>
        </div>
      </MDBRow>
    </div>
  );
};

export default WelcomeBack;
