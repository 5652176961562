import { MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { customerPaths } from "../customer/routes";
import Favorites from "./pages/Favorites";
import Project from "./pages/Project/Project";
import Saved from "./pages/SavedBuilds/SavedBuildsContainer";
import Wishlists from "./pages/Wishlists";

export interface RouteContainerProps {}

const RouteContainer: React.FC<RouteContainerProps> = (props) => {
  return (
    <MDBContainer fluid>
      <MDBRow>
        <Switch>
          <Route
            path={customerPaths.children.collections.path}
            exact
            render={() => (
              <Redirect
                to={customerPaths.children.collections.children.wishlists.path}
              />
            )}
          />
          <Route
            path={customerPaths.children.collections.children.wishlists.path}
            component={Wishlists}
            exact
          />
          <Route
            path={`${customerPaths.children.collections.children.wishlists.path}/:name`}
            component={Project}
          />
        </Switch>
      </MDBRow>
    </MDBContainer>
  );
};

export default RouteContainer;
