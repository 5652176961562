/*global google*/
import Icon from "app/assets/icon/icon";
//import { isEmpty } from "core/helpers/functions";
import Breadcrumbs from "app/layout/breadcrumbs";
import ReactLoader from "app/layout/loader";
import Select from "app/layout/Select";
import { useScript } from "core/state/hooks/useScript";
// import { Circle } from "react-google-maps";
import { isEmpty, isEqual, isUndefined } from "lodash";
import {  useSelector } from "react-redux";
import {
  MDBBox,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { withRouter } from "react-router-dom";
import { compose, withHandlers } from "recompose";
import { googleMapsStyle } from "./googleMapsStyle";

const {
  MarkerClusterer,
} = require("react-google-maps/lib/components/addons/MarkerClusterer");

const MyMapComponent = compose(
  withHandlers({
    onMarkerClustererClick: () => (markerClusterer) => {},
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  return (
    <GoogleMap
      defaultOptions={{
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        styles: googleMapsStyle,
      }}
      defaultZoom={props.newPosition.radius.zoom}
      zoom={props.newPosition.radius.zoom}
      defaultCenter={{ lat: props.newPosition.lat, lng: props.newPosition.lng }}
      center={{ lat: props.newPosition.lat, lng: props.newPosition.lng }}
    >
      {/* store markers */}
      {props.isMarkerShown && (
        <>
          <MarkerClusterer
            onClick={props.onMarkerClustererClick}
            averageCenter
            enableRetinaIcons
            gridSize={60}
          >
            {props.stores.map((store) => {
              return (
                <Marker
                  key={`store-locator-marker-store-id-${store.store_id}`}
                  icon={{
                    url: require("../../assets/icon/marker.svg"),
                  }}
                  onClick={() => {
                    props.setShownStores({
                      ...props.shownStore,
                      [store.store_id]: !props.shownStores[store.store_id],
                    });
                    props.setNewPosition({
                      lat: store.lat,
                      lng: store.lng,
                    });
                  }}
                  position={{ lat: store.lat, lng: store.lng }}
                >
                  {props.shownStores[store.store_id] && (
                    <InfoWindow
                      onCloseClick={() => {
                        props.setShownStores({
                          ...props.shownStores,
                          [store.store_id]: !props.shownStores[store.store_id],
                        });
                      }}
                    >
                      <>
                        <MDBTypography tag="h2" className="body-1 mb-3">
                          {store.name}
                        </MDBTypography>
                        <MDBBox className={`d-flex align-items-start mb-2`}>
                          <Icon icon="address" />
                          <MDBBox className={`ml-3`}>
                            <MDBTypography
                              tag="p"
                              className="body-6 mb-2 text-primary fwregular"
                            >
                              {store.street}
                            </MDBTypography>
                            <MDBTypography
                              tag="p"
                              className="body-6 mb-2 text-primary fwregular"
                            >
                              {store.city}, {store.state} {store.postcode}
                            </MDBTypography>
                            {/* <MDBTypography
                              tag="p"
                              className="body-6 mb-2 text-primary fwregular"
                            >
                              {store.state} {store.postcode}
                            </MDBTypography> */}
                          </MDBBox>
                        </MDBBox>
                        <MDBBox
                          className={
                            "d-flex flex-column website-content-wrapper"
                          }
                        >
                          <MDBBox
                            className={
                              "d-flex mb-2 get-direction-content align-items-start"
                            }
                          >
                            <Icon icon="location" className={`location-icon`} />
                            <a
                              href={
                                "https://www.google.com/maps?saddr=Current+Location&daddr=" +
                                store.street.replace(/<[^>]+>/g, "")
                              }
                              target={"_blank"}
                              rel="noopener noreferrer"
                              className={`link-3 ml-3 mb-2`}
                            >
                              Get Directions
                            </a>
                          </MDBBox>
                          {store.telephone.length !== 0 ? (
                            <MDBBox className={`d-flex align-items-start mb-2`}>
                              <Icon icon="phone" />
                              <MDBTypography
                                tag="p"
                                className="body-6 ml-3 mb-2 text-primary fwregular"
                              >
                                {store.telephone}
                              </MDBTypography>
                            </MDBBox>
                          ) : (
                            <></>
                          )}
                          {store.website.length !== 0 ? (
                            <MDBBox className={"website-content d-flex mb-2"}>
                              <Icon icon="globeBlue" />
                              <a
                                href={
                                  store.website.includes("http")
                                    ? store.website
                                    : "http://" + store.website
                                }
                                target={"_blank"}
                                rel="noopener noreferrer"
                                className={"link-3 ml-3"}
                              >
                                {store.website}
                              </a>
                            </MDBBox>
                          ) : (
                            <></>
                          )}
                        </MDBBox>
                      </>
                    </InfoWindow>
                  )}
                </Marker>
              );
            })}
          </MarkerClusterer>

          {props.newPosition.lat !== null && props.showMarker && (
            <Marker
              position={{
                lat: props.newPosition.lat,
                lng: props.newPosition.lng,
              }}
            />
          )}
        </>
      )}
    </GoogleMap>
  );
});

const StoreLocator = (props) => {
  
   
  let { location } = props;
  // check if the request came from form outside of this page
  let isPredefined =
    typeof location.state !== "undefined" &&
    location.state !== null &&
    typeof location.state.input !== "undefined" &&
    location.state.input !== "";
  let predefinedSearch = isPredefined ? location.state.input : "";
  let predefinedRadius = { label: "100 mi", value: "100", zoom: 8 };
  // isPredefined
  //   ? { label: "100 mi", value: "100", zoom: 5 }
  //   : "";
  let predefinedCoordinates = isPredefined
    ? location.state.coordinates
    : {
        lat: 39.8398317,
        lng: -80.2621225,
      };

  const [showMarker, setShowMarker] = useState(false);
  const [isMarkerShown, setIsMarkerShown] = useState(false);
  let defaultShownStores = {};
  if (props.stores) {
    props.stores.forEach((store) => {
      defaultShownStores[store.store_id] = false;
    });
  }
  const [shownStores, setShownStores] = useState(defaultShownStores);

  const [address, setAddress] = useState(predefinedSearch);
  const [radius, setRadius] = useState(predefinedRadius);

  const [newPosition, setNewPosition] = useState({
    lat: 39.3118339,
    lng: -80.6773204,
    radius: radius,
  });
  const [storesNearby, setStoresNearby] = useState([]);
  const [coordinates, setCoordinates] = useState(predefinedCoordinates);
  const [defaultLocation] = useState({ ...location });
  const [, setZipCode] = useState("");
  const [, setCountryShortName] = useState("");
  const [, setCountryLongName] = useState("");
  const [, setUserLocation] = useState(undefined);
  let Map = (
    <MyMapComponent
      data={props.data}
      coordinates={coordinates}
      newPosition={
        newPosition.radius === ""
          ? {
              lat: newPosition.lat,
              lng: newPosition.lng,
              radius: { value: "10", zoom: 5 },
            }
          : { lat: newPosition.lat, lng: newPosition.lng, radius: radius }
      }
      setNewPosition={setNewPosition}
      showMarker={showMarker}
      isMarkerShown={isMarkerShown}
      stores={storesNearby}
      shownStores={shownStores}
      setShownStores={setShownStores}
      etailers={props.etailers}
      radius={radius}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${props?.googleApiKey}&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `760px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
  function handleChange(e) {
    setRadius(e);
    if (!isEmpty(address)) findRetail(coordinates.lat, coordinates.lng, e);
  }

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    var addressComponent = results[0].address_components;
    setZipCode("");
    setCountryLongName("");
    setCountryShortName("");
    for (var x = 0; x < addressComponent.length; x++) {
      var chk = addressComponent[x];
      if (chk.types[0] === "postal_code") {
        setZipCode(chk.long_name);
      }
      if (chk.types[0] === "country") {
        setCountryShortName(chk.short_name);
        setCountryLongName(chk.long_name);
      }
    }
    const latLng = await getLatLng(results[0]);
    setCoordinates(latLng);
    setAddress(value);
  };

  var getDistance = function (lat1, lng1, lat2, lng2) {
    let distance = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(lat1, lng1),
      new google.maps.LatLng(lat2, lng2)
    );
    return distance / 1000 / 1.609;
  };

  const findRetail = (lat, lng, radius) => {
    if (status) {
      setNewPosition({
        lat: 26.7903264,
        lng: -80.0984423,
        radius: {
          label: "10 km",
          value: 10,
          zoom: 12,
        },
      });
      // setShowMarker(true);
    } else {
      setStoresNearby([
        ...props.stores
          .filter((store) => {
            let distance = getDistance(lat, lng, store.lat, store.lng);
            return distance <= radius.value;
          })
          .sort((firstStore, secondStore) => {
            const distanceForFirstStore = getDistance(
              lat,
              lng,
              firstStore.lat,
              firstStore.lng
            );
            const distanceForSecondStore = getDistance(
              lat,
              lng,
              secondStore.lat,
              secondStore.lng
            );

            return distanceForFirstStore - distanceForSecondStore;
          })
          .map((store) => store),
      ]);

      setNewPosition({
        lat,
        lng,
        radius,
      });
      setRadius(radius);
      // setShowMarker(true);
    }
  };
  const [status] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [showResults, setShowResult] = useState(true);
  const toggle = () => {
    setViewAll(!viewAll);
  };

  useEffect(() => {
    if (isPredefined) {
      if (!isEqual(location, defaultLocation)) {
        handleSelect(location.state.input);
        setRadius({ ...{ label: "100 mi", value: "100", zoom: 8.5 } });
        setTimeout(() => {
          document.getElementById("store-locator-submit").click();
        }, 500);
      } else {
        setTimeout(() => {
          document.getElementById("store-locator-submit").click();
        }, 500);
      }
    }
  }, [location, defaultLocation, isPredefined]);
  

  const [loaded, error] = useScript(
    props?.googleApiKey?.length > 1
      ? `https://maps.googleapis.com/maps/api/js?&key=${props?.googleApiKey}&libraries=places`
      : ""
  );

  if (!loaded || error) {
    return <ReactLoader />;
  }
  let links = [
    { url: "/", title: "Home" },
    { url: "/where-to-buy", title: "Where To Buy" },
  ];

  return (
    <MDBContainer className={"store-locator customer-service mb-5 pb-5"}>
      <div style={{ display: "hidden" }} id="map"></div>
      <MDBRow>
        <MDBCol md="12">{<Breadcrumbs links={links} />}</MDBCol>
      </MDBRow>
      <MDBTypography tag="h2" className="text-gray-darken-3 mb-5">
        Where to Buy
      </MDBTypography>
      <MDBRow className={"store-locator-wrapper"}>
        <MDBCol>
          <MDBBox className="map-inputs-wrapper border">
            <MDBBox className={`px-4`}>
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
                searchOptions={{
                  types: ["geocode"],
                }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <MDBBox>
                    <MDBInput
                      type="text"
                      hint="Enter Address or Zip/Postal Code"
                      className="w-100"
                      containerClass="w-100"
                      {...getInputProps()}
                    >
                      <Icon
                        onClick={(e) => {
                          navigator.geolocation.getCurrentPosition(
                            (position) => {
                              fetchGeoLocation(
                                position,
                                setAddress,
                                "AIzaSyCHc46yecsF5Daln1s3DaKF-dlLWJpSDes"
                              );
                              setCoordinates({
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                              });
                              setIsMarkerShown(true);
                              findRetail(
                                position.coords.latitude,
                                position.coords.longitude,
                                radius === ""
                                  ? { label: "100 mi", value: "100", zoom: 8 }
                                  : radius
                              );
                              setUserLocation(true);
                            },
                            (error) => {
                              console.error(error);
                              setUserLocation(false);
                            },
                            {
                              enableHighAccuracy: true,
                              timeout: 5 * 1000, // 5 seconds
                              maximumAge: 0,
                            }
                          );
                        }}
                        className="locator-icon"
                        icon={"location"}
                      />
                    </MDBInput>
                    <MDBBox className={"locations-wrapper"}>
                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#ced4d2"
                            : "#fff",
                          marginTop: "1.5rem",
                          padding: "1rem",
                          fontSize: "1.2rem",
                        };
                        return (
                          <div
                            key={suggestion.description}
                            {...getSuggestionItemProps(suggestion, { style })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </MDBBox>
                  </MDBBox>
                )}
              </PlacesAutocomplete>
              <Select
                onChange={(e) => handleChange(e)}
                value={radius}
                options={[
                  { label: "10 mi", value: "10", zoom: 12 },
                  { label: "50 mi", value: "50", zoom: 10 },
                  { label: "100 mi", value: "100", zoom: 9 },
                  { label: "150 mi", value: "150", zoom: 8 },
                  { label: "200 mi", value: "200", zoom: 7 },
                  { label: "250 mi", value: "250", zoom: 6 },
                ]}
                placeholder={"Radius"}
                openIndicator="caret-up"
                closedIndicator="caret-down"
              />
              <MDBBtn
                id={`store-locator-submit`}
                color={"default"}
                className="w-100 my-4 mx-0"
                disabled={isEmpty(address) || radius === ""}
                onClick={() => {
                  setIsMarkerShown(true);

                  findRetail(
                    coordinates.lat,
                    coordinates.lng,
                    radius === "" ? 10 : radius
                  );
                  setShowMarker(true);
                }}
              >
                Search
              </MDBBtn>
            </MDBBox>
            {showMarker && (
              <MDBBox
                className={`search-container ${
                  (viewAll && "all-results") || ""
                }`}
              >
                <MDBBox
                  className={`d-flex justify-content-between px-4 ${
                    (showResults === false && "mb-4") || ""
                  }`}
                >
                  <MDBTypography tag="h4" className="text-gray-darken-3">
                    Search Results:
                  </MDBTypography>
                  {(storesNearby.length > 0 && (
                    <MDBTypography
                      tag="h4"
                      className="link-2 fwregular"
                      onClick={() => {
                        setShowResult(!showResults);
                      }}
                    >
                      {(showResults && "Hide Results") || "Show Results"}
                    </MDBTypography>
                  )) || <></>}
                </MDBBox>
                {!isUndefined(storesNearby) &&
                storesNearby.length > 0 &&
                showMarker ? (
                  <>
                    <ol
                      className={`${viewAll ? "clicked" : "not-clicked"} ${
                        showResults ? "results-visible" : "results-hidden"
                      } mx-4`}
                    >
                      {storesNearby.map((store) => {
                        const handleChooseStoreLocation = () => {
                          setShownStores({
                            ...shownStores,
                            [store.store_id]: !shownStores[store.store_id],
                          });
                          setNewPosition({
                            lat: store.lat,
                            lng: store.lng,
                          });
                        };
                        return (
                          <li
                            key={`store-locator-list-of-location-id-${store.store_id}`}
                            className={`store-content p-0 ${
                              shownStores[store.store_id] ? "active" : ""
                            }`}
                          >
                            <div
                              onClick={handleChooseStoreLocation}
                              onKeyUp={handleChooseStoreLocation}
                              tabIndex={0}
                              role="button"
                              className={`store-content-inner border-bottom border-gray`}
                            >
                              <MDBTypography tag="h2" className="body-1 mb-3">
                                {store.name.toUpperCase()}
                              </MDBTypography>
                              <MDBBox
                                className={`d-flex align-items-start mb-2`}
                              >
                                <Icon icon="address" />
                                <MDBBox className={`ml-3`}>
                                  <MDBTypography
                                    tag="p"
                                    className="body-6 mb-2 text-primary fwregular"
                                  >
                                    {store.street}
                                  </MDBTypography>
                                  <MDBTypography
                                    tag="p"
                                    className="body-6 mb-2 text-primary fwregular"
                                  >
                                    {store.city}, {store.state} {store.postcode}
                                  </MDBTypography>
                                  {/* <MDBTypography
                                    tag="p"
                                    className="body-6 mb-2 text-primary fwregular"
                                  >
                                    {store.state} {store.postcode}
                                  </MDBTypography> */}
                                </MDBBox>
                              </MDBBox>
                              <MDBBox
                                className={
                                  "d-flex mb-2 get-direction-content align-items-start"
                                }
                              >
                                <Icon
                                  icon="location"
                                  className={`location-icon`}
                                />
                                <a
                                  href={
                                    "https://www.google.com/maps?saddr=Current+Location&daddr=" +
                                    store.street.replace(/<[^>]+>/g, "")
                                  }
                                  target={"_blank"}
                                  rel="noopener noreferrer"
                                  className={`link-3 ml-3 mb-2`}
                                >
                                  Get Directions
                                </a>
                              </MDBBox>
                              {store.telephone.length !== 0 ? (
                                <MDBBox
                                  className={`d-flex align-items-start mb-2`}
                                >
                                  <Icon icon="phone" />
                                  <MDBTypography
                                    tag="p"
                                    className="body-6 ml-3 mb-2 text-primary fwregular"
                                  >
                                    {store.telephone}
                                  </MDBTypography>
                                </MDBBox>
                              ) : (
                                <></>
                              )}
                              <MDBBox
                                className={
                                  "d-flex align-items-start website-content-wrapper mb-2"
                                }
                              >
                                {store.website.length !== 0 ? (
                                  <>
                                    <Icon icon="globeBlue" />
                                    <div className={"website-content ml-3"}>
                                      <a
                                        href={
                                          store.website.includes("http")
                                            ? store.website
                                            : "http://" + store.website
                                        }
                                        target={"_blank"}
                                        rel="noopener noreferrer"
                                        className="link-3"
                                      >
                                        {store.website}
                                      </a>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </MDBBox>
                            </div>
                          </li>
                        );
                      })}
                    </ol>
                    {storesNearby.length > 2 && showResults && (
                      <MDBBox
                        onClick={toggle}
                        onKeyUp={toggle}
                        tabIndex={0}
                        role="button"
                        className={`view-toggle position-sticky ${
                          showResults ? "results-visible" : "results-hidden"
                        }`}
                      >
                        <MDBTypography
                          tag="h4"
                          style={{ cursor: "pointer" }}
                          className={`text-center view-all link-2 py-3`}
                        >
                          {!viewAll ? "View All" : "View Less"}
                        </MDBTypography>
                      </MDBBox>
                    )}
                  </>
                ) : (
                  <MDBBox className={"search-container-no-results mx-4"}>
                    <MDBTypography tag="h2" className="body-1 my-3">
                      Sorry, there don't seem to be any stores in your location
                    </MDBTypography>
                    <MDBTypography tag="h2" className="body-6 mb-3 mt-5">
                      Check out some of our e-tailers:
                    </MDBTypography>
                    <ol className={`${viewAll ? "clicked" : "not-clicked"}`}>
                      {props.etailers.map((etailer) => {
                        return (
                          <li key={etailer.website} className={"store-content"}>
                            <MDBTypography tag="h2" className="body-1 mb-3">
                              {" "}
                              {etailer.name}
                            </MDBTypography>

                            {etailer.website && (
                              <div className={"website-content"}>
                                <a
                                  href={
                                    etailer.website.includes("http")
                                      ? etailer.website
                                      : "http://" + etailer.website
                                  }
                                  target={"_blank"}
                                  rel="noopener noreferrer"
                                  className="link-3"
                                >
                                  Visit URL
                                </a>
                              </div>
                            )}
                          </li>
                        );
                      })}
                    </ol>
                  </MDBBox>
                )}
              </MDBBox>
            )}
          </MDBBox>
        </MDBCol>
        <MDBCol lg="12" xl="12" className={"map"}>
          {Map}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

const fetchGeoLocation = async (position, setAddress, api_key) => {
  return await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${parseFloat(
      position.coords.latitude
    )},${parseFloat(position.coords.longitude)}&key=${api_key}`
  )
    .then((response) => response.json())
    .then((data) => {
      setAddress(
        data.results.length > 0 ? data.results[0].formatted_address : ""
      );
      return data.formatted_address;
    });
};

export default withRouter(StoreLocator);
