export const styleManipulation = (modalOpen, isMobile) => {
  let stickyElement;
  const headerMobile = document.querySelector(".header-mobile-container");
  if (!window.location.pathname.includes("build-your-own")) {
    stickyElement = document.querySelector(".generic-pdp .col-lg-8");
    if (stickyElement) {
      // @ts-ignore
      if (modalOpen && stickyElement)
        // @ts-ignore
        stickyElement.style.position = "relative";
      // @ts-ignore
      else stickyElement.style.position = "sticky";
    }
  } else {
    stickyElement = document.querySelector(".custom-pdp .custom-left");
    if (stickyElement) {
      // @ts-ignore
      if (modalOpen && stickyElement) {
        // @ts-ignore
        if (isMobile && headerMobile?.style) {
          // @ts-ignore
          headerMobile.style.zIndex = "1";
        }
        stickyElement.classList.add("modal-open");
      } else {
        // @ts-ignore
        if (isMobile && headerMobile?.style) {
          // @ts-ignore
          headerMobile.style.zIndex = "100";
        }
        stickyElement.classList.remove("modal-open");
      }
    }
  }
};
