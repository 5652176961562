// @ts-check

import Icon from "app/assets/icon/icon";
import CollectionsContext from "app/layout/collections/CollectionsContext";
import { createEnterHandler } from "app/utils/utils";
import {
  MDBBox,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React from "react";
import EmailAndPassword from "./components/EmailAndPassword";
import Express from "./components/Express";
import WelcomeBack from "./components/WelcomeBack";

/**
 * @typedef {Pick<import('./components/WelcomeBack/DataContainer').DataContainerProps, 'login'> & { setup: import('./models').Setup; isOpen: boolean; onClose: () => void; isLoggedIn: boolean; fallback?: React.ReactNode; }} LoginProps
 *
 * @param {LoginProps} props
 */
const Login = ({ isLoggedIn, isOpen, onClose, fallback, setup, login }) => {
  // @ts-ignore
  const { selectedProduct, setSelectedProduct } = React.useContext(
    CollectionsContext
  );

  const [windowY, setWindowY] = React.useState(0);
  React.useEffect(() => {
    return () => {
      // @ts-ignore
      setSelectedProduct(null);
    };
  }, []);

  return isLoggedIn ? (
    <>{fallback}</>
  ) : (
    <MDBModal
      inline={false}
      noClickableBodyWithoutBackdrop
      overflowScroll={false}
      isOpen={isOpen}
      toggle={onClose}
      size="lg"
      // @ts-ignore
      // className={selectedProduct && "favorites"}
      className={"favorites"}
    >
      <MDBModalBody className="p-0 d-flex flex-column">
        <MDBContainer className="p-0">
          <MDBRow
            style={{ position: "static" }}
            className="d-flex bg-default mx-0 p-4 justify-content-center align-items-center position-relative"
          >
            <div
              className="left-0 ml-4 pl-2 position-absolute close-icon"
              onClick={onClose}
              onKeyUp={createEnterHandler(onClose)}
              tabIndex={0}
              role="button"
            >
              <Icon icon="closeWhite" />
            </div>
            <MDBBox>
              <MDBTypography
                tag="h2"
                variant="display-2"
                className="text-white font-weight-light text-center"
              >
                {/* @ts-ignore */}
                {selectedProduct ? "Add to Favorites" : "Login"}
              </MDBTypography>
            </MDBBox>
          </MDBRow>
        </MDBContainer>
        <MDBContainer className="bg-white">
          {setup?.type === "password" ? (
            <WelcomeBack
              emailAndFirstname={setup.payload}
              login={login}
              onClose={onClose}
              // @ts-ignore
              windowY={windowY}
              setWindowY={setWindowY}
            />
          ) : setup.type === "express" ? (
            <Express onClose={onClose} />
          ) : (
            // @ts-ignore
            <EmailAndPassword
              /* @ts-ignore */
              setWindowY={setWindowY}
              windowY={windowY}
              selectedProduct={selectedProduct}
            />
          )}
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );
};

export default Login;
