import Icon from "app/assets/icon/icon";
import { selectIsLoggedIn } from "app/state/redux/data/customer/selectors";
import {
  MDBBox,
  MDBCol,
  MDBCollapse,
  MDBContainer,
  MDBHamburgerToggler,
  MDBLink,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ToggleMenu from "./toggle-menu";
import Typography from "app/pages/typography/typography";

const Mobile = (props) => {
  let {
    menuItems,
    setOpen,
    open,
    menuToggle,
    collapseId,
    setCollapseId,
    toggleHamburger,
  } = props;
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const listStaticLinks = [
    {
      label: "Company Story",
      url: "/company-story",
      showLink: "always",
    },
    {
      label: "Industry Services",
      url: "/sales-representative",
      showLink: "always",
    },
    {
      label: "Craftsmanship",
      url: "/craftsmanship",
      showLink: "always",
    },
    {
      label: "Environmental Policy",
      url: "/environmental-policy",
      showLink: "always",
    },
    {
      label: "Careers",
      url: "/careers",
      showLink: "always",
    },
    {
      label: "Contact Us",
      url: "/contact",
      showLink: "always",
    },
    {
      label: "Resources",
      url: "/resources",
      showLink: "loggedin",
    },
    {
      label: "Accessibility Statement",
      url: "/accessibillity-statement",
      showLink: "always",
    },
    {
      label: "Warranty",
      url: "/warranty",
      showLink: "always",
    },
  ];
  const closeBurger = () => {
    if (open) {
      setCollapseId([]);
      document.getElementById("hamburger1").click();
    }
  };
  const [, setWidth] = useState(document.documentElement.clientWidth);
  const [hideBottomPart, setHideBottomPart] = useState(false);
  const [menuItemSelectedById, setMenuItemSelectedById] = useState([]);
  const onResizeSetWidth = () => {
    setWidth(document.documentElement.clientWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", onResizeSetWidth);
    return () => {
      window.removeEventListener("resize", onResizeSetWidth);
    };
  }, []);

  useEffect(() => {
    if (open) {
      document.body.classList.add("scroll-lock");
    } else {
      document.body.classList.remove("scroll-lock");
    }
  }, [open]);

  useEffect(() => {
    if (collapseId.length > 0) {
      setHideBottomPart(true);
    } else {
      setHideBottomPart(false);
    }
  }, [collapseId]);

  const location = useLocation();

  const navigation = [
    <MDBBox key="1">
      <div className="d-flex justify-content-center header-menu-container w-100">
        <MDBNavItem className="dropdown-toggle nav-link static-link p-0 w-100">
          <Link
            to={"/residential"}
            className={`text-decoration-none menu-header-link text-uppercase text-center w-100 ${
              location.pathname === "/residential" ? "active" : ""
            }`}
            key={`nav-item-not-uncontrolled-nav-1`}
            onClick={(e) => {
              closeBurger();
            }}
          >
            <MDBTypography tag="span" className="body-1 font-weight-bold">
              Residential
            </MDBTypography>
          </Link>
        </MDBNavItem>

        <MDBNavItem className="dropdown-toggle nav-link static-link p-0 w-100">
          <Link
            to={"/commercial"}
            className={`text-decoration-none menu-header-link text-uppercase text-center w-100 ${
              location.pathname === "/commercial" ? "active" : ""
            }`}
            key={`nav-item-not-uncontrolled-nav-1`}
            onClick={(e) => {
              closeBurger();
            }}
          >
            <MDBTypography tag="span" className="body-1 font-weight-bold">
              Commercial /<br /> Senior Living
            </MDBTypography>
          </Link>
        </MDBNavItem>
      </div>
      

      <MDBNavbarNav left>
        {Object.keys(menuItems).map((element, index) => {
          let currentMenuItem = menuItems[index];

          if (menuItems[index].include_in_menu === 0) {
            return <Fragment key={`menu-fragment-${index}`}></Fragment>;
          }

          let newCurrentMenuItem = { ...currentMenuItem };

          // Check if location is residential or commercial
          if (
            ["/residential", "/commercial"].includes(
              location.pathname.toLowerCase()
            )
          ) {
            if (currentMenuItem.name === "Products") {
              // Find the residential or commercial object
              const matchingMenuByPath = currentMenuItem.children.find(
                (child) =>
                  `/${child.name}`.toLowerCase() ===
                  location.pathname.toLowerCase()
              );

              newCurrentMenuItem.children = matchingMenuByPath?.children;
            }
          }

          return (
            (newCurrentMenuItem.children?.length > 0 && (
              <MDBNavItem key={`uncontrolled-nav-dropdown-${index}`}>
                <MDBBox
                  className=" dropdown-toggle nav-link p-0 "
                  id={`nav-dropdown-${index}`}
                >
                  <MDBBox
                    className={`mobile-dropdown w-100`}
                    id={`dropdown-visible-${index}`}
                  >
                    <Link display={`flex`} flex={`column`}>
                      <ToggleMenu
                        items={newCurrentMenuItem.children}
                        menuItem={newCurrentMenuItem}
                        toggleHamburger={toggleHamburger}
                        setOpen={setOpen}
                        open={open}
                        closeBurger={closeBurger}
                        collapseId={collapseId}
                        setCollapseId={setCollapseId}
                        setMenuItemSelectedById={setMenuItemSelectedById}
                        menuItemSelectedById={menuItemSelectedById}
                      />
                    </Link>
                  </MDBBox>
                </MDBBox>
              </MDBNavItem>
            )) ||
            (isLoggedIn ? (
              <MDBNavItem className="dropdown-toggle nav-link static-link">
                <Link
                  to={`/${newCurrentMenuItem.url_path}`}
                  className={`text-decoration-none link-mobile text-uppercase`}
                  key={`nav-item-not-uncontrolled-nav-${index}`}
                  onClick={(e) => {
                    toggleHamburger(props);
                    closeBurger();
                  }}
                >
                  <MDBTypography tag="span" className="body-1">
                    {newCurrentMenuItem.name}
                  </MDBTypography>
                </Link>
              </MDBNavItem>
            ) : newCurrentMenuItem.name !== "Resources" ? (
              <MDBNavItem className="dropdown-toggle nav-link static-link">
                <Link
                  to={`/${newCurrentMenuItem.url_path}`}
                  className={`text-decoration-none link-mobile text-uppercase`}
                  key={`nav-item-not-uncontrolled-nav-${index}`}
                  onClick={(e) => {
                    toggleHamburger(props);
                    closeBurger();
                  }}
                >
                  <MDBTypography tag="span" className="body-1">
                    {newCurrentMenuItem.name}
                  </MDBTypography>
                </Link>
              </MDBNavItem>
            ) : (
              ""
            ))
          );
        })}
      </MDBNavbarNav>
    </MDBBox>,
  ];

  return (
    <>
      <MDBNavbar
        id={`mobile-header`}
        expand="lg"
        scrolling
        className={`${
          open
            ? "active white align-items-start "
            : "not-active white align-items-start"
        }`}
      >
        <MDBContainer fluid className={`px-0`}>
          <MDBBox
            display={`flex`}
            flex={`row`}
            justifyContent={`between`}
            id={`mobile-navigation-container`}
            className={`mobile-navigation-container-wrapper`}
          >
            <MDBBox className={`hamburger-menu mobile-left`}>
              <MDBHamburgerToggler
                color="#000000"
                id="hamburger1"
                onClick={() => {
                  toggleHamburger(props);
                  setCollapseId([]);
                }}
              />
            </MDBBox>
          </MDBBox>

          <MDBContainer
            fluid
            id={`mobile-navigation`}
            className={`align-items-start ${
              (open && `is-open`) || `is-closed`
            } px-0`}
          >
            <MDBCollapse className={`collapse-menu`} isOpen={open} navbar>
              <TransitionGroup component={null}>
                {navigation.map((value, index) => (
                  <CSSTransition
                    key={menuToggle}
                    // in={menuToggle}
                    classNames={`toggle`}
                    appear={600}
                    timeout={600}
                  >
                    {navigation[menuToggle]}
                  </CSSTransition>
                ))}
              </TransitionGroup>
              {/*Menu toggle tabs*/}
              {!hideBottomPart && (
                <MDBContainer className="mobile-middle-block " fluid>
                  {/* <CmsBlock id="mobile_links_menu" /> */}
                  <MDBRow className={"mobile-links-block overflow-scroll"}>
                    {listStaticLinks.map(
                      (list, index) =>
                        (list.showLink === "always" ||
                          (list.showLink === "loggedin" && isLoggedIn)) && (
                          <MDBCol key={index} size="12" className="py-2">
                            <MDBLink
                              onClick={() => {
                                toggleHamburger(props);
                                closeBurger();
                              }}
                              to={list.url}
                            >
                              <MDBTypography tag="span" className="body-3">
                                {list.label}
                              </MDBTypography>
                            </MDBLink>
                          </MDBCol>
                        )
                    )}
                  </MDBRow>
                </MDBContainer>
              )}
              {!hideBottomPart &&
                (!isLoggedIn ? (
                  <MDBContainer fluid className="p-0 bottom-mobile-block">
                    <MDBRow className="mobile-bottom-block w-100 m-0">
                      <MDBCol size="12">
                        <MDBLink
                          to={"/where-to-buy"}
                          className="text-center"
                          onClick={() => {
                            toggleHamburger(props);
                            closeBurger();
                          }}
                        >
                          <Icon icon="globeWhite" />
                          <br />
                          <MDBTypography tag="span" className="body-3">
                            WHERE TO BUY
                          </MDBTypography>
                        </MDBLink>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                ) : (
                  <MDBContainer fluid className="p-0 bottom-mobile-block">
                    <MDBRow className="mobile-bottom-block w-100 m-0">
                      <MDBCol size="4">
                        <MDBLink
                          to={"/where-to-buy"}
                          className="text-center"
                          onClick={() => {
                            toggleHamburger(props);
                            closeBurger();
                          }}
                        >
                          <Icon icon="globeWhite" />
                          <br />
                          <MDBTypography tag="span" className="body-3">
                            WHERE TO BUY
                          </MDBTypography>
                        </MDBLink>
                      </MDBCol>
                      <MDBCol size="4">
                        <MDBLink
                          to={"customer/collections/lists"}
                          className="text-center"
                          onClick={() => {
                            toggleHamburger(props);
                            closeBurger();
                          }}
                        >
                          <Icon icon="heartWhite" />
                          <br />
                          <MDBTypography tag="span" className="body-3">
                            FAVORITES
                          </MDBTypography>
                        </MDBLink>
                      </MDBCol>
                      <MDBCol size="4">
                        <MDBLink
                          to={"/quote"}
                          className="text-center"
                          onClick={() => {
                            toggleHamburger(props);
                            closeBurger();
                          }}
                        >
                          <Icon icon="requestQuoteWhite" />
                          <br />
                          <MDBTypography tag="span" className="body-3">
                            REQUEST A QUOTE
                          </MDBTypography>
                        </MDBLink>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                ))}
            </MDBCollapse>
          </MDBContainer>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
};

export default Mobile;
