// logic is inversed, true means that an error exists
import { emailValidation } from "app/config/customer/validationRules";
import { passwordValidation } from "app/config/customer/validationRules";
export const validate = (email, password, confirm_password) => {
  return {
    email: email.length === 0 || ValidateEmail(email),
    password: password.length === 0 || ValidatePassword(password),
    confirm_password: password !== confirm_password,
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];

  return hasError ? shouldShow : false;
};

function ValidateEmail(mail) {
  if (emailValidation.test(mail)) {
    return false;
  }
  return true;
}
function ValidatePassword(pass) {
  if (passwordValidation.test(pass)) {
    return false;
  }
  return true;
}
