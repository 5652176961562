import React, { useState } from "react";
import FabricMediaGallery from "./fabricMediaGallery";

const FabricMediaGalleryDataContainer = ({
  productData,
  aggregations,
  ...props
}) => {
  const [sliderNav, setSliderNav] = useState(null);
  const [thumbnailSliderNav, setThumbnailSliderNav] = useState(null);
  let mainSlider = null;
  let thumbnailSlider = null;
  let mediaGallery = productData.media_gallery.filter((image) => {
    return !image.disabled;
  });

  return (
    <FabricMediaGallery
      productData={productData}
      mediaGallery={mediaGallery}
      mainSlider={mainSlider}
      thumbnailSlider={thumbnailSlider}
      sliderNav={sliderNav}
      setSliderNav={setSliderNav}
      thumbnailSliderNav={thumbnailSliderNav}
      setThumbnailSliderNav={setThumbnailSliderNav}
      aggregations={aggregations}
      {...props}
    />
  );
};

export default FabricMediaGalleryDataContainer;
