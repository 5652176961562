export const overviewSize = 371;

export const overviewWrapperWidth = {
  width: overviewSize,
};
export const overviewWrapperHeight = {
  height: overviewSize,
};
export const overviewWrapperStyle = {
  ...overviewWrapperWidth,
  ...overviewWrapperHeight,
};
export const mobileSize = "100%";
export const mobileSizeWidth={
  width: mobileSize,
}