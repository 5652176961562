import { MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React from "react";

export interface TitleProps {
  children: React.ReactNode;
}

const Title: React.FC<TitleProps> = (props) => {
  return (
    <MDBRow>
      <MDBCol>
        <MDBTypography tag="h1" className="mt-4 border-bottom-1 pb-3 mb-5">
          {props.children}
        </MDBTypography>
      </MDBCol>
    </MDBRow>
  );
};

export default Title;
