import React, { useMemo, Fragment } from "react";

const ItemSelections = ({ item, isTablet }) => {
  const buildYourOwnOptions = useMemo(() => {
    let byoItem = item?.selected_options?.find((option) =>
      option?.label?.includes("Build your own options")
    );
    if (!byoItem) return null;
    let byoValues = JSON.parse(byoItem.value);
    return Object.entries(byoValues).map(([label, value]) => ({
      label: "Select " + label,
      value: value?.name,
    }));
  }, [item]);

  const regularOptions = useMemo(
    () =>
      item?.selected_options
        .filter((option) => !option?.label?.includes("Build your own"))
        ?.map((option) => {
          return { label: option.label, value: option.fabric_finish_value };
        }),
    [item]
  );

  const enteredOptions = useMemo(
    () =>
      item?.entered_options?.map((option) => {
        return { label: option.label, value: option.fabric_finish_value };
      }),
    [item]
  );

  const allOptions = useMemo(() => {
    let options = [];
    options = options.concat(
      buildYourOwnOptions,
      regularOptions,
      enteredOptions
    );
    return options.filter(
      (option) =>
        option &&
        option.label !== "Build your own image" &&
        option.label !== "Build your own configurator url" &&
        option.label !== "Upholstery Options" &&
        !option.value?.toLowerCase()?.includes("upcharge") &&
        !option.label?.toLowerCase()?.includes("upcharge")
    );
  }, [buildYourOwnOptions, enteredOptions, regularOptions]);

  return (
    <div
      className={`d-flex align-items-start ${isTablet ? "flex-column" : ""}`}
    >
      <p className="mr-5">Selections</p>
      {allOptions.length ? (
        <div className="d-grid w-100">
          {allOptions?.map((option, i) => (
            <Fragment key={i}>
              <div className="d-flex justify-content-between">
                <p className="text-capitalize option-title">{option?.label}</p>
                <p className="text-right">{option?.value}</p>
              </div>
            </Fragment>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default ItemSelections;
