import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  MDBBox,
  MDBCol,
  MDBContainer,
  MDBFooter,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBTypography,
  MDBBtn,
} from "mdbreact";
import Icon from "app/assets/icon/icon";
import SearchInput from "./searchInput";
const SearchModal = ({
  open,
  setOpen,
  setSearchVal,
  setOption,
  option,
  searchVal,
  searchResult,
  productType,
  setProductType,
  productTypeOptions,
  categories,
  category,
  setCategoryFilter,
}) => {
  useEffect(() => {
    document.body.classList.add("search-open");

    return function cleanup() {
      document.body.classList.remove("search-open");
    };
  }, [open]);

  const toggleFn = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);
  let catName = category.toLowerCase().split(" ").join("-");

  const [isExact, setExact] = useState(true);

  return (
    <>
      {open && (
        <MDBContainer className="search-modal-wrapper">
          <MDBModal
            inline={false}
            noClickableBodyWithoutBackdrop
            overflowScroll={false}
            isOpen={open}
            toggle={toggleFn}
            backdropClassName="d-none"
            className="m-0 ml-auto search-modal modal-wrapper"
            id={"search-modal-id"}
          >
            <MDBModalHeader className="d-flex bg-default mx-0 p-4 justify-content-center align-items-center position-relative">
              <MDBBox
                className="ml-4 pl-2 position-absolute modal-title-search cursor-pointer"
                onClick={toggleFn}
                style={{ left: 0 }}
              >
                <Icon icon="closeWhite" />
              </MDBBox>
              <MDBRow style={{ position: "static" }}>
                <MDBBox>
                  <MDBTypography
                    tag="h2"
                    variant="display-2"
                    className="text-white font-weight-light text-center"
                  >
                    Search
                  </MDBTypography>
                </MDBBox>
              </MDBRow>
            </MDBModalHeader>
            <div className="ml-5 mr-5 pt-5 d-flex flex-row-reverse justify-content-end search-tabs">
              {categories.sort().map((cat, index) => {
                return (
                  <MDBBox
                    key={cat}
                    // @ts-ignore
                    onClick={() => {
                      setCategoryFilter(cat);
                    }}
                    tag="div"
                    className={`tab horisontal-tab pt-3 mr-5 ${
                      category === cat ? "active" : ""
                    }`.trim()}
                  >
                    {cat}
                  </MDBBox>
                );
              })}
            </div>
            <MDBModalBody className="pt-2 pb-0 px-5 bg-white">
              <SearchInput
                option={option}
                setOption={setOption}
                searchVal={searchVal}
                setSearchVal={setSearchVal}
                toggleFn={toggleFn}
                productType={productType}
                setProductType={setProductType}
                productTypeOptions={productTypeOptions}
                category={category}
                isExact={isExact}
                setExact={setExact}
              />
            </MDBModalBody>
            <MDBFooter className="view-all-search-wrapper">
              {searchVal.length > 0 &&
                searchResult?.products !== "undefined" &&
                searchResult?.products?.total_count !== "undefined" &&
                searchResult?.products?.total_count > 0 && (
                  <MDBContainer fluid className="bg-gray-lighten-4 border-top">
                    <MDBRow className="align-items-center">
                      <MDBCol size="6">
                        <MDBBox className="p-lg-5">
                          <MDBBtn
                            className="m-0 w-100"
                            color="secondary"
                            onClick={() => setSearchVal("")}
                          >
                            Clear Search
                          </MDBBtn>
                        </MDBBox>
                      </MDBCol>
                      <MDBCol size="6">
                        <MDBBox className="p-lg-5 py-5">
                          <a
                            onClick={toggleFn}
                            href={
                              typeof productType?.value !== "undefined"
                                ? `/search-results?search=` +
                                  encodeURI(searchVal) +
                                  "&options=" +
                                  option.label +
                                  "&category=" +
                                  catName +
                                  "&category_id_product=" +
                                  productType?.value +
                                  "&page=1" +
                                  (isExact ? "&exact=1" : "")
                                : `/search-results?search=` +
                                  encodeURI(searchVal) +
                                  "&options=" +
                                  option.label +
                                  "&category=" +
                                  catName +
                                  "&page=1" +
                                  (isExact ? "&exact=1" : "")
                            }
                            className={`btn btn-default button w-100 m-0`}
                          >
                            View All
                          </a>
                        </MDBBox>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                )}
            </MDBFooter>
          </MDBModal>
        </MDBContainer>
      )}
    </>
  );
};
export default SearchModal;
