import React from "react";
import CollectionNotCustom from "./collectionNotCustom";

const CollectionNotDataContainer = ({
  imageAndVideo,
  productsInCollection,
  currentPage,
  setCurrentPage,
  totalCount,
  pageSize,
  setPageSize,
  data,
  totalPages,
  ...props
}) => {
  const catImages = imageAndVideo?.media_gallery_entries;
  const catVideo = imageAndVideo?.category_video;
  return (
    <CollectionNotCustom
      catVideo={catVideo}
      catImages={catImages}
      productsInCollection={productsInCollection}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalPages={totalPages}
      totalCount={totalCount}
      setPageSize={setPageSize}
      pageSize={pageSize}
      data={data}
      {...props}
      categoryInfo={props.category}
    />
  );
};

export default CollectionNotDataContainer;
