import JolaViewer from "@jola_interactive/jola_viewer";
import {
  TextureLoader,
  PlaneBufferGeometry,
  MeshBasicMaterial,
  ShadowMaterial,
  RepeatWrapping,
  Mesh,
  Vector3,
  Box3,
  LineBasicMaterial,
  Color,
  Group,
  BufferGeometry,
  Line,
  FontLoader,
  TextGeometry,
  sRGBEncoding,
  Fog,
  Euler,
  AnimationMixer,
  LoopOnce,
  Clock,
} from "three";
import themeConfiguration from "../../../config/themeConfiguration";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { USDZExporter } from "three/examples/jsm/exporters/USDZExporter";

import QRCode from "react-qr-code";

export const SeniorModels = [
  "acc1-sa.gltf",
  "acc2-sa.gltf",
  "acc3-sa.gltf",
  "acc4-sa.gltf",
  "acc5-sa.gltf",
  "acc_base.gltf",
  "frame-01-semi-wide.gltf",
  "frame-01-semi.gltf",
  "frame-01-tight-wide.gltf",
  "frame-01-tight.gltf",
  "frame-40-semi-wide.gltf",
  "frame-40-semi.gltf",
  "frame-40-tight-wide.gltf",
  "frame-40-tight.gltf",
  "frame-452.gltf",
  "frame-453F.gltf",
  "frame-454F.gltf",
  "frame-455F.gltf",
  "frame-456F.gltf",
  "frame-50-semi-wide.gltf",
  "frame-50-semi.gltf",
  "frame-50-tight-wide.gltf",
  "frame-50-tight.gltf",
  "frame-55-semi-wide.gltf",
  "frame-55-semi.gltf",
  "frame-55-tight-wide.gltf",
  "frame-55-tight.gltf",
  "frame-56-semi-wide.gltf",
  "frame-56-semi.gltf",
  "frame-56-tight-wide.gltf",
  "frame-56-tight.gltf",
  "frame-69-semi-wide.gltf",
  "frame-69-semi.gltf",
  "frame-69-tight-wide.gltf",
  "frame-69-tight.gltf",
  "frame-70-semi-wide.gltf",
  "frame-70-semi.gltf",
  "frame-70-tight-wide.gltf",
  "frame-70-tight.gltf",
  "frame-S-G.gltf",
  "frame-S-H.gltf",
  "frame-S-R.gltf",
  "frame-S-T.gltf",
  "frame-S-M.gltf",
  "leg-Q.gltf",
  "leg-R.gltf",
  "leg-S-01-G.gltf",
  "leg-S-01-H.gltf",
  "leg-S-01-M.gltf",
  "leg-S-01-R.gltf",
  "leg-S-01-T.gltf",
  "leg-S-40-G.gltf",
  "leg-S-40-H.gltf",
  "leg-S-40-M.gltf",
  "leg-S-40-R.gltf",
  "leg-S-40-T.gltf",
  "leg-S-50-G.gltf",
  "leg-S-50-H.gltf",
  "leg-S-50-M.gltf",
  "leg-S-50-R.gltf",
  "leg-S-50-T.gltf",
  "leg-S-55-G.gltf",
  "leg-S-55-H.gltf",
  "leg-S-55-M.gltf",
  "leg-S-55-R.gltf",
  "leg-S-55-T.gltf",
  "leg-S-56-G.gltf",
  "leg-S-56-H.gltf",
  "leg-S-56-M.gltf",
  "leg-S-56-R.gltf",
  "leg-S-56-T.gltf",
  "leg-S-69-G.gltf",
  "leg-S-69-H.gltf",
  "leg-S-69-M.gltf",
  "leg-S-69-R.gltf",
  "leg-S-69-T.gltf",
  "leg-S-70-G.gltf",
  "leg-S-70-H.gltf",
  "leg-S-70-M.gltf",
  "leg-S-70-R.gltf",
  "leg-S-70-T.gltf",
  "leg-W.gltf",
  "seat-01-E.gltf",
  "seat-01-S.gltf",
  "seat-40-E.gltf",
  "seat-40-S.gltf",
  "seat-50-E.gltf",
  "seat-50-S.gltf",
  "seat-55-E.gltf",
  "seat-55-S.gltf",
  "seat-56-E.gltf",
  "seat-56-S.gltf",
  "seat-69-E.gltf",
  "seat-69-S.gltf",
  "seat-70-E.gltf",
  "seat-70-S.gltf",
  "side-G-left.gltf",
  "side-G-right.gltf",
  "side-H-left.gltf",
  "side-H-right.gltf",
  "side-M-left.gltf",
  "side-M-right.gltf",
  "side-R-left.gltf",
  "side-R-right.gltf",
  "side-T-left.gltf",
  "side-T-right.gltf",
  "mechanism_animation.gltf",
  "remote_pocket.gltf",
  "remote_pocket_G.gltf",
  "remote_pocket_H.gltf",
  "remote_pocket_R.gltf",
];

export const zoomOptions = {
  min: -3,
  current: 0,
  max: 8,
};

export class Player extends JolaViewer {
  constructor(containerID, options) {
    super(containerID, options);
    this.collectionPath = options.collectionPath
      ? options.collectionPath
      : "senior-living-solutions";
    this.hdrPath = `${themeConfiguration.model_url}/3d/${this.collectionPath}/webgl/hdr/hdr.hdr`;
    this.loadHDR(this.hdrPath, 0.2);

    this.scene.background = new Color("#fbfbfb");

    this.model = new Group();
    this.model.name = "model";
    this.modelFrame = new Group();
    this.modelArm = new Group();
    this.modelSeat = new Group();
    this.modelBase = new Group();
    this.modelCushion = new Group();
    this.modelDimensions = new Group();
    this.modelAccessories = new Group();
    this.modelAccessoriesLeft = new Group();
    this.modelAccessoriesRight = new Group();
    this.modelPowerOptions = new Group();
    this.mainFabricCounter = 0;
    this.init = false;
    this.forcedChange = false;

    this.framePin = "small_pins";
    this.sidePin = "side_pins";

    this.materials = [];

    this.clock = new Clock();

    this.mechanismMixer = null;
    this.mechanismActiveAction = null;
    this.frameMixer = null;
    this.frameActiveAction = null;

    this.actions = [];

    this.textureLoader = new TextureLoader();

    this.frameObject = {
      id: "01",
      name: "Chair",
      image: `${themeConfiguration.model_url}/3d/${this.collectionPath}/images.jpg`,
    };
    this.seatObject = {
      id: "E",
      name: "EasyClean, Pull-Out Seat with Semi-Attached Back",
      type: "semi",
      frame: {
        id: "semi",
      },
      seat: {
        id: "E",
      },
      image: `${themeConfiguration.model_url}/3d/${this.collectionPath}/images/seat-semi-E.jpg`,
    };
    this.armObject = {
      id: "G",
      name: "Border Track Arm",
      image: `${themeConfiguration.model_url}/3d/${this.collectionPath}/images/side-G.jpg`,
      type: "wide",
    };
    this.baseObject = {
      id: "Q",
      name: "Leg Q",
      image: `${themeConfiguration.model_url}/3d/${this.collectionPath}/images/leg-Q-brass.jpg`,
      type: "leg",
    };
    this.pinsObject = {};
    this.accessoryLeftObject = {};
    this.accessoryRightObject = {};
    this.powerOptionObject = {};

    this.modelsPath = `${themeConfiguration.model_url}/3d/${this.collectionPath}/webgl/models/`;
    this.cushionEnabled = false;
    this.accessoriesEnabled = false;
    this.nailsEnabled = false;

    this.productDimensions = {};
  }

  async Init() {
    this.model.add(this.modelFrame);
    this.model.add(this.modelArm);
    this.model.add(this.modelSeat);
    this.model.add(this.modelBase);
    this.model.add(this.modelCushion);
    this.model.add(this.modelDimensions);
    this.modelAccessories.add(this.modelAccessoriesLeft);
    this.modelAccessories.add(this.modelAccessoriesRight);
    this.model.add(this.modelAccessories);

    this.scene.add(this.model);

    await this.SetFloor();

    await this.LoadFinish(
      "main",
      `${themeConfiguration.model_url}/3d/textures/fabric/3100_linen_seamless.jpg`,
      `${themeConfiguration.model_url}/3d/textures/fabric/3100_linen_seamless_icon.jpg`,
      "Linen",
      8
    );

    await this.LoadFinish(
      "woodMAT",
      `${themeConfiguration.model_url}/3d/textures/wood/cerused_espresso_seamless.jpg`,
      `${themeConfiguration.model_url}/3d/textures/wood/cerused_espresso_seamless_icon.jpg`,
      "Cerused Expresso",
      1
    );

    await this.LoadFinish(
      "nail_finish",
      `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_aged_bronze.jpg`,
      `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_aged_bronze_swach.png`,
      "Aged Bronze",
      "5",
      "0.3",
      "0.15"
    );

    this.materials.push({
      type: "nail_finish",
      map: null,
    });

    await this.LoadFrame(this.frameObject, true);
  }

  async SetFloor() {
    let parquetMap = await this.LoadTexture(
      `${themeConfiguration.model_url}/3d/textures/floor/grid.jpg`
    );
    parquetMap.repeat.set(500, 500);
    parquetMap.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    parquetMap.wrapT = RepeatWrapping;
    parquetMap.wrapS = RepeatWrapping;

    let geo = new PlaneBufferGeometry(100, 100);
    let mat = new MeshBasicMaterial({
      map: parquetMap,
    });

    this.plane = new Mesh(geo, mat);
    //plane.visible = false;
    this.plane.receiveShadow = true;
    this.plane.position.set(0, 0, 0);
    this.plane.rotation.set(Math.PI / -2, 0, 0);

    this.scene.fog = new Fog(0xfbfbfb, 10, 20);
    this.scene.add(this.plane);

    let shadowMat = new ShadowMaterial({ opacity: 0.2 });
    this.shadowPlane = new Mesh(geo, shadowMat);
    this.shadowPlane.receiveShadow = true;
    this.shadowPlane.position.set(0, 0, 0);
    this.shadowPlane.rotation.set(Math.PI / -2, 0, 0);
    this.scene.add(this.shadowPlane);
  }

  async LoadFloor(map, repeat) {
    let newMap = await this.LoadTexture(map);
    newMap.repeat.set(repeat, repeat);
    newMap.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    newMap.wrapS = RepeatWrapping;
    newMap.wrapT = RepeatWrapping;

    this.plane.material.map = newMap;
  }

  LoadObject(url) {
    return new Promise((resolve) => {
      this.loader.load(url, (result) => {
        this.updateMap(result.scene);
        resolve(result);
      });
    });
  }

  async Reset() {
    this.modelFrame.children = [];
    this.modelArm.children = [];
    this.modelSeat.children = [];
    this.modelBase.children = [];
    this.modelCushion.children = [];
    this.modelDimensions.children = [];
    this.modelAccessoriesLeft.children = [];
    this.modelAccessoriesRight.children = [];
    this.modelPowerOptions.children = [];
  }

  async LoadAll() {
    await this.Reset();

    await this.LoadFrame(this.frameObject);
    await this.LoadArm(this.armObject);
    await this.LoadSeat(this.seatObject);
    await this.LoadBase(this.baseObject);
  }

  async LoadFrame(frameobject, updateCameraPosition = false) {
    await this.Reset();
    await this.HideDimensions();

    this.frameObject = frameobject;

    if (Array.isArray(this.frameObject.id)) {
      for (const id of this.frameObject.id) {
        let componentPath;

        const frameArmSeatPath =
          "frame-" +
          id +
          "-" +
          this.seatObject.frame.id +
          "-" +
          this.armObject.type +
          ".gltf";

        const frameSeatPath =
          this.modelsPath +
          "frame-" +
          id +
          "-" +
          this.seatObject.frame.id +
          ".gltf";

        const framePath = "frame-" + id + ".gltf";

        if (this.ModelExist(frameArmSeatPath)) {
          componentPath = this.modelsPath + frameArmSeatPath;
        } else if (this.ModelExist(frameSeatPath)) {
          componentPath = this.modelsPath + frameSeatPath;
        } else {
          componentPath = this.modelsPath + framePath;
        }

        let componentModel = await this.LoadObject(componentPath);

        let component = componentModel.scene;

        if (this.modelFrame.children.length > 0) {
          let frame = this.modelFrame.children[
            this.modelFrame.children.length - 1
          ];

          let componentR = frame.getObjectByName("component_r");

          componentR.getWorldQuaternion(component.quaternion);
          component.updateMatrixWorld();

          let componentL = component.getObjectByName("component_l");

          component.rotateX(-componentL.rotation.x);
          component.rotateY(-componentL.rotation.y);
          component.rotateZ(-componentL.rotation.z);

          component.updateMatrixWorld();

          //Position
          let componentRWP = new Vector3();
          componentR.getWorldPosition(componentRWP);
          let componentLWP = new Vector3();
          componentL.getWorldPosition(componentLWP);

          component.position.add(componentRWP);
          component.position.add(componentLWP.negate());

          component.updateMatrixWorld();

          component.remove(componentL);
        }

        component.name = id;
        this.modelFrame.add(component);
        this.modelFrame.updateMatrixWorld();
      }
    } else {
      if (this.frameObject.recliner) {
        let mechanismPath = this.modelsPath + "mechanism_animation.gltf";
        let mechanismData = await this.LoadObject(mechanismPath);

        let mechanism = mechanismData.scene;
        let mechanismSocket = mechanism.getObjectByName("recliner_base");

        this.mechanismMixer = new AnimationMixer(mechanism);
        this.actions = [];

        mechanismData.animations.forEach((animation) => {
          let action = this.mechanismMixer.clipAction(animation);
          action.clampWhenFinished = true;
          action.setLoop(LoopOnce, 1);
          this.actions.push(action);
        });

        const frameArmPath = `frame-S-${this.armObject.id}.gltf`;

        let framePath = this.modelsPath + "frame-S.gltf";

        if (this.ModelExist(frameArmPath)) {
          framePath = this.modelsPath + frameArmPath;
        }

        if (this.frameObject.uvc) {
          framePath = this.modelsPath + "frame-S-449Z.gltf";
        }

        let frameData = await this.LoadObject(framePath);
        let frame = frameData.scene;

        this.frameMixer = new AnimationMixer(frame);

        frameData.animations.forEach((animation) => {
          let action = this.frameMixer.clipAction(animation);
          action.clampWhenFinished = true;
          action.setLoop(LoopOnce, 1);
          this.actions.push(action);
        });

        let padName = `${this.armObject.id}_pad`;
        let pads = ["G_pad", "H_pad", "M_pad", "R_pad", "T_pad"];

        pads.forEach((pad) => {
          if (pad !== padName) {
            mechanism.getObjectByName(pad).visible = false;
          }
        });

        mechanismSocket.add(frame);
        this.modelFrame.add(mechanism);
        this.modelFrame.updateMatrixWorld();

        this.modelFrame.add(this.modelPowerOptions);

        if (this.powerOptionObject)
          await this.LoadPowerOption(this.powerOptionObject);
      } else {
        const frameArmSeatPath =
          "frame-" +
          this.frameObject.id +
          "-" +
          this.seatObject.frame.id +
          "-" +
          this.armObject.type +
          ".gltf";

        const frameSeatPath =
          "frame-" +
          this.frameObject.id +
          "-" +
          this.seatObject.frame.id +
          ".gltf";

        let framePath =
          this.modelsPath + "frame-" + this.frameObject.id + ".gltf";

        if (this.ModelExist(frameArmSeatPath)) {
          framePath = this.modelsPath + frameArmSeatPath;
        } else if (this.ModelExist(frameSeatPath)) {
          framePath = this.modelsPath + frameSeatPath;
        }

        let frameData = await this.LoadObject(framePath);

        frameData.scene.name = this.frameObject.id;

        this.modelFrame.add(frameData.scene);
        this.modelFrame.updateMatrixWorld();
      }
    }

    await this.LoadArm(this.armObject);

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async LoadPowerOption(poweroptionobject, updateCameraPosition = false) {
    this.modelPowerOptions.children = [];

    this.powerOptionObject = poweroptionobject;

    let frame = this.modelFrame.children[0];

    if (frame === undefined) return;

    let frameMC_PL_PR = frame.getObjectByName("motion_controler_PL_PR", true);
    if (frameMC_PL_PR) frameMC_PL_PR.children = [];

    let frameMC_PB = frame.getObjectByName("motion_controler_PB", true);
    if (frameMC_PB) frameMC_PB.children = [];

    let frameMC_pocket = frame.getObjectByName("motion_controler_pocket", true);
    if (frameMC_pocket) frameMC_pocket.children = [];

    let pocketPath, pocketGLTF, pocket;

    switch (poweroptionobject.id) {
      case "MR":
      default:
        break;
      case "PR":
      case "PL":
        if (!frameMC_PL_PR) break;
        pocketPath = this.modelsPath + "remote_PL_PR.gltf";
        pocketGLTF = await this.LoadObject(pocketPath);
        pocket = pocketGLTF.scene;

        frameMC_PL_PR.add(this.modelPowerOptions);
        this.modelPowerOptions.add(pocket);
        pocket.updateMatrixWorld();
        break;
      case "PB":
        if (!frameMC_PB) break;
        pocketPath = this.modelsPath + "remote_PB.gltf";
        pocketGLTF = await this.LoadObject(pocketPath);
        pocket = pocketGLTF.scene;

        frameMC_PB.add(this.modelPowerOptions);
        this.modelPowerOptions.add(pocket);
        pocket.updateMatrixWorld();
        break;
      case "SR":
        if (!frameMC_pocket) break;

        if (this.ModelExist(`remote_pocket_${this.armObject.id}.gltf`)) {
          pocketPath = `${this.modelsPath}remote_pocket_${this.armObject.id}.gltf`;
        } else {
          pocketPath = this.modelsPath + "remote_pocket.gltf";
        }

        pocketGLTF = await this.LoadObject(pocketPath);
        pocket = pocketGLTF.scene;

        frameMC_pocket.add(this.modelPowerOptions);
        this.modelPowerOptions.add(pocket);
        pocket.updateMatrixWorld();
        break;
    }

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async LoadArm(armobject, updateCameraPosition = false) {
    this.modelArm.children = [];

    if (this.frameObject.recliner) {
      if (this.armObject.id !== armobject.id) {
        this.armObject = armobject;
        await this.LoadFrame(this.frameObject, true);
      } else {
        if (this.accessoryLeftObject.id)
          await this.LoadAccessory(this.accessoryLeftObject, "l");
        if (this.accessoryRightObject.id)
          await this.LoadAccessory(this.accessoryRightObject, "r");
      }
      return;
    }

    if (this.armObject.type !== armobject.type) {
      this.armObject = armobject;
      await this.LoadFrame(this.frameObject, updateCameraPosition);
      return;
    }

    this.armObject = armobject;

    for (const frame of this.modelFrame.children) {
      if (frame.getObjectByName("side_l")) {
        let armName = "side-" + this.armObject.id;
        let customName = "side-" + this.armObject.id + "-" + frame.name;

        if (this.ModelExist(customName + "-left.gltf")) {
          armName = customName;
        }

        let leftArm = await this.LoadObject(
          this.modelsPath + armName + "-left.gltf"
        );
        let leftSide = leftArm.scene;

        leftSide.rotation.copy(frame.rotation);
        leftSide.updateMatrixWorld();

        let offsetL = new Vector3();
        leftSide
          .getObjectByName("sockets")
          .getObjectByName("side_r")
          .getWorldPosition(offsetL);

        let frameSocketPositionL = new Vector3();
        frame
          .getObjectByName("sockets")
          .getObjectByName("side_l")
          .getWorldPosition(frameSocketPositionL);

        leftSide.position.copy(frameSocketPositionL);
        leftSide.position.add(offsetL.negate());

        leftSide.updateMatrixWorld();

        leftSide.name = "model-side-l";
        this.modelArm.add(leftSide);
      }

      if (frame.getObjectByName("side_r")) {
        let armName = "side-" + this.armObject.id;
        let customName = "side-" + this.armObject.id + "-" + frame.name;

        if (this.ModelExist(this.modelsPath + customName + "-right.gltf")) {
          armName = customName;
        }

        let rightArm = await this.LoadObject(
          this.modelsPath + armName + "-right.gltf"
        );
        let rightSide = rightArm.scene;

        rightSide.rotation.copy(frame.rotation);
        rightSide.updateMatrixWorld();

        let offsetR = new Vector3();
        rightSide
          .getObjectByName("sockets")
          .getObjectByName("side_r")
          .getWorldPosition(offsetR);

        let frameSocketPositionR = new Vector3();
        frame
          .getObjectByName("sockets")
          .getObjectByName("side_r")
          .getWorldPosition(frameSocketPositionR);

        rightSide.position.copy(frameSocketPositionR);
        rightSide.position.add(offsetR.negate());

        rightSide.updateMatrixWorld();

        rightSide.name = "model-side-r";
        this.modelArm.add(rightSide);
      }
    }

    await this.ShowFramePins(
      this.framePin,
      `${themeConfiguration.model_url}/3d/textures/nail_finish/nail_small`,
      "Small"
    );
    await this.ShowSidePins(
      this.sidePin,
      `${themeConfiguration.model_url}/3d/textures/nail_finish/nail_small`,
      "Small"
    );

    await this.LoadSeat(this.seatObject);
    await this.LoadBase(this.baseObject);
    await this.LoadCushion();

    await this.LoadAccessory(this.accessoryLeftObject, "l");
    await this.LoadAccessory(this.accessoryRightObject, "r");

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async LoadSeat(seatobject, updateCameraPosition = false) {
    this.modelSeat.children = [];

    if (this.frameObject.recliner) return;

    if (this.seatObject.frame.id !== seatobject.frame.id) {
      this.seatObject = seatobject;
      await this.LoadFrame(this.frameObject, updateCameraPosition);
      return;
    }

    this.seatObject = seatobject;

    for (const frame of this.modelFrame.children) {
      let seatPath;
      if (
        this.ModelExist(
          "seat-" + frame.name + "-" + this.seatObject.seat.id + ".gltf"
        )
      ) {
        seatPath =
          this.modelsPath +
          "seat-" +
          frame.name +
          "-" +
          this.seatObject.seat.id +
          ".gltf";
      } else if (this.ModelExist("seat-" + frame.name + ".gltf")) {
        seatPath = this.modelsPath + "seat-" + frame.name + ".gltf";
      }

      var seatLoader = await this.LoadObject(seatPath);
      let seat = seatLoader.scene;

      frame
        .getObjectByName("sockets")
        .getObjectByName("seat_pillow")
        .getWorldQuaternion(seat.quaternion);

      seat.updateMatrixWorld();

      let offset = new Vector3();
      seat
        .getObjectByName("sockets")
        .getObjectByName("seat_pillow")
        .getWorldPosition(offset);

      let frameSocketPosition = new Vector3();
      frame
        .getObjectByName("sockets")
        .getObjectByName("seat_pillow")
        .getWorldPosition(frameSocketPosition);

      seat.position.copy(frameSocketPosition);
      seat.position.add(offset.negate());

      seat.updateMatrixWorld();

      seat.name = "model-seat";
      this.modelSeat.add(seat);
    }

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async LoadBase(baseobject, updateCameraPosition = false) {
    this.modelBase.children = [];

    if (this.frameObject.recliner) return;

    if (baseobject.id !== this.baseObject.id) {
      this.baseObject = baseobject;
      await this.LoadFrame(this.frameObject, updateCameraPosition);
      return;
    }

    let basePath;

    if (this.baseObject.type === "leg") {
      basePath = this.modelsPath + "leg-" + this.baseObject.id + ".gltf";

      let legLoader = await this.LoadObject(basePath);
      let leg = legLoader.scene;

      if (this.baseObject.material) {
        leg.traverse((o) => {
          if (o.isMesh) {
            if (o.material) {
              o.material.color = new Color(baseobject.material.color);
              o.material.metalness = baseobject.material.metalness;
              o.material.roughness = baseobject.material.roughness;
            }
          }
        });
      }

      for (const arm of this.modelArm.children) {
        let localScale = arm.scale;
        let sockets = arm.getObjectByName("sockets");
        for (const socket of sockets.children) {
          if (socket.name.startsWith("leg")) {
            let newLeg = leg.clone();

            newLeg.scale.set(localScale.x, localScale.y, localScale.z);

            socket.getWorldQuaternion(newLeg.quaternion);
            newLeg.updateMatrixWorld();

            let offset = newLeg
              .getObjectByName("sockets")
              .getObjectByName("leg_b").position;

            socket.getWorldPosition(newLeg.position);

            newLeg.position.add(offset.negate());
            newLeg.updateMatrixWorld();

            this.modelBase.add(newLeg);
          }
        }
      }
      for (const frame of this.modelFrame.children) {
        let localScale = frame.scale;
        let sockets = frame.getObjectByName("sockets");
        for (const socket of sockets.children) {
          if (socket.name.startsWith("leg")) {
            let newLeg = leg.clone();

            newLeg.scale.set(localScale.x, localScale.y, localScale.z);

            socket.getWorldQuaternion(newLeg.quaternion);
            newLeg.updateMatrixWorld();

            let offset = newLeg
              .getObjectByName("sockets")
              .getObjectByName("leg_b").position;

            socket.getWorldPosition(newLeg.position);

            newLeg.position.add(offset.negate());
            newLeg.updateMatrixWorld();

            this.modelBase.add(newLeg);
          }
        }
      }
    } else if (this.baseObject.type === "skirt") {
      for (const frame of this.modelFrame.children) {
        let customPath =
          "leg-" +
          this.baseObject.id +
          "-" +
          frame.name +
          "-" +
          this.armObject.id +
          ".gltf";
        if (this.ModelExist(customPath)) {
          basePath = this.modelsPath + customPath;
        } else {
          basePath =
            this.modelsPath +
            "leg-" +
            this.baseObject.id +
            "-" +
            frame.name +
            ".gltf";
        }

        let skirtLoader = await this.LoadObject(basePath);
        let skirt = skirtLoader.scene;

        skirt.rotation.copy(frame.rotation);
        skirt.updateMatrixWorld();

        let offset = new Vector3();
        skirt
          .getObjectByName("sockets")
          .getObjectByName("skirt_base")
          .getWorldPosition(offset);

        let frameSkirtSocket = new Vector3();
        frame
          .getObjectByName("sockets")
          .getObjectByName("skirt_base")
          .getWorldPosition(frameSkirtSocket);

        skirt.position.copy(frameSkirtSocket);
        skirt.position.add(offset.negate());

        skirt.updateMatrixWorld();

        skirt.name = "model-skirt";
        this.modelBase.add(skirt);
      }
    }

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async LoadCushion() {
    this.modelCushion.children = [];

    if (this.frameObject.recliner) return;

    if (!this.cushionEnabled) {
      return;
    }

    for (const frame of this.modelFrame.children) {
      if (frame.getObjectByName("cushion_l")) {
        let cushionPath = this.modelsPath + "cushion-left.gltf";
        let cushionLoader = await this.LoadObject(cushionPath);

        let cushion = cushionLoader.scene;

        frame
          .getObjectByName("sockets")
          .getObjectByName("cushion_l")
          .getWorldQuaternion(cushion.quaternion);
        cushion.updateMatrixWorld();

        let offset = new Vector3();
        cushion
          .getObjectByName("sockets")
          .getObjectByName("cushion_base")
          .getWorldPosition(offset);

        let frameSocketPosition = new Vector3();
        frame
          .getObjectByName("sockets")
          .getObjectByName("cushion_l")
          .getWorldPosition(frameSocketPosition);

        cushion.position.copy(frameSocketPosition);
        cushion.position.add(offset.negate());

        cushion.updateMatrixWorld();
        this.modelCushion.add(cushion);
      }

      if (frame.getObjectByName("cushion_r")) {
        let cushionPath = this.modelsPath + "cushion-right.gltf";
        let cushionLoader = await this.LoadObject(cushionPath);

        let cushion = cushionLoader.scene;

        frame
          .getObjectByName("sockets")
          .getObjectByName("cushion_r")
          .getWorldQuaternion(cushion.quaternion);
        cushion.updateMatrixWorld();

        let offset = new Vector3();
        cushion
          .getObjectByName("sockets")
          .getObjectByName("cushion_base")
          .getWorldPosition(offset);

        let frameSocketPosition = new Vector3();
        frame
          .getObjectByName("sockets")
          .getObjectByName("cushion_r")
          .getWorldPosition(frameSocketPosition);

        cushion.position.copy(frameSocketPosition);
        cushion.position.add(offset.negate());

        cushion.updateMatrixWorld();
        this.modelCushion.add(cushion);
      }
    }
  }

  async Recline(animationSpeed = 1, fadeOutSpeed = 1) {
    if (this.mechanismActiveAction) {
      this.mechanismActiveAction.fadeOut(fadeOutSpeed);
    }

    if (this.frameActiveAction) {
      this.frameActiveAction.fadeOut(fadeOutSpeed);
    }

    if (this.mechanismActiveAction === this.actions[1]) {
      this.mechanismActiveAction = null;
      return;
    }

    this.mechanismActiveAction = this.actions[1];
    this.mechanismActiveAction
      .reset()
      .setEffectiveTimeScale(animationSpeed)
      .setEffectiveWeight(1)
      .play();
    this.frameActiveAction = this.actions[3];
    this.frameActiveAction
      .reset()
      .setEffectiveTimeScale(animationSpeed)
      .setEffectiveWeight(1)
      .play();
  }

  async Lift(animationSpeed = 1, fadeOutSpeed = 1) {
    if (this.mechanismActiveAction) {
      this.mechanismActiveAction.fadeOut(fadeOutSpeed);
    }

    if (this.frameActiveAction) {
      this.frameActiveAction.fadeOut(fadeOutSpeed);
    }

    if (this.mechanismActiveAction === this.actions[0]) {
      this.mechanismActiveAction = null;
      return;
    }

    this.mechanismActiveAction = this.actions[0];

    if (this.mechanismActiveAction) {
      this.mechanismActiveAction
        .reset()
        .setEffectiveTimeScale(animationSpeed)
        .setEffectiveWeight(1)
        .play();
    }
  }

  LoadTexture(url) {
    return new Promise((resolve) => {
      this.textureLoader.load(url, (result) => resolve(result));
    });
  }

  async LoadFinish(
    type,
    map,
    icon,
    name,
    repeat = 1,
    roughness,
    metalness,
    updateCameraPosition = false
  ) {
    if (type === "main" && !this.init) {
      this.mainFabricCounter += 1;
    }
    if (type === "main" && this.mainFabricCounter > 1) {
      this.mainFabricCounter = 0;
      this.init = true;
      return;
    }
    let newMap = null;
    if (map) {
      var finishLoader = await this.LoadTexture(map);

      newMap = finishLoader;
      newMap.wrapS = RepeatWrapping;
      newMap.wrapT = RepeatWrapping;
      newMap.repeat.set(repeat, repeat);
    }

    let materialObject = {
      type: type,
      map: newMap,
      icon: icon,
      name: name,
      roughness: roughness || null,
      metalness: metalness || null,
    };

    let found = false;
    this.materials.forEach((material) => {
      if (material.type === type) {
        found = true;

        material.map = newMap;
        material.icon = icon;
        material.name = name;

        material.roughness = roughness || null;
        material.metalness = metalness || null;
      }
    });

    if (!found) {
      this.materials.push(materialObject);
    }

    this.updateMap(this.model);

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  SetAccessoriesVisible(newState) {
    this.accessoriesEnabled = newState;

    if (!this.accessoriesEnabled) {
      this.modelAccessoriesLeft.children = [];
      this.modelAccessoriesRight.children = [];
    }
  }

  async LoadAccessory(accessory, side, updateCameraPosition = false) {
    if (side === "l") {
      this.modelAccessoriesLeft.children = [];
      this.accessoryLeftObject = accessory;
    } else {
      this.modelAccessoriesRight.children = [];
      this.accessoryRightObject = accessory;
    }

    if (accessory === "X") {
      if (updateCameraPosition) await this.UpdateCameraPosition();
      return;
    }

    const baseAccessoryURL = this.modelsPath + "acc_base.gltf";

    if (!this.accessoriesEnabled) {
      return;
    }

    if (this.frameObject.recliner) {
      let frame = this.modelFrame.children[0];

      if (frame === undefined) return;

      let acc_base_left = frame.getObjectByName("acc_base");
      let acc_base_right = frame.getObjectByName("acc_base_1");

      acc_base_left.children = acc_base_right.children = [];

      let base = (await this.LoadObject(baseAccessoryURL)).scene;

      if (this.accessoryLeftObject.id) {
        let leftBase = base.clone();
        let leftAccessoryURL =
          this.modelsPath + this.accessoryLeftObject.id + ".gltf";
        let leftAccessory = (await this.LoadObject(leftAccessoryURL)).scene;
        leftBase.add(leftAccessory);
        let leftOffset = leftAccessory.getObjectByName("acc_model", true)
          .position;
        leftAccessory.position.add(leftOffset.negate());
        acc_base_left.add(leftBase);
      }

      if (this.accessoryRightObject.id) {
        let rightBase = base.clone();
        let rightAccessoryURL =
          this.modelsPath + this.accessoryRightObject.id + ".gltf";
        let rightAccessory = (await this.LoadObject(rightAccessoryURL)).scene;
        rightAccessory.scale.set(-1, 1, 1);
        rightBase.add(rightAccessory);
        let rightOffset = rightAccessory.getObjectByName("acc_model", true)
          .position;
        rightAccessory.position.add(rightOffset.negate());
        acc_base_right.add(rightBase);
      }
    } else {
      let armName = `model-side-${side}`;

      let baseSocket;

      for (const arm of this.modelArm.children) {
        if (arm.name === armName) {
          switch (armName) {
            case "model-side-l":
              baseSocket = arm.getObjectByName("acc_base");
              if (baseSocket && this.accessoryLeftObject.id) {
                this.loader.load(baseAccessoryURL, (baseGLTF) => {
                  let base = baseGLTF.scene;

                  baseSocket.getWorldPosition(base.position);
                  base.rotation.copy(arm.rotation);
                  base.updateMatrixWorld();

                  base.name = "accessory-base-l";

                  this.modelAccessoriesLeft.add(base);

                  let accessoryURL =
                    this.modelsPath + this.accessoryLeftObject.id + ".gltf";

                  this.loader.load(accessoryURL, (accessoryGLTF) => {
                    let accessory = accessoryGLTF.scene;

                    let accessorySocket = accessory
                      .getObjectByName("sockets")
                      .getObjectByName("acc_model");
                    let offset = accessorySocket.position;

                    accessory.position.copy(base.position);
                    accessory.position.add(offset.negate());

                    accessory.name = "accessory-l";

                    this.modelAccessoriesLeft.add(accessory);
                  });
                });
              }
              break;
            case "model-side-r":
              baseSocket = arm.getObjectByName("acc_base");
              if (baseSocket && this.accessoryRightObject.id) {
                this.loader.load(baseAccessoryURL, (baseGLTF) => {
                  let base = baseGLTF.scene;

                  baseSocket.getWorldPosition(base.position);
                  base.rotation.copy(arm.rotation);
                  base.updateMatrixWorld();

                  base.name = "accessory-base-r";

                  this.modelAccessoriesRight.add(base);

                  let accessoryURL =
                    this.modelsPath + this.accessoryRightObject.id + ".gltf";

                  this.loader.load(accessoryURL, (accessoryGLTF) => {
                    let accessory = accessoryGLTF.scene;

                    let accessorySocket = accessory
                      .getObjectByName("sockets")
                      .getObjectByName("acc_model");
                    let offset = accessorySocket.position;

                    accessory.position.copy(base.position);
                    accessory.position.add(offset.negate());

                    accessory.scale.set(-1, 1, 1);

                    accessory.name = "accessory-r";

                    this.modelAccessoriesRight.add(accessory);
                  });
                });
              }
              break;
            default:
              return;
          }
        }
      }
    }

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  setCushionsVisible(newState) {
    this.cushionEnabled = newState;
    this.LoadCushion();
  }

  setPlaneVisible(newState) {
    if (this.plane) {
      this.plane.visible = newState;
    }
  }

  setNailsVisible(newState) {
    this.nailsEnabled = newState;
    this.ShowFramePins(this.framePin);
    this.ShowSidePins(this.sidePin);
  }

  async setDimensions(productDimensions) {
    this.productDimensions = productDimensions;
  }

  setDimensionsVisible(newState) {
    this.dimensionsToggle = newState;

    this.HideDimensions();

    if (this.dimensionsToggle) {
      this.ShowDimensions();
    }
  }

  async HideDimensions() {
    this.modelDimensions.children = [];
  }

  async ShowDimensions() {
    await this.HideDimensions();

    let box = new Box3().setFromObject(this.model);

    let lineMaterial = new LineBasicMaterial({
      color: 0x000000,
    });

    let lineOffset = 0.02;

    let x = [];
    x.push(
      new Vector3(box.min.x, box.max.y + lineOffset, box.min.z - lineOffset)
    );
    x.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.min.z - lineOffset
      )
    );

    let y = [];
    y.push(
      new Vector3(box.max.x + lineOffset, box.min.y, box.max.z + lineOffset)
    );
    y.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.max.z + lineOffset
      )
    );

    let z = [];
    z.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.max.z + lineOffset
      )
    );
    z.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.min.z - lineOffset
      )
    );

    let geometryX = new BufferGeometry().setFromPoints(x);
    let geometryY = new BufferGeometry().setFromPoints(y);
    let geometryZ = new BufferGeometry().setFromPoints(z);

    let lineX = new Line(geometryX, lineMaterial);
    lineX.name = "lineX";
    let lineY = new Line(geometryY, lineMaterial);
    lineY.name = "lineY";
    let lineZ = new Line(geometryZ, lineMaterial);
    lineZ.name = "lineZ";

    lineX.computeLineDistances();

    this.modelDimensions.add(lineX);
    this.modelDimensions.add(lineY);
    this.modelDimensions.add(lineZ);

    let swapDimensions = false;

    let boxWidth = box.max.x - box.min.x;
    let boxDepth = box.max.z - box.min.z;

    if (
      (boxWidth < boxDepth &&
        Number(this.productDimensions.width) >
          Number(this.productDimensions.depth)) ||
      (boxWidth > boxDepth &&
        Number(this.productDimensions.width) <
          Number(this.productDimensions.depth))
    ) {
      swapDimensions = true;
    }

    let distanceX = swapDimensions
      ? this.productDimensions.depth
      : this.productDimensions.width;
    let distanceY = this.productDimensions.height;
    let distanceZ = swapDimensions
      ? this.productDimensions.width
      : this.productDimensions.depth;

    const fontLoader = new FontLoader();

    fontLoader.load(
      `${themeConfiguration.model_url}/3d/fonts/helvetiker_regular.typeface.json`,
      (font) => {
        const fontGeometryX = new TextGeometry(distanceX.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });

        fontGeometryX.translate(-0.1, 0, 0);

        let fontMeshX = new Mesh(
          fontGeometryX,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        let fontOffset = 0.02;

        fontMeshX.position.copy(
          new Vector3(
            box.min.x + (box.max.x - box.min.x) / 2,
            box.max.y + fontOffset + lineOffset,
            box.min.z
          )
        );
        fontMeshX.updateMatrixWorld();

        fontMeshX.name = "fontMeshX";
        this.modelDimensions.add(fontMeshX);

        const fontGeometryY = new TextGeometry(distanceY.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });

        fontGeometryY.translate(0.05, 0, 0);

        let fontMeshY = new Mesh(
          fontGeometryY,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        fontMeshY.rotateY(-Math.PI / 2);
        fontMeshY.position.copy(
          new Vector3(
            box.max.x,
            box.min.y + (box.max.y - box.min.y) / 2,
            box.max.z
          )
        );
        fontMeshY.updateMatrixWorld();

        fontMeshY.name = "fontMeshY";
        this.modelDimensions.add(fontMeshY);

        const fontGeometryZ = new TextGeometry(distanceZ.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });
        fontGeometryZ.translate(-0.05, 0, 0);

        let fontMeshZ = new Mesh(
          fontGeometryZ,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        fontMeshZ.rotateY(-Math.PI / 2);
        fontMeshZ.position.copy(
          new Vector3(
            box.max.x,
            box.max.y + fontOffset + lineOffset,
            box.min.z + (box.max.z - box.min.z) / 2
          )
        );
        fontMeshZ.updateMatrixWorld();

        fontMeshZ.name = "fontMeshZ";
        this.modelDimensions.add(fontMeshZ);
      }
    );
  }

  async ShowFramePins(pinsobject, pinImage, pinName) {
    this.framePin = pinsobject;
    for (const frame of this.modelFrame.children) {
      let smallPins = frame.getObjectByName("small_pins");
      let mediumPins = frame.getObjectByName("medium_pins");
      let largePins = frame.getObjectByName("large_pins");

      if (smallPins) smallPins.visible = false;
      if (mediumPins) mediumPins.visible = false;
      if (largePins) largePins.visible = false;

      if (frame.getObjectByName(pinsobject) && this.nailsEnabled)
        frame.getObjectByName(pinsobject).visible = true;
    }

    for (const arm of this.modelArm.children) {
      let smallPins = arm.getObjectByName("small_pins");
      let mediumPins = arm.getObjectByName("medium_pins");
      let largePins = arm.getObjectByName("large_pins");

      if (smallPins) smallPins.visible = false;
      if (mediumPins) mediumPins.visible = false;
      if (largePins) largePins.visible = false;

      if (arm.getObjectByName(pinsobject) && this.nailsEnabled)
        arm.getObjectByName(pinsobject).visible = true;
    }
  }

  async ShowSidePins(pinsobject, pinImage, pinName) {
    this.sidePin = pinsobject;
    for (const arm of this.modelArm.children) {
      let sidePins = arm.getObjectByName("side_pins");

      if (sidePins) sidePins.visible = false;

      if (arm.getObjectByName(pinsobject) && this.nailsEnabled)
        arm.getObjectByName(pinsobject).visible = true;
    }
  }

  async UpdateCameraPosition() {
    let box = new Box3().setFromObject(this.model);
    let size = box.getSize(new Vector3()).length();
    let center = box.getCenter(new Vector3());

    if (this.plane) {
      this.plane.position.y = box.min.y;
      this.shadowPlane.position.y = box.min.y;
    }

    this.controls.minDistance = size * 0.75;
    this.controls.maxDistance = size * 1.5;

    this.camera.position.copy(center);
    this.camera.position.x += 0; //this.size * 0.1;
    this.camera.position.y += size * 0.2;
    this.camera.position.z += size * 5.3;
    this.camera.updateMatrixWorld();

    this.controls.target = center;
    this.camera.lookAt(center);

    this.camera.updateProjectionMatrix();

    if (this.dimensionsToggle) {
      await this.ShowDimensions();
    }

    document.getElementById("loading-screen").classList.add("fade-out");
  }

  updateMap(object) {
    object.traverse((o) => {
      if (o.isMesh) {
        o.castShadow = true;
        o.receiveShadow = true;

        let found = false;
        this.materials.forEach((material) => {
          if (material.type === o.material.name) {
            found = true;
            if (material.map) o.material.map = material.map;
            if (material.roughness) o.material.roughness = material.roughness;
            if (material.metalness) o.material.metalness = material.metalness;
          }
        });

        if (!found) {
          const furnitureNames = [
            "main",
            "back_pillow",
            "seat_cushion",
            "contrast_welt",
          ];

          if (furnitureNames.includes(o.material.name)) {
            let mainMaterial = this.materials[0];

            if (mainMaterial) {
              if (mainMaterial.map) o.material.map = mainMaterial.map;
              if (mainMaterial.roughness)
                o.materials.roughness = mainMaterial.roughness;
              if (mainMaterial.metalness)
                o.material.metalness = mainMaterial.metalness;
            }
          }
        }
        if (this.envMap) {
          o.material.envMap = this.envMap;
          o.material.envMapIntensity = this.envMapIntensity;
        }

        if (o.material) {
          if (o.material.map) {
            o.material.map.encoding = sRGBEncoding;
          }

          if (o.material.ao) {
            o.material.ao.encoding = sRGBEncoding;
          }
        }

        o.material.needsUpdate = true;
      }
    });
  }

  degreesToRadians = (degrees) => {
    var pi = Math.PI;
    return degrees * (pi / 180);
  };

  determinCameraDistance = (modelSize) => {
    let cameraDistance;
    let halfFOVInRadians = this.degreesToRadians(
      (this.camera.fov * this.camera.aspect) / 4
    );
    let height = modelSize.height;
    cameraDistance = height / 2 / Math.tan(halfFOVInRadians);
    return cameraDistance;
  };

  updateZoom(zoom, diff = 0) {
    let newZoom = 1 + (zoom + diff) / 10;

    if (
      newZoom >= 1 + zoomOptions.min / 10 &&
      newZoom <= 1 + zoomOptions.max / 10
    ) {
      this.controls.object.zoom = newZoom;
      this.controls.object.updateProjectionMatrix();
      return {
        ...zoomOptions,
        current: zoom + diff,
      };
    } else {
      return { ...zoomOptions, current: zoom };
    }
  }

  getCameraAngle = () => {
    const euler = new Euler();
    const rotation = euler.setFromQuaternion(this.camera.quaternion);
    const radians = rotation._z > 0 ? rotation._z : 2 * Math.PI + rotation._z;
    return radians * (180 / Math.PI);
  };

  update() {
    requestAnimationFrame(this.update);
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    if (this.dimensionsToggle) {
      let fontMeshX = this.modelDimensions.getObjectByName("fontMeshX");
      let fontMeshY = this.modelDimensions.getObjectByName("fontMeshY");
      let fontMeshZ = this.modelDimensions.getObjectByName("fontMeshZ");
      fontMeshX && fontMeshX.lookAt(this.camera.position);
      fontMeshY && fontMeshY.lookAt(this.camera.position);
      fontMeshZ && fontMeshZ.lookAt(this.camera.position);
    }

    const delta = this.clock.getDelta();

    if (this.mechanismMixer) {
      this.mechanismMixer.update(delta);
    }

    if (this.frameMixer) {
      this.frameMixer.update(delta);
    }

    this.cameraAngle = this.getCameraAngle();
  }

  ModelExist(modelName) {
    return SeniorModels.includes(modelName);
  }

  getCameraPosition() {
    return {
      x: this.camera.position.x,
      y: this.camera.position.y,
      z: this.camera.position.z,
    };
  }
  setCameraPosition({ x = 50, y = -1, z = 50 }) {
    this.camera.position.x = x;
    this.camera.position.y = y;
    this.camera.position.z = z;
  }

  createBlob(input, type) {
    return new Promise((resolve) => {
      input = input || this.model;

      switch (type) {
        case "USDZ":
          this.exporter = new USDZExporter();
          this.exporter.parse(input).then((buffer) => {
            let blob = new Blob([buffer], {
              type: "application/octet-stream",
            });
            resolve(blob);
          });
          break;

        case "GLTF":
        default:
          let options = {
            trs: false,
            onlyVisible: true,
            trunctateDrawRange: false,
            binary: true,
            maxTextureSize: 2048,
          };
          this.exporter = new GLTFExporter();
          this.exporter.parse(
            input,
            (buffer) => {
              let blob = new Blob([buffer], {
                type: "application/octet-stream",
              });
              resolve(blob);
            },
            options
          );
          break;
      }
    });
  }

  async uploadBlob(name, blob) {
    try {
      return new Promise((resolve) => {
        let xmlhttp = new XMLHttpRequest();
        xmlhttp.open(
          "POST",
          `${themeConfiguration.magento_url}jola_ar/index/index?fileName=${name}`,
          true
        );

        xmlhttp.onload = function () {
          resolve(true);
        };
        xmlhttp.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xmlhttp.send(blob);
      });
    } catch (error) {}
  }

  createQR(input) {
    return new Promise((resolve) => {
      resolve(input);
    });
  }

  viewInRoom() {
    let copyModel;
    this.model.updateMatrixWorld();
    copyModel = this.model.clone();
    let idList = [];
    copyModel.traverse((o) => {
      if (!o.visible) {
        idList.push(o.id);
      }

      if (o.material) {
        o.material.aoMap = null;
        o.material.normalMap = null;
      }
    });
    idList.forEach((result) => {
      let test = copyModel.getObjectById(result);
      let parent = test.parent;
      parent.remove(test);
    });
    let uniqueHash = Date.now();
    let userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.match(/Android/i);
    const isApple = userAgent.match(/iPhone|iPad|iPod/i);

    if (isAndroid) {
      this.createBlob(copyModel, "GLTF").then((gltf) => {
        let glbName = uniqueHash.toString() + ".glb";

        this.uploadBlob(glbName, gltf).then(
          () =>
            (window.location.href = `${themeConfiguration.app_url}build-your-own/senior-living-solutions?file_name=${uniqueHash}&ar=true`)
        );
      });
    } else if (isApple) {
      this.createBlob(copyModel, "USDZ").then((usdz) => {
        let usdzName = uniqueHash.toString() + ".usdz";
        this.uploadBlob(usdzName, usdz).then(
          () =>
            (window.location.href = `${themeConfiguration.app_url}build-your-own/senior-living-solutions?file_name=${uniqueHash}&ar=true`)
        );
      });
    } else {
      this.createBlob(copyModel, "GLTF").then((gltf) => {
        let glbName = uniqueHash.toString() + ".glb";
        this.uploadBlob(glbName, gltf).then(() => {
          this.createBlob(copyModel, "USDZ").then((usdz) => {
            let usdzName = uniqueHash.toString() + ".usdz";
            this.uploadBlob(usdzName, usdz).then(() => {
              this.createQR(
                `${themeConfiguration.app_url}build-your-own/senior-living-solutions?file_name=${uniqueHash}&ar=true`
              ).then((url) => {
                copyModel.clear();
                return url;
              });
            });
          });
        });
      });
    }
    return uniqueHash;
  }
}
