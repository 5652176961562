import React, { useState } from "react";
import { MDBBtn, MDBBox } from "mdbreact";
import { useWishlist } from "core/state/hooks/wishlistHooks/wishlistHookAddRemove";
const WishlistItemsInner = (props) => {
  const [, , , , moveItemToWishlist, , , , , ,] = useWishlist();

  const [loading, setLoading] = useState(false);
  let { activeWishlistName, wishlistInner, item } = props;
  return (
    <MDBBox className="d-flex justify-content-between flex-wrap align-items-center">
      <MDBBox className="move-inner select">
        <MDBBtn
          className="wishlist-btn"
          onClick={(e) => {
            let wishlistMoveItems = {
              fromWishlist: activeWishlistName, //ActiveWishlist
              toWishlist: wishlistInner.name, //Selectovane wishliste
              input: [{ sku: item.product.sku }], //Item
            };
            setLoading(true);
            moveItemToWishlist(wishlistMoveItems).then(setLoading(false));
          }}
        >
          {loading ? "Moving..." : "Move to"}
        </MDBBtn>
      </MDBBox>
      <MDBBox className="move-inner title">
        <h4>{wishlistInner.name}</h4>
      </MDBBox>
    </MDBBox>
  );
};

export default WishlistItemsInner;
