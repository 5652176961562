const CHANGE_SEARCH = "jola/search/CHANGE_SEARCH";
const TOGGLE_SEARCH = "jola/search/TOGGLE_SEARCH";
const SAVE_SEARCH = "jola/search/SAVE_SEARCH";
const LOAD_MORE_ITEMS = "jola/search/LOAD_MORE_ITEMS";
export default {
  CHANGE_SEARCH,
  TOGGLE_SEARCH,
  SAVE_SEARCH,
  LOAD_MORE_ITEMS
};
