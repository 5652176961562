import React, { useEffect } from "react";
import gql from "graphql-tag";
import FrequentlyAskedQuestions from "../frequentlyAskedQuestions";

export const getNewsQuery = gql`
  query {
    getNewsInfo {
      news {
        content
        entity_id
        title
      }
      unread_news_count
      unread_news_ids
    }
  }
`;

export const SaveReadNews = gql`
  mutation {
    saveReadNews {
      unread_news_count
      unread_news_ids
    }
  }
`;

function SiteUpdates() {
  return <FrequentlyAskedQuestions query={getNewsQuery} />;
}

export default SiteUpdates;
