import gql from "graphql-tag";
export const DATA = gql`
  query getTextileAllianceData($input: GradeFilters) {
    getTextileAllianceData(input: $input) {
      page_size
      page_number
      last_page_number
      result_count
      data {
        fabric_vendor
        fabric_pattern
        fabric_color
        fairfield_grade
        railroad
      }
    }
  }
`;
export const GRADES = gql`
  query getTextileAllianceGrades($input: SearchGradeFilters) {
    getTextileAllianceGrades(input: $input) {
      page_size
      page_number
      grades
    }
  }
`;
export const GRADES_ALL = gql`
  {
    getTextileAllianceGrades {
      grades
    }
  }
`;
export const VENDORS = gql`
  {
    getTextileAllianceVendors {
      vendors
    }
  }
`;

export const LOGODATA = gql`
  {
    getTextileAllianceLogoData {
      vendor
      logo_file
      website
      pdf
    }
  }
`;
