import React, { useMemo, useState } from "react";
import Button from "core/components/button";
import InputField from "core/components/inputField";
import { MDBBox, MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { Redirect, useLocation } from "react-router";
import { shouldMarkError, validate } from "./validation";
import Login from "app/pages/customer/login";
import { ModalConsumer } from "core/components/modal/ModalContext";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomerToken } from "app/state/redux/data/customer/selectors";
import apolloClient from "core/graphql/apolloClient";
import queryLoader from "app/graphql/queryLoader";
import { messagesActions } from "core/state/redux/data/messages";
import { useHistory } from "react-router-dom";

const ForgotPassword = (props) => {
  const tokenExists = useSelector(selectCustomerToken);
  const history = useHistory();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const emailLocation = search?.split("=")?.[1];
  let rest = {};
  let { location } = props;
  const [loading, setLoading] = useState(false);
  let token =
    location.search !== "" && location.search.includes("token")
      ? location.search.split("=")[1]
      : "";
  const [email, setEmail] = useState(
    emailLocation !== null ? emailLocation : ""
  );
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [touched, setTouched] = useState({ email: false, password: false });

  // this will determine which step is selected
  const [passwordResetSent, setPasswordResetSent] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const errors = useMemo(() => validate(email, password, confirmPassword), [
    email,
    password,
    confirmPassword,
  ]);

  const emailError = useMemo(() => shouldMarkError("email", errors, touched), [
    errors,
    touched,
  ]);

  React.useEffect(() => {
    if (document.querySelector(".close-icon"))
      document.querySelector(".close-icon").click();
  }, []);

  const setEmailFn = (e) => {
    setTouched((prevTouched) =>
      !prevTouched.email ? { ...prevTouched, email: true } : prevTouched
    );
    setEmail(e.target.value);
  };
  const setPasswordFn = (e) => setPassword(e.target.value);
  const setConfirmPasswordFn = (e) => setConfirmPassword(e.target.value);
  const [passwordCheck] = useState(false);
  const resetPassword = async (request) => {
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("resetCustomerPassword"),
      variables: request,
    });
    if (data !== undefined) {
      if (data?.resetPasswordJola?.code === "error") {
        dispatch(messagesActions.addMessage(data?.resetPasswordJola?.message));
        setLoading(false);
      } else {
        setLoading(false);
        setPasswordUpdated(true);
      }
    }
  };
  const validateInput = () => {
    let isTrue = true;
    setTouched({
      password: true,
      confirm_password: true,
      email: true,
    });
    Object.keys(errors).forEach((item) => {
      let error = errors[item];
      if (item === "email") {
        error = false;
      }
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
    });

    if (isTrue) {
      setLoading(true);
      props.lock({
        always: () => {
          setLoading(true);
        },
        success: () => {
          setLoading(false);
          setPasswordUpdated(true);
        },
        fail: () => {
          setLoading(false);
          //change this when we fix BE issue to false
          setPasswordUpdated(true);
        },
      });
      resetPassword({
        input: { token, password, password_confirm: confirmPassword, email },
      });
    }
  };
  if (tokenExists) return <Redirect to={"/"} />;

  return (
    <>
      <MDBBox className={"forgot-password-page-wrapper"}>
        {!token && !passwordResetSent ? (
          <>
            <MDBContainer className={"page-main-content forgot-password first"}>
              <MDBRow>
                <MDBCol sm="8" xl="12">
                  <MDBBox
                    tag="h2"
                    className="display-1 text-gray-darken-3 py-5"
                  >
                    Forgot Your Password?
                  </MDBBox>
                  <MDBBox tag="p" className="display-3 text-default ">
                    Please enter your email address below to receive a password
                    reset.
                  </MDBBox>
                </MDBCol>
              </MDBRow>
              <MDBRow className="input-btn-content my-5">
                <MDBCol sm="12" className="input-field-content mt-3">
                  <MDBRow>
                    <MDBCol sm="8" xl="6">
                      <InputField
                        hint="email@email.com"
                        className={`custom-input ${
                          emailError ? "validation-error" : ""
                        }`}
                        label={`Email*`}
                        onBlur={(e) => {
                          setTouched({ ...touched, email: true });
                        }}
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                        value={email !== undefined ? email : ""}
                        onChange={setEmailFn}
                      />
                      {emailError ? (
                        email.length === 0 ? (
                          <div className="text-red position-absolute">
                            Email is required.
                          </div>
                        ) : (
                          <div className="text-red position-absolute">
                            Email is invalid.
                          </div>
                        )
                      ) : (
                        <></>
                      )}
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol className="mt-5">
                  <MDBRow>
                    {/* <MDBCol sm="4" xl="3" className="recaptcha">
                      Here goes recaptcha
                    </MDBCol> */}
                    <MDBCol sm="4" xl="3" className="clickable-button">
                      <Button
                        color="default"
                        className={"btn-default btn-with-icon"}
                        direction={"mr-2"}
                        disabled={
                          (!touched["email"] && email === "") || emailError
                        }
                        children={
                          <span>{`${
                            loading ? "Sending..." : "Reset My Password"
                          }`}</span>
                        }
                        onClick={(e) => {
                          setLoading(true);
                          props.lock({
                            always: () => {
                              setLoading(true);
                            },
                            success: () => {
                              setLoading(false);
                              setPasswordResetSent(true);
                            },
                            fail: () => {
                              setLoading(false);
                              setPasswordResetSent(false);
                            },
                          });
                          props.onForgotPassword(email);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            <MDBRow className={"w-100 spacer-first"}></MDBRow>
          </>
        ) : !token && passwordResetSent ? (
          <>
            <MDBContainer
              className={"page-main-content forgot-password second"}
            >
              <MDBRow>
                <MDBCol sm="8" xl="6">
                  <MDBBox
                    tag="h2"
                    className="display-1 text-gray-darken-3 py-5"
                  >
                    Password Reset
                  </MDBBox>
                  <MDBBox tag="p" className="display-3 text-default pb-5">
                    You should receive a link in few moments. Please open that
                    link to reset your password.
                  </MDBBox>
                  <MDBBox tag={"h5"} className={`display-3 text-primary mb-5`}>
                    {email}
                  </MDBBox>
                </MDBCol>
              </MDBRow>
              <MDBRow className="input-btn-content">
                <MDBCol sm="8" xl="6">
                  <MDBBox className={`d-flex align-items-center pl-4 pr-4`}>
                    <ModalConsumer>
                      {({ showModal, hideModal }) => {
                        return (
                          <Button
                            color="default"
                            className={"btn-default btn-with-icon"}
                            direction={"mr-2"}
                            children={<span>Back to login</span>}
                            onClick={(/** @type {React.MouseEvent} */ e) => {
                              e.preventDefault();
                              // @ts-ignore
                              showModal(() => {
                                return (
                                  <Login {...rest} hideModal={hideModal} />
                                );
                              });
                            }}
                          />
                        );
                      }}
                    </ModalConsumer>
                  </MDBBox>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            <MDBRow className={"w-100 spacer-second"}></MDBRow>
          </>
        ) : token && !passwordUpdated ? (
          <>
            <MDBContainer className={"page-main-content forgot-password third"}>
              <MDBRow className="py-4">
                <MDBCol sm="8" xl="12">
                  <MDBBox
                    tag="h2"
                    className="display-1 text-gray-darken-3 py-5"
                  >
                    {history.location.pathname.includes("forgot")
                      ? "Update password"
                      : "Create password"}
                  </MDBBox>
                </MDBCol>
              </MDBRow>
              <MDBRow className="input-btn-content">
                <MDBCol sm="12" className="input-field-content">
                  <MDBRow>
                    <MDBCol sm="8" xl="6">
                      <MDBRow>
                        <MDBCol>
                          <InputField
                            hint={"New Password*"}
                            className={`custom-input ${
                              shouldMarkError("password", errors, touched) ||
                              passwordCheck
                                ? "validation-error"
                                : ""
                            }`}
                            // label={`${
                            //   shouldMarkError("password", errors, touched) ||
                            //   passwordCheck
                            //     ? password.length === 0
                            //       ? "Password is required*"
                            //       : "Password is invalid*"
                            //     : "Password*"
                            // }`}
                            label={`Password*`}
                            group
                            type="password"
                            validate
                            error="wrong"
                            success="right"
                            onChange={setPasswordFn}
                            onBlur={(e) => {
                              setTouched({ ...touched, password: true });
                            }}
                            value={password !== undefined ? password : ""}
                          />
                          {(password.length > 0 && password.length < 10 && (
                            <div className={`text-red`}>
                              password must be at least 10 characters
                            </div>
                          )) ||
                            (password.length >= 10 &&
                              errors.password === true && (
                                <div className={`text-red`}>
                                  Password must include 3 different classes of
                                  characters: lower case, upper case, digits,
                                  and/or special characters
                                </div>
                              )) || <></>}
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className={`mt-5 mb-5`}>
                        <MDBCol>
                          <InputField
                            hint={"Confirm Password*"}
                            className={`custom-input ${
                              shouldMarkError(
                                "confirm_password",
                                errors,
                                touched
                              )
                                ? "validation-error"
                                : ""
                            }`}
                            label={`Confirm password*`}
                            onBlur={(e) =>
                              setTouched({
                                ...touched,
                                confirm_password: true,
                              })
                            }
                            group
                            type="password"
                            validate
                            error="wrong"
                            success="right"
                            value={
                              confirmPassword !== undefined
                                ? confirmPassword
                                : ""
                            }
                            onChange={setConfirmPasswordFn}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="mb-5">
                        <MDBCol>
                          <InputField
                            hint="email@email.com"
                            className={`custom-input ${
                              emailError ? "validation-error" : ""
                            }`}
                            label={`Email*`}
                            onBlur={(e) =>
                              setTouched({ ...touched, email: true })
                            }
                            group
                            type="email"
                            validate
                            error="wrong"
                            success="right"
                            onChange={setEmailFn}
                          />
                          {emailError ? (
                            email.length === 0 ? (
                              <div className="text-red position-absolute">
                                Email is required.
                              </div>
                            ) : (
                              <div className="text-red position-absolute">
                                Email is invalid.
                              </div>
                            )
                          ) : (
                            <></>
                          )}
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol sm="12" className="clickable-button">
                  <MDBRow>
                    <MDBCol sm="8" xl="12">
                      <Button
                        className={"btn-with-icon"}
                        direction={"mr-4"}
                        children={
                          <span>{`${
                            loading
                              ? "reseting..."
                              : `${
                                  history.location.pathname.includes("forgot")
                                    ? "update password"
                                    : "Set password"
                                }`
                          }`}</span>
                        }
                        onClick={(e) => {
                          if (confirmPassword !== password) {
                            dispatch(
                              messagesActions.addMessage(
                                "Password does not match",
                                "danger"
                              )
                            );
                          }
                          validateInput();
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            <MDBRow className={"w-100 spacer-third"}></MDBRow>
          </>
        ) : (
          token &&
          passwordUpdated && (
            <>
              <MDBContainer
                className={"page-main-content forgot-password second"}
              >
                <MDBRow>
                  <MDBCol sm="8" xl="6">
                    <MDBBox
                      tag="h2"
                      className="display-1 text-gray-darken-3 py-5"
                    >
                      Success!
                    </MDBBox>
                    <MDBBox tag="p" className="display-3 text-default pb-5">
                      {history.location.pathname.includes("forgot")
                        ? "Your Password has been successfully changed."
                        : "Your Password has been successfully created."}
                    </MDBBox>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="input-btn-content">
                  <MDBCol sm="8" xl="3">
                    <MDBBox className={`d-flex align-items-center`}>
                      <Button
                        color="default"
                        className={"btn-default btn-with-icon"}
                        direction={"mr-2"}
                        children={<span>Home</span>}
                        to={"/"}
                      />
                    </MDBBox>
                  </MDBCol>
                  <MDBCol sm="8" xl="3">
                    <MDBBox className={`d-flex align-items-center`}>
                      <ModalConsumer>
                        {({ showModal, hideModal }) => {
                          return (
                            <Button
                              color="default"
                              className={"btn-default btn-with-icon"}
                              direction={"mr-2"}
                              children={<span>Back to login</span>}
                              onClick={(/** @type {React.MouseEvent} */ e) => {
                                e.preventDefault();
                                // @ts-ignore
                                showModal(() => {
                                  return (
                                    <Login {...rest} hideModal={hideModal} />
                                  );
                                });
                              }}
                            />
                          );
                        }}
                      </ModalConsumer>
                    </MDBBox>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
              <MDBRow className={"w-100 spacer-second"}></MDBRow>
            </>
          )
        )}
      </MDBBox>
    </>
  );
};

export default ForgotPassword;
