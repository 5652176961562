import { messagesActions } from "core/state/redux/data/messages";
import { useDispatch } from "react-redux";
import { CONTACT_US } from "./query";
import apolloClient from "core/graphql/apolloClient";
import { commonActions } from "core/state/redux/data/common";

const useContactUs = () => {
  const dispatch = useDispatch();

  const contact = async (
    company_name,
    customer_email,
    customer_type,
    existing_customer,
    first_name,
    last_name,
    message,
    subject,
    type_of_inquiry,
    zip_code
  ) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: CONTACT_US,
        variables: {
          company_name: company_name,
          customer_email: customer_email,
          customer_type: customer_type,
          existing_customer: existing_customer,
          first_name: first_name,
          last_name: last_name,
          message: message,
          subject: subject,
          type_of_inquiry: type_of_inquiry,
          zip_code: zip_code,
        },
      });
      if (data) {
        dispatch(messagesActions.addMessage("Contact Mail Sent", "success"));
        dispatch(commonActions.unlock("success"));
      }
      return data;
    } catch (err) {
      dispatch(messagesActions.addMessage("Please fill form", "danger"));
      dispatch(commonActions.unlock("fail"));
    }
  };
  return { contact };
};

export { useContactUs };
