// Add a new query
export default (state, action) => {
  let currentQueries = state.items ? state.items : {};
  let newQueries = { ...currentQueries };

  if (action.variables.id) {
    newQueries[action.variables.id] = {
      query: action.query,
      variables: action.variables,
      type: action.queryType
    };
  } else if (action.variables.url) {
    newQueries[action.variables.url] = {
      query: action.query,
      variables: action.variables,
      type: action.queryType
    };
  }

  return Object.assign({}, state, { items: newQueries });
};
