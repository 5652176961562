import CardImage from "app/layout/cardImage";
import CmsBlock from "app/layout/cmsBlock";
import { Collapse } from "app/layout/Collapse";
import React, { useState } from "react";
import Careers from "./careers";

const CareersDataContainer = (props) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [tabCat, setTabCat] = useState("All");
  let tabs = [
    {
      name: <CmsBlock id="careers_all_name" />,
      items: [
        <Collapse
          key="careers_sofa_title"
          className="my-2"
          title={<CmsBlock id="careers_sofa_title" />}
        >
          <CardImage
            className="card-image"
            imageLinkText={"Read More"}
            imageLinkLessText={"Read Less"}
            imageDescription={<CmsBlock id="careers_sofa_description" />}
            useCmsBlockImage={true}
          />
        </Collapse>,
        <Collapse
          key="careers_human_title"
          className="my-2"
          title={<CmsBlock id="careers_human_title" />}
        >
          <CardImage
            className="card-image"
            imageLinkText={"Read More"}
            imageLinkLessText={"Read Less"}
            imageDescription={<CmsBlock id="careers_human_description" />}
            useCmsBlockImage={true}
          />
        </Collapse>,
        <Collapse
          key="careers_sewers_title"
          className="my-2"
          title={<CmsBlock id="careers_sewers_title" />}
        >
          <CardImage
            className="card-image"
            imageLinkText={"Read More"}
            imageLinkLessText={"Read Less"}
            imageDescription={<CmsBlock id="careers_sewers_description" />}
            useCmsBlockImage={true}
          />
        </Collapse>,
        <Collapse
          key="careers_job_title_name_title"
          className="my-2"
          title={<CmsBlock id="careers_job_title_name_title" />}
        >
          <CardImage
            className="card-image"
            imageLinkText={"Read More"}
            imageLinkLessText={"Read Less"}
            imageDescription={
              <CmsBlock id="careers_job_title_name_description" />
            }
            useCmsBlockImage={true}
          />
        </Collapse>,
      ],
    },
    {
      name: <CmsBlock id="careers_corporate_name" />,
      items: [
        <Collapse key="Corporate" className="my-2" title="Corporate">
          <CardImage
            className="card-image"
            imageLinkText={"Read More"}
            imageLinkLessText={"Read Less"}
            imageDescription={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.jasdkjsadkjlasdkjlasdkjlasdkjsadkjsk"
            }
            useCmsBlockImage={true}
          />
        </Collapse>,
      ],
    },
    {
      name: <CmsBlock id="careers_manufacturing_name" />,
      items: [
        <Collapse key="Manufacturing" className="my-2" title="Manufacturing">
          <CardImage
            className="card-image"
            imageLinkText={"Read More"}
            imageLinkLessText={"Read Less"}
            imageDescription={
              "This five-layered cushion uses a soft grade of 1.8 lb. HR foam for the cushion core. The foam core is then capped with a 1-3/4” thick, soft convoluted foam on each side and wrapped with a 1-1/4 ounce fiber crown. The cushion is then encased in a white non-woven ticking. This combination of foams and fiber is designed to create a soft plush cushion yet will still maintain positive “memory” of its original shape. This cushion is standard on most sofas and lounge chairs."
            }
            useCmsBlockImage={true}
          />
        </Collapse>,
      ],
    },
  ];
  let tabProps = {
    tabCat,
    setTabCat,
    currentTab,
    setCurrentTab,
    tabs,
  };
  return <Careers {...props} tabProps={tabProps} />;
};

export default CareersDataContainer;
