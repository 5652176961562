import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import CmsPage from "app/pages/cms";
import Breadcrumbs from "app/layout/breadcrumbs";

const PrivacyPolicy = (props) => {
  return (
    <MDBContainer className="static-page privacy-policy-page">
      <MDBRow>
        <MDBCol size="12" className="mt-5">
          <Breadcrumbs />
        </MDBCol>
        <MDBCol>
          <CmsPage dangHtml={true} id={7} />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default PrivacyPolicy;
