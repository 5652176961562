import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { sliderSetting } from "./sliderSettings";
import FilterOptionItem from "./filterOptionItem";
import { useLocation } from "react-router-dom";

const FilterOptions = (props) => {
  let {
    options,
    addFilter,
    activeFilters,
    setActiveFilters,
    filterCode,
    filterType,
    currentPage,
    setCurrentPage,
    selectedGrades = [],
  } = props;
  const location = useLocation();
  let noItems = location.pathname.includes("graded-in") ? 18 : 9;
  let dots =
    options && options.length > 0 && Math.ceil(options.length / noItems);
  let array = [];
  for (let i = 0; i < dots; i++) {
    array.push(i);
  }

  const [mainSliderNav, setMainSliderNav] = useState(null);
  const [dotsSliderNav, setDotsSliderNav] = useState(null);
  let mainSlider = null;
  let navSlider = null;
  useEffect(() => {
    setDotsSliderNav(navSlider);
    setMainSliderNav(mainSlider);
  }, [mainSlider, navSlider]);
  const [activeSlide, setActiveSlide] = useState(0);
  const isPatternFilter = filterCode.includes("pattern");
  const isGradeFilter = filterCode.includes("grade");
  return (
    <div className="multi-select-filter-options">
      {options && options.length > 0 && (
        <Slider
          {...sliderSetting(
            options.length,
            activeSlide,
            setActiveSlide,
            isPatternFilter
          )}
        >
          {options
            .sort((oldVal, newVal) => {
              return oldVal.label < newVal.label
                ? -1
                : oldVal.label > newVal.label
                ? 1
                : 0;
            })
            .map((option, index) => {
              return (
                <div
                  key={`filter-option-${index}`}
                  style={{
                    width: location.pathname.includes("graded-in") && "16.66%",
                  }}
                >
                  <FilterOptionItem
                    option={option}
                    addFilter={addFilter}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    filterCode={filterCode}
                    filterType={filterType}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    isGradeFilter={isGradeFilter}
                    selectedGrades={selectedGrades}
                  />
                </div>
              );
            })}
        </Slider>
      )}
      {/*{array && array.length > 1 && (*/}
      {/*  <Slider*/}
      {/*    ref={(slider) => (navSlider = slider)}*/}
      {/*    asNavFor={mainSliderNav}*/}
      {/*    className={"dots-nav-slider"}*/}
      {/*    {...{*/}
      {/*      dots: false,*/}
      {/*      infinite: false,*/}
      {/*      speed: 500,*/}
      {/*      slidesToShow: location.pathname.includes("graded-in")*/}
      {/*        ? dots + 1*/}
      {/*        : 10,*/}
      {/*      arrows: false,*/}
      {/*      slidesToScroll: 1,*/}
      {/*      swipeToSlide: true,*/}
      {/*      initialSlide: 0,*/}
      {/*      adaptiveHeight: true,*/}
      {/*      variableWidth: false,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {array.map((index) => {*/}
      {/*      return (*/}
      {/*        <button*/}
      {/*          onClick={() => {*/}
      {/*            setActiveSlide(index);*/}
      {/*            mainSlider.slickGoTo(index);*/}
      {/*          }}*/}
      {/*          key={index}*/}
      {/*          className={`nav-slider-item ${*/}
      {/*            index === activeSlide && "active"*/}
      {/*          }`}*/}
      {/*        />*/}
      {/*      );*/}
      {/*    })}*/}
      {/*  </Slider>*/}
      {/*)}*/}
    </div>
  );
};

export default FilterOptions;
