import gql from "graphql-tag";

export const QUERYOPTIONS = gql`
  query QueryOptions($filters: ProductAttributeFilterInput!) {
    products(filter: $filters) {
      aggregations {
        label
        attribute_code
        options {
          label
          value
        }
      }
      total_count
      items {
        id
        name
        sku
        url_key
        image {
          url(width: 700, height: 700)
          label
        }
        small_image {
          url(width: 265, height: 265)
          label
        }
        thumbnail {
          url(width: 100, height: 100)
          label
        }
        grade: ffifabrics_grade
      }
    }
  }
`;
