import { DomainCartFragment } from "app/generated/graphql";
import { DomainCartItem } from "./models";

export const acceptDocuments = [".jpg", ".pdf", ".tiff"];

export const formatDate = (date: Date) =>
  date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

export const getOptionsPrice = (
  options: DomainCartItem["customizable_options"]
) => {
  return (
    options?.reduce(
      (price, option) =>
        price +
        (option &&
        option.label &&
        option.label.toLowerCase() !== "upholstery options"
          ? option?.values?.reduce(
              (valuePrice, value) => valuePrice + (value?.price?.value ?? 0),
              0
            ) ?? 0
          : 0),
      0
    ) ?? 0
  );
};

export function isDomainCartItem(
  item: NonNullable<DomainCartFragment["items"]>[number]
): item is DomainCartItem {
  return !!item && "id" in item && "customizable_options" in item;
}
export const chunkArray = <A extends {}>(
  array: Array<A>,
  chunkLength: number
) => {
  return array.reduce((resultArray, _, i, a) => {
    if (i % chunkLength === 0) {
      return [...resultArray, a.slice(i, i + chunkLength)];
    } else {
      return resultArray;
    }
  }, [] as Array<Array<A>>);
};
