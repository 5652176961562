// @ts-check

import Breadcrumbs from "app/layout/breadcrumbs";
import themeConfiguration from "config/themeConfiguration";
import CmsBlock from "app/layout/cmsBlock";
import { MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdbreact";
import React from "react";
import Helmet from "react-helmet";
import RegisterDataContainer from "./DataContainer";

/**
 *
 * @type {React.FC<React.ComponentProps<typeof RegisterDataContainer>>}
 */
const Register = (props) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Registration - {themeConfiguration.name}</title>
        <link
          rel="canonical"
          href={`${themeConfiguration.app_url}/customer/register`}
        />
      </Helmet>
      <MDBContainer
        id="trade-register-B2B"
        fluid
        className={"page-main-content"}
      >
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <Breadcrumbs />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <MDBTypography tag="h1">Register</MDBTypography>
            </MDBCol>
          </MDBRow>
          <CmsBlock id="register-welcome" />
        </MDBContainer>
        <RegisterDataContainer {...props} />
      </MDBContainer>
    </>
  );
};

export default Register;
