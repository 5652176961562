import themeConfiguration from "../../../../config/themeConfiguration";

export const modelsArray = [
  {
    id: "M001-17",
    name: "Portofino Credenza",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M001-17.jpg`,
    parentName: "Frame",
    sku: "M001-17",
    finishIDs: [
      { id: "almond_buff" },
      { id: "american_navy" },
      { id: "antique_pinewood" },
      { id: "black_iron" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "clasic_emerald" },
      { id: "dark_sable_ash" },
      { id: "english_honey" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "hazelnut" },
      { id: "iron_ore" },
      { id: "linen" },
      { id: "modern_truffle_ash" },
      { id: "ox_blood" },
      { id: "sheer_dove_ash" },
      { id: "soft_white" },
      { id: "tobacco" },
    ],
  },

  {
    id: "M001-81",
    name: "Portofino Desk",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M001-81.jpg`,
    parentName: "Frame",
    sku: "M001-81",
    finishIDs: [
      { id: "almond_buff" },
      { id: "american_navy" },
      { id: "antique_pinewood" },
      { id: "black_iron" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "clasic_emerald" },
      { id: "dark_sable_ash" },
      { id: "english_honey" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "hazelnut" },
      { id: "iron_ore" },
      { id: "linen" },
      { id: "modern_truffle_ash" },
      { id: "ox_blood" },
      { id: "sheer_dove_ash" },
      { id: "soft_white" },
      { id: "tobacco" },
    ],
  },

  {
    id: "M001-84",
    name: "Portofino Lateral File",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M001-84.jpg`,
    parentName: "Frame",
    sku: "M001-84",
    finishIDs: [
      { id: "almond_buff" },
      { id: "american_navy" },
      { id: "antique_pinewood" },
      { id: "black_iron" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "clasic_emerald" },
      { id: "dark_sable_ash" },
      { id: "english_honey" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "hazelnut" },
      { id: "iron_ore" },
      { id: "linen" },
      { id: "modern_truffle_ash" },
      { id: "ox_blood" },
      { id: "sheer_dove_ash" },
      { id: "soft_white" },
      { id: "tobacco" },
    ],
  },

  {
    id: "M001-DT",
    name: "Portofino Dining Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M001-DT.jpg`,
    parentName: "Frame",
    sku: "M001-DT",
    finishIDs: [
      { id: "almond_buff" },
      { id: "american_navy" },
      { id: "antique_pinewood" },
      { id: "black_iron" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "clasic_emerald" },
      { id: "dark_sable_ash" },
      { id: "english_honey" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "hazelnut" },
      { id: "iron_ore" },
      { id: "linen" },
      { id: "modern_truffle_ash" },
      { id: "ox_blood" },
      { id: "sheer_dove_ash" },
      { id: "soft_white" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M066-17-U",
    name: "Coeurd'alene Credenza",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M066-17-U.jpg`,
    parentName: "Frame",
    sku: "M066-17-U",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M066-17-W",
    name: "Coeurd'alene Credenza",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M066-17-W.jpg`,
    parentName: "Frame",
    sku: "M066-17-W",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M066-38",
    name: "Coeurd'alene Chest",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M066-38.jpg`,
    parentName: "Frame",
    sku: "M066-38",
    contrastDrawers: true,
    striping: true,
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M066-86",
    name: "Coeurd'alene Entry Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M066-86.jpg`,
    parentName: "Frame",
    sku: "M066-86",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M066-99",
    name: "Coeurd'alene Console Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M066-99.jpg`,
    parentName: "Frame",
    sku: "M066-99",
    striping: true,
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M066-AC",
    name: "Coeurd'alene Accent Chest",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M066-AC.jpg`,
    parentName: "Frame",
    sku: "M066-AC",
    striping: true,
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M066-ST",
    name: "CREDENZA",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M066-ST.jpg`,
    parentName: "Frame",
    sku: "M066-ST",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  // {
  //   id: "M067-48",
  //   name: "Table",
  //   image: `${themeConfiguration.model_url}/3d/monogram/images/M067-48.jpg`,
  //   parentName: "Frame",
  //   sku: "M067-48",
  //   striping: true,
  // },
  // {
  //   id: "M067-60",
  //   name: "Table",
  //   image: `${themeConfiguration.model_url}/3d/monogram/images/M067-60.jpg`,
  //   parentName: "Frame",
  //   sku: "M067-60",
  //   striping: true,
  // },
  // {
  //   id: "M067-TB-48",
  //   name: "Table",
  //   image: `${themeConfiguration.model_url}/3d/monogram/images/M067-TB-48.jpg`,
  //   parentName: "Frame",
  //   sku: "M067-TB-48",
  //   striping: true,
  // },
  // {
  //   id: "M067-TB-60",
  //   name: "Table",
  //   image: `${themeConfiguration.model_url}/3d/monogram/images/M067-TB-60.jpg`,
  //   parentName: "Frame",
  //   sku: "M067-TB-60",
  //   striping: true,
  // },
  // {
  //   id: "M069-TB",
  //   name: "M069-TB",
  //   image: `${themeConfiguration.model_url}/3d/monogram/images/M069-TB.jpg`,
  //   parentName: "Frame",
  //   sku: "M069-TB",
  // },
  {
    id: "M084-CH",
    name: "Lizzie Chest",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M084-CH.jpg`,
    parentName: "Frame",
    sku: "M084-CH",
    contrastDrawers: true,
    striping: true,
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M092-17",
    name: "Cheñeais Credenza",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M092-17.jpg`,
    parentName: "Frame",
    sku: "M092-17",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M092-19",
    name: "Cheñeais End Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M092-19.jpg`,
    parentName: "Frame",
    sku: "M092-19",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M092-92",
    name: "Cheñeais Square Cocktail Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M092-92.jpg`,
    parentName: "Frame",
    sku: "M092-92",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M092-95",
    name: "Cheñeais Chairside Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M092-95.jpg`,
    parentName: "Frame",
    sku: "M092-95",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M092-98",
    name: "Cheñeais Round Lamp Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M092-98.jpg`,
    parentName: "Frame",
    sku: "M092-98",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M092-CH",
    name: "Cheñeais Chest",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M092-CH.jpg`,
    parentName: "Frame",
    sku: "M092-CH",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M092-DT",
    name: "Cheñeais Dining Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M092-DT.jpg`,
    parentName: "Frame",
    sku: "M092-DT",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M092-MC",
    name: "Cheñeais Credenza",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M092-MC.jpg`,
    parentName: "Frame",
    sku: "M092-MC",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M098-38",
    name: "East Camden Chest",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M098-38.jpg`,
    parentName: "Frame",
    sku: "M098-38",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M098-48",
    name: "East Camden Bar Cabinet",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M098-48.jpg`,
    parentName: "Frame",
    sku: "M098-48",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M098-97",
    name: "East Camden Chest",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M098-97.jpg`,
    parentName: "Frame",
    sku: "M098-97",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M098-AC",
    name: "East Camden Accent Chest",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M098-AC.jpg`,
    parentName: "Frame",
    sku: "M098-AC",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M181-48",
    name: "Sundries Bar Cabinet",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M181-48.jpg`,
    parentName: "Frame",
    sku: "M181-48",
    customWood: true,
    striping: true,
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },

  {
    id: "M189-13",
    name: "Expedition Round Cocktail Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M189-13.jpg`,
    parentName: "Frame",
    sku: "M189-13",
    finishIDs: [
      { id: "almond_buff" },
      { id: "american_navy" },
      { id: "antique_pinewood" },
      { id: "black_iron" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "clasic_emerald" },
      { id: "dark_sable_ash" },
      { id: "english_honey" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "hazelnut" },
      { id: "iron_ore" },
      { id: "linen" },
      { id: "modern_truffle_ash" },
      { id: "ox_blood" },
      { id: "sheer_dove_ash" },
      { id: "soft_white" },
      { id: "tobacco" },
    ],
  },

  {
    id: "M189-19",
    name: "Expedition Round End Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M189-19.jpg`,
    parentName: "Frame",
    sku: "M189-19",
    finishIDs: [
      { id: "almond_buff" },
      { id: "american_navy" },
      { id: "antique_pinewood" },
      { id: "black_iron" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "clasic_emerald" },
      { id: "dark_sable_ash" },
      { id: "english_honey" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "hazelnut" },
      { id: "iron_ore" },
      { id: "linen" },
      { id: "modern_truffle_ash" },
      { id: "ox_blood" },
      { id: "sheer_dove_ash" },
      { id: "soft_white" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M189-81",
    name: "Expedition Pedestal Desk",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M189-81.jpg`,
    parentName: "Frame",
    sku: "M189-81",
    contrastDrawers: true,
    customWood: true,
    striping: true,
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },

  {
    id: "M189-88",
    name: "Expedition Martini Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M189-88.jpg`,
    parentName: "Frame",
    sku: "M189-88",
    finishIDs: [
      { id: "almond_buff" },
      { id: "american_navy" },
      { id: "antique_pinewood" },
      { id: "black_iron" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "clasic_emerald" },
      { id: "dark_sable_ash" },
      { id: "english_honey" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "hazelnut" },
      { id: "iron_ore" },
      { id: "linen" },
      { id: "modern_truffle_ash" },
      { id: "ox_blood" },
      { id: "sheer_dove_ash" },
      { id: "soft_white" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M189-91",
    name: "Expedition Cocktail Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M189-91.jpg`,
    parentName: "Frame",
    sku: "M189-91",
    contrastDrawers: true,
    customWood: true,
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M189-95",
    name: "Expedition End Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M189-95.jpg`,
    parentName: "Frame",
    sku: "M189-95",
    contrastDrawers: true,
    customWood: true,
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M189-96",
    name: "Expedition Console",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M189-96.jpg`,
    parentName: "Frame",
    sku: "M189-96",
    contrastDrawers: true,
    customWood: true,
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },

  {
    id: "M189-99",
    name: "Expedition Sofa Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M189-99.jpg`,
    parentName: "Frame",
    sku: "M189-99",
    finishIDs: [
      { id: "almond_buff" },
      { id: "american_navy" },
      { id: "antique_pinewood" },
      { id: "black_iron" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "clasic_emerald" },
      { id: "dark_sable_ash" },
      { id: "english_honey" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "hazelnut" },
      { id: "iron_ore" },
      { id: "linen" },
      { id: "modern_truffle_ash" },
      { id: "ox_blood" },
      { id: "sheer_dove_ash" },
      { id: "soft_white" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M189-AC",
    name: "Expedition Chest",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M189-AC.jpg`,
    parentName: "Frame",
    sku: "M189-AC",
    contrastDrawers: true,
    customWood: true,
    striping: true,
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },

  {
    id: "M189-AD",
    name: "Expedition Console",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M189-AD.jpg`,
    parentName: "Frame",
    sku: "M189-AD",
    contrastDrawers: true,
    finishIDs: [
      { id: "almond_buff" },
      { id: "american_navy" },
      { id: "antique_pinewood" },
      { id: "black_iron" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "clasic_emerald" },
      { id: "dark_sable_ash" },
      { id: "english_honey" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "hazelnut" },
      { id: "iron_ore" },
      { id: "linen" },
      { id: "modern_truffle_ash" },
      { id: "ox_blood" },
      { id: "sheer_dove_ash" },
      { id: "soft_white" },
      { id: "tobacco" },
    ],
  },

  {
    id: "M189-CC",
    name: "Expedition Chairside Chest",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M189-CC.jpg`,
    parentName: "Frame",
    sku: "M189-CC",
    contrastDrawers: true,
    finishIDs: [
      { id: "almond_buff" },
      { id: "american_navy" },
      { id: "antique_pinewood" },
      { id: "black_iron" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "clasic_emerald" },
      { id: "dark_sable_ash" },
      { id: "english_honey" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "hazelnut" },
      { id: "iron_ore" },
      { id: "linen" },
      { id: "modern_truffle_ash" },
      { id: "ox_blood" },
      { id: "sheer_dove_ash" },
      { id: "soft_white" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M189-CH",
    name: "Expedition Chest",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M189-CH.jpg`,
    parentName: "Frame",
    sku: "M189-CH",
    contrastDrawers: true,
    customWood: true,
    striping: true,
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },

  {
    id: "M189-HT",
    name: "Expedition Hall Console Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M189-HT.jpg`,
    parentName: "Frame",
    sku: "M189-HT",
    finishIDs: [
      { id: "almond_buff" },
      { id: "american_navy" },
      { id: "antique_pinewood" },
      { id: "black_iron" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "clasic_emerald" },
      { id: "dark_sable_ash" },
      { id: "english_honey" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "hazelnut" },
      { id: "iron_ore" },
      { id: "linen" },
      { id: "modern_truffle_ash" },
      { id: "ox_blood" },
      { id: "sheer_dove_ash" },
      { id: "soft_white" },
      { id: "tobacco" },
    ],
  },

  {
    id: "M201-DT",
    name: "BD Collection For Fairfield Cooper Dining Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M201-DT.jpg`,
    parentName: "Frame",
    sku: "M201-DT",
    finishIDs: [
      { id: "almond_buff" },
      { id: "american_navy" },
      { id: "antique_pinewood" },
      { id: "black_iron" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "clasic_emerald" },
      { id: "dark_sable_ash" },
      { id: "english_honey" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "hazelnut" },
      { id: "iron_ore" },
      { id: "linen" },
      { id: "modern_truffle_ash" },
      { id: "ox_blood" },
      { id: "sheer_dove_ash" },
      { id: "soft_white" },
      { id: "tobacco" },
    ],
  },

  {
    id: "M305-18",
    name: "BD Collection For Fairfield Pamela Curio Cabinet",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M305-18.jpg`,
    parentName: "Frame",
    sku: "M305-18",
    finishIDs: [
      { id: "almond_buff" },
      { id: "american_navy" },
      { id: "antique_pinewood" },
      { id: "black_iron" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "clasic_emerald" },
      { id: "dark_sable_ash" },
      { id: "english_honey" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "hazelnut" },
      { id: "iron_ore" },
      { id: "linen" },
      { id: "modern_truffle_ash" },
      { id: "ox_blood" },
      { id: "sheer_dove_ash" },
      { id: "soft_white" },
      { id: "tobacco" },
    ],
  },

  {
    id: "M307-18",
    name: "BD Collection For Fairfield Edward Curio Cabinet",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M307-18.jpg`,
    parentName: "Frame",
    sku: "M307-18",
    finishIDs: [
      { id: "almond_buff" },
      { id: "american_navy" },
      { id: "antique_pinewood" },
      { id: "black_iron" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "clasic_emerald" },
      { id: "dark_sable_ash" },
      { id: "english_honey" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "hazelnut" },
      { id: "iron_ore" },
      { id: "linen" },
      { id: "modern_truffle_ash" },
      { id: "ox_blood" },
      { id: "sheer_dove_ash" },
      { id: "soft_white" },
      { id: "tobacco" },
    ],
  },

  {
    id: "M309-18",
    name: "BD Collection For Fairfield Alexander Curio Cabinet",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M309-18.jpg`,
    parentName: "Frame",
    sku: "M309-18",
    finishIDs: [
      { id: "almond_buff" },
      { id: "american_navy" },
      { id: "antique_pinewood" },
      { id: "black_iron" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "clasic_emerald" },
      { id: "dark_sable_ash" },
      { id: "english_honey" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "hazelnut" },
      { id: "iron_ore" },
      { id: "linen" },
      { id: "modern_truffle_ash" },
      { id: "ox_blood" },
      { id: "sheer_dove_ash" },
      { id: "soft_white" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M701-92",
    name: "Libby Langdon Peconic Square Cocktail Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M701-92.jpg`,
    parentName: "Frame",
    sku: "M701-92",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M701-CT",
    name: "M701-CT",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M701-CT.jpg`,
    parentName: "Frame",
    sku: "M701-CT",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M702-93",
    name: "Libby Langdon Dune Rectangular Cocktail Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M702-93.jpg`,
    parentName: "Frame",
    sku: "M702-93",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M703-12",
    name: "M703-12",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M703-12.jpg`,
    parentName: "Frame",
    sku: "M703-12",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M721-47",
    name: "Libby Langdon Wheelock End Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M721-47.jpg`,
    parentName: "Frame",
    sku: "M721-47",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M722-19",
    name: "Libby Langdon Oakleigh End Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M722-19.jpg`,
    parentName: "Frame",
    sku: "M722-19",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M723-36",
    name: "Libby Langdon Foster Right Side Facing Open End Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M723-36.jpg`,
    parentName: "Frame",
    sku: "M723-36",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M723-37",
    name: "Libby Langdon Foster Left Side Facing Open End Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M723-37.jpg`,
    parentName: "Frame",
    sku: "M723-37",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M725-95",
    name: "Libby Langdon Gibson End Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M725-95.jpg`,
    parentName: "Frame",
    sku: "M725-95",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M740-17",
    name: "Libby Langdon Heatherwood Master Chest",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M740-17.jpg`,
    parentName: "Frame",
    sku: "M740-17",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M740-38",
    name: "Libby Langdon Heatherwood Large End Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M740-38.jpg`,
    parentName: "Frame",
    sku: "M740-38",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M740-CH",
    name: "Libby Langdon Heatherwood Chest",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M740-CH.jpg`,
    parentName: "Frame",
    sku: "M740-CH",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M740-ET",
    name: "Libby Langdon Heatherwood End Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M740-ET.jpg`,
    parentName: "Frame",
    sku: "M740-ET",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M741-17",
    name: "Libby Langdon Miller Master Chest",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M741-17.jpg`,
    parentName: "Frame",
    sku: "M741-17",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M741-38",
    name: "Libby Langdon Miller Large End Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M741-38.jpg`,
    parentName: "Frame",
    sku: "M741-38",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M741-CH",
    name: "Libby Langdon Miller Chest",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M741-CH.jpg`,
    parentName: "Frame",
    sku: "M741-CH",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M741-ET",
    name: "Libby Langdon Miller End Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M741-ET.jpg`,
    parentName: "Frame",
    sku: "M741-ET",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M762-96",
    name: "Libby Langdon Lendell Console Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M762-96.jpg`,
    parentName: "Frame",
    sku: "M762-96",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M763-17",
    name: "Libby Langdon Hillcrest Credenza",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M763-17.jpg`,
    parentName: "Frame",
    sku: "M763-17",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M763-96",
    name: "Libby Langdon Hillcrest Console Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M763-96.jpg`,
    parentName: "Frame",
    sku: "M763-96",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M764-81",
    name: "Libby Langdon Lionel Console Desk",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M764-81.jpg`,
    parentName: "Frame",
    sku: "M764-81",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },

  {
    id: "M780-81",
    name: "Libby Langdon Patton Desk",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M780-81.jpg`,
    parentName: "Frame",
    sku: "M780-81",
    finishIDs: [
      { id: "almond_buff" },
      { id: "american_navy" },
      { id: "antique_pinewood" },
      { id: "black_iron" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "clasic_emerald" },
      { id: "dark_sable_ash" },
      { id: "english_honey" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "hazelnut" },
      { id: "iron_ore" },
      { id: "linen" },
      { id: "modern_truffle_ash" },
      { id: "ox_blood" },
      { id: "sheer_dove_ash" },
      { id: "soft_white" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M800-DT",
    name: "Libby Langdon Lynncliff Dining Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M800-DT.jpg`,
    parentName: "Frame",
    sku: "M800-DT",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M801-DT",
    name: "Libby Langdon Whitby Dining Table",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M801-DT.jpg`,
    parentName: "Frame",
    sku: "M801-DT",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M802-DT",
    name: "M802-DT",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M802-DT.jpg`,
    parentName: "Frame",
    sku: "M802-DT",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M820-17",
    name: "Libby Langdon Winston Credenza",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M820-17.jpg`,
    parentName: "Frame",
    sku: "M820-17",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M821-17",
    name: "Libby Langdon Baystone Credenza",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M821-17.jpg`,
    parentName: "Frame",
    sku: "M821-17",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M900-KB",
    name: "Libby Langdon Shelby King Bed",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M900-KB.jpg`,
    parentName: "Frame",
    sku: "M900-KB",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
  {
    id: "M901-KB",
    name: "Libby Langdon Ackerly King Bed",
    image: `${themeConfiguration.model_url}/3d/monogram/images/M901-KB.jpg`,
    parentName: "Frame",
    sku: "M901-KB",
    finishIDs: [
      { id: "almond_buff" },
      { id: "cerussed_espresso_ash" },
      { id: "charcoal" },
      { id: "dark_sable_ash" },
      { id: "flint" },
      { id: "french_oak" },
      { id: "grecian_clay" },
      { id: "hazelnut" },
      { id: "modern_truffle_ash" },
      { id: "sheer_dove_ash" },
      { id: "sheer_dove_walnut" },
      { id: "tobacco" },
    ],
  },
];

export const finishesArray = [
  {
    id: "almond_buff",
    name: "Almond Buff",
    image: `${themeConfiguration.model_url}/3d/monogram/images/almond_buff.jpg`,
    parentName: "Finish",
    sku: "X1",
  },
  {
    id: "american_navy",
    name: "American Navy",
    image: `${themeConfiguration.model_url}/3d/monogram/images/american_navy.jpg`,
    parentName: "Finish",
    sku: "XL",
  },
  {
    id: "antique_pinewood",
    name: "Antique Pinewood",
    image: `${themeConfiguration.model_url}/3d/monogram/images/antique_pinewood.jpg`,
    parentName: "Finish",
    sku: "XN",
  },
  {
    id: "black_iron",
    name: "Black Iron",
    image: `${themeConfiguration.model_url}/3d/monogram/images/black_iron.jpg`,
    parentName: "Finish",
    sku: "XJ",
  },
  {
    id: "cerussed_espresso_ash",
    name: "Cerussed Espresso",
    image: `${themeConfiguration.model_url}/3d/monogram/images/Cerussed Espresso Ash.jpg`,
    parentName: "Finish",
    sku: "X2",
  },
  {
    id: "charcoal",
    name: "Charcoal",
    image: `${themeConfiguration.model_url}/3d/monogram/images/charcoal.jpg`,
    parentName: "Finish",
    sku: "X3",
  },
  {
    id: "clasic_emerald",
    name: "Classic Emerald",
    image: `${themeConfiguration.model_url}/3d/monogram/images/classic_emerald.jpg`,
    parentName: "Finish",
    sku: "XI",
  },
  {
    id: "dark_sable_ash",
    name: "Dark Sable",
    image: `${themeConfiguration.model_url}/3d/monogram/images/dark_sable_ash.jpg`,
    parentName: "Finish",
    sku: "X5",
  },
  {
    id: "english_honey",
    name: "English Honey",
    image: `${themeConfiguration.model_url}/3d/monogram/images/english_honey.jpg`,
    parentName: "Finish",
    sku: "XP",
  },
  {
    id: "flint",
    name: "Flint",
    image: `${themeConfiguration.model_url}/3d/monogram/images/flint.jpg`,
    parentName: "Finish",
    sku: "X6",
  },
  {
    id: "french_oak",
    name: "French Oak",
    image: `${themeConfiguration.model_url}/3d/monogram/images/french_oak.jpg`,
    parentName: "Finish",
    sku: "X7",
  },

  {
    id: "hazelnut",
    name: "Hazelnut",
    image: `${themeConfiguration.model_url}/3d/monogram/images/hazelnut.jpg`,
    parentName: "Finish",
    sku: "X8",
  },
  {
    id: "iron_ore",
    name: "Iron Ore",
    image: `${themeConfiguration.model_url}/3d/monogram/images/iron_ore.jpg`,
    parentName: "Finish",
    sku: "XO",
  },
  {
    id: "linen",
    name: "Linen",
    image: `${themeConfiguration.model_url}/3d/monogram/images/linen.jpg`,
    parentName: "Finish",
    sku: "XH",
  },
  {
    id: "modern_truffle_ash",
    name: "Modern Truffle",
    image: `${themeConfiguration.model_url}/3d/monogram/images/modern_truffle_ash.jpg`,
    parentName: "Finish",
    sku: "X9",
  },
  {
    id: "ox_blood",
    name: "Ox Blood",
    image: `${themeConfiguration.model_url}/3d/monogram/images/ox_blood.jpg`,
    parentName: "Finish",
    sku: "XK",
  },
  {
    id: "sheer_dove_ash",
    name: "Sheer Dove",
    image: `${themeConfiguration.model_url}/3d/monogram/images/sheer_dove_ash.jpg`,
    parentName: "Finish",
    sku: "XC",
  },

  {
    id: "soft_white",
    name: "Soft White",
    image: `${themeConfiguration.model_url}/3d/monogram/images/soft_white.jpg`,
    parentName: "Finish",
    sku: "XM",
  },
  {
    id: "tobacco",
    name: "Tobacco",
    image: `${themeConfiguration.model_url}/3d/monogram/images/tobacco.jpg`,
    parentName: "Finish",
    sku: "XM",
  },
];

export const stripingArray = [
  {
    id: "gold",
    name: "Gold",
    image: `${themeConfiguration.model_url}/3d/monogram/images/Gold Striping.jpg`,
    color: "#653F19",
    metalness: 1,
    roughness: 0.2,
    parentName: "Striping",
  },
  {
    id: "platinum",
    name: "Platinum",
    image: `${themeConfiguration.model_url}/3d/monogram/images/Platinum Striping.jpg`,
    color: "#A9A9A9",
    metalness: 1,
    roughness: 0.2,
    parentName: "Striping",
  },
  {
    id: "no_striping",
    name: "No Striping",
    image: `${themeConfiguration.model_url}/3d/monogram/images/no_striping.jpg`,
    parentName: "Striping",
  },
];

export const floorArray = [
  {
    name: "Grid",
    default: 1,
    map: `${themeConfiguration.model_url}/3d/textures/floor/grid.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/floor/grid_icon.jpg`,
    repeat: 500,
  },
  {
    name: "Carpeting",
    default: 0,
    map: `${themeConfiguration.model_url}/3d/textures/floor/carpet.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/floor/carpet_icon.jpg`,
    repeat: 20,
  },
  {
    name: "Bamboo",
    default: 0,
    map: `${themeConfiguration.model_url}/3d/textures/floor/parquet.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/floor/parquet_icon.jpg`,
    repeat: 75,
  },
  {
    name: "Cedar",
    default: 0,
    map: `${themeConfiguration.model_url}/3d/textures/floor/dark_parquet.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/floor/dark_parquet_icon.jpg`,
    repeat: 75,
  },
  {
    name: "Mocca",
    default: 0,
    map: `${themeConfiguration.model_url}/3d/textures/floor/dark_parquet_2.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/floor/dark_parquet_2_icon.jpg`,
    repeat: 75,
  },
];
