// @ts-check

import billingAndShipping from "app/assets/icon/billingAndShipping.svg";
import companyDetails from "app/assets/icon/companyDetails.svg";
import contactInfo from "app/assets/icon/contactInfo.svg";
import clsx from "clsx";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import Step from "../Step";

/**
 * @typedef {object} StepOptions
 * @prop {import('../Step').StepProps['status']} status
 * @prop {import('../Step').StepProps['onClick']} onClick
 *
 * @typedef {object} StepConfig
 * @prop {StepsProps['step']} activeStep
 * @prop {StepsProps['step']} currentStep
 * @prop {StepsProps['setStep']} setStep
 * @prop {StepsProps['validateStep']} validateStep
 *
 * @param {StepConfig} config
 * @returns {StepOptions}
 */
const getStepProps = ({ activeStep, currentStep, setStep, validateStep }) => {
  if (activeStep === currentStep) {
    return {
      status: "active",
      onClick: () => {},
    };
  } else if (currentStep < activeStep) {
    return {
      status: "complete",
      onClick: () => {
        setStep(currentStep);
      },
    };
  } else {
    return {
      status: undefined,
      onClick: () => {
        if (validateStep) {
          validateStep(activeStep);
        }
      },
    };
  }
};

/**
 * @typedef {number} Step
 *
 * @typedef {object} StepsProps
 * @property {Step} step
 * @property {(step: Step) => void} setStep
 * @property {(step: Step) => void} [validateStep]
 *
 * @param {StepsProps} props
 */
const Steps = (props) => {
  return (
    <MDBRow
      className={clsx(
        "steps",
        "justify-content-lg-center",
        "justify-content-left",
        "bg-gray-lighten-4"
      )}
    >
      <MDBContainer
        className={clsx("steps-container ", {
          "translate-0": props.step === 0,
          "translate-2": props.step === 2,
        })}
      >
        <MDBRow className="steps-inner-wrapper h-100 flex-lg-wrap  flex-nowrap">
          <MDBCol className="h-100">
            <Step
              icon={companyDetails}
              caption="Company Details"
              {...getStepProps({
                activeStep: props.step,
                currentStep: 0,
                setStep: props.setStep,
                validateStep: props.validateStep,
              })}
            />
          </MDBCol>
          <MDBCol>
            <Step
              icon={contactInfo}
              caption="Contact Info"
              {...getStepProps({
                activeStep: props.step,
                currentStep: 1,
                setStep: props.setStep,
                validateStep: props.validateStep,
              })}
            />
          </MDBCol>

          <MDBCol>
            <Step
              icon={billingAndShipping}
              caption="Billing and Shipping"
              {...getStepProps({
                activeStep: props.step,
                currentStep: 2,
                setStep: props.setStep,
                validateStep: props.validateStep,
              })}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBRow>
  );
};

export default Steps;
