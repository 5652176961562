import React from "react";
import { MDBModal, MDBModalBody } from "mdbreact";
import GalleryDetailsDataComponent from "../galleryDetail";

function GalleryModal({
  popupVisible,
  setPopupVisible,
  path,
  bottomGalleryImages,
  image,
}) {
  return (
    <MDBModal
      className={"gallery-modal-popup"}
      isOpen={popupVisible}
      toggle={setPopupVisible}
      centered
    >
      <MDBModalBody className="px-4 py-0">
        <GalleryDetailsDataComponent
          galleryName={path}
          toggleModal={setPopupVisible}
          bottomGalleryImages={bottomGalleryImages}
          mainImage={image}
        />
      </MDBModalBody>
    </MDBModal>
  );
}

export default GalleryModal;
