import React from "react";
import { Generic, JSONLD } from "react-structured-data";
import themeConfiguration from "config/themeConfiguration";
import SeoHelmet from "app/layout/seoHelmet";

const SeoContainer = ({ productData, type, jsonLdType }) => {
  return (
    <>
      <JSONLD>
        <Generic
          type={type}
          jsonldype={jsonLdType}
          schema={{
            name: productData.name,
            sku: productData.sku,
            image:
              typeof productData.image !== "undefined"
                ? productData.image.url
                : "",
            url: themeConfiguration.app_url + productData.url_key,
          }}
          json
        ></Generic>
      </JSONLD>
      <SeoHelmet
        url={productData.url}
        title={
          typeof productData.meta_title !== "undefined" &&
          productData.meta_title !== null
            ? productData.meta_title
            : productData.name
        }
        description={
          typeof productData.meta_description !== "undefined" &&
          productData.meta_description !== null
            ? productData.meta_description
            : productData.description
        }
        image={
          typeof productData.image !== "undefined" &&
          typeof productData.image.url !== "undefined" &&
          productData.image.url !== null
            ? productData.image.url
            : ""
        }
      />
    </>
  );
};

export default SeoContainer;
