// @ts-check

import checked from "app/assets/icon/checked.svg";
import clsx from "clsx";
import { MDBTypography } from "mdbreact";
import React from "react";

/**
 * @typedef {import('../Switch').SwitchProps & { className?: string }} CheckboxProps
 *
 * @param {CheckboxProps} props
 */
const Checkbox = (props) => {
  return (
    <label
      className={clsx(
        "checkbox",
        "d-flex",
        "align-items-center",
        "cursor-pointer",
        props.className
      )}
    >
      <input
        {...props}
        type="checkbox"
        className={clsx(props.className, "hide")}
      />
      <span
        className={clsx(
          "checkbox-body",
          "size-20",
          "border-gray-1",
          "border-radius-05",
          "d-flex",
          "align-items-center",
          "justify-content-center",
          "mr-3",
          props.checked ? "bg-default" : "white"
        )}
      >
        {props.checked && (
          <img className="size-12" src={checked} alt="checked" />
        )}
      </span>
      <MDBTypography tag="h5">{props.label}</MDBTypography>
    </label>
  );
};

export default Checkbox;
