import React, { useState } from "react";
import CmsBlock from "app/layout/cmsBlock";
import { MDBCol, MDBContainer, MDBModal, MDBRow } from "mdbreact";
import { useMediaQuery } from "react-responsive";
import useVirtualDesignChallengeHook from "./useVirtualDesignChallengeHook";
import Title from "./Title";
import DesignersAndWinners from "./DesignersAndWinners";

function VirtualDesignChallenge() {
  const {
    vdcGalleryItems,
    virtualDesignersGalleryDataItems,
    virtualDesignChallengeWinners2022GalleryDataItems,
    virtualDesignChallengeWinners2021GalleryDataItems,
  } = useVirtualDesignChallengeHook();

  let isMobile = useMediaQuery({ maxWidth: 992 });

  const getName = (name, part) => name?.split("|")[part];
  const [selectedImage, setSelectedImage] = useState(null);
  return (
    <>
      <div className="vdc">
        <div className="hero-img-wrapper">
          <CmsBlock id="vdc_hero_image" />
        </div>
        <MDBContainer className="mt-4">
          <CmsBlock id="vdc_heading" />
          <Title text="2023 Virtual Design Challenge" />
          <div className="gallery-wrapper">
            {vdcGalleryItems
              ?.filter((item) =>
                isMobile
                  ? !item?.name?.includes("desktop")
                  : !item?.name?.includes("mobile")
              )
              ?.map((item, idx) => (
                <MDBRow
                  key={item.id}
                  className={`${idx === 0 && "pb-5 winner"} mb-5`}
                >
                  <MDBCol
                    size={idx === 0 ? "12" : "6"}
                    lg="4"
                    className={`position-relative`}
                  >
                    <img
                      src={item?.image}
                      alt={item?.name}
                      className="img-fluid h-100"
                    />
                    <div className="name-wrapper-bottom">
                      <h3 className="font-weight-bold">
                        {getName(item?.name, 0)}
                      </h3>
                      <a href={getName(item?.name, 2)} target="_blank">
                        <h3 className="font-weight-light">
                          {getName(item?.name, 1)}
                        </h3>
                      </a>
                    </div>
                  </MDBCol>
                  <MDBCol size={idx === 0 ? "12" : "6"} lg={"8"}>
                    {item?.description && <p>{item?.description}</p>}
                    <img
                      src={item?.banner_image}
                      alt={item?.banner_image}
                      className="img-fluid w-100 cursor-pointer banner_image"
                      onClick={() => setSelectedImage(item?.banner_image)}
                    />
                  </MDBCol>
                </MDBRow>
              ))}
          </div>
          <DesignersAndWinners
            title="2023 Designers"
            inRow="four"
            items={virtualDesignersGalleryDataItems}
            getName={getName}
            setSelectedImage={setSelectedImage}
          />
          <DesignersAndWinners
            title="2022 Virtual Design Challenge Winners"
            inRow="three"
            items={virtualDesignChallengeWinners2022GalleryDataItems}
            getName={getName}
          />
          <DesignersAndWinners
            title="2021 Virtual Design Challenge Winners"
            inRow="three"
            items={virtualDesignChallengeWinners2021GalleryDataItems}
            getName={getName}
          />
        </MDBContainer>
      </div>
      <MDBModal
        className={"gallery-modal-popup"}
        isOpen={selectedImage}
        toggle={() => setSelectedImage(null)}
        centered
      >
        <img
          src={selectedImage}
          alt="popup-image"
          style={{ maxHeight: "90vh" }}
        />
      </MDBModal>
    </>
  );
}

export default VirtualDesignChallenge;
