import { ModalConsumer } from "core/components/modal/ModalContext";
import { MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React, { useEffect, useContext } from "react";
import { RemoveScroll } from "react-remove-scroll";
import Icon from "app/assets/icon/icon";
import { MoreOptionsSidebar } from "./MoreOptionsSidebar";
import { CheckBoxContext } from "../checkboxContext";

export const MoreOptionsSection = ({
  title,
  options,
  customizationProps,
  isCustom,
  viewMoreOptionsPrice,
  viewMorePdpPrice,
  setViewMorePdpPrice,
  viewMoreOptions,
  // setViewMoreOptions,
  queryPathFabrics,
}) => {
  const { setViewMoreOptions } = useContext(CheckBoxContext);
  let sidebarProps = {
    options: options
      .filter(
        (option) => option.title.toLowerCase().indexOf("contrast welt") === -1
      )
      .filter(
        (option) =>
          option.title.toLowerCase().indexOf("contrasting buttons") === -1
      )
      .filter(
        (option) => option.title.toLowerCase().indexOf("decorative cord") === -1
      )
      .filter((option) => option.title.toLowerCase().indexOf("fringe") === -1)
      .map((option) => {
        if (Object.keys(queryPathFabrics).length > 0) {
          Object.keys(queryPathFabrics).map((key) => {
            if (option.title.toLowerCase().split(" ").join("_") === key) {
              option.checked = true;
            }
          });
        }
        return option;
      }),
    customizationProps,
    viewMoreOptionsPrice,
    viewMorePdpPrice,
    setViewMorePdpPrice,
    viewMoreOptions,
    setViewMoreOptions,
    queryPathFabrics,
  };

  useEffect(() => {
    let addItems = sidebarProps.options.filter((io) => {
      return io.checked;
    });
    if (addItems.length > 0) {
      setViewMoreOptions(addItems);
    }
  }, [queryPathFabrics]);
  return (
    <MDBCol className="more-options-wrapper pl-lg-4 p-0">
      <MDBCol className={`${isCustom ? "" : "px-4"}`}>
        <MDBRow className="">
          <ModalConsumer value={{ useWrapper: false }}>
            {({ showModal, hideModal }) => {
              return (
                <MDBCol
                  className="bg-secondary py-3 rounded cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    showModal(() => {
                      return (
                        <RemoveScroll>
                          <MoreOptionsSidebar
                            hideModal={hideModal}
                            {...sidebarProps}
                          />
                        </RemoveScroll>
                      );
                    });
                  }}
                >
                  <MDBTypography
                    tag="h2"
                    className="fs-16 fwlight underline text-white d-flex align-items-center justify-content-between click-to-view"
                  >
                    View {title}
                    <Icon icon="arrowRightWhite" />
                  </MDBTypography>
                </MDBCol>
              );
            }}
          </ModalConsumer>
        </MDBRow>
      </MDBCol>
    </MDBCol>
  );
};
