import {
  CustomerToken,
  DomainProductFragment,
  DomainWishlistFragment,
} from "app/generated/graphql";
import axios, { AxiosRequestConfig } from "axios";
import themeConfiguration from "config/themeConfiguration";
import { DeepNonNullable } from "utility-types";

const baseUrl = `${themeConfiguration.magento_url}/rest/V1`;

const customerRegisterUploadUrl = `${baseUrl}/jola-customerregister/uploadDocument`;
const customerOrdersUploadUrl = `${baseUrl}/jola-customerorders/uploadImage`;
const savedBuildsUploadUrl = `${baseUrl}/jola-multiplewishlist/uploadImage`;

export const uploadFile = (url: string) => (file: File) => {
  const formData = new FormData();

  formData.append("file", file);

  const config: AxiosRequestConfig = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const response = axios.post<string>(url, formData, config);

  return response;
};

export const customerRegisterUpload = uploadFile(customerRegisterUploadUrl);
export const customerOrdersUpload = uploadFile(customerOrdersUploadUrl);
export const savedBuildsUpload = ({
  file,
  sku,
  wishlistId,
  token,
}: DeepNonNullable<Pick<CustomerToken, "token">> &
  DeepNonNullable<Pick<DomainProductFragment, "sku">> & {
    file: File;
    wishlistId: NonNullable<DomainWishlistFragment["id"]>;
  }) => {
  const formData = new FormData();

  formData.append("sku", sku);
  formData.append("wishlist_id", wishlistId);
  formData.append("file", file);

  const config: AxiosRequestConfig = {
    headers: {
      "content-type": "multipart/form-data",
      authorization: `bearer ${token}`,
    },
  };

  const response = axios.post<string>(savedBuildsUploadUrl, formData, config);

  return response;
};
