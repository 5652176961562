import { MDBContainer } from "mdbreact";
import React from "react";
import Iframe from "react-iframe";
import { useQuery } from "@apollo/react-hooks";
import { GET_SHOWROOM_URL } from "./queries";
import ReactLoader from "app/layout/loader/loader";

const VirtualShowroom = () => {
  const { data, loading, error } = useQuery(GET_SHOWROOM_URL, {
    fetchPolicy: "no-cache",
  });
  const matterportUrl = data?.storeConfig?.matterport_url;
  if (loading)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <ReactLoader />
      </div>
    );
  return (
    <>
      <MDBContainer fluid className="virtual-showroom-wrapper px-0">
        <div className="iFrame-wrapper">
          <Iframe
            url={matterportUrl}
            frameborder="0"
            allowfullscreen
            allowtransparency
          />
        </div>
      </MDBContainer>
    </>
  );
};

export default VirtualShowroom;
