import React, { memo } from "react";
import { MDBContainer } from "mdbreact";
import CmsBlock from "app/layout/cmsBlock";
const Collection = memo(
  ({
    designer = "Libby Langdon",
    slogan = "Easy, Elegant, Everyday Style",
    description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
    url = "/libby-langdon",
    image = {
      url: "http://via.placeholder.com/1840x840/",
      label: "collection-promo",
    },
  }) => {
    return (
      <MDBContainer fluid className="collection-container py-5">
        <CmsBlock id={"homepage_libby"} />
      </MDBContainer>
    );
  }
);

export default Collection;
