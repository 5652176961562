import React, { useState, useEffect } from "react";
import { useSearch } from "app/state/hooks/searchHooks/useSearch";
import SearchModal from "./searchModal";
import { useSelector } from "react-redux";

const SearchModalStateContainer = () => {
  const shopCategories = useSelector(
    (state) =>
      state?.queries?.data[2]?.data?.categoryList[0]?.children[1]?.children[1]
        .children
  );

  const categories = ["Products", "Fabrics and Leather"];

  const [category, setCategoryFilter] = useState("Products");
  const [options, setOptions] = useState([
    { label: "Select industry", value: null },
    { label: "All", value: "" },
    // { label: "Industry", value: "a" },
    { label: "Residential", value: "b" },
    { label: "Commercial", value: "c" },
    { label: "Senior Living", value: "d" },
  ]);

  // NEED TO UPDATE USESEARCH HOOK TO RECEIVE ANOTHER PARAMETER
  const productTypeOptions = shopCategories?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));
  if (productTypeOptions)
    productTypeOptions.unshift({ label: "All", value: 2 });
  const [option, setOption] = useState(options[0]);
  const [productType, setProductType] = useState(productTypeOptions);

  const [open, setOpen] = useState(true);
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    setSearchVal("");
  }, [category]);

  let searchResult = useSearch(searchVal, option.label, productType?.value);

  return (
    <SearchModal
      option={option}
      setOption={setOption}
      searchVal={searchVal}
      setSearchVal={setSearchVal}
      open={open}
      setOpen={setOpen}
      searchResult={searchResult}
      productType={productType}
      setProductType={setProductType}
      productTypeOptions={productTypeOptions}
      categories={categories}
      category={category}
      setCategoryFilter={setCategoryFilter}
    />
  );
};
export default SearchModalStateContainer;
