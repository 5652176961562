// import desktopLogoLight from "app/assets/logo/desktopLogoLight.png";
import desktopLogoDark from "app/assets/logo/desktopLogo.png";
import { Maybe } from "app/generated/graphql";
import { WishlistItemWithProduct } from "app/pages/collections/models";
import themeConfiguration from "config/themeConfiguration";
import Price from "core/components/price";
import { MDBBox, MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdbreact";
import React from "react";
import {
  ContactInformationValues,
  IQuantityPerPage,
  Pricing,
  PrintConfig,
  printConfig,
  quantitiesPerPageRecord,
} from "../config";
import { chunkArray } from "../utils";

export interface PrintProps {
  quantityPerPage: IQuantityPerPage;
  contactInformation: Maybe<ContactInformationValues>;
  pricing: Pricing;
  message: string;
  itemsWithPrintableProducts: WishlistItemWithProduct[];
  classNameNotes: string;
}
class Print extends React.Component<PrintProps> {
  showPrice(itemCategories: any[] | undefined | null): boolean {
    let isShown: boolean = true;
    if (itemCategories?.length) {
      itemCategories.forEach((category) => {
        if (
          category.name?.toLowerCase() === "custom options" ||
          category.name?.toLowerCase() === "finishes" ||
          category.name?.toLowerCase() === "fabric" ||
          category.name?.toLowerCase() === "leather"
        )
          isShown = false;
      });
    }
    return isShown;
  }
  render() {
    const {
      props: {
        contactInformation,
        message,
        quantityPerPage,
        pricing,
        itemsWithPrintableProducts,
        classNameNotes,
      },
    } = this;
    const {
      colSize,
      prodDisplay,
      heading,
      imageHeight,
      largeFont,
    }: PrintConfig = (() => {
      switch (quantityPerPage) {
        case quantitiesPerPageRecord[2]:
          return {
            ...printConfig,
            colSize: 12,
            prodDisplay: "row",
            imageHeight: "auto",
            heading: 3,
          };
        case quantitiesPerPageRecord[4]:
          return {
            ...printConfig,
            colSize: 6,
            imageHeight: "300px",
            heading: 4,
          };
        case quantitiesPerPageRecord[9]:
          return {
            ...printConfig,
            colSize: 4,
            heading: 4,
            imageHeight: "170px",
            smallFont: "1rem",
            largeFont: "1.2rem",
          };
        default:
          return printConfig;
      }
    })();

    const itemsPerPage = chunkArray(
      itemsWithPrintableProducts,
      quantityPerPage
    );
    return (
      <MDBContainer
        fluid
        style={{ height: "100%" }}
        className=" pdf-one-container  bg-white mx-auto"
      >
        {itemsPerPage.map((items, i) => {
          return (
            <MDBRow
              className={`mx-5`}
              key={i}
              style={{
                height: "100%",
                pageBreakAfter: "always",
              }}
            >
              <MDBCol className="d-flex h-100 mh-100 flex-column">
                <MDBRow>
                  <MDBCol className="py-3 d-flex justify-content-center align-content-center">
                    <a href={`${themeConfiguration.app_url}`}>
                      <img src={desktopLogoDark} alt="Logo" className="mt-5" />
                    </a>
                  </MDBCol>
                </MDBRow>
                {contactInformation && (
                  <MDBRow className="pdf-contact border-black border-bottom d-flex justify-content-between px-5">
                    <MDBCol className="d-flex flex-column">
                      <MDBRow>
                        <MDBCol>
                          <p className="font-weight-normal color-black fs-13">
                            {contactInformation.name}
                          </p>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol>
                          <p className="font-weight-normal color-black fs-13">
                            {contactInformation.companyName}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol className="d-flex flex-column justify-content-end">
                      <MDBRow>
                        <MDBCol className="d-flex justify-content-end">
                          <p className="font-weight-normal color-black-1 fs-13">
                            {contactInformation.email}
                          </p>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol className="d-flex justify-content-end">
                          <p className="font-weight-normal color-black-1 fs-13">
                            {contactInformation.phone}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                )}
                <MDBRow className="flex-grow-1 w-100 pt-5 mx-auto">
                  <MDBCol size="12">
                    <MDBRow>
                      {items.map((item) => {
                        const width = item.product.ffi_frmdimw;
                        const height = item.product.ffi_frmdimh;
                        const depth = item.product.ffi_frmdimd;

                        return (
                          <MDBCol
                            key={item.product.sku}
                            className={`col-${colSize} text-center`}
                          >
                            <a
                              style={{ textDecoration: "none" }}
                              href={`${themeConfiguration.app_url}${item.product.url_key}`}
                            >
                              <MDBRow className="align-items-center favorite-pdf-image">
                                <MDBCol
                                  size={prodDisplay === "column" ? "12" : "6"}
                                  className={`pdf-print-product-image`}
                                >
                                  {item.product.image?.url && (
                                    <img
                                      style={{
                                        height: imageHeight,

                                        maxWidth: "100%",
                                      }}
                                      src={item.product.image.url}
                                      alt="Product"
                                    />
                                  )}
                                </MDBCol>

                                <MDBCol
                                  size={prodDisplay === "column" ? "12" : "6"}
                                >
                                  {prodDisplay === "row" && (
                                    <MDBBox className="d-flex mb-4 text-center justify-content-center ">
                                      <MDBTypography
                                        tag="h1"
                                        className="color-black"
                                      >
                                        {item.product.sku}
                                      </MDBTypography>
                                    </MDBBox>
                                  )}

                                  <MDBBox className="d-flex flex-column align-items-center justify-content-center">
                                    {prodDisplay === "column" && (
                                      <MDBCol
                                        size="12"
                                        lg="6"
                                        className={`d-flex text-center p-0 mt-3 mb-1 flex-column align-content-center`}
                                      >
                                        {heading === 2 && (
                                          <MDBTypography
                                            tag="h1"
                                            className="color-black"
                                          >
                                            {item.product.sku}
                                          </MDBTypography>
                                        )}
                                        {heading === 3 && (
                                          <MDBTypography
                                            tag="h1"
                                            className="color-black"
                                          >
                                            {item.product.sku}
                                          </MDBTypography>
                                        )}
                                        {heading === 4 && (
                                          <MDBTypography
                                            tag="h4"
                                            className="color-black"
                                          >
                                            {item.product.sku}
                                          </MDBTypography>
                                        )}
                                      </MDBCol>
                                    )}
                                    {heading === 4 ? (
                                      <MDBCol className="pl-0 text-center mb-4 color-black">
                                        <MDBTypography
                                          tag="h4"
                                          className="color-black"
                                        >
                                          {item.product.name}
                                        </MDBTypography>
                                      </MDBCol>
                                    ) : (
                                      <MDBCol className="pl-0 text-center mb-4 color-black">
                                        <MDBTypography tag="h1">
                                          {item.product.name}
                                        </MDBTypography>
                                      </MDBCol>
                                    )}

                                    <MDBCol size="12">
                                      {height && depth && width && (
                                        <MDBBox className="d-flex mb-4 text-center justify-content-center">
                                          <MDBBox className="mr-3">
                                            <MDBTypography className="m-0 fs-16 body-4 font-weight-normal  color-black">
                                              Overall
                                            </MDBTypography>
                                          </MDBBox>
                                          <MDBBox className="d-flex">
                                            <MDBTypography className="mb-0 fs-16 body-4 color-black">
                                              {width}
                                            </MDBTypography>
                                            <MDBTypography className="mb-0 fs-16 body-4 color-black">
                                              {" x "}
                                            </MDBTypography>
                                            <MDBTypography className="mb-0 fs-16 body-4 color-black">
                                              {depth}
                                            </MDBTypography>
                                            <MDBTypography className="mb-0 fs-16 body-4 color-black">
                                              {" x "}
                                            </MDBTypography>
                                            <MDBTypography className="mb-0 fs-16 body-4 color-black">
                                              {height}
                                            </MDBTypography>
                                          </MDBBox>
                                        </MDBBox>
                                      )}
                                    </MDBCol>
                                    {!!item.product.price_range.minimum_price
                                      .final_price.value &&
                                      this.showPrice(
                                        // @ts-ignore
                                        item.product?.categories
                                      ) &&
                                      (pricing === true ? (
                                        <MDBCol
                                          size="12"
                                          className="d-flex mb-4 justify-content-center align-items-center"
                                        >
                                          <MDBBox>
                                            <MDBTypography className="body-1 mr-3 fs-13 color-black">
                                              Starting at{" "}
                                            </MDBTypography>
                                          </MDBBox>
                                          <MDBBox>
                                            <p
                                              className="color-black m-0"
                                              style={{
                                                fontSize: largeFont,
                                                color: "black",
                                              }}
                                            >
                                              <Price
                                                price={
                                                  item.product.price_range
                                                    .minimum_price.final_price
                                                    .value
                                                }
                                                onlyValues={true}
                                                type={"fixed"}
                                              />
                                            </p>
                                          </MDBBox>
                                        </MDBCol>
                                      ) : typeof pricing === "number" ? (
                                        <MDBCol
                                          size="12"
                                          className="d-flex pr-0 justify-content-center align-items-center"
                                        >
                                          <MDBBox>
                                            <MDBTypography className="body-1 mr-3 fs-13 color-black">
                                              Starting at{" "}
                                            </MDBTypography>
                                          </MDBBox>
                                          <p
                                            className="text-primary m-0 color-black"
                                            style={{ fontSize: largeFont }}
                                          >
                                            <Price
                                              price={
                                                item.product.price_range
                                                  .minimum_price.final_price
                                                  .value * pricing
                                              }
                                              onlyValues={true}
                                              type={"fixed"}
                                            />
                                          </p>
                                        </MDBCol>
                                      ) : null)}
                                  </MDBBox>
                                </MDBCol>
                              </MDBRow>
                            </a>
                          </MDBCol>
                        );
                      })}
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
                {message && (
                  <MDBRow
                    className={`mt-auto notes-container justify-content-center align-items-center ${classNameNotes} `}
                  >
                    <MDBCol size="1">
                      <MDBTypography className="mb-0 fs-16 font-weight-normal color-black">
                        NOTES
                      </MDBTypography>
                    </MDBCol>
                    <MDBCol size="11" className="">
                      <MDBTypography className="fs-13 mb-0 color-black">
                        {message}
                      </MDBTypography>
                    </MDBCol>
                  </MDBRow>
                )}
                <MDBBox className="footer-pdf-quote">
                  <MDBRow
                    className={
                      "mt-auto justify-content-center footer-pdf-quote-inner"
                    }
                  >
                    <MDBCol>
                      <MDBBox className="footer-pdf-quote w-100">
                        <MDBBox className={"footer-pdf-quote-inner "}>
                          <MDBBox className="border-top border-black my-2" />
                          <MDBBox className="py-3 d-flex justify-content-center text-white align-content-center">
                            <MDBTypography className="mb-1 fs-16 body-4 color-black">
                              FairfieldChair.com
                            </MDBTypography>
                          </MDBBox>
                        </MDBBox>
                      </MDBBox>
                    </MDBCol>
                  </MDBRow>
                </MDBBox>
              </MDBCol>
            </MDBRow>
          );
        })}
      </MDBContainer>
    );
  }
}

export default Print;
