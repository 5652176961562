import types from "./types";

import changeSearch from "./reducers/changeSearch";
import toggleSearch from "./reducers/toggleSearch";
import saveSearch from "./reducers/saveSearch";
import loadMoreItems from "./reducers/loadMoreItems";

const searchReducer = (state, action) => {
  if (state === undefined) {
    return {};
  }

  if (action.type === types.CHANGE_SEARCH) {
    return changeSearch(state, action);
  }

  if (action.type === types.TOGGLE_SEARCH) {
    return toggleSearch(state, action);
  }

  if (action.type === types.SAVE_SEARCH) {
    return saveSearch(state, action);
  }

  if (action.type === types.LOAD_MORE_ITEMS) {
    return loadMoreItems(state, action);
  }

  return state;
};

export default searchReducer;
