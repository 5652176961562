import { createEnterHandler } from "app/utils/utils";
import { MDBTypography } from "mdbreact";
import React from "react";
export interface SnackbarContentProps {
  onAddToProjects: React.EventHandler<React.SyntheticEvent>;
  firstOption: React.ReactNode;
}

const AddedToProjectsSnackbar: React.FC<SnackbarContentProps> = (props) => {
  return (
    <div className="d-flex align-items-center">
      <MDBTypography className="mb-0 mr-3 pr-2 fs-16 fwregular text-white">
        Added to favorites
      </MDBTypography>
      {props.firstOption}
      <div
        onClick={props.onAddToProjects}
        onKeyUp={createEnterHandler(props.onAddToProjects)}
        className="ml-5"
        tabIndex={0}
        role="button"
      >
        <span className="pointer fs-14 fwregular underline text-white">
          Add to Lists
        </span>
      </div>
    </div>
  );
};

export default AddedToProjectsSnackbar;
