import React from "react";
import {
    MDBContainer,
  } from "mdbreact";
const SocialIcons = () => {
  return (
    <MDBContainer className="d-flex justify-content-around footer-social-icons-wrapper">
    <a
      href={"https://www.facebook.com/fairfieldchair/"}
      target="_blank"
      without
      rel="noopener noreferrer"
    >
      <i className="fab fa-facebook-f" />
    </a>
    <a
      href={"http://pinterest.com/fairfieldchair"}
      target="_blank"
      without
      rel="noopener noreferrer"
    >
      <i className="fab fa-pinterest" />
    </a>
    <a
      href={"https://www.instagram.com/fairfieldchair"}
      target="_blank"
      without
      rel="noopener noreferrer"
    >
      <i className="fab fa-instagram" />
    </a>
    <a
      href={"https://twitter.com/fairfieldchair"}
      target="_blank"
      without
      rel="noopener noreferrer"
    >
      <i className="fab fa-twitter" />
    </a>
  </MDBContainer>
  );
};

export default SocialIcons;
