import {
  Aggregation,
  AggregationOption,
  Maybe,
  SortEnum,
} from "app/generated/graphql";
import { NonNullableKeys } from "app/models/models";
import React from "react";
import { createSelector } from "reselect";
import * as yup from "yup";

const lanes = ["", "ffi_laners", "ffi_lanect", "ffi_lanesl"] as const;
type Lanes = typeof lanes;
type Lane = Lanes[number];
export interface IFilter {
  lane: Lane;
  is_new: boolean;
  in_stock_filter: boolean;
  ffi_assem_req: boolean;
  ffi_leatheravl: boolean;
  ffi_collection: string[];
  dimensions: string;
  ffifabrics_collection: string[];
  ffifabrics_grade: string[];
  ffifabrics_category1: string[];
  ffifabrics_hexclrgrp: string[];
  ffifabrics_finishtype: string[];
  fficustopt_type_filterable: string[];
  price: Maybe<{ from: number; to: number }>;
  ffifabrics_avail_filter_bucket: Maybe<{ from: number; to: number }>;
  ffi_qty_filter_bucket: Maybe<{ from: number; to: number }>;
  ffi_width_filter_bucket: Maybe<{ from: number; to: number }>;
  ffi_height_filter_bucket: Maybe<{ from: number; to: number }>;
  ffi_depth_filter_bucket: Maybe<{ from: number; to: number }>;
  category_id_product: string;
  ready_to_ship_filter: boolean;
  product_features: string[];
  ffi_product_type_filter: string[];
}
export type FilterParams = Partial<
  Omit<
    IFilter,
    | "is_new"
    | "in_stock_filter"
    | "ffi_assem_req"
    | "ffi_leatheravl"
    | "ffi_collection"
    | "ffifabrics_collection"
    | "ffifabrics_grade"
    | "ffifabrics_category1"
    | "ffifabrics_hexclrgrp"
    | "ffifabrics_finishtype"
    | "fficustopt_type_filterable"
    | "price"
    | "ffifabrics_avail_filter_bucket"
    | "ffi_qty_filter_bucket"
    | "category_id_product"
    | "ready_to_ship_filter"
    | "ffi_qty_filter_bucket"
    | "ffi_width_filter_bucket"
    | "ffi_height_filter_bucket"
    | "ffi_depth_filter_bucket"
    | "dimensions"
    | "product_features"
    | "ffi_product_type_filter"
  > & {
    is_new: string;
    in_stock_filter: string;
    ffi_assem_req: string;
    ffi_leatheravl: string;
    ffi_collection: string;
    ffifabrics_collection: string;
    ffifabrics_grade: string;
    ffifabrics_category1: string;
    ffifabrics_hexclrgrp: string;
    ffifabrics_finishtype: string;
    fficustopt_type_filterable: string;
    price: string;
    ffifabrics_avail_filter_bucket: string;
    ffi_qty_filter_bucket: string;
    ffi_width_filter_bucket: string;
    ffi_height_filter_bucket: string;
    ffi_depth_filter_bucket: string;
    category_id_product: string;
    ready_to_ship_filter: string;
    dimensions: string;
    product_features: any;
    ffi_product_type_filter: any;
  }
>;
const booleanSchema = yup.string().oneOf([String(true), String(false)]);
const arraySchema = yup.string().matches(/^([^,]+|([^,]+(,[^,]+)*))$/);
export const filterSchema: yup.ObjectSchemaDefinition<FilterParams> = {
  lane: yup.string().oneOf(lanes),
  is_new: booleanSchema,
  in_stock_filter: booleanSchema,
  ffi_assem_req: booleanSchema,
  ffi_leatheravl: booleanSchema,
  ffi_collection: arraySchema,
  ffifabrics_collection: arraySchema,
  ffifabrics_grade: arraySchema,
  ffifabrics_category1: arraySchema,
  ffifabrics_hexclrgrp: arraySchema,
  ffifabrics_finishtype: arraySchema,
  fficustopt_type_filterable: arraySchema,
  price: yup.string().matches(/^[^,]+,[^,]+$/),
  category_id_product: yup.string(),
  ready_to_ship_filter: booleanSchema,
  product_features: arraySchema,
  ffi_product_type_filter: arraySchema
};

const getLaneFilter = (lane: IFilter["lane"]) => {
  return lane
    ? { industry_filter: { in: lane } }
    : { industry_filter: { in: [] } };
};

export const getQueryAggregations = (categoryId: string, filter: any) => {
  return {
    category_id: {
      // @ts-ignore
      eq: filter?.category_id_product
        ? // @ts-ignore
          filter?.category_id_product
        : categoryId,
    },
    ...(filter.ffifabrics_category1
      ? { ffifabrics_category1: { in: filter.ffifabrics_category1 } }
      : {}),
    ...{ industry_filter: { in: [] } },
  };
};

export const getQueryFilter = (categoryId: string, filter: IFilter) => {
  return {
    category_id: {
      // @ts-ignore
      eq: filter?.category_id_product
        ? // @ts-ignore
          filter?.category_id_product
        : categoryId,
    },
    ...(filter.ffi_collection.length
      ? { ffi_collection: { in: filter.ffi_collection } }
      : {}),
    ...(filter.product_features.length
      ? { product_features: { in: filter.product_features } }
      : {}),
      ...(filter.ffi_product_type_filter.length
        ? { ffi_product_type_filter: { in: filter.ffi_product_type_filter } }
        : {}),

    // @ts-ignore
    ...getLaneFilter(filter.lane),
    ...(filter.is_new ? { is_new: { eq: "1" } } : {}),
    ...(filter.in_stock_filter ? { in_stock_filter: { eq: "1" } } : {}),
    ...(filter.ready_to_ship_filter
      ? { ready_to_ship_filter: { eq: "1" } }
      : {}),
    ...(filter.ffi_assem_req ? { ffi_assem_req: { eq: "1" } } : {}),
    ...(filter.ffi_leatheravl ? { ffi_leatheravl: { eq: "1" } } : {}),
    ...(filter.ffifabrics_collection.length
      ? { ffifabrics_collection: { in: filter.ffifabrics_collection } }
      : {}),
    ...(filter.ffifabrics_grade.length
      ? { ffifabrics_grade: { in: filter.ffifabrics_grade } }
      : {}),
    ...(filter.ffifabrics_grade.length
      ? { ffifabrics_grade: { in: filter.ffifabrics_grade } }
      : {}),
    ...(filter.fficustopt_type_filterable.length
      ? {
          fficustopt_type_filterable: { in: filter.fficustopt_type_filterable },
        }
      : {}),
    ...(filter.ffifabrics_hexclrgrp.length
      ? { ffifabrics_hexclrgrp: { in: filter.ffifabrics_hexclrgrp } }
      : {}),
    ...(filter.ffifabrics_finishtype.length
      ? { ffifabrics_finishtype: { in: filter.ffifabrics_finishtype } }
      : {}),
    ...(filter.price
      ? { price: { from: filter.price.from, to: filter.price.to } }
      : {}),
    ...(filter.ffifabrics_avail_filter_bucket
      ? {
          ffifabrics_avail_filter: {
            from: filter.ffifabrics_avail_filter_bucket.from,
            to: filter.ffifabrics_avail_filter_bucket.to,
          },
        }
      : {}),
    ...(filter.ffi_qty_filter_bucket
      ? {
          ffi_qty_filter: {
            from: filter.ffi_qty_filter_bucket.from,
            to: filter.ffi_qty_filter_bucket.to,
          },
        }
      : {}),
    ...(filter.ffi_width_filter_bucket
      ? {
          ffi_width_filter: {
            from: filter.ffi_width_filter_bucket.from,
            to: filter.ffi_width_filter_bucket.to,
          },
        }
      : {}),
    ...(filter.ffi_height_filter_bucket
      ? {
          ffi_height_filter: {
            from: filter.ffi_height_filter_bucket.from,
            to: filter.ffi_height_filter_bucket.to,
          },
        }
      : {}),
    ...(filter.ffi_depth_filter_bucket
      ? {
          ffi_depth_filter: {
            from: filter.ffi_depth_filter_bucket.from,
            to: filter.ffi_depth_filter_bucket.to,
          },
        }
      : {}),
    ...(filter.ffifabrics_category1
      ? { ffifabrics_category1: { in: filter.ffifabrics_category1 } }
      : {}),
  };
};

export interface Sort {
  key: "name" | "price" | "ffi_introdat" | "sku" | "relevance";
  direction: SortEnum;
}

export interface SortOption {
  label: string;
  value: string;
}

export const getSortOptions = (
  isLoggedIn: boolean,
  location: any
): SortOption[] => [
  { label: "Name A-Z", value: `name,${SortEnum.Asc}` }, // * this is how the BE filtration works
  { label: "Name Z-A", value: `name,${SortEnum.Desc}` },
  // { label: "Introduction Date Asc", value: `ffi_introdat,${SortEnum.Asc}` },
  { label: "Newest", value: `introdat_filter,${SortEnum.Desc}` },
  { label: "SKU Asc", value: `sku,${SortEnum.Asc}` },
  { label: "SKU Desc", value: `sku,${SortEnum.Desc}` },

  ...(isLoggedIn &&
  !location.pathname.includes("fabrics-finishes/") &&
  !location.pathname.includes("/custom-options")
    ? [
        { label: "Price Lower-Higher", value: `price,${SortEnum.Asc}` },
        { label: "Price Higher-Lower", value: `price,${SortEnum.Desc}` },
      ]
    : []),
];

export const getSortFromOption = (option: SortOption) => {
  const [key, direction] = option.value.split(",");

  return { key, direction } as Sort;
};

export enum Filters {
  none = "none",
  lane = "lane",
  ffi_collection = "ffi_collection",
  ffifabrics_collection = "ffifabrics_collection",
  ffifabrics_grade = "ffifabrics_grade",
  ffifabrics_category1 = "ffifabrics_category1",
  fficustopt_type_filterable = "fficustopt_type_filterable",
  ffifabrics_hexclrgrp = "ffifabrics_hexclrgrp",
  ffifabrics_finishtype = "ffifabrics_finishtype",
  price = "price",
  ffifabrics_avail_filter_bucket = "ffifabrics_avail_filter_bucket",
  ffi_qty_filter_bucket = "ffi_qty_filter_bucket",
  ffi_width_filter_bucket = "ffi_width_filter_bucket",
  ffi_height_filter_bucket = "ffi_height_filter_bucket",
  ffi_depth_filter_bucket = "ffi_depth_filter_bucket",
  dimensions = "dimensions",
  product_features = "product_features",
  ffi_product_type_filter = "ffi_product_type_filter"
}

export const initialFilter: IFilter = {
  lane: "",
  is_new: false,
  in_stock_filter: false,
  ffi_assem_req: false,
  ffi_leatheravl: false,
  ffi_collection: [],
  ffifabrics_collection: [],
  ffifabrics_grade: [],
  ffifabrics_category1: [],
  ffifabrics_hexclrgrp: [],
  ffifabrics_finishtype: [],
  fficustopt_type_filterable: [],
  price: null,
  ffifabrics_avail_filter_bucket: null,
  ffi_qty_filter_bucket: null,
  ffi_width_filter_bucket: null,
  ffi_height_filter_bucket: null,
  ffi_depth_filter_bucket: null,
  category_id_product: "",
  ready_to_ship_filter: false,
  dimensions: "",
  product_features: [],
  ffi_product_type_filter: []
};

export const initialSort: Sort = {
  key: "name",
  direction: SortEnum.Asc,
};

export interface IOption {
  label: React.ReactNode;
  value: string;
}
export type Options = Array<IOption>;
export interface OptionWithChildren extends IOption {
  children: IOption[];
}

export const initialOptions: Options = [];

export const getMultiValue = (options: Options, selected: IOption["value"][]) =>
  options.filter((option) => selected?.includes(option.value));

export const prependAllOption = (
  // count: Aggregation["count"], // this is removed because we didn't want count next to "All" label
  options: Options
) => {
  return [{ label: appendCount("All"), value: "" }, ...options];
};
export type AttributeCode =
  | Exclude<keyof IFilter, "lane">
  | Exclude<Lane, "all">;

export const getAggregation = (
  attribute_code: AttributeCode,
  aggregations: Array<Maybe<Aggregation>>
) =>
  aggregations?.find(
    (aggregation) => aggregation?.attribute_code === attribute_code
  );
export const mapAggregationOptions = (
  attribute_code: AttributeCode,
  mapOptions: (options: Aggregation["options"]) => Options,
  aggregations: Array<Maybe<Aggregation>>
) => {
  const aggregation = getAggregation(attribute_code, aggregations);
  if (attribute_code === 'product_features') {
    return [
      !!aggregation?.options?.length,
      mapOptions(aggregation?.options?.filter(o => o?.label !== 'Default')),
    ] as const;
  } else {
    return [
      !!aggregation?.options?.length,
      prependAllOption(mapOptions(aggregation?.options)),
    ] as const;
  }
};
const appendCount = (
  // count: AggregationOption["count"], // this is removed because we didn't want count next to "All" label
  label: AggregationOption["label"]
) => label && `${label}`;
export const appendCountToOptionsLabel = (options: AggregationOption[]) =>
  options.map((option) => ({
    ...option,
    // label: appendCount(option.label) + ` (${option.count})`, // Count is displayed only for options
    // Quick fix count needs to be removed on plp, TODO new function for mapping options without appendCount
    label: option.label
  }));
const isAggregationOption = (
  option: NonNullable<Aggregation["options"]>[number]
): option is NonNullableKeys<AggregationOption, "label"> => !!option?.label;
export const appendCountToMaybeOptionsLabel = (
  options: Aggregation["options"]
) => appendCountToOptionsLabel(options?.filter(isAggregationOption) ?? []);
export const mapGradeAggregationOptions = (options: Aggregation["options"]) => {
  return (
    options
      ?.filter(isAggregationOption)
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      })
      ?.map((option) => ({
        ...option,
        label: option.label && `Grade: ${appendCount(option.label)}`,
      })) ?? []
  );
};
export const mapPatternAggregationOptions = (
  options: Aggregation["options"]
) => {
  return appendCountToOptionsLabel(
    options?.filter(isAggregationOption).sort((a, b) => {
      return Number(a.label) - Number(b.label);
    }) ?? []
  );
};
export const mapHexclrgrpAggregationOptions = (
  options: Aggregation["options"]
) => {
  return (
    options?.filter(isAggregationOption).map((option) => {
      return {
        ...option,
        // label: (
        //   <div className="d-flex align-items-center">
        //     <div
        //       className="border-radius-05 border-gray-1 mr-3 size-18"
        //       style={{ backgroundColor: `#${option.label}` }}
        //     />
        //     {appendCount(option.count, option.label)}
        //   </div>
        // ),
      };
    }) ?? []
  );
};

export const mapFabricFinishTypeAggregationOptions = (
  options: Aggregation["options"]
) => {
  return (
    options?.filter(isAggregationOption).map((option) => {
      return {
        ...option,
      };
    }) ?? []
  );
};

export const getHasAggregation = (
  attribute_code: AttributeCode,
  aggregations: Array<Maybe<Aggregation>>
) => !!getAggregation(attribute_code, aggregations)?.count;

export const getNewFilterHasAggregation = (
  aggregations: Array<Maybe<Aggregation>>
) => {
  const newFilterFound = aggregations?.find(
    (aggregation) => aggregation?.attribute_code === "is_new"
  );

  if (!!newFilterFound) {
    if (
      newFilterFound.count !== null &&
      newFilterFound.count !== undefined &&
      newFilterFound?.count >= 1
    ) {
      return newFilterFound?.count;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const selectDefaultCategory = (state: any) => {
  return state?.queries?.data[2]?.data?.categoryList[0];
};
const fabricsAndFinishes = "Fabrics & Finishes";
export const selectFabrics = createSelector(
  selectDefaultCategory,
  (defaultCategory) => {
    return defaultCategory?.children.find(
      (child: any) => child?.name === fabricsAndFinishes
    );
  }
);
export const selectProducts = createSelector(
  selectDefaultCategory,
  (defaultCategory) => {
    const shopBy = defaultCategory.children?.[0];
    const products = shopBy?.children?.[1];

    return products;
  }
);
