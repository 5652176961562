import {
  DomainWishlistFragment,
  DomainWishlistItemFragment,
  ProductImage,
} from "app/generated/graphql";
import { favoritesName } from "../../config";
import { ProductWithSku } from "../../models";
import productPlaceholder from "./assets/product-placeholder.png";

export const image: ProductImage = {
  url: productPlaceholder,
  label: "Placeholder Product",
};

export const product: ProductWithSku = {
  price_range: { minimum_price: { final_price: {} } },
  small_image: image,
  sku: "C-6301-54",
  name: "Derring Laf Corner Sofa",
};

export const item: DomainWishlistItemFragment = {
  id: "0",
  product,
};

export const item1: DomainWishlistItemFragment = {
  id: "1",
  product: {
    price_range: { minimum_price: { final_price: {} } },
    small_image: image,
    sku: "C-6301-55",
    name: "Derring Laf Corner Sofa",
  },
};

export const wishlist: DomainWishlistFragment = {
  name: "Project Name",
  items_count: "0",
  items: [item],
};

export const favorites: DomainWishlistFragment = {
  name: favoritesName,
  items_count: "1",
  items: [item1],
};
