import React from "react";
import { searchActions } from "core/state/redux/data/search";
import { connect } from "react-redux";
import HeaderQueryContainer from "./header-query-container";

const HeaderReduxContainer = (props) => {
  return <HeaderQueryContainer {...props} />;
};
const mapStateToProps = (state) => ({
  state: state,
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSearch: () => {
      dispatch(searchActions.toggleSearch(false));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderReduxContainer);
