import React, { useMemo } from "react";
import { PRODUCT, CUSTOMER_PRODUCT } from "./actions";
import { useQuery } from "@apollo/react-hooks";

function InStock({ isLoggedIn, sku, base }) {
  let skuArray = useMemo(() => [sku, base?.sku], [sku, base]);

  const { data: productData, loading, error } = useQuery(
    isLoggedIn ? CUSTOMER_PRODUCT : PRODUCT,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        filter: {
          sku: {
            in: skuArray,
          },
        },
      },
    }
  );

  let inStockLogicLiveEdge = useMemo(() => {
    const products = productData?.products?.items;
    if (products?.every((product) => Number(product?.ffi_avails) > 3)) {
      if (
        products?.some((product) => product?.ffi_status === "NEW NOT APPROVE")
      )
        return "Coming Soon";
      else
        return `As Shown Available: ${Math.min(
          ...[
            ...products.reduce(
              (acc, curr) => [...acc, Number(curr?.ffi_avails)],
              []
            ),
          ]
        )}`;
    } else {
      if (products?.every((product) => Number(product?.ffi_onords) > 0)) {
        let dates = [
          ...products.reduce((acc, curr) => [...acc, curr?.ffi_duedate], []),
        ];
        if (dates.every((date) => !date)) return "Coming Soon";
        let date = dates[0] > dates[1] || !dates[1] ? dates[0] : dates[1];
        date = date?.split("/");
        if (+date?.[1] > 26) {
          date[1] = "1";
          if (+date[0] === 12) {
            date[0] = 1;
            date[2] = +date[2] + 1;
          } else {
            date[0] = +date[0] + 1;
          }
        }
        date = date && `${date?.[0]}/${date?.[2]}`;
        return `As Shown Available: ${date}`;
      }
      return "Out of Stock";
    }
  }, [productData]);
  return loading ? <>...</> : inStockLogicLiveEdge;
}

export default InStock;
