import apolloClient from "core/graphql/apolloClient";
import customerActions from "../../actions";
import commonActions from "../../../common/actions";
import messagesActions from "../../../messages/actions";
import queryActions from "../../../queries/actions";
import { gql } from "graphql.macro";

const SET_BILLING_ADDRESS_ON_CART = gql`
  mutation SetBillingAddressOnCart($input: SetBillingAddressOnCartInput) {
    setBillingAddressOnCart(input: $input) {
      cart {
        id
        prices {
          subtotal_including_tax {
            currency
            value
          }
          subtotal_excluding_tax {
            currency
            value
          }
          grand_total {
            currency
            value
          }
          applied_taxes {
            amount {
              currency
              value
            }
            label
          }
          discounts {
            amount {
              currency
              value
            }
            label
          }
        }
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          street
          telephone
        }
      }
    }
  }
`;
const SET_BILLING_ADDRESS_ON_CART_WITH_SHIPPING = gql`
  mutation SetBillingAddressOnCart($input: SetBillingAddressOnCartInput) {
    setBillingAddressOnCart(input: $input) {
      cart {
        id
        prices {
          subtotal_including_tax {
            currency
            value
          }
          subtotal_excluding_tax {
            currency
            value
          }
          grand_total {
            currency
            value
          }
          applied_taxes {
            amount {
              currency
              value
            }
            label
          }
          discounts {
            amount {
              currency
              value
            }
            label
          }
        }
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          street
          telephone
        }
        shipping_addresses {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          street
          telephone
          region {
            code
            label
          }
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_excl_tax {
              currency
              value
            }

            price_incl_tax {
              currency
              value
            }
          }
          selected_shipping_method {
            amount {
              currency
              value
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
      }
    }
  }
`;

export default async (store, action) => {
  try {
    let USED_QUERY = SET_BILLING_ADDRESS_ON_CART;
    let cart_id = store.getState().customer.data.cartToken;

    let input = {
      cart_id: cart_id,
      billing_address: { ...action.billing_address },
    };

    if (input.billing_address.same_as_shipping) {
      USED_QUERY = SET_BILLING_ADDRESS_ON_CART_WITH_SHIPPING;
    }

    const { data } = await apolloClient.mutate({
      mutation: USED_QUERY,
      variables: { input: input },
      fetchPolicy: "no-cache",
    });
    if (data.setBillingAddressOnCart !== null) {
      let newCart = {
        ...store.getState().customer.data.cart,
        ...data.setBillingAddressOnCart.cart,
      };
      store.dispatch(customerActions.setCartInformation(newCart));
      store.dispatch(
        messagesActions.addMessage("updated cart information", "success")
      );
      store.dispatch(commonActions.unlock("success"));
    }
  } catch (error) {
    if (error.message.includes("Could not find a cart with ID")) {
      store.dispatch(customerActions.createEmptyCart());
    } else {
      store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    }
    store.dispatch(commonActions.unlock("fail"));
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
