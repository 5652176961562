import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import CmsPage from "app/pages/cms/";

const ErrorPage = (props) => {
  // eslint-disable-next-line no-unused-vars
  let loggedIn = false;
  if (typeof props.customer !== "undefined") loggedIn = false;
  if (typeof props.customer === "object") {
    if ("token" in props.customer) {
      if (props.customer.token && props.customer.token !== null) {
        loggedIn = true;
      }
    }
  }
  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol>
          <CmsPage dangHtml={true} id={1} />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ErrorPage;
