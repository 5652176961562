import React from "react";
import { Link } from "react-router-dom";
import Icon from "app/assets/icon/icon";
import { MDBBtn, MDBIcon } from "mdbreact";

const Button = (props) => {
  return (
    <>
      {(props.to && (
        <Link className={"btn-link"} to={props.to}>
          <MDBBtn {...props} className={props.className}>
            {props.leftIcon && (
              <>
                {(props.icon && (
                  <MDBIcon icon={props.icon} className={`${props.direction}`} />
                )) || <Icon icon={props.id} />}
                {!props.children && props.text}
              </>
            )}
            {props.children}
            {props.righticon && (
              <>
                {!props.children && props.text}
                {(props.icon && (
                  <MDBIcon icon={props.icon} className={`${props.direction}`} />
                )) || <Icon icon={props.id} />}
              </>
            )}
          </MDBBtn>
        </Link>
      )) || (
        <MDBBtn {...props} className={props.className}>
          {props.leftIcon && (
            <>
              {(props.icon && (
                <MDBIcon icon={props.icon} className={`${props.direction}`} />
              )) || <Icon icon={props.id} />}
              {!props.children && props.text}
            </>
          )}
          {props.children}
          {props.righticon && (
            <>
              {!props.children && props.text}
              {(props.icon && (
                <MDBIcon icon={props.icon} className={`${props.direction}`} />
              )) || <Icon icon={props.id} />}
            </>
          )}
        </MDBBtn>
      )}
    </>
  );
};

export default Button;
