import React from "react";
import MediaGallery from "app/layout/mediaGallery";

const Gallery = ({ gallery, hideLabel = false }) => {
  if (gallery?.length) {
    return (
      <>
        <div className={"gallery-container"}>
          <MediaGallery images={gallery} hideLabel={hideLabel} />
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default Gallery;
