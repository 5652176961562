import { ApolloError } from "apollo-client";
import {
  CreationCustomerFragment,
  CustomerInput,
  GenerateCustomerTokenMutationFn,
  Maybe,
  UpdateCustomerAadChangePasswordMutationFn,
  UpdateCustomerMutationFn,
} from "app/generated/graphql";
import { customerActions } from "app/state/redux/data/customer";
import { FormikConfig, useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import AccountInfo, { AccountInfoProps } from "./AccountInfo";
import { messagesActions } from "core/state/redux/data/messages";
import {
  accountInfoSchema,
  AccountInfoValues,
  initialAccountInfoValues,
  initialChangePasswordValues,
  initialChangeEmailValues,
} from "./config";
import { useMutation } from "react-apollo-hooks";
import queryLoader from "app/graphql/queryLoader";

export interface StateContainerProps extends Omit<AccountInfoProps, "form"> {
  customer: Maybe<CreationCustomerFragment>;
  updateCustomer: UpdateCustomerMutationFn;
  updateCustomerAndChangePassword: UpdateCustomerAadChangePasswordMutationFn;
  generateCustomerToken: GenerateCustomerTokenMutationFn;
}

const StateContainer: React.FC<StateContainerProps> = (props) => {
  const dispatch = useDispatch();
  const [updateCustomerEmailMutation] = useMutation(
    queryLoader("updateCustomerEmail")
  );
  // @ts-ignore
  const updateEmailAndOrPasswordHandler = (
    // @ts-ignore
    action,
    // @ts-ignore
    setFieldValue,
    // @ts-ignore
    setFieldTouched,
    // @ts-ignore
    setFieldError
  ) =>
    new Promise((resolve, reject) => resolve(action))
      .then((result) => {
        // @ts-ignore
        if (result.data?.generateCustomerToken?.token) {
          dispatch(
            customerActions._setCustomerToken(
              // @ts-ignore
              result.data.generateCustomerToken.token
            )
          );
          dispatch(
            messagesActions.addMessage(
              "Password successfully updated!",
              "success"
            )
          );
        } else {
          console.error(
            `Could not log in because email value is "${props.customer?.email}".`
          );
        }
        return result;
      })
      .then((result) => {
        // @ts-ignore
        setFieldTouched("changeEmail", false);
        // @ts-ignore
        setFieldValue("changeEmail", initialChangeEmailValues);
        // @ts-ignore
        setFieldTouched("changePassword", false);
        // @ts-ignore
        setFieldValue("changePassword", initialChangePasswordValues);
        return result;
      })
      .catch((error: ApolloError) => {
        // @ts-ignore
        setFieldError(
          "changePassword.currentPassword",
          error.graphQLErrors[0]?.message
        );
        return error;
      });

  const config: FormikConfig<AccountInfoValues> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues: {
        ...initialAccountInfoValues,
        firstname:
          props.customer?.firstname || initialAccountInfoValues.firstname,
        lastname: props.customer?.lastname || initialAccountInfoValues.lastname,
      },
      validationSchema: accountInfoSchema,
      onSubmit: (
        { firstname, lastname, changePassword, changeEmail },
        { setFieldValue, setFieldTouched, setFieldError }
      ) => {
        const input: CustomerInput = {
          firstname,
          lastname,
        };
        if (
          props.customer?.firstname !== firstname ||
          props.customer?.lastname !== lastname
        )
          props
            .updateCustomer({
              variables: {
                input,
              },
            })
            .then(() =>
              dispatch(
                messagesActions.addMessage(
                  "Account information successfully updated!",
                  "success"
                )
              )
            );

        if (changePassword && changeEmail) {
          return updateCustomerEmailMutation({
            variables: {
              email: changeEmail?.email,
              password: changeEmail?.currentPassword,
            },
          })
            .then(() =>
              updateEmailAndOrPasswordHandler(
                props.updateCustomerAndChangePassword({
                  variables: {
                    input,
                    currentPassword: changePassword.currentPassword,
                    newPassword: changePassword.newPassword,
                    email: changeEmail?.email,
                  },
                }),
                setFieldValue,
                setFieldTouched,
                setFieldError
              )
            )
            .catch((err) =>
              dispatch(
                messagesActions.addMessage(
                  err.graphQLErrors[0]?.message,
                  "error"
                )
              )
            );
        } else if (changeEmail) {
          return updateCustomerEmailMutation({
            variables: {
              email: changeEmail?.email,
              password: changeEmail?.currentPassword,
            },
          })
            .then(() => {
              setFieldTouched("changeEmail", false);
              setFieldValue("changeEmail", initialChangeEmailValues);
              dispatch(
                messagesActions.addMessage(
                  "Email successfully updated!",
                  "success"
                )
              );
            })
            .catch((err) =>
              dispatch(
                messagesActions.addMessage(
                  err.graphQLErrors[0]?.message,
                  "error"
                )
              )
            );
        } else if (changePassword) {
          if (props.customer?.email)
            return updateEmailAndOrPasswordHandler(
              props.updateCustomerAndChangePassword({
                variables: {
                  input,
                  currentPassword: changePassword.currentPassword,
                  newPassword: changePassword.newPassword,
                  email: props.customer?.email,
                },
              }),
              setFieldValue,
              setFieldTouched,
              setFieldError
            ).catch((err) =>
              dispatch(
                messagesActions.addMessage(
                  err.graphQLErrors[0]?.message,
                  "error"
                )
              )
            );
          else
            console.error(
              `Cannot generate token since email is "${props.customer}".`
            );
        }
      },
    }),
    [props, dispatch]
  );
  const form = useFormik(config);

  return <AccountInfo {...props} form={form} />;
};

export default StateContainer;
