import React from "react";
import { connect } from "react-redux";
import actions from "core/state/redux/data/messages/actions";
import SessionMessages from "./sessionMessages";

const SessionMessagesReduxContainer = props => {
  return <SessionMessages {...props} />;
};

const mapStateToProps = state => {
  return {
    messages: state.messages.items
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onMessageClose: (messageText, messageType) => {
      dispatch(actions.removeMessage(messageText, messageType));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionMessagesReduxContainer);
