import React, { useState, useEffect } from "react";
import { useGetFabricOrFinishFromSku } from "../../../../utils";
import { pure } from "recompose";
import Sidebar from "./components/sidebar";
import {
  frameNailSizeArray,
  nailFinishArray,
} from "app/pages/Custom/haven/data.js";

const generateNailSizeOption = (val) => ({
  name: frameNailSizeArray.find((nailSize) => nailSize?.name === val?.title)
    ?.name,
  id: val?.option_type_id,
  image: {
    url: frameNailSizeArray.find((nailSize) => nailSize?.name === val?.title)
      ?.icon,
  },
  grade: ["false"],
  pattern: ["false"],
  map: frameNailSizeArray.find((nailSize) => nailSize?.name === val?.title)
    ?.map,
  icon: frameNailSizeArray.find((nailSize) => nailSize?.name === val?.title)
    ?.icon,
  size: frameNailSizeArray.find((nailSize) => nailSize?.name === val?.title)
    ?.size,
});

const Selector = ({
  option,
  optionSku,
  productImage,
  optionDispatch,
  optionState,
  isCustom,
  player,
  isFinish = false,
  afterFirstOpenOptionState = false,
  sku,
  leatherAvailable,
  isPelleProvisions,
  finishOption,
  customName,
  isOutdoor = false,
}) => {
  const [visible, setVisible] = useState(false);
  const selOption = useGetFabricOrFinishFromSku(optionSku);

  const selectedOption =
    !selOption && typeof optionSku === "number"
      ? generateNailSizeOption(
          option?.value?.find((val) => val.option_type_id === optionSku)
        )
      : selOption;
  return (
    <>
      <Sidebar
        visible={visible}
        setVisible={setVisible}
        option={option}
        selectedOption={selectedOption}
        productImage={productImage}
        optionDispatch={optionDispatch}
        key={option?.id}
        isCustom={isCustom}
        player={player}
        optionState={optionState}
        isFinish={isFinish}
        afterFirstOpenOptionState={afterFirstOpenOptionState}
        sku={sku}
        leatherAvailable={leatherAvailable}
        isPelleProvisions={isPelleProvisions}
        finishOption={finishOption}
        customName={customName}
        isOutdoor={isOutdoor}
      />
      <div className={`selector-container ${isFinish && "finish"}`}>
        <h2 className={"body-2 selector-title"} title={option?.title}>
          {option?.title === "Main Fabric" ? "Main" : option?.title}
        </h2>
        <div
          className={`selected-option ${
            !optionState?.nail_size &&
            !optionState?.nail_finish &&
            option?.title === "Nail Band" &&
            "disabled"
          }`}
          onClick={() => {
            if (
              !optionState?.nail_size &&
              !optionState?.nail_finish &&
              option?.title === "Nail Band"
            )
              return;
            setVisible(true);
          }}
        >
          {selectedOption ? (
            <div className={"found-option"}>
              <img
                src={
                  option?.title === "Nail Finish"
                    ? nailFinishArray.find(
                        (finish) => finish.name === selectedOption.name
                      ).map
                    : selectedOption?.image?.url
                }
                alt={selectedOption?.image?.label}
              />
              <p>
                {`${selectedOption?.name} ${
                  selectedOption?.pattern[0] !== "false"
                    ? "Pattern " + selectedOption?.pattern[0]
                    : ""
                } ${
                  selectedOption?.grade[0] !== "false"
                    ? " Grade " + selectedOption?.grade[0]
                    : ""
                }`}
              </p>
            </div>
          ) : (
            <div className={`no-option-found`}>
              <p>Please select a {option?.title}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default pure(Selector);
