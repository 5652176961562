import apolloClient from "core/graphql/apolloClient";
import customerActions from "../actions";
import queryActions from "../../queries/actions";
import queryLoader from "core/graphql/queryLoader";
import { messagesActions } from "../../messages";
import { commonActions } from "../../common";

export default async (store, action) => {
  return new Promise((resolve, reject) => {
    if (!action.email || !action.password) {
      reject("Invalid email or password");
    }

    apolloClient
      .mutate({
        mutation: queryLoader("userLogin"),
        variables: { email: action.email, password: action.password },
        fetchPolicy: "no-cache",
      })
      .then((data) => {
        if (
          !data.data ||
          !data.data.generateCustomerToken ||
          !data.data.generateCustomerToken.token
        ) {
          console.log("GraphQL response", data);
          reject("No customer token received");
        }

        store
          .dispatch(
            customerActions._setCustomerToken(
              data.data.generateCustomerToken.token,
              action.cartToken
            )
          )
          .then(() => {
            resolve();
          });
      })
      .catch((error) => {
        reject(error.message);
      });
  }).catch((error) => {
    store.dispatch(commonActions.unlock("fail"));
    store.dispatch(
      messagesActions.addMessage(error.toString(), "danger", "login")
    );
    store.dispatch(queryActions.removeQuery());
    // store.dispatch(customerActions._reduceCustomer());s
  });
};
