import Iframe from "react-iframe";
import { useLocation } from "react-router-dom";
import {
  MDBBox,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBLink,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import { useBoolean } from "ahooks";
import React from "react";
import Image from "core/components/image/image";
import ProductWidget from "app/layout/productWidget";
import CollectionsContext from "app/layout/collections/CollectionsContext";
import { useFavorites, useToggleFavorite } from "app/pages/collections/hooks";
import { getBadges, getIsFavorite } from "app/pages/collections/utils";
import HeroBanner from "../../heroBanner";
import { selectIsLoggedIn } from "app/state/redux/data/customer/selectors";
import { useSelector } from "react-redux";
import Toolbar from "app/pages/catalog/category/toolbar";
import { useWishlists } from "app/pages/collections/hooks";
import Filter from "../../Filter/Filter";
import GradeQueryContainer from "../../grade";
import SkeletonCategory from "../../skeleton-category";
import { useMediaQuery } from "react-responsive";

const CollectionNotCustom = ({
  heroBannerProps,
  catImages,
  catVideo,
  productsInCollection,
  currentPage,
  setCurrentPage,
  toolbarProps,
  totalPages,
  totalCount,
  pageSize,
  handleSetPageSize,
  pageSizeOptions,
  setPageSize,
  data,
  categoryInfo,
  ...props
  // setShowCollectionAsCategory,
}) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const wishlists = useWishlists();
  // let urlLibby = window.location.href.indexOf("libby-langdon");
  let locationArray = window.location.pathname.split("/");
  locationArray.pop();
  let prevLocation = locationArray.join("/");
  let prevLocationName =
    locationArray?.[locationArray.length - 1].replace(/-/g, " ") ||
    "collection";
  const { setSelectedProduct } = React.useContext(CollectionsContext);
  const favorites = useFavorites();
  const onToggleFavorite = useToggleFavorite(favorites, ([product]) => {
    setSelectedProduct(product);
  });
  const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };
  let arr = [];
  let location = useLocation();
  let isMobile = useMediaQuery({ maxWidth: 992 });

  const [filterModalOpen, setFilterModalOpen] = useBoolean(!isMobile);

  const handleFilterOpen = React.useCallback(
    () => setFilterModalOpen.toggle(),
    [setFilterModalOpen]
  );
  console.log(props);

  return (
    <MDBContainer fluid className="collection-wrapper collection-not-custom">
      <MDBRow className="img-full justify-content-center " id="plp-hero-image">
        <HeroBanner
          {...heroBannerProps}
          showPDFNotCustom={true}
          categoryInfo={categoryInfo}
        />
      </MDBRow>

      <MDBRow className="my-5">
        <MDBCol>
          {location.pathname.includes("graded-in") ? (
            <GradeQueryContainer />
          ) : (
            <MDBContainer className="plp-container-wrap" fluid>
              <MDBRow className="collection-row">
                <MDBCol>
                  <MDBBox className="d-flex align-items-center">
                    <MDBIcon
                      icon="caret-left"
                      size="2x"
                      className="mr-3 text-default"
                    />
                    <MDBLink to={prevLocation}>
                      <MDBTypography
                        className="text-default"
                        tag="h2"
                        variant="display-5"
                      >
                        Back to {titleCase(prevLocationName)}
                      </MDBTypography>
                    </MDBLink>
                  </MDBBox>
                  <MDBBox
                    className={`align-items-center  pb-md-0 px-0 ${
                      ((totalCount >= 12 || totalCount >= 16) &&
                        "pb-5 justify-content-md-between justify-content-center") ||
                      "pb-0 justify-content-center justify-content-md-end"
                    }`}
                  >
                    {/* {totalCount >= 12 ? (
                      <>
                        <Toolbar
                          pageSize={pageSize}
                          currentPage={currentPage}
                          totalPages={totalPages}
                          setCurrentPage={setCurrentPage}
                          totalCount={totalCount}
                          setPageSize={setPageSize}
                          pageSizeOptions={pageSizeOptions}
                        />
                      </>
                    ) : (
                      <MDBTypography
                        tag="span"
                        className="d-flex body-6 my-5 py-md-5"
                      >
                        {totalCount} Item(s)
                      </MDBTypography>
                    )} */}
                    <Filter
                      inFabricsContext={props.inFabricsContext}
                      aggregations={props.aggregations}
                      filter={props.filter}
                      setFabricsCollectionFilter={
                        props.setFabricsCollectionFilter
                      }
                      setGradeFilter={props.setGradeFilter}
                      setPatternFilter={props.setPatternFilter}
                      setHexclrgrpFilter={props.setHexclrgrpFilter}
                      priceAggregation={props.priceAggregation ?? null}
                      minPrice={props.minPrice}
                      maxPrice={props.maxPrice}
                      fromPercentage={props.fromPercentage}
                      setFromPercentage={props.setFromPercentage}
                      toPercentage={props.toPercentage}
                      setToPercentage={props.setToPercentage}
                      setPriceFilter={props.setPriceFilter}
                      gradeOptions={props.gradeOptions}
                      patternOptions={props.patternOptions}
                      hexclrgrpOptions={props.hexclrgrpOptions}
                      setCollectionFilter={props.setCollectionFilter}
                      toggleIsNewFilter={props.toggleIsNewFilter}
                      setLaneFilter={props.setLaneFilter}
                      clearFilter={props.clearFilter}
                      clearLaneFilter={props.clearLaneFilter}
                      isLoggedIn={props.isLoggedIn}
                      sort={props.sort}
                      setSort={props.setSort}
                      toggleIsAsc={props.toggleIsAsc}
                      isCollection={true}
                      listingProps={props.listingProps}
                      toggleInStockFilter={props.toggleInStockFilter}
                      setCurrentPage={setCurrentPage}
                      setSearchVal={props.setSearchVal}
                      setFilterModalOpen={setFilterModalOpen}
                      filterModalOpen={filterModalOpen}
                      handleFilterOpen={handleFilterOpen}
                      isTopFilter={true}
                      paginationProps={toolbarProps}
                      setProductFeatureFilters={props?.setProductFeatureFilters}
                      setProductTypeFilters={props?.setProductTypeFilters}
                      setWidthFilter={props.setWidthFilter}
                      setHeightFilter={props.setHeightFilter}
                      setDepthFilter={props.setDepthFilter}
                    />
                  </MDBBox>
                  {/* <MDBBox className="my-5 border-light border-bottom"></MDBBox> */}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                {(typeof catImages !== "undefined" &&
                  catImages.map((item, index) => (
                    <MDBCol
                      key={item.thumbnail}
                      size="12"
                      lg="4"
                      sm="6"
                      className="py-4"
                    >
                      <Image
                        className="img-fluid collection-img w-100"
                        source={item.image}
                      />
                    </MDBCol>
                  ))) || <></>}
                {/* temporary solution */}
                {/* {urlLibby !== -1 ? (
                <MDBCol size="12" className="d-none text-center">
                  <MDBLink to="#" className="default btn-default btn">
                    View Collection
                  </MDBLink>
                </MDBCol>
              ) : ( */}
                {/* <MDBCol size="12" className="text-center">
                <MDBLink
                  onClick={() => {
                    setShowCollectionAsCategory(true);
                  }}
                  className="default btn-default btn"
                >
                  View Collection
                </MDBLink>
              </MDBCol> */}
                {/* )} */}
              </MDBRow>
              {catVideo === null || typeof catVideo === "undefined" ? (
                <></>
              ) : (
                <MDBRow className="mt-5">
                  <MDBCol size="12" className="mt-5">
                    <div className="iFrame-wrapper">
                      <Iframe
                        url={catVideo}
                        frameborder="0"
                        allowfullscreen
                        allowtransparency
                      />
                    </div>
                  </MDBCol>
                  {/* temporary solution */}
                  {/* {urlLibby !== -1 ? (
                  <MDBCol size="12" className="d-none text-center mt-5">
                    <MDBLink to="#" className="default btn-default btn">
                      View Collection
                    </MDBLink>
                  </MDBCol>
                ) : ( */}
                  {/* <MDBCol size="12" className="text-center mt-5">
                  <MDBLink
                    onClick={() => {
                      setShowCollectionAsCategory(true);
                    }}
                    className="default btn-default btn"
                  >
                    View Collection
                  </MDBLink>
                </MDBCol> */}
                  {/* )} */}
                </MDBRow>
              )}
              <MDBContainer fluid>
                <MDBRow>
                  {filterModalOpen && (
                    <MDBCol
                      size="3"
                      className={!isMobile ? "d-none d-md-flex p-0" : "max-w-0 p-0"}
                      >
                      <Filter
                        inFabricsContext={props.inFabricsContext}
                        aggregations={props.aggregations}
                        filter={props.filter}
                        setFabricsCollectionFilter={
                          props.setFabricsCollectionFilter
                        }
                        setGradeFilter={props.setGradeFilter}
                        setPatternFilter={props.setPatternFilter}
                        setHexclrgrpFilter={props.setHexclrgrpFilter}
                        priceAggregation={props.priceAggregation ?? null}
                        minPrice={props.minPrice}
                        maxPrice={props.maxPrice}
                        fromPercentage={props.fromPercentage}
                        setFromPercentage={props.setFromPercentage}
                        toPercentage={props.toPercentage}
                        setToPercentage={props.setToPercentage}
                        setPriceFilter={props.setPriceFilter}
                        gradeOptions={props.gradeOptions}
                        patternOptions={props.patternOptions}
                        hexclrgrpOptions={props.hexclrgrpOptions}
                        setCollectionFilter={props.setCollectionFilter}
                        toggleIsNewFilter={props.toggleIsNewFilter}
                        setLaneFilter={props.setLaneFilter}
                        clearFilter={props.clearFilter}
                        clearLaneFilter={props.clearLaneFilter}
                        isLoggedIn={props.isLoggedIn}
                        sort={props.sort}
                        setSort={props.setSort}
                        toggleIsAsc={props.toggleIsAsc}
                        isCollection={true}
                        listingProps={props.listingProps}
                        toggleInStockFilter={props.toggleInStockFilter}
                        setCurrentPage={setCurrentPage}
                        setSearchVal={props.setSearchVal}
                        setFilterModalOpen={setFilterModalOpen}
                        filterModalOpen={filterModalOpen}
                        handleFilterOpen={handleFilterOpen}
                        paginationProps={toolbarProps}
                        isTopFilter={false}
                        setProductFeatureFilters={
                          props?.setProductFeatureFilters
                        }
                        setProductTypeFilters={props?.setProductTypeFilters}
                        setWidthFilter={props.setWidthFilter}
                        setHeightFilter={props.setHeightFilter}
                        setDepthFilter={props.setDepthFilter}
                        isMobile={isMobile}
                      />
                    </MDBCol>
                  )}

                  <MDBCol>
                    {props?.loadingData ? (
                      <SkeletonCategory />
                    ) : (
                      <>
                        {(productsInCollection?.length > 0 && (
                          <MDBRow>
                            {productsInCollection?.map((product) => {
                              const badges = getBadges(product);
                              const isFavorite = getIsFavorite({
                                favorites: favorites ?? [],
                                sku: product.sku,
                              });
                              let price =
                                product?.price_range?.minimum_price?.final_price
                                  ?.value || undefined;
                              const handleToggleFavorite = () => {
                                onToggleFavorite({
                                  product: {
                                    ...product,
                                  },
                                  checked: !isFavorite,
                                });
                              };
                              let arr = [];
                              wishlists.map((wishlist) => {
                                return wishlist.items?.filter((wishistitem) => {
                                  if (
                                    wishistitem?.product?.sku === product?.sku
                                  ) {
                                    arr.push(wishistitem);
                                    return wishistitem;
                                  }
                                });
                              });

                              return product.ffi_isvisible ? (
                                <ProductWidget
                                  key={product.sku}
                                  image={product.image}
                                  name={product.name}
                                  sku={product.sku}
                                  url={
                                    location.pathname.substring(1) +
                                    "/" +
                                    product.url_key
                                  }
                                  product={product}
                                  colSize="6"
                                  price={isLoggedIn ? price : ""}
                                  mdSize="3"
                                  className={"px-5 py-3 mb-4"}
                                  isFavorite={isFavorite}
                                  is_new={product.is_new}
                                  onToggleFavorite={handleToggleFavorite}
                                  badges={badges}
                                  items={productsInCollection}
                                  isFullHeart={
                                    arr.length > 0 && !isFavorite ? true : false
                                  }
                                  // isBuildYourOwn={isBuildYourOwn}
                                  // showFavorites={
                                  //   !isFabricOrFinishesCategory && !isBuildYourOwn
                                  // }
                                />
                              ) : (
                                <></>
                              );
                            })}
                          </MDBRow>
                        )) || (
                          <MDBRow>
                            <MDBCol>
                              <MDBTypography
                                tag="h4"
                                className={`fwregular text-default`}
                              >
                                There are no products currently in this
                                collection
                              </MDBTypography>
                            </MDBCol>
                          </MDBRow>
                        )}
                        {productsInCollection?.length > 0 && (
                          <MDBRow className="collection-bottom-toolbar">
                            {totalCount >= 12 ? (
                              <Toolbar
                                pageSize={pageSize}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                                totalCount={totalCount}
                                setPageSize={setPageSize}
                                pageSizeOptions={pageSizeOptions}
                              />
                            ) : (
                              <MDBTypography
                                tag="span"
                                className="d-flex body-6 my-5 py-md-5"
                              >
                                {totalCount} Item(s)
                              </MDBTypography>
                            )}
                          </MDBRow>
                        )}
                      </>
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBContainer>
          )}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default CollectionNotCustom;
