import Icon from "app/assets/icon/icon";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import DropdownItems from "../dropdownItems/dropdownItems";

const MenuItem = ({ item }) => {
  const [isHovered, setIsHovered] = useState(false);
  const hasChildren = !!item?.children?.length;
  if (!item.include_in_menu) return <></>;
  const shouldDisableLink =
    item.url_path === "products/residential/shop-by-room";

  const urlPath = item?.url_path
    .split("/")
    .map((path) => {
      return path?.replace("featured-collections", "upholstery");
    })
    .join("/");

  // Filter the children to only include those where include_in_menu is 1
  const includedChildren = item.children?.filter(
    (child) => child.include_in_menu === 1
  );

  return (
    <div
      className={"menu-item-wrap"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link
        to={"/" + urlPath}
        className={`menu-item ${
          shouldDisableLink ? "pointer-events-none" : ""
        }`}
      >
        {item?.name}
        {(hasChildren && includedChildren.length && (
          <Icon icon="menuDropdownArrow" />
        )) ||
          null}
      </Link>
      {hasChildren && (
        <DropdownItems isHovered={isHovered} items={item.children} />
      )}
    </div>
  );
};

export default MenuItem;
