import types from "./types";
import { default as addQuery } from "./reducers/addQuery";
import { default as addQuerySuccess } from "./reducers/addQuerySuccess";
import { default as addQueryData } from "./reducers/addQueryData";
import { default as removeQuery } from "./reducers/removeQuery";
import { default as removeSpecificQuery } from "./reducers/removeSpecificQuery";
import { default as removeAllQueries } from "./reducers/removeAllQueries";
import { default as hideSplash } from "./reducers/hideSplash";
import addQueryDataSuccess from "./reducers/addQueryDataSuccess";
import removeAllTmpQueries from "./reducers/removeAllTmpQueries";

// Main reducer
const queryReducer = (state, action) => {
  if (state === undefined) return {};
  if (action.type === types.ADD_QUERY) return addQuery(state, action);
  if (action.type === types.ADD_QUERY_SUCCESS)
    return addQuerySuccess(state, action);
  if (action.type === types.ADD_QUERY_DATA) return addQueryData(state, action);
  if (action.type === types.ADD_QUERY_DATA_SUCCESS)
    return addQueryDataSuccess(state, action);
  if (action.type === types.REMOVE_QUERY) return removeQuery(state, action);
  if (action.type === types.REMOVE_ALL_QUERIES)
    return removeAllQueries(state, action);
  if (action.type === types.HIDE_SPLASH) return hideSplash(state, action);
  if (action.type === types.REMOVE_SPECIFIC_QUERY)
    return removeSpecificQuery(state, action);

  if (action.type === types.REMOVE_ALL_TMP_QUERIES)
    return removeAllTmpQueries(state, action);
  return state;
};

export default queryReducer;
