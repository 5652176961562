import produce from "immer";

const actions = {
  setSelectedCategory: (state, action) => {
    state.selectedCategory = action.category;
    state.fabricPage = 0;
  },
  nextFabricPage: (state, action) => {
    state.fabricPage = state.fabricPage + 1;
  },
  setFilterState: (state, action) => {
    //{type: 'setFilterState', filterId, filterState}
    state.selectedFilters.ffifabrics_collection.active =
      action.filterId === "ffifabrics_collection"
        ? action.filterState
        : action.filterState
        ? false
        : state.selectedFilters.ffifabrics_collection.active;
    state.selectedFilters.ffifabrics_grade.active =
      action.filterId === "ffifabrics_grade"
        ? action.filterState
        : action.filterState
        ? false
        : state.selectedFilters.ffifabrics_grade.active;
    state.selectedFilters.ffifabrics_category1.active =
      action.filterId === "ffifabrics_category1"
        ? action.filterState
        : action.filterState
        ? false
        : state.selectedFilters.ffifabrics_category1.active;
    state.selectedFilters.ffifabrics_finishtype.active =
      action.filterId === "ffifabrics_finishtype"
          ? action.filterState
          : action.filterState
          ? false
          : state.selectedFilters.ffifabrics_finishtype.active;
    state.selectedFilters.ffifabrics_hexclrgrp.active =
      action.filterId === "ffifabrics_hexclrgrp"
        ? action.filterState
        : action.filterState
        ? false
        : state.selectedFilters.ffifabrics_hexclrgrp.active;
    state.fabricPage = 0;
  },
  setFilterOptions: (state, action) => {
    //{type: 'setFilterOptions', filterId, options}
    state.selectedFilters[action.filterId].options = action.options;
    state.fabricPage = 0;
  },
  resetFilterOptions: (state, action) => {
    state.selectedFilters = {
      ffifabrics_collection: {
        active: false,
        options: [],
        label: "Collection",
        selectedOptions: [],
      },
      ffifabrics_grade: {
        active: false,
        label: "Grade",
        options: [],
        selectedOptions: [],
      },
      ffifabrics_category1: {
        active: false,
        label: "Pattern",
        options: [],
        selectedOptions: [],
      },
      ffifabrics_finishtype: {
        active: false,
        label: "Finish",
        options: [],
        selectedOptions: [],
      },
      ffifabrics_hexclrgrp: {
        active: false,
        label: "Color",
        options: [],
        selectedOptions: [],
      },
    };
    state.fabricPage = 0;
  },
  setFilterSelectedOption: (state, action) => {
    //{type: 'setFilterSelectedOption', filterId, optionId, optionState}
    state.selectedFilters[action.filterId].selectedOptions = action.optionState
      ? state.selectedFilters[action.filterId].selectedOptions.concat([
          action.optionId,
        ])
      : state.selectedFilters[action.filterId].selectedOptions.filter(
          (option) => option.value !== action.optionId
        );
    state.fabricPage = 0;
  },
  toggleFilterSelectedOption: (state, action) => {
    //{type: 'toggleFilterSelectedOption', filterId, optionId}
    state.selectedFilters[action.filterId].selectedOptions =
      state.selectedFilters[action.filterId].selectedOptions.indexOf(
        action.optionId
      ) < 0
        ? state.selectedFilters[action.filterId].selectedOptions.concat([
            action.optionId,
          ])
        : state.selectedFilters[action.filterId].selectedOptions.filter(
            (option) => option !== action.optionId
          );
    state.fabricPage = 0;
  },
};

const reducer = produce((state, action) =>
  actions.hasOwnProperty(action.type)
    ? actions[action.type](state, action)
    : state
);

export default reducer;
