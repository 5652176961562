import { useQuery } from "@apollo/react-hooks";
import queryLoader from "core/graphql/queryLoader";
import { isEmpty } from "core/helpers/functions";
import React from "react";
import Hotspot from "./hotspot";

const query = queryLoader("products");

const HotspotQueryLoader = (props) => {
  let dots = [];
  let skuArray = [];

  let contentArray = JSON.parse(props.contentArray.content);
  if (contentArray !== null && contentArray !== undefined) {
    contentArray.map((dot) => {
      if (dot.sku !== "") skuArray.push(dot.sku);
      dots.push({
        top: dot.top,
        left: dot.left,
        display_type: dot.display_type,
        sku: dot.sku,
        addInfo: dot.addInfo,
        isVisible: false,
      });
      return dots;
    });
  }
  const { data, loading, error } = useQuery(query, {
    variables: { filters: { sku: { in: skuArray } } },
  });

  if (loading) return <></>;
  if (error) return <></>;
  if (!isEmpty(skuArray)) {
    if (data !== undefined) {
      data.products.items.sort(function (a, b) {
        return data.products.items.indexOf(a) - skuArray.indexOf(b);
      });
      data.products.items.map((info) => {
        let found = null;
        dots.find((value, index) => {
          if (value.sku === info.sku) {
            found = index;
          }
        });
        if (found !== null) {
          dots[found].image = info.image.url;
          dots[found].name = info.name;
          dots[found].content = info.name;
          dots[found].price = info.price_range.minimum_price.final_price.value;
          dots[found].priceCurrency =
            info.price_range.minimum_price.final_price.currency;
          dots[found].url = info.url_key;
          dots[found].colors = info.upsell_products.length;
          found += 1;
          return info;
        }
      });
    }
  }
  return (
    <>
      <Hotspot dots={dots} {...props} />
    </>
  );
};

export default HotspotQueryLoader;
