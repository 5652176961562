// Action types
const GET_CACHE_VERSION = "jola/cache/GET_CACHE_VERSION";
const SET_CACHE_VERSION = "jola/cache/SET_CACHE_VERSION";
const SET_FLAG = "jola/cache/SET_FLAG";

export default {
  GET_CACHE_VERSION,
  SET_CACHE_VERSION,
  SET_FLAG
};
