import {queriesTypes} from "../../queries";
import {begin, pendingTask} from "react-redux-spinner";

const REMOVE_SPECIFIC_QUERY = queriesTypes.REMOVE_SPECIFIC_QUERY;

const routeChange = (store, action) => {

    store.dispatch({
        type: REMOVE_SPECIFIC_QUERY,
        [pendingTask]: begin, // Make sure you embrace `pendingTask` in brackets [] to evaluate it,
        // Any additional key/values may be included here
        query_identifier: action.query
    });

    // store.dispatch(commonActions.removeQue(action.match))
};

export default routeChange;