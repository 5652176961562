import React, {memo} from "react";
import {isEqual} from "lodash";

const JolaCache = memo((props) => {
  let { children } = props;
  return <>{children}</>;
}, (prevProps, nextProps) => {
  if(isEqual(prevProps, nextProps)){
    return true;
  }

  return false;
});

export default JolaCache;
