import clsx from "clsx";
import { MDBTypography } from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";
import { NavigationItem } from "../../models";

export interface ItemProps extends NavigationItem {
  active: boolean;
}

const Item: React.FC<ItemProps> = (props) => {
  return (
    <Link to={props.pathname} className={props.itemClass}>
      <MDBTypography
        tag="h4"
        className={clsx("py-3", "px-4", { "bg-default-02": props.active })}
      >
        {props.label}
      </MDBTypography>
    </Link>
  );
};

export default Item;
