import { Maybe } from "app/generated/graphql";
import { ProductWithSku } from "app/pages/collections/models";
import { createContext } from "react";

export interface ICollectionsContext {
  selectedProduct: Maybe<ProductWithSku>;
  formattedOptions?: Array<{}>;
  wishlistName?: string;
  setSelectedProduct: (product: ProductWithSku | null) => void;
  setFormattedOptions: (formattedOptions: Array<{}>) => void;
  setWishlistName: (wishlistName: string) => void;
  isFavorite?: boolean;
  setIsFavorite: (isFavorite: boolean) => void;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

export const initialCollectionsContext: ICollectionsContext = {
  selectedProduct: null,
  formattedOptions: [],
  wishlistName: "",
  setSelectedProduct: () => {},
  setFormattedOptions: () => {},
  setWishlistName: () => {},
  isFavorite: false,
  setIsFavorite: () => {},
  showModal: false,
  setShowModal: () => {},
};

export default createContext(initialCollectionsContext);
