import * as yup from "yup";
import { passwordSchema } from "../../../config";

export interface PasswordValues {
  password: string;
}

export const initialPasswordValues: PasswordValues = {
  password: "",
};

export const passwordValidationSchema: yup.ObjectSchema<PasswordValues> = yup
  .object()
  .required()
  .shape({
    password: yup.string()
    .required("Password is required."),
  });
