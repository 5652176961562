import themeConfiguration from "../../../../config/themeConfiguration";

export const frameArray = [
  {
    id: "01",
    sku: "01",
    name: "Chair",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/chair.jpg`,
    hide_pillow: true,
    price: "$2,893",
    parentName: "Frame",
  },
  {
    id: "20",
    sku: "20",
    name: "Ottoman",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/ottoman.jpg`,
    hide_pillow: true,
    price: "$1,350",
    parentName: "Frame",
  },
  {
    id: "50",
    sku: "50",
    name: "Sofa",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/sofa.jpg`,
    price: "$4,823",
    parentName: "Frame",
  },
  {
    id: "60",
    sku: "QS",
    name: "Sleeper Sofa",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/sofa.jpg`,
    price: "",
    parentName: "Frame",
  },
  {
    id: ["52", "29", "51"],
    sku: "CK",
    name: "Large Symmetrical Corner",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/52-29-51.jpg`,
    price: "$12,065",
    parentName: "Frame",
  },
  {
    id: ["54", "51"],
    sku: "CH",
    name: "Left Arm Corner",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/54-51.jpg`,
    price: "$10,477",
    parentName: "Frame",
  },
  {
    id: ["52", "53"],
    sku: "CG",
    name: "Right Arm Corner",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/52-53.jpg`,
    price: "$10,477",
    parentName: "Frame",
  },
  {
    id: ["27", "78", "78", "26"],
    sku: "CI",
    name: "Double Chaise",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/27-78-78-26.jpg`,
    price: "$13,929",
    parentName: "Frame",
  },
  {
    id: ["76", "78", "78", "75"],
    sku: "CL",
    name: "Double Chaise Cuddle",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/76-78-78-75.jpg`,
    price: "$15,218",
    parentName: "Frame",
  },
  {
    id: ["52", "26"],
    sku: "CD",
    name: "Right Arm Chaise/Sofa",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/52-26.jpg`,
    price: "$8,678",
    parentName: "Frame",
  },
  {
    id: ["27", "51"],
    sku: "CC",
    name: "Left Arm Chaise/Sofa",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/27-51.jpg`,
    price: "$8,678",
    parentName: "Frame",
  },
  {
    id: ["78", "29", "78"],
    sku: "CJ",
    name: "Small Armless Corner",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/78-29-78.jpg`,
    price: "$8,637",
    parentName: "Frame",
  },
  {
    id: ["52", "29", "78", "78", "29", "51"],
    sku: "CM",
    name: "Large Pit",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/52-29-78-78-29-51.jpg`,
    price: "$20,702",
    parentName: "Frame",
  },
  {
    id: ["27", "78", "78", "53"],
    sku: "CA",
    name: "Left Arm Chaise",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/27-78-78-53.jpg`,
    price: "$15,728",
    parentName: "Frame",
  },
  {
    id: ["54", "78", "78", "26"],
    sku: "CB",
    name: "Right Arm Chaise",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/54-78-78-26.jpg`,
    price: "$15,728",
    parentName: "Frame",
  },
  {
    id: ["54", "78", "78", "75"],
    name: "Right Arm U-Shaped Cuddle",
    sku: "CF",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/54-78-78-75.jpg`,
    price: "$16,373",
    parentName: "Frame",
  },
  {
    id: ["76", "78", "78", "53"],
    sku: "CE",
    name: "Left Arm U-Shaped Cuddle",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/76-78-78-53.jpg`,
    price: "$16,373",
    parentName: "Frame",
  },
];

export const backArray = [
  {
    id: "B",
    name: "Border Back",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/back-B.jpg`,
    parentName: "Back Cushion",
  },

  {
    id: "M",
    name: "Multi-Pillow Back",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/back-M.jpg`,
    parentName: "Back Cushion",
  },
];

export const armArray = [
  {
    id: "B",
    name: "Border Track",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/side-B.jpg`,
    parentName: "Arm",
  },
  {
    id: "C",
    name: "Clipped",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/side-C.jpg`,
    parentName: "Arm",
  },
  {
    id: "S",
    name: "Small Track",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/side-S.jpg`,
    parentName: "Arm",
  },
  {
    id: "H",
    name: "Chamfered",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/side-H.jpg`,
    parentName: "Arm",
  },
];

export const seatArray = [
  {
    id: "S",
    name: "Standard Cushion",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/seat_01.jpg`,
    type: "standard",
    parentName: "Cushion",
  },
  {
    id: "B",
    name: "Bench Seat",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/seat_02.jpg`,
    type: "bench",
    parentName: "Cushion",
  },
];

export const baseArray = [
  {
    id: "A",
    name: "Acrylic Leg",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/leg-A.jpg`,
    type: "leg",
    parentName: "Base",
  },
  {
    id: "M",
    name: "Metal Leg - Nickel Finish",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/leg-M.jpg`,
    type: "leg",
    material: {
      color: "#6C6154",
      metalness: 0.5,
      roughness: 0.3,
    },
    parentName: "Base",
  },
  {
    id: "M",
    name: "Metal Leg - Bronze Finish",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/Metal Leg-M_bronze.jpg`,
    type: "leg",
    material: {
      color: "#3C3933",
      metalness: 0.6,
      roughness: 0.4,
    },
    parentName: "Base",
  },
  {
    id: "P",
    name: "Plinth Base",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/leg-P.jpg`,
    type: "leg",
    parentName: "Base",
  },
  {
    id: "W",
    name: "Wood Leg",
    image: `${themeConfiguration.model_url}/3d/urban-living/images/leg-W.jpg`,
    type: "leg",
    parentName: "Base",
  },
];

export const baseFinishArray = [
  {
    id: "1",
    name: "Almond Buff",
    map: `${themeConfiguration.model_url}/3d/textures/wood/almond_buff_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/almond_buff_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/almond_buff_seamless_icon.jpg`,
  },
  {
    id: "2",
    name: "Black",
    map: `${themeConfiguration.model_url}/3d/textures/wood/black_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/black_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/black_seamless_icon.jpg`,
  },
  {
    id: "3",
    name: "Candlelight",
    map: `${themeConfiguration.model_url}/3d/textures/wood/candlelight_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/candlelight_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/candlelight_seamless_icon.jpg`,
  },
  {
    id: "4",
    name: "Cerused Espresso",
    map: `${themeConfiguration.model_url}/3d/textures/wood/cerused_espresso_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/cerused_espresso_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/cerused_espresso_seamless_icon.jpg`,
  },
  {
    id: "5",
    name: "Charcoal",
    map: `${themeConfiguration.model_url}/3d/textures/wood/charcoal_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/charcoal_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/charcoal_seamless_icon.jpg`,
  },
  {
    id: "6",
    name: "Cherry",
    map: `${themeConfiguration.model_url}/3d/textures/wood/cherry_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/cherry_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/cherry_seamless_icon.jpg`,
  },
  {
    id: "7",
    name: "Cognac",
    map: `${themeConfiguration.model_url}/3d/textures/wood/cognac_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/cognac_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/cognac_seamless_icon.jpg`,
  },
  {
    id: "8",
    name: "Creme Brulee",
    map: `${themeConfiguration.model_url}/3d/textures/wood/creme_brulee_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/creme_brulee_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/creme_brulee_seamless_icon.jpg`,
  },
  {
    id: "9",
    name: "Dark Sable Beech",
    map: `${themeConfiguration.model_url}/3d/textures/wood/dark_sable_beech_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/dark_sable_beech_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/dark_sable_beech_seamless_icon.jpg`,
  },
  {
    id: "10",
    name: "Espresso",
    map: `${themeConfiguration.model_url}/3d/textures/wood/espresso_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/espresso_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/espresso_seamless_icon.jpg`,
  },
  {
    id: "11",
    name: "Flint",
    map: `${themeConfiguration.model_url}/3d/textures/wood/flint_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/flint_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/flint_seamless_icon.jpg`,
  },
  {
    id: "12",
    name: "French Oak",
    map: `${themeConfiguration.model_url}/3d/textures/wood/french_oak_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/french_oak_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/french_oak_seamless_icon.jpg`,
  },
  {
    id: "13",
    name: "Graphite",
    map: `${themeConfiguration.model_url}/3d/textures/wood/graphite_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/graphite_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/graphite_seamless_icon.jpg`,
  },
  {
    id: "14",
    name: "Greystone",
    map: `${themeConfiguration.model_url}/3d/textures/wood/greystone_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/greystone_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/greystone_seamless_icon.jpg`,
  },
  {
    id: "15",
    name: "Harvest",
    map: `${themeConfiguration.model_url}/3d/textures/wood/harvest_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/harvest_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/harvest_seamless_icon.jpg`,
  },
  {
    id: "16",
    name: "Hazelnut",
    map: `${themeConfiguration.model_url}/3d/textures/wood/hazelnut_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/hazelnut_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/hazelnut_seamless_icon.jpg`,
  },
  {
    id: "17",
    name: "Heirloom",
    map: `${themeConfiguration.model_url}/3d/textures/wood/heirloom_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/heirloom_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/heirloom_seamless_icon.jpg`,
  },
  {
    id: "18",
    name: "Light Cherry",
    map: `${themeConfiguration.model_url}/3d/textures/wood/light_cherry_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/light_cherry_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/light_cherry_seamless_icon.jpg`,
  },
  {
    id: "19",
    name: "Mahagony",
    map: `${themeConfiguration.model_url}/3d/textures/wood/mahagony_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/mahagony_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/mahagony_seamless_icon.jpg`,
  },
  {
    id: "20",
    name: "Manhattan",
    map: `${themeConfiguration.model_url}/3d/textures/wood/manhattan_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/manhattan_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/manhattan_seamless_icon.jpg`,
  },
  {
    id: "21",
    name: "Metro Gray",
    map: `${themeConfiguration.model_url}/3d/textures/wood/metro_gray_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/metro_gray_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/metro_gray_seamless_icon.jpg`,
  },
  {
    id: "22",
    name: "Mica",
    map: `${themeConfiguration.model_url}/3d/textures/wood/mica_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/mica_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/mica_seamless_icon.jpg`,
  },
  {
    id: "23",
    name: "Modern Truffle Beech",
    map: `${themeConfiguration.model_url}/3d/textures/wood/modern_truffle_beech_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/modern_truffle_beech_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/modern_truffle_beech_seamless_icon.jpg`,
  },
  {
    id: "24",
    name: "Montego Bay",
    map: `${themeConfiguration.model_url}/3d/textures/wood/montego_bay_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/montego_bay_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/montego_bay_seamless_icon.jpg`,
  },
  {
    id: "25",
    name: "Old English",
    map: `${themeConfiguration.model_url}/3d/textures/wood/old_english_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/old_english_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/old_english_seamless_icon.jpg`,
  },
  {
    id: "26",
    name: "Pearl",
    map: `${themeConfiguration.model_url}/3d/textures/wood/pearl_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/pearl_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/pearl_seamless_icon.jpg`,
  },
  {
    id: "27",
    name: "Pecan",
    map: `${themeConfiguration.model_url}/3d/textures/wood/pecan_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/pecan_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/pecan_seamless_icon.jpg`,
  },
  {
    id: "28",
    name: "Reclaimed Oak",
    map: `${themeConfiguration.model_url}/3d/textures/wood/reclaimed_oak_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/reclaimed_oak_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/reclaimed_oak_seamless_icon.jpg`,
  },
  {
    id: "29",
    name: "Relic Black",
    map: `${themeConfiguration.model_url}/3d/textures/wood/relic_black_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/relic_black_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/relic_black_seamless_icon.jpg`,
  },
  {
    id: "30",
    name: "Rustic Portobella",
    map: `${themeConfiguration.model_url}/3d/textures/wood/rustic_portobella_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/rustic_portobella_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/rustic_portobella_seamless_icon.jpg`,
  },
  {
    id: "31",
    name: "Sable",
    map: `${themeConfiguration.model_url}/3d/textures/wood/sable_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/sable_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/sable_seamless_icon.jpg`,
  },
  {
    id: "32",
    name: "Sheer Dove",
    map: `${themeConfiguration.model_url}/3d/textures/wood/sheer_dove_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/sheer_dove_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/sheer_dove_seamless_icon.jpg`,
  },
  {
    id: "33",
    name: "Tobacco",
    map: `${themeConfiguration.model_url}/3d/textures/wood/tobacco_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/tobacco_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/tobacco_seamless_icon.jpg`,
  },
  {
    id: "34",
    name: "Toffee",
    map: `${themeConfiguration.model_url}/3d/textures/wood/toffee_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/toffee_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/toffee_seamless_icon.jpg`,
  },
  {
    id: "35",
    name: "Walnut",
    map: `${themeConfiguration.model_url}/3d/textures/wood/walnut_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/wood/walnut_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/wood/walnut_seamless_icon.jpg`,
  },
];

export const fabricArray = [
  {
    id: "2034-68",
    name: "Stone",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/2034_stone_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/2034_stone_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/2034_stone_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3000-97",
    name: "Heather",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3000_heather_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3000_heather_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3000_heather_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3000-01",
    name: "Taupe",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3000_taupe_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3000_taupe_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3000_taupe_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3013-65",
    name: "Pewter",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3013_pewter_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3013_pewter_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3013_pewter_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3016-63",
    name: "Silver",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3016_silver_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3016_silver_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3016_silver_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3080-98",
    name: "Cobalt",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3080_cobalt_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3080_cobalt_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3080_cobalt_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3100-90",
    name: "Blue",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3100_blue_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3100_blue_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3100_blue_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3100-92",
    name: "Cloud",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3100_cloud_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3100_cloud_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3100_cloud_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3100-72",
    name: "Linen",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3100_linen_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3100_linen_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3100_linen_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3100-74",
    name: "Snow",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3100_snow_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3100_snow_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3100_snow_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3100-68",
    name: "Stone",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3100_stone_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3100_stone_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3100_stone_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3134-75",
    name: "Ivory",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3134_ivory_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3134_ivory_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3134_ivory_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3135-90",
    name: "Dresden",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3135_dresden_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3135_dresden_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3135_dresden_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3150-73",
    name: "Alabaster",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3150_alabaster_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3150_alabaster_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3150_alabaster_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3150-72",
    name: "Linen",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3150_linen_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3150_linen_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3150_linen_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3154-61",
    name: "Charcoal",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3154_charcoal_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3154_charcoal_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3154_charcoal_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3155-72",
    name: "Linen",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3155_linen_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3155_linen_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3155_linen_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3158-73",
    name: "Alabaster",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3158_alabaster_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3158_alabaster_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3158_alabaster_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3251-61",
    name: "Charcoal",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3251_charcoal_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3251_charcoal_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3251_charcoal_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3257-90",
    name: "Dresden",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3257_dresden_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3257_dresden_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3257_dresden_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3259-90",
    name: "Indigo",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3259_indigo_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3259_indigo_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3259_indigo_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3336-72",
    name: "Pearl",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3336_pearl_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3336_pearl_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3336_pearl_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3342-72",
    name: "Flax",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3342_flax_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3342_flax_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3342_flax_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3344-01",
    name: "Taupe",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3344_taupe_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3344_taupe_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3344_taupe_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3350-70",
    name: "Eggshell",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3350_eggshell_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3350_eggshell_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3350_eggshell_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3364-65",
    name: "Pewter",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3364_pewter_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3364_pewter_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3364_pewter_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3368-93",
    name: "Sapphire",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3368_sapphire_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3368_sapphire_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3368_sapphire_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3401-72",
    name: "Flax",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3401_flax_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3401_flax_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3401_flax_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3502-35",
    name: "Driftwood",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3502_driftwood_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3502_driftwood_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3502_driftwood_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3506-73",
    name: "Cream",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3506_cream_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3506_cream_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3506_cream_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3506-90",
    name: "Denim",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3506_denim_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3506_denim_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3506_denim_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3506-00",
    name: "Dune",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3506_dune_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3506_dune_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3506_dune_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3506-62",
    name: "Metal",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3506_metal_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3506_metal_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3506_metal_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3506-76",
    name: "Mushroom",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3506_mushroom_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3506_mushroom_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3506_mushroom_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3506-65",
    name: "Putty",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3506_putty_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3506_putty_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3506_putty_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3506-44",
    name: "Terracotta",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3506_terracotta_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3506_terracotta_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3506_terracotta_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3608-90",
    name: "Denim",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3608_denim_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3608_denim_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3608_denim_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3746-90",
    name: "Indigo",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3746_indigo_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3746_indigo_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3746_indigo_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3755-65",
    name: "Pewter",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3755_pewter_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3755_pewter_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3755_pewter_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3981-31",
    name: "Fog",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3981_fog_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3981_fog_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3981_fog_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3981-66",
    name: "Midnight",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3981_midnight_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3981_midnight_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3981_midnight_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "3988-92",
    name: "Cloud",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/3988_cloud_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/3988_cloud_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/3988_cloud_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "4721-97",
    name: "Oasis",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/4721_oasis_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/4721_oasis_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/4721_oasis_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "4901-64",
    name: "Ebony",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/4901_ebony_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/4901_ebony_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/4901_ebony_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "4957-93",
    name: "Sapphire",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/4957_sapphire_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/4957_sapphire_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/4957_sapphire_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "7602-61",
    name: "Charcoal",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/7602_charcoal_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/7602_charcoal_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/7602_charcoal_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "7602-73",
    name: "Cream",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/7602_cream_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/7602_cream_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/7602_cream_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "7602-63",
    name: "Grey",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/7602_grey_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/7602_grey_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/7602_grey_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "7602-90",
    name: "Sky",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/7602_sky_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/7602_sky_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/7602_sky_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "7602-06",
    name: "Tan",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/7602_tan_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/7602_tan_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/7602_tan_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "8703-73",
    name: "Alabaster",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/8703_alabaster_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/8703_alabaster_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/8703_alabaster_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "8703-73",
    name: "Bamboo",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/8703_bamboo_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/8703_bamboo_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/8703_bamboo_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "8703-92",
    name: "Cloud",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/8703_cloud_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/8703_cloud_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/8703_cloud_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "8703-90",
    name: "Indigo",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/8703_indigo_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/8703_indigo_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/8703_indigo_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "8703-97",
    name: "Steel",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/8703_steel_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/8703_steel_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/8703_steel_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "8703-09",
    name: "Tumbleweed",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/8703_tumbleweed_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/8703_tumbleweed_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/8703_tumbleweed_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "8794-70",
    name: "Parchment",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/8794_parchment_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/8794_parchment_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/8794_parchment_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "8796-72",
    name: "Natural",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/8796_natural_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/8796_natural_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/8796_natural_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9174-96",
    name: "Navy",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9174_navy_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9174_navy_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9174_navy_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9177-36",
    name: "Mist",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9177_mist_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9177_mist_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9177_mist_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9508-05",
    name: "Sand",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9508_sand_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9508_sand_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9508_sand_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9508-63",
    name: "Smoke",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9508_smoke_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9508_smoke_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9508_smoke_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9511-97",
    name: "Oasis",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9511_oasis_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9511_oasis_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9511_oasis_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9511-08",
    name: "Straw",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9511_straw_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9511_straw_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9511_straw_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9534-96",
    name: "Navy",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9534_navy_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9534_navy_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9534_navy_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9599-08",
    name: "Bamboo",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9599_bamboo_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9599_bamboo_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9599_bamboo_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9600-90",
    name: "Delft",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9600_delft_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9600_delft_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9600_delft_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9600-70",
    name: "Parchment",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9600_parchment_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9600_parchment_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9600_parchment_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9600-63",
    name: "Sterling",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9600_sterling_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9600_sterling_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9600_sterling_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9600-08",
    name: "Wheat",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9600_wheat_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9600_wheat_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9600_wheat_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9688-08",
    name: "Bamboo",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9688_bamboo_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9688_bamboo_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9688_bamboo_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9690-63",
    name: "Silver",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9690_silver_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9690_silver_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9690_silver_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9691-07",
    name: "Barley",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9691_barley_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9691_barley_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9691_barley_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9691-36",
    name: "Mist",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9691_mist_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9691_mist_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9691_mist_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9691-68",
    name: "Onyx",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9691_onyx_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9691_onyx_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9691_onyx_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9691-63",
    name: "Smoke",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9691_smoke_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9691_smoke_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9691_smoke_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9701-90",
    name: "Denim",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9701_denim_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9701_denim_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9701_denim_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9701-72",
    name: "Linen",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9701_linen_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9701_linen_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9701_linen_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9701-76",
    name: "Oyster",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9701_oyster_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9701_oyster_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9701_oyster_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9701-63",
    name: "Smoke",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9701_smoke_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9701_smoke_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9701_smoke_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9704-65",
    name: "Pewter",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9704_pewter_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9704_pewter_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9704_pewter_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9706-08",
    name: "Bamboo",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9706_bamboo_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9706_bamboo_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9706_bamboo_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9706-35",
    name: "Driftwood",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9706_driftwood_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9706_driftwood_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9706_driftwood_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9706-61",
    name: "Graphite",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9706_graphite_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9706_graphite_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9706_graphite_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9706-75",
    name: "Ivory",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9706_ivory_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9706_ivory_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9706_ivory_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9706-93",
    name: "Lavender",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9706_lavender_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9706_lavender_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9706_lavender_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9706-37",
    name: "Patina",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9706_patina_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9706_patina_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9706_patina_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9800-63",
    name: "Feather",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9800_feather_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9800_feather_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9800_feather_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9800-75",
    name: "Ivory",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9800_ivory_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9800_ivory_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9800_ivory_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9800-73",
    name: "Papyrus",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9800_papyrus_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9800_papyrus_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9800_papyrus_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9800-60",
    name: "Quarry",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9800_quarry_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9800_quarry_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9800_quarry_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9901-63",
    name: "Feather",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9901_feather_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9901_feather_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9901_feather_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9901-72",
    name: "Linen",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9901_linen_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9901_linen_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9901_linen_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9901-62",
    name: "Pumice",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9901_pumice_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9901_pumice_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9901_pumice_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9901-75",
    name: "Vanilla",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9901_vanilla_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9901_vanilla_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9901_vanilla_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9901-69",
    name: "Zinc",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9901_zinc_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9901_zinc_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9901_zinc_seamless_icon.jpg`,
    repeat: "8",
  },
  {
    id: "9949-03",
    name: "Cafe",
    map: `${themeConfiguration.model_url}/3d/textures/fabric/9949_cafe_seamless.jpg`,
    normal: `${themeConfiguration.model_url}/3d/textures/fabric/9949_cafe_seamless_normal.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/fabric/9949_cafe_seamless_icon.jpg`,
    repeat: "8",
  },
];

export const floorArray = [
  {
    name: "Grid",
    default: 1,
    map: `${themeConfiguration.model_url}/3d/textures/floor/grid.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/floor/grid_icon.jpg`,
    repeat: 500,
  },
  {
    name: "Carpeting",
    default: 0,
    map: `${themeConfiguration.model_url}/3d/textures/floor/carpet.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/floor/carpet_icon.jpg`,
    repeat: 20,
  },
  {
    name: "Bamboo",
    default: 0,
    map: `${themeConfiguration.model_url}/3d/textures/floor/parquet.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/floor/parquet_icon.jpg`,
    repeat: 75,
  },
  {
    name: "Cedar",
    default: 0,
    map: `${themeConfiguration.model_url}/3d/textures/floor/dark_parquet.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/floor/dark_parquet_icon.jpg`,
    repeat: 75,
  },
  {
    name: "Mocca",
    default: 0,
    map: `${themeConfiguration.model_url}/3d/textures/floor/dark_parquet_2.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/floor/dark_parquet_2_icon.jpg`,
    repeat: 75,
  },
];
export const nailFinishArray = [
  {
    name: "Aged Bronze",
    map: `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_aged_bronze.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_aged_bronze_swach.png`,
    repeat: 5,
    roughness: 0.3,
    metalness: 0.15,
  },
  {
    name: "Black Nickel",
    map: `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_black_nickel.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_black_nickel_swach.png`,
    repeat: 5,
    roughness: 0.3,
    metalness: 0.45,
  },
  {
    name: "Brass",
    map: `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_brass.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_brass_swach.png`,
    repeat: 5,
    roughness: 0.3,
    metalness: 0.45,
  },
  {
    name: "Bright Brass",
    map: `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_bright_brass.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_bright_brass_swach.png`,
    repeat: 5,
    roughness: 0.3,
    metalness: 0.2,
  },
  {
    name: "Nickel",
    map: `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_nickel.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_nickel_swach.png`,
    repeat: 5,
    roughness: 0.15,
    metalness: 0.6,
  },
  {
    name: "Pewter",
    map: `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_pewter.jpg`,
    icon: `${themeConfiguration.model_url}/3d/textures/nail_finish/Haven Reserve_pewter_swach.png`,
    repeat: 5,
    roughness: 0.25,
    metalness: 0.4,
  },
];

export const frameNailSizeArray = [
  {
    name: "Small",
    icon: `${themeConfiguration.model_url}/3d/textures/nail_finish/nail_small.png`,
    size: "small_pins",
  },
  {
    name: "Medium",
    icon: `${themeConfiguration.model_url}/3d/textures/nail_finish/nail_medium.png`,
    size: "medium_pins",
  },
  {
    name: "Large",
    icon: `${themeConfiguration.model_url}/3d/textures/nail_finish/nail_large.png`,
    size: "large_pins",
  },
];

export const sideNailSizeArray = [
  {
    name: "Small",
    icon: `${themeConfiguration.model_url}/3d/textures/nail_finish/nail_small.png`,
    size: "small_pins",
  },
];
