import { MDBRow, MDBContainer } from "mdbreact";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { RouteComponentProps } from "react-router-dom";
import CollectionHeader from "../../components/CollectionHeader";
import WishlistOverview from "../../components/WishlistOverview";
import WishlistsWrapper from "../../components/WishlistsWrapper";
import { useDeleteWishlist, useWishlists } from "../../hooks";
import { getCounts } from "../../utils";

export interface WishlistsProps extends RouteComponentProps {}

const Wishlists: React.FC<WishlistsProps> = (props) => {
  const wishlists = useWishlists();

  const counts = React.useMemo(() => getCounts(wishlists), [wishlists]);

  const deleteWishlist = useDeleteWishlist();
  const isTablet = useMediaQuery({ maxWidth: 992 });

  return (
    <WishlistsWrapper counts={counts} path={props.location.pathname}>
      <CollectionHeader
        name="Your Lists"
        count={counts.wishlists}
        editButton={null}
      />
      <>
        <div className="mt-5 d-grid">
          {wishlists.map((wishlist) => {
            const handleDelete = () => {
              deleteWishlist(wishlist ?? null);
            };

            return (
              <WishlistOverview
                key={wishlist.name}
                wishlist={wishlist}
                onDelete={handleDelete}
              />
            );
          })}
        </div>
      </>
    </WishlistsWrapper>
  );
};

export default Wishlists;
