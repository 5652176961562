import React from "react";
import Pagination from "app/layout/Pagination/Pagination";
import Select from "app/layout/Select";
import { MDBTypography } from "mdbreact";

function Toolbar({
  currentPage,
  setCurrentPage,
  totalPages,
  pageSize,
  setPageSize,
  pageSizeOptions,
  totalCount,
  isTablet,
}) {
  let pageOptionsLessItems = pageSizeOptions;
  // if (totalCount / 100 >= 1) {
  //   pageOptionsLessItems = pageSizeOptions;
  // } else if (totalCount / 50 >= 1) {
  //   pageOptionsLessItems = pageSizeOptions;
  // } else if (totalCount / 20 >= 1) {
  //   pageOptionsLessItems = pageSizeOptions?.filter(function (option) {
  //     return option.value !== 100;
  //   });
  // } else if (totalCount / 10 >= 1) {
  //   pageOptionsLessItems = pageSizeOptions?.filter(function (option) {
  //     return option.value !== 100 && option.value !== 50;
  //   });
  // }

  return (
    <div
      className={`d-flex ${
        isTablet ? "justify-content-end" : "justify-content-start"
      }`}
    >
      <div
        className="px-0 p-0 d-flex justify-content-lg-end justify-content-start align-items-center view-and-pag-col view"
        style={{ overflow: "inherit" }}
      >
        <MDBTypography tag="span" className="body-6 mr-3 d-none d-lg-block">
          View:
        </MDBTypography>
        <div className="w-100 counter-select">
          <Select
            value={pageSize}
            options={pageOptionsLessItems}
            onChange={(e) => setPageSize(e.value)}
            isSearchable={false}
            placeholder={pageSize}
          />
        </div>
      </div>
      <Pagination
        // @ts-ignore
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
      />
    </div>
  );
}

export default Toolbar;
