export default (state, action) => {
  return Object.assign({}, state, {
    data: Object.assign({}, state.data, {
      cartToken: action.cart.id,
      cart: {
        ...action.cart,
        items: action.cart.items.map((item) => ({
          ...item,
          tempQuantity: item.quantity,
        })),
      },
      loading: false,
    }),
  });
};
