import MagicSliderDots from "react-magic-slider-dots";
import React from "react";

export const sliderSetting = (
  arrayLength,
  activeSlide,
  setActiveSlide,
  isPatternFilter
) => {
  return {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    controls: true,
    arrows: true,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: 0,
    rows: isPatternFilter ? 4 : 3,
    slidesPerRow: isPatternFilter
      ? 4
      : window.location.pathname.includes("graded-in")
      ? window.innerWidth > 576
        ? 6
        : 2
      : 3,
    adaptiveHeight: false,
    variableWidth: false,
    afterChange: (index) => {
      setActiveSlide(index);
    },
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />;
    },
  };
};

export const navSliderSettings = () => {
  return {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 10,
    arrows: true,
    slidesToScroll: 10,
    swipeToSlide: true,
    initialSlide: 0,
    adaptiveHeight: true,
    variableWidth: false,
  };
};
