import React from "react";
import { connect } from "react-redux";
import commonActions from "app/state/redux/data/common/actions";
import customerActions from "app/state/redux/data/customer/actions";
import OrderNotesStateContainer from "./orderNotes-state-container";

const OrderNotesReduxContainer = (props) => {
  return <OrderNotesStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  cart_id:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cart !== "undefined" &&
    typeof state.customer.data.cart.id !== "undefined" &&
    state.customer.data.cart.id !== null
      ? state.customer.data.cart.id
      : "",
  order_comment:
    typeof state.customer !== "undefined" &&
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cart !== "undefined" &&
    typeof state.customer.data.cart.order_comment !== "undefined" &&
    state.customer.data.cart.order_comment !== null
      ? state.customer.data.cart.order_comment
      : "",
});
const mapDispatchToProps = (dispatch) => {
  return {
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    addOrderNotesToCart: (payload) => {
      dispatch(customerActions.setOrderNotes(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderNotesReduxContainer);
