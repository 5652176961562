import React, { memo } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import InputField from "core/components/inputField";
import Button from "core/components/button";
import { isEqual } from "lodash";

const PoNumber = memo(
  ({ poNumber, setPoNumber, submit, originalPoNumber, loading }) => {
    return (
      <MDBCol size="12" className="po-number">
        <MDBRow>
          <MDBCol size="12" className={"po-number-wrapper"}>
            <InputField
              disabled={loading}
              className={`custom-input`}
              hint="Po Number"
              label={`enter po number`}
              type="text"
              value={poNumber}
              onChange={(e) => {
                setPoNumber(e.target.value);
              }}
            />
            <Button
              color="primary"
              disabled={
                loading ||
                (poNumber !== null && poNumber.length <= 2) ||
                (poNumber !== null && poNumber === originalPoNumber)
                  ? true
                  : false
              }
              className={"btn-with-icon"}
              direction={"mr-2"}
              leftIcon={true}
              icon={"arrow-right"}
              text={<span>{`${loading ? "applying..." : "apply"}`}</span>}
              onClick={(e) => {
                if (poNumber.length > 2 && poNumber !== originalPoNumber) {
                  submit(poNumber);
                }
              }}
            />
          </MDBCol>
        </MDBRow>
      </MDBCol>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default PoNumber;
