import { ApolloError } from "apollo-client";
import {
  CartItemUpdateInput,
  CustomizableOptionInput,
  SimpleProductCartItemInput,
  UpdateCartItemsInput,
  useAddSimpleProductsToCartMutation,
  useCustomerCartQuery,
  useUpdateCartItemsMutation,
} from "app/generated/graphql";
import CartContext from "app/layout/cart";
import {
  selectIsLoggedIn,
  selectCart,
  selectCustomerData,
} from "app/state/redux/data/customer/selectors";
import { messagesActions } from "core/state/redux/data/messages";
import { customerActions } from "app/state/redux/data/customer";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";

export type CartItemInput = Pick<SimpleProductCartItemInput, "data"> & {
  customizable_options: CustomizableOptionInput[];
};

export const useAddItemToCart = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const dispatch = useDispatch();

  const cartQuery = useCustomerCartQuery({
    skip: !isLoggedIn,
    fetchPolicy: "network-only",
  });

  const { openModal } = React.useContext(CartContext);

  const [addItemToCart, result] = useAddSimpleProductsToCartMutation({
    onCompleted: () => {
      dispatch(customerActions.getCartInformation());
      openModal();
    },
    onError: (error: ApolloError) => {
      dispatch(
        messagesActions.addMessage(
          error.graphQLErrors[0]?.message ??
            "Failed to add item to quote request.",
          "error"
        )
      );
    },
  });

  return [
    React.useCallback(
      (cartItemInput: CartItemInput) => {
        if (cartQuery.data?.customerCart.id) {
          return addItemToCart({
            variables: {
              input: {
                cart_id: cartQuery.data.customerCart.id,
                cart_items: Array.isArray(cartItemInput)
                  ? cartItemInput
                  : [cartItemInput],
              },
            },
          });
        } else {
          console.error("Cannot add item to cart if the cart id is undefined.");
        }
      },
      [addItemToCart, cartQuery]
    ),
    result,
  ] as const;
};

export const useUpdateItemsOnCart = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const cart = useSelector(selectCustomerData).cart;

  const dispatch = useDispatch();

  const cartQuery = useCustomerCartQuery({
    skip: !isLoggedIn,
    fetchPolicy: "network-only",
  });

  const { openModal } = React.useContext(CartContext);

  const [updateCartItems, result] = useUpdateCartItemsMutation({
    onCompleted: () => {
      dispatch(customerActions.getCartInformation());
      openModal();
    },
    onError: (error: ApolloError) => {
      dispatch(
        messagesActions.addMessage(
          error.graphQLErrors[0]?.message ??
            "Failed to add item to quote request.",
          "error"
        )
      );
    },
  });

  return [
    React.useCallback(
      (updateItem) => {
        const pathname = window.location.pathname;

        let monogramProductId;
        if (pathname.includes("monogram")) {
          const arr = window.location.search.split("=");
          monogramProductId = arr[arr.length - 1];
        }
        let parsed = qs.parse(window.location.href);
        const parsedItemId = parsed?.["item_id"];
        const itemId = pathname.includes("monogram")
          ? monogramProductId
          : qs.parse(
              updateItem.customizable_options?.[
                pathname.includes("build-your-own")
                  ? updateItem.customizable_options?.length - 2
                  : updateItem.customizable_options?.length - 1
              ]?.value_string
              // @ts-ignore
            )?.["item_id"] ?? parsedItemId?.[parsedItemId.length - 1];

        let customizable_options: CustomizableOptionInput[] =
          updateItem.customizable_options;

        if (cartQuery.data?.customerCart.id) {
          let cart_items: CartItemUpdateInput[] = [];

          cart?.items?.forEach((item) => {
            if (item?.id == itemId || item?.id == parsedItemId) {
              cart_items.push({
                cart_item_id: parsedItemId
                  ? // @ts-ignore
                    parseFloat(parsedItemId)
                  : // @ts-ignore
                    parseFloat(item.id),
                customizable_options,
                // @ts-ignore
                quantity: item.quantity,
              });
            }
          });

          return updateCartItems({
            variables: {
              input: {
                // @ts-ignore
                cart_id: cartQuery.data.customerCart.id,
                cart_items,
              },
            },
          });
        } else {
          console.error("Cannot add item to cart if the cart id is undefined.");
        }
      },
      [updateCartItems, cartQuery]
    ),
    result,
  ] as const;
};
