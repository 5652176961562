/* eslint-disable jsx-a11y/no-static-element-interactions */
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React, { useEffect } from "react";
import Slider from "react-slick";
import InfoContainer from "../info";
import { fabricSliderSettings } from "./fabricSliderSettings";

const FabricMediaGallery = (props) => {
  let {
    productData,
    mediaGallery,
    mainSlider,
    thumbnailSlider,
    setSliderNav,
    thumbnailSliderNav,
    setThumbnailSliderNav,
    aggregations,
    colorwaysRef,
    colorwaysItems,
  } = props;

  useEffect(() => {
    setThumbnailSliderNav(thumbnailSlider);
    setSliderNav(mainSlider);
  }, [mainSlider, setSliderNav, setThumbnailSliderNav, thumbnailSlider]);
  return (
    <MDBContainer className="fabric-media-gallery-container py-md-5">
      <MDBRow>
        <MDBCol size="12" lg="7">
          <Slider
            {...fabricSliderSettings}
            className="fabric-media-slider"
            ref={(slider) => (mainSlider = slider)}
            asNavFor={thumbnailSliderNav}
          >
            {mediaGallery &&
              mediaGallery.map((image, index) => {
                return (
                  <img
                    key={index}
                    src={image.url}
                    alt={image.label || "The product"}
                  />
                );
              })}
          </Slider>
        </MDBCol>
        <MDBCol size="12" lg="5" className="fabric-info-container">
          <MDBRow style={{ height: "100%" }}>
            <MDBCol size="12">
              <InfoContainer
                productData={productData}
                aggregations={aggregations}
                colorwaysRef={colorwaysRef}
                colorwaysItems={colorwaysItems}
              />
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default FabricMediaGallery;
