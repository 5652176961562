import React, { useState } from "react";
import { MDBCol } from "mdbreact";
import Switch from "app/layout/Switch";
import { addFilter, removeFilter } from "../functions";

const SwitchFilterItem = (props) => {
  let { filter, activeFilters, setActiveFilters } = props;
  const [switched, setSwitched] = useState(false);
  const handleChange = () => {
    let option = false;
    if (switched) {
      option = { label: "0", value: "0" };
      setSwitched(false);
      removeFilter(
        option,
        activeFilters,
        setActiveFilters,
        filter.attribute_code,
        filter.type
      );
    } else {
      option = { label: "1", value: "1" };
      addFilter(
        option,
        activeFilters,
        setActiveFilters,
        filter.attribute_code,
        filter.type
      );
      setSwitched(true);
    }
  };
  return (
    <MDBCol className="switch-filter-container">
      <Switch
        label={filter.label}
        checked={switched}
        onChange={(e) => {
          handleChange();
        }}
      />
    </MDBCol>
  );
};
export default SwitchFilterItem;
