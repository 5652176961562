import { useBoolean } from "ahooks";
import React from "react";
import { useCreationWishlistForm } from "../../hooks";
import WishlistsModal, { WishlistsModalProps } from "./WishlistsModal";
export interface StateContainerProps
  extends Omit<
    WishlistsModalProps,
    | "creationWishlistForm"
    | "formShown"
    | "onShowForm"
    | "creationWishlistError"
  > {}

const StateContainer: React.FC<StateContainerProps> = (props) => {
  const creationWishlistForm = useCreationWishlistForm();
  const [formShown, setFormShown] = useBoolean(true);

  return (
    <WishlistsModal
      {...props}
      creationWishlistForm={creationWishlistForm}
      formShown={formShown}
      onShowForm={setFormShown.setTrue}
    />
  );
};

export default StateContainer;
