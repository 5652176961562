import Collections from "./collections";
import React, { useState, useEffect } from "react";
import Config from "config/themeConfiguration";

const CollectionQueryContainer = (props) => {
  const [pageSize, setPageSize] = useState(Config.pageSizeOptions[0].value);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSizeOptions = Config.pageSizeOptions;

  /**
   *
   * @param {{ value: number }} pageSize
   */
  const handleSetPageSize = (pageSize) => {
    props.toolbarProps.setPageSize(pageSize);
    setPageSize(pageSize.value);
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize]);

  useEffect(() => {
    props.toolbarProps.setCurrentPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setPageSize(props?.data?.products?.page_info?.page_size);
  }, [props?.data?.products?.page_info?.page_size]);

  const productsInCollection = props?.listingProps;

  return (
    <Collections
      data={props?.data}
      productsInCollection={productsInCollection}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalPages={props?.data?.products?.page_info?.total_pages}
      totalCount={props?.data?.products?.total_count}
      setPageSize={handleSetPageSize}
      pageSizeOptions={pageSizeOptions}
      pageSize={pageSize}
      {...props}
    />
  );
};

export default CollectionQueryContainer;
