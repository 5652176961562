// define project specific reducers
// import testReducer from "./data/test";
import commonReducer from "./data/common";
import customerReducer from "./data/customer/reducer";
import filtersReducer from "./data/filters";
import { networkStatus } from "./data/networkStatus/networkStatus";
import searchReducer from "./data/search";

const projectReducers = {
  // test: testReducer
  common: commonReducer,
  filters: filtersReducer,
  customer: customerReducer,
  networkStatus,
  searchPage: searchReducer,
};

export default projectReducers;
