import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";

const FabricSidebarSkeleton = () => {
  const isMobile = useMediaQuery({ maxWidth: "600px" });
  return (
    <div className="finish-sidebar-skeleton">
      <MDBContainer fluid className="px-0 pt-1 pr-3 my-1 pb-4 items-skeleton">
        <MDBCol>
          <MDBRow>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            {!isMobile && (
              <>
                <MDBCol>
                  <Skeleton height={`5.6rem`} width={`100%`} />
                </MDBCol>
                <MDBCol>
                  <Skeleton height={`5.6rem`} width={`100%`} />
                </MDBCol>
              </>
            )}
          </MDBRow>
        </MDBCol>
      </MDBContainer>
      <MDBContainer fluid className="px-0 pr-3 my-1 pb-4  items-skeleton">
        <MDBCol>
          <MDBRow>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            {!isMobile && (
              <>
                <MDBCol>
                  <Skeleton height={`5.6rem`} width={`100%`} />
                </MDBCol>
                <MDBCol>
                  <Skeleton height={`5.6rem`} width={`100%`} />
                </MDBCol>
              </>
            )}
          </MDBRow>
        </MDBCol>
      </MDBContainer>
      <MDBContainer fluid className="px-0 pr-3 my-1 pb-4  items-skeleton">
        <MDBCol>
          <MDBRow>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            {!isMobile && (
              <>
                <MDBCol>
                  <Skeleton height={`5.6rem`} width={`100%`} />
                </MDBCol>
                <MDBCol>
                  <Skeleton height={`5.6rem`} width={`100%`} />
                </MDBCol>
              </>
            )}
          </MDBRow>
        </MDBCol>
      </MDBContainer>
      <MDBContainer fluid className="px-0 pr-3 my-1 pb-4  items-skeleton">
        <MDBCol>
          <MDBRow>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            {!isMobile && (
              <>
                <MDBCol>
                  <Skeleton height={`5.6rem`} width={`100%`} />
                </MDBCol>
                <MDBCol>
                  <Skeleton height={`5.6rem`} width={`100%`} />
                </MDBCol>
              </>
            )}
          </MDBRow>
        </MDBCol>
      </MDBContainer>
      <MDBContainer fluid className="px-0 pr-3 my-1 pb-4  items-skeleton">
        <MDBCol>
          <MDBRow>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            <MDBCol>
              <Skeleton height={`5.6rem`} width={`100%`} />
            </MDBCol>
            {!isMobile && (
              <>
                <MDBCol>
                  <Skeleton height={`5.6rem`} width={`100%`} />
                </MDBCol>
                <MDBCol>
                  <Skeleton height={`5.6rem`} width={`100%`} />
                </MDBCol>
              </>
            )}
          </MDBRow>
        </MDBCol>
      </MDBContainer>
    </div>
  );
};
export default FabricSidebarSkeleton;
