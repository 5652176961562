import { customerActions } from "app/state/redux/data/customer";
import {
  CartItemUpdateInput,
  DomainCartFragment,
  RemoveItemFromCartInput,
  UpdateCartItemsInput,
  useRemoveItemFromCartMutation,
  useUpdateCartItemsMutation,
} from "app/generated/graphql";
import { messagesActions } from "core/state/redux/data/messages";
import { FormikConfig, useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { quantitySchema, QuantityValues } from "./components/Item/config";
import { DomainCartItem } from "./models";
import { isDomainCartItem } from "./utils";

export const useIsMobile = () => useMediaQuery({ maxWidth: 992 });

export type RemoveCartItemInput = Pick<
  RemoveItemFromCartInput,
  "cart_item_id"
> & {
  cart: DomainCartFragment;
};

export const useParameterizedRemoveItemFromCart = () => {
  const dispatch = useDispatch();

  const [removeItemFromCart, result] = useRemoveItemFromCartMutation({
    onCompleted: () => {
      dispatch(messagesActions.addMessage("Item removed.", "success"));
      dispatch(customerActions.getCartInformation());
    },
    onError: (error) => {
      dispatch(
        messagesActions.addMessage(
          error.graphQLErrors[0]?.message ??
            "Failed to remove item, please retry.",
          "error"
        )
      );
    },
  });

  const handleRemoveItemFromCart = React.useCallback(
    ({ cart, cart_item_id }: RemoveCartItemInput) => {
      removeItemFromCart({
        variables: {
          input: { cart_id: cart.id, cart_item_id },
        },
        optimisticResponse: (variables) => ({
          __typename: "Mutation",
          removeItemFromCart: {
            __typename: "RemoveItemFromCartOutput",
            cart: {
              ...cart,
              items: cart.items
                ?.filter(isDomainCartItem)
                .filter((item) => Number(item.id) !== cart_item_id),
            },
          },
        }),
      });
    },
    [removeItemFromCart]
  );

  return [handleRemoveItemFromCart, result] as const;
};

export const useRemoveItemFromCart = (input: RemoveCartItemInput) => {
  const [removeItemFromCart, result] = useParameterizedRemoveItemFromCart();

  return [
    React.useCallback(() => removeItemFromCart(input), [
      removeItemFromCart,
      input,
    ]),
    result,
  ] as const;
};

export const useQuantityForm = ({
  cart,
  cart_item_id,
  quantity,
}: RemoveCartItemInput & Pick<DomainCartItem, "quantity">) => {
  const dispatch = useDispatch();

  const [updateCartItems] = useUpdateCartItemsMutation({
    optimisticResponse: ({ input: { cart_id, cart_items } }) => ({
      __typename: "Mutation",
      updateCartItems: {
        __typename: "UpdateCartItemsOutput",
        cart: {
          __typename: "Cart",
          ...cart,
          id: cart_id,
          items: cart.items?.filter(isDomainCartItem)?.map((item) => {
            const itemToUpdate = cart_items.find(
              (cartItem) => cartItem?.cart_item_id === Number(item.id)
            );

            if (itemToUpdate) {
              return {
                ...item,
                quantity: itemToUpdate.quantity ?? item.quantity,
                product: {
                  ...item.product,
                  gift_message_available:
                    itemToUpdate.gift_message ??
                    item.product.gift_message_available,
                },
              };
            } else {
              return item;
            }
          }),
        },
      },
    }),
    onCompleted: () => {
      dispatch(customerActions.getCartInformation());
    },
    onError: (error) => {
      dispatch(
        messagesActions.addMessage(
          error.graphQLErrors[0]?.message ??
            "Failed to updated quantity, please retry.",
          "error"
        )
      );
    },
  });

  const updateCartItemQuantity = React.useCallback(
    ({
      cart_id,
      cart_item_id,
      quantity,
    }: Pick<UpdateCartItemsInput, "cart_id"> &
      Required<Pick<CartItemUpdateInput, "cart_item_id" | "quantity">>) => {
      updateCartItems({
        variables: {
          input: { cart_id, cart_items: [{ cart_item_id, quantity }] },
        },
      });
    },
    [updateCartItems]
  );

  const quantityConfig: FormikConfig<QuantityValues> = React.useMemo(
    () => ({
      initialValues: { quantity },
      validationSchema: quantitySchema,
      onSubmit: ({ quantity }) => {
        updateCartItemQuantity({
          cart_id: cart.id,
          //@ts-ignore
          cart_item_id: cart_item_id,
          quantity,
        });
      },
    }),
    [cart.id, cart_item_id, updateCartItemQuantity, quantity]
  );
  return useFormik(quantityConfig);
};
