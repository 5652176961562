import { MDBCol, MDBTypography } from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";
import { Address } from "../models";

export interface AddressProps {
  address: Address;
  title: string;
  editLink: string;
}

const AddressOverview: React.FC<AddressProps> = (props) => {
  return (
    <MDBCol size="12" lg="6" className="mb-5">
      {props.title ? (
        <MDBTypography tag="h4" className="mb-4 pb-2">
          {props.title}
        </MDBTypography>
      ) : null}
      {props?.address?.address_label ? (
        <MDBTypography
          tag="h4"
          className="mb-4 pb-2 font-weight-normal"
          // @ts-ignore
          style={{ color: "#6c808b" }}
        >
          {props?.address?.address_label}
        </MDBTypography>
      ) : null}
      {/* @ts-ignore */}
      <MDBTypography className="mb-3" variant="body-6">
        {`${props.address.firstname} ${props.address.lastname}`}
      </MDBTypography>
      {/* @ts-ignore */}
      <MDBTypography className="mb-3" variant="body-6">
        {props.address.street?.[0]}
      </MDBTypography>
      {/* @ts-ignore */}
      <MDBTypography className="mb-3" variant="body-6">
        {props.address.city}, {props.address?.region?.region}{" "}
        {props.address.postcode}
      </MDBTypography>
      {/* @ts-ignore */}
      <MDBTypography className="mb-4 pb-4" variant="body-6">
        {props.address.country_name}
      </MDBTypography>
      <Link to={props.editLink}>
        {/* @ts-ignore */}
        <MDBTypography variant="body-1" className="d-inline-block underline">
          Edit
        </MDBTypography>
      </Link>
    </MDBCol>
  );
};

export default AddressOverview;
