export default (state, action) => {
  return Object.assign({}, state, {
    data: {
      ...state.data,
      compareItems: action.payload,
    },
    open: state.open,
    loading: false,
  });
};
