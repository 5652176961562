import { selectCustomerToken } from "app/state/redux/data/customer/selectors";
import commonActions from "core/state/redux/data/common/actions";
import { messagesActions } from "core/state/redux/data/messages";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductStateContainer from "./state-container";
// import FabricPdp from "./fabricPdp";

const ProductReduxContainer = (props) => {
  // let attribute =
  //   props.data.attribute_set_id === undefined
  //     ? props.data.urlResolver.attribute_set_id
  //     : props.data.attribute_set_id;
  // let isFabricPDP = attribute === 9 ? true : false;

  let productSku =
    props.data.sku === undefined ? props.data.urlResolver.sku : props.data.sku;

  const dispatch = useDispatch();
  const customer = useSelector(selectCustomerToken);
  const dispatchProps = {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: () => {
      dispatch(commonActions.lock());
    },
    addMessage: (message, type) => {
      dispatch(messagesActions.addMessage(message, type));
    },
  };

  // if (isFabricPDP) {
  //   return <FabricPdp {...props} />;
  // } else {

  // }
  return (
    <ProductStateContainer
      {...props}
      {...dispatchProps}
      customer={customer}
      productSku={productSku}
    />
  );
};

export default ProductReduxContainer;
