import React from "react";
import { MDBInput } from "mdbreact";
import Icon from "app/assets/icon/icon";
import Checkbox from "app/layout/Checkbox";

function Filters({ searchVal, setSearchVal, stockToggle, setStockToggle }) {
  return (
    <div className="">
      <div className="d-flex">
        <div className="search-input-wrapper">
          <MDBInput
            className={"search-input"}
            hint={`Search`}
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
          >
            <Icon icon="search" />
          </MDBInput>
        </div>
        <Checkbox
          containerClass={"checkbox-container check-button"}
          label={"In Stock"}
          type={"checkbox"}
          value={stockToggle}
          checked={stockToggle}
          onChange={() => setStockToggle(!stockToggle)}
          wrapperClass={"checkmark gray"}
        />
      </div>
    </div>
  );
}

export default Filters;
