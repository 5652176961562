import { useBoolean } from "ahooks";
import MiniCart from "app/pages/Cart/components/MiniCart";
import React from "react";
import CartContext, { initialCartContext } from "./CartContext";

export interface CartContextProviderProps {}

const CartContextProvider: React.FC<CartContextProviderProps> = (props) => {
  const [isModalOpen, setIsModalOpen] = useBoolean();

  const [orderItemDataRecord, setOrderItemDataRecord] = React.useState(
    initialCartContext.orderItemDataRecord
  );

  return (
    <CartContext.Provider
      value={React.useMemo(
        () => ({
          isModalOpen,
          openModal: setIsModalOpen.setTrue,
          closeModal: setIsModalOpen.setFalse,
          orderItemDataRecord,
          setOrderItemDataRecord,
        }),
        [isModalOpen, setIsModalOpen, orderItemDataRecord]
      )}
    >
      {props.children}
      <MiniCart />
    </CartContext.Provider>
  );
};

export default CartContextProvider;
