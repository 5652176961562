// @ts-check

import { useFormik } from "formik";
import { MDBCol, MDBRow } from "mdbreact";
import React, { useState } from "react";
import StepOne from "./steps/StepOne";
import {
  companyDetailsInitialValues,
  companyDetailsValidationSchema,
} from "./steps/StepOne/config";
import StepThree from "./steps/StepThree";
import {
  mailingAndShippingInitialValues,
  mailingAndShippingValidationSchema,
} from "./steps/StepThree/config";
import StepTwo from "./steps/StepTwo";
import {
  contactInfoInitialValues,
  contactInfoValidationSchema,
} from "./steps/StepTwo/config";

/**
 * @type {import('./steps/StepOne').Doc}
 */
const initialTaxIdDoc = {
  status: "initial",
  value: null,
};

/**
 *
 * @typedef {Pick<import('./steps/StepThree').StepThreeProps, 'isSubmitting' | 'error'>
 *   & {
 *   onSubmit: (formValues: {
 *     companyDetails: import('./steps/StepOne/config').CompanyDetailsValues; contactInfo: import('./steps/StepTwo/config').ContactInfoValues;
 *     mailingValues: import('./steps/StepThree/config').MailingShippingValues;
 *     shippingValues: null | import('./steps/StepThree/config').MailingShippingValues,
 *     is_subscribed: boolean,
 *     taxIdDoc: string,
 *   }) => Promise<void>
 * }} StepsContainerProps
 * /
 /**
 * @type {React.FC<StepsContainerProps>}
 */
const RegisterStepsContainer = (props) => {
  const [step, setStep] = useState(0);
  const [error, setError] = useState("");
  /**
   * @type {[import('./steps/StepOne').Doc, React.Dispatch<React.SetStateAction<import('./steps/StepOne').Doc>>]}
   */
  // @ts-ignore
  const [taxIdDoc, setTaxIdDoc] = React.useState(initialTaxIdDoc);

  /**
   * @type {import('formik').FormikConfig<import('./steps/StepOne/config').CompanyDetailsValues>}
   */
  const companyDetailsFormConfig = React.useMemo(
    () => ({
      initialValues: companyDetailsInitialValues,
      validationSchema: companyDetailsValidationSchema,
      onSubmit: () => {
        if (error === "") {
          setStep(1);
        }
      },
    }),
    [setStep, error]
  );
  const companyDetailsForm = useFormik(companyDetailsFormConfig);
  const [stepTwoData, setStepTwoData] = React.useState({});

  /**
   * @type {import('formik').FormikConfig<import('./steps/StepTwo/config').ContactInfoValues>}
   */
  // @ts-ignore
  const contactInfoConfig = React.useMemo(
    () => ({
      initialValues: contactInfoInitialValues,
      validationSchema: contactInfoValidationSchema,
      onSubmit: () => {
        setStep(2);
      },
    }),
    [setStep]
  );

  const contactInfoForm = useFormik(contactInfoConfig);
  /**
   * @type {import('formik').FormikConfig<import('./steps/StepThree/config').MailingAndShippingValues>}
   */
  const mailingAndShippingConfig = React.useMemo(() => {
    return {
      initialValues: mailingAndShippingInitialValues,
      validationSchema: mailingAndShippingValidationSchema,
      onSubmit: (values) => {
        props.onSubmit({
          companyDetails: companyDetailsForm.values,
          contactInfo: contactInfoForm.values,
          mailingValues: values.mailing,
          shippingValues: values.sameAsMailing ? null : values.shipping,
          is_subscribed: values.updates,
          // @ts-ignore
          taxIdDoc: taxIdDoc.value?.name,
        });
      },
    };
  }, [taxIdDoc, props, companyDetailsForm, contactInfoForm]);
  const mailingAndShippingForm = useFormik(mailingAndShippingConfig);

  React.useEffect(() => {
    setStepTwoData(contactInfoForm.values);
  }, [contactInfoForm]);

  /**
   * Copies relevant data from step two to step three form
   */
  React.useEffect(() => {
    mailingAndShippingForm.setFieldValue(
      "mailing.firstName",
      contactInfoForm.values.firstName
    );
    mailingAndShippingForm.setFieldValue(
      "mailing.lastName",
      contactInfoForm.values.lastName
    );
    mailingAndShippingForm.setFieldValue(
      "mailing.email",
      contactInfoForm.values.email
    );
    mailingAndShippingForm.setFieldValue(
      "mailing.phone",
      contactInfoForm.values.phone
    );
    mailingAndShippingForm.setFieldValue(
      "mailing.contactName",
      `${mailingAndShippingForm.values.mailing?.firstName?.trim()} ${mailingAndShippingForm.values.mailing?.lastName?.trim()}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contactInfoForm.values.firstName,
    contactInfoForm.values.lastName,
    contactInfoForm.values.email,
    contactInfoForm.values.phone,
  ]);

  const currentStep = React.useMemo(
    () =>
      [
        <StepOne
          key="1"
          taxIdDoc={taxIdDoc}
          setTaxIdDoc={setTaxIdDoc}
          setStep={setStep}
          form={companyDetailsForm}
          // @ts-ignore
          error={error}
          setError={setError}
        />,
        <StepTwo key="2" setStep={setStep} form={contactInfoForm} />,
        <StepThree
          key="3"
          isSubmitting={props.isSubmitting}
          error={props.error}
          form={mailingAndShippingForm}
          setStep={setStep}
          stepTwoData={stepTwoData}
        />,
      ][step],
    [
      step,
      companyDetailsForm,
      contactInfoForm,
      mailingAndShippingForm,
      props.isSubmitting,
      props.error,
      taxIdDoc,
      setTaxIdDoc,
    ]
  );

  return (
    <MDBRow className="registration-container">
      <MDBCol>{currentStep}</MDBCol>
    </MDBRow>
  );
};

export default RegisterStepsContainer;
