export const defaultOptions = (product) => {
  let defaultFabricOptions = [
    "contrasting_welt",
    "contrasting_buttons",
    "inside_back",
    "outside_back",
    "cushion",
    "tight_seat",
    "contrast_welt",
    "back_pillow",
    "main_fabric",
    "throw_pillow_contrast",
  ];

  let initialOptionState = {
    contrasting_welt: false,
    contrasting_buttons: false,
    inside_back: false,
    outside_back: false,
    cushion: false,
    tight_seat: false,
    contrast_welt: false,
    back_pillow: false,
    main_fabric: false,
    throw_pillow_contrast: false,
    throw_pillow_contrast_welt: false,
    nail_finish: false,
    nail_size: false,
  };

  defaultFabricOptions.forEach(
    (fabric) => (initialOptionState[fabric] = product?.default_fabric)
  );

  if (product?.options) {
    for (let i = 0; i < product?.options.length; i++) {
      const option = product?.options[i];
      if (option?.title === "Upholstery Options") {
        initialOptionState["upholstery_options"] = option?.value?.find(
          (opt) => {
            if (product?.default_fabric?.startsWith("116")) {
              return opt?.title === "leather1";
            }
            return opt?.title === "graded";
          }
        )?.option_type_id;
      }

      if (
        (option?.value?.length === 1 &&
          option?.title?.toLowerCase()?.split(" ")?.join("_") in
            initialOptionState) ||
        option?.category_options === "Additional Options"
      ) {
        initialOptionState[
          option?.title?.toLowerCase()?.split(" ")?.join("_")
        ] = option?.value?.[0]?.option_type_id;
        if (option?.title === "Nail Size" && option?.value?.length === 1)
          initialOptionState["nail_finish"] = "Bright Brass";
      }
    }
  }

  return initialOptionState;
};
