import React from "react";
import FilterTypes from "./filterTypes";

const addTypeToFilters = (filters) => {
  return filters.map((filter) => {
    if (!filter.hasOwnProperty("type") && isFilterSwitchFilter(filter)) {
      return {
        ...filter,
        type: "boolean",
      };
    } else {
      if (filter.attribute_code === "brand") {
        return {
          ...filter,
          type: "boolean",
        };
      }
      return {
        ...filter,
        type: "multi",
      };
    }
  });
};

const groupBy = (filters, key) => {
  return filters.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};

export const isFilterSwitchFilter = (filter) => {
  let filterIsSwitch = false;

  // eslint-disable-next-line no-unused-vars
  let mapValue = filter?.options?.map((filterOption) => {
    if (
      filterOption.value.toString() === "1" ||
      filterOption.value.toString() === "0"
    ) {
      filterIsSwitch = true;
    }
    return filterIsSwitch;
  });

  return filterIsSwitch;
};

export const generateFilterRender = (
  filters,
  activeFilters,
  setActiveFilters,
  selectedFilters,
  setSelectedFilters,
  currentPage,
  setCurrentPage,
  allItems
) => {
  if (filters === undefined) {
    return;
  }

  let copiedFilters = filters;
  // Remove Magento default filters
  if (filters[0]?.hasOwnProperty("options")) {
    copiedFilters = filters.filter((item) => {
      return (
        //add to array filter attribute_code to remove it from filters array that will be rendered
        [
          "price",
          "category_id",
          "is_new",
          "ffifabrics_vendor",
          "ffifabrics_cutyard",
          "filterable_attribute_set_name",
          "ffifabrics_pattern",
        ].indexOf(item.attribute_code) === -1
      );
    });

    copiedFilters = addTypeToFilters(copiedFilters);
    copiedFilters = groupBy(copiedFilters, "type");
    let sortedFilters = copiedFilters?.multi?.map((filter) => {
      return filter.attribute_code === "ffifabrics_collection"
        ? { ...filter, position: 1 }
        : filter.attribute_code === "ffifabrics_grade"
        ? { ...filter, position: 2 }
        : filter.attribute_code === "ffifabrics_category1"
        ? { ...filter, position: 3 }
        : filter.attribute_code === "ffifabrics_hexclrgrp"
        ? { ...filter, position: 4 }
        : filter;
    });
    sortedFilters.sort(
      (filter1, filter2) => filter1.position - filter2.position
    );
    copiedFilters = { ...copiedFilters, multi: sortedFilters };
  }

  return (
    <FilterTypes
      filters={copiedFilters}
      activeFilters={activeFilters}
      setActiveFilters={setActiveFilters}
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      allItems={allItems}
    />
    // <></>
    //FIXME: Here lies some madness I have just commented out, check on this at some point...
  );
};
