import JolaViewer from "@jola_interactive/jola_viewer";
import * as data from "../../pages/Custom/monogram/data.js";

import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { USDZExporter } from "three/examples/jsm/exporters/USDZExporter";
import themeConfiguration from "../../../config/themeConfiguration";

import {
  TextureLoader,
  sRGBEncoding,
  RepeatWrapping,
  Color,
  PlaneBufferGeometry,
  MeshBasicMaterial,
  ShadowMaterial,
  Mesh,
  Fog,
  Euler,
  Box3,
  Vector3,
  LineBasicMaterial,
  BufferGeometry,
  Line,
  FontLoader,
  TextGeometry,
  Group,
  PCFSoftShadowMap,
} from "three";

export const zoomOptions = {
  min: -3,
  current: 0,
  max: 8,
};

export class Player extends JolaViewer {
  constructor(containerID, options) {
    super(containerID, options);
    this.collectionPath = options.collectionPath
      ? options.collectionPath
      : "monogram";
    this.hdrPath = `${themeConfiguration.model_url}/3d/${this.collectionPath}/webgl/hdr/hdr.hdr`;
    this.loadHDR(this.hdrPath, 0.3);

    this.modelDimensions = new Group();

    this.scene.background = new Color("#fbfbfb");

    this.materials = [];

    this.frameObject = {
      id: "M066-38",
      // image:"https://fairfieldchair-models.s3.us-east-2.amazonaws.com/3d/monogram/images/M066-38.jpg",
      image:
        "https://fairfieldm2.joladev.com/media/3d/monogram/images/M066-38.jpg",
      name: "M066-38",
      parentName: "Frame",
      sku: "M066-38",
      striping: true,
    };

    this.modelsPath = `${themeConfiguration.model_url}/3d/${this.collectionPath}/webgl/models/`;
    this.sku = null;

    this.textureLoader = new TextureLoader();

    this.dracoLoader.setDecoderPath("/decoder/");
    this.loader.setDRACOLoader(this.dracoLoader);

    this.finishId = null;
    this.drawerFinishId = null;

    this.productDimensions = {};

    this.data = data;
  }

  LoadTexture(url) {
    return new Promise((resolve) => {
      this.textureLoader.load(url, (result) => resolve(result));
    });
  }

  LoadObject(url) {
    return new Promise((resolve) => {
      this.loader.load(url, (result) => {
        this.updateMap(result.scene);
        resolve(result);
      });
    });
  }

  async Init() {
    this.scene.add(this.modelDimensions);

    this.renderer.shadowMap.type = PCFSoftShadowMap;

    await this.SetFloor();

    await this.LoadStriping({
      name: "Gold",
      image: `${themeConfiguration.model_url}/3d/${this.collectionPath}/images/Gold Striping.jpg`,
      color: "#653F19",
      metalness: 1,
      roughness: 0.2,
    });

    await this.LoadFrame(this.frameObject, true);
  }

  async LoadFrame(frameObject, updateCameraPosition = false) {
    this.model.children = [];
    this.sku = frameObject.sku;

    await this.HideDimensions();

    let modelURL = `${themeConfiguration.model_url}/3d/${this.collectionPath}/webgl/models/${this.sku}/${this.sku}.glb`;

    let gltf = await this.LoadObject(modelURL);
    this.model.add(gltf.scene);

    if (
      this.finishId &&
      this.data.finishesArray.find(
        (finish) =>
          finish.id === this.finishId &&
          this.frameObject.finishIDs.find((x) => x.id === finish.id)
      )
    ) {
      await this.LoadFinish({ id: this.finishId });
    } else {
      await this.LoadFinish({ id: this.data.finishesArray[0].id });
    }

    if (
      this.drawerFinishId &&
      this.data.finishesArray.find(
        (finish) =>
          finish.id === this.drawerFinishId &&
          this.frameObject.finishIDs.find((x) => x.id === this.drawerFinishId)
      )
    ) {
      await this.LoadDrawerFinish({ id: this.drawerFinishId });
    } else {
      await this.LoadDrawerFinish({
        id: this.data.finishesArray[0].id,
      });
    }

    this.updateMap(this.model);

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async LoadFinish(finishObject, updateCameraPosition = false) {
    this.finishId = finishObject.id;

    this.materials = this.materials.filter(
      (material) => material.type !== "wood" && material.type !== "wood2"
    );

    let url = `${themeConfiguration.model_url}/3d/${this.collectionPath}/webgl/models/${this.sku}/resources/${this.finishId}.jpg`;

    let texture = await this.LoadTexture(url);

    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    texture.flipY = false;

    this.materials.push({
      type: "wood",
      map: texture,
    });

    let otherUrl;
    let otherTexture = texture;

    let customWoodArray = [
      "M181-48",
      "M189-81",
      "M189-91",
      "M189-95",
      "M189-96",
      "M189-AC",
      "M189-CH",
    ];

    if (this.sku === "M066-38") {
      otherUrl = `${themeConfiguration.model_url}/3d/${this.collectionPath}/webgl/models/${this.sku}/resources/${this.finishId}_other.jpg`;
      otherTexture = await this.LoadTexture(otherUrl);

      otherTexture.wrapS = RepeatWrapping;
      otherTexture.wrapT = RepeatWrapping;
      otherTexture.flipY = false;
    } else if (customWoodArray.includes(this.sku)) {
      otherUrl = `${themeConfiguration.model_url}/3d/${this.collectionPath}/webgl/models/${this.sku}/resources/wood2_${this.finishId}.jpg`;
      otherTexture = await this.LoadTexture(otherUrl);

      otherTexture.wrapS = RepeatWrapping;
      otherTexture.wrapT = RepeatWrapping;
      otherTexture.flipY = false;
    }

    this.materials.push({
      type: "wood2",
      map: otherTexture,
    });

    this.updateMap(this.model);

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async LoadDrawerFinish(finishObject, updateCameraPosition = false) {
    this.drawerFinishId = finishObject.id;

    this.materials = this.materials.filter(
      (material) => material.type !== "contrast_drawers"
    );

    let url = `${themeConfiguration.model_url}/3d/${this.collectionPath}/webgl/models/${this.sku}/resources/${this.drawerFinishId}.jpg`;

    let texture = await this.LoadTexture(url);

    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    texture.flipY = false;

    this.materials.push({
      type: "contrast_drawers",
      map: texture,
    });

    this.updateMap(this.model);

    if (updateCameraPosition) await this.UpdateCameraPosition();
  }

  async setDimensions(productDimensions) {
    this.productDimensions = productDimensions;
  }

  async setDimensionsVisible(newState) {
    this.dimensionsToggle = newState;

    if (this.dimensionsToggle) {
      await this.ShowDimensions();
    } else {
      await this.HideDimensions();
    }
  }

  async HideDimensions() {
    this.modelDimensions.children = [];
  }

  async ShowDimensions() {
    await this.HideDimensions();

    let box = new Box3().setFromObject(this.model);

    let lineMaterial = new LineBasicMaterial({
      color: 0x000000,
    });

    let lineOffset = 0.02;

    let x = [];
    x.push(
      new Vector3(box.min.x, box.max.y + lineOffset, box.min.z - lineOffset)
    );
    x.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.min.z - lineOffset
      )
    );

    let y = [];
    y.push(
      new Vector3(box.max.x + lineOffset, box.min.y, box.max.z + lineOffset)
    );
    y.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.max.z + lineOffset
      )
    );

    let z = [];
    z.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.max.z + lineOffset
      )
    );
    z.push(
      new Vector3(
        box.max.x + lineOffset,
        box.max.y + lineOffset,
        box.min.z - lineOffset
      )
    );

    let geometryX = new BufferGeometry().setFromPoints(x);
    let geometryY = new BufferGeometry().setFromPoints(y);
    let geometryZ = new BufferGeometry().setFromPoints(z);

    let lineX = new Line(geometryX, lineMaterial);
    lineX.name = "lineX";
    let lineY = new Line(geometryY, lineMaterial);
    lineY.name = "lineY";
    let lineZ = new Line(geometryZ, lineMaterial);
    lineZ.name = "lineZ";

    lineX.computeLineDistances();

    this.modelDimensions.add(lineX);
    this.modelDimensions.add(lineY);
    this.modelDimensions.add(lineZ);

    let swapDimensions = false;

    let boxWidth = box.max.x - box.min.x;
    let boxHeight = box.max.y - box.min.y;
    let boxDepth = box.max.z - box.min.z;

    if (
      (boxWidth < boxDepth &&
        Number(this.productDimensions.width) >
          Number(this.productDimensions.depth)) ||
      (boxWidth > boxDepth &&
        Number(this.productDimensions.width) <
          Number(this.productDimensions.depth))
    ) {
      swapDimensions = true;
    }

    let distanceX = swapDimensions
      ? this.productDimensions.depth
      : this.productDimensions.width;
    let distanceY = this.productDimensions.height;
    let distanceZ = swapDimensions
      ? this.productDimensions.width
      : this.productDimensions.depth;

    const fontLoader = new FontLoader();

    fontLoader.load(
      `${themeConfiguration.model_url}/3d/fonts/helvetiker_regular.typeface.json`,
      (font) => {
        const fontGeometryX = new TextGeometry(distanceX.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });

        fontGeometryX.translate(-0.1, 0, 0);

        let fontMeshX = new Mesh(
          fontGeometryX,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        let fontOffset = 0.02;

        fontMeshX.position.copy(
          new Vector3(
            box.min.x + (box.max.x - box.min.x) / 2,
            box.max.y + fontOffset + lineOffset,
            box.min.z
          )
        );
        fontMeshX.updateMatrixWorld();

        fontMeshX.name = "fontMeshX";
        this.modelDimensions.add(fontMeshX);

        const fontGeometryY = new TextGeometry(distanceY.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });

        fontGeometryY.translate(0.05, 0, 0);

        let fontMeshY = new Mesh(
          fontGeometryY,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        fontMeshY.rotateY(-Math.PI / 2);
        fontMeshY.position.copy(
          new Vector3(
            box.max.x,
            box.min.y + (box.max.y - box.min.y) / 2,
            box.max.z
          )
        );
        fontMeshY.updateMatrixWorld();

        fontMeshY.name = "fontMeshY";
        this.modelDimensions.add(fontMeshY);

        const fontGeometryZ = new TextGeometry(distanceZ.toString() + '"', {
          font: font,
          size: 0.05,
          height: 0,
        });
        fontGeometryZ.translate(-0.05, 0, 0);

        let fontMeshZ = new Mesh(
          fontGeometryZ,
          new MeshBasicMaterial({ color: 0x000000 })
        );

        fontMeshZ.rotateY(-Math.PI / 2);
        fontMeshZ.position.copy(
          new Vector3(
            box.max.x,
            box.max.y + fontOffset + lineOffset,
            box.min.z + (box.max.z - box.min.z) / 2
          )
        );
        fontMeshZ.updateMatrixWorld();

        fontMeshZ.name = "fontMeshZ";
        this.modelDimensions.add(fontMeshZ);
      }
    );
  }

  async LoadStriping(stripeObject) {
    this.materials = this.materials.filter(
      (material) => material.type !== "striping"
    );

    if (stripeObject.color) {
      this.materials.push({
        type: "striping",
        roughness: stripeObject.roughness,
        metalness: stripeObject.metalness,
        color: stripeObject.color,
      });
    }

    this.updateMap(this.model);
  }

  updateMap(object) {
    object.traverse((o) => {
      if (o.isMesh) {
        o.castShadow = true;
        o.receiveShadow = true;

        let material = this.materials.find(
          (material) => material.type === o.material.name
        );

        if (material) {
          if (material.map) {
            o.material.map = material.map;
            o.material.map.encoding = sRGBEncoding;
          } else {
            o.material.map = null;
          }

          if (material.roughness) o.material.roughness = material.roughness;
          if (material.metalness) o.material.metalness = material.metalness;
          if (material.color) o.material.color = new Color(material.color);
        } else {
          if (o.material.name === "striping") {
            let material = this.materials.find(
              (material) => material.type === "wood"
            );

            if (material) {
              if (material.map) {
                o.material.map = material.map;
                o.material.map.encoding = sRGBEncoding;
              }
              o.material.roughness = 1;
              o.material.metalness = 0.1;
              o.material.color = new Color("#FFFFFF");
            }
          }

          if (o.material.name === "contrast_drawers") {
            let material = this.materials.find(
              (material) => material.type === "wood"
            );
            if (material) {
              if (material.map) {
                o.material.map = material.map;
                o.material.map.encoding = sRGBEncoding;
              } else {
                o.material.map = undefined;
              }

              if (material.roughness) o.material.roughness = material.roughness;
              if (material.metalness) o.material.metalness = material.metalness;
              if (material.color) o.material.color = new Color(material.color);
            }
          }
        }

        if (this.envMap) {
          o.material.envMap = this.envMap;
          o.material.envMapIntensity = this.envMapIntensity;
        }

        o.material.needsUpdate = true;
      }
    });
  }

  update() {
    if (this.dimensionsToggle) {
      let fontMeshX = this.modelDimensions.getObjectByName("fontMeshX");
      let fontMeshY = this.modelDimensions.getObjectByName("fontMeshY");
      let fontMeshZ = this.modelDimensions.getObjectByName("fontMeshZ");
      fontMeshX && fontMeshX.lookAt(this.camera.position);
      fontMeshY && fontMeshY.lookAt(this.camera.position);
      fontMeshZ && fontMeshZ.lookAt(this.camera.position);
    }

    this.controls.update();
    this.renderer.render(this.scene, this.camera);

    requestAnimationFrame(this.update);
  }

  async UpdateCameraPosition() {
    let box = new Box3().setFromObject(this.model);
    let size = box.getSize(new Vector3()).length();
    let center = box.getCenter(new Vector3());

    if (this.plane) {
      this.plane.position.y = box.min.y;
      this.shadowPlane.position.y = box.min.y;
    }

    this.controls.minDistance = size * 0.75;
    this.controls.maxDistance = size * 1.5;

    this.camera.position.copy(center);
    this.camera.position.x += 0; //this.size * 0.1;
    this.camera.position.y += size * 0.2;
    this.camera.position.z += size * 5.3;
    this.camera.updateMatrixWorld();

    this.controls.target = center;
    this.camera.lookAt(center);

    this.camera.updateProjectionMatrix();

    if (this.dimensionsToggle) {
      await this.ShowDimensions();
    }

    document.getElementById("loading-screen").classList.add("fade-out");
  }

  setPlaneVisible(newState) {
    if (this.plane) {
      this.plane.visible = newState;
    }
  }

  async SetFloor() {
    let parquetMap = await this.LoadTexture(
      `${themeConfiguration.model_url}/3d/textures/floor/grid.jpg`
    );
    parquetMap.repeat.set(500, 500);
    parquetMap.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    parquetMap.wrapT = RepeatWrapping;
    parquetMap.wrapS = RepeatWrapping;

    let geo = new PlaneBufferGeometry(100, 100);
    let mat = new MeshBasicMaterial({
      map: parquetMap,
    });

    this.plane = new Mesh(geo, mat);
    //plane.visible = false;
    this.plane.receiveShadow = true;
    this.plane.position.set(0, 0, 0);
    this.plane.rotation.set(Math.PI / -2, 0, 0);

    this.scene.fog = new Fog(0xfbfbfb, 10, 20);
    this.scene.add(this.plane);

    let shadowMat = new ShadowMaterial({ opacity: 0.2 });
    this.shadowPlane = new Mesh(geo, shadowMat);
    this.shadowPlane.receiveShadow = true;
    this.shadowPlane.position.set(0, 0, 0);
    this.shadowPlane.rotation.set(Math.PI / -2, 0, 0);
    this.scene.add(this.shadowPlane);
  }

  async LoadFloor(map, repeat) {
    let newMap = await this.LoadTexture(map);
    newMap.repeat.set(repeat, repeat);
    newMap.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    newMap.wrapS = RepeatWrapping;
    newMap.wrapT = RepeatWrapping;

    this.plane.material.map = newMap;
  }

  degreesToRadians = (degrees) => {
    var pi = Math.PI;
    return degrees * (pi / 180);
  };

  determinCameraDistance = (modelSize) => {
    let cameraDistance;
    let halfFOVInRadians = this.degreesToRadians(
      (this.camera.fov * this.camera.aspect) / 4
    );
    let height = modelSize.height;
    cameraDistance = height / 2 / Math.tan(halfFOVInRadians);
    return cameraDistance;
  };

  updateZoom(zoom, diff = 0) {
    let newZoom = 1 + (zoom + diff) / 10;

    if (
      newZoom >= 1 + zoomOptions.min / 10 &&
      newZoom <= 1 + zoomOptions.max / 10
    ) {
      this.controls.object.zoom = newZoom;
      this.controls.object.updateProjectionMatrix();
      return {
        ...zoomOptions,
        current: zoom + diff,
      };
    } else {
      return { ...zoomOptions, current: zoom };
    }
  }

  getCameraAngle = () => {
    const euler = new Euler();
    const rotation = euler.setFromQuaternion(this.camera.quaternion);
    const radians = rotation._z > 0 ? rotation._z : 2 * Math.PI + rotation._z;
    return radians * (180 / Math.PI);
  };

  getCameraPosition() {
    return {
      x: this.camera.position.x,
      y: this.camera.position.y,
      z: this.camera.position.z,
    };
  }

  setCameraPosition({ x = 50, y = -1, z = 50 }) {
    this.camera.position.x = x;
    this.camera.position.y = y;
    this.camera.position.z = z;
  }

  createBlob(input, type) {
    return new Promise((resolve) => {
      input = input || this.model;

      switch (type) {
        case "USDZ":
          this.exporter = new USDZExporter();
          this.exporter.parse(input).then((buffer) => {
            let blob = new Blob([buffer], {
              type: "application/octet-stream",
            });
            resolve(blob);
          });
          break;

        case "GLTF":
        default:
          let options = {
            trs: false,
            onlyVisible: true,
            trunctateDrawRange: false,
            binary: true,
            maxTextureSize: 2048,
          };
          this.exporter = new GLTFExporter();
          this.exporter.parse(
            input,
            (buffer) => {
              let blob = new Blob([buffer], {
                type: "application/octet-stream",
              });
              resolve(blob);
            },
            options
          );
          break;
      }
    });
  }

  async uploadBlob(name, blob) {
    try {
      return new Promise((resolve) => {
        let xmlhttp = new XMLHttpRequest();
        xmlhttp.open(
          "POST",
          `${themeConfiguration.magento_url}jola_ar/index/index?fileName=${name}`,
          true
        );

        xmlhttp.onload = function () {
          resolve(true);
        };
        xmlhttp.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xmlhttp.send(blob);
      });
    } catch (error) {}
  }

  createQR(input) {
    return new Promise((resolve) => {
      resolve(input);
    });
  }

  viewInRoom() {
    let copyModel;
    this.model.updateMatrixWorld();
    copyModel = this.model.clone();
    copyModel.traverse((o) => {
      if (o.material) {
        o.material.aoMap = null;
        o.material.normalMap = null;
      }
    });
    let uniqueHash = Date.now();
    let userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.match(/Android/i);
    const isApple = userAgent.match(/iPhone|iPad|iPod/i);

    if (isAndroid) {
      this.createBlob(copyModel, "GLTF").then((gltf) => {
        let glbName = uniqueHash.toString() + ".glb";

        this.uploadBlob(glbName, gltf).then(
          () =>
            (window.location.href = `${themeConfiguration.app_url}build-your-own/monogram?file_name=${uniqueHash}&ar=true`)
        );
      });
    } else if (isApple) {
      this.createBlob(copyModel, "USDZ").then((usdz) => {
        let usdzName = uniqueHash.toString() + ".usdz";
        this.uploadBlob(usdzName, usdz).then(
          () =>
            (window.location.href = `${themeConfiguration.app_url}build-your-own/monogram?file_name=${uniqueHash}&ar=true`)
        );
      });
    } else {
      this.createBlob(copyModel, "GLTF").then((gltf) => {
        let glbName = uniqueHash.toString() + ".glb";
        this.uploadBlob(glbName, gltf).then(() => {
          this.createBlob(copyModel, "USDZ").then((usdz) => {
            let usdzName = uniqueHash.toString() + ".usdz";
            this.uploadBlob(usdzName, usdz).then(() => {
              this.createQR(
                `${themeConfiguration.app_url}build-your-own/monogram?file_name=${uniqueHash}&ar=true`
              ).then((url) => {
                copyModel.clear();
                return url;
              });
            });
          });
        });
      });
    }
    return uniqueHash;
  }
}
