export default {
  data: {
    token: false,
    id: false,
    email: false,
    firstname: false,
    lastname: false,
    is_subscribed: false,
    is_trade: false,
    resetPassword: false
  },
  loading: false
};
