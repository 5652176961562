import { isEmpty, isUndefined } from "core/helpers/functions";

export default (state, action) => {
  let currentFilter = state;
  let newFilter = action.filter;

  // Object.keys(currentFilter).map((index, value) => {
  //   let searchKey = Object.keys(newFilter)[0];

  //   if (
  //     !isUndefined(currentFilter["items"]) &&
  //     searchKey in currentFilter["items"]
  //   ) {
  //     return (currentFilter["items"][searchKey] = newFilter[searchKey]);
  //   } else {
  //     if (!isUndefined(currentFilter["items"])) {
  //       return (currentFilter["items"][searchKey] = newFilter[searchKey]);
  //     }
  //   }
  //   return true;
  // });
  // if (isEmpty(currentFilter["items"])) {
  //   currentFilter["items"] = newFilter;
  // }

  if (isUndefined(currentFilter["items"])) {
    currentFilter["items"] = {};
  }

  let newF = Object.keys(newFilter).reduce((accumulator, key) => {
    let filter = newFilter[key];
    if (filter.filter_type === "multi") {
      let newFilterValues =
        currentFilter.items[key] && currentFilter.items[key].filter_value
          ? currentFilter.items[key].filter_value.concat(filter.filter_value)
          : [filter.filter_value];
      accumulator[key] = Object.assign({}, currentFilter.items[key], {
        filter_value: newFilterValues,
        filter_label: filter.filter_label,
        filter_type: filter.filter_type,
      });
    } else {
      accumulator[key] = filter;
    }
    return accumulator;
  }, {});

  if (isEmpty(currentFilter["items"])) {
    currentFilter["items"] = newF;
  } else {
    currentFilter["items"] = Object.assign({}, currentFilter["items"], newF);
  }

  if (
    typeof currentFilter.data !== "undefined" &&
    typeof currentFilter.data.page_info !== "undefined"
  ) {
    currentFilter.data.page_info.current_page = 1;
  } else {
    currentFilter.data = {
      page_info: { current_page: 1 },
      sort_fields: { default: "name" },
    };
  }

  return Object.assign({}, state, currentFilter);
};
