import React from "react";
import { MDBBox } from "mdbreact";
import Icon from "app/assets/icon/icon";
import { ModalConsumer } from "core/components/modal/ModalContext";
import SearchModal from "./searchModal";
import { useMediaQuery } from "react-responsive";
const Search = ({
  open,
  setOpen,
  searchVal,
  setSearchVal,
  option,
  setOption,
  searchResult,
  options,
}) => {
  let isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <MDBBox className="text-center search-container ml-0 ml-xl-4">
      <ModalConsumer value={{ useWrapper: false }}>
        {({ showModal, hideModal }) => (
          <div
            className="d-flex"
            onClick={(e) => {
              showModal(() => {
                return (
                  <SearchModal
                    open={open}
                    setOpen={setOpen}
                    searchVal={searchVal}
                    setSearchVal={setSearchVal}
                    option={option}
                    setOption={setOption}
                    options={options}
                    hideModal={hideModal}
                    searchResult={searchResult}
                  />
                );
              });
            }}
          >
            {!isMobile && <h2 className="display-4 mr-3 text-white">SEARCH</h2>}
            <Icon icon="search" className="search-icon" />
          </div>
        )}
      </ModalConsumer>
    </MDBBox>
  );
};
export default Search;
